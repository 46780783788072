import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import moment from 'moment';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { environment } from '#environment';

import { InvoiceStatusFilter } from './invoice-status.filter';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { IPageWrapperTitle } from 'app/shared/components/cmx-page-wrapper/page-wrapper-interface';
import { IGridColumn, IGridRow } from 'app/shared/components/cmx-custom-grid/interfaces';
import { INVOICE_STATUS_COLUMNS } from './constants/invoice-status-columns.constant';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';

import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { InvoiceStatusService } from './services/invoice-status.service';
import { UserService } from '../../../auth/_services/user.service';
import { InvoiceStatusUtilsService } from './services/invoice-status-utils.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';
import { EventSourceService } from '#services/_eventSource/eventSource.service';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { DomainService } from '#services/_domain/domain.service';
import { UtilsService } from '#services/_utils/utils.service';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';

@Component({
    selector: 'invoice-status',
    templateUrl: './invoice-status.component.html',
    styleUrls: ['./invoice-status.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class InvoiceStatusComponent implements OnInit {
    titleComponent: IPageWrapperTitle = { title: 'Notas Fiscais' };
    currentUser$ = this._userService.currentUser$;

    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);
    helpDescription = this._defineHelpDescription();

    options: {
        client: DropdownOption[];
        company: DropdownOption[];
        cr: DropdownOption[];
    } = {
        client: [],
        company: [],
        cr: [],
    };

    intraSwitch = {
        values: ['c/ IntraCompany', 's/ IntraCompany'],
        index: 0,
    };

    exportAsync = false;
    loadingInvoiceStatusTable = true;

    queryId = Math.random();
    subscription;
    zipSubscription;

    startDate = this._parseStartDate();
    endDate = this._parseEndDate();

    filterState: InvoiceStatusFilter = this._filterStateService.getInvoiceStatusFilter();

    summaries = [];
    selectedSummary = 0;
    summaryLine = [];

    gridColumns: IGridColumn[] = this._setGridColumns();
    gridRows: IGridRow[] = [];
    limit = 50;
    pageNumber = 0;
    pages = [];

    multipleImpClientCode;
    invoiceNumber = null;

    periodTypes = [
        { code: 'FAT', name: 'Faturamento' },
        { code: 'DEV', name: 'Devolução' },
        { code: 'related', name: 'Com IMP' },
        { code: 'unrelated', name: 'Sem IMP' },
    ];

    preSelectedPeriod = [];

    gridHeaderFilter = { column: '', filter: '' };

    showChassiModal = false;
    chassiDetails;
    chassiList;

    constructor(
        public invoiceStatusUtilsService: InvoiceStatusUtilsService,
        private _eventChannel: EventSourceService,
        private _invoiceStatusService: InvoiceStatusService,
        private _domainService: DomainService,
        private _route: ActivatedRoute,
        private _userService: UserService,
        private _router: Router,
        private _utilsService: UtilsService,
        private _userCacheService: UserCacheService,
        private _featureFlagsService: FeatureFlagService,
        private _cmxDropdownService: CmxDropdownService,
        private _filterStateService: FilterStateService,
        private _customDialogService: CustomDialogService,
    ) {
        if (this._route.snapshot.params.intraCompany) {
            const intraCompany = this._route.snapshot.params.intraCompany === '1' ? false : true;
            this.toggleIntraSwitch(intraCompany);
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.zipSubscription) {
            this.zipSubscription.unsubscribe();
        }
    }

    ngOnInit() {
        this.filterState.getInvoiceRelatedImpList().forEach((c) => this.preSelectedPeriod.push(c));
        this.filterState.getInvoiceTypeList().forEach((c) => this.preSelectedPeriod.push(c));

        $('.InvoiceStatusContainer #startDate, .InvoiceStatusContainer #endDate').datepicker({
            format: 'dd/mm/yyyy',
            language: 'pt-BR',
            updateViewDate: false,
            autoclose: true,
        });
    }

    ngAfterViewInit() {
        this.zipSubscription = forkJoin([
            this._domainService.getCompanies(),
            this._domainService.getProfitCenter(this.optionalCR()),
        ]).subscribe(
            (values) => {
                const [companies, profitCenters] = values;
                this.options.client = this._cmxDropdownService.toDropdownItems(
                    this._userCacheService.getClientGroups()
                );
                this.options.company = this._cmxDropdownService.toDropdownItems(companies);
                this.options.cr = this._cmxDropdownService.toDropdownItems(profitCenters);
                const param = this._route.snapshot.params.profitCenters;
                if (param !== '0' && param !== '16' && !param) {
                    this.filterState.setCompanies([]);
                    this.filterState.setProfitCenters([]);
                }
                if (param?.includes('!')) {
                    const CR = param.split('!')[0];
                    const type = param.split('!')[1];
                    const codeType = param.split('!')[2];
                    this.options.cr.forEach((cr) => {
                        if (CR === cr.id) {
                            this.filterState.getProfitCenters().push(cr);
                        }
                    });
                    if (type === 'company') {
                        this.options.company.forEach((comp) => {
                            if (codeType === comp.id) {
                                this.filterState.getCompanies().push(comp);
                            }
                        });
                    } else {
                        this.options.client.forEach((cli) => {
                            if (codeType === cli.id) {
                                this.filterState.getClientGroups().push(cli);
                            }
                        });
                    }
                } else {
                    this.options.cr.forEach((cr) => {
                        if (param === cr.id) {
                            this.filterState.getProfitCenters().push(cr);
                        }
                    });
                }

                this._handleRequestParam();
                this.loadInvoices();
            },
            (error) => {
                this._utilsService.errorHandler(error);
            }
        );
    }

    datesChanged(dates) {
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
    }

    toggleIntraSwitch(values) {
        this.filterState.setIntraCompany(values);
    }

    toImpDetail(imp) {
        if (this._utilsService.isIE()) {
            this._router.navigate([`./supply-chain/importacao/${this.multipleImpClientCode}/${imp}`]);
        } else {
            window.open(`./supply-chain/importacao/${this.multipleImpClientCode}/${imp}`);
        }
    }

    switchSummary() {
        this.selectedSummary = this.selectedSummary === 0 ? 1 : 0;
        this._populateSummaryLine(this.summaries[this.selectedSummary]);
    }

    applyFilter() {
        this.resetPages();
        this.loadInvoices();
    }

    changeTypeView(type, element) {
        if ($(element.target).hasClass('checked')) {
            $(element.target).removeClass('checked');
        } else {
            $(element.target).addClass('checked');
        }
        if (this.filterState.getInvoiceTypeList().indexOf(type) > -1) {
            this.filterState.setInvoiceTypeList(this.filterState.getInvoiceTypeList().filter((e) => e !== type));
        } else {
            this.filterState.getInvoiceTypeList().push(type);
        }
        this.resetPages();
        this.loadInvoices();
    }

    changeImpRelatedView(type, element) {
        if ($(element.target).hasClass('checked')) {
            $(element.target).removeClass('checked');
        } else {
            $(element.target).addClass('checked');
        }
        if (this.filterState.getInvoiceRelatedImpList().indexOf(type) > -1) {
            this.filterState.setInvoiceRelatedImpList(
                this.filterState.getInvoiceRelatedImpList().filter((e) => e !== type)
            );
        } else {
            this.filterState.getInvoiceRelatedImpList().push(type);
        }
        this.resetPages();
        this.loadInvoices();
    }

    loadInvoices(changePage?) {
        this.loadingInvoiceStatusTable = true;
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        const userHasAutomotivePermission = this.invoiceStatusUtilsService.userHasAutomotivePermission().toString();
        this.subscription = this._invoiceStatusService
            .getInvoices(
                this.filterState._getRequest(
                    this.gridHeaderFilter,
                    userHasAutomotivePermission,
                    this.startDate,
                    this.endDate,
                    this.limit,
                    this.pageNumber
                )
            )
            .subscribe(
                (nfs: any) => {
                    const data = this._featureFlagsService.isFeatureFlagEnabled(
                        'invoice-service:client-invoice-use-elastic'
                    )
                        ? this._invoiceStatusService.convertsToCompatibleData(nfs)
                        : nfs.content;
                    this.summaries = nfs.summary;
                    this.summaryLine = nfs.summary;
                    this._populateSummaryLine(nfs.summary[0]);
                    this.pages = Array.apply(null, { length: nfs.totalPages }).map(Number.call, Number);
                    this.gridColumns = this._setGridColumns();
                    this.gridRows = this.extractRows(data);
                    this.loadingInvoiceStatusTable = false;

                    if (!changePage) {
                        this.selectedSummary = 0;
                    }
                    this.loadingInvoiceStatusTable = false;
                },
                (error) => {
                    this.gridRows = undefined;
                    this._utilsService.errorHandler(error, 'Notas Fiscais');
                    this.loadingInvoiceStatusTable = false;
                }
            );
    }

    changePage(pageNumber) {
        this.pageNumber = pageNumber - 1;
        this.loadInvoices('changePage');
    }

    exportInvoices() {
        this.callExportMessage();
        let params = '';
        params += `?startDate=${moment(this.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')}`;
        params += `&endDate=${moment(this.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')}`;
        params += `&companies=${this.filterState.getCompanies().map((company) => company.id).join(',')}`;
        params += `&profitCenters=${this.filterState.getProfitCenters().map((c) => c.id).join(',')}`;
        params += `&invoiceTypes=${this.filterState.getInvoiceTypeList().join(',')}`;
        params += `&intraCompanies=${this.filterState.getIntraCompany()}`;
        params += `&isAutomotive=${this.invoiceStatusUtilsService.userHasAutomotivePermission().toString()}`;

        // Custom filters in grid columns
        if (this.gridHeaderFilter.filter) {
            params += `&${this.gridHeaderFilter.column}=${this.gridHeaderFilter.filter}`;
        }

        const headers = `${this.filterState.getClientGroups().map((c) => c['id']).join(',')}`;

        if (this.filterState.getInvoiceRelatedImpList().length !== 0 && this.filterState.getInvoiceRelatedImpList().length !== 2) {
            params += `&impRelated=${this.filterState.getInvoiceRelatedImpList()[0] === 'related' ? 'true' : 'false'}`;
        }

        let url = `${environment.endpoints.supplyChainService}/invoice/analytical-report/async`;

        if (this._featureFlagsService.isFeatureFlagEnabled('invoice-service:client-invoice-use-elastic')) {
            url = `${environment.endpoints.invoiceService}/v1/client-invoices/analytical-report/async`;
        }

        this._eventChannel.openEventSource(url, headers, params);
    }

    onCellClick({ item, column }) {
        const { companyCode, completeImpsData } = item;
        const imp = completeImpsData[0] && completeImpsData[0].imp;
        if (column === 'imps' && imp && this._userService.getCurrentUser().has('ROUTE_SUPPLY_CHAIN_IMP_DETAIL')) {
            this.invoiceStatusUtilsService.navigateToImpDetail({ companyCode, imp });
        }

        if(column === 'chassis') {
            this.chassiList = item.chassis;
            this.chassiDetails = [
                {
                    title: 'NF',
                    value: item.invoiceNumber,
                },
                {
                    title: 'Valor NF',
                    value: this._utilsService.formatNumber(item.invoiceAmount || 0, { style: 'currency', currency: 'BRL' }),
                },
            ];

            this.showChassiModal = true;
            this._customDialogService.open('invoice-status-chassi-dialog');
        }
    }

    onSelectedPeriod({ selected }) {
        this.filterState.setInvoiceTypeList([]);
        this.filterState.setInvoiceRelatedImpList([]);
        selected.forEach((tab) => {
            if (tab.code === 'FAT' || tab.code === 'DEV') {
                this.filterState.getInvoiceTypeList().push(tab.code);
            } else {
                this.filterState.getInvoiceRelatedImpList().push(tab.code);
            }
        });
        this.resetPages();
        this.loadInvoices();
    }

    private _populateSummaryLine(nfs) {
        this.summaryLine = [
            {
                title: nfs.qty + ' NFs',
                value: this._utilsService.formatNumber(nfs.totalAmount || 0, { style: 'currency' }) || 0,
            },
            {
                title: nfs.impRelatedQty + ' NFs c/ IMPS',
                value: this._utilsService.formatNumber(nfs.impRelatedTotalAmount || 0, { style: 'currency' }) || 0,
            },
            {
                title: nfs.otherQty + ' NFs s/ IMPS',
                value: this._utilsService.formatNumber(nfs.otherTotalAmount || 0, { style: 'currency' }) || 0,
            },
        ];
    }

    private _extractExtraParamsFromImps(imps: any[]): any {
        const initializedParams = {
            chassis: [],
            clientName: [],
            clientReference: [],
            subsidiaryCnpj: [],
            vendor: [],
        };

        return (
            imps &&
            imps.reduce((accumulator: any, currentValue: any) => {
                return {
                    chassis: _.compact(accumulator.chassis.concat(currentValue.chassis)),
                    clientName: _.compact([...accumulator.clientName, currentValue.client && currentValue.client.name]),
                    clientReference: _.compact([...accumulator.clientReference, currentValue.clientReference]),
                    subsidiaryCnpj: _.compact([...accumulator.subsidiaryCnpj, currentValue.subsidiaryCnpj]),
                    vendor: _.compact([...accumulator.vendor, currentValue.vendor]),
                };
            }, initializedParams)
        );
    }

    private extractRows(data: any): any[] {
        const invoiceTypeList = !this.filterState.getInvoiceTypeList().length ? ['FAT', 'DEV'] : this.filterState.getInvoiceTypeList();
        return data
            .filter((row: any) => invoiceTypeList.indexOf(row.invoiceType) > -1)
            .map((row: any, index: number) => {
                const company = row.company || { code: '', name: '' };
                const imps = row.imps && _.compact(row.imps.map((imp) => imp.imp));

                return {
                    id: index,
                    invoiceNumber: row.invoiceNumber,
                    imps,
                    completeImpsData: row.imps,
                    companyCode: company.code,
                    companyName: company.name || '\u2014',
                    invoiceDate: moment(row.invoiceDate).format('DD/MM/YYYY'),
                    invoiceAmount: row.invoiceAmount,
                    invoiceAmountAverageRate: row.invoiceAmountAverageRate,
                    ...this._extractExtraParamsFromImps(row.imps),
                };
            });
    }

    private resetPages() {
        this.queryId = Math.random();
        this.pageNumber = 0;
    }

    private optionalCR() {
        if (this.filterState.getProfitCenters().filter((e) => e.code === '49')) {
            return 49;
        } else {
            return undefined;
        }
    }

    private callExportMessage() {
        this.exportAsync = true;
        setTimeout(() => {
            this.exportAsync = false;
        }, 5000);
    }

    private _setGridColumns(): IGridColumn[] {
        const columns = _.cloneDeep(INVOICE_STATUS_COLUMNS);
        if (this.invoiceStatusUtilsService.userHasAutomotivePermission()) {
            columns.splice(1, 0, {
                field: 'chassis',
                name: 'CHASSI',
                width: 200,
                formatterType: 'multiple',
            });
        }

        if (this.invoiceStatusUtilsService.filterHasCxTradeCompany(this.filterState.getCompanies())) {
            columns.splice(columns.length - 2, 0, {
                field: 'invoiceAmountAverageRate',
                name: 'Valor Taxa Média',
                formatterType: 'currency',
                width: 250,
                toolTip: 'Base de cálculo considerando Taxa Média para análise contábil',
            });
        }
        return columns;
    }

    private _handleRequestParam() {
        if (this._route.snapshot.queryParamMap.get('force')) {
            this.filterState.defaultFilter();
            this.preSelectedPeriod = [];

            let clientGroups = this._route.snapshot.queryParamMap.getAll('clientGroups');
            if (clientGroups) {
                this.options.client.forEach((cli) => {
                    if (clientGroups?.includes(cli.id)) {
                        this.filterState.getClientGroups().push(cli);
                    }
                });
            }

            let companies = this._route.snapshot.queryParamMap.getAll('companies');
            this.options.company.forEach((comp) => {
                if (companies?.includes(comp.id)) {
                    this.filterState.getCompanies().push(comp);
                }
            });
            this.filterState.setIntraCompany(this._route.snapshot.queryParamMap.get('intraCompany') === 'true');

            let profitCenters = this._route.snapshot.queryParamMap.getAll('profitCenters');
            this.options.cr.forEach((cr) => {
                if (profitCenters?.includes(cr.id)) {
                    this.filterState.getProfitCenters().push(cr);
                }
            });
        }
    }

    private _parseStartDate() {
        return moment(this._route.snapshot.params.startDate || moment().format('YYYY-MM-01'), 'YYYY-MM-DD').format(
            'DD/MM/YYYY'
        );
    }

    private _parseEndDate() {
        if (this._route.snapshot.params.endDate) {
            return moment(this._route.snapshot.params.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
        }
        return moment(moment().format('YYYY-MM-01'), 'YYYY-MM-DD').add('1', 'M').add('-1', 'days').format('DD/MM/YYYY');
    }

    private _defineHelpDescription(): object[] {
        return [
            {
                type: 'text',
                value: 'Acesso à relação de processos faturados, exibindo: ',
            },
            {
                type: 'list',
                list: 'Número, data de emissão e valor da NF;',
            },
            {
                type: 'list',
                list: 'Referências do processo e exportador.', hasLineAfter: true,
            },
            {
                type: 'text',
                value: 'É possível filtrar por: ',
            },
            {
                type: 'list',
                list: '“Faturamento” e “Devolução;”',
            },
            {
                type: 'list', hasLineAfter: true,
                list: 'Período de faturamento.',
            },
            {
                type: 'text',
                value: 'Linha totalizadora refletindo quantidade e valor total das NFs',
            }
        ]
    }
}
