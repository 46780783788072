import { Component, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { generateData, statuses, layouts } from './data';

declare let moment: any;

@Component({
    selector: ".m-grid__item.m-grid__item--fluid.m-wrapper",
    templateUrl: "./imp-follow-up.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class ImpFollowUpComponent implements AfterViewInit {

    tabs = [
        "Pedido",
        "A Embarcar",
        "Trânsito",
        "Em Registro",
        "Registrado",
        "Faturamento e Entrega"
    ];

    data: Array<any> = [];

    datatable: any;

    constructor() {

    }

    ngAfterViewInit() {
        this.data = generateData();
        this.datatable = this.updateTable(0, this.tabs[0]);
    }

    updateTable(i: number, tab: string) {
        if (this.datatable) {
            this.datatable.destroy();
        }

        this.datatable = $(`#imp-follow-up-${i}`).mDatatable({
            // datasource definition
            data: {
                type: 'local',
                source: this.data,
                pageSize: 10
            },

            // layout definition
            layout: {
                theme: 'default', // datatable theme
                class: '', // custom wrapper class
                scroll: false, // enable/disable datatable scroll both horizontal and vertical when needed.
                height: 450, // datatabreturn le's body's fixed height
                footer: false // display/hide footer
            },

            // column sorting
            sortable: true,

            pagination: true,

            // columns definition
            columns: layouts[tab]
        })
    }

    trackByFn(index: number, item: any) {
        return index;
    }
}
