export const FOLLOWUP_FILTER_LISTS = {
    listNotFilter: [
        'status',
        'exchange[*].paymentTerms',
        'vendorInvoices.invoices[*].letterOfCredit',
        'customsClearance.dtaChannel',
        'customsClearance.clearanceData.daData.channel',
        'customsClearance.clearanceData.diDataList[*].channel',
        'vendorInvoices.invoices[*].totalWeight',
        'vendorInvoices.invoices[*].totalGrossWeight',
        'exchangeDetail.exchangeAmountLocalTotal',
        'exchangeDetail.entries[*].exchangeAmountLocal',
        'exchangeDetail.exchangeBalanceTotal',
        'vendorInvoices.invoices[*].items[*].billableQuantityDecimal',
        'vendorInvoices.invoices[*].items[*].billableUnitMeasureDescription',
        'vendorInvoices.invoices[*].items[*].batchNumber',
    ],
    listNotSortable: [
        'vendorInvoices.invoices[*].totalWeight',
        'vendorInvoices.invoices[*].totalGrossWeight',
        'exchangeDetail.exchangeAmountLocalTotal',
        'exchangeDetail.exchangeBalanceTotal',
        'vendorInvoices.invoices[*].items[*].billableQuantityDecimal',
        'vendorInvoices.invoices[*].items[*].billableUnitMeasureDescription',
        'vendorInvoices.invoices[*].items[*].batchNumber',
    ],
    listFilterDates: [
        'proformaDate',
        'exchangeDetail.entries[*].paymentExchangeDate',
        'importationLicenses[*].dates.restrictionDate',
        'purchaseOrderDate',
        'registrationDate',
        'vendorInvoices.invoices[*].invoiceDate',
        'importationLicenses[*].dates.acceptanceDate',
        'importationLicenses[*].dates.issueDate',
        'importationLicenses[*].dates.protocolDate',
        'boardingAuthorizationDate',
        'customsClearance.shippingDate',
        'customsClearance.boardingForecastDate',
        'customsClearance.eta',
        'customsClearance.arrivalDate',
        'containerControls[*].requestRemoveDate',
        'containerControls[*].removeDate',
        'containerControls[*].transitConclusionRemoveDate',
        'customsClearance.dates.customDate',
        'customsClearance.clearanceData.daData.registrationDate',
        'customsClearance.clearanceData.daData.clearanceDate',
        'customsClearance.clearanceData.diDataList[*].registrationDate',
        'customsClearance.clearanceData.diDataList[*].clearanceDate',
        'customsClearance.cargoPresenceDate',
        'customsClearance.unloadingDate',
        'containerControls[*].requestUnloadingDate',
        'containerControls[*].expectedUnloadingDate',
        'customsClearance.dates.diReceptionDate',
        'customsClearance.dates.distributionDate',
        'customsClearance.dates.inspectionMapDate',
        'customsClearance.dates.conferenceDate',
        'customsClearance.registrationDeadline',
        'customsClearance.mapaSubmissionDate',
        'customsClearance.mapaClearanceDate',
        'customsClearance.dates.demurrageExpirationDate',
        'containerControls[*].returnDate',
        'customsClearance.removalDate',
        'customsClearance.dates.rescheduleClearanceDate',
        'entryInvoices[*].invoiceDate',
        'shipmentInvoices[*].invoiceDate',
        'returnInvoices[*].invoiceDate',
        'billingInvoices[*].invoiceDate',
        'exchange[*].exchangeDate',
        'exchange[*].exchangeDueDate',
        'customsClearance.dates.insuranceExtensionDate',
        'customsClearance.dates.retificationDate',
        'customsClearance.dates.inspectionDate',
        'customsClearance.dates.inspectionApprovalDate',
        'closingDate',
        'customsClearance.dates.billOfLadingLiberation',
        'customsClearance.dates.merchandiseByExporter',
        'customsClearance.dates.merchandiseToAgent',
        'customsClearance.documentDeliveries[*].operationalDocumentDeliveryDate',
        'customsClearance.documentDeliveries[*].clearingDocumentDeliveryDate',
        'delivery.loadingDates[*].date',
        'delivery.loadingForecastDates[*].date',
        'delivery.deliveryForecastDates[*].date',
        'delivery.deliveryDates[*].date',
        'delivery.loadingRequestDates[*].date',
        'customsClearance.freightForwarderSendDate',
        'customsClearance.sealingEndDate',
        'customsClearance.boardingInstructionDate',
        'containerControls[*].carrierDescription',
        'containerFormatter.formatRequestRemoveDate',
        'merchandiseNeededDate',
        'daExpirationDate',
        'previsionBillingDate',
        'customsClearance.siscargaClearanceDate',
        'previsionBillingDates[*].date',
        'customsClearance.etaPort',
        'importationLicenses[*].gruPaymentDates',
        'vendorInvoices.invoices[*].hedges[*].contractDate',
        'vendorInvoices.invoices[*].hedges[*].expirationDate',
        'customsClearance.dates.customsTransitDate',
        'customsClearance.dates.transitClosureDate',
        'liRequestDate',

        //FUP SKU
        'consolidatedImp.proformaDate',
        'consolidatedImp.vendorInvoices.invoices[*].invoiceDate',
        'consolidatedImp.purchaseOrderDate',
        'consolidatedImp.registrationDate',
        'consolidatedImp.importationLicenses[*].dates.acceptanceDate',
        'consolidatedImp.importationLicenses[*].dates.issueDate',
        'consolidatedImp.importationLicenses[*].dates.protocolDate',
        'consolidatedImp.boardingAuthorizationDate',
        'consolidatedImp.customsClearance.shippingDate',
        'consolidatedImp.customsClearance.eta',
        'consolidatedImp.customsClearance.arrivalDate',
        'consolidatedImp.containerControls[*].requestRemoveDate',
        'consolidatedImp.containerControls[*].removeDate',
        'consolidatedImp.containerControls[*].transitConclusionRemoveDate',
        'consolidatedImp.customsClearance.dates.customDate',
        'consolidatedImp.customsClearance.clearanceData.daData.registrationDate',
        'consolidatedImp.customsClearance.clearanceData.diDataList[*].clearanceDate',
        'consolidatedImp.customsClearance.clearanceData.daData.clearanceDate',
        'consolidatedImp.customsClearance.cargoPresenceDate',
        'consolidatedImp.customsClearance.unloadingDate',
        'consolidatedImp.containerControls[*].requestUnloadingDate',
        'consolidatedImp.containerControls[*].expectedUnloadingDate',
        'consolidatedImp.customsClearance.dates.distributionDate',
        'consolidatedImp.customsClearance.dates.diReceptionDate',
        'consolidatedImp.customsClearance.dates.inspectionMapDate',
        'consolidatedImp.customsClearance.dates.conferenceDate',
        'consolidatedImp.customsClearance.dates.conferenceDate',
        'consolidatedImp.customsClearance.registrationDeadline',
        'consolidatedImp.customsClearance.mapaSubmissionDate',
        'consolidatedImp.customsClearance.mapaClearanceDate',
        'consolidatedImp.customsClearance.dates.demurrageExpirationDate',
        'consolidatedImp.containerControls[*].returnDate',
        'consolidatedImp.entryInvoices[*].invoiceDate',
        'consolidatedImp.shipmentInvoices[*].invoiceDate',
        'consolidatedImp.returnInvoices[*].invoiceDate',
        'consolidatedImp.billingInvoices[*].invoiceDate',
        'consolidatedImp.exchange[*].exchangeDate',
        'consolidatedImp.exchange[*].exchangeDueDate',
        'consolidatedImp.customsClearance.dates.insuranceExtensionDate',
        'consolidatedImp.closingDate',
        'consolidatedImp.delivery.loadingDates[*].date',
        'consolidatedImp.delivery.deliveryForecastDates[*].date',
        'consolidatedImp.delivery.deliveryDates[*].date',
        'consolidatedImp.delivery.loadingRequestDates[*].date',
        'consolidatedImp.delivery.loadingForecastDates[*].date',
        'consolidatedImp.customsClearance.freightForwarderSendDate',
        'consolidatedImp.customsClearance.sealingEndDate',
        'consolidatedImp.customsClearance.documentDeliveries[*].operationalDocumentDeliveryDate',
        'consolidatedImp.customsClearance.documentDeliveries[*].clearingDocumentDeliveryDate',
        'consolidatedImp.customsClearance.boardingInstructionDate',
        'consolidatedImp.containerControls[*].carrierDescription',
        'consolidatedImp.customsClearance.dates.rescheduleClearanceDate',
        'consolidatedImp.customsClearance.removalDate',
        'consolidatedImp.customsClearance.clearanceData.diDataList[*].registrationDate',
        'consolidatedImp.customsClearance.dates.retificationDate',
        'consolidatedImp.customsClearance.dates.merchandiseToAgent',
        'consolidatedImp.customsClearance.dates.inspectionDate',
        'consolidatedImp.customsClearance.dates.inspectionApprovalDate',
        'consolidatedImp.customsClearance.dates.merchandiseByExporter',
        'consolidatedImp.vendorInvoices.invoices[*].lcOpeningDate',
        'consolidatedImp.importationLicenses[*].dates.boardingLimitDate',
        'consolidatedImp.importationLicenses[*].dates.diRegistrationExpiryDate',
        'consolidatedImp.customsClearance.boardingForecastDate',
        'consolidatedImp.customsClearance.siscargaClearanceDate',
        'consolidatedImp.merchandiseNeededDate',
        'consolidatedImp.daExpirationDate',
        'importationLicenses[*].dates.diRegistrationExpiryDate',
        'importationLicenses[*].dates.boardingLimitDate',
        'vendorInvoices.invoices[*].lcOpeningDate',
        'consolidatedImp.containerFormatter.formatRequestRemoveDate',
        'consolidatedImp.importationLicenses[*].gruPaymentDatesFormatter',
        'consolidatedImp.vendorInvoices.invoices[*].hedges[*].contractDate',
        'consolidatedImp.vendorInvoices.invoices[*].hedges[*].expirationDate',
        'consolidatedImp.importationLicenses[*].dates.restrictionDate',
        'consolidatedImp.customsClearance.dates.customsTransitDate',
        'consolidatedImp.customsClearance.dates.transitClosureDate',
        'consolidatedImp.liRequestDate',
    ],
    listFilterMonetaryValues: [
        'vendorInvoices.totalAmount',
        'exchangeDetail.exchangeAmountForeignTotal',
        'exchangeDetail.exchangeAmountLocalTotal',
        'exchangeDetail.exchangeBalanceTotal',
        'vendorInvoices.invoices[*].amount',
        'customsClearance.penaltyAmount',
        'entryInvoices[*].amount',
        'exchangeDetail.entries[*].exchangeAmountForeign',
        'shipmentInvoices[*].amount',
        'returnInvoices[*].amount',
        'billingInvoices[*].amount',
        'clientInvoices.totalAmount',
        'clientInvoices.totalWithoutMarkup',
        'forecast',
        'importationForecastTax',
        'totalCash'
    ],
    listFilterMonetaryValuesBigDecimal: [
        'diTaxDolar',
        'diTaxEuro',
        'exchangeDetail.exchangeRateTotal',

        // FUP SKU
        'consolidatedImp.diTaxDolar',
        'consolidatedImp.diTaxEuro',
    ],
    listOnlyNumbers: [
        'exchange[*].exchangeRemainingDays',

        // FUP SKU
        'consolidatedImp.exchange[*].exchangeRemainingDays',
    ]
};
