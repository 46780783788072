export class NewTrafficSummary {
  private totalCustomerSearch: number = 0;
  private totalVessel: number = 0;
  private totalAirPlane: number = 0;
  private totalImps: number = 0;  
  private totalPackages: number = 0;

  constructor(summary?) {
    if (summary && typeof summary === 'object') {
      if (summary.hasOwnProperty('resume')) {
        this.totalCustomerSearch = summary.resume.totalCustomerSearch || 0;
        this.totalVessel = summary.resume.totalVessel || 0;
        this.totalAirPlane = summary.resume.totalAirPlane || 0;
        this.totalImps = summary.resume.totalImps || 0;
        this.totalPackages = summary.resume.totalPackages || 0;
      }
    }
  }

  getTotalCustomerSearch(): number {
    return this.totalCustomerSearch;
  }
  getTotalVessel(): number {
    return this.totalVessel;
  }
  getTotalAirPlane(): number {
    return this.totalAirPlane;
  }
  getTotalImps(): number {
    return this.totalImps;
  }
  getTotalPackages(): number {
    return this.totalPackages;
  }
}
