import { Component } from '@angular/core';
import { IGridColumn, IGridRow } from 'app/shared/components/cmx-custom-grid/interfaces';
import { PO_ITEMS_SLICK_COLUMNS } from './constants/slick-columns.constant';
import { PoStatusService } from '../../po-status.service';

@Component({
    selector: 'po-status-skus',
    templateUrl: './po-status-skus.component.html',
    styleUrls: ['./po-status-skus.component.scss'],
})
export class PoStatusSkusComponent {

    gridColumns: IGridColumn[] = PO_ITEMS_SLICK_COLUMNS;
    gridRows: IGridRow[];

    constructor(public poStatusService: PoStatusService) { }

}
