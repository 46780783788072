import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import { DropdownSettings } from './cmx-dropdown.interface';
import { ListItem, MyException } from './cmx-dropdown.model';
import { CmxDropdownSize } from './cmx-dropdown.types';
import { UtilsService } from '#services/_utils/utils.service';
import { CmxDropdownService } from './cmx-dropdown.service';
import { FormTabService } from '#services/_form-tab/form-tab.service';

const DROPDOWN_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CmxDropdownComponent),
    multi: true
};

@Component({
    selector: 'cmx-dropdown',
    templateUrl: './cmx-dropdown.component.html',
    styleUrls: ['./cmx-dropdown.component.scss', './cmx-dropdown.theme.scss'],
    providers: [DROPDOWN_CONTROL_VALUE_ACCESSOR],
})
export class CmxDropdownComponent implements OnInit, ControlValueAccessor {
    @Input() data: Array<ListItem>;
    @Input() settings: DropdownSettings;
    @Input() styles: any = {};
    @Input('size') sizeTheme: CmxDropdownSize = 'sm';
    @Input('theme') darkTheme: boolean = false;
    @Input() required: boolean = false;
    @Input() dataLoading: boolean = false;
    @Input() forceLoading: boolean = false;

    @Output() onSelect: EventEmitter<ListItem> = new EventEmitter<ListItem>();
    @Output() onDeSelect: EventEmitter<ListItem> = new EventEmitter<ListItem>();
    @Output() onSelectAll: EventEmitter<Array<ListItem>> = new EventEmitter<Array<ListItem>>();
    @Output() onDeSelectAll: EventEmitter<Array<ListItem>> = new EventEmitter<Array<ListItem>>();
    @Output() onScrollToEnd: EventEmitter<any> = new EventEmitter<any>();
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('dropdownComponent') _elDropdownComponent: ElementRef;
    @ViewChild('searchFilterInput') _elSearchFilterInput: ElementRef;
    @ViewChild('searchFilterContainer') _elSearchFilterContainer: ElementRef;
    @ViewChild('checkboxFilterContainer') _elCheckboxFilterContainer: ElementRef;

    singleSelectedItem: ListItem = new ListItem();
    selectedItems: Array<ListItem> = [];
    isActive: boolean = false;
    isSelectAll: boolean = false;
    filter: ListItem = new ListItem();
    uniqueId: string;
    ignoreCloseList: boolean = false;
    inverted: boolean = null;
    showSelectedItems: boolean = false;
    dropdownDistance;
    rightAlign: boolean = false;
    defaultSettings: DropdownSettings = {
        singleSelection: false,
        text: 'Selecione',
        badgeShowLabel: 'selecionados',
        selectAllText: 'Selecionar todos',
        unSelectAllText: 'Desmarcar todos',
        emptyDataMessage: 'Dados não encontrados',
        enableCheckAll: true,
        enableSearchFilter: true,
        maxHeight: 300,
        maxWidth: null,
        badgeShowLimit: 1,
        classes: '',
        disabled: false,
        icon: false,
        searchPlaceholderText: 'Buscar',
        preventEmpty: false,
        forceFullWidth: false,
        forceLoading: false,
        // dataLoading: false,
        prefix: '',
        suffix: '',
    };

    preSelectedItemIndex: number = 0;
    incrementItems: number = 50;
    maxWidth: any;

    @HostListener('click', ['$event']) clickInside(event) {
        if (!this.settings.disabled && this.isActive && event.target.className.indexOf('c-dropdown-btn') !== -1) {
            this.closeDropdown();
            return;
        }
        if (!this.settings.disabled && !this.isActive && event.target.className.indexOf('c-dropdown-btn') !== -1) {
            this.openDropdown();
            return;
        }
    }

    @HostListener('document:click', ['$event']) clickout(event) {
        if (this.isActive && !this._ref.nativeElement.contains(event.target)) {
            this.closeDropdown(this.ignoreCloseList);
        }
    }

    constructor(
        private _cmxDropdownService: CmxDropdownService,
        private _formTabService: FormTabService,
        private _ref: ElementRef,
        private _renderer2: Renderer2,
        public utilsService: UtilsService
    ) {
        this.uniqueId = `cmx-dropdown-container-${Date.now()}-${Math.floor(Math.random() * 9999999)}`;
    }

    get totalItemsSelecteds() {
        return `${this.selectedItems.length} ${this.settings.badgeShowLabel}`;
    }

    get itemsNameSelecteds() {
        return this.selectedItems && this.selectedItems?.map((s) => (s.itemName ? s.itemName : '–')).join(', ');
    }

    ngAfterViewInit() {
        this.dropdownDistance =
            $(window)?.width() - ($(`#${this.uniqueId}`)?.offset()?.left + $(`#${this.uniqueId}`)?.width());
    }

    ngOnDestroy() {
        this._cmxDropdownService.remove(this.uniqueId);
    }

    ngOnInit() {
        this.settings = Object.assign(this.settings || {}, this.defaultSettings);
        this._cmxDropdownService.add(this);
        this._formTabService.dropdownSubject.subscribe(({ field, action }) => {
            if (field.uniqueId === this.uniqueId) {
                const filteredData = !this.filter?.itemName ? this.data : this.data.filter((item) => {
                return this.utilsService.normalizeString(item?.itemName)?.toLowerCase()?.includes(this.utilsService.normalizeString(this.filter?.itemName)?.toLowerCase()) })
                if (action === 38 && this.preSelectedItemIndex > 0) {
                    this.preSelectedItemIndex--;
                }
                if (action === 40 && this.preSelectedItemIndex < filteredData?.length - 1) {
                    this.preSelectedItemIndex++;
                }
                if (action === 13) {
                    const item = filteredData[this.preSelectedItemIndex];
                    this.onItemClick(item);
                }
                if (action === 'close') {
                    this.closeDropdown();
                }
            }
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        const changesSettings = changes?.settings;

        if (changesSettings?.currentValue) {
            this.defaultSettings = {
                ...this.defaultSettings,
                ...changesSettings?.currentValue,
            };
        }
    }

    ngDoCheck() {
        if (this.selectedItems) {
            if (
                this.selectedItems.length == 0 ||
                this.data.length == 0 ||
                this.selectedItems.length < this.data.length
            ) {
                this.isSelectAll = false;
            }
        }
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    onChangeCallback: (_: any) => void = () => {};

    onTouchedCallback: (_: any) => void = () => {};

    onItemClick(item: ListItem) {
        if (!item) { return }
        if (this.settings?.disabled || item?.disabled) {
            if (item?.disabled && this.isSelected(item)) {
                this._verifyUnselectItem(item);
            }
            return;
        }
        if (this.settings?.singleSelection) {
            this.singleSelectedItem = item;
            this.closeDropdown();
        }
        if (!this.isSelected(item)) {
            if (this.settings?.limitSelection) {
                if (this.selectedItems?.length < this.settings?.limitSelection) {
                    this.addSelected(item);
                    this.onSelect.emit(item);
                    this.onChange.emit(item);
                }
            } else {
                this.addSelected(item);
                this.onSelect.emit(item);
                this.onChange.emit(item);
            }
        } else {
            this._verifyUnselectItem(item);
        }
        if (this.isSelectAll || this.data?.length > this.selectedItems?.length) {
            this.isSelectAll = false;
        }
        if (this.data.length == this.selectedItems?.length) {
            this.isSelectAll = true;
        }
        this._formTabService.lastSpecialInputField = {
            type: 'dropdown', open: false, uniqueId: this.uniqueId,
            field: $(this._elDropdownComponent?.nativeElement)?.parent()?.attr('formcontrolname'),
        }
    }

    writeValue(value: any) {
        if (value !== undefined && value !== null) {
            if (this.settings.singleSelection) {
                try {
                    if (value.length > 1) {
                        this.selectedItems = [value[0]];
                        if (Array.isArray(value)) {
                            throw new MyException(404, {
                                msg: 'Single Selection Mode, Selected Items cannot have more than one item.',
                            });
                        }
                    } else {
                        this.selectedItems = value;
                    }
                } catch (e) {
                    console.error(e.body.msg);
                }
            } else {
                if (this.settings.limitSelection) {
                    this.selectedItems = value.splice(0, this.settings.limitSelection);
                } else {
                    this.selectedItems = value;
                }
                if (this.selectedItems.length === this.data.length && this.data.length > 0) {
                    this.isSelectAll = true;
                }
            }
        } else {
            this.selectedItems = [];
        }
    }

    verifySelectedItem(id): boolean {
        return this.selectedItems?.findIndex((item) =>
            this.utilsService.normalizeString((item.id)?.toString())?.toLowerCase()?.replace(/ /g, '') === this.utilsService.normalizeString((id)?.toString())?.toLowerCase()?.replace(/ /g, '')
        ) === -1 ? false : true;
    }

    trackByFn(index: number, item: any) {
        return index;
    }

    isSelected(clickedItem: ListItem) {
        let found = false;
        this.selectedItems &&
            this.selectedItems?.forEach((item) => {
                if (clickedItem?.id === item?.id) {
                    found = true;
                }
            });
        return found;
    }

    addSelected(item: ListItem) {
        if (this.settings.singleSelection) {
            this.selectedItems = [];
            this.selectedItems.push(item);
        } else this.selectedItems.push(item);
        this.onChangeCallback(this.selectedItems);
    }

    removeSelected(clickedItem: ListItem) {
        this.selectedItems &&
            this.selectedItems.forEach((item) => {
                if (clickedItem.id === item.id) {
                    this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
                }
            });
        this.onChangeCallback(this.selectedItems);
    }

    toggleDropdown(evt: any) {
        if (this.settings.disabled) {
            return false;
        }
        evt.preventDefault();
    }

    openDropdown() {
        this.showSelectedItems = !this.settings.singleSelection && this.selectedItems.length > 0 ? true : false;
        this._cmxDropdownService.open(this.uniqueId);
        this._calcShowDropdown(this._elDropdownComponent.nativeElement);
        this.preSelectedItemIndex = 0;
        this._formTabService.lastSpecialInputField = {
            type: 'dropdown', open: true, uniqueId: this.uniqueId,
            field: $(this._elDropdownComponent?.nativeElement)?.parent()?.attr('formcontrolname')
        }
        this._setConfigFilterSearch();
    }

    closeDropdown(ignoreClose: boolean = false) {
        this.filter = new ListItem();
        this._cmxDropdownService.close(this.uniqueId, ignoreClose);
        this.inverted = false;
        this.ignoreCloseList = false;
        this.incrementItems = 50;
        this.onClose.emit({
            selected: this.selectedItems,
            all: this.data?.length === this.selectedItems?.length,
        });
    }

    toggleSelectAll() {
        this.selectedItems = [];
        if (!this.isSelectAll) {
            this.selectedItems = this.data.slice();
            this.isSelectAll = true;
            this.showSelectedItems = true;
            this.onSelectAll.emit(this.selectedItems);
        } else {
            this.isSelectAll = false;
            this.showSelectedItems = false;
            this.onDeSelectAll.emit(this.selectedItems);
        }
        this.onChangeCallback(this.selectedItems);
        this.onChange.emit(this.selectedItems);
    }

    listScroll(event) {
        if (event.target.offsetHeight + event.target.scrollTop + 15 >= event.target.scrollHeight) {
            this.incrementItems += 50;
        }

        if (event.srcElement.scrollHeight - event.srcElement.scrollTop === event.srcElement.clientHeight) {
            this.onScrollToEnd.emit({ currentLength: this.data.length });
        }
    }

    searchKeyUp(event) {
        if (event.keyCode === 13 || event.keyCode === 38 || event.keyCode === 40) { event.preventDefault() }
        this.onSearch.emit({ value: event.srcElement.value });
        if (event.keyCode !== 38 && event.keyCode !== 40) {
            this.preSelectedItemIndex = 0;
        }
    }

    private _verifyUnselectItem(item) {
        if (!(this.settings.preventEmpty && this.selectedItems.length < 2)) {
            if (
                this.selectedItems[this.selectedItems.length - 1].itemName === item.itemName &&
                this.selectedItems.length > 1
            ) {
                this.ignoreCloseList = true;
            }
            this.removeSelected(item);
        }
        this.onDeSelect.emit(item);
        this.onChange.emit(item);
    }

    private _calcElements(values: number[]): number {
        return values.reduce(
            (previousValue: number, currentValue: number) => Math.round(previousValue) + Math.round(currentValue),
            0
        );
    }

    private _calcShowDropdown(_element) {
        setTimeout(() => {
            const _elListDropdownList = _element.children[1];
            const _elListDropdownItems =
                _element.children[1].children[0].children[_element.children[1].children[0].children.length - 1];

            const calcFilters = [];

            if (this.settings.enableSearchFilter && this.data?.length > 6) {
                calcFilters.push(this._elSearchFilterContainer?.nativeElement?.getBoundingClientRect()?.height);
            }

            if (this.settings.enableCheckAll && !this.settings.singleSelection) {
                calcFilters.push(this._elCheckboxFilterContainer?.nativeElement?.getBoundingClientRect()?.height);
            }

            const getTotalPage: number = document.body.scrollHeight - window.scrollY;
            const getHeightToShowList =
                getTotalPage - (_element?.getBoundingClientRect()?.y - _element?.getBoundingClientRect()?.height);
            const heightListItems = getHeightToShowList - this._calcElements(calcFilters);

            this.inverted = false;
            this.maxWidth = typeof this.settings.maxWidth  !== 'string' ? (this.settings.maxWidth +'px') : this.settings.maxWidth;
            this._calcWidthList(_element);

            let maxHeight = this._calcElements(calcFilters) > 50 ? 140 : 80;
            if (
                (getHeightToShowList >= maxHeight && getHeightToShowList <= 173) ||
                (getHeightToShowList >= maxHeight &&
                    getHeightToShowList < _elListDropdownList.getBoundingClientRect().height)
            ) {
                this._renderer2.setStyle(_elListDropdownItems, 'height', heightListItems + 'px');
            } else if (getHeightToShowList < maxHeight) {
                const getWindowHeight =
                    _element.getBoundingClientRect().y - _element.getBoundingClientRect().height - 190;
                this.inverted = true;
                this._renderer2.setStyle(
                    _elListDropdownItems,
                    'max-height',
                    this.settings.maxHeight ? this.settings.maxHeight : getWindowHeight + 'px'
                );
                this._renderer2.removeStyle(_elListDropdownItems, 'height');
            } else {
                this._renderer2.removeStyle(_elListDropdownItems, 'height');
            }

            // Verifica se o dropdown está muito próximo da borda direita da janela
            const listWidth = $(`#${this.uniqueId} .list-area`).width();
            const dropdownWidth = $(`#${this.uniqueId}`).width();
            this.rightAlign = listWidth >= dropdownWidth + this.dropdownDistance ? true : false;

            this._renderer2.setStyle(_elListDropdownList, 'opacity', 1);
        });
    }

    private _calcWidthList(_element) {
        if (this.settings.maxWidth) {
            this._renderer2.setStyle(_element.children[1], 'max-width', this.settings.maxWidth + 'px');
            this._renderer2.setStyle(
                _element.children[1].children[0].children[_element.children[1].children[0].children.length - 1],
                'max-width',
                this.settings.maxWidth + 'px'
            );
        }
    }

    private _setConfigFilterSearch() {
        if (this.settings.enableSearchFilter) {
            setTimeout(() => {
                this._elSearchFilterInput.nativeElement.value = '';
                this._elSearchFilterInput.nativeElement.focus();
            }, 0);
        }
    }
}
