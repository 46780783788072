import { Pipe, PipeTransform } from '@angular/core';
import { ListItem } from './cmx-dropdown.model';
import { UtilsService } from '#services/_utils/utils.service';

@Pipe({
    name: 'listFilter',
    pure: false
})
export class ListFilterPipe implements PipeTransform {
    constructor(private utilsService: UtilsService) {}

    transform(items: ListItem[], filter: ListItem): ListItem[] {
        if (!items || !filter) {
            return items;
        }
        return items.filter((item: ListItem) => this.applyFilter(item, filter));
    }

    applyFilter(item: ListItem, filter: ListItem): boolean {
        const filterText = this.utilsService.normalizeString(filter.itemName || '');
        const itemText = this.utilsService.normalizeString(item.itemName || '');
        return itemText.indexOf(filterText) !== -1;
    }
}
