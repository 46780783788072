import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'valueOrDash',
})
export class ValueOrDashPipe implements PipeTransform {
    transform(input: string): string {
        return input || '\u2014';
    }
}
