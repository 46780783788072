import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'cmx-form-message',
    templateUrl: './cmx-form-message.component.html',
    styleUrls: ['./cmx-form-message.component.scss'],
})
export class FormMessageComponent {

    @Input() message: string = '';
    @Input() type: string;

    @Input() size?: string = 'md';
    @Input() closeable?: boolean = true;
    @Input() visivle?: boolean;
    @Input() height?: string = '100%';

    @Output('onClose') onClose = new EventEmitter();

    container = {
        height: "100%",
        display: "flex",
    }

    constructor(){}

    ngOnInit(){
        this._setSize(this.size);
        this._setHeight(this.height);
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    closeMessage() {
        this.onClose.emit(true);
    }

    private _setHeight(value: string): void {
        this.container["height"] = value || "100%";
    }

    private _setSize(value:string): void {

        const size = {
          "sm": "200px",
          "md": "350px",
          "lg": "500px"
        }

        this.container["width"] = size[value] || value || size['md'];
      }

}
