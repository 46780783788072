<div *ngIf="asyncPermissions == false" class="fullLoader admModalLoader">
    <loader></loader>
</div>

<i (click)="close()" id="close" class="la la-close"></i>

<div class="admin-account-border">
    <div class="line-space">
        <cmx-listing
            [list]="permissions"
            (onSelect)="selectPermission($event)"
            height="calc(100vh - 160px)"
            searchPlaceholder="Buscar Permissão"
            [hasAddButton]="true"
            (onNewItem)="prepareNewPermission()"
        ></cmx-listing>
    </div>
</div>
<div class="admItem">
    <a *ngIf="newPermission == false" (click)="admService.openDeleteModal(modal)" class="deleteBtn"><i class="la la-trash"></i></a>
    <span class="admTitle">{{selectedPermission.name}}</span>
    <hr>
    <div class="admForm">
        <label>Nome</label>
        <input *ngIf="newPermission" class="admInput" type="text" [(ngModel)]="selectedPermission.name">
        <input *ngIf="newPermission == false" disabled class="admInput" type="text" [(ngModel)]="selectedPermission.name">
        <label>Path</label>
        <input class="admInput" type="text" [(ngModel)]="selectedPermission.path" (ngModelChange)="refreshState()">
        <div class="radioContainer">
            <div class="admRadio">
                <label>API</label>
                <input class="admRadioItem" value="api" name="type" type="radio" [(ngModel)]="selectedPermission.type" (ngModelChange)="refreshState()">
            </div>
            <div class="admRadio">
                <label>Route</label>
                <input class="admRadioItem" value="route" name="type" type="radio" [(ngModel)]="selectedPermission.type" (ngModelChange)="refreshState()">
            </div>
        </div>
        <p class="error 404 hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>Essa permissão não existe!</p>
        <p class="error 409 hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>Já existe uma permissão com esse nome!</p>
        <p class="error 422 hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>Já existe um path com esse nome!</p>
        <p class="error name hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>A permissão precisa de um Nome.</p>
        <p class="error path hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>Nesse caso, é necessário adicionar um Path.</p>
        <p class="success created hidden"><i (click)='admService.hideSuccess(modal)' class="la la-close"></i>Permissão criada!</p>
        <p class="success edited hidden"><i (click)='admService.hideSuccess(modal)' class="la la-close"></i>Permissão alterada!</p>
        <p class="success deleted hidden"><i (click)='admService.hideSuccess(modal)' class="la la-close"></i>Permissão deletada!</p>
    </div>
    <button *ngIf="newPermission" (click)="addPermission()" class="add">Adicionar</button>
    <button *ngIf="newPermission == false && hasAnyChange == false" class="edit disabled">Salvar</button>
    <button *ngIf="newPermission == false && hasAnyChange" (click)="savePermission()" class="edit">Salvar</button>
</div>
<div class="deleteModal hidden">
    <p>Deseja deletar a permissão {{selectedPermission.name}} ?</p>
    <div class="deleteButtons">
        <button (click)="confirmDeletePermission()" class="delete">Deletar</button>
        <button (click)="admService.closeDeleteModal(modal)" class="cancel">Cancelar</button>
    </div>
</div>

