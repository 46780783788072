import { _applyPosFormatters } from "./pos-formatters/pos-formatters";
import { textFormatter } from "./text.formatter";

export function exchangeApprovalFormatter(row, cell, value, columnDef, dataContext) {
    if (!Array.isArray(value)) {
        return textFormatter(row, cell, value, columnDef, dataContext);
    }
    let icons = '';

    value.forEach((approval) => {
        if (approval.approvalCommand === 'APROVAR' || approval.approvalCommand === 'LIBERAR') {
            icons += `<span class="material-icons approved" title="${approval.approvalUsername || ''}">done</span>`;
        } else {
            icons += `<span class="material-icons waiting" title="${approval.approvalUsername || ''}">panorama_fish_eye</span>`;
        }
    });
    const containerApprovalFormatter = columnDef.formatterData.exchangeApprovalContainer ? columnDef.formatterData.exchangeApprovalContainer : '';
    const hasAnimation = columnDef.formatterData.animation ? columnDef.formatterData.animation : '';
    const contentValue = `<div class="exchange-approval-container ${containerApprovalFormatter} ${hasAnimation}">${icons}</div>`;
    return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
}

