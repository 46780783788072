import { TemplateRef } from "@angular/core";

export enum TemplateLoginEnum{
    LOGIN,
    TWO_FACTOR,
    CREATE_TWO_FACTOR,
    RECOVERY_PASSWORD
}

export interface TemplateModel {
    type: TemplateLoginEnum,
    value: TemplateRef<any>;
}