import { UserCacheService } from '#services/_user/app-user-cache.service';
import { Component, ViewEncapsulation } from '@angular/core';
import { DocumentsGedCRUDService } from './documents-ged.service';
import { NotificationService } from '#services/_notification/notification.service';

@Component({
    selector: 'documents-ged-crud',
    templateUrl: './documents-ged.component.html',
    styleUrls: ['./documents-ged.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DocumentsGedCRUDComponent {
    async: boolean = false;

    clientSearch = '';
    clientList = [];
    selectedClient;

    allowedTypes = [];
    notAllowedTypes = [];

    constructor(
        private _documentsGedCRUDService: DocumentsGedCRUDService,
        private _userCacheService: UserCacheService,
        private _notificationService: NotificationService,
    ) {
        this.clientList = this._userCacheService.getClientGroups();
        this.selectClient(this.clientList[0])
    }

    selectClient(client) {
        this.async = true;
        this.selectedClient = client;
        this._documentsGedCRUDService.getDocumentsConfiguration(client.code).subscribe(
            (configuration) => {
                this.allowedTypes = configuration?.allowedTypes || [];
                this.notAllowedTypes = configuration?.notAllowedTypes || [];
                this.async = false;
            },
            (error) => {
                this.async = false;
                this._notificationService.openNotification('error', error.error.message);
            }
        );
    }

    saveConfiguration() {
        this.async = true;
        this._documentsGedCRUDService
            .saveDocumentsConfiguration(this.selectedClient.code, {
                allowedTypes: this.allowedTypes,
                notAllowedTypes: this.notAllowedTypes,
            })
            .subscribe(
                (response) => {
                    this.async = false;
                    this._notificationService.openNotification('success', `Grupo de cliente: ${this.selectedClient.name} atualizado com sucesso`, '');
                },
                (error) => {
                    this.async = false;
                    this._notificationService.openNotification('error', error.error.message);
                }
            );
    }

    changeConfiguration(event) {
        this.allowedTypes = event.rightData.map(item => item.name);
        this.notAllowedTypes = event.leftData.map(item => item.name);
    }

    trackByFn(index: number, item: any) {
        return index;
    }
}
