import { _applyPosFormatters } from "./pos-formatters/pos-formatters";

export function buttonFormatter(row, cell, _value, columnDef, dataContext) {
    const iconType = {
        material: `<span class="material-icons">${columnDef?.formatterData?.icon}</span>`,
        fontawesome: `<i class="fa ${columnDef?.formatterData?.icon}"></i>`,
        default: '',
    };
    const buttonSize = {
        sm: 'button-sm',
        md: 'button-md',
        lg: 'button-lg',
    };
    const buttonColor = {
        success: 'color-success',
        info: 'color-info',
        warning: 'color-warning',
        danger: 'color-danger',
        default: 'color-default',
    };
    const setButtonColor =
        buttonColor[columnDef?.formatterData?.color ? columnDef?.formatterData?.color : 'default'];
    const setButtonSize = buttonSize[columnDef?.formatterData?.size ? columnDef?.formatterData?.size : 'md'];
    const setIcon = iconType[columnDef?.formatterData?.iconType]
        ? iconType[columnDef?.formatterData?.iconType]
        : iconType['default'];
    const buttonType = {
        rounded: `button-slickgrid button-rounded ${setButtonSize} ${setButtonColor}`,
        round: `button-slickgrid button-round ${setButtonSize} ${setButtonColor}`,
        square: `button-slickgrid button-square ${setButtonSize} ${setButtonColor}`,
    };
    const setButtonType = buttonType[columnDef?.formatterData?.buttonType]
        ? buttonType[columnDef?.formatterData?.buttonType]
        : buttonType['square'];
    const contentValue = `<div style="display:flex; align-items: center; justify-content: center; width: ${
        columnDef?.formatterData?.width ? columnDef?.formatterData?.width : '100%'
    }">
        <button type="button" class="${setButtonType}">${setIcon} ${
        columnDef?.formatterData?.label ? columnDef?.formatterData?.label : ''
    }</button>
        </div>`;
    return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
}