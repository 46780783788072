<div *ngIf="asyncCustomization" class="fullLoader">
    <span class="exporting">Salvando Preferências</span>
    <loader></loader>
</div>

<cmx-custom-dialog 
    id="followup-unified-customization-modal" 
    [title]="'Follow UP - Customização'" 
    content="full"
    heightModal="100%"
    overlay="true"
    animation="bottom-top"
    customColorBorderTop="#495057"
    (beforeOpen)="onBeforeDialogOpen()">
    <ng-container cmx-custom-dialog-body>      
        <div style="display: flex; height: 100%">
            <div style="width: 200px; margin-right: 20px;">
                <cmx-save-config
                    [saveList]="reportPreferencesService.saveList"
                    [selectedSave]="reportPreferencesService.selectedSave"
                    [startAsNew]="true"
                    [iconsBaseHeight]="'calc(100vh - 380px)'"
                    [savesBaseHeight]="'calc(100vh - 310px)'"
                    [forceClear]="forceClear"
                    (onChange)="onSaveChanges($event)"
                    (onNew)="onNewSave($event)"
                    (onDelete)="onDeleteSave($event)"
                    (onSelect)="onSelectSave($event)"
                ></cmx-save-config>
            </div>
    
            <div style="width: calc(100% - 200px);
            border-left: 1px solid grey;
            padding-left: 20px;">
                <cmx-drag-and-drop-table
                    [showMode]="true"
                    [tableType]="'grouped'"
                    [title]="'Follow UP'"
                    [leftTitle]="'Visíveis'"
                    [leftIcon]="'check_circle_outline'"
                    [leftData]="reportPreferencesService.leftData"
                    [defaultLeftData]="reportPreferencesService.defaultLeftData"
                    [leftColor]="'#314968'"
                    [rightTitle]="'Ocultos'"
                    [rightIcon]="'block'"
                    [rightData]="reportPreferencesService.rightData"
                    [rightColor]="'#ad0000'"
                    [search]="'colunas'"
                    [cleanSearch]="true"
                    [enableChangeColumnGroup]="true"
                    [enableGroupRename]="true"
                    [enableGroupReorder]="true"
                    [enableResetNames]="false"
                    [enableRearrangeGroups]="true"
                    [enableResetAll]="true"
                    [enableColumnCreate]="true"
                    [enableRightDrag]="false"
                    [leftContainerHeightBase]="'calc(100vh - 320px)'"
                    [rightContainerHeightBase]="'calc(100vh - 320px)'"
                    (onFinishButton)="saveCustomChanges($event)">
                </cmx-drag-and-drop-table>
            </div>
        </div>

    </ng-container>
</cmx-custom-dialog>