<div class="automotiveDailyContainer">
    <div *ngIf="asyncDaily"><loader></loader></div>
    <div id='searchLine' class="titleLine search-container invisible">
        <span style="display: flex; align-items: center;"><i class="material-icons">swap_vert</i>
            <span style="margin-left: 5px;">Movimentação Diária</span></span>
        <input name="date" id="date" type="text" class="form-control m-input date-impStatusTable"/>
    </div>
    <div id="dailyGrid" class="dailyGrid"></div>
    <span *ngIf="noData" class="noData">Nenhum resultado encontrado para essa data.</span>
    <div class="dailyDetailModal hidden">
        <i (click)="closeDetailModal()" class="close la la-close"></i>
        <automotive-detail-daily></automotive-detail-daily>
    </div>
</div>
