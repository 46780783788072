import { environment } from '#environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class InternationalFreightService {
  private msgErrorDefault = 'Não foi possível carregar os dados';
  constructor(private http: HttpClient) {}

  getOrigin(): Observable<any> {
    return this.http.get(`${environment.endpoints.quotationService}/international-transport/origin`).pipe(catchError((err) => {
      return throwError({
        status: err.status,
        message: 'Erro ao carregar as origens',
      });
    }));
  }

  getDestination(): Observable<any> {
    return this.http.get(`${environment.endpoints.quotationService}/international-transport/destination`).pipe(catchError((err) => {
      return throwError({
        status: err.status,
        message: 'Erro ao carregar os destinos',
      });
    }));
  }

  findByFilter(origins, destinations, cargoType): Observable<any> {
    
    const filter = `origins=${origins.join(',')}&destinations=${destinations.join(',')}&cargoType=${cargoType}`;
    
    return this.http.get(`${environment.endpoints.quotationService}/international-transport/find-by-filter?${filter}`)
      .pipe(catchError((err) => {console.log("Err: " + JSON.stringify(err));
      
        return throwError({
          status: err.status,
          message: err.error.message,
        });
      }));
  }

  uploadFreightValues(file) {

    const headers = new HttpHeaders();

    headers.set('Content-Type', 'multipart/form-data');

    return this.http.post(`${environment.endpoints.quotationService}/international-transport/upload-international-freight-values`, 
      file, { headers, responseType: 'text' });
  }
}
