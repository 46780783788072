import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IDocumentEmissionFilter } from './interfaces/document-emission-filter.interface';

export class DocumentsEmissionFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialDocumentEmissionFilter: IDocumentEmissionFilter = <IDocumentEmissionFilter>{};
    private _documentEmissionFilter: IDocumentEmissionFilter = <IDocumentEmissionFilter>{
        sortField: null,
        clientGroups: [],
        subsidiaryNames: [],
        documentStatus: []
    };

    private filterAll: boolean;
    private filterField: string;
    private filterValue: string;
    private page: number;
    private size: number;
    private sort: string;

    constructor(filter?: DocumentsEmissionFilter) {
        this._documentEmissionFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'documentEmissionFilter', this._documentEmissionFilter, filter);
        this._initialDocumentEmissionFilter = _.cloneDeep(this._documentEmissionFilter);
        this.filterAll = filter ? filter.filterAll: true;
        this.filterField = filter ? filter.filterField : '';
        this.filterValue = filter ? filter.filterValue : '';
        this.sort = filter ? filter.sort : 'asc';
        this.page = filter ? filter.page : 0;
        this.size = filter ? filter.size : 50;
    }

    public setFilterAll(filterAll): void {
        this.filterAll = filterAll;
    }
    public setSortField(sortField): void {
        this._documentEmissionFilter.sortField = sortField;
    }
    public setSort(sort): void {
        this.sort = sort;
    }
    public setFilterField(filterField): void {
        this.filterField = filterField;
    }
    public setFilterValue(filterValue): void {
        this.filterValue = filterValue;
    }
    public setPage(page): void {
        this.page = page;
    }
    public setSize(size): void {
        this.size = size;
    }
    public setSubsidiaryNames(subsidiaryNames): void {
        this._documentEmissionFilter.subsidiaryNames = subsidiaryNames;
    }
    public setClientGroups(clientGroups): void {
        this._documentEmissionFilter.clientGroups = clientGroups;
    }
    public setDocumentStatus(documentStatus): void {
        this._documentEmissionFilter.documentStatus = documentStatus;
    }

    public getFilterAll(): boolean {
        return this.filterAll;
    }
    public getSortField(): string {
        return this._documentEmissionFilter.sortField;
    }
    public getSort(): string {
        return this.sort;
    }
    public getFilterField() {
        return this.filterField;
    }
    public getFilterValue() {
        return this.filterValue;
    }
    public getPage(): number {
        return this.page;
    }
    public getSize(): number {
        return this.size;
    }
    public getSubsidiaryNames(): any[] {
        return this._documentEmissionFilter.subsidiaryNames;
    }
    public getClientGroups(): string[] {
        return this._documentEmissionFilter.clientGroups;
    }
    public getDocumentStatus() {
        return this._documentEmissionFilter.documentStatus;
    }
    public getRequest() {
        this._initialDocumentEmissionFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialDocumentEmissionFilter, this._documentEmissionFilter, 'documentEmissionFilter', this._userCacheService.getUserPreferences().id);
        let params = new HttpParams();
        let headers = new HttpHeaders();

        headers = headers.set('X-Requested-Client-Groups', this.getClientGroups() === [] ? '' : this.getClientGroups().map((c) => c['id']).join(','));

        if(this.getFilterAll()) {
            params = params.append('subsidiaryNames', this.getSubsidiaryNames().map((c) => c.itemName).join(','));
            params = params.append('documentStatus', this.getDocumentStatus().map((c) => c.id).join(','));

            if (this.getSortField() && this.getSort()) {
                params = params.append('sort', `${this.getSortField()},${this.getSort()}`);
            }
        }

        params = params.append('licensingBody', 'ANVISA');
        params = params.append('page', `${this.getPage()}`);
        params = params.append('size', `${this.getSize()}`);

        if (this.getFilterValue()) {
            params = params.append(`${this.getFilterField()}`, `${this.getFilterValue()}`);
        }

        return { params, headers };
    }
}
