import { Hierarchy } from '#services/_results/results.service';

export const hierarchyOptions = [
    // { name: 'DRE Gerencial Antigo', value: Hierarchy.income_statement_mgmt_legacy },
    { name: 'DRE Gerencial', value: Hierarchy.income_statement_mgmt, beta: true },
    { name: 'DRE Contábil', value: Hierarchy.income_statement },
    { name: 'Balancete', value: Hierarchy.balance_sheet },
];

export function translateHierarchy(value: string) {
    switch (value) {
        case 'balance-sheet': return 'Balancete';
        case 'income-statement': return 'DRE Contábil';
        // case 'income-statement-mgmt-legacy': return 'DRE Gerencial Antigo';
        case 'income-statement-mgmt': return 'DRE Gerencial';
    }
}
