import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';

import { LayoutModule } from '../../../layouts/layout.module';

@NgModule({
    imports: [
        CommonModule,
        LayoutModule,
        SharedModule
    ], exports: [], declarations: [],
    providers: [],
})
export class NewFeaturesModule {

}
