import { AfterViewInit, Component, Input, OnDestroy, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';
import { StatusService } from '#services/_status/status.service';
import { UtilsService } from '#services/_utils/utils.service';
import { UserService } from 'app/auth/_services';
import { DatamartFiltersService } from 'app/shared/components/cmx-datamart-preferences/services/cmx-datamart-preferences-filters';
import * as _ from 'lodash';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { FollowUpUnifiedDatamartService } from './services/followup-unified-datamart.service';
import { ReportPreferencesService } from '#services/_report-preferences/report-preferences.service';
import { DatamartService } from '#services/_datamart/datamart.service';
interface ILinkOption { id, name, enabled }


@Component({
    selector: 'followup-unified-datamart-modal',
    templateUrl: './followup-unified-datamart-modal.component.html',
    styleUrls: ['followup-unified-datamart-modal.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    providers: [DatamartFiltersService]
})
export class FollowUpUnifiedDatamartModalComponent implements AfterViewInit {

    @Input() hideEmail: boolean = false;
    @Input() hideGuide: boolean = false;
    @Input() modalTitle: string = 'Data Mart - Follow UP';
    @Input() linkOptions: ILinkOption[] = [];

    @Output() onLinkOptionsChange = new EventEmitter();

    array = Array;
    url = window.location.href.replace('//', '.').split('.')[1];
    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);

    async: boolean = false;
    linkEnabled: boolean = false;
    emailEnabled: boolean = false;
    showUpdateLinkModal: boolean = false;
    linkUrlBase: string = this._defineURLBase();
    linkUrl: string = '';
    options: { modelList: DropdownOption[] } = { modelList: [] };
    datamartFilters: any = new Object();
    asyncDatamart: boolean = true;
    subscription;
    currentUser = this._userService.getCurrentUser();
    currentUser$;
    selectedTypeReport = 'imp';
    selectedReport;
    useServerlessDatamart: boolean = false;

    datamartName: string = this._getDatamartName();
    serviceId: string;
    generatorId: string = this._getGeneratorId();
    oldGeneratorId: string = '';
    filters = this._determineFilters();

    allTypes = [
        { name: 'IMP', icon: 'directions_boat', type: 'material', permission: 'DASHBOARD_COMPONENT_IMP_STATUS', checked: true },
        { name: 'SKU', icon: 'comexport-package', type: 'awesome', permission: 'DASHBOARD_COMPONENT_SKU_STATUS', checked: false },
    ];

    filterState = this.filterStateService.getFupFollowUpFilter();

    constructor(
        public filterStateService: FilterStateService,
        private _datamartService: FollowUpUnifiedDatamartService,
        private _featureFlagService: FeatureFlagService,
        private _utilsService: UtilsService,
        private _userService: UserService,
        private _statusService: StatusService,
        private _datamartFiltersService: DatamartFiltersService,
        private _reportPreferencesService: ReportPreferencesService,
        private _cmxDropdownService: CmxDropdownService,
        private _oldDatamartService: DatamartService,
    ) {
        this.currentUser$ = this._userService.currentUser$;
    }

    ngAfterViewInit() {
        this.getDatamartFeatureFlag().then(() => {
            this.serviceId = this._getServiceId();
            this.getReportList();
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    onChangeSwitch(event) {
        if (event.switchName === 'Gerar Link') {
            this.linkEnabled = event.switchValue;
        } else {
            this.emailEnabled = event.switchValue;
        }
        this.saveDatamartConfig();
    }

    onSelectLinkOption(option) {
        this.linkOptions.map((option) => option.enabled = false);
        option.enabled = true;
        this.onLinkOptionsChange.emit(option);
        setTimeout(() => { this.saveDatamartConfig() }, 500);
    }

    async getDatamartFeatureFlag(): Promise<void> {
        this.useServerlessDatamart = await this._featureFlagService.isFeatureFlagEnabledV2('followup_serverless_datamart');
    }

    confirmUpdateLink() {
        this.showUpdateLinkModal = true;
    }

    updateLink() {
        this.showUpdateLinkModal = false;
        this.saveDatamartConfig();
    }

    applyType(type: string) {
        this.selectedReport = [];
        this.asyncDatamart = true;
        for (let status of this.allTypes) {
            if (status.name == type) {
                this.selectedTypeReport = status.name.toLowerCase();
                this.serviceId = this._getServiceId();
                status.checked = true;
                if (type == 'IMP') { this._getImpStatus(); }
                if (type == 'SKU') { this._getSkuStatus(); }
            } else {
                status.checked = false;
            }
        }
    }

    private _getImpStatus() {
        this.generatorId = 'fup-unified-imp';
        this.oldGeneratorId = '';
        this.getReportList();
    }

    private _getSkuStatus() {
        this.generatorId = 'fup-unified-sku';
        this.oldGeneratorId = '';
        this.getReportList();
    }

    async getDatamartConfig() {
        const subItem = this.selectedReport || [];
        this.asyncDatamart = true;
        this.oldGeneratorId = '';
        (await this._datamartService
            .getDatamartConfig(
                this.datamartName,
                this.serviceId,
                this.generatorId,
                subItem[0].id,
                this.currentUser.has('FOLLOWUP_COMPLETE') ? false : true
            ))
            .subscribe((datamartConfig: any) => {
                this.linkUrl = datamartConfig.uri || '';
                this._setDeliveryOptions(datamartConfig.deliveryOptions);
                this.datamartFilters = this._datamartFiltersService.defineVisualFilters(datamartConfig.parameters, this.generatorId, this.currentUser);
                this.asyncDatamart = false;
            }, () => {
                this.getOldDatamartConfig();
            });
    }

    async saveDatamartConfig() {
        const subItem = this.selectedReport || [];
        this.asyncDatamart = true;
        if (subItem.length || !this.options.modelList) {
            if (this.subscription) this.subscription.unsubscribe();

            let datamartServiceMethod = this._datamartService.setDatamartConfiguration(
                this.datamartName,
                this.serviceId,
                this.generatorId,
                this._getDeliveryOptions(),
                subItem[0].id,
                this._datamartFiltersService.prepareFiltersToService(this.generatorId, this.filterState, this.currentUser, subItem || []),
                subItem[0].id,
                this.currentUser.has('FOLLOWUP_COMPLETE') ? false : true
            );

            if(this.oldGeneratorId === 'fup' || this.oldGeneratorId === 'fup-sku') {
                datamartServiceMethod = this._oldDatamartService.migrateDatamartConfiguration(
                    this.serviceId,
                    this.oldGeneratorId,
                    subItem[0].itemName,
                    this.datamartName,
                    this.serviceId,
                    this.generatorId,
                    this._getDeliveryOptions(),
                    subItem[0].id,
                    this._datamartFiltersService.prepareFiltersToService(this.generatorId, this.filterState, this.currentUser, subItem || []),
                    subItem[0].id,
                    this.currentUser.has('FOLLOWUP_COMPLETE') ? false : true
                );
            }

            this.subscription = (await datamartServiceMethod).subscribe(
                (datamartConfig: any) => {
                    this.linkUrl = datamartConfig.uri || '';
                    this.datamartFilters = this._datamartFiltersService.defineVisualFilters(datamartConfig.parameters, this.generatorId, this.currentUser);
                    this.asyncDatamart = false;
                },
                (error) => {
                    this.asyncDatamart = false;
                    this._utilsService.errorHandler(error, 'Data Mart');
                }
            );
        } else {
            this.asyncDatamart = false;
            this._utilsService.errorHandler('Por favor, selecione um relatório', 'Data Mart');
        }
    }

    getOldDatamartConfig() {
        const subItem = this.selectedReport || [];
        this.asyncDatamart = true;
        const generatorId = this._getOldGeneratorId();
        this._oldDatamartService
            .getDatamartConfig(this.datamartName, this.serviceId, generatorId, subItem.length ? subItem[0].itemName : 'default')
            .subscribe((datamartConfig: any) => {
                this.oldGeneratorId = generatorId;
                this.linkUrl = datamartConfig.uri || '';
                this._setDeliveryOptions(datamartConfig.deliveryOptions);
                this.datamartFilters = this._datamartFiltersService.defineVisualFilters(datamartConfig.parameters, this.generatorId, this.currentUser);
                this.asyncDatamart = false;
            }, () => {
                this.linkUrl = '';
                this.linkEnabled = false;
                this.emailEnabled = false;
                this.asyncDatamart = false;
            }
        );
    }

    downloadDatamartGuide() {
        window.open('/assets/app/pdf/Manual_Data_Mart.pdf', '_blank');
    }

    getCurrentFilter(filter, status?) {
        const statusLength = this._statusService.impStatuslLength;
        if (!filter) { return 'Todos' }
        if (filter.length === 1) {
            return filter[0] ? filter[0] : 'Todos';
        } else if (filter.length > 1) {
            return status && statusLength === filter.length ? 'Todos' : `${filter.length} selecionados`;
        } else {
            return 'Todos';
        }
    }

    private _getDeliveryOptions() {
        const options = [];
        if (this.linkEnabled) options.push('LINK');
        if (this.emailEnabled) options.push('EMAIL');
        return options;
    }

    private _setDeliveryOptions(options) {
        this.linkEnabled = options.includes('LINK') ? true : false;
        this.emailEnabled = options.includes('EMAIL') ? true : false;
    }

    private _defineURLBase() {
        return `https://${this.url.includes('bi-stage') || this.url.includes('localhost') ?
            'bi-stage' : 'bi'}.comexport.com.br/datamart`;
    }

    private async getReportList(): Promise<any> {
        this._reportPreferencesService.getCustomReport(this._getReportId(), undefined, this.currentUser.has('FOLLOWUP_COMPLETE') ? false : true).subscribe(async (modelList) => {
            this.updateModelList(modelList);
        });
    }

    private _getServiceId() {
        return this.useServerlessDatamart 
        ? 'followup-service' 
        : this.selectedTypeReport === 'imp' ? 'imp-service' : 'sku-service'
    }

    private _getGeneratorId() {
        return this.selectedTypeReport === 'imp' ? 'fup-unified-imp' : 'fup-unified-sku';
    }

    private _getOldGeneratorId() {
        return this.selectedTypeReport === 'imp' ? 'fup' : 'fup-sku';
    }

    private _getDatamartName() {
        return this.selectedTypeReport === 'imp' ? 'FUP UNIFIED IMP' : 'FUP UNIFIED SKU';
    }

    private _determineFilters() {
        const user = this._userService.getCurrentUser();
        const filters = [
            { title: 'Status', field: 'status' },
            { title: 'Modal', field: 'modalTransportations' },
            { title: `${user.has('INTERNAL_FILTERS') ? 'Clientes' : ''}`, field: 'clientGroups' },
            { title: 'Tipo de Data', field: 'referenceDateType' },
            { title: '', field: 'field' },
            { title: '', field: 'sort' }
        ];
        if (this.selectedTypeReport === 'imp') {
            filters.push(
                { title: 'Filiais', field: 'subsidiaryNames' },
            )
        }
        return filters
    }

    _getReportId() {
        return `FOLLOWUP_SKU`;
    }

    updateModelList(modelList: any) {
        this.options.modelList = this._cmxDropdownService.toDropdownItems(
            modelList.reports.map((model) => {
                return { code: model.id, name: model.reportName };
            })
        );
        this.selectedReport = [this.options.modelList[0]];
        this.asyncDatamart = false;
        this.getDatamartConfig();
    }
}
