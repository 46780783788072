import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import * as _ from 'lodash';
import * as moment from 'moment';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IExchangePackageListingFilter } from './interface/exchange-package-listing.interface';
import { IGridRow } from 'app/shared/components/cmx-custom-grid/interfaces';

export class ExchangePackageListingFilter {
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);
    private _initialExchangePackageListingFilter: IExchangePackageListingFilter = <IExchangePackageListingFilter>{};
    private _exchangePackageListingFilter: IExchangePackageListingFilter = <IExchangePackageListingFilter>{
        status: [],
        clientCode: [],
        companyCode: [],
    };

    private filterField;
    private filterValue;
    private closedStartDate;
    private closedEndDate;
    private debitStartDate;
    private debitEndDate;
    private page: number;
    private pageSize: number = 50;

    private _spGridRows: IGridRow[] = [];
    private _spPreviousGridRows: IGridRow[] = [];

    constructor(filter?: ExchangePackageListingFilter) {
        this._exchangePackageListingFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'exchangePackageListingFilter', this._exchangePackageListingFilter, filter);
        this._initialExchangePackageListingFilter = _.cloneDeep(this._exchangePackageListingFilter);

        this.closedStartDate = filter ? filter.closedStartDate : null;
        this.closedEndDate = filter ? filter.closedEndDate : null;
        this.debitStartDate = filter ? filter.debitStartDate : null;
        this.debitEndDate = filter ? filter.debitEndDate : null;
        this.page = filter ? filter.page : 0;
    }

    getStatus() { return this._exchangePackageListingFilter.status; }
    getClients() { return this._exchangePackageListingFilter.clientCode; }
    getCompanies() { return this._exchangePackageListingFilter.companyCode; }
    getFilterField() { return this.filterField; }
    getFilterValue() { return this.filterValue; }
    getClosedStartDate() { return this.closedStartDate; }
    getClosedEndDate() { return this.closedEndDate; }
    getDebitStartDate() { return this.debitStartDate; }
    getDebitEndDate() { return this.debitEndDate; }
    getPage() { return this.page; }
    getPageSize() { return this.pageSize; }
    getSpGridRows() { return this._spGridRows }
    getSpPreviousGridRows() { return this._spPreviousGridRows }

    setStatus(status): void { this._exchangePackageListingFilter.status = status }
    setClients(clients): void { this._exchangePackageListingFilter.clientCode = clients }
    setCompanies(companies): void { this._exchangePackageListingFilter.companyCode = companies }
    setFilterField(filterField): void { this.filterField = filterField }
    setFilterValue(filterValue): void { this.filterValue = filterValue }
    setClosedStartDate(closedStartDate): void { this.closedStartDate = closedStartDate }
    setClosedEndDate(closedEndDate): void { this.closedEndDate = closedEndDate }
    setDebitStartDate(debitStartDate): void { this.debitStartDate = debitStartDate }
    setDebitEndDate(debitEndDate): void { this.debitEndDate = debitEndDate }
    setPage(page): void { this.page = page }
    setPageSize(size): void { this.pageSize = size }
    setSpGridRows(rows) { this._spGridRows = rows }
    setSpPreviousGridRows(rows) { this._spPreviousGridRows = rows }

    getRequest() {
        let params = new HttpParams();
        let headers = new HttpHeaders();

        this._initialExchangePackageListingFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialExchangePackageListingFilter, this._exchangePackageListingFilter, 'exchangePackageListingFilter', this._userCacheService.getUserPreferences().id);

        params = params.append('status', this.getStatus().map((c) => c.id).join(','));
        params = params.append('clientCode', this.getClients().map((c) => c.id).join(','));
        params = params.append('companyCode', this.getCompanies().map((c) => c.id).join(','));
        params = params.append('page', `${this.getPage()}`);
        params = params.append('size', `${this.getPageSize()}`);

        if (this.getClosedStartDate()){
            params = params.append('closedStartDate', moment(this.getClosedStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD'));
        }

        if (this.getClosedEndDate()) {
            params = params.append('closedEndDate', moment(this.getClosedEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD'));
        }

        if (this.getDebitStartDate()){
            params = params.append('debitStartDate', moment(this.getDebitStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD'));
        }

        if (this.getDebitEndDate()) {
            params = params.append('debitEndDate', moment(this.getDebitEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD'));
        }

        if (this.getFilterField() && this.getFilterValue()) {
            params = params.append(`${this.getFilterField()}`, `${this.getFilterValue()}`);
        }

        return { params, headers };
    }
}
