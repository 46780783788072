import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '#environment';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { IAssembledData } from 'app/shared/components/cmx-assembled-data/interfaces/assembled-data.interface';
import { IGridRow } from 'app/shared/components/cmx-custom-grid/interfaces';
import { IDocumentFile } from '../../interfaces/exchange.interface';
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';

@Injectable()
export class ExchangeSpListingService {

    asyncData: boolean = false;
    dataChanged: boolean = false;
    inputDetailsData = { creditNote: false };
    redirectEnabled;

    private _subscription;
    private _spGridRows: IGridRow[] = [];
    private _spPreviousGridRows: IGridRow[] = [];
    private _spDetails: IAssembledData[];
    private _spExchangeDetails: IAssembledData[];
    private _spDocuments: IAssembledData[];
    private _selectedSpNumber;
    private _importationNumber;
    private _vendor;

    constructor(private _http: HttpClient, private _featureFlagService: FeatureFlagService) {
        this.getFeatureFlag();
     }

    getSpGridRows() { return this._spGridRows }
    getSpPreviousGridRows() { return this._spPreviousGridRows }
    getSpDetails() { return this._spDetails }
    getSpExchangeDetails() { return this._spExchangeDetails }
    getSpDocuments() { return this._spDocuments }
    getPaymentRequestNumber() {return this._selectedSpNumber}
    getCreditNote() { return this.inputDetailsData.creditNote}
    getVendor() {return this._vendor}

    setSpGridRows(rows) { this._spGridRows = rows }
    setSpPreviousGridRows(rows) { this._spPreviousGridRows = rows }
    setCreditNote(creditNote) {this.inputDetailsData.creditNote = creditNote}

    loadSpDetailData(selectedSp) {
        if (this._subscription) { this._subscription.unsubscribe() }
        this.dataChanged = false;
        this.asyncData = true;

        this._selectedSpNumber = selectedSp.paymentRequestNumber;
        this._importationNumber = selectedSp.importationNumber;
        this.inputDetailsData.creditNote = selectedSp.creditNote;

        this._vendor = selectedSp.vendor ? selectedSp.vendor.split(' ')[0]: '';

        this._spDetails = this._verifyFinimp(selectedSp);

        this._spExchangeDetails = [{
            title: 'Banco',
            value: selectedSp.bankName
        }, {
            title: 'Moeda',
            value: selectedSp.currency.currencyCode
        }, {
            title: 'Valor ME',
            value: selectedSp.totalAmountMe?.toFixed(2)
        }, {
            title: 'Taxa Cambial',
            value: selectedSp.tax?.toFixed(4)
        }, {
            title: 'Valor R$',
            value: selectedSp.totalAmountBrl?.toFixed(2)
        }, {
            title: 'Débito',
            value: selectedSp.nationalSettlementDate
        }, {
            title: 'Valuta',
            value: selectedSp.internationalSettlementDate
        }, {
            title: 'Siscomex',
            value: selectedSp?.siscomexNumber
        }, {
            title: 'Número da DI',
            value: selectedSp?.diNumber
        }, {
            title: 'Contrato de Câmbio',
            value: selectedSp?.exchangeContractNumber
        }, {
            title: 'Corretora',
            value: selectedSp.exchangeBroker?.name
        }];
        this._spDocuments = [
            {
                title: 'IMP',
                value: selectedSp.importationNumber
            },
        ];

        this.asyncData = false;
    }

    getDetails(): Observable<any> {
        return this._http.get(`${environment.endpoints.exchangeService}/payment-request-exchange/${this._selectedSpNumber}/${this._importationNumber}`);
    }

    getSpList(request): Observable<any> {
        return this._http.get(`${environment.endpoints.exchangeService}/payment-request-exchange`, request);
    }

    getHistoricList(): Observable<any> {
        return this._http.get(`${environment.endpoints.exchangeService}/payment-request-exchange/history/${this._selectedSpNumber}/${this._importationNumber}`);
    }

    getSubsidiaryNames(): Observable<any> {
        return this._http.get(`${environment.endpoints.exchangeService}/payment-request-exchange/find-subsidiaries`);
    }

    getStatus(): Observable<any> {
        return this._http.get(`${environment.endpoints.exchangeService}/payment-request-exchange/find-all-exchange-status`);
    }

    getExchangeFlow(filter){
        return this._http.get(`${environment.endpoints.exchangeService}/exchange-flow?${filter.subsidiaryNames ? 'subsidiaryNames='+filter.subsidiaryNames+'&' : ''}startDate=${filter.startDate}&endDate=${filter.endDate}`).pipe(take(1));
    }

    getHistory(paymentRequestNumber) {
        return this._http.get(`${environment.endpoints.exchangeService}/payment-request-exchange/history-sp/${paymentRequestNumber}`);
    }

    getChangesApprovalPaymentRequestNumber(paymentRequestNumber, importationNumber): Promise<any> {
        return this._http.put(`${environment.endpoints.exchangeService}/payment-request-exchange/approval-list/${paymentRequestNumber}/${importationNumber}`, null).toPromise();
    }

    setChangedSpDetails(): Observable<any> {
        return this._http.put(`${environment.endpoints.exchangeService}/payment-request-exchange/update-exchange-data/${this._selectedSpNumber}/${this._importationNumber}`, {
            creditNote: this.inputDetailsData.creditNote,
        });
    }

    setExchangeContract(contractNumber:string){
        this._spExchangeDetails.find( m => m.title === 'Contrato de Câmbio').value = contractNumber;
    }

    approveDocument(): Observable<any> {
        return this._http.put(`${environment.endpoints.exchangeService}/payment-request-exchange/approve-documentary-analysis/${this._selectedSpNumber}/${this._importationNumber}`, {});
    }

    undoApproval(): Observable<any> {
        return this._http.put(`${environment.endpoints.exchangeService}/payment-request-exchange/undo-approval-documentary-analysis/${this._selectedSpNumber}/${this._importationNumber}`, {});
    }

    packSPs(request): Observable<any> {
        return this._http.post(`${environment.endpoints.exchangeService}/package`, request);
    }

    deleteFile(fileName:string){
        if (this.redirectEnabled) {
		    return this._http.delete(`${environment.endpoints.documentCommandService}/v1/${fileName}/1`)
        }
		return this._http.delete(`${environment.endpoints.documentService}/v1/${fileName}/1`)
	}

    sendFile(file:IDocumentFile) {
        if (this.redirectEnabled) {
		    return this._http.post(`${environment.endpoints.documentCommandService}/v1/sync-upload`, file);
        }
		return this._http.post(`${environment.endpoints.documentService}/v1/sync-upload`, file);
	}

    updateExchangeContract(type:any, contractNumber:string, removeContract:boolean): Observable<any> {
        return this._http.put(`${environment.endpoints.exchangeService}/payment-request-exchange/update-exchange-contract/${this._selectedSpNumber}`, {
            type: type,
            contractNumber: contractNumber,
            delete: removeContract
        });
    }

    private _verifyFinimp(selectedSp) {
        if(selectedSp.financialModality?.modalityName === 'Finimp'){
            return [{
                title: 'Vencimento',
                value: selectedSp.ticket?.ticketDue
            }, {
                title: 'Desembolso',
                value: selectedSp.disbursementDate
            }, {
                title: 'Filial',
                value: selectedSp.subsidiary?.subsidiaryName
            }, {
                title: 'Modalidade',
                value: selectedSp.financialModality?.modalityName
            }, {
                title: 'Cliente',
                value: selectedSp.client?.clientName
            }, {
                title: 'Tipo de Doc.',
                value: selectedSp.document?.documentType
            }, {
                title: 'Usuário',
                value: selectedSp.user,
                noBreakWord: true
            }, {
                title: 'País do Exportador',
                value: selectedSp.document?.documentExportingCountry
            }, {
                title: 'Aprovações',
                value: selectedSp.approvalList,
                formatter: 'exchangeApproval'
            }, {
                title: 'Status',
                value: selectedSp.status,
                formatter: 'status'
            }, {
                title: 'Beneficiário',
                value: selectedSp.financialInstitution
            }, {
                title: 'Centro de Custo',
                value: selectedSp.costCenter
            }, {
                title: 'IMP',
                value: selectedSp.importationNumber
            }, {
                title: 'Débito',
                value: selectedSp.nationalSettlementDate
            }, {
                title: 'Valuta',
                value: selectedSp.internationalSettlementDate
            }];
        } else {
            return [{
                title: 'Vencimento',
                value: selectedSp.ticket?.ticketDue
            }, {
                title: 'Emissão',
                value: selectedSp.document?.documentIssueDate
            }, {
                title: 'Filial',
                value: selectedSp.subsidiary?.subsidiaryName
            }, {
                title: 'Modalidade',
                value: selectedSp.financialModality?.modalityName
            }, {
                title: 'Cliente',
                value: selectedSp.client?.clientName
            }, {
                title: 'Tipo de Doc.',
                value: selectedSp.document?.documentType
            }, {
                title: 'Nº Documento',
                value: selectedSp.document?.documentNumber
            }, {
                title: 'Usuário',
                value: selectedSp.user,
                noBreakWord: true
            }, {
                title: 'País do Exportador',
                value: selectedSp.document?.documentExportingCountry
            }, {
                title: 'Aprovações',
                value: selectedSp.approvalList,
                formatter: 'exchangeApproval'
            }, {
                title: 'Status',
                value: selectedSp.status,
                formatter: 'status'
            }, {
                title: 'Exportador',
                value: selectedSp.vendor
            }, {
                title: 'Centro de Custo',
                value: selectedSp.costCenter
            }, {
                title: 'IMP',
                value: selectedSp.importationNumber
            }, {
                title: 'Débito',
                value: selectedSp.nationalSettlementDate
            }, {
                title: 'Valuta',
                value: selectedSp.internationalSettlementDate
            }];
        }
    }

    async getFeatureFlag(): Promise<void> {
        this.redirectEnabled = await this._featureFlagService.isFeatureFlagEnabledV2(FeatureFlagService.FEATURE_REDIRECT_GED_V2);
    }
}
