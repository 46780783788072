<div class="group-attach">
    <cmx-button id="national-freight-rate-upload-button" variant="success" icon="fa-cloud-upload" (click)="fileInput.click()" style="position: absolute; right: 10px; top: 15px">
        <ng-container>ATUALIZAR BASE DE DADOS</ng-container>
    </cmx-button>
</div>

<input
    id="national-freight-rate-select-hidden-file"
    hidden
    type="file"
    #fileInput
    (change)="setSelectedFile($event)"
    (click)="cleanEvent($event)"
    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
/>

<br>

<div class="nationalFreightContent">
    <section>
        <ng-container *ngTemplateOutlet="painelConsulta"></ng-container>
        <ng-container *ngTemplateOutlet="painelGrid"></ng-container>
    </section>
</div>

<ng-template #painelConsulta>
    <div class="grid filters">
        <div>
            <cmx-dropdown [styles]="{ width: '250px' }" name="origin" [data]="origin" [ngModel]="filterState.getNationalOrigin()"
                (ngModelChange)="handleSelectOrigin($event)" [settings]="dropdownSettingsOrigin"></cmx-dropdown>
        </div>
        <div>
            <cmx-dropdown [styles]="{ width: '250px' }" name="destination" [data]="destination"
                [ngModel]="filterState.getNationalDestination()" (ngModelChange)="handleSelectDestination($event)"
                [settings]="dropdownSettingsDestination"></cmx-dropdown>
        </div>
        <div>
            <cmx-dropdown [styles]="{ width: '220px' }" name="transport" [data]="transport"
                [ngModel]="filterState.getNationalTransport()" (ngModelChange)="handleSelectTransport($event)"
                [settings]="dropdownSettingsTransport"></cmx-dropdown>
        </div>
        <button class="btn-search" (click)="handleSelect()">
            <i class="fa fa-check"></i>
        </button>
    </div>
</ng-template>

<ng-template #painelGrid>
    <loader *ngIf="showLoader"></loader>
    <div class="d-flex mt-3">
        <div class="gridContent" *ngIf="!showLoader && gridRows?.length > 0">
            <div class="nationalFreightGrid mb-2">
                <cmx-custom-grid
                    [id]="'national-freight-rates-grid'"
                    [columns]="gridColumns"
                    [rows]="gridRows"
                    [options]="{ forceFitColumns: false, useCategoryGrouping: true }"
                    [styles]="{ height: 'calc(100vh - 270px)'}"
                ></cmx-custom-grid>
            </div>
            <span class="text-danger">*</span> Custo <b>por ajudante</b> cobrado no Ct-e (haverá incidência de ICMS)
        </div>
        <div class="chartContent mt-3" [hidden]="showLoader || gridRows?.length < 1">
            <div class="chart" id="national-freight-rate-total-freight-chart"></div>
        </div>
    </div>

    <div *ngIf="!showLoader && gridRows?.length < 1" class="noContent">Não existem custos a serem detalhados.</div>

</ng-template>

<cmx-confirmation-modal
    id="modalApproveUpload"
    question=""
    labelAction="Sim"
    labelCancel="Não"
    [hidden]="!fileSelected"
    (onConfirm)="handleAttachFile()"
    (onCancel)="handleClearAttachFile()"
    >

    <ng-container cmx-body>
        <p *ngIf="fileSelected">
            Atualizar base com o arquivo <span style="font-weight: 500;">{{ fileSelected.name }}</span> ?
        </p>
    </ng-container>
</cmx-confirmation-modal>
