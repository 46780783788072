
export const generateData = function() {
    let qtd = 20;
    let data = [];
    let average = [0, 0, 0, 0];

    function makeKpi(obj: any, kpiIndex: number): void {
        let sla = Math.round(Math.random() * 10) < 2 ? 13 : 10;
        let actual = sla - 3 + (Math.round(Math.random() * 10) % 5);
        average[kpiIndex - 1] += actual;

        obj[`sla${kpiIndex}`] = sla;
        obj[`actual${kpiIndex}`] = actual;
        obj[`color${kpiIndex}`] = sla < actual ? '#f4516c' : '#08a3cc';
    }

    for (var i = 0; i < qtd; i++) {

        let impNo = Math.round(Math.random() * 100000) + '-00';
        let imp = { imp: impNo };
        makeKpi(imp, 1);
        makeKpi(imp, 2);
        makeKpi(imp, 3);
        makeKpi(imp, 4);

        data.push(imp);
    }

    average.forEach((val, i) => {
        average[i] = average[i] / qtd;
    });

    data.forEach((val, i) => {
        val.average1 = average[0];
        val.average2 = average[1];
        val.average3 = average[2];
        val.average4 = average[3];
    })

    return data;
};

export const data = generateData();
