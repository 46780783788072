import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StompRService} from '@stomp/ng2-stompjs';

import { WebsocketService } from './websocket.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    StompRService,
    WebsocketService,
  ]
})
export class WebsocketModule { }
