
import {first, map} from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '#environment';
import { Observable } from 'rxjs';
import { DocumentsFilter } from '../helpers/document.filter';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';

@Injectable()
export class DocumentService {

    filterState:DocumentsFilter = this._filterStateService.getDocumentsFilter();
    redirectEnabled;

    constructor(
        private http: HttpClient,
        private _filterStateService: FilterStateService,
        private _featureFlagService: FeatureFlagService
    ) { 
        this.getFeatureFlag();
    }

    getDocumentTypes() {
        if (this.redirectEnabled) {
            return this.http.get(`${environment.endpoints.documentQueryService}/v1/list-type-files`);
        }
        return this.http.get(`${environment.endpoints.documentService}/v1/list-type-files`);
    }

    getImpsList(request): Observable<any> {
        if (this.redirectEnabled) {
            return this.http.get(`${environment.endpoints.documentQueryService}/v1/list-imp-files-by-external-type`, request);
        }
        return this.http.get(`${environment.endpoints.documentService}/v1/list-imp-files-by-external-type`, request);
    }

    getDocumentsByImp(imp, clientGroup, isInternalUser): Observable<any> {
        const importationNumber = imp;
        const clientGroupHeader = clientGroup.map((c) => c.id).join(',');
        if (this.redirectEnabled) {
            return this.http.get(`${environment.endpoints.documentQueryService}/v1/list-files-by-imp/${importationNumber}?X-Internal-User=${isInternalUser}`, {
                headers: new HttpHeaders().set('X-Requested-Client-Groups', clientGroupHeader),
            });
        }
        return this.http.get(`${environment.endpoints.documentService}/v1/list-files-by-imp/${importationNumber}?X-Internal-User=${isInternalUser}`, {
            headers: new HttpHeaders().set('X-Requested-Client-Groups', clientGroupHeader),
        });
    }

    getAttachedFilesPackage(listImps: string){

        return this.http.get(`${environment.endpoints.exchangeService}/package/find-files-by-imp?imps=${listImps}`).pipe(first());
    }

    filterImps(imp): Observable<any> {
        if (this.redirectEnabled) {
            return this.http.get(
            `${environment.endpoints.documentQueryService}/v1/file-by-importationNumber/${imp}`);
        }
        return this.http.get(
        `${environment.endpoints.documentService}/v1/file-by-importationNumber/${imp}`);
    }

    exportDocuments(imp, options, isInternalUser, uuidList): Observable<HttpResponse<any>> {
        if (this.redirectEnabled) {
            return this.http.post(`${environment.endpoints.documentQueryService}/v1/download-by-importation-number/${imp}?X-Internal-User=${isInternalUser}&uuidList=${uuidList}`,
            options, { observe: 'response', responseType: 'blob' },
            );
        }
        return this.http.post(`${environment.endpoints.documentService}/v1/download-by-importation-number/${imp}?X-Internal-User=${isInternalUser}&uuidList=${uuidList}`,
        options, { observe: 'response', responseType: 'blob' },
        );
    }

    exportSingleDocument(documentName, options, isInternalUser): Observable<HttpResponse<any>> {
        if (this.redirectEnabled) {
            return this.http.post(`${environment.endpoints.documentQueryService}/v1/download-by-document-name/${documentName}?X-Internal-User=${isInternalUser}`,
            options, { observe: 'response', responseType: 'blob' });
        }
        return this.http.post(`${environment.endpoints.documentService}/v1/download-by-document-name/${documentName}?X-Internal-User=${isInternalUser}`,
        options, { observe: 'response', responseType: 'blob' });
    }

    getPDF(code): Observable<any> {
        if (this.redirectEnabled) {
            return this.http.get(`${environment.endpoints.documentQueryService}/v1/download-by-document-name/${code}`,
            { responseType: 'blob'}).pipe(map((res) => {
                return new Blob([res], { type: 'application/pdf' });
            }));
        }
        return this.http.get(`${environment.endpoints.documentService}/v1/download-by-document-name/${code}`,
        { responseType: 'blob'}).pipe(map((res) => {
            return new Blob([res], { type: 'application/pdf' });
        }));
    }

    async getFeatureFlag(): Promise<void> {
        this.redirectEnabled = await this._featureFlagService.isFeatureFlagEnabledV2(FeatureFlagService.FEATURE_REDIRECT_GED_V2);
    }
}
