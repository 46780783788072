<ng-template #trace><span class="invoice-percentage">-</span></ng-template>
<div class="moveBtn"><div class="moveLine"></div></div>
<div *ngIf="asyncConsolidation"><loader></loader></div>

<div class="headerContainer">
    <div class="title-container">
        <span class="analyticsName">Consolidação Operacional</span>
        <cmx-help-modal
            help-title="Consolidação Operacional"
            [help-description]="helpDescription"
        ></cmx-help-modal>
        <h5>Consolidação Operacional</h5>
    </div>
    <div id="searchLine" class="reduzed">
        <div class="input-group-operational">
            <cmx-dropdown
                [styles]="{ width: '130px' }"
                name="profit-centers"
                [data]="options.profitCenters"
                [settings]="dropdownSettings.profitCenters"
                [ngModel]="filterState.getProfitCenters()"
                (ngModelChange)="filterState.setProfitCenters($event)"
            ></cmx-dropdown>
        </div>
        <div class="input-group">
            <cmx-filter-datepicker
                [date]="'Chegada'"
                [startDate]="filterState.getStartDate()"
                [endDate]="filterState.getEndDate()"
                (onChangeDates)="datesChanged($event)"
            ></cmx-filter-datepicker>
        </div>
        <button (click)="changeDateCustom()" class="applyImpStatus">
            <i class="fa fa-check"></i>
        </button>
    </div>
</div>
<div>
    <span class="extraInfo">*Com base na data de chegada dos veículos (navios, aviões e caminhões) usados no
        transporte</span>
</div>
<div (click)="changeDate('back')" *ngIf="!oldDate" class="btnMonth leftBtnMonth" title={{messageOldDate}}>
    <i class="la la-angle-left"></i>
</div>
<div (click)="changeDate('go')" *ngIf="!futureDate" class="btnMonth rightBtnMonth" title={{messageFutureDate}}>
    <i class="la la-angle-right"></i>
</div>

<div class="status-consolidation-container">
    <div *ngIf="!asyncConsolidation" class="consolidationContent">
        <div *ngFor="let consolidation of consolidationOrder; trackBy: trackByFn" class="consolidation">
            <div
                class="numberContainer"
                [class.clickable]="getValue(consolidation) > 0"
                (click)="openDetailModal(consolidation)"
            >
                <span class="number">{{ getValue(consolidation) }}</span>
                <img src="assets/img/icons/logisticConsolidation/{{ consolidation }}.png" />
            </div>
            <div class="title">{{ getTitle(consolidation) }}</div>
            <div class="progress">
                <div
                    id="dataInvoicesBilledItens"
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    [style.width]="getPercentage(consolidation) + '%'"
                ></div>
            </div>
            <div class="progressText">
                <span class="invoice-detail">Rel. Periodo Anterior</span>
                <span *ngIf="filterState.getStartDate() != '01/01/2017'; else trace" class="invoice-percentage">
                    {{ getPercentage(consolidation) + '%' }}
                </span>
            </div>
        </div>
    </div>
</div>

<div class="detailModal hidden">
    <i (click)="closeDetailModal()" class="close la la-close"></i>
    <div class="fixedHeader">
        <span class="title">{{ selectedDetailTitle }}</span>
        <hr />
    </div>
    <loader *ngIf="asyncDetailConsolidation"></loader>
    <div *ngIf="!asyncDetailConsolidation" class="modalContent">
        <div class="tableHeader">
            <span>Cliente</span>
            <span>{{ selectedTableTitle }}</span>
        </div>
        <div class="tableData">
            <div *ngFor="let data of detailData; trackBy: trackByFn" class="detailData" (click)="redirectTo(data)">
                <span>{{ data.name }}</span>
                <span>{{ data.value }}</span>
            </div>
        </div>
    </div>
</div>
