import { AuthenticationService } from 'app/auth/_services';

import {mergeMap} from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { User } from '../_models/user';
import { UserService } from './user.service';
import moment from 'moment';

@Injectable()
export class AuthorizationTokenInterceptor implements HttpInterceptor {
    constructor(private userService: UserService, public router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser:any = this.userService.getCurrentUser();

        if (this.userIsLoggedIn(currentUser) && this.isApiRequest(req)) {

            if (this.router.url != '/logout' && currentUser.hasToEnableTwoFactor()) {
                this.router.navigate(['/profile']);
            }

            const dateToExpire = currentUser.data.expireIn;

            if(moment(new Date()).unix() > moment(dateToExpire).unix()){
                
                this.userService.updateToken(currentUser.data)
            }
            
            return currentUser.getToken().pipe(mergeMap((token) => {
                let headers = req.headers;
                headers = headers.set('Authorization', `Bearer ${token}`)
                let sessionId = localStorage.getItem('sessionId');
                if (sessionId) {
                    headers = headers.set('X-Authorization-SessionId', localStorage.getItem('sessionId'));
                }
                return next.handle(
                    req.clone({
                        headers: headers
                    })
                );
            }));
        } else {
            return next.handle(req);
        }
    }

    private userIsLoggedIn(user: User) {
        return user != null;
    }

    private isApiRequest(req: HttpRequest<any>) {
        return req.url.indexOf('/api/') !== -1;
    }
}
