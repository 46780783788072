<div class="cmx-form">
    <form [formGroup]="form" novalidate="novalidade" autocomplete="off">
        <div *ngFor="let row of fieldRows; let index = index; let last = last" class="row" [ngClass]="{'last': last}">
            <cmx-splitter-line *ngIf="row?.splitterLine?.enable"
                [style.margin-top]="index > 0 ? '10px' : '0px'"
                [title]="row?.splitterLine?.title"
                [icon]="row?.splitterLine?.icon"
                [info]="row?.splitterLine?.info"
                [placement]="row?.splitterLine?.infoDirection || 'top'"
                [buttonList]="row?.splitterLine?.buttonList"
                [rightButton]="row?.splitterLine?.rightButton"
                [switch]="row?.splitterLine?.switch"
                [selectedSwitch]="form.get('splitter' + index).value"
                [buttonList]="row?.splitterLine?.buttonList"
                (onSwitchSelect)="onSplitterLineSwitch($event, 'splitter' + index)"
                (listButtonClick)="onSplitterButton($event, row)"
            ></cmx-splitter-line>
            <div *ngFor="let field of row.fields" class="p-field {{ field?.classes }}">
                <label>{{ field?.label }}
                    <span *ngIf="field.required" class="required">*</span>
                    <div *ngIf="field.info"
                        [tooltip]="field.info"
                        [placement]="field?.infoDirection || 'top'"
                        [nowrap]="field?.infoWrap !== undefined ? field.infoWrap : true"
                        class="info-container">
                    <span>?</span></div>
                </label>
                <input *ngIf="field?.type === 'input'" pInputText type="text"
                    [formControlName]="field?.controlName"
                    [attr.disabled]="field?.disabled"
                    [mask]="field?.mask"
                    [maxlength]="field?.maxlength"
                    (input)="onChange(field?.controlName)"
                />
                <textarea
                    pInputTextarea
                    *ngIf="field?.type === 'textarea'"
                    [formControlName]="field?.controlName"
                    [attr.disabled]="field?.disabled"
                    [maxlength]="field?.maxlength"
                    (keydown.enter)="preventEnterKey($event)"
                    (input)="onChange(field?.controlName)"
                ></textarea>
                <p-inputSwitch *ngIf="field?.type === 'boolean'"
                    [formControlName]="field?.controlName"
                    [disabled]="field?.disabled"
                    (onChange)="onChange(field?.controlName)"></p-inputSwitch>
                <cmx-dropdown *ngIf="field?.type === 'dropdown'"
                    name="field?.label"
                    [formControlName]="field?.controlName"
                    [data]="field?.dropdownData"
                    [settings]="field?.dropdownSettings"
                    (onChange)="onChange(field?.controlName)"
                ></cmx-dropdown>
                <cmx-filter-datepicker *ngIf="field?.type === 'datepicker'"
                    id="field?.label"
                    alone="'true'"
                    type="single-date"
                    visual="big"
                    [disabled]="field?.disabled"
                    [startDate]="form.get(field?.controlName).value || currentDate"
                    [startDateRestriction]="field?.datepickerSettings?.startDateRestriction"
                    [endDateRestriction]="field?.datepickerSettings?.endDateRestriction"
                    (onChangeDates)="onDatepickerChanges($event, field?.controlName)"
                ></cmx-filter-datepicker>
            </div>
        </div>
    </form>
</div>
