import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';

export const CLEARANCE_PACKAGE_COLUMNS: IGridColumn[] = [
    {
        field: 'description',
        name: 'Volume',
        width: 115,
    },
    {
        field: 'quantity',
        name: 'Quantidade',
        width: 115,
        headerCssClass: 'grid-header align-right',
        cssClass: 'grid-cell align-right',
    },
];
