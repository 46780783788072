<div class="newFeaturesComponent hidden">
    <div class="newFeaturesContent">
        <div class="titleContainer">
            <i (click)="newFeaturesConsumerService.closeNewFeature()" class="la la-close"></i>
            O quê há de novo?
        </div>
        <div class="featuresContainer">
            <loader *ngIf="newFeaturesConsumerService.asyncModal"></loader>
            <div *ngFor="let release of newFeaturesConsumerService.getReleases(); trackBy: trackByFn" class="featureContent">
                <div class="titleContent">
                    <hr>
                    <div class="title">{{release?.title}}</div>
                </div>
                <div *ngFor="let feature of release.features; trackBy: trackByFn" class="feature">
                    <div class="featureTitle">
                        <div class="titleBall {{feature?.type?.code}}"></div>{{feature?.title}}
                        <div class="featureType {{feature?.type?.code}}">{{feature?.type?.name}}</div>
                    </div>
                    <div class="featureDescription">
                        {{feature?.description}}
                    </div>
                    <div *ngIf="feature.fileName !== '' && feature.fileName !== null"
                        class="featureImg">
                        <img id="feature.fileName" alt=""
                            [src]="newFeaturesConsumerService.getPath(feature.fileName)">
                    </div>
                    <div (click)="newFeaturesConsumerService.toFeatureLink(feature.link)" *ngIf="feature.link"
                        class="link">
                        <span>Clique para acessar</span>
                    </div>
                    <hr>
                </div>
            </div>
        </div>
    </div>
</div>
