import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import moment from 'moment';

@Injectable()
export class CountdownService {

    dateTimer: string;
    timerSeconds: number;
    timerSubscribe;
    _autoReset: boolean = true;

    setAutoReset(autoReset) {
        this._autoReset = autoReset;
    }

    public timer(active: boolean, seconds: number, countdown: ICountdown) {
        this._autoReset = active;
        clearInterval(this.timerSubscribe);
        if (this._autoReset) {
            this.timerSeconds = seconds;
            this.timerSubscribe = setInterval(() => {
                if (this._autoReset) {
                    this.timerSeconds >= 0 ?
                    this.dateTimer = moment().startOf('day').seconds(this.timerSeconds--).format('H:mm:ss') :
                    this.finish(seconds, countdown);
                }
            }, 1000);
        } else {
            clearInterval(this.timerSubscribe);
            this.dateTimer = '0:00:00';
        }
    }

    public finish(seconds: number, countdown: ICountdown) {
        this.timerSeconds = seconds;
        countdown.timerFinished();
    }

}

export interface ICountdown {
    timerFinished(): void;
 }
