import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';

export const BOARDING_STATUS_VEHICLES_COLUMNS: IGridColumn[] = [
    {
        name: 'Veículo',
        field: 'vehicleType',
        width: 120,
    },
    {
        name: 'Quantidade',
        field: 'totalVehicles',
        formatterType: 'badge',
        width: 70,
        headerCssClass: 'grid-header align-center',
    }
];
