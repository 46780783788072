import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class MessagesService {

    public asyncMessages: boolean = false;
    public isVisibleMessages: boolean = false;
    public reloadMessages = new Subject<any>();
    public reloadMessages$ = this.reloadMessages.asObservable();
    public messagesCount: number = 0;

    public loadMessages() {
        this.reloadMessages.next('first');
    }

    public openMessagesModal() {
        if ($('.messagesModalContainer').hasClass('hidden')) {
            this.isVisibleMessages = true;
            $('.messagesModalContainer').removeClass('hidden');
            $('.messagesModalContainer').addClass('border');
            this.reloadMessages.next();
            this.closeMessagesByClickingOut();
            this.disableWheelOutOfMessageComponents();
        } else {
            this.closeMessagesModal();
        }
    }

    public closeMessagesModal() {
        this.isVisibleMessages = false;
        $('.messagesModalContainer').removeClass('border');
        $('.messagesModalContainer').addClass('hidden');
        this.enableWheelInAllComponents();
    }

    private closeMessagesByClickingOut() {
        $('.mainMenuContent, #nameWithPhotoContainer, #alertIconContainer, #features').off('click');
        $('.mainMenuContent, #nameWithPhotoContainer, #alertIconContainer, #features').on('click', (e) => {
            const target = $(e.target);
            const isBtnComponent = target.parents('#messages').length;
            const isMessageComponent = target.parents('.messagesModalContainer, .selectedMessageContainer').length;
            if (!isBtnComponent && !isMessageComponent) {
                $('.mainMenuContent').unbind('click');
                this.closeMessagesModal();
            }
        });
    }

    private disableWheelOutOfMessageComponents() {
        $('messages .listContainer').off('wheel');
        $('messages .listContainer').on('wheel', (e) => {
            const target = $(e.target);
            const isMessageDescendant = target.parents('.messageContent').length ||
            target.hasClass('messageContent');
            if (!isMessageDescendant) {
                e.preventDefault();
            }
        });
    }
    private enableWheelInAllComponents() {
        $('.listContainer').unbind('wheel');
    }

}
