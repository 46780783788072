export const MOVEMENT_BY_MODAL_CHART_CONFIG = {
    type: 'serial',
    theme: 'light',
    categoryField: 'label',
    language: 'pt',
    categoryAxis: {
        gridPosition: 'start',
        position: 'left',
        autoWrap: false,
        fontSize: 9,
        boldLabels: true,
    },
    graphs: [
        {
            balloonText: '',
            fillColors: '#d9d9d9',
            lineColor: '#d9d9d9',
            fillAlphas: 0.8,
            lineAlpha: 0.2,
            type: 'column',
            valueField: 'previousValue',
            labelText: '[[previousValue]]',
            showAllValueLabels: true,
            fontSize: 10,
        },
        {
            balloonText: '',
            fillColors: '#364476',
            lineColor: '#364476',
            fillAlphas: 0.8,
            lineAlpha: 0.2,
            type: 'column',
            valueField: 'value',
            labelText: '[[value]]',
            showAllValueLabels: true,
            fontSize: 10,
        },
    ],
    legend: {
        enabled: true,
        useGraphSettings: true,
    },
    valueAxes: [
        {
            axisAlpha: 0,
            position: 'left',
        },
    ],
}