import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class CustomGridUtilsService {

    onDropdownApply(gridColumns, { column, dropdownSelection }) {
        const selectedColumn = gridColumns.find((f) => f.field == column.field );
        selectedColumn.selectedDropdownOptions = dropdownSelection || [];
        selectedColumn.filtering = false;
    }

}
