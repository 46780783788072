import { FilterStateService } from '#services/_filters/filter-state.service';
import { Component, ViewEncapsulation } from '@angular/core';
import { Tabs } from './interfaces/quotation-freight-rates-filter.interface';
import { QuotatioFreightRatesFilter } from './quotation-freight-rates-filter.model';

@Component({
  selector: 'freight-rate',
  templateUrl: './quotation-freight-rates.component.html',
  styleUrls: ['./quotation-freight-rates.component.scss'],
})
export class QuotationFreightRatesComponent {

  dataTitle;
  showNationalFreightTab = true;
  helpDescription  = this._defineHelpDescription();

  filterState: QuotatioFreightRatesFilter = this.filterStateService.getQuotatioFreightRatesFilter();

  constructor(
    private filterStateService: FilterStateService,
  ) { }

  ngOnInit() {
    this.dataTitle = { title: 'Simular Frete' };
    this.changeTab(this.filterState.getTab());
  }

  changeTab(tabId) {

    if (tabId === '') {
      tabId = 0;
    }

    this.showNationalFreightTab = false;
    if (tabId == Tabs.NATIONAL) {
      this.filterState.setTab(tabId);
      $('#freight-rate-national-tab').addClass('checked');
      $('#freight-rate-international-tab').removeClass('checked');
      this.showNationalFreightTab = true;
    } else {
      this.filterState.setTab(tabId);
      $('#freight-rate-national-tab').removeClass('checked');
      $('#freight-rate-international-tab').addClass('checked');
    }

    this.updateTab();
  }

  private updateTab() {
    this.filterState.updatePreference();
  }

  private _defineHelpDescription(): object[] {
    return [
        {
            type: 'list',
            list: "É possível saber a média de fretes nacionais e internacionais, com base em planilhas imputadas no sistema, basta incluir os parâmetros de interesse que o sistema trará a média de valores dos fretes acordados com os fornecedores. As planilhas são atualizadas quinzenalmente. "
        }
    ]
}
}
