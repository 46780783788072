export const MOVEMENT_BY_MODAL_TYPES_CHART_CONFIG = {
    type: 'serial',
    theme: 'none',
    categoryField: 'label',
    startDuration: 0,
    gridAboveGraphs: false,
    zoomOutText: '',
    addClassNames: true,
    graphs: [
        {
            balloonText: '',
            fillAlphas: 0.9,
            lineAlpha: 0.2,
            type: 'column',
            valueField: 'value',
            fillColorsField: 'color',
            lineColorsField: 'color',
            labelText: '[[value]]',
            showAllValueLabels: true,
            fontSize: 10,
        },
    ],
    valueAxes: [
        {
            gridColor: '#FFFFFF',
            gridAlpha: 0.2,
        },
    ],
    chartCursor: {
        categoryBalloonEnabled: false,
        cursorAlpha: 0,
        zoomable: false,
    },
    categoryAxis: {
        gridPosition: 'start',
        gridAlpha: 0,
        tickPosition: 'start',
        tickLength: 20,
        autoWrap: false,
        fontSize: 9,
        boldLabels: true,
    },
    export: {
        enabled: true,
    },
};