import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';

import { AutomotiveDetailDailyReportSlickGrid } from './detail-daily.slickGrid';
import { environment } from '#environment';
import { UtilsService } from '#services/_utils/utils.service';

declare let Slick: any;

@Injectable()
export class AutomotiveDetailDailyService {

    public editableElements = ['#detailDailyGrid', 'pagination'];
    public subscription;
    public queryId = Math.random();
    public pages = [];
    public page = 0;
    public size = 50;
    public columns;
    public rows;
    public dataView;
    public detailDailyTitle;
    public detailDailyGrid;
    private date;
    private filters;
    private division;

    constructor(
        private http: HttpClient,
        private utilsService: UtilsService,
        private slick: AutomotiveDetailDailyReportSlickGrid,
    ) { }

    public changePage(pageNumber) {
        this.page = (pageNumber - 1);
        this.loadDetailDaily(this.date, this.filters, this.division);
    }

    public loadDetailDaily(date, filters, division) {
        $('automotive-detail-daily loader').css('display', 'block');
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.utilsService.setInvisible('automotive-detail-daily', this.editableElements);
        this.subscription = this.getDetailDaily(date, filters, division).subscribe((data) => {
            this.date = date;
            this.filters = filters;
            this.division = division;
            this.pages = Array.apply(null, { length: data.totalPages }).map(Number.call, Number);
            this.columns = this.slick.createColumns(filters);
            this.rows = data.content;
            const options = {
                defaultColumnWidth: 120,
                enableCellNavigation: true,
                enableTextSelectionOnCells: true,
                rowHeight: 30,
                forceFitColumns: true,
                enableColumnReorder: true,
            };
            this.dataView = new Slick.Data.DataView({ inlineFilters: false });
            this.dataView.beginUpdate();
            this.dataView.setItems(this.rows);
            this.dataView.endUpdate();
            this.detailDailyGrid = new Slick.Grid('#detailDailyGrid', this.dataView, this.columns, options);
            this.detailDailyGrid.render();
            this.detailDailyGrid.onCellChange.subscribe((function (e, args) {
                this.dataView.updateItem(args.item.id, args.item);
            }).bind(this));
            this.dataView.onRowCountChanged.subscribe((function (e, args) {
                this.dailyGrid.updateRowCount();
                this.dailyGrid.render();
            }).bind(this));
            this.dataView.onRowsChanged.subscribe((function (e, args) {
                this.dailyGrid.invalidateRows(args.rows);
                this.dailyGrid.render();
            }).bind(this));
            $('automotive-detail-daily loader').css('display', 'none');
            this.utilsService.setVisible('automotive-detail-daily', this.editableElements);
        }, (error) => {
            this.utilsService.setVisible('automotive-detail-daily', this.editableElements);
            $('automotive-detail-daily loader').css('display', 'none');
            this.utilsService.errorHandler(error, 'Detalhe Movimentação Diária');
        });
    }

    private formatValueSpaces(value) {
        const newValue = value.split(' - ').join('_');
        return newValue.split(' ').join('_');
    }

    private getDetailDaily(date, filters, division): Observable<any> {
        return this.http.get(`${environment.endpoints.automotiveV2QueryService
                }/api/v2/reports-query/details/DAILY_MOVEMENT?date=${moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
                }&division=${this.formatValueSpaces(division === 'total' ? '' : division)
                }&section=${this.formatValueSpaces(filters.section || '')
                }&subSection=${this.formatValueSpaces(filters.subSection || '')
                }&carrier=${this.formatValueSpaces(filters.carrier || '')
                }&page=${this.page
                }&size=${this.size}`);
    }

}
