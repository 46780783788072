import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from 'app/shared/shared.module';
import { DragulaModule } from 'ng2-dragula';
import { LayoutModule } from '../../../layouts/layout.module';

import { CustomDashboardComponent } from './custom-dashboard.component';
import { ComponentDescriptions } from './custom-dashboard.descriptions';

const routes: Routes = [
    {
        path: '',
        component: CustomDashboardComponent,
    },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        LayoutModule,
        DragulaModule,
        SharedModule,
    ],
    declarations: [
        CustomDashboardComponent,
    ],
    exports: [
        CustomDashboardComponent,
    ],
    providers: [
        ComponentDescriptions,
        // AccountingCrudService,
    ],
})
export class CustomDashboardModule {

}
