<div [id]="skeletonLoaderId" class="skeleton-loader-container">

    <!-- Grid -->
    <div class="skeleton-grid-container" *ngIf="type === 'grid'">
        <div class="skeleton-grid-anchor-container">
            <div class="skeleton-grid-anchor"></div>
            <div class="skeleton-grid-anchor"></div>
            <div class="skeleton-grid-anchor"></div>
            <div class="skeleton-grid-anchor"></div>
            <div class="skeleton-grid-anchor"></div>
        </div>
        <div *ngFor="let row of [].constructor(gridRows); let first = first"
            class="skeleton-grid-row {{ first ? 'first' : '' }}"
            [style.height]="rowHeight + 'px'">
            <div class="skeleton-grid-cell" style="width: calc(5% - 10px)"></div>
            <div class="skeleton-grid-cell" style="width: calc(15% - 10px)"></div>
            <div class="skeleton-grid-cell" style="width: calc(10% - 10px)"></div>
            <div class="skeleton-grid-cell" style="width: calc(20% - 10px)"></div>
            <div class="skeleton-grid-cell" style="width: calc(25% - 10px)"></div>
            <div class="skeleton-grid-cell" style="width: calc(15% - 10px)"></div>
            <div class="skeleton-grid-cell" style="width: calc(10% - 10px)"></div>
        </div>
    </div>

    <!-- Pagination -->
    <div class="skeleton-pagination-container" *ngIf="type === 'pagination'">
        <div *ngFor="let page of [].constructor(5);" class="skeleton-pagination-box"></div>
    </div>

    <!-- Page Wrapper Title -->
    <div class="skeleton-page-wrapper-title-container" *ngIf="type === 'pageWrapperTitle'">
        <div class="skeleton-page-wrapper-title"></div>
        <div class="skeleton-page-wrapper-title-content">
            <div class="skeleton-page-wrapper-title half"
                style="margin-bottom: 4px"></div>
            <div class="skeleton-page-wrapper-title"></div>
        </div>
    </div> 

    <!-- Page Wrapper RightButtons -->
    <div class="skeleton-page-wrapper-right-buttons-container" *ngIf="type === 'pageWrapperRightButtons'">
        <div class="skeleton-page-wrapper-right-buttons"></div>
        <div class="skeleton-page-wrapper-right-buttons"></div>
        <div class="skeleton-page-wrapper-right-buttons"></div>
        <div class="skeleton-page-wrapper-right-buttons"></div>
    </div> 

    <!-- Page Wrapper TopButtons -->
    <div class="skeleton-page-wrapper-top-buttons-container" *ngIf="type === 'pageWrapperTopButtons'">
        <div class="skeleton-page-wrapper-top-buttons left"></div>
        <div class="skeleton-page-wrapper-top-buttons center"></div>
        <div class="skeleton-page-wrapper-top-buttons right"></div>
    </div> 

</div>