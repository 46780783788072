import { AfterContentInit, Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpHeaders } from '@angular/common/http';
import { UserService } from 'app/auth/_services';
import * as _ from 'lodash';
import { SP_DOCUMENTS_COLUMNS } from '../../constants/sp-documents-columns.constant';
import { ExchangeSpListingFilter } from 'app/theme/pages/finance/exchange/exchange-sp-listing/exchangeSpListing.model';
import { IGridColumn, IGridRow } from 'app/shared/components/cmx-custom-grid/interfaces';
import { IDocumentFile } from 'app/theme/pages/finance/interfaces/exchange.interface';
import { DownloadService } from '#services/_download/download.service';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { NotificationService } from '#services/_notification/notification.service';
import { UtilsService } from '#services/_utils/utils.service';
import { DocumentService } from 'app/modules/documents/pages/document/services/document.service';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';
import { ExchangeSpListingService } from 'app/theme/pages/finance/exchange/services/exchange-sp-listing.service';

@Component({
    selector: 'sp-documents',
    templateUrl: './sp-documents.component.html',
    styleUrls: ['./sp-documents.component.scss'],
})
export class SpDocumentsComponent implements AfterContentInit {
    currentUser = this._userService.getCurrentUser();
    subscription;

    listAllSps = [];
    contractList = [];
    contractSearch = '';
    indexContract: number;
    asyncDocuments = true;
    asyncPdf = true;
    asyncHistoric = true;
    pdfSubscribe;
    pdfData;
    showInput = false;
    status;
    documentStatus;
    filterState: ExchangeSpListingFilter = this._filterStateService.getExchangeSpListingFilter();
    historicList = [];
    docToDelete;
    hasExchangeContract:boolean = false;
    docExchangeContractName:string;
    fileToUpload:IDocumentFile = null;
    impNumber = null;

    showApprovedModal: boolean = false;
    showUndoApprovedModal: boolean = false;

    initialGridLoading = true;
    gridColumns: IGridColumn[] = _.cloneDeep(SP_DOCUMENTS_COLUMNS);
    gridRows: IGridRow[] = [];

    readonly documentTypes = [
        {name:'Conhecimento de Embarque', deletable: false},
        {name:'Commercial Invoice', deletable: false},
        {name:'Proforma', deletable: false},
        {name:'Comprovante de Importação', deletable: false},
        {name:'DI', deletable: false},
        {name:'D.I', deletable: false},
        {name:'Declaração de Importação', deletable: false},
        {name:'Canal bancário', deletable: false},
        {name:'Contrato de Câmbio', deletable: true},
    ]

    constructor(
        public sanitizer: DomSanitizer,
        public spListingService: ExchangeSpListingService,
        private _documentService: DocumentService,
        private _userService: UserService,
        private _customDialogService: CustomDialogService,
        private _downloadService: DownloadService,
        private _utilsService: UtilsService,
        private _notificationService: NotificationService,
        private _filterStateService: FilterStateService,
    ) {
        const imp = this.spListingService.getSpDocuments();
        this.impNumber = imp[0].value;
    }

    ngAfterContentInit() {
        this.getDocuments();
        this.getDocumentsStatus();
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.asyncDocuments = true;
    }

    getDocuments() {
        this.asyncDocuments = true;
        this.hasExchangeContract = false;
        this.docExchangeContractName = null;
        this.subscription = this._documentService.getAttachedFilesPackage(this.impNumber).subscribe((data:any[]) => {
            const rows = [];

            data.forEach((d, index) => {
                d.groupDocumentType.forEach(doc => {
                    if(doc.documents.length > 0){
                        doc.documents.forEach(docType => {
                            rows.push({
                                type: doc.documentType,
                                ...docType
                            });
                        });
                    }
                });
                d.groupDocumentTypeInternal.forEach(doc => {
                    if(doc.documents.length > 0){
                        doc.documents.forEach(docType => {
                            rows.push({
                                type: doc.documentType,
                                ...docType
                            });
                        });
                    }
                });
                d.groupDocumentTypeOthers.forEach(doc => {
                    if(doc.documents.length > 0){
                        doc.documents.forEach(docType => {
                            rows.push({
                                type: doc.documentType,
                                ...docType
                            });
                        });
                    }
                });
            });

            this.gridRows = rows;
            this.asyncDocuments = false;
            this.initialGridLoading = false;
        }, (error) => {
            this.gridRows = [];
            this._utilsService.errorHandler(error, 'Documentos');
            this.asyncDocuments = false;
        });
    }

    getDocumentsStatus(){
        this.spListingService.getDetails().subscribe((res) => {
            this.documentStatus = res.documentaryAnalysisStatus;
            this.status = res.status;
        });
    }

    getHistoricList() {
        this.asyncHistoric = true;
        this.historicList = [];
        this.subscription = this.spListingService.getHistoricList().subscribe((res) => {
            this.historicList = res;
            this.asyncHistoric = false;
        }, (error) => {
            this.asyncHistoric = false;
            this._utilsService.errorHandler(error, 'Histórico Documental');
        });
    }

    getHistoricalStatus(status) {
        switch (status) {
            case 'Aprovado':
            return { status: 'Análise Documental Aprovada', icon: 'done' }
            case 'A Analisar(Análise Documental Revertida)':
            return { status: 'Análise Documental Revertida', icon: 'undo' }
            case 'A Analisar(Alteração Documental)':
            return { status: 'Alteração Documental Realizada', icon: 'task' }
            case 'Em Retificação':
            return { status: 'Análise Documental Solicitada', icon: 'sim_card_alert' }
            default:
            return { status: '', icon: '' }
        }
    }

    onCellClick(event) {
        if (event.column == 'download') {
            this.downloadDocument(event.item);
        } else if (event.column == 'view') {
            this.showPDF(event.item);
        }
    }

    showPDF(document) {
        this._customDialogService.open('sp-documents-cmx-custom-dialog-view');

        const splittedDocName = document.aliasDocumentName.split('.');
        const documentType = splittedDocName[splittedDocName.length - 1];
        if (documentType.toLowerCase() === 'pdf') {
            if (this.pdfSubscribe) {
                this.pdfSubscribe.unsubscribe();
            }
            this.pdfData = null;
            this.asyncPdf = true;
            this.pdfSubscribe = this._documentService.getPDF(document.documentName).subscribe(
                (response) => {
                    this.pdfData = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(response));
                    this.asyncPdf = false;
                }, (error) => {
                    this.pdfData = 'noPdf';
                    this.asyncPdf = false;
                }
            );
        } else {
            this.pdfData = 'noPdf';
            this.asyncPdf = false;
        }
    }

    downloadDocument(document) {
        const options = this._downloadService.getSourceOptions();
        options.headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        const url = this._documentService.exportSingleDocument(
            document.documentName,
            options,
            this.currentUser.has('INTERNAL_FILTERS')
        );
        this._downloadService.downloadFrom(url, `${document.documentDescription}`).subscribe(
            (error) => {
                this._utilsService.errorHandler(error, 'GED');
            }
        );
    }

    confirmUndoApproval() {
        this.closeConfirmationModal();
        this.subscription = this.spListingService.undoApproval().subscribe(() => {
            this._reloadGridLine();
            this.spListingService.dataChanged = true;
            this._notificationService.openNotification('success', 'Aprovação desfeita com sucesso', 'Desfazer Aprovação');
        }, (error) => {
            this._utilsService.errorHandler(error, 'Desfazer Aprovação');
        });
    }

    confirmReview() {
        this.closeConfirmationModal();
        this.subscription = this.spListingService.approveDocument().subscribe((res) => {
            this._reloadGridLine();
            this._notificationService.openNotification('success', 'Aprovada com sucesso', 'Análise Documental Aprovada');
        }, (error) => {
            this._utilsService.errorHandler(error, 'Análise Documental Aprovada');
        });
    }

    openConfirmationModal(){
        this.closeConfirmationModal();
        if (this.documentStatus != 'Aprovado') {
            this.showApprovedModal = true;
        } else {
            this.showUndoApprovedModal = true;
        }
    }

    openLogModal() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.closeConfirmationModal();
        this.getHistoricList();
        this._customDialogService.open('sp-documents-cmx-custom-dialog-log');
    }

    closeConfirmationModal() {
        this.showUndoApprovedModal = false;
        this.showApprovedModal = false;
    }

    private _reloadGridLine() {
        this.subscription = this.spListingService.getDetails().subscribe(
            (selectedSp) => {
                this.documentStatus = selectedSp.documentaryAnalysisStatus;
                this.status = selectedSp.status;

                const previousIndex = this.spListingService.getSpPreviousGridRows().findIndex((sp) => {
                    return sp.paymentRequestNumber === selectedSp.paymentRequestNumber && sp.importationNumber === selectedSp.importationNumber;
                });

                this.spListingService.getSpPreviousGridRows()[previousIndex] = _.cloneDeep(selectedSp);

                this.spListingService.setSpGridRows([{
                    ...selectedSp,
                    selectBlocked: true,
                }]);
            }
        );
    }
}
