import { CommonModule, SlicePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { BooleanYesOrNoPipe } from './boolean-yes-or-no.pipe';
import { CurrencyType } from './currency-type.pipe';
import { FormatBytes } from './format-bytes.pipe';
import { ValueOrDashPipe } from './value-or-dash.pipe';
import { ValueOrNaPipe } from './value-or-na.pipe';
import { ValuePtBrPipe } from './value-pt-br.pipe';
import { KeyValuePipe } from './key-value.pipe';

import { CmxAmountPipe } from './amount.pipe';
import { CmxCurrencyPipe } from './currency.pipe';
import { CmxMaskPipe } from './mask.pipe';
import { CmxValidValuePipe } from './valid-value';
import { SearchFilterPipe } from './search-filter.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        BooleanYesOrNoPipe,
        CurrencyType,
        CmxAmountPipe,
        CmxValidValuePipe,
        CmxMaskPipe,
        CmxCurrencyPipe,
        FormatBytes,
        KeyValuePipe,
        ValueOrDashPipe,
        ValueOrNaPipe,
        ValuePtBrPipe,
        SearchFilterPipe
   ],
    exports: [
        BooleanYesOrNoPipe,
        CurrencyType,
        CmxAmountPipe,
        CmxValidValuePipe,
        FormatBytes,
        KeyValuePipe,
        ValueOrDashPipe,
        ValueOrNaPipe,
        ValuePtBrPipe,
        SearchFilterPipe,
    ],
    providers: [
        CmxMaskPipe,
        SlicePipe,
        CmxCurrencyPipe,
    ],
})
export class PipesModule { }
