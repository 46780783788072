import { _applyPosFormatters } from "./pos-formatters/pos-formatters";
import { textFormatter } from "./text.formatter";

export function multiButtonsFormatter(row, cell, value, columnDef, dataContext) {
    if (!Array.isArray(value)) {
        return textFormatter(row, cell, value, columnDef, dataContext);
    }
    let labelText;
    let buttons = '';
    if (columnDef?.formatterData?.label && typeof value[0] === 'string') {
        labelText = value[0];
    }
    const type = columnDef.formatterData && columnDef.formatterData.type || '';
    value.forEach((button, index) => {
        if (button && button.hasOwnProperty('name')) {
            let position = value.length > 1 ? 'center' : 'alone';
            if (value.length > 1 && index === 0) {
                position = 'left';
            } else if (value.length > 1 && index === value.length - 1) {
                position = 'right';
            }
            buttons += `<span
                class="${button.code} multi-button ${position} ${type} ${
                columnDef?.formatterData?.style?.buttons ? columnDef.formatterData.style.buttons : ''
            } ${button.animate ? button.animate + '-animate' : ''} ${button.status}"
                style="background-color: ${button.status === 'disabled' ? 'grey' : button.color}; color: ${
                button.textColor ? button.textColor : 'white'
            }">
                ${button.icon ? `<span class="material-icons">${button.icon}</span>` : ''} ${button.name}
            </span>`;
        }
    });
    const contentValue = `<div class="multi-buttons-container align-${
        columnDef?.formatterData?.groupAlign ? columnDef?.formatterData?.groupAlign : 'left'
    }">${labelText ? labelText + ' ' : ''}<div class="buttons-area ${
        buttons === '' ? 'hidden' : ''
    }">${buttons}</div></div>`;
    return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
}