import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';

export const INVOICES_EXCHANGE_COLUMNS: IGridColumn[] = [
    {
        field: 'paymentTerms',
        name: 'Condição',
        width: 50,
        formatterType: 'badge',
        formatterData: {
            backgroundColor: '',
        },
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
    {
        field: 'exchangeDate',
        name: 'Câmbio',
        width: 30,
        formatterType: 'date',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
    {
        field: 'exchangeDueDate',
        name: 'Vencimento',
        width: 30,
        formatterType: 'date',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
];
