import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';

export const INVOICES_ITEMS_COLUMNS: IGridColumn[] = [
    {
        field: 'code',
        name: 'Item',
        width: 55,
        formatterType: 'badge',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
    {
        field: 'ncm',
        name: 'NCM',
        width: 50,
    },
    {
        field: 'description',
        name: 'Descrição',
        width: 120,
    },
    {
        field: 'quantity',
        name: 'Quantidade',
        width: 55,
        formatterType: 'replaceDot',
    },
    {
        field: 'weight',
        name: 'Peso',
        width: 55,
        formatterType: 'replaceDot',
    },
    {
        field: 'manufacturer',
        name: 'Fabricante',
        width: 110,
        formatterData: {
            singleField: 'name',
        },
    },
    {
        field: 'price',
        name: 'Valor',
        width: 60,
        formatterType: 'currency',
        headerCssClass: 'grid-header align-right',
        cssClass: 'grid-cell align-right',
    },
    {
        field: 'totalPrice',
        name: 'Valor Total',
        width: 70,
        formatterType: 'currency',
        headerCssClass: 'grid-header align-right',
        cssClass: 'grid-cell align-right',
    },
];
