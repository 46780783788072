import { Component, EventEmitter, Input, Output } from '@angular/core';

type BadgeType = 'default' | 'secondary' | 'success' | 'danger' | 'info' | 'warning';

@Component({
  selector: 'cmx-badge',
  template: ` <div class="badge" [ngClass]="type">
    <ng-content></ng-content>
    <ng-container *ngIf="action"><i [ngClass]="'icon-badge fa ' + icon" (click)="handleClick()"></i></ng-container>
  </div>`,
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
  @Input() type: BadgeType = 'default';
  @Input() action: boolean = false;
  @Input() icon: string = 'fa-times';

  @Output('onClick')
  onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  handleClick() {
    this.onClick.emit(true);
  }
}
