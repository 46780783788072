import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { INewTrafficVesselsFilter } from './interface/newTraffic-vessels.interface';
import moment from 'moment';

export class NewTrafficMapFilter {

  private _userCacheService = AppModule.injector.get(UserCacheService);
  private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

  private _initialTrafficVesselsFilter: INewTrafficVesselsFilter = <INewTrafficVesselsFilter>{};
  private _trafficVesselsFilter: INewTrafficVesselsFilter = <INewTrafficVesselsFilter>{
      search: '',
      clientGroups: [],
      startDate: moment().format('DD/MM/YYYY'),
      endDate: moment().add(30, 'day').format('DD/MM/YYYY'),
      status: [],
  };

  constructor(filter?: NewTrafficMapFilter) {
    this._trafficVesselsFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'trafficVesselsFilter', this._trafficVesselsFilter, filter);
    this._initialTrafficVesselsFilter = _.cloneDeep(this._trafficVesselsFilter);
  }

  getSearch(): string { return this._trafficVesselsFilter.search }
  getClientGroups(): any[] { return this._trafficVesselsFilter.clientGroups }
  getStartDate(): string { return this._trafficVesselsFilter.startDate }
  getEndDate(): string { return this._trafficVesselsFilter.endDate }
  getStatus(): object[] { return this._trafficVesselsFilter.status }

  setSearch(search): void { this._trafficVesselsFilter.search = search }
  setClientGroups(clientGroups): void { this._trafficVesselsFilter.clientGroups = clientGroups }
  setStartDate(startDate): void { this._trafficVesselsFilter.startDate = startDate }
  setEndDate(endDate): void { this._trafficVesselsFilter.endDate = endDate }
  setStatus(status): void { this._trafficVesselsFilter.status = status }

  updateUserPreferences() {
    this._initialTrafficVesselsFilter = this._userPreferencesService.verifyFilterChanges(
    this._initialTrafficVesselsFilter, this._trafficVesselsFilter, 'trafficVesselsFilter', this._userCacheService.getUserPreferences().id);
  }

  getRequest(full?) {
    this._initialTrafficVesselsFilter = this._userPreferencesService.verifyFilterChanges(
    this._initialTrafficVesselsFilter, this._trafficVesselsFilter, 'trafficVesselsFilter', this._userCacheService.getUserPreferences().id);
    const headers = new HttpHeaders({
      "X-Requested-Client-Groups": full ? '' : this.getClientGroups().map((c) => c["id"]).join(","),
    });
    let params = new HttpParams();
    params = params.append("statusImp", 'TRANSITO');
    return { params, headers };
  }
  public getRequestAirplane() {
    const headers = new HttpHeaders({"X-Requested-Client-Groups": this.getClientGroups().map((c) => c["id"]).join(",")});
    return { headers };
  }
}
