import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { Helpers } from '../../helpers';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class LogoutComponent implements OnInit {

    constructor(
        private router: Router,
        private authService: AuthenticationService,
    ) {
    }

    public ngOnInit(): void {
        Helpers.setLoading(true);
        this.authService.logout();
        this.router.navigate(['/login']);
    }
}
