<div class="cmx-summary-line" [style.margin-left]="marginLeft">
    <span *ngIf="switchList.length" id="cmx-summary-line-switch" class="material-icons"
    (click)="changeSwitch()" [tooltip]="selectedSwitch">sync</span>
    <span *ngIf="title" class="title">{{ title }}</span>
    <div *ngFor="let data of dataList; let i = index" class="data" [style.font-size]="fontSize">
        <strong>{{ data?.title }}: </strong>
        <span class="value">{{ data?.value }}</span>
        <span *ngIf="data.tooltip" class="material-icons extra-info" [title]="data.tooltip">info</span>
        <div *ngIf="i !== dataList.length -1" class="separator">•</div>
    </div>
</div>
