<div class="page-title-identifier">Painel ADM</div>
<div class="admMenu">
    <ul class="admMenuItens">
        <li *ngIf="currentUser$.has('API_ADMIN_PERMISSIONS') || currentUser$.has('API_ADMIN_PROFILES') || currentUser$.has('API_ADMIN_USERS')" class="accountControl">Controle de Contas</li>
        <div class="accountControlItem">
            <li *ngIf="currentUser$.has('API_ADMIN_PERMISSIONS')" (click)="openAccountPermission()" class="menuItem"><i class="flaticon-user-settings"></i>Permissões</li>
            <li *ngIf="currentUser$.has('API_ADMIN_PROFILES')" (click)="openAccountProfile()" class="menuItem"><i class="flaticon-user-ok"></i>Perfil</li>
            <li *ngIf="currentUser$.has('API_ADMIN_USERS')" (click)="openAccountUser()" class="menuItem"><i class="flaticon-user-add"></i>Usuários</li>
        </div>
        <li *ngIf="currentUser$.has('API_ADMIN_CLIENTS_GROUPS') || currentUser$.has('API_ADMIN_CLIENTS_GROUPS_UPDATE')  || currentUser$.has('API_ADMIN_TRADERS_DIRECTORS')" class="accountControl">Controle Hierárquico</li>
        <div class="accountControlItem">
            <li *ngIf="currentUser$.has('API_ADMIN_CLIENTS_GROUPS') || currentUser$.has('API_ADMIN_CLIENTS_GROUPS_UPDATE')" (click)="openDomainClientGroup()" class="menuItem"><i class="comexport-group"></i>Clientes / Grupos</li>
            <li *ngIf="currentUser$.has('API_ADMIN_TRADERS_DIRECTORS')" (click)="openDomainTraderDirector()" class="menuItem"><i class="comexport-boss"></i>Traders / Diretores</li>
        </div>
        <li class="accountControl">Controle de Versão</li>
        <div class="accountControlItem">
            <li *ngIf="currentUser$.has('API_ADMIN_CLIENTS_GROUPS')" (click)="openReleases()" class="menuItem" style="display: flex"><i class="material-icons">new_releases</i>Releases</li>
        </div>
    </ul>
</div>

<div class="admPanel">
    <div *ngIf="currentUser$.has('API_ADMIN_PERMISSIONS') && currentUser$.has('API_ADMIN_PROFILES') && currentUser$.has('API_ADMIN_USERS')" id="accounts" class="admItem">
        <ng-template #loader>
            <loader *ngIf="!accountComponent.asyncPermissions || !profileComponent.asyncProfile || !userComponent.asyncUser"></loader>
        </ng-template>
        <div *ngIf="accountComponent.asyncPermissions && profileComponent.asyncProfile && userComponent.asyncUser; else loader" id="accountsContent">
            <div class="topItem">
                <div class="admItemPortion">
                    <p id="accountsTitle">Permissões</p>
                    <span class="accountsNumbers permission">{{accountComponent.permissions.length}}</span>
                </div>
                <div class="admItemPortion">
                    <p id="accountsTitle">Perfis</p>
                    <span class="accountsNumbers profile">{{profileComponent.profiles.length}}</span>
                </div>
                <div class="admItemPortion">
                    <p id="accountsTitle">Usuários</p>
                    <span class="accountsNumbers user">{{userComponent.users.length}}</span>
                </div>
            </div>
            <!-- <div class="middleItem">
                <p id="accountsTitle">Adicionados este Mês</p>
            </div>
            <div class="bottomItem">
                <div class="admItemPortion">
                    <span class="permission">0</span>
                </div>
                <div class="admItemPortion">
                    <span class="profile">0</span>
                </div>
                <div class="admItemPortion">
                    <span class="user">0</span>
                </div>
            </div> -->
        </div>
    </div>
    <div class="admItem">
    </div>
    <div class="admItem">
    </div>
    <div class="admItem">
    </div>
</div>

<!-- Account Permission Modal -->
<adm-account-permission class="admModal hidden" id="accountPermissionModal" (onClose)="closeAdmModal()"> </adm-account-permission>

<!-- Account Profile Modal -->
<adm-account-profile class="admModal hidden" id="accountProfileModal" (onClose)="closeAdmModal()" (onUpdate)="refreshProfiles($event)" (onSelectUser)="refreshSelectedUser($event)"> </adm-account-profile>

<!-- Account User Modal -->
<adm-account-user class="admModal hidden" id="accountUserModal" (onClose)="closeAdmModal()"> </adm-account-user>

<!-- Domain Trader Director Modal -->
<adm-domain-trader-director class="admModal hidden" id="domainTraderDirectorModal" (onClose)="closeAdmModal()"> </adm-domain-trader-director>

<!-- Domain Client Group Modal -->
<adm-domain-client-group class="admModal hidden" id="domainClientGroupModal" (onClose)="closeAdmModal()"> </adm-domain-client-group>

<!-- Release Modal -->
<release-crud class="admModal hidden" id="releasesModal" (onClose)="closeAdmModal()"> </release-crud>
