import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';
import { ImportDetailsService } from '../import-details.service';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';
import { IMP_ORDERS_COLUMNS } from './constants/imp-orders-columns.constants';
import { IMP_MANUFACTURERS_COLUMNS } from './constants/imp-manufacturers-columns.constants';
import { ImportDetailItem } from '../import-details-item';
import { Router } from '@angular/router';

@Component({
    selector: 'app-import-details-imp',
    templateUrl: './imp.component.html',
    styleUrls: ['./imp.component.scss'],
    encapsulation: ViewEncapsulation.None
  })
export class ImpComponent extends ImportDetailItem implements OnInit {
    asyncDetail = !this.showLoader;
    showPOModal = false;

    dataLoaded;
    impDetails;
    ordersList;
    poNumber;
    poDetails;
    purchaseOrderDate;
    proformaDate;

    manufecturersList;

    gridOrderColumns: IGridColumn[] = IMP_ORDERS_COLUMNS;
    gridManufecturersColumns: IGridColumn[] = IMP_MANUFACTURERS_COLUMNS;

    constructor(
        private _importDetailsService: ImportDetailsService,
        private _customDialogService: CustomDialogService,
        protected _router: Router,
    ) {
        super(_router);
    }

    ngOnInit() {
        (this.showLoader) ? 
            setTimeout(() => {this.loadDetails();}, 1000) : 
            this.loadDetails();
    }

    loadDetails() {
        this.asyncDetail = false;
        this.dataLoaded = this._importDetailsService.getDetails();
        if(this.dataLoaded) {

            this.impDetails = [
                {
                    title: 'Cliente',
                    value: this.dataLoaded.clientName,
                },
                {
                    title: 'Ref. Cliente',
                    value: this.dataLoaded.clientReference.replace(/_/g, ' '),
                },
                {
                    title: 'Descrição',
                    value: this.dataLoaded.description.replace(/_/g, ' '),
                },
                {
                    title: 'Modalidade',
                    value: this.dataLoaded.operationModality.replace(/_/g, ' '),
                    permission: 'INTERNAL_FILTERS',
                },
            ];

            this.ordersList = this.dataLoaded.orders;
            this.purchaseOrderDate = this.dataLoaded.purchaseOrderDate;
            this.proformaDate = this.dataLoaded.proformaDate;
        }

        this.asyncDetail = true;
    }

    onCellClick({ item }) {
        this.poNumber = item.orderNumber;
        this.manufecturersList = item.manufacturers;

        this.poDetails = [
            {
                title: 'Ref. Cliente',
                value: item.clientReference,
            },
            {
                title: 'Fornecedor',
                value: item.vendor.name,
            },
            {
                title: 'Fabricante',
                value: this._formattermManufacturers(item.manufacturers),
                formatter: 'multiple'
            },
            {
                title: 'Incoterm',
                value: item.incoterm,
            },
            {
                title: 'Data Recebimento',
                value: this.purchaseOrderDate,
            },
            {
                title: 'Data Pedido Comexport',
                value: item.registrationDate,
            },
            {
                title: 'Data Proforma',
                value: this.proformaDate,
            },
            {
                title: 'Carta de Crédito',
                value: item.letterOfCredit === '1' ? 'Sim' : item.letterOfCredit === '2' ? 'Não' : null,
            },
        ];

        this._customDialogService.open('import-details-imp-po-dialog');

        setTimeout(() => {
            this.showPOModal = true;
        }, 100);
    }

    private _formattermManufacturers(manufacturers) {
        let newList = [];
        manufacturers.forEach((item) => {
            newList.push(item.name);
        });

        return newList;
    }
}
