import { NotificationService } from '#services/_notification/notification.service';
import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PaginationComponent {
    @Input() numberOfPages;
    @Input() queryId;
    @Input() maxPages: number = 9;
    @Input() showPagesText: boolean = true;
    @Input() initialPage?: number;
    @Input() small?: boolean = false;
    @Input() skeletonLoader?: boolean = false;
    @Input() itensByPage?: number;
    @Input() maxItensByPage: number = 200;
    @Input() minItensByPage: number = 10;
    @Output() onPageChange = new EventEmitter<number>();
    @Output() onItensByPageChange = new EventEmitter<number>();

    showItensByPage: boolean;
    arrayPages: Array<{ pageNumber: number, selected: boolean }>;
    viewPages: Array<{ pageNumber: number, selected: boolean }>;
    selectedPage: number;

    constructor(private _notificationService: NotificationService) { }

    ngAfterContentInit() {
        this.showItensByPage = this.itensByPage ? true : false;
        this.selectedPage = this.initialPage ? this.initialPage : 1;
        this.setViewPages();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            if ((changes.queryId && !changes.queryId.firstChange) || !changes.queryId) {
                this.selectedPage = 1;
                this.changePage(1, 'reset');
            }
            if (changes.initialPage && changes.initialPage.currentValue) {
                this.selectedPage = changes.initialPage.currentValue;
                this.setViewPages();
            }
        }
    }

    changeItensByPage(input) {
        const value = input.target.value;
        if (value) {
            if (value > this.maxItensByPage) {
                this._notificationService.openNotification('error', `O valor máximo de itens por página é ${this.maxItensByPage}.`);
                this.itensByPage = this.maxItensByPage;
                this.onItensByPageChange.emit(this.maxItensByPage);
            } else if (value < this.minItensByPage) {
                this._notificationService.openNotification('error', `O valor mínimo de itens por página é ${this.minItensByPage}.`);
                this.itensByPage = this.minItensByPage;
                this.onItensByPageChange.emit(this.minItensByPage);
            } else {
                this.onItensByPageChange.emit(value);
            }
        }
    }

    setViewPages() {
        this.arrayPages = Array.apply(null, { length: this.numberOfPages }).map(
            Number.call,
            Number,
        ).map((e) => {
            return {
                pageNumber: e + 1,
                selected: e + 1 === this.selectedPage,
            };
        });
        this.viewPages = this.arrayPages.slice(0, this.maxPages);
    }

    changePage(i, el) {
        this.setViewPages();
        const previousPage = this.findPage(this.selectedPage);
        this.selectedPage = Math.min(Math.max(parseInt(i, 10), 1), this.numberOfPages);

        const newPage = this.findPage(this.selectedPage);
        previousPage.selected = false;
        newPage.selected = true;

        if (this.numberOfPages >= this.maxPages) {
            const sliceStart = Math.max(this.selectedPage - 5, 0);
            const sliceEnd = Math.min(sliceStart + Number(this.maxPages), this.numberOfPages);
            if (sliceEnd - sliceStart === this.maxPages) {
                this.viewPages = this.arrayPages.slice(sliceStart, sliceEnd);
            } else {
                this.viewPages = this.arrayPages.slice(sliceEnd - Number(this.maxPages), sliceEnd);
            }
        } else {
            this.viewPages = this.arrayPages.slice(0, this.maxPages);
        }

        if (previousPage.pageNumber === this.selectedPage) {
            return;
        }

        this.onPageChange.emit(this.selectedPage);
    }

    private findPage(pageNumber: number) {
        return this.arrayPages.find((e) => e.pageNumber === pageNumber);
    }

    trackByFn(index: number, _item: any) {
        return index;
    }
}
