<div class="trackingTimelineContainer">
    <ng-template #trace><td>-</td></ng-template>
    <div class="trackingTimelineContent">
        <loader *ngIf="trackingFilterState.getAsync()"></loader>
        <div *ngIf="!trackingFilterState.getAsync() && trackingFilterState.getTimeline().length === 0" class="placeContainer noContent">
            Não existem registros para esse navio.
        </div>
        <div *ngFor="let place of trackingFilterState.getTimeline(); trackBy: trackByFn" class="placeContainer">
            <div class="titleContainer">
                <div class="title left">
                    <i class="comexport-time"></i>
                    <span>Planejado</span>
                </div>
                <div class="title">
                    <span class="main-title">{{ place?.location }}</span>
                    <div *ngIf="getFlag(place.country)">
                        <img src="{{ getFlag(place.country) }}" alt="">
                    </div>
                </div>
                <div class="title right">
                    <i class="comexport-route"></i>
                    <span>Realizado</span>
                </div>
            </div>
            <div class="eventContainer">
                <div class="verticalLine">
                    <hr class="vertical">
                </div>
                <div *ngFor="let event of place.events; trackBy: trackByFn" class="eventContent">
                    <div class="eventMoment">
                        <span *ngIf="event.eventPlannedTime" class="eventTitle">{{event?.eventName}}</span>
                        <span *ngIf="event.eventPlannedTime; else trace" class="eventDates">
                            <span>{{moment(event?.eventPlannedTime, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY')}}</span>
                            <span>{{moment(event?.eventPlannedTime, 'DD/MM/YYYY HH:mm:ss').format('HH:mm:ss')}}</span>
                        </span>
                    </div>
                    <div class="eventType">
                        <div class="ball">
                            <span class="material-icons">{{ event.eventTransportType === 'Truck' ? 'local_shipping' : event.eventTransportType === 'Ocean' ? 'directions_boat' : '' }}</span>
                        </div>
                    </div>
                    <div class="eventMoment">
                        <span *ngIf="event.eventActualTime" class="eventTitle">{{event?.eventName}}</span>
                        <span *ngIf="event.eventActualTime; else trace" class="eventDates">
                            <span>{{moment(event?.eventActualTime, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY')}}</span>
                            <span>{{moment(event?.eventActualTime, 'DD/MM/YYYY HH:mm:ss').format('HH:mm:ss')}}</span>
                        </span>
                    </div>
                    <hr class="bottom">
                </div>
            </div>
        </div>
    </div>
</div>
