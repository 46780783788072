<div class="moveBtn"><div class="moveLine"></div></div>
<div *ngIf="asyncLogisticCosts"><loader></loader></div>

<div *ngIf="currentUser$ | async as user" class="header-container">
    <div class="title-information">
        <span class="analyticsName">Custos Logísticos</span>
        <cmx-help-modal
            help-title="Custos Logísticos"
            [help-description]="helpDescription"
        ></cmx-help-modal>
        <h5>Custos Logísticos</h5>
        <div class="filter-container" style="z-index: 2"
        [style.margin-left]="user.has('ROUTE_AUTOMOTIVE') && !user.has('AUTOMOTIVE_USER') ? '45px' : '0px'">
            <cmx-switch
                [options]="filterTypes"
                [selected]="filterState.getType()"
                (onSelect)="toggleTypeSwitch($event)"
            ></cmx-switch>
        </div>
    </div>
</div>

<cmx-marker-filter
    *ngIf="currentUser$ | async as user"
    [filters]="periodTypes"
    [preSelected]="preSelectedPeriod"
    type="period"
    [multiple]="false"
    [hasSelectAll]="false"
    [leftDistance]="user.has('ROUTE_AUTOMOTIVE') && !user.has('AUTOMOTIVE_USER') ? 500 : 455"
    (onSelected)="changeDateParam($event)"
></cmx-marker-filter>

<div *ngIf="currentUser$ | async as user" class="filterContainer">
    <div *ngIf="user.has('ROUTE_AUTOMOTIVE') && !user.has('AUTOMOTIVE_USER')"
        title="Modo Automotivo: {{ filterState.getAutomotiveMode() }}"
        class="filterMode"
        [ngClass]="{'clicked': filterState.getAutomotiveMode() === 'Ligado'}"
        (click)="toggleFilterMode()">
        <div class="icon-align"><em class="material-icons">directions_car</em></div>
    </div>
    <div id="searchLine" class="reduzed">
        <div class="input-group">
            <cmx-dropdown
                *ngIf="isInternalUser && clientOptions.length > 1"
                [styles]="{ width: '180px' }"
                name="clients"
                [data]="clientOptions"
                [ngModel]="filterState.getClientGroups()"
                (ngModelChange)="filterState.setClientGroups($event)"
                [settings]="dropdownSettings.clientGroups"
                (onChange)="loadLogisticCosts(periodType)"
            ></cmx-dropdown>
            <span class="input-group-addon" style="margin: 0 -6px 0 -15px;"></span>
        </div>
        <div class="input-group">
            <cmx-dropdown
                [styles]="{ width: '180px' }"
                name="costs"
                [data]="costsOptions"
                [ngModel]="filterState.getLogisticCosts()"
                (ngModelChange)="filterState.setLogisticCosts($event)"
                [settings]="dropdownSettingsCosts"
                (onChange)="loadLogisticCosts(periodType)"
            ></cmx-dropdown>
            <span class="input-group-addon" style="margin: 0 -6px 0 -15px;"></span>
        </div>

        <div class="input-group">
            <cmx-dropdown
                [styles]="{ width: '160px' }"
                name="subsidiaryNames"
                [data]="subsidiaryNames"
                [settings]="dropdownSettings.subsidiaryNames"
                [ngModel]="filterState.getSubsidiaryNames()"
                (ngModelChange)="filterState.setSubsidiaryNames($event)"
                (onChange)="loadLogisticCosts(periodType)"
            ></cmx-dropdown>
        </div>

        <div class="input-group">
            <span class="input-group-addon"></span>
            <cmx-dropdown
                [styles]="{ width: '125px' }"
                name="modal"
                [data]="modalOptions"
                [ngModel]="filterState.getModal()"
                (ngModelChange)="filterState.setModal($event)"
                [settings]="dropdownSettings.modals"
                (onChange)="loadLogisticCosts(periodType)"
            ></cmx-dropdown>
        </div>

        <div class="input-group">
            <span class="input-group-addon"></span>
            <cmx-filter-switch
                [values]="visualSwitch.values"
                [inputIndex]="visualSwitch.values.indexOf(filterState.getVisual())"
                (onSwitch)="toggleVisualSwitch($event)"
            ></cmx-filter-switch>
        </div>

        <div *ngIf="user.has('AUTOMOTIVE_USER') || filterState.getAutomotiveMode() === 'Ligado'" class="input-group">
            <span class="input-group-addon" style="margin-right: -25px"></span>
            <div [tooltip]="'Armazenagem EADI'" [ngClass]="{'clicked': filterState.getStorageToogle() === 'Considerando' }"
                class="storageToogle pointer" (click)="toggleStorage()">
                <div style="pointer-events: none"><em class="material-icons">widgets</em></div>
            </div>
        </div>
    </div>
</div>

<div id="summaryLine">
    <cmx-summary-line
        [dataList]="summaryLine"
    ></cmx-summary-line>
</div>

<div class="chart" id="logisticCosts"></div>
<div *ngIf="!asyncLogisticCosts && chartData === undefined && !manualSearch" class="reloadContainer">
    <div class="reloadContent">
        <i (click)="loadLogisticCosts(periodType)" class="material-icons">refresh</i>
    </div>
</div>
<div *ngIf="manualSearch" class="reloadContainer">
    <div class="reloadContent">Por favor, selecione um cliente</div>
</div>
