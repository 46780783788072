import { UtilsService } from '#services/_utils/utils.service';
import { Component, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'cmx-skeleton-loader',
    templateUrl: './cmx-skeleton-loader.component.html',
    styleUrls: ['./cmx-skeleton-loader.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SkeletonLoaderComponent {

    @Input() type: 'grid' | 'pagination' | 'filterLine' | 'pageWrapperTopButtons' 
        | 'pageWrapperRightButtons' | 'pageWrapperTitle' | 'form' | 'listing' | 'markerFilter' | 'summaryLine';

    skeletonLoaderId = this._utilsService.generateGUID('skeleton-loader');

    // grid
    gridRows: number = 0;
    rowHeight: number = 25;

    constructor(private _utilsService: UtilsService) { }

    ngAfterViewInit(): void {
        if (this.type === 'grid') this._defineGridRows();
    }

    private _defineGridRows() {
        const gridContainer = document.getElementById(this.skeletonLoaderId);
        const gridHeight = gridContainer?.offsetHeight;
        this.gridRows = Math.floor(gridHeight / (this.rowHeight + 10));
    }

}
