import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'valueOrNa',
})
export class ValueOrNaPipe implements PipeTransform {
    transform(input: string): string {
        return input || 'N/A';
    }
}
