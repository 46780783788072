import { HttpHeaders, HttpParams } from '@angular/common/http';
import moment from 'moment';
import { IChannelStatusFilter } from './interface/channel-status.interface';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { AppModule } from 'app/app.module';
import * as _ from 'lodash';

export class ChannelStatusFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initiaChannelStatusFilter: IChannelStatusFilter = <IChannelStatusFilter>{};
    private _channelStatusFilter: IChannelStatusFilter = <IChannelStatusFilter>{

        clientGroups: [],
        status: [{code: 'yellow', name: 'Amarelo'},
        { code: 'red', name: 'Vermelho' }, { code: 'grey', name: 'Cinza' }],
        urfs: [],
        type: 'history',
        televisionMode: false
    };

    private startDate;
    private endDate;
    private offset: number;
    private limit: number;


    constructor(filter?: ChannelStatusFilter) {

        this._channelStatusFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'channelStatusFilter', this._channelStatusFilter, filter);
        this._initiaChannelStatusFilter = _.cloneDeep(this._channelStatusFilter);

        this.startDate = filter ? filter.startDate : moment().format('MM/YYYY');
        this.endDate = filter ? filter.endDate : moment().format('MM/YYYY');
        this.offset = filter ? filter.offset : 0;
        this.limit = filter ? filter.limit : 50;
    }

    getClientGroups(): string[] { return this._channelStatusFilter.clientGroups; }
    getUrfs(): any[] { return this._channelStatusFilter.urfs; }
    getStartDate() { return this.startDate; }
    getEndDate() { return this.endDate; }
    getOffset(): number { return this.offset; }
    getLimit(): number { return this.limit; }
    getStatus() { return this._channelStatusFilter.status; }
    getType() { return this._channelStatusFilter.type; }
    getTelevisionMode() { return this._channelStatusFilter.televisionMode; }

    setUrfs(urfs): void { this._channelStatusFilter.urfs = urfs; }
    setStartDate(startDate): void { this.startDate = startDate; }
    setEndDate(endDate): void { this.endDate = endDate; }
    setOffset(offset): void { this.offset = offset; }
    setLimit(limit): void { this.limit = limit; }
    setStatus(status): void { this._channelStatusFilter.status = status; }
    setType(type): void { this._channelStatusFilter.type = type; }
    setTelevisionMode(televisionMode): void {
        this._channelStatusFilter.televisionMode = televisionMode;
        this.updatePreference();
    }

    getRequest(type) {

        this._initiaChannelStatusFilter = this._userPreferencesService.verifyFilterChanges(
            this._initiaChannelStatusFilter, this._channelStatusFilter, 'channelStatusFilter', this._userCacheService.getUserPreferences().id);

        let params = new HttpParams();
        let headers = new HttpHeaders();

        headers = headers.set('X-Requested-Client-Groups', this.getClientGroups().map((c) => c['id']).join(','));
        const newStart = moment(this.getStartDate(), 'MM/YYYY').format('YYYY-MM-01');
        const newEnd = moment(this.getEndDate(), 'MM/YYYY').add(1, 'M').add(-1, 'days').format('YYYY-MM-DD');
        params = params.append('startDate', newStart);
        params = params.append('endDate', newEnd);
        params = params.append('offset', `${ this.getOffset() * 50 }`);
        params = params.append('limit', `${ this.getLimit() }`);
        params = params.append('channelVisionType', this.getType());
        params = params.append('urfNationalizationCodes', this.getUrfs().map((c) => c['id']).join(','));
        params = params.append('sort', 'channel,asc');
        if (type === 'Desembaraço') {
            params = params.append('referenceDateType', 'clearance');
        }
        return { headers, params };
    }

    updatePreference() {
        this._initiaChannelStatusFilter = this._userPreferencesService.verifyFilterChanges(
            this._initiaChannelStatusFilter, this._channelStatusFilter, 'channelStatusFilter', this._userCacheService.getUserPreferences().id);
    }
}
