import { IGridColumn } from "app/shared/components/cmx-custom-grid/interfaces";

export const SP_EXCHANGE_BANKING_CHANNELS_COLUMNS: IGridColumn[] = [
    {
        field: 'receiver',
        name: 'Recebedor/Beneficiário',
        width: 250
    },
    {
        field: 'country',
        name: 'País Beneficiário',
        width: 250
    },
    {
        field: 'bank',
        name: 'Banco Beneficiário',
        width: 250
    },
    {
        field: 'bankCountry',
        name: 'País Banco',
        width: 250,
    },
    {
        field: 'accountIban',
        name: 'Conta Beneficiário/IBAN',
        width: 250
    },
    {
        field: 'swiftCode',
        name: 'Cód. Swift/BIC',
        width: 150
    },
    {
        field: 'abaCode',
        name: 'Cód. Aba',
        width: 150
    },
    {
        field: 'intermediaryBank',
        name: 'Banco Indermediário',
        width: 250
    },
    {
        field: 'intermediarySwift',
        name: 'Swift Indermediário',
        width: 250
    },
    {
        field: 'intermediaryCountry',
        name: 'País Indermediário',
        width: 250
    },
    {
        field: 'intermediaryAccount',
        name: 'Conta Indermediário',
        width: 250
    },
    {
        field: 'intermediaryAba',
        name: 'Aba Indermediário',
        width: 250
    },
];
