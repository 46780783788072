import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'app/shared/shared.module';
import { PreferencesModule } from 'app/theme/pages/preferences/preferences.module';
import { UserProfileDisplayComponent } from './cmx-user-profile-display.component';
import { ProfileDisplayMenuComponent } from './profile-display-menu/profile-display-menu.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    PreferencesModule
],
  declarations: [
    UserProfileDisplayComponent,
    ProfileDisplayMenuComponent,
  ],
  exports: [
    UserProfileDisplayComponent,
    ProfileDisplayMenuComponent
  ],
})

export class UserProfileDisplayModule {}
