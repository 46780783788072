import { badgeFormatter } from "./badge.formatter";
import { boldFormatter } from "./bold.formatter";
import { booleanFormatter } from "./boolean.formatter";
import { buttonFormatter } from "./button-formatter";
import { cnpjFormatter } from "./cnpj.formatter";
import { collapseFormatter } from "./collapse.formatter";
import { currencyFormatter } from "./currency.formatter";
import { dateOrBadgeFormatter } from "./date-or-badge.formatter";
import { dateFormatter } from "./date.formatter";
import { dropdownFormatter } from "./dropdown.formatter";
import { exchangeApprovalFormatter } from "./exchange-approval.formatter";
import { fullfilmentFormatter } from "./fullfilment-formatter";
import { iconFormatter } from "./icon.formatter";
import { kpiFormatter } from "./kpi-formatter";
import { multiButtonsFormatter } from "./multi-buttons.formatter";
import { multiRoundFormatter } from "./multi-round.formatter";
import { multipleFormatter } from "./multiple.formatter";
import { ncmFormatter } from "./ncm.formatter";
import { percentageFormatter } from "./percentage-formatter";
import { phoneFormatter } from "./phone.formatter";
import { preIconFormatter } from "./pre-icon.formatter";
import { replaceCommaFormatter } from "./replace-comma.formatter";
import { replaceDotFormatter } from "./replace-dot.formatter";
import { roundFormatter } from "./round.formatter";
import { slaFormatter } from "./sla.formatter";
import { statusBadgeFormatter } from "./status-badge.formatter";
import { statusFormatter } from "./status.formattter";
import { switchFormatter } from "./switch.formatter";
import { textColorFormatter } from "./text-color.formatter";
import { textStatusFormatter } from "./text-status.formatter";
import { textFormatter } from "./text.formatter";

export const FORMATTERS = {
    default: textFormatter,
    badge: badgeFormatter,
    bold: boldFormatter,
    boolean: booleanFormatter,
    button: buttonFormatter,
    cnpj: cnpjFormatter,
    collapse: collapseFormatter,
    currency: currencyFormatter,
    date: dateFormatter,
    dateOrBadge: dateOrBadgeFormatter,
    exchangeApproval: exchangeApprovalFormatter,
    fullfilment: fullfilmentFormatter,
    icon: iconFormatter,
    kpi: kpiFormatter,
    multiButtons: multiButtonsFormatter,
    multiple: multipleFormatter,
    multiRound: multiRoundFormatter,
    ncm: ncmFormatter,
    percentage: percentageFormatter,
    phone: phoneFormatter,
    preIcon: preIconFormatter,
    replaceDot: replaceDotFormatter,
    sla: slaFormatter,
    replaceComma: replaceCommaFormatter,
    round: roundFormatter,
    status: statusFormatter,
    statusBadge: statusBadgeFormatter,
    textColor: textColorFormatter,
    textStatus: textStatusFormatter,
    switch: switchFormatter,
    dropdown: dropdownFormatter,
}
