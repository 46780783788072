import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import moment from 'moment';
import * as _ from 'lodash';

import { DOWNLOAD_ACCEPTANCES } from './constants/cmx-downloads-acceptances';
import { DOWNLOAD_URLS } from './constants/cmx-downloads-urls';
import { DownloadTypes } from './types/cmx-download-types';
import { DownloadService } from '#services/_download/download.service';
import { EventSourceService } from '#services/_eventSource/eventSource.service';
import { DownloadServices } from './types/cmx-download-services';

@Component({
    selector: 'cmx-download',
    templateUrl: './cmx-download.component.html',
    styleUrls: ['./cmx-download.component.scss'],
})
export class DownloadComponent {
    @Input() types: DownloadTypes[];
    @Input() async: boolean = true;
    @Input() service: DownloadServices;
    @Input() clientGroups?: string;
    @Input() stringParams?: string;
    @Input() params?;

    @Output('onSync') onSync = new EventEmitter();

    asyncDownload: boolean = false;

    constructor(
        private http: HttpClient,
        private _downloadService: DownloadService,
        private _eventChannel: EventSourceService
    ) {}

    download(type) {
        const options = this._downloadService.getSourceOptions();
        options.headers = new HttpHeaders({
            Accept: DOWNLOAD_ACCEPTANCES[type],
        });

        if (this.async) {
            this.asyncDownload = true;
            setTimeout(() => {
                this.asyncDownload = false;
            }, 2000);

            const preparedParams = this._prepareStringParams();
            const url = `${DOWNLOAD_URLS[this.service]}${
                this._defineFirstStringParamCharacter(this.stringParams) || preparedParams
            }`;

            this._eventChannel.openEventSource(url, this.clientGroups || '', '');
        } else {
            const url = this.http.post(
                `${DOWNLOAD_URLS[this.service]}${this._defineFirstStringParamCharacter(this.stringParams)}`,
                this.params || '',
                options
            );
            this._downloadService
                .downloadFrom(url, `${this.service}-${moment().format('YYYY-MM-DD')}.${type}`)
                .subscribe(() => {
                    this.onSync.emit();
                });
        }
    }

    private _defineFirstStringParamCharacter(stringParams: string): string {
        if (stringParams && stringParams.length) {
            return stringParams.charAt(0) === '?' ? stringParams : stringParams.replace(/^.{1}/g, '?');
        } else {
            return '';
        }
    }

    private _prepareStringParams() {
        let stringParams = '';

        if (typeof this.params === 'object') {
            Object.entries(this.params).forEach((param, index) => {
                stringParams += index === 0 ? '?' : '&';
                stringParams += `${param[0]}=`;
                stringParams += Array.isArray(param[1])
                    ? param[1]?.map((item) =>
                              item.hasOwnProperty('code') ? item.code : item.hasOwnProperty('id') ? item.id : item
                          ).join(',')
                    : param[1];
            });
        }

        return stringParams;
    }
}
