import { AppModule } from 'app/app.module';
import moment from 'moment';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { ILogisticHandlingFilter } from './interface/logistic-handling.interface';

export class LogisticsHandlingFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialLogisticsHandlingFilter: ILogisticHandlingFilter = <ILogisticHandlingFilter>{};
    private _logisticsHandlingFilter: ILogisticHandlingFilter = <ILogisticHandlingFilter>{
        clientGroups: [],
        states:[],
        warehouses: [],
        type: 'state',
        typeView: 'TEUs'
    };
    private year: string;

    constructor(modalType, filter?: LogisticsHandlingFilter) {
        this._logisticsHandlingFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), `logisticsHandling${modalType}Filter`, this._logisticsHandlingFilter, filter);
        this._initialLogisticsHandlingFilter = _.cloneDeep(this._logisticsHandlingFilter);
        this.year = moment().format('YYYY');
    }

    getClientGroups() {
        return this._logisticsHandlingFilter.clientGroups;
    }
    getStates() {
        return this._logisticsHandlingFilter.states;
    }
    getWarehouses() {
        return this._logisticsHandlingFilter.warehouses;
    }
    getType() {
        return this._logisticsHandlingFilter.type;
    }
    getTypeView() {
        return this._logisticsHandlingFilter.typeView;
    }


    setClientGroups(clientGroups): void {
        this._logisticsHandlingFilter.clientGroups = clientGroups;
    }
    setStates(states): void {
        this._logisticsHandlingFilter.states = states;
    }
    setWarehouses(warehouses): void {
        this._logisticsHandlingFilter.warehouses = warehouses;
    }
    setType(type): void {
        this._logisticsHandlingFilter.type = type;
    }
    setTypeView(typeView): void {
        this._logisticsHandlingFilter.typeView = typeView.name;
    }

    public getYear(): string {
        return this.year;
    }

    public setYear(year): void {
        this.year = year;
    }

    savePreferences(modalType) {
        this._initialLogisticsHandlingFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialLogisticsHandlingFilter, this._logisticsHandlingFilter, `logisticsHandling${modalType}Filter`, this._userCacheService.getUserPreferences().id);
    }

}
