<div *ngIf="!asyncDetail" class="fullLoader">
    <loader></loader>
</div>

<div id="import-details-clearance-container" *ngIf="asyncDetail">
    <div id="import-details-clearance-content" *ngIf="dataLoaded">
        <div class="detailTitle carga">
            <img src="./assets/app/media/img/icons/carga-checked.png" alt="" />
            <span>Presença de Carga</span>
        </div>
        <cmx-assembled-data [data]="cargaDetails" [maxRowElements]="4"></cmx-assembled-data>

        <hr />

        <div class="detailTitle dta">
            <img src="./assets/app/media/img/icons/dta.png" alt="" />
            <span>DTA</span>
        </div>
        <cmx-assembled-data [data]="dtaDetails" [maxRowElements]="4"></cmx-assembled-data>

        <hr />

        <div class="detailTitle vistorias">
            <img src="./assets/app/media/img/icons/vistoria.png" alt="" />
            <span>Vistorias</span>
        </div>
        <cmx-assembled-data
            [data]="vistoriasDetails"
            [maxRowElements]="4"
            (onClick)="onPackagesClick($event)"
        ></cmx-assembled-data>

        <hr />

        <div class="d-flex">
            <div class="w-50">
                <div class="detailTitle registro">
                    <img src="./assets/app/media/img/icons/registro_da-checked.png" alt="" />
                    <span>Registro DA</span>
                </div>
                <cmx-assembled-data [data]="daRegisterDetails" [maxRowElements]="2"></cmx-assembled-data>
            </div>
            <div class="w-50">
                <div class="detailTitle desembaraco">
                    <img src="./assets/app/media/img/icons/desembaraco_da-checked.png" alt="" />
                    <span>Desembaraço DA</span>
                </div>
                <cmx-assembled-data [data]="daClearanceDetails" [maxRowElements]="2"></cmx-assembled-data>
            </div>
        </div>

        <div class="d-flex">
            <div class="w-50">
                <div class="detailTitle registro">
                    <img src="./assets/app/media/img/icons/registro-checked.png" alt="" />
                    <span>Registro DI</span>
                </div>
                <cmx-assembled-data
                    [data]="diRegisterDetails"
                    [maxRowElements]="2"
                    (onClick)="onDiClick()"
                ></cmx-assembled-data>
            </div>
            <div class="w-50">
                <div class="detailTitle desembaraco">
                    <img src="./assets/app/media/img/icons/desembaraco-checked.png" alt="" />
                    <span>Desembaraço DI</span>
                </div>
                <cmx-assembled-data
                    [data]="diClearanceDetails"
                    [maxRowElements]="2"
                    (onClick)="onDiClick($event)"
                ></cmx-assembled-data>
            </div>
        </div>

        <hr />

        <div class="detailTitle documentacao">
            <img src="./assets/app/media/img/icons/documents.png" alt="" />
            <span>Documentos Originais Desembaraço</span>
        </div>
        <span *ngFor="let document of originalDocuments" class="documentacaoList">
            {{ document.clearingDocumentNumberDelivered }} - <strong>{{ document.documentDeliveryDate }}</strong>
        </span>

        <div class="detailTitle documentacao mt-4">
            <img src="./assets/app/media/img/icons/documents.png" alt="" />
            <span>Documentos Originais Operacional</span>
        </div>
        <span *ngFor="let document of originalDocumentsOperational" class="documentacaoList">
            {{ document.operationalDocumentNumberDelivered }} - <strong>{{ document.documentDeliveryDate }}</strong>
        </span>
    </div>
</div>

<cmx-custom-dialog
    [id]="'import-details-clearance-packeage-dialog'"
    [title]="'Volume'"
    animation="right-left"
    [internalFade]="true"
    [closeButton]="true"
    [overlay]="true"
    [widthModal]="30"
    (modal-closed)="showPackageModal = false"
>
    <ng-container cmx-custom-dialog-body>
        <div class="import-details-clearance-package-container" *ngIf="showPackageModal && packageList.length > 1">
            <cmx-custom-grid
                [id]="'import-details-clearance-package-grid'"
                [columns]="gridPackageColumns"
                [rows]="packageList"
                [options]="{ forceFitColumns: true }"
                [styles]="{ 'max-height': 'calc(100vh - 330px)', 'margin-top': '0px' }"
            ></cmx-custom-grid>
        </div>
    </ng-container>
</cmx-custom-dialog>

<cmx-custom-dialog
    [id]="'import-details-clearance-di-dialog'"
    [title]="'DI'"
    animation="left-right"
    [internalFade]="true"
    [closeButton]="true"
    [overlay]="true"
    [widthModal]="45"
    (modal-closed)="showDiModal = false"
>
    <ng-container cmx-custom-dialog-body>
        <div class="import-details-clearance-di-container" *ngIf="showDiModal && diList.length > 1">
            <cmx-custom-grid
                [id]="'import-details-clearance-di-grid'"
                [columns]="gridDiColumns"
                [rows]="diList"
                [options]="{ forceFitColumns: true }"
                [styles]="{ 'max-height': 'calc(100vh - 330px)', 'margin-top': '0px' }"
            ></cmx-custom-grid>
        </div>
    </ng-container>
</cmx-custom-dialog>
