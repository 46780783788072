import { Component } from '@angular/core';
import { Subscription } from 'rxjs';

import { InternationalFreightRatesInterface, InternationalTaxInterface } from '../../../interfaces/international-freight-rates.interface';

import { InternationalFreightService } from '../../../services/international-freight.service';
import { NotificationService } from '#services/_notification/notification.service';
import { UtilsService } from '#services/_utils/utils.service';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { QuotatioFreightRatesFilter } from '../quotation-freight-rates-filter.model';
import { Tabs } from '../interfaces/quotation-freight-rates-filter.interface';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';

@Component({
    selector: 'international-freight-rate',
    templateUrl: './international-freight-rates.component.html',
    styleUrls: ['./international-freight-rates.component.scss'],
})
export class InternationalFreightRatesComponent {
    dataTitle;

    cargoTypes = [
        { id: 'Base ports', itemName: 'Base Ports' },
        { id: 'Têxtil', itemName: 'Têxtil' },
        { id: 'Painel solar', itemName: 'Painel Solar' },
    ];
    origins: any = [];
    destinations: any = [];

    filterState: QuotatioFreightRatesFilter = this.filterStateService.getQuotatioFreightRatesFilter();

    private _subscription: Subscription;

    gridRows: InternationalFreightRatesInterface[] = [];
    taxes: InternationalTaxInterface[] = [];
    freightRatesChartData = [];
    gridMessage = false;
    loadFreight = false;

    showLoader = false;

    fileSelected = null;

    creationDate = null;

    readonly dropdownSettingsCargoType = {
        singleSelection: true,
        text: 'Selecione o tipo de Carga',
        badgeShowLabel: 'Cargas selecionadas',
    };
    readonly dropdownSettingsOrigins = {
        singleSelection: false,
        text: 'Selecione a Origem',
        badgeShowLabel: 'Origens selecionadas',
    };
    readonly dropdownSettingsDestinations = {
        singleSelection: false,
        text: 'Selecione o Destino',
        badgeShowLabel: 'Destinos selecionados',
    };

    constructor(
        private _freightRatesService: InternationalFreightService,
        private notificationService: NotificationService,
        private _formatCurrency: UtilsService,
        private filterStateService: FilterStateService
    ) {
        this.getOrigins();
        this.getDestinations();
    }

    ngOnInit() {
        if (this.filterState.getTab() === Tabs.INTERNATIONAL) {
            this.findByFilter();
        }
    }

    ngOnDestroy() {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }

    handleCargoType($event) {
        this.filterState.setInternationalCargoType($event);
    }

    handleOrigins($event) {
        this.filterState.setInternationalOrigin($event);
    }

    handleDestinations($event) {
        this.filterState.setInternationalDestination($event);
    }

    trackByFn(index: number, item: any) {
        return index;
    }

    handleSelect() {
        if (
            this.filterState.getInternationalOrigin().length > 0 &&
            this.filterState.getInternationalDestination().length > 0 &&
            this.filterState.getInternationalCargoType() !== null
        ) {
            this.findByFilter();
        }
    }

    private getOrigins() {
        this._subscription = this._freightRatesService.getOrigin().subscribe(
            (data) => {
                this.origins = data.map((o) => {
                    const value = o.charAt(0).toUpperCase() + o.slice(1).toLowerCase();
                    return {
                        id: value,
                        itemName: value,
                    };
                });
            },
            (error) => {
                this.origins = [];
                this.notificationService.openNotification('error', error.message);
            }
        );
    }

    private toDropdownItems(items: any[]) {
        return items && items.map(this.toDropdownItem);
    }

    private toDropdownItem(item: any): DropdownOption {
        return new DropdownOption(item, `${item}`);
    }

    private getDestinations() {
        this._subscription = this._freightRatesService.getDestination().subscribe(
            (data) => {
                this.destinations = data.map((d) => {
                    const value = d.charAt(0).toUpperCase() + d.slice(1).toLowerCase();
                    return {
                        id: value,
                        itemName: value,
                    };
                });
            }, (error) => {
                this.destinations = [];
                this.notificationService.openNotification('error', error.message);
            }
        );
    }

    private findByFilter() {
        this.gridRows = [];

        this.gridMessage = false;
        this.loadFreight = false;

        this.showLoader = true;

        const originsParam = [];
        const destinationsParam = [];

        this.filterState.getInternationalOrigin().forEach((o) => originsParam.push(o.itemName.toUpperCase()));
        this.filterState.getInternationalDestination().forEach((d) => destinationsParam.push(d.itemName.toUpperCase()));

        this.filterState.updatePreference();

        this._freightRatesService.findByFilter(originsParam, destinationsParam, this.filterState.getInternationalCargoType()[0]?.itemName).subscribe(
            (data) => {
                if (data == null) {
                    this.notificationService.openNotification('error', 'Não foram encontrados resultados para a busca');
                } else {
                    this.gridRows = [];

                    this.creationDate = data[0].creationDate;
                    this.taxes = data[0].taxes;

                    data.forEach((item, i) => {
                        item.equipments.forEach((equipment) => {
                            equipment.freight = this.formatCurrency(equipment.freight);
                            equipment.extraCharge = this.formatCurrency(equipment.extraCharge);
                            equipment.totalFreight = this.formatCurrency(equipment.totalFreight);

                            this.gridRows.push({
                                ...item,
                                equipment: equipment,
                            });
                        });
                    });

                    this.taxes.forEach((tax) => {
                        tax.valueFormatted = this.formatCurrency(tax.value);
                    });

                    this.gridMessage = this.gridRows.length == 0;

                    this.loadFreight = true;
                }

                this.showLoader = false;
            },
            (error) => {
                this.notificationService.openNotification('error', error.message);
                this.gridMessage = true;
                this.loadFreight = false;
                this.showLoader = false;
            }
        );
    }

    formatCurrency(data) {
        return this._formatCurrency.formatNumber(data, {
            style: 'decimal',
        });
    }

    cleanEvent($event) {
        $event.target.value = '';
    }

    handleAttachFile($event) {
        this.loadFreight = false;

        let file = $event.target.files[0];

        this.fileSelected = file.name;

        const request = new FormData();
        request.append('file', file, this.fileSelected);

        this.notificationService.openNotification('startUpload', 'Atualizando valores de frete, aguarde por favor');

        this._freightRatesService
            .uploadFreightValues(request)

            .subscribe(
                (data) => {
                    this.getOrigins();
                    this.getDestinations();

                    this.fileSelected = null;

                    this.notificationService.openNotification('success', 'Valores de frete atualizados com sucesso');
                },
                (responseError) => {
                    let err = JSON.parse(responseError.error);

                    this.notificationService.openNotification('error', err.message);

                    this.fileSelected = null;
                }
            );
    }

    handleClearAttachFile() {
        this.fileSelected = null;
    }
}
