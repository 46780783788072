import { CountdownService, ICountdown } from '#services/_countdown/countdown.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'cmx-filter-reload-timer',
    templateUrl: './cmx-filter-reload-timer.component.html',
    styleUrls: ['./cmx-filter-reload-timer.component.scss'],
})
export class FilterReloadTimerComponent implements ICountdown {
    @Input() autoReset: boolean = true;
    @Input() seconds: number = 10 * 60;

    @Output() onTimerFinish = new EventEmitter();

    constructor(public countdown: CountdownService) { }

    ngAfterViewInit() {
        this._startTimer();
    }

    switchAutoReset() {
        this.autoReset = !this.autoReset;
        this.countdown.setAutoReset(this.autoReset);
    }

    timerFinished() {
        if (this.autoReset) this._startTimer();
        this.onTimerFinish.emit();
    }

    private _startTimer() {
        this.countdown.timer(this.autoReset, this.seconds, this);
    }

}