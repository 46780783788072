import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';
import { IMenuControl } from './menu-control.interface';
import { CLIENT_GROUPS_PERMITED } from 'app/theme/layouts/header-nav/constants/client-groups-permited.constants';

export const MENU: IMenuControl[] = [
    {
        name: 'Financeiro',
        icon: 'finance',
        children: [
            {
                name: 'Câmbio',
                route: null,
                permissions: {
                    have: [
                        { permission: 'API_EXCHANGE_SP_LISTING', valid: true },
                    ],
                },
                children: [
                    {
                        name: "Pacotes SP's",
                        route: '/financeiro/cambio/pacotes',
                        permissions: {
                            have: [{ permission: 'API_EXCHANGE_SP_LISTING', valid: true }],
                        },
                    },
                    {
                        name: "Listagem SP's",
                        route: '/financeiro/cambio',
                        permissions: {
                            have: [{ permission: 'API_EXCHANGE_SP_LISTING', valid: true }],
                        },
                    },
                    {
                        name: 'Fluxo Cambial',
                        route: '/financeiro/cambio/fluxo-cambial',
                        permissions: {
                            have: [{ permission: 'API_EXCHANGE_SP_LISTING', valid: true }],
                        },
                    },
                ],
            },
            {
                name: 'Consulta Fiscal',
                route: 'financeiro/consulta-fiscal',
                permissions: {
                    have: [
                        { permission: 'API_TAX_CONSULTATION', valid: true },
                    ],
                },
            },
            {
                name: 'Contas a Pagar',
                secondaryName: 'Contas a Receber',
                secoundaryPermissions: {
                    have: [{ permission: 'INTERNAL_FILTERS', valid: true }],
                },
                route: '/invoices-receivable',
                permissions: {
                    haveSome: [
                        {
                            permission: 'API_INVOICES_RECEIVABLE',
                            valid: true,
                        },
                        {
                            permission: 'API_INVOICES_RECEIVABLE_INSTALLMENT',
                            valid: true,
                        },
                    ],
                },
            },
            {
                name: 'Custos',
                route: '/financeiro/custos',
                permissions: {
                    have: [{ permission: 'ROUTE_REPORT_COSTS', valid: true }],
                },
            },
            {
                name: 'Notas Fiscais',
                route: `/supply-chain/notas-fiscais`,
                permissions: {
                    have: [{ permission: 'ROUTE_SUPPLY_CHAIN_INVOICE_STATUS', valid: true }],
                },
            },
            {
                name: 'DRE',
                route: '/financeiro/resultado',
                permissions: {
                    have: [{ permission: 'ROUTE_RESULTS', valid: true }],
                },
            },
            {
                name: 'Fechamento',
                route: '/financeiro/fechamento',
                permissions: {
                    have: [{ permission: 'ROUTE_ADM', valid: true }],
                },
            },
            {
                name: 'Premissa Comercial',
                route: '/financeiro/premissa-comercial',
                permissions: {
                    have: [{ permission: 'API_PREMISE', valid: true }],
                },
            },
            {
                name: 'Estudo Tributário',
                route: '/financeiro/estudo-tributario',
                permissions: {
                    have: [{ permission: 'API_TAX_STUDY', valid: true }],
                },
            },
        ],
    },
    {
        name: 'Operacional',
        icon: 'operational',
        children: [
            {
                name: 'Aprovações',
                route: '/operacional/aprovacoes',
                permissions: {
                    haveSome: [
                        { permission: 'APPROVAL_FLOW', valid: true },
                        { permission: 'APPROVAL_FLOW_DI_APPROVAL', valid: true }
                    ],
                },
                clientGroupCodes: CLIENT_GROUPS_PERMITED.approval_flow,
            },
            {
                name: 'Chassi',
                route: '/operacional/chassi',
                permissions: {
                    have: [{ permission: 'DASHBOARD_COMPONENT_CHASSI_STATUS', valid: true }],
                },
            },
            {
                name: 'Emissão Docs',
                route: '/operacional/emissao-documentos',
                permissions: {
                    have: [{ permission: 'API_LI_DOCUMENTS_EMISSION', valid: true }],
                },
            },
            {
                name: 'Importação',
                route: '/operacional/importacao',
                permissions: {
                    have: [{ permission: 'DASHBOARD_COMPONENT_IMP_STATUS', valid: true }],
                },
            },
            {
                name: 'Pedidos',
                route: '/operacional/pedidos',
                permissions: {
                    have: [{ permission: 'API_PURCHASE_ORDER', valid: true }],
                },
            },
            {
                name: 'Precificação',
                route: '/operacional/precificacao',
                permissions: {
                    have: [{ permission: 'API_PROJECT_PRICING', valid: true }],
                },
            },
            {
                name: 'SKU',
                route: '/operacional/sku',
                permissions: {
                    have: [{ permission: 'DASHBOARD_COMPONENT_SKU_STATUS', valid: true }],
                },
            },
        ],
    },
    {
        name: 'Performance',
        icon: 'performance',
        children: [
            {
                name: 'Canal Status',
                route: '/performance/canal-status',
                permissions: {
                    have: [{ permission: 'ROUTE_SUPPLY_CHAIN_CHANNEL_STATUS', valid: true }],
                },
            },
            {
                name: 'Geren. Alertas',
                route: '/performance/geren-alertas',
                permissions: {
                    have: [{ permission: 'API_NOTIFICATIONS', valid: true }],
                },
            },
            {
                name: 'SLA Status',
                route: '/performance/sla-status',
                permissions: {
                    have: [{ permission: 'API_SLA_KPI', valid: true }],
                },
            },
            {
                name: 'Validações DI',
                route: '/performance/validacao-di',
                permissions: {
                    have: [{ permission: 'ROUTE_DI_VALIDATION_HISTORIC', valid: true }],
                },
            },
        ],
    },
    {
        name: 'Relatórios',
        icon: 'reports',
        children: [
            {
                name: 'Despesas',
                route: '/report/despesas',
                permissions: {
                    have: [{ permission: 'API_INTERNAL_EXPENSES', valid: true }],
                },
            },
            {
                name: 'Follow UP',
                route: '/report/followup',
                permissions: {
                    haveSome: [
                        { permission: 'ROUTE_REPORT_FOLLOWUP', valid: true },
                        { permission: 'ROUTE_AUTOMOTIVE_FOLLOWUP', valid: true },
                        { permission: 'ROUTE_SKU_FOLLOWUP', valid: true },
                    ],
                },
                featureFlag: {
                    notEnabled: FeatureFlagService.FEATURE_FOLLOWUP_UNIFIED_ENABLED
                },
            },
            {
                name: 'Follow UP',
                permissions: {
                    haveSome: [
                        { permission: 'ROUTE_REPORT_FOLLOWUP', valid: true },
                        { permission: 'ROUTE_AUTOMOTIVE_FOLLOWUP', valid: true },
                        { permission: 'ROUTE_SKU_FOLLOWUP', valid: true },
                    ],
                },
                featureFlag: {
                    enabled: FeatureFlagService.FEATURE_FOLLOWUP_UNIFIED_ENABLED
                },
                children: [
                    {
                        name: 'Completo',
                        route: '/report/followup',
                        permissions: {
                            have: [{ permission: 'ROUTE_REPORT_FOLLOWUP', valid: true }],
                        }
                    },
                    {
                        name: 'Específicos',
                        children: [
                            {
                                name: 'IMP',
                                route: '/report/followup-imp',
                                permissions: {
                                    have: [{ permission: 'ROUTE_REPORT_FOLLOWUP', valid: true }],
                                },
                            },
                            {
                                name: 'Chassi',
                                route: '/report/followup-automotive',
                                permissions: {
                                    have: [{ permission: 'ROUTE_AUTOMOTIVE_FOLLOWUP', valid: true }],
                                },
                            },
                            {
                                name: 'SKU',
                                route: '/report/followup-sku',
                                permissions: {
                                    have: [{ permission: 'ROUTE_SKU_FOLLOWUP', valid: true }],
                                },
                            },
                        ]
                    },
                ],
            },
            {
                name: 'Relatórios Externos',
                route: null,
                permissions: {
                    haveSome: [
                        { permission: 'REPORT_MERCEDES', valid: true },
                        { permission: 'REPORT_TOYOTA', valid: true },
                        { permission: 'REPORT_FISCAL', valid: true },
                        { permission: 'REPORT_VOLKSWAGEN', valid: true },
                        { permission: 'REPORT_VOLVO', valid: true },
                        { permission: 'REPORT_TOKSTOK', valid: true },
                        { permission: 'REPORT_CMS_USER', valid: true },
                        { permission: 'REPORT_LOREAL', valid: true },
                        { permission: 'REPORT_HONDA', valid: true },
                        { permission: 'REPORT_CXT', valid: true },
                        { permission: 'REPORT_MANAGEMENT_PANEL_CXT', valid: true },
                        { permission: 'REPORT_PRODUCTS_CXT', valid: true },
                        { permission: 'REPORT_NOVELIS', valid: true },
                        { permission: 'REPORT_BYD', valid: true },
                        { permission: 'REPORT_BYD_SUAPE', valid: true },
                        { permission: 'REPORT_BYD_SC', valid: true },
                        { permission: 'REPORT_GWM', valid: true },
                        { permission: 'REPORT_GM', valid: true },
                        { permission: 'REPORT_ALL_BRANDS_VIX', valid: true },
                        { permission: 'REPORT_ALL_BRANDS', valid: true },
                        { permission: 'REPORT_SOLAR', valid: true },
                        { permission: 'REPORT_IFF', valid: true },
                        { permission: 'REPORT_HYUNDAI', valid: true },
                        { permission: 'REPORT_SEBRA', valid: true },
                        { permission: 'REPORT_PBI_FINANCEIRO', valid: true },
                    ],
                },
                children: [
                    {
                        name: 'Mercedes',
                        route: '/report/power-bi/mercedes',
                        permissions: {
                            have: [{ permission: 'REPORT_MERCEDES', valid: true }],
                        },
                    },
                    {
                        name: 'Toyota',
                        route: '/report/power-bi/toyota',
                        permissions: {
                            have: [{ permission: 'REPORT_TOYOTA', valid: true }],
                        },
                    },
                    {
                        name: 'Fiscal',
                        route: '/report/power-bi/fiscal',
                        permissions: {
                            have: [{ permission: 'REPORT_FISCAL', valid: true }],
                        },
                    },
                    {
                        name: 'Estoque CXT',
                        route: '/report/power-bi/estoque-cxt',
                        permissions: {
                            have: [{ permission: 'REPORT_CXT', valid: true }],
                        },
                    },
                    {
                        name: 'Painel Supri',
                        route: '/report/power-bi/painel-supri',
                        permissions: {
                            have: [{ permission: 'REPORT_CXT', valid: true }],
                        },
                    },
                    {
                        name: 'Painel Gerencial CXT',
                        route: '/report/power-bi/painel-gerencial-cxt',
                        permissions: {
                            have: [{ permission: 'REPORT_MANAGEMENT_PANEL_CXT', valid: true }],
                        },
                    },
                    {
                        name: 'Fluxo Produtos CXT',
                        route: '/report/power-bi/fluxo-produtos-cxt',
                        permissions: {
                            have: [{ permission: 'REPORT_PRODUCTS_CXT', valid: true }],
                        },
                    },
                    {
                        name: 'Honda',
                        route: '/report/power-bi/honda',
                        permissions: {
                            have: [{ permission: 'REPORT_HONDA', valid: true }],
                        },
                    },
                    {
                        name: 'L’Oréal',
                        route: '/report/power-bi/loreal',
                        permissions: {
                            have: [{ permission: 'REPORT_LOREAL', valid: true }],
                        },
                    },
                    {
                        name: 'Volkswagen',
                        route: '/report/power-bi/volkswagen',
                        permissions: {
                            have: [{ permission: 'REPORT_VOLKSWAGEN', valid: true }],
                        },
                    },
                    {
                        name: 'Volvo',
                        route: '/report/power-bi/volvo',
                        permissions: {
                            have: [{ permission: 'REPORT_VOLVO', valid: true }],
                        },
                    },
                    {
                        name: 'GM',
                        route: '/report/power-bi/gm',
                        permissions: {
                            have: [{ permission: 'REPORT_GM', valid: true }],
                        },
                    },
                    {
                        name: 'BYD',
                        route: '/report/power-bi/byd',
                        permissions: {
                            have: [{ permission: 'REPORT_BYD', valid: true }],
                        },
                    },
                    {
                        name: 'BYD Suape',
                        route: '/report/power-bi/byd-suape',
                        permissions: {
                            have: [{ permission: 'REPORT_BYD_SUAPE', valid: true }],
                        },
                    },
                    {
                        name: 'BYD SC',
                        route: '/report/power-bi/byd-sc',
                        permissions: {
                            have: [{ permission: 'REPORT_BYD_SC', valid: true }],
                        },
                    },
                    {
                        name: 'GWM',
                        route: '/report/power-bi/gwm',
                        permissions: {
                            have: [{ permission: 'REPORT_GWM', valid: true }],
                        },
                    },
                    {
                        name: 'Novelis',
                        route: '/report/power-bi/novelis',
                        permissions: {
                            have: [{ permission: 'REPORT_NOVELIS', valid: true }],
                        },
                    },
                    {
                        name: 'Tok&Stok',
                        route: '/report/power-bi/tokstok',
                        permissions: {
                            have: [{ permission: 'REPORT_TOKSTOK', valid: true }]
                        }
                    },
                    {
                        name: 'Usuários CMS',
                        route: '/report/power-bi/cms-user',
                        permissions: {
                            have: [{ permission: 'REPORT_CMS_USER', valid: true }]
                        }
                    },
                    {
                        name: 'Multimarcas VIX',
                        route: '/report/power-bi/all-brands-vix',
                        permissions: {
                            have: [{ permission: 'REPORT_ALL_BRANDS_VIX', valid: true }]
                        }
                    },
                    {
                        name: 'Multimarcas',
                        route: '/report/power-bi/all-brands',
                        permissions: {
                            have: [{ permission: 'REPORT_ALL_BRANDS', valid: true }]
                        }
                    },
                    {
                        name: 'Solar',
                        route: '/report/power-bi/solar',
                        permissions: {
                            have: [{ permission: 'REPORT_SOLAR', valid: true }]
                        }
                    },
                    {
                        name: 'IFF',
                        route: '/report/power-bi/iff',
                        permissions: {
                            have: [{ permission: 'REPORT_IFF', valid: true }]
                        }
                    },
                    {
                        name: 'KPI - NCPE',
                        route: '/report/power-bi/kpi-ncpe',
                        permissions: {
                            have: [{ permission: 'REPORT_KPI_NCPE', valid: true }]
                        }
                    },
                    {
                        name: 'Comparativo',
                        route: '/report/power-bi/comparativo',
                        permissions: {
                            have: [{ permission: 'REPORT_COMPARATIVO', valid: true }]
                        }
                    },
                    {
                        name: 'Porsche',
                        route: '/report/power-bi/porsche',
                        permissions: {
                            have: [{ permission: 'REPORT_PORSCHE', valid: true }]
                        }
                    },
                    {
                        name: 'Hyundai',
                        route: '/report/power-bi/hyundai',
                        permissions: {
                            have: [{ permission: 'REPORT_HYUNDAI', valid: true }]
                        }
                    },
                    {
                        name: 'Sebra',
                        route: '/report/power-bi/sebra',
                        permissions: {
                            have: [{ permission: 'REPORT_SEBRA', valid: true }]
                        }
                    },
                    {
                        name: 'PBI Financeiro',
                        route: '/report/power-bi/financeiro',
                        permissions: {
                            have: [{ permission: 'REPORT_PBI_FINANCEIRO', valid: true }]
                        }
                    },
                ],
            },
            {
                name: 'Traders',
                route: '/report/traders',
                permissions: {
                    have: [{ permission: 'ROUTE_REPORT_TRADERS', valid: true }],
                },
            },
        ],
    },
    {
        name: 'Integrações',
        icon: 'integrations',
        children: [
            {
                name: 'Fornecedores',
                route: null,
                permissions: {
                    haveSome: [
                        { permission: 'SUPPLY_ENGINE_AUTO', valid: true },
                        { permission: 'SUPPLY_ENGINE_MANUAL', valid: true },
                        { permission: 'API_PROJECT_OPERATIONAL_BILL_OF_LADING', valid: true }
                    ],
                },
                children: [
                    {
                        name: 'Solicitações de Pagamentos',
                        route: '/integracoes/fornecedores/pagamentos',
                    },
                    {
                        name: 'Conhecimento de Embarque',
                        route: 'integracoes/fornecedores/embarque',
                        permissions: {
                            have: [{ permission: 'API_PROJECT_OPERATIONAL_BILL_OF_LADING', valid: true }],
                        },
                    },
                ],
            },
            {
                name: 'Clientes',
                route: '/cadastro/invoice-external',
                permissions: {
                    have: [{ permission: 'API_INVOICE_EXTERNAL_CRUD', valid: true }],
                },
                children: [
                    {
                        name: 'Invoice',
                        route: '/cadastro/invoice-external',
                        queryParam: ['type=invoice'],
                    },
                    {
                        name: 'P.O.',
                        route: '/cadastro/pedidos'
                    }
                ],
            },
        ],
    },
    {
        name: 'Cadastros',
        icon: 'registration',
        children: [
            {
                name: 'Automotivo',
                route: '/cadastro/automotivo',
                permissions: {
                    have: [{ permission: 'ROUTE_AUTOMOTIVE_CRUD', valid: true }],
                },
            },
            {
                name: 'Câmbio',
                route: null,
                permissions: {
                    haveSome: [
                        { permission: 'ROUTE_FINANCIAL_CRUD', valid: true },
                        { permission: 'ROUTE_ADM', valid: true },
                    ],
                },
                children: [
                    {
                        name: 'Canais Bancários',
                        route: '/cadastro/cambio/canais-bancarios',
                    },
                    {
                        name: 'Contas Correntes',
                        route: '/cadastro/cambio/contas-correntes',
                    },
                    {
                        name: 'Corretoras',
                        route: '/cadastro/cambio/corretoras',
                    },
                ],
            },
            {
                name: 'Integrações',
                route: null,
                permissions: {
                    haveSome: [{ permission: 'SUPPLY_ENGINE_AUTO', valid: true }],
                },
                children: [
                    {
                        name: 'Contas Projeto',
                        route: '/cadastro/integracoes/account/fornecedores',
                    },
                ],
            },
            {
                name: 'Financeiro',
                route: '/cadastro/financeiro/contas/balancete',
                permissions: {
                    haveSome: [
                        { permission: 'ROUTE_FINANCIAL_CRUD', valid: true },
                        { permission: 'ROUTE_CURRENCY_EXCHANGE', valid: true },
                        { permission: 'API_FINANCIAL', valid: true },
                        { permission: 'API_FINANCIAL_CFOP', valid: true },
                    ],
                },
                children: [
                    {
                        name: 'Central de Fornecedores',
                        route: '/cadastro/financeiro/central-fornecedores',
                        permissions: {
                            have: [{ permission: 'API_FINANCIAL', valid: true }],
                        },
                    },
                    {
                        name: 'CFOP',
                        route: '/cadastro/financeiro/cfop',
                        permissions: {
                            have: [{ permission: 'API_FINANCIAL_CFOP', valid: true }],
                        },
                    },
                    {
                        name: 'Contas Contábeis',
                        route: '/cadastro/financeiro/contas/balancete',
                        permissions: {
                            have: [{ permission: 'API_FINANCIAL', valid: true }],
                        },
                    },
                    {
                        name: 'Taxas',
                        route: '/financeiro/taxas-cambiais',
                        permissions: {
                            have: [{ permission: 'ROUTE_CURRENCY_EXCHANGE', valid: true }],
                        },
                    },
                ],
            },
            {
                name: 'Operacional',
                route: '/cadastro/supply-chain',
                permissions: {
                    haveSome: [
                        { permission: 'COST_LIMIT', valid: true },
                        { permission: 'DI_VALIDATOR_XML', valid: true },
                        { permission: 'API_PROJECT_OPERATIONAL_CONFIG_CLIENT', valid: true },
                        { permission: 'API_PROJECT_OPERATIONAL_CONFIG_COMEXPORT', valid: true },
                        { permission: 'ROUTE_SLA_KPI_MANAGEMENT', valid: true },
                    ],
                },
                children: [
                    {
                        name: 'Limite de Custos',
                        route: '/cadastro/supply-chain',
                        permissions: {
                            have: [{ permission: 'COST_LIMIT', valid: true }],
                        },
                    },
                    {
                        name: 'Validação DI',
                        route: '/cadastro/supply-chain/validacao-di',
                        permissions: {
                            have: [{ permission: 'DI_VALIDATOR_XML', valid: true }],
                        },
                    },
                    {
                        name: 'Responsáveis',
                        route: '/cadastro/supply-chain/responsaveis/cliente',
                        permissions: {
                            have: [{ permission: 'API_PROJECT_OPERATIONAL_CONFIG_CLIENT', valid: true }],
                        },
                    },
                    {
                        name: 'SLA',
                        route: '/cadastro/supply-chain/sla',
                        permissions: {
                            have: [{ permission: 'ROUTE_SLA_KPI_MANAGEMENT', valid: true }],
                        },
                    },
                ],
            },
            {
                name: 'Logística',
                route: '',
                permissions: {
                    haveSome: [
                        { permission: 'EDIT_FREIGHT_FORWARDER', valid: true },
                        { permission: 'DELIVERY_COORDINATION', valid: true },
                    ],
                },
                children: [
                    {
                        name: 'Cotação',
                        route: null,
                        permissions: {
                            haveSome: [
                                { permission: 'EDIT_FREIGHT_FORWARDER', valid: true },
                            ],
                        },
                        children: [
                            {
                                name: 'Agentes de Carga',
                                route: '/quotation/freight-forwarder',
                                permissions: {
                                    have: [{ permission: 'EDIT_FREIGHT_FORWARDER', valid: true }],
                                },
                            },
                            {
                                name: 'Locais',
                                route: '/locations',
                                permissions: {
                                    have: [{ permission: 'EDIT_FREIGHT_FORWARDER', valid: true }],
                                },
                            },
                            {
                                name: 'Taxas',
                                route: '/taxes',
                                permissions: {
                                    have: [{ permission: 'EDIT_FREIGHT_FORWARDER', valid: true }],
                                },
                            },
                        ],
                    },
                    {
                        name: 'Carregamento',
                        route: null,
                        permissions: {
                            haveSome: [
                                { permission: 'DELIVERY_COORDINATION', valid: true },
                            ],
                        },
                        children: [
                            {
                                name: 'Filiais',
                                route: 'cadastro/logistica/filiais',
                                permissions: {
                                    have: [{ permission: 'DELIVERY_COORDINATION', valid: true }],
                                },
                            },
                            {
                                name: 'Escoltas',
                                route: '/cadastro/logistica/escoltas',
                                permissions: {
                                    have: [{ permission: 'DELIVERY_COORDINATION', valid: true }],
                                },
                            },
                            {
                                name: 'Transportadoras',
                                route: '/cadastro/logistica/transportadoras',
                                permissions: {
                                    have: [{ permission: 'DELIVERY_COORDINATION', valid: true }],
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        name: 'Logística',
        icon: 'logistic',
        children: [
            {
                name: 'Carregamento',
                route: '/logistica/carregamento',
                permissions: {
                    have: [{ permission: 'DELIVERY_COORDINATION', valid: true }],
                },
            },
            {
                name: 'Cotação Internacional',
                route: '/logistica/cotacao',
                permissions: {
                    have: [{ permission: 'API_QUOTATION_CREATE', valid: true }],
                },
            },
            {
                name: 'Devolução de Container',
                route: '/devolucao-container',
                permissions: {
                    have: [{ permission: 'DELIVERY_CONTAINER_RETURN', valid: true }],
                },
            },
            {
                name: 'Simular Frete',
                route: '/freight-rate',
                permissions: {
                    have: [{ permission: 'FREIGHT_RATES_ESTIMATE', valid: true }],
                },
            },
        ],
    },
    {
        name: 'Documentos',
        icon: 'documents',
        route: '/documentos',
        permissions: {
            have: [{ permission: 'API_GED', valid: true }],
        },
    },
];
