import { multipleFormatter } from "./multiple.formatter";
import { _applyPosFormatters } from "./pos-formatters/pos-formatters";
import { textFormatter } from "./text.formatter";

export function currencyFormatter(row, cell, value, columnDef, dataContext) {
    if (dataContext.isBlank) {
        return '&nbsp;';
    }
    const includeZero = columnDef.formatterData && columnDef.formatterData.includeZero && value === 0;
    if (!value && !includeZero) {
        return textFormatter(row, cell, value, columnDef, dataContext);
    }
    if (typeof value === 'string') {
        value = parseFloat(value);
    }
    if (Array.isArray(value)) {
        if (value.length) {
            return multipleFormatter(row, cell, value, columnDef, dataContext);
        }
    }

    const currency = (columnDef.formatterData && columnDef.formatterData.currency) || dataContext.currency || 'BRL';
    const locale = (columnDef.formatterData && columnDef.formatterData.locale) || 'pt-br';
    const digits = (columnDef.formatterData && columnDef.formatterData.digits) || 2;
    const fontWeight = (columnDef.formatterData && columnDef.formatterData.fontWeight) || '500';
    const float = (columnDef.formatterData && columnDef.formatterData.float) || 'right';
    const bold = dataContext.bold;
    const cursorPointer = dataContext.cursorPointer;
    const color = (columnDef.formatterData && columnDef.formatterData.negativeColor) && value < 0 ? '#d40808' : 'black';

    let formatedValue;
    if (columnDef.formatterData && columnDef.formatterData.viewFormat) {
        if (columnDef.formatterData.viewFormat === 'noCents') {
            formatedValue = Math.trunc(value).toLocaleString('pt-BR');
            return `<span style="color: ${color}; font-weight: ${fontWeight}; float: ${float}; cursor: ${
                cursorPointer && value ? 'pointer' : null
            }" class="${bold ? 'font-weight-bold' : null}">${formatedValue}</span>`;
        }
        if (columnDef.formatterData.viewFormat === 'thousand') {
            formatedValue = Math.trunc(value / 1000).toLocaleString('pt-BR');
            return `<span style="color: ${color}; font-weight: ${fontWeight}; float: ${float}; cursor: ${
                cursorPointer && value ? 'pointer' : null
            }" class="${bold ? 'font-weight-bold' : null}">${formatedValue}</span>`;
        }
        if (columnDef.formatterData.viewFormat === 'million') {
            formatedValue = Math.trunc(value / 1000000).toLocaleString('pt-BR');
            return `<span style="color: ${color}; font-weight: ${fontWeight}; float: ${float}; cursor: ${cursorPointer && value ? 'pointer' : null}" class="${bold ? 'font-weight-bold' : null}">${formatedValue}</span>`;
        }
    } else {
        formatedValue = value;
    }

    let currencyValue;
    if (currency === 'BRL' || currency === 'USD' || currency === 'EUR' || currency === 'none') {
        currencyValue = Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency === 'none' ? 'BRL' : currency?.substring(0,3) || 'BRL',
            minimumFractionDigits: digits,
        }).format(formatedValue);
    } else {
        return '\u2014';
    }
    currencyValue = currency === 'none' ? currencyValue.substring(3) : currencyValue;
    const contentValue = `<span style="color: ${color}; font-weight: ${fontWeight}; float: ${float}; cursor: ${cursorPointer && value ? 'pointer' : null}" class="${bold ? 'font-weight-bold' : null}">${currencyValue}</span>`;
    return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
}