import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';

@Component({
    selector: 'cmx-custom-view',
    templateUrl: './custom-view.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CustomViewComponent implements OnInit, OnDestroy {

    @Input('cmx-dark-theme') darkTheme: boolean = true;
    @Output() onPreferencesEnable: EventEmitter<boolean> = new EventEmitter(false);

    readonly dropdownSettings = {
        singleSelection: true,
        text: 'Selecionar Visão',
        enableSearchFilter: false,
        searchPlaceholderText: 'Visões customizadas',
    };

    changeCustomView = false;
    enableDropdown: boolean = false;

    preferences: DropdownOption[] = [];
    currentPreference: any;
    selectedPreference: any;
    user;

    private _preferencesChange: Subscription;

    constructor(
        private _userCacheService: UserCacheService,
        private userPreferencesService$: UserPreferencesService
    ) {}

    ngOnInit(): void {

        this.currentPreference = this._userCacheService.getUserPreferences();
        this.selectedPreference = [this.mapDropdownOption(this.currentPreference)];

        this._preferencesChange = this.userPreferencesService$.changePreferences$.subscribe(
            () => this._getPreferences()
        );
    }

    ngOnDestroy(): void {
        if (this._preferencesChange) {
            this._preferencesChange.unsubscribe();
        }
    }

    mapDropdownOption(item: any) {
        return new DropdownOption(item.id, item.name);
    }

    onSelect(preference) {
        this.changeCustomView = false;
        if (!this.currentPreference || this.currentPreference.id !== preference.id) {
            setTimeout(() => {
                this.userPreferencesService$.updateSelectedPreference(preference.id);
            }, 200);
        }
    }

    private _getPreferences() {
        this.userPreferencesService$.getUserPreferences().subscribe((preferences) => {
            this.preferences = preferences.map(this.mapDropdownOption);
            if(this.preferences.length > 1){
                this.enableDropdown = true;
            }else{
                this.enableDropdown = false;
            }
        });
    }
}
