<div *ngIf="costsService.exportAsync" class="fullLoader">
    <span class="exporting">Solicitando Exportação</span>
    <loader></loader>
</div>

<cmx-marker-filter
    id="costs-consolidated-marker-filter"
    type="tab"
    returnBasedOn="code"
    alignment="start"
    [multiple]="false"
    [filters]="tabs"
    [leftDistance]="290"
    [preSelected]="preSelectedPreset"
    (onSelected)="changePreset($event)"
></cmx-marker-filter>

<div class="costs-container">
    <div *ngIf="!asyncCosts">
        <loader></loader>
    </div>
    <span class="warning-btn" *ngIf="showWarning">
        <em class="material-icons" tooltip="Faturamento Parcial" size="sm" placement="bottom">warning</em>
    </span>

    <costs-filters
        (onLoadCost)="loadCosts(true)"
    ></costs-filters>

    <div *ngIf="asyncCosts" class="content">
        <div *ngIf="allCosts?.length === 0" class="noContent"> Não existem custos para os filtros aplicados. </div>
        <div *ngFor="let category of allCosts; trackBy: trackByFn" class="categoryContainer">
            <div *ngIf="category.expensesItems.length > 0" class="costsSubTitle">
                <img src="assets/img/icons/{{ formatCategoryName(category.name) }}.png" alt="" />
                <span class="detailTitle" style="color: #215aa0">{{ category.name }}</span>
                <span (click)="openAnaliticModal(category.name)" class="analiticButton">
                    <span style="margin-right: 5px">|</span> detalhamento<i class="material-icons">sort</i>
                </span>
            </div>
            <div *ngIf="category.expensesItems.length > 0" class="costsContent">
                <div *ngFor="let cost of category.expensesItems; trackBy: trackByFn" class="costsSet">
                    <div *ngIf="category.name != 'Duplicatas'" class="inner-costs-set w-100">
                        <span class="costsTitle" (click)="openAnaliticModal(category.name, cost.name)">
                            {{cost.name}}
                        </span>
                        <div class="costsValueContainer">
                            <span class="costsValue">{{ cost?.value || 0 | cmxValid | currencyType }}</span>
                        </div>
                        <div class="invoice-progress progress">
                            <div
                                id="dataInvoicesBilledItens"
                                class=""
                                role="progressbar"
                                style="background-color: #98b8e0 !important"
                                [style.width]="cost?.percentage + '%'"
                                aria-valuenow="50"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        <div class="textProgress">
                            <span class="invoice-detail">Rel. Mês Anterior</span>
                            <span class="invoice-percentage">{{ cost?.percentage || 0 | cmxValid }}%</span>
                        </div>
                    </div>
                    <div *ngIf="category.name == 'Duplicatas'" class="inner-costs-set w-100">
                        <div class="duplicatesTitleContainer">
                            <span class="costsTitle" (click)="openAnaliticModal(category.name, cost.name)" [tooltip]="cost.name.length > 20 ? cost.name : null" size="sm" placement="bottom">
                                {{cost.name | slice: 0:20}}{{cost.name.length > 20 ? '...' : ''}}
                            </span>
                            <div class="duplicatesDueDate" *ngIf="cost.dueDate">
                                <div class="title">
                                    Vencimento
                                </div>
                                <div class="value">
                                    <strong>{{cost.dueDate | date: 'dd/MM/yyyy'}}</strong>
                                </div>
                            </div>
                        </div>
                        <div class="duplicatesValueContainer">
                            <div class="duplicatesItens">
                                <div class="title">
                                    Valor Total
                                </div>
                                <div class="value">
                                    <span>{{ cost?.totalValue || 0 | cmxValid | currencyType }}</span>
                                </div>
                            </div>
                            <div class="duplicatesItens">
                                <div class="title">
                                    Desconto
                                </div>
                                <div class="value discount">
                                    <span>{{ cost?.discountValue || 0 | cmxValid | currencyType }}</span>
                                </div>
                            </div>
                            <div class="duplicatesItens">
                                <div class="title">
                                    Valor Líquido
                                </div>
                                <div class="value netValue">
                                    <span>{{ cost?.value || 0 | cmxValid | currencyType }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="invoice-progress progress">
                            <div id="dataInvoicesBilledItens" class="" role="progressbar"
                                style="background-color: #98b8e0 !important" [style.width]="cost?.percentage + '%'"
                                aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div class="textProgress">
                            <span class="invoice-detail">Rel. Mês Anterior</span>
                            <span class="invoice-percentage">{{ cost?.percentage || 0 | cmxValid }}%</span>
                        </div>
                    </div>
                </div>
                <div class="costsSet">
                    <span class="costsTitle" (click)="openAnaliticModal(category.name)">TOTAL</span>
                    <div class="costsValueContainer">
                        <span class="costsValue">{{ category?.value || 0 | cmxValid | currencyType }}</span>
                    </div>
                    <div class="invoice-progress progress">
                        <div
                            id="dataInvoicesBilledItens"
                            class=""
                            role="progressbar"
                            style="background-color: #98b8e0 !important"
                            [style.width]="category?.percentage + '%'"
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        ></div>
                    </div>
                    <div class="textProgress">
                        <span class="invoice-detail">Rel. Mês Anterior</span>
                        <span class="invoice-percentage">{{ category?.percentage || 0 | cmxValid }}%</span>
                    </div>
                </div>
            </div>
        </div>

        <cmx-custom-dialog id="cmx-custom-dialog-costs-sku" overlay="true">
            <ng-container cmx-custom-dialog-header>
                <div *ngIf="!asyncSkuValues">
                    <loader></loader>
                </div>
                <div class="title-modal" *ngIf="asyncSkuValues">
                    <i (click)="costsService.exportCosts('modal')" class="flaticon-download downloadButtonDialog"></i>
                    <div class="titleContainer">
                        <span class="titleHeader">{{ costCategoryDetail?.name }}</span>
                    </div>
                </div>
            </ng-container>

            <ng-container cmx-custom-dialog-body *ngIf="asyncSkuValues">
                <div class="conteinerContainer">
                    <div class="containerNumberContainer">
                        <span class="title">Contêineres</span>
                        <span class="value">{{ costCategoryDetail?.containerQuantity }}</span>
                    </div>
                    <div class="containerAverageValueContainer">
                        <span class="title">Valor Médio por Contêiner</span>
                        <span class="value">{{ costCategoryDetail?.containerAverageValue | currencyType }}</span>
                    </div>
                </div>
                <hr style="width: calc(100% + 20px); margin-left: -20px" />
                <div class="skuListContainer">
                    <span style="font-size: 12px; font-weight: 500">Valores por SKU e Unidade Comercializada
                        (U.C)</span>
                    <div class="skuList">
                        <div *ngFor="let sku of costCategoryDetail.skuValueList; trackBy: trackByFn"
                            class="valueContainer">
                            <div class="skuContainer">
                                <span class="sku">{{ sku?.skuCode }}</span>
                                <span class="value">{{ sku?.skuCostValue | currencyType }}</span>
                            </div>
                            <div class="ucContainer">
                                <span class="uc">x{{ sku?.unitsSold }} U.C</span>
                                <span class="value">{{ sku?.skuCostValueByUnit | currencyType }}</span>
                            </div>
                            <hr style="margin: 10px 0" />
                        </div>
                    </div>
                </div>
                <div *ngIf="skuPages.length > 1">
                    <pagination
                        class="small"
                        [numberOfPages]="skuPages.length"
                        [queryId]="queryId"
                        [maxPages]="5"
                        [showPagesText]="false"
                        (onPageChange)="changeSkuModalPage($event)"
                    ></pagination>
                </div>
            </ng-container>
        </cmx-custom-dialog>

    </div>

</div>
