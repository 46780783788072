<div *ngIf="!asyncDetail" class="fullLoader">
    <loader></loader>
</div>

<div id="import-details-chassi-container" *ngIf="asyncDetail">
    <div id="import-details-chassi-content-error" *ngIf="!chassiRows.length">
        <span class="noContent">Chassis não encontrados</span>
    </div>
    <div id="import-details-chassi-content" *ngIf="chassiRows.length">
        <div id="import-details-chassi-content" *ngIf="chassiRows.length">
            <cmx-custom-grid
                [id]="'import-details-chassi-grid'"
                [columns]="gridChassiColumns"
                [rows]="chassiRows"
                [styles]="{ 'max-height': 'calc(100vh - 330px)'}"
            ></cmx-custom-grid>
        </div>
    </div>
</div>
