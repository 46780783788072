import { HttpClient, HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import * as _ from 'lodash';

import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { IGridColumn, IGridRow } from 'app/shared/components/cmx-custom-grid/interfaces';
import { environment } from '#environment';

import { ChartsServices } from '../charts.services';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { DomainService } from '#services/_domain/domain.service';
import { UserPermissionBlockerService } from '#services/_user/userPermissionBlocker.service';
import { UtilsService } from '#services/_utils/utils.service';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';
import { CrProcessesFilter } from './cr-processes-table.filter';
import { FilterStateService } from '#services/_filters/filter-state.service';

@Component({
    selector: 'cr-processes-table',
    templateUrl: './cr-processes-table.component.html',
    styleUrls: ['../charts.scss', './cr-processes-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CrProcessesTableComponent implements AfterViewInit, OnDestroy {

    public readonly dropdownSettingsCompanies = {
        singleSelection: false,
        text: 'Empresas',
        selectAllText: 'Selecionar Todos',
        unSelectAllText: 'Desmarcar Todos',
        enableSearchFilter: false,
        badgeShowLabel: 'Empresas selecionadas',
    };

    public subscription: Subscription;
    filterState: CrProcessesFilter = this._filterStateService.getCrProcessesFilter();
    helpDescription = this._defineHelpDescription();

    public tablePeriodSwitch = false;
    public tableInterCompanySwitch = false;
    public tableValueSwitch = this.filterState.getValueSwitch();
    public asyncCrTable = true;
    public loadingRows = true;
    public validSelection = false;

    public startDate = moment().format('01/YYYY');
    public endDate = moment().format('MM/YYYY');
    public endDateLimitMonth = moment().format('MM/YYYY');
    public endDateLimitDay = moment().format('DD/MM/YYYY');

    selectedPeriod = "Mensal";
    selectedInterCompany = this.filterState.getInterCompanySwitch() ? "Considerar" : "Desconsiderar";

    periodTypes = [
        {
            code: 'CURRENT_YEAR',
            name: 'Ano Corrente',
            color: '#68217a'
        },
        {
            code: 'TWELVE_MONTHS',
            name: '12 Meses',
            color: '#68217a'
        },
        {
            code: 'CURRENT_MONTH',
            name: 'Mês Corrente',
            color: '#68217a'
        },
        {
            code: 'LAST_MONTH',
            name: 'Mês Anterior',
            color: '#68217a'
        },
        {
            code: 'LAST_TEN_DAYS',
            name: '10 Dias',
            color: '#68217a'
        },
        {
            code: 'LAST_THIRTY_DAYS',
            name: '30 Dias',
            color: '#68217a'
        },
    ];
    preSelectedPeriod = ['CURRENT_YEAR'];

    public selectedCompanies = [];
    public options: {
        companies: DropdownOption[];
    } = {
        companies: [],
    };

    public typeSwitch = {
        values: ['Nacionais', 'Todas', 'Internacionais'],
        index: 1,
    }
    public viewSwitch = {
        values: ['Clientes', 'Empresas'],
        index: 0,
    }
    public sortSwitch = {
        values: ['Pais', 'Filhos'],
        index: 1,
    }
    public crSwitch = {
        values: ['Separado', 'Agrupado'],
        index: 0,
    }
    public currencySwitch = {
        values: ['BRL', 'USD'],
        index: 0,
    }

    public zeroValuesSwitch = {
        values: ['Sim', 'Não'],
        index: 1,
    }

    public orderMaster: boolean = false;
    public orderChildren: boolean = true;
    public orderField: string = 'code';
    public orderDirection: string = 'asc';

    public refParam = 'Todas';
    public currencyParam = 'BRL';
    public intraChanged = false;
    public atualSubParam = 'client';
    public atualUrl;
    public chartData;
    public crParams = ['49', '55'];
    public zeroValues = false;

    gridColumns: IGridColumn[] = [];
    gridRows: IGridRow[] = [];

    dialogId = "dialog-processes-table";
    dialogTitle = "Editar - Faturamento x CR";
    customSelection = false;

    private companies = [];
    private editableElements = ['#crProcessesGrid', '#searchLine', '.checkmark-container'];

    private editing = false;

    getDatesOnFilter = {
        startDate: null,
        endDate: null
    }

    showModal = false;
    clearDates = false;

    @HostListener('click') onSetZIndex() {
        this.renderer2.setStyle(this._elementRef.nativeElement, 'z-index', this.utilsService.autoIncrementValue);
    }

    constructor(
        public router: Router,
        public chartService: ChartsServices,
        private domainService: DomainService,
        private http: HttpClient,
        private el: ElementRef,
        private permissionBlocker: UserPermissionBlockerService,
        private utilsService: UtilsService,
        private _cmxDropdownService: CmxDropdownService,
        private _elementRef: ElementRef,
        private renderer2: Renderer2,
        private _customDialogService: CustomDialogService,
        private _filterStateService: FilterStateService
    ) {
        this.atualUrl = window.location.href.split('/').slice(-1).pop();
    }

    get periodFormat(){
        return this.selectedPeriod === 'Mensal' ? 'MM/YYYY' : 'DD/MM/YYYY';
    }

    get periodFormatOutput(){
        return this.selectedPeriod === 'Mensal' ? 'YYYY-MM' : 'YYYY-MM-DD';
    }

    public ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public ngAfterViewInit() {

        this.domainService.getCompanies().subscribe(
            (data) => {
                this.companies = data;
                this.options.companies = this._cmxDropdownService.toDropdownItems(data);
                this.selectedCompanies = this._cmxDropdownService.toDropdownItems(data);
                this.loadCrProcessesTable(this.startDate, this.endDate);
            },
        );
        $('.date-crTable').hide();
    }

    public applyFilter(ordered?) {
        this.loadCrProcessesTable(this.startDate, this.endDate, ordered);
    }

    onCellClick({ item, column }): void {
        if (column !== 'code' && column !== 'total') {
            let startDate;
            let endDate;
            if (column.length === 7) {
                startDate = `${column}-01`;
                endDate = moment(column).add('1', 'M').add('-1', 'days').format('YYYY-MM-DD');
            } else {
                startDate = column;
                endDate = column;
            }

            if (item.parent) {
                const queryParams = {
                    force: true,
                    invoiceRelatedImpList: [],
                    invoiceTypeList: [],
                    clientGroups: this.filterState.getViewSwitch() !== 'Empresas' ? item.clientCode : [],
                    companies: this.filterState.getViewSwitch() === 'Empresas' ? item.clientCode : [],
                    intraCompany: this.filterState.getInterCompanySwitch(),
                    profitCenters: item.cr || []
                }
                this.router.navigate([`./supply-chain/notas-fiscais/${item.cr}!${this.filterState.getViewSwitch() === 'Empresas' ? 'company' : 'client'}!${item.clientCode}/0/${startDate}/${endDate}`],  { queryParams })
            } else {
                const CR = (item.code).split(' - ')[0] === 'TOTAL' ? 0 : (item.code).split(' - ')[0];
                const queryParams = {
                    force: true,
                    invoiceRelatedImpList: [],
                    invoiceTypeList: [],
                    companies: this.filterState.getCompanies().map((company) => company.id),
                    clientGroups: [],
                    intraCompany: this.filterState.getInterCompanySwitch(),
                    profitCenters: CR
                }
                this.router.navigate([`./supply-chain/notas-fiscais/${CR}/0/${startDate}/${endDate}`],  { queryParams });
            }
        }
    }

    onSortColumn(column) {
        this.orderField = column.field;
        this.orderDirection = column.sort;
        this.applyFilter(true);
    }

    public changeDropdown() {
        const codeList = [];
        this.filterState.setTypeSwitch('Todas')
        if (this.filterState.getCompanies().length === this.companies.length || this.filterState.getCompanies().length === 0) {
            this.typeSwitch.index = 1;
        } else {
            this.filterState.getCompanies().forEach((company) => {
                codeList.push(company.id);
            });
            this._determineType(codeList);
        }
    }

    public toggleTypeSwitch(values, changed?) {
        this.filterState.setTypeSwitch(values.value)
        this.filterState.setCurrencySwitch('BRL')
        if (!changed) {
            this.filterState.setCompanies(this._cmxDropdownService.toDropdownItems(
                this.filteredCompanies(this.companies)))
        }
    }

    public toggleSortSwitch(values) {
        this.filterState.setSortSwitch(values.value)
    }

    public toggleCRSwitch(values) {
        if (values.value === 'Separado') {
            this.crParams.push('49');
            this.crParams.push('55');
        } else {
            this.crParams = [];
        }
    }

    public toggleCurrencySwitch(values) {
        this.filterState.setCurrencySwitch(values.value);
    }

    public toggleInterCompanySwitch({ name }) {
        this.intraChanged = true;
        this.tableInterCompanySwitch = name === 'Desconsiderar' ? false : true;
        this.selectedInterCompany = name;
        this.filterState.setInterCompanySwitch(!this.filterState.getInterCompanySwitch());
    }

    public toggleViewSwitch(values) {
        this.filterState.setViewSwitch(values.value);
    }

    public toggleTablePeriodSwitch({ name }) {
        this.selectedPeriod = name;
        this.tablePeriodSwitch = name === 'Mensal' ? false : true;
        this.clearDates = true;
        setTimeout(() => { this.clearDates = false }, 60);

        if(name === 'Mensal'){
            this.startDate = moment().format('01/YYYY');
            this.endDate = moment().format('MM/YYYY');
            this.preSelectedPeriod = ["CURRENT_YEAR"];
            this.getDatesOnFilter.startDate = this.startDate;
            this.getDatesOnFilter.endDate = this.endDate;
            return;
        }

        this.crTableChangeDateParam('day', 0, false);
        this.preSelectedPeriod = ["CURRENT_MONTH"];
        this.getDatesOnFilter.startDate = this.startDate;
        this.getDatesOnFilter.endDate = this.endDate;
        this.customSelection = false;

        const newSwitchValue = !this.tablePeriodSwitch;
        $('#periodFrom-crTable-Month').val('');
        $('#periodTo-crTable-Month').val('');
        $('#periodFrom-crTable-Date').val('');
        $('#periodTo-crTable-Date').val('');
        if (newSwitchValue === false) {
            $('.date-crTable').hide();
            $('.month-crTable').show();
        } else {
            $('.date-crTable').show();
            $('.month-crTable').hide();
        }
    }

    public toggleTableValueSwitch(event) {
        this.intraChanged = true;

        this.tableValueSwitch = event.target.value;
        this.filterState.setValueSwitch(this.tableValueSwitch);
    }

    public toggleZeroValuesSwitch(values) {
        this.filterState.setZeroValuesSwitch(values.value)
    }

    public changeCrTableDateCustom() {
        this.updatePeriodSwitch();
        this.validSelection = true;
    }

    public crTableChangeDateParam(date, period, loadProcesses = true) {
        this.customSelection = false;
        this._resetOrder();

        if (date === 'month') {
            this.tablePeriodSwitch = false;
            this.selectedPeriod = "Mensal";
            if (period === 99) { // ano corrente
                this.startDate = moment().format('01/YYYY');
                this.endDate = this.nextMonthOrDecember();
            } else { // 12 meses
                this.startDate = moment().add(-period, 'M').format('MM/YYYY');
                this.endDate = moment().format('MM/YYYY');
            }
        } else {
            this.tablePeriodSwitch = true; // diário
            this.selectedPeriod = "Diária";

            if (period === 0) { // mês corrente
                this.startDate = moment().format('01/MM/YYYY');
                this.endDate = moment().format('DD/MM/YYYY');
            } else if (period === 98) { // mês anterior
                this.startDate = moment().subtract(1, 'M').format('01/MM/YYYY');
                this.endDate = moment().set('date', 0).format('DD/MM/YYYY');
            } else { // 10 e 30 dias
                this.startDate = moment().add(-period, 'day').format('DD/MM/YYYY');
                this.endDate = moment().format('DD/MM/YYYY');
            }
        }

        if(loadProcesses){
            this.loadCrProcessesTable(this.startDate, this.endDate);
        }
    }

    onSelectedPeriod({ selected }): void {
        const periodCod = selected[0].code;

        if(periodCod === "CURRENT_YEAR") {
            this.crTableChangeDateParam('month', 99);
        }

        if(periodCod === "TWELVE_MONTHS") {
            this.crTableChangeDateParam('month', 11);
        }

        if(periodCod === "LAST_MONTH") {
            this.crTableChangeDateParam('day', 98);
        }

        if(periodCod === "CURRENT_MONTH") {
            this.crTableChangeDateParam('day', 0);
        }

        if(periodCod === "LAST_TEN_DAYS") {
            this.crTableChangeDateParam('day', 9);
        }

        if(periodCod === "LAST_THIRTY_DAYS") {
            this.crTableChangeDateParam('day', 29);
        }
    }

    setDateFilter(typeDate, event){
        if(typeDate === 'startDate'){
            this.startDate = event.startDate;
        }

        if(typeDate === 'endDate'){
            this.endDate = event.startDate;
        }
    }

    onHandlerFilter(){

        if(this.getDatesOnFilter.startDate !== this.startDate || this.getDatesOnFilter.startDate !== this.startDate){
            this.preSelectedPeriod = [];
            this.customSelection = true;
        }else{
            this.customSelection = false;
        }

        this.loadCrProcessesTable(this.startDate, this.endDate);
        this._customDialogService.close(this.dialogId);
        this.editing = !this.editing;
    }

    public loadCrProcessesTable(start, end, ordered?) {
        ordered ? this.loadingRows = false : this.asyncCrTable = false;

        if (this.permissionBlocker.permissionValidator(this.el.nativeElement.tagName)) {
            this.utilsService.setInvisible('cr-processes-table', this.editableElements);
            this.validSelection = false;
            const dates = this._defineParamDates(start, end);
            this.subscription = this._getCRProcessesData(dates.startDate, dates.endDate).subscribe(
                (data) => {
                    this.chartData = _.cloneDeep(data);
                    if (ordered) {
                        this.gridRows = this._setRows();
                        this.loadingRows = true;
                    } else {
                        this._loadGrid();
                    }
                    this.utilsService.setVisible('cr-processes-table', this.editableElements);
                }, (error) => {
                    this.chartData = undefined;
                    this.asyncCrTable = true;
                    this.loadingRows = true;
                    this.utilsService.errorHandler(error, 'Faturamento x CR');
                }
            );
        }
    }

    openEditModal(){
        this.getDatesOnFilter.startDate = this.startDate;
        this.getDatesOnFilter.endDate = this.endDate;

        this._customDialogService.open(this.dialogId);
        this.showModal = true;
        this.changeCrTableDateCustom();
    }

    checkPeriodDates(name) {
        if(name === 'type') {
            return this.selectedPeriod === 'Mensal' ? 'single-month' : 'single-date';
        }

        if(name === 'periodType') {
            return this.selectedPeriod === 'Mensal' ? 'month' : 'day';
        }

        if(name === 'startDateRestriction') {
            return this.selectedPeriod === 'Mensal' ? '01/2000' : '01/01/2020';
        }

        if(name === 'endDateRestriction') {
            return this.selectedPeriod === 'Mensal' ? this.endDateLimitMonth : this.endDateLimitDay;
        }
    }

    private _defineParamDates(start, end) {
        return {
            startDate: start.length === 7 ? moment(start, 'MM/YYYY').format('YYYY-MM')
            : moment(start, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            endDate: end.length === 7 ? moment(end, 'MM/YYYY').format('YYYY-MM')
            : moment(end, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        }
    }

    private _loadGrid() {
        this.gridColumns = this._setColumns(this.chartData ? this.chartData.billingAmounts : null);
        this.gridRows = this._setRows();
        this.asyncCrTable = true;
    }

    private _determineType(codeList) {
        if (this.filterState.getCompanies().length === 1) {
            if (codeList.indexOf('12') > -1 || codeList.indexOf('17') > -1) {
                // 1 Selecionado (Internacional)
                this._setType('Internacionais');
            } else {
                // 1 Selecionado (Nacional)
                this._setType('Nacionais');
            }
        } else if (this.filterState.getCompanies().length === 2) {
            if (codeList.indexOf('12') > -1 && codeList.indexOf('17') > -1) {
                // 2 Selecionados (2 Internacionais)
                this._setType('Internacionais');
            } else if (codeList.indexOf('12') > -1 || codeList.indexOf('17') > -1) {
                // 2 Selecionados (1 Internacional)
                this._setType('Todas');
            } else {
                // 2 Selecionados (0 Internacional)
                this._setType('Nacionais');
            }
        } else if (codeList.indexOf('12') > -1 || codeList.indexOf('17') > -1) {
            // Varios Selecionados (1 Internacional) = all
            this._setType('Todas');
        } else {
            // Varios Selecionados (0 Internacional) = nacional
            this._setType('Nacionais');
        }
    }

    private _setType(type) {
        if (type === 'Nacionais') {
            this.typeSwitch.index = 0;
            this.filterState.setTypeSwitch('Nacionais')
            this.filterState.setCurrencySwitch('BRL');
        } else if (type === 'Internacionais') {
            this.typeSwitch.index = 2;
            this.filterState.setTypeSwitch('Internacionais')
        } else {
            this.typeSwitch.index = 1;
            this.filterState.setTypeSwitch('Todas')
            this.filterState.setCurrencySwitch('BRL');
        }
    }

    private _setColumns(billingAmounts) {
        const columns: any = [{
            field: 'code',
            name: 'CR',
            formatterType: 'collapse',
            formatterData: {
                fontWeight: 500,
            },
            width: 180,
            isSortable: true,
        }];
        for (const date in billingAmounts) {
            if (Object.prototype.hasOwnProperty.call(billingAmounts, date)) {
                columns.push({
                    field: date,
                    name: date.length === 7 ? moment(date, 'YYYY-MM').format('MM/YYYY')
                    : moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                    formatterType: 'currency',
                    formatterData: {
                        fontWeight: 100,
                        viewFormat: this.filterState.getValueSwitch(),
                    },
                    width: 110,
                    isSortable: true,
                })
            }
        }
        columns.push({
            field: 'total',
            name: 'Total',
            formatterType: 'currency',
            formatterData: {
                viewFormat: this.filterState.getValueSwitch(),
            },
            width: 130,
            isSortable: true,
        })
        return columns;
    }

    private _setRows() {
        let index = -1;
        const rows = [];
        if (!this.chartData) { return rows }
        this.chartData.children.forEach((cr) => {
            index++;
            const crName = `${cr.profitCenterCode} - ${cr.profitCenterName}`;
            const crTotal = cr.total;
            rows.push({
                id: index,
                code: crName,
                total: crTotal || '0',
                _collapsed: true,
                alone: !cr.children.length,
            })
            for (const crDate in cr.billingAmounts) {
                if (Object.prototype.hasOwnProperty.call(cr.billingAmounts, crDate)) {
                    rows[rows.length - 1][crDate] = `${cr.billingAmounts[crDate]}`;
                }
            }
            const parent = index;
            cr.children.forEach((crChild) => {
                index++;
                rows.push({
                    id: index,
                    cr: cr.profitCenterCode,
                    clientCode: crChild.code,
                    code: crChild.name,
                    total: crChild.total || '0',
                    parent,
                    isLeaf: true,
                });
                for (const childDate in crChild.billingAmounts) {
                    if (Object.prototype.hasOwnProperty.call(crChild.billingAmounts, childDate)) {
                        rows[rows.length - 1][childDate] = `${crChild.billingAmounts[childDate]}`;
                    }
                }
            });
        });
        rows.push({
            id: rows.length,
            code: 'TOTAL',
            total: this.chartData.total,
            _collapsed: true,
            alone: true,
        })
        for (const value in this.chartData.billingAmounts) {
            if (Object.prototype.hasOwnProperty.call(this.chartData.billingAmounts, value)) {
                rows[rows.length - 1][value] = `${this.chartData.billingAmounts[value]}`;
            }
        }
        return rows;
    }

    private _resetOrder() {
        this.orderField = 'code';
        this.orderDirection = 'asc';
    }

    private nextMonthOrDecember() {
        const currentDate = moment();
        if (currentDate.month() !== 11) { // january = 0, december = 11
            currentDate.add(1, 'months');
        }
        return currentDate.format('MM/YYYY');
    }

    private updatePeriodSwitch() {
        if ($('#ano-corrente, #doze-meses').hasClass('checked')) {
            this.tablePeriodSwitch = false;
        } else if ($('#mes-corrente, #mes-anterior, #dez-dias, #trinta-dias').hasClass('checked')) {
            this.tablePeriodSwitch = true;
        } else if (!$('.cr-tableMonth').hasClass('hidden')) {
            this.tablePeriodSwitch = false;
        } else if (!$('.cr-tableDate').hasClass('hidden')) {
            this.tablePeriodSwitch = true;
        }
    }

    private prepareParams() {
        let params = new HttpParams();
        params = params.append('companyCodes', this.selectedCompanies.map((company) => company.id).join(','));
        params = params.append('optionalProfitCenters', `${this.crParams}`);
        params = params.append('intraCompanies', `${this.tableInterCompanySwitch}`);
        params = params.append('currency', `${this.currencyParam}`);
        params = params.append('exchangeCurrency', `${this.refParam === 'Internacionais' && this.currencyParam === 'BRL' ? true : false}`);
        params = params.append('orderMaster', `${this.orderMaster}`);
        params = params.append('orderChildren', `${this.orderChildren}`);
        params = params.append('orderField', `${this.orderField.toUpperCase()}`);
        params = params.append('orderDirection', `${this.orderDirection.toUpperCase()}`);
        params = params.append('zeroValues', `${this.zeroValues}`);
        return params;
    }

    private _getCRProcessesData(start, end): Observable<any> {
        const type = this.filterState.getViewSwitch() === 'Empresas' ? 'ByCompany' : 'ByClient';
        const dateType = start.length === 7 ? 'Month' : 'Date';
        const params = this.filterState.getRequest( this.crParams, this.orderField.toUpperCase(), this.orderDirection.toUpperCase() );
        const endpoint = `${environment.endpoints.supplyChainService
        }/profitCenterData/invoiceAmountBy${dateType}AndCRGrouped${type
        }?start${dateType}=${start}&end${dateType}=${end}`;
        return this.http.get(endpoint, params );
    }

    private filteredCompanies(companies) {
        const filterdCompanies = [];
        if (this.filterState.getTypeSwitch() === 'Nacionais') {
            companies.forEach((company) => {
                if (company.code !== '12' && company.code !== '17') {
                    filterdCompanies.push(company);
                };
            });
            return filterdCompanies;
        } else if (this.filterState.getTypeSwitch() === 'Internacionais') {
            companies.forEach((company) => {
                if (company.code === '12' || company.code === '17') {
                    filterdCompanies.push(company);
                }
            });
            return filterdCompanies;
        } else {
            return companies;
        }
    }

    private _defineHelpDescription(): object[] {
        return [
            { type: 'text', value: "Informa a quantidade faturada para cada centro de resultado, sendo possível segregar os valores por clientes ou empresas. As informações podem ser vistas por meses ou dias."},
        ]
    }
}
