/* global ga */
/*jshint camelcase: false */

import './autotrack';

export function initGoogleAnalytics(gaAccount) {
    (function(i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r;
        i[r] = i[r] || function() {
            (i[r].q = i[r].q || [])
            .push(arguments)
        }, i[r].l = 1 * new Date();
        a = s.createElement(o),
            m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m)
    })(window, document, 'script',
        'https://www.google-analytics.com/analytics.js', 'ga');

    return $.Deferred(function(defer) {
        ga('create', gaAccount, 'auto');
        ga('require', 'outboundLinkTracker');
        ga(function() {
            defer.resolve(ga);
        });
    }).promise();
}
