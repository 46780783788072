<div *ngIf="async" class="fullLoader">
    <loader></loader>
</div>
<div class="documentsGedContent">
    <div class="clientsListColumn" [style.width]="'30%'">
        <cmx-listing
            id="cost-limit-listing"
            [list]="clientList"
            [searchPlaceholder]="'Buscar Cliente'"
            [instantSearch]="true"
            [hasAddButton]="false"
            [height]="'calc(100vh - 250px)'"
            (onSelect)="selectClient($event)"
        ></cmx-listing>
    </div>
    <div class="documentsColumn">
        <h5>{{ selectedClient?.name }}</h5>
        <hr>
        <cmx-drag-and-drop-table
            [title]="'Liberações do GED'"
            [leftTitle]="'Bloqueados'"
            [leftIcon]="'block'"
            [leftData]="notAllowedTypes"
            [leftColor]="'#ad0000'"
            [rightTitle]="'Permitidos'"
            [rightIcon]="'check_circle_outline'"
            [rightData]="allowedTypes"
            [rightColor]="'#314968'"
            [search]="'Documento'"
            (onChangeValues)="changeConfiguration($event)"
            (onFinishButton)="saveConfiguration()">
        </cmx-drag-and-drop-table>
    </div>
</div>
