<div *ngIf="detailTable.asyncExport" class="fullLoader">
    <span class="exporting">Exportando</span>
    <loader></loader>
</div>

<div id="myGrid"></div>

<div class="DetailTableModal">
    <div class="detailContent">
        <i id="close" (click)="closeDetailModal()" class="pointer m-menu__hor-arrow la la-close"></i>
        <div *ngIf="detailTable.hasData" class="flex-btn solo-btn">
            <span (click)="detailTable.export('csv')" class="hidden-type">CSV</span>
            <span (click)="detailTable.export('xls')" class="hidden-type">XLS</span>
            <i style="margin-left: 5px" class="flaticon-download"></i>
            <span class="tooltip-text">Download</span>
        </div>
        <div class="detail-modalContent">
            <h5 class="detail-title">
                <b>Detail Table</b>
                <span class="labelContainer" *ngIf="detailTable.asyncDetailTitle">
                    <span class="color-label" id="title">
                        {{ detailTable.accountingCode }}
                        <div *ngIf="detailTable.commentHistory" (click)="detailTable.openHistoric()" class="historic">
                            <i class="material-icons">history</i>
                        </div>
                    </span>
                    <div class="balanceContainer">
                        <div class="innerContainer text">
                            <span class="balanceItem">Saldo Anterior</span>
                            <span class="balanceItem">Saldo do Período</span>
                        </div>
                        <div class="innerContainer icon">
                            <img class="balanceItem" src="./assets/app/media/img/icons/saldo-anterior.png" alt="" />
                            <img class="balanceItem" src="./assets/app/media/img/icons/saldo-periodo.png" alt="" />
                        </div>
                        <div class="innerContainer value">
                            <ng-template #zero>
                                <span class="balanceItem value">0,00</span>
                            </ng-template>
                            <span *ngIf="detailTable.previousBalance !== 0; else zero" class="balanceItem value">{{
                                detailTable.previousBalance
                            }}</span>
                            <span *ngIf="detailTable.totalAmount !== 0; else zero" class="balanceItem value">{{
                                detailTable.totalAmount
                            }}</span>
                        </div>
                    </div>
                    <span class="color-label" id="value">{{ detailTable.totalAmountPlusBalance }}</span>
                </span>
            </h5>
            <hr />
            <div *ngIf="detailTable.asyncDetailTable == false">
                <loader></loader>
            </div>
            <detailtable style="width: calc(100% - 100px)"></detailtable>
        </div>
    </div>
</div>

<ul id="contextMenu" style="display: none; position: absolute">
    <b style="cursor: default">Detalhamento:</b>
    <li style="margin-top: 10px" data="Detail" (click)="detailTable.openDetailModal(0, false)">Detail Table</li>
    <li style="pointer-events: none; opacity: 0.6" data="Turn">Turn Table</li>
</ul>

<div *ngIf="asyncExport" class="fullLoader">
    <span class="exporting">Exportando</span>
    <loader></loader>
</div>
