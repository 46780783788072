export class Cost {

    public static blank() {
        return new Cost({
            accessoriesDPI: 0,
            brokerage: 0,
            capatazias: 0,
            cofins: 0,
            dispatcher: 0,
            financialExpenses: 0,
            insurance: 0,
            ipi: 0,
            merchantNavy: 0,
            pis: 0,
            remoteShipping: 0,
            seaportExpenses: 0,
            shipping: 0,
            siscomexRate: 0,
            storage: 0,
            tegmaDPI: 0,
            totalFinancialCost: 0,
            commissionFee: 0,
        });
    }

    public accessoriesDPI: number; // Acessórios PDI
    public brokerage: number; // Corretagem de Câmbio
    public capatazias: number; // THC/Capatazias
    public cofins: number; // Cofins
    public dispatcher: number;
    public financialExpenses: number; // Despesas Financeiras
    public insurance: number; // Seguro
    public ipi: number; // I.P.I
    public merchantNavy: number;
    public pis: number; // P.I.S
    public remoteShipping: number; // Remoção
    public seaportExpenses: number; // Despesas Comexport
    public shipping: number; // Frete
    public siscomexRate: number; // Taxa Siscomex
    public storage: number; // Armaz. Portuária
    public tegmaDPI: number; // Taxa PDI
    public totalFinancialCost: number;
    public commissionFee: number;

    constructor(source: Cost) {
        this.accessoriesDPI = source.accessoriesDPI || 0;
        this.brokerage = source.brokerage || 0;
        this.capatazias = source.capatazias || 0;
        this.cofins = source.cofins || 0;
        this.dispatcher = source.dispatcher || 0;
        this.financialExpenses = source.financialExpenses || 0;
        this.insurance = source.insurance || 0;
        this.ipi = source.ipi || 0;
        this.merchantNavy = source.merchantNavy || 0;
        this.pis = source.pis || 0;
        this.remoteShipping = source.remoteShipping || 0;
        this.seaportExpenses = source.seaportExpenses || 0;
        this.shipping = source.shipping || 0;
        this.siscomexRate = source.siscomexRate || 0;
        this.storage = source.storage || 0;
        this.tegmaDPI = source.tegmaDPI || 0;
        this.totalFinancialCost = source.totalFinancialCost || 0;
        this.commissionFee = source.commissionFee || 0;
    }

}
