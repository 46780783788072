import { AppModule } from 'app/app.module';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { ITaxConsultationFilter } from '../interface/tax-consultation.interface';
import moment from 'moment';
import * as _ from 'lodash';

export class TaxConsultationFilter {
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialTaxConsultationFilter: ITaxConsultationFilter = <ITaxConsultationFilter>{};
    private _taxConsultationFilter: ITaxConsultationFilter = <ITaxConsultationFilter>{
        impNumber: '',
        clientGroups: [],
        status: [],
        responsibles: [],
        responsibleUserOdf: [],
    };

    private _startDate;
    private _endDate;
    private _filterField;
    private _filterValue;
    private _page: number;
    private _size: number;

    constructor(filter?: TaxConsultationFilter) {
        this._taxConsultationFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'taxConsultationFilter', this._taxConsultationFilter, filter);
        this._initialTaxConsultationFilter = _.cloneDeep(this._taxConsultationFilter);
        this._startDate = filter ? filter._startDate : moment().format('01/01/YYYY');
        this._endDate = filter ? filter._endDate : moment().format('DD/MM/YYYY');
        this._filterField = filter ? filter._filterField : '';
        this._filterValue = filter ? filter._filterValue : '';
        this._page = filter ? filter._page : 0;
        this._size = filter ? filter._size : 50;
    }

    getImpNumber() { return this._taxConsultationFilter.impNumber }
    getClientGroups() { return this._taxConsultationFilter.clientGroups; }
    getStatus() { return this._taxConsultationFilter.status; }
    getResponsibles() { return this._taxConsultationFilter.responsibles; }
    getResponsibleUserOdf() { return this._taxConsultationFilter.responsibleUserOdf; }
    getStartDate() { return this._startDate; }
    getEndDate() { return this._endDate; }
    getFilterField() { return this._filterField; }
    getFilterValue() { return this._filterValue; }
    getPage(): number { return this._page; }
    getSize(): number { return this._size; }

    setImpNumber(impNumber): void {this._taxConsultationFilter.impNumber = impNumber; }
    setClientGroups(clientGroups): void {this._taxConsultationFilter.clientGroups = clientGroups; }
    setStatus(status): void { this._taxConsultationFilter.status = status; }
    setResponsibles(responsibles): void { this._taxConsultationFilter.responsibles = responsibles; }
    setResponsibleUserOdf(responsibleUserOdf): void { this._taxConsultationFilter.responsibleUserOdf = responsibleUserOdf; }
    setStartDate(startDate): void { this._startDate = startDate; }
    setEndDate(endDate): void { this._endDate = endDate; }
    setFilterField(filterField): void { this._filterField = filterField; }
    setFilterValue(filterValue): void { this._filterValue = filterValue; }
    setPage(page): void { this._page = page; }
    setSize(size): void { this._size = size; }

    updatePreferences() {
        this._initialTaxConsultationFilter = this._userPreferencesService.verifyFilterChanges(this._initialTaxConsultationFilter,this._taxConsultationFilter,'taxConsultationFilter',this._userCacheService.getUserPreferences().id);
    }

    getRequest() {
        this.updatePreferences();

        let params = new HttpParams();
        let headers = new HttpHeaders();

        if (this.getFilterValue() && this.getFilterField()) {
            params = params.append(this.getFilterField(), this.getFilterValue());
        } else {
            this.setFilterField(null);
            this.setFilterValue(null);
        }

        if(this.getClientGroups()?.length > 0) {
            headers = headers.set('X-Requested-Client-Groups', this.getClientGroups().map((c) => c['id']).join(','));
        }

        params = params.append('importationNumber', `${ this.getImpNumber() }`);
        params = params.append('status', this.getStatus().map((status) => status.id).join(',').toUpperCase());
        params = params.append('responsibleUserNames', this.getResponsibles().map((responsibles) => responsibles.itemName).join(','));
        params = params.append('responsibleUserOdfs', this.getResponsibleUserOdf().map((responsibleUserOdf) => responsibleUserOdf.itemName).join(','));
        params = params.append('startDate', `${moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`);
        params = params.append('endDate', `${moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`);
        params = params.append('page', `${ this.getPage() }`);
        params = params.append('size', `${ this.getSize() }`);

        this.removeNullValuesFromQueryParams(params);

        return { params, headers };
    }

    removeNullValuesFromQueryParams(params: HttpParams) {
        const paramsKeysAux = params.keys();
        paramsKeysAux.forEach((key) => {
          const value = params.get(key);
          if (value === null || value === undefined || value === '') {
            params['map'].delete(key);
          }
        });

        return params;
      }
}
