import * as _ from 'lodash';

import { Chart } from '../../../../../shared/utils/chart';

import { Utils } from '../utils/utils';

import { BASE_COLORS, FIXED_COLOR } from '../constants/base-colors.constant';
import { DAMAGE_TYPES } from '../constants/damage-types.constant';

export class DamageChartModel {
    private _damageTypes: string[] = [];

    constructor(private _damageData: any[]) {}

    loadChart(chartId: string): AmCharts.AmChart {
        return Chart.makeChart(chartId, this._getConfigs());
    }

    private _hasSomeVehiclesType(...types: string[]): boolean {
        return types.some((t) => t === 'Veículos');
    }

    private _getDinamicGraphs(): any[] {
        const colors = Utils.getColors(BASE_COLORS);

        return this._damageTypes
            .reduce((accumulator: any[], currentValue: string, currentIndex: number) => {
                const description = DAMAGE_TYPES[currentValue] || currentValue;
                const total = `[[total${currentValue}]] ${description}`;
                const totalDamage = `[[totalDamage${currentValue}]] ${description}`;
                const totalDamagedPercentage = `Não Conformes ([[totalDamagedPercentage${currentValue}]]%)`;
                const balloonTextData = `${total} • ${totalDamage} ${totalDamagedPercentage}`;
                const color = this._hasSomeVehiclesType(description) ? FIXED_COLOR : colors[currentIndex];

                const graph = {
                    negativeBase: 2,
                    balloonText: `<span style='font-size:12px;'><b>${balloonTextData}</b></span>`,
                    title: description,
                    bullet: 'round',
                    bulletSize: 12,
                    lineColor: color,
                    lineThickness: 2,
                    negativeLineColor: color,
                    type: 'smoothedLine',
                    valueField: `totalDamage${currentValue}`,
                };

                return [...accumulator, graph];
            }, [])
            .sort((a, b) => (this._hasSomeVehiclesType(a.title, b.title) ? -1 : 1));
    }

    private _getParsedDamageData(): { dinamicDamageData: any; dinamicGraphs: any } {
        const dinamicDamageData = this._damageData.reduce((accumulator: any[], currentValue: any) => {
            const damageByType = Object.keys(currentValue.damageByChassisType).reduce(
                (accumulator: any, key: string) => {
                    this._damageTypes = _.union(this._damageTypes, [key]);

                    const damageByTypeValue = currentValue.damageByChassisType[key];

                    return _.extend(accumulator, {
                        [`total${key}`]: damageByTypeValue.totalChassis || 0,
                        [`totalDamage${key}`]: damageByTypeValue.totalDamagedChassis || 0,
                        [`totalDamagedPercentage${key}`]: damageByTypeValue.damagedPercentage || 0,
                    });
                },
                {}
            );

            return [...accumulator, _.extend({ period: currentValue.period }, damageByType)];
        }, []);

        return {
            dinamicDamageData,
            dinamicGraphs: this._getDinamicGraphs(),
        };
    }

    private _getConfigs(): any {
        const { dinamicDamageData, dinamicGraphs } = this._getParsedDamageData();

        return {
            type: 'serial',
            theme: 'none',
            marginLeft: 20,
            showHandOnHover: true,
            dataProvider: dinamicDamageData,
            startDuration: 0,
            graphs: dinamicGraphs,
            categoryField: 'period',
            balloon: {
                fixedPosition: true,
                maxWidth: 10000,
            },
            legend: {
                enabled: true,
                useGraphSettings: true,
                equalWidths: false,
            },
            valueAxes: [
                {
                    axisAlpha: 0,
                    inside: true,
                    position: 'left',
                    ignoreAxisWidth: true,
                },
            ],
            categoryAxis: {
                minorGridAlpha: 2,
                minorGridEnabled: true,
                fontSize: 9.5,
            },
            chartCursor: {
                cursorAlpha: 0.5,
                cursorColor: '#1f3d7a',
                cursorPosition: 'mouse',
                categoryBalloonColor: '#1f3d7a',
            },
        };
    }
}
