import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable()
export class FollowUpReportLiFormatter {

    // Deferimento
    public formatLiDeferment(row, cell, value, columnDef, dataContext) {
        const lis = [];
        value?.forEach((li) => {
            if (!lis.includes(li.dates.acceptanceDate)) {
                lis.push(li.dates.acceptanceDate);
            }
        });
        if (lis.length === 1) {
            if (lis[0] === null || lis[0] === undefined || lis[0] === '') {
                return '';
            }
            return `<span class='dateValue'>${moment(lis[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (lis.length > 1) {
            return `<span class="multipleLiDeferment">Várias (${lis.length})</span>`;
        } else {
            return '';
        }
    }

    // Número LI
    public formatLiNumber(row, cell, value, columnDef, dataContext) {

        const lis = [];
        value?.forEach((li) => {
            if (!lis.includes(li.liNumber)) {
                lis.push(li.liNumber);
            }
        });
        if (lis.length === 1) {
            return lis[0];
        } else if (lis.length > 1) {
            return `<span class="multipleLis">Várias (${lis.length})</span>`;
        } else {
            return '';
        }
    }

    // Agente LI
    public formatLiAgency(row, cell, value, columnDef, dataContext) {
        const lis = [];
        value?.forEach((li) => {
            if (li.licensingBody != null) {
                lis.push(li.licensingBody);
            }
        });
        if (lis.length === 1) {
            return lis[0];
        } else if (lis.length > 1) {
            return `<span class="multipleLis">Várias (${lis.length})</span>`;
        } else {
            return '';
        }
    }

    // Status LI
    public formatLiStatus(row, cell, value, columnDef, dataContext) {
        const lis = [];
        value?.forEach((li) => {
            if (li.liStatus !== null) {
                lis.push(li.liStatus);
            }
        });
        if (lis.length === 1) {
            if (lis[0] === null || lis[0] === undefined || lis[0] === '') {
                return '';
            } else if(lis[0] == 'NÃO SE APLICA') {
                return lis[0];
            } else {
                return `<span class="li ${lis[0]}">${lis[0]}</span>`;
            }
        } else if (lis.length > 1) {
            return `<span class="multipleLis">Várias (${lis.length})</span>`;
        } else {
            return '';
        }
    }

    // Emissão LI
    public formatLiEmission(row, cell, value, columnDef, dataContext) {
        const lis = [];
        value?.forEach((li) => {
            if (!lis.includes(li.dates.issueDate)) {
                lis.push(li.dates.issueDate);
            }
        });
        if (lis.length === 1) {
            if (lis[0] === null || lis[0] === undefined || lis[0] === '') {
                return '';
            } else if(lis[0] == 'NÃO SE APLICA') {
                return lis[0];
            }

            return `<span class='dateValue'>${moment(lis[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (lis.length > 1) {
            return `<span class="multipleLiEmission">Várias (${lis.length})</span>`;
        } else {
            return '';
        }
    }

    // Protocolo LI
    public formatLiProtocol(row, cell, value, columnDef, dataContext) {
        const lis = [];
        value?.forEach((li) => {
            if (!lis.includes(li.dates.protocolDate)) {
                lis.push(li.dates.protocolDate);
            }
        });

        if (lis.length === 1) {
            if (lis[0] === null || lis[0] === undefined || lis[0] === '') {
                return '';
            }
            return `<span class='dateValue'>${moment(lis[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (lis.length > 1) {
            return `<span class="multipleLiProtocol">Várias (${lis.length})</span>`;
        } else {
            return '';
        }
    }

    // Restriction Date
    public formatLiRestrictionDate(row, cell, value, columnDef, dataContext) {
        const restrictionDates = [];
        value?.forEach((li) => {
            if (!restrictionDates.includes(li.dates.restrictionDate)) {
                restrictionDates.push(li.dates.restrictionDate);
            }
        });
        if (restrictionDates.length === 1) {
            if (restrictionDates[0] === null || restrictionDates[0] === undefined || restrictionDates[0] === '') {
                return '';
            }
            return `<span class='dateValue'>${moment(restrictionDates[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (restrictionDates.length > 1) {
            return `<span class="multipleRestrictionDates">Várias (${restrictionDates.length})</span>`;
        } else {
            return '';
        }
    }

    // Validade da Anuência para Embarque
    public formatBoardingLimitDate(row, cell, value, columnDef, dataContext) {
        const lis = [];
        value?.forEach((li) => {
            if (li.dates.boardingLimitDate !== null) {
                lis.push(li.dates.boardingLimitDate);
            }
        });
        if (lis.length === 1) {
            if (lis[0] === null || lis[0] === undefined || lis[0] === '') {
                return '';
            }
            return `<span class='dateValue'>${moment(lis[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (lis.length > 1) {
            return `<span class="multipleLis">Várias (${lis.length})</span>`;
        } else {
            return '';
        }
    }

    // Validade da Anuência para Despacho
    public formatDiRegistrationExpiryDate(row, cell, value, columnDef, dataContext) {
        const lis = [];
        value?.forEach((li) => {
            if (li.dates.diRegistrationExpiryDate !== null) {
                lis.push(li.dates.diRegistrationExpiryDate);
            }
        });
        if (lis.length === 1) {
            if (lis[0] === null || lis[0] === undefined || lis[0] === '') {
                return '';
            }
            return `<span class='dateValue'>${moment(lis[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (lis.length > 1) {
            return `<span class="multipleLis">Várias (${lis.length})</span>`;
        } else {
            return '';
        }
    }

    // Pagamento ANVISA
    public formatLiGruPayment(row, cell, value, columnDef, dataContext) {
        const lis = [];
        value?.forEach((li) => {
            if ((Array.isArray(li.gruPaymentDates) && li.gruPaymentDates.length)) {
                li.gruPaymentDates.forEach((date) => {
                    lis.push(date);
                });
            }
        });
        if (lis.length === 1) {
            if (lis[0] === null || lis[0] === undefined || lis[0] === '') {
                return '';
            }
            return `<span class='dateValue'>${moment(lis[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (lis.length > 1) {
            return `<span class="multipleGruPayments">Vários (${lis.length})</span>`;
        } else {
            return '';
        }
    }

}
