<cmx-page-wrapper
    layout="bigHeader"
    [titleComponent]="titleComponent"
    helpTitle="Notas Fiscais"
    [helpDescription]="helpDescription">
    <ng-container cmx-page-wrapper-body *ngIf="currentUser$ | async as user">
        <div *ngIf="asyncExport" class="fullLoader">
            <span class="exporting">Exportando</span>
            <loader></loader>
        </div>

        <cmx-marker-filter
            type="tab"
            [filters]="periodTypes"
            [preSelected]="preSelectedPeriod"
            [hasSelectAll]="false"
            [leftDistance]="180"
            (onSelected)="onSelectedPeriod($event)"
        ></cmx-marker-filter>

        <div class="invoice-status-content">
            <section id="invoice-status-filter-line">
                <cmx-filter-wrapper [flex]="false" (onClick)="applyFilter()">
                    <cmx-filter-wrapper-group [divisor]="false" *ngIf="user.has('INTERNAL_FILTERS')">
                        <cmx-dropdown
                            [styles]="{width: '175px'}"
                            name="clients"
                            [data]="options.client"
                            [ngModel]="filterState.getClientGroups()"
                            (ngModelChange)="filterState.setClientGroups($event)"
                            [settings]="dropdownSettings.clientGroups"
                        ></cmx-dropdown>
                    </cmx-filter-wrapper-group>
                    <cmx-filter-wrapper-group [divisor]="false" *ngIf="user.has('INTERNAL_FILTERS')">
                        <cmx-dropdown
                            [styles]="{width: '155px'}"
                            name="crs"
                            [data]="options.cr"
                            [ngModel]="filterState.getProfitCenters()"
                            (ngModelChange)="filterState.setProfitCenters($event)"
                            [settings]="dropdownSettings.profitCenters"
                        ></cmx-dropdown>
                    </cmx-filter-wrapper-group>
                    <cmx-filter-wrapper-group [divisor]="false" *ngIf="user.has('INTERNAL_FILTERS')">
                        <cmx-dropdown
                            [styles]="{width: '180px'}"
                            [data]="options.company"
                            [ngModel]="filterState.getCompanies()"
                            (ngModelChange)="filterState.setCompanies($event)"
                            [settings]="dropdownSettings.companies"
                        ></cmx-dropdown>
                    </cmx-filter-wrapper-group>
                    <cmx-filter-wrapper-group [divisor]="false" *ngIf="user.has('INTERNAL_FILTERS')">
                        <cmx-filter-switch
                            size="lg"
                            [values]="intraSwitch.values"
                            [inputIndex]="intraSwitch.values.indexOf(filterState.getIntraCompany() === true ? 'c/ IntraCompany' : 's/ IntraCompany')"
                            (onSwitch)="toggleIntraSwitch($event.value === 'c/ IntraCompany' ? true : false)"
                        ></cmx-filter-switch>
                    </cmx-filter-wrapper-group>
                    <cmx-filter-wrapper-group [divisor]="true" label="Emissão">
                        <cmx-filter-datepicker
                            id="invoice-status-datepicker"
                            [startDate]="startDate"
                            [endDate]="endDate"
                            [into-pagewrapper]="true"
                            (onChangeDates)="datesChanged($event)"
                        ></cmx-filter-datepicker>
                    </cmx-filter-wrapper-group>
                </cmx-filter-wrapper>
            </section>

            <div class="my-4">
                <cmx-summary-line
                    [dataList]="summaryLine"
                    [title]="summaries[selectedSummary]?.type === 'DEFAULT' ? 'Valor NF:' : 'Valor Taxa Média:'"
                    [switchList]="summaries.length > 1? ['Por NF', 'Por Taxa Média'] : []"
                    (onSwitch)="switchSummary()"
                ></cmx-summary-line>
            </div>

            <div class="downloadButton">
                <em class="flaticon-download" title="Download"  (click)="exportInvoices()"></em>
            </div>

            <div class="gridContainer">
                <div id="invoice-status-loader" *ngIf="loadingInvoiceStatusTable"><loader></loader></div>
                <div *ngIf="!loadingInvoiceStatusTable && !gridRows" class="noContent">Não foram encontrados registros</div>

                <cmx-custom-grid
                    id="invoice-status-grid"
                    [style.visibility]="!loadingInvoiceStatusTable ? 'visible' : 'hidden'"
                    [columns]="gridColumns"
                    [rows]="gridRows"
                    [styles]="{ height: 'calc(100vh - 330px)' }"
                    (onCellClick)="onCellClick($event)"
                    [options]="{ forceFitColumns: false, useEmptyDataMessage: true, showEmptyDataHeader: true}"
                ></cmx-custom-grid>

                <div *ngIf="pages.length > 1">
                    <pagination
                        [numberOfPages]="pages.length"
                        [queryId]="queryId"
                        [style.visibility]="loading ? 'hidden' : 'visible'"
                        (onPageChange)="changePage($event)"
                    ></pagination>
                </div>
            </div>
        </div>

        <cmx-custom-dialog
            [id]="'invoice-status-chassi-dialog'"
            [title]="'Chassi'"
            animation="right-left"
            [internalFade]="true"
            [closeButton]="true"
            [overlay]="true"
            [widthModal]="20"
            (modal-closed)="showChassiModal = false; chassiDetails = null; chassiList = null">
            <ng-container cmx-custom-dialog-body>
                <div class="invoice-status-chassi-dialog-container" *ngIf="showChassiModal">
                    <div id="nvoice-status-chassi-dialog-content" *ngIf="chassiDetails">
                        <cmx-assembled-data [data]="chassiDetails" [maxRowElements]="2"></cmx-assembled-data>
                        <hr class="mb-4"/>
                        <div *ngFor="let chassi of chassiList;">
                            <div class="section-title">{{ chassi }}</div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </cmx-custom-dialog>

    </ng-container>
</cmx-page-wrapper>
