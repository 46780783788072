import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { CmxCurrencyPipe } from 'app/shared/pipes/currency.pipe';
import { environment } from '#environment';
import moment from 'moment';
import { Observable } from 'rxjs';

@Component({
    selector: 'currency-exchange',
    templateUrl: './currency-exchange.component.html',
    styleUrls: ['./currency-exchange.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CurrencyExchangeComponent {
    // tslint:disable:max-line-length

    @ViewChild(CurrencyExchangeComponent)
    public currencyExchangeComp: CurrencyExchangeComponent;

    public currencyExchangeList = [];
    public currencyExchangeYearList = [];
    public selectedCurrencyExchange = {
        referenceMonth: '',
        averageCurrencyExchange: 0.0,
        finalExchangeRate: 0.0,
        historicalExchangeRate: 0.0,
        annualHistoricalExchangeRate: 0.0,
    };
    public selectedElementClass;
    public selectedElement;

    public beginDate;
    public endDate;
    public isNewEntity;
    public asyncLoad = true;

    constructor(private http: HttpClient, private cmxCurrencyPipe: CmxCurrencyPipe) {

    }

    public ngOnInit() {
        this.loadDates();
    }

    public ngAfterViewInit() {
        this.loadData();
    }

    public reloadDate(action) {
        if (action === 'add') {
            this.beginDate = this.beginDate.add(1, 'year');
            this.endDate = this.endDate.add(1, 'year');
        } else {
            this.beginDate = this.beginDate.subtract(1, 'year');
            this.endDate = this.endDate.subtract(1, 'year');
        }
        this.currencyExchangeYearList = this.getRangeOfDates(this.beginDate, this.endDate, 'month');
    }

    public loadDates() {
        const lastYear = moment().subtract(1, 'year').format('YYYY');
        const currentYear = moment().format('YYYY');
        this.beginDate = moment(lastYear + '-01-01').add(11, 'month');
        this.endDate = moment(currentYear + '-01-01').add(12, 'month');
        this.currencyExchangeYearList = this.getRangeOfDates(this.beginDate, this.endDate, 'month');
    }

    public loadData() {
        this.asyncLoad = true;
        this.getExchangeData().subscribe((currencyExchangeList) => {
            this.currencyExchangeList = currencyExchangeList;
            this.asyncLoad = false;
        });
    }

    public clear() {
        const month = this.selectedCurrencyExchange.referenceMonth;
        this.selectedCurrencyExchange = {
            referenceMonth: month,
            averageCurrencyExchange: 0.0,
            finalExchangeRate: 0.0,
            historicalExchangeRate: 0.0,
            annualHistoricalExchangeRate: 0.0,
        };
        this.hideError();
    }

    public hideError() {
        $('.error').addClass('hidden');
        $('.success').addClass('hidden');
    }

    public create() {
        this.createExchangeData().subscribe((response) => {
            this.isNewEntity = false;
            $('.taxContainer .success.created').removeClass('hidden');
            this.loadData();
            $('.edit').removeClass('disabled');
        }, (rsp) => {
            if (rsp.status === 304) {
                $('.taxContainer .error.hastax').removeClass('hidden');
            } else if (rsp.status === 409) {
                $('.taxContainer .error.hastax').removeClass('hidden');
            } else if (rsp.status === 201) {
                this.isNewEntity = false;
                $('.taxContainer .success.created').removeClass('hidden');
                this.loadData();
            } else {
                $('.taxContainer .error.nocreated').removeClass('hidden');
            }
            $('.edit').removeClass('disabled');
        });
    }

    public update() {
        this.updateExchangeData().subscribe((response) => {
            $('.taxContainer .success.edited').removeClass('hidden');
            this.loadData();
            $('.edit').removeClass('disabled');
        }, (rsp) => {
            if (rsp.status === 409) {
                $('.taxContainer .error.hastax').removeClass('hidden');
            } else {
                $('.taxContainer .error.noedited').removeClass('hidden');
            }
            $('.edit').removeClass('disabled');
        });
    }

    public save() {

        this.hideError();
        $('.edit').addClass('disabled');
        this.asyncLoad = true;

        if (this.selectedCurrencyExchange.referenceMonth === '') {
            $('.taxContainer .error.notaxselected').removeClass('hidden');
            $('.edit').removeClass('disabled');
            this.asyncLoad = false;
            return;
        } else {
            if (this.selectedCurrencyExchange.averageCurrencyExchange === 0 || this.selectedCurrencyExchange.finalExchangeRate === 0
                || this.selectedCurrencyExchange.historicalExchangeRate === 0 || this.selectedCurrencyExchange.annualHistoricalExchangeRate === 0) {
                $('.taxContainer .error.notax').removeClass('hidden');
                $('.edit').removeClass('disabled');
                this.asyncLoad = false;
                return;
            }
        }

        if (this.isNewEntity) {
            this.create();
        } else {
            this.update();
        }

    }

    public getExchangeData(): Observable<any> {
        return this.http.get(`${environment.endpoints.exchangeService}/currencies/`);
    }

    public createExchangeData(): Observable<HttpResponse<any>> {
        const url = `${environment.endpoints.exchangeService}/currencies/USD/BRL/${this.selectedCurrencyExchange.referenceMonth}`;
        return this.http.post(url, this.selectedCurrencyExchange, { observe: 'response', responseType: 'json' },
        );
    }

    public updateExchangeData(): Observable<HttpResponse<any>> {
        const url = `${environment.endpoints.exchangeService}/currencies/USD/BRL/${this.selectedCurrencyExchange.referenceMonth}`;
        return this.http.put(url, this.selectedCurrencyExchange, { observe: 'response', responseType: 'json' });
    }

    public getRangeOfDates(start, end, key, arr = [start.startOf(key)]) {
        if (start.isAfter(end)) throw new Error('start must precede end')
        const next = moment(start).add(1, key).startOf(key);

        if (next.isAfter(end, key)) return arr;

        return this.getRangeOfDates(next, end, key, arr.concat(next));
    }

    public selectCurrencyExchangeMonth(element) {

        this.isNewEntity = false;
        if (this.selectedElement !== undefined) {
            this.selectedElement.removeClass();
            this.selectedElement.addClass(this.selectedElementClass);
        }

        this.selectedElement = $(element.target);
        this.selectedElementClass = $(element.target).attr('class');

        const content = this.selectedElement.text();
        const month = content.substring(3, 7) + content.substring(0, 2);

        this.selectedCurrencyExchange = undefined;

        this.currencyExchangeList.forEach(
            (value) => {
                const referenceMonth = value.referenceMonth.substring(4, 6) + '/' + value.referenceMonth.substring(0, 4);
                if (referenceMonth === content) {
                    this.selectedCurrencyExchange = value;
                }
            },
        );

        if (this.selectedCurrencyExchange === undefined) {
            this.isNewEntity = true;
            this.selectedCurrencyExchange = {
                referenceMonth: month,
                averageCurrencyExchange: 0.0,
                finalExchangeRate: 0.0,
                historicalExchangeRate: 0.0,
                annualHistoricalExchangeRate: 0.0,
            };
        }

        $(element.target).removeClass();

        if (this.selectedElementClass === 'first span-date') {
            $(element.target).addClass('selected first');
        } else if (this.selectedElementClass === 'last span-date-n') {
            $(element.target).addClass('selected last');
        } else {
            $(element.target).addClass('selected normal');
        }
    }

    public maskCurrencyExchange() {

        if (this.selectedCurrencyExchange.averageCurrencyExchange == null) {
            this.selectedCurrencyExchange.averageCurrencyExchange = 0.0000;
            return;
        }
        if (this.selectedCurrencyExchange.finalExchangeRate == null) {
            this.selectedCurrencyExchange.finalExchangeRate = 0.0000;
            return;
        }
        if (this.selectedCurrencyExchange.historicalExchangeRate == null) {
            this.selectedCurrencyExchange.historicalExchangeRate = 0.0000;
            return;
        }
        if (this.selectedCurrencyExchange.annualHistoricalExchangeRate == null) {
            this.selectedCurrencyExchange.annualHistoricalExchangeRate = 0.0000;
            return;
        }

        this.selectedCurrencyExchange.averageCurrencyExchange =
            parseFloat(this.cmxCurrencyPipe.transform(
                this.selectedCurrencyExchange.averageCurrencyExchange, 4));
        this.selectedCurrencyExchange.finalExchangeRate =
            parseFloat(this.cmxCurrencyPipe.transform(
                this.selectedCurrencyExchange.finalExchangeRate, 4));
        this.selectedCurrencyExchange.historicalExchangeRate =
            parseFloat(this.cmxCurrencyPipe.transform(
                this.selectedCurrencyExchange.historicalExchangeRate, 4));
        this.selectedCurrencyExchange.annualHistoricalExchangeRate =
            parseFloat(this.cmxCurrencyPipe.transform(
                this.selectedCurrencyExchange.annualHistoricalExchangeRate, 4));
    }

    // public replacePositiveOrNegative(value: number): string {
    //     return value.toString().replace('+', '').replace('-', '');
    // }

    trackByFn(index: number, item: any) {
        return index;
    }

}
