import moment from "moment";
import { badgeFormatter } from "./badge.formatter";
import { _applyPosFormatters } from "./pos-formatters/pos-formatters";

export function dateOrBadgeFormatter(row, cell, value, columnDef, dataContext) {
    if (!value || !moment(value).isValid()) {
        const text = (columnDef.formatterData && columnDef.formatterData.text) || 'AGUARDANDO';
        return badgeFormatter(row, cell, text, columnDef, dataContext);
    }
    const contentValue = `<span>${moment(value).format('DD/MM/YYYY')}</span>`;
    return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
}