<div class="gridContainer">

    <cmx-custom-grid
        id="project-pricing-proposes-grid"
        [style.visibility]="gridRows?.length ? 'visible' : 'hidden'"
        [columns]="gridColumns"
        [rows]="gridRows"
        [styles]="{ height: 'calc(100vh - 400px)' }"
        (onCellClick)="onCellClick($event)"
        (onPopoverHover)="onPopoverHover($event)"
        (onPopoverMouseLeave)="onPopoverMouseLeave()"
    ></cmx-custom-grid>

    <cmx-slick-popover
        [dataRows]="projectPricingService.popoverData"
        [top]="projectPricingService.popoverTopDistance"
        [left]="projectPricingService.popoverLeftDistance"
        [maxWidth]="'250px'"
        [fadeOutTimmer]="100"
        [fadeInTimmer]="0"
        [forceFadeOut]="projectPricingService.forceFadeOut"
        [inverseHeightAdition]="18">
    </cmx-slick-popover>

</div>
