<!-- Simple Pagination -->
<ul class="pages {{ small ? 'small' : ''}}" *ngIf="!skeletonLoader && numberOfPages > 1 && numberOfPages <= maxPages">
    <li *ngFor="let page of viewPages; let i = index; trackBy: trackByFn">
        <a (click)="changePage($event.target.text, $event)" id="{{i}}" [ngClass]="'page ' + (page.selected ? 'active' : '')">{{page.pageNumber}}</a>
    </li>
    <div *ngIf="showItensByPage" class="itensByPage">
        <span>Linhas: </span>
        <input type="number" [(ngModel)]="itensByPage" (keydown.enter)="changeItensByPage($event)"/>
    </div>
</ul>

<!-- Pagination -->
<ul *ngIf="!skeletonLoader && numberOfPages > 1 && numberOfPages > maxPages" class="pages {{ small ? 'small' : ''}}">
    <li class="page arrow-left-double" (click)="changePage(1, $event)"><i class="la la-angle-double-left"></i></li>
    <li class="page arrow-left" (click)="changePage(selectedPage - 1, $event)"><i class="la la-angle-left"></i></li>
    <li *ngFor="let page of viewPages; let i = index; trackBy: trackByFn">
        <a (click)="changePage($event.target.text, $event)" id="{{i}}" [ngClass]="'page ' + (page.selected ? 'active' : '')">{{page.pageNumber}}</a>
    </li>
    <li class="page arrow-right" (click)="changePage(selectedPage + 1, $event)"><i class="la la-angle-right"></i></li>
    <li class="page arrow-right-double" (click)="changePage(numberOfPages, $event)"><i class="la la-angle-double-right"></i></li>
    <div *ngIf="showItensByPage" class="itensByPage">
        <span>Linhas: </span>
        <input type="number" [(ngModel)]="itensByPage" (keydown.enter)="changeItensByPage($event)"/>
        <span *ngIf="showPagesText" style="margin: 0px 3px 0 8px">|</span>
    </div>
    <li *ngIf="showPagesText" class="totalPages">{{numberOfPages}} páginas</li>
</ul>

<div *ngIf="skeletonLoader">
    <cmx-skeleton-loader [type]="'pagination'"></cmx-skeleton-loader>
</div>
