import { UserPreferencesService } from "#services/_user/user-preferences.service";
import { AppModule } from "app/app.module";
import { UserCacheService } from "#services/_user/app-user-cache.service";
import * as _ from 'lodash';
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { IPaymentClosingFilter } from "./interfaces/payment-closing-filter.interface";

export class PaymentClosingFilter {
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _page: number;
    private _size: number;
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);
    private _initialPaymentClosingFilter: IPaymentClosingFilter = <IPaymentClosingFilter>{};
    private _paymentClosingFilter: IPaymentClosingFilter = <IPaymentClosingFilter> {
        status: [],
        clientGroups: [],
    };

    constructor(filter?: PaymentClosingFilter) {
        this._paymentClosingFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'taxStudyFilter', this._paymentClosingFilter, filter);
        this._initialPaymentClosingFilter = _.cloneDeep(this._paymentClosingFilter);
        this._page = filter ? filter._page : 0;
        this._size = filter ? filter._size : 50;
    }

    public getStatus(): any { return this._paymentClosingFilter.status; }
    public getClientGroups(): any { return this._paymentClosingFilter.clientGroups; }
    public getPage(): number { return this._page; }
    public getSize(): number { return this._size; }

    public setStatus(status): void { this._paymentClosingFilter.status = status; }
    public setClientGroups(clientGroups): void { this._paymentClosingFilter.clientGroups = clientGroups; }
    public setPage(page): void { this._page = page; }
    public setSize(size): void { this._size = size; }

    public getRequest() {
        this._initialPaymentClosingFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialPaymentClosingFilter, this._paymentClosingFilter, 'paymentClosingFilter', this._userCacheService.getUserPreferences().id);
        let params = new HttpParams();
        let headers = new HttpHeaders();
        if (this.getStatus()?.length > 0) {
            params = params.append('field', `status::${this.getStatus().map((c) => (c.id !== c.itemName ? c.id : null)).join(',')}`);
        }
        if (this.getClientGroups()?.length > 0) {
            params = params.append('field', `clientGroups::${this.getClientGroups().map((c) => c.itemName).join(',')}`);
        }
        params = params.append('page', `${ this.getPage() }`);
        params = params.append('size', `${ this.getSize() }`);
        return { params, headers };
    }

}
