<div
    class="custom-dialog content-{{ content }} animation-{{ animation }} {{ modalType === 'info' ? 'help-dialog-info' : modalType}} "
    [ngClass]="{ 'border-top': customColorBorderTop, 'dark-theme' : userCacheService.getUserDarkTheme() }"
    [style.borderColor]="customColorBorderTop"
    [ngStyle]="dinamicStyles"
    [@anCustomModal]="!hidden ? 'show' : 'hidden'"
    *ngIf="!hidden">

    <div *ngIf="!hideHeader" class="dialog-header" [style.visibility]="fade ? 'hidden' : 'visible'">
        <div *ngIf="title; else customTitleContent">
            <h5 class="dialog-title">{{ title }}
                <span class="dialog-subtitle" [ngClass]="{ 'pointer': subtitleClickable }" *ngIf="subtitle" (click)="subClick()">{{subtitle}}</span>
            </h5>
        </div>

        <ng-template #customTitleContent>
            <ng-content select="[cmx-custom-dialog-header]"></ng-content>
        </ng-template>

        <ng-container *ngIf="closeButton">
            <span [style.flex]="'0 0 auto'"></span>
            <em (click)="close()" class="close-btn pointer la la-close"></em>
        </ng-container>
    </div>
    <em *ngIf="hideHeader" (click)="close()" class="close-btn pointer la la-close"
    style="position: absolute; right: 10px; top: 10px"></em>

    <div class="dialog-body" [ngClass]="{ 'with-actions': actions.children.length > 0 }"
        [style.visibility]="fade ? 'hidden' : 'visible'"
        [style.max-height]="modalType === 'info' ? 'calc(50vh - 65px)' : 'auto' ">
        <ng-content select="[cmx-custom-dialog-body]"></ng-content>
    </div>

    <div #actions class="dialog-actions" [ngClass]="{ 'show-actions': actions.children.length > 0 }"
        [style.visibility]="fade ? 'hidden' : 'visible'">
        <ng-content select="[cmx-custom-dialog-actions]"></ng-content>
    </div>
</div>
<div (click)="close()" class="custom-dialog-overlay" *ngIf="overlay" [ngClass]="{ hidden: hidden }"></div>
