import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import moment from 'moment';

@Injectable()
export class AutomotiveDetailDailyReportSlickGrid {

    public createColumns(filters) {
        const cols = [
            { id: 0, name: 'Chassi', width: 100, field: 'chassis', cssClass: 'impHeader', headerCssClass: 'impHeader',
            formatter: this.formatChassi },
            { id: 1, name: 'Modelo', width: 100, field: 'model', cssClass: 'impHeader', headerCssClass: 'impHeader' },
            { id: 2, name: 'Cor', width: 100, field: 'color', cssClass: 'impHeader', headerCssClass: 'impHeader' },
            { id: 3, name: this.defineExtraColumn(filters).name, field: this.defineExtraColumn(filters).field,
            cssClass: 'impHeader', headerCssClass: 'impHeader', width: 100, formatter: this.formatDate },
        ];

        return cols;
    }

    private defineExtraColumn(filters) {
        const extraColumn = { name: '', field: '' };
        switch (filters.section) {
            case 'STOCK_PORT':
            extraColumn.name = 'Data de Chegada no Porto';
            extraColumn.field = 'portInDate';
            break;
            case 'ARRIVE':
            extraColumn.name = 'Data de Chegada no CD';
            extraColumn.field = 'yardInDate';
            break;
            case 'PPO':
            extraColumn.name = 'Data de Chegada no CD';
            extraColumn.field = 'yardInDate';
            break;
            case 'INVOICING':
            extraColumn.name = 'Data de Faturamento';
            extraColumn.field = 'billingDate';
            break;
            case 'YARD_OUT':
            extraColumn.name = 'Data de Saída do CD';
            extraColumn.field = 'yardOutDate';
            break;
            case 'SUAPE_YARD':
            extraColumn.name = 'Data de chegada no CD';
            extraColumn.field = 'yardInDate';
            break;
            case 'INVOICED':
            extraColumn.name = 'Data de Faturamento';
            extraColumn.field = 'billingDate';
            break;
            case 'NOT_INVOICED':
            extraColumn.name = 'Data de Chegada no CD';
            extraColumn.field = 'arrivalDate';
            break;
        }
        return extraColumn;
    }

    private formatDate(row, cell, value, columnDef, dataContext) {
        if (value === null || value === undefined) { return '';
        } else { return `<div>${moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')}</div>`; }
    }

    private formatChassi(row, cell, value, columnDef, dataContext) {
        return `<span class='chassiValue'>${value}</span>`;
    }

}
