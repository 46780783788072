import { environment } from '#environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class UserAuthService {

    constructor(
        private http: HttpClient,
    ) { }

    public sendPasswordResetEmail(email: string, firstAccess: boolean,captchaToken, mobile: string, origin: String): Observable<any> {
        const headers = new HttpHeaders().set('X-Mobile', `${mobile}`);
        return this.http.post(`${environment.endpoints.adminService}/signIn/create-password`, {
            email: email,
            firstAccess: firstAccess,
            captchaToken: captchaToken,
            origin: origin,
        }, {headers: headers});
    }

}
