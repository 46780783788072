
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environment';

@Injectable()
export class SlaStatusService {

    constructor(private _http: HttpClient) { }

    updateJustification(request): Observable<any> {
        return this._http.put(`${environment.endpoints.slaKpiService}/sla/justification`, request, { observe: 'response', responseType: 'json' });
    }

    approveJustification(request): Observable<any> {
        return this._http.put(`${environment.endpoints.slaKpiService}/sla/justification/approval`, request, { observe: 'response', responseType: 'json' });
    }
}
