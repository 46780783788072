import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import * as _ from 'lodash';
import moment from 'moment';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IDeliveryCoordinationIndirectFilter } from '../interfaces/delivery-coordination.interface';

export class DeliveryCoordinationIndirectFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);
    private _initialDeliveryCoordinationIndirectFilter: IDeliveryCoordinationIndirectFilter = <IDeliveryCoordinationIndirectFilter>{};
    private _deliveryCoordinationIndirectFilter: IDeliveryCoordinationIndirectFilter = <IDeliveryCoordinationIndirectFilter>{
        type: { id: 'IN_PROGRESS', name: 'Em Andamento', value: 'IN PROGRESS' },
        loadingState: 'IN_PROGRESS',
        processNumber: '',
        statusList: [],
        clientGroups: [],
        roadCarrierCodes: [],
        vehicles: [],
        subsidiaryCodes: [],
    };

    private _columnFilter;
    private _startDate;
    private _endDate;
    private _page: number;
    private _size: number;

    constructor(filter?: DeliveryCoordinationIndirectFilter) {
        this._deliveryCoordinationIndirectFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'deliveryCoordinationIndirectFilter', this._deliveryCoordinationIndirectFilter, filter);
        this._initialDeliveryCoordinationIndirectFilter = _.cloneDeep(this._deliveryCoordinationIndirectFilter);
        this._columnFilter = filter ? filter._columnFilter : [];
        this._startDate = filter ? filter._startDate : moment().add(-1, 'M').format('DD/MM/YYYY');
        this._endDate = filter ? filter._endDate : moment().add(2, 'M').format('DD/MM/YYYY');
        this._page = filter ? filter._page : 0;
        this._size = filter ? filter._size : 50;
    }

    getProcessNumber() { return this._deliveryCoordinationIndirectFilter.processNumber }
    getStatusDelivery() { return this._deliveryCoordinationIndirectFilter.statusList }
    getClientGroups() { return this._deliveryCoordinationIndirectFilter.clientGroups }
    getRoadCarriers() { return this._deliveryCoordinationIndirectFilter.roadCarrierCodes }
    getVehicles() { return this._deliveryCoordinationIndirectFilter.vehicles }
    getSubsidiaries() { return this._deliveryCoordinationIndirectFilter.subsidiaryCodes }
    getColumnFilter() { return this._columnFilter }
    getStartDate() { return this._startDate }
    getEndDate() { return this._endDate }
    getType() { return this._deliveryCoordinationIndirectFilter.type }
    getPage(): number { return this._page; }
    getSize(): number { return this._size; }

    setProcessNumber(processNumber): void { this._deliveryCoordinationIndirectFilter.processNumber = processNumber }
    setStatusDelivery(statusList): void { this._deliveryCoordinationIndirectFilter.statusList = statusList }
    setClientGroups(clientGroups): void { this._deliveryCoordinationIndirectFilter.clientGroups = clientGroups }
    setRoadCarriers(roadCarrierCodes): void { this._deliveryCoordinationIndirectFilter.roadCarrierCodes = roadCarrierCodes }
    setVehicles(vehicles): void { this._deliveryCoordinationIndirectFilter.vehicles = vehicles }
    setSubsidiaries(subsidiaryCodes): void { this._deliveryCoordinationIndirectFilter.subsidiaryCodes = subsidiaryCodes }
    setColumnFilter(columnFilter): void { this._columnFilter = columnFilter }
    setStartDate(startDate): void { this._startDate = startDate }
    setEndDate(endDate): void { this._endDate = endDate }
    setType(type) { this._deliveryCoordinationIndirectFilter.type = type; }
    setPage(page): void { this._page = page; }
    setSize(size): void { this._size = size; }

    updatePreferences() {
        this._initialDeliveryCoordinationIndirectFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialDeliveryCoordinationIndirectFilter, this._deliveryCoordinationIndirectFilter, 'deliveryCoordinationIndirectFilter', this._userCacheService.getUserPreferences().id);
    }

    getRequest() {
        this.updatePreferences();

        let params = new HttpParams();
        let headers = new HttpHeaders();

        params = params.append('loadingState', this.getType()?.id);

        if (this.getProcessNumber()) {
            params = params.append('processNumber', this.getProcessNumber());
        } else {
            headers = headers.set('X-Requested-Client-Groups', this.getClientGroups().map((c) => c['id']).join(','));
            params = params.append('statusList', `${(this.getStatusDelivery().map((c) => c['id']).join(',')) || []}`);
            params = params.append('roadCarrierCodes', `${(this.getRoadCarriers().map((c) => c['id']).join(',')) || []}`);
            params = params.append('vehicles', `${(this.getVehicles().map((c) => c['id']).join(',')) || []}`);
            params = params.append('branchCodes', `${(this.getSubsidiaries().map((c) => c['id']).join(',')) || []}`);
            params = params.append('startDate', `${moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`);
            params = params.append('endDate', `${moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`);

            if (this.getColumnFilter()) {
                this.getColumnFilter().map((c) => {
                    params = params.append(`${c.field}`, `${c.value}`);
                });
            }
        }

        params = params.append('page', `${ this.getPage() }`);
        params = params.append('size', `${ this.getSize() }`);

        this.removeNullValuesFromQueryParams(params);
        return { headers, params };
    }

    removeNullValuesFromQueryParams(params: HttpParams) {
        const paramsKeysAux = params.keys();
        paramsKeysAux.forEach((key) => {
          const value = params.get(key);
          if (value === null || value === undefined || value === '') {
            params['map'].delete(key);
          }
        });

        return params;
      }
}
