export const DINAMIC_ATRIBUTE_VALUES = {
    sku: 'code', skuCustomer: 'code',
    vendors: 'vendorName',
    countryFrom: 'countryFrom',
    productDesc: 'description',
    line: 'line',
    family: 'family',
    brand: 'brand',
    collection: 'collection',
    productQuantity: 'quantity',
    unitMeasureDescription: 'unitMeasureDescription',
    ncms: 'ncmCode',
    vendorInvoiceNumber: 'invoiceNumber',
    vendorInvoiceDate: 'invoiceDate',
    clientInvoiceNumber: 'invoiceNumber',
    clientInvoiceDate: 'invoiceDate',
    cfops: 'cfopCode',
    iiTaxPercentage: 'iiTaxPercentage',
    pisTaxPercentage: 'pisTaxPercentage',
    ipiTaxPercentage: 'ipiTaxPercentage',
    cofinsTaxPercentage: 'cofinsTaxPercentage',
    icmsTaxPercentage: 'icmsTaxPercentage',
    itemSummarizedApplication: 'application',
    itemSummarizedExternalCode: 'externalCode',
    itemSummarizedBarCode: 'barCode',
    clientInvoiceAccessKey: 'accessKey',
    automotiveManufacturingYear: 'automotiveManufacturingYear',
    automotiveModelYear: 'automotiveModelYear',
    automotiveMotorNumber: 'automotiveMotorNumber',
    automotiveColor: 'automotiveColor',
}

export const DINAMIC_GENERAL_MONETARY_VALUES = [
    'usFob', 'usFreight', 'usInsurance', 'discountIcms'
]