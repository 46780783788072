import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';

import { ITimelineItems } from './interfaces/cmx-timeline.interface';

@Component({
    selector: 'cmx-timeline',
    templateUrl: './cmx-timeline.component.html',
    styleUrls: ['./cmx-timeline.component.scss'],
})
export class CmxTimelineComponent implements OnInit {
    @Input() items: ITimelineItems[] = [];
    @Input('format-date-input') formatDateInput: string = 'YYYY-MM-DD';
    @Input('format-date-output') formatDateOutput: string = 'DD/MM/YYYY';
    @Input('timeline-animated') timelineAnimated: boolean = false;

    errorMessage: string = null;

    constructor() {}

    ngOnInit() {
        if (this.items.length > 0) {
            this.items.forEach((item, index) => {
                if (!item.description) {
                    return (this.errorMessage = 'É obrigatório que os itens da timeline possua uma descrição');
                }

                if (!item.author) {
                    return (this.errorMessage = 'É obrigatório que os itens da timeline possua um autor');
                }

                this.items[index] = {
                    ...item,
                    dateItem: item.dateItem
                        ? moment(item.dateItem, this.formatDateInput).format(this.formatDateOutput)
                        : null,
                    animateDelay: 100 * (index + 1),
                };
            });
        }
    }
}
