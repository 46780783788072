
import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';

import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { ForecastStatusFilter } from 'app/theme/layouts/operational/forecast-by-status/models/forecast-status-filter.model';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';

import { UtilsService } from '#services/_utils/utils.service';
import { UserService } from 'app/auth/_services';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { ForecastByStatusService } from './forecast-by-status.service';

import { IGridColumn, IGridRow } from 'app/shared/components/cmx-custom-grid/interfaces';
import { IForecastByStatusResponse, IForecastByStatusResponseData } from 'app/shared/interfaces/forecast-by-status.interface';
import { IStatusDomain } from 'app/shared/interfaces/status-domain.interface';

@Component({
    selector: 'forecast-by-status',
    templateUrl: './forecast-by-status.component.html',
    styleUrls: ['./forecast-by-status.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ForecastByStatusComponent implements OnInit, OnDestroy {

    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);
    allStatus: IStatusDomain[];
    clientOptions: DropdownOption[] = [];
    filterState: ForecastStatusFilter;
    gridColumns: IGridColumn[];
    gridRows: IGridRow[];
    loading: boolean;
    loadingClientsStatus: boolean;
    selectedStatusCodes: { [key: string]: boolean };
    data: IForecastByStatusResponseData[];
    currentUser$;
    helpDescription = this._defineHelpDescription();

    private _generatedId = 0;
    private _subscription;

    @HostListener('click') onSetZIndex() {
        this.renderer2.setStyle(this._elementRef.nativeElement, 'z-index', this._utilsService.autoIncrementValue);
    }

    constructor(
        private _forecastByStatusService: ForecastByStatusService,
        private _utilsService: UtilsService,
        private _userService: UserService,
        private _userCacheService: UserCacheService,
        private _elementRef: ElementRef,
        private renderer2: Renderer2
    ) {
        this.filterState = new ForecastStatusFilter();
        this.loading = true;
        this.loadingClientsStatus = true;
        this.currentUser$ = this._userService.currentUser$;
    }

    ngOnInit(): void {
        this._loadClientsAndStatus();
    }

    ngOnDestroy(): void {
        if (this._subscription) { this._subscription.unsubscribe() }
    }

    identify(_, item): string {
        return item.code;
    }

    applyClientsFilter(): void {
        this._loadForecastByStatus();
    }

    toogleStatus(status: IStatusDomain) {
        if (this.selectedStatusCodes[status.code]) {
            const index = this.filterState.status.findIndex((s) => s.code === status.code);
            this.filterState.status.splice(index, 1);
        } else {
            this.filterState.status.push(status);
        }

        this.selectedStatusCodes[status.code] = !this.selectedStatusCodes[status.code];
        this._loadForecastByStatus();
    }

    selectAllStatus(): void {
        if (this.allStatus.length !== this.filterState.status.length) {
            this.filterState.status = _.cloneDeep(this.allStatus);

            for (const key of Object.keys(this.selectedStatusCodes)) {
                this.selectedStatusCodes[key] = true;
            }

            this._loadForecastByStatus();
        }
    }

    private _toDropdownItemWithCode(e: any): DropdownOption {
        return new DropdownOption(e.code, `${e.code} - ${e.name}`);
    }

    private _toDropdownItem(e: any): DropdownOption {
        return new DropdownOption(e.code, `${e.name}`);
    }

    private _toDropdownItems(items: any[], includeCode = true): DropdownOption[] {
        return !_.isNil(items) ? items.map(includeCode ? this._toDropdownItemWithCode : this._toDropdownItem) : [];
    }

    private _loadClientsAndStatus(): void {
        if (this._subscription) { this._subscription.unsubscribe() }
        this.loadingClientsStatus = true;
        this._subscription = this._forecastByStatusService
            .getForecastStatusTypes()
            .subscribe((statusTypes: IStatusDomain[]) => {
                const clientGroups = this._userCacheService.getClientGroups();
                this.clientOptions = this._toDropdownItems(clientGroups, false);
                this.allStatus = statusTypes;
                this.selectedStatusCodes = this.filterState.status.reduce((accumulator, currentValue: IStatusDomain) => {
                    return { ...accumulator, [currentValue.code]: true };
                }, {});
                this._loadForecastByStatus();
                this.loadingClientsStatus = false;
            }, ((error) => {
                this.loadingClientsStatus = false;
                this._utilsService.errorHandler(error, 'Previsão de Faturamento');
            }));
    }

    private _loadForecastByStatus(): void {
        if (this._subscription) { this._subscription.unsubscribe() }
        this.loading = true;
        const { params, headers } = this.filterState.getRequest(_.cloneDeep(this.allStatus));
        this._subscription = this._forecastByStatusService
            .getForecastByStatus(params.status, headers.clientCodes)
            .subscribe((response: IForecastByStatusResponse) => {
                this.data = response && response.data;
                if (this.data && this.data.length) {
                    this._generatedId = 0;
                    this.gridColumns = this._createColumns();
                    this.gridRows = this._createRows(response.data);
                }
                this.loading = false;
            }, ((error) => {
                this.loading = false;
                this._utilsService.errorHandler(error, 'Previsão de Faturamento');
            }));
    }

    private _createColumns(): IGridColumn[] {
        const firstColumn = {
            field: 'cr',
            name: 'CR',
            formatterType: 'collapse',
            width: 180,
        };

        const centerColumns = Object.keys(this.selectedStatusCodes)
            .filter((key) => this.selectedStatusCodes[key])
            .map((key) => {
                const name = `<img
                width='35px'
                height='auto'
                src='assets/img/icons/${key.toLowerCase()}-checked.png'
                alt='${key} Status Icon'
            />`;

                return {
                    field: key,
                    name,
                    formatterType: 'bold',
                    width: 150,
                };
            });

        const totalColumn = {
            field: 'total',
            name: '<span style="font-weight: bold;">Total</span>',
            cssClass: 'grid-cell font-weight-bold',
            width: 180,
        };

        return [firstColumn, ...centerColumns, totalColumn].map((column) => ({
            ...column,
            headerCssClass: 'grid-header align-center height-40 font-size-14',
        })) as IGridColumn[];
    }

    private _createRows(data: IForecastByStatusResponseData[], parentNodeId?: number): IGridRow[] {
        return data.reduce((accumulator: IGridRow[], currentValue: any) => {
            const code = currentValue.profitCenterCode;
            const name = currentValue.profitCenterName || currentValue.clientName;
            const children = currentValue.clients || [];
            const total = this._calculateTotal(Object.values(currentValue.valueByStatus));
            const totalLabel =
                total && total > 0 ? `${this._utilsService.formatNumber(total, { style: 'currency' })}` : '\u2014';
            const valueByStatus = Object.keys(currentValue.valueByStatus).reduce((acc, currentKey) => {
                const value =
                    currentValue.valueByStatus[currentKey] && currentValue.valueByStatus[currentKey] > 0
                        ? `${this._utilsService.formatNumber(currentValue.valueByStatus[currentKey], {
                              style: 'currency',
                          })}`
                        : '\u2014';

                return { ...acc, [currentKey]: value };
            }, {});

            const dataRow = {
                id: this._generatedId,
                cr: `<span style='font-weight: bold;'>${
                    name !== 'TOTAL' && code && code !== 'TOTAL' ? `${code} - ` : ''
                }${name}</span>`,
                parent: parentNodeId,
                ...valueByStatus,
                total: totalLabel,
                bold: name === 'TOTAL',
            };
            _.assign(dataRow, _.isNil(parentNodeId) ? { _collapsed: true, alone: !children.length } : { isLeaf: true });
            const dataField = [...accumulator, dataRow];
            this._generatedId = this._generatedId + 1;
            if (children.length) {
                return [...dataField, ...this._createRows(children, this._generatedId - 1)];
            }
            return dataField;
        }, []);
    }

    private _calculateTotal(values: number[]): number {
        return values.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }

    private _defineHelpDescription(): object[] {
        return [
            { type: 'text',hasLineAfter: true, value: "Informa a previsão de Faturamento por status e cliente."},
            { type: 'text', value: "Cálculo da previsão de faturamento : Valor total da invoice X média do fator de importação dos últimos 12 meses X Ptax da moeda da invoice."}
        ]
    }


}
