import { BehaviorSubject } from 'rxjs';

export function GetterSetter(defaultValue = null): any {
    return (target, key) => {
        const accessor = `${key}$`;
        const secret = `_${key}$`;

        Object.defineProperty(target, accessor, {
            get() {
                if (!this[secret]) {
                    this[secret] = new BehaviorSubject(defaultValue);
                }
                return this[secret];
            },
            set() {
                throw new Error('You cannot set this property if you use @GetterSetter');
            },
        });

        Object.defineProperty(target, key, {
            get() {
                return this[accessor].getValue();
            },
            set(value: any) {
                this[accessor].next(value);
            },
        });
    };
}
