import { Component } from '@angular/core';
import { IGridColumn, IGridRow } from 'app/shared/components/cmx-custom-grid/interfaces';
import { PO_DETAILS_SLICK_COLUMNS } from './constants/slick-columns.constant';
import { PoStatusService } from '../../po-status.service';

@Component({
    selector: 'po-status-imps',
    templateUrl: './po-status-imps.component.html',
    styleUrls: ['./po-status-imps.component.scss'],
})
export class PoStatusImpsComponent {

    gridColumns: IGridColumn[] = PO_DETAILS_SLICK_COLUMNS;
    gridRows: IGridRow[];

    constructor(public poStatusService: PoStatusService) { }

    onGridCallback({ grid, dataView }): void {
        grid.onClick.subscribe((e, _) => {
            const cell = grid.getCellFromEvent(e);
            const cellData = dataView.getItem(cell.row);
            if (cellData.imp) {
                window.open(`./supply-chain/importacao/0/${cellData.imp}`);
            }
        });
    }

}
