<div *ngIf="poStatusService.asyncData">
    <loader></loader>
</div>

<div *ngIf="!poStatusService.asyncData"
    style="height: 100%; overflow: auto;">
    <cmx-assembled-data
        [data]="poStatusService.getPoDetail()"
    ></cmx-assembled-data>

    <div class="separator">
        <span class="title">Datas da P.O</span><hr>
    </div>
    
    <cmx-assembled-data
        [data]="poStatusService.getPoDates()"
    ></cmx-assembled-data>
</div>
