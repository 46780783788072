import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, ElementRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import Inputmask from 'inputmask';
import * as _ from 'lodash';
import moment from 'moment';

import { environment } from '#environment';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { SlaConsolidationFilter } from 'app/theme/layouts/charts/sla-consolidation/slaConsolidationFilter.model';

import { FilterStateService } from '#services/_filters/filter-state.service';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { UserService } from '../../../../auth/_services/user.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { CountdownService } from '#services/_countdown/countdown.service';
import { DownloadService } from '#services/_download/download.service';
import { UserPermissionBlockerService } from '#services/_user/userPermissionBlocker.service';
import { UtilsService } from '#services/_utils/utils.service';
import { SlaStatusService } from './sla-status.service';
import { SlaStatusFilter } from './sla-status.filter.model';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';
import { MODALS } from 'app/shared/constants/modals.constants';

declare let Slick: any;

@Component({
    selector: 'sla-status',
    templateUrl: './sla-status.component.html',
    styleUrls: ['./../charts.scss', './sla-status.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [SlaStatusService],
})
export class SlaStatusComponent {
    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);

    readonly dropdownSettingsCategories = {
        singleSelection: true,
        text: 'Selecionar Categoria',
        enableSearchFilter: false,
    };

    queryId = Math.random();
    currentUser$ = this.userService.currentUser$;
    currentUser = this.userService.getCurrentUser();
    slaTypeSwitch = false; // Criação
    moment = moment;
    componentId: string = `ImpSlaGrid-${String(Math.round(Math.random() * 1000000000))}`;
    asyncSlaTable = true;
    asyncJustification = true;
    subscription;
    dbClick = false;
    editableElements = [`#${this.componentId}`, '#searchLine', 'pagination'];
    gridData;
    placeholderSearch;

    validDate = true;
    startDate = moment().add(-30, 'days').format('DD/MM/YYYY');
    endDate = moment().format('DD/MM/YYYY');

    kpis = [];
    columns = [];
    rows = [];
    page = 0;
    offset = 0;
    limit = 50;
    pages = [];
    data;
    noContent = false;
    hasApproval = false;
    references;
    isCalculate: boolean = true;

    slaSearch = '';
    refSearch = '';
    selectedImp;
    selectedClient;
    selectedClientCode;
    selectedHistoric;
    selectedCompanyCode;
    tableKpis;
    kpiList = [];
    selectedSla = {
        slaCode: '',
        accomplished: 0,
        expected: 0,
        index: 0,
        dateParam1: '',
        dateParam2: '',
        dateValue1: '',
        dateValue2: '',
        kpi: '',
        status: '',
        titleParam1: '',
        titleParam2: '',
        kpiName: '',
        kpiCode: '',
        slaId: '',
        category: { code: '', description: '' },
        justification: {
            hasApproval: false,
            comment: '',
            reason: {
                reasonCode: '',
                reasonName: '',
            },
        },
        justificationStatus: '',
        reason: '',
        reasons: [],
    };
    kpiJustifications;
    newReason = true;

    selectedJustification;
    selectedCell;
    fromHistory;
    columnName;
    countUnjustified: number;
    countPreJustified: number;
    countJustified: number;

    options: {
        client: DropdownOption[];
        kpi: DropdownOption[];
        category: DropdownOption[];
        selectedCategory: DropdownOption[];
        modal: DropdownOption[];
    } = {
        client: [],
        kpi: [],
        category: [],
        selectedCategory: [],
        modal: [],
    };

    auxImps = [];

    asyncExport = false;
    total: number = 0;

    filterState: SlaStatusFilter = this._filterStateService.getSlaStatusFilter();
    datamartFilters = this._determineDatamartFilters(this.currentUser);

    private dataView = null;
    private slaGrid = null;

    private automotiveRequest = false;
    private _consolidationfilterState: SlaConsolidationFilter = this._filterStateService.getSlaConsolidationFilter();

    constructor(
        public countdown: CountdownService,
        private http: HttpClient,
        private userService: UserService,
        private downloadService: DownloadService,
        private el: ElementRef,
        private permissionBlocker: UserPermissionBlockerService,
        private router: Router,
        private utilsService: UtilsService,
        private userCacheService: UserCacheService,
        private _filterStateService: FilterStateService,
        private _cmxDropdownService: CmxDropdownService,
        private _slaStatusService: SlaStatusService,
        private _customDialogService: CustomDialogService,
    ) {
        const url = window.location.href;
        if (url.includes('/result') && this._consolidationfilterState.getSelectedKpi()) {
            this.startDate = this._consolidationfilterState.getStartDate();
            this.endDate = this._consolidationfilterState.getEndDate();
            this.filterState.setTypeSwitch(this._consolidationfilterState.getReferenceDateType());
            this.filterState.setClientGroups(this._consolidationfilterState.getClientGroups());
            this.filterState.setSelectedKpi(this._cmxDropdownService.toDropdownItems([
                this._consolidationfilterState.getSelectedKpi(),
            ]));
        }

        this.placeholderSearch = `Buscar ${this.filterState.getTypeSearch()}`;
    }

    ngOnDestroy() {
        $(document).off('keyup');
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        if (this.filterState.getTypeSwitch() === 'invoiceDate') {
            $('#searchLine .toogleLeft').addClass('clicked');
        }

        this.updateDateInputs();
        $(document).ready(() => {
            $(document).on('keyup', this.enterBtn.bind(this));
            $(document).click((e: any) => {
                const parent = e.target.offsetParent;
                if (!$(parent).hasClass('searchInput') && !$(parent).hasClass('searchOptions')) {
                    $('sla-status .searchOptions').addClass('hidden');
                }
            });
        });
        this.options.client = this._cmxDropdownService.toDropdownItems(this.userCacheService.getClientGroups());
        this.options.modal = this._cmxDropdownService.toDropdownItemsImg(MODALS);

        this.getKPI().subscribe((kpiList) => {
            this.kpiJustifications = kpiList;
        });

        $(document).ready(() => {
            $(document).on('keyup', this.enterBtn.bind(this));
        });
        this.slaTypeSwitch = true; // Faturamento
        this.loadImpSlaTable(false);
    }

    verifyAutomotiveMode(user) {
        return (this.automotiveRequest =
            (user.has('AUTOMOTIVE_USER') && !this.currentUser.has('INTERNAL_FILTERS')) ||
            (user.has('ROUTE_AUTOMOTIVE') && this.filterState.getAutomotiveMode() === 'Ligado')
                ? true
                : false);
    }

    toggleFilterMode() {
        this.isCalculate = true;
        if ($('sla-status .filterMode').hasClass('clicked')) {
            this.filterState.setAutomotiveMode('Desligado');
            this.filterState.setTypeSearch(this.currentUser.has('INTERNAL_FILTERS') ? 'IMP' : 'Ref');
            this.placeholderSearch = `Buscar ${this.filterState.getTypeSearch()}`;
            $('sla-status .filterMode').removeClass('clicked');
        } else {
            this.filterState.setAutomotiveMode('Ligado');
            $('sla-status .filterMode').addClass('clicked');
        }

        this.resetPages();
        this.loadImpSlaTable(false, this.isCalculate);
    }

    enterBtn(element) {
        if (element.keyCode === 13 && this.validDate) {
            if (element.currentTarget.URL.indexOf('/performance/sla-status') >= 0 || $('sla-status .searchItem').is(':focus')) {
                $('sla-status .searchOptions').addClass('hidden');
                this.applyFilter();
            }
        }
    }

    changeSearch() {
        $('sla-status .searchOptions').hasClass('hidden')
            ? $('sla-status .searchOptions').removeClass('hidden')
            : $('sla-status .searchOptions').addClass('hidden');
    }

    selectSearchType(element, type) {
        $('sla-status .searchItem').removeClass('selected');
        $(element.target).addClass('selected');
        this.filterState.setTypeSearch(type);
        this.placeholderSearch = `Buscar ${this.filterState.getTypeSearch()}`;
    }

    toogleStatus(element) {
        this.isCalculate = true;
        if ($(element.target).hasClass('checked')) {
            if (element.target.id === 'EXPIRED') {
                this.filterState.setToogleJustify([]);
                $('#UNJUSTIFIED').removeClass('checked');
                $('#PREJUSTIFIED').removeClass('checked');
                $('#JUSTIFIED').removeClass('checked');
            }
            $(element.target).removeClass('checked');
        } else {
            if (element.target.id === 'EXPIRED') {
                this.filterState.setToogleJustify(['UNJUSTIFIED', 'PREJUSTIFIED', 'JUSTIFIED']);
                $('#UNJUSTIFIED').addClass('checked');
                $('#PREJUSTIFIED').addClass('checked');
                $('#JUSTIFIED').addClass('checked');
            }
            $(element.target).addClass('checked');
        }
        if (this.filterState.getToggleStatus().includes(element.target.id)) {
            this.filterState.setToggleStatus(this.filterState.getToggleStatus().filter((status) => status !== element.target.id));
        } else {
            this.filterState.getToggleStatus().push(element.target.id);
        }

        if (this.filterState.getToggleStatus().includes("")) {
            this.filterState.setToggleStatus(this.filterState.getToggleStatus().filter((status) => status !== ""));
        }

        this.resetPages();
        this.loadImpSlaTable(false, this.isCalculate);
    }

    toggleTypeSwitch($event) {
        if ($('#searchLine .toogleLeft').hasClass('clicked')) {
            this.filterState.setTypeSwitch('impIssueDate');
            $('#searchLine .toogleLeft').removeClass('clicked');
        } else {
            this.filterState.setTypeSwitch('invoiceDate');
            $('#searchLine .toogleLeft').addClass('clicked');
        }
    }

    toogleJustify(element) {
        this.isCalculate = false;
        if ($(element.target).hasClass('checked')) {
            $(element.target).removeClass('checked');
        } else {
            $(element.target).addClass('checked');
        }
        if (this.filterState.getToogleJustify().includes(element.target.id)) {
            this.filterState.setToogleJustify(this.filterState.getToogleJustify().filter((status) => status !== element.target.id));
        } else {
            this.filterState.getToogleJustify().push(element.target.id);
        }

        this.resetPages();
        this.loadImpSlaTable(false, this.isCalculate);
    }

    openReasonModal() {
        // tslint:disable:no-string-literal
        $('.reasonArea').removeClass('hidden');
    }

    closeReasonModal() {
        this.loadImpSlaTable(false, true);
        $('.reasonArea').addClass('hidden');
        this.hideMsg();
    }

    closeSlaModal() {
        $('.slaModal').addClass('hidden');
    }

    applyFilter() {
        this.resetPages();
        this.loadImpSlaTable(false, true);
    }

    sendJustification(type) {
        this.hideMsg();

        if (this.options.selectedCategory[0] === undefined) {
            $('sla-status .error.kpi').removeClass('hidden');
            return;
        } else if (this.selectedJustification === '' || this.selectedJustification === null || this.selectedJustification.trim() === '') {
            $('sla-status .error.reason').removeClass('hidden');
            return;
        }

        this.asyncJustification = false;

        let request = this._getJustificationParams();

        if (type === 'add' || type === 'edit') {
            this._slaStatusService.updateJustification(request).subscribe(
                (res) => {
                    this._updateJustification();
                    type === 'add' ? $('sla-status .success.created').removeClass('hidden') : $('sla-status .success.edited').removeClass('hidden');
                },
                (error) => {
                    this.utilsService.errorHandler(error, 'Justificativas SLA');
                    this.asyncJustification = true;
                },
                () => {
                    this.asyncJustification = true;
                }
            );
        }

        if (type === 'approve') {
            this._slaStatusService.approveJustification(request).subscribe(
                (res) => {
                    this._updateJustification();
                    $('sla-status .success.approved').removeClass('hidden');
                },
                (error) => {
                    this.utilsService.errorHandler(error, 'Justificativas SLA');
                    this.asyncJustification = true;
                },
                () => {
                    this.asyncJustification = true;
                }
            );
        }
    }

    hideMsg() {
        $('sla-status .success').addClass('hidden');
        $('sla-status .error').addClass('hidden');
    }

    loadImpSlaTable(autoLoad, isCalculate?) {
        if (this.permissionBlocker.permissionValidator(this.el.nativeElement.tagName)) {
            this.asyncSlaTable = true;
            this.noContent = false;
            if (this.subscription) {
                this.subscription.unsubscribe();
            }
            if (!autoLoad) {
                this.utilsService.setInvisible('sla-status', this.editableElements);
                this.asyncSlaTable = false;
            }
            this.subscription = this.getSla().subscribe(
                (imps: any) => {
                    this.gridData = imps;
                    this.pages = Array.apply(null, { length: imps.totalPages }).map(Number.call, Number);

                    if (imps.content.length > 0) {
                        this.options.kpi = this._cmxDropdownService.toDropdownItems(
                            _.orderBy(imps.content[0].kpis, 'code')
                        );

                        const slaImps = imps.content[0].imps;
                        if (imps.content.length === 0 || imps.content[0].justificationReport == null) {
                            this.countUnjustified = 0;
                            this.countPreJustified = 0;
                            this.countJustified = 0;
                        } else if (isCalculate) {
                            this.countUnjustified = imps.content[0].justificationReport[0].countOfSlaUnjustified || 0;
                            this.countPreJustified = imps.content[0].justificationReport[0].countOfSlaPreJustified || 0;
                            this.countJustified = imps.content[0].justificationReport[0].countOfSlaJustified || 0;
                        }

                        $('sla-status .subCheckmark .count').removeClass('invisible');
                        this.auxImps = slaImps;
                        this.asyncSlaTable = true;
                        this.data = this.prepareData();
                        this.columns = this.data.cols;
                        this.rows = this.data.rows;

                        const options = {
                            defaultColumnWidth: 120,
                            editable: false,
                            enableCellNavigation: true,
                            enableTextSelectionOnCells: false,
                            rowHeight: 35,
                            forceFitColumns: false,
                            frozenColumn: 1,
                        };

                        this.dataView = new Slick.Data.DataView({ inlineFilters: false });
                        this.dataView.beginUpdate();
                        this.dataView.setItems(this.rows);
                        this.dataView.endUpdate();

                        this.slaGrid = new Slick.Grid(`#${this.componentId}`, this.dataView, this.columns, options);

                        this.slaGrid.onCellChange.subscribe(
                            function (e, args) {
                                this.dataView.updateItem(args.item.id, args.item);
                            }.bind(this)
                        );

                        this.dataView.onRowCountChanged.subscribe(
                            function (e, args) {
                                this.grid.updateRowCount();
                                this.grid.render();
                            }.bind(this)
                        );

                        this.dataView.onRowsChanged.subscribe(
                            function (e, args) {
                                this.grid.invalidateRows(args.rows);
                                this.grid.render();
                            }.bind(this)
                        );

                        this.slaGrid.onClick.subscribe(_.debounce(this.onClick.bind(this), 200));
                        this.slaGrid.onDblClick.subscribe(this.onDblClick.bind(this));

                        this.utilsService.setVisible('sla-status', this.editableElements);
                    } else {
                        this.asyncSlaTable = true;
                        this.noContent = true;
                        this.utilsService.setVisible('sla-status', this.editableElements);
                        this.options.kpi = _.cloneDeep(this.filterState.getSelectedKpi());
                    }
                },
                (error) => {
                    this.gridData = undefined;
                    this.asyncSlaTable = true;
                    this.noContent = true;
                    this.options.kpi = _.cloneDeep(this.filterState.getSelectedKpi());
                    this.utilsService.setVisible('sla-status', this.editableElements);
                    this.utilsService.errorHandler(error, 'SLA Status');
                }
            );
        }
    }

    selectFromHistoric(element, index) {
        this.fromHistory = true;
        $('.slaModal table td').removeClass('selected');
        if ($(element.target).is('td')) {
            $(element.target).addClass('selected');
        } else if ($(element.target).is('img') || $(element.target).is('span') || $(element.target).is('div')) {
            $(element.target.offsetParent).addClass('selected');
        }
        const selected = this.selectedHistoric[index];
        this.columnName = selected.columnName;
        this.selectedSla = selected;
        const updatedSla = this.data.rows[this.selectedCell.row][this.columnName];
        this.selectedSla.kpiName = selected.kpi.name;
        this.selectedSla.kpiCode = selected.kpi.code;
        this.selectedSla.slaId = selected.slaId;
        if (updatedSla.justification === null) {
            this.selectedJustification = '';
            this.options.selectedCategory = [];
            this.newReason = true;
        } else {
            this.newReason = false;
            this.selectedJustification = updatedSla.justification.comment;
            this.options.selectedCategory = this.CategorytoDropdownItems([updatedSla.justification.reason], false);
        }
        this.kpiJustifications.kpis.map((kpi) => {
            if (kpi.code === this.selectedSla.kpiCode) {
                this.selectedSla.reasons = kpi.reasons;
                this.options.category = this.CategorytoDropdownItems(kpi.reasons, false);
            }
        });
    }

    toImpDetail() {
        if (this.selectedCompanyCode && this.selectedImp) {
            const companyCode = this.selectedCompanyCode.toString().padStart(2, '0');
            if (this.currentUser.has('ROUTE_SUPPLY_CHAIN_IMP_DETAIL')) {
                if (this.isIE()) {
                    this.router.navigate([`./supply-chain/importacao/${companyCode}/${this.selectedImp}`]);
                } else {
                    window.open(`./supply-chain/importacao/${companyCode}/${this.selectedImp}`);
                }
            }
        }
    }

    closeRefs() {
        $('.RefModal').addClass('hidden');
    }

    export(type: any) {
        this.filterState.updatePreferences();
        this.asyncExport = true;
        const options = this.downloadService.getSourceOptions();
        const clientGroups = this.getClientGroups();
        let params = this._getDefaultParams();

        if (type === 'csv') {
            // CSV
            options.headers = new HttpHeaders({
                'X-Requested-Client-Groups': clientGroups,
                Accept: 'text/csv',
            });
        } else {
            // XLS
            options.headers = new HttpHeaders({
                'X-Requested-Client-Groups': clientGroups,
                Accept: 'application/vnd.ms-excel',
            });
        }

        if ((this.filterState.getTypeSearch() === 'IMP' || this.filterState.getTypeSearch() === 'Chassi') && this.slaSearch !== '') {
            params = params.append('startDate', '2016-01-01');
            params = params.append('endDate', moment().format('YYYY-MM-DD'));
        } else {
            params = params.append('startDate', moment(this.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
            params = params.append('endDate', moment(this.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
            params = params.set('slaStatus', this.filterState.getToggleStatus().join(','));
            params = params.append('kpis', this.filterState.getSelectedKpi().map((kpi) => kpi.id).join(','));

            if (this.filterState.getToggleStatus().length == 1 && this.filterState.getToggleStatus()[0] === 'EXPIRED')
                params = params.append('justified', this.filterState.getToogleJustify().join(','));
        }

        this.http.post(`${environment.endpoints.slaKpiService}/slas/imps/async`, params, options).subscribe(
            () => {
                this.downloadService.onAsyncReport();
                this.asyncExport = false;
            },
            (error) => {
                this.utilsService.errorHandler(error, 'SLA Status');
            }
        );
    }

    changePage(pageNumber) {
        $(`sla-status #${this.componentId} .grid-canvas`).addClass('hidden');
        this.offset = pageNumber - 1;
        this.loadImpSlaTable(false);
    }

    verifyDates(datePicker, date) {
        if (date.replace(/\D/g, '').length === 8) {
            if (datePicker === 'startDate') {
                if (moment('01/01/2019', 'DD/MM/YYYY').isAfter(moment(date, 'DD/MM/YYYY'))) {
                    this.startDate = '01/01/2019';
                    this.updateDateInputs();
                } else if (moment(date, 'DD/MM/YYYY').isAfter(moment(this.endDate, 'DD/MM/YYYY'))) {
                    this.endDate = this.startDate = date;
                    setTimeout(() => {
                        this.updateDateInputs();
                    }, 300);
                } else {
                    this.startDate = date;
                    this.updateDateInputs();
                }
            } else if (moment(this.startDate, 'DD/MM/YYYY').isAfter(moment(date, 'DD/MM/YYYY'))) {
                this.startDate = this.endDate = date;
                setTimeout(() => {
                    this.updateDateInputs();
                }, 300);
            } else {
                this.endDate = date;
                this.updateDateInputs();
            }
        }
    }

    clickCheckbox() {
        this.hasApproval = !this.hasApproval;
    }

    verifyJustification() {
        if (this.selectedSla.status === 'EXPIRED') {
            if (
                this.currentUser.has('SLA_KPI_JUSTIFICATION') &&
                this.selectedSla.justificationStatus === 'UNJUSTIFIED'
            ) {
                return true;
            }

            // Analista Operacional e Analista Automotivo (Pré-Justificados)
            if (
                this.currentUser.has('SLA_KPI_EDIT_JUSTIFICATION') &&
                !this.currentUser.has('SLA_KPI_APPROVE_JUSTIFICATION') &&
                this.selectedSla.justificationStatus === 'PREJUSTIFIED'
            ) {
                return true;
            }

            // Gestor Operacional e Gestor Automotivo (Justificados/Pré-Justificados)
            if (
                this.currentUser.has('SLA_KPI_APPROVE_JUSTIFICATION') &&
                (this.selectedSla.justificationStatus === 'PREJUSTIFIED' ||
                    this.selectedSla.justificationStatus === 'JUSTIFIED')
            ) {
                return true;
            }
        }

        return false;
    }

    verifyIgnoreResult() {
        if (
            this.currentUser.has('INTERNAL_FILTERS') &&
            this.currentUser.has('SLA_KPI_IGNORE_RESULT') &&
            this.options.selectedCategory[0]?.id?.reasonCode === 20 &&
            (this.selectedSla.justificationStatus === 'PREJUSTIFIED' ||
                this.selectedSla.justificationStatus === 'JUSTIFIED')
        ) {
            return true;
        }

        return false;
    }

    trackByFn(index: number, item: any) {
        return index;
    }

    openDatamartModal() {
        this._customDialogService.open('datamart-modal');
    }

    private onClick(e, args) {
        const item = this.dataView.getItem(args.row);
        if (!this.dbClick) {
            if ($(e.target).hasClass('ImpValue') && this.currentUser.has('ROUTE_SUPPLY_CHAIN_IMP_DETAIL')) {
                if (item.imp && item.companyCode) {
                    const imp = item.imp;
                    const companyCode = item.companyCode.toString().padStart(2, '0');
                    if (this.isIE()) {
                        this.router.navigate([`./supply-chain/importacao/${companyCode}/${imp}`]);
                    } else {
                        window.open(`./supply-chain/importacao/${companyCode}/${imp}`);
                    }
                }
            }
            if ($(e.target).hasClass('ball') || $(e.target).hasClass('secondBall') || $(e.target).hasClass('arrow')) {
                this.determineSla(args);
                $('.slaModal').removeClass('hidden');
                $('.slaModal table td').removeClass('selected');
                setTimeout(() => {
                    $(`.slaModal table #${this.selectedSla.kpi}`).addClass('selected');
                }, 100);
            } else if ($(e.target).hasClass('justBall')) {
                this.determineSla(args);
                this.openReasonModal();
            } else if ($(e.target).hasClass('multipleReferences')) {
                $('sla-status .RefModal').removeClass('hidden');
                this.references = item.clientReference;
            }
        }
    }

    private onDblClick(e, args) {
        const row = this.dataView.getItem(args.row);
        const selectedColumn = this.slaGrid.getColumns()[args.cell].field;
        this.dbClick = true;
        this.determineSla(args);
        if (row[selectedColumn].status === 'EXPIRED' || row[selectedColumn].status === 'ESPECIAL') {
            this.openReasonModal();
        }
        setTimeout(() => {
            this.dbClick = false;
        }, 400);
    }

    private determineSla(args: any) {
        this.fromHistory = false;
        this.selectedCell = args;
        this.kpiList = [];
        this.selectedImp = this.dataView.getItem(args.row).imp;
        this.selectedClient = this.dataView.getItem(args.row).clientName;
        this.selectedClientCode = this.dataView.getItem(args.row).clientCode;
        this.selectedCompanyCode = this.dataView.getItem(args.row).companyCode;
        this.tableKpis = this.dataView.getItem(args.row);
        const selectedColumn = this.slaGrid.getColumns()[args.cell].field;
        const dateParam1 = 'dateParam1';
        const dateParam2 = 'dateParam2';
        const columnName = 'columnName';
        this.auxImps.map((imp) => {
            if (imp.imp === this.selectedImp) {
                this.selectedHistoric = imp.slas;
                this.selectedHistoric.map((historicImp) => {
                    historicImp[dateParam1] = `./assets/app/media/img/icons/kpis/${historicImp.kpi.dateParam1}.png`;
                    historicImp[dateParam2] = `./assets/app/media/img/icons/kpis/${historicImp.kpi.dateParam2}.png`;
                    historicImp[columnName] = `${historicImp.kpi.dateParam1}${historicImp.kpi.dateParam2}`;
                    if (historicImp.justification !== null) {
                        if (historicImp.justification.reasonCode === 30) {
                            historicImp['accomplished'] = 0;
                            historicImp['status'] = 'ESPECIAL';
                        }
                    }
                });
            }
        });
        for (const kpi of Object.keys(this.tableKpis)) {
            if (kpi === selectedColumn) {
                this.selectedSla = this.tableKpis[kpi];
            }
            if (kpi !== 'id' && kpi !== 'imp' && kpi !== 'chassi' && kpi !== 'clientName' && kpi !== 'clientCode') {
                this.kpiList.push(this.tableKpis[kpi]);
            }
        }
        this.kpiList.sort((a, b) => {
            if (a.hasOwnProperty('index') && b.hasOwnProperty('index')) {
                if (a.index > b.index) {
                    return -1;
                }
                if (a.index < b.index) {
                    return 1;
                }
                return 0;
            }
        });

        this.selectedSla.titleParam1 = this.selectedSla.titleParam1.replace('-', ' ');
        this.selectedSla.titleParam2 = this.selectedSla.titleParam2.replace('-', ' ');
        if (this.selectedSla.justification === null) {
            this.newReason = true;
            this.hasApproval = false;
            this.selectedJustification = '';
            this.options.selectedCategory = [];
        } else {
            this.newReason = false;
            this.selectedJustification = this.selectedSla.justification.comment;
            this.hasApproval = this.selectedSla.justification.hasApproval;
            this.options.selectedCategory = this.CategorytoDropdownItems(
                [this.selectedSla.justification.reason],
                false
            );
        }

        this.kpiJustifications.kpis.map((kpi) => {
            if (kpi.code === this.selectedSla.kpiCode) {
                this.selectedSla.reasons = kpi.reasons;
                this.options.category = this.CategorytoDropdownItems(kpi.reasons, false);
            }
        });
    }

    private getSla(): Observable<any> {
        let headers = new HttpHeaders();
        let params = this._getDefaultParams();
        this.filterState.updatePreferences();
        if (
            (this.currentUser.has('AUTOMOTIVE_USER') && !this.currentUser.has('INTERNAL_FILTERS')) ||
            (this.currentUser.has('ROUTE_AUTOMOTIVE') && this.filterState.getAutomotiveMode() === 'Ligado')
        ) {
            if (this.filterState.getTypeSearch() === 'Chassi' && this.slaSearch !== '') {
                params = params.append('startDate', '2016-01-01');
                params = params.append('endDate', moment().format('YYYY-MM-DD'));
            } else {
                headers = headers.set('X-Requested-Client-Groups', this.filterState.getClientGroups().map((c) => c.id).join(','));
                params = params.set('slaStatus', this.filterState.getToggleStatus().join(','));
                params = params.append('startDate', moment(this.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
                params = params.append('endDate', moment(this.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
                params = params.append('kpis', this.filterState.getSelectedKpi().map((kpi) => kpi.id).join(','));

                if (this.filterState.getToggleStatus().length == 1 && this.filterState.getToggleStatus()[0] === 'EXPIRED')
                    params = params.append('justified', this.filterState.getToogleJustify().join(','));

            }
            return this.http.get(`${environment.endpoints.slaKpiService}/slas/chassis`, { headers, params });
        } else {
            if (this.filterState.getTypeSearch() === 'IMP' && this.slaSearch !== '') {
                params = params.append('startDate', '2016-01-01');
                params = params.append('endDate', moment().format('YYYY-MM-DD'));
            } else {
                headers = headers.set('X-Requested-Client-Groups', this.filterState.getClientGroups().map((c) => c.id).join(','));
                params = params.set('slaStatus', this.filterState.getToggleStatus().join(','));
                params = params.append('startDate', moment(this.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
                params = params.append('endDate', moment(this.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));

                params = params.append('kpis', this.filterState.getSelectedKpi().map((kpi) => kpi.id).join(','));
                if (this.filterState.getToggleStatus().length == 1 && this.filterState.getToggleStatus()[0] === 'EXPIRED')
                    params = params.append('justified', this.filterState.getToogleJustify().join(','));

            }
            return this.http.get(`${environment.endpoints.slaKpiService}/slas/imps`, { headers, params });
        }
    }

    private getClientGroups() {
        if (this.filterState.getClientGroups().length === 0) {
            return '';
        } else {
            return this.filterState.getClientGroups().map((c) => c.id).join(',');
        }
    }

    private formatImp(row, cell, value, columnDef, dataContext) {
        return `<span class='ImpValue'>${value}</span>`;
    }

    private formatChassi(row, cell, value, columnDef, dataContext) {
        return `<span class='ChassiValue'>${value}</span>`;
    }

    private formatRef(row, cell, value, columnDef, dataContext) {
        return `<span>${value || ''}</span>`;
    }

    private formatRefChassi(row, cell, value, columnDef, dataContext) {
        return `<span>${value || ''}</span>`;
    }

    private formatModal(row, cell, value, columnDef, dataContext) {
        let newValue = value || '';
        newValue = newValue.toLowerCase();
        return `<i class='material-icons' title='
        ${
            newValue === 'aereo' ? 'Aéreo' : newValue === 'rodoviario' ? 'Rodoviário' : 'Marítimo'
        }' style='margin-top: -2px;color: #1d8ab3'>
        ${
            newValue === 'aereo' ? 'local_airport' : newValue === 'rodoviario' ? 'local_shipping' : 'directions_boat'
        }</i>`;
    }

    private formatKpi(row, cell, value, columnDef, dataContext) {
        if (value === undefined) {
            return "<span class='greyBall'></span>";
        } else if (
            value.status === 'EXPIRED' &&
            (value.justification === null || value.justificationStatus === 'UNJUSTIFIED')
        ) {
            return `<span class='ball ${value.status}'>${value.accomplished}</span>
                <span class='arrow'>></span>
                <span class='secondBall'>${value.expected}</span>
                <i class='comexport-attention justBall red'></i>`;
        } else if (value.status === 'EXPIRED' && value.justificationStatus === 'PREJUSTIFIED') {
            return `<span class='ball ${value.status}'>${value.accomplished}</span>
                <span class='arrow'>></span>
                <span class='secondBall'>${value.expected}</span>
                <i class='fa fa-clock-o justBall blue'></i>`;
        } else if (value.status === 'EXPIRED' && value.justificationStatus === 'JUSTIFIED') {
            return `<span class='ball ${value.status}'>${value.accomplished}</span>
                <span class='arrow'>></span>
                <span class='secondBall'>${value.expected}</span>
                <i class='fa fa-check justBall green'></i>`;
        } else {
            return `<span class='ball ${value.status}'>${value.accomplished}</span>
            <span class='arrow'>></span>
            <span class='secondBall'>${value.expected}</span>`;
        }
    }

    private prepareData() {
        const kpiList = [];
        this.filterState.getSelectedKpi().map((kpi) => {
            kpiList.push(kpi.id);
        });
        this.kpis = [];
        const rows = this.auxImps.map((imp) => {
            const row = !this.verifyAutomotiveMode(this.currentUser)
                ? {
                      id: imp.imp,
                      imp: imp.imp,
                      chassi: imp.chassi,
                      clientName: imp.client.name,
                      clientCode: imp.client.code,
                      clientReference: imp.clientReference,
                      transportTypeDescription: imp.transportTypeDescription,
                      companyCode: imp.companyCode,
                  }
                : {
                      id: `${imp.imp} - ${imp.chassi}`,
                      imp: imp.imp,
                      chassi: imp.chassi,
                      clientName: imp.client.name,
                      clientCode: imp.client.code,
                      clientReference: imp.clientCodeReference,
                      transportTypeDescription: imp.transportTypeDescription,
                      companyCode: imp.companyCode,
                  };
            imp.slas.forEach((sla) => {
                if (this.filterState.getToggleStatus().includes(sla.status) || this.filterState.getToggleStatus().length === 0) {
                    if (kpiList.includes(sla.kpi.code) || this.filterState.getSelectedKpi().length === 0) {
                        if (this.kpis.indexOf(sla.kpi.code) === -1) {
                            this.kpis.push({
                                id: sla.kpi.code,
                                name: `<div title='${sla.kpi.name}'>
                                <img src='./assets/app/media/img/icons/kpis/${sla.kpi.dateParam1}.png'>
                                <img class='arrow' src='./assets/app/media/img/icons/kpis/arrow.png'>
                                <img src='./assets/app/media/img/icons/kpis/${sla.kpi.dateParam2}.png'>
                                </div>`,
                                field: `${sla.kpi.dateParam1}${sla.kpi.dateParam2}`,
                                index: sla.kpi.index,
                                resizable: false,
                                formatter: this.formatKpi,
                                cssClass: 'cellContainer',
                                headerCssClass: 'headerContainer',
                            });
                        }
                        const key = `${sla.kpi.dateParam1}${sla.kpi.dateParam2}`;
                        let accomplished;
                        if (sla.status === 'UNKNOWN') {
                            accomplished = '?';
                        } else {
                            sla.accomplished > 999 ? (accomplished = '999+') : (accomplished = sla.accomplished);
                        }
                        row[key] = {
                            kpi: key,
                            dateParam1: `./assets/app/media/img/icons/kpis/${sla.kpi.dateParam1}.png`,
                            dateParam2: `./assets/app/media/img/icons/kpis/${sla.kpi.dateParam2}.png`,
                            titleParam1: sla.kpi.dateParam1,
                            titleParam2: sla.kpi.dateParam2,
                            dateValue1: sla.dateValue1,
                            dateValue2: sla.dateValue2,
                            status: sla.status,
                            accomplished,
                            expected: sla.expected,
                            index: sla.kpi.index,
                            kpiName: sla.kpi.name,
                            kpiCode: sla.kpi.code,
                            slaId: sla.slaId,
                            justification: sla.justification,
                            justificationStatus: sla.justificationStatus,
                        };
                        if (sla.justification !== null) {
                            if (sla.justification.reasonCode === 30) {
                                row[key]['accomplished'] = 0;
                                row[key]['status'] = 'ESPECIAL';
                            }
                        }
                    }
                }
            });
            return row;
        });

        const cols = !this.verifyAutomotiveMode(this.currentUser)
            ? [
                  {
                      id: 'imp',
                      name: 'IMP',
                      field: 'imp',
                      formatter: this.formatImp,
                      headerCssClass: 'headerContainer',
                      cssClass: 'cellContainer',
                  },
                  {
                      id: 'client',
                      name: 'Cliente',
                      field: 'clientName',
                      headerCssClass: 'headerContainer',
                      cssClass: 'clientContainer',
                      width: 200,
                  },
                  {
                      id: 'clientReference',
                      name: 'Referência',
                      field: 'clientReference',
                      headerCssClass: 'headerContainer',
                      cssClass: 'refContainer',
                      width: 150,
                      formatter: this.formatRef,
                  },
                  {
                      id: 'transportTypeDescription',
                      name: 'Modal',
                      field: 'transportTypeDescription',
                      headerCssClass: 'headerContainer',
                      cssClass: 'modalContainer',
                      width: 70,
                      formatter: this.formatModal,
                  },
              ]
            : [
                  {
                      id: 'chassi',
                      name: 'CHASSI',
                      field: 'chassi',
                      formatter: this.formatChassi,
                      headerCssClass: 'headerContainer',
                      cssClass: 'cellContainer',
                      width: 185,
                  },
                  {
                      id: 'client',
                      name: 'Cliente',
                      field: 'clientName',
                      headerCssClass: 'headerContainer',
                      cssClass: 'clientContainer',
                      width: 200,
                  },
                  {
                      id: 'clientReference',
                      name: 'Referência',
                      field: 'clientReference',
                      headerCssClass: 'headerContainer',
                      cssClass: 'refContainer',
                      width: 150,
                      formatter: this.formatRefChassi,
                  },
                  {
                      id: 'transportTypeDescription',
                      name: 'Modal',
                      field: 'transportTypeDescription',
                      headerCssClass: 'headerContainer',
                      cssClass: 'modalContainer',
                      width: 70,
                      formatter: this.formatModal,
                  },
              ];
        this.kpis.sort((a, b) => {
            if (a.index > b.index) {
                return 1;
            }
            if (a.index < b.index) {
                return -1;
            }
            return 0;
        });
        this.kpis.forEach((kpi) => {
            if (!cols.some((col) => col.field === kpi.field)) {
                cols.push(kpi);
            }
        });
        return { cols, rows };
    }

    private updateDateInputs() {
        if (!moment(this.startDate, 'DD/MM/YYYY').isValid()) {
            this.startDate = moment().format('DD/MM/YYYY');
        }
        if (!moment(this.endDate, 'DD/MM/YYYY').isValid()) {
            this.endDate = moment().format('DD/MM/YYYY');
        }
        ($('sla-status input#startDatePicker') as any).val(this.startDate).Zebra_DatePicker({
            direction: ['01/01/2019', false],
            onSelect: (selectedDate) => {
                this.verifyDates('startDate', selectedDate);
            },
            onClear: () => {
                this.startDate = undefined;
                this.endDate = undefined;
                this.updateDateInputs();
            },
        });
        setTimeout(() => {
            ($('sla-status input#endDatePicker') as any).val(this.endDate).Zebra_DatePicker({
                direction: [this.startDate, false],
                onSelect: (selectedDate) => {
                    this.endDate = selectedDate;
                },
            });
        }, 300);
        Inputmask({ mask: '99/99/9999' }).mask($('input#startDatePicker'));
        Inputmask({ mask: '99/99/9999' }).mask($('input#endDatePicker'));
    }

    private resetPages() {
        this.queryId = Math.random();
        this.offset = 0;
    }

    private isIE() {
        return (
            navigator.userAgent.indexOf('MSIE') !== -1 ||
            navigator.appVersion.indexOf('Trident/') > -1 ||
            window.navigator.userAgent.indexOf('Edge') > -1
        );
    }

    private CategorytoDropdownItems(items: any[], includeCode = true) {
        if (items != null) {
            return items.map((item) => this.CategorytoDropdownItem(item, includeCode));
        } else {
            return [];
        }
    }

    private CategorytoDropdownItem(e: any, includeCode: boolean): DropdownOption {
        return new DropdownOption(e, `${e.reasonName}`);
    }

    private getKPI(): Observable<any> {
        return this.http.get(`${environment.endpoints.slaKpiService}/kpis`);
    }

    private _getDefaultParams() {
        let params = new HttpParams();
        let slaSearchParam = {
            IMP: 'imp',
            Ref: 'clientReference',
            Chassi: 'chassi',
        };

        if (this.slaSearch) (params = params.append(`${slaSearchParam[this.filterState.getTypeSearch()]}`, this.slaSearch));

        params = params.append('offset', `${this.offset * 50}`);
        params = params.append('limit', `${this.limit}`);
        params = params.append('referenceDateType', this.filterState.getTypeSwitch());
        params = params.append('modalTransportations', this.filterState.getModals().map((modals) => modals.itemName).join(','));
        return params;
    }

    private _getJustificationParams() {
        return {
            justificationSlaId: this.selectedSla.slaId,
            justificationImpCode: this.selectedImp,
            justificationReasonCode: this.options.selectedCategory[0].id.reasonCode,
            justificationComment: this.selectedJustification,
            justificationHasApproval: this.options.selectedCategory[0].id.reasonCode === 20 ? this.hasApproval : false,
        };
    }

    private _updateJustification() {
        const newJustification = {
            comment: this.selectedJustification || '',
            reason: {
                reasonCode: this.options.selectedCategory[0].id.reasonCode,
                reasonJustificable: this.options.selectedCategory[0].id.reasonJustificable,
                reasonName: this.options.selectedCategory[0].id.reasonName,
            },
        };
        if (!this.fromHistory) {
            this.columnName = this.slaGrid.getColumns()[this.selectedCell.cell].field;
        }
        this.rows[this.selectedCell.row][this.columnName].justification = newJustification;
        this.slaGrid.invalidateRow(this.selectedCell.row);
        this.slaGrid.render();
    }

    private _determineDatamartFilters(user) {
        return [
            { title: 'Status', field: 'slaStatus' },
            { title: 'Modal', field: 'modalTransportations' },
            { title: `${user.has('INTERNAL_FILTERS') ? 'Clientes' : ''}`, field: 'clientCodes' },
            { title: 'KPI', field: 'kpis' },
            { title: 'Tipo de Data', field: 'referenceDateType' },
            { title: 'Período', field: 'period' }
        ];
    }
}
