
var generateData = function() {
    var qtd = 20;
    var data = [];

    function status(): number {
        return Math.round(Math.random() * 100) < 5 ? 0 : 1;
    }

    for (var i = 0; i < qtd; i++) {

        var imp = Math.round(Math.random() * 100000) + '-00';

        data.push({
            imp: imp,
            kpi1: status(),
            kpi2: status(),
            kpi3: status(),
            kpi4: status()
        });
    }

    return data;
};

export const data = generateData();
