import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UtilsService } from '#services/_utils/utils.service';

@Component({
    selector: 'cmx-line-chart',
    templateUrl: './cmx-line-chart.component.html',
    styleUrls: ['./cmx-line-chart.component.scss'],
})
export class LineChartComponent {
    @Input() color: string;
    @Input() description: string;
    @Input() percentage: number;
    @Input() value: number;
    @Output() clickEvent: EventEmitter<any>;

    constructor(public utilsService: UtilsService) {
        this.clickEvent = new EventEmitter();
    }

    dispatchEvent(): void {
        if (this.value) {
            this.clickEvent.emit();
        }
    }
}
