import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cmxAmount',
    pure: false,
})
export class CmxAmountPipe implements PipeTransform {
    transform(value: any, minimumFractionDigits = 2, maximumFractionDigits = 2, currency = 'BRL'): any {
        if (value) {
            if (typeof value === 'string') {
                value = parseFloat(value);
            }
            value = Intl.NumberFormat('pt-br', {
                style: 'currency',
                currency,
                minimumFractionDigits,
                maximumFractionDigits,
            }).format(value);
        }
        return value;
    }
}
