import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import moment from 'moment';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { ISlaConsolidationFilter } from './interface/sla-consolidation.interface';

export class SlaConsolidationFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialSlaConsolidationFilter: ISlaConsolidationFilter = <ISlaConsolidationFilter>{};
    private _slaConsolidationFilter: ISlaConsolidationFilter = <ISlaConsolidationFilter>{
        searchType: 'SLA Consolidation',
        clientGroups: [],
        referenceDateType: 'impIssueDate',
        selectedKpi: []
    };

    private startDate;
    private endDate;

    constructor(filter?: SlaConsolidationFilter) {

        this._slaConsolidationFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'slaConsolidationFilter', this._slaConsolidationFilter, filter);
        this._initialSlaConsolidationFilter = _.cloneDeep(this._slaConsolidationFilter);
        this.startDate = filter ? filter.startDate : moment().format('01/01/YYYY');
        this.endDate = filter ? filter.endDate : moment().format('DD/MM/YYYY');

    }

    public getClientGroups() {
        return this._slaConsolidationFilter.clientGroups;
    }
    public getReferenceDateType(): string {
        return this._slaConsolidationFilter.referenceDateType;
    }
    public getStartDate() {
        return this.startDate;
    }
    public getEndDate() {
        return this.endDate;
    }
    public getSelectedKpi() {
        return this._slaConsolidationFilter.selectedKpi;
    }

    public setClientGroups(clientGroups): void {
        this._slaConsolidationFilter.clientGroups = clientGroups;
    }
    public setReferenceDateType(referenceDateType): void {
        this._slaConsolidationFilter.referenceDateType = referenceDateType;
    }
    public setStartDate(startDate): void {
        this.startDate = startDate;
    }
    public setEndDate(endDate): void {
        this.endDate = endDate;
    }
    public setSelectedKpi(selectedKpi): void {
        this._slaConsolidationFilter.selectedKpi = selectedKpi;
    }

    public getRequest() {

        this._initialSlaConsolidationFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialSlaConsolidationFilter, this._slaConsolidationFilter, 'slaConsolidationFilter', this._userCacheService.getUserPreferences().id);

        let params = new HttpParams();
        let headers = new HttpHeaders();
        headers = headers.set('X-Requested-Client-Groups', this.getClientGroups().map((c) => c.id).join(','));
        params = params.append('startDate', `${moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`);
        params = params.append('endDate', `${moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`);
        params = params.set('referenceDateType', `${this.getReferenceDateType()}`);
        return { params, headers };
    }
}
