import { Injectable } from '@angular/core';

@Injectable()
export class TransportModalService {

    private modalList = [
        {id: 'assets/img/icons/air.png', itemName: 'Aéreo', code: 'AIR'},
        {id: 'assets/img/icons/maritime.png', itemName: 'Marítimo', code: 'MARITIME'},
        {id: 'assets/img/icons/roads.png', itemName: 'Rodoviário', code: 'ROADS'},
    ];

    getModalList() {
        return this.modalList;
    }

    getEnglishName(name: string) {
        switch (name) {
            case 'Aéreo':
                return 'AIR';
            case 'Marítimo':
                return 'MARITIME';
            case 'Rodoviário':
                return 'ROADS';
            default:
                return name;
        }
    }

    getPortugueseName(name: string) {
        switch (name) {
            case 'AIR':
                return 'Aéreo';
            case 'MARITIME':
                return 'Marítimo';
            case 'ROADS':
                return 'Rodoviário';
            default:
                return name;
        }
    }

    mapToPortugueseModalNames(modals) {
        if (!modals || modals.length === 0) {
            return [];
        }
        const modalArray = typeof modals === 'string' ? modals.split(', ') : modals;
        const translatedArrays = modalArray.map(modalString => {
            const modalNames = modalString.split(', ');
            const translatedModals = modalNames.map(modal => this.getPortugueseName(modal));
            return translatedModals.join(', ');
        });

        return translatedArrays;
    }

}
