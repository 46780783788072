<div *ngIf="!asyncDetail" class="fullLoader">
    <loader></loader>
</div>

<div id="import-details-delivery-container" *ngIf="asyncDetail">
    <div id="import-details-delivery-content" *ngIf="dataLoaded">
        <cmx-assembled-data
            [data]="deliveryDetails"
            [maxRowElements]="4"
            *ngIf="containersList.length < 1"
        ></cmx-assembled-data>

        <div class="import-details-delivery-grid-content">
            <cmx-custom-grid
                [id]="'import-details-delivery-nfe-grid'"
                [columns]="gridNfeColumns"
                [rows]="nfeList"
                [options]="{ forceFitColumns: true }"
                [styles]="{ 'max-height': '270px', 'min-height': '100px', 'margin-top': '0px' }"
            ></cmx-custom-grid>
        </div>

        <div class="import-details-delivery-grid-content" *ngIf="containersList.length > 0">
            <hr />
            <cmx-custom-grid
                [id]="'import-details-delivery-container-grid'"
                [columns]="gridContainerColumns"
                [rows]="containersList"
                [options]="{ forceFitColumns: true }"
                [styles]="{ 'max-height': '270px', 'min-height': '100px' }"
            ></cmx-custom-grid>
        </div>
    </div>
</div>
