export const DEFAULT_OPTIONS = {
    useCategoryGrouping: false,
    horizontalAnchoring: false,
    useEmptyDataMessage: false,
    showEmptyDataHeader: true,
    collapseAllButton: false,
    editable: false,
    enableCellNavigation: true,
    enableTextSelectionOnCells: true,
    headerRowHeight: 35,
    rowHeight: 35,
    rowHover: false,
    infiniteScroll: false,
    tryLoadResults: 50,
    multiFilter: false,
    meta: {
        checkboxSelection: {
            enabled: false,
            selectionOptionFieldPath: '',
            selectionOptions: [],
        },
    },
};
