import { Component, ViewEncapsulation } from '@angular/core';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import Inputmask from 'inputmask';
import * as _ from 'lodash';

import { NotificationsCRUDService } from './notifications.service';
import { UserService } from '../../../../auth/_services/user.service';

@Component({
    selector: 'notifications-crud',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NotificationsCRUDComponent {
    public readonly dropdownSettingsFrequency = {
        singleSelection: true,
        enableSearchFilter: false,
        maxHeight: 200,
        disabled: false
    };
    public readonly dropdownSettingsClients = {
        singleSelection: false,
        text: 'Cliente',
        enableCheckAll: true,
        maxHeight: 200,
        disabled: false,
        badgeShowLabel: 'Clientes selecionados',
    };
    public dropdown: {
        frequency: { options: DropdownOption[]; selected: DropdownOption[] },
        clients: { options: DropdownOption[]; selected: DropdownOption[] }
    } = {
            frequency: { options: [], selected: [] },
            clients: { options: [], selected: [] },
        };
    public asyncNotifications = false;
    public email;
    public phone;
    public data;
    public messageText = '';
    public emailProperties;
    public phoneProperties;
    public allClients;
    public currentUser$;

    constructor(
        private notificationsCRUDService: NotificationsCRUDService,
        private userService: UserService,
        private _userCacheService: UserCacheService,
    ) {
        this.currentUser$ = userService.currentUser$;
    }

    public ngAfterViewInit() {
        this.notificationsCRUDService.getNotificationConfiguration().subscribe((data) => {
            this.data = data;
            this.dropdown.frequency.options = data.frequencyList;
            this.allClients = this._userCacheService.getClientGroups();
            if (this.data.category.hasOwnProperty('children')) {
                this.data.category.children.forEach((child: any) => {
                    child.dropdown = _.cloneDeep(this.dropdown);
                    this.appendPage(child.dropdown.clients.options);
                    child.dropdownSettingsClients = _.cloneDeep(this.dropdownSettingsClients);
                    child.dropdownSettingsFrequency = _.cloneDeep(this.dropdownSettingsFrequency);
                    child.frequency = [child.frequency || { id: 'DAILY', itemName: 'Diário' }];
                    this.checkIfDisabled(child);
                });
            } else {
                this.data.category.dropdown = _.cloneDeep(this.dropdown);
                this.appendPage(this.data.category.dropdown.clients.options);
                this.data.category.dropdownSettingsClients = _.cloneDeep(this.dropdownSettingsClients);
                this.data.category.dropdownSettingsFrequency = _.cloneDeep(this.dropdownSettingsFrequency);
                this.data.category.frequency = [this.data.category.frequency || { id: 'DAILY', itemName: 'Diário' }];
                this.checkIfDisabled(this.data.category);
            }
            this.emailProperties = this.getEmailProperties();
            this.asyncNotifications = true;
            setTimeout(() => {
                Inputmask({ mask: '(99) 9999[9]-9999' }).mask($('input#phone'));
            }, 200);
        });
    }

    public toogle(id, element) {
        if ($(element.target.previousElementSibling).hasClass('fa-caret-down')) {
            $(element.target.previousElementSibling).removeClass('fa-caret-down');
            $(element.target.previousElementSibling).addClass('fa-caret-right');
            $(`.single.notification.${id}`).css('display', 'none');
        } else {
            $(element.target.previousElementSibling).removeClass('fa-caret-right');
            $(element.target.previousElementSibling).addClass('fa-caret-down');
            $(`.single.notification.${id}`).css('display', 'flex');
        }
    }

    public validateEmailDomain() {
        const loggedUserEmail = this.userService.getCurrentUser().getEmail();
        const loggedUserEmailDomain = loggedUserEmail.substr(loggedUserEmail.indexOf("@") + 1);
        const currentEmailDomain = this.email.substr(this.email.indexOf("@") + 1);
        const result = loggedUserEmailDomain === currentEmailDomain
        if (!result) {
            this.showMessage('Domínio do E-mail precisa ser o mesmo do usuário logado \"' + loggedUserEmailDomain + '\".', 'error');
        }
        return result;
    }

    public addEmail() {
        if (this.validateEmail(this.email)) {
            if (this.emailProperties.value.includes(this.email)) {
                this.showMessage('Este e-mail já está em sua lista.', 'error');
            } else if (this.validateEmailDomain()) {
                this.emailProperties.value.push(this.email);
                this.email = '';
            }
        }
    }

    public addPhone() {
        let phone = this.phone.match(/\d/g);
        phone = phone.join('');
        if (parseInt(phone.length, 10) === 11) {
            if (this.phoneProperties.value.includes(phone)) {
                this.showMessage('Este telefone já está em sua lista.', 'error');
            } else {
                this.phoneProperties.value.push(phone);
                this.phone = '';
            }
        } else {
            this.showMessage('Telefone Inválido.', 'error');
        }
    }

    public removeFrom(array, index) {
        if (array === 'emailList') {
            this.emailProperties.value.splice(index, 1);
        } else {
            this.data.phoneList.splice(index, 1);
        }
    }

    public saveConfiguration() {
        const configurationData = this.preparedataToSave();
        this.asyncNotifications = false;
        this.notificationsCRUDService.saveNotificationConfiguration(configurationData)
            .subscribe((response) => {
                this.asyncNotifications = true;
                this.showMessage('Configuração salva.', 'success');
            });
    }

    public validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const result = re.test(String(email).toLowerCase());
        if (!result) {
            this.showMessage('E-mail Inválido.', 'error');
        }
        return result;
    }

    public closeMessage() {
        $('.message').addClass('hidden');
    }

    public changeMedium(child: any, medium: string) {
        child.mediums[medium] = !child.mediums[medium];
        this.checkIfDisabled(child);
    }

    private appendPage(options: any[]) {
        const pageClients = this.allClients;
        this.toDropdownItems(pageClients).forEach(e => options.push(e));
    }

    private checkIfDisabled(child: any) {
        let disabled = child.disabled;
        child.disabled = !Object.values(child.mediums).some(e => e);
        if (child.disabled != disabled) {
            const dropdownSettingsClients = _.cloneDeep(this.dropdownSettingsClients);
            dropdownSettingsClients.disabled = child.disabled;
            child.dropdownSettingsClients = dropdownSettingsClients;
            const dropdownSettingsFrequency = _.cloneDeep(this.dropdownSettingsFrequency);
            dropdownSettingsFrequency.disabled = child.disabled;
            child.dropdownSettingsFrequency = dropdownSettingsFrequency;
            if (child.disabled) {
                child.clients = [];
            } else if (!child.clients) {
                child.clients = this.selectClients(this.allClients,
                    child.includedClientGroupCodes, child.excludedClientGroupCodes);
            }
        }
    }

    private getEmailProperties() {
        let properties: any = this.data.mediumProperties.find(
            (item: any) => item.medium === 'EMAIL' && item.key === 'email');

        if (!properties) {
            properties = {
                medium: 'EMAIL',
                key: 'email',
                value: [],
            };
            this.data.mediumProperties.push(properties);
        }

        return properties;
    }

    private showMessage(text, type) {
        this.messageText = text;
        $('.message').removeClass('error success hidden');
        $('.message').addClass(type);
    }

    private preparedataToSave() {
        const preparedData = _.cloneDeep(this.data);
        if (preparedData.category.hasOwnProperty('children')) {
            preparedData.category.children.forEach((child: any) => {
                child.frequency = child.frequency[0].id;
                const { included, excluded } = this.getIncludedExcluded(this.allClients, child.clients);
                child.includedClientGroupCodes = included;
                child.excludedClientGroupCodes = excluded;
                delete (child.clients);
                delete (child.dropdown);
                delete (child.disabled);
                delete (child.dropdownSettingsFrequency);
                delete (child.dropdownSettingsClients);
            });
        } else {
            preparedData.category.frequency = preparedData.category.frequency[0].id;
            const { included, excluded } = this.getIncludedExcluded(this.allClients, preparedData.category.clients);
            preparedData.category.includedClientGroupCodes = included;
            preparedData.category.excludedClientGroupCodes = excluded;
            delete (preparedData.category.clients);
            delete (preparedData.category.dropdown);
            delete (preparedData.category.disabled);
            delete (preparedData.category.dropdownSettingsFrequency);
            delete (preparedData.category.dropdownSettingsClients);
        }
        delete preparedData.frequencyList;
        return preparedData;
    }

    private getIncludedExcluded(allItems: any[], selectedItems: any[]) {
        if (selectedItems.length >= allItems.length / 2) {
            const included = selectedItems.map(e => e.id);
            const excluded = allItems.map(e => e.code).filter(e => !included.includes(e));
            return { included: [], excluded };
        } else if (selectedItems.length != allItems.length) {
            const included = selectedItems.map(e => e.id);
            const excluded = [];
            return { included, excluded };
        } else {
            return { included: [], excluded: [] }
        }
    }

    private selectClients(items: any[], included: any[], excluded: any[]) {
        if (included && included.length > 0) {
            return items.filter(e => included.includes(e.code)).map(this.toDropdownItem);
        } else if (excluded && excluded.length > 0) {
            return items.filter(e => !excluded.includes(e.code)).map(this.toDropdownItem);
        } else {
            return [];
        }
    }

    private toDropdownItems(items: any[]) {
        return items != null ? items.map(this.toDropdownItem) : [];
    }

    private toDropdownItem(e: any): DropdownOption {
        return { id: e.code, itemName: e.name };
    }

    private _isValidRequestData(requestData): boolean {
        return requestData.category && requestData.category.children && requestData.category.children.some((c) => {
            return Object.values(c.mediums).includes(true);
        });
    }

    trackByFn(index: number, item: any) {
        return index;
    }
}
