import { FilterWrapperGroupComponent } from './components/cmx-filter-wrapper-group/cmx-filter-wrapper-group.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterWrapperComponent } from './cmx-filter-wrapper.component';

@NgModule({
    imports: [CommonModule],
    declarations: [FilterWrapperComponent, FilterWrapperGroupComponent],
    exports: [FilterWrapperComponent, FilterWrapperGroupComponent],
})
export class CmxFilterWrapperModule {}
