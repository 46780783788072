<div class="countdown">
    <div class="countdown-item">
      <div class="countdown-value">{{ days }}</div>
      <div class="countdown-label">Dias</div>
    </div>
    <div class="countdown-item">
      <div class="countdown-value">{{ hours }}</div>
      <div class="countdown-label">Horas</div>
    </div>
    <div class="countdown-item">
      <div class="countdown-value">{{ minutes }}</div>
      <div class="countdown-label">Minutos</div>
    </div>
    <div class="countdown-item">
      <div class="countdown-value">{{ seconds }}</div>
      <div class="countdown-label">Segundos</div>
    </div>
  </div>
  