import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';

export const HISTORIC_COLUMNS: IGridColumn[] = [
    {
        field: 'date',
        name: 'Data',
        width: 70,
    },
    {
        field: 'message',
        name: 'Descrição',
        autoWidth: true,
        minAutoWidth: 900
    },
];
