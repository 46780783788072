import { IGridColumn } from "app/shared/components/cmx-custom-grid/interfaces";

export const SLA_DETAIL_RESULT_COLUMNS: IGridColumn[] = [{
    field: 'KPI',
    name: 'Etapa',
    formatterType: 'kpi',
    width: 200,
}, {
    field: 'BEFORE_TIME',
    name: 'Antes do Prazo',
    formatterType: 'percentage',
    formatterData: {
        fontSize: '17px',
        color: '#0ba100'
    },
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: 150,
}, {
    field: 'ON_TIME',
    name: 'No Prazo',
    formatterType: 'percentage',
    formatterData: {
        fontSize: '17px',
        color: '#008eff'
    },
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: 150,
}, {
    field: 'EXPIRED',
    name: 'Expirado',
    formatterType: 'percentage',
    formatterData: {
        fontSize: '17px',
        color: '#ff0000'
    },
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: 150,
}, {
    field: 'RESULT',
    name: 'Resultado',
    formatterType: 'percentage',
    formatterData: {
        fontSize: '17px',
        color: 'dynamic'
    },
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: 150,
}]