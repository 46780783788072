<div (click)="dispatchEvent()" [style.cursor]="value ? 'pointer' : 'unset'">
    <div class="value-container">
        <h4 [style.color]="color">{{ value > 999 ? '999+' : value }}</h4>
    </div>
    <div class="line-chart-container">
        <span class="description" [tooltip]="description" [checkEllipsisActive]="true">{{ description }}</span>
        <div class="line-chart"></div>
        <div class="line-chart" [ngStyle]="{ width: percentage + '%', 'background-color': color }"></div>
        <span class="percentage" [ngStyle]="{ color: color }">{{
            utilsService.formatNumber(percentage, { style: 'percent' })
        }}</span>
    </div>
</div>
