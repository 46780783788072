import { Utils } from '../utils/utils';

import { Chart } from '../../../../../shared/utils/chart';

import { UtilsService } from '#services/_utils/utils.service';

import { ILogisticsHandlingTypeChart } from 'app/shared/interfaces/logistics-handling-types.interface';

import { LogisticsHandlingModalTypes } from 'app/shared/types/logistics-handling-types.type';

import { LOGISTICS_HANDLING_VALUE_AXIS_TITLES } from '../constants/logistics-handling-value-axis-titles.constant';

const TYPE_COLORS = ['#364476', '#525D8F', '#747CA8', '#9DA7CB', '#B1BEDC', '#B7C4D9', '#BBC2CE', '#A9ABAE', '#848688'];

interface ICustomizedLogisticsHandlingStates extends ILogisticsHandlingTypeChart {
    color: string;
    label: string;
}

export class LogisticsHandlingTypesChart {
    private _logisticsHandlingType: LogisticsHandlingModalTypes;
    private _logisticsHandlingTypesData: ILogisticsHandlingTypeChart[];

    constructor(
        logisticsHandlingType: LogisticsHandlingModalTypes,
        logisticsHandlingTypesData: ILogisticsHandlingTypeChart[],
        private _utilsService: UtilsService
    ) {
        this._logisticsHandlingType = logisticsHandlingType;
        this._logisticsHandlingTypesData = logisticsHandlingTypesData;
    }

    loadChart(chartId: string, typeView?: string): AmCharts.AmChart {
        return Chart.makeChart(chartId, this._getConfigs(typeView));
    }

    private _getCustomizedLogisticsHandlingStatesData(): ICustomizedLogisticsHandlingStates[] {
        return this._logisticsHandlingTypesData.map((data: ILogisticsHandlingTypeChart, index: number) => ({
            ...data,
            color: TYPE_COLORS[index] || TYPE_COLORS[0],
            label: `${this._prepareName(data.name)}<br>${Utils.getFormattedPercentage('chart', data.percentage, this._utilsService)}`,
        }));
    }

    private _prepareName(name: string): string {
        const splitted = name.split('-');
        const reducedName = splitted[1] && splitted[1].length > 13 ?
            `${splitted[1].substring(0,13)}...` : splitted[1]
        let splittedName = splitted[1] ? `<br>${reducedName}` : '';
        return `${splitted[0]}${splittedName}`;
    }

    private _getConfigs(typeView?: string) {
        const dataProvider = this._getCustomizedLogisticsHandlingStatesData();

        return {
            dataProvider,
            type: 'serial',
            theme: 'none',
            categoryField: 'label',
            startDuration: 0,
            gridAboveGraphs: false,
            zoomOutText: '',
            addClassNames: true,
            graphs: [
                {
                    balloonText: '',
                    fillAlphas: 0.9,
                    lineAlpha: 0.2,
                    type: 'column',
                    valueField: 'value',
                    fillColorsField: 'color',
                    lineColorsField: 'color',
                    labelText: '[[value]]',
                    showAllValueLabels: true,
                    fontSize: 10,
                    labelFunction: (value) =>
                        Utils.getGraphLabelByLogisticsHandlingModalType(
                            value.values.value,
                            this._logisticsHandlingType,
                            this._utilsService
                        ),
                },
            ],
            valueAxes: [
                {
                    gridColor: '#FFFFFF',
                    gridAlpha: 0.2,
                    labelFunction: (value) =>
                        Utils.getGraphLabelByLogisticsHandlingModalType(
                            value,
                            this._logisticsHandlingType,
                            this._utilsService
                        ),
                    title: LOGISTICS_HANDLING_VALUE_AXIS_TITLES[`${this._logisticsHandlingType}${this._logisticsHandlingType === 'MARITIME' ? `_${typeView.toUpperCase()}` : ''}`],

                },
            ],
            chartCursor: {
                categoryBalloonEnabled: false,
                cursorAlpha: 0,
                zoomable: false,
            },
            categoryAxis: {
                gridPosition: 'start',
                gridAlpha: 0,
                tickPosition: 'start',
                tickLength: 20,
                autoWrap: false,
                fontSize: 9,
                boldLabels: true,
            },
            export: {
                enabled: true,
            },
        };
    }
}
