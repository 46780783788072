import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';

export const BOARDING_CONTAINER_COLUMNS: IGridColumn[] = [
    {
        field: 'containerNumber',
        name: 'Contâiner',
        width: 115,
    },
    {
        field: 'returnDate',
        name: 'Devolução',
        width: 115,
        headerCssClass: 'grid-header align-right',
        cssClass: 'grid-cell align-right',
    },
];
