import { SkuValues } from './skuValues';

export class CostCategoryDetail {

    public name: string;
    public containerQuantity: number;
    public containerAverageValue: number;
    public skuValueList: SkuValues[];

    constructor(costDetail?: CostCategoryDetail) {
        if (costDetail) {
            this.name = costDetail.name || '';
            this.containerQuantity = costDetail.containerQuantity || 0;
            this.containerAverageValue = costDetail.containerAverageValue || 0;
            this.skuValueList = costDetail.skuValueList || [];
        } else {
            this.name = '';
            this.containerQuantity = 0;
            this.containerAverageValue = 0;
            this.skuValueList = [];
        }
    }

}
