<section>
    <p *ngIf="errorMessage" class="error-message">{{ errorMessage }}</p>
    <ul class="timeline-container" *ngIf="!errorMessage">
        <li *ngFor="let item of items; let isLast = last" [class.animated]="timelineAnimated" [ngStyle]="{'animation-delay': item.animateDelay + 'ms'}">
            <div class="border-height-timeline" *ngIf="!isLast" [ngStyle]="{'animation-delay': (item.animateDelay + 100) + 'ms'}"></div>
            <div class="icon-item-timeline" [style.border-color]="item.iconColor">
                <span class="material-icons" [style.color]="item.iconColor">{{ item.icon ? item.icon : 'info' }}</span>
            </div>
            <div class="description">
                {{ item.description }}
                <span class="author">{{ item.dateItem ? item.dateItem + ' - ' + item.author : item.author}}</span>
            </div>
        </li>
    </ul>
</section>
