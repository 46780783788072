export const BADGE_STATUS_VALUES = {
    DEFAULT: {
        label: "Comum",
        icon: "fa fa-check-circle-o",
        class: "status-type-default"
    },
    LOADING: {
        label: "A Programar",
        icon: "fa fa-calendar",
        class: "status-type-loading"
    },
    REVIEW:{
        label: "A Revisar",
        icon: "fa fa-eye",
        class: "status-type-in-confirmation"
    },
    IN_CONFIRMATION:{
        label: "Em Confirmação",
        icon: "fa fa-eye",
        class: "status-type-review"
    },
    PROGRAMMED:{
        label: "Programado",
        icon: "fa fa-check",
        class: "status-type-programmed"
    },
    IN_REVISION:{
        label: "Em revisão",
        icon: "fa fa-list",
        class: "status-type-in-revision"
    },
    IN_REVISION_DOC:{
        label: "Em revisão doc",
        icon: "fa fa-list",
        class: "status-type-in-revision-doc"
    },
    IN_TRANSIT:{
        label: "Em trânsito",
        icon: "fa fa-exchange",
        class: "status-type-in-transit"
    },
    IN_DEV_CNTR:{
        label: "Em dev. CNTR",
        icon: "fa fa-exchange",
        class: "status-type-in-dev-cntr"
    },
    PROCESSED:{
        label: "Processado",
        icon: "fa fa-check",
        class: "status-type-processed"
    },
    IN_PROCESSING:{
        label: "Em processamento",
        icon: "fa fa-hourglass-start",
        class: "status-type-in-processing"
    },
    FINISHED:{
        label: "Finalizado",
        icon: "fa fa-check",
        class: "status-type-finished"
    },
    FINISHED_WITH_PENDING:{
        label: "Finalizado com pendência",
        icon: "fa fa-check",
        class: 'status-type-finished-pending'
    },
    REJECTED:{
        label: "Rejeitado",
        icon: "fa fa-times",
        class: 'status-type-rejected'
    },
    ERROR:{
        label: "Erro",
        icon: "fa fa-times",
        class: 'status-type-error'
    },
    DUPLICATED:{
        label: "Duplicado",
        icon: "fa fa-copy",
        class: 'status-type-duplicated'
    }
}
