import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthHandlerComponent } from './auth/auth-handler.component';
import { AuthComponent } from './auth/auth.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { LogoutMsgComponent } from './auth/logout-msg.component';
import { PwdRecoveryComponent } from './auth/pwd-recovery.component';


const routes: Routes = [
    { path: 'auth-handler', component: AuthHandlerComponent },
    { path: 'login', component: AuthComponent },
    { path: 'logout', component: LogoutComponent },
    { path: 'pwd-recovery', component: PwdRecoveryComponent },
    { path: 'logout-msg', component: LogoutMsgComponent },
    { path: '', redirectTo: 'index', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
