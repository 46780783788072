<div class="cmx-listing">
    <div *ngIf="searchPlaceholder" class="search-container">
        <div class="p-field">
            <input pInputText
                type="text"
                autocomplete="off"
                class="comexport-theme"
                [placeholder]="searchPlaceholder"
                (keyup.enter)="applySearch()"
                [(ngModel)]="search"
            />
        </div>
        <div class="p-field" *ngIf="dropdownListItems" [ngStyle]="dropdownStyle">
            <cmx-dropdown
                size="md"
                [data]="dropdownList.list"
                [ngModel]="dropdownList.selecteds"
                (ngModelChange)="onSelectDropdownItems($event)"
                [settings]="dropdownSettings"
                [ngModelOptions]="{standalone: true}"
            ></cmx-dropdown>
        </div>
        <a *ngIf="hasAddButton" (click)="newItem()" class="addBtn"><span>+</span></a>

        <div *ngFor="let list of iconList" class="icon-list {{ list?.selected ? 'selected' : '' }}"
        [tooltip]="list.label" (click)="selectIcon(list)">
            <span *ngIf="list.type === 'material-icons' || !list.type" 
                class="material-icons round" [style.color]="list.iconColor">
                {{ list.icon }}
            </span>
            <img *ngIf="list.type === 'svg'" src="../../../../assets/app/media/img/icons/{{list.icon}}.svg" alt="">
        </div>
    </div>
    <hr *ngIf="searchPlaceholder" />
    <div class="list-container" [style.height]="height">
        <div class="table">
            <table>
                <tr *ngFor="let item of list; let i = index;" (click)="selectItem(item, $event)" class="list-item {{ size }}" [ngClass]="{'selected' : verifySelected(item), 'inactive' : item.inactive, 'icon': verifyIcon(item)}">
                    <td *ngIf="verifySearch(item)" [ngClass]="{ 'description-container' : item.cmxListDescription }">
                        <div class="item-container">
                            <span *ngIf="item.preTitleIcon"
                                class="material-icons" [style.color]="item.preTitleIconColor || 'grey'">{{ item?.preTitleIcon }}
                            </span>
                            <span class="name" 
                                [style.width]="item.rightInfo ? 'calc(100% - 90px)' : '100%'">
                                {{ item?.name }}
                            </span>
                            <span class="right-info" *ngIf="item.rightInfo">{{ item?.rightInfo }}</span>
                        </div>
                        <div class="item-container">
                            <span class="description"
                                [style.width]="item.rightDescriptionIcon ? 'calc(100% - 40px)' : '100%'">
                                {{ item?.cmxListDescription }}
                            </span>
                            <span class="material-icons lock" *ngIf="item.inactive">lock</span>
                            <div class="icon-container" *ngIf="verifyIcon(item)">
                                <span class="icon-count">{{ item?.iconCount }}</span>
                                <span class="material-icons">{{ icon }}</span>
                            </div>
                            <span *ngIf="item.rightDescriptionIcon && (item.rightIconType === 'material-icons' || !item.rightIconType)" 
                                class="material-icons right-icon" [style.color]="item.rightDescriptionIconColor || 'grey'">
                                {{ item?.rightDescriptionIcon }}
                            </span>
                            <img *ngIf="item.rightDescriptionIcon && item.rightIconType === 'svg'" src="../../../../assets/app/media/img/icons/{{item.rightDescriptionIcon}}.svg" alt="">
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
