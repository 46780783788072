<div>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
    <!-- Creator: CorelDRAW 2018 (64 Bit) -->
    <svg id="svg-cargo-vessel-with-container" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="93px" height="40px" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
    viewBox="0 0 1.6 0.69"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
    <style type="text/css">
    <![CDATA[
        .fil0 {fill:#373435}
    ]]>
    </style>
    </defs>
    <g id="Camada_x0020_1">
    <metadata id="CorelCorpID_0Corel-Layer"/>
    <path class="fil0" d="M0.16 0.69l1.35 0 0.09 -0.2 -1.08 0c-0.01,0 -0.02,-0.02 -0.03,-0.03 0,-0.01 -0.01,-0.02 -0.02,-0.03l-0.04 -0.06 -0.43 0 0.16 0.32zm0.74 -0.57l0.24 0 0 -0.12 -0.24 0 0 0.12zm-0.29 0l0.24 0 0 -0.12 -0.24 0 0 0.12zm0 0.33l0.24 0 0 -0.12 -0.24 0 0 0.12zm0 -0.27l0 0.09 0.01 0.01 0.23 0 0 -0.12 0 0 -0.22 0 -0.02 0 0 0.02zm0.29 0.1l0.24 0 0 -0.11 -0.24 -0.01c0,0.03 0,0.11 0,0.12zm0.28 0l0.24 0c0.01,-0.01 0,-0.09 0,-0.12l-0.24 0 0 0.12zm0 0.17l0.24 0 0 -0.12 -0.24 0 0 0.12zm-0.28 0l0.24 0 0 -0.12 -0.24 0 0 0.12z"/>
    </g>
    </svg>
</div>
