<div *ngIf="exportAsync" class="fullLoader">
    <span class="exporting">Solicitando Exportação</span>
    <loader></loader>
</div>

<div *ngIf="asyncSkuStatusTable == false">
    <loader></loader>
</div>

<div class="moveBtn">
    <div class="moveLine"></div>
</div>

<span class="widgetTitle"
    >Quick SKU Status
    <span class="icon-bloqueado"></span>
</span>

<cmx-marker-filter
    *ngIf="allStatus.length"
    [filters]="allStatus"
    [preSelected]="filterState.getSearch().length === 0 ? preSelectedStatus : []"
    type="status"
    (onSelected)="onSelectedStatus($event)"
></cmx-marker-filter>

<div id="searchLine" class="reduzed statusComponent" *ngIf="currentUser$ | async as user">
    <div class="search-container">
        <div class="searchOptions hidden" style="flex-wrap: wrap; z-index: 2">
            <div (click)="selectSearchType($event, 'IMP')" class="searchItem" style="border-right: 1px solid #eaeaea"
                >IMP</div
            >
            <div (click)="selectSearchType($event, 'SKU')" class="searchItem selected">SKU</div>
            <div (click)="selectSearchType($event, 'Referência')" class="searchItem full">Referência</div>
            <div (click)="selectSearchType($event, 'Fornecedor')" class="searchItem full">Fornecedor</div>
        </div>
        <div class="searchInput">
            <input
                id="impSearch"
                class="search"
                type="text"
                name="imp"
                [(placeholder)]="placeholderSearch"
                style="width: 165px; z-index: 4"
                [ngModel]="filterState.getSearch()"
                (ngModelChange)="filterState.setSearch($event);"
            />
            <button (click)="changeSearch()" class="applyImpStatus dropdownSearch" style="z-index: 3">
                <i style="pointer-events: none" class="fa fa-angle-down"></i>
            </button>
        </div>
        <button (click)="applyFilter()" class="applyImpStatus first">
            <i class="fa fa-check"></i>
        </button>
    </div>
    <br />
    <div class="search-container">
        <div class="input-group" style="width: max-content; width: fit-content; margin-left: 10px">
            <cmx-dropdown
                id="modal"
                name="modal"
                [data]="options.modal"
                [ngModel]="filterState.getModals()"
                (ngModelChange)="filterState.setModals($event)"
                [settings]="dropdownSettings.modals"
            ></cmx-dropdown>
        </div>
        <div *ngIf="user.has('INTERNAL_FILTERS')" class="input-group" style="width: max-content; width: fit-content">
            <span class="input-group-addon"></span>
            <cmx-dropdown
                id="clients"
                name="clients"
                [data]="options.client"
                [ngModel]="filterState.getClientGroups()"
                (ngModelChange)="filterState.setClientGroups($event)"
                (onScroll)="scrollList($event)"
                [settings]="dropdownSettings.clientGroups"
            ></cmx-dropdown>
        </div>
        <div class="input-group">
            <span class="input-group-addon">De</span>
            <input
                (keyup)="verifyDates('startDate', startDate)"
                [ngModel]="filterState.getStartDate()"
                (ngModelChange)="filterState.setStartDate($event)"
                name="startDatePicker"
                id="startDatePicker"
                type="text"
                class="form-control m-input date-impStatusTable"
                style="width: 125px"
            />
        </div>
        <div class="input-group right">
            <span class="input-group-addon">Até</span>
            <input
                (keyup)="verifyDates('endDate', endDate)"
                [(ngModel)]="endDate"
                name="endDatePicker"
                id="endDatePicker"
                type="text"
                class="form-control m-input date-impStatusTable"
                style="width: 125px"
            />
        </div>
        <button (click)="applyFilter()" class="applyImpStatus">
            <i class="fa fa-check"></i>
        </button>
        <div
            *ngIf="filterState.getSearch().length !== 0"
            class="disableSearch"
            title="Os filtros são desativados ao realizar uma busca específica."
        ></div>
    </div>
</div>

<div class="invisible" id="infoLine" *ngIf="currentUser$ | async as user">
    <span class="checkmark total">
        Total IMPs:
        <b>{{ totalImps }}</b>
        <span style="margin: 0 15px">|</span>
        <span>SKUs:</span>
        <b>{{ total }}</b>
    </span>
</div>

<div id="SkuStatusGrid" class="SkuStatusGrid"></div>

<div *ngIf="pages.length > 1">
    <pagination [numberOfPages]="pages.length" [queryId]="queryId" (onPageChange)="changePage($event)"></pagination>
</div>

<div class="downloadTable">
    <i (click)="exportSku()" class="flaticon-download"></i>
</div>

<div *ngIf="asyncSkuStatusTable && gridData === undefined" class="reloadContainer">
    <div class="reloadContent">
        <i (click)="applyFilter()" class="material-icons">refresh</i>
    </div>
</div>

<!-- NCMModal -->
<div class="ncmModal hidden">
    <div class="fixedHeader">
        <h5
            >NCM • <span class="ncmValue">{{ ncm }}</span></h5
        >
        <hr />
    </div>
    <div class="modalContent">
        <i (click)="closeNcmModal()" class="close la la-close"></i>
        <div class="mainDetail">
            <div class="detailSubTitle">
                <span class="detailTitle">Categoria</span>
                <span class="detailValue">{{ selectedNcm?.categoria | cmxValid }}</span>
            </div>
            <div class="detailSubTitle">
                <span class="detailTitle">Descrição</span>
                <span class="detailValue">{{ selectedNcm?.descricao | cmxValid }}</span>
            </div>
            <div class="detailSubTitle half">
                <div class="subContent">
                    <span class="detailTitle">Vigência</span>
                    <span class="detailValue">{{
                        moment(selectedNcm?.inicioVigencia).format('DD/MM/YYYY') | cmxValid
                    }}</span>
                </div>
                <div class="subContent">
                    <span class="detailTitle">IPI</span>
                    <span class="detailValue">{{ selectedNcm?.IPI | cmxValid }}%</span>
                </div>
            </div>
            <div class="detailSubTitle">
                <span class="detailTitle">Unidade Tributária</span>
                <span class="detailValue">{{ selectedNcm?.descricaoUTrib | cmxValid }}</span>
            </div>
        </div>
    </div>
</div>

<!-- diModal -->
<div class="diModal hidden">
    <div class="fixedHeader">
        <h5>Dados de DA</h5>
        <hr />
        <div class="modalTitle">
            <b>Registro DA: {{ daNumber }}</b>
        </div>
        <hr />
    </div>
    <div class="modalContent">
        <i (click)="closeDi()" class="close la la-close"></i>
        <table>
            <tr>
                <th>Registro DI</th>
                <th>Data Registro DI</th>
                <th style="text-align: right">Canal DI</th>
            </tr>
            <tr *ngFor="let di of diList; let i = index; trackBy: trackByFn">
                <td>{{ di.registrationNumber }}</td>
                <td>{{ di.registrationDate }}</td>
                <td style="text-align: right">
                    <span [innerHTML]="di.channel" style="display: flex; justify-content: center"></span>
                </td>
            </tr>
        </table>
    </div>
</div>

<!-- RefModal -->
<div class="RefModal hidden">
    <div class="fixedHeader">
        <h5>Referências</h5>
        <hr />
    </div>
    <div class="modalContent">
        <i (click)="closeRefs()" class="close la la-close"></i>
        <table>
            <tr *ngFor="let reference of references; let i = index; trackBy: trackByFn">
                <td>{{ reference }}</td>
            </tr>
        </table>
    </div>
</div>
