import { environment } from '#environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DownloadService } from '../_download/download.service';

@Injectable()
export class FinanceExportService {

    public name;

    constructor(
        private http: HttpClient,
        private downloadService: DownloadService,
    ) { }

    public exportArquive(arquive): Observable<any> {
        const options = this.downloadService.getSourceOptions();
        options.headers = new HttpHeaders({
            'X-Requested-Client-Groups': '',
            'Accept': 'application/vnd.ms-excel',
        });
        const url = this.http.get(`${environment.endpoints.resultsService}/expenses-worksheet/${arquive}`, options);
        return this.downloadService.downloadFrom(url, `${arquive}.xls`);
    }

    public exportZip(imp): Observable<any> {
        const options = this.downloadService.getSourceOptions();
        options.headers = new HttpHeaders({
            'X-Requested-Client-Groups': '',
            'Accept': 'application/zip',
        });
        const url = this.http.get(`${environment.endpoints.resultsService}/expenses-worksheet/zip/${imp}`, options);
        return this.downloadService.downloadFrom(url, `Planilhas de Custos de: ${imp}`);
    }

}
