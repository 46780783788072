
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { environment } from '#environment';

@Injectable()
export class SlaConsolidationService {

    constructor(private _http: HttpClient) { }

    getSlaConsolidationResult(request): Observable<any> {
        return this._http.get(`${environment.endpoints.slaKpiService}/sla/percentage-status`, request);
    }

    getSlaDetailResult(request): Observable<any> {
        return this._http.get(`${environment.endpoints.slaKpiService}/sla/percentage-kpi`, request);
    }

}
