import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import moment from 'moment';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IChassiStatusFilter } from './interface/chassi-status.interface';
export class ChassiStatusFilter {
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);
    private _initialChassiStatusFilter: IChassiStatusFilter = <IChassiStatusFilter>{};
    private _chassiStatusFilter: IChassiStatusFilter = <IChassiStatusFilter>{
        chassi: '',
        colors: [],
        models: [],
        clientGroups: [],
        referenceDateType: 'registration',
        status: [
            { name: 'Aguard. Embarque', code: 'EMBARQUE' },
            { name: 'Em Trânsito', code: 'TRANSITO' },
            { name: 'Aguard. Presença de Carga', code: 'CARGA' },
            { name: 'Aguard. Liberação', code: 'LIBERACAO' },
            { name: 'Aguard. Registro DA', code: 'REGISTRO_DA' },
            { name: 'Aguard. Registro DI', code: 'REGISTRO' },
            { name: 'Aguard. Desembaraço DA', code: 'DESEMBARACO_DA' },
            { name: 'Aguard. Desembaraço DI', code: 'DESEMBARACO' },
            { name: 'Aguard. Faturamento', code: 'FATURAMENTO' },
            { name: 'Faturado Comexport', code: 'FATURADO' },
            { name: 'Bloqueado', code: 'BLOQUEADO' },
            { name: 'Aguard. PDI', code: 'VPCEND' },
            { name: 'Disponível para Faturar', code: 'DISPFATURAMENTO' },
        ]
    };

    private startDate;
    private endDate;
    private offset: number;
    private limit: number;
    private page: number;

    constructor(filter?: ChassiStatusFilter) {
        this._chassiStatusFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'chassiStatusFilter', this._chassiStatusFilter, filter);
        this._initialChassiStatusFilter = _.cloneDeep(this._chassiStatusFilter);

        this.startDate = filter ? filter.startDate : moment().add(-24, 'M').format('DD/MM/YYYY');
        this.endDate = filter ? filter.endDate : moment().format('DD/MM/YYYY');
        this.offset = filter ? filter.offset : 0;
        this.limit = filter ? filter.limit : 30;
        this.page = filter ? filter.page : 0;
    }

    public getSearch(): string { return this._chassiStatusFilter.chassi; }
    public getColors(): any[] { return this._chassiStatusFilter.colors; }
    public getModels(): any[] { return this._chassiStatusFilter.models; }
    public getClientGroups(): string[] { return this._chassiStatusFilter.clientGroups; }
    public getReferenceDateType(): string { return this._chassiStatusFilter.referenceDateType; }
    public getStartDate() { return this.startDate; }
    public getEndDate() { return this.endDate; }
    public getStatus(): any[] { return this._chassiStatusFilter.status; }
    public getOffset(): number { return this.offset; }
    public getLimit(): number { return this.limit; }
    public getPage(): number { return this.page; }

    public setSearch(chassi): void { this._chassiStatusFilter.chassi = chassi; }
    public setColors(colors): void { this._chassiStatusFilter.colors = colors; }
    public setModels(models): void { this._chassiStatusFilter.models = models; }
    public setClientGroups(clientGroups): void { this._chassiStatusFilter.clientGroups = clientGroups; }
    public setReferenceDateType(referenceDateType): void { this._chassiStatusFilter.referenceDateType = referenceDateType; }
    public setStartDate(startDate): void { this.startDate = startDate; }
    public setEndDate(endDate): void { this.endDate = endDate; }
    public setStatus(status): void { this._chassiStatusFilter.status = status; }
    public setOffset(offset): void { this.offset = offset; }
    public setLimit(limit): void { this.limit = limit; }
    public setPage(page): void { this.page = page; }

    public getRequest(allChassiList?, count?, currentUser?) {
        this._initialChassiStatusFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialChassiStatusFilter, this._chassiStatusFilter, 'chassiStatusFilter', this._userCacheService.getUserPreferences().id);
        const headers = {
            clientCodes: this.getSearch() !== '' ? '' : this.getClientGroups().length === 0 ? '' :
                this.getClientGroups().map((c) => c['id']).join(','),
        };
        let paramsPage = new HttpParams();
        const colors = [];
        const models = [];
        this.getColors().map((c) => colors.push(c['itemName']));
        this.getModels().map((c) => models.push(c['itemName']));
        const params = {
            chassi: this.getSearch(),
            colors: this.getSearch() !== '' ? [] : colors,
            models: this.getSearch() !== '' ? [] : models,
            referenceDateType: this.getSearch() !== '' ? 'registration' : this.getReferenceDateType(),
            startDate: `${this.getSearch() === '' ?
                moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD') :
                moment().add(-6, 'M').format('YYYY-MM-DD')}`,
            endDate: `${this.getSearch() === '' ?
                moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD') :
                moment().format('YYYY-MM-DD')}`,
            showInternalFilters : currentUser !== undefined && currentUser !== null ?
                currentUser.has('INTERNAL_FILTERS') :
                false,
            status: this.getSearch() === '' ? this.getStatus() : allChassiList,
        };
        if (!count) {
            params['limit'] = this.getLimit();
            params['offset'] = this.getOffset() * 30;
        }
        paramsPage = paramsPage.append('page', `${this.getPage()}`);
        return { params, headers };
    }

    getRequestParams(currentUser?) {
        this._initialChassiStatusFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialChassiStatusFilter, this._chassiStatusFilter, 'chassiStatusFilter', this._userCacheService.getUserPreferences().id);
        const chassis = this.getSearch();
        const colors = this.getColors().map((c) => c.id).join(',');
        const models = this.getModels().map((m) => m.id).join(',');

        let params = '?';

        if (chassis === '') {
            if (this.getStartDate() !== '') {
                params += `startDate=${moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`;
            }

            if (this.getEndDate() !== '') {
                params += `&endDate=${moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`;
            }

            if (colors !== '') {
                params += `&colors=${colors}`;
            }

            if (models !== '') {
                params += `&models=${models}`;
            }

            params += `&status=${this.getStatus().map((status) => `${status.code}-${status.name}`).join(',')}`;

            if (currentUser !== undefined && currentUser !== null) {
                params += `&showInternalFilters=${currentUser.has('INTERNAL_FILTERS')}`;
            }
        } else {
            if (this.getStartDate() !== '') {
                params += `startDate=${moment().add(-6, 'M').format('YYYY-MM-DD')}`;
            }

            if (this.getEndDate() !== '') {
                params += `&endDate=${moment().format('YYYY-MM-DD')}`;
            }
            params += `&chassis=${chassis}`;
        }

        return params;
    }

}
