import { CmxDropdownModule } from 'app/shared/components/cmx-dropdown/cmx-dropdown.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { VanSVGComponent } from './automotive/van/van-svg';
import { VehicleSVGComponent } from './automotive/vehicle/vehicle-svg';
import { CargoVesselWithContainerSVGComponent } from './importation/cargo-vessel-with-container/cargo-vessel-with-container';

@NgModule({
    entryComponents: [VehicleSVGComponent, VanSVGComponent, CargoVesselWithContainerSVGComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, CmxDropdownModule],
    declarations: [VehicleSVGComponent, VanSVGComponent, CargoVesselWithContainerSVGComponent],
    exports: [VehicleSVGComponent, VanSVGComponent, CargoVesselWithContainerSVGComponent],
})
export class SVGModule {}
