export const COST_DISTRIBUTION_CHART_CONFIG = {
    type: 'pie',
    theme: 'light',
    startDuration: 0,
    pieY: '60%',
    radius: 90,
    valueField: 'value',
    titleField: 'name',
    labelRadius: 2, 
    innerRadius: '40%',
    outlineAlpha: 0.4,
    depth3D: 10,
    fontSize: 9,
    maxLabelWidth: 150,
    colors: [
        '#1f3d7a',
        '#2a52a2',
        '#3466cb',
        '#5d85d5',
        '#85a3e0',
        '#aec2ea',
        '#3c4861',
        '#505f7c',
        '#7585a5',
        '#54585f',
        '#73767c',
        '#433f79',
        '#454091',
        '#26235a' 
    ],
    export: {
        enabled: true,
        menu: [{
            format: 'PDF',
            label: "<i class='flaticon-download'></i>",
            title: 'Gerar PDF',
        }],
    },
}