<div class="cmx-drag-and-drop-table">
    <div class="titleContainer">
        <span class="title">{{ title }}</span>
        <input [hidden]="!search" type="text" [(ngModel)]="searchValue" [placeholder]="'Buscar ' + search">
        <span class="warning-btn" *ngIf="columnsWarning <= getColumnLength('left')">
            <em class="material-icons" [tooltip]="'Muitas colunas selecionadas (' + getColumnLength('left') + '). Sua tela poderá apresentar lentidão.'" placement="bottom">warning</em>
        </span>
    </div>
    <div id='drag-and-drop-main' [style.height]="height"
    class="mainContainer" cdkDropListGroup>
        <div id="cmx-drag-and-drop-table-left-column-container" class="columnContainer"
            [style.width]="showMode ? '100%' : tableType === 'grouped' ? '68%' : '44%'"
            [style.height]="leftContainerHeightBase">
            <span *ngIf="!showMode" class="title" [style.color]="leftColor">
                <span *ngIf="leftIcon" class="material-icons">{{ leftIcon }}</span>
                {{ leftTitle }}
            </span>
            <span *ngIf="enableMultiSelect && leftData.length && !showMode" (click)="selectAll('left')" class="selectAll">
                {{ verifyAllSelected('left') ? 'deselecionar' : 'selecionar' }} todos
            </span>
            <div cdkDropList cdkDropListGroup
                id="left-list-container"
                class="dropList {{ tableType === 'grouped' ? 'grouped' : '' }}"
                [cdkDropListOrientation]="tableType === 'grouped' ? 'horizontal' : 'vertical'"
                [style.flex-direction]="tableType === 'grouped' ? 'row' : 'column'"
                [cdkDropListData]="leftData"
                [cdkDropListConnectedTo]="tableType === 'grouped' ? null : ['cdk-drop-list-1']"
                (cdkDropListDropped)="drop($event)">
                <div *ngFor="let item of getMainData()" class="{{ tableType === 'grouped' ? 'group-container' : '' }}">
                    <div *ngIf="showMode" (click)="showAll(hasHiddenColumns(item) ? true : false, item?.name)" class="show-all">
                        <span class="material-icons">{{ hasShowColumns(item) ? 'visibility' : 'visibility_off'}}</span></div>
                    <div *ngIf="filterBySearch(item)" cdkDrag [cdkDragDisabled]="!enableGroupReorder"
                    class="cmx-drag-and-drop-table-box
                        {{ tableType === 'grouped' ? 'grouped' : '' }}
                        {{ item?.selected ? 'selected' : '' }}"
                    [cdkDragLockAxis]="tableType === 'grouped' ? 'x' : ''"
                    [style.padding-left]="showMode ? '40px' : '0'"
                    (click)="selectContainerGroupBox(item)">
                        <div class="rename-group-container" id="{{ item?.name }}"y
                            style="width: -webkit-fill-available"
                            [style.padding-left]="showMode ? '0px' : '10px'">
                            <img *ngIf="leftInTableIconType === 'svg' && leftInTableIcon"
                            src="{{ leftInTableIcon }}" alt="">
                            <span *ngIf="leftInTableIconType === 'material-icon' && leftInTableIcon"
                            class="material-icons">{{ leftInTableIcon }}</span>
                            <span style="pointer-events: none; text-wrap: nowrap">{{ item?.name }}</span>
                        </div>
                        <div *ngIf="item.isCustom" (click)="deleteGroup(item)" class="delete-button">
                            <span class="material-icons">delete</span>
                        </div>
                    </div>
                    <div *ngIf="tableType === 'grouped'" cdkDropList
                    [cdkDropListData]="item.data"
                    [cdkDropListConnectedTo]="subItemListConnection"
                    (cdkDropListDropped)="drop($event)"
                    class="group-itens-container">
                        <div *ngFor="let groupItem of item.data"
                        class="group-item-container">
                            <div *ngIf="filterBySearch(groupItem, 'grouped')" cdkDrag
                                class="group-item-content"
                                (click)="selectBox(groupItem, null, showMode ? item?.name : null)">
                                <div *ngIf="!showMode" class="selectBox {{ groupItem?.selected }}"></div>
                                <span *ngIf="showMode" class="material-icons visibility {{ !groupItem?.show ? 'visibility_off' : '' }}">
                                    {{ groupItem?.show ? 'visibility' : 'visibility_off' }}</span>
                                <span style="text-wrap: nowrap">{{ groupItem?.name || groupItem?.label }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!showMode" class="arrowContainer">
            <img src="../../../../assets/app/media/img/icons/drag-and-drog-table/arrow-left.svg"
            *ngIf="tableType !== 'grouped'"
            (click)="sendAllSelected('right')" class="{{ verifyEnabledArrow('right') ? 'enabled' : '' }}" alt="">
            <img src="../../../../assets/app/media/img/icons/drag-and-drog-table/arrow-right.svg"
            (click)="sendAllSelected('left')" class="{{ verifyEnabledArrow('left') ? 'enabled' : '' }}" alt="">
        </div>

        <div id="cmx-drag-and-drop-table-right-column-container" class="columnContainer"
            *ngIf="!showMode"
            [style.width]="tableType === 'grouped' ? '20%' : '44%'"
            [style.height]="rightContainerHeightBase"
            [style.margin-bottom]="enableSaveButton ? '20px' : '0px'">
            <span class="title" [style.color]="rightColor">
                <span *ngIf="rightIcon" class="material-icons">{{ rightIcon }}</span>
                {{ rightTitle }}
            </span>
            <span *ngIf="enableMultiSelect && rightData.length && tableType !== 'grouped'" (click)="selectAll('right')" class="selectAll">
                {{ verifyAllSelected('right') ? 'deselecionar' : 'selecionar'}} todos
            </span>
            <span *ngIf="rightData.length && tableType === 'grouped'" (click)="removeAllFromBin()" class="selectAll">
                permitir todos
            </span>
            <div cdkDropList cdkDropListGroup
                id="right-list-container"
                class="dropList group-itens-container"
                [cdkDropListData]="rightData"
                [cdkDropListConnectedTo]="subItemListConnection"
                (cdkDropListDropped)="drop($event)">
                <div *ngFor="let item of rightData">
                    <div *ngIf="filterBySearch(item, tableType === 'grouped')" cdkDrag [cdkDragDisabled]="enableRightDrag"
                        class="cmx-drag-and-drop-table-box {{ item?.selected ? 'selected' : '' }}"
                        (click)="selectBox(item, 'rightData')">
                        <img *ngIf="rightInTableIconType === 'svg' && rightInTableIcon"
                            src="{{ rightInTableIcon }}" alt="">
                        <span *ngIf="rightInTableIconType === 'material-icon' && rightInTableIcon"
                            class="material-icons">{{ rightInTableIcon }}</span>
                        <span>{{ item?.name || item?.label }}</span>
                        <img src="../../../../assets/app/media/img/icons/drag-and-drog-table/arrow-left.svg"
                            *ngIf="tableType === 'grouped'"
                            (click)="sendBackToGroup(item)" class="enabled" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="action-buttons-container" [style.justify-content]="tableType === 'grouped' ? 'space-between' : 'flex-end'">
        <div *ngIf="tableType === 'grouped'" style="display: flex">
            <!-- <div *ngIf="enableResetNames" class="action-button resetNames" (click)="resetGroupNames()">
                <rem class="material-icons">settings_ethernet</rem><span>Resetar Nomes</span></div> -->
            <div *ngIf="enableRearrangeGroups" class="action-button resetGroups" (click)="resetGroupPositions()">
                <rem class="material-icons">view_week</rem><span>Reordenar Grupos</span></div>
            <div *ngIf="enableResetAll" class="action-button reset" (click)="resetColumnPositions()">
                <rem class="material-icons">dashboard</rem><span>Resetar Campos</span></div>
            <!-- <div *ngIf="enableColumnCreate" class="action-button new" (click)="createGroup()">
                <rem class="material-icons">add_box</rem><span>Adicionar Grupo</span></div> -->
        </div>
        <div *ngIf="enableSaveButton" style="display: flex">
            <cmx-button 
                variant="save"
                width="140px"
                (click)="finishButton()">
                Salvar
            </cmx-button>
        </div>
    </div>

</div>
