<form id="filter-form" class="m-form m-form--label-align-right" *ngIf="!loadingData">

    <div class="m-portlet__body">
        <div class="row" id="hierarchyGroupingRow">
            <div class="col-4">
                <div class="form-group m-form__group">
                    <label class="text-right" for="hierarchy">
                        Hierarquia
                    </label>
                    <select id="sel-hierarchy" (change)="checkValueBySort()" class="form-control m-bootstrap-select m_selectpicker" name="hierarchy" [(ngModel)]="editable.hierarchy">
                        <option *ngFor="let option of options.hierarchies; trackBy: trackByFn" value="{{option.value}}">
                            {{option.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group m-form__group">
                    <label class="text-right" for="hierarchy">
                        Contexto
                    </label>
                    <select id="sel-grouping" (change)="loadImpDropDown(); checkValueBySort()" class="form-control m-bootstrap-select m_selectpicker" name="grouping" [(ngModel)]="editable.grouping">
                        <option *ngFor="let option of options.groupings; trackBy: trackByFn" value="{{option.value}}">
                            {{option.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group m-form__group">
                    <label class="text-right">
                        Ordenar Por
                    </label>
                    <select id="sel-sortBy" class="form-control m-bootstrap-select m_selectpicker" name="sortBy" [(ngModel)]="editable.sortBy">
                        <option value="">Selecione</option>
                        <option *ngFor="let option of options.sortBy; trackBy: trackByFn" value="{{option.name}}">
                            {{option.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="form-group m-form__group">
            <label class="text-right" for="dateRange">
                Período
            </label>
            <div class="row" id="periodRow">
                <div class="col-6 col-lg-3">
                    <div class="input-group">
                        <span class="input-group-addon">
                            De
                        </span>
                        <input autocomplete="off" name="periodFrom" id="periodFrom" [(ngModel)]="editable.filter.startDate" type="text" class="form-control m-input" />
                    </div>
                </div>
                <div class="col-6 col-lg-3" id="to">
                    <div class="input-group">
                        <span class="input-group-addon">
                            Até
                        </span>
                        <input autocomplete="off" name="periodTo" id="periodTo" [(ngModel)]="editable.filter.endDate" type="text" class="form-control m-input" />
                    </div>
                </div>
                <div class="col-12 col-lg-6 py-3 py-lg-0 preDefined">
                    <button type="button" class="btn btn-secondary m-btn px-2" (click)="changePeriod('YTD')">YTD</button>
                    <button type="button" class="btn btn-secondary m-btn px-2" (click)="changePeriod('previousYear')">Ano anterior</button>
                    <label *ngIf="currencySwitch" class="switch">
                        <input type="checkbox" name="currencyValue" [(ngModel)]="currencyValue">
                        <span class="slider"></span>
                        <span class="m-form__help">BRL</span>
                        <span class="m-form__help right">USD</span>
                    </label>
                    <label *ngIf="crGroupedSwitch" class="switchCr">
                        <input type="checkbox" name="crGroupedValue" [(ngModel)]="crGroupedValue">
                        <span class="slider"></span>
                        <span class="m-form__help">Separado</span>
                        <span class="m-form__help right">Agrupado</span>
                        <div style="margin-top: 7px;">CR 35</div>
                    </label>
                </div>
                <span class="m-form__help"></span>
            </div>
        </div>

        <p-accordion>
            <p-accordionTab header="Mais filtros">
                <div *ngIf="!verifyImpState()" class="form-group m-form__group" style="padding: 0">
                    <label class="text-right" for="companies">Empresa</label>
                    <div class="row">
                        <div class="col-9 col-lg-10">
                            <cmx-dropdown id="companies" size="md" [data]="options.companies" [(ngModel)]="editable.filter.companies" [settings]="dropdownSettings"
                            (onChange)="verifyCompanies()" name="companies"></cmx-dropdown>
                        </div>
                        <!-- <div class="col-2">
                            <button type="button" class="btn btn-secondary" (click)="derive('company')">Derivar</button>
                        </div> -->
                    </div>
                </div>

                <div *ngIf="!verifyImpState()" class="form-group m-form__group" style="padding: 0">
                    <label class="text-right" for="companies">Centro de Resultado</label>
                    <div class="row">
                        <div class="col-9 col-lg-10">
                            <cmx-dropdown id="profitCenter" size="md" [data]="options.profitCenter" [(ngModel)]="editable.filter.profitCenter" [settings]="dropdownSettings"
                                name="profitCenter"></cmx-dropdown>
                        </div>
                        <!-- <div class="col-2">
                            <button type="button" class="btn btn-secondary" (click)="derive('profitCenter')">Derivar</button>
                        </div> -->
                    </div>
                </div>

                <div id="dropMgmt" style="margin-bottom: 8px">
                <div *ngIf="!verifyImpState()" class="form-group m-form__group" style="padding: 0"
                onclick="$('#dropMgmt').css('display', 'block')">
                    <label class="text-right" for="companies">Tipos de Lançamentos</label>
                        <div class="row">
                            <div class="col-9 col-lg-10">
                                <cmx-dropdown id="transactionTypes" size="md" [data]="options.transactionTypes" [(ngModel)]="editable.filter.transactionTypes" [settings]="dropdownSettings"
                                name="transactionTypes"></cmx-dropdown>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="!verifyImpState()" class="form-group m-form__group" style="padding: 0">
                    <label class="text-right" for="companies">Trader</label>
                    <div class="row">
                        <div class="col-9 col-lg-10">
                            <cmx-dropdown id="trader" size="md" [data]="options.trader" [(ngModel)]="editable.filter.trader" [settings]="dropdownSettings"
                                name="trader"></cmx-dropdown>
                        </div>
                        <!-- <div class="col-2">
                            <button type="button" class="btn btn-secondary" (click)="derive('trader')">Derivar</button>
                        </div> -->
                    </div>
                </div>

                <div *ngIf="!verifyImpState()" class="form-group m-form__group" style="padding: 0">
                    <label class="text-right" for="companies">Diretor</label>
                    <div class="row">
                        <div class="col-9 col-lg-10">
                            <cmx-dropdown id="director" size="md" [data]="options.director" [(ngModel)]="editable.filter.director" [settings]="dropdownSettings"
                            name="director"></cmx-dropdown>
                        </div>
                        <!-- <div class="col-2">
                            <button type="button" class="btn btn-secondary" (click)="derive('director')">Derivar</button>
                        </div> -->
                    </div>
                </div>

                <div id="clientDropdown" class="form-group m-form__group" style="padding: 0">
                    <label class="text-right" for="companies">Cliente</label>
                    <div class="row">
                        <div class="col-9 col-lg-10">
                            <cmx-dropdown id="client" size="md" [data]="options.client" [(ngModel)]="editable.filter.client" [settings]="dropdownSettings"
                            (onChange)="loadImpDropDown()" name="client"></cmx-dropdown>
                        </div>
                        <!-- <div class="col-2">
                            <button type="button" class="btn btn-secondary" (click)="derive('client')">Derivar</button>
                        </div> -->
                    </div>
                </div>

                <div *ngIf="editable.filter.client.length === 1 && editable.grouping === 'byImp'" id="impDropdown" class="form-group m-form__group" style="padding: 0">
                    <label class="text-right" for="companies">IMPs</label>
                    <div class="row">
                        <cmx-dropdown id="imps" size="md" [data]="options.imps" [(ngModel)]="editable.filter.imps"
                        [settings]="dropdownSettings" name="imps" style="width:calc(100% - 30px); margin-left:15px"></cmx-dropdown>
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>
        <div id="buttonsForTraders">
            <button id="cancel" onclick="$('.reportFilterModal').addClass('hidden'); $('#buttonsForTraders').css('display', 'none');">Cancelar</button>
            <button id="apply" (click)="applyChanges()">Aplicar</button>
        </div>
    </div>
</form>

<ng-container cmx-page-wrapper-body *ngIf="loadingData">
    <loader></loader>
</ng-container>
