

import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';

import { environment } from '#environment';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { DomainService } from '#services/_domain/domain.service';
import { UserService } from 'app/auth/_services';

@Component({
    selector: 'permission-info',
    templateUrl: './permission-info.component.html',
    styleUrls: ['./permission-info.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PermissionInfoComponent implements OnInit {
    crs = [
        {
            code: '',
            name: '',
            type: '',
        },
    ];
    clients = [
        {
            code: '',
            name: '',
            type: '',
        },
    ];
    preferenceClients = [
        {
            id: '',
            itemName: ''
        },
    ];
    currentUser;
    asyncPermisssionInfo = false;

    constructor(
        private domainService: DomainService,
        private userService: UserService,
        private http: HttpClient,
        private userCacheService: UserCacheService
    ) {}

    ngOnInit() {
        this.userService.currentUser$.subscribe((user) => {
            forkJoin([this.domainService.getProfitCenter()]).subscribe(
                (values: any) => {
                    const [crs, data] = values;
                    this.currentUser = user;
                    this.clients = this.userCacheService.getClientGroups();
                    this.crs = crs;
                    this.preferenceClients = this.userCacheService.getUserPreferences()?.filters?.clientGroups?.content?.value;
                    this.asyncPermisssionInfo = true;
                }
            );
        });
    }

    openPermissionInfoModal() {
        $('.permissionInfoModal').removeClass('hidden');
    }
    closePermissionInfoModal() {
        $('.permissionInfoModal').addClass('hidden');
    }
    openDateInfoModal() {
        $('.dateInfoModal').removeClass('hidden');
    }
    closeDateInfoModal() {
        $('.dateInfoModal').addClass('hidden');
    }

    trackByFn(index: number, item: any) {
        return index;
    }
}
