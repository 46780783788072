<div *ngIf="asyncLiLeadTime">
    <loader></loader>
</div>
<div class="title-information">
    <span class="analyticsName">Quantidade de LI's e seu LeadTime</span>
    <cmx-help-modal help-title="Quantidade de LI's e seu LeadTime" [help-description]="helpDescription"></cmx-help-modal>
    <h5>Quantidade de LI's e seu LeadTime</h5>
</div>
<div *ngIf="currentUser$ | async as user" id="searchLine" class="reduzed">
    <cmx-dropdown
        *ngIf="user.has('INTERNAL_FILTERS')"
        [styles]="{ width: '180px' }"
        name="clients"
        [data]="options.clients"
        [ngModel]="filterState.getClientGroups()"
        (ngModelChange)="filterState.setClientGroups($event)"
        [settings]="dropdownSettings.clientGroups"
    >
    </cmx-dropdown>
    <div class="input-group right">
        <cmx-filter-datepicker
            id="sku-status-datepicker"
            periodType="month"
            type="range-month"
            [date]="'Registro'"
            [startDate]="filterState.getStartDate()"
            [endDate]="filterState.getEndDate()"
            (onChangeDates)="datesChanged($event)"
        ></cmx-filter-datepicker>
    </div>
    <button (click)="togglePeriodSwitch()" class="applyImpStatus">
        <i class="fa fa-check"></i>
    </button>
</div>

<span *ngIf="noContent && !asyncLiLeadTime" class="noContent">Sem resultados para esse filtro</span>

<i class="resizeBtn solo la la-expand" (click)="chartService.resizeBtn($event)"></i>
<div class="chart" id="lileadtime"></div>
<div *ngIf="asyncLiLeadTime && chartData === undefined" class="reloadContainer">
    <div class="reloadContent">
        <i (click)="loadLiLeadTime()" class="material-icons">refresh</i>
    </div>
</div>
