<div *ngIf="asyncDistributionCost">
    <loader></loader>
</div>
<div class="title-information">
    <span class="analyticsName">Custos Distribuídos</span>
    <cmx-help-modal help-title="Custos Distribuídos" [help-description]="helpDescription"></cmx-help-modal>
    <h5>Custos</h5>
</div>

<div class="moveBtn">
    <div class="moveLine"></div>
</div>
<cmx-button
    class="detailsCosts"
    [variant]="'primary'"
    (click)="detailsCosts()"
>Detalhamento</cmx-button>

<div *ngIf="currentUser$ | async as user" id="searchLine" class="reduzed">
    <div class="search-container" style="margin-right: 42px">
        <cmx-filter-search
            [type]="filterState.getSearchType()"
            [types]="['IMP', 'REF', 'SKU']"
            [width]="'90px'"
            [value]="filterState.getSearchValue()"
            (onInput)="setSearch($event)"
            (onChangeType)="changeSearchType($event)"
            (onApply)="applyFilter()"
        ></cmx-filter-search>
    </div>

    <div class="search-container">
        <div class="input-group">
            <cmx-dropdown
                [styles]="{ width: '85px' }"
                *ngIf="user.has('INTERNAL_FILTERS')"
                id="clients"
                name="clients"
                [data]="options.clients"
                [ngModel]="filterState.getClientGroups()"
                (ngModelChange)="filterState.setClientGroups($event)"
                [settings]="dropdownSettings.clientGroups"
            ></cmx-dropdown>
        </div>

        <div class="input-group">
            <cmx-filter-datepicker
                [date]="'Faturamento'"
                [startDate]="filterState.getStartDate()"
                [endDate]="filterState.getEndDate()"
                (onChangeDates)="datesChanged($event)"
            ></cmx-filter-datepicker>
        </div>

        <div
            *ngIf="filterState.getSearchValue().length !== 0 && filterState.getSearchType() !== 'SKU'"
            class="disableSearch"
            title="Os filtros são desativados ao realizar uma busca específica.">
        </div>

        <button (click)="applyFilter()" class="applyImpStatus">
            <i class="fa fa-check"></i>
        </button>
    </div>
</div>

<div *ngIf="!asyncDistributionCost && currentUser$ | async as user" class="checkmark-container">
    <div *ngIf="!zeroedData.valorcif" id="valor" class="checkmark"
        (click)="toggleTypeSwitch('Valor CIF', $event)">
        <img src="./assets/app/media/img/icons/checkmark_{{ user.has('AUTOMOTIVE_USER') ? 'car' : 'fob' }}-checked.png" alt=""/>
        <span class="tooltip-text">Valor CIF</span>
    </div>
    <div *ngIf="!zeroedData.impostosdanacionalizao" id="impostos" class="checkmark"
        (click)="toggleTypeSwitch('Impostos da Nacionalização', $event)">
        <img src="./assets/app/media/img/icons/checkmark_tax.png" alt="" />
        <span class="tooltip-text">Impostos da Nacion.</span>
    </div>
    <div *ngIf="!zeroedData.despesasoperacionais" id="operacionais" class="checkmark impStatus-table"
        (click)="toggleTypeSwitch('Despesas Operacionais', $event)">
        <img src="./assets/app/media/img/icons/checkmark_despesas.png" alt="" />
        <span class="tooltip-text">Despesas Operacionais</span>
    </div>
    <div *ngIf="!zeroedData.despesasfinanceiras" id="financeiras" class="checkmark impStatus-table"
        (click)="toggleTypeSwitch('Despesas Financeiras', $event)">
        <img src="./assets/app/media/img/icons/checkmark_despesas_financeiras.png" alt="" />
        <span class="tooltip-text">Despesas Financeiras</span>
    </div>
    <div *ngIf="!zeroedData.despesasservios" id="servicos" class="checkmark impStatus-table"
        (click)="toggleTypeSwitch('Despesas Serviços', $event)">
        <img src="./assets/app/media/img/icons/checkmark_despesas_financeiras.png" alt="" />
        <span class="tooltip-text">Despesas Serviços</span>
    </div>
    <div *ngIf="!zeroedData.crditoimpostosentrada" id="entrada" class="checkmark impStatus-table"
        (click)="toggleTypeSwitch('Crédito Impostos Entrada', $event)">
        <img src="./assets/app/media/img/icons/checkmark_nf.png" alt="" />
        <span class="tooltip-text">Créd. Impostos Entrada</span>
    </div>
    <div *ngIf="!zeroedData.impostosdesada" id="saida" class="checkmark impStatus-table"
        (click)="toggleTypeSwitch('Impostos de Saída', $event)">
        <img src="./assets/app/media/img/icons/checkmark_nf.png" alt="" />
        <span class="tooltip-text">Impostos de Saída</span>
    </div>
    <div *ngIf="!zeroedData.ipi" id="ipi" class="checkmark impStatus-table"
        (click)="toggleTypeSwitch('IPI', $event)">
        <img src="./assets/app/media/img/icons/checkmark_nf.png" alt="" />
        <span class="tooltip-text">IPI</span>
    </div>
</div>

<div class="chart" id="costsDistributionChart"></div>
<div *ngIf="!asyncDistributionCost && (chartData === undefined || !chartData.length)" class="reloadContainer">
    <div class="reloadContent">
        <i (click)="loadCostsDistribution()" class="material-icons">refresh</i>
    </div>
</div>
