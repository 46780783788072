<div *ngIf="asyncProfile == false" class="fullLoader admModalLoader">
    <loader></loader>
</div>

<i (click)="close()" id="close" class="la la-close"></i>

<div class="admin-account-border">
    <div class="line-space">
        <cmx-listing
            [list]="utilsService.sortObjectArrayBy(profiles, 'name')"
            [hasAddButton]="true"
            (onSelect)="selectProfile($event)"
            searchPlaceholder="Buscar Perfil"
            height="calc(100vh - 160px)"
            (onNewItem)="clearProfileForm()"
            icon="person"
            iconAtributeCondition="iconCondition"
            (onClickIcon)="selectProfile($event, true)"
        ></cmx-listing>
    </div>
</div>
<div class="admItem">
    <a *ngIf="newProfile == false" (click)="admService.openDeleteModal(modal)" class="deleteBtn"><i class="la la-trash"></i></a>
    <span *ngIf="newProfile" class="admTitle">Adicionar Novo Perfil</span>
    <span *ngIf="newProfile == false" class="admTitle">Editar Perfil</span>
    <hr>
    <div class="admForm">
        <label>Nome</label>
        <input [(ngModel)]='selectedProfile.name' class="admInput" type="text" (keyup)="refreshState()">
        <div class="checkContainer">
            <div class="admCheckGroup" *ngFor='let tag of permissionTags; let i = index; trackBy: trackByFn'>
                <div class="admCheck route">
                    <input (click)='routeClick(tag, $event)' id='{{ tag }}' class="admCheckItem" name="type" type="checkbox" [checked]="profileContainsAny(tag)">
                    <label (click)='routeClick(tag, $event)'>{{ tag }}</label>
                </div>
                <div *ngFor='let permissionGroup of permissionGroups[tag]; trackBy: trackByFn' class="admCheck component">
                    <input (click)="inputClicked(permissionGroup, tag)" [checked]="selectedProfile.has(permissionGroup)" class="admCheckItem component {{ tag }}" name="type" type="checkbox">
                    <label (click)="inputClicked(permissionGroup, tag)">{{ permissionGroup.description }}</label>
                </div>
            </div>
        </div>

        <div class="timeContainer">
            <div class="timeContent externalAccess">
                <span class="timeTitle">Acesso Externo</span>
                <div (click)='toggleTimeSwitch($event)' class="toogleContainer">
                    <div class="toogleContent">
                        <label class="toogleLeft">Bloquear</label>
                        <span class="middleHandler"></span>
                        <label class="toogleRight">Permitir</label>
                    </div>
                </div>
            </div>
            <div class="timeContent">
                <span class="timeTitle">Entrada</span>
                <input class='timeInput' type="time" [(ngModel)]="selectedProfile.startHour">
            </div>
            <div class="timeContent">
                <span class="timeTitle">Saída</span>
                <input class='timeInput' type="time" [(ngModel)]="selectedProfile.endHour">
            </div>
        </div>
        <div class="timeContainer expiration">
            <div class="timeContent simultaneousLogin">
                <span class="timeTitle">Acesso Simultâneo</span>
                <div (click)='toggleSimultaneousLoginSwitch($event)' class="toogleContainer">
                    <div class="toogleContent">
                        <label class="toogleLeft">Bloquear</label>
                        <span class="middleHandler"></span>
                        <label class="toogleRight">Permitir</label>
                    </div>
                </div>
            </div>
            <div class="timeContent">
                <span class="timeTitle">Expiração</span>
                <div>
                    <input class='timeInput' (change)="refreshState()" type="number" maxlength="4" max="1440" min="0" step="30" placeholder="Não" p="[0-9]{4}" [(ngModel)]="selectedProfile.sessionMaxIdleTime">
                    <span class="timeTitle">Min</span>
                </div>
            </div>
        </div>

        <p class="error 404 hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>Esse perfil não existe!</p>
        <p class="error conflict hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>Já existe um perfil com esse nome!</p>
        <p class="error conflictSource hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>O perfil <b>{{conflictProfile}}</b> já existe com essas características.</p>
        <p class="error name hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>O perfil precisa de um Nome.</p>
        <p class="success created hidden"><i (click)='admService.hideSuccess(modal)' class="la la-close"></i>Perfil criado!</p>
        <p class="success edited hidden"><i (click)='admService.hideSuccess(modal)' class="la la-close"></i>Perfil alterado!</p>
        <p class="success deleted hidden"><i (click)='admService.hideSuccess(modal)' class="la la-close"></i>Perfil deletado!</p>
    </div>
    <button *ngIf="newProfile" (click)="addProfile()" class="add">Adicionar</button>
    <button *ngIf="newProfile == false && hasAnyChange == false" class="edit disabled">Salvar</button>
    <button *ngIf="newProfile == false && hasAnyChange" (click)="saveProfile()" class="edit">Salvar</button>
</div>
<div class="deleteModal hidden">
    <p>Deseja deletar o perfil {{selectedProfile.name}} ?</p>
    <div class="deleteButtons">
        <button (click)="confirmDeleteProfile()" class="delete">Deletar</button>
        <button (click)="admService.closeDeleteModal(modal)" class="cancel">Cancelar</button>
    </div>
</div>

<div class="userListModal hidden">
    <div class="userListContent">
        <i (click)="closeUserList()" id="close" class="la la-close"></i>
        <div *ngIf='this.asyncUserList === true; else loader' >
            <span class="userListTitle">Usuários com Perfil de {{ selectedProfile.name }}</span>
            <hr style='margin-top: 8px'>
            <div>
                <cmx-listing
                    [list]="userList"
                    (onSelect)="selectUser($event)"
                    height="calc(100vh - 180px)"
                ></cmx-listing>
            </div>
        </div>
        <ng-template #loader>
            <div><loader></loader></div>
        </ng-template>
    </div>
</div>
