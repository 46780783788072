import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'van-svg',
    templateUrl: './van-svg.html',
    styleUrls: ['./van-svg.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VanSVGComponent {

}
