<div *ngIf="!asyncDetail" class="fullLoader">
    <loader></loader>
</div>

<div id="import-details-sku-container" *ngIf="asyncDetail">
    <div id="import-details-sku-content-error" *ngIf="!skuRows.length">
        <span class="noContent">SKUs não encontrados</span>
    </div>
    <div id="import-details-sku-content" *ngIf="skuRows.length">
        <div id="import-details-sku-content" *ngIf="skuRows.length">
            <cmx-custom-grid
                [id]="'import-details-sku-grid'"
                [columns]="gridSKUColumns"
                [rows]="skuRows"
                [styles]="{ 'max-height': 'calc(100vh - 330px)'}"
            ></cmx-custom-grid>
        </div>
    </div>
</div>
