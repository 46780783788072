import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Observable } from 'rxjs';

import { environment } from '#environment';
import { UserPermissionBlockerService } from '#services/_user/userPermissionBlocker.service';
import { UtilsService } from '#services/_utils/utils.service';
import { ChartsServices } from './../charts.services';

declare let AmCharts: any;

@Component({
    selector: 'exchange-chart',
    templateUrl: './exchange.component.html',
    styleUrls: [ '../charts.scss' ],
    encapsulation: ViewEncapsulation.None,
})
export class ExchangeComponent {

    public asyncExchange: boolean = false;

    public startDate = moment().add(-2, 'years').format('YYYY-MM-DD');
    public endDate = moment().format('YYYY-MM-DD');
    public chartData;
    helpDescription = this._defineHelpDescription();

    @HostListener('click') onSetZIndex() {
        this.renderer2.setStyle(this._elementRef.nativeElement, 'z-index', this.utilsService.autoIncrementValue);
    }

    constructor(
        public chartService: ChartsServices,
        public router: Router,
        private http: HttpClient,
        private el: ElementRef,
        private permissionBlocker: UserPermissionBlockerService,
        private utilsService: UtilsService,
        private _elementRef: ElementRef,
        private renderer2: Renderer2
    ) { }

    public ngAfterViewInit() {
        this.loadExchange();
    }

    public loadExchange() {
        this.asyncExchange = false;
        if (this.permissionBlocker.permissionValidator(this.el.nativeElement.tagName)) {
            this.getExchange().subscribe((data) => {
                this.asyncExchange = true;
                this.chartData = data;
                const chartData = generateChartData(data);
                const chart = AmCharts.makeChart('exchange', {
                    type: 'serial',
                    fontSize: 12,
                    language: 'pt',
                    fontFamily: 'Poppins; sans serif',
                    dataDateFormat: 'YYYY-MM-DD',
                    decimalSeparator: ',',
                    thousandsSeparator: '.',
                    dataProvider: chartData,
                    theme: 'light',
                    marginRight: 40,
                    marginLeft: 60,
                    autoMarginOffset: 20,
                    categoryField: 'date',
                    categoryAxis: {
                        parseDates: true,
                        dashLength: 1,
                        minorGridEnabled: true,
                    },
                    valueAxes: [
                        {
                            id: 'v1',
                            axisAlpha: 0,
                            position: 'left',
                            ignoreAxisWidth: true,
                        },
                    ],
                    balloon: {
                        borderThickness: 1,
                        shadowAlpha: 0,
                    },
                    graphs: [
                        {
                            id: 'g1',
                            fillAlphas: 0.2,
                            balloon: {
                                borderThickness: 2,
                            },
                            bulletBorderColor: '#02617a',
                            bulletBorderAlpha: 1,
                            bulletBorderThickness: 2,
                            bulletColor: '#FFFFFF',
                            bulletSize: 5,
                            hideBulletsCount: 50,
                            lineThickness: 2,
                            title: 'red line',
                            useLineColorForBulletBorder: true,
                            valueField: 'value',
                            balloonText:
                                "<span style='font-size:20px;'>[[value]]</span>",
                        },
                    ],
                    chartCursor: {
                        categoryBalloonDateFormat: 'DD/MM/YYYY',
                        valueLineEnabled: true,
                        valueLineBalloonEnabled: true,
                        cursorAlpha: 1,
                        cursorColor: '#02617a',
                        limitToGraph: 'g1',
                        zoomable: true,
                        valueZoomable: false,
                        valueLineAlpha: 0.2,
                        categoryBalloonColor: '#02617a',
                    },
                    export: {
                        enabled: true,
                        pageOrigin: false,
                        menu: [{
                                format: 'PDF',
                                label: "<i class='flaticon-download'></i>",
                                title: 'Gerar PDF',
                                fileName: `Câmbio • ${moment().format('DD/MM/YYYY')}`,
                                content: [
                                    `Câmbio PTAX BRL/USD • ${moment().format('DD/MM/YYYY')}`
                                    , {
                                        image: 'reference',
                                        fit: [ 523.28, 769.89 ],
                                    }],
                            },
                        ],
                    },
                });
                function generateChartData(values) {
                    const dataChart = values;
                    const chartValues = [];
                    // get date from Json
                    const firstDate = dataChart.startDate;
                    // generate values
                    for (const value of dataChart.values) {
                        const newDate = value.dateQuotation;
                        const newValue = value.quotationValue;
                        // add data item to the array
                        chartValues.push({
                            date: newDate,
                            value: newValue,
                        });
                    }
                    return chartValues;
                }
                chart.addListener('drawn', () => {
                    $('a[title*=JavaScript]').attr(
                        'style',
                        'display: none; opacity: 0;',
                    );
                });
                $('a[title*=JavaScript]').attr(
                    'style',
                    'display: none; opacity: 0;',
                );
            }, (error) => {
                this.chartData = undefined;
                this.asyncExchange = true;
                this.utilsService.errorHandler(error, 'Câmbio');
            });
        }
    }

    // Get data for Exchange Chart
    private getExchange(): Observable<any> {
        return this.http.get(
            `${
            environment.endpoints.exchangeService
            }/exchanges/daily-exchange/USD?startDate=${this.startDate}&endDate=${this.endDate}`,
        );
    }

    private _defineHelpDescription(): object[] {
        return [
            { type: 'text', value: "Informa a cotação do Dólar em relação ao Real dia a dia dos últimos 2 anos."},
        ]
    }
}
