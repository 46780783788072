import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { forkJoin } from 'rxjs';

@Injectable()
export class AppConfigService {
    private _config: any;
    private _configFiles = ['feature-flags.config.json'];
    private _configPath = './assets/app/config/';

    constructor(private _injector: Injector) {}

    set appConfig(config: any) {
        this._config = config;
    }

    get appConfig(): any {
        return this._config;
    }

    private get _http(): HttpClient {
        return this._injector.get(HttpClient);
    }

    async loadConfig(): Promise<any> {
        try {
            const [featureFlags] = await forkJoin(
                this._configFiles.map((file: string) => this._http.get(`${this._configPath}${file}`))
            ).toPromise();

            this.appConfig = { featureFlags };
        } catch (err) {
            console.error(err);
        }
    }

    getConfigObjectKey(key: string): any {
        return this.appConfig && this.appConfig[key];
    }
}
