import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '#environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class LocationsService {
  private msgErrorDefault = 'Não foi possível carregar os dados';
  constructor(private http: HttpClient) { }


  getLocations(dataFilter): Observable<any> {
    
    return this.http.get(`${environment.endpoints.quotationService}/locations?page=${dataFilter.page}&size=${dataFilter.limit}&sort=countryCode,asc`).pipe(catchError((err) => {
      return observableThrowError({
        status: err.status,
        message: 'Erro ao carregar a lista locais',
      });
    }));
  }

  getByFilter(dataFilter): Observable<any> {

    return this.http.get(`${environment.endpoints.quotationService}/locations/find-by-filter?${dataFilter.filterValue}&page=${dataFilter.page}&size=${dataFilter.limit}&sort=country,asc`).pipe(catchError((err) => {
      return observableThrowError({
        status: err.status,
        message: 'Erro ao carregar a lista locais',
      });
    }));    
  }

  getCountries(): Observable<any> {
    return this.http.get('/assets/app/js/countries.json');
  }


  create(data): Observable<any> {
    return this.http.post(`${environment.endpoints.quotationService}/locations`, data).pipe(catchError((err) => {
      return observableThrowError({
        status: err.status,
        message: err.error.message,
      });
    }));
  }

  update(data): Observable<any> {
    return this.http
      .put(`${environment.endpoints.quotationService}/locations/${data.id}`, data).pipe(
      catchError((err) => {
        return observableThrowError({
          status: err.status,
          message: err.error.message,
        });
      }));
  }

  delete(id) {
    return this.http
      .delete(`${environment.endpoints.quotationService}/locations/${id}`)
      .pipe(catchError((err) => {
        return observableThrowError({
          status: err.status,
          message: err.error.message,
        });
      }));
  }

}
