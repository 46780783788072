import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environment';

export class Engine {
    constructor(
        public readonly id: number,
        public name: string,
        public readonly yearData: any = {}) {
    }
}

export class Value {
    constructor(
        public readonly profitCenterCode: string,
        public readonly referenceMonth: number,
        public value: number = 0,
        public percentage: number = 0) {

    }
}

@Injectable()
export class ApportionmentService {

    readonly endpoint;

    constructor(private http: HttpClient) {
        this.endpoint = environment.endpoints.apportionmentService;
    }

    list(): Observable<Array<Engine>> {
        return this.http.get<Array<any>>(`${this.endpoint}/engine`).pipe(
            map(data => {
                return data.map(e => new Engine(e.id, e.name))
            }))
    }

    get(code: number, year: string): Observable<Engine> {
        return this.http.get(`${this.endpoint}/engine/${code}/year/${year}`, { responseType: 'text' }).pipe(
            map(raw => {

                if (raw == undefined || raw.length == 0) {
                    return new Engine(code, "", {});
                }

                let data = JSON.parse(raw);
                let map: any = {};
                data.profitCenterList
                    .map(it => new Value(it.id, it.month, it.value))
                    .forEach(element => {
                        let code = element.profitCenterCode;
                        let referenceMonth = element.referenceMonth;

                        if (map[code] == undefined) {
                            map[code] = {};
                        }

                        map[code][referenceMonth] = element;
                    });

                return new Engine(data.id, data.name, map);
            }));
    }

    save(engine: Engine) {
        return engine.id ? this.update(engine) : this.create(engine);
    }

    delete(engine: Engine) {
        return this.http.delete(`${this.endpoint}/engine/${engine.id}`, { responseType: "text" }).pipe(
            map(() => engine));
    }

    private create(engine: Engine) {
        return this.http.post<any>(`${this.endpoint}/engine`, {
            id: engine.id,
            name: engine.name,
            profitCenterList: []
        }).pipe(
            map(data => new Engine(data.id, data.name)));
    }

    private update(engine: Engine) {
        return this.http.put<any>(`${this.endpoint}/engine/${engine.id}`, {
            id: engine.id,
            name: engine.name,
            profitCenterList: this.toList(engine.yearData)
        }).pipe(
            map(() => engine));
    }

    private toList(map: any) {

        let list = [];

        for (let profitCenter of Object.keys(map)) {
            for (let month of Object.keys(map[profitCenter])) {
                let value = map[profitCenter][month];
                list.push({
                    id: value.profitCenterCode,
                    month: value.referenceMonth,
                    value: value.value
                });
            }
        }

        return list;
    }
}
