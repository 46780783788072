
import {map} from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as FileSaver from "file-saver";
import { Observable } from 'rxjs';
import { WebsocketService } from '#services/_websocket/websocket.service';
import { Notification } from 'app/models/_notification/notification.data';
import { NotificationService } from '#services/_notification/notification.service';
import { UtilsService } from '#services/_utils/utils.service';

export interface IExportParam { param: string; value: any }

@Injectable()
export class DownloadService {

    async: boolean = false;
    private readonly filenameRegex = /filename="([^"].+)"/;
    private readonly defaultContentType = "application/octet-stream";

    constructor(
        private _http: HttpClient,
        private _websocket: WebsocketService,
        private _notification: NotificationService,
        private _utilsService: UtilsService,
    ) {
        this._initWeSocketConnection();
    }

    asyncExport(url: string, clientGroups, exportParams: IExportParam[], page: string) {
        this.async = true;
        let params = new HttpParams();
        let headers = new HttpHeaders();
        headers = headers.set('X-Requested-Client-Groups', clientGroups.map((c) => c).join(','));

        exportParams.forEach((param) => {
            params = params.append(param.param, param.value);
        });

        this._http.get(url, { params, headers }).subscribe(() => {
            this.onAsyncReport();
            this.async = false;
        }, (error) => {
            this.async = false;
            this._utilsService.errorHandler(error, page);
        });
    }

    syncExport(url: string, exportParams: IExportParam[], page: string, fileName?: string, contentType?: string) {
        this.async = true;
        let params = new HttpParams();
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', contentType || 'application/json');
        exportParams.forEach((param) => {
            params = params.append('field', `${param.param}::${param.value}`);
        })
        this.downloadFrom(this._http.get(url, { responseType: 'blob', params, headers }), fileName).subscribe((file) => {
            this.async = false;
        }, (error) => {
            this.async = false;
            this._utilsService.errorHandler(error, page);
        });
    }

    download(url: string, fallbackFilename: string) {
        return this._http
            .get(url, this.getSourceOptions()).pipe(
            map(this._saveFile(fallbackFilename)));
    }

    downloadFrom(source: Observable<any>, fallbackFilename: string) {
        return source.pipe(map(this._saveFile(fallbackFilename)));
    }

    getSourceOptions(): any {
        return {
            observe: "response",
            responseType: "blob"
        };
    }

    private _saveFile(fallbackFilename) {
        return response => {
            const contentType = this.contentTypeOf(response);
            const filename = this.filenameOf(response);
            const file = new File([response.body || response], filename || fallbackFilename, {
                type: contentType
            });
            FileSaver.saveAs(file);
        };
    }

    private contentTypeOf(response): string {
        return response?.headers?.get("content-type") ||
        response?.type || this.defaultContentType;
    }

    private filenameOf(response): string {
        const contentDisposition = response?.headers?.get("Content-Disposition");
        if (contentDisposition === null || contentDisposition?.trim() === "") {
            return null;
        }
        const filenameMatches = this.filenameRegex?.exec(contentDisposition);
        if (filenameMatches === null || filenameMatches?.length == 0) {
            return null;
        }
        return filenameMatches[1];
    }

    private _initWeSocketConnection(): void {
        this._websocket.getEvents().subscribe( (message: Notification) => {
            switch (message?.type) {
                case 'creating-report':
                    this.onCreatingReport();
                    break;
                case 'report-finished':
                    this.onReportFinished(message);
                    break;
                case 'report-error':
                    this.onDownloadError();
                    break;
                default:
                    break;
            }
        });
    }

    onAsyncReport(): void {
        this._notification.openNotification('downloadInfo',
                'Geração do relatório começará em breve.', 'Geração do Relatório Solicitado');
    }


    private onCreatingReport(message?): void {
        this._notification.openNotification('downloadInfo',
                message?.message || 'Geração do relatório em andamento.',
                message?.title || 'Criando Relatório');
    }

    private onReportFinished(message?): void {
        this._notification.openNotification('downloadSuccess',
                message?.message || 'Você possui downloads solicitados que estão prontos, clique aqui para conferir.',
                message?.title || 'Relatório Criado');
    }

    private onDownloadError(message?): void {
        this._notification.openNotification('downloadError',
                message?.message || 'O relatório solicitado para download não pôde ser gerado, por favor, tente novamente.');
    }
}
