import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import moment from 'moment';
import Inputmask from 'inputmask';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { ILiLeadTimeFilter } from './interface/li-lead-time.interface';


export class LiLeadTimeFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialLiLeadTimeFilter: ILiLeadTimeFilter = <ILiLeadTimeFilter>{};
    private _liLeadTimeFilter: ILiLeadTimeFilter = <ILiLeadTimeFilter>{
        clientGroups: [],
    };

    private startDate;
    private endDate;

    constructor(filter?: LiLeadTimeFilter) {

        this._liLeadTimeFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'liLeadTimeFilter', this._liLeadTimeFilter, filter);
        this._initialLiLeadTimeFilter = _.cloneDeep(this._liLeadTimeFilter);

        this.startDate = filter ? filter.startDate : moment().add(-6, 'M').format('MM/YYYY');
        this.endDate = filter ? filter.endDate : moment().format('MM/YYYY');
    }

    getClientGroups(): string[] {
        return this._liLeadTimeFilter.clientGroups;
    }
    getStartDate() {
        return this.startDate;
    }
    getEndDate() {
        return this.endDate;
    }

    setClientGroups(clientGroups): void {
        this._liLeadTimeFilter.clientGroups = clientGroups;
    }
    setStartDate(startDate): void {
        this.startDate = startDate;
    }
    setEndDate(endDate): void {
        this.endDate = endDate;
    }

    getRequest(kpi) {

        this._initialLiLeadTimeFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialLiLeadTimeFilter, this._liLeadTimeFilter, 'liLeadTimeFilter', this._userCacheService.getUserPreferences().id);

        let headers = new HttpHeaders();
        let params = new HttpParams();
        headers = headers.set('X-Requested-Client-Groups', this.getClientGroups().map((c) => c['id']).join(','));
        params = params.append('kpis', kpi);
        params = params.append('startYearMonth', moment(this.startDate, 'MM/YYYY').format('YYYY-MM'));
        params = params.append('endYearMonth', moment(this.endDate, 'MM/YYYY').format('YYYY-MM'));
        return { headers, params };

    }
}
