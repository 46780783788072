import { Component, Input, Output, EventEmitter, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import * as _ from 'lodash';
import { UtilsService } from '#services/_utils/utils.service';

type ITableType = 'simple' | 'grouped';
@Component({
    selector: 'cmx-drag-and-drop-table',
    templateUrl: './cmx-drag-and-drop-table.component.html',
    styleUrls: ['./cmx-drag-and-drop-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class DragAndDropTableComponent {
    @Input() showMode: boolean = false;
    @Input() showData: any[] = [];

    @Input() tableType: ITableType = 'simple';
    @Input() title: string;
    @Input() search: string = null;
    @Input() cleanSearch: boolean = false;
    @Input() columnsWarning: number = 100;

    @Input() leftTitle: string;
    @Input() leftData: any[] = [];
    @Input() leftIcon?: string;
    @Input() leftColor: string = 'black';
    @Input() leftInTableIcon: string = null;
    @Input() leftInTableIconType: 'material-icon' | 'svg' = 'material-icon';
    @Input() defaultLeftData: any[] = [];

    @Input() rightTitle: string;
    @Input() rightData: any[] = [];
    @Input() rightIcon?: string;
    @Input() rightColor: string = 'black';
    @Input() rightInTableIcon: string = null;
    @Input() rightInTableIconType: 'material-icon' | 'svg' = 'material-icon';

    @Input() enableSaveButton: boolean = true;
    @Input() enableMultiSelect: boolean = true;
    @Input() enableGroupRename: boolean = true;
    @Input() enableGroupReorder: boolean = true;
    @Input() enableChangeColumnGroup: boolean = true;
    @Input() enableResetNames: boolean = true;
    @Input() enableRearrangeGroups: boolean = true;
    @Input() enableResetAll: boolean = true;
    @Input() enableColumnCreate: boolean = true;
    @Input() enableRightDrag: boolean = true;

    @Input() leftContainerHeightBase: string;
    @Input() rightContainerHeightBase: string;

    searchValue = '';
    height: string;

    cacheLeftData = [];
    cacheRightData = [];
    subItemListConnection = [];
    groupDragged: boolean = false;

    renamingGroup: boolean = false;
    newGroupName = '';
    lastGroupId;
    lastGroupName;

    @Output('onChangeValues') onChangeValues = new EventEmitter();
    @Output('onFinishButton') onFinishButton = new EventEmitter();

    constructor(private _utilsService: UtilsService) { }

    ngOnInit() {
        if (this.showMode) {
            this._reestructureShowData();
        } else {
            this._reestructureData();
        }
        this._triggers();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && (changes.leftData && !changes.leftData.firstChange)
            && (changes.rightData && !changes.rightData.firstChange)) {
                if (this.showMode) {
                    this._reestructureShowData();
                } else {
                    this._reestructureData();
                }
        }
    }

    ngAfterViewInit() {
        if (!this.leftContainerHeightBase && !this.rightContainerHeightBase) {
            setTimeout(() => {
                this.leftContainerHeightBase = `calc(100vh - ${this._utilsService.getTopDistanceByElement('cmx-drag-and-drop-table-left-column-container', 75)}px)`;
                this.rightContainerHeightBase = `calc(100vh - ${this._utilsService.getTopDistanceByElement('cmx-drag-and-drop-table-right-column-container', 80)}px)`;
            }, 100);
        }
    }

    getMainData() {
        return this.showMode ? this.showData : this.leftData;
    }

    getColumnLength(side) {
        return this._getSideList(side).length;
    }

    drop(event: CdkDragDrop<string[]>) {
        const previousGroupId = event?.previousContainer?.element?.nativeElement?.parentElement?.children[1]?.children[0]?.id;
        const dropGroupId = event?.container?.element?.nativeElement?.parentElement?.children[1]?.children[0]?.id;
        const previousGroupIndex = this.leftData.findIndex((group) => { return group.group === previousGroupId });
        const dropGroupIndex = this.leftData.findIndex((group) => { return group.group === dropGroupId });

        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            if (event.previousContainer?.id === 'left-list-container' && this.showMode) {
                const group = this.showData.splice(event.previousIndex, 1)[0];
                this.showData.splice(event.currentIndex, 0, group);
            }
        } else {
            if (this.tableType === 'grouped') {
                if (event.previousContainer.id === 'left-list-container' && event.container.id === 'right-list-container') {
                    const groupData = this.leftData[event.previousIndex].data;
                    this.rightData.push(...groupData);
                    this.leftData[event.previousIndex].data = [];
                    return;
                } else if (event.previousContainer.id === 'right-list-container' && event.container.id === 'left-list-container') {
                    return;
                } else if ((event.previousContainer.id !== 'right-list-container' && event.container.id !== 'right-list-container') &&
                    event.previousContainer.id !== event.container.id && !this.enableChangeColumnGroup) {
                    return;
                }
            }
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }
        if (this.showMode) {
            // Adiciona ao LeftData, o grupo, apenas com colunas show = true
            this.leftData[dropGroupIndex].data = event.container.data.filter((column: any) => column?.show);
            this.leftData[previousGroupIndex].data = event.previousContainer.data.filter((column: any) => column?.show);
        }
        this._emitChanges();
    }

    finishButton() {
        this.onFinishButton.emit({
            leftData: this.leftData?.map((data) => {
                return data;
            }),
            rightData: this.rightData?.map((data) => {
                return data;
            }),
        });
    }

    selectContainerGroupBox(item) {
        if (this.tableType !== 'grouped') {
            item.selected = !item.selected;
        }
    }

    selectBox(item, side?, group?) {
        if (this.showMode) {
            this._hideOrShow(item, group);
        } else {
            if (!(this.tableType === 'grouped' && side === 'rightData')) {
                item.selected = !item.selected;
            }
        }
    }

    hasHiddenColumns(group): boolean {
        return group?.data?.filter((column) => !column.show).length > 0;
    }

    hasShowColumns(group): boolean {
        return group?.data?.filter((column) => column.show).length > 0;
    }

    showAll(show: boolean, groupName) {
        this.showData.forEach((showGroup) => {
            if (showGroup.name === groupName) {
                showGroup.data.forEach((column) => {
                    if (show && !column.show) { // mostrar todos
                        this._hideOrShow(column, groupName)
                    } else if (!show && column.show) { // esconder todos
                        this._hideOrShow(column, groupName)
                    }
                })
            }
        })
    }

    filterBySearch(item, grouped?): boolean {
        const search = this.cleanSearch ? this._utilsService.normalizeString(this.searchValue) : this.searchValue;
        const name = this.cleanSearch ? this._utilsService.normalizeString(item.name) : item.name;
        if (!grouped && this.tableType === 'grouped') {
            return true;
        }
        if (search === '') {
            return true;
        } else if (!name) {
            return false;
        }
        return name.toLowerCase().includes(search.toLowerCase());
    }

    verifyEnabledArrow(side) {
        let list = this._getSideList(side, false, this.tableType === 'grouped') || [];
        return list.filter((e) => e?.selected).length > 0;
    }

    selectAll(side) {
        const list = this._getSideList(side);
        const allSelected = this.verifyAllSelected(side);
        return list?.map((e) => (e.selected = allSelected ? false : true));
    }

    sendBackToGroup(item) {
        this.defaultLeftData.forEach((defaultGroup) => {
            defaultGroup?.columns?.forEach((defaultData) => {
                if (defaultData?.field === item?.field) {
                    const cachedGroupFound = defaultGroup;
                    this.leftData.forEach((group) => {
                        if (cachedGroupFound.name === group.name) {
                            group.data.push(item);
                            this.rightData = _.reject(this.rightData, (column) => { return column.field === item.field; });
                        }
                    })
                }
            })
        })
    }

    removeAllFromBin() {
        const columns = _.cloneDeep(this.rightData);
        columns.forEach((data) => {
            this.sendBackToGroup(data);
        })
    }

    sendAllSelected(side) {
        let from = this._getSideList(side, false, this.tableType === 'grouped');
        const to = this._getSideList(side, 'inverted');
        from.forEach((value) => {
            if (value.selected) {
                to.unshift(this.tableType === 'grouped' ? { ...value, selected: false } : value);
            }this.search
        });
        for (let i = 0; i < from.length; i++) {
            if (from[i].selected) {
                if (this.tableType === 'grouped') {
                    this.leftData.forEach((group) => {
                        group.data.forEach((column, index) => {
                            if (column.selected) {
                                group.data.splice(index, 1)
                            }
                        })
                    })
                } else {
                    from.splice(i, 1);
                    i--;
                }
            }

        }
        this._emitChanges();
    }

    verifyAllSelected(side) {
        const list = this._getSideList(side);
        return list.every((e) => e?.selected === true);
    }

    resetGroupNames() {
        this.leftData.forEach((group) => {
            this.cacheLeftData.forEach((cacheGroup) => {
                if (group.id === cacheGroup.id) {
                    document.getElementById(group.name).children[0].innerHTML = cacheGroup.name
                    group.name = _.cloneDeep(cacheGroup.name);
                }
                if (this.showMode) {
                    this.showData.forEach((showGroup) => {
                        if (showGroup.id === cacheGroup.id) {
                            showGroup.name = _.cloneDeep(cacheGroup.name);
                        }
                    })
                }
            })
        })
    }

    resetGroupPositions() {
        this.leftData.sort((a,b) => a.id - b.id);
        if (this.showMode) this.showData.sort((a,b) => a.id - b.id);
        this._defineConnections();
    }

    resetColumnPositions() {
        this.defaultLeftData.forEach((defaultGroup) => {
            this.leftData.forEach((group) => {
                if (defaultGroup?.name === group?.name) {
                    group.columns = _.cloneDeep(defaultGroup.columns);
                    group.data = _.cloneDeep(defaultGroup.columns);
                }
            })
        })
        this.rightData = [];
        this._reestructureShowData();
    }

    createGroup() {
        const newGroup = {
            id: this.leftData.length,
            name: `Grupo ${this.leftData.length + 1}`,
            label: `Grupo ${this.leftData.length + 1}`,
            group: `Grupo ${this.leftData.length + 1}`,
            selected: false, data: [], isCustom: true,
        };
        if (this.showMode) {
            this.showData.push(newGroup);
        }
        this.leftData.push(newGroup)
        this._reestructureShowData();
        this._defineConnections();
        const container = document.getElementById('left-list-container');
        $(container).animate({scrollLeft: container.scrollWidth}, 800);
    }

    deleteGroup(group) {
        group.data.forEach((column) => {
            this.rightData.push(column);
            if (this.showMode) {
                this.defaultLeftData.forEach((defaultGroup) => {
                    defaultGroup.columns.forEach((defaultColumn) => {
                        if (defaultColumn.field === column.field) {
                            const groupName = defaultGroup.name;
                            this.showData.forEach((showGroup) => {
                                if (showGroup.name === groupName) {
                                    showGroup.data.push({ ...defaultColumn, show: false });
                                }
                            })
                        }
                    });
                })
            }
        })
        this.leftData.splice(this.leftData.findIndex((i) => { return i.id === group.id }), 1);
        if (this.showMode) {
            this.showData.splice(this.showData.findIndex((i) => { return i.id === group.id }), 1);
        }
    }

    private _hideOrShow(item, groupName) {
        if (item.show) {
            item.show = false;
            this.rightData[0]?.data?.push(item);
            this.leftData.forEach((group) => {
                group.data = _.reject(group?.data, (column) => { return column?.field === item?.field; });
            });
        } else {
            item.show = true;
            this.rightData[0].data = _.reject(this.rightData[0].data, (column) => { return column?.field === item?.field; });
            this.leftData.forEach((group) => {
                if (groupName === group.group) {
                    if (!group.data.some((column) => column.field === item.field)) {
                        group.data.push(item);
                    }
                }
            });
        }
        this._emitChanges();
    }

    private _getSideList(side, inverted?, grouped?) {
        if (inverted) return side === 'left' ? this.rightData : this.leftData;

        if (this.tableType === 'grouped') {
            const list = side === 'left' ? this.leftData : this.rightData
            let childrenList = []
            list.forEach((item) => {
                childrenList = childrenList.concat(item.data)
            });
            return childrenList;
        } else {
            return side === 'left' ? this.leftData : this.rightData;
        }
    }

    private _reestructureShowData() {
        this.showData = this.leftData?.map((data, index) => {
            const columns = data?.data?.map((column) => { column.show = true; return column; });
            return { id: index, name: data.group, data: columns, isCustom: data.isCustom }
        });
        const rightColumns = _.cloneDeep(this.rightData);
        rightColumns[0]?.data?.forEach((data) => {
            this.defaultLeftData.forEach((defaultGroup) => {
                defaultGroup?.columns?.forEach((defaultData) => {
                    if (defaultData?.field === data?.field) {
                        const cachedGroupFound = defaultGroup;
                        this.showData.forEach((group) => {
                            if (cachedGroupFound.name === group.name) {
                                group.data.push({ ...data, show: false });
                            }
                        })
                    }
                })
            })
        })
        this.cacheLeftData = _.cloneDeep(this.leftData);
        this.cacheRightData = _.cloneDeep(this.rightData);
        if (this.tableType === 'grouped') {
            this._defineConnections();
        }
    }

    private _reestructureData() {
        this.leftData = this.leftData?.map((data, index) => {
            return this.tableType === 'grouped' ?
                { id: index, name: data.group, selected: false, data: data.data } :
                { name: data, selected: false };
        });

        if (this.tableType === 'grouped') {
            const children = []
            this.rightData = this.rightData?.map((data) => {
                data.data.forEach((child) => { children.push(child) });
            });
            this.rightData = children;
        } else {
            this.rightData = this.rightData?.map((data) => {
                return { name: data, selected: false };
            });
        }
        this.cacheLeftData = _.cloneDeep(this.leftData);
        this.cacheRightData = _.cloneDeep(this.rightData);
        if (this.tableType === 'grouped') {
            this._defineConnections();
        }
    }

    private _defineConnections() {
        setTimeout(() => {
            this.subItemListConnection = [];
            const containers = Array.from(document.getElementsByClassName('group-itens-container'));
            containers.forEach((group) => {
                this.subItemListConnection.push(group.id);
            });
        }, 300);
    }

    private _renameTriggers() {
        const input = document.getElementById("renameGroupInput")
        $(document).click((event) => {
            if ($(event.target).is('#renameGroupInput')) {
                event.preventDefault();
            } else if (this.renamingGroup) {
                this.renamingGroup = false;
                this._verifyRenameGroup(input);
            }
        });
        input.addEventListener("keypress", (event) => {
            if (event.key === "Enter") {
                this._verifyRenameGroup(input);
            }
        });
        setTimeout(() => {
            this.renamingGroup = true;
        }, 200);
    }

    private _verifyRenameGroup(input) {
        this.newGroupName = (document.getElementById('renameGroupInput') as HTMLInputElement)?.value || '';
        if (this.newGroupName === '' || this._verifyRenameDuplicity()) {
            this.newGroupName = this.lastGroupName;
        }
        this.leftData.forEach((data) => {
            if (data.name === this.lastGroupId) {
                data.name = this.newGroupName;
            }
        })
        const newSpanValue = $(`<div class="rename-group-container" id="${this.lastGroupId}" style="width: -webkit-fill-available">
        <span style="pointer-events: none">${this.newGroupName}</span></div>`)
        input.replaceWith(newSpanValue[0]);
        this.renamingGroup = false;
    }

    private _verifyRenameDuplicity() {
        this.leftData.some((group) => {
            if (group.name === this.newGroupName) {
                return true;
            }
        })
        return false;
    }

    private _triggers() {
        // Clique em Tela
        $(document).click((event) => {
            if (this.tableType === 'grouped' && this.enableGroupRename) {
                if ($(event.target).hasClass('rename-group-container')) {
                    if (this.renamingGroup) {
                        const input = document.getElementById("renameGroupInput")
                        this._verifyRenameGroup(input)
                    }
                    this._renameGroup(
                        $(event.target).attr('id'),
                        $(event.target.lastElementChild.outerHTML)[0].innerText, event);
                }
                if (!$(event.target).hasClass('rename-group-container') && this.renamingGroup) {
                    const input = document.getElementById("renameGroupInput")
                    this._verifyRenameGroup(input)
                }
            }
        });
    }

    private _renameGroup(id, name, element) {
        if (this.tableType === 'grouped' && this.enableGroupRename) {
            this.lastGroupId = id;
            this.lastGroupName = name;
            const input = $('<input id="renameGroupInput" placeholder="Renomear">', {
                val: $(this).text(), type: 'text', style: `width: ${$(element.target).width()}`,
            });
            $(element.target).replaceWith(input);
            $('#renameGroupInput').focus();
            this._renameTriggers();
        }
    }

    private _emitChanges() {
        this.onChangeValues.emit({
            leftData: this.leftData?.map((data) => {
                return data;
            }),
            rightData: this.rightData?.map((data) => {
                return data;
            }),
        });
    }
}
