import { COLOR_LIST } from "../constants/round-list-colors.constant";
import { _applyPosFormatters } from "./pos-formatters/pos-formatters";

export function multiRoundFormatter(row, cell, value, columnDef, dataContext) {
    const roundType = (columnDef.formatterData && columnDef.formatterData.roundType) || 'ball';
    const roundLimit = (columnDef.formatterData && columnDef.formatterData.roundType) || 5;
    if (Array.isArray(value)) {
        if (value.length === 0) {
            return '';
        } else {
            let rounds = '';
            value.forEach((round, index) => {
                if (roundLimit > index) {
                    rounds += `<div class="round ${roundType}" title="${
                        round.name || ''
                    }" style="background-color: ${COLOR_LIST[index % 10] || 'grey'}; z-index: ${
                        value.length - index
                    }">${round.name ? round.name.charAt(0) : '?'}</div>`;
                } else if (roundLimit == index) {
                    rounds += `<div class="round ${roundType}" title="+${value.length - index}"
                        style="color: #5c5c5c; margin-left: 0; z-index: ${value.length - index}">+${
                        value.length - index
                    }</div>`;
                }
            });
            const contentValue = `<div class="multi-round-container">${rounds}</div>`;
            return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
        }
    }
}