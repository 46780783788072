<div class="cmx-filter-input" [ngClass]="[hasIcon ? 'with-icon' : '', hasIcon && hasIcon.position ? 'pos-'+hasIcon.position : 'pos-left']" [ngStyle]="{'width': width ? width : 'auto'}">

    <ng-container *ngIf="hasIcon?.type === 'material-icons'">
        <span class="icon-input material-icons">{{ hasIcon.value }}</span>
    </ng-container>
    
    <ng-container *ngIf="hasIcon?.type === 'fontawesome'">
        <i class="icon-input fa" [ngClass]="hasIcon.value"></i>
    </ng-container>

    <input type="text" 
        [name]="id" 
        [id]="id"
        [maxlength]="maxLength"
        [placeholder]="placeholder"
        [(ngModel)]="value"
        (input)="onInput($event)"
    >
</div>