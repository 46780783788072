import { Injectable } from '@angular/core';

@Injectable()
export class FollowUpReportWarehousesFormatter {

    // Armazens
    formatWarehouses(row, cell, value, columnDef, dataContext) {
        
        if (!value) {
            return '';
        }

        const warehouses = [];
        value.forEach((warehouse) => {
            if (!warehouses.includes(warehouse.description)) {
                warehouses.push(warehouse.description);
            }
        });
        if (warehouses.length === 1) {
            if (warehouses[0] === null || warehouses[0] === undefined || warehouses[0] === '') {
                return '';
            }
            return warehouses[0];
        } else if (warehouses.length > 1) {
            return `<span class="multipleWarehouses">Várias (${warehouses.length})</span>`;
        } else {
            return '';
        }
    }
}
