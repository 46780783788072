import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DIValidationService } from 'app/modules/crud/pages/supply-chain/di-validation/di-validation.service';

declare let Slick: any;

@Component({
    selector: 'external-di-validation',
    templateUrl: './di-validation.component.html',
    styleUrls: ['./di-validation.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ExternalDiValidationComponent {
    asyncValidation = false;
    imp;
    comment = '';
    comments: {
        createdDate: string;
        commentOrigin: string;
        comment: string;
    }[];
    messageError = '';
    impDetails;
    files = {
        comexport: { file: null, loading: false },
        client: { file: null, loading: false },
    };
    private columns;
    private rows;
    private dataView;
    private validatedGrid;

    constructor(
        private _dIValidationService: DIValidationService,
        private _urlValue: ActivatedRoute,
        private _router: Router
    ) {
        const urlParams = this._urlValue.snapshot.params;
        this.imp = urlParams.imp;
    }

    ngAfterViewInit() {
        this.selectImpDetails();
    }

    selectImpDetails() {
        this._dIValidationService.getIMPs('', this.imp, 0, '').subscribe((impDetails) => {
            if (impDetails.hasOwnProperty('content')) {
                if (impDetails.content.length === 1) {
                    this.impDetails = impDetails.content[0];
                    if (this.impDetails.status === 'PENDING_APPROVAL_REQUEST') {
                        this.loadValidatedTable();
                    }
                }
            }
        });
    }

    downloadFile(type) {
        this.hideError();
        this.asyncValidation = true;
        this._dIValidationService.downloadFile(type, this.imp).subscribe(
            (data) => {
                this.asyncValidation = false;
            },
            (error) => {
                this.asyncValidation = false;
                this.messageError = 'Algo deu Errado';
                $('.messageError').removeClass('hidden');
            }
        );
    }

    loadValidatedTable() {
        this.asyncValidation = true;
        this._dIValidationService.getValidated(this.imp).subscribe(
            (tableData) => {
                this.comments = tableData.comments;
                this.columns = this.createColumns();
                this.rows = this.extractRows(tableData.tags);
                const options = {
                    defaultColumnWidth: 120,
                    editable: false,
                    enableCellNavigation: true,
                    enableTextSelectionOnCells: true,
                    rowHeight: 30,
                    forceFitColumns: true,
                };
                this.dataView = new Slick.Data.DataView({ inlineFilters: false });
                this.dataView.beginUpdate();
                this.dataView.setItems(this.rows);
                this.dataView.endUpdate();
                this.validatedGrid = new Slick.Grid('#validatedGrid', this.dataView, this.columns, options);
                this.asyncValidation = false;
            },
            (error) => {
                this.asyncValidation = false;
            }
        );
    }

    approvalChoice(type) {
        this.hideError();
        if (type === 'REFUSED' && this.comment === '') {
            this.messageError = 'Por Favor, inclua um comentário';
            $('.messageError').removeClass('hidden');
        } else {
            this.asyncValidation = true;
            this._dIValidationService.approvalChoice(this.imp, this.comment, type).subscribe(
                (response) => {
                    this.asyncValidation = false;
                    this._router.navigate(['./']);
                },
                (error) => {
                    this.asyncValidation = false;
                    this.messageError = 'Algo deu Errado';
                    $('.messageError').removeClass('hidden');
                }
            );
        }
    }

    hideError() {
        $('.messageError').addClass('hidden');
    }

    private createColumns() {
        return [
            { id: 0, name: 'Campo', width: 200, field: 'field', formatter: this.formatValue },
            {
                id: 1,
                name: '<span title="Valor Original">Valor Original</span>',
                width: 140,
                field: 'sourceValue',
                formatter: this.formatValue,
            },
            {
                id: 2,
                name: '<span title="Valor Comexport">Valor Comexport</span>',
                width: 140,
                field: 'testValue',
                formatter: this.formatValue,
            },
            { id: 3, name: 'Diferença', width: 120, field: 'diff', formatter: this.formatValue },
            { id: 4, name: 'Tag XML', width: 170, field: 'tag', formatter: this.formatValue },
        ];
    }

    private extractRows(dataList) {
        const rows = [];
        dataList.forEach((data, index) => {
            rows.push({
                id: index,
                field: data.field,
                sourceValue: data.sourceValue,
                testValue: data.testValue,
                diff: data.diff,
                tag: data.tag,
            });
        });
        return rows;
    }

    private formatValue(row, cell, value, columnDef, dataContext) {
        return `<span class="tableValue" title="${value}">${value ? value : ''}</span>`;
    }

    trackByFn(index: number, item: any) {
        return index;
    }
}
