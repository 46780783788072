<nav #cmxNavMainMenu id="nav-main-menu" [class.dark-theme]="darkTheme">
    <ul class="group-level-nav">
        <li *ngFor="let group of menu" [id]="'menu-category-' + utilsService.prepareIdName(group.name.toLowerCase())" [class.actived]="hasActivedMenu && group.name === parentHighlight" (auxclick)="open(group, null, $event, group.name)" (click)="open(group, null, $event, group.name)">
            <span [class]="'icon-'+group.icon"></span>
            <p>{{ group.name }}</p>
            <ng-container *ngTemplateOutlet="secondLevel; context: {$implicit: group, groupname: 'second-level-nav', parentname: group.name}"></ng-container>
        </li>
    </ul>
</nav>


<ng-template #secondLevel let-item let-groupname="groupname" let-parentname="parentname">
    <div class="arrow-next-nav" *ngIf="item?.children && item?.children.length > 0"></div>
    <ul #elContainer *ngIf="item?.children && item?.children.length > 0" [class]="groupname">
        <li *ngFor="let i of item.children" [id]="'menu-item-' + utilsService.prepareIdName(i.name.toLowerCase())" [class.no-pointer]="!i?.route" [class.has-childrens]="i?.children && i.children.length > 0" [class.actived]="i.route === itemHighlight?.route && i.name === itemHighlight?.name" [routerLinkActive]="'actived'" (auxclick)="open(i, elContainer, $event, parentname)" (click)="open(i, elContainer, $event, parentname)">
            <p>
                {{ i.name }}
            </p>
            <ng-container *ngTemplateOutlet="thirdLevel; context: {$implicit: i, groupname: 'third-level-nav', parentname: parentname}"></ng-container>
        </li>
    </ul>
</ng-template>

<ng-template #thirdLevel let-item let-groupname="groupname" let-parentname="parentname">
    <div class="arrow-next-nav" *ngIf="item?.children && item?.children.length > 0"></div>
    <ul #elContainer *ngIf="item?.children && item?.children.length > 0" class="nav-group-menu-levels" [class]="groupname">
        <li *ngFor="let i of item.children" [id]="'menu-sub-item-' + utilsService.prepareIdName(i.name.toLowerCase())" [class.no-pointer]="!i?.route" [class.has-childrens]="i?.children" [class.actived]="i.route === itemHighlight?.route && i.name === itemHighlight?.name" (auxclick)="open(i, elContainer, $event, parentname)" (click)="open(i, elContainer, $event, parentname)">
            <p>
                {{ i.name }}
            </p>
            <ng-container *ngTemplateOutlet="fourthLevel; context: {$implicit: i, groupname: 'third-level-nav', parentname: parentname}"></ng-container>
        </li>
    </ul>
</ng-template>

<ng-template #fourthLevel let-item let-groupname="groupname" let-parentname="parentname">
    <div class="arrow-next-nav" *ngIf="item?.children && item?.children.length > 0"></div>
    <ul #elContainer *ngIf="item?.children && item?.children.length > 0" class="nav-group-menu-levels" [class]="groupname">
        <li *ngFor="let i of item.children" [id]="'menu-sub-item-' + utilsService.prepareIdName(i.name.toLowerCase())" [class.no-pointer]="!i?.route" [class.actived]="i.route === itemHighlight?.route && i.name === itemHighlight?.name" (auxclick)="open(i, elContainer, $event, parentname)" (click)="open(i, elContainer, $event, parentname)">
            <p>
                {{ i.name }}
            </p>
        </li>
    </ul>
</ng-template>
