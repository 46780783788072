import { AppModule } from 'app/app.module';
import moment from 'moment';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IBoardingStatusFilter } from './interfaces/boarding-status.interface';


export class BoardingStatusFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialBoardingStatusFilter: IBoardingStatusFilter = <IBoardingStatusFilter>{};
    private _boardingStatusFilter: IBoardingStatusFilter = <IBoardingStatusFilter>{
        clientGroups: [],
        shipName: [],
        portOrigin: [],
        portDestination: [],
        boardingStatus:['PRE_SHIPPING', 'IN_TRANSIT']
    };

    private startDate;
    private endDate;
    private sort: string;
    private offset: number;
    private limit: number;

    constructor(filter?: BoardingStatusFilter) {
        this._boardingStatusFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'boardingStatusFilter', this._boardingStatusFilter, filter);
        this._initialBoardingStatusFilter = _.cloneDeep(this._boardingStatusFilter);
        this.startDate = filter ? filter.startDate : moment().add(-6, 'M').format('DD/MM/YYYY');
        this.endDate = filter ? filter.endDate : moment().add(6, 'M').format('DD/MM/YYYY');
        this.sort = filter ? filter.sort : 'shipmentCode,asc';
        this.offset = filter ? filter.offset : 0;
        this.limit = filter ? filter.limit : 30;
    }

    getClient() { return this._boardingStatusFilter.clientGroups; }
    getShip() { return this._boardingStatusFilter.shipName; }
    getPortOrigin() {  return this._boardingStatusFilter.portOrigin; }
    getPortDestination() { return this._boardingStatusFilter.portDestination; }
    getBoardingStatus() { return this._boardingStatusFilter.boardingStatus; }
    getStartDate() { return this.startDate; }
    getEndDate() { return this.endDate; }
    getSort(): string { return this.sort; }
    getOffset(): number { return this.offset; }
    getLimit(): number { return this.limit; }

    setClient(clientGroups): void { this._boardingStatusFilter.clientGroups = clientGroups; }
    setShip(shipName): void { this._boardingStatusFilter.shipName = shipName; }
    setPortOrigin(portOrigin): void { this._boardingStatusFilter.portOrigin = portOrigin; }
    setPortDestination(portDestination): void { this._boardingStatusFilter.portDestination = portDestination; }
    setBoardingStatus(boardingStatus): void { this._boardingStatusFilter.boardingStatus = boardingStatus; }
    setStartDate(startDate): void { this.startDate = startDate; }
    setEndDate(endDate): void { this.endDate = endDate; }
    setSort(sort): void { this.sort = sort; }
    setOffset(offset): void { this.offset = offset; }
    setLimit(limit): void { this.limit = limit; }

    getRequest() {
        this._initialBoardingStatusFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialBoardingStatusFilter, this._boardingStatusFilter, 'boardingStatusFilter', this._userCacheService.getUserPreferences().id
        );

        return {
            headers: { clientGroupCodes: this.getClient().map((client) => client && client.id).join(',') },
            params: {
                shipmentStatus: this.getBoardingStatus().map((c) => c).join(','),
                shipmentCode: '',
                shipName: this.getShip().map((ship) => ship && ship.id).join(','),
                portOrigin: this.getPortOrigin().map((portOrigin) => portOrigin && portOrigin.id).join(','),
                portDestination: this.getPortDestination().map((portDestination) => portDestination && portDestination.id).join(','),
                sort:  _.cloneDeep(this.getSort()),
                startDate: moment(this.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                endDate: moment(this.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            },
        }
    }
}
