import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import moment from 'moment';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IInvoicesFilter } from './interface/invoices.interface';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';

export class InvoiceStatusFilter {


    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initiaInvoiceStatusFilter: IInvoicesFilter = <IInvoicesFilter>{};
    private _invoiceStatusFilter: IInvoicesFilter = <IInvoicesFilter>{
        clientGroups: [],
        profitCenters: [],
        companies: [],
        intraCompany: false,
        invoiceTypeList: [],
        invoiceRelatedImpList: []
    };

    constructor(filter?: InvoiceStatusFilter) {
        this._invoiceStatusFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'invoiceStatusFilter', this._invoiceStatusFilter, filter);
        this._initiaInvoiceStatusFilter = _.cloneDeep(this._invoiceStatusFilter);
    }

    public getClientGroups(): DropdownOption[] { return this._invoiceStatusFilter.clientGroups; }
    public getProfitCenters(): DropdownOption[] { return this._invoiceStatusFilter.profitCenters; }
    public getCompanies(): DropdownOption[] { return this._invoiceStatusFilter.companies; }
    public getIntraCompany() { return this._invoiceStatusFilter.intraCompany; }
    public getInvoiceTypeList() { return this._invoiceStatusFilter.invoiceTypeList; }
    public getInvoiceRelatedImpList() { return this._invoiceStatusFilter.invoiceRelatedImpList; }

    public setClientGroups(clientGroups): void { this._invoiceStatusFilter.clientGroups = clientGroups; }
    public setProfitCenters(profitCenters): void { this._invoiceStatusFilter.profitCenters = profitCenters; }
    public setCompanies(companies): void { this._invoiceStatusFilter.companies = companies; }
    public setIntraCompany(intraCompany): void { this._invoiceStatusFilter.intraCompany = intraCompany; }
    public setInvoiceTypeList(invoiceTypeList): void { this._invoiceStatusFilter.invoiceTypeList = invoiceTypeList; }
    public setInvoiceRelatedImpList(invoiceRelatedImpList): void { this._invoiceStatusFilter.invoiceRelatedImpList = invoiceRelatedImpList; }

    public _getRequest(gridHeaderFilter, userHasAutomotivePermission, startDate, endDate, limit, pageNumber) {

        this._initiaInvoiceStatusFilter = this._userPreferencesService.verifyFilterChanges(
            this._initiaInvoiceStatusFilter, this._invoiceStatusFilter, 'invoiceStatusFilter', this._userCacheService.getUserPreferences().id);

        let headers = new HttpHeaders();
        headers = headers.set('X-Requested-Client-Groups', this.getClientGroups().map((c) => c.id).join(','));
        let params = new HttpParams();

        params = params.append('offset', `${pageNumber * limit}`);
        params = params.append('pageSize', `${limit}`);
        params = params.append('startDate', moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
        params = params.append('endDate', moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
        params = params.set('companies', this.getCompanies().map((company) => company.id).join(','));
        params = params.set('profitCenters', this.getProfitCenters().map((cr) => cr.id).join(','));
        params = params.set('invoiceTypes', this.getInvoiceTypeList().join(','));
        params = params.set('intraCompanies', this.getIntraCompany());
        params = params.set('isAutomotive', userHasAutomotivePermission);

        // Custom filters in grid columns
        if (gridHeaderFilter.filter) {
            params = params.set(gridHeaderFilter.column, gridHeaderFilter.filter);
        }

        if (this.getInvoiceRelatedImpList().length === 0 || this.getInvoiceRelatedImpList().length === 2) {
            //
        } else if (this.getInvoiceRelatedImpList()[0] === 'related') {
            params = params.set('impRelated', 'true');
        } else {
            params = params.set('impRelated', 'false');
        }
        const request = { headers, params };
        return request;
    }
    defaultFilter() {
        this.setClientGroups([]);
        this.setProfitCenters([]);
        this.setCompanies([]);
        this.setIntraCompany(false);
        this.setInvoiceTypeList([]);
        this.setInvoiceRelatedImpList([]);
    }

}
