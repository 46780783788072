import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import Inputmask from 'inputmask';
import * as _ from 'lodash';
import moment from 'moment';

import { DatepickerTypes } from './types/cmx-filter-datepicker-types.component';

@Component({
    selector: 'cmx-filter-datepicker',
    templateUrl: './cmx-filter-datepicker.component.html',
    styleUrls: ['./cmx-filter-datepicker.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FilterDatepickerComponent implements AfterViewInit, OnChanges {

    @Input() type: DatepickerTypes = 'range-date';
    @Input() periodType: string = 'day';
    @Input() visual: string = 'default';
    @Input() date: string;
    @Input() startDate = null;
    @Input() endDate? = null;
    @Input() startDateRestriction?;
    @Input() endDateRestriction?;
    @Input() icon?: string;
    @Input() inverted?: boolean;
    @Input() lockable?: boolean;
    @Input() alone?: boolean = false;
    @Input() noData?: boolean = false;
    @Input('empty-dates') emptyDates?: boolean = false;
    @Input() singleNoData?: string;
    @Input() clearDates?: boolean = false;
    @Input() disableWeekend: boolean = false;
    @Input('into-pagewrapper') intoPageWrapperComponent: boolean = false;
    @Input() disabled: boolean = false;
    @Input() fullFillWidth: boolean = false;

    @Output('onChangeDates') onChangeDates = new EventEmitter();
    @Input() startActived: boolean = true;
    @Input() endActived: boolean = true;
    @Input() disabledWithData: boolean = false;

    uniqueId: string;
    storedBlockedStartDate: string;
    storedBlockedEndDate: string;
    calendarSelected: boolean = false;
    activedInput: boolean = false;

    constructor() {
        this.uniqueId = `${this.date ? this.date.replace(/ /g, '').replace(/\./g, '') : ''}-${Date.now()}-${Math.floor(
            Math.random() * 9999999
        )}`;
    }

    ngAfterViewInit() {
        this._verifyInitialNoData();
        this._verifyOutsideClick();
        this._applyPosRenderFullWidth();
    }

    ngOnChanges(){
        if (this.clearDates) {
            this.startDate = null;
            this.endDate = null;
            this.startDateRestriction = null;
            this.endDateRestriction = null;
            this._emitChange(false);
        }
     }

    changeDate(dateType, element) {
        this.verifyDates(dateType, $(element.target).val());
    }

    switchActivatedDate(dateType, initial?: boolean) {
        if (dateType === 'startDate') {
            this.startActived = initial ? this.startActived : !this.startActived;
            if (this.startActived) {
                this.startDate = _.cloneDeep(this.storedBlockedStartDate) || moment().format(this._getDateFormat());
                this._generateZebraDatepicker('start');
                if (
                    moment(this.startDate, this._getDateFormat()).isAfter(moment(this.endDate, this._getDateFormat()))
                ) {
                    this.startDate = _.cloneDeep(this.endDate) || moment().format(this._getDateFormat());
                    this.updateDateInputs();
                }
            } else {
                this.storedBlockedStartDate = _.cloneDeep(this.startDate) || moment().format(this._getDateFormat());
                this._generateZebraDatepicker('end');
            }
        }
        if (dateType === 'endDate') {
            this.endActived = initial ? this.endActived : !this.endActived;
            if (this.endActived) {
                this.endDate = _.cloneDeep(this.storedBlockedEndDate) || moment().format(this._getDateFormat());
                if (
                    moment(this.startDate, this._getDateFormat()).isAfter(moment(this.endDate, this._getDateFormat()))
                ) {
                    this.endDate = _.cloneDeep(this.startDate);
                    this.updateDateInputs();
                }
            } else {
                this.storedBlockedEndDate = _.cloneDeep(this.endDate) || moment().format(this._getDateFormat());
            }
        }
        this._emitChange(false);
    }

    verifyDates(datePicker, date, blur?) {
        if (!date) {
            this.updateDateInputs();
        } else if (
            (this.periodType === 'day' && date.replace(/\D/g, '').length === 8) ||
            (this.periodType === 'month' && date != null && date.replace(/\D/g, '').length === 6) ||
            (this.periodType === 'year' && date.replace(/\D/g, '').length === 4)
        ) {
            if (datePicker === 'startDate') {
                if (moment(date, this._getDateFormat()).isAfter(moment(this.endDate, this._getDateFormat()))) {
                    this.startDate = date;
                    if (this.type === 'range-date' || this.type === 'range-month') {
                        this.endDate = date;
                    }
                    setTimeout(() => {
                        this.updateDateInputs(blur);
                    }, 300);
                } else {
                    this.startDate = date;
                    this.updateDateInputs(blur);
                }
            } else {
                if (moment(this.startDate, this._getDateFormat()).isAfter(moment(date, this._getDateFormat()))) {
                    this.startDate = date;
                    if (this.type === 'range-date' || this.type === 'range-month') {
                        this.endDate = date;
                    }
                    setTimeout(() => {
                        this.updateDateInputs(blur);
                    }, 300);
                } else {
                    if (this.type === 'range-date' || this.type === 'range-month') {
                        this.endDate = date;
                    }
                    this.updateDateInputs(blur);
                }
            }
        } else {
            this._verifyOnBlur(blur, datePicker);
        }
    }

    getMask() {
        return this.periodType === 'day' ? 'd0/M0/0000' :
        this.periodType === 'month' ? 'M0/0000' : '0000';
    }

    checkOpen() {
        this.activedInput = !this.activedInput;
    }

    private updateDateInputs(invalidDate?) {
        if (!invalidDate) {
            if (!moment(this.startDate, this._getDateFormat()).isValid() && this.startActived && !this.emptyDates) {
                this.startDate = moment().format(this._getDateFormat());
            }
            if (!moment(this.endDate, this._getDateFormat()).isValid() && this.endActived && !this.emptyDates) {
                if (this.type === 'range-date' || this.type === 'range-month') {
                    this.endDate = moment().format(this._getDateFormat());
                }
            }

            setTimeout(() => {
                ($(`input#${this.uniqueId}-startDate`) as any)
                    .val(this.startDate || this.storedBlockedStartDate)
                    .Zebra_DatePicker({
                        format: this.type === 'single-year' ? 'Y' : this.type === 'range-month' || this.type === 'single-month' ? 'm/Y' : 'd/m/Y',
                        disabled_dates: this.disableWeekend ? ["* * * 0,6"]: "",
                        direction: this.type === 'range-month' || this.type === 'range-date' ?
                            [this.startDateRestriction || false, false] :
                            [this.startDateRestriction || false,
                                this.endDateRestriction || false],
                        onSelect: (selectedDate) => {
                            this.calendarSelected = true;
                            this.verifyDates('startDate', selectedDate);
                        },
                        onClear: () => {
                            this.startDate = undefined;
                            this.endDate = undefined;
                            this.updateDateInputs();
                        },
                    });
                if (this.type === 'range-date' || this.type === 'range-month') {
                    ($(`input#${this.uniqueId}-endDate`) as any)
                        .val(this.endDate || this.storedBlockedEndDate)
                        .Zebra_DatePicker({
                            format: this.type === 'range-month' ? 'm/Y' : 'd/m/Y',
                            direction: this.endDateRestriction
                                ? [this.startDate, this.endDateRestriction]
                                : [this.startDate, false],
                            onSelect: (selectedDate) => {
                                this.calendarSelected = true;
                                this.endDate = selectedDate;
                                this.updateDateInputs();
                            },
                        });
                }
            }, 50);
            this._maskInput();
        }
        this._emitChange(invalidDate);
    }

    private _generateZebraDatepicker(type) {
        if (type === 'start') {
            ($(`input#${this.uniqueId}-endDate`) as any).val(this.endDate || this.storedBlockedEndDate).Zebra_DatePicker({
                direction: [this.startDate, false],
                onSelect: (selectedDate) => {
                    this.startDate = selectedDate;
                    this.updateDateInputs();
                },
            });
        } else {
            ($(`input#${this.uniqueId}-endDate`) as any).val(this.endDate || this.storedBlockedEndDate).Zebra_DatePicker({
                onSelect: (selectedDate) => {
                    this.endDate = selectedDate;
                    this.updateDateInputs();
                },
            });
        }
    }

    private _maskInput() {
        Inputmask({ mask: this._getMaskFormat() }).mask($(`input#${this.uniqueId}-startDate`));
        if (this.type === 'range-date' || this.type === 'range-month') {
            Inputmask({ mask: this._getMaskFormat() }).mask($(`input#${this.uniqueId}-endDate`));
        }
    }

    private _emitChange(invalidDate) {
        this.onChangeDates.emit({
            date: this.date,
            startDate: this.startActived ? this.startDate : null,
            endDate: this.endActived ? this.endDate : null,
            invalid: invalidDate,
        });
    }

    private _verifyInitialNoData() {
        if (this.noData) {
            this.switchActivatedDate('startDate', true);
            this.switchActivatedDate('endDate', true);
        } else {
            if (this.singleNoData === 'startDate') this.switchActivatedDate('startDate', true);
            if (this.singleNoData === 'endDate') this.switchActivatedDate('endDate', true);
        }
        this.updateDateInputs();
    }

    private _getDateFormat(): string {
        return this.periodType === 'day' ? 'DD/MM/YYYY' :
        this.periodType === 'month' ? 'MM/YYYY' : 'YYYY';
    }

    private _getMaskFormat(): string {
        return this.periodType === 'day' ? '99/99/9999' :
        this.periodType === 'month' ? '99/9999' : '9999';
    }

    private _verifyOnBlur(blur, datePicker) {
        if (blur) {
            if (datePicker === 'startDate') {
                this.startDate = this.endDate;
            } else {
                this.endDate = this.startDate;
            }
        }
        this.updateDateInputs(true);
    }

    private _applyPosRenderFullWidth() {
        if (this.alone && this.fullFillWidth) {
            const element = $(`#${this.uniqueId}`)
            if (element) {
                element.addClass('full-fill-width');
            }
        }
    }

    private _verifyOutsideClick() {
        this.activedInput && document.addEventListener('click', (event) => {
            const target = $(event.target);
            const outsideCalendar = !target.parents('.Zebra_DatePicker').length;
            const outsideStartDate = !target.parents(`#${this.uniqueId}.start-datepicker`).length;
            const outsideEndDate = !target.parents(`#${this.uniqueId}.end-datepicker`).length;
            if (outsideCalendar) {
                this.calendarSelected = false;
            }
            if (!this.calendarSelected && outsideCalendar) {
                if (outsideStartDate && outsideEndDate) {
                    this.verifyDates('startDate', this.startDate, true);
                    this.verifyDates('endDate', this.endDate, true);
                } else if (outsideStartDate && (this.type === 'range-date'
                    || this.type === 'range-month') && this.startActived) {
                    this.verifyDates('startDate', this.startDate, true);
                } else if (outsideEndDate && (this.type === 'range-date'
                    || this.type === 'range-month') && this.endActived) {
                    this.verifyDates('endDate', this.endDate, true);
                }
            }
        });
    }
}
