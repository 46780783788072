import { IHoliday } from "../interfaces/calendar.interface"

export const CALENDAR_DAYS = [
    { name: 'domingo', abbreviation: 'Dom' },
    { name: 'segunda', abbreviation: 'Seg' },
    { name: 'terça', abbreviation: 'Ter' },
    { name: 'quarta', abbreviation: 'Qua' },
    { name: 'quinta', abbreviation: 'Qui' },
    { name: 'sexta', abbreviation: 'Sex' },
    { name: 'sábado', abbreviation: 'Sáb' },
]

export const CALENDAR_HOLIDAYS: IHoliday[] = [
    { day: 1, month: 0, name: 'Ano Novo', code: 'ano_novo' },
    { day: 21, month: 3, name: 'Tiradentes', code: 'tiradentes' },
    { day: 1, month: 4, name: 'Dia do Trabalho', code: 'dia_trabalho' },
    { day: 30, month: 4, name: 'Corpus Christi', code: 'corpus_christi', optional: true },
    { day: 7, month: 8, name: 'Independência do Brasil', code: 'independencia' },
    { day: 12, month: 9, name: 'Nossa Senhora de Aparecida', code: 'nossa_senhora' },
    { day: 2, month: 10, name: 'Finados', code: 'finados' },
    { day: 15, month: 10, name: 'Proclamação da República', code: 'proclamacao_republica' },
    { day: 20, month: 10, name: 'Consciência Negra', code: 'consciencia_negra' },
    { day: 24, month: 11, name: 'Véspera de Natal', code: 'vespera_natal', optional: true, half: true },
    { day: 25, month: 11, name: 'Natal', code: 'natal' },
    { day: 31, month: 11, name: 'Véspera de Ano Novo', code: 'vespera_ano_novo', optional: true, half: true },
]