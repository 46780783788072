<cmx-custom-dialog id="fup-unified-datamart-modal" animation="right-left"
    [title]="modalTitle"
    [closeButton]="true"
    [overlay]="true"
    [widthModal]="35">
    <ng-container cmx-custom-dialog-body>
        <loader *ngIf="asyncDatamart"></loader>
        <div id="button-datamart-update-filters" class="reduzed" *ngIf="currentUser$ | async as user">
            <div class="input-group left">
                <ng-container cmx-page-wrapper-body *ngFor="let type of allTypes">
                    <cmx-button
                        size="sm"
                        [icon]="type.icon"
                        [iconType]="type.type"
                        [variant]="type.checked ? 'primary' : 'light'"
                        (click)="applyType(type.name)"
                        *ngIf="user.has(type.permission)">
                        {{ type.name }}
                    </cmx-button>
                </ng-container>
            </div>
        </div>
        <div *ngIf="!asyncDatamart">
            <div *ngIf="array.isArray(options.modelList) && selectedReport" id="fup-unified-datamart-modal-multiselect-container">
                <span>Relatório: </span>
                <cmx-dropdown
                    size="md"
                    id="fup-unified-datamart-modal-multiselect"
                    name="fupModels"
                    [data]="options.modelList"
                    [(ngModel)]="selectedReport"
                    [settings]="dropdownSettings.fupModels"
                    (onChange)="getDatamartConfig()"
                ></cmx-dropdown>
            </div>
            <div *ngIf="linkEnabled || emailEnabled" id="fup-unified-datamart-modal-filters">
                <span class="title">Filtros Considerados</span>
                <span *ngFor="let filter of filters">
                    <ng-container *ngIf="filter.title">
                        <span  class="title">{{ filter?.title }}:</span>
                        {{ getCurrentFilter(datamartFilters[filter.field], filter.field === 'status' ? true : false) }}
                    </ng-container>
                </span>
                <cmx-button
                    id="button-datamart-update-filters"
                    size="xs"
                    width="125px"
                    type="info"
                    (click)="confirmUpdateLink()"
                    >Atualizar Filtros
                </cmx-button>
            </div>
            <cmx-switch-section
                [enabled]="linkEnabled"
                [switchName]="'Gerar Link'"
                [linkUrl]="linkUrl ? linkUrlBase + linkUrl : ''"
                [messages]="[{ message: linkOptions?.length ? '' : 'O link será atualizado cada 2 horas entre 07:00 e 19:00.'}]"
                [messagesIcon]="linkOptions?.length ? null : 'warning'"
                [messageIconColor]="'#d8a600'"
                (onSwitch)="onChangeSwitch($event)"
            ></cmx-switch-section>
            <div *ngIf="linkOptions?.length && linkEnabled" class="link-options-container">
                <hr>
                <span class="title">Selecione uma opção de período:</span>
                <div *ngFor="let option of linkOptions; let first = first;" class="option-container"
                [style.margin-top]="first ? '10px' : '5px'">
                    <span class="label-switch">{{ option?.name }}</span>
                    <p-inputSwitch
                        [style.pointer-events]="option?.enabled ? 'none' : 'auto'"
                        [ngModel]="option?.enabled"
                        (onChange)="onSelectLinkOption(option)"
                    ></p-inputSwitch>
                </div>
            </div>
            <cmx-switch-section
                *ngIf="!hideEmail"
                [enabled]="emailEnabled"
                [switchName]="'E-mail Automático'"
                [messages]="[{ message: 'Horário de envio 13:00 e 18:00 e somente dias úteis.' }]"
                [messagesIcon]="'warning'"
                [messageIconColor]="'#d8a600'"
                (onSwitch)="onChangeSwitch($event)"
            ></cmx-switch-section>
            <cmx-button
                *ngIf="!hideGuide"
                id="button-datamart-guide"
                icon="fa-download"
                type="default"
                (click)="downloadDatamartGuide()"
                >Baixar Manual de Integração do Link
            </cmx-button>
        </div>
    </ng-container>
</cmx-custom-dialog>

<cmx-confirmation-modal
    id="modalUpdateLink"
    [question]="'Deseja realmente atualizar seu Link? Os filtros serão alterados para os atuais selecionados.'"
    labelAction="Atualizar"
    confirmType="danger"
    [hidden]="!showUpdateLinkModal"
    (onConfirm)="updateLink()"
    (onCancel)="showUpdateLinkModal = false"
></cmx-confirmation-modal>
