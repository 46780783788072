import { _applyPosFormatters } from "./pos-formatters/pos-formatters";

export function statusBadgeFormatter(row, cell, value, columnDef, dataContext) {
    const statusSize = {
        sm: 'status-badge-sm',
        md: 'status-badge-md',
        lg: 'statys-badge-lg',
    };

    const statusType = {
        LOADING: 'status-type-loading',
        IN_CONFIRMATION: 'status-type-in-confirmation',
        PROGRAMMED: 'status-type-programmed',
        REVIEW: 'status-type-review',
        IN_REVISION: 'status-type-in-revision',
        IN_REVISION_DOC: 'status-type-in-revision-doc',
        IN_TRANSIT: 'status-type-in-transit',
        IN_DEV_CNTR: 'status-type-in-dev-cntr',
        PROCESSED: 'status-type-processed',
        IN_PROCESSING: 'status-type-in-processing',
        FINISHED: 'status-type-finished',
        FINISHED_WITH_PENDING: 'status-type-finished-pending',
        REJECTED: 'status-type-rejected',
        ERROR: 'status-type-error',
        DUPLICATED: 'status-type-duplicated',
    };

    const setStatusType = statusType[value ? value : statusType['TO_PROGRAM']];
    const setStatusSize = statusSize[columnDef?.formatterData?.size ? columnDef?.formatterData?.size : 'md'];

    const theme = {
        default: `status-badge-theme-default ${setStatusSize}`,
        light: `status-badge-theme-light ${setStatusSize}`,
    };

    const setStatusTheme = theme[columnDef?.formatterData?.theme]
        ? theme[columnDef?.formatterData?.theme]
        : theme['default'];

    const setStatus = {
        LOADING: `
            <button class="${setStatusType}" data-status="${setStatusType}">
                <i class="fa fa-calendar"></i> A Programar
            </button>
        `,
        IN_CONFIRMATION: `
            <button class="${setStatusType}" data-status="${setStatusType}">
                <i class="fa fa-clock-o"></i> EM Confirmação
            </button>
        `,
        PROGRAMMED: `
            <button class="${setStatusType}" data-status="${setStatusType}">
                <i class="fa fa-check"></i> Programado
            </button>
        `,
        REVIEW: `
            <button class="${setStatusType}" data-status="${setStatusType}">
                <i class="fa fa-eye"></i> A Revisar
            </button>
        `,
        IN_REVISION: `
            <button class="${setStatusType}" data-status="${setStatusType}">
                <i class="fa fa-list"></i> Em revisão
            </button>
        `,
        IN_REVISION_DOC: `
            <button class="${setStatusType}" data-status="${setStatusType}">
                <i class="fa fa-list"></i> Em revisão doc
            </button>
        `,
        IN_TRANSIT: `
            <button class="${setStatusType}" data-status="${setStatusType}">
                <i class="fa fa-exchange"></i> Em trânsito
            </button>
        `,
        IN_DEV_CNTR: `
            <button class="${setStatusType}" data-status="${setStatusType}">
                <i class="fa fa-exchange"></i> Em dev. CNTR
            </button>
        `,
        PROCESSED: `
            <button class="${setStatusType}" data-status="${setStatusType}">
                <i class="fa fa-check"></i> Processado
            </button>
        `,
        IN_PROCESSING: `
            <button class="${setStatusType}" data-status="${setStatusType}">
                <i class="fa fa-hourglass-start"></i> Em processamento
            </button>
        `,
        FINISHED: `
            <button class="${setStatusType}" data-status="${setStatusType}">
                <i class="fa fa-check"></i> Finalizado
            </button>
        `,
        FINISHED_WITH_PENDING: `
        <button class="${setStatusType}" data-status="${setStatusType}">
            <i class="fa fa-check"></i> Finalizado com pendência
        </button>
        `,
        REJECTED: `
        <button class="${setStatusType}" data-status="${setStatusType}">
            <i class="fa fa-times"></i> Rejeitado
        </button>
        `,
        ERROR: `
        <button class="${setStatusType}" data-status="${setStatusType}">
            <i class="fa fa-times"></i> Erro
        </button>
        `,
        DUPLICATED: `
        <button class="${setStatusType}" data-status="${setStatusType}">
            <i class="fa fa-copy"></i> Duplicado
        </button>
        `,
    };

    const contentValue = `<div class="slick-status-badge ${setStatusTheme}">${setStatus[value]}</div>`;
    return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
}