import { UtilsService } from '#services/_utils/utils.service';
import { LOGISTICS_HANDLING_MODAL_TYPES_DESCRIPTION } from 'app/shared/constants/logistics-handling-modal-types-description.constant';
import { LogisticsHandlingModalTypes } from 'app/shared/types/logistics-handling-types.type';

export class Utils {
    static getFormattedPercentage(type: string, percentage: number, utilsService: UtilsService): string {
        const formattedPercentage = utilsService.formatNumber(percentage, { style: 'percent' });
        return `${percentage > 0 && type === 'table' ? `+${formattedPercentage}` : formattedPercentage}`;
    }

    static getIconMarginByLogisticsHandlingModalType(modalType: LogisticsHandlingModalTypes): number {
        const margins = {
            AIR: 5,
            MARITIME: -10,
            ROAD: 15,
            VEHICLES: 15,
        };

        return margins[modalType];
    }

    static getGraphLabelByLogisticsHandlingModalType(
        value: any,
        type: LogisticsHandlingModalTypes,
        utilsService: UtilsService
    ): string {
        const fractionDigits = type !== 'AIR' ? 0 : 2;

        return utilsService.formatNumber(value, {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
        });
    }

    static getLogisticsHandlingModalTypeFormats(
        modalType: LogisticsHandlingModalTypes,
        utilsService: UtilsService
    ): [string, string, string] {
        return [
            utilsService.capitalize(modalType),
            modalType.toLowerCase(),
            LOGISTICS_HANDLING_MODAL_TYPES_DESCRIPTION[modalType].name,
        ];
    }
}
