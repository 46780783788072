import { UserCacheService } from '#services/_user/app-user-cache.service';
import { Injectable } from '@angular/core';
import { UserService } from 'app/auth/_services';
import { MENU } from './menu-control.constant';
import * as _ from 'lodash';
import { IMenuControl, IMenuControlChildren, IMenuControlFeatureFlag } from './menu-control.interface';
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';
import { FeatureToggle } from '#services/_feature-flag/feature-flag.entity';

@Injectable()
export class MenuControlService {

    rawMenu = MENU;
    currentUser = this._userService.getCurrentUser();
    featuresToggleMenu: Map<string, boolean> = new Map<string, boolean>();

    constructor(
        private _userService: UserService,
        private _userCacheService: UserCacheService,
        private _featureFlagService: FeatureFlagService,
    ) { 
        this._checkFeatureStatus = this._checkFeatureStatus.bind(this);
    }

    async defineMenu() {

        await this._verifyFeatureFlagMenu(this.rawMenu);

        const menu = [];
        this.rawMenu.forEach((category: IMenuControl) => {
            const itens = this._verifyItemPermissions(category);
            this._verifySubChildrenPermissions(itens);
            this._verifyChildrenSecondaryName(itens);
            if (itens.length) {
                if (itens.length === 1 && (!itens[0].hasOwnProperty('children') || itens[0].children.length === 0)) {
                    menu.push({
                        name: itens[0].aloneName ? itens[0].aloneName : itens[0].name,
                        icon: category.icon,
                        children: [],
                        route: itens[0].route,
                    })
                } else {
                    this._pushToMenu(menu, category, itens);
                }
            } else if (!category.children) {
                let verification = true;
                category.permissions.have.forEach((mustHavePermissionRule) => {
                    if (mustHavePermissionRule.valid && !this.currentUser.has(mustHavePermissionRule.permission) ||
                        !mustHavePermissionRule.valid && this.currentUser.has(mustHavePermissionRule.permission)) {
                            verification = false;
                    }
                });

                if (verification) {
                    this._pushToMenu(menu, category, itens);
                }
   
            }
        });
        return menu;
    }

    private _pushToMenu(menu: IMenuControl[], category: IMenuControl, itens: IMenuControlChildren[]) {

        menu.push({
            name: category.name,
            icon: category.icon,
            children: itens.length > 1 ? this._orderMenuItens(itens) : itens,
            route: category.route,
        });
    }

    private _verifyItemPermissions(category): IMenuControlChildren[] {

        const itens = [];
        if (category.hasOwnProperty('children')) {
            category.children.forEach((item: IMenuControlChildren) => {
                this._applyMissingAtributes(item);
                if (!item.clientGroupCodes.length || (item.clientGroupCodes.length && this._verifyClientPermission(item.clientGroupCodes))) {
                    if ((!item.permissions.have.length && !item.permissions.haveSome.length) || this._verifyPermission(item.permissions)) {
                        this._verifyItemFeatureFlag(itens, item);
                    }
                }
            })
        }
        return itens;
    }

    private _verifyClientPermission(clientGroupCodes): boolean {
        const userClientCodes = [];
        this._userCacheService.getClientGroups().forEach((c) => userClientCodes.push(c['code']));
        return (_.intersection(userClientCodes, clientGroupCodes).length > 0);
    }

    private _verifyPermission(permissions): boolean {
        let verification = true;
        permissions.have.forEach((mustHavePermissionRule) => {
            if (mustHavePermissionRule.valid && !this.currentUser.has(mustHavePermissionRule.permission) ||
                !mustHavePermissionRule.valid && this.currentUser.has(mustHavePermissionRule.permission)) {
                    verification = false;
            }
        });
        if (!verification) {
            return verification;
        } else {
            if (permissions.haveSome.length) {
                verification = false;
            }
            permissions.haveSome.forEach((haveSomePermissionRule) => {
                if (haveSomePermissionRule.valid && this.currentUser.has(haveSomePermissionRule.permission) ||
                    !haveSomePermissionRule.valid && !this.currentUser.has(haveSomePermissionRule.permission)) {
                        verification = true;
                }
            })
        }
        return verification;
    }

    private _verifySubChildrenPermissions(itens) {
        itens.forEach((item) => {
            if (item.children) {
                const subItens = this._verifyItemPermissions(item);
                item.children = subItens;
                this._verifySubChildrenPermissions(item.children)
            }
        })
    }

    private _verifyChildrenSecondaryName(itens) {
        itens.forEach((item) => {
            if (item.secondaryName && item.secoundaryPermissions) {
                this._applyMissingAtributes(item);
                if ((!item.secoundaryPermissions.have.length && !item.secoundaryPermissions.haveSome.length) || this._verifyPermission(item.secoundaryPermissions)) {
                    item.name = item.secondaryName;
                }
            }
        })
    }

    private _applyMissingAtributes(item) {
        if (!item.hasOwnProperty('clientGroupCodes')) {
            item['clientGroupCodes'] = [];
        }
        if (!item.hasOwnProperty('permissions')) {
            item['permissions'] = {};
        }
        if (!item.permissions.hasOwnProperty('have')) {
            item.permissions['have'] = [];
        }
        if (!item.permissions.hasOwnProperty('haveSome')) {
            item.permissions['haveSome'] = [];
        }
        if (item.hasOwnProperty('secoundaryPermissions')) {
            if (!item.secoundaryPermissions.hasOwnProperty('have')) {
                item.secoundaryPermissions['have'] = [];
            }
            if (!item.secoundaryPermissions.hasOwnProperty('haveSome')) {
                item.secoundaryPermissions['haveSome'] = [];
            }
        }
    }

    private _orderMenuItens(itens){
        return itens.map(i => {

            return {
                ...i,
                children: i.children && i.children.length > 1 ? this._orderMenuItens(i.children) : i.children
            }
        }).sort((a, b) => {
            if(this._replaceSpecialChar(a.name) === this._replaceSpecialChar(b.name)){
                return 0;
            }

            return this._replaceSpecialChar(a.name) > this._replaceSpecialChar(b.name) ? 1 : -1
        });
    }

    private _replaceSpecialChar(char: string){
        return char.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    private async _verifyFeatureFlagMenu(menu: IMenuControl[]): Promise<void> {
        try {
            const features: string[] = this._collectFeatures(menu);
            if(features && features.length > 0) {
                const featureStatusPromises = features.map(this._checkFeatureStatus);
                const featureStatus = await Promise.all(featureStatusPromises);
                featureStatus.map(item => this.featuresToggleMenu.set(item.name, item.enabled));
            }
        } catch(error) {
            this.featuresToggleMenu = null;
        }
    }

    private _collectFeatures(menuItems: (IMenuControl | IMenuControlChildren)[], featureFlags: Set<string> = new Set()): string[] {
        for (const menuItem of menuItems) {
            if ('featureFlag' in menuItem && menuItem.featureFlag) {
                if (menuItem.featureFlag.enabled) {
                    featureFlags.add(menuItem.featureFlag.enabled);
                }
                if (menuItem.featureFlag.notEnabled) {
                    featureFlags.add(menuItem.featureFlag.notEnabled);
                }
            }
        
            if ('children' in menuItem && menuItem.children) {
                this._collectFeatures(menuItem.children, featureFlags);
            }
        }
        return [...featureFlags];
    }

    private async _checkFeatureStatus(feature: string): Promise<FeatureToggle> {
        try {
            const isEnabled = await this._featureFlagService.isFeatureFlagEnabledV2(feature);
            return new FeatureToggle(feature, isEnabled);
        } catch (error) {
            return new FeatureToggle(feature, false);
        }
    }

    private _verifyItemFeatureFlag(itens: IMenuControlChildren[], item: IMenuControlChildren) {
        if ((!item.featureFlag) || (!item.featureFlag.enabled && !item.featureFlag.notEnabled)) { 
            itens.push(item);
            return;
        }

        if(this._verifyFeatureFlagEnabled(item.featureFlag)) {
            itens.push(item);
        }
    }

    private _verifyFeatureFlagEnabled(featureFlag: IMenuControlFeatureFlag): boolean {
        let featureName = null;
        
        if(!this.featuresToggleMenu || this.featuresToggleMenu.size <= 0) return false;
        if(featureFlag.enabled) featureName = featureFlag.enabled;
        if(featureFlag.notEnabled) featureName = featureFlag.notEnabled;
        if(!featureName) return false;

        const enabled = this.featuresToggleMenu.get(featureName);

        return (featureFlag.enabled && enabled) || (featureFlag.notEnabled && !enabled);
    }    
}
