export function phoneFormatter(row, cell, value, { formatterData }, dataContext){
    if(!value || value.length === 0){ return "—" }
    const formatPhoneNumber = (value, countryCode = false) => {
        if(value.length <= 9) {
            return '—';
        }
        const cleaned = (value).replace(/\D/g, '');
        if (!countryCode) {
            const match = cleaned.length === 10 ? cleaned.match(/^(\d{2})(\d{4})(\d{4})$/) : cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        if (countryCode === true) {
            const match = cleaned.length === 12 ? cleaned.match(/^(\d{2})(\d{2})(\d{4})(\d{4})$/) : cleaned.match(/^(\d{2})(\d{2})(\d{5})(\d{4})$/);
            return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`
        }
    }
    if (formatterData?.countryCode) {
        if(typeof value === "object"){
            const phones = [];
            for (const phone of value) {
                phones.push(formatPhoneNumber(phone, formatterData.countryCode));
            }
            return phones.join(', ');
        }else{
            return formatPhoneNumber(value, formatterData.countryCode);
        }
    }
    return formatPhoneNumber(value);
}