import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';
import { ImportDetailsService } from '../import-details.service';
import { CHASSI_COLUMNS } from './constants/chassi-columns.constants';
import { FollowUpReportSlickGrid } from '../../reports/report-followup/report-followup.slickGrid';
import { Router } from '@angular/router';
import { ImportDetailItem } from '../import-details-item';

@Component({
    selector: 'app-import-details-chassi',
    templateUrl: './chassi.component.html',
    styleUrls: ['./chassi.component.scss'],
    encapsulation: ViewEncapsulation.None
  })
export class ChassiComponent extends ImportDetailItem implements OnInit {
    asyncDetail = !this.showLoader;

    dataLoaded;
    gridChassiColumns: IGridColumn[] = CHASSI_COLUMNS;
    chassiRows;

    constructor(
        private _importDetailsService: ImportDetailsService,
        private _followUpReport: FollowUpReportSlickGrid,
        protected _router: Router,
    ) {
        super(_router);
    }

    ngOnInit() {
        (this.showLoader) ? 
            setTimeout(() => {this.loadDetails();}, 1000) : 
            this.loadDetails();
    }

    loadDetails() {
        this.asyncDetail = false;
        this.dataLoaded = this._importDetailsService.getDetails();
        this.chassiRows = this._followUpReport.extractChassis(this.dataLoaded);

        this.asyncDetail = true;
    }
}
