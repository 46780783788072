import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import moment from 'moment';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IExchangeFlowFilter } from './interface/exchange-flow.interface';


export class ExchangeFlowFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialExchangeFlowFilter: IExchangeFlowFilter = <IExchangeFlowFilter>{};
    private _exchangeFlowFilter: IExchangeFlowFilter = <IExchangeFlowFilter>{
        subsidiaryNames: []
    };

    constructor(filter?: ExchangeFlowFilter) {

        this._exchangeFlowFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'exchangeFlowFilter', this._exchangeFlowFilter, filter);
        this._initialExchangeFlowFilter = _.cloneDeep(this._exchangeFlowFilter);

    }

    getSubsidiaryNames() {
        return this._exchangeFlowFilter.subsidiaryNames;
    }

    setSubsidiaryNames(subsidiaryNames): void {
        this._exchangeFlowFilter.subsidiaryNames = subsidiaryNames;
    }

    updatePreferences() {
        this._initialExchangeFlowFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialExchangeFlowFilter, this._exchangeFlowFilter, 'exchangeFlowFilter', this._userCacheService.getUserPreferences().id);
    }
}
