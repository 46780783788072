import { multipleFormatter } from "./multiple.formatter";
import { _applyPosFormatters } from "./pos-formatters/pos-formatters";
import { textFormatter } from "./text.formatter";

export function badgeFormatter(row, cell, value, columnDef, dataContext) {
    const currentValue = columnDef.formatterData && columnDef.formatterData.singleField ? value[columnDef.formatterData.singleField] : value;
    const defaultMultiple = (columnDef.formatterData && columnDef.formatterData.defaultMultiple) || false;
    const includeZero = columnDef.formatterData && columnDef.formatterData.includeZero && currentValue === 0;

    if (!currentValue && !includeZero) {
        return textFormatter(row, cell, value, columnDef, dataContext);
    }

    if (Array.isArray(currentValue)) {
        if (currentValue.length > 1 && defaultMultiple) {
            return multipleFormatter(row, cell, value, columnDef, dataContext);
        } else if(currentValue.length === 0) {
            return textFormatter(row, cell, null, columnDef, dataContext);
        }
    }

    const badgeColor = dataContext.badgeColor ? dataContext.badgeColor : '#808080';
    const backgroundColor = (columnDef.formatterData && columnDef.formatterData.backgroundColor) || badgeColor;
    const classes = (columnDef.formatterData && columnDef.formatterData.classes) || '';
    const contentValue = `<div class="badge-container">
        <div class="badge ${classes}" style="background-color: ${backgroundColor}"><span>${currentValue}</span></div>
    </div>`;
    return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
}