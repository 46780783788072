import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import moment from 'moment';
import 'moment/locale/pt-br';

import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { FilterSelection } from '../../pages/default/balance-sheet/filter-selection';

import { FilterStateService } from '../../pages/default/balance-sheet/filter-state.service';
import { GroupingStateService } from '../../pages/default/balance-sheet/grouping-state.service';
import { HierarchyStateService } from '../../pages/default/balance-sheet/hierarchy-state.service';
import { DetailTableCommentsService } from './detailtable-comments.service';
import { DownloadService } from '#services/_download/download.service';
import { Grouping, Hierarchy } from '#services/_results/results.service';
import { UtilsService } from '#services/_utils/utils.service';
import { UserService } from 'app/auth/_services';
import { environment } from '#environment';

declare let Slick: any;
@Component({
    selector: 'detailtable',
    templateUrl: './detailtable.component.html',
    styleUrls: ['./detailtable.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [DetailTableCommentsService],
})
export class DetailTableComponent {
    moment = moment;
    queryId = Math.random();
    subscription;
    innerSearch = '';
    atualFilter = '';
    filterOptions = [];
    pagechanged = false;
    commentHistory = false;
    historic = false;
    editableElements = ['#detailGrid', '.solo-btn', 'pagination'];

    rowGroup: string;
    filters = {
        imps: [],
        clientGroups: [],
        costCenters: [],
        vendorNames: [],
        users: [],
    };
    selectedOptions = {
        imps: [],
        clientGroups: [],
        costCenters: [],
        vendorNames: [],
        users: [],
    };
    requestFilters = {
        imps: [],
        clientGroups: [],
        costCenters: [],
        vendorNames: [],
        users: [],
    };

    data;
    accountingCode;
    previousBalance;
    totalAmountPlusBalance;
    totalAmount;

    selectedCommentId;
    selectedImp;
    selectedCostCenter;
    selectedAccount;
    selectedTransactionReferenceDate;
    selectedRecno;
    selectedOriginalRecno;
    selectedDebitCreditIndicator;
    selectedCompanyCode;
    currentComments = [];
    selectedComment = '';
    allCrs = false;
    editCommentMode = false;
    hadCommentChange = false;
    asyncComments = false;
    asyncDetailTable = false;
    asyncDetailTitle = false;
    asyncExport = false;
    limit = null;
    pages = [];
    orderBy = 'IMPORTATION_NUMBER';
    sort = true;
    hasData = true;
    hasDataWithFilter = true;
    startDate;
    endDate;
    reportType = this.getReportType(window.location.href);
    indexLine;

    private columns = null;
    private rows = null;
    private dataView = null;
    private detailGrid = null;
    private total = null;
    private offset = 0;

    private financeiroFilterState: FilterSelection;
    private financeiroGroupingState: Grouping;
    private financeiroHierarchyState: Hierarchy;

    constructor(
        private route: ActivatedRoute,
        private http: HttpClient,
        private filterState: FilterStateService,
        private groupingState: GroupingStateService,
        private downloadService: DownloadService,
        private hierarchyState: HierarchyStateService,
        private userService: UserService,
        private router: Router,
        private commentService: DetailTableCommentsService,
        private utilsService: UtilsService
    ) {
        $(document).ready(() => {
            $(document).on('mousedown', this.click.bind(this));
        });
    }

    ngOnDestroy() {
        $(document).off('mousedown', this.click);
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    openHistoric() {
        this.historic = true;
        this.selectedImp = '';
        this.selectedCostCenter = '';
        this.selectedTransactionReferenceDate = '';
        this.selectedRecno = '';
        this.selectedOriginalRecno = '';
        this.selectedDebitCreditIndicator = '';
        this.selectedCompanyCode = '';
        this.getComments();
        this.openCommentsModal();
    }

    openCommentsModal() {
        $('detailtable .commentsModal').removeClass('hidden');
        $('detailtable .commentsArea').removeClass('invisible');
    }
    closeCommentsModal() {
        this.hideAllErrorOrSucess();
        $('detailtable .commentsModal').addClass('hidden');
        $('detailtable .commentsArea').addClass('invisible');
    }
    addComment() {
        const atualComment = _.cloneDeep(this.selectedComment);
        this.calCommentAction();
        this.commentService
            .postComment(
                this.selectedImp,
                this.selectedAccount,
                atualComment,
                this.allCrs ? '' : this.selectedCostCenter,
                this.selectedTransactionReferenceDate,
                this.selectedRecno,
                this.selectedOriginalRecno,
                this.selectedDebitCreditIndicator,
                this.selectedCompanyCode
            )
            .subscribe(
                (response) => {
                    this.updateComments();
                    this.showSuccess('create');
                    this.contComments();
                },
                (error) => {
                    this.updateComments();
                    this.showError();
                }
            );
    }
    editComment(id) {
        const atualComment = _.cloneDeep(this.selectedComment);
        this.calCommentAction();
        this.commentService
            .editComment(
                this.selectedCommentId,
                this.selectedImp,
                this.selectedAccount,
                atualComment,
                this.allCrs ? '' : this.selectedCostCenter,
                this.selectedTransactionReferenceDate,
                this.selectedRecno,
                this.selectedOriginalRecno,
                this.selectedDebitCreditIndicator,
                this.selectedCompanyCode
            )
            .subscribe(
                (response) => {
                    this.updateComments();
                    this.showSuccess('edit');
                },
                (error) => {
                    this.updateComments();
                    this.showError();
                }
            );
    }
    calCommentAction() {
        this.hideAllErrorOrSucess();
        this.selectedComment = '';
        this.asyncComments = true;
    }
    updateComments() {
        this.hadCommentChange = true;
        this.editCommentMode = false;
        this.allCrs = false;
        this.getComments();
    }
    startEditComment(comment) {
        this.selectedCommentId = comment.commentId;
        if ($(`detailtable .comments .comment.${comment.commentId} .edit`).hasClass('clicked')) {
            this.editCommentMode = false;
            this.selectedComment = '';
            $(`detailtable .comments .comment.${comment.commentId} .edit`).removeClass('clicked');
        } else {
            this.editCommentMode = true;
            this.selectedComment = comment.importationComment;
            $('detailtable .comments .comment .edit').removeClass('clicked');
            $(`detailtable .comments .comment.${comment.commentId} .edit`).addClass('clicked');
        }
    }
    clickCheckbox() {
        this.allCrs = !this.allCrs;
    }

    showError() {
        $('detailtable .commentsModal .error').removeClass('hidden');
    }
    showSuccess(type) {
        $(`detailtable .commentsModal .success.${type}`).removeClass('hidden');
    }
    hideAllErrorOrSucess() {
        $('.error').addClass('hidden');
        $('.success').addClass('hidden');
    }

    // Fecha o modal, caso clicado fora do mesmo, e determina o typo do filtro atual, caso clicado em abrir filtro.
    click(element) {
        if (
            !$(element.target.parentElement).hasClass('selectModal') &&
            !$(element.target.parentElement).hasClass('selectItem') &&
            !$(element.target.parentElement).hasClass('buttonArea') &&
            !$(element.target).hasClass('selectModal') &&
            !$(element.target).hasClass('selectItem') &&
            !$(element.target).hasClass('buttonArea') &&
            !$(element.target).hasClass('detailInput')
        ) {
            $('.selectModal').addClass('hidden');
            this.innerSearch = '';
        } else if ($(element.target).hasClass('detailInput')) {
            if ($(element.target).attr('id') !== undefined) {
                this.atualFilter = $(element.target).attr('id');
            }
            this.populateFilterOptions();
        }
    }

    // Popula os objetos com a variavel selected, vazia ou 'selected', para apresentar os itens já selecionados.
    populateFilterOptions() {
        switch (this.atualFilter) {
            case '':
                this.filterOptions = [];
                break;
            default:
                this.filterOptions = this.filters[this.atualFilter];
                this.filters[this.atualFilter].map((filter) => {
                    filter.selected = '';
                    this.selectedOptions[this.atualFilter].map((selectedFilter) => {
                        if (filter.name === selectedFilter.name) {
                            filter.selected = 'selected';
                        }
                    });
                });
                break;
        }
    }

    export(type: any) {
        this.asyncExport = true;
        const options = this.downloadService.getSourceOptions();
        const data = this.getFilter('export');
        data.offset = 0;
        data.limit = this.total;

        if (type === 'csv') {
            // CSV
            options.headers = new HttpHeaders({
                Accept: 'text/csv',
            });
        } else {
            // XLS
            options.headers = new HttpHeaders({
                Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
        }

        const url = this.http.post(`${environment.endpoints.resultsService}/account-transactions`, data, options);

        this.downloadService
            .downloadFrom(url, `lancamentos-${moment().format('YYYY-MM-DD-HHMMSS')}.${type}`)
            .subscribe(() => {
                this.asyncExport = false;
            });
    }

    // Seleciona visualmente a opção e adiciona à variável de grupo de opções selecionadas.
    selectOption(option, element) {
        if (this.selectedOptions[this.atualFilter].indexOf(option) === -1) {
            this.selectedOptions[this.atualFilter].push(option);
        } else {
            this.selectedOptions[this.atualFilter].splice(this.selectedOptions[this.atualFilter].indexOf(option), 1);
        }
        if ($(element.target).hasClass('selected')) {
            $(element.target).removeClass('selected');
        } else {
            $(element.target).addClass('selected');
        }
    }

    // Remove o campo selected e devolve ao formato de array de string os campos necessários.
    applyFilters() {
        $('.selectModal').addClass('hidden');
        Object.keys(this.selectedOptions).forEach((type) => {
            switch (type) {
                case 'vendorNames':
                case 'users':
                case 'imps':
                    this.requestFilters[type] = [];
                    this.selectedOptions[type].map((filter) => {
                        this.requestFilters[type].push(filter.name);
                    });
                    break;
                default:
                    this.requestFilters[type] = [];
                    this.selectedOptions[type].map((filter) => {
                        this.requestFilters[type].push(filter.code);
                    });
                    break;
            }
        });
        this.prepareDetailTable(this.offset, 'filtered');
    }

    changePage(pageNumber) {
        this.pagechanged = true;
        $('.grid-canvas').addClass('hidden');
        this.offset = pageNumber - 1;
        this.prepareDetailTable(this.offset, 'paginate');
    }

    loadDetailTable(data: any, pagination) {
        this.selectedAccount = data.accountingCode;
        this.accountingCode = `${data.accountingCode} • ${data.accountingName}`;
        this.totalAmount = this.utilsService.formatNumber(data.totalAmount, {
            style: 'currency',
        });
        this.previousBalance = this.utilsService.formatNumber(data.previousBalance, {
            style: 'currency',
        });
        this.totalAmountPlusBalance = this.utilsService.formatNumber(data.totalAmountPlusBalance, {
            style: 'currency',
        });
        if (pagination === false) {
            $('a').removeClass('active');
            $('li:first-child a').addClass('active');
        }
        this.total = data.total;
        const num = Math.ceil(data.total / 50);
        this.pages = Array.apply(null, { length: num }).map(Number.call, Number);
        const detailData = data.analyticalTransactions;

        this.columns = this.createColumns();
        this.rows = this.extractRows(detailData);

        const options = {
            defaultColumnWidth: 120,
            editable: false,
            enableCellNavigation: true,
            enableTextSelectionOnCells: true,
            rerenderOnResize: true,
        };

        // initialize the model
        this.dataView = new Slick.Data.DataView({ inlineFilters: false });
        this.dataView.beginUpdate();
        this.dataView.setItems(this.rows);
        this.dataView.setFilter(this.filter.bind(this));
        this.dataView.endUpdate();

        // initialize the grid
        this.detailGrid = new Slick.Grid('#detailGrid', this.dataView, this.columns, options);

        this.detailGrid.onHeaderClick.subscribe(
            function (e, args) {
                const id = $(e.target).attr('id');
                if (
                    ($(e.target).hasClass('detailInput') && id === 'imps') ||
                    id === 'costCenters' || id === 'vendorNames' || id === 'users' ||
                    id === 'clientGroups'
                ) {
                    this.innerFilter = '';
                    const heightPosition = $(e.target.offsetParent).offset().top - $(window).scrollTop();
                    const widthPosition = $(e.target.offsetParent).offset().left;
                    const width = $(e.target.offsetParent).width();
                    const height = $(e.target.offsetParent.offsetParent).height();
                    if ($('.selectModal.one').hasClass('hidden') && $('.selectModal.two').hasClass('hidden')) {
                        $('.selectModal.one').attr(
                            'style',
                            `width: calc(${width}px + 12px);
                                        top: calc(${heightPosition}px + ${height}px);
                                        left: calc(${widthPosition}px + 12px)`
                        );
                        $('.selectModal.one').removeClass('hidden');
                    } else if ($('.selectModal.two').hasClass('hidden')) {
                        $('.selectModal.one').addClass('hidden');
                        $('.selectModal.two').attr(
                            'style',
                            `width: calc(${width}px + 12px);
                                        top: calc(${heightPosition}px + ${height}px);
                                        left: calc(${widthPosition}px + 12px)`
                        );
                        $('.selectModal.two').removeClass('hidden');
                    } else {
                        $('.selectModal.two').addClass('hidden');
                        $('.selectModal.one').attr(
                            'style',
                            `width: calc(${width}px + 12px);
                                        top: calc(${heightPosition}px + ${height}px);
                                        left: calc(${widthPosition}px + 12px)`
                        );
                        $('.selectModal.one').removeClass('hidden');
                    }
                }
                if ($(e.target).hasClass('la')) {
                    $('.grid-canvas').addClass('hidden');
                    const lastOrder = this.orderBy;
                    this.orderBy = args.column.field;
                    if (lastOrder === this.orderBy) {
                        this.sort = !this.sort;
                    } else {
                        this.sort = true;
                    }
                    this.openDetailModal(this.offset, true, true, this.rowGroup);
                }
            }.bind(this)
        );

        this.detailGrid.onClick.subscribe(this.onClick.bind(this));

        this.dataView.onRowCountChanged.subscribe(
            function (e, args) {
                this.detailGrid.updateRowCount();
                this.detailGrid.render();
            }.bind(this)
        );

        this.dataView.onRowsChanged.subscribe(
            function (e, args) {
                this.detailGrid.invalidateRows(args.rows);
                this.detailGrid.render();
            }.bind(this)
        );
    }

    openDetailModal(page, pagination, ordenation?, rowGroup?) {
        this.rowGroup = rowGroup ? rowGroup : null;
        this.offset = page;
        this.asyncDetailTitle = false;

        $('.DetailTableModal').addClass('modal-open');

        ordenation ? this.prepareDetailTable(pagination, 'ordenation') : this.prepareDetailTable(pagination, 'first');
    }

    prepareDetailTable(pagination: any, turn) {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.asyncDetailTable = false;
        this.utilsService.setInvisible('detailtable', this.editableElements);
        this.subscription = this.http
            .post(`${environment.endpoints.resultsService}/account-transactions`, this.getFilter(turn))
            .subscribe((response) => {
                this.innerSearch = '';
                this.data = response;
                // Aplica os filtros apenas na primeira chamada
                if (turn === 'first') {
                    const filters = 'filters';
                    const selected = 'selected';
                    // Em caso de filtros que chegam em array de string, os transforma em objeto com key 'name'.
                    // Aplica o campo selected = '' em todos os objetos.
                    Object.keys(response[filters]).forEach((type) => {
                        switch (type) {
                            case 'vendorNames':
                            case 'users':
                            case 'imps':
                                this.filters[type] = [];
                                response[filters][type].map((filter) => {
                                    this.filters[type].push({ name: filter, selected: '' });
                                });
                                break;
                            default:
                                this.filters[type] = response[filters][type];
                                this.filters[type].map((filter) => {
                                    filter[selected] = '';
                                });
                                break;
                        }
                    });
                    if (this.data.analyticalTransactions.length === 0) {
                        this.hasData = false;
                    } else {
                        this.hasData = true;
                        this.hasDataWithFilter = true;
                    }
                } else {
                    if (this.data.analyticalTransactions.length === 0) {
                        this.hasDataWithFilter = false;
                    } else {
                        this.hasData = true;
                        this.hasDataWithFilter = true;
                    }
                }

                this.limit = this.data.total;
                this.asyncDetailTable = true;
                this.asyncDetailTitle = true;
                this.loadDetailTable(this.data, pagination);
                $('.grid-canvas').removeClass('hidden');
                $('.pagination').removeClass('invisible');
                this.asyncDetailTable = true;
                this.utilsService.setVisible('detailtable', this.editableElements);
                this.detailGrid.resizeCanvas();
            });
    }

    resetFilters() {
        this.hasDataWithFilter = true;
        this.requestFilters = {
            imps: [],
            clientGroups: [],
            costCenters: [],
            vendorNames: [],
            users: [],
        };
        this.selectedOptions = {
            imps: [],
            clientGroups: [],
            costCenters: [],
            vendorNames: [],
            users: [],
        };
        this.offset = 0;
        this.prepareDetailTable(this.offset, 'first');
    }

    filterByFilter(option): boolean {
        if (this.innerSearch === '') {
            return true;
        } else if (!option.name) {
            return false;
        }
        this.populateFilterOptions();
        return option.name.toLowerCase().includes(this.innerSearch.toLowerCase());
    }

    trackByFn(index: number, item: any) {
        return index;
    }

    private onClick(e, args) {
        const item = this.dataView.getItem(args.row);
        if (
            $(e.target).hasClass('impValue') &&
            this.userService.getCurrentUser().has('ROUTE_SUPPLY_CHAIN_IMP_DETAIL')
        ) {
            const imp = item.IMPORTATION_NUMBER;
            if (imp !== '-') {
                const companyCode = item.COMPANY_CODE ? ("0" + item.COMPANY_CODE).slice(-2) : '0'
                if (
                    navigator.userAgent.indexOf('MSIE') !== -1 ||
                    navigator.appVersion.indexOf('Trident/') > -1 ||
                    window.navigator.userAgent.indexOf('Edge') > -1
                ) {
                    this.router.navigate([`./supply-chain/importacao/${companyCode}/${imp}`]);
                } else {
                    window.open(`./supply-chain/importacao/${companyCode}/${imp}`);
                }
            }
        } else if ($(e.target).hasClass('ball')) {
            this.indexLine = item.id;
            this.historic = false;
            const costCenter = item.COST_CENTER_CODE;
            this.selectedCostCenter = costCenter.split(' - ')[0];
            this.selectedImp = item.IMPORTATION_NUMBER;
            this.selectedTransactionReferenceDate = item.TRANSACTION_REFERENCE_DATE;
            this.selectedRecno = item.RECNO;
            this.selectedOriginalRecno = item.ORIGINAL_RECNO;
            this.selectedDebitCreditIndicator = item.DEBIT_CREDIT_INDICATOR;
            this.selectedCompanyCode = item.COMPANY_CODE;
            this.hadCommentChange
                ? this.getComments()
                : (this.currentComments = item.COMMENTS);
            this.openCommentsModal();
        }
    }

    private getComments() {
        this.asyncComments = true;
        this.commentService
            .getComments(
                this.selectedTransactionReferenceDate,
                this.selectedRecno,
                this.selectedOriginalRecno,
                this.selectedDebitCreditIndicator,
                this.selectedCompanyCode
            )
            .subscribe((comments) => {
                this.currentComments = comments;
                this.asyncComments = false;
            });
    }

    private formatImp(row, cell, value, columnDef, dataContext) {
        return `<span class='impValue'>${value}</span>`;
    }

    private formatComments(row, cell, value, columnDef, dataContext) {
        if (value.length === 0) {
            return `<span id="comment${dataContext.id}" class='ball'><i class="material-icons">create</i></span>`;
        } else {
            return `<span id="comment${dataContext.id}" class="ball comment"> <i ="material-icons"></i>${value.length}</span>`;
        }
    }

    private contComments() {
        const counterValue = $(`#comment${this.indexLine}`).text();
        const standardValue = 1;
        $(`#comment${this.indexLine}`).html(`${standardValue}`);
        if (counterValue === 'create') {
            $(`#comment${this.indexLine}`).removeClass('ball').addClass('ball comment');
        } else {
            $(`#comment${this.indexLine}`).html(`${parseInt(counterValue, 10) + 1}`);
        }
    }

    private createColumns() {
        const cols = [
            {
                id: 0,
                name: "<div id='comentarios'><i class='material-icons'>comment</i></div>",
                field: 'COMMENTS',
                width: 32,
                minWidth: 32,
                formatter: this.formatComments,
                cssClass: '',
                headerCssClass: '',
            },
            {
                id: 1,
                name: "<div id='imps' class='detailInput'>IMP<i class='fa fa-caret-down'></i></div>",
                field: 'IMPORTATION_NUMBER',
                width: 160,
                minWidth: 160,
                formatter: this.formatImp,
                cssClass: 'center-align impWidth',
                headerCssClass: 'impWidth',
            },
            {
                id: 2,
                name: "<div class='detailInput'>Valor</div>",
                field: 'ANALYTICAL_TRANSACTION_AMOUNT',
                width: 160,
                minWidth: 160,
                cssClass: 'amountValue amountWidth',
                headerCssClass: 'amountWidth',
            },
            {
                id: 3,
                name: "<div class='detailInput'>Data de Transação</div>",
                field: 'TRANSACTION_DATE',
                width: 190,
                minWidth: 190,
                cssClass: 'transactionDate amountDateWidth',
                headerCssClass: 'amountDateWidth',
            },
            {
                id: 4,
                name:
                    "<div id='clientGroups' class='detailInput'>Grupo de Clientes<i class='fa fa-caret-down'></i></div>",
                field: 'CLIENT_GROUP_NAME',
                width: 190,
                minWidth: 190,
                cssClass: 'clientGroupsWidth',
                headerCssClass: 'clientGroupsWidth',
            },
            {
                id: 5,
                name: "<div class='detailInput'>Empresa</div>",
                field: 'COMPANY_NAME',
                width: 160,
                minWidth: 160,
                cssClass: 'companiesWidth',
                headerCssClass: 'companiesWidth',
            },
            {
                id: 6,
                name: "<div class='detailInput'>Número do Lote</div>",
                field: 'ACCOUNTING_NUMBER_DESCRIPTION',
                width: 180,
                minWidth: 180,
                cssClass: 'lotNumber, accountingNumberWidth',
                headerCssClass: 'accountingNumberWidth',
            },
            {
                id: 7,
                name: "<div class='detailInput'>Tipo de Lançamento</div>",
                field: 'TRANSACTION_TYPE',
                width: 190,
                minWidth: 190,
                cssClass: 'transactionTypeWidth',
                headerCssClass: 'transactionTypeWidth',
            },
            {
                id: 8,
                name: "<div id='costCenters' class='detailInput'>Centro de Custo<i class='fa fa-caret-down'></i></div>",
                field: 'COST_CENTER_CODE',
                width: 180,
                minWidth: 180,
                cssClass: 'profitCentersWidth',
                headerCssClass: 'profitCentersWidth',
            },
            {
                id: 9,
                name: "<div class='detailInput'>Cliente</div>",
                field: 'COUPON_CUSTOMER_NAME',
                width: 180,
                minWidth: 180,
                cssClass: 'clientWidth',
                headerCssClass: 'clientWidth',
            },
            {
                id: 10,
                name: "<div class='detailInput'>Histórico</div>",
                width: 250,
                minWidth: 250,
                field: 'TRANSACTION_HISTORY_DESCRIPTION',
                cssClass: 'historicWidth',
                headerCssClass: 'historicWidth',
            },
            {
                id: 11,
                name: "<div class='detailInput'>Diretor</div>",
                field: 'DIRECTOR_NAME',
                width: 180,
                minWidth: 180,
                cssClass: 'directorWidth',
                headerCssClass: 'directorWidth',
            },
            {
                id: 12,
                name: "<div class='detailInput'>Trader</div>",
                field: 'TRADER_USER_NAME',
                width: 180,
                minWidth: 180,
                cssClass: 'traderWidth',
                headerCssClass: 'traderWidth',
            },
            {
                id: 13,
                name: "<div class='centralized'>Origem</div>",
                field: 'SOURCE_SYSTEM',
                width: 180,
                minWidth: 180,
                cssClass: 'directorWidth',
                headerCssClass: 'directorWidth',
            },
        ];

        if (this._verifyFullReport()) {
            cols.splice(6, 0, {
                id: 5.1,
                name: "<div id='vendorNames' class='detailInput'>Fornecedor<i class='fa fa-caret-down'></i></div>",
                field: 'VENDOR_NAME',
                width: 160,
                minWidth: 160,
                cssClass: 'vendorNamesWidth',
                headerCssClass: 'vendorNamesWidth',
            });
            cols.splice(7, 0, {
                id: 5.2,
                name:"<div id='users' class='detailInput'>Usuário<i class='fa fa-caret-down'></i></div>",
                field: 'USER',
                width: 160,
                minWidth: 160,
                cssClass: 'usersWidth',
                headerCssClass: 'usersWidth',
            },);
        }

        for (const col of cols) {
            const name = col.name;
            let newName;
            if (col.field.indexOf(this.orderBy) !== -1) {
                if (this.sort) {
                    newName = `<div class="headerItem"><i class='la la-arrow-down'></i> ${name}<div>`;
                } else {
                    newName = `<div class="headerItem"><i class='la la-arrow-up'></i> ${name}<div>`;
                }
            } else if (col.field !== 'COMMENTS') {
                newName = `<div class="headerItem"><i class='la default'>•</i> ${name}<div>`;
            } else {
                newName = `<div class="headerItem">${name}<div>`;
            }
            col.name = newName;
        }
        return cols;
    }

    private extractRows(data: any) {
        const rows = [];
        const length = 0;
        for (let i = 0; i < data.length; i++) {
            const accountingNumberDescription = data[i].accountingNumberDescription;
            let analyticalTransactionAmount = data[i].analyticalTransactionAmount;
            if (analyticalTransactionAmount !== 0) {
                if (parseFloat(analyticalTransactionAmount) < 0) {
                    analyticalTransactionAmount = analyticalTransactionAmount.toString().replace('-', '');
                    analyticalTransactionAmount = parseFloat(analyticalTransactionAmount).toFixed(2).split('.');
                    analyticalTransactionAmount[0] = analyticalTransactionAmount[0].split(/(?=(?:...)*$)/).join('.');
                    analyticalTransactionAmount = `-${analyticalTransactionAmount}`;
                } else {
                    analyticalTransactionAmount = analyticalTransactionAmount.toFixed(2).split('.');
                    analyticalTransactionAmount[0] = analyticalTransactionAmount[0].split(/(?=(?:...)*$)/).join('.');
                }
            }
            const comments = data[i].analyticalComments;
            const clientGroupName = data[i].clientGroupName;
            const companyName = data[i].companyName;
            const costCenterCode = `${data[i].costCenterCode} - ${data[i].costCenterName}`;
            const couponCustomerName = data[i].couponCustomerName;
            const directorName = data[i].directorName;
            const importationNumber = data[i].importationNumber;
            const transactionReferenceDate = data[i].transactionReferenceDate;
            const recno = data[i].recno;
            const originalRecno = data[i].originalRecno;
            const debitCreditIndicator = data[i].debitCreditIndicator;
            const companyCode = data[i].companyCode;
            const traderUserName = data[i].traderUserName;
            let transactionDate = data[i].transactionDate;
            const history = data[i].transactionHistoryDescription;
            const transactionType = data[i].transactionType;
            const splitDate = transactionDate.split('-');
            const dataOriginDescription = data[i].dataOriginDescription;
            transactionDate = `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;
            const vendorName = data[i].vendorName;
            const user = data[i].user;
            rows.push({
                id: i.toString(),
                COMMENTS: comments,
                ACCOUNTING_NUMBER_DESCRIPTION: accountingNumberDescription,
                ANALYTICAL_TRANSACTION_AMOUNT: analyticalTransactionAmount.toString(),
                CLIENT_GROUP_NAME: clientGroupName,
                COMPANY_NAME: companyName,
                COST_CENTER_CODE: costCenterCode,
                COUPON_CUSTOMER_NAME: couponCustomerName,
                DIRECTOR_NAME: directorName,
                IMPORTATION_NUMBER: importationNumber,
                TRADER_USER_NAME: traderUserName,
                TRANSACTION_DATE: transactionDate,
                TRANSACTION_HISTORY_DESCRIPTION: history,
                TRANSACTION_TYPE: transactionType,
                SOURCE_SYSTEM: dataOriginDescription,
                TRANSACTION_REFERENCE_DATE: transactionReferenceDate,
                RECNO: recno,
                ORIGINAL_RECNO: originalRecno,
                DEBIT_CREDIT_INDICATOR: debitCreditIndicator,
                COMPANY_CODE: companyCode,
                VENDOR_NAME: vendorName,
                USER: user,
            });
        }
        return rows;
    }

    private filter(item) {
        if (item.parent != null) {
            let parent = this.rows[item.parent];
            while (parent) {
                if (parent._collapsed) {
                    return false;
                }
                parent = this.rows[parent.parent];
            }
        }
        return true;
    }

    private getReportType(url) {
        const urlSplited = url.split('/');
        return urlSplited[urlSplited.length - 1];
    }

    private getFilter(turn) {
        const financeiroFilterState = localStorage.getItem('financeiro-filterState');
        const isDataPresent = financeiroFilterState !== null && financeiroFilterState !== undefined;
        const isByImpView = this.reportType === 'byImp';
        const isFullReport: boolean = this._verifyFullReport();

        // Caso tenha acessado através da tela de filtro por imp, deverá usar o filter baseado no localstorage
        if (isDataPresent && isByImpView) {
            this.getLocalStorageData();

            const filterByImp = this.filterState.getCurrent();
            const { col, row, content } = $('#contextMenu').data('cell');
            filterByImp.account = [row];
            filterByImp.imps = this.financeiroFilterState.imps;
            filterByImp.companies = this.financeiroFilterState.companies;
            filterByImp.currency = this.financeiroFilterState.currency;

            filterByImp.startDate = moment(this.financeiroFilterState.startDate, 'YYYY-MM')
                .add(-1, 'M')
                .add(1, 'days')
                .format('YYYY-MM-DD');

            filterByImp.endDate = moment(this.financeiroFilterState.endDate, 'YYYY-MM')
                .add(1, 'M')
                .add(-1, 'days')
                .format('YYYY-MM-DD');

            const dataByImp = {
                hierarchyType: this.financeiroHierarchyState,
                grouping: this.financeiroGroupingState,
                filter: filterByImp.toServiceFilter(),
                orderBy: {
                    column: this.orderBy,
                    direction: this.sort ? 'ASC' : 'DESC',
                },
                offset: this.offset * 50,
                limit: 50,
            };

            const splitedStartDate = dataByImp.filter.startDate.split('/');
            const splitedEndDate = dataByImp.filter.endDate.split('/');
            dataByImp.filter.startDate = `${splitedStartDate[2]}-${splitedStartDate[1]}-${splitedStartDate[0]}`;
            dataByImp.filter.endDate = `${splitedEndDate[2]}-${splitedEndDate[1]}-${splitedEndDate[0]}`;

            return dataByImp;
        }

        // Reseta os campos selecionados na primeira chamada.
        if (turn === 'first') {
            this.selectedOptions = {
                imps: [],
                clientGroups: [],
                costCenters: [],
                vendorNames: [],
                users: [],
            };
            this.requestFilters = {
                imps: [],
                clientGroups: [],
                costCenters: [],
                vendorNames: [],
                users: [],
            };
        }
        const { col, row, content } = $('#contextMenu').data('cell');
        const groupingKey = [{ id: col.id.toString(), itemName: col.name.toString() }];

        const filter = this.filterState.getCurrent();
        filter.account = [row];

        switch (this.groupingState.getCurrent()) {
            case 'byCompany':
                filter.companies = groupingKey;
                break;
            case 'byMonth':
                let date = groupingKey[0].id;
                if (date.includes('T')) {
                    this.handleTrimesterPeriod(date, filter);
                } else if (date.includes('S')) {
                    this.handleSemesterPeriod(date, filter);
                } else {
                    const year = date.substr(0, 4);
                    const month = date.substr(4, 2);
                    date = `${year}-${month}`;
                    date = moment(date).locale('pt-br').format('YYYY-MM-DD');
                    const endDate = moment(date).add('1', 'M').add('-1', 'days').format('YYYY-MM-DD');
                    this.startDate = filter.startDate = date;
                    this.endDate = filter.endDate = endDate;
                }
                break;
            case 'byCr':
                filter.profitCenter = groupingKey;
                break;
            case 'byTrader':
                filter.trader = groupingKey;
                break;
            case 'byClient':
                filter.client = groupingKey;
                break;
            case 'byDirector':
                filter.director = groupingKey;
                break;
            case 'byImp':
                filter.imps = [groupingKey[0].id];
                break;
        }

        const data = {
            hierarchyType: this.hierarchyState.getCurrent(),
            grouping: this.groupingState.getCurrent(),
            filter: filter.toServiceFilter(),
            orderBy: {
                column: this.orderBy,
                direction: this.sort ? 'ASC' : 'DESC',
            },
            offset: this.offset * 50,
            limit: 50,
            isFullReport,
        };
        if (
            data.hierarchyType === 'balance-sheet' &&
            (data.filter.accountingCode[0].toString().charAt(0) === '1' ||
                data.filter.accountingCode[0].toString().charAt(0) === '2')
        ) {
            this.commentHistory = true;
        } else {
            this.commentHistory = false;
        }
        if (this.groupingState.getCurrent() === 'byMonth') {
            data.filter.startDate = this.startDate;
            data.filter.endDate = this.endDate;
        } else {
            const splitedStartDate = data.filter.startDate.split('/');
            const splitedEndDate = data.filter.endDate.split('/');
            data.filter.startDate = `${splitedStartDate[2]}-${splitedStartDate[1]}-${splitedStartDate[0]}`;
            data.filter.endDate = `${splitedEndDate[2]}-${splitedEndDate[1]}-${splitedEndDate[0]}`;
        }
        // Aplica os filtros selecionados caso não seja a primeira chamada
        if (turn !== 'first') {
            const newFilter = data.filter.clone();
            if (this.groupingState.getCurrent() !== 'byClient') {
                newFilter.clientGroups = this.requestFilters.clientGroups.length ?
                this.requestFilters.clientGroups : newFilter.clientGroups;
            }
            const imps = 'imps';
            newFilter[imps] = this.requestFilters.imps.length ? this.requestFilters.imps :
                this.groupingState.getCurrent() === 'byImp' ? data.filter.imps : [];
            newFilter.costCenters = this.requestFilters.costCenters;
            newFilter.vendorNames = this.requestFilters.vendorNames;
            newFilter.users = this.requestFilters.users;
            if (!_.isEqual(newFilter, data.filter) && this.pagechanged === false) {
                this.resetPages();
                data.offset = 0;
                data.filter = _.cloneDeep(newFilter);
            } else if (!_.isEqual(newFilter, data.filter)) {
                data.filter = _.cloneDeep(newFilter);
            }
            this.pagechanged = false;
        }
        return data;
    }

    private getLocalStorageData() {
        const currentImpCode = this.route.snapshot.params.impCode;
        this.financeiroFilterState = JSON.parse(localStorage.getItem(`${currentImpCode}-financeiro-filterState`));
        this.financeiroGroupingState = JSON.parse(localStorage.getItem(`${currentImpCode}-financeiro-groupingState`));
        this.financeiroHierarchyState = JSON.parse(localStorage.getItem(`${currentImpCode}-financeiro-hierarchyState`));
    }

    private handleTrimesterPeriod(date: string, filter: FilterSelection) {
        const sliptedDate = date.split('T');
        const year = sliptedDate[0];
        const num = sliptedDate[1];
        let startMonth = '01';
        let endMonth = '04';
        switch (num) {
            case '1':
                break;
            case '2':
                startMonth = '04';
                endMonth = '07';
                break;
            case '3':
                startMonth = '07';
                endMonth = '10';
                break;
            case '4':
                startMonth = '10';
                endMonth = '12';
                break;
        }
        startMonth = moment(`${startMonth}/01/${year}`).format('YYYY-MM-DD');
        if (num === '4') {
            endMonth = moment(`${endMonth}-31-${year}`).format('YYYY-MM-DD');
        } else {
            endMonth = moment(`${endMonth}/01/${year}`).add('-1', 'days').format('YYYY-MM-DD');
        }
        this.startDate = startMonth;
        this.endDate = endMonth;
    }

    private resetPages() {
        this.queryId = Math.random();
        this.offset = 0;
    }

    private handleSemesterPeriod(date: string, filter: FilterSelection) {
        const sliptedDate = date.split('S');
        const year = sliptedDate[0];
        const num = sliptedDate[1];
        let startMonth;
        let endMonth;

        switch (num) {
            case '1':
                startMonth = moment(`01/01/${year}`).format('YYYY-MM-DD');
                endMonth = moment(`07/01/${year}`).add('-1', 'days').format('YYYY-MM-DD');
                break;
            case '2':
                startMonth = moment(`07/01/${year}`).format('YYYY-MM-DD');
                endMonth = moment(`12/31/${year}`).format('YYYY-MM-DD');
                break;
        }

        this.startDate = startMonth;
        this.endDate = endMonth;
    }

    private toDropdownItems(items: any[], includeCode = true) {
        return items != null ? items.map(includeCode ? this.toDropdownItemWithCode : this.toDropdownItem) : [];
    }

    private toDropdownItemWithCode(e: any): DropdownOption {
        return new DropdownOption(e.code, `${e.code} - ${e.name}`);
    }

    private toDropdownItem(e: any): DropdownOption {
        return new DropdownOption(e.code, `${e.name}`);
    }

    private _verifyFullReport(): boolean {
        return this.rowGroup.includes('COMERCIAIS') || this.rowGroup.includes('PESSOAL')
        || this.rowGroup.includes('ADMINISTRATIVAS');
    }
}
