<div class="pwd-reset-area" *ngIf="initialized">
    <div class="pwd-reset-container">
        <div class="logo-container">
            <img [src]="logoLogin" />
        </div>
        <div class="form-container">
            <div class="form-fields">
                <span *ngIf="expiredPassword && !updated" class="oldPassword">Sua senha expirou, por favor digite uma nova senha</span>
                <span *ngIf="isValidCode && !updated && !expiredPassword" class="pwd-reset-title">Por favor digite uma senha para {{ model.email }}</span>
                <span class="throw-error-message" [innerHTML]="errorPasswordReset"></span>
                <span class="pwd-reset-msg" [innerHTML]="msgPasswordReset"></span>
                <div class="input-container" *ngIf="isValidCode && !updated && expiredPassword">
                    <input
                        (focus)="focusHit = true"
                        (blur)="focusHit = false"
                        style="width: 300px; margin-bottom: 15px;"
                        [type]="oldPasswordVisible ? 'text' : 'password'"
                        placeholder="Senha atual"
                        name="password"
                        [(ngModel)]="model.oldPassword"
                    />
                    <span class="eyeicon" (click)="oldPasswordVisible = !oldPasswordVisible"><i [ngClass]="oldPasswordVisible ? 'fa fa-eye-slash' : 'fa fa-eye' "></i></span>
                </div>
                <div class="input-container"  *ngIf="isValidCode && !updated">
                    <input
                        (focus)="focusHit = true"
                        (blur)="focusHit = false"
                        (input)="checkPassword($event)"
                        style="width: 300px; margin-bottom: 15px;"
                        [type]="firstPasswordVisible?'password':'text'"
                        placeholder="Nova senha"
                        name="password"
                        [(ngModel)]="model.password"
                    />
                    <span class="eyeicon" (click)="changePasswordVisibility(true)"><i [ngClass]="firstPasswordVisible?'fa fa-eye':'fa fa-eye-slash'"></i></span>
                </div>
                <div class="input-container" *ngIf="isValidCode && !updated">
                    <input
                        (focus)="focusHit = true"
                        (blur)="focusHit = false"
                        style="width: 300px; margin-bottom: 15px;"
                        [type]="secondPasswordVisible?'password':'text'"
                        [disabled]="!model.password"
                        placeholder="Confirmar Senha"
                        name="passwordConfirmation"
                        [(ngModel)]="model.passwordConfirmation"
                    />
                    <span class="eyeicon" (click)="changePasswordVisibility(false)"><i [ngClass]="secondPasswordVisible?'fa fa-eye':'fa fa-eye-slash'"></i></span>
                </div>
                <div class="password-hits" *ngIf="isValidCode && !updated">
                    <ul *ngIf="isValidCode" [ngClass]="{'hit-password': focusHit}">
                        <li [ngClass]="{'danger-color': !checkNumbersCharsPassword, 'success-color': checkNumbersCharsPassword}"><i class="fa fa-times" *ngIf="!checkNumbersCharsPassword"></i><i class="fa fa-check" *ngIf="checkNumbersCharsPassword"></i> Deve conter número, letras minúscula e maiúscula</li>
                        <li [ngClass]="{'danger-color': !checkSpecialCharPassword, 'success-color': checkSpecialCharPassword}"><i class="fa fa-times" *ngIf="!checkSpecialCharPassword"></i><i class="fa fa-check" *ngIf="checkSpecialCharPassword"></i> Pelo menos um caracter especial: !@#$%&</li>
                        <li [ngClass]="{'danger-color': !checkTotalPassword, 'success-color': checkTotalPassword}"><i class="fa fa-times" *ngIf="!checkTotalPassword"></i><i class="fa fa-check" *ngIf="checkTotalPassword"></i> Mínimo de 8 caracteres</li>
                        <div *ngIf="samePasswords()">
                            <li [ngClass]="'danger-color'"><i class="fa fa-times"></i> As senhas não conferem</li>
                        </div>
                    </ul>
                </div>
                <div style="margin-bottom: 15px;" *ngIf="isValidCode && !updated && expiredPassword && captchaEnable">
                    <p-captcha #captcha [siteKey]="captchaKey" (onResponse)="validateCaptcha($event)" (onExpire)="resetCaptcha()" *ngIf="showCaptcha"></p-captcha>
                </div>
                <button
                    *ngIf="isValidCode && !updated"
                    class="button-submit"
                    type="submit"
                    [disabled]="loading || !passwordAccept"
                    (click)="confirmPasswordReset()"
                    >Confirmar</button
                >
                <button
                    class="button-submit resend disabled"
                    [ngClass]="{ disabled: resendDisabled === true }"
                    *ngIf="!isValidCode && !resendDisabled"
                    type="submit"
                    [disabled]="loading"
                    (click)="resendLink()"
                    >Reenviar link de {{ firstAccess ? 'primeiro' : ''}} acesso</button
                >
                <button *ngIf="updated && !expiredPassword" class="button-submit" [disabled]="loading || !passwordAccept" (click)="continue()">Continuar</button>
                <button type="button" class="button-back" [disabled]="loading" (click)="goToLogin()">Voltar para tela de login</button>
            </div>
            <div *ngIf="asyncLogin">
                <div class="loaderModel4" style="position: relative; margin-top: 30px">
                    <div class="cube cube1"></div>
                    <div class="cube cube2"></div>
                    <div class="cube cube3"></div>
                    <div class="cube cube4"></div>
                    <div class="cube cube5"></div>
                    <div class="cube cube6"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-container" [ngStyle]="{'background-position': client === 'bi' ? 'left center' : 'right center'}" style="background-position: right center" [style.background-image]="backLogin"></div>
</div>
