<div class="m-subheader">
    <div class="d-flex align-items-center">
        <div class="mr-auto">

            <h3 class="m-subheader__title m-subheader__title--separator">
                SLAs
            </h3>

            <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                <li class="m-nav__item m-nav__item--home">
                    <a href="#" class="m-nav__link m-nav__link--icon">
                        <i class="m-nav__link-icon la la-home"></i>
                    </a>
                </li>
                <li class="m-nav__separator">
                    -
                </li>
                <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                        <span class="m-nav__link-text">
                            Supply Chain
                        </span>
                    </a>
                </li>
                <li class="m-nav__separator">
                    -
                </li>
                <li class="m-nav__item">
                    <a href="/supply-chain/slas" class="m-nav__link">
                        <span class="m-nav__link-text">
                            SLAs
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="m-content">
    <div class="m-portlet">
        <div class="m-portlet__head">
            <div class="m-portlet__head-tools">
                <ul class="nav nav-pills nav-pills--brand m-nav-pills--align-right m-nav-pills--btn-pill m-nav-pills--btn-sm" role="tablist">
                    <li class="nav-item m-tabs__item">
                        <a class="nav-link m-tabs__link active" (click)="resetGraph($event)" data-toggle="tab" href="#m_widget2_tab1_content" role="tab">
                            Chegada x Deferimento LI
                        </a>
                    </li>
                    <li class="nav-item m-tabs__item">
                        <a class="nav-link m-tabs__link" (click)="resetGraph($event)" data-toggle="tab" href="#m_widget2_tab2_content1" role="tab">
                            Deferimento LI x Registro DI
                        </a>
                    </li>
                    <li class="nav-item m-tabs__item">
                        <a class="nav-link m-tabs__link" (click)="resetGraph($event)" data-toggle="tab" href="#m_widget2_tab3_content1" role="tab">
                            Faturamento x Entrega
                        </a>
                    </li>
                    <li class="nav-item m-tabs__item">
                        <a class="nav-link m-tabs__link" (click)="resetGraph($event)" data-toggle="tab" href="#m_widget2_tab3_content1" role="tab">
                            Presença Carga x Registro DI
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="m-portlet__body">
            <div id="slas" style="height: 500px"></div>
        </div>
    </div>
</div>