
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as _ from 'lodash';

import { Observable } from 'rxjs';

import { environment } from '#environment';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';

@Injectable()
export class ChassiStatusService {

    dialogType: string = 'Avarias';
    constructor(
        private _http: HttpClient,
        private _customDialogService: CustomDialogService,
        ) { }

    // -----------------------------------------------------------------------------------------------------------------
    // Public Methods
    // -----------------------------------------------------------------------------------------------------------------

    getChassiStatusList(request: any): Observable<any> {

        return this._http.post(`${environment.endpoints.automotiveV2QueryService}/api/v2/chassis-status/count`, request.params, {
            headers: new HttpHeaders().set('X-Requested-Client-Groups', request.headers.clientCodes),
        }).pipe(
            map((chassiStatusList: any) => {
                const status = chassiStatusList && chassiStatusList.chassisStatus;

                return _.reduce(status, (prev, curr) => {
                    return [...prev, _.omit(_.merge(curr, curr.status), 'status')]
                }, []);
            }));
    }

    openModal(type) {
        this.dialogType = type;
        this._customDialogService.open('chassi-status-input-modal');
    }
}
