import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MaskDataInterceptor implements HttpInterceptor {

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (window.location.href.includes('demo.comexport.com.br') || window.location.href.includes('ford.comexport.com.br')) {
      const customReq = request.clone({});
      return next.handle(customReq).pipe(map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body instanceof Array) {
            this.maskArray(event.body);
          } else if (event.body instanceof Object) {
            this.maskSingleObject(event.body, '');
          }
        }
        return event;
      }));
    } else {
      return next.handle(request.clone({}));
    }
  }

  private maskArray(arr: any[]) {
    arr.forEach((element) => {
      const keys = Object.keys(element);
      keys.forEach((k) => {
        if (mapMaskedValue.get(k) != null && !(element[k] instanceof Array || element[k] instanceof Object)) {
          element[k] = this.getRandomPosition(mapMaskedValue.get(k));
        }

        if (element[k] instanceof Array) {
          element[k].forEach((cWhitoutName) => {
            if (cWhitoutName instanceof Array) {
              this.maskArray(cWhitoutName);
            } else {
              this.maskSingleObject(cWhitoutName, k);
            }
          });

          this.maskArray(element[k]);
        } else if (element[k] instanceof Object || element[k] instanceof String) {
          this.maskSingleObject(element[k], k);
        }
      });
    });
  }

  private maskSingleObject(obj: any, nodeParent: string) {
    const keyContentObj = Object.keys(obj);

    keyContentObj.forEach((kContent) => {
      let parentAndChild = '';
      if (nodeParent !== '') {
        parentAndChild = nodeParent + '.' + kContent;
      } else {
        parentAndChild = kContent;
      }

      if (obj[kContent] instanceof Array) {
        this.maskArray(obj[kContent]);
      } else if (obj[kContent] instanceof Object) {
          if (mapMaskedValue.get(parentAndChild) != null) {
            obj[kContent] = this.getRandomPosition(mapMaskedValue.get(parentAndChild));
          }
          this.maskSingleObject(obj[kContent], kContent);
      } else if (obj[kContent] instanceof String && mapMaskedValue.get(parentAndChild) != null) {
          obj[kContent] = this.getRandomPosition(mapMaskedValue.get(parentAndChild));
      } else {
        if (mapMaskedValue.get(parentAndChild) != null) {
          obj[kContent] = this.getRandomPosition(mapMaskedValue.get(parentAndChild));
        }
      }
    });
  }

  private getRandomPosition(arrMaskedValues: any[]): string {
    if (arrMaskedValues === undefined || arrMaskedValues === null || arrMaskedValues.length === 0) {
      return '********';
    }

    return arrMaskedValues[Math.floor(Math.random() * arrMaskedValues.length)];
  }
}

const mapMaskedValue = new Map<string, any[]>();
mapMaskedValue.set('chassisNumber', ['C0M3XP0RTP7700987', 'C0M3XP0RTP7709999', 'C0M3XP0RTP7701234']);
mapMaskedValue.set('chassisNumber', ['C0M3XP0RTP7700987', 'C0M3XP0RTP7709999', 'C0M3XP0RTP7701234']);
mapMaskedValue.set('chassi', ['C0M3XP0RTP7700987', 'C0M3XP0RTP7709999', 'C0M3XP0RTP7701234']);
mapMaskedValue.set('chassis', ['C0M3XP0RTP7700987', 'C0M3XP0RTP7709999', 'C0M3XP0RTP7701234']);
mapMaskedValue.set('clientName', ['COMEXPORT SISTEMAS', 'COMEXPORT PRODUTOS', 'COMEXPORT PEÇAS']);
mapMaskedValue.set('clientCodes.name', ['COMEXPORT SISTEMAS', 'COMEXPORT PRODUTOS', 'COMEXPORT PEÇAS']);
mapMaskedValue.set('client.name', ['COMEXPORT SISTEMAS', 'COMEXPORT PRODUTOS', 'COMEXPORT PEÇAS']);
mapMaskedValue.set('client', ['COMEXPORT SISTEMAS', 'COMEXPORT PRODUTOS', 'COMEXPORT PEÇAS']);
mapMaskedValue.set('vendor.name', ['FORNECEDOR C0M3X', 'FORNECEDOR TR4D1N9', 'FORNECEDOR N390C10S']);
mapMaskedValue.set('vendor', ['FORNECEDOR C0M3X', 'FORNECEDOR TR4D1N9', 'FORNECEDOR N390C10S']);
mapMaskedValue.set('vendorName', ['FORNECEDOR C0M3X', 'FORNECEDOR TR4D1N9', 'FORNECEDOR N390C10S']);
mapMaskedValue.set('manufacturers.name', ['FABRICANTE C0M3X', 'FABRICANTE TR4D1N9', 'FABRICANTE N390C10S']);
mapMaskedValue.set('manufacturer.name', ['FABRICANTE C0M3X', 'FABRICANTE TR4D1N9', 'FABRICANTE N390C10S']);
mapMaskedValue.set('chassi.model', ['COMEX XZ4 MANUAL', 'COMEX ZZ8 2.0 TURBO', 'COMEX XZ4 2.0 AUT.']);
mapMaskedValue.set('model', ['COMEX XZ4 MANUAL', 'COMEX ZZ8 2.0 TURBO', 'COMEX XZ4 2.0 AUT.']);
mapMaskedValue.set('chassi.color', ['PRETO', 'BRANCO', 'VERMELHO', 'AZUL']);
mapMaskedValue.set('color', ['PRETO', 'BRANCO', 'VERMELHO', 'AZUL']);
mapMaskedValue.set('chassi.engineNumber', ['C0M3XP0RTP7700987', 'C0M3XP0RTP7709999', 'C0M3XP0RTP7701234']);
mapMaskedValue.set('engineNumber', ['C0M3XP0RTP7700987', 'C0M3XP0RTP7709999', 'C0M3XP0RTP7701234']);
mapMaskedValue.set('clientReference', ['REF-X014', 'REF-X099', 'REF-X000']);
mapMaskedValue.set('description', ['PRODUTO C0MEX014', 'PRODUTO C0MEXAA01', 'PRODUTO C0MEX999']);
mapMaskedValue.set('items.description', ['PRODUTO C0MEX014', 'PRODUTO C0MEXAA01', 'PRODUTO C0MEX999']);
mapMaskedValue.set('dealer.name', ['LOJA 01 – SAO PAULO', 'LOJA 01 – BRASILIA', 'LOJA 01 – VITORIA']);
mapMaskedValue.set('dealer.documentNumber', ['CXPOR00924', 'CXPOR00925', 'CXPOR00926']);
mapMaskedValue.set('liNumber', ['1234569-04', '1234569-45', '1234569-05']);
mapMaskedValue.set('dtaNumber', ['9999354', '9999855', '9999699']);
mapMaskedValue.set('documentDeliveries.dtaNumber', ['9999354', '9999855', '9999699']);
mapMaskedValue.set('cargoPresenceMasterNumber', ['XXX99045689', 'HXQ99045689', 'C0M3X99045689']);
mapMaskedValue.set('containerNumber', ['CTN003666985986', 'CTN003666984445', 'CTN003666983648']);
mapMaskedValue.set('shippingCompanyDescription', ['CHINA VESSEL X001', 'CHINA VESSEL X001', 'CHINA VESSEL X003']);
mapMaskedValue.set('observation.message', ['Sua mercadoria está a caminho sem problemas mapeados',
'Registro da LI concluido com sucesso', 'Mercadoria em processo de desembaraço']);
mapMaskedValue.set('trackingNumber.orderNumber', ['5454547878784', '5454533569779', '5454547778878']);
mapMaskedValue.set('notes', ['Aqui serão mostrados todas as anotações do seu processo, etapa por etapa.']);
mapMaskedValue.set('children.name', ['COMEXPORT SISTEMAS', 'COMEXPORT PRODUTOS', 'COMEXPORT PEÇAS']);
mapMaskedValue.set('options.client', ['MASK']);
mapMaskedValue.set('content.name', ['COMEXPORT SISTEMAS', 'COMEXPORT PRODUTOS', 'COMEXPORT PEÇAS']);
mapMaskedValue.set('customsClearance.diNumber', ['9993334410']);
mapMaskedValue.set('customsClearance.cargoPresence', ['COMEXPORT30301010']);
mapMaskedValue.set('customsClearance.masterNumber', ['999102277889910']);
mapMaskedValue.set('invoiceNumber', ['001122-00', '003287-00', '001000-00', '003209-00', '005472-00']);
mapMaskedValue.set('customsClearance.trackingNumber', ['CMXBIWW200042', 'CMXBIWW200063',
'CMXBIWW200027', 'CMXBIWW200015']);
mapMaskedValue.set('billOfLading', ['CMXBIWW200042', 'CMXBIWW200063',
'CMXBIWW200027', 'CMXBIWW200015']);
mapMaskedValue.set('customsClearance.observation', ['14/02 AG CHEGADA EM VIX  17/02  DTC  17/0077998-0',
'20/02 AG CHEGADA EM VIX  17/02  DTC  17/0077998-0']);
mapMaskedValue.set('message', ['AGUARDANDO PRONTIDÃO DE MERCADORIA',
'AGUARDANDO PRONTIDÃO DE MERCADORIA', 'AGUARDANDO APROVAÇÃO DE DRAFTS']),
mapMaskedValue.set('customsClearance.dtaNumber', ['9999354', '9999855', '9999699']),
mapMaskedValue.set('consolidatedImp.clientName', ['COMEXPORT SISTEMAS', 'COMEXPORT PRODUTOS', 'COMEXPORT PEÇAS']),
mapMaskedValue.set('skuInvoiceNumber', ['001122-00', '003287-00', '001000-00', '003209-00', '005472-00']),
mapMaskedValue.set('skuManufacturerName', ['FABRICANTE C0M3X', 'FABRICANTE TR4D1N9', 'FABRICANTE N390C10S']),
mapMaskedValue.set('manufacturerName', ['FABRICANTE C0M3X', 'FABRICANTE TR4D1N9', 'FABRICANTE N390C10S']),
mapMaskedValue.set('orders.clientReference', ['REF-X014', 'REF-X099', 'REF-X000']),
mapMaskedValue.set('consolidatedImp.description', ['PRODUTO C0MEX014', 'PRODUTO C0MEXAA01', 'PRODUTO C0MEX999']),
mapMaskedValue.set('consolidatedImp.description', ['PRODUTO C0MEX014', 'PRODUTO C0MEXAA01', 'PRODUTO C0MEX999']),
mapMaskedValue.set('entryInvoices.invoiceNumber', ['001122-00', '003287-00', '001000-00', '003209-00', '005472-00']);
