import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';

export const CHASSI_COLUMNS: IGridColumn[] = [
        {field: 'chassiChassisNumber', label: 'CHASSI', name: 'CHASSI', width: 200, groupName: 'Chassi', formatterType: 'multiple'}, 
        {field: 'chassiCheckoutDate', label: 'Data Saída', name: 'Data Saída', width: 120, groupName: 'Chassi', formatterType: 'multiple'}, 
        {field: 'chassiVehicleType', label: 'Tipo', name: 'Tipo', width: 120, groupName: 'Chassi', formatterType: 'multiple'}, 
        {field: 'chassiChassiModel', label: 'Modelo Veículo', name: 'Modelo Veículo', width: 200, groupName: 'Chassi', formatterType: 'multiple'}, 
        {field: 'chassiChassiColor', label: 'Cor Veículo', name: 'Cor Veículo', width: 200, groupName: 'Chassi', formatterType: 'multiple'}, 
        {field: 'chassiModelYear', label: 'Ano Modelo', name: 'Ano Modelo', width: 120, groupName: 'Chassi', formatterType: 'multiple'}, 
        {field: 'chassiManufacturerYear', label: 'Ano Fabricação', name: 'Ano Fabricação', width: 120, groupName: 'Chassi', formatterType: 'multiple'}, 
        {field: 'chassiEngineNumber', label: 'Motor', name: 'Motor', width: 200, groupName: 'Chassi', formatterType: 'multiple'}, 
        {field: 'chassiBlockSituation', label: 'Bloqueio', name: 'Bloqueio', width: 120, groupName: 'Chassi', formatterType: 'multiple'},
];
