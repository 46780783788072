<div class="cmx-switch-section">
    <div class="switch-section-container">
        <p-inputSwitch
            [ngModel]="enabled"
            (onChange)="onChangeSwitch()"
        ></p-inputSwitch>
        <span class="switch-name">{{ switchName }}</span>
        <hr style="width: -webkit-fill-available">
    </div>
    <div *ngIf="enabled" class="switch-section-content">
        <div *ngIf="linkUrl" (click)="copyToClipboard()" class="link-container">
            <input id="cmx-switch-section-link" [(ngModel)]="linkUrl" class="link">
            <span class="material-icons">content_copy</span>
        </div>
        <div *ngIf="messages.length" class="message-container">
            <span *ngIf="messagesIcon" class="material-icons" [style.color]="messageIconColor">
                {{ messagesIcon }}
            </span>
            <div class="messages">
                <span *ngFor="let message of messages" class="message {{ message?.classes }}">{{ message?.message }}</span>
            </div>
        </div>
        <div *ngIf="actionButton" class="action-button-container">
            <cmx-button
                size="xs"
                width="125px"
                (click)="actionClick()">
                {{ actionButton }}
            </cmx-button>
        </div>
    </div>
</div>
