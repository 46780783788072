import { IGridColumn } from "app/shared/components/cmx-custom-grid/interfaces";

export const SP_DOCUMENTS_COLUMNS: IGridColumn[] = [
    {
        field: 'type',
        name: 'Tipo',
        width: 70,
    },
    {
        field: 'aliasDocumentName',
        name: 'Nome',
        width: 140,
    },
    {
        field: 'download',
        name: '<span class="material-icons documents">download</span>',
        formatterType: 'round',
        formatterData: {roundType: 'download'},
        width: 48,
    },
    {
        field: 'view',
        name: '<span class="material-icons documents">visibility</span>',
        formatterType: 'round',
        formatterData: {roundType: 'view'},
        width: 48,
    },
]
