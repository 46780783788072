<div *ngIf="!asyncDetail" class="fullLoader">
    <loader></loader>
</div>

<div id="import-details-imp-container" *ngIf="asyncDetail">
    <div id="import-details-imp-content-error" *ngIf="!dataLoaded">
        <span class="noContent">IMP não encontrada</span>
    </div>
    <div id="import-details-imp-content" *ngIf="dataLoaded">
        <cmx-assembled-data [data]="impDetails" [maxRowElements]="4"></cmx-assembled-data>

        <div id="import-details-imp-orders-content" *ngIf="ordersList.length">
            <cmx-custom-grid
                [id]="'import-details-imp-orders-grid'"
                [columns]="gridOrderColumns"
                [rows]="ordersList"
                [options]="{ forceFitColumns: true }"
                [styles]="{ 'max-height': 'calc(100vh - 330px)'}"
                (onCellClick)="onCellClick($event)"
            ></cmx-custom-grid>
        </div>
    </div>
</div>

<cmx-custom-dialog
    [id]="'import-details-imp-po-dialog'"
    [title]="'P.O • ' + poNumber"
    animation="left-right"
    [internalFade]="true"
    [closeButton]="true"
    [overlay]="true"
    [widthModal]="45"
    (modal-closed)="showPOModal = false">
    <ng-container cmx-custom-dialog-body>
        <div class="import-details-imp-po-dialog-container" *ngIf="poDetails && showPOModal">
            <cmx-assembled-data [data]="poDetails" [maxRowElements]="2"></cmx-assembled-data>

            <div id="import-details-imp-po-dialog-manufacturers-content" *ngIf="manufecturersList.length > 1">
                <hr/>
                <cmx-custom-grid
                    [id]="'import-details-imp-po-dialog-manufacturers-grid'"
                    [columns]="gridManufecturersColumns"
                    [rows]="manufecturersList"
                    [options]="{ forceFitColumns: true }"
                    [styles]="{ 'max-height': 'calc(100vh - 600px)'}"
                ></cmx-custom-grid>
            </div>
        </div>
    </ng-container>
</cmx-custom-dialog>
