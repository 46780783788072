import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MaskDataCustomInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if ( (window.location.href.includes('demo.comexport.com.br') || window.location.href.includes('ford.comexport.com.br')) 
    && (request.url.includes('/api/domain')
    || request.url.includes('/api/traceability') || request.url.includes('/api/importation/stats/details/imp')
    || request.url.includes('/api/automotive-query/stats/operational/details')
    || request.url.includes('/api/v2/automotive-query/api/v2/stats/operational/details')
    || request.url.includes('/api/importation/operational-alerts/type/'))) {
      const customReq = request.clone({});
      return next.handle(customReq).pipe(map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body instanceof Array) {
            this.maskArray(event.body);
          } else if (event.body instanceof Object) {
            this.maskSingleObject(event.body, '');
          }
        }
        return event;
      }));
    } else {
      return next.handle(request.clone({}));
    }
  }

  private maskArray(arr: any[]) {
    arr.forEach((element) => {
      const keys = Object.keys(element);
      keys.forEach((k) => {
        if (mapMaskedValue.get(k) != null && !(element[k] instanceof Array || element[k] instanceof Object)) {
          element[k] = this.getRandomPosition(mapMaskedValue.get(k));
        }

        if (element[k] instanceof Array) {
          element[k].forEach((cWhitoutName) => {
            if (cWhitoutName instanceof Array) {
              this.maskArray(cWhitoutName);
            } else {
              this.maskSingleObject(cWhitoutName, k);
            }
          });

          this.maskArray(element[k]);
        } else if (element[k] instanceof Object || element[k] instanceof String) {
          this.maskSingleObject(element[k], k);
        }
      });
    });
  }

  private maskSingleObject(obj: any, nodeParent: string) {
    const keyContentObj = Object.keys(obj);

    keyContentObj.forEach((kContent) => {
      let parentAndChild = '';
      if (nodeParent !== '') {
        parentAndChild = nodeParent + '.' + kContent;
      } else {
        parentAndChild = kContent;
      }

      if (obj[kContent] instanceof Array) {
        this.maskArray(obj[kContent]);
      } else if (obj[kContent] instanceof Object) {
          if (mapMaskedValue.get(parentAndChild) != null) {
            obj[kContent] = this.getRandomPosition(mapMaskedValue.get(parentAndChild));
          }
          this.maskSingleObject(obj[kContent], kContent);
      } else if (obj[kContent] instanceof String && mapMaskedValue.get(parentAndChild) != null) {
          obj[kContent] = this.getRandomPosition(mapMaskedValue.get(parentAndChild));
      } else {
        if (mapMaskedValue.get(parentAndChild) != null) {
          obj[kContent] = this.getRandomPosition(mapMaskedValue.get(parentAndChild));
        }
      }
    });
  }

  private getRandomPosition(arrMaskedValues: any[]): string {
    if (arrMaskedValues === undefined || arrMaskedValues === null || arrMaskedValues.length === 0) {
      return '********';
    }

    return arrMaskedValues[Math.floor(Math.random() * arrMaskedValues.length)];
  }
}

const mapMaskedValue = new Map<string, any[]>();
mapMaskedValue.set('name', ['COMEXPORT SISTEMAS', 'COMEXPORT PRODUTOS', 'COMEXPORT PEÇAS']);
mapMaskedValue.set('cells.column1', ['COMEXPORT SISTEMAS', 'COMEXPORT PRODUTOS', 'COMEXPORT PEÇAS']);