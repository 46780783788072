<div class="alert-type-header">
    <div class="title">
        <h5>{{ title }}</h5>
        <cmx-help-modal [help-title]="title" [help-description]="helpDescription" [loading]="loadingInfoModal"
        (onOpen)="loadingInfoModal =  false"></cmx-help-modal>
    </div>
    <span>{{ subtitle || defaultSubtitle }}</span>
</div>

<div class="cmx-line-chart-container">
    <cmx-line-chart
        class="cmx-line-chart"
        *ngFor="let alert of data; trackBy: identify"
        [color]="utils.getColorBySeverity(alert.severity)"
        [description]="alert.severityName"
        [percentage]="alert.percentage"
        [value]="alert.quantity"
        (clickEvent)="clickEvent.emit(alert._link)"
    ></cmx-line-chart>
</div>
