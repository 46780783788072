import { environment } from '#environment';
import { Injectable } from '@angular/core';
import { UserService } from 'app/auth/_services';
import { FollowUpReportImpFormatter } from '../../theme/pages/reports/report-followup/formatters/report-followup.formatter.imp';
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';

const CHASSIS_V2 = 'automotive';

@Injectable()
export class DomainTypeService {

    private currentUser = this.userService.getCurrentUser();

    constructor(
        private impFormatter: FollowUpReportImpFormatter,
        private userService: UserService,
        private featureFlagService: FeatureFlagService,
    ) { }

    public getDomainType() {
        const urlSplited = window.location.href.split('/');
        const currentUrlType = urlSplited[urlSplited.length - 1];
        const currentType = currentUrlType.split('-')[1];
        return currentType === 'chassi' || currentType === CHASSIS_V2 || currentType === 'sku' ? currentType : 'imp';
    }

    // FollowUp
    public async getFollowUpEndpoint(type, query: boolean = true) {
        if (type  === CHASSIS_V2 || type  === 'chassi') {
            return (query ? `${environment.endpoints.automotiveV2QueryService}/api/v2/chassis`
                          : `${environment.endpoints.automotiveCommandService}/api/v2`);
        } else if (type  === 'sku') {
            return `${environment.endpoints.skuService}`;
        } else if (type === 'followup') {
            return this.getFollowUpURL();
        } else {
            return `${environment.endpoints.importationService}`;
        }
    }

    public getFollowUpExportEndpoint(type) {
        return type === 'imp' ? 'followup-report' : 'followup';
    }
    public getFollowUpType(type) {
        if (type  === 'chassi' || type  === CHASSIS_V2 ) {
            return 'followup:chassi';
        } else if (type  === 'sku') {
            return 'followup:sku';
        } else {
            return 'followup';
        }
    }
    public getFollowUpMainColumn(type) {
        if (type  === 'chassi' || type === CHASSIS_V2) {
            return '#chassiGrid';
        } else if (type  === 'sku') {
            return '#skuGrid';
        } else {
            return '#impGrid';
        }
    }
    public getFollowUpDataColumn(type) {
        return type === 'imp' ? '#FollowUpGrid' : '#FollowUpAutoGrid';
    }
    public getFollowUpOriginParam(type) {
        if (type  === 'chassi' || type  ===  CHASSIS_V2) {
            return 'CHASSI';
        } else if (type  === 'sku') {
            return 'SKU';
        } else {
            return 'IMP';
        }
    }
    public getFollowUpMainWidthColumn(type) {
        return type === 'imp' ? 100 : 180;
    }
    public getFollowUpWidth(type) {
        if (type === 'imp') {
            if (this.currentUser.has('FUP_VALIDATION')) {
                $('#impGrid').css('width', '132px');
                $('#FollowUpGrid').css('left', '132px');
                $('#FollowUpGrid').css('width', 'calc(100% - 180px)');
            } else {
                $('#impGrid').css('width', '100px');
                $('#FollowUpGrid').css('left', '100px');
                $('#FollowUpGrid').css('width', 'calc(100% - 152px)');
            }
        } else if (this.currentUser.has('FUP_VALIDATION')) {
            $('#chassiGrid').css('width', '212px');
            $('#skuGrid').css('width', '212px');
            $('#FollowUpAutoGrid').css('left', '212px');
            $('#FollowUpAutoGrid').css('width', 'calc(100% - 262px)');
        } else {
            $('#chassiGrid').css('width', '180px');
            $('#skuGrid').css('width', '180px');
            $('#FollowUpAutoGrid').css('left', '180px');
            $('#FollowUpAutoGrid').css('width', 'calc(100% - 230px)');
        }
    }
    public getFollowUpGreyBlockWidth(type) {
        if (type === 'imp') {
            if (this.currentUser.has('FUP_VALIDATION')) {
                $('.greyBlock').css('width', '132px');
            } else {
                $('.greyBlock').css('width', '100px');
            }
        } else if (this.currentUser.has('FUP_VALIDATION')) {
            $('.greyBlock').css('width', '212px');
        } else {
            $('.greyBlock').css('width', '180px');
        }
    }
    // FollowUp SlickGrid
    public getFollowUpSlickPath(type) {
        if (type  === 'chassi' || type === CHASSIS_V2 ) {
            return 'chassiNumber';
        } else if (type  === 'sku') {
            return 'code';
        } else {
            return 'imp';
        }
    }
    public getFollowUpSlickMainColumn(type) {
        if (type  === 'chassi' || type === CHASSIS_V2) {
            return { id: 0, path: 'chassiNumber', name: 'CHASSI', width: 180.001,
            field: 'chassiNumber', formatter: this.impFormatter.formatChassi, groupName: ' ' };
        } else if (type  === 'sku') {
            return { id: 0, path: 'customerSku', name: 'SKU', width: 180.001,
            field: 'customerSku', formatter: this.impFormatter.formatSku, groupName: ' ' };
        } else {
            return { id: 0, path: 'imp', name: 'IMP', width: 100.001, field: 'imp',
            formatter: this.impFormatter.formatImp, groupName: ' ' };
        }
    }

    // Relatórios
    public getReportHierarchy(type) {
        return type === 'traders' ? 'trader-profitability-report' :
        type === 'despesas' ? 'expenses-report' : 'imp-details-report';
    }
    public getReportTitle(type) {
        return type === 'traders' ? 'Traders' :
        type === 'despesas' ? 'Despesas' : 'Fin. por IMP';
    }

    public async getFollowUpURL() {
        let followupServiceURL = environment.endpoints.followupService;
        try {
            const isEnabled = await this.featureFlagService.isFeatureFlagEnabledV2(FeatureFlagService.FEATURE_FOLLOWUP_MONGODB_SERVICE);
            if(isEnabled) followupServiceURL = environment.endpoints.followupMongoDBService;
        } catch (error) {
            console.warn(error);
        }
        return followupServiceURL;
    }
}
