import { _applyPosFormatters } from "./pos-formatters/pos-formatters";

export function dropdownFormatter(row, cell, value, columnDef, dataContext) {
    if (!value?.data || !value?.data?.length) {
        return _applyPosFormatters(row, cell, '', columnDef, dataContext);
    }

    const contentValue =
    `<div class="dropdown-formatter-container">
        <div class='dropdown-formatter-content'>
            <span class="dropdown-formatter-innerText" style="pointer-events: none">${ value?.itemName || 'SELECIONAR' }</span>
            <span class="material-icons">expand_more</span>
        </div>
        <div class="dropdown-formatter-list-content" id="dropdown-formatter-list-${columnDef?.field}-${row}">
            ${ value?.data?.map((item) => `<div class="dropdown-formatter-item" id="${item?.id}">${
                item?.itemName }</div>`).join('') }
        </div>
    </div>`;
    return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
}
