<div *ngIf="spListingService.asyncData">
    <loader></loader>
</div>

<div *ngIf="!spListingService.asyncData" style="height: 100%; overflow: auto; position: relative;">
    <cmx-assembled-data [data]="spExchangeDetails"></cmx-assembled-data>
    <div class="form-assembled">
        <div class="form-group">
            <div class="p-field">
                <label>Credit Note</label>
                <p class="switch-item">
                    <p-inputSwitch [(ngModel)]="creditNote" (onChange)="spListingService.setCreditNote($event.checked);changeSpDetails()" [disabled]="spStatus.code != 'OPENED'"></p-inputSwitch>
                    <span class="label-switch">{{ creditNote | yesOrNo }}</span>
                </p>
            </div>
        </div>
    </div>
</div>
