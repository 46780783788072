<div *ngIf="poStatusService.asyncData">
    <loader></loader>
</div>

<span *ngIf="!poStatusService.asyncData && poStatusService.getPoItems().length === 0" class="noContent">
    Não existem resultados para essa P.O
</span>

<cmx-custom-grid
    id="poStatusSku"
    [style.visibility]="!poStatusService.asyncData && poStatusService.getPoItems().length ? 'visible' : 'hidden'"
    [loading]="poStatusService.asyncData"
    [columns]="gridColumns"
    [rows]="poStatusService.getPoItems()"
    [styles]="{ height: '95%' }"
></cmx-custom-grid>
