import { environment } from '#environment';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { CostsFilter } from './costsFilter.model';
import * as _ from 'lodash';

@Injectable()
export class CostsService {

    currentParams = {
        imp: '',
        chassi: '',
        startDate: moment().format('01/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY'),
        clients: [],
        crs: [],
        models: [],
        colors: [],
        manufacturerYears: [],
        modelYears: [],
        context: 'imp',
        clientReference: '',
        showUc: true,
    };
    private _filterState: CostsFilter = this._filterStateService.getCostsFilter();

    constructor(
        private _http: HttpClient,
        private _filterStateService: FilterStateService
    ) { }

    getCosts(request): Observable<any> {
        return this._http.get(`${environment.endpoints.expensesService}/summary/importationExpenses`, request);
    }

    getAutomotiveDomainFilters(): Observable<any> {
        return this._http.get(`${environment.endpoints.automotiveV2QueryService}/api/v2/chassis/filters`);
       
    }

    getContainerCosts(request): Observable<any> {
        return this._http.get(`${environment.endpoints.expensesService}/expenses-container`, request);
    }

    getSKUCosts(request): Observable<any> {
        return this._http.get(`${environment.endpoints.expensesService}/expenses-sku`, request);
    }

    export(url, headers, params ): Observable<any> {
        return this._http.get(`${url}${params}`, {
            headers: {
                'X-Requested-Client-Groups': headers
            },
        });
    }

    getParamsForDetails() {
        return (this._filterState.getAutomotiveMode() === 'Desligado' && (this.currentParams.imp || this.currentParams.clientReference)) ||
               (this._filterState.getAutomotiveMode() === 'Ligado' && this.currentParams.chassi) ?
               _.cloneDeep({ ..._.cloneDeep(this.currentParams), startDate: '01/01/2016', endDate: moment().format('DD/MM/YYYY') }) : this.currentParams
    }

}
