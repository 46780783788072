import { _applyPosFormatters } from "./pos-formatters/pos-formatters";
import { textFormatter } from "./text.formatter";

export function booleanFormatter(row, cell, value, columnDef, dataContext) {
    const currentValue = columnDef.formatterData && columnDef.formatterData.singleField ? value[columnDef.formatterData.singleField] : value;
    const customLabel = (columnDef.formatterData && columnDef.formatterData.customLabel) || ['SIM', 'NÃO'];

    if (customLabel) {
        const contentValue = `<span>${currentValue === true ? customLabel[0] : customLabel[1]}</span>`;
        return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
    }

    return textFormatter(row, cell, value, columnDef, dataContext);
}
