import { environment } from '#environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable()
export class NewTrackingTimelineService {

    constructor(private http: HttpClient) { }

    public getTrackingTimeline(request): Observable<any> {
        return this.http.get(`${environment.endpoints.newTrafficTraceability}/container/timeline-events?containerNumber=${request.equipmentNumber}`);
    }
}
