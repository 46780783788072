import { Injectable } from '@angular/core';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { DETAIL_COSTS_CONTEXT } from '../detailed/detail-costs-modal.constant';
import { CostsService } from '../consolidated/costs.service';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { DomainService } from '#services/_domain/domain.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import moment from 'moment';
import * as _ from 'lodash';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';

@Injectable()
export class CostsDropdownService {

    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);
    filterState: any = this._filterStateService.getCostsFilter();
    options: {
        imp: DropdownOption[];
        cr: DropdownOption[];
        models: DropdownOption[];
        colors: DropdownOption[];
        manufacturerYears: DropdownOption[];
        modelYears: DropdownOption[];
        client: DropdownOption[];
    } = {
        imp: [],
        cr: [],
        models: [],
        colors: [],
        manufacturerYears: [],
        modelYears: [],
        client: [],
    };

    constructor(
        private _filterStateService: FilterStateService,
        private _cmxDropdownService: CmxDropdownService,
        private _userCacheService: UserCacheService,
        private _domainService: DomainService,
        private _costsService: CostsService
    ) {
        this.options.imp = this._cmxDropdownService.toDropdownItems(DETAIL_COSTS_CONTEXT);
        let currentYear = moment().year();
        let manufacturerYears = [];
        this._range(currentYear, 5, manufacturerYears)
        this.options.manufacturerYears = this._cmxDropdownService.toDropdownItems(manufacturerYears);

        let modelYears = [];
        this._range( (currentYear +1), 6, modelYears)
        this.options.modelYears = this._cmxDropdownService.toDropdownItems(modelYears);
        this.filterState.setShowUc(this.filterState.getShowUc());
        this.filterState.setContext(this.filterState.getContext());

        this._costsService.getAutomotiveDomainFilters().subscribe((autoFilters) => {
            this.options.models = this._cmxDropdownService.toDropdownItems(autoFilters.models);
            this.options.colors = this._cmxDropdownService.toDropdownItems(autoFilters.colors);
        });

        this._domainService.getProfitCenter().subscribe((profitCenters) => {
            this.options.client = this._cmxDropdownService.toDropdownItems(this._userCacheService.getClientGroups());
            this.options.cr = this._cmxDropdownService.toDropdownItems(profitCenters);
        });

    }

    private _range(currentYear: number, size: number, dropdow) {
        var startYear = currentYear;
        var endYear = currentYear - size;
        for (var i = startYear; i > endYear; i--) {
            dropdow.push( { code: i, name: i });
        }
    }

}
