<div class="page-title-identifier">Movimentação Diária (Automotiva)</div>
<div class="detailDailyComponent">
    <div><loader></loader></div>
    <span id="detailDailyTitle"></span>
    <div id="detailDailyGrid"></div>
    <div *ngIf="automotiveDetailDailyService.pages.length > 1">
        <pagination [numberOfPages]="automotiveDetailDailyService.pages.length"
        [queryId]="automotiveDetailDailyService.queryId"
        (onPageChange)="automotiveDetailDailyService.changePage($event)"></pagination>
    </div>
</div>
