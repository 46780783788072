
import { tap } from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class LoginInterceptor implements HttpInterceptor {
    constructor(private router: Router, private authService: AuthenticationService) { }

    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(tap((event: HttpEvent<any>) => { }, async (err: HttpErrorResponse) => {            
            if (err.status === 401) {
                if (location.pathname !== '/login' && location.pathname !== '/logout-msg' && location.pathname !== 'pwd-recovery') {
                   if (err.error === 'TWO_FACTOR_REQUIRED') {
                        return next.handle(req);
                    } else if (localStorage.getItem('isLogged') !== null) {
                    if (err.error === 'TWO_FACTOR_REQUIRED') {
                        return next.handle(req);
                    } else if (err.error === 'INVALID_OR_EXPIRED_SESSION' || localStorage.getItem('isLogged') !== null) {
                        await this.authService.logout(false);
                        this.router.navigate(['/logout-msg'], { queryParams: { errorCode: err.error } });
                    } else if (err.status === 401) {
                        return next.handle(req);
                    } 
                } 
                else if (err.error === 'EXPIRED_PASSWORD') {
                    this.router.navigate(['/auth-handler']);
                }
            }else{

                const url = req.url;
                const getPath = url.split('/');
                if(req.method === 'PUT' && getPath[getPath.length - 1] === 'logout'){
                    window.location.reload();
                }
            }
        }}));
    }

}
