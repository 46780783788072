import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'cargo-vessel-with-container-svg',
    templateUrl: './cargo-vessel-with-container.html',
    styleUrls: [],
    encapsulation: ViewEncapsulation.None,
})
export class CargoVesselWithContainerSVGComponent {

}
