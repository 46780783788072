
import {catchError, map} from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { environment } from '#environment';
import { JsonPipe } from '@angular/common';

@Injectable()
export class SubscriptionsService {
    // tslint:disable: max-line-length
    constructor(private http: HttpClient) {}

    findAllEventType(): Observable<any> {
        return this.http.get<any>(`${environment.endpoints.notificationService}/metadata/notificationType`);
    }

    getSubscriptions(request?: any): Observable<any> {
        return this.http
            .get<any>(`${environment.endpoints.notificationService}/notifications/category/IMPORTATION_CHANGE`, {
                headers: request.headers,
                observe: 'body',
                params: request.params,
                responseType: 'json',
            })
            .pipe((response) => {
                return response.pipe(map((webhook): any => {
                    const subscriptions = [];
                    if (webhook) {
                        for (const subscription of webhook.category.children) {
                            if (subscription.properties) {
                                subscription.properties.forEach((item) => {
                                    subscription[item.key] = item.value;
                                });
                            }
                            subscriptions.push({
                                ...subscription,
                            });
                        }
                    }
                    return subscriptions;
                }), catchError(error => of(error)));
            });
    }

    save(subscription, request?: any): Observable<any> {
        return this.http.put(
            `${environment.endpoints.notificationService}/notifications/webhook/${this.getIdFromEvent(
                subscription.selectedEvent
            )}`,
            subscription,
            request
        );
    }

    delete(event, request?: any): Observable<any> {
        return this.http.delete(`${environment.endpoints.notificationService}/notifications/webhook/${event}`, request);
    }

    getLogs(notificationTypeId, impSearch, startDate, endDate, page, selectedStatus, request?: any): Observable<any> {
        let url = `${environment.endpoints.notificationService}/history?notificationTypeId=${notificationTypeId}&size=50&page=${page}&startTime=${startDate}&endTime=${endDate}`;
        if (impSearch) {
            url += `&filter=imp&imp.value=${impSearch}`;
        }
        if (selectedStatus.length > 0) {
            url += `&status=${selectedStatus[0].id}`;
        }
        return this.http.get<any>(url, {
            headers: request.headers,
            observe: 'body',
            responseType: 'json',
        });
    }

    retry(entryId) {
        return this.http.put(`${environment.endpoints.notificationService}/history/resend/${entryId}`, {});
    }

    private getIdFromEvent(selectedEvent) {
        if (selectedEvent && selectedEvent.length === 1) {
            return selectedEvent[0].id;
        }
        return '';
    }
}
