<div *ngIf="poStatusService.asyncData">
    <loader></loader>
</div>

<span *ngIf="!poStatusService.asyncData && poStatusService.getImpList().length === 0" class="noContent">
    Não existem resultados para essa P.O
</span>

<cmx-custom-grid
    id="poStatusImp"
    [style.visibility]="!poStatusService.asyncData && poStatusService.getImpList().length ? 'visible' : 'hidden'"
    [loading]="poStatusService.asyncData"
    [columns]="gridColumns"
    [rows]="poStatusService.getImpList()"
    [options]="{ forceFitColumns: true }"
    [styles]="{ height: '95%' }"
    (onCallback)="onGridCallback($event)"
></cmx-custom-grid>