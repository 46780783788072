import { AfterViewInit, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { FollowUpModelService } from '../report-followup-model/report-followup-model.service';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { DomainTypeService } from '#services/_domain/domainType.service';
import { UserService } from 'app/auth/_services';

@Component({
    selector: 'followup-datamart-modal',
    templateUrl: './followup-datamart-modal.component.html',
    styleUrls: ['followup-datamart-modal.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class FollowUpDatamartModalComponent implements AfterViewInit {
    url = window.location.href.replace('//', '.').split('.')[1];
    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);

    type = this._domainTypeService.getDomainType();
    datamartModalTitle: string = this._determineModalTitle();
    serviceId: string = this.type === 'imp' ? 'imp-service' : 'sku-service';
    generatorId: string = this.type === 'imp' ? 'fup' : 'fup-sku';
    datamartName: string = this.type === 'imp' ? 'FUP IMP' : 'FUP SKU';
    options: { modelList: DropdownOption[] } = { modelList: [] };
    selectedModel;
    datamartFilters: any = new Object();
    currentUser = this.userService.getCurrentUser();

    filterState = this.type === 'imp' ? this.filterStateService.getFupImpFilter()
    : this.filterStateService.getFupSkuFilter();
    filters = this._determineFilters(this.currentUser);

    constructor(
        public filterStateService: FilterStateService,
        private _domainTypeService: DomainTypeService,
        private _followUpModelService: FollowUpModelService,
        private _cmxDropdownService: CmxDropdownService,
        private userService: UserService,
    ) { }

    ngAfterViewInit() {
        this._followUpModelService.getSaveModelList().subscribe(async (modelList) => {
            this.options.modelList = this._cmxDropdownService.toDropdownItems(
                modelList.map((model) => {
                    return { code: model.modelName, name: model.modelName };
                })
            );
        });
        const selectedModel = this._followUpModelService.getSelectedFollowUpEditModel();
        if (selectedModel) {
            this.selectedModel = this._cmxDropdownService.toDropdownItems(
                [selectedModel].map((model) => {
                    return { code: model.modelName, name: model.modelName };
                })
            );
        }
    }

    private _determineModalTitle() {
        return `Data Mart - FUP ${this.type === 'automotive' ? 'Chassi' : this.type.toUpperCase()}`;
    }

    private _determineFilters(user) {
        const filters = [
            { title: 'Status', field: 'status' },
            { title: 'Modal', field: 'modalTransportations' },
            { title: `${user.has('INTERNAL_FILTERS') ? 'Clientes' : ''}`, field: 'clientGroups' },
            { title: 'Tipo de Data', field: 'referenceDateType' },
            { title: '', field: 'field' },
            { title: '', field: 'sort' }
        ];
        if (this.type === 'imp') {
            filters.push(
                { title: 'Filiais', field: 'subsidiaryNames' },
                { title: 'Período', field: 'period' },
            )
        }
        return filters
    }

}
