import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CALENDAR_DAYS, CALENDAR_HOLIDAYS } from './constants/calendar.constant';
import { ICalendarDay, IHoliday } from './interfaces/calendar.interface';
import moment from 'moment';
import { UtilsService } from '#services/_utils/utils.service';

@Component({
    selector: 'cmx-calendar',
    templateUrl: 'cmx-calendar.component.html',
    styleUrls: ['cmx-calendar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class CalendarComponent {

    @Input() amplitude: 'month' | 'week' | 'day' = 'month';
    @Input() useDayAbbreviation: boolean = true;
    @Input() useLeftDayContainer: boolean = true;
    @Input() disableDaysBefore: boolean = false;
    @Input() disableDaysAfter: boolean = true;
    @Input() disableLastAndNext: boolean = true;
    @Input() disableWeekend: boolean = true;
    @Input() showHolidays: boolean = true;
    @Input() disableHolidays: boolean = true;
    @Input() disableHalfHolidays: boolean = false;
    @Input() selectCurrentDayOnInit: boolean = true;
    @Input() useEvents: boolean = false;
    @Input() dayList: [] = [];

    moment = moment;
    holidayList: IHoliday[] = CALENDAR_HOLIDAYS;
    calendarDaysList = CALENDAR_DAYS;
    date = new Date();
    year = this.date.getFullYear();
    month = this.date.getMonth();
    structureDays: ICalendarDay[] = [];
    selectedDay: ICalendarDay;
    selectedMonth: number;
    selectedYear: number;

    @Output('onSelect') onSelect = new EventEmitter();
    @Output('onStructureDays') onStructureDays = new EventEmitter();
    @Output('onChangeMonth') onChangeMonth = new EventEmitter();

    constructor(public utilsService: UtilsService) { }

    ngAfterViewInit() {
        if (this.amplitude === 'month') { 
            this._initMonthCalendarStructure(true);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes?.dayList) {
            this._initMonthCalendarStructure();
        }
    }

    selectDay(day: ICalendarDay) {
        if (!day?.disabled) {
            this.structureDays.forEach((day) => { day.selected = false });
            this.selectedDay = day;
            this.selectedMonth = this.month;
            this.selectedYear = this.year;
            day.selected = true;
            this.onSelect.emit({ day, month: this.month, year: this.year });
        }
    }

    changeMonth(value) {
        this.month += value;
        if (this.month === -1) {
            this.year--;
            this.month = 11;
        } else if (this.month === 12) {
            this.year++;
            this.month = 0;
        }
        this._initMonthCalendarStructure(false, true);
        const firstAllowedDay = this.structureDays.find((day: ICalendarDay) => { return day.firstAllowedDay });
        this.onChangeMonth.emit({ month: this.month, year: this.year, firstAllowedDay: firstAllowedDay?.number?.toString().padStart(2, '0') });
    }

    private _initMonthCalendarStructure(initial?: boolean, onChangeMonth?: boolean) {
        this.structureDays = [];
        const firstDayColumnIndex = new Date(this.year, this.month, 1).getDay();
        const lastDay = new Date(this.year, this.month + 1, 0).getDate();
        const lastDayColumnIndex = new Date(this.year, this.month, lastDay).getDay();
        const lastMonthLastDay = new Date(this.year, this.month, 0).getDate();
        const firstStructureDay = lastMonthLastDay - (firstDayColumnIndex - 1)
        for (let index = firstStructureDay; index <= lastMonthLastDay; index++) {
            const holiday = this._verifyHoliday(index, this.month === 0 ? 11 : this.month - 1)
            this.structureDays.push({ 
                number: index, period: 'last', selected: false, holiday, color: this._defineColor(index, this.month - 1, holiday, 'last'),
                disabled: this._verifyDisabledDay('last', index, holiday, new Date(this.year, this.month, index))
            })
        }
        for (let index = 1; index <= lastDay; index++) {
            const holiday = this._verifyHoliday(index, this.month)
            const color = this._defineColor(index, this.month, holiday, 'current');
            let firstAllowedDay = -1;
            if (color === '#d1d100' && firstAllowedDay === -1) {
                firstAllowedDay = index;
            }
            this.structureDays.push({ 
                number: index, period: 'current', selected: false, holiday, color,
                disabled: this._verifyDisabledDay('current', index, holiday, new Date(this.year, this.month, index)),
                firstAllowedDay: firstAllowedDay === index,
            })
        }
        const structureLengthBeforeNextMonth = 42 - this.structureDays.length;
        for (let index = 1; index <= structureLengthBeforeNextMonth; index++) {
            const holiday = this._verifyHoliday(index, this.month === 11 ? 0 : this.month + 1)
            this.structureDays.push({
                number: index, period: 'next', selected: false, holiday, color: this._defineColor(index, this.month + 1, holiday, 'next'),
                disabled: this._verifyDisabledDay('next', index, holiday, new Date(this.year, this.month, index))
            })
        }
        if (this.useEvents) { this._applyMonthCalendarEvents() }
        if (this.selectCurrentDayOnInit) { 
            if (initial) {
                this._forceSelectDay()
            } else if (this.selectedMonth === this.month && this.selectedYear === this.year && !initial) {
                this._forceSelectDay(this.selectedDay.number)
            }
        }
        if (initial || onChangeMonth) {
            this.onStructureDays.emit({ structureDays: this.structureDays, month: this.month, year: this.year });
        }
    }

    private _applyMonthCalendarEvents() {
        // this.structureDays[10].events = [{ title: 'Teste' }]
    }

    private _forceSelectDay(number?: number) {
        setTimeout(() => {
            const day = this.structureDays.find((day) => { return day?.number?.toString() === (number?.toString() || this.moment().format('D')) && day?.period === 'current' })
            if (!day?.disabled) { this.selectDay(day) };
        }, 200);
    }

    private _verifyHoliday(day, month): IHoliday {
        const carnivalDate = this._getCarnivalAndHolyFridayDate(this.year).carnivalDate;
        const carnivalMonth = parseInt(moment(carnivalDate)?.format('MM'));
        const carnivalDay = parseInt(moment(carnivalDate)?.format('DD'));
        const holyFridayDate = this._getCarnivalAndHolyFridayDate(this.year).holyFridayDate;
        const holyFridayMonth = parseInt(moment(holyFridayDate)?.format('MM'));
        const holyFridayDay = parseInt(moment(holyFridayDate)?.format('DD'));
        const previousCarnivalMonth = carnivalDay === 1 ? carnivalMonth - 1 : carnivalMonth;
        const previousCarnivalDay = carnivalDay === 1 ? 31 : carnivalDay - 1;
        const wednesdayMonth = carnivalDay === 29 ? carnivalMonth + 1 : carnivalMonth;
        const wednesday = carnivalDay === 29 ? 1 : carnivalDay + 1;
        if (day === holyFridayDay && month === holyFridayMonth - 1) {
            return { day: holyFridayDay, month: holyFridayMonth - 1, name: 'Sexta-feira Santa', code: 'sexta_santa' }
        } else if (day === previousCarnivalDay && month === previousCarnivalMonth - 1) {
            return { day: previousCarnivalDay, month: previousCarnivalMonth - 1, name: 'Carnaval', code: 'carnaval', optional: true }
        } else if (day === carnivalDay && month === carnivalMonth - 1) {
            return { day: carnivalDay, month: carnivalMonth - 1, name: 'Carnaval', code: 'carnaval', optional: true }
        } else if (day === wednesday && month === wednesdayMonth - 1) {
            return { day: wednesday, month: wednesdayMonth - 1, name: 'Quarta-feira de Cinzas', code: 'quarta_de_cinzas', optional: true, half: true }
        } else {
            return this.holidayList.find((holiday: IHoliday) => { return holiday.day === day && holiday.month === month })
        }
    }

    private _verifyDisabledDay(period, day, holiday, date) {
        const weekDay = new Date(this.year, period === 'last' ? this.month - 1 : period === 'next' ? this.month + 1 : this.month, day).getDay()
        if (((period === 'last' || period === 'next') && this.disableLastAndNext)
            || (moment(date).isBefore(moment(this.date), 'day') && this.disableDaysBefore)
            || (moment(date).isAfter(moment(this.date), 'day') && this.disableDaysAfter)
            || ((weekDay === 0 || weekDay === 6) && this.disableWeekend)
            || ((holiday && !holiday?.half && this.disableHolidays) || (holiday?.half && this.disableHolidays && this.disableHalfHolidays))) {
            return true;
        } else {
            return false;
        }
    }

    private _defineColor(currentDay, month, holiday, period) {
        const date = `${moment(`${currentDay}-${month + 1}-${this.year}`, 'DD-MM-YYYY').format('DD-MM-YYYY')}`;
        const dayFound: any = this.dayList.find((day: any) => { return day.date === date })
        // feriados, outros meses e finais se semana
        if ((this.disableLastAndNext && (period === 'last' || period === 'next'))
           || (((holiday && !holiday?.half) && this.disableHolidays) || (holiday?.half && this.disableHalfHolidays))
           || ((moment(date, 'DD-MM-YYYY').isoWeekday() === 6 || moment(date, 'DD-MM-YYYY').isoWeekday() === 7) && this.disableWeekend)) {
            return 'grey';
        }
        if (dayFound) {
            return dayFound?.color || '#3b6394';
        } else {
            if (moment(date, 'DD-MM-YYYY').isBefore(moment())) {
                return '#d1d100';
            } else {
                return 'grey';
            } 
        }
    }

    _getCarnivalAndHolyFridayDate(year) {
        // Calcula Páscoa
        const f = Math.floor;
        const G = year % 19;
        const C = f(year / 100);
        const H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30;
        const I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11));
        const J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7;
        const L = I - J;
        const month = 3 + f((L + 40) / 44);
        const day = L + 28 - 31 * f(month / 4);
        // Calcula Dia do Carnaval (47 dias antes da Páscoa)
        const easterDate = new Date(year, month - 1, day);
        const carnivalDate = new Date(easterDate);
        carnivalDate.setDate(easterDate.getDate() - 47);
        const holyFridayDate = new Date(easterDate);
        holyFridayDate.setDate(easterDate.getDate() - 2);
        return { carnivalDate, holyFridayDate };
    }

}