import { Feature } from './feature';

export class Release {

    public static blank() {
        return new Release();
    }

    public id: string;
    public title: string;
    public features: Feature[];
    name: string;

    constructor(source?: any) {
        if (!source) {
            this.title = 'Nova Release';
            this.name = 'Nova Release';
            this.features = [new Feature()];
        } else {
            this.title = source.title;
            this.name = source.title;
            this.features = source.features.map((feature) => new Feature(feature)) || [];
        }
    }

    public getId(): string { return this.id; }
    public getTitle(): string { return this.title; }
    public getFeatures(): object[] { return this.features; }
    public getName(): string { return this.name; }

    public setId(id): void { this.id = id; }
    public setTitle(title): void { this.title = title; }
    public setName(name): void { this.name = name; }
    public setFeatures(features): void { this.features = features; }

}
