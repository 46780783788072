
import { PageTabControlService } from '#services/_page-tab-control/page-tab-control.service';
import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { DetailedCostsComponent } from './detailed/costs-detailed.component';
import { ConsolidatedCostsComponent } from './consolidated/costs-consolidated.component';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { ReportPreferencesService } from '#services/_report-preferences/report-preferences.service';
import { COST_HELP_DESCRIPTION } from './consolidated/constants/costs-help-description.constant';

@Component({
  selector: 'new-costs',
  templateUrl: './costs.component.html',
  styleUrls: ['./costs.component.scss']
})
export class NewCostsComponent {

    @ViewChild('bodyContent', { read: ViewContainerRef }) _bodyContent: ViewContainerRef;
    filterState: any = this._filterStateService.getCostsFilter();
    rightExtraPaddingPage: boolean = false;
    helpDescription = COST_HELP_DESCRIPTION;
    pageConfig = {
        title: 'Custos',
        url: 'financeiro/custos',
        urlParams: this._route.snapshot.params,
        tabs: [
            { code: 'consolidado', title: 'Consolidado', component: ConsolidatedCostsComponent,
                topButtonList: [{
                    code: 'costs-consolidated-download', name: 'Download', leftIcon: 'file_download'
                }, {
                    code: 'costs-consolidated-swap', name: 'Mudar Visão', leftIcon: 'swap_vert'
                }] 
            },
            { code: 'detalhado', title: 'Detalhado', component: DetailedCostsComponent,
                switch: [
                    { name: 'IMP', disabled: this._defineAutomotiveBlock(), disabledText: 'Modo automotivo ligado' },
                    { name: 'SKU', disabled: this._defineAutomotiveBlock(), disabledText: 'Modo automotivo ligado' },
                    { name: 'NF', disabled: this._defineAutomotiveBlock(), disabledText: 'Modo automotivo ligado' }
                ],
                topButtonList: [{
                    code: 'costs-detailed-download', name: 'Download', leftIcon: 'file_download'
                }, {
                    code: 'costs-detailed-datamart', name: 'Datamart', leftIcon: 'source'
                }, { 
                    code: 'costs-detailed-customize', name: 'Personalizar', leftIcon: 'dashboard', rightIcon: 'add'
                }]
            }
        ]
    }

    constructor(
        public reportPreferencesService: ReportPreferencesService,
        public pageTabControlService: PageTabControlService,
        private _filterStateService: FilterStateService,
        private _route: ActivatedRoute,
    ) { }

    ngAfterViewInit() {
        setTimeout(() => {
            this.pageTabControlService.setPageConfigs(this.pageConfig, this._bodyContent);
            this._defineRightExtraPadding();
        }, 0);
        this.filterState.changeClientGroups.subscribe(() => {
            this._changeDetailedSwitch();
        })
        this.filterState.changeAutomotiveMode.subscribe(() => {
            this._changeDetailedSwitch(true);
        })
    }

    onSelectTab(tab) {
        if (tab.code === 'consolidado') {
            this.pageTabControlService.onNavigate(tab, null, true)
        } else if (tab.code === 'detalhado') {
            const context = this.filterState.getContext().toLowerCase();
            this.pageTabControlService.onNavigate(tab, { name: context === 'item' ? 'nf' : context }, false)
        }
    }

    private _defineAutomotiveBlock() {
        return this.filterState.getAutomotiveMode() === 'Ligado';
    }

    private _defineRightExtraPadding() {
        this.rightExtraPaddingPage = window.location.href.includes('detalhado');
    }

    private _changeDetailedSwitch(reset?) {
        this.pageConfig.tabs[1].switch[0].disabled = this._defineAutomotiveBlock();
        this.pageConfig.tabs[1].switch[1].disabled = this._defineAutomotiveBlock();
        this.pageConfig.tabs[1].switch[2].disabled = this._defineAutomotiveBlock();

        if (reset) {
            this.filterState.setContext('imp');
            setTimeout(() => {
                $('.page-wrapper-title .cmx-switch .left').trigger('click');
            }, 100);
        }
    }

}