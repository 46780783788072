import { APP_INITIALIZER } from '@angular/core';

import { AppConfigService } from '#services/_app-config/app-config.service';

export function initConfig(appConfigService: AppConfigService) {
    return () => appConfigService.loadConfig();
}

export const APP_INITIALIZER_PROVIDER = {
    provide: APP_INITIALIZER,
    useFactory: initConfig,
    deps: [AppConfigService],
    multi: true,
};
