<div class="moveBtn">
    <div class="moveLine"></div>
</div>

<div class="header-container">
    <div class="title-container">
        <span class="analyticsName">Movimentação {{ modalTypeFormats[2] }}</span>
        <cmx-help-modal help-title="Movimentação {{ modalTypeFormats[2] }}" [help-description]="helpDescription"></cmx-help-modal>
        <h5>Movimentação {{ modalTypeFormats[2] }}</h5>
    </div>
    <div class="filter-container" *ngIf="modalType === 'MARITIME'">
        <cmx-switch
        [options]="[{ name: 'TEUs' }, { name: 'Containers' }]"
        [selected]="filterState.getTypeView()"
        (onSelect)="filterState.setTypeView($event);getFilters()"
        ></cmx-switch>
    </div>
</div>

<div id="searchLine" class="reduzed">
    <div class="search-container">
        <div class="input-group right">
            <input
                [name]="'startYear' + modalTypeFormats[0]"
                [id]="'startYear' + modalTypeFormats[0]"
                type="text"
                class="form-control m-input date-impStatusTable"
                [ngModel]="filterState.getYear()"
                (ngModelChange)="filterState.setYear($event)"
            />
        </div>
        <ng-container *ngIf="modalType === 'AIR' || modalType === 'MARITIME'">
            <div class="input-group">
                <span class="input-group-addon">Estados</span>
                <cmx-dropdown
                    [styles]="{width: '160px'}"
                    name="states"
                    [data]="statesOptions"
                    [style.margin-left]="'-20px'"
                    [ngModel]="filterState.getStates()"
                    (ngModelChange)="filterState.setStates($event)"
                    [settings]="dropdownSettingsStates"
                ></cmx-dropdown>
            </div>
            <div class="input-group">
                <span class="input-group-addon">Recintos</span>
                <cmx-dropdown
                    [styles]="{width: '180px'}"
                    name="warehouses"
                    [data]="warehousesOptions"
                    [style.margin-left]="'-20px'"
                    [ngModel]="filterState.getWarehouses()"
                    (ngModelChange)="filterState.setWarehouses($event)"
                    [settings]="dropdownSettingsWarehouses"
                ></cmx-dropdown>
            </div>
        </ng-container>
        <div *ngIf="currentUser$ | async as user">
            <div *ngIf="user.has('INTERNAL_FILTERS')" class="input-group">
                <span class="input-group-addon" [style.margin-right]="'-15px'"></span>
                <cmx-dropdown
                    [styles]="{width: '160px'}"
                    name="clients"
                    [data]="clientsOptions"
                    [ngModel]="filterState.getClientGroups()"
                    (ngModelChange)="filterState.setClientGroups($event)"
                    [settings]="dropdownSettings.clientGroups">
                </cmx-dropdown>
            </div>
        </div>
        <button
        (click)="applyDate()"
        class="applyImpStatus">
            <i class="fa fa-check"></i>
        </button>
    </div>


    <div class="type-header-container" *ngIf="modalType === 'AIR' || modalType === 'MARITIME'">
        <span>Movimentação por:</span>
        <cmx-switch
        [options]="[{ name: 'Estado' }, { name: 'Recinto' }]"
        [selected]="selectedTypeSwitch"
        (onSelect)="onSelectTypeChanged($event)"
        ></cmx-switch>
    </div>
</div>

<div [style.display]="'flex'" [style.height]="'220px'">
    <div [style.width]="modalType === 'AIR' || modalType === 'MARITIME' ? '70%' : '100%'">
        <div
            [style.display]="loadingPrincipalChart ? 'none' : 'block'"
            class="principal-chart"
            [id]="modalTypeFormats[1] + '-chart'"
        ></div>
        <div class="chart-loader" *ngIf="loadingPrincipalChart">
            <loader></loader>
        </div>
    </div>

    <div *ngIf="modalType === 'AIR' || modalType === 'MARITIME'" class="secondary-chart-container">
        <div class="filter-divisor"></div>
        <div class="secondary-chart-content">
            <div class="text-center mt-5" *ngIf="!loadingSecondaryChart && !hasTypeData">
                <span class="noContent">Nenhuma informação encontrada</span>
            </div>

            <div
                class="secondary-chart"
                [style.display]="loadingSecondaryChart ? 'none' : 'block'"
                [id]="'type-' + modalTypeFormats[0] + '-chart'"
            ></div>
            <div class="chart-loader" *ngIf="loadingSecondaryChart">
                <loader></loader>
            </div>
            <div
                *ngIf="selectedType === 'warehouse' && !loadingSecondaryChart && hasTypeData"
                class="arrows-container"
                (click)="$event.stopPropagation()"
            >
                <div
                    class="arrow arrow-left pointer"
                    [ngClass]="{ unclickable: pagination.isFirstPage }"
                    (click)="paginateWarehouseTypeChart(-1)"
                ></div>
                <div
                    class="arrow arrow-right pointer"
                    [ngClass]="{ unclickable: pagination.isLastPage }"
                    (click)="paginateWarehouseTypeChart(1)"
                ></div>
            </div>
        </div>
    </div>
</div>

<div class="extra-legend" [style.display]="loadingPrincipalChart ? 'none' : 'block'">
    <i class="fa fa-info-circle"></i>
    <span>Dados com base na data de chegada</span>
</div>
