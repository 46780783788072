import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { environment } from '#environment';

import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { UserService } from 'app/auth/_services';
import { ChartsServices } from './../charts.services';
import { UtilsService } from '#services/_utils/utils.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { CostDistribuitionFilter } from './cost-distribuition.filter';
import { COST_DISTRIBUTION_CHART_CONFIG } from './constants/cost-distribution-chart.constant';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';

declare let AmCharts: any;

@Component({
    selector: 'cost-distribution-chart',
    templateUrl: './cost-distribution.component.html',
    styleUrls: ['../charts.scss', './cost-distribution.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CostDistributionComponent {

    filterState: CostDistribuitionFilter = this._filterStateService.getCostDistribuitionFilter();
    currentUser = this.userService.getCurrentUser();
    currentUser$ = this.userService.currentUser$;
    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);
    options: { clients: DropdownOption[] } = { clients: [] };
    costsDistributionChart: any;
    asyncDistributionCost: boolean = true;
    total: number;
    selectedClients = [];
    selectedClientGroup;
    editableElements = ['.chart'];
    currentCategory = '';
    zeroedData = { };
    chartData;
    helpDescription = this._defineHelpDescription();

    @HostListener('click') onSetZIndex() {
        this.renderer2.setStyle(this._elementRef.nativeElement, 'z-index', this.utilsService.autoIncrementValue);
    }

    constructor(
        public chartService: ChartsServices,
        public router: Router,
        private http: HttpClient,
        private utilsService: UtilsService,
        private userService: UserService,
        private userCacheService: UserCacheService,
        private _cmxDropdownService: CmxDropdownService,
        private _elementRef: ElementRef,
        private renderer2: Renderer2,
        private _filterStateService: FilterStateService
    ) {}

    ngAfterViewInit() {
        this.loadCostsDistribution();
        this.options.clients = this._cmxDropdownService.toDropdownItems(this.userCacheService.getClientGroups());
    }

    applyFilter() {
        this.loadCostsDistribution();
    }

    detailsCosts() {
        let startDateFormat = moment(this.filterState.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD');
        let endDateFormat = moment(this.filterState.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD');
        let params;
        this.selectedClients.length === 0
            ? (params = {
                  startDate: startDateFormat,
                  endDate: endDateFormat,
              })
            : (params = {
                  clientGroupCode: this.selectedClients[0].id,
                  clientGroupName: this.selectedClients[0].itemName,
                  startDate: startDateFormat,
                  endDate: endDateFormat,
              });
        this.router.navigate(['./financeiro/custos'], { queryParams: params });
    }

    loadCostsDistribution() {
        this.asyncDistributionCost = true;
        this.utilsService.setInvisible('cost-distribution-chart', this.editableElements);
        this.getCostsDistribution().subscribe(
            (costsResponse) => {
                this.chartData = this._absoluteValue(costsResponse.distributedCosts);
                this.verifyZeroedData();
                this.loadDistributionCost('');
                this.asyncDistributionCost = false;
                this.utilsService.setVisible('cost-distribution-chart', this.editableElements);
            },
            (error) => {
                this.chartData = undefined;
                this.asyncDistributionCost = false;
                this.utilsService.errorHandler(error, 'Custos Distribuídos');
            }
        );
    }

    toggleTypeSwitch(mode, element) {
        if ($(element.target).hasClass('checked')) {
            $(element.target).removeClass('checked');
            this.loadDistributionCost('');
        } else {
            $('cost-distribution-chart .checkmark').removeClass('checked');
            $(element.target).addClass('checked');
            const chartMode = mode;
            this.loadDistributionCost(chartMode);
        }
    }

    loadDistributionCost(type?) {
        this.currentCategory = type;
        this.costsDistributionChart = AmCharts.makeChart('costsDistributionChart', {
            ...COST_DISTRIBUTION_CHART_CONFIG,
            dataProvider: type ? this.setCostCategorieItens(type) : this.orderChartValues(this.chartData),
            labelText: '[[labelText]]',
            balloonText: '[[balloonText]]',
            angle: type ? 40 : 30,
            pullOutRadius: type ? 0 : 2,
            balloon: { fixedPosition: type ? true : false },
            listeners: [type ? {} : {
                event: 'clickSlice',
                method: (event) => {
                    this.determineMode(event.dataItem.title);
                },
            }],
        });
        $('a[title*=JavaScript]').attr('style', 'display: none; opacity: 0;');
    }

    datesChanged(event) {
        this.filterState.setStartDate(event.startDate);
        this.filterState.setEndDate(event.endDate);
    }

    changeSearchType(event) {
        this.filterState.setSearchType(event);
    }

    setSearch(event) {
        this.filterState.setSearchValue(event);
    }

    private _absoluteValue(categories) {
        categories.forEach((category) => {
            category['absoluteValue'] = category.value;
            category.value = Math.abs(category.value);
            const formatedValue = Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }).format(Math.abs(category.value)).substring(3);
            category['labelText'] = `${category.name} • ${category.percentage}%`
            category['balloonText'] = `${category.name}<br><span style='font-size:14px'><b>R$ ${
                category.absoluteValue < 0 ? '-' : ''}${formatedValue}</b> (${category.percentage}%)</span>`
            category.expensesItems.forEach((item) => {
                item['absoluteValue'] = item.value;
                item.value = Math.abs(item.value);
                const formatedValue = Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }).format(Math.abs(item.value)).substring(3);
                item['labelText'] = `${item.name} • ${item.percentage}%`
                item['balloonText'] = `${item.name}<br><span style='font-size:14px'><b>R$ ${
                    item.absoluteValue < 0 ? '-' : ''}${formatedValue}</b> (${item.percentage}%)</span>`
            });
        });
        return categories;
    }

    private setCostCategorieItens(categorie) {
        this.currentCategory = categorie;
        let itens;
        this.chartData?.some((cost) => {
            if (cost.name === categorie) {
                itens = this.orderChartValues(cost.expensesItems);
                return true;
            }
        });
        return itens;
    }

    private orderChartValues(itens) {
        itens.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
        itens.forEach((item) => {
            item.name = this.formatColumnName(item.name);
        });
        return itens;
    }

    private formatColumnName(name) {
        const splitted = name.split('-');
        return splitted[splitted.length - 1].replace('(TROP)', '');
    }

    private determineMode(mode) {
        $('cost-distribution-chart .checkmark').removeClass('checked');
        this.loadDistributionCost(mode);
        switch (mode) {
            case 'Valor CIF':
                $('cost-distribution-chart .checkmark#valor').addClass('checked');
                break;
            case 'Impostos da Nacionalização':
                $('cost-distribution-chart .checkmark#impostos').addClass('checked');
                break;
            case 'Despesas Operacionais':
                $('cost-distribution-chart .checkmark#operacionais').addClass('checked');
                break;
            case 'Despesas Financeiras':
                $('cost-distribution-chart .checkmark#financeiras').addClass('checked');
                break;
            case 'Despesas Serviços':
                $('cost-distribution-chart .checkmark#servicos').addClass('checked');
                break;
            case 'Crédito Impostos Entrada':
                $('cost-distribution-chart .checkmark#entrada').addClass('checked');
                break;
            case 'Impostos de Saída':
                $('cost-distribution-chart .checkmark#saida').addClass('checked');
                break;
            case 'IPI':
                $('cost-distribution-chart .checkmark#ipi').addClass('checked');
                break;
        }
    }

    private verifyZeroedData() {
        this.chartData.forEach((cost) => {
            this.zeroedData[cost.name.replace(/\s/g, '').replace(/[^\w\s]/gi, '').toLowerCase()] = cost.value === 0 ? true : false;
        });
    }

    private getCostsDistribution(): Observable<any> {
        return this.http.get(`${environment.endpoints.expensesService}/summary/importationExpenses?resumed=true`, this.filterState.getRequest());
    }

    private _defineHelpDescription(): object[] {
        return [
            { type: 'text', value: "Informa a porcentagem de custos para cada categoria de custo selecionada."},
        ]
    }
}
