<div *ngIf="!asyncData" class="cmx-simple-line-chart"
    [style.height]="height">
    <div (click)="selectItem(data)" *ngFor="let data of chartData; let i = index" 
        class="data {{ clickable && data.value ? 'clickable' : '' }}"
        [style.min-width]="minSectionSize"
        [style.width]="verifyWidth(data)"
        [style.background-color]="data.color + (data.selected && visualSelection ? '99' : '60')"
        [style.border-top-left-radius]=" i === 0 ? '100px' : '0px'"
        [style.border-bottom-left-radius]=" i === 0 ? '100px' : '0px'"
        [style.border-top-right-radius]=" i === chartData.length - 1 ? '100px' : '0px'"
        [style.border-bottom-right-radius]=" i === chartData.length - 1 ? '100px' : '0px'"
        [style.border-bottom]="data.selected && visualSelection ? ('2px solid ' + data.color) : ''">
        <span class="percentage"
            [style.color]="data.color">
            {{ data.value > 999 ? '999+' : data.value }}{{ percentageValue ? '%' : '' }}
        </span>
    </div>
    <div class="legendContainer" [ngClass]="{'inverse-legend': inverseLegend}">
        <div (click)="selectItem(data)" *ngFor="let data of chartData" 
        class="legendContent {{ clickable && data.value ? 'clickable' : '' }}">
            <div class="legend"
                [style.width]="legendSquareSize"
                [style.height]="legendSquareSize"
                [style.background-color]="data.color + (data.selected ? '99' : '60')"
                [style.border]="data.selected && visualSelection ? ('2px solid ' + data.color) : ''"
                [style.cursor]="clickable ? 'pointer' : 'default'"></div>
            <span 
                [style.font-size]="legendFontSize"
                [style.font-weight]="legendBold ? '500' : '100'"
                >{{ data.name }}</span>
            <span class="legend-info">{{ data.valueInfo }}</span>
            <span class="legend-info" *ngIf="data.valueTotal">| {{ data.valueTotal }}</span>
        </div>
    </div>
</div>