import { Injectable } from '@angular/core';
import { AppConfigService } from '#services/_app-config/app-config.service';


@Injectable()
export class FeatureFlagsService {
    private _featureFlags: JSON;

    constructor(private _appConfig: AppConfigService) {
        this._featureFlags = this._appConfig.getConfigObjectKey('featureFlags');
    }

    isFeatureFlagEnabled(flag: string) {
        return this._featureFlags && this._featureFlags[flag] && this._featureFlags[flag].enabled;
    }
}
