export function customIconPosFormatter(iconConfigs, dataContext) {
    let iconElements = '';
    iconConfigs.forEach((iconConfig) => {
        let animationClassname = '';
        const urlIcon = iconConfig.iconTypeUrl ? `<img class="url-icon" src="./assets/app/media/img/icons/${iconConfig.iconTypeUrl}.svg">` : '';
        const checkIconClass = urlIcon ? 'url-custom-icon' : 'material-icons';
        const conditionAnimation = dataContext[iconConfig.animationCondition] || [];

        if (iconConfig.valueAnimationConditions && iconConfig.animation) {
            animationClassname = iconConfig.valueAnimationConditions.includes(conditionAnimation) ? iconConfig.animation : '';
        } else if (!iconConfig.valueAnimationConditions && iconConfig.animation) {
            animationClassname = iconConfig.animation;
        }

        const icon = `<span
            title="${ iconConfig.iconTooltip || ''}"
            class="${checkIconClass} grid-custom-icon ${ iconConfig.iconPosition || 'top-right'} ${iconConfig.iconClickable ? 'clickable' : '' } ${animationClassname}"
            style="color: ${dataContext.customIconColor || iconConfig.iconColor || '#193d69' }; font-size: ${iconConfig.iconFontSize}">
                ${ urlIcon || dataContext.customIconType || iconConfig.iconType || 'info' }
        </span>`;

        if (iconConfig.atributeCondition) {
            const conditionData = dataContext[iconConfig.atributeCondition] || [];

            if (iconConfig.valueConditions) {
                iconElements += iconConfig.valueConditions.includes(conditionData) ? icon : '';
            }
        } else {
            iconElements += icon;
        }
    })
    return iconElements;
}
