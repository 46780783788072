import { AfterContentInit, Component } from '@angular/core';
import { IGridColumn, IGridRow } from 'app/shared/components/cmx-custom-grid/interfaces';
import { ExchangeSpListingService } from 'app/theme/pages/finance/exchange/services/exchange-sp-listing.service';
import { SP_EXCHANGE_BANKING_CHANNELS_COLUMNS } from '../../../constants/sp-exchange-banking-channels-columns.constants';
import * as _ from 'lodash';
import { ExchangeSpBeneficiariesService } from '../../../services/exchange-sp-beneficiaries.service';
import { UtilsService } from '#services/_utils/utils.service';

@Component({
    selector: 'sp-banking-channels',
    templateUrl: './sp-banking-channels.component.html',
    styleUrls: ['./sp-banking-channels.component.scss'],
    providers: [ExchangeSpBeneficiariesService],
})
export class SpBankingChannelsComponent implements AfterContentInit {
    gridColumns: IGridColumn[] = _.cloneDeep(SP_EXCHANGE_BANKING_CHANNELS_COLUMNS);
    gridRows: IGridRow[] = [];
    subscription;
    async;

    constructor(
        private _spListingService: ExchangeSpListingService,
        private _spBeneficiariesService: ExchangeSpBeneficiariesService,
        private _utilsService: UtilsService
    ) {
    }

    ngAfterContentInit(): void {
        this._getBankData()
    }

   private _getBankData() {
        this.async = true;
        this.subscription = this._spBeneficiariesService.getBeneficiarieWithBankData(this._spListingService.getVendor()).subscribe((data:any) => {
            const rows = data.bankData || []
            this.gridRows = rows;
            this.async = false;
        }, (error) => {
            this.gridRows = [];
            this._utilsService.errorHandler(error, 'Canais Bancários');
            this.async = false;
        });
    }
    
}
