<ng-template #painelCadastro>
  <div class="flex-3">
    <h2 class="title-section my-3">Cadastrar novo local</h2>
    <div class="transport-section">
        <p class="transport-label">Selecione o tipo de local</p>
        <div class="transport-type">
            <label class="checkbox" *ngFor="let transport of freightTypeTransport; let i = index; trackBy: trackByFn" [ngClass]="{actived: transport.checked}">
                <input type="checkbox" [id]="'checkbox-transport-' + i" [checked]="transport.checked" (change)="handleModalTransportation($event, i)" [value]="transport.value" />
                <ng-container *ngIf="transport.name === 'Aéreo'">
                <em class="fa fa-plane"></em>
                </ng-container>
                <ng-container *ngIf="transport.name === 'Ferroviário'">
                <em class="fa fa-train"></em>
                </ng-container>
                <ng-container *ngIf="transport.name === 'Marítimo'">
                <em class="fa fa-ship"></em>
                </ng-container>
                <ng-container *ngIf="transport.name === 'Rodoviário'">
                <em class="fa fa-truck"></em>
                </ng-container>
                {{ transport.name }}
            </label>
        </div>
    </div>
    <div class="p-field my-2">
      <label>País</label>
      <p-dropdown id="dropdown-country" [options]="listCountries" scrollHeight="112px" class="comexport-theme" styleClass="sm full"
      optionLabel="country" filterPlaceholder="País" [filter]="true" filterBy="country"
      [placeholder]="labelCountriesFilter" [(ngModel)]="selectedCountry" (ngModelChange)="handleCountry($event)"></p-dropdown>
    </div>
    <div class="p-field">
        <label>Local</label>
        <input type="text" pInputText [(ngModel)]="formValue.name">
    </div>
    <div class="mt-4 d-flex justify-content-between">
        <cmx-button variant="light" id="button-clean" (click)="reset()">Limpar</cmx-button>
        <cmx-button variant="success" id="button-additem" (click)="addItem()" *ngIf="showCreateLocation">Cadastrar</cmx-button>
        <cmx-button variant="success" id="button-updateitem" (click)="update()" *ngIf="!showCreateLocation">Atualizar</cmx-button>
    </div>
  </div>
</ng-template>

<ng-template #painelGrid>
  <div id="locations-filter">
    <div id="searchLine" class="reduzed mb-0">
        <div class="p-field">
            <input type="text" pInputText placeholder="Buscar Local" [(ngModel)]="searchLocation" (keyup.enter)="handleFilterByLocation()">
        </div>

        <button class="applyImpStatus" (click)="handleFilterByLocation()" (disabled)="!searchLocation">
            <i class="fa fa-check"></i>
        </button>
    </div>
    <div class="location-combo-filter">
      <button id="button-clean-location-filter" class="btn-clear" [hidden]="!searchCountry" (click)="resetLocations()">Limpar</button>
      <p-dropdown id="dropdown-location-filter" [options]="listCountries" scrollHeight="112px" [style]="{'width': '290px'}" class="comexport-theme" styleClass="sm full" optionLabel="country" filterPlaceholder="País" [filter]="true" filterBy="country" [placeholder]="labelCountriesFilter" [(ngModel)]="searchCountry" (ngModelChange)="handleFilterByCountry($event)"></p-dropdown>
    </div>
  </div>
  <ng-container *ngIf="gridRows?.length === 0">
    <h4 class="empty-message">Não encontramos resultado para este local.</h4>
  </ng-container>
  <ng-container *ngIf="gridRows?.length">
    <cmx-custom-grid
    id="locationGrid"
    [columns]="gridColumns"
    [rows]="gridRows"
    [options]="{ forceFitColumns: true }"
    (onCallback)="onGridCallback($event)"
    [styles]="{ height: 'calc(100% - 190px)' }"
    ></cmx-custom-grid>
    <div class="section-pagination">
      <div class="total-results">
        Total de registros: {{ locationsPaginationState.totalResults }}
      </div>
      <div *ngIf="locationsPaginationState?.totalPages > 1" class="approval-flow-pagination-container">
        <pagination
        [numberOfPages]="locationsPaginationState?.totalPages"
        [queryId]="locationsPaginationState?.queryId"
        (onPageChange)="changePage($event)"
        ></pagination>
      </div>
    </div>
  </ng-container>
</ng-template>

<div *ngIf = "selectedDeleted === 'delete'" class="modal-wrapper" [ngClass]="'show'">
  <div class="delete-local">
      <p>Deseja excluir essa localidade ?</p>
      <div class="container">
          <cmx-button (click) ="deleteLocations()" variant="danger">
              Confirmar
          </cmx-button>
          <cmx-button variant="light" (click)="selectedDeleted = null">Cancelar</cmx-button>
      </div>
  </div>
</div>

<cmx-page-wrapper [titleComponent]="dataTitle" [layout]="'middle'">
  <ng-container cmx-page-wrapper-body>
    <div class="content">
      <section>
        <aside>
          <ng-container *ngTemplateOutlet="painelCadastro"></ng-container>
        </aside>
        <div class="content-locations">
          <div class="loader-overflow" *ngIf="loadLocations">
            <loader></loader>
          </div>
          <ng-container *ngTemplateOutlet="painelGrid"></ng-container>
        </div>
      </section>
    </div>
  </ng-container>
</cmx-page-wrapper>
