<cmx-custom-dialog [id]="infoDialogId" content="middle" animation="left-right">

    <ng-container cmx-custom-dialog-header>
        <div class="alert-detail-header">
            <span class="title">
                {{ info?.title }}
            </span>
        </div>
    </ng-container>

    <ng-container cmx-custom-dialog-body>

        <ng-container *ngIf="info">
            <p style="font-size: 11px; font-weight: 500">
                {{ info?.primaryText }}
            </p>
            <p>Então, dividimos em {{ info.grey ? '4' : '3' }} status:</p>
            <p><b style="color: green;">Verde:</b> {{info?.green}}</p>
            <p><b style="color: rgb(150, 153, 0);">Amarelo:</b> {{info?.yellow}}</p>
            <p><b style="color: rgb(128, 0, 0);">Vermelho:</b> {{info?.red}}</p>
            <p *ngIf="info.grey"><b style="color: rgb(61, 61, 61);">Cinza:</b> {{info?.grey}}</p>
        </ng-container>

        <hr>

        <ng-container>
            <p style="font-size: 11px; font-weight: 500">
                {{ info?.secondaryText }}
            </p>
        </ng-container>

        <ng-container *ngIf="secondaryType === 'table'">
            <cmx-custom-grid
                [columns]="gridColumns"
                [rows]="gridRows"
                [options]="{ forceFitColumns: false }"
                [styles]="{ height: '570px', 'margin-top': 0 }"
            ></cmx-custom-grid>
        </ng-container>

        <ng-container *ngIf="secondaryType === 'list'">
            <div *ngFor="let data of info.secondaryData">
                <p style="margin-bottom: 7px"><b>{{data.name}}: </b><span>{{data.value}}</span></p>
            </div>
        </ng-container>

        <p style="font-size: 11px; font-weight: 500; margin-top: 15px"> {{ info?.thirdText }} </p>
        <p style="font-size: 11px; font-weight: 500"> {{ info?.fourthText }} </p>

    </ng-container>

</cmx-custom-dialog>