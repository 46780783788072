export const transformations = {
    amounts: {
        "cents": null,
        "no_cents": (data: any, prop: string) => fn.round(fn.toNumber(data[prop])).toLocaleString(),
        "thousands": (data: any, prop: string) => fn.round(fn.toNumber(data[prop]) / 1000).toLocaleString(),
        "millions": (data: any, prop: string) => fn.round(fn.toNumber(data[prop]) / 1000000).toLocaleString()
    },
    description: {
        accountCodes: {
            "true": (data: any) => `${data.id} - ${data.description}`,
            "false": (data: any) => data.description
        }
    },
    style: (node: any) => {
        if (node.sectionHeader) {
            node.expanded = true;
            node.styleClass = 'section-header';
        }

        if (node.total) {
            node.styleClass = 'total-line';
        }
    }
}

export const fn = {
    toNumber: (value: string) => +(value.replace(/\./g, '').replace(',', '.')),
    round: (value: number) => value >= 0 ? Math.round(value)
        : value % 0.5 === 0 ? Math.floor(value)
            : Math.round(value)
}
