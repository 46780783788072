import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'cmx-filter-wrapper',
    templateUrl: './cmx-filter-wrapper.component.html',
    styleUrls: ['./cmx-filter-wrapper.component.scss'],
})
export class FilterWrapperComponent {
    @Input() blocked: boolean = false;
    @Input('flex') elementFlex: boolean = true;

    @Output('onClick') onClickButtonFilter: EventEmitter<any> = new EventEmitter();

    @ViewChild('filterWrapper') _elFilterWrapper: ElementRef;

    ngAfterViewInit(){
        setTimeout(() => {
            const groupsWrapper = this._elFilterWrapper.nativeElement.querySelectorAll('cmx-filter-wrapper-group');

            if(groupsWrapper.length > 0){
                groupsWrapper[0].children[0].classList.add('first-group');

                if(groupsWrapper[0].getAttribute('label')){
                    groupsWrapper[0].children[0].classList.add('first-label');
                }

                groupsWrapper.forEach(group => {
                    if(group.getAttribute('not-divisor') && group.getAttribute('label')){
                        group.children[0].classList.add('remove-divisor-label');
                    }
                });
            }
        });
    }

    onClickFilter($event) {
        this.onClickButtonFilter.emit($event);
    }
}
