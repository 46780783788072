export class FeatureToggle {
    public readonly name: string;
    public readonly enabled: boolean;

    constructor(name: string, enabled: boolean) {
        this.name = name;
        this.enabled = enabled;
    }
}

export class FeatureVariant {
    public readonly type: string;
    public readonly value: string;

    constructor(type: string, value: string) {
        this.type = type;
        this.value = value;
    }
}