import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable()
export class FollowUpReportNfFormatter {

    // Número NF
    public formatNfNumber(row, cell, value, columnDef, dataContext) {
        const nfs = [];
        value?.forEach((nf) => {
            if (!nfs.includes(nf.invoiceNumber) && nf.invoiceNumber !== null) {
                nfs.push(nf.invoiceNumber);
            }
        });
        if (nfs.length === 1) {
            if (nfs[0] === null || nfs[0] === undefined || nfs[0] === '') {
                return '';
            }
            return nfs[0];
        } else if (nfs.length > 1) {
            return `<span class="multiple-${columnDef.field}">Vários (${nfs.length})</span>`;
        } else {
            return '';
        }
    }

    // Data NF
    public formatNfDate(row, cell, value, columnDef, dataContext) {
        const nfs = [];
        value?.forEach((nf) => {
            if (!nfs.includes(nf.invoiceDate) && nf.invoiceDate !== null) {
                nfs.push(nf.invoiceDate);
            }
        });
        if (nfs.length === 1) {
            if (nfs[0] === null || nfs[0] === undefined || nfs[0] === '') {
                return '';
            }
            return `<span class='dateValue'>${moment(nfs[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (nfs.length > 1) {
            return `<span class="multiple-${columnDef.field}">Várias (${nfs.length})</span>`;
        } else {
            return '';
        }
    }

    // Valor NF
    public formatNfValue(row, cell, value, columnDef, dataContext) {
        const format = { minimumFractionDigits: 2 };
        const nfs = [];
        value?.forEach((nf) => {
            if (!nfs.includes(nf.amount) && nf.amount !== null) {
                nfs.push({
                    amount: nf.amount,
                    currency: nf.currency,
                });
            }
        });
        if (nfs.length === 1) {
            if (nfs[0].amount === null || nfs[0].amount === undefined || nfs[0].amount === '') {
                return '';
            }
            return `<span class="formatValue">${nfs[0].currency} ${nfs[0].amount.toLocaleString('pt-BR', format)}</span>`;
        } else if (nfs.length > 1) {
            return `<span class="multiple-${columnDef.field}">Várias (${nfs.length})</span>`;
        } else {
            return '';
        }
    }

    // Tipo NF
    public formatNfType(row, cell, value, columnDef, dataContext) {
        const nfs = [];
        value?.forEach((nf) => {
            if (!nfs.includes(nf.type) && nf.type !== null) {
                nfs.push(nf.type);
            }
        });
        if (nfs.length === 1) {
            if (nfs[0] === null || nfs[0] === undefined || nfs[0] === '') {
                return '';
            }
            return `<span class="type ${nfs[0]}">${nfs[0]}</span>`;
        } else if (nfs.length > 1) {
            return `<span class="multiple-${columnDef.field}">Vários (${nfs.length})</span>`;
        } else {
            return '';
        }
    }

    // Transportadora NF
    public formatShippingCompany(row, cell, value, columnDef, dataContext) {
        const nfs = [];
        value?.forEach((nf) => {
            if (!nfs.includes(nf.shippingCompanyDescription) && nf.shippingCompanyDescription !== null && nf.shippingCompanyDescription !== '') {
                nfs.push(nf.shippingCompanyDescription);
            }
        });
        if (nfs.length === 1) {
            if (nfs[0] === null || nfs[0] === undefined || nfs[0] === '') {
                return '';
            }
            return nfs[0];
        } else if (nfs.length > 1) {
            return `<span class="multiple-${columnDef.field}">Vários (${nfs.length})</span>`;
        } else {
            return '';
        }
    }

    // FILIAL NF DE FATURAMENTO 
    public formatBillingCnpj(row, cell, value, columnDef, dataContext) {
        const billingCnpjs = [];

        value?.forEach((invoice) => {
            const billingCnpj = invoice.billingCnpj;
            if (!billingCnpjs.includes(billingCnpj) && billingCnpj !== null && billingCnpj !== '') {
                billingCnpjs.push(billingCnpj);
            }
        });
        if (billingCnpjs.length === 1) {
            if (billingCnpjs[0] === null || billingCnpjs[0] === undefined || billingCnpjs[0] === '') {
                return '';
            }
            return billingCnpjs[0];
        } else if (billingCnpjs.length > 1) {
            return `<span class="multiple-${columnDef.field}">Vários (${billingCnpjs.length})</span>`;
        } else {
            return '';
        }
    }

    // Total Faturado
    public formatTotalAmountValue(row, cell, value, columnDef, dataContext) {
        const format = { minimumFractionDigits: 2 };
        if (value === null || value === undefined || value === '') {
            return '';
        } else {
            return `<span class="formatValue">R$ ${value.toLocaleString('pt-BR', format)}</span>`;
        }
    }
}
