import { UtilsService } from '#services/_utils/utils.service';
import { Component, Input, Output, ViewEncapsulation, EventEmitter, SimpleChanges } from '@angular/core';
import { COLOR_LIST } from './constants/cmx-save-config-color-list.constant';
import { ICON_LIST } from './constants/cmx-save-config-icon-list.constant';
import { ISave } from './interfaces/save.interface';
import * as _ from 'lodash';

@Component({
    selector: 'cmx-save-config',
    templateUrl: './cmx-save-config.component.html',
    styleUrls: ['./cmx-save-config.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CmxSaveConfigComponent {

    @Input() iconList = ICON_LIST;
    @Input() colorList = COLOR_LIST;

    @Input() saveList: ISave[] = [];
    @Input() title: string = '';
    @Input() description: string = '';
    @Input() selectedIcon?: string;
    @Input() selectedColor?: string;
    @Input() selectedSave?: ISave;
    @Input() iconsBaseHeight: string;
    @Input() savesBaseHeight: string;
    @Input() forceClear: boolean = false;

    auxSelectedSave: ISave;
    showDeleteModal: boolean = false;

    @Input() enableNewSave: boolean = true;
    @Input() startAsNew: boolean = false;

    @Output('onChange') onChange = new EventEmitter();
    @Output('onNew') onNew = new EventEmitter();
    @Output('onDelete') onDelete = new EventEmitter();
    @Output('onSelect') onSelect = new EventEmitter();

    constructor(private _utilsService: UtilsService) {
        if (!this.selectedSave && this.saveList.length) {
            this.selectSave(this.saveList[0])
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.selectedSave) {
            if (changes?.selectedSave?.currentValue) {
                this.selectSave(this.selectedSave);
            }
        }
        if (changes && changes.forceClear) {
            if (changes?.forceClear?.currentValue) {
                this.newSave()
                this.forceClear = false;
            }
        }
    }

    ngOnInit(): void {
        if (this.iconList.length < 2) {
            this.iconList = ICON_LIST;
            console.error('A lista de ícones deve conter ao menos 2 ícones');
        }
        if (this.colorList.length !== 8) {
            this.colorList = COLOR_LIST;
            console.error('A lista de cores deve conter 8 cores');
        }
        if (!this.selectedIcon) {
            this.selectedIcon = this.iconList[0];
        }
        if (!this.selectedColor) {
            this.selectedColor = this.colorList[0];
        }
        if (this.startAsNew) {
            this.newSave();
        }
    }

    ngAfterViewInit() {
        if (!this.iconsBaseHeight && !this.savesBaseHeight) {
            this._calculateHeight();
        }
    }

    selectIcon(icon): void {
        this.selectedIcon = icon;
        this.emitChanges();
    }

    selectColor(color): void {
        this.selectedColor = color;
        this.emitChanges();
    }

    selectSave(save: ISave) {
        this.selectedSave = save;
        this.auxSelectedSave = _.cloneDeep(save);
        this.title = save?.title;
        this.description = save.description || '';
        this.selectedIcon = save.icon;
        this.selectedColor = save.color; 
        this.onSelect.emit(save);
        this.emitChanges();
    }

    newSave(): void {
        this.selectedSave = null;
        this.auxSelectedSave = null;
        this.title = '';
        this.description = '';
        this.selectedIcon = this.iconList[0];
        this.selectedColor = this.colorList[0];
        this.onNew.emit({ 
            title: this.title,
            description: this.description,
            icon: this.iconList[0],
            color: this.colorList[0]
        });
    }

    deleteSave() {
        this.showDeleteModal = false;
        this.onDelete.emit(this.auxSelectedSave);
    }

    openDeleteModal() {
        this.showDeleteModal = true;
    }

    emitChanges() {
        this.onChange.emit({
            save: this.selectedSave,
            title: this.title,
            description: this.description,
            icon: this.selectedIcon,
            color: this.selectedColor,
        })
    }

    private _calculateHeight() {
        this.iconsBaseHeight = `calc(100vh - ${this._utilsService.getTopDistanceByElement('cmx-save-config-icon-container', 20)}px)`;
        this.savesBaseHeight = `calc(100vh - ${this._utilsService.getTopDistanceByElement('cmx-save-config-saveList-container', 20)}px)`;
    }

}
