
import { FilterStateService } from '#services/_filters/filter-state.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UtilsService } from '#services/_utils/utils.service';
import { Component, ViewEncapsulation } from '@angular/core';
import { UserService } from 'app/auth/_services';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import * as _ from 'lodash';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { MOVEMENT_BY_MODAL_CHART_CONFIG } from './constants/movement-by-modal-chart.constant';
import { MOVEMENT_BY_MODAL_TYPES_CHART_CONFIG } from './constants/movement-by-modal-type-chart.constant';
import { TYPE_COLORS } from './constants/movement-by-modal-type-colors.contant';
import { MOVEMENT_BY_MODAL_TYPES } from './constants/movement-by-modal-types.constant';
import { MovementByModalFilter } from './movement-by-modal.filter';
import { MovementByModalService } from './movement-by-modal.service';

@Component({
    selector: 'movement-by-modal',
    templateUrl: './movement-by-modal.component.html',
    styleUrls: ['./movement-by-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MovementByModalComponent {

    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);
    helpDescription;
    filterState: MovementByModalFilter = this._filterStateService.getMovementByModalFilter();
    modalType = 'Marítima';
    modalTypes = [{ name: 'Marítima' }, { name: 'Aérea' }, { name: 'Rodoviária' }, { name: 'Veículos' },]
    currentUser$ = this.userService.currentUser$;
    async: boolean = false;
    secondaryAsync: boolean = false;


    pages = [];
    page = 0;
    dropdown: {
        clients: { options: DropdownOption[]; selected: DropdownOption[] };
        states: { options: DropdownOption[]; selected: DropdownOption[] };
        warehouses: { options: DropdownOption[]; selected: DropdownOption[] };
        referenceDateType: { options: DropdownOption[]; selected: DropdownOption[] };
    } = {
        clients: { options: [], selected: [] },
        states: { options: [], selected: [] },
        warehouses: { options: [], selected: [] },
        referenceDateType: { options: [
            { id: 'arrival', itemName: 'Atracação' },
            { id: 'previsionArrival', itemName: 'ETA' },
        ], selected: [] },
    };

    constructor(
        private userService: UserService,
        private _filterStateService: FilterStateService,
        private _movementByModalService: MovementByModalService,
        private _cmxDropdownService: CmxDropdownService,
        private _userCacheService: UserCacheService,
        private _utilsService: UtilsService,
    ) {
        this._populateDropdowns();
        this.applyFilters();
        this.helpDescription = this._defineHelpDescription();
    }

    applyFilters() {
        this.async = true;
        this.secondaryAsync = true;
        this.helpDescription = this._defineHelpDescription();
        this._movementByModalService.getMovementByModal(
            this.filterState.getType(), this.filterState.getRequest()
        ).subscribe((data) => {
            this.filterState.savePreferences();
            setTimeout(() => {
                this._createChart(this._prepareData(data));
                if (this.filterState.getType() === 'Marítima' || this.filterState.getType() === 'Aérea') {
                    this._getSecondaryChartData()
                }
            }, 300);
        }, (error) => {
            this._utilsService.errorHandler(error, `Movimentação ${this.filterState.getType()}`);
            this.async = false;
        });
    }

    changeTypeChartPage(direction) {
        this.page += direction;
        this._createSecondaryChart();
    }

    private _prepareData(movementData) {
        const dataList = movementData.data.map((data) => ({
            ...data,
            month: moment(data.yearMonth, 'YYYYMM').format('MM'),
        })).sort((a, b) => a.month.localeCompare(b.month))
        .map((data) => {
            const month = this._utilsService.capitalize(moment(data.month, 'MM').format('MMMM'));
            const formattedPercentage = this._utilsService.formatNumber(data.percentage, { style: 'percent' });
            const label = `${month}<br>${formattedPercentage}`;
            return { ...data, month, label };
        });
        return { ...movementData, data: dataList }
    }

    private _createChart(data): void {
        const chart = AmCharts.makeChart('movementChart', this._defineChartSettings(data));
        setTimeout(() => {
            const chart = AmCharts.makeChart('movementChart', this._defineChartSettings(data));
            this._removeChartLogo(chart)
            this.async = false;
        }, 200);
        this._removeChartLogo(chart)
    }

    private _createSecondaryChart(): void {
        const chart = AmCharts.makeChart('secondaryChart', this._defineSecondaryChartSettings());
        this._removeChartLogo(chart)
    }

    private _getSecondaryChartData(): void {
        this._resetPages();
        this._movementByModalService.getMovementByStateAndWarehouse(
            this.filterState.getType(), this.filterState.getStateAndWarehouseRequest()
        ).subscribe((data) => {
            const responseData = data.data;
            let pageSession = 0;
            while(responseData.length) {
                this.pages[pageSession] = responseData.slice(0, 3).map((data, index) => {
                    const fractionDigits = this.filterState.getType() !== 'Aérea' ? 0 : 2;
                    const formattedPercentage = this._utilsService.formatNumber(data.percentage, { style: 'percent',
                        minimumFractionDigits: fractionDigits,
                        maximumFractionDigits: fractionDigits,
                    });
                    const color = TYPE_COLORS[index] || TYPE_COLORS[0];
                    return this.filterState.getSecondaryType() === 'Estado' ? {
                        ...data, color, label: `${data.code}<br>${formattedPercentage}`
                    } : {
                        ...data, color, label: `${data.code}<br>${data.name.split('-')[1]}<br>${formattedPercentage}`
                    }
                });
                responseData.splice(0, 3);
                pageSession++;
            }
            this._createSecondaryChart();
            this.secondaryAsync = false;
        }, (error) => {
            this.secondaryAsync = false;
            this._utilsService.errorHandler(error, `Movimentação ${this.filterState.getType()}`);
        })
    }

    private _populateDropdowns() {
        this.dropdown.clients.options = this._cmxDropdownService.toDropdownItems(this._userCacheService.getClientGroups());
        forkJoin(
            this._movementByModalService.getLogisticsStates(),
            this._movementByModalService.getLogisticsWarehouses(),
        ).subscribe(([states, warehouses]) => {
            this.dropdown.states.options = this._cmxDropdownService.toDropdownItems(states, false);
            this.dropdown.warehouses.options = this._cmxDropdownService.toDropdownItems(
                [...warehouses.map((d) => ({ ...d, name: `${d.code}-${d.name}` }))], false
            );
        }, (error) => {
            this._utilsService.errorHandler(error, `Movimentação ${this.filterState.getType()}`);
        });
    }

    private _defineChartSettings(data) {
        const settings = {
            ...MOVEMENT_BY_MODAL_CHART_CONFIG,
            dataProvider: data.data
        }
        settings.valueAxes[0]['title'] = this.filterState.getType() === 'Marítima' ? this.filterState.getTypeView() :
        MOVEMENT_BY_MODAL_TYPES[this.filterState.getType()].subType;

        const period = data.period.split('-');
        settings.graphs[0]['title'] = period[0];
        settings.graphs[1]['title'] = period[1];

        const labelFunction = (value) => {
            const fractionDigits = this.filterState.getType() !== 'Aérea' ? 0 : 2;
            return this._utilsService.formatNumber(value.values.value, {
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits,
            });
        }
        settings.graphs[0]['labelFunction'] = labelFunction;
        settings.graphs[1]['labelFunction'] = labelFunction;

        return settings;
    }

    private _defineSecondaryChartSettings() {
        const settings =             {
            ...MOVEMENT_BY_MODAL_TYPES_CHART_CONFIG,
            dataProvider: this.pages[this.page]
        }
        settings.valueAxes[0]['title'] = this.filterState.getType() === 'Marítima' ? this.filterState.getTypeView() :
        MOVEMENT_BY_MODAL_TYPES[this.filterState.getType()].subType;

        const labelFunction = (value) => {
            const fractionDigits = this.filterState.getType() !== 'Aérea' ? 0 : 2;
            return this._utilsService.formatNumber(value.values.value, {
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits,
            });
        }
        settings.graphs[0]['labelFunction'] = labelFunction;

        return settings
    }

    private _resetPages() {
        this.pages = [];
        this.page = 0;
    }

    private _defineHelpDescription(): object[] {
        if (this.filterState.getType() === 'Marítima') {
            return [
                { type: 'text',hasLineAfter: true, value: "Informa o volume de TEU’s ou Containers movimentados ao longo do ano."},
                { type: 'text', hasLineAfter: true, value:'Compara a movimentação realizada com o período anterior.'},
                { type: 'text', hasLineAfter: true, value: 'Permite analisar se houve aumento ou diminuição no volume embarcado por Recinto e Estado.' },
                { type: 'text', value: "Dados com base na data de chegada dos embarques."},
            ]
        } else if (this.filterState.getType() === 'Aérea') {
            return [
                { type: 'text',hasLineAfter: true, value: "Informa o volume de Toneladas Aéreas movimentados ao longo do ano."},
                { type: 'text', hasLineAfter: true, value:'Compara a movimentação realizada com o período anterior.'},
                { type: 'text', hasLineAfter: true, value: 'Permite analisar se houve aumento ou diminuição no volume embarcado por Recinto e Estado.' },
                { type: 'text', value: "Dados com base na data de chegada dos embarques."},
            ]
        } else if (this.filterState.getType() === 'Rodoviária') {
            return [
                { type: 'text',hasLineAfter: true, value: "Informa o volume de Carretas Internacionais movimentados ao longo do ano."},
                { type: 'text', hasLineAfter: true, value:'Compara a movimentação realizada com o período anterior.'},
                { type: 'text', value: "Dados com base na data de chegada dos embarques."},
            ]
        } else if (this.filterState.getType() === 'Veículos') {
            return [
                { type: 'text',hasLineAfter: true, value: "Informa o volume de Veículos movimentados ao longo do ano."},
                { type: 'text', hasLineAfter: true, value:'Compara a movimentação realizada com o período anterior.'},
                { type: 'text', value: "Dados com base na data de chegada dos embarques."},
            ]
        }
    }

    private _removeChartLogo(chart) {
        chart.addListener('drawn', () => {
            $('a[title*=JavaScript]').attr('style', 'display: none; opacity: 0;');
            $('g[aria-label*=Meta]').attr('style', 'display: none; opacity: 0;');
        });
        $('a[title*=JavaScript]').attr('style', 'display: none; opacity: 0;');
        $('g[aria-label*=Meta]').attr('style', 'display: none; opacity: 0;');
    }
}
