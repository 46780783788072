import { Component, ViewEncapsulation } from '@angular/core';

import { AutomotiveDetailDailyService } from './detail-daily.service';

@Component({
    selector: 'automotive-detail-daily',
    templateUrl: './detail-daily.component.html',
    styleUrls: ['../reports.component.scss', './detail-daily.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DetailAutomotiveDailyReportComponent {

    constructor(public automotiveDetailDailyService: AutomotiveDetailDailyService) { }

}
