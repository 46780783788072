import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable()
export class FollowUpReportExchangeFormatter {
    static readonly formatCurrency = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
    static readonly formatFraction = { minimumFractionDigits: 4, maximumFractionDigits: 4 };

    public formatPaymentExchangeDate(row, cell, value, columnDef, dataContext) {
        if(!value){ return '' }
        const entries = value.entries;
        if (entries.length === 1) {
            if (entries[0] === null || entries[0] === undefined || entries[0] === '') {
                return '';
            }
            return `<span class='dateValue'>${moment(entries[0].paymentExchangeDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (entries.length > 1) {
            return `<span class="multipleExchangeDetailEntry">Várias (${entries.length})</span>`;
        } else {
            return '';
        }
    }

    public formatExchangeDueDate(row, cell, value, columnDef, dataContext) {
        if(!value){ return '' }
        if (value.length === 1) {
            if (value[0].exchangeDueDate === null || value[0].exchangeDueDate === undefined || value[0].exchangeDueDate === '') {
                return '';
            }
            return `<span class='dateValue'>${moment(value[0].exchangeDueDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (value.length > 1) {
            return `<span class="multipleExchangeDueDates">Várias (${value.length})</span>`;
        } else {
            return '';
        }
    }

    public formatExchangeContractNumber(row, cell, value, columnDef, dataContext) {
        if (!value || !value.entries || value.entries.length === 0) {
            return '';
        }
        if (value && value.entries) {
            if (value.entries[0].exchangeContractNumber === null ||
                value.entries[0].exchangeContractNumber === undefined ||
                value.entries[0].exchangeContractNumber === '') {
                return '';
            }
            if (value.entries.length === 1) {
                return value.entries[0].exchangeContractNumber;
            }
            return `<span class="multipleExchangeDetailEntry">Várias (${value.entries.length})</span>`;
        }
        return '';
    }

    public formatExchangeRate(row, cell, value, columnDef, dataContext) {
        if (!value || !value.entries || value.entries.length === 0) {
            return '';
        }
        if (value && value.entries) {
            if (value.entries[0].exchangeAmountLocal === null ||
                value.entries[0].exchangeAmountLocal === undefined ||
                value.entries[0].exchangeAmountLocal === '') {
                return '';
            }
            if (value.entries.length === 1) {
                return `<span class="formatValue">${value.entries[0].exchangeRate.toLocaleString('pt-BR', FollowUpReportExchangeFormatter.formatFraction)}</span>`;
            }
            return `<span class="multipleExchangeDetailEntry">Várias (${value.entries.length})</span>`;
        }
        return '';
    }

    public formatExchangeAmountLocal(row, cell, value, columnDef, dataContext) {
        if (!value || !value.entries || value.entries.length === 0) {
            return '';
        }
        if (value && value.entries) {
            if (value.entries[0].exchangeAmountLocal === null ||
                value.entries[0].exchangeAmountLocal === undefined ||
                value.entries[0].exchangeAmountLocal === '') {
                return '';
            }
            if (value.entries.length === 1) {
                return `<span class="formatValue">R$${value.entries[0].exchangeAmountLocal.toLocaleString('pt-BR', FollowUpReportExchangeFormatter.formatCurrency)}</span>`;
            }
            return `<span class="multipleExchangeDetailEntry">Várias (${value.entries.length})</span>`;
        }
        return '';
    }

    public formatExchangeAmountForeign(row, cell, value, columnDef, dataContext) {
        if (!value || !value.entries || value.entries.length === 0) {
            return '';
        }
        if (value && value.entries) {
            if (value.entries[0].exchangeAmountForeign === null ||
                value.entries[0].exchangeAmountForeign === undefined ||
                value.entries[0].exchangeAmountForeign === '') {
                return '';
            }
            if (value.entries.length === 1) {
                return `<span class="formatValue">
                ${value.invoiceCurrency}
                ${value.entries[0].exchangeAmountForeign.toLocaleString('pt-BR', FollowUpReportExchangeFormatter.formatCurrency)}</span>`;
            }
            return `<span class="multipleExchangeDetailEntry">Várias (${value.entries.length})</span>`;
        }
        return '';
    }

    public formatExchangeAmountForeignTotal(row, cell, value, columnDef, dataContext) {
        if (value && value.exchangeAmountForeignTotal) {
            const styleClass = `formatValue${
                value.entries != null && value.entries.length > 1 ? " multipleExchangeDetailEntry" : ""
            }`;
            return `<span class="${styleClass}">
            ${value.invoiceCurrency}
            ${value.exchangeAmountForeignTotal.toLocaleString('pt-BR', FollowUpReportExchangeFormatter.formatCurrency)}
            </span>`;
        }
        return '';
    }

    public formatExchangeRateTotal(row, cell, value, columnDef, dataContext) {
        if (value && value.exchangeRateTotal) {
            const styleClass = `formatValue${
                value.entries != null && value.entries.length > 1 ? " multipleExchangeDetailEntry" : ""
            }`;
            return `<span class="${styleClass}">
                ${value.exchangeRateTotal.toLocaleString('pt-BR', FollowUpReportExchangeFormatter.formatFraction)}
            </span>`;
        }
        return '';
    }

    public formatExchangeAmountLocalTotal(row, cell, value, columnDef, dataContext) {
        if (value && value.exchangeAmountLocalTotal) {
            const styleClass = `formatValue${
                value.entries != null && value.entries.length > 1 ? " multipleExchangeDetailEntry" : ""
            }`;
            return `<span class="${styleClass}">
                R$ ${value.exchangeAmountLocalTotal.toLocaleString('pt-BR', FollowUpReportExchangeFormatter.formatCurrency)}
            </span>`;
        }
        return '';
    }

    public formatDiscountWithoutCoverageTotal(row, cell, value, columnDef, dataContext) {
        if (value && value.discountWithoutCoverageTotal) {
            const styleClass = `formatValue${
                value.entries != null && value.entries.length > 1 ? " multipleExchangeDetailEntry" : ""
            }`;

            return `<span class="${styleClass}">
                R$ ${value.discountWithoutCoverageTotal.toLocaleString('pt-BR', FollowUpReportExchangeFormatter.formatCurrency)}
            </span>`;
        }
        return '';
    }

    public formatExchangeBalanceTotal(row, cell, value, columnDef, dataContext) {
        if (value && value.exchangeBalanceTotal != null) {
            const styleClass = `formatValue${
                value.entries != null && value.entries.length > 1 ? " multipleExchangeDetailEntry" : ""
            }`;
            return `<span class="${styleClass}">
            ${value.invoiceCurrency}
            ${value.exchangeBalanceTotal.toLocaleString('pt-BR', FollowUpReportExchangeFormatter.formatCurrency)}
            </span>`;
        }
        return '';
    }

    // ProductCodes
    public formatExpense(row, cell, value, columnDef, dataContext) {
        const format = { minimumFractionDigits: 2 };
        const splitedPath = columnDef.path.split('.');
        const domainColumn = splitedPath[splitedPath.length - 1];
        const expenses = [];
        value.forEach((expense) => {
            if (domainColumn === 'productCode') {
                expenses.push(expense[domainColumn]);
            } else {
                const object = {
                    productCode: expense.productCode,
                };
                object[domainColumn] = expense[domainColumn];
                expenses.push(object);
            }
        });
        if (expenses.length === 1) {
            if (domainColumn === 'productCode') {
                return expenses[0];
            } else if (domainColumn === 'vendorInvoiceNumber') {
                return expenses[0][domainColumn];
            } else {
                if (expenses[0][domainColumn] !== null && expenses[0][domainColumn] !== undefined) {
                    return `<span class="formatValue">R$
                    ${expenses[0][domainColumn].toLocaleString('pt-BR', format)}</span>`;
                } else {
                    return '';
                }
            }
        } else if (expenses.length > 1) {
            return `<span class="multipleExpense ${domainColumn}">Várias (${expenses.length})</span>`;
        } else {
            return '';
        }
    }
}
