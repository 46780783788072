import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { environment } from '#environment';

@Injectable()
export class ImpStatusService {
    constructor(private _http: HttpClient) {}

    getImpStatusList(request: any): Observable<any> {
        return this._http.post(`${environment.endpoints.importationMongoDBService}/imps/status/importation/count`, request.params, { headers: new HttpHeaders().set('X-Requested-Client-Groups', request.headers.clientCodes) })
        .pipe(
            map((impsStatusList: any) => {
                const status = impsStatusList && impsStatusList?.statusCount;

                return _.reduce(
                    status,
                    (prev, curr) => {
                        return [...prev, _.omit(_.merge(curr, curr.status), 'status')];
                    }, []);
            })
        );
    }
}
