import { HttpHeaders, HttpParams } from '@angular/common/http';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { AppModule } from 'app/app.module';
import * as _ from 'lodash';
import { IBillOfLadingFilter } from '../interfaces/bill-of-lading.interface';

export class BillOfLadingFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initiaChannelStatusFilter: IBillOfLadingFilter= <IBillOfLadingFilter>{};
    private _billOfLadingFilter: IBillOfLadingFilter = <IBillOfLadingFilter>{
        clientGroups: [],
        subsidiaryName: [],
        status: [],
    };

    private filterField;
    private filterValue;
    private page: number;
    private size: number;

    constructor(filter?: BillOfLadingFilter) {
        this._billOfLadingFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'billOfLadingFilter', this._billOfLadingFilter, filter);
        this._initiaChannelStatusFilter = _.cloneDeep(this._billOfLadingFilter);
        this.filterField = filter ? filter.filterField : '';
        this.filterValue = filter ? filter.filterValue : '';
        this.page = filter ? filter.page : 0;
        this.size = filter ? filter.size : 40;
    }

    public getClientGroups(): string[] { return this._billOfLadingFilter.clientGroups; }
    public getSubsidiaryName(): any[] { return this._billOfLadingFilter.subsidiaryName; }
    public getStatus(): any[] { return this._billOfLadingFilter.status; }
    public getFilterField() { return this.filterField; }
    public getFilterValue() { return this.filterValue; }
    public getPage(): number { return this.page; }
    public getSize(): number { return this.size; }

    public setClientGroups(clientGroups): void { this._billOfLadingFilter.clientGroups = clientGroups; }
    public setSubsidiaryName(subsidiaryName): void { this._billOfLadingFilter.subsidiaryName = subsidiaryName; }
    public setStatus(status): void { this._billOfLadingFilter.status = status; }
    public setFilterField(filterField): void { this.filterField = filterField; }
    public setFilterValue(filterValue): void { this.filterValue = filterValue; }
    public setPage(page): void { this.page = page; }
    public setSize(size): void { this.size = size; }

    public getRequest() {
        this._initiaChannelStatusFilter = this._userPreferencesService.verifyFilterChanges(
            this._initiaChannelStatusFilter, this._billOfLadingFilter, 'billOfLadingFilter', this._userCacheService.getUserPreferences().id
        );

        let params = new HttpParams();
        let headers = new HttpHeaders();

        if (this.getFilterValue()) {
            params = params.append('field', `${this.getFilterField()}::${this.getFilterValue()}`);
        }

        if(this.getClientGroups()?.length > 0) {
            headers = headers.set('X-Requested-Client-Groups', this.getClientGroups().map((c) => c['id']).join(','));
        }

        if(this.getSubsidiaryName()?.length > 0) {
            params = params.append('field',`subsidiaryName::${this.getSubsidiaryName().map((c) => c.id).join(',')}`);
        }

        if(this.getStatus()?.length > 0) {
            params = params.append('field', `status::${this.getStatus().map((c) => (c.id !== c.itemName ? c.id : null)).join(',')}`);
        }

        params = params.append('page', `${this.getPage()}`);
        params = params.append('size', `${this.getSize()}`);

        return { params, headers };
    }
}
