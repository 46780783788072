import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '#environment';
import { ReportPreferencesService } from '#services/_report-preferences/report-preferences.service';

@Injectable()
export class DateNotificationService {

    constructor(private _http: HttpClient,
        public reportPreferencesService: ReportPreferencesService,
    ) { }

    getEmailList(clientGroupCode: string, internalUser: boolean): Observable<any> {
        const headers = new HttpHeaders();
        let params = new HttpParams();
        params = params.append('excludeDeleted', 'true')
        params = params.append('onlyActive', 'true')
        params = params.append('ignoreGroupAll', internalUser ? 'false' : 'true')
        params = params.append('clientGroup', clientGroupCode);
        return this._http.get(`${environment.endpoints.adminService}/users`, { params, headers });
    }

    getDataDefaultList(isCustomer): Observable<any> {
        return this.reportPreferencesService.getDefaultReport('FOLLOWUP_SKU', isCustomer);
    }

    getDataList(clientGroupCode): Observable<any> {
        let headers = new HttpHeaders();
        let params = new HttpParams();
        headers = headers.set('X-Requested-Client-Groups', clientGroupCode);
        return this._http.get(`${environment.endpoints.notificationService}/notifications/category/DATA_CHANGE`, { params, headers });
    }

    saveNotification(notificationData, clientGroupCode): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('X-Requested-Client-Groups', clientGroupCode);
        return this._http.put(`${environment.endpoints.notificationService}/notifications/category/DATA_CHANGE`, notificationData, { headers } );
    }

}