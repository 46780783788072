export class DashComponent {

    public component: string;
    public name: string;
    public size: number;
    public expansive: boolean;
    public permission: string;
    public description: string;

    constructor(component?: DashComponent) {
        this.component = component ? component.component : null;
        this.name = component ? component.name : null;
        this.size = component ? component.size : 50;
        this.expansive = component ? component.expansive : false;
        this.permission = component ? component.permission : null;
        this.description = '';
    }

}
