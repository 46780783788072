<div *ngIf="values.length === 2 || values.length === 3" class="cmx-filter-switch"
[title]="disableMessage ? disableMessage : ''"
[style.width]="size === 'md' ? '120px' : size === 'lg' ? '160px' : '80px'">
    <div class="switchContainer"
    [style.margin-left]="size === 'md' && index === 1 ? '-118px' : 
                         size === 'md' && index === 2 ? '-236px' :
                         size === 'lg' && index === 1 ? '-158px' : 
                         size === 'lg' && index === 2 ? '-286px' :
                         size === 'sm' && index === 1 ? '-78px' : 
                         size === 'sm' && index === 2 ? '-196px' : ''">
        <div *ngFor="let value of values; let i = index" class="switchContent {{ disabled ? 'disabled' : '' }} ">
            <div class="valueContainer"
            (click)="switch(index, true)" 
            [style.cursor]="(index === 0 || (index === 1 && values.length === 2) || index === 2) && !disabled ? 'pointer' : 'default'"
            [style.width]="size === 'md' ? '118px' : size === 'lg' ? '158px' : '78px'"
            [style.padding-right]="i === 0 ? '8px' : '0px'"
            [style.padding-left]="i === 2 ? '8px' :
                                  i === 1 && values.length === 2 ? '8px' :
                                  '0px'">
                {{ value }}
            </div>
        </div>
    </div>

    <div *ngIf="(index === 1 || index === 2) && !disabled" 
        (click)="switch(-1)" class="toogle left"></div>
    <div *ngIf="(index === 0 || (index === 1 && values.length === 3)) && !disabled"
        (click)="switch(1)" class="toogle right"></div>
</div>