import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';

@Injectable()
export class CustomGridDropdownService {

    currentDropdownColumn: string;
    currentDropdownRow: number;
    currentClosestRow: any;
    dropdownSelectSubject: Subject<any> = new Subject<any>();

    private _dropdownElementHeight = 20;

    openDropdown(dropdownElement, column, row, item) {
        this.closeDropdown();
        this.currentDropdownColumn = column;
        this.currentDropdownRow = row;
        setTimeout(() => {
            // distância do elemento base ao topo da páginsa
            const dropdownElementTop = dropdownElement.offset().top;
            // obtendo elemento de lista
            const dropdownList = document.getElementById(`dropdown-formatter-list-${column}-${row}`);
            // capturando a largura do dropdown
            const dropdownWidth = dropdownList?.parentElement?.clientWidth;
            this.currentClosestRow = $(dropdownList).closest('.slick-row');
            this.currentClosestRow.css('z-index', 3);
            if (dropdownList) {
                // aplicando estilos ao elemento lista
                dropdownList.style.display = 'block';
                dropdownList.style.width = `${ dropdownWidth }px`;
                dropdownList.style.top = `${ dropdownElementTop + this._dropdownElementHeight }px`;
                // verificando se o dropdown está próximo ao final da página
                this._verifyDropdownCloseToPageBottom(dropdownList)
                dropdownList.onclick = (event) => {
                    const target = event.target as HTMLElement;
                    if (target.classList.contains('dropdown-formatter-item') &&
                        target.id && target.innerHTML) {
                        this.dropdownSelectSubject.next({ id: target.id, itemName: target.innerHTML, column, row: item?.rawIndex });
                        const innerText = dropdownElement.find('.dropdown-formatter-innerText');
                        if (innerText) { innerText.html(target.innerHTML); }       
                    }
                }
                // adicionando evento de click para fechar o dropdown
                const closeListenner = () => {
                    this.closeDropdown();
                    document.removeEventListener('click', closeListenner);
                }
                document.addEventListener('click', closeListenner);
                // adicionando evento de scroll e tela para fechar o dropdown
                window.onscroll = () => {
                    this.closeDropdown();
                    window.onscroll = null;
                }
            }
        }, 200);
    }

    closeDropdown() {
        const dropdownList = document.getElementById(`dropdown-formatter-list-${this.currentDropdownColumn}-${this.currentDropdownRow}`);
        this.currentClosestRow?.css('z-index', 0);
        if (dropdownList) {
            dropdownList.style.display = 'none';
        }
    }

    apllyDropdownValue(rawRowData, rows, selection) {
        // atribui a seleção de um dropdown ao item da linha, permanentemente
        rawRowData[selection.row][selection.column] = { 
            ...rawRowData[selection.row][selection.column],
            id: selection.id,
            itemName: selection.itemName
        };
        rows = _.cloneDeep(rawRowData);
    }

    private _verifyDropdownCloseToPageBottom(dropdownList) {
        setTimeout(() => {
            const dropdownListHeight = dropdownList.clientHeight;
            const distanceToBottom = window.innerHeight - dropdownList.getBoundingClientRect().bottom;
            if (distanceToBottom < 5) {
                // se o dropdown estiver próximo ao final da página, ele é exibido acima do elemento base
                dropdownList.style['margin-top'] = `-${dropdownListHeight + this._dropdownElementHeight + 3}px`;
            }
        }, 0);
    }

}