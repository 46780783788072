import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SpListingDetailsComponent } from 'app/theme/pages/finance/exchange/exchange-sp-listing/grid-modals/sp-listing-details/sp-listing-details.component';
import { SpListingExchangeDetailsComponent } from 'app/theme/pages/finance/exchange/exchange-sp-listing/grid-modals/sp-listing-exchange-details/sp-listing-exchange-details.component';
import { SpDocumentsComponent } from 'app/theme/pages/finance/exchange/exchange-sp-listing/grid-modals/sp-documents/sp-documents.component';

import { SlickTabsModalComponent } from './cmx-slick-tabs-modal.component';
import { SharedModule } from 'app/shared/shared.module';
import { PoStatusDataComponent } from 'app/modules/operational/pages/po-status/grid-modals/po-status-data/po-status-data.component';
import { PoStatusImpsComponent } from 'app/modules/operational/pages/po-status/grid-modals/po-status-imps/po-status-imps.component';
import { PoStatusSkusComponent } from 'app/modules/operational/pages/po-status/grid-modals/po-status-skus/po-status-skus.component';
import { ProjectPricingProposesComponent } from 'app/modules/operational/pages/project-pricing/grid-modals/project-pricing-proposes/project-pricing-proposes.component';
import { ProjectPricingDetailsComponent } from 'app/modules/operational/pages/project-pricing/grid-modals/project-pricing-details/project-pricing-details.component';
import { SpBankingChannelsComponent } from 'app/theme/pages/finance/exchange/exchange-sp-listing/grid-modals/sp-banking-channels/sp-banking-channels.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule
],
  declarations: [
    SlickTabsModalComponent,
    SpDocumentsComponent,
    SpListingDetailsComponent,
    SpBankingChannelsComponent,
    SpListingExchangeDetailsComponent,
    ProjectPricingProposesComponent,
    ProjectPricingDetailsComponent,
    PoStatusDataComponent,
    PoStatusImpsComponent,
    PoStatusSkusComponent,
  ],
  exports: [
    SlickTabsModalComponent,
  ],
  entryComponents: [
    SpDocumentsComponent,
    SpListingDetailsComponent,
    SpBankingChannelsComponent,
    SpListingExchangeDetailsComponent,
    ProjectPricingProposesComponent,
    ProjectPricingDetailsComponent,
    PoStatusDataComponent,
    PoStatusImpsComponent,
    PoStatusSkusComponent,
  ],
})

export class SlickTabsModule {}
