<div class="cmx-tab-modal" [style.height]="height" [style.visibility]="hidden ? 'hidden' : 'visible'">
    <cmx-marker-filter
        *ngIf="tabs && !hidden"
        type="tab"
        returnBasedOn="code"
        alignment="start"
        style="left: 20px;"
        [filters]="tabGroups"
        [preSelected]="preSelectedTab"
        [multiple]="false"
        [style.visibility]="hideTabs ? 'hidden' : 'visible'"
        (onSelected)="onSelectTabEvent($event)"
    ></cmx-marker-filter>

    <i (click)="onCloseEvent()" class="pointer la la-close"></i>

    <div *ngIf="!dinamicTabs" class="tabContent" [style.padding]="contentPadding">
        <ng-template #contentContainer></ng-template>
    </div>

    <div *ngIf="dinamicTabs" class="tabContent" [style.padding]="contentPadding">
        <div style="height: 100%; overflow: auto">
            <cmx-assembled-data
                maxRowElements="6"
                [data]="getDinamicData()"
                (onClick)="onDinamicDataClick($event)"
            ></cmx-assembled-data>
        </div>
    </div>
</div>
