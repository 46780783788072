import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from 'app/shared/shared.module';
import { LayoutModule } from 'app/theme/layouts/layout.module';
import { InputTextModule } from 'primeng/inputtext';

import { ConfigurationsComponent } from './configurations.component';
import { DocumentsGedCRUDComponent } from './documents-ged/documents-ged.component';
import { DocumentsGedCRUDService } from './documents-ged/documents-ged.service';
import { NotificationsCRUDComponent } from './notifications/notifications.component';
import { NotificationsCRUDService } from './notifications/notifications.service';
import { PreferencesCRUDComponent } from './preferences/preferences.component';
import { DateNotificationCRUDComponent } from './notifications/date-notification/date-notification.component';
import { DateNotificationService } from './notifications/date-notification/date-notification.service';

const routes: Routes = [
    { path: '', component: ConfigurationsComponent },
    { path: ':page', component: ConfigurationsComponent },
    { path: ':page/:subPage', component: ConfigurationsComponent },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        LayoutModule,
        SharedModule,
        InputTextModule,
    ],
    declarations: [
        ConfigurationsComponent,
        NotificationsCRUDComponent,
        DateNotificationCRUDComponent,
        DocumentsGedCRUDComponent,
        PreferencesCRUDComponent
    ],
    exports: [
        ConfigurationsComponent
    ],
    providers: [
        NotificationsCRUDService,
        DocumentsGedCRUDService,
        DateNotificationService,
    ],
    entryComponents: [
        NotificationsCRUDComponent,
        DateNotificationCRUDComponent,
        DocumentsGedCRUDComponent,
        PreferencesCRUDComponent
    ]
})

export class ConfigurationModule {

}
