<div *ngIf="!asyncDetail" class="fullLoader">
    <loader></loader>
</div>

<div id="import-details-licence-container" *ngIf="asyncDetail">
    <div id="import-details-licence-content-error" *ngIf="licenceList.length < 1">
        <span class="noContent">Nenhuma Licença atribuída à IMP {{impNumber}}</span>
    </div>
    <div id="import-details-licence-content" *ngIf="licenceList.length">
        <cmx-custom-grid
            [id]="'import-details-licence-grid'"
            [columns]="gridLicenceColumns"
            [rows]="licenceList"
            [options]="{ forceFitColumns: true }"
            [styles]="{ 'height': 'calc(100vh - 230px)', 'margin-top': '0px' }"
            (onCellClick)="onCellClick($event)"
        ></cmx-custom-grid>
    </div>
</div>

<cmx-custom-dialog
    [id]="'import-details-licence-dialog'"
    [title]="'L.I • ' + liNumber"
    animation="left-right"
    [internalFade]="true"
    [closeButton]="true"
    [overlay]="true"
    [widthModal]="45">
    <ng-container cmx-custom-dialog-body>
        <div class="import-details-licence-dialog-container" *ngIf="liDetails">
            <cmx-assembled-data [data]="liDetails" [maxRowElements]="3"></cmx-assembled-data>
        </div>
    </ng-container>
</cmx-custom-dialog>
