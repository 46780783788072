<!-- Loader Model 1 -->
<!-- <div class="loaderModel1">
    <div class="cube cube1"></div>
    <div class="cube cube2"></div>
    <div class="cube cube3"></div>
    <div class="cube cube4"></div>
    <div class="cube cube5"></div>
    <div class="cube cube6"></div>
    <div class="cube cube7"></div>
    <div class="cube cube8"></div>
    <div class="cube cube9"></div>
</div> -->

<!-- Loader Model 2 -->
<!-- <div class="loaderModel2">
    <div class="cube cube1"></div>
    <div class="cube cube2"></div>
    <div class="cube cube3"></div>
    <div class="cube cube4"></div>
</div> -->

<!-- Loader Model 3 -->
<!-- <div class="loaderModel3">
    <div class="loaderRotate">
        <div class="rotateCube1"></div>
        <div class="rotateCube2"></div>
    </div>
</div> -->

<!-- Loader Model 4 -->
<div class="loaderModel4">
    <div class="cube cube1"></div>
    <div class="cube cube2"></div>
    <div class="cube cube3"></div>
    <div class="cube cube4"></div>
    <div class="cube cube5"></div>
    <div class="cube cube6"></div>
</div>
