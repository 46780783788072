<ng-template #loader>
    <loader></loader>
</ng-template>

<div *ngIf="hasData && hasDataWithFilter" id="detailGrid"></div>
<div class="noDataDetail" *ngIf="hasData == false">Nenhum detalhamento para essa célula.</div>
<div class="noDataDetail" *ngIf="hasDataWithFilter == false">Nenhum resultado contemplando esse filtro.</div>
<div *ngIf="hasDataWithFilter == false" class="centerBtn">
    <div class="flex-btn" (click)="resetFilters()"><i class="flaticon-refresh"></i>
        <span class="tooltip-text">Resetar</span>
    </div>
</div>
<div *ngIf="pages.length > 1">
    <pagination [numberOfPages]="pages.length" [queryId]="queryId" (onPageChange)="changePage($event)"></pagination>
</div>

<div class="selectModal one hidden">
    <input type="text" placeholder="Filtrar" [(ngModel)]="innerSearch">
    <div class="table">
        <table class="selectItem">
            <tr (click)="selectOption(option, $event)" *ngFor="let option of filterOptions; let i = index; trackBy: trackByFn">
                <td *ngIf="filterByFilter(option)" class="selectItem {{option.selected}}">{{option.name}}</td>
            </tr>
        </table>
    </div>
    <div class="buttonArea">
        <span (click)="applyFilters()">Aplicar</span>
    </div>
</div>

<div class="selectModal two hidden">
    <input type="text" placeholder="Filtrar" [(ngModel)]="innerSearch">
    <div class="table">
        <table class="selectItem">
            <tr (click)="selectOption(option, $event)" *ngFor="let option of filterOptions; let i = index; trackBy: trackByFn">
                <td *ngIf="filterByFilter(option)" class="selectItem {{option.selected}}">{{option.name}}</td>
            </tr>
        </table>
    </div>
    <div class="buttonArea">
        <span (click)="applyFilters()">Aplicar</span>
    </div>
</div>

<div class="commentsArea invisible"></div>
<div class="commentsModal hidden">
    <div class="commentsContent">
        <i (click)="closeCommentsModal()" class="close la la-close"></i>
        <span>Comentários <span class="impValue {{historic}}" style="margin-left:5px">{{commentHistory ? selectedAccount : selectedImp}}</span></span>
        <hr>
        <div *ngIf="!asyncComments; else loader" class="comments {{historic}}">
            <span *ngIf="currentComments === null" class="noContent">Nenhum comentário foi encontrado.</span>
            <div style="width:100%" *ngIf="currentComments?.length > 0">
                <div class="comment {{historic}} {{comment.commentId}}" *ngFor="let comment of currentComments; trackBy: trackByFn">
                    <div *ngIf="historic" class="impValue impNumber">{{comment.importationNumber}}</div>
                    <span class="content">{{comment.importationComment}}</span>
                    <span class="date">{{comment.userName}} • {{moment(comment.lastModification, 'YYYY-MM-DD').format('DD/MM/YYYY')}}</span>
                    <div *ngIf="!historic" class="edit" (click)="startEditComment(comment)"><i class="material-icons">edit</i></div>
                </div>
            </div>
        </div>
        <div *ngIf="!historic" class="inputArea">
            <textarea [(ngModel)]="selectedComment"></textarea>
            <button *ngIf="!editCommentMode && selectedComment !== ''" (click)="addComment()" class="addBtn">Comentar</button>
            <button *ngIf="!editCommentMode && selectedComment === ''" class="addBtn disabled">Comentar</button>
            <button *ngIf="editCommentMode && selectedComment !== ''" (click)="editComment()" class="editBtn">Editar</button>
            <button *ngIf="editCommentMode && selectedComment === ''" class="editBtn disabled">Editar</button>
        </div>
    </div>
    <p class="error hidden"><i (click)='hideAllErrorOrSucess()' class="la la-close"></i>Algo deu errado.</p>
    <p class="success create hidden"><i (click)='hideAllErrorOrSucess()' class="la la-close"></i>Comentário adicionado.</p>
    <p class="success edit hidden"><i (click)='hideAllErrorOrSucess()' class="la la-close"></i>Comentário alterado.</p>
</div>
