<div *ngIf="!asyncDetail" class="fullLoader">
    <loader></loader>
</div>

<div id="import-details-internal-container" *ngIf="asyncDetail">
    <div id="import-details-internal-content" *ngIf="dataLoaded">
        <cmx-assembled-data [data]="observationDetails" [maxRowElements]="1"></cmx-assembled-data>
        <cmx-assembled-data [data]="internalDetails" [maxRowElements]="4"></cmx-assembled-data>

        <hr />

        <div class="detailTitle desembaraco">
            <img src="assets/img/icons/documents.png" alt="" />
            <span>Desembaraço / Registro DI</span>
        </div>
        <cmx-assembled-data
            [data]="clearanceDetails"
            [maxRowElements]="4"
            (onClick)="openPenaltyModal($event)"
        ></cmx-assembled-data>
    </div>
</div>

<cmx-custom-dialog
    [id]="'import-details-internal-dialog'"
    [title]="'Multas'"
    animation="right-left"
    [internalFade]="true"
    [closeButton]="true"
    [overlay]="true"
    [widthModal]="45"
>
    <ng-container cmx-custom-dialog-body>
        <div class="import-details-internal-dialog-container" *ngIf="penaltyDetails">
            <cmx-assembled-data [data]="penaltyDetails" [maxRowElements]="1"></cmx-assembled-data>
        </div>
    </ng-container>
</cmx-custom-dialog>
