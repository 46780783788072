export const COLOR_LIST = [
    '#345683',
    '#bb4c70',
    '#037872',
    '#c78726',
    '#6987b8',
    '#61459f',
    '#b77383',
    '#05919d',
    '#bfaf6f',
    '#6F7788',
];
