<h5>Preços de Frete</h5>
<div class="moveBtn">
    <div class="moveLine"></div>
</div>
<i class="editBtn la la-ellipsis-v" (click)="chartService.editBtn($event)"></i>
<div class="chart" id="freightRates"></div>

<!-- editModal -->
<div class="editModal freight-rates-chart hidden">
    <i class="resizeBtn la la-expand" (click)="chartService.resizeBtn($event)"></i>
    <h5>Editar -  Preços de Frete</h5>
</div>
