import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'cmx-filter-wrapper-group',
    templateUrl: './cmx-filter-wrapper-group.component.html',
    styleUrls: ['./cmx-filter-wrapper-group.component.scss'],
})
export class FilterWrapperGroupComponent implements OnInit {
    @Input('divisor') showDivisor: boolean = true;
    @Input('flex') elementFlex: boolean = true;
    @Input('label') labelGroup: string = null;
    @Input('label-inline') labelInline: boolean = false;
    @Input('first-label') firstLabel: boolean = false;
    @Input('styles') styles: any = null;

    constructor() {}

    ngOnInit() {}
}
