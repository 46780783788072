import 'moment/locale/pt-br';

import moment from 'moment';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { AppModule } from 'app/app.module';
import * as _ from 'lodash';

import { Filter as DerivationFilter } from '#services/_domain/domain.filter';
import { Filter as ServiceFilter } from '#services/_results/results.service';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { Injectable } from "@angular/core";
import { IFilterSelection } from './interfaces/filter-selection.interface';

@Injectable()
export class FilterSelection {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialFilterSelection: IFilterSelection = <IFilterSelection>{};
    private _filterSelection: IFilterSelection = <IFilterSelection>{};

    public readonly defaultPeriod: string[];
    public startDate: string;
    public endDate: string;
    public companies: DropdownOption[] = [];
    public profitCenter: DropdownOption[] = [];
    public trader: DropdownOption[] = [];
    public director: DropdownOption[] = [];
    public client: DropdownOption[] = [];
    public account: number[] = [];
    public currency: string = 'BRL';
    public imps: string[] = [];
    public crGrouped: boolean;
    public transactionTypes: DropdownOption[] = [];
    public paymentTypes: string[] = [];
    public users: string[] = [];
    public vendorNames: string[] = [];

    constructor(filter?: FilterSelection) {
        this._filterSelection = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'filterSelection', this._filterSelection, filter);
        this._initialFilterSelection = _.cloneDeep(this._filterSelection);
        this.setFilterPreference();

        this.defaultPeriod = this.calculateDefaultPeriod();
        this.startDate = this.defaultPeriod[0];
        this.endDate = this.defaultPeriod[1];
        this.crGrouped = false;
    }

    public anyFilterSet(): boolean {
        this.readDates();
        this.filterSelectionUpdated();
        return (
            this.companies.length > 0 ||
            this.client.length > 0 ||
            this.profitCenter.length > 0 ||
            this.director.length > 0 ||
            this.trader.length > 0 ||
            this.startDate != null ||
            this.endDate != null ||
            this.currency !== '' ||
            this.imps.length > 0 ||
            this.crGrouped ||
            this.transactionTypes.length > 0 ||
            this.paymentTypes.length > 0
        );
    }

    public clear() {
        this.startDate = null;
        this.endDate = null;
        this.companies = [];
        this.profitCenter = [];
        this.trader = [];
        this.director = [];
        this.client = [];
        this.currency = 'BRL';
        this.imps = [];
        this.crGrouped = false;
        this.transactionTypes = [];
        this.paymentTypes = [];

        this.filterSelectionUpdated();
    }

    public refresh() {
        this.readDates();
    }

    public toServiceFilter(): ServiceFilter {
        this.readDates();

        const filter = new ServiceFilter();

        const parsedStartDate = moment(this.startDate, 'MM/YYYY');
        filter.startDate = parsedStartDate.isValid()
            ? parsedStartDate.format('DD/MM/YYYY')
            : undefined;

        const parsedEndDate = moment(this.endDate, 'MM/YYYY');
        filter.endDate = parsedEndDate.isValid()
            ? parsedEndDate
                .add(1, 'month')
                .date(0)
                .format('DD/MM/YYYY')
            : undefined;

        const parsedExchangeDate = moment(this.endDate, 'MM/YYYY');
        filter.exchangeReferenceDate = parsedExchangeDate.isValid()
            ? parsedEndDate
                .add(1, 'month')
                .date(0)
                .format('YYYY-MM-DD')
            : undefined;

        filter.companies = this.idsOf(this.companies);
        filter.clientGroups = this.idsOf(this.client);
        filter.profitCenters = this.idsOf(this.profitCenter);
        filter.directors = this.idsOf(this.director);
        filter.traders = this.idsOf(this.trader);
        filter.accountingCode = [...this.account];
        filter.currency = this.currency;
        filter.imps = this.imps;
        filter.crGrouped = this.crGrouped;
        filter.transactionTypes = this.idsOf(this.transactionTypes);
        filter.paymentTypes = this.idsOf(this.paymentTypes);
        filter.vendorNames = this.vendorNames;
        filter.users = this.users;

        this.filterSelectionUpdated();

        return filter;
    }

    public toDerivationParams(entityType: any): DerivationFilter {
        this.readDates();

        const params = new DerivationFilter();

        switch (entityType) {
            case 'company':
                params.codes = this.idsOf(this.companies);
                break;
            case 'profitCenter':
                params.codes = this.idsOf(this.profitCenter);
                break;
            case 'clientGroup':
                params.codes = this.idsOf(this.client);
                break;
            case 'director':
                params.codes = this.idsOf(this.director);
                break;
            case 'trader':
                params.codes = this.idsOf(this.trader);
                break;
        }

        params.startDate = this.startDate;
        params.endDate = this.endDate;
        params.currency = this.currency;
        params.crGrouped = this.crGrouped;

        this.filterSelectionUpdated();

        return params;

    }

    public clone(): FilterSelection {
        const clone = new FilterSelection();

        clone.startDate = this.startDate;
        clone.endDate = this.endDate;
        clone.companies = [...this.companies];
        clone.profitCenter = [...this.profitCenter];
        clone.director = [...this.director];
        clone.trader = [...this.trader];
        clone.client = [...this.client];
        clone.account = [...this.account];
        clone.currency = this.currency;
        clone.imps = [...this.imps];
        clone.crGrouped = this.crGrouped;
        clone.transactionTypes = this.transactionTypes;
        const types = [];
        if (this.transactionTypes.length > 0) {
            this.transactionTypes.forEach((paymentTypes) => {
                types.push(paymentTypes);
            });
        }
        clone.paymentTypes = types;

        this.filterSelectionUpdated();

        return clone;
    }

    private resetDates() {
        const lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        this.startDate = moment(lastMonth).format('MM/YYYY');
        this.endDate = this.startDate;
    }

    private readDates() {
        const from = $('#periodFrom').val();
        const to = $('#periodTo').val();

        if (from) {
            const parsed = moment(from, 'MM/YYYY');
            this.startDate = parsed.isValid()
                ? parsed.format('MM/YYYY')
                : undefined;
        } else {
            this.startDate = this.defaultPeriod[0];
        }

        if (to) {
            const parsed = moment(to, 'MM/YYYY');
            this.endDate = parsed.isValid()
                ? parsed
                    .add(1, 'month')
                    .date(0)
                    .format('MM/YYYY')
                : undefined;
        } else {
            this.endDate = this.defaultPeriod[1];
        }
    }

    private idsOf(selectedItems: any[]) {
        return (selectedItems || []).map((t) => t.id);
    }

    private calculateDefaultPeriod() {
        // YTD
        return [
            moment()
                .month(0)
                .date(1)
                .format('MM/YYYY'),
            moment().format('MM/YYYY'),
        ];
    }

    private setFilterPreference() {
        this.companies = this._filterSelection.companies || [];
        this.profitCenter = this._filterSelection.profitCenter || [];
        this.trader = this._filterSelection.trader || [];
        this.director = this._filterSelection.director || [];
        this.client = this._filterSelection.clientGroups || [];
        this.account = this._filterSelection.account || [];
        this.currency = this._filterSelection.currency || 'BRL';
        this.imps = this._filterSelection.imps || [];
        this.crGrouped = this._filterSelection.crGrouped || false;
        this.transactionTypes = this._filterSelection.transactionTypes || [];
        this.paymentTypes = this._filterSelection.paymentTypes || [];
        this.users = this._filterSelection.users || [];
        this.vendorNames = this._filterSelection.vendorNames || [];
    }

    private filterSelectionUpdated() {
        this._filterSelection.companies = this.companies;
        this._filterSelection.profitCenter = this.profitCenter;
        this._filterSelection.trader = this.trader;
        this._filterSelection.director = this.director;
        this._filterSelection.clientGroups = this.client;
        this._filterSelection.account = this.account;
        this._filterSelection.currency = this.currency;
        this._filterSelection.imps = this.imps;
        this._filterSelection.crGrouped = this.crGrouped;
        this._filterSelection.transactionTypes = this.transactionTypes;
        this._filterSelection.paymentTypes = this.paymentTypes;
        this._filterSelection.users = this.users;
        this._filterSelection.vendorNames = this.vendorNames;
        this.updatePreferences();
    }

    private updatePreferences(){
        this._initialFilterSelection = this._userPreferencesService.verifyFilterChanges(
            this._initialFilterSelection, this._filterSelection, 'filterSelection', this._userCacheService.getUserPreferences().id);
    }

}
