export class Director {

    public static blank() {
        return new Director('Novo Diretor', null, []);
    }

    public code: string;
    public name: string;
    public traders: Itrader[];

    constructor(name: string, code: string, traders: Itrader[]);
    constructor(source: {name: string, code: string, traders?: Itrader[]});
    constructor(source: string | any, code?: string, traders?: Itrader[]) {
        if (typeof source === 'string') {
            this.name = source;
            this.code = null;
            this.traders = [];
        } else {
            this.name = source.name;
            this.code = source.code;
            this.traders = [].concat(...source.traders);
        }
    }

    public clone() {
        return new Director(this);
    }

    public equals(other: Director): boolean {
        return this.name === other.name
            && this.code === other.code;
    }
}

interface Itrader {
    name: string;
    code: string;
}
