import { Component, ViewEncapsulation } from '@angular/core';
import { UtilsService } from '#services/_utils/utils.service';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';
import { IGridColumn, IGridRow } from 'app/shared/components/cmx-custom-grid/interfaces';
import { ALERT_TYPE_INFO } from '../../constants/info.constant';

@Component({
    selector: 'info-dialog',
    templateUrl: './info-dialog.component.html',
    styleUrls: ['./info-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class InfoDialogComponent {

    infoDialogId: string;
    type: string;
    info;
    secondaryType: string;
    gridColumns: IGridColumn[];
    gridRows: IGridRow[];

    constructor(
        private _customDialogService: CustomDialogService,
        private _utilsService: UtilsService,
    ) {
        this.infoDialogId = this._utilsService.generateGUID('dialog');
    }

    open(): void {
        this.secondaryType = null;
        if (!this.type) {
            throw new Error('[AlertInfoDetail] Must have type to render dialog!');
        } else {
            this.info = ALERT_TYPE_INFO.find((info) => info.type === this.type) || null;
            if (!this.info) {
                throw new Error('[AlertInfoDetail] Must have infoType to render dialog!');
            }
            if (this.info.secondaryType) {
                if (this.info.secondaryType === 'table') {
                    setTimeout(() => {
                        this.secondaryType = this.info.secondaryType
                        this._loadGrid();
                    }, 1000);
                } else {
                    this.secondaryType = this.info.secondaryType
                }
            }
            this._customDialogService.open(this.infoDialogId);
        }
    }

    private _loadGrid() {
        this.gridColumns = this.info.secondaryData.columns || [];
        this.gridRows = this.info.secondaryData.rows || [];
    }

}
