<div *ngIf="!asyncDetail" class="fullLoader">
    <loader></loader>
</div>

<div id="import-details-invoices-container" *ngIf="asyncDetail">
    <div id="import-details-invoices-content-error" *ngIf="!dataLoaded">
        <span class="noContent">Invoice não encontrada</span>
    </div>
    <div id="import-details-invoices-content" *ngIf="dataLoaded">
        <cmx-assembled-data [data]="invoicesDetails" [maxRowElements]="4" (onClick)="onInvoicesDetailsClick($event)"></cmx-assembled-data>

        <div id="import-details-invoices-grid-content">
            <cmx-custom-grid
                *ngIf="invoicesList.length > 0"
                [id]="'import-details-invoices-grid'"
                [columns]="gridInvoicesColumns"
                [rows]="invoicesList"
                [options]="{ forceFitColumns: true }"
                [styles]="{ 'max-height': 'calc(100vh - 400px)'}"
                (onCellClick)="onCellClick($event)"
            ></cmx-custom-grid>

            <div id="import-details-invoices-grid-content-error" *ngIf="invoicesList.length < 1">
                <hr>
                <span class="noContent">Nenhuma Invoice atribuída à IMP {{ impNumber }}</span>
            </div>
        </div>
    </div>
</div>

<cmx-custom-dialog
    [id]="'import-details-invoices-items-dialog'"
    [title]="'Itens da Invoice • ' + invoiceNumber"
    animation="left-right"
    [internalFade]="true"
    [closeButton]="true"
    [overlay]="true"
    [widthModal]="71"
    (modal-closed)="closeInvoicesItemsModal()">
    <ng-container cmx-custom-dialog-body>
        <div class="import-details-invoices-items-dialog-container" *ngIf="showInvoicesItemsModal && itemsDetails">
            <cmx-assembled-data [data]="itemsDetails" [maxRowElements]="4"></cmx-assembled-data>

            <div id="import-details-invoices-items-dialog-grid-content" *ngIf="itemsList.length > 0">
                <cmx-custom-grid
                    [id]="'import-details-invoices-items-grid'"
                    [columns]="gridInvoicesItemsColumns"
                    [rows]="itemsList"
                    [options]="{ forceFitColumns: true }"
                    [styles]="{ 'max-height': 'calc(100vh - 330px)'}"
                    (onCellClick)="itemsModalOnCellClick($event)"
                ></cmx-custom-grid>
            </div>
        </div>
    </ng-container>
</cmx-custom-dialog>

<cmx-custom-dialog
    [id]="'import-details-invoices-ncm-dialog'"
    [title]="'NCM • ' + ncmNumber"
    animation="right-left"
    [internalFade]="true"
    [closeButton]="true"
    [overlay]="true"
    [widthModal]="25"
    (modal-closed)="closeInvoicesNcmModal()">
    <ng-container cmx-custom-dialog-body>
        <div class="import-details-invoices-ncm-dialog-container" *ngIf="ncmDetails && showInvoicesNcmModal" >
            <cmx-assembled-data [data]="ncmDetails" [maxRowElements]="1"></cmx-assembled-data>
        </div>
    </ng-container>
</cmx-custom-dialog>

<cmx-custom-dialog
    [id]="'import-details-invoices-exchange-dialog'"
    [title]="'Câmbio'"
    animation="right-left"
    [internalFade]="true"
    [closeButton]="true"
    [overlay]="true"
    [widthModal]="30"
    (modal-closed)="showInvoicesExchangeModal = false">
    <ng-container cmx-custom-dialog-body>
        <div class="import-details-invoices-exchange-dialog-container" *ngIf="showInvoicesExchangeModal && exchangeList.length">
            <cmx-custom-grid
                [id]="'import-details-invoices-exchange-grid'"
                [columns]="gridInvoicesExchangeColumns"
                [rows]="exchangeList"
                [options]="{ forceFitColumns: true }"
                [styles]="{ 'max-height': 'calc(100vh - 330px)', 'margin-top': '0px'}"
            ></cmx-custom-grid>
        </div>
    </ng-container>
</cmx-custom-dialog>
