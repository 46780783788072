import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Grouping } from '#services/_results/results.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { AppModule } from 'app/app.module';
import * as _ from 'lodash';
import { IFilterGroupingStateSelection } from './interfaces/filter-grouping-state-selection.interface';

@Injectable()
export class GroupingStateService {

    public state = new BehaviorSubject<Grouping>(Grouping.byCompany);

    public current$ = this.state.asObservable();

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialGroupingStateSelection: IFilterGroupingStateSelection = <IFilterGroupingStateSelection>{};
    private _groupingState: IFilterGroupingStateSelection = <IFilterGroupingStateSelection>{
        grouping: Grouping.byCompany
    };

    public loadGrouping(){
        this._groupingState = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'groupingfilter', this._groupingState, undefined);
        this._initialGroupingStateSelection = _.cloneDeep(this._groupingState);
        this.state.next(this._groupingState.grouping);
    }

    public changeGrouping(newGrouping: Grouping) {
        this._groupingState.grouping = newGrouping;
        this._initialGroupingStateSelection = this._userPreferencesService.verifyFilterChanges(
            this._initialGroupingStateSelection, this._groupingState, 'groupingfilter', this._userCacheService.getUserPreferences().id);
        this.state.next(newGrouping);
    }

    public getCurrent() {
        return this.state.getValue();
    }
}
