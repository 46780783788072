import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { FilterSelection } from './filter-selection';

@Injectable()
export class FilterStateService {

    public state = new BehaviorSubject<FilterSelection>(new FilterSelection());

    public current$ = this.state.asObservable();

    public changeFilter(newFilter: FilterSelection) {
        this.state.next(newFilter);
    }

    public getCurrent() {
        return this.state.getValue().clone();
    }
}
