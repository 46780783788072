import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '#environment';
import { UserService } from 'app/auth/_services';

@Injectable()
export class CustomService {

    private currentUser;
    private cache = {};

    constructor(
        private userService: UserService,
        private http: HttpClient
    ) {
        this.currentUser = this.userService.getCurrentUser();
    }

    // Get Default
    public getDefault(type, name): Observable<any> {
        const endpoint = this.getEndpoint(name, true);
        name = this.getName(name);
        const url = `${endpoint}/customization/${type}?name=${name}&isCustomer=${!this.currentUser.has('FOLLOWUP_COMPLETE')}`;
        return Observable.create(observer => {
            let customization = this.cache[url];
            if (typeof customization == 'undefined') {
                this.http.get(url).subscribe(data => {
                    observer.next(data);
                    this.cache[url] = data;
                    observer.complete();
                });
            } else {
                observer.next(customization);
                observer.complete();
            }
        });
    }

    // Get Custom
    public getCustomization(type, name, modelName?): Observable<any> {
        const endpoint = this.getEndpoint(name, true);
        name = this.getName(name);
        const url = `${endpoint}/customization/${
            type}?name=${name}&userId=${this.currentUser.data.uid}&isCustomer=${
            !this.currentUser.has('FOLLOWUP_COMPLETE')}&modelName=${modelName}`;
        return Observable.create(observer => {
            let customization = this.cache[url];
            if (typeof customization == 'undefined') {
                this.http.get(url).subscribe(data => {
                    observer.next(data);
                    this.cache[url] = data;
                    observer.complete();
                });
            } else {
                observer.next(customization);
                observer.complete();
            }
        });
    }

    // Save Custom
    public saveCustomization(type, name, json, modelName): Observable<HttpResponse<any>> {
        const endpoint = this.getEndpoint(name);
        name = this.getName(name);
        this.cache = {};
        return this.http.post(`${endpoint}/customization/${
            type}?name=${name}&userId=${this.currentUser.data.uid}&isCustomer=${
            !this.currentUser.has('FOLLOWUP_COMPLETE')}&modelName=${modelName}`
            , json, { observe: 'response', responseType: 'json' });
    }

    // Delete Custom
    public deleteCustomization(type, name, modelName?): Observable<any> {
        const endpoint = this.getEndpoint(name);
        name = this.getName(name);
        this.cache = {};
        return this.http.delete(`${endpoint}/customization/${
            type}?name=${name}&userId=${this.currentUser.data.uid}&modelName=${modelName}`);
    }

    private getEndpoint(name, query = false) {
        if (name === 'followup-sku' || name === 'followup:sku') {
            return environment.endpoints.skuService;
        } else if (name === 'followup-chassi' || name === 'followup:chassi') {
            return  this.getChassisEndpoint(query)
        } else {
            return environment.endpoints.importationService;
        }
    }

    private getChassisEndpoint(query: boolean) {
        return query ? `${environment.endpoints.automotiveV2QueryService}/api/v2` :
            `${environment.endpoints.automotiveCommandService}/api/v2` ;
    }

    private getName(name) {
        return name === 'followup-sku' || name === 'followup:sku' ? 'followup:sku' : name;
    }

}
