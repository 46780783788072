<div (click)="notificationClick($event)" class="notificationModal hidden">
    <div class="styleContainer">
        <div class="styleBall">
            <i class="material-icons"></i>
        </div>
        <div class="whiteLine"></div>
    </div>
    <div class="infoContainer">
        <span class="title"></span>
        <span class="info" style="white-space: pre-line"></span>
    </div>
    <i class="material-icons hideArrow">arrow_downward</i>
</div>

<div class="smallNotificationModal hidden"></div>
