import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable()
export class FollowUpReportDeliveryFormatter {
    public formatDate(row, cell, value, columnDef, dataContext) {
        if (value === null || value === undefined || value === '') {
            return '';
        }
        return `<span class='dateValue'>${moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
    }

    // Previsão de Carregamento (Container)
    formatLoadingRequestDate(row, cell, value, columnDef, dataContext) {
        if (dataContext.delivery === null || dataContext.delivery === undefined || dataContext.delivery.loadingRequestDates === null) {
            return '';
        }

        const dates = [];
        dataContext.delivery.loadingRequestDates.forEach((indexedDate) => {
            if (!dates.includes(indexedDate.date) && indexedDate.date) {
                dates.push(indexedDate.date);
            }
        });
        if (dates.length === 1) {
            if (dates[0] === null || dates[0] === undefined || dates[0] === '') {
                return '';
            }
            return `<span class='dateValue'>${moment(dates[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (dates.length > 1) {
            return `<span class="multiple-container-loadingRequestDates">Várias (${dates.length})</span>`;
        } else {
            return '';
        }
    }

    // Carregamento (Container)
    formatLoadingDate(row, cell, value, columnDef, dataContext) {
        if (dataContext.delivery === null || dataContext.delivery === undefined || dataContext.delivery.loadingDates === null) {
            return '';
        }

        const dates = [];
        dataContext.delivery.loadingDates.forEach((indexedDate) => {
            if (!dates.includes(indexedDate.date) && indexedDate.date) {
                dates.push(indexedDate.date);
            }
        });
        if (dates.length === 1) {
            if (dates[0] === null || dates[0] === undefined || dates[0] === '') {
                return '';
            }
            return `<span class='dateValue'>${moment(dates[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (dates.length > 1) {
            return `<span class="multiple-container-loadingDates">Várias (${dates.length})</span>`;
        } else {
            return '';
        }
    }

    // Previsão de Entrega (Container)
    formatDeliveryForecastDate(row, cell, value, columnDef, dataContext) {
        if (dataContext.delivery === null || dataContext.delivery === undefined || dataContext.delivery.deliveryForecastDates === null) {
            return '';
        }

        const dates = [];
        dataContext.delivery.deliveryForecastDates.forEach((indexedDate) => {
            if (!dates.includes(indexedDate.date) && indexedDate.date) {
                dates.push(indexedDate.date);
            }
        });
        if (dates.length === 1) {
            if (dates[0] === null || dates[0] === undefined || dates[0] === '') {
                return '';
            }
            return `<span class='dateValue'>${moment(dates[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (dates.length > 1) {
            return `<span class="multiple-container-deliveryForecastDates">Várias (${dates.length})</span>`;
        } else {
            return '';
        }
    }

    // Previsão de Entrega (Container)
    formatLoadingForecastDate(row, cell, value, columnDef, dataContext) {
        if (dataContext.delivery === null || dataContext.delivery === undefined || dataContext.delivery.loadingForecastDates === null) {
            return '';
        }

        const dates = [];
        dataContext.delivery.loadingForecastDates.forEach((indexedDate) => {
            if (!dates.includes(indexedDate.date) && indexedDate.date) {
                dates.push(indexedDate.date);
            }
        });
        if (dates.length === 1) {
            if (dates[0] === null || dates[0] === undefined || dates[0] === '') {
                return '';
            }
            return `<span class='dateValue'>${moment(dates[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (dates.length > 1) {
            return `<span class="multiple-container-loadingForecastDates">Várias (${dates.length})</span>`;
        } else {
            return '';
        }
    }    

    // Data de Previsão de Faturamento (Container)
    formatPrevisionBillingDate(row, cell, value, columnDef, dataContext) {
        if (dataContext.previsionBillingDates === null || dataContext.previsionBillingDates === undefined) {
            return '';
        }

        const dates = [];
        dataContext.previsionBillingDates.forEach((indexedDate) => {
            if (!dates.includes(indexedDate.date) && indexedDate.date) {
                dates.push(indexedDate.date);
            }
        });
        if (dates.length === 1) {
            if (dates[0] === null || dates[0] === undefined || dates[0] === '') {
                return '';
            }
            return `<span class='dateValue'>${moment(dates[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (dates.length > 1) {
            return `<span class="multiple-container-previsionBillingDates">Várias (${dates.length})</span>`;
        } else {
            return '';
        }
    }

    // Entrega (Container)
    formatDeliveryDate(row, cell, value, columnDef, dataContext) {
        if (dataContext.delivery === null || dataContext.delivery === undefined || dataContext.delivery.deliveryDates === null) {
            return '';
        }

        const dates = [];
        dataContext.delivery.deliveryDates.forEach((indexedDate) => {
            if (!dates.includes(indexedDate.date) && indexedDate.date) {
                dates.push(indexedDate.date);
            }
        });
        if (dates.length === 1) {
            if (dates[0] === null || dates[0] === undefined || dates[0] === '') {
                return '';
            }
            return `<span class='dateValue'>${moment(dates[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (dates.length > 1) {
            return `<span class="multiple-container-deliveryDates">Várias (${dates.length})</span>`;
        } else {
            return '';
        }
    }

    // Data Carregamento
    public formatShippingCompany(row, cell, value, columnDef, dataContext) {
        const documents = [];
        value.forEach((document) => {
            if (
                !documents.includes(document.shippingCompanyDescription) &&
                document.shippingCompanyDescription !== null
            ) {
                documents.push(document.shippingCompanyDescription);
            }
        });
        if (documents.length === 1 && documents[0] !== null && documents[0] !== undefined) {
            return `<span class='dateValue'>${moment(documents[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (documents.length > 1) {
            return `<span class="multipleShippingDates">Vários (${documents.length})</span>`;
        } else {
            return '';
        }
    }
}
