import { _applyPosFormatters } from "./pos-formatters/pos-formatters";

export function switchFormatter(row, cell, value, columnDef, dataContext) {
    const contentValue = 
    `<div class="switch-formatter-container">
        <div class='switch-formatter-content ${value ? 'on' : 'off'}'>
            <div class='switch-ball'></div>
        </div>
    </div>`;
    return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
}