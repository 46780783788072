import { Component, ViewEncapsulation } from '@angular/core';
import { ImportDetailsService } from '../import-details.service';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '#services/_utils/utils.service';
import { ImportDetailItem } from '../import-details-item';

@Component({
    selector: 'app-import-details-internal',
    templateUrl: './internal.component.html',
    styleUrls: ['./internal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class InternalComponent extends ImportDetailItem {
    impNumber = this._route.snapshot.params.imp;
    asyncDetail = !this.showLoader;

    dataLoaded;
    internalDetails;
    observationDetails;
    clearanceDetails;
    penaltyDetails;

    // gridLicenceColumns: IGridColumn[] = LICENCE_COLUMNS;

    constructor(
        private _importDetailsService: ImportDetailsService,
        private _customDialogService: CustomDialogService,
        private _route: ActivatedRoute,
        private _utilsService: UtilsService,
        protected _router: Router,
    ) {
        super(_router);
    }

    ngOnInit() {
        (this.showLoader) ? 
            setTimeout(() => {this.loadDetails();}, 1000) : 
            this.loadDetails();
    }

    loadDetails() {
        this.asyncDetail = false;
        this.dataLoaded = this._importDetailsService.getDetails();
        if (this.dataLoaded) {
            this.observationDetails = [
                {
                    title: 'Observações Internas',
                    value: this.dataLoaded.customsClearance.observation,
                },
            ];
            this.internalDetails = [
                {
                    title: 'Usuário',
                    value: this.dataLoaded.nmUser,
                },
                {
                    title: 'Centro Custo',
                    value: this._determineCR(this.dataLoaded.costCenter),
                },
                {
                    title: 'Tipo Operação',
                    value: this.dataLoaded.operationType.replace(/_/g, ' '),
                },
                {
                    title: 'Liberação BL',
                    value: this.dataLoaded.customsClearance.dates.billOfLadingLiberation,
                },
                {
                    title: 'Vencimento Seguro',
                    value: this.dataLoaded.customsClearance.dates.insuranceExtensionDate,
                },
                {
                    title: 'Data Criação',
                    value: this.dataLoaded.issueDate,
                },
                {
                    title: 'Última Atualização',
                    value: this.dataLoaded.lastEventDate,
                },
            ];
            this.clearanceDetails = [
                {
                    title: 'Recepção DI',
                    value: this.dataLoaded.customsClearance.dates.diReceptionDate,
                },
                {
                    title: 'Distribuição',
                    value: this.dataLoaded.customsClearance.dates.distributionDate,
                },
                {
                    title: 'Retificação',
                    value: this.dataLoaded.customsClearance.dates.retificationDate,
                },
                {
                    title: 'Multas',
                    value: this.dataLoaded.customsClearance.qtPenalty,
                    formatter: this.dataLoaded.customsClearance.qtPenalty > 0 ? 'status' : null,
                    color: 'red',
                },
                {
                    title: 'Perdimento',
                    value: this.dataLoaded.customsClearance.registrationDeadline,
                },
                {
                    title: 'Vencimento Despacho',
                    value: this.dataLoaded.customsClearance.dates.rescheduleClearanceDate,
                },
                {
                    title: 'Pedido Carregamento',
                    value: this.dataLoaded.delivery.loadingRequestDate,
                },
                {
                    title: 'Fechamento',
                    value: this.dataLoaded.closingDate,
                },
            ];
            this.penaltyDetails = [
                {
                    title: 'Valor Multa',
                    value: this._utilsService.formatNumber(this.dataLoaded.customsClearance.penaltyAmount || 0, {
                        style: 'currency',
                        currency: 'BRL',
                    }),
                },
                {
                    title: 'Motivo',
                    value: this.dataLoaded.customsClearance.penaltyReason,
                },
            ];
        }

        this.asyncDetail = true;
    }

    openPenaltyModal(item) {
        if (item.title == 'Multas') {
            this._customDialogService.open('import-details-internal-dialog');
        }
    }

    private _determineCR(num) {
        const costCenter = {
            '20': `${num} - Novos Negócios`,
            '22': `${num} - Petroquímico`,
            '23': `${num} - Químico`,
            '24': `${num} - Infraestrutura`,
            '30': `${num} - Aço`,
            '31': `${num} - Têxtil`,
            '35': `${num} - Terceirização`,
            '49': `${num} - Automotivo`,
            '61': `${num} - Distribuição`,
            '62': `${num} - RPP`,
            '63': `${num} - BR`,
            '998': `${num} - Sócios`,
        };

        return costCenter[num];
    }
}
