import { HttpHeaders, HttpParams } from '@angular/common/http';

export class SubscriptionsFilter {

    private static CLIENT_GROUP_HEADER = 'X-Requested-Client-Groups';
    clientGroups;
    textFilter;

    constructor(filter?: SubscriptionsFilter) {
        this.clientGroups = filter ? filter.clientGroups : [];
    }

    getClientGroups(): object[] { return this.clientGroups; }
    setClientGroups(clientGroups): void { this.changeClient(); this.clientGroups = clientGroups; }

    changeClient() {
        this.textFilter = '';
    }

    getRequestFilter() {
        let headers = new HttpHeaders();
        headers = headers.set(SubscriptionsFilter.CLIENT_GROUP_HEADER,  this.clientGroups.map((c) => c.id).join(',') );

        const params = new HttpParams();
        return { params, headers };
    }

    getRequest() {
        let headers = new HttpHeaders();
        headers = headers.set(SubscriptionsFilter.CLIENT_GROUP_HEADER,  this.clientGroups.map((c) => c.id).join(',') );
        const params = new HttpParams();
        return { params, headers };
    }

    appendParam(header: string, filter: any, params: HttpParams ): HttpParams {
        if ( filter !== undefined) {
            return params.append(header, filter);
        }
        return params;
    }
}
