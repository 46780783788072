import { NotificationService } from '#services/_notification/notification.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'cmx-switch-section',
    templateUrl: './cmx-switch-section.component.html',
    styleUrls: ['./cmx-switch-section.component.scss'],
})
export class SwitchSectionComponent {
    @Input() enabled: boolean = false;
    @Input() switchName: string;
    @Input() linkUrl?: string;
    @Input() messages: string[] = [];
    @Input() messagesIcon?: string;
    @Input() messageIconColor: string = 'black';
    @Input() actionButton?: string;

    @Output('onSwitch') onSwitch = new EventEmitter();
    @Output('onActionClick') onActionClick = new EventEmitter();

    constructor(private _notificationService: NotificationService) { }

    onChangeSwitch() {
        this.enabled = !this.enabled;
        this.onSwitch.emit({
            switchName: this.switchName,
            switchValue: this.enabled
        });
    }

    copyToClipboard() {
        const inputKey = document.getElementById('cmx-switch-section-link') as HTMLInputElement;
        inputKey.select();
        document.execCommand('copy');
        this._notificationService.openSmallNotification('info', 'Copiado para área de transferência');
        this._notificationService.updateSmallNotification('info', 'Copiado para área de transferência');
    }

    actionClick() {
        this.onActionClick.emit();
    }

}