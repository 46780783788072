import { environment } from '#environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class NotificationsCRUDService {

    constructor(private http: HttpClient) {}

    public getNotificationConfiguration(): Observable<any> {
        return this.http.get(`${environment.endpoints.notificationService}/notifications/category/STATUS_CHANGE`);
    }

    public saveNotificationConfiguration(configuration): Observable<any> {
        return this.http.put(`${environment.endpoints.notificationService}/notifications/category/STATUS_CHANGE`,
        configuration);
    }

}
