import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';

export const INVOICES_COLUMNS: IGridColumn[] = [
    {
        field: 'invoiceNumber',
        name: 'Invoice',
        width: 50,
        formatterType: 'badge',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
    {
        field: 'invoiceDate',
        name: 'Data',
        width: 30,
        formatterType: 'date',
    },
    {
        field: 'totalQuantity',
        name: 'Quantidade Total',
        width: 45,
        formatterType: 'replaceDot',
    },
    {
        field: 'totalWeight',
        name: 'Peso Total',
        width: 40,
        formatterType: 'replaceDot',
    },
    {
        field: 'amount',
        name: 'Valor Faturado',
        width: 115,
        formatterType: 'currency',
        headerCssClass: 'grid-header align-right',
        cssClass: 'grid-cell align-right',
    },
];
