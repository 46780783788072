<router-outlet></router-outlet>
<app-scroll-top></app-scroll-top>
<div *ngIf="globalLoader" class="fullLoader app">
    <img src="assets/img/logos/logo-bi.png" style="display:none">
    <span style="display:none" class="exporting">• Iniciando •</span>
    <div class="loaderModel4">
        <div class="cube cube1"></div>
        <div class="cube cube2"></div>
        <div class="cube cube3"></div>
        <div class="cube cube4"></div>
        <div class="cube cube5"></div>
        <div class="cube cube6"></div>
    </div>
</div>

