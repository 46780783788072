import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '#environment';
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';

@Injectable()
export class InvoiceStatusService {
    private _baseUrl: string;

    constructor(private _http: HttpClient, private _featureFlagsService: FeatureFlagService) {
        this._baseUrl = `${environment.endpoints.invoiceService}/v1/client-invoices`;
    }

    getInvoices(request: any): Observable<any> {
        if (this._featureFlagsService.isFeatureFlagEnabled('invoice-service:client-invoice-use-elastic')) {
            return this._http.get(this._baseUrl, request);
        }

        return this._http.get(`${environment.endpoints.supplyChainService}/invoices`, request);
    }

    convertsToCompatibleData(response: any): any {
        const content = response.content.map((contentData: any) => {
            const imps = contentData.imps
                .filter((impData: any) => impData)
                .map((impData: any) => ({
                    ...impData,
                    imp: impData.importationNumber,
                    chassis: contentData.chassis.map((chassi: any) => chassi.chassisNumber),
                    vendor: impData.vendor && impData.vendor.name,
                    originalAmount: contentData.originalAmount,
                    originalCurrency: contentData.originalCurrency,
                }));

            return {
                ...contentData,
                invoiceNumber:
                    contentData.invoiceNumberSeries || `${contentData.invoiceNumber}-${contentData.invoiceSeries}`,
                imps,
            };
        });

        return content;
    }
}
