import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from 'app/shared/shared.module';
import { LayoutModule } from '../../../layouts/layout.module';

import { CostsService } from './costs.service';
import { CostsComponent } from './costs.component';
import { DetailCostsComponent } from './detail-costs/detail-costs.component';
import { SlickTabsModule } from 'app/shared/components/cmx-slick-tabs-modal/cmx-slick-tabs-modal.module';

const routes: Routes = [{ path: '', component: CostsComponent }];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        FormsModule,
        LayoutModule,
        SharedModule,
        SlickTabsModule
    ],
    declarations: [CostsComponent, DetailCostsComponent],
    providers: [CostsService],
})
export class CostsModule {}
