<div>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
    <!-- Creator: CorelDRAW 2018 (64 Bit) -->
    <svg id="svg-van" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="800px" height="500px" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
    viewBox="0 0 376.11 235.07"
     xmlns:xlink="http://www.w3.org/1999/xlink">
     <defs>
            <style type="text/css">
             <![CDATA[
              .str0 {stroke:#373435;stroke-width:4.06;stroke-miterlimit:22.9256}
              .fil0 {fill:none}
              .fil1 {fill:#848688}
             ]]>
            </style>
           </defs>
           <g id="Camada_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer"/>
                <rect id="svgArea" class="fil0" width="374.62" height="234.14"/>
                <path class="area str0" d="M147.06 217.76c24.81,0.11 49.12,0.11 70.94,0.01 -0.22,-1.19 -0.32,-2.38 -0.3,-3.55 -8.48,0.14 -20.31,0.32 -35.51,0.54 0,0 -11.63,0.02 -34.87,0.05l0.03 0c-0.02,0.98 -0.11,1.97 -0.29,2.95zm-4.57 -29.48c5.46,-15.73 18.77,-24.25 39.91,-25.54 15.94,-0.45 26.65,-0.23 39.38,-0.31 18.84,-0.02 28.27,-0.03 28.27,-0.03 3.59,-0.13 5.26,1.52 5,4.93 0.11,6.15 0.16,9.23 0.16,9.23 -0.16,3.71 -2.49,5.56 -7,5.53 -8.58,0.13 -17.27,0.39 -26.06,0.79 -0.06,7.55 -0.23,14.48 -0.81,20.79 1.26,-1.68 2.81,-3.18 4.62,-4.43 6.07,-4.19 14.04,-4.47 20.35,-0.64 1.15,0.7 2.2,1.51 3.12,2.41 3.09,-0.66 8.39,-1.03 15.91,-1.1 0.04,-1 0.06,-2 0.07,-2.99l0 0.35 -3.45 0c-0.55,0 -1.01,-0.46 -1.01,-1.01l0 -8.89c0,-0.56 0.46,-1.01 1.01,-1.01l3.17 0c-0.69,-9.98 -2.61,-19.18 -5.82,-27.07 -20.92,-0.45 -45.19,-0.45 -72.81,-0.02 -8.7,0.04 -17.2,1.31 -24.94,3.79 -3.36,2.82 -8.27,6.54 -13.05,9.4 -10.11,6.41 -8.15,8.46 -24.17,11.33l0.02 -0.01c-4.89,1.97 -7.98,2.48 -7.98,2.48 0.16,1.73 -0.02,3.43 -0.54,5.11 -1.27,3 -6.03,4.93 -14.28,5.77l0 0c-0.5,0.77 -1,1.57 -1.48,2.4 -0.79,1.61 -1.39,3.23 -1.8,4.85 5.1,0.74 10.51,0.87 15.38,0.35l-0.03 0.05c1.47,-2.34 3.47,-4.35 5.91,-5.85 6.14,-3.77 13.92,-3.57 19.86,0.49 1.82,1.25 3.36,2.78 4.59,4.51 -0.93,-4.68 -1.43,-9.9 -1.5,-15.66zm79.29 -25.85c0,0 0,0.04 0,0zm-60.27 0.65c0.06,-0.02 0.05,-0.02 0,0z"/>
                <path class="area str0" d="M265.13 186.36l-3.17 0c-0.55,0 -1.01,0.45 -1.01,1.01l0 8.89c0,0.55 0.46,1.01 1.01,1.01l3.45 0c0.06,-3.72 -0.03,-7.36 -0.28,-10.91z"/>
                <path class="area str0" d="M182.4 184.77c-12.33,0.7 -25.34,1.9 -39.91,3.51 0.13,10.7 1.74,19.54 4.83,26.53 23.24,-0.03 34.87,-0.05 34.87,-0.05 0.14,-20 0.21,-29.99 0.21,-29.99z"/>
                <path class="area str0" d="M182.4 184.77c-0.07,10.31 -0.14,20.31 -0.21,29.99 16.52,-0.24 29.07,-0.43 37.65,-0.58 1.84,-8.86 2.22,-19.3 2.31,-31.3 -14.38,0.64 -23.23,1.03 -39.75,1.89z"/>
                <path class="area str0" d="M182.4 184.77c0,-7.59 0,-14.93 0,-22.03 -21.14,1.29 -34.45,9.81 -39.91,25.54 15.63,-1.71 26.94,-2.77 39.91,-3.51z"/>
                <path class="area str0" d="M182.4 184.77c0,-8.53 0,-15.88 0,-22.03 15.94,-0.45 26.65,-0.23 39.38,-0.31 0.09,8.17 0.25,13.84 0.37,20.45 -15.93,0.72 -27.15,1.25 -39.75,1.89z"/>
                <path class="area str0" d="M222.15 182.88c-0.16,-7.12 -0.28,-13.93 -0.37,-20.45 18.84,-0.02 28.27,-0.03 28.27,-0.03 3.59,-0.13 5.26,1.52 5,4.93 0.11,6.15 0.16,9.23 0.16,9.23 -0.16,3.71 -2.49,5.56 -7,5.53 -8.58,0.13 -17.27,0.39 -26.06,0.79z"/>
                <path class="area str0" d="M143.21 217.75c2.57,0.01 3.85,0.01 3.85,0.01 0.18,-0.98 0.27,-1.97 0.29,-2.95l-0.03 0c-1.43,-3.23 -2.54,-6.85 -3.33,-10.87 -1.23,-1.73 -2.77,-3.26 -4.59,-4.51 -5.94,-4.06 -13.72,-4.26 -19.86,-0.49 -6.13,3.76 -9.49,10.78 -8.56,17.92 1.26,-0.03 2.45,-0.1 3.57,-0.22 -0.87,-5.81 1.83,-11.57 6.84,-14.63 5.02,-3.05 11.37,-2.8 16.13,0.63 4.76,3.43 7,9.39 5.69,15.11z"/>
                <path class="area str0" d="M265.34 199.91c-12.29,0.2 -18.66,1.08 -19.09,2.62 4.34,4.24 5.11,8.9 4.53,14.22 8.62,-0.04 14.56,-0.29 15.53,-3.88 1.56,-9.01 1.5,-13 -0.97,-12.96z"/>
                <circle class="area str0" cx="129.14" cy="214.53" r="11.64"/>
                <circle class="area str0" cx="236.66" cy="214.53" r="11.64"/>
                <path class="area str0" d="M116.38 186.26c-5.95,1.21 -10.89,4.83 -14.82,10.88 8.25,-0.84 13.01,-2.77 14.28,-5.77 0.52,-1.68 0.7,-3.38 0.54,-5.11z"/>
                <path class="area str0" d="M221.76 217.75c-0.96,-5.81 0.82,-12.01 5.71,-15.55 4.89,-3.53 12.09,-3.88 17.21,-0.69 1.18,0.74 1.57,1.02 1.57,1.02 0.17,-0.6 1.24,-1.1 3.21,-1.5 -0.94,-0.9 -1.99,-1.72 -3.15,-2.43 -6.31,-3.83 -14.28,-3.55 -20.35,0.64 -1.81,1.25 -3.36,2.75 -4.62,4.43 -0.34,3.72 -0.82,7.23 -1.5,10.51l-2.14 0.04c-0.02,1.17 0.08,2.36 0.3,3.55l3.76 -0.02z"/>
                <path class="area str0" d="M98.28 204.39c-0.84,3.5 -0.91,6.81 -0.19,9.94 3.58,1.79 7.88,2.64 12.89,2.53 -0.52,-4.33 0.37,-8.37 2.68,-12.12 -4.87,0.52 -10.28,0.39 -15.38,-0.35z"/>
                <path class="area str0" d="M144.56 171.5c4.38,-3.29 10.05,-6.1 17.01,-8.44 -3.37,2.82 -8.28,6.54 -13.06,9.4 -10.11,6.41 -8.15,8.46 -24.17,11.33 4.6,-1.94 8.47,-3.95 11.61,-6.03 3.7,-2.51 6.57,-4.59 8.61,-6.26z"/>
                <path class="area str0" d="M259.79 93.93c-0.76,-4.99 -3.5,-7.67 -8.22,-8.04 3.28,0.49 0.19,0 -0.16,0l-32.13 0 -39.66 0c0,0 -13.48,0.05 -41.47,0.16l-0.06 -0.01c-5.36,0.07 -9.4,1.46 -12.12,4.18l0 0.01 0 0.01 -0.01 0.01c-5.71,-1.72 -12.9,-2.26 -21.6,-1.59 3.99,-2.33 9.33,-3.85 16.36,-4.61 9.89,-0.3 34.19,-0.4 72.89,-0.29 41.11,0.14 63.73,0.21 63.73,0.21 5.45,0.06 8.24,2.01 9.41,5.98l-0.85 0c-1.38,0 -2.51,1.13 -2.51,2.51l0 23.91 -3.6 0 0 -22.44zm-133.82 -3.71l0 0 0 0zm0 0l0 0 0 0zm0 0l0 0 0 0zm0 0l0 0.01 0 -0.01zm0 0.01l0 0 0 0zm0 0l0 0 0 0z"/>
                <path class="area str0" d="M156.8 92.54c-10.44,-2.01 -20.72,-2.77 -30.83,-2.3 -7.68,13.11 -7.68,39.16 0,52.27 10.11,0.47 20.39,-0.3 30.83,-2.31 -6.61,-15.02 -6.61,-32.64 0,-47.66zm-11.56 18.8l2.02 0c0.45,0 0.82,0.37 0.82,0.83l0 8.41c0,0.45 -0.37,0.82 -0.82,0.82l-2.02 0c-0.46,0 -0.83,-0.37 -0.83,-0.82l0 -8.41c0,-0.46 0.37,-0.83 0.83,-0.83z"/>
                <path class="area str0" d="M179.6 90.3c-0.02,-2.21 0.02,-4.41 0.02,-4.41 0,0 -13.5,0.05 -41.54,0.16 12.18,3.02 25.72,4.43 41.52,4.25z"/>
                <polygon class="area str0" points="179.62,85.89 219.28,85.89 219.28,90.3 179.62,90.3 "/>
                <path class="area str0" d="M219.28 85.89l32.13 0c0.56,0 1.01,0.45 1.01,1.01l0 2.39c0,0.55 -0.45,1.01 -1.01,1.01l-32.13 0 0 -4.41z"/>
                <path class="area str0" d="M179.6 142.53c-0.02,2.21 0.02,4.41 0.02,4.41 0,0 -13.5,-0.05 -41.54,-0.16 12.18,-3.02 25.72,-4.43 41.52,-4.25z"/>
                <polygon class="area str0" points="179.62,146.94 219.28,146.94 219.28,142.53 179.62,142.53 "/>
                <path class="area str0" d="M219.28 146.94l32.13 0c0.56,0 1.01,-0.45 1.01,-1.01l0 -2.39c0,-0.55 -0.45,-1.01 -1.01,-1.01l-32.13 0 0 4.41z"/>
                <path class="area str0" d="M104.36 88.66c-6.24,3.66 -9.64,9.71 -10.21,18.15 -0.3,5.19 -0.27,11.84 0.09,19.94 0.83,8.15 4.2,13.93 10.12,17.34 8.69,0.66 15.9,0.14 21.61,-1.58 -7.52,-13 -7.75,-38.78 -0.01,-52.26 -5.71,-1.72 -12.91,-2.26 -21.6,-1.59z"/>
                <path class="area str0" d="M171.88 116.47l87.91 0c0,0 0,-7.26 0,-22.54 -0.76,-4.99 -3.5,-7.67 -8.22,-8.04 0.48,0.07 0.86,0.49 0.86,0.99l0 2.4c0,0.55 -0.46,1 -1.01,1l-32.14 0 -39.66 0c0,0 0,0 -0.02,0 -15.79,0.19 -29.33,-1.23 -41.51,-4.24 -5.36,0.07 -9.4,1.46 -12.12,4.18l0 0c-0.01,0.02 0.07,0.01 0.11,0.01 10.04,-0.46 20.24,0.31 30.6,2.29l0.13 0.01 -0.02 0.05c-1.09,2.48 -2,5.03 -2.73,7.63 -1.45,5.38 -2.25,11.37 -2.21,16.26l9 0c0,-0.03 0,-0.07 0,-0.1 0,-1.16 0.94,-2.1 2.1,-2.1 0.92,0 1.7,0.58 1.99,1.4l6.3 0c0.35,0 0.64,0.28 0.64,0.64l0 0.13 0 0.03z"/>
                <path class="area str0" d="M171.88 116.28l87.91 0c0,0 0,7.26 0,22.53 -0.75,4.89 -3.39,7.56 -7.92,8.02 0.31,-0.16 0.52,-0.47 0.55,-0.83 0,-0.04 0.01,-0.09 0.01,-0.14l0 -2.39c0,-0.55 -0.46,-1.01 -1.01,-1.01l-32.14 0 -39.66 0c0,0 0,0 -0.02,0 -15.79,-0.18 -29.33,1.23 -41.51,4.25 -5.36,-0.07 -9.4,-1.47 -12.12,-4.19l0 0 0 0 0 0 0 0 0 0 0 0 0 -0.01 0 0 0 0.01 0.11 0c10.04,0.45 20.24,-0.31 30.6,-2.29l0.13 -0.01 -0.02 -0.05c-1.09,-2.48 -2,-5.04 -2.73,-7.64 -1.45,-5.37 -2.25,-11.37 -2.21,-16.25l9 0c0,0.03 0,0.06 0,0.09 0,1.17 0.94,2.11 2.1,2.11 0.92,0 1.7,-0.58 1.99,-1.4l6.3 0c0.35,0 0.64,-0.29 0.64,-0.64l0 -0.13 0 -0.03zm-45.92 26.22l0 -0.01 -0.01 0 0.01 0.01 0 0zm0 0l0.01 0.01 -0.01 -0.01z"/>
                <path class="area str0" d="M162.95 114.27c0.92,0 1.7,0.58 1.99,1.4l6.3 0c0.35,0 0.64,0.28 0.64,0.64l0 0.13c0,0.35 -0.29,0.64 -0.64,0.64l-6.3 0c-0.29,0.82 -1.07,1.4 -1.99,1.4 -1.16,0 -2.1,-0.94 -2.1,-2.11 0,-1.16 0.94,-2.1 2.1,-2.1z"/>
                <path class="area str0" d="M266.75 142.75c0.39,-1.33 0.6,-2.89 0.66,-4.67 0.05,-14.49 0.07,-21.3 0.07,-21.71l0 -0.02 0 -0.02c0,-0.41 -0.02,-7.23 -0.07,-21.71 -0.06,-1.79 -0.27,-3.35 -0.66,-4.67l-0.85 0c-1.38,0 -2.51,1.13 -2.51,2.51l0 47.77c0,1.38 1.13,2.52 2.51,2.52l0.85 0z"/>
                <path class="area str0" d="M147.06 16.33c24.81,-0.11 49.12,-0.11 70.94,-0.01 -0.22,1.2 -0.32,2.38 -0.3,3.55 -8.48,-0.14 -20.31,-0.32 -35.51,-0.54 0,0 -11.63,-0.02 -34.87,-0.05l0.03 0c-0.02,-0.98 -0.11,-1.97 -0.29,-2.95zm-4.57 29.48c5.46,15.74 18.77,24.25 39.91,25.54 15.94,0.45 26.65,0.23 39.38,0.31 18.84,0.02 28.27,0.03 28.27,0.03 3.59,0.13 5.26,-1.52 5,-4.93 0.11,-6.15 0.16,-9.23 0.16,-9.23 -0.16,-3.71 -2.49,-5.55 -7,-5.53 -8.58,-0.13 -17.27,-0.39 -26.06,-0.79 -0.06,-7.55 -0.23,-14.48 -0.81,-20.79 1.26,1.68 2.81,3.18 4.62,4.43 6.07,4.19 14.04,4.47 20.35,0.64 1.15,-0.7 2.2,-1.51 3.12,-2.41 3.09,0.66 8.39,1.03 15.91,1.1 0.04,1 0.06,2 0.07,2.99l0 -0.35 -3.45 0c-0.55,0 -1.01,0.46 -1.01,1.01l0 8.89c0,0.56 0.46,1.01 1.01,1.01l3.17 0c-0.69,9.98 -2.61,19.18 -5.82,27.07 -20.92,0.45 -45.19,0.46 -72.81,0.02 -8.7,-0.04 -17.2,-1.31 -24.94,-3.79 -3.36,-2.82 -8.27,-6.54 -13.05,-9.4 -10.11,-6.41 -8.15,-8.46 -24.17,-11.33l0.02 0.01c-4.89,-1.97 -7.98,-2.48 -7.98,-2.48 0.16,-1.73 -0.02,-3.43 -0.54,-5.11 -1.27,-3 -6.03,-4.93 -14.28,-5.77l0 0c-0.5,-0.77 -1,-1.57 -1.48,-2.4 -0.79,-1.61 -1.39,-3.23 -1.8,-4.85 5.1,-0.74 10.51,-0.87 15.38,-0.35l-0.03 -0.04c1.47,2.33 3.47,4.34 5.91,5.84 6.14,3.77 13.92,3.57 19.86,-0.49 1.82,-1.24 3.36,-2.78 4.59,-4.51 -0.93,4.68 -1.43,9.9 -1.5,15.66zm79.29 25.85c0,0 0,-0.04 0,0zm-60.27 -0.65c0.06,0.02 0.05,0.02 0,0z"/>
                <path class="area str0" d="M265.13 47.73l-3.17 0c-0.55,0 -1.01,-0.45 -1.01,-1.01l0 -8.89c0,-0.55 0.46,-1.01 1.01,-1.01l3.45 0c0.06,3.72 -0.03,7.36 -0.28,10.91z"/>
                <path class="area str0" d="M182.4 49.33c-12.33,-0.71 -25.34,-1.91 -39.91,-3.52 0.13,-10.7 1.74,-19.54 4.83,-26.53 23.24,0.03 34.87,0.05 34.87,0.05 0.14,20 0.21,30 0.21,30z"/>
                <path class="area str0" d="M182.4 49.33c-0.07,-10.32 -0.14,-20.32 -0.21,-30 16.52,0.24 29.07,0.43 37.65,0.58 1.84,8.86 2.22,19.3 2.31,31.3 -14.38,-0.64 -23.23,-1.03 -39.75,-1.88z"/>
                <path class="area str0" d="M182.4 49.33c0,7.58 0,14.92 0,22.02 -21.14,-1.29 -34.45,-9.8 -39.91,-25.54 15.63,1.71 26.94,2.77 39.91,3.52z"/>
                <path class="area str0" d="M182.4 49.33c0,8.52 0,15.87 0,22.02 15.94,0.45 26.65,0.23 39.38,0.31 0.09,-8.17 0.25,-13.84 0.37,-20.45 -15.93,-0.72 -27.15,-1.25 -39.75,-1.88z"/>
                <path class="area str0" d="M222.15 51.21c-0.16,7.12 -0.28,13.94 -0.37,20.45 18.84,0.02 28.27,0.03 28.27,0.03 3.59,0.13 5.26,-1.52 5,-4.93 0.11,-6.15 0.16,-9.23 0.16,-9.23 -0.16,-3.71 -2.49,-5.55 -7,-5.53 -8.58,-0.13 -17.27,-0.39 -26.06,-0.79z"/>
                <path class="area str0" d="M143.21 16.34c2.57,-0.01 3.85,-0.01 3.85,-0.01 0.18,0.98 0.27,1.97 0.29,2.95l-0.03 0c-1.43,3.23 -2.54,6.85 -3.33,10.87 -1.23,1.73 -2.77,3.27 -4.59,4.51 -5.94,4.06 -13.72,4.26 -19.86,0.49 -6.13,-3.76 -9.49,-10.78 -8.56,-17.92 1.26,0.03 2.45,0.1 3.57,0.22 -0.87,5.81 1.83,11.57 6.84,14.63 5.02,3.05 11.37,2.8 16.13,-0.63 4.76,-3.43 7,-9.39 5.69,-15.11z"/>
                <path class="area str0" d="M265.34 34.18c-12.29,-0.2 -18.66,-1.08 -19.09,-2.62 4.34,-4.24 5.11,-8.9 4.53,-14.22 8.62,0.04 14.56,0.29 15.53,3.88 1.56,9.01 1.5,13 -0.97,12.96z"/>
                <circle class="area str0" cx="129.14" cy="19.57" r="11.64"/>
                <circle class="area str0" cx="236.66" cy="19.57" r="11.64"/>
                <path class="area str0" d="M116.38 47.83c-5.95,-1.2 -10.89,-4.83 -14.82,-10.88 8.25,0.84 13.01,2.77 14.28,5.77 0.52,1.68 0.7,3.38 0.54,5.11z"/>
                <path class="area str0" d="M221.76 16.34c-0.96,5.81 0.82,12.01 5.71,15.55 4.89,3.53 12.09,3.88 17.21,0.69 1.18,-0.74 1.57,-1.02 1.57,-1.02 0.17,0.6 1.24,1.1 3.21,1.5 -0.94,0.91 -1.99,1.72 -3.15,2.43 -6.31,3.83 -14.28,3.55 -20.35,-0.64 -1.81,-1.25 -3.36,-2.75 -4.62,-4.43 -0.34,-3.72 -0.82,-7.23 -1.5,-10.51l-2.14 -0.04c-0.02,-1.17 0.08,-2.36 0.3,-3.55l3.76 0.02z"/>
                <path class="area str0" d="M98.28 29.7c-0.84,-3.5 -0.91,-6.81 -0.19,-9.94 3.58,-1.79 7.88,-2.64 12.89,-2.53 -0.52,4.33 0.37,8.37 2.68,12.12 -4.87,-0.52 -10.28,-0.39 -15.38,0.35z"/>
                <path class="area str0" d="M144.56 62.59c4.38,3.29 10.05,6.1 17.01,8.44 -3.37,-2.82 -8.28,-6.54 -13.06,-9.4 -10.11,-6.41 -8.15,-8.46 -24.17,-11.33 4.6,1.94 8.47,3.95 11.61,6.03 3.7,2.51 6.57,4.6 8.61,6.26z"/>
                <path class="area str0" d="M68.3 85.87c2.21,-0.98 3.52,-1.33 4.99,-1.65l0 11.51 0 41.23 0 11.51c-3.27,-0.8 -2.93,-0.85 -4.99,-1.65l0 -60.95zm12.49 9.86l0 -11.71c3.31,0.75 5.73,2.88 5.75,7.01 0.09,8.41 0.09,17.04 0,25.43 0.09,8.39 0.09,16.79 0,25.2 -0.02,4.14 -2.44,6.26 -5.75,7.01l0 -11.71 0 -41.23z"/>
                <path class="area str0" d="M62.04 116.55c-0.05,-10.37 0.08,-16.45 0,-24.27 -0.01,-2.38 2.67,-4.76 6.26,-6.41l0 60.95c-3.59,-1.65 -6.27,-4.03 -6.26,-6.41 0.08,-7.82 -0.05,-13.49 0,-23.86z"/>
                <path class="area str0" d="M80.79 95.73l0 41.23c-2.5,-2.76 -5,-2.76 -7.5,0l0 -41.23c2.5,2.76 5,2.76 7.5,0z"/>
                <path class="area str0" d="M80.79 136.96l0 11.71c-2.25,0.5 -4.91,0.37 -7.5,-0.2l0 -11.51c2.5,-2.76 5,-2.76 7.5,0z"/>
                <path class="area str0" d="M80.79 84.02l0 11.71c-2.5,2.76 -5,2.76 -7.5,0l0 -11.51c2.59,-0.57 5.25,-0.7 7.5,-0.2z"/>
                <path class="area str0" d="M301.72 148.94c-2.32,0.01 -14.79,0.02 -22.18,0.04 -2.56,-0.04 -5.72,-2.97 -5.72,-5.58 -0.03,-9.2 -0.03,-19.72 0,-27.25 -0.03,-7.53 -0.03,-17.66 0,-26.86 0,-1.9 1.68,-3.97 3.58,-4.97 0.01,2.11 0.01,4.23 0,6.35 0.01,1.32 1.02,1.96 3.03,1.91 2.04,0 13.83,0 15.19,0 2.49,-0.5 3.7,-1.71 3.63,-3.65 -0.01,-1.68 0,-3.41 0.02,-5.19l3.61 0.02c-4.06,20.65 -4.06,42.38 0,65.17l-1.16 0.01zm-24.21 -64.68c0.27,-0.14 0.55,-0.25 0.83,-0.34 -0.27,0.09 -0.55,0.2 -0.83,0.34zm2.03 64.72c-0.66,-0.01 -1.37,-0.21 -2.14,-0.61 0.01,-2.11 0.01,-4.23 0,-6.35 0.01,-1.32 1.02,-1.96 3.03,-1.91 2.04,0 13.83,0 15.19,0 2.49,0.5 3.7,1.71 3.63,3.65 -0.01,1.68 0,3.41 0.02,5.19 -2.45,0.01 -15.75,0.02 -19.73,0.03z"/>
                <path class="area str0" d="M279.65 83.71c-0.66,0.01 -1.38,0.21 -2.14,0.61 0,2.11 0,4.23 0,6.35 0.01,1.32 1.02,1.96 3.03,1.9 2.04,0 13.72,0 15.08,0 2.49,-0.49 3.7,-1.7 3.63,-3.64 -0.01,-1.68 0,-3.41 0.02,-5.19 -2.45,-0.01 -15.64,-0.02 -19.62,-0.03z"/>
                <path class="area str0" d="M279.54 148.98c-0.66,-0.01 -1.37,-0.21 -2.14,-0.61 0.01,-2.11 0.01,-4.23 0,-6.35 0.01,-1.32 1.02,-1.96 3.03,-1.91 2.04,0 13.83,0 15.19,0 2.49,0.5 3.7,1.71 3.63,3.65 -0.01,1.68 0,3.41 0.02,5.19 -2.45,0.01 -15.75,0.02 -19.73,0.03z"/>
                <path class="area str0" d="M312.4 139.68c0,-2.17 0,-3.25 0,-3.25 -0.97,0 -2.53,0 -4.69,0 -1.44,0.03 -2.11,-0.96 -2.03,-2.96 -0.02,-2.01 0.67,-2.95 2.1,-2.83 1.32,0 2.88,0 4.71,0 0.14,-9.71 0.14,-19.24 0,-28.59 0,0 -1.57,0 -4.71,0 -1.51,0.04 -2.21,-0.9 -2.1,-2.83 -0.11,-1.97 0.56,-2.95 2.03,-2.96 1.56,0 3.13,0 4.69,0 0,0 0,-1.08 0,-3.24 0.03,-1.74 -0.65,-3.7 -2.02,-5.9 -1.18,-1.93 -2.7,-3.05 -4.56,-3.35 -0.87,-0.01 -1.85,-0.01 -2.94,-0.01 -4.06,20.65 -4.06,42.38 0,65.17 1.28,0 2.26,0 2.94,-0.01 2.01,-0.27 3.72,-1.69 5.12,-4.26 1.02,-1.96 1.51,-3.62 1.46,-4.98z"/>
                <circle class="area str0" cx="309.14" cy="99.11" r="1.9"/>
                <circle class="area str0" cx="309.14" cy="133.51" r="1.9"/>
                <path class="area str0" d="M134.46 148.87c4.7,0.02 7.05,0.03 7.05,0.03 -0.67,1.34 -0.45,2.77 0.66,4.27 0.72,0.72 1.04,1.86 0.96,3.41 -0.56,2.7 -2.14,3.38 -4.73,2.04 0,0 0,-1.52 0,-4.55 -0.34,-2.33 -1.65,-4.07 -3.94,-5.2z"/>
                <path class="area str0" d="M134.46 83.8c4.7,-0.01 7.05,-0.02 7.05,-0.02 -0.67,-1.35 -0.45,-2.78 0.66,-4.28 0.72,-0.72 1.04,-1.86 0.96,-3.41 -0.56,-2.7 -2.14,-3.38 -4.73,-2.03 0,0 0,1.51 0,4.54 -0.34,2.34 -1.65,4.07 -3.94,5.2z"/>
                <path class="area str0" d="M284.79 25.26l2.86 -4.95 2.86 -4.96 5.72 0 5.72 0 2.86 4.96 2.86 4.95 -2.86 4.95 -2.86 4.96 -5.72 0 -5.72 0 -2.86 -4.96 -2.86 -4.95zm11.44 -5.49c3.03,0 5.49,2.46 5.49,5.49 0,3.03 -2.46,5.49 -5.49,5.49 -3.03,0 -5.49,-2.46 -5.49,-5.49 0,-3.03 2.46,-5.49 5.49,-5.49z"/>
                <path class="area str0" d="M259.79 138.9c-0.76,4.99 -3.5,7.67 -8.22,8.04 3.28,-0.49 0.19,0 -0.16,0l-32.13 0 -39.66 0c0,0 -13.48,-0.05 -41.47,-0.16l-0.06 0.02c-5.36,-0.07 -9.4,-1.47 -12.12,-4.19l0 -0.01 0 0 -0.01 -0.02c-5.71,1.72 -12.9,2.26 -21.6,1.6 3.99,2.32 9.33,3.84 16.36,4.6 9.89,0.31 34.19,0.4 72.89,0.29 41.11,-0.14 63.73,-0.21 63.73,-0.21 5.45,-0.06 8.24,-2.01 9.41,-5.97l-0.85 0c-1.38,0 -2.51,-1.14 -2.51,-2.52l0 -23.9 -3.6 0 0 22.43zm-133.82 3.71l0 0 0 0zm0 0l0 0 0 0zm0 0l0 0 0 0zm0 0l0 0 0 0zm0 0l0 -0.01 0 0.01zm0 -0.01l0 0 0 0z"/>
                <rect class="area str0" x="144.41" y="111.34" width="3.67" height="10.06" rx="0.82" ry="0.82"/>
               </g>
    </svg>
  </div>
