import { _applyPosFormatters } from "./pos-formatters/pos-formatters";
import { textFormatter } from "./text.formatter";

export function kpiFormatter(row, cell, value, columnDef, dataContext) {
    if (!value) { return textFormatter(row, cell, value, columnDef, dataContext) }
    const kpiNames = value.name.split(' X ');
    const imgPath = '../../../../../assets/app/media/img/icons/kpis/';
    const contentValue = `<div class="slick-kpi-formatter">
        <div class="slick-kpi-name">
            <span>${kpiNames[0]}</span>
            <span>${kpiNames[1]}</span>
        </div>
        <div class="slick-kpi-image">
            <img src='${imgPath}${value.event1}.png'>
            <img src='${imgPath}${value.event2}.png'>
        </div>
    </div>`;
    return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
}