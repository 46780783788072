<div class="cmx-filter-multiple-datepicker">
    <div *ngFor="let dateType of dateTypes; let i = index" class="dateType"
    [style.width]="i > 0 ? '187px' : '182px'">
        <span (click)="openCalendar(dateType)" class="material-icons"
        [style.margin-left]="i > 0 ? '4px' : '0px'"
        >insert_invitation</span>
        <div class="dateContainer">
            <p-calendar 
                #calendars
                id="{{ dateType?.code }}"
                class="rangeDates"
                dateFormat="dd/mm/yy"
                selectionMode="range"
                rangeSeparator="até"
                [readonlyInput]="true"
                [style]="{'position':'absolute', 'bottom':'2px'}"
                [inputStyle]="{'border':'0px', 'font-size':'10px', 'font-weight':'700', 'width':'140px', 'background-color':'transparent', 'padding':'0px'}"
                [ngModel]="dateType.range"
                (ngModelChange)="changeDates($event, dateType.name, dateType.code, dateType.activated)">
            ></p-calendar>
            <span id="{{ dateType?.code }}-noData" class="noData hidden">Desconsiderando Data</span>
            <span (click)="openCalendar(dateType)" class="name">{{ dateType?.name }}</span>
        </div>
        <span *ngIf="desactivated.length < (dateTypes.length - 1) || !dateType.activated"
        (click)="clearDate(dateType)" class="material-icons deleteBtn" 
        [style.color]="dateType?.activated ? '#a70101' : '#09c717'"
        [style.right]="dateType?.activated ? '0px' : '5px'">{{ dateType?.activated ? 'clear' : 'add'}}</span>
    </div>
</div>