<div class="structure-container">
    <div *ngIf="!followUp" class="left-container">
        <!-- Formatação Valores -->
        <div *ngIf="amountsFormat" style="margin-bottom: 30px">
            <label class="">Formatação Valores</label>
            <div class="">
                <div class="">
                    <div class="m-radio-list">
                        <label class="m-radio" *ngFor="let option of options.amountsFormat; trackBy: trackByFn">
                            <input name="value_format" type="radio" [value]="option.value" [checked]="editableStructure.amountsFormat == option.value"
                                (change)="changeAmountsFormat(option.value, option.example)">{{option.desc}}
                            <span></span>
                        </label>
                    </div>
                    <span class="m-form__help">{{help.amountsFormat}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="right-container">
        <div class="structure-flex-container">
            <!-- Valores Zerados -->
            <div *ngIf="zeroedNodes" class="structure-flex-item">
                <label class="">Mostrar Contas Zeradas</label>
                <div class="">
                    <div class="">
                        <span class="m-switch m-switch--icon m-switch--primary">
                            <label>
                                <input type="checkbox" name="" [checked]="editableStructure.displayZeroedNodes" (click)="toggleZeroedNodes()">
                                <span></span>
                            </label>
                        </span>
                        <br/>
                        <span class="m-form__help">{{help.zeroedNodes}}</span>
                    </div>
                </div>
            </div>
            <!-- Mostrar Apenas 35 -->
            <div *ngIf="allProfitCenter" class="structure-flex-item">
                <label class="">Mostrar Todos CR</label>
                <div class="">
                    <div class="">
                        <span class="m-switch m-switch--icon m-switch--primary">
                            <label>
                                <input disabled type="checkbox" name="" [checked]="editableStructure.allProfitCenter" (click)="toggleProfitCenter()">
                                <span></span>
                            </label>
                        </span>
                        <br/>
                        <span class="m-form__help">{{help.allProfitCenter}}</span>
                    </div>
                </div>
            </div>
            <!-- Mostrar Arrendatários -->
            <div *ngIf="showLeaseHolder" class="structure-flex-item">
                <label class="">Mostrar Por Arrendatário</label>
                <div class="">
                    <div class="">
                        <span class="m-switch m-switch--icon m-switch--primary">
                            <label>
                                <input type="checkbox" name="" [checked]="editableStructure.showLeaseHolder" (click)="toggleLeaseHolder()">
                                <span></span>
                            </label>
                        </span>
                        <br/>
                        <span class="m-form__help">{{help.showLeaseHolder}}</span>
                    </div>
                </div>
            </div>
            <!-- Mostrar Serviços -->
            <div *ngIf="showServiceInfo" class="structure-flex-item">
                <label class="">Mostrar Serviços</label>
                <div class="">
                    <div class="">
                        <span class="m-switch m-switch--icon m-switch--primary">
                            <label>
                                <input type="checkbox" name="" [checked]="editableStructure.showServiceInfo" (click)="toggleServiceInfo()">
                                <span></span>
                            </label>
                        </span>
                        <br/>
                        <span class="m-form__help">{{help.showServiceInfo}}</span>
                    </div>
                </div>
            </div>
            <!-- Mostrar Datas (FollowUp) -->
            <div *ngIf="followUp" class="structure-flex-item followUp">
                <label class="">Mostrar Datas</label>
                <div class="">
                    <div class="">
                        <span class="m-switch m-switch--icon m-switch--primary">
                            <label>
                                <input type="checkbox" name="" [checked]="followUpColumns.followUpDates" (click)="toggleFollowUp('dates')">
                                <span></span>
                            </label>
                        </span>
                        <br/>
                        <span class="m-form__help">{{help.followUpDates}}</span>
                    </div>
                </div>
            </div>
            <!-- Mostrar Cliente (FollowUp) -->
            <div *ngIf="followUp" class="structure-flex-item followUp">
                <label class="">Mostrar Cliente</label>
                <div class="">
                    <div class="">
                        <span class="m-switch m-switch--icon m-switch--primary">
                            <label>
                                <input type="checkbox" name="" [checked]="followUpColumns.followUpClient" (click)="toggleFollowUp('client')">
                                <span></span>
                            </label>
                        </span>
                        <br/>
                        <span class="m-form__help">{{help.followUpClient}}</span>
                    </div>
                </div>
            </div>
            <!-- Mostrar Importação (FollowUp) -->
            <div *ngIf="followUp" class="structure-flex-item followUp">
                <label class="">Mostrar Importação</label>
                <div class="">
                    <div class="">
                        <span class="m-switch m-switch--icon m-switch--primary">
                            <label>
                                <input type="checkbox" name="" [checked]="followUpColumns.followUpImp" (click)="toggleFollowUp('imp')">
                                <span></span>
                            </label>
                        </span>
                        <br/>
                        <span class="m-form__help">{{help.followUpImp}}</span>
                    </div>
                </div>
            </div>
            <!-- Mostrar PO (FollowUp) -->
            <div *ngIf="followUp" class="structure-flex-item followUp">
                <label class="">Mostrar P.O</label>
                <div class="">
                    <div class="">
                        <span class="m-switch m-switch--icon m-switch--primary">
                            <label>
                                <input type="checkbox" name="" [checked]="followUpColumns.followUpPo" (click)="toggleFollowUp('po')">
                                <span></span>
                            </label>
                        </span>
                        <br/>
                        <span class="m-form__help">{{help.followUpPo}}</span>
                    </div>
                </div>
            </div>
            <!-- Mostrar Invoice (FollowUp) -->
            <div *ngIf="followUp" class="structure-flex-item followUp">
                <label class="">Mostrar Invoice</label>
                <div class="">
                    <div class="">
                        <span class="m-switch m-switch--icon m-switch--primary">
                            <label>
                                <input type="checkbox" name="" [checked]="followUpColumns.followUpInvoice" (click)="toggleFollowUp('invoice')">
                                <span></span>
                            </label>
                        </span>
                        <br/>
                        <span class="m-form__help">{{help.followUpInvoice}}</span>
                    </div>
                </div>
            </div>
            <!-- Mostrar LI (FollowUp) -->
            <div *ngIf="followUp" class="structure-flex-item followUp">
                <label class="">Mostrar LI</label>
                <div class="">
                    <div class="">
                        <span class="m-switch m-switch--icon m-switch--primary">
                            <label>
                                <input type="checkbox" name="" [checked]="followUpColumns.followUpLi" (click)="toggleFollowUp('li')">
                                <span></span>
                            </label>
                        </span>
                        <br/>
                        <span class="m-form__help">{{help.followUpLi}}</span>
                    </div>
                </div>
            </div>
            <!-- Mostrar Transito (FollowUp) -->
            <div *ngIf="followUp" class="structure-flex-item followUp">
                <label class="">Mostrar Trânsito</label>
                <div class="">
                    <div class="">
                        <span class="m-switch m-switch--icon m-switch--primary">
                            <label>
                                <input type="checkbox" name="" [checked]="followUpColumns.followUpSailing" (click)="toggleFollowUp('sailing')">
                                <span></span>
                            </label>
                        </span>
                        <br/>
                        <span class="m-form__help">{{help.followUpSailing}}</span>
                    </div>
                </div>
            </div>
            <!-- Mostrar DTA (FollowUp) -->
            <div *ngIf="followUp" class="structure-flex-item followUp">
                <label class="">Mostrar DTA</label>
                <div class="">
                    <div class="">
                        <span class="m-switch m-switch--icon m-switch--primary">
                            <label>
                                <input type="checkbox" name="" [checked]="followUpColumns.followUpDta" (click)="toggleFollowUp('dta')">
                                <span></span>
                            </label>
                        </span>
                        <br/>
                        <span class="m-form__help">{{help.followUpDta}}</span>
                    </div>
                </div>
            </div>
            <!-- Mostrar Desembaraço (FollowUp) -->
            <div *ngIf="followUp" class="structure-flex-item followUp">
                <label class="">Mostrar Desembaraço</label>
                <div class="">
                    <div class="">
                        <span class="m-switch m-switch--icon m-switch--primary">
                            <label>
                                <input type="checkbox" name="" [checked]="followUpColumns.followUpClearence" (click)="toggleFollowUp('clearence')">
                                <span></span>
                            </label>
                        </span>
                        <br/>
                        <span class="m-form__help">{{help.followUpClearence}}</span>
                    </div>
                </div>
            </div>
            <!-- Mostrar Penalidade (FollowUp) -->
            <div *ngIf="followUp" class="structure-flex-item followUp">
                <label class="">Mostrar Penalidade</label>
                <div class="">
                    <div class="">
                        <span class="m-switch m-switch--icon m-switch--primary">
                            <label>
                                <input type="checkbox" name="" [checked]="followUpColumns.followUpPenalty" (click)="toggleFollowUp('penalty')">
                                <span></span>
                            </label>
                        </span>
                        <br/>
                        <span class="m-form__help">{{help.followUpPenalty}}</span>
                    </div>
                </div>
            </div>
            <!-- Mostrar Container (FollowUp) -->
            <div *ngIf="followUp" class="structure-flex-item followUp">
                <label class="">Mostrar Container</label>
                <div class="">
                    <div class="">
                        <span class="m-switch m-switch--icon m-switch--primary">
                            <label>
                                <input type="checkbox" name="" [checked]="followUpColumns.followUpContainer" (click)="toggleFollowUp('container')">
                                <span></span>
                            </label>
                        </span>
                        <br/>
                        <span class="m-form__help">{{help.followUpContainer}}</span>
                    </div>
                </div>
            </div>
            <!-- Mostrar Entrega (FollowUp) -->
            <div *ngIf="followUp" class="structure-flex-item followUp">
                <label class="">Mostrar Entrega</label>
                <div class="">
                    <div class="">
                        <span class="m-switch m-switch--icon m-switch--primary">
                            <label>
                                <input type="checkbox" name="" [checked]="followUpColumns.followUpDelivery" (click)="toggleFollowUp('delivery')">
                                <span></span>
                            </label>
                        </span>
                        <br/>
                        <span class="m-form__help">{{help.followUpDelivery}}</span>
                    </div>
                </div>
            </div>
            <!-- Mostrar Notas Fiscais (FollowUp) -->
            <div *ngIf="followUp" class="structure-flex-item followUp">
                <label class="">Mostrar Notas Fiscais</label>
                <div class="">
                    <div class="">
                        <span class="m-switch m-switch--icon m-switch--primary">
                            <label>
                                <input type="checkbox" name="" [checked]="followUpColumns.followUpNf" (click)="toggleFollowUp('nf')">
                                <span></span>
                            </label>
                        </span>
                        <br/>
                        <span class="m-form__help">{{help.followUpNf}}</span>
                    </div>
                </div>
            </div>
            <!-- Mostrar Faturamento (FollowUp) -->
            <div *ngIf="followUp" class="structure-flex-item followUp">
                <label class="">Mostrar Faturamento</label>
                <div class="">
                    <div class="">
                        <span class="m-switch m-switch--icon m-switch--primary">
                            <label>
                                <input type="checkbox" name="" [checked]="followUpColumns.followUpBilling" (click)="toggleFollowUp('billing')">
                                <span></span>
                            </label>
                        </span>
                        <br/>
                        <span class="m-form__help">{{help.followUpBilling}}</span>
                    </div>
                </div>
            </div>
            <!-- Mostrar Outros (FollowUp) -->
            <div *ngIf="followUp" class="structure-flex-item followUp">
                <label class="">Mostrar Outros</label>
                <div class="">
                    <div class="">
                        <span class="m-switch m-switch--icon m-switch--primary">
                            <label>
                                <input type="checkbox" name="" [checked]="followUpColumns.followUpOthers" (click)="toggleFollowUp('others')">
                                <span></span>
                            </label>
                        </span>
                        <br/>
                        <span class="m-form__help">{{help.followUpOthers}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="structure-buttons">
        <span *ngIf="followUp && !allChecked" (click)="changeAllFollowUp(true)" class="selectBtn all">Selecionar Todos</span>
        <span *ngIf="followUp && allChecked" (click)="changeAllFollowUp(false)" class="selectBtn none">Deselecionar Todos</span>
        <button type="button" class="btn btn-secondary" onclick="$('.reportStructureModal').addClass('hidden');">Cancelar</button>
        <button type="button" class="btn btn-outline-primary m-btn" (click)="applyChanges()">Aplicar</button>
    </div>
</div>
