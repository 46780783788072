export class FollowUpModel {

    public modelName: string;
    public color: string;
    public description: string;
    public icon: string;

    constructor(model?: FollowUpModel) {
        if (model) {
            this.modelName = model.modelName || 'Padrão';
            this.color = model.color || '#808080';
            this.description = model.description || 'Modelo Padrão de FollowUp';
            this.icon = model.icon || 'content_paste';
        }
    }

    public getName() { return this.modelName; }
    public getColor() { return this.color; }
    public getDescription() { return this.description; }
    public getIcon() { return this.icon; }

    public setName(modelName) { this.modelName = modelName; }
    public setColor(color) { this.color = color; }
    public setDescription(description) { this.description = description; }
    public setIcon(icon) { this.icon = icon; }

}
