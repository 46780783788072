
import {throwError as observableThrowError, of as observableOf,  Observable } from 'rxjs';

import {delay, take, map, concat, mergeMap, retryWhen} from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import moment from 'moment';
import { DownloadService } from '../_download/download.service';
import { environment } from '#environment';
import { byMonth } from 'app/shared/mocks/results-service.mock';

export enum Hierarchy {
    balance_sheet = 'balance-sheet',
    income_statement = 'income-statement',
    income_statement_mgmt = 'income-statement-mgmt',
    income_statement_mgmt_legacy = 'income-statement-mgmt-legacy',
}

export enum Grouping {
    byCompany = 'byCompany',
    byCr = 'byCr',
    byCrGrouped = 'byCrGrouped',
    byImp = 'byImp',
    byTrader = 'byTrader',
    byClient = 'byClient',
    byDirector = 'byDirector',
    byMonth = 'byMonth',
}

export enum PeriodGrouping {
    month = 'month',
    quarter = 'quarter',
    halfYear = 'halfYear',
    year = 'year',
}


export interface ExportOptions {
    amountsFormat?: string;
    displayAccountCodes?: boolean;
}

@Injectable()
export class ResultsService {

    private readonly EMPTY = {
        metadata: { columns: [] },
        results: [],
    };

    private readonly endpoint;

    constructor(
        private http: HttpClient,
        private downloadService: DownloadService,
    ) {
        this.endpoint = environment.endpoints.resultsService;
    }

    public createResultsQuery(hierarchy: Hierarchy, grouping: Grouping, sortBy: string, filter?: Filter): Observable<string> {
        const requestFilter: {
            companies?: number[],
            clients?: number[],
            clientGroups?: string[],
            profitCenters?: number[],
            directors?: number[],
            traders?: number[],
            startDate?: string,
            endDate?: string,
            currency?: string,
            imps?: string[],
            paymentTypes?: string[],
        } = {};

        if (this.isSet(filter.companies)) {
            requestFilter.companies = filter.companies;
        }
        if (this.isSet(filter.clients)) {
            requestFilter.clients = filter.clients;
        }
        if (this.isSet(filter.clientGroups)) {
            requestFilter.clientGroups = filter.clientGroups;
        }
        if (this.isSet(filter.profitCenters)) {
            requestFilter.profitCenters = filter.profitCenters;
        }
        if (this.isSet(filter.directors)) {
            requestFilter.directors = filter.directors;
        }
        if (this.isSet(filter.traders)) {
            requestFilter.traders = filter.traders;
        }
        if (this.isSet(filter.startDate)) {
            requestFilter.startDate = this.formatDate(filter.startDate);
        }
        if (this.isSet(filter.endDate)) {
            requestFilter.endDate = this.formatDate(filter.endDate);
        }
        if (this.isSet(filter.currency)) {
            requestFilter.currency = filter.currency;
        }
        if (this.isSet(filter.imps)) {
            requestFilter.imps = filter.imps;
        }

        if (this.isSet(filter.transactionTypes)) {
            requestFilter.paymentTypes = filter.transactionTypes;
        }

        const sendingFilter = _.cloneDeep(requestFilter);
        if (sendingFilter.imps) {
            if (sendingFilter.imps.length > 0) {
                const imps = [];
                sendingFilter.imps.forEach((element) => {
                    imps.push(element['id']);
                });
                sendingFilter.imps = imps;
            }
        }

        if (filter.crGrouped === true) {
            grouping = Grouping.byCrGrouped;
        }

        const request: { hierarchyType: Hierarchy, grouping: Grouping, sortBy: string, filter?: any } = {
            hierarchyType: hierarchy,
            grouping,
            sortBy: sortBy,
            filter: sendingFilter,
        };
        return this.http.post(`${this.endpoint}/`, request).pipe(
            map((data: any) => data.uuid));
    }

    public export(uuid: any, options: Options, exportOptions: ExportOptions): Observable<any> {
        return this.http.get(`${this.endpoint}/${uuid}`, {
            observe: 'response',
            responseType: 'blob',
            headers: new HttpHeaders({
                Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
            params: this.toHttpParams(options, exportOptions),
        });
    }

    public exportWithRelativePath(relativePath: string, isXslx: boolean): Observable<any> {
        const options = this.downloadService.getSourceOptions();
        var fileExtension = '';

        if (isXslx) {
            fileExtension = '.xlsx';
        }

        options.headers = new HttpHeaders({
            'X-Requested-Client-Groups': '',
            'Accept': isXslx ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                : 'application/zip',
        });

        const url = this.http.get(`${this.endpoint}/${relativePath}`, options);
        return this.downloadService.downloadFrom(url, `Planilhas de Custos${fileExtension}`);
    }

    public getResultsByUUID(uuid: string, options?: Options): Observable<any> {

        const params = this.toHttpParams(options);

        return this.http.get(`${this.endpoint}/${uuid}`, {
            params,
        }).pipe(
            retryWhen((errors) => {
                return errors.pipe(mergeMap((error: any) => {
                    if (error.status === 507) {
                        return observableOf(error.status).pipe(delay(1000));
                    } else {
                        return observableThrowError(error);
                    }
                }),
                    take(100),
                    concat(observableThrowError({})),);
            }),
            map((data) => data || { metadata: { columns: [] }, results: [] }),);

    }

    getAccountHierarchyGroups(): Observable<any> {
        return this.http.get(`${this.endpoint}/get-account-hierarchy-groups`);
    }

    private format(values: any[]): string {
        const joinedValues: string = values.join(',');
        return joinedValues || '';
    }

    private handleError(error: Response | any) {
        return observableThrowError(error.status);
    }

    private isSet(values: any[] | string): boolean {
        return values !== undefined && values.length > 0;
    }

    private formatDate(date: string) {
        return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    private toHttpParams(options: Options, exportOptions?: ExportOptions) {
        let params = new HttpParams();

        if (options) {
            if (options.accumulateBalance != null) {
                params = params.set('accumulateBalance', `${options.accumulateBalance}`);
            }

            if (options.periodGrouping != null) {
                params = params.set('periodGrouping', `${options.periodGrouping}`);
            }

            if (options.clientGrouping != null) {
                params = params.set('clientGrouping', `${options.clientGrouping ? 'group' : 'none'}`);
            }
        }

        if (exportOptions) {
            const { amountsFormat: amountFormat, displayAccountCodes } = exportOptions;
            params = params.set('amountFormat', amountFormat);
            params = params.set('descriptionFormat', displayAccountCodes ? 'codeAndName' : 'nameOnly');
        }

        return params;
    }
}

@Injectable()
export class MockedBalanceSheetService {

    public createResultsQuery(hierarchy: Hierarchy, grouping: Grouping, filter?: any): Observable<any> {
        return observableOf('uuid').pipe(delay(1000));
    }

    public getResultsByUUID(uuid: String): Observable<any> {
        return observableOf({
            metadata: JSON.parse(JSON.stringify(byMonth.metadata)),
            results: JSON.parse(JSON.stringify(byMonth.results)),
        }).pipe(delay(1000));
    }
}

export class Filter {
    public companies: number[] = [];
    public profitCenters: number[] = [];
    public costCenters: number[] = [];
    public traders: number[] = [];
    public directors: number[] = [];
    public clients: number[] = [];
    public clientGroups: string[] = [];
    public startDate: string;
    public endDate: string;
    public exchangeReferenceDate: string;
    public accountingCode: number[] = [];
    public currency: string;
    public imps: string[] = [];
    public crGrouped: boolean;
    public transactionTypes: string[] = [];
    public paymentTypes: string[] = [];
    public vendorNames: string[] = [];
    public users: string[] = [];

    public clone() {
        const clone = new Filter();
        clone.companies = this.companies;
        clone.profitCenters = this.profitCenters;
        clone.costCenters = this.costCenters;
        clone.traders = this.traders;
        clone.directors = this.directors;
        clone.clients = this.clients;
        clone.clientGroups = this.clientGroups;
        clone.startDate = this.startDate;
        clone.endDate = this.endDate;
        clone.exchangeReferenceDate = this.exchangeReferenceDate;
        clone.accountingCode = this.accountingCode;
        clone.currency = this.currency;
        clone.imps = this.imps;
        clone.crGrouped = this.crGrouped;
        clone.transactionTypes = this.transactionTypes;
        const types = [];
        if (this.transactionTypes.length > 0) {
            this.transactionTypes.forEach((corporate) => {
                types.push(corporate);
            });
        }
        clone.paymentTypes = types;
        return clone;
    }
}

export class Options {
    public accumulateBalance: boolean;
    public periodGrouping: string;
    public clientGrouping: boolean;
}
