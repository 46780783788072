import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '#environment';
import { Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ExchangeSpBeneficiariesService {

    constructor(private _http: HttpClient) { }

    getBeneficiarieWithBankData(vendor): Observable<any> {
        return this._http.get(`${environment.endpoints.exchangeService}/beneficiary/${vendor}?homologated=true`);
    }

}
