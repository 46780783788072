import { AppModule } from 'app/app.module';
import moment from 'moment';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IDocumentConsolidationFilter } from './interfaces/document-consolidation-filter.interface';

export class DocumentConsolidationFilter {
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialDocumentConsolidationFilter: IDocumentConsolidationFilter = <IDocumentConsolidationFilter>{};
    private _documentConsolidationFilter: IDocumentConsolidationFilter = <IDocumentConsolidationFilter>{
        clientGroups: [],
        subsidiaryNames: [],
        urfs: [],
    };


    private startDate: string;
    private endDate: string;

    constructor(filter?: DocumentConsolidationFilter) {
        this._documentConsolidationFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'documentConsolidationFilter', this._documentConsolidationFilter, filter);
        this._initialDocumentConsolidationFilter = _.cloneDeep(this._documentConsolidationFilter);
        this.startDate = moment().add(-11, 'M').format('MM/YYYY');
        this.endDate = moment().format('MM/YYYY');
    }
    public getClients() {
        return this._documentConsolidationFilter.clientGroups;
    }
    public getSubsidiaryNames() {
        return this._documentConsolidationFilter.subsidiaryNames;
    }
    public getUrfs() {
        return this._documentConsolidationFilter.urfs;
    }
    public getStartDate(): string {
        return this.startDate;
    }
    public getEndDate(): string {
        return this.endDate;
    }

    public setClients(clientGroups): void {
        this._documentConsolidationFilter.clientGroups = clientGroups;
    }
    public setSubsidiaryNames(subsidiaryNames): void {
        this._documentConsolidationFilter.subsidiaryNames = subsidiaryNames;
    }
    public setUrfs(urfs): void {
        this._documentConsolidationFilter.urfs = urfs;
    }
    public setStartDate(startDate): void {
        this.startDate = startDate;
    }
    public setEndDate(endDate): void {
        this.endDate = endDate;
    }

    savePreferences() {
        this._initialDocumentConsolidationFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialDocumentConsolidationFilter, this._documentConsolidationFilter, 'documentConsolidationFilter', this._userCacheService.getUserPreferences().id);
    }

}
