import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';

export const SKU_COLUMNS: IGridColumn[] = [
        {field: 'skuCode', label: 'SKU Code', name: 'SKU Code', width: 120, groupName: 'SKU', formatterType: 'multiple'}, 
        {field: 'skuInvoiceNumber', label: 'Número Invoice SKU', name: 'Número Invoice SKU', width: 200, groupName: 'SKU', formatterType: 'multiple'}, 
        {field: 'skuDescription', label: 'Produto', name: 'Produto', width: 400, groupName: 'SKU', formatterType: 'multiple'}, 
        {field: 'skuBrand', label: 'Marca', name: 'Marca', width: 120, groupName: 'SKU', formatterType: 'multiple'}, 
        {field: 'skuFamily', label: 'Família', name: 'Família', width: 120, groupName: 'SKU', formatterType: 'multiple'}, 
        {field: 'skuLine', label: 'Linha', name: 'Linha', width: 120, groupName: 'SKU', formatterType: 'multiple'}, 
        {field: 'skuCollection', label: 'Coleção', name: 'Coleção', width: 120, groupName: 'SKU', formatterType: 'multiple'}, 
        {field: 'skuNcm', label: 'NCM', name: 'NCM', width: 120, groupName: 'SKU', formatterType: 'multiple'}, 
        {field: 'skuManufacturerName', label: 'Fornecedor SKU', name: 'Fornecedor SKU', width: 400, groupName: 'SKU', formatterType: 'multiple'}, 
        {field: 'skuWeightAsBigDecimal', label: 'Peso Líquido', name: 'Peso Líquido', width: 120, groupName: 'SKU', formatterType: 'multiple'}, 
        {field: 'skuGrossWeightAsBigDecimal', label: 'Peso Bruto', name: 'Peso Bruto', width: 120, groupName: 'SKU', formatterType: 'multiple'}, 
        {field: 'skuQuantity', label: 'Qtd', name: 'Qtd', width: 120, groupName: 'SKU', formatterType: 'multiple'}, 
        {field: 'skuPrice', label: 'Valor Unitário', name: 'Valor Unitário', width: 120, groupName: 'SKU', formatterType: 'multiple'}, 
        {field: 'skuTotalPrice', label: 'Valor Total SKU', name: 'Valor Total SKU', width: 120, groupName: 'SKU', formatterType: 'multiple'}, 
        {field: 'skuUnitMeasureDescription', label: 'Unidade', name: 'Unidade', width: 120, groupName: 'SKU', formatterType: 'multiple'}, 
        {field: 'skuBillableQuantityDecimal', label: 'Qtd. Faturável', name: 'Qtd. Faturável', width: 120, groupName: 'SKU', formatterType: 'multiple'}, 
        {field: 'skuBillableUnitMeasureDescription', label: 'Unidade Faturável', name: 'Unidade Faturável', width: 120, groupName: 'SKU', formatterType: 'multiple'}, 
        {field: 'skuBatchNumber', label: 'Lote', name: 'Lote', width: 120, groupName: 'SKU', formatterType: 'multiple'}, 
];
