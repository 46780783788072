import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(value: any, args?: any, itemsToExclude: string[] = []): any {
    if(!value) return null;
    if(!args) return value;
    args = args.toLowerCase();

    return value.filter((item) => {
        const itemSearch = {...item};
        if(itemsToExclude.length > 0){
            itemsToExclude.forEach(propertyRemove => {
                if(Object(itemSearch).hasOwnProperty(propertyRemove)){
                    delete itemSearch[propertyRemove];
                }
            });
        }
        const row = JSON.stringify(itemSearch).toLowerCase().includes(args);
        if(row){
            return item;
        }
    })
  }

}
