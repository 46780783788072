import { environment } from '#environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class OperationalConsolidationService {

    constructor(private http: HttpClient) { }

    public getConsolidation(startDate, endDate, clients, profitCenters): Observable<any> {
        return this.http.get(`${environment.endpoints.importationService}/stats/imp?startDate=${startDate}&endDate=${endDate}&profitCenters=${this.getCostCentersCodes(profitCenters)}`, this.getHeaders(clients));
    }

    // IMPS, TEUS, TRUCK_TRAILERS, TONS_BY_AIR, AIRCRAFTS
    public getDetailedConsolidation(startDate,endDate, type, clients, profitCenters): Observable<any> {
        return this.http.get(`${environment.endpoints.importationService
        }/stats/details/imp?startDate=${startDate}&endDate=${endDate}&type=${type}&profitCenters=${this.getCostCentersCodes(profitCenters)}`, this.getHeaders(clients));
    }

        // VEHICLES
    public getConsolidationAutomotive(startDate, endDate, clients, profitCenters): Observable<any> {
        return this.http.get(`${environment.endpoints.automotiveV2QueryService}/api/v2/stats/operational?startDate=${startDate}&endDate=${endDate}&profitCenters=${this.getCostCentersCodes(profitCenters)}`, this.getHeaders(clients));
    }

    public getDetailedConsolidationAutomotive(startDate, endDate, type, clients, profitCenters): Observable<any> {
        return this.http.get(`${environment.endpoints.automotiveV2QueryService}/api/v2/stats/operational/details?startDate=${startDate}&endDate=${endDate}&type=${type}&profitCenters=${this.getCostCentersCodes(profitCenters)}`, this.getHeaders(clients));
    }

    private getHeaders(clients){
        let headers = new HttpHeaders();
        headers = headers.set('X-Requested-Client-Groups', clients.map((c) => c['id']).join(','));
        return { headers }
      }

    private getCostCentersCodes (profitCenters) {
        return profitCenters.map((profitCenter) => {
            return profitCenter.id
        })
    }
}
