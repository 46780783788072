import { UtilsService } from '#services/_utils/utils.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currencyType',
})
export class CurrencyType implements PipeTransform {
    constructor(private utils: UtilsService) {}
    getCurrency = {
        BRL: {
            locale: 'pt-br',
            style: 'currency',
            currency: 'BRL',
        },
        USD: {
            locale: 'en-US',
            style: 'currency',
            currency: 'USD',
        },
        790: {
            locale: 'en-US',
            style: 'currency',
            currency: 'USD',
        },
        EUR: {
            locale: 'fr-FR',
            style: 'currency',
            currency: 'EUR',
        },
        default: {
            locale: 'pt-br',
            style: 'currency',
            currency: 'BRL',
        },
    };

    transform(value: number, currency = 'BRL', minimumFractionDigits?, maximumFractionDigits?) {
        const numberFormat = this.getCurrency.hasOwnProperty(currency) ? this.getCurrency[currency] : this.getCurrency['BRL'];
        return this.utils.formatNumber(
            value,
            {
                ...numberFormat,
                minimumFractionDigits: minimumFractionDigits || 2,
                maximumFractionDigits: maximumFractionDigits || 2,
            }
        );
    }
}
