import { environment } from '#environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TopRegistrationService {
    constructor(private http: HttpClient) {}

    getRegistration(startYearMonth, endYearMonth, typeRegister, clients): Observable<any> {
        return this.http.get(
            `${environment.endpoints.importationService}/stats/top-stats?startYearMonth=${startYearMonth}&endYearMonth=${endYearMonth}&type=${typeRegister}`,
            this.getHeaders(clients)
        );
    }

    private getHeaders(clients) {
        let headers = new HttpHeaders();
        headers = headers.set('X-Requested-Client-Groups', clients.map((c) => c['id']).join(','));
        return { headers };
    }
}
