import { SLA_COLORS } from "../constants/sla-colors.constant";
import { _applyPosFormatters } from "./pos-formatters/pos-formatters";
import { textFormatter } from "./text.formatter";

export function slaFormatter(row, cell, value, columnDef, dataContext) {
    if (!value) {
        return textFormatter(row, cell, value, columnDef, dataContext);
    }
    const contentValue =  `<div class="sla-container"> <span class='ball' style="background-color: ${SLA_COLORS[value.status] || 'grey'}">${value.accomplished}</span> <span class='arrow'>></span> <span class='secondBall'>${value.expected}</span> </div>`;
    return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
}