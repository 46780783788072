import { IGridEditorProperties } from './../interfaces/grid-editor.interface';
export const EDITORCELL = {

    default: ({elementProperties, item}: IGridEditorProperties) => {

        const   input = document.createElement('input');
                input.setAttribute('type', 'text');

                if(item.columnDef.editable.minLength && typeof item.columnDef.editable.minLength === 'number'){
                    input.setAttribute('minlength', item.columnDef.editable.minLength);
                }

                if(item.columnDef.editable.maxLength && typeof item.columnDef.editable.maxLength === 'number'){
                    input.setAttribute('maxlength', item.columnDef.editable.maxLength);
                }

                if(item.columnDef.editable.placeholder && typeof item.columnDef.editable.placeholder === 'string'){
                    input.setAttribute('placeholder', item.columnDef.editable.placeholder);
                }

                input.classList.add('input-editor-cell', getIdInput(elementProperties.position.row, elementProperties.position.cell));
                input.value = item.dataContext[item.columnDef.columnName] ? item.dataContext[item.columnDef.columnName] : '';

                if(item.dataContext[item.columnDef.columnName] && input.value.length > item.columnDef.editable.maxLength){
                    input.classList.add('error-limit-reached');
                }

                if(input.value.length !== 0 && item.dataContext[item.columnDef.columnName] && input.value.length < item.columnDef.editable.minLength){
                    input.classList.add('error-limit-reached');
                }


        if(elementProperties.element.classList.contains('grid-cell')){
            elementProperties.element.appendChild(input);
        }

        if(elementProperties.element.parentElement.classList.contains('grid-cell')){
            elementProperties.element.parentElement.appendChild(input);
        }

        if(elementProperties.element.parentElement.parentElement.classList.contains('grid-cell')){
            elementProperties.element.parentElement.parentElement.appendChild(input);
        }

        if(elementProperties.element.parentElement.parentElement.parentElement.classList.contains('grid-cell')){
            elementProperties.element.parentElement.parentElement.parentElement.appendChild(input);
        }

        input.focus();
        input.addEventListener('input', (e:any) => {

            if(item.columnDef.editable.maxLength && e.target.value.length > item.columnDef.editable.maxLength){
                input.classList.add('error-limit-reached');
            }else{

                if(e.target.value.length !== 0 && item.columnDef.editable.minLength && e.target.value.length < item.columnDef.editable.minLength){
                    input.classList.add('error-limit-reached');
                }else{
                    if(input.classList.contains('error-limit-reached')){
                        input.classList.remove('error-limit-reached');
                    }
                }
            }
        });

        input.addEventListener('blur', () => {
            if(!input.classList.contains('error-limit-reached')){
                sendValues({elementProperties, item}, input.value);
                input.remove();
            }
        });

        input.addEventListener('keyup', (e) => {

            if(e.key === "Enter") {
                input.blur();
            }
        });
    },

    number: ({elementProperties, item}: IGridEditorProperties) => {

        const   input = document.createElement('input');
                input.setAttribute('type', 'number');

                if(item.columnDef.editable.minLength && typeof item.columnDef.editable.minLength === 'number'){
                    input.setAttribute('minlength', item.columnDef.editable.minLength);
                }

                if(item.columnDef.editable.maxLength && typeof item.columnDef.editable.maxLength === 'number'){
                    input.setAttribute('maxlength', item.columnDef.editable.maxLength);
                }

                if(item.columnDef.editable.min && typeof item.columnDef.editable.min === 'number'){
                    input.setAttribute('min', item.columnDef.editable.min);
                }

                if(item.columnDef.editable.max && typeof item.columnDef.editable.max === 'number'){
                    input.setAttribute('max', item.columnDef.editable.max);
                }

                if(item.columnDef.editable.placeholder && typeof item.columnDef.editable.placeholder === 'string'){
                    input.setAttribute('placeholder', item.columnDef.editable.placeholder);
                }

                input.classList.add('input-editor-cell', getIdInput(elementProperties.position.row, elementProperties.position.cell));
                input.value = item.dataContext[item.columnDef.columnName] ? item.dataContext[item.columnDef.columnName] : '';

                if(item.dataContext[item.columnDef.columnName] && input.value.length > item.columnDef.editable.maxLength){
                    input.classList.add('error-limit-reached');
                }

                if(input.value.length !== 0 && item.dataContext[item.columnDef.columnName] && input.value.length < item.columnDef.editable.minLength){
                    input.classList.add('error-limit-reached');
                }


        if(elementProperties.element.classList.contains('grid-cell')){
            elementProperties.element.appendChild(input);
        }

        if(elementProperties.element.parentElement.classList.contains('grid-cell')){
            elementProperties.element.parentElement.appendChild(input);
        }

        if(elementProperties.element.parentElement.parentElement.classList.contains('grid-cell')){
            elementProperties.element.parentElement.parentElement.appendChild(input);
        }

        if(elementProperties.element.parentElement.parentElement.parentElement.classList.contains('grid-cell')){
            elementProperties.element.parentElement.parentElement.parentElement.appendChild(input);
        }

        input.focus();
        input.addEventListener('input', (e:any) => {

            if(item.columnDef.editable.maxLength && e.target.value.length > item.columnDef.editable.maxLength){
                input.classList.add('error-limit-reached');
            }else{

                if(e.target.value.length !== 0 && item.columnDef.editable.minLength && e.target.value.length < item.columnDef.editable.minLength){
                    input.classList.add('error-limit-reached');
                }else{
                    if(input.classList.contains('error-limit-reached')){
                        input.classList.remove('error-limit-reached');
                    }
                }
            }
        });

        input.addEventListener('blur', () => {
            if(!input.classList.contains('error-limit-reached')){
                sendValues({elementProperties, item}, input.value);
                input.remove();
            }
        });

        input.addEventListener('keyup', (e) => {

            if(e.key === "Enter") {
                input.blur();
            }
        });
    },

    currency: ({elementProperties, item}: IGridEditorProperties) => {
        const   input = document.createElement('input');
        input.setAttribute('type', 'text');

        if(item.columnDef.editable.minLength && typeof item.columnDef.editable.minLength === 'number'){
            input.setAttribute('minlength', item.columnDef.editable.minLength);
        }

        if(item.columnDef.editable.maxLength && typeof item.columnDef.editable.maxLength === 'number'){
            input.setAttribute('maxlength', item.columnDef.editable.maxLength);
        }

        if(item.columnDef.editable.min && typeof item.columnDef.editable.min === 'number'){
            input.setAttribute('min', item.columnDef.editable.min);
        }

        if(item.columnDef.editable.max && typeof item.columnDef.editable.max === 'number'){
            input.setAttribute('max', item.columnDef.editable.max);
        }

        if(item.columnDef.editable.placeholder && typeof item.columnDef.editable.placeholder === 'string'){
            input.setAttribute('placeholder', item.columnDef.editable.placeholder);
        }

        input.classList.add('input-editor-cell', getIdInput(elementProperties.position.row, elementProperties.position.cell));
        input.value = item.dataContext[item.columnDef.columnName] ? item.dataContext[item.columnDef.columnName] : '';

        if(item.dataContext[item.columnDef.columnName] && input.value.length > item.columnDef.editable.maxLength){
            input.classList.add('error-limit-reached');
        }

        if(input.value.length !== 0 && item.dataContext[item.columnDef.columnName] && input.value.length < item.columnDef.editable.minLength){
            input.classList.add('error-limit-reached');
        }

        if(elementProperties.element.classList.contains('grid-cell')){
            elementProperties.element.appendChild(input);
        }

        if(elementProperties.element.parentElement.classList.contains('grid-cell')){
            elementProperties.element.parentElement.appendChild(input);
        }

        if(elementProperties.element.parentElement.parentElement.classList.contains('grid-cell')){
            elementProperties.element.parentElement.parentElement.appendChild(input);
        }

        if(elementProperties.element.parentElement.parentElement.parentElement.classList.contains('grid-cell')){
            elementProperties.element.parentElement.parentElement.parentElement.appendChild(input);
        }

        input.focus();
        input.addEventListener('input', (e:any) => {

            const value = e.target.value;
            const currencyValidationRegex = /^[0-9][0-9.,]*$/;

            if (!currencyValidationRegex.test(value)) {
                e.target.value = e.target.value.slice(0, -1);
            } else if (/^[.,]/.test(value)) {
                e.target.value = '';
            }

            if(item.columnDef.editable.maxLength && e.target.value.length > item.columnDef.editable.maxLength){
                input.classList.add('error-limit-reached');
            } else {
                if(e.target.value.length !== 0 && item.columnDef.editable.minLength && e.target.value.length < item.columnDef.editable.minLength){
                    input.classList.add('error-limit-reached');
                } else {
                    if(input.classList.contains('error-limit-reached')){
                        input.classList.remove('error-limit-reached');
                    }
                }
            }
        });

        input.addEventListener('blur', () => {
            if(!input.classList.contains('error-limit-reached')){
                if (/^[.,]/.test(input.value) || /[.,]$/.test(input.value)) {
                    input.value = '';
                }
                sendValues({elementProperties, item}, input.value);
                input.remove();
            }
        });

        input.addEventListener('keyup', (e) => {
            if(e.key === "Enter") {
                input.blur();
            }
        });
    },

    date: (_target, _row, _cell) => {

        _target.innerHTML = `<input type="text" class="input-editor-${_row}-${_cell}" value="asddasdasdsaad" />`;

        ($('.input-editor-'+_row+'-'+_cell) as any).Zebra_DatePicker({
            direction: [0,1],
            onSelect: (selectedDate) => {},
        });
    },
}

const getIdInput = (_rowNumber, _cellNumber) => {

    return `input-editor-${_rowNumber}-${_cellNumber}`;
}

const sendValues = ({elementProperties, item}: IGridEditorProperties, value) => {


    item.dataContext[item.columnDef.columnName] = value;
    const cmxEditorEvent = new CustomEvent("onSlickEditorCell", {
        detail: {
            output: item.dataContext
        },
        bubbles: true,
        cancelable: true,
        composed: false,
    });

    elementProperties.element.dispatchEvent(cmxEditorEvent);
}
