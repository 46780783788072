import { Observable } from 'rxjs';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '#environment';

@Injectable()
export class CommissionService {
    private readonly endpoint;

    constructor(private http: HttpClient) {
        this.endpoint = `${environment.endpoints.financialService + '/commission'}`;
    }

    public saveCommission(params): Observable<HttpResponse<any>> {
        return this.http.post(`${this.endpoint}`, params, { observe: 'response', responseType: 'text' });
    }

    public getLastCommission(clientGroupCode): Promise<any> {
        return this.http
            .get(`${this.endpoint}/${clientGroupCode}`)
            .toPromise()
            .then((res: any) => res);
    }

    public getClientHistory(clientGroupCode): Observable<any> {
        return this.http.get(`${this.endpoint}/history/${clientGroupCode}?page=0&size=200`);
    }
}
