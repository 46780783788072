import { _applyPosFormatters } from "./pos-formatters/pos-formatters";

export function cnpjFormatter(row, cell, value, columnDef, dataContext){
    if (!value || value.length === 0) { return "—" }
    if (value.length !== 14 && value.length !== 11) { return '—' }
    const cleaned = (value).replace(/\D/g, '');
    if (cleaned.length === 14) { // cnpj
        return cleaned.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
    } else if (cleaned.length === 11) { // cpf
        return cleaned.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
    }
    return _applyPosFormatters(row, cell, cleaned, columnDef, dataContext);
}