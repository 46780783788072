import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { CmxDropdownComponent } from './cmx-dropdown.component';
import { CmxDropdownService } from './cmx-dropdown.service';
import { groupByPipe } from './group-by';
import { ListFilterPipe } from './list-filter';
import { ProgressSpinnerModule } from 'primeng/progressspinner';


@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        ProgressSpinnerModule,
    ],
    declarations: [CmxDropdownComponent, ListFilterPipe, groupByPipe],
    exports: [CmxDropdownComponent, ListFilterPipe, groupByPipe],
    providers: [CmxDropdownService]
})
export class CmxDropdownModule { }
