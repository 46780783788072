import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from '#environment';
import { DashComponent } from 'app/theme/pages/default/custom-dashboard/dashComponent.model';
import { UserService } from 'app/auth/_services';
import { LoginService } from 'app/auth/_services/login.service';

@Injectable()
export class LoginPermissionService {
    client = this.loginService.getClient();

    currentUser$ = this.userService.currentUser$;
    currentUser = this.userService.getCurrentUser();
    asyncComponents: boolean = true;
    customComponentId;

    private _customDashboardComponent = "CustomDashboardComponent";

    private _storedComponents: DashComponent[] = [];
    private _defaultComponents: DashComponent[] = [];
    private _customComponents: DashComponent[] = [];
    private _mergedComponents: DashComponent[] = [];

    private operationalMergedComponents: DashComponent[] = [
        {
            component: 'OperationalConsolidationComponent',
            expansive: false,
            name: 'Consolidação Logística',
            permission: 'API_OPERATIONAL_CONSOLIDATION',
            size: 100,
            description: '',
        },
        {
            component: 'ForecastByStatusComponent',
            expansive: false,
            name: 'Previsão de Faturamento',
            permission: 'API_FORECAST_BY_STATUS',
            size: 100,
            description: 'Previsão de Faturamento por status',
        },
        {
            component: 'DocumentConsolidationComponent',
            expansive: false,
            name: 'Consolidação de Documentos',
            permission: 'API_DOCUMENT_CONSOLIDATION',
            size: 100,
            description: '',
        },
        {
            component: 'TopDIRegistrationComponent',
            expansive: false,
            name: "Top DI's Registradas",
            permission: 'API_TOP_DI_REGISTRATION',
            size: 50,
            description: '',
        },
        {
            component: 'TopLIRegistrationComponent',
            expansive: false,
            name: "Top LI's Registradas",
            permission: 'API_TOP_LI_REGISTRATION',
            size: 50,
            description: '',
        },
        {
            component: 'OperationalAlertsComponent',
            expansive: false,
            name: 'Alertas operacionais',
            permission: 'API_OPERATIONAL_ALERTS',
            size: 100,
            description: '',
        },
        {
            component: 'MovementByModalComponent',
            expansive: false,
            name: 'Movimentação',
            permission: 'API_OPERATIONAL_ALERTS',
            size: 100,
            description: '',
        },
    ];

    constructor(
        private http: HttpClient,
        public router: Router,
        private userService: UserService,
        private loginService: LoginService
    ) { }

    getDefaultComponentList(): DashComponent[] {
        return this._defaultComponents;
    }
    setDefaultComponentList(defaultComponents: DashComponent[]): void {
        this._defaultComponents = defaultComponents;
    }

    getCustomComponentList(): DashComponent[] {
        return this._customComponents;
    }
    setCustomComponentList(customComponents: DashComponent[]): void {
        this._customComponents = customComponents;
    }

    getMergedComponentList(operational?): DashComponent[] {
        return operational ? this.operationalMergedComponents || [] : this._mergedComponents || [];
    }
    setMergedComponentList(mergedComponents: DashComponent[]): void {
        this._mergedComponents = mergedComponents;
    }

    getStoredComponentList(): DashComponent[] {
        return this._storedComponents;
    }

    pushToMerged(component: DashComponent) {
        this._mergedComponents.push(component);
    }
    pushToStored(component: DashComponent) {
        const storedIndex = this.getStoredComponentList().findIndex(
            (storedComponent) => storedComponent.component === component.component
        );
        const mergedIndex = this.getMergedComponentList().findIndex(
            (mergedComponent) => mergedComponent.component === component.component
        );
        if (storedIndex === -1 && mergedIndex === -1) {
            this._storedComponents.push(component);
        }
    }

    getComponents(): Observable<DashComponent[]> {
        return this.currentUser$.pipe(
            take(1),
            map((user) => {
                return this._mergedComponents.filter((c) => user.has(c.permission));
            })
        );
    }

    setComponents(componentList): void {
        this._mergedComponents = componentList;
    }
    setStoredComponents(componentList): void {
        this._storedComponents = componentList;
    }

    changeComponent(newComponent): void {
        this._mergedComponents[
            this._mergedComponents.findIndex((component) => component.component === newComponent.component)
        ] = newComponent;
    }

    addComponent(addComponent): void {
        this._storedComponents.splice(
            this._storedComponents.findIndex((component) => component.component === addComponent.component),
            1
        );
        this._mergedComponents.unshift(addComponent);
    }

    removeComponent(deleteComponent): void {
        this._mergedComponents.splice(
            this._mergedComponents.findIndex((component) => component.component === deleteComponent.component),
            1
        );
        this._storedComponents.unshift(deleteComponent);
    }

    saveCustomChanges(): void {
        this.asyncComponents = true;
        this.saveOrUptadeCustomComponents   (this.getPositionedMergedComponents('mergedComponents')).subscribe((response) => {
            this.loadComponents(true);
        });
    }

    resetChanges(): void {
        this.setMergedComponentList([]);
        this.setMergedComponentList(this.getDefaultComponentList());
        this.setStoredComponents([]);
    }

    loadComponents(save?): void {
        this._resetComponentLists();
        this.getDefaultComponents().subscribe((defaultComponents) => {
            this.setDefaultComponentList(defaultComponents.items);
            this.getCustomComponents().subscribe((customComponents) => {
                this.setCustomComponentList(
                    customComponents ? customComponents.items : []
                );
                this.populateMergedComponents();
                this.populateStoredComponents();
                this.asyncComponents = false;
                if (save) {
                    this.router.navigate(['/']);
                }
            });
        });
    }

    populateStoredComponents(): void {
        this.getDefaultComponentList().forEach((defaultComponent: DashComponent) => {
            const found = this.getCustomComponentList().find(
                (customComponent) => defaultComponent.component === customComponent.component
            );
            if (!found) {
                this.pushToStored(defaultComponent);
            }
        });
    }

    populateMergedComponents(): void {
        this.getCustomComponentList().forEach((customComponent: DashComponent) => {
            const found = this.getDefaultComponentList().find(
                (defaultComponent) => defaultComponent.component === customComponent.component
            );
            if (found) {
                this.pushToMerged(customComponent);
            }
        });
    }

    getDefaultComponents(): Observable<any> {
        return this.http.get(`${environment.endpoints.userPreferencesService}/component/default?componentId=${this._customDashboardComponent}&isCustomer=${this.currentUser.has('FOLLOWUP_COMPLETE')  ? false : true}`);
    }
    getCustomComponents(): Observable<any> {
        return this.http.get(`${environment.endpoints.userPreferencesService}/component?componentId=${this._customDashboardComponent}&isCustomer=${this.currentUser.has('FOLLOWUP_COMPLETE')  ? false : true}`);
    }

    saveOrUptadeCustomComponents(saveList): Observable<any> {

        var dat = {
            label: 'Dashboard',
            componentId: this._customDashboardComponent,
            isCustomer: this.currentUser.has('FOLLOWUP_COMPLETE') ? false : true,
            items: saveList
        }

        return this.customComponentId ? this.updateComponent(dat) :  this.saveComponent(dat);
    }

    getPositionedMergedComponents(container, componentChangeSize?): DashComponent[] {
        const positionedMergedComponents: DashComponent[] = [];
        const components = $(`#${container}`)[0].children;
        Array.from(components).forEach((component) => {
            const found = this._mergedComponents.find((mergedComponent) => mergedComponent.component === component.id);
            if (found) {
                positionedMergedComponents.push(found);
            }
        });
        return componentChangeSize
            ? this._getMergedAfterChangeSize(positionedMergedComponents, componentChangeSize)
            : positionedMergedComponents;
    }

    private _getMergedAfterChangeSize(positionedMergedComponents, componentChangeSize) {
        const index = positionedMergedComponents.findIndex(
            (component: DashComponent) => component.component === componentChangeSize
        );
        if (index !== -1) {
            positionedMergedComponents[index].size === 100
                ? (positionedMergedComponents[index].size = 50)
                : (positionedMergedComponents[index].size = 100);
        }
        return positionedMergedComponents;
    }

    private _resetComponentLists(): void {
        this._defaultComponents = [];
        this._mergedComponents = [];
        this._customComponents = [];
        this._storedComponents = [];
    }

    setCustomComponentId(id: string){
        this.customComponentId = id;
    }

    private saveComponent(component){
        return this.http.post(
            `${environment.endpoints.userPreferencesService}/component?isCustomer=${this.currentUser.has('FOLLOWUP_COMPLETE')  ? false : true}`,
            component,
            { observe: 'response', responseType: 'json' }
        ).pipe(
            map((resp: any) => {
                this.customComponentId = resp.body.id;
                return resp;
            })
        );

    }

    private updateComponent(component){
        return this.http.put(
            `${environment.endpoints.userPreferencesService}/component/${this.customComponentId}?isCustomer=${this.currentUser.has('FOLLOWUP_COMPLETE')  ? false : true}`,
            component,
            { observe: 'response', responseType: 'json' }
        );
    }

}
