import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IOperationalAlertsFilter } from './interfaces/operational-alerts.interface';
import { ALERT_TYPE_INFO } from './constants/info.constant';

export class OperationalAlertsFilter {
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialOperationalAlertsFilter: IOperationalAlertsFilter = <IOperationalAlertsFilter>{};
    private _operationalAlertsFilter: IOperationalAlertsFilter = <IOperationalAlertsFilter>{
        clientGroups: [],
        subsidiaryNames: [],
        alertsEnabled: ALERT_TYPE_INFO.map((alert) => { return alert.title }),
    };

    private page: number;
    private size: number;

    constructor(filter?: OperationalAlertsFilter) {
        this._operationalAlertsFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'operationalAlertsFilter', this._operationalAlertsFilter, filter);
        this._initialOperationalAlertsFilter = _.cloneDeep(this._operationalAlertsFilter);
        this.page = filter ? filter.page : 0;
        this.size = filter ? filter.size : 40;
    }

    public setPage(page): void {
        this.page = page;
    }
    public setSize(size): void {
        this.size = size;
    }
    public setSubsidiaryNames(subsidiaryNames): void {
        this._operationalAlertsFilter.subsidiaryNames = subsidiaryNames;
    }
    public setClientGroups(clientGroups): void {
        this._operationalAlertsFilter.clientGroups = clientGroups;
    }
    setAlertsEnabled(alertsEnabled): void {
        this._operationalAlertsFilter.alertsEnabled = alertsEnabled;
    }

    public getPage(): number {
        return this.page;
    }
    public getSize(): number {
        return this.size;
    }
    public getSubsidiaryNames(): any[] {
        return this._operationalAlertsFilter.subsidiaryNames;
    }
    public getClientGroups(): string[] {
        return this._operationalAlertsFilter.clientGroups;
    }
    getAlertsEnabled(): string[] {
        return this._operationalAlertsFilter.alertsEnabled;
    }

    saveUserPreferences() {
        this._initialOperationalAlertsFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialOperationalAlertsFilter, this._operationalAlertsFilter, 'operationalAlertsFilter', this._userCacheService.getUserPreferences().id);
    }

    public getRequest() {
        this.saveUserPreferences();
        let params = new HttpParams();
        let headers = new HttpHeaders();
        headers = headers.set('X-Requested-Client-Groups', this.getClientGroups() === [] ? '' : this.getClientGroups().map((c) => c['id']).join(','));
        params = params.append('subsidiaryNames', this.getSubsidiaryNames().map((c) => c.itemName).join(','));
        params = params.append('page', `${this.getPage()}`);
        params = params.append('size', `${this.getSize()}`);
        return { params, headers };
    }
}
