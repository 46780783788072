<div class="main-container" *ngIf="currentUser$ | async as user">

    <div *ngIf="!async" class="fullLoader"><loader></loader></div>

    <div class="preferences-list-column">
        <cmx-listing
            id="preferences-listing"
            atributeName="name"
            [list]="preferences"
            searchPlaceholder="Selecionar Visão"
            [instantSearch]="true"
            [height]="'calc(100vh - 250px)'"
            [hasAddButton]="true"
            icon="star"
            iconAtributeCondition="defaultPreference"
            (onSelect)="selectPreference($event)"
            (onNewItem)="newPreference()"
        ></cmx-listing>
    </div>

    <div class="preferences-form-column">

        <form [formGroup]="form" novalidate="novalidade" autocomplete="off" id="form-exchange-broker" class="form">
            <div class="content-detail">
                <div class="panel">
                    <div class="row">
                        <div class="col-sm-10 col-md-10 p-field">
                            <label class="label label-input">Nome da Visão<span class="required">*</span></label>
                            <input type="text" pInputText class="comexport-theme" formControlName="name" autocomplete="one-time-code" placeholder="Nome da Visão">
                        </div>
                        <div class="col-sm-2 col-md-2 p-field">
                            <label class="label label-input">Visão favorita</label>
                            <div *ngIf="preference" class="favorite" (click)="onChangeDefault()">
                                <i class="fa fa-star" [class.disabled]="!defaultPreference$"></i>
                            </div>
                        </div>
                    </div>
                    <ng-container formGroupName="filters" *ngIf="!pageFiltersLoading">
                        <div class="row">
                            <div class="col-sm-12 col-md-3 p-field">
                                <label class="label label-input">Selecionar Clientes</label>
                                <cmx-dropdown
                                    size="md"
                                    id="clientGroups"
                                    name="clientGroups"
                                    formControlName="clientGroups"
                                    [data]="options.clientGroups"
                                    [settings]="dropdownSettings.clientGroups"
                                    ></cmx-dropdown>
                            </div>
                            <div class="col-sm-12 col-md-3 p-field">
                            <label class="label label-input">Selecionar Filial</label>
                            <cmx-dropdown *ngIf="options.subsidiaryNames"
                                size="md"
                                id="subsidiaryNames"
                                name="subsidiaryNames"
                                formControlName="subsidiaryNames"
                                [data]="options.subsidiaryNames"
                                [settings]="dropdownSettings.subsidiaryNames"
                                ></cmx-dropdown>
                            </div>
                            <div class="col-sm-12 col-md-3 p-field" *ngIf="false">
                                <label class="label label-input">Selecionar CR</label>
                                <cmx-dropdown id="profitCenters"
                                    size="md"
                                    name="profitCenters"
                                    [data]="options.profitCenters"
                                    formControlName="profitCenters"
                                    [settings]="dropdownSettings.crs"
                                ></cmx-dropdown>
                            </div>
                            <div class="col-sm-12 col-md-3 p-field" *ngIf="false">
                                <label class="label label-input">Modais usados</label>
                                <cmx-dropdown id="modals"
                                    size="md"
                                    name="modals"
                                    formControlName="modals"
                                    [data]="options.modals"
                                    [settings]="dropdownSettings.modals"
                                    ></cmx-dropdown>
                            </div>
                        </div>
                    </ng-container>
                    <hr>
                    <cmx-form-message message="Os filtros selecionados nesta visão serão aplicados em todas as telas caso o filtro esteja presente." type="warning"></cmx-form-message>

                </div>
                <div class="actions-right">
                    <ng-container  style="margin-right: 10px; border-right: 1px solid #d3d3d3; padding-right: 10px;">
                        <cmx-button (click)="delete()" variant="danger" *ngIf="preference?.id">Excluir</cmx-button>
                    </ng-container>
                    <cmx-button variant="light" (click)="cancelEditing()" style="margin-right: 5px;" *ngIf="preference?.id">Cancelar</cmx-button>
                    <cmx-button (click)="saveOrEdit()" style="margin-right: 5px;" variant="success" *ngIf="form.dirty">{{ preference?.id ? 'Salvar' : 'Adicionar' }}</cmx-button>
                </div>
            </div>
        </form>
    </div>
</div>

<cmx-confirmation-modal
    id="showDeletePreferenceModal"
    question="Deseja realmente deletar a visão?"
    labelAction="Confirmar"
    confirmType="danger"
    [hidden]="!showDeletePreferenceModal"
    (onConfirm)="handleDeletePreference()"
    (onCancel)="showDeletePreferenceModal = false"
></cmx-confirmation-modal>
