import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { FilterStateService } from './filter-state.service';
import { GroupingStateService } from './grouping-state.service';
import { translateGrouping } from './constants/grouping.options';
import { HierarchyStateService } from './hierarchy-state.service';
import { translateHierarchy } from './constants/hierarchy.options';

@Component({
    selector: 'cmx-filter-state',
    templateUrl: './filter-state.component.html',
    styleUrls: ['./filter-state.component.scss'],
})
export class FilterStateComponent implements OnDestroy {

    public hierarchy: string;
    public grouping: string;
    public period: string = 'Indefinido';
    public filterSet: boolean = false;
    public currency = 'BRL';

    private subscriptions: Subscription[] = [];

    constructor(
        private groupingState: GroupingStateService,
        private hierarchyState: HierarchyStateService,
        private filterState: FilterStateService) {

        this.subscriptions.push(this.hierarchyState.current$.subscribe((hierarchy) => {
            this.hierarchy = translateHierarchy(hierarchy);
        }));

        this.subscriptions.push(this.groupingState.current$.subscribe((grouping) => {
            this.grouping = translateGrouping(grouping);
        }));

        this.subscriptions.push(this.filterState.current$.subscribe((filter) => {
            if (filter) {
                this.currency = filter.currency;
                this.period = `De ${filter.startDate} até ${filter.endDate}`;
                this.filterSet = (filter.companies != null && filter.companies.length !== 0)
                    || (filter.profitCenter != null && filter.profitCenter.length !== 0)
                    || (filter.trader != null && filter.trader.length !== 0)
                    || (filter.director != null && filter.director.length !== 0)
                    || (filter.client != null && filter.client.length !== 0)
                    || (filter.imps != null && filter.imps.length !== 0);
            }
        }));
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}
