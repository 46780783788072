import { EventEmitter } from '@angular/core';
import { BADGE_STATUS_VALUES } from './constants/cmx-status';
import { Component, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'cmx-badge-status',
  templateUrl: './cmx-badge-status.component.html',
  styleUrls: ['./cmx-badge-status.component.scss']
})
export class CmxBadgeStatusComponent implements OnInit {

    @Input('status-theme') statusTheme: string = 'default';
    @Input('status-value') statusValue: string = 'default';
    @Input('button-size') buttonSize: string = 'md';

    @Output('onValue') onValue: EventEmitter<any> = new EventEmitter<any>();

    setStatusTheme:string = null;
    setStatusClass:string = null;
    setStatusIconValue:string = null;
    setStatusLabel:string = null;
    setButtonSize:string = null;

    constructor() { }

    ngOnInit(): void {

      if(!this.statusTheme){

        return console.error('Necessário preencher um tema para o status');
      }

      if(!this.statusValue){

        return console.error('Necessário passar um valor para o status');
      }

      this.setStatusTheme = this._setStatusTheme(this.statusTheme);
      this.setStatusClass = this._setStatusClass(this.statusValue);
      this.setStatusIconValue = this._setStatusIcon(this.statusValue);
      this.setStatusLabel = this._setStatusLabel(this.statusValue);
      this.setButtonSize = this._setButtonSize(this.buttonSize);
    }

    ngOnChanges(simple: SimpleChanges){

      for(const statusValue in simple){

          if(simple.hasOwnProperty(statusValue)){
              this.setStatusClass = this._setStatusClass(simple.statusValue.currentValue);
              this.setStatusIconValue = this._setStatusIcon(simple.statusValue.currentValue);
              this.setStatusLabel = this._setStatusLabel(simple.statusValue.currentValue);
          }
      }
    }

    handleClick(){

        this.onValue.emit({
            label: BADGE_STATUS_VALUES[this.statusValue.toUpperCase()].label,
            value: this.statusValue
        });
    }

    private _setStatusTheme(value:string): string {

      const statusTheme = {
        "default": `badge-status-theme-default`,
        "light": `badge-status-theme-light`,
      }

      return statusTheme[value] ? statusTheme[value] : statusTheme['default']
    }

    private _setStatusClass(value:string): string {

      return BADGE_STATUS_VALUES[value.toUpperCase()] ? BADGE_STATUS_VALUES[value.toUpperCase()].class : BADGE_STATUS_VALUES['DEFAULT'].class;
    }

    private _setStatusIcon(value:string): string {

        return BADGE_STATUS_VALUES[value.toUpperCase()] ? BADGE_STATUS_VALUES[value.toUpperCase()].icon : BADGE_STATUS_VALUES['DEFAULT'].icon;
    }

    private _setStatusLabel(value:string): string {

        return BADGE_STATUS_VALUES[value.toUpperCase()] ? BADGE_STATUS_VALUES[value.toUpperCase()].label : BADGE_STATUS_VALUES['DEFAULT'].label;
    }

    private _setButtonSize(value:string): string {

      const buttonSize = {
        "sm": "badge-status-sm",
        "md": "badge-status-md",
        "lg": "badge-status-lg"
      }

      return buttonSize[value] ? buttonSize[value] : buttonSize['md']
    }

}
