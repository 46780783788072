import { UserService } from 'app/auth/_services/user.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'cmx-user-profile-display',
    templateUrl: './cmx-user-profile-display.component.html',
    styleUrls: ['./cmx-user-profile-display.component.scss'],
})
export class UserProfileDisplayComponent {
    @Input() userName: string = '?';
    @Input() desactivated?: boolean;
    userInitials: string;

    @Output('onClick') onClick = new EventEmitter();

    constructor(private _userService: UserService){}

    ngOnInit() {
        this._userService.currentUser$.subscribe(({ data }: any) => {

            if(data){
                this.userName = data.name;
                this.userInitials = data.name.split(' ')[0].charAt(0);
            }
        });
    }

    onClickEvent() {
        this.onClick.emit();
    }

}
