import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class AutomotiveDailyReportSlickGrid {
    // tslint:disable:no-string-literal

    public createColumns(models) {
        const cols = [
            { id: 0, name: 'Etapas', width: 280, field: 'step', formatter: this.formatStep.bind(this),
            cssClass: 'impHeader', headerCssClass: 'impHeader' },
        ];
        models.forEach((model, index) => {
            cols.push({
                id: index + 1, name: model, width: 130, field: model, formatter: this.formatValueColumn.bind(this),
                cssClass: 'impHeader', headerCssClass: 'impHeader',
            });
        });
        cols.push({ id: 0, name: 'Total', width: 130, field: 'total', formatter: this.formatTotalColumn.bind(this),
            cssClass: 'impHeader', headerCssClass: 'impHeader' });
        return cols;
    }

    public extractRows(steps) {
        delete steps.date;
        delete steps.models;
        const rows = [];
        let index = -1;
        let parent = 0;
        Object.entries(steps).forEach((step, stepIndex) => {
            index++;
            rows.push({
                id: `${stepIndex}`,
                step: step[0],
                indent: 0,
                _collapsed: false,
                isLeaf: true,
                rowType: 'step',
                parent: null,
                parentStep: null,
                parentGroup: null,
                total: step[1]['total'].value,
                delta: step[1]['total'].delta,
            });
            if (step[1] !== null) {
                Object.entries(step[1]['totalByModel']).forEach((stepValues) => {
                    rows[index][stepValues[0]] = stepValues[1]['value'] || 0;
                    rows[index][`delta-${stepValues[0]}`] = stepValues[1]['delta'] || 0;
                    rows[index][`filter-${stepValues[0]}`] = stepValues[1]['filter'] || null;
                    rows[index]['filter'] = stepValues[1]['filter'] || null;
                });
                if (_.has(step[1], 'totalByGroup')) {
                    rows[index]['isLeaf'] = false;
                    parent = index;
                    Object.entries(step[1]['totalByGroup']).forEach((stepGroup, groupIndex) => {
                        index++;
                        rows.push({
                            id: `${stepIndex} - ${groupIndex}`,
                            step: stepGroup[0],
                            indent: 10,
                            _collapsed: false,
                            isLeaf: true,
                            rowType: 'group',
                            parent: _.has(stepGroup[1], 'totalByGroup') ? parent - groupIndex : parent,
                            parentStep: step[0],
                            parentGroup: null,
                            total: stepGroup[1]['total'].value,
                            delta: stepGroup[1]['total'].delta,
                        });
                        if (stepGroup[1] !== null) {
                            Object.entries(stepGroup[1]['totalByModel']).forEach((groupValues) => {
                                rows[index][groupValues[0]] = groupValues[1]['value'] || 0;
                                rows[index][`delta-${groupValues[0]}`] = groupValues[1]['delta'] || 0;
                                rows[index][`filter-${groupValues[0]}`] = groupValues[1]['filter'] || null;
                                rows[index]['filter'] = groupValues[1]['filter'] || null;
                            });
                        }
                        if (_.has(stepGroup[1], 'totalByGroup')) {
                            rows[index]['isLeaf'] = false;
                            parent = index;
                            Object.entries(stepGroup[1]['totalByGroup']).forEach((stepLeaf, leafIndex) => {
                                index++;
                                rows.push({
                                    id: `${stepIndex} - ${groupIndex} - ${leafIndex}`,
                                    step: stepLeaf[0],
                                    indent: 20,
                                    _collapsed: false,
                                    isLeaf: true,
                                    rowType: 'leaf',
                                    parent,
                                    parentStep: step[0],
                                    parentGroup: stepGroup[0],
                                    total: stepLeaf[1]['total'].value,
                                    delta: stepLeaf[1]['total'].delta,
                                });
                                if (stepLeaf[1] !== null) {
                                    Object.entries(stepLeaf[1]['totalByModel']).forEach((leafValues) => {
                                        rows[index][leafValues[0]] = leafValues[1]['value'] || 0;
                                        rows[index][`delta-${leafValues[0]}`] = leafValues[1]['delta'] || 0;
                                        rows[index][`filter-${leafValues[0]}`] = leafValues[1]['filter'] || null;
                                        rows[index]['filter'] = leafValues[1]['filter'] || null;
                                    });
                                }
                            });
                        }
                    });
                }
            }
        });
        return rows;
    }

    private formatStep(row, cell, value, columnDef, dataContext) {
        if (!dataContext.isLeaf) {
            if (dataContext._collapsed) {
                return `<div class='nameContainer ${dataContext.rowType}'><span style="margin-left:
                ${dataContext.indent}px">
                <span class='toggle fa fa-caret-right pointer' style="width: 9px;"></span>
                ${value}</span></div>`;
            } else {
                return `<div class='nameContainer ${dataContext.rowType}'><span style="margin-left:
                ${dataContext.indent}px">
                <span class='toggle fa fa-caret-down pointer' style="width: 9px;"></span>
                ${value}</span></div>`;
            }
        } else {
            return `<div class='nameContainer ${dataContext.rowType}'><span style="margin-left:
            ${dataContext.indent + 10}px">
            ${value}</span></div>`;
        }
    }

    private formatValueColumn(row, cell, value, columnDef, dataContext) {
        const newValue = value === undefined || value === null ? '' : value;
        if (dataContext[`delta-${columnDef.field}`] === 0) {
            return `<div class="nameContainer valueContainer ${dataContext.rowType}">
            <div class="value">${newValue}</div></div>`;
        } else if (dataContext[`delta-${columnDef.field}`] > 0) {
            return `<div class="nameContainer valueContainer ${dataContext.rowType}">
                    <div class="value">${newValue}<div class="delta up">${
                        dataContext[`delta-${columnDef.field}`] || ''
                    }<span class="material-icons">arrow_upward</span></div></div></div>`;
        } else {
            return `<div class="nameContainer valueContainer ${dataContext.rowType}">
                    <div class="value">${newValue}<div class="delta down">${
                        dataContext[`delta-${columnDef.field}`] || ''
                    }<span class="material-icons">${dataContext[`delta-${columnDef.field}`]
                    === undefined ? '' : 'arrow_downward'}</span></div></div></div>`;
        }
    }

    private formatTotalColumn(row, cell, value, columnDef, dataContext) {
        const newValue = value === undefined || value === null ? '' : value;
        if (dataContext.delta === 0) {
            return `<div class="nameContainer valueContainer ${dataContext.rowType}">
            <div class="value total">${newValue}</div></div>`;
        } else if (dataContext.delta > 0) {
            return `<div class="nameContainer valueContainer ${dataContext.rowType}">
                    <div class="value total">${newValue}<div class="delta up">${
                        dataContext.delta || ''
                    }<span class="material-icons">arrow_upward</span></div></div></div>`;
        } else {
            return `<div class="nameContainer valueContainer ${dataContext.rowType}">
                    <div class="value total">${newValue}<div class="delta down">${
                        dataContext.delta || ''
                    }<span class="material-icons">${dataContext[`delta-${columnDef.field}`]
                    === undefined ? '' : 'arrow_downward'}</span></div></div></div>`;
        }
    }

}
