import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Utils } from '../../utils/utils';
import { IOperationalAlertItem } from 'app/shared/interfaces/operational-alerts.interface';
import { AlertTypes } from 'app/shared/types/operational-alert-types.type';
import { ALERT_TYPE_INFO } from '../../constants/info.constant'

@Component({
    selector: 'alert-type',
    templateUrl: './alert-type.component.html',
    styleUrls: ['./alert-type.component.scss'],
})
export class AlertTypeComponent {
    @Input() title: string;
    @Input() subtitle: string;
    @Input() type: AlertTypes;
    @Input() data: IOperationalAlertItem[];
    @Output() clickEvent: EventEmitter<any>;
    @Output() openInfoEvent: EventEmitter<any>;

    defaultSubtitle = '*IMP`s em dias para vencer';
    utils: Utils;
    helpDescription: any = [];
    loadingInfoModal = true;

    constructor() {
        this.clickEvent = new EventEmitter();
        this.openInfoEvent = new EventEmitter();
        this.utils = Utils;
    }

    ngAfterViewInit() {
        this.helpDescription = this._defineHelpDescription()
    }

    openInfo() {
        if (this.type) {
            this.openInfoEvent.emit(this.type);
        }
    }

    identify(_, item): string {
        return item.severity;
    }

    private _defineHelpDescription() {
        return ALERT_TYPE_INFO.find((info) => info.type === this.type)?.value || [];
    }
}
