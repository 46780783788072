

import { Injectable } from '@angular/core';

@Injectable()
export class FollowUpIconList {

    private _iconList = [
        'account_box',
        'admin_panel_settings',
        'alarm',
        'analytics',
        'announcement',
        'api',
        'assignment',
        'book',
        'build_circle',
        'card_giftcard',
        'credit_card',
        'date_range',
        'find_in_page',
        'leaderboard',
        'pending_actions',
        'privacy_tip',
        'request_page',
        'room',
        'vertical_split',
        'watch_later',
        'work',
        'wysiwyg',
        'notification_important',
        'shuffle',
        'contacts',
        'import_contacts',
        'content_paste',
        'insights',
        'push_pin',
        'airplanemode_active',
        'attach_money',
        'monetization_on',
        'bubble_chart',
        'linear_scale',
        'pie_chart',
        'attachment',
        'folder_open',
        'developer_board',
        'memory',
        'point_of_sale',
        'security',
        'assistant',
        'center_focus_strong',
        'flare',
        'receipt_long',
        'rotate_left',
        'rotate_right',
        'shutter_speed',
        'straighten',
        'style',
        'timer',
        'directions_boat',
        'directions_bus',
        'directions_car',
        'local_shipping',
        'handyman',
        'layers',
        'local_atm',
        'local_offer',
        'local_police',
        'map',
        'menu_book',
        'money',
        'account_tree',
        'all_inclusive',
        'corporate_fare',
        'emoji_objects',
        'notifications_active',
        'public',
        'share',
        'star',
        'payments',
    ];

    getIconList() {
        return this._iconList;
    }

}
