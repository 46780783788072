import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '#environment';
import { IRightButton } from 'app/shared/components/cmx-page-wrapper/interfaces/top-button.interface';
import * as _ from 'lodash';

@Injectable()
export class ReportPreferencesService {

    asyncValues: boolean = true;
    currentDefaultReport;
    currentUtilColumns;
    saveList = [];
    selectedSave;

    rightButtonList: IRightButton[] = []
    selectedRightButton: IRightButton;
    defaultLeftData = [];
    leftData = [];
    rightData = [];
    enableChangeColumnGroup: boolean = false;

    fupSaveSubject = new Subject<any>();
    costsSaveSubject = new Subject<any>();
    premiseSaveSubject = new Subject<any>();
    taxStudySaveSubject = new Subject<any>();
    taxConsultationSaveSubject = new Subject<any>();

    fupDeleteSubject = new Subject<any>();

    constructor(private _http: HttpClient) { }

    getDefaultReport(reportId, isCustomer?: boolean): Observable<any> {
        let params = new HttpParams();
        params = params.append('reportId', reportId);
        if (isCustomer !== undefined) {
            params = params.append('isCustomer', `${isCustomer}`);
        }
        return this._http.get(`${environment.endpoints.userPreferencesService}/report/default`, { params });
    }

    getCustomReport(reportId, onlyInitial?: boolean, isCustomer?: boolean): Observable<any> {
        let params = new HttpParams();
        params = params.append('reportId', reportId);
        params = params.append('onlyInitial', `${onlyInitial || ''}`);
        if (isCustomer !== undefined) {
            params = params.append('isCustomer', `${isCustomer}`);
        }
        return this._http.get(`${environment.endpoints.userPreferencesService}/report`, { params });
    }

    setCustomReport(data, isCustomer?: boolean): Observable<any> {
        const parsedData = _.cloneDeep(data);
        let params = new HttpParams();
        if (isCustomer !== undefined) {
            params = params.append('isCustomer', `${isCustomer}`);
        }
        return this._http.put(`${environment.endpoints.userPreferencesService}/report/${parsedData.id}`, parsedData, { params });
    }

    createCustomReport(reportId, data, isCustomer?: boolean): Observable<any> {
        let params = new HttpParams();
        params = params.append('reportId', reportId);
        if (isCustomer !== undefined) {
            params = params.append('isCustomer', `${isCustomer}`);
        }
        return this._http.post(`${environment.endpoints.userPreferencesService}/report`, data, { params });
    }

    deleteCustomReport(reportId, isCustomer?: boolean): Observable<any> {
        let params = new HttpParams();
        if (isCustomer !== undefined) {
            params = params.append('isCustomer', `${isCustomer}`);
        }
        return this._http.delete(`${environment.endpoints.userPreferencesService}/report/${reportId}`,{ params });
    }

    prepareReportData(groups) {
        return groups.map((group) => { return {
            ...group,
            group: group.group || group.name || group.label || '',
            data: group.columns.map((column) => { return {
                ...column,
                name: column.name || column.label || '' } }),
            selected: false
        }});
    }

    prepareSaveList(rawSaveList) {
        return rawSaveList.map((save, index) => { return {
            ...save,
            icon: save?.icon || 'assignment',
            color: save?.color || 'grey',
            title: save?.title || save?.reportName || `Relatório ${index}`,
            description: save?.description || '',
        }})
    }

    prepareRightButtonList(rawSaveList) {
        return rawSaveList.map((save, index) => { return {
            ...save,
            code: save?.id || save?.code || save?.title || save?.reportName || `${index}`,
            icon: save?.icon || 'assignment',
            color: save?.color || 'grey',
            name: save?.title || save?.reportName || `Relatório ${index}`,
            description: save?.description || '',
        }})
    }

    prepareRightButton(button) {
        return button ? {
            ...button,
            code: button?.id || button?.code || button?.title || button?.reportName,
            icon: button?.icon || 'assignment',
            color: button?.color || 'grey',
            name: button?.title || button?.reportName || '',
            description: button?.description || '',
        } : null;
    }

    defineLeftAndRightData(defaultGroups, customGroups, forceEmptyGroups?, forceDefaultGroupOrder?) {
        defaultGroups = this._getNonRepeatedColumnsInGroups(defaultGroups);
        customGroups = this._getNonRepeatedColumnsInGroups(customGroups);
        this.defaultLeftData = defaultGroups;
        this.leftData = []
        this.rightData = [{ data: [] }]
        let defaultColumns = [];
        let customColumns = [];
        defaultGroups.forEach((group) => { defaultColumns = defaultColumns.concat(group.columns) });
        customGroups.forEach((group) => { customColumns = customColumns.concat(group.columns) });
        if (forceEmptyGroups) {
            defaultGroups.forEach((defaultGroup, index) => {
                if (!customGroups.some(c => c.name === defaultGroup.label)) {
                    const item = { name: defaultGroup.label, columns: [] }
                    forceDefaultGroupOrder ? customGroups.splice(index, 0, item) : customGroups.push(item)
                }
            })
        }
        defaultColumns.forEach((column) => {
            if (!customColumns.some((e) => e.field === column.field)) {
                this.rightData[0].data.push({ ...column, name: column?.name || column?.label || '' })
            }
        });
        const groupsToRender = this._getDefaultGroupsToRender(defaultGroups, customGroups);
        this.leftData = this.prepareReportData(groupsToRender || [])
    }

    removeSaveFromList(save) {
        this.saveList.splice(this.saveList.findIndex((s) => { return s.id === save.id }), 1);
        this.rightButtonList.splice(this.rightButtonList.findIndex((s) => { return s.code === save.id }), 1);
        this.selectedSave = null;
    }

    // Se true, durante o carregamento das colunas a esquerda, a lógica será alterada para que o padrão da coluna seja localizada em qualquer outro grupo
    setEnableChangeColumnGroup(enabled: boolean) {
        this.enableChangeColumnGroup  = enabled;
    }

    // Previne Colunas Repetidas (mesmo field)
    private _getNonRepeatedColumnsInGroups(groups) {
        const newGroups = []
        groups.forEach((group) => {
            newGroups.push({
                label: group?.label || group?.name || '',
                name: group?.name || group?.label || '',
                isCustom: group?.isCustom || false,
                columns: []
            })
            group.columns.forEach((column) => {
                if (!newGroups[newGroups.length - 1].columns.some((c) => c.field === column.field)) {
                    newGroups[newGroups.length - 1].columns.push(column)
                }
            })
        })
        return newGroups;
    }

    // Garante a renderização à partir das características das colunas em Default (por ordem customizada) + colunas em Grupos Criados
    private _getDefaultGroupsToRender(defaultGroups, customGroups) {
        let groupsToRender = _.cloneDeep(customGroups);
        groupsToRender.forEach((groupToRender) => {
            groupToRender.columns.forEach((columntoRender) => {
                defaultGroups.forEach((defaultGroup) => {
                    defaultGroup.columns.forEach((defaultColumn) => {
                        if (columntoRender.field === defaultColumn.field) {
                            columntoRender = defaultColumn;
                        }
                    });
                });
            });
        });
        return groupsToRender;
    }
}
