import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from 'app/shared/shared.module';
import { LayoutModule } from '../../layouts/layout.module';

import { InvoiceStatusComponent } from './invoice-status.component';
import { InvoiceStatusUtilsService } from './services/invoice-status-utils.service';
import { InvoiceStatusService } from './services/invoice-status.service';

const routes: Routes = [
    {
        path: '',
        component: InvoiceStatusComponent,
    },
    {
        path: ':profitCenters/:intraCompany/:startDate/:endDate',
        component: InvoiceStatusComponent,
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        FormsModule,
        SharedModule,
        LayoutModule,
        SharedModule,
    ],
    declarations: [InvoiceStatusComponent],
    providers: [InvoiceStatusUtilsService, InvoiceStatusService],
})
export class InvoiceStatusModule {}
