import { Component, EventEmitter, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { environment } from '#environment';

import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { ReleasesCrud } from './adm-releases.crud';
import { Feature } from './feature';
import { Release } from './release';

import { UtilsService } from '#services/_utils/utils.service';
import { AdmPanelService } from '../adm-panel.service';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { CmxImgUploadComponent } from 'app/shared/components/cmx-img-upload/img-upload.component';

@Component({
    selector: 'release-crud',
    templateUrl: './adm-releases.component.html',
    styleUrls: ['./adm-releases.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ReleaseCrudComponent {

    @ViewChild('imageUpload')
    private imageUpload!: CmxImgUploadComponent;

    @Output()
    public onClose = new EventEmitter<any>();

    public readonly MAX_PAGES = 3;
    public readonly dropdownSettingsPermissions = {
        singleSelection: false,
        text: 'Selecionar',
        enableCheckAll: false,
        searchPlaceholderText: 'Buscar Permissões',
        badgeShowLabel: 'Permissões selecionadas',
    };
    public asyncReleases: boolean = false;
    public loadingImage: boolean = false;
    public page = 0;
    public pages = [];
    public size = 30;

    public subscription;
    public releaseSearch = '';
    public scrollList;
    public editableElements = ['#releaseModal'];
    public featureEditable = ['.admFormRelease', '.addBtn feature'];
    public deleteMode: string;
    public base64File;
    public modal: string = 'releaseModal';
    public newRelease: boolean = true;
    public release;
    public releaseList = [];
    public featuresList = [];
    public imagePreview;

    public selectedRelease: Release = new Release();
    public selectedFeature: Feature = new Feature();

    public featureTypeList = [
        { code: 'NEW_FEATURE', name: 'Novidade'},
        { code: 'IMPROVEMENT', name: 'Melhoria'},
        { code: 'BUG', name: 'Correção'},
    ];
    public dropdown: {
        permissions: { options: DropdownOption[], selected: DropdownOption[] },
    } = {
            permissions: { options: [], selected: [] },
        };

    private releases = [];
    selectecItem;

    constructor(
        public releasesCrud: ReleasesCrud,
        public utilsService: UtilsService,
        private http: HttpClient, public admService: AdmPanelService,
        private sanitizer: DomSanitizer,
        private _cmxDropdownService: CmxDropdownService,
    ) {
        this.getPermissions().subscribe((permissionList) => {
            this.dropdown.permissions.options = this._cmxDropdownService.toDropdownItems(permissionList);
        });
    }

    public ngAfterViewInit() {
        this.utilsService.setInvisible('release-crud', this.editableElements);
        this.getReleases();
    }

    public selectRelease(item) {
        this.newRelease = false;
        this.hideAllErrorOrSucess();
        this.clearAllForm();
        this.findRelease(item.id);
        this.selectecItem = item;
    }

    public findRelease(idRelease) {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.utilsService.setInvisible('release-crud', this.featureEditable);
        this.asyncReleases = true;
        this.subscription = this.releasesCrud.getReleaseById(idRelease).subscribe((releaseFetch) => {
            this.selectedRelease = new Release(releaseFetch.body);
            this.selectedRelease.setId(idRelease);
            if (this.selectedRelease.features.length > 0) {
                this.selectFeature(this.selectedRelease.features[0]);
            }
            this.asyncReleases = false;
            this.utilsService.setVisible('release-crud', this.featureEditable);
        });
    }

    public searchRelease() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.hideAllErrorOrSucess();
        this.asyncReleases = true;
        this.releaseList = [];
        this.utilsService.setInvisible('release-crud', this.editableElements);
        const paginaton = {
            size: this.size,
            page: 0,
        };
        this.subscription = this.releasesCrud.getReleases(this.releaseSearch, paginaton).subscribe((releaseFetch) => {
            releaseFetch.content.forEach((release) => {
                const newRelease = new Release();
                newRelease.id = release.id;
                newRelease.title = release.title;
                newRelease.name = release.title;
                this.releaseList.push(newRelease);
            });
            this.asyncReleases = false;
            this.utilsService.setVisible('release-crud', this.editableElements);
        }, (error) => {
            this.asyncReleases = false;
            this.utilsService.setVisible('release-crud', this.editableElements);
            this.utilsService.errorHandler(error, 'Releases');
        });
    }

    public selectFeature(feature) {
        this.selectedFeature = feature;
        this.loadImage(this.selectedFeature.getFileName(), this.selectedFeature.getBase64Image());
        const featuresToDropDown = [];
        if (this.selectedFeature.permissions != null) {
            this.selectedFeature.permissions.forEach((featurePermission) => {
                featuresToDropDown.push({ name: featurePermission });
            });
        }
        this.dropdown.permissions.selected = this._cmxDropdownService.toDropdownItems(featuresToDropDown);
    }

    public close() {
        this.onClose.emit(true);
    }

    public getReleases() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.utilsService.setInvisible('release-crud', this.editableElements);
        this.asyncReleases = true;
        this.releaseList = [];
        this.clearAllForm();
        const paginaton = {
            size: this.size,
            page: this.page,
        };
        this.subscription = this.releasesCrud.getReleases(null, paginaton).subscribe((releases) => {
            this.pages = Array.apply(null, { length: releases.totalPages }).map(
                Number.call,
                Number,
            );
            releases.content.forEach((release) => {
                this.clearAllForm();
                const newRelease = new Release();
                newRelease.id = release.id;
                newRelease.title = release.title;
                newRelease.name = release.title;
                this.releaseList.push(newRelease);
            });
            setTimeout(() => {
                this.asyncReleases = false;
                this.utilsService.setVisible('release-crud', this.editableElements);
            }, 550);
        }, (error) => {
            this.utilsService.errorHandler(error, 'Release');
            this.asyncReleases = false;
            this.utilsService.setVisible('release-crud', this.editableElements);
        });
    }

    public getRequestEdit() {
        const permissionsSelected = [];
        this.dropdown.permissions.selected.forEach((permissionList) => {
            permissionsSelected.push(permissionList.id);
        });
        if (permissionsSelected.length == 0) {
            this.selectedFeature.setPermissions(null);
        } else {
            this.selectedFeature.setPermissions(permissionsSelected);
        }
        return this.selectedRelease;
    }

    public createRelease() {
        this.hideAllErrorOrSucess();
        if (this.selectedRelease.getTitle() === '' || null || undefined) {
            return this.showErrors('releasesInfo', 'emptyField');
        }
        if (this.selectedFeature.getTitle() === '' || null || undefined) {
            return this.showErrors('releasesInfo', 'emptyFeatureName');
        }
        if (this.selectedFeature.getDescription() === '' || null || undefined) {
            return this.showErrors('releasesInfo', 'emptyFeatureDescription');
        }
        this.newRelease = true;
        this.asyncReleases = true;
        this.utilsService.setInvisible('release-crud', this.editableElements);
        this.releasesCrud.createReleases(this.getRequestEdit()).subscribe((response) => {
            this.clearAllForm();
            this.asyncReleases = false;
            this.showSuccess('releasesInfo', 'create');
            this.utilsService.setVisible('release-crud', this.editableElements);
            this.getReleases();
        }, (error) => {
            this.utilsService.errorHandler(error, 'Releases');
            this.asyncReleases = false;
            this.utilsService.setVisible('release-crud', this.editableElements);
        });
    }

    public startNewRelease() {
        this.newRelease = true;
        this.clearAllForm();
        this.hideAllErrorOrSucess();
        this.admService.closeDeleteModal(this.modal);
        if (this.selectedRelease.features.length > 0) {
            this.selectFeature(this.selectedRelease.features[0]);
        }
    }

    public addNewFeature() {
        this.hideAllErrorOrSucess();
        this.admService.closeDeleteModal(this.modal);
        this.loadingImage = true;
        this.imageUpload?.clenupAttach();
        this.selectedFeature = new Feature();
        this.selectedRelease.features.push(this.selectedFeature);
    }

    public showSuccess(modal, type) {
        $(`#${modal} .success.${type}`).removeClass('hidden');
    }

    public showErrors(modal, type) {
        $(`#${modal} .error.${type}`).removeClass('hidden');
    }
    public hideAllErrorOrSucess() {
        $('.error').addClass('hidden');
        $('.success').addClass('hidden');
    }

    public confirmDelete() {
        this.deleteMode === 'release' ? this.confirmDeleteRelease() :
            this.confirmDeleteFeature();
    }

    public saveChanges(deleteFeature?) {
        this.hideAllErrorOrSucess();
        if (!deleteFeature) {
            if (this.selectedRelease.getTitle() === '' || null || undefined) {
                return this.showErrors('releasesInfo', 'emptyField');
            }
            if (this.selectedFeature.getTitle() === '' || null || undefined) {
                return this.showErrors('releasesInfo', 'emptyFeatureName');
            }
            if (this.selectedFeature.getDescription() === '' || null || undefined) {
                return this.showErrors('releasesInfo', 'emptyFeatureDescription');
            }
        }
        this.newRelease = true;
        this.asyncReleases = true;
        this.utilsService.setInvisible('release-crud', this.editableElements);
        this.releasesCrud.editEditReleases(this.selectedRelease.getId(), this.getRequestEdit()).subscribe((response) => {
            this.utilsService.setVisible('release-crud', this.editableElements);
            this.clearAllForm();
            if (deleteFeature) {
                this.showSuccess('releasesInfo', 'delete');
            } else {
                this.showSuccess('releasesInfo', 'edit');
            }
            this.getReleases();
        }, (error) => {
            this.asyncReleases = false;
            this.utilsService.errorHandler(error, 'Releases');
            this.utilsService.setVisible('release-crud', this.editableElements);
        });
    }

    public addFile($file) {
        if (!this.loadingImage) {
            if ($file) {
                const reader = new FileReader();
                reader.readAsDataURL($file);
                reader.onload = () => {
                    this.imagePreview = $file;
                    this.selectedFeature.setBase64Image(reader.result);
                };
            } else {
                this.selectedFeature.setBase64Image('');
                this.selectedFeature.setDeleteImage(true);
            }
        } else {
            this.loadingImage = false;
        }
    }

    public loadImage(fileName, base64Image) {
        this.loadingImage = true;
        if (base64Image !== '' && base64Image !== null) {
            fetch(base64Image)
            .then(res => res.blob())
            .then(blob => {
                this.imagePreview = blob;
            })
        } else if (fileName !== '' && fileName !== null) {
            this.releasesCrud.loadImage(fileName).subscribe(res => {
                let file = res;
                file['name'] = fileName;
                this.imagePreview =  file;
            }, (error) => this.imageUpload.clenupAttach());
        } else {
            this.imageUpload?.clenupAttach();
        }
    }

    public clearAllForm() {
        this.selectedRelease = new Release();
        this.selectedFeature = this.selectedRelease.features[0];
        this.dropdown.permissions.selected = [];
        this.loadingImage = true;
        this.imageUpload?.clenupAttach();
    }

    public updatePermissions() {
        this.selectedFeature.permissions = [];
        this.dropdown.permissions.selected.forEach((permission) => {
            this.selectedFeature.permissions.push(permission.itemName);
        });
    }

    public toogleFeatureType(featureType, element) {
        if (!$(element.target).hasClass('selected')) {
            $('.typeContainer .featureType').removeClass('selected');
            $(element.target).addClass('selected');
            this.selectedFeature.setFeatureType(featureType);
        }
    }

    public changePage(pageNumber) {
        this.page = (pageNumber - 1);
        this.getReleases();
    }

    public enterBtn(element) {
        this.hideAllErrorOrSucess();
        if (element.keyCode === 13) {
            if ($('release-crud #releaseSearch').is(':focus')) {
                this.searchRelease();
            }
        }
    }

    private confirmDeleteRelease() {
        this.hideAllErrorOrSucess();
        this.asyncReleases = true;
        this.utilsService.setInvisible('release-crud', this.editableElements);
        this.releasesCrud.deleteReleases(this.selectedRelease.id).subscribe(() => {
            this.admService.closeDeleteModal(this.modal);
            this.getReleases();
            this.showSuccess('releasesInfo', 'delete');
        }, (error) => {
            this.admService.closeDeleteModal(this.modal);
            this.utilsService.errorHandler(error, 'Erro ao Deletar Release');
            this.utilsService.setVisible('release-crud', this.editableElements);
            this.asyncReleases = false;
        });
    }
    private confirmDeleteFeature() {
        const filteredFeatures = this.selectedRelease.features.filter((feature) => feature !== this.selectedFeature);
            this.selectedRelease.features = filteredFeatures;
            this.selectFeature(filteredFeatures[0]);
        this.admService.closeDeleteModal(this.modal);
    }

    private getPermissions(): Observable<any> {
        return this.http.get(`${environment.endpoints.adminService}/permissions`);
    }

    trackByFn(index: number, item: any) {
        return index;
    }
}
