import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, HostListener, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Observable } from 'rxjs';

import { environment } from '#environment';
import { UserService } from '../../../../auth/_services';
import { ChartsServices } from './../charts.services';
import { UserPermissionBlockerService } from '#services/_user/userPermissionBlocker.service';
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';
import { UtilsService } from '#services/_utils/utils.service';
import { InvoicesFilter } from './invoices.filter';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';

declare let Slick: any;

@Component({
    selector: 'invoices-chart',
    templateUrl: './invoices.component.html',
    styleUrls: ['./../charts.scss', './invoices.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class InvoicesComponent implements AfterViewInit {

    filterState: InvoicesFilter = this._filterStateService.getInvoicesFilter();
    currentUser$ = this.userService.currentUser$;
    intraSwitch = {
        values: ['s/ IntraCompany', 'c/ IntraCompany'],
        index: 0,
    };

    dataInvoicesBilledItens;
    dataInvoicesReceived: any = {};
    dataInvoiceAmount: any;
    dataCurrentExchange: any;
    dataLastExchange: any;
    stringCurrentExchange: string;
    stringInvoiceAmount: string;
    dateCurrentExchange: string;
    dateLastExchange: string;

    asyncInvoicesBilledItens: boolean = false;
    asyncInvoicesReceived: boolean = false;
    asyncInvoiceAmount: boolean = false;
    asyncExchangeValue: boolean = false;

    percentageInvoicesBilledItens: number;
    percentageInvoicesReceived: number;
    percentageInvoiceAmount: number;
    percentageExchange: any;
    percentageNegativeExchange;
    percentagePositiveExchange;

    subscriptionInvoicesBilledItens;
    subscriptionInvoicesReceived;
    subscriptionInvoiceAmount;
    subscriptionExchange;
    changedMonth = 0;

    startDate = moment().add(-7, 'days').format('YYYY-MM-DD');
    endDate = moment().format('YYYY-MM-DD');

    currentMonth = moment().format('YYYY-MM');
    lastMonth = moment().add(-1, 'M').format('YYYY-MM');
    UIcurrentMonth = moment().format('MM/YYYY');
    helpDescription = this._defineHelpDescription();

    @HostListener('click') onSetZIndex() {
        this.renderer2.setStyle(this._elementRef.nativeElement, 'z-index', this.utilsService.autoIncrementValue);
    }

    constructor(
        public chartService: ChartsServices,
        public router: Router,
        public featureFlagService: FeatureFlagService,
        private http: HttpClient,
        private userService: UserService,
        private el: ElementRef,
        private permissionBlocker: UserPermissionBlockerService,
        private utilsService: UtilsService,
        private _elementRef: ElementRef,
        private renderer2: Renderer2,
        private _filterStateService: FilterStateService,
        private _customDialogService: CustomDialogService,
    ) {
        this.loadInvoices();
    }

    ngAfterViewInit() {
        $(document).on('click', (e: any) => {
            const selector = (e.target.className);
            if (selector === 'selector') {
                return;
            } else {
                $('.options').attr('class', 'options hidden');
            }
        });
    }

    toggleFilterMode() {
        if ($('invoices-chart .filterMode').hasClass('clicked')) {
            this.filterState.setAutomotiveMode('Desligado');
            $('invoices-chart .filterMode').removeClass('clicked');
        } else {
            this.filterState.setAutomotiveMode('Ligado');
            $('invoices-chart .filterMode').addClass('clicked');
        }
        this.asyncInvoicesBilledItens = false;
        this.asyncInvoicesReceived = false;
        this.asyncInvoiceAmount = false;
        if (this.subscriptionInvoicesBilledItens) {
            this.subscriptionInvoicesBilledItens.unsubscribe();
        }
        if (this.subscriptionInvoicesReceived) {
            this.subscriptionInvoicesReceived.unsubscribe();
        }
        if (this.subscriptionInvoiceAmount) {
            this.subscriptionInvoiceAmount.unsubscribe();
        }
        this.userService.currentUser$.subscribe((user) => {
            this.loadImpOrChassi(user);
        });
    }

    toIMPStatus() {
        this.router.navigate([`./operacional/importacao/faturadas/${this.currentMonth}`]);
    }

    toInvoiceStatus() {
        const startDate = `${this.currentMonth}-01`;
        const endDate = moment(this.currentMonth).add('1', 'M').add('-1', 'days').format('YYYY-MM-DD');
        const intraCompany = this.filterState.getIntraCompany() ? '0' : '1';
        const queryParams = {
            force: true,
            intraCompany: this.filterState.getIntraCompany(),
            clientGroups: this.filterState.getClientGroups()
        }
        this.router.navigate([`./supply-chain/notas-fiscais/0/${intraCompany}/${startDate}/${endDate}`], { queryParams });
    }

    changeMonth(num) {
        this.asyncInvoicesBilledItens = false;
        this.asyncInvoicesReceived = false;
        this.asyncInvoiceAmount = false;
        this.changedMonth += num;
        this.currentMonth = moment().add(this.changedMonth, 'M').format('YYYY-MM');
        this.UIcurrentMonth = moment(this.currentMonth).format('MM/YYYY');
        this.loadInvoices();
    }

    loadInvoices() {
        this.userService.currentUser$.subscribe((user) => {
            if (this.permissionBlocker.permissionValidator(this.el.nativeElement.tagName)) {
                if (this.changedMonth === 0) {
                    this.startDate = moment().add(-7, 'days').format('YYYY-MM-DD');
                    this.endDate = moment().format('YYYY-MM-DD');
                } else {
                    this.startDate = moment(this.currentMonth, 'YYYY-MM').format('YYYY-MM-25');
                    this.endDate = moment(this.currentMonth, 'YYYY-MM')
                    .add(1, 'M')
                    .add(-1, 'days')
                    .format('YYYY-MM-DD');
                }
                if (this.subscriptionInvoicesBilledItens) {
                    this.subscriptionInvoicesBilledItens.unsubscribe();
                }
                if (this.subscriptionInvoicesReceived) {
                    this.subscriptionInvoicesReceived.unsubscribe();
                }
                if (this.subscriptionInvoiceAmount) {
                    this.subscriptionInvoiceAmount.unsubscribe();
                }
                if (this.subscriptionExchange) {
                    this.subscriptionExchange.unsubscribe();
                }
                this.currentMonth = moment().add(this.changedMonth, 'M').format('YYYY-MM');
                this.lastMonth = moment().add((this.changedMonth - 1), 'M').format('YYYY-MM');

                this.loadImpOrChassi(user);
                this.loadExchange();
            }
        });
    }

    loadImpOrChassi(user) {
        this.asyncInvoicesBilledItens = false;
        this.asyncInvoicesReceived = false;
        this.asyncInvoiceAmount = false;
        this.filterState.updatePreferences()
        const automotive = user.has('AUTOMOTIVE_USER') || user.has('IMP_AUTOMOTIVE_USER') && this.filterState.getAutomotiveMode() === 'Ligado';
        if (automotive) {
            this.subscriptionInvoicesBilledItens = this.getInvoicesBilledMonth(user.has('AUTOMOTIVE_USER') ||
            this.filterState.getAutomotiveMode() === 'Ligado').subscribe((dataValue) => {
                this.dataInvoicesBilledItens = dataValue.numberOfProcess;
                this.percentageInvoicesBilledItens = Math.round(dataValue.percentage * 100);
                this.asyncInvoicesBilledItens = true;
            }, (error) => {
                this.dataInvoicesBilledItens = undefined;
                this.asyncInvoicesBilledItens = true;
                this.utilsService.errorHandler(error, 'Chassis Faturados (Métricas)');
            });
        } else {
            this.subscriptionInvoicesBilledItens = this.getImps()
            .subscribe((dataValue) => {
                this.dataInvoicesBilledItens = dataValue.total;
                this.percentageInvoicesBilledItens = Math.round(dataValue.percentage * 100);
                this.asyncInvoicesBilledItens = true;
            }, (error) => {
                this.dataInvoicesBilledItens = undefined;
                this.asyncInvoicesBilledItens = true;
                this.utilsService.errorHandler(error, 'IMPs Faturadas (Métricas)');
            });
        }

        if (this.featureFlagService.isFeatureFlagEnabled('invoice-service:client-invoice-use-elastic')) {
            this.subscriptionInvoicesReceived = this.getInvoicesReceived(automotive).subscribe((dataValue) => {
                this.dataInvoicesReceived = dataValue;
                this.dataInvoicesReceived.amountValue = (Math.trunc(this.dataInvoicesReceived.amountValue / 10000) / 100);
                this.stringInvoiceAmount = this.dataInvoicesReceived.amountValue.toString().replace('.', ',');
                this.percentageInvoicesReceived = Math.round(dataValue.percentage * 100);
                this.percentageInvoiceAmount = Math.round(dataValue.amountPercentage * 100);
                this.asyncInvoicesReceived = true;
                this.asyncInvoiceAmount = true;
            }, (error) => {
                this.dataInvoicesReceived = undefined;
                this.asyncInvoicesReceived = true;
                this.asyncInvoiceAmount = true;
                this.utilsService.errorHandler(error, 'Faturas (Métricas)');
            });
        } else {
            this.subscriptionInvoicesReceived = this.getInvoicesReceived(automotive).subscribe((dataValue) => {
                this.dataInvoicesReceived = dataValue;
                this.percentageInvoicesReceived = Math.round(dataValue.percentage * 100);
                this.asyncInvoicesReceived = true;
            }, (error) => {
                this.dataInvoicesReceived = undefined;
                this.asyncInvoicesReceived = true;
                this.utilsService.errorHandler(error, 'Faturas (Métricas)');
            });
            this.subscriptionInvoiceAmount = this.getInvoiceAmount(automotive).subscribe((dataValue) => {
                let lastMonth = dataValue.invoiceAmount.data[0].value;
                this.dataInvoiceAmount = dataValue.invoiceAmount.data[1].value;
                if (this.dataInvoiceAmount === 0) {
                    this.percentageInvoiceAmount = 0;
                } else {
                    this.percentageInvoiceAmount = Math.trunc((this.dataInvoiceAmount / lastMonth ) * 100);
                }
                this.dataInvoiceAmount = Math.trunc(this.dataInvoiceAmount / 10000) / 100;
                this.asyncInvoiceAmount = true;
                this.stringInvoiceAmount = this.dataInvoiceAmount.toString().replace('.', ',');
            }, (error) => {
                this.dataInvoiceAmount = undefined;
                this.asyncInvoiceAmount = true;
                this.utilsService.errorHandler(error, 'Faturamento (Métricas)');
            });
        }

    }

    loadExchange() {
        this.asyncExchangeValue = false;
        this.subscriptionExchange = this.getExchange().subscribe((dataValue) => {
            let date = dataValue.values[dataValue.values.length - 1].dateQuotation;
            let lastDate = dataValue.values[dataValue.values.length - 2].dateQuotation;
            date = date.split('-');
            lastDate = lastDate.split('-');
            this.dateCurrentExchange = `${date[2]}/${date[1]}/${date[0]}`;
            this.dateLastExchange = `${lastDate[2]}/${lastDate[1]}/${lastDate[0]}`;
            this.dataCurrentExchange = dataValue.values[dataValue.values.length - 1].quotationValue;
            this.dataLastExchange = dataValue.values[dataValue.values.length - 2].quotationValue;
            this.percentageExchange = (((this.dataCurrentExchange * 100) /
            this.dataLastExchange) - 100).toFixed(2);
            if (this.percentageExchange >= 0) {
                this.percentageNegativeExchange = 100;
                this.percentagePositiveExchange = this.percentageExchange * 10;
            } else {
                this.percentageExchange = parseFloat(this.percentageExchange);
                this.percentagePositiveExchange = 0;
                this.percentageNegativeExchange = 100 - ((100 - (this.percentageExchange + 100)) * 10);
            }
            this.asyncExchangeValue = true;
            this.stringCurrentExchange = this.dataCurrentExchange.toString().replace('.', ',');
        }, (error) => {
            this.asyncExchangeValue = true;
            this.utilsService.errorHandler(error, 'Cotação Dólar');
        });
    }

    toggleTypeSwitch(values) {
        if (this.subscriptionInvoicesBilledItens) {
            this.subscriptionInvoicesBilledItens.unsubscribe();
        }
        if (this.subscriptionInvoicesReceived) {
            this.subscriptionInvoicesReceived.unsubscribe();
        }
        if (this.subscriptionInvoiceAmount) {
            this.subscriptionInvoiceAmount.unsubscribe();
        }
        this.filterState.setIntraCompany(values);
        this.asyncInvoicesBilledItens = false;
        this.asyncInvoicesReceived = false;
        this.asyncInvoiceAmount = false;

        this.userService.currentUser$.subscribe((user) => {
            this.loadImpOrChassi(user);
        });
    }

    openDetailModal() {
        this._customDialogService.open("cmx-custom-dialog-information")
    }

    trackByFn(index: number, item: any) {
        return index;
    }

    // Get data for Invoice
    // Processes Billed in Month
    private getInvoicesBilledMonth(automotive?): Observable<any> {
        if (automotive) {
            return this.http.get(`${environment.endpoints.automotiveV2QueryService}/api/v2/stats/chassis-invoiced?referenceMonth=${this.currentMonth}`, this._getHeaders());
        }
        return this.http.get(
            `${environment.endpoints.invoiceService
            }/stats/v1/invoice?origin=IMP&groupingBy=invoice&referenceMonth=${this.currentMonth}`, this._getHeaders());
    }
    // Invoices Received
    private getInvoicesReceived(automotive?): Observable<any> {
        const origin = automotive ? 'CHASSI' : 'IMP';
        return this.http.get(
            `${environment.endpoints.invoiceService
            }/stats/v1/invoice?origin=${origin}&referenceMonth=${this.currentMonth}&intraCompanies=${this.filterState.getIntraCompany()}`, this._getHeaders());
    }
    // Get Billing
    private getInvoiceAmount(automotive?): Observable<any> {
        const CR = automotive ? '49' : '';
        return this.http.get(
            `${
            environment.endpoints.supplyChainService
            }/stats/invoiceAmount?costCenters=${CR}&startMonth=${this.lastMonth}&endMonth=${this.currentMonth}&intraCompanies=${this.filterState.getIntraCompany()}`, this._getHeaders());
    }
    // Get data for Exchange Chart
    private getExchange(): Observable<any> {
        return this.http.get(
            `${
            environment.endpoints.exchangeService
            }/exchanges/daily-exchange/USD?startDate=${this.startDate}&endDate=${this.endDate}`, this._getHeaders()
        );
    }
    // Get IMPS
    private getImps(): Observable<any> {
        const request = {
            startDate: `${this.currentMonth}-01`,
            endDate: this.endDate,
            referenceDateType: 'billing',
            clientReference: '',
            intraCompanies: this.filterState.getIntraCompany(),
            calculatePercentual: true,
        };
        return this.http.post(
            `${environment.endpoints.importationService}/imps/billing-stats`, request, this._getHeaders(),
        );
    }

    private _getHeaders() {
        let headers = new HttpHeaders();
        headers = headers.set('X-Requested-Client-Groups', this.filterState.getClientGroups().map((c) => c['id']).join(','));
        return { headers }
    }

    private _defineHelpDescription(): object[] {
        return [
            { type: 'text', hasLineAfter: true,  value: 'Informa a quantidade de processos faturados, número de notas fiscais faturadas e valor faturado referente ao mês selecionado.'},
            { type: 'text', value: 'É apresentado a cotação do Dólar Ptax em relação ao Real.' },
        ]
    }

}
