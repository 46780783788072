import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import moment from 'moment';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IAlertsManagerFilter } from './interface/alerts-manager.interface';

export class AlertsManagerFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialAlertsManagerFilter: IAlertsManagerFilter = <IAlertsManagerFilter>{};
    private _alertsManagerFilter: IAlertsManagerFilter = <IAlertsManagerFilter>{
        clientGroups: [],
        subsidiaryNames: [],
        urfNationalizationCodes: [],
        alertTypes: ['imp', 'customs_clearance', 'operational', 'tax', 'bi'],
        alertPriorities: ['LOW', 'MEDIUM', 'HIGH', 'CRITICAL']
    };

    constructor(filter?: AlertsManagerFilter) {
        this._alertsManagerFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'alertsManagerFilter', this._alertsManagerFilter, filter);
        this._initialAlertsManagerFilter = _.cloneDeep(this._alertsManagerFilter);
    }

    public getClientGroups() {
        return this._alertsManagerFilter.clientGroups;
    }
    public getSubsidiaryNames() {
        return this._alertsManagerFilter.subsidiaryNames;
    }
    public getUrfs() {
        return this._alertsManagerFilter.urfNationalizationCodes;
    }
    public getAlertTypes() {
        return this._alertsManagerFilter.alertTypes;
    }
    public getAlertPriorities() {
        return this._alertsManagerFilter.alertPriorities;
    }
    public getStatus() {
        return this._alertsManagerFilter.alertStatus;
    }

    public setClientGroups(clientGroups): void {
        this._alertsManagerFilter.clientGroups = clientGroups;
    }
    public setSubsidiaryNames(subsidiaryNames): void {
        this._alertsManagerFilter.subsidiaryNames = subsidiaryNames;
    }
    public setUrfs(urfs): void {
        this._alertsManagerFilter.urfNationalizationCodes = urfs;
    }
    public setAlertTypes(alertTypes): void {
        this._alertsManagerFilter.alertTypes = alertTypes;
    }
    public setAlertPriorities(alertPriorities): void {
        this._alertsManagerFilter.alertPriorities = alertPriorities;
    }
    public setStatus(alertStatus): void {
        this._alertsManagerFilter.alertStatus = alertStatus;
    }

    public updatePreferences() {
        this._initialAlertsManagerFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialAlertsManagerFilter, this._alertsManagerFilter, 'alertsManagerFilter', this._userCacheService.getUserPreferences().id);
    }
}
