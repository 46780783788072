import { UserPreferencesService } from "#services/_user/user-preferences.service";
import { AppModule } from "app/app.module";
import { UserCacheService } from "#services/_user/app-user-cache.service";
import * as _ from 'lodash';
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { IPaymentClosingFilter } from "./interfaces/payment-closing-filter.interface";
import moment from "moment";

export class PaymentClosingFilter {
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _page: number;
    private _size: number;
    private activeFilters: { [key: string]: string } = {};
    private filterField: string;
    private filterValue: string;
    private _startDate;
    private _endDate;
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);
    private _initialPaymentClosingFilter: IPaymentClosingFilter = <IPaymentClosingFilter>{};
    private _paymentClosingFilter: IPaymentClosingFilter = <IPaymentClosingFilter> {
        expensesStatus: [],
        impStatus: [],
        clientGroups: [],
        subsidiaries: [],
        crs: [],
        origins: [],
        handlings: [],
    };

    constructor(filter?: PaymentClosingFilter) {
        this._paymentClosingFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'paymentClosingFilter', this._paymentClosingFilter, filter);
        this._initialPaymentClosingFilter = _.cloneDeep(this._paymentClosingFilter);
        this._startDate = filter ? filter._startDate : moment().format('01/01/YYYY');
        this._endDate = filter ? filter._endDate : moment().format('DD/MM/YYYY');
        this._page = filter ? filter._page : 0;
        this._size = filter ? filter._size : 50;
    }

    getExpensesStatus(): any { return this._paymentClosingFilter.expensesStatus; }
    getImpStatus(): any { return this._paymentClosingFilter.impStatus; }
    getClientGroups(): any { return this._paymentClosingFilter.clientGroups; }
    getSubsidiaries(): any { return this._paymentClosingFilter.subsidiaries; }
    getOrigins(): any { return this._paymentClosingFilter.origins; }
    getHandlings(): any { return this._paymentClosingFilter.handlings; }
    getProfitCenters(): any { return this._paymentClosingFilter.crs; }
    getStartDate() { return this._startDate; }
    getEndDate() { return this._endDate; }
    getPage(): number { return this._page; }
    getSize(): number { return this._size; }
    getFilterField() { return this.filterField; }
    getFilterValue() { return this.filterValue; }

    setExpensesStatus(expensesStatus): void { this._paymentClosingFilter.expensesStatus = expensesStatus; }
    setImpStatus(impStatus): void { this._paymentClosingFilter.impStatus = impStatus; }
    setClientGroups(clientGroups): void { this._paymentClosingFilter.clientGroups = clientGroups; }
    setSubsidiaries(subsidiaries): void { this._paymentClosingFilter.subsidiaries = subsidiaries; }
    setOrigins(origins): void { this._paymentClosingFilter.origins = origins; }
    setHandlings(handlings): void { this._paymentClosingFilter.handlings = handlings; }
    setProfitCenters(crs): void { this._paymentClosingFilter.crs = crs; }
    setStartDate(startDate): void { this._startDate = startDate; }
    setEndDate(endDate): void { this._endDate = endDate; }
    setPage(page): void { this._page = page; }
    setSize(size): void { this._size = size; }
    setFilterField(field): void {if (field !== undefined && field !== null) {this.filterField = field}}
    setFilterValue(value): void {
        this.filterValue = value;
        this.activeFilters[this.filterField] = this.filterValue;
    }

    getRequest() {
        this._initialPaymentClosingFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialPaymentClosingFilter, this._paymentClosingFilter, 'paymentClosingFilter', this._userCacheService.getUserPreferences().id);
        let params = new HttpParams();
        let headers = new HttpHeaders();
        if (this.getExpensesStatus()?.length > 0) {
            params = params.append('field', `closings[*].status::${this.getExpensesStatus().map((c) => (c.id)).join(',')
                .replace('FINISHED', 'RECONCILED,ABSORBED,ALLOCATED')}`);
        }
        if (this.getClientGroups()?.length > 0) {
            params = params.append('field', `clientGroup.name::${this.getClientGroups().map((c) => c.itemName).join(',')}`);
        }
        if (this.getSubsidiaries()?.length > 0) {
            params = params.append('field', `subsidiaryName::${this.getSubsidiaries().map((c) => c.itemName).join(',')}`);
        }
        if (this.getProfitCenters()?.length > 0) {
            params = params.append('field', `costCenter::${this.getProfitCenters().map((c) => c.itemName).join(',')}`);
        }
        if (this.getOrigins()?.length > 0) {
            params = params.append('field', `closings[*].difference.origin::${this.getOrigins().map((c) => c.id).join(',')}`);
        }
        if (this.getHandlings()?.length > 0) {
            params = params.append('field', `closings[*].difference.handling::${this.getHandlings().map((c) => c.id).join(',')}`);
        }
        params = params.append('field', `dateIssueFirstInvoice::${moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}.${moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}::DATE_RANGE`);
        params = params.append('page', `${ this.getPage() }`);
        params = params.append('size', `${ this.getSize() }`);
        Object.keys(this.activeFilters).forEach(key => {
            let filterValue = this.activeFilters[key];
            if (filterValue) {
                const encodedValue = encodeURIComponent(filterValue);
                if (key === 'impNumber') {
                    if (filterValue && !/^\d+$/.test(filterValue)) {
                        params = params.append('field', `closings[*].expense.nationalizationExpenses::${encodedValue}`);
                    } else {
                        params = params.append('field', `${key}::${encodedValue}`);
                    }
                } else {
                    params = params.append('field', `${key}::${encodedValue}`);
                }
            }
        });
        return { params, headers };
    }

}
