import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class CountryFlagsService {

    private url = 'https://flagcdn.com/';
    private size = 'h20/';
    private format = '.png';
    private countryCodes = {
        ALEMANHA: 'de', GERMANY: 'de',
        ARGENTINA: 'ar',
        AUSTRIA: 'at',
        AUSTRALIA: 'au',
        BARBADOS: 'bb',
        BELGICA: 'be', BELGIUM: 'be',
        BULGARIA: 'bg',
        BOLIVIA: 'bo',
        BRASIL: 'br', BRAZIL: 'br',
        BAHAMAS: 'bs',
        CANADA: 'ca',
        CHILE: 'cl',
        CAMAROES: 'cm', CAMEROON: 'cm',
        CHINA: 'cn',
        COLOMBIA: 'co',
        COREIA: 'kr', KOREA: 'kr',
        COSTARICA: 'cr',
        CROACIA: 'hr', CROATIA: 'hr',
        DINAMARCA: 'dk', DENMARK: 'dk',
        EQUADOR: 'ec', ECUADOR: 'ec',
        EGITO: 'eg', EGYPT: 'eg',
        ESPANHA: 'es', SPAIN: 'es',
        FINLANDIA: 'fi', FINLAND: 'fi',
        FRANCA: 'fr', FRANCE: 'fr',
        GRECIA: 'gr', GREECE: 'gr',
        HONGKONG: 'hk', 
        HUNGRIA: 'hu', HUNGARY: 'hu',
        INDONESIA: 'id',
        INDIA: 'in',
        IRAQUE: 'iq', IRAQ: 'iq',
        IRA: 'ir',
        ITALIA: 'it', ITALY: 'it',
        NOVAZELANDIA: 'nz', NEWZEALAND: 'nz',
        MALASIA: 'my', MALAYSIA: 'my',
        JAPAO: 'jp', JAPAN: 'jp',
        MEXICO: 'mx',
        PERU: 'pe',
        FILIPINAS: 'ph', PHILIPPINES: 'ph',
        PAISESBAIXOS: 'nl', NETHERLANDS: 'nl',
        PAQUISTAO: 'pk', PAKISTAN: 'pk',
        POLONIA: 'pl', POLAND: 'pl',
        PORTUGAL: 'pt',
        PARAGUAI: 'py', PARAGUAY: 'py',
        REINOUNIDO: 'gb', UNITEDKINGDOM: 'gb',
        RUSSIA: 'ru',
        SUECIA: 'se', SWEDEN: 'se',
        SUICA: 'ch', SWITZERLAND: 'ch',
        TAILANDIA: 'th', THAILAND: 'th',
        TURQUIA: 'tr', TURKEY: 'tr',
        TAIWAN: 'tw',
        UCRANIA: 'ua', UKRAINE: 'ua',
        ESTADOSUNIDOS: 'us', UNITEDSTATES: 'us', EUA: 'us',
        URUGUAI: 'uy', URUGUAY: 'uy',
        VIETNA: 'vn', VIETNAM: 'vn',
        AFRICADOSUL: 'za', SOUTHAFRICA: 'za',
    };

    private countryCompoundNames = {
        COSTARICA: 'COSTA RICA',
        HONGKONG: 'HONG KONG',
        NOVAZELANDIA: 'NOVA ZELANDIA', NEWZEALAND: 'NOVA ZELANDIA',
        PAISESBAIXOS: 'PAISES BAIXOS', NETHERLANDS: 'PAISES BAIXOS',
        REINOUNIDO: 'REINO UNIDO', UNITEDKINGDOM: 'REINO UNIDO',
        ESTADOSUNIDOS: 'ESTADOS UNIDOS', UNITEDSTATES: 'ESTADOS UNIDOS', EUA: 'ESTADOS UNIDOS',
        AFRICADOSUL: 'AFRICA DO SUL', SOUTHAFRICA: 'AFRICA DO SUL'
    }

    getFlag(countryName) {
        let key;
        if (countryName !== null && countryName !== undefined && countryName !== '') {
            Object.keys(this.countryCodes).forEach((country) => {
                if (_.deburr(countryName.toUpperCase()).replace(/\s/g, '').includes(country)) {
                    key = country;
                }
            });
        } else {
            key = '';
        }
        return this.countryCodes[key] ? `${this.url}${this.size}${this.countryCodes[key]}${this.format}` : '';
    }

    async getReduzedName(countryName) {
        return new Promise<any>((resolve, reject) => {
            let name = ''
            Object.keys(this.countryCodes).forEach((country) => {
                if (_.deburr(countryName.toUpperCase()).replace(/\s/g, '').includes(country)) {
                    name = this.countryCompoundNames[country] ? this.countryCompoundNames[country] : country
                }
            });
            setTimeout(() => { resolve(name) }, 100);
        });
    }

}
