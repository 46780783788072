import { Component, ViewEncapsulation } from '@angular/core';
import moment from 'moment';
import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';
import { ImportDetailsService } from '../import-details.service';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LICENCE_COLUMNS } from './constants/licence-columns.constants';
import { ImportDetailItem } from '../import-details-item';

@Component({
    selector: 'app-import-details-licence',
    templateUrl: './licence.component.html',
    styleUrls: ['./licence.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LicenceComponent extends ImportDetailItem {
    impNumber = this._route.snapshot.params.imp;
    asyncDetail = !this.showLoader;

    dataLoaded;
    licenceList;
    liNumber;
    liDetails;

    gridLicenceColumns: IGridColumn[] = LICENCE_COLUMNS;

    constructor(
        private _importDetailsService: ImportDetailsService,
        private _customDialogService: CustomDialogService,
        private _route: ActivatedRoute,
        protected _router: Router,
    ) {
        super(_router);
    }

    ngOnInit() {
        (this.showLoader) ? 
            setTimeout(() => {this.loadDetails();}, 1000) : 
            this.loadDetails();
    }

    loadDetails() {
        this.asyncDetail = false;
        this.dataLoaded = this._importDetailsService.getDetails();
        if (this.dataLoaded) {
            this.licenceList = this.dataLoaded.importationLicenses;
        }

        this.asyncDetail = true;
    }

    onCellClick({ item }) {
        this.liNumber = item.liNumber;

        this.liDetails = [
            {
                title: 'Órgão Anuente',
                value: item.licensingBody,
            },
            {
                title: 'Status',
                value: item.liStatus,
            },
            {
                title: 'Data Registro LI',
                value: this._formatDate(item.dates.issueDate),
            },
            {
                title: 'Data Protocolo LI',
                value: this._formatDate(item.dates.protocolDate),
            },
            {
                title: 'Data Situação LI',
                value: item.dates.statusUpdatedAt,
            },
            {
                title: 'Deferimento LI',
                value: this._formatDate(item.dates.acceptanceDate),
            },
        ];

        this._customDialogService.open('import-details-licence-dialog');
    }

    private _formatDate(date) {
        return moment(date, 'YYYYMMDD').isValid() ? moment(date).format('DD/MM/YYYY') : date
    }
}
