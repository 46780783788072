import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
@Directive({
  selector: '[dragDropUpload]'
})
export class DragDropUploadDirective {

    @Output() onFileDropped = new EventEmitter<any>();

    constructor() { }
  
    @HostListener('dragover', ['$event']) onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
    };

    @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
    }

    @HostListener('drop', ['$event']) public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        
        let files = evt.dataTransfer.files;
        
        if (files.length > 0) {
            this.onFileDropped.emit(files)
        }
    }
}