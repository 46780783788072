
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';

import { Observable } from 'rxjs';

import { environment } from '#environment';

@Injectable()
export class SkuStatusService {
    constructor(private _http: HttpClient) { }

    getSkuStatusList(request: any): Observable<any> {
        return this._http.get(`${environment.endpoints.skuService}/sku-status/count`, request).pipe(
            map((skuStatusList: any) => {
                const status = skuStatusList && skuStatusList.skuStatus;

                return _.reduce(status, (prev, curr) => {
                    return [...prev, _.omit(_.merge(curr, curr.status), 'status')]
                }, []);
            }));
    }
}
