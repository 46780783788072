import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Hierarchy } from '#services/_results/results.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { AppModule } from 'app/app.module';
import * as _ from 'lodash';
import { IHierarchySelection } from './interfaces/filter-hierarchy-selection.interface';

@Injectable()
export class HierarchyStateService {

    public state = new BehaviorSubject<Hierarchy>(Hierarchy.income_statement_mgmt_legacy);

    public current$ = this.state.asObservable();

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialHierarchieFilter: IHierarchySelection = <IHierarchySelection>{};
    private _hierarchieFilter: IHierarchySelection = <IHierarchySelection>{
        hierarchie: Hierarchy.income_statement_mgmt_legacy
    };

    public loadHierarchy(){
        this._hierarchieFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'hierarchieFilter', this._hierarchieFilter, undefined);
        this._initialHierarchieFilter = _.cloneDeep(this._hierarchieFilter);
        this.state.next(this._hierarchieFilter.hierarchie);
    }

    public changeHierarchy(newHierarchy: Hierarchy) {
        this._hierarchieFilter.hierarchie = newHierarchy;
        this._initialHierarchieFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialHierarchieFilter, this._hierarchieFilter, 'hierarchieFilter', this._userCacheService.getUserPreferences().id);
        this.state.next(newHierarchy);
    }

    public getCurrent() {
        return this.state.getValue();
    }
}
