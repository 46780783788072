import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '#environment';

import { IStatusDomain } from 'app/shared/interfaces/status-domain.interface';

@Injectable()
export class StatusService {
    impStatuslLength = 10;
    private _impStatusList = null;
    private _chassiStatusList = null;

    constructor(private http: HttpClient) {}

    getImpStatusList() {
        return this._impStatusList;
    }

    getChassiStatusList() {
        return this._chassiStatusList;
    }

    setImpDomainStatus() {
        return new Promise((resolve) => {
            this.getStatusDomain('imp').subscribe((impStatusList) => {
                this._impStatusList = impStatusList;
                resolve(impStatusList);
            });
        });
    }

    setChassiDomainStatus() {
        return new Promise((resolve) => {
            this.getStatusDomain('chassis').subscribe((chassiStatusList) => {
                this._chassiStatusList = chassiStatusList;
                resolve(chassiStatusList);
            });
        });
    }

    convertStatusToVisualDropdown(statusList) {
        const convertedStatusList = [];
        statusList.forEach((status) => {
            convertedStatusList.push({
                code: status.code,
                id: `assets/img/icons/${status.code.toLowerCase()}-checked.png`,
            });
        });
        return convertedStatusList;
    }

    private getStatusDomain(type: string): Observable<IStatusDomain[]> {
        return this.http.get<IStatusDomain[]>(`${environment.endpoints.statusService}/status-domain?type=${type}`);
    }
}
