export class GedFilter {

    private impSearch;
    private documentTypes;
    private typeMode;
    private clientGroups;

    constructor(filter?: GedFilter) {
        this.impSearch = filter ? filter.impSearch : '';
        this.typeMode = filter ? filter.typeMode : 'preDefinition';
        this.clientGroups = filter ? filter.clientGroups : [];
        this.documentTypes = filter ? filter.documentTypes : [];
    }

    public getSearch(): string { return this.impSearch; }
    public getMode(): string { return this.typeMode; }
    public getDocumentTypes(): string[] { return this.documentTypes; }
    public getClientGroups(): string[] { return this.clientGroups; }

    public setSearch(impSearch): void { this.impSearch = impSearch; }
    public setMode(typeMode): void { this.typeMode = typeMode; }
    public setDocumentTypes(documentTypes): void { this.documentTypes = documentTypes; }
    public setClientGroups(clientGroups): void { this.clientGroups = clientGroups; }

}
