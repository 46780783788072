<div *ngIf="asyncLeadTime"> <loader></loader> </div>
<div class="headerContainer" style="display: flex; align-items: center">
    <span class="analyticsName">LeadTime</span>
    <cmx-help-modal
        help-title="LeadTime"
        [help-description]="helpDescription"
    ></cmx-help-modal>
    <h5>LeadTime</h5>
</div>

<cmx-marker-filter
    id="lead-time-marker-filter"
    type="tab"
    returnBasedOn="code"
    alignment="start"
    [multiple]="false"
    [filters]="tabs"
    [leftDistance]="180"
    [preSelected]="preSelectedPreset"
    (onSelected)="changePreset($event)"
></cmx-marker-filter>

<div id="searchLine" class="reduzed" *ngIf="currentUser$ | async as user">
    <div class="input-group right-switch">
        <cmx-filter-switch
            [values]="visualSwitch.values"
            [inputIndex]="visualSwitch.values.indexOf(filterState.getVisual())"
            (onSwitch)="toggleVisualSwitch($event)"
        ></cmx-filter-switch>
        <span class="input-group-addon" style="margin: 0 -6px 0 -15px;"></span>
    </div>
    <div class="input-group">
        <cmx-dropdown
            [styles]="{ width: '180px' }"
            *ngIf="user.has('INTERNAL_FILTERS')"
            id="clients"
            name="clients"
            [data]="options.client"
            [ngModel]="filterState.getClientGroups()"
            (ngModelChange)="filterState.setClientGroups($event)"
            [settings]="dropdownSettings.clientGroups"
            (onChange)="getDropdownKPIs(true)"
        ></cmx-dropdown>
        <span class="input-group-addon" style="margin: 0 -6px 0 -15px;"></span>
    </div>
    <div class="input-group">
        <cmx-dropdown
            [styles]="{ width: '180px'}"
            name="kpi"
            [data]="options.kpiList"
            [ngModel]="filterState.getSelectedKpi()"
            (ngModelChange)="filterState.setSelectedKpi($event)"
            [settings]="dropdownSettingsKPI"
        ></cmx-dropdown>
    </div>
    <div class="input-group">
        <cmx-filter-datepicker
            id="lead-time-datepicker"
            periodType="month"
            type="range-month"
            [date]="'Finalização'"
            [startDate]="filterState.getStartDate()"
            [endDate]="filterState.getEndDate()"
            (onChangeDates)="datesChanged($event)"
            [startDateRestriction]="startDateRestriction"
            [endDateRestriction]="endDateRestriction"
        ></cmx-filter-datepicker>
    </div>
    <button (click)="loadLeadTime()" class="applyImpStatus">
        <i class="fa fa-check"></i>
    </button>
</div>

<div class="chart" id="leadTime"></div>
<div *ngIf="!asyncLeadTime" class="empty-container">
    <p *ngIf="options.kpiList.length <= 1 && filterState.getClientGroups().length > 0" class="empty-kpi">Sem KPI Cadastrada</p>
    <p *ngIf="noResultsFound && !filterState.getClientGroups().length" class="empty-client">Por favor, selecione um cliente</p>
    <p *ngIf="noResultsFound && !filterState.getSelectedKpi().length " class="empty-results">Por favor, selecione um KPI</p>
    <p *ngIf="noResultsFound && filterState.getClientGroups().length > 0 && filterState.getSelectedKpi().length > 0" class="empty-results">Não foram encontrados resultados</p>
</div>
