import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import {filter, mergeMap, combineLatest, debounceTime, switchMap} from 'rxjs/operators';
import { Subscription } from 'rxjs';
import 'moment/locale/pt-br';
import * as moment from 'moment';

import { TreeTableComponent } from '../../../layouts/treetable/treetable.component';
import { ColumnVisitor } from './column.visitor';
import { FilterSelection } from './filter-selection';
import { FilterComponent } from './filter.component';
import { NodeVisitor } from './node.visitor';
import { StructureChangedEvent } from './constants/structure-changed-event';
import { StructureProperty } from './constants/structure-property';
import { StructureSettings } from './constants/structure-settings';
import { StructureComponent } from './structure.component';

import { DownloadService } from '#services/_download/download.service';
import { Grouping, Hierarchy, Options, ResultsService } from '#services/_results/results.service';
import { UtilsService } from '#services/_utils/utils.service';
import { FilterStateService } from './filter-state.service';
import { GroupingStateService } from './grouping-state.service';
import { HierarchyStateService } from './hierarchy-state.service';
import { SortByStateService } from './sort-by-state.service';

@Component({
    selector: '.m-grid__item.m-grid__item--fluid.m-wrapper',
    templateUrl: './balance-sheet.component.html',
    styleUrls: [ '../../../../../../node_modules/primeng/resources/primeng.css', './balance-sheet.component.scss' ],
    encapsulation: ViewEncapsulation.None,
})
export class BalanceSheetComponent implements OnInit, OnDestroy {

    public percentCompleteThreshold;
    public searchString;
    public dataGrid;
    public noContent = false;
    public selectedRowIds = [];
    public asyncTreetable = false;
    public asyncExport = false;

    public firstFilter = true;

    public statusCode: number;

    public lastResults: any;

    public lastUuid: any;

    helpDescription = this._defineHelpDescription();

    @ViewChild(StructureComponent, { static: true }) public structureComponent: StructureComponent;

    @ViewChild(FilterComponent, { static: true }) public filterComponent: FilterComponent;

    @ViewChild(TreeTableComponent, { static: true }) public treetableComponent: TreeTableComponent;

    public reportType = this.getReportType(window.location.href);

    private subscriptions: Subscription[] = [];

    private financeiroFilterState: FilterSelection;
    private financeiroGroupingState: Grouping;
    private financeiroHierarchyState: Hierarchy;
    private financeiroSortByState: string;

    constructor(
        private route: ActivatedRoute,
        private resultsService: ResultsService,
        private hierarchyState: HierarchyStateService,
        private groupingState: GroupingStateService,
        private sortByState: SortByStateService,
        private filterState: FilterStateService,
        private downloadService: DownloadService,
        private utilsService: UtilsService,
        @Inject(DOCUMENT) private document: any,
    ) { }

    public openModal() {
        $('.structureModal').addClass('modal-open');
    }

    public ngOnInit() {
        const data = localStorage.getItem('financeiro-filterState');
        const isDataPresent = data !== null && data !== undefined;
        const isByImpView = this.reportType === 'byImp';

        if (isDataPresent && isByImpView) {
            this.utilsService.setInvisible('treetable', ['#myGrid']);
            this.asyncTreetable = false;
            this.firstFilter = false;
            this.callServiceAndUpdateTable();
        } else {
            $('#filter-modal').modal('show');
        }

    }

    public updateTable(data: any) {
        const settings = this.structureComponent.getActiveStructure();
        const { nodes, columns: columnsWithAmounts } = this.prepareNodes(data.results, settings);
        const columns = this.prepareColumns(data.metadata.columns, settings, columnsWithAmounts);
        this.noContent = data.results.length > 0 ? false : true;
        this.treetableComponent.loadData({
            grouping: this.grouping(),
            results: nodes,
            hierarchy: this.hierarchyState.getCurrent(),
            metadata: {
                columns,
            },
        });
        this.asyncTreetable = true;
        this.utilsService.setVisible('treetable', ['#myGrid']);
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        $('.modal-backdrop').remove();

        const data = localStorage.getItem('financeiro-filterState');
        if (data !== null && data !== undefined) {
            localStorage.removeItem('financeiro-filterState');
            localStorage.removeItem('financeiro-groupingState');
            localStorage.removeItem('financeiro-hierarchyState');
            localStorage.removeItem('financeiro-sortByState');
        }
    }

    public queryBackend(): void {
        this.utilsService.setInvisible('treetable', ['#myGrid']);
        this.asyncTreetable = false;
        this.fetchUuid(this.hierarchy(), this.grouping(), this.filter()).pipe(
            mergeMap((uuid) => this.fetchResults(uuid)))
            .subscribe(
                (data) => {
                    this.lastResults = this.clone(data);
                    this.updateTable(data);
                },
                (errorCode) => {
                    this.statusCode = errorCode;
                },
            );
    }

    public export() {
        this.asyncExport = true;

        const xls = this.resultsService.export(this.lastUuid, this.populateOptions(), this.structure());

        this.downloadService.downloadFrom(xls, `financeiro-${moment().format('YYYY-MM-DD-HHMMSS')}.xls`)
            .subscribe(() => {
                this.asyncExport = false;
            });
    }

    public changeStructure(event: StructureChangedEvent) {
        if (this.requiresBackendQuery(event.changedProperties)) {
            this.queryBackend();
        } else {
            this.updateTable(this.clone(this.lastResults));
        }
    }

    public changeFilter() {
        this.utilsService.setInvisible('treetable', ['#myGrid']);
        this.asyncTreetable = false;
        this.closeFilter();
        this.filterComponent.applyChanges();
    }

    public showFilter() {
        this.filterComponent.prepare();
        $('#filter-modal').modal('show');
    }

    public closeFilter() {
        $('#filter-modal').modal('hide');
    }

    public closeStructure() {
        $('#structure').collapse('hide');
    }

    firstState() {
        this.firstFilter = false;
        const hierarchyAndGrouping$ = this.hierarchyState.current$.pipe(
            combineLatest(this.groupingState.current$, (hierarchy, grouping) => [hierarchy, grouping]));
        const withFilter$ = hierarchyAndGrouping$.pipe(
            combineLatest(this.filterState.current$.pipe(
                filter((value) => value != null)), (hierarchyAndGrouping: any[], filter: any) => {
                    return [
                        hierarchyAndGrouping[0],
                        hierarchyAndGrouping[1],
                        filter,
                    ];
                }));
        const uuid$ = withFilter$.pipe(debounceTime(100),switchMap((values) => {
            const [hierarchy, grouping, filter] = values;
            return this.fetchUuid(hierarchy, grouping, filter);
        }),);
        const actualResults$ = uuid$.pipe(switchMap((uuid) => this.fetchResults(uuid)));
        this.changeFilter();
        this.subscriptions.push(actualResults$.subscribe((data) => {
            this.lastResults = this.clone(data);
            this.updateTable(data);
        }, (errorCode) => {
            this.statusCode = errorCode;
        }));
    }

    private fetchUuid(
        hierarchy: Hierarchy,
        grouping: Grouping,
        filter: FilterSelection,
    ) {
        return this.resultsService.createResultsQuery(
            hierarchy,
            grouping,
            this._checkBySortRules(hierarchy, grouping),
            filter.toServiceFilter(),
        );
    }

    private fetchResults(uuid: string) {
        this.lastUuid = uuid;
        const options = this.populateOptions();
        return this.resultsService.getResultsByUUID(uuid, options);
    }

    private requiresBackendQuery(properties: StructureProperty[]) {
        return properties.find(
            (it) =>
                it === StructureProperty.accumulateBalance ||
                it === StructureProperty.periodGrouping ||
                it === StructureProperty.groupingClients,
        );
    }

    private prepareColumns(columns: any[], settings: StructureSettings, columnsWithAmounts: Set<string>) {
        return new ColumnVisitor(this.grouping(), settings, columnsWithAmounts).visit(columns);
    }

    private prepareNodes(nodes: any[], settings: StructureSettings) {
        return new NodeVisitor(settings).visit(nodes, []);
    }

    private filter() {
        return this.filterState.getCurrent() || new FilterSelection();
    }

    private populateOptions() {
        const settings = this.structure();
        const options = new Options();
        options.accumulateBalance = settings.accumulateBalance;
        options.periodGrouping = settings.periodGrouping;
        options.clientGrouping = settings.groupingClients;
        return options;
    }

    private hierarchy() {
        return this.hierarchyState.getCurrent();
    }

    private grouping() {
        return this.groupingState.getCurrent();
    }

    private sortBy() {
        return this.sortByState.getCurrent();
    }

    private structure() {
        return this.structureComponent.getActiveStructure();
    }

    private clone(obj: any) {
        return JSON.parse(JSON.stringify(obj));
    }

    private getReportType(url) {
        const urlSplited = url.split('/');
        return urlSplited[urlSplited.length - 1];
    }

    private getLocalStorageData() {
        const currentImpCode = this.route.snapshot.params.impCode;
        this.financeiroFilterState = JSON.parse(localStorage.getItem(`${currentImpCode}-financeiro-filterState`));
        this.financeiroGroupingState = JSON.parse(localStorage.getItem(`${currentImpCode}-financeiro-groupingState`));
        this.financeiroHierarchyState = JSON.parse(localStorage.getItem(`${currentImpCode}-financeiro-hierarchyState`));
        this.financeiroSortByState = JSON.parse(localStorage.getItem(`${currentImpCode}-financeiro-sortByState`));
    }

    private callServiceAndUpdateTable() {
        this.getLocalStorageData();
        this.hierarchyState.changeHierarchy(this.financeiroHierarchyState);
        this.groupingState.changeGrouping(this.financeiroGroupingState);
        this.sortByState.changeSortBy(this.financeiroSortByState);

        const filterStateToService = new FilterSelection();
        filterStateToService.imps = this.financeiroFilterState.imps;
        filterStateToService.startDate = this.financeiroFilterState.startDate;
        filterStateToService.endDate = this.financeiroFilterState.endDate;
        filterStateToService.director = this.financeiroFilterState.director;
        filterStateToService.companies = this.financeiroFilterState.companies;
        filterStateToService.trader = this.financeiroFilterState.trader;
        filterStateToService.currency = this.financeiroFilterState.currency;
        filterStateToService.account = this.financeiroFilterState.account;
        filterStateToService.profitCenter = this.financeiroFilterState.profitCenter;
        filterStateToService.client = this.financeiroFilterState.client;

        this.filterState.changeFilter(filterStateToService);

        $('#periodFrom').val(filterStateToService.startDate);
        $('#periodTo').val(filterStateToService.endDate);

        this.filterComponent.overrideState(
            this.financeiroHierarchyState,
            this.financeiroGroupingState,
            this.financeiroSortByState,
            this.financeiroFilterState,
        );

        const uuid$ = this.fetchUuid(this.financeiroHierarchyState, this.financeiroGroupingState, filterStateToService);
        const actualResults$ = uuid$.pipe(switchMap((uuid) => this.fetchResults(uuid)));

        this.subscriptions.push(actualResults$.subscribe((data) => {
            this.lastResults = this.clone(data);
            this.updateTable(data);
            $('#filterDiv').hide();
        }, (errorCode) => {
            this.statusCode = errorCode;
        }));
    }

    private _defineHelpDescription(): object[] {
        return [
            {
                type: 'text',
                value: 'A tela tem por função gerar relatórios personalizados, ao acessar a tela é possível configuração do relatório desejado.',
            },
            {
                type: 'list',
                list: 'Hierarquia: Gerencial, Contábil e Balancete',
            },
            {
                type: 'list',
                list: 'Contexto: Por empresa, Período, CR, Diretor, Cliente, Trader, IMP',
            },
            {
                type: 'list',
                list: 'Filtros por Período',
            },
            {
                type: 'text',
                value: 'Em mais filtros é possível ainda personalizar o relatório por: Empresa, CR, Tipos de Resultados, Diretor, Trader ou cliente.'
            }
        ]
    }

    _checkBySortRules(hierarchy, grouping) {
        if (hierarchy != 'income-statement-mgmt' && grouping != 'byClient') {
            this.sortByState.changeSortBy('');
        }

        return this.sortBy();
    }
}
