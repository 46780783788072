import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cmxMask',
})
export class CmxMaskPipe implements PipeTransform {

    private readonly DECIMAL_SEPARATOR = '.';
    private readonly GROUP_SEPARATOR = ',';
    private maskedDocNumber: any;

    public transform(docNumber: string, docNumberType: string): any {
        return this.mask(docNumber, docNumberType);
    }

    private unFormat(docNumberFormatted: string) {
        if (!docNumberFormatted) {
            return '';
        }

        docNumberFormatted = docNumberFormatted.replace(/\D/g, '');

        return this.GROUP_SEPARATOR === ',' ? docNumberFormatted.replace(/,/g, '') :
            docNumberFormatted.replace(/\./g, '');
    }

    private maskCpf(unmaskedCpf: string) {
        return unmaskedCpf.replace(/\D/g, '')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    }

    private maskCnpj(unmaskedCnpj: string) {
        return unmaskedCnpj.replace(/\D/g, '')
            .replace(/^(\d{2})(\d)/, '$1.$2')
            .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
            .replace(/\.(\d{3})(\d)/, '.$1/$2')
            .replace(/(\d{4})(\d)/, '$1-$2');
    }

    private mask(docNumber: string, docNumberType: string) {
        if (!docNumber) {
            return '';
        }

        const arrDocNumber = this.unFormat(docNumber.toString()).split(this.DECIMAL_SEPARATOR);
        this.maskedDocNumber = 'CPF' === docNumberType.toUpperCase() ?
            this.maskCpf(arrDocNumber[0]) : this.maskCnpj(arrDocNumber[0]);

        return this.maskedDocNumber;
    }
}
