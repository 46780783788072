import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'vehicle-svg',
    templateUrl: './vehicle-svg.html',
    styleUrls: ['./vehicle-svg.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VehicleSVGComponent implements OnInit {

    @Output() public ufSelection = new EventEmitter();

    private readonly UF_NAME_SIZE = 2;
    private readonly UF_TAG = 'brazil-map a';

    private ufName: any;

    public ngOnInit(): void {
        $(document).ready((e) => {
        $(this.UF_TAG).off('click');
        $(this.UF_TAG).on('click', (el) => {
            if ($(el.target.nextElementSibling).is('text')) {
                this.ufName = el.target;
                this.ufChanged();
            }
        });
        });
    }

    public ngOnDestroy() {
        $(this.UF_TAG).unbind('click');
    }

    public ufChanged() {
        this.ufSelection.emit(this.ufName);
    }
}
