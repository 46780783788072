<cmx-page-wrapper
    layout="bigHeader"
    [titleComponent]="{ title: pageTabControlService.currentPageTitle }"
    [tabs]="pageConfig.tabs"
    [tab-blur]="pageTabControlService.currentTabSelected"
    [switch]="pageTabControlService.currentSwitches"
    [selectedSwitch]="pageTabControlService.selectedSwitch"
    (onSelectTab)="pageTabControlService.onNavigate($event, null, true)"
    (onSwitchSelect)="pageTabControlService.onSwitchSelect($event)">
    <ng-container cmx-page-wrapper-body #bodyContent></ng-container>
</cmx-page-wrapper>
