import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
    selector: 'cmx-toggle-switch',
    templateUrl: './toggle-switch.component.html',
    styleUrls: ['./toggle-switch.component.scss'],
})
export class ToggleSwitchComponent implements OnChanges {
    @Input() items: [string, string];
    @Input() viewItems: [string, string];
    @Input() selectedItem: string;
    @Output() onSelected: EventEmitter<string>;

    constructor() {
        this.onSelected = new EventEmitter();
    }

    ngOnChanges(): void {
        if (!this.items || (this.items && !this.items.length)) {
            throw new Error('[ToggleSwitchComponentError] must have items to render toggle switch component.');
        }

        this.viewItems = this.viewItems || this.items;
        this.selectedItem = this.selectedItem || this.items[0];
    }

    firstItemIsSelected(): boolean {
        return this.selectedItem === this.items[0];
    }

    onSelect(): void {
        this.selectedItem = this.firstItemIsSelected() ? this.items[1] : this.items[0];
        this.onSelected.emit(this.selectedItem);
    }
}
