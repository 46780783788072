<div *ngIf="!asyncDetail" class="fullLoader">
    <loader></loader>
</div>

<div id="import-details-boarding-container" *ngIf="asyncDetail">
    <div id="import-details-boarding-content" *ngIf="dataLoaded">

        <div class="detailTitle embarque">
            <img src="./assets/app/media/img/icons/embarque-checked.png" alt="" />
            <span>Embarque</span>
        </div>
        <cmx-assembled-data [data]="boardingDetails" [maxRowElements]="4"></cmx-assembled-data>

        <hr />

        <div class="detailTitle atracacao">
            <img src="./assets/app/media/img/icons/atracamento.png" alt="" />
            <span>Atracação</span>
        </div>
        <cmx-assembled-data [data]="arrivalDetails" [maxRowElements]="4"></cmx-assembled-data>

        <div *ngIf="currentUser.has('INTERNAL_FILTERS')">
            <hr />

            <cmx-assembled-data [data]="othersDetails" [maxRowElements]="4" (onClick)="onContainerClick($event)"></cmx-assembled-data>
        </div>

    </div>
</div>

<cmx-custom-dialog
    [id]="'import-details-boarding-container-dialog'"
    [title]="'Contâiner'"
    animation="right-left"
    [internalFade]="true"
    [closeButton]="true"
    [overlay]="true"
    [widthModal]="30"
    (modal-closed)="showBoardingContainerModal = false">
    <ng-container cmx-custom-dialog-body>
        <div class="import-details-boarding-container-dialog-container" *ngIf="showBoardingContainerModal && containerList.length">
            <cmx-custom-grid
                [id]="'import-details-boarding-container-grid'"
                [columns]="gridContainerColumns"
                [rows]="containerList"
                [options]="{ forceFitColumns: true }"
                [styles]="{ 'max-height': 'calc(100vh - 330px)', 'margin-top': '0px'}"
            ></cmx-custom-grid>
        </div>
    </ng-container>
</cmx-custom-dialog>
