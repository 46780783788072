import { Injectable } from '@angular/core';

@Injectable()
export class FollowUpReportChassiFormatter {
    public formatChassi(row, cell, value, columnDef, dataContext) {
        return `<span class='ChassiValue'>${value}</span>`;
    }

    public formatGrossWeight(row, cell, value, columnDef, dataContext) {
        if (value && Array.isArray(value)) {
            const nonEmptyValues = value.filter(item => item[columnDef.field] !== null && item[columnDef.field] !== '');
            if (nonEmptyValues.length === 1) {
                value = nonEmptyValues[0][columnDef.field]
            } else if(nonEmptyValues.length > 0) {
                return `<span class="multiple">Vários (${value.length})</span>`;
            } else {
                return '—'
            }
        }
        if (value) {
            if (value.items && Array.isArray(value.items)) {
                return value.items[0].grossWeight || '';
            }
        }
        return '—';
    }

    public formatNcm(row, cell, value, columnDef, dataContext) {
        if (value && Array.isArray(value)) {
            const nonEmptyValues = value.filter(item => item[columnDef.field] !== null && item[columnDef.field] !== '');
            if (nonEmptyValues.length === 1) {
                value = nonEmptyValues[0][columnDef.field]
            } else if(nonEmptyValues.length > 0) {
                return `<span class="multiple">Vários (${value.length})</span>`;
            } else {
                return '—'
            }
        }
        if (value) {
            if (value.items && Array.isArray(value.items)) {
                return value.items[0].ncm || '';
            }
        }
        return '—';
    }

    public formatSupplierSku(row, cell, value, columnDef, dataContext) {
        if (value && Array.isArray(value)) {
            const nonEmptyValues = value.filter(item => item[columnDef.field] !== null && item[columnDef.field] !== '');
            if (nonEmptyValues.length === 1) {
                value = nonEmptyValues[0][columnDef.field]
            } else if(nonEmptyValues.length > 0) {
                return `<span class="multiple">Vários (${value.length})</span>`;
            } else {
                return '—'
            }
        }
        if (value) {
            if (value.items && Array.isArray(value.items)) {
                return value.items[0].supplierSku || '';
            }
        }
        return '—';
    }

    public formatCustomerSku(row, cell, value, columnDef, dataContext) {
        if (value && Array.isArray(value)) {
            const nonEmptyValues = value.filter(item => item[columnDef.field] !== null && item[columnDef.field] !== '');
            if (nonEmptyValues.length === 1) {
                value = nonEmptyValues[0][columnDef.field]
            } else if(nonEmptyValues.length > 0) {
                return `<span class="multiple">Vários (${value.length})</span>`;
            } else {
                return '—'
            }
        }
        return value?.items?.[0]?.customerSku || '—';
    }

    public formatWeight(row, cell, value, columnDef, dataContext) {
        if (value && Array.isArray(value)) {
            const nonEmptyValues = value.filter(item => item[columnDef.field] !== null && item[columnDef.field] !== '');
            if (nonEmptyValues.length === 1) {
                value = nonEmptyValues[0][columnDef.field]
            } else if(nonEmptyValues.length > 0) {
                return `<span class="multiple">Vários (${value.length})</span>`;
            } else {
                return '—'
            }
        }
        if (value) {
            if (value.items && Array.isArray(value.items)) {
                return value.items[0].weight || '';
            }
        }
        return '—';
    }

    public formatProductCode(row, cell, value, columnDef, dataContext) {
        if (value && Array.isArray(value)) {
            const nonEmptyValues = value.filter(item => item[columnDef.field] !== null && item[columnDef.field] !== '');
            if (nonEmptyValues.length === 1) {
                value = nonEmptyValues[0][columnDef.field]
            } else if(nonEmptyValues.length > 0) {
                return `<span class="multiple">Vários (${value.length})</span>`;
            } else {
                return '—'
            }
        }
        if (value) {
            if (value.items && Array.isArray(value.items)) {
                return value.items[0].productCode || '';
            }
        }
        return '—';
    }

    public formatClientName(row, cell, value, columnDef, dataContext) {
        if (value && Array.isArray(value)) {
            const nonEmptyValues = value.filter(item => item[columnDef.field] !== null && item[columnDef.field] !== '');
            if (nonEmptyValues.length === 1) {
                value = nonEmptyValues[0][columnDef.field]
            } else if(nonEmptyValues.length > 0) {
                return `<span class="multiple">Vários (${value.length})</span>`;
            } else {
                return '—'
            }
        }
        if (value?.toLowerCase().includes('toyota')) {
            return `<img style="height:100%" src="assets/img/icons/logos/toyotaLogo.png"
            title='${value}'/> Toyota`;
        } else if (value?.toLowerCase().includes('mercedes')) {
            return `<img style="height:100%" src="assets/img/icons/logos/mercedesLogo.png"
            title='${value}'/> Mercedes`;
        } else if (value?.toLowerCase().includes('volvo')) {
            return `<img style="height:100%" src="assets/img/icons/logos/volvoLogo.png"
            title='${value}'/> Volvo`;
        } else {
            return value;
        }
    }

    public formatColor(row, cell, value, columnDef, dataContext) {
        if (value && Array.isArray(value)) {
            const nonEmptyValues = value.filter(item => item[columnDef.field] !== null && item[columnDef.field] !== '');
            if (nonEmptyValues.length === 1) {
                value = nonEmptyValues[0][columnDef.field]
            } else if(nonEmptyValues.length > 0) {
                return `<span class="multiple">Vários (${value.length})</span>`;
            } else {
                return '—'
            }
        }
        if (value) {
            const colorClass = value?.toLowerCase().replace('-', ' ');
            return `<div class='colorBallContainer'><div class='colorBall ${colorClass}'></div>${value}</div>`;
        }
        return '—'
    }

    public formatBlock(row, cell, value, columnDef, dataContext) {
        if (value && Array.isArray(value)) {
            const nonEmptyValues = value.filter(item => item[columnDef.field] !== null && item[columnDef.field] !== '');
            if (nonEmptyValues.length === 1) {
                value = nonEmptyValues[0][columnDef.field]
            } else if(nonEmptyValues.length > 0) {
                return `<span class="multiple">Vários (${value.length})</span>`;
            } else {
                return '—'
            }
        }
        if (value) {
            return `<span class='formatBlock ${value}'>${value}</span>`;
        }
        return '—';
    }

    public formatDamages(row, cell, value, columnDef, dataContext) {
        if (value === null || value === undefined || value?.length === 0) {
            return '—';
        } else {
            let fixed = true;
            value.forEach((damage) => {
                if (damage.fixDate === null || damage.fixDate === undefined) {
                    fixed = false;
                }
            });
            if (fixed) {
                return `<div class="multipleDamages ball true">
                <span>${value?.length}</span>
                <i class="fa fa-check"></i>
                </div>`;
            } else {
                return `<div class="multipleDamages ball true">
                <span>${value?.length}</span></div>`;
            }
        }
    }

    public formatAbsences(row, cell, value, columnDef, dataContext) {
        if (value === null || value === undefined || value?.length === 0) {
            return '—';
        } else {
            let fixed = true;
            value.forEach((damage) => {
                if (damage.fixDate === null || damage.fixDate === undefined) {
                    fixed = false;
                }
            });
            if (fixed) {
                return `<div class="multipleAbsences ball true">
                <span>${value?.length}</span>
                <i class="fa fa-check"></i>
                </div>`;
            } else {
                return `<div class="multipleAbsences ball true">
                <span>${value?.length}</span></div>`;
            }
        }
    }

    // Descrição do Produto
    public formatProductDescription(row, cell, value, columnDef, dataContext) {
        if (value && Array.isArray(value)) {
            const nonEmptyValues = value.filter(item => item[columnDef.field] !== null && item[columnDef.field] !== '');
            if (nonEmptyValues.length === 1) {
                value = nonEmptyValues[0][columnDef.field]
            } else if(nonEmptyValues.length > 0) {
                return `<span class="multiple">Vários (${value.length})</span>`;
            } else {
                return '—'
            }
        }
        if (value) {
            if (value.items && Array.isArray(value.items)) {
                return value.items[0].productDescription || '';
            }
        }
        return '—';
    }

    // Descrição Detalhada de Produto
    public formatProductDescriptionDetail(row, cell, value, columnDef, dataContext) {
        if (value && Array.isArray(value)) {
            const nonEmptyValues = value.filter(item => item[columnDef.field] !== null && item[columnDef.field] !== '');
            if (nonEmptyValues.length === 1) {
                value = nonEmptyValues[0][columnDef.field]
            } else if(nonEmptyValues.length > 0) {
                return `<span class="multiple">Vários (${value.length})</span>`;
            } else {
                return '—'
            }
        }
        if (value) {
            if (value.items && Array.isArray(value.items)) {
                return value.items[0].productDescriptionDetail || '';
            }
        }
        return '—';
    }
}
