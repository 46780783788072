import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ISwitchOption } from './cmx-switch.interface';

@Component({
    selector: 'cmx-switch',
    templateUrl: './cmx-switch.component.html',
    styleUrls: ['./cmx-switch.component.scss'],
})
export class SwitchComponent {
    @Input() options: ISwitchOption[];
    @Input() selected: string;

    @Output('onSelect') onSelect = new EventEmitter();

    selectOption(option) {
        if (option.name !== this.selected && !option.disabled) {
            this.onSelect.emit(option);
        }
    }
}
