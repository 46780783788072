declare let moment: any;

export const statuses = {
    1: {
        desc: 'Pedido',
        label: 'info'
    },
    2: {
        desc: 'A Embarcar',
        label: 'metal'
    },
    3: {
        desc: 'Em Trânsito',
        label: 'warning'
    },
    4: {
        desc: 'Em Registro',
        label: 'warning'
    },
    5: {
        desc: 'A Faturar',
        label: 'primary'
    },
    6: {
        desc: 'Em Entrega',
        label: 'brand'
    },
    7: {
        desc: 'Entregue',
        label: 'success'
    }
};

export const generateData = function() {

    var qtd = 100;
    var data = [];

    function addDays(date: Date, qtd: number) {
        return new Date(date.getTime() + (qtd * 24 * 3600 * 1000));
    }

    var suppliers = [
        'ZHEJIANG ANJI HUIYE FURNITURE CO., LTD',
        'INTERNATIONAL TRADING COMPANY',
        'HUZHOU HOMELAND I/E CO., LTD',
        'SOURCEBYNET PTE LTD',
        'ROBERT MARK FAR EAST CO.,LTD.',
        'SHAOXING SHANGYU HENGDA LIGHTING CO., LTD',
        'LY-ECO'
    ];

    let divisions = ['Cabelos', 'Coloração', 'Pele', 'Protetor Solar'];

    let products = {
        'Cabelos': [
            'Elseve Anticaspa',
            'Elseve Arginina',
            'Elseve Citrus',
            'Elseve Colorvive',
            'Elseve Hydra-Detox',
            'Elseve Hydra-Max Colágeno',
            'Elseve Light-Poo',
            'Elseve Óleo Extraordinário',
            'Elseve Quera-Liso Leve E Sedoso',
            'Elseve Supreme Control 4D',
            'Elseve Reparação Total 5+',
        ],
        'Coloração': [
            'Casting Creme Gloss',
            'Imédia Excellence',
            'Imédia Excellence Fashion Paris',
            'Imédia Excellence Ice Colors',
            'Magic Retouch',
        ],
        'Pele': [
            'Idade Expert',
            'BB Cream',
            'Revitalift',
            'Água Micelar',
            'Pure Zone',
            'Argila Pura',
            'Limpeza Express',
        ],
        'Protetor Solar': [
            'Linha Corporal',
            'Linha Facial',
        ]
    };

    for (var i = 0; i < qtd; i++) {

        var imp = Math.round(Math.random() * 100000) + '-00';
        var supplier = Math.round(Math.random() * 10) % suppliers.length;
        var status = (Math.round(Math.random() * 10) % 7) + 1;
        var division = divisions[Math.round(Math.random() * 100) % divisions.length]
        var product = products[division][Math.round(Math.random() * 100) % products[division].length];

        // Order
        var orderDate = addDays(new Date(), -(Math.round(Math.random() * 100) % 30));
        var issueDate = addDays(orderDate, (Math.round(Math.random() * 10) % 4));
        var proformaDate = orderDate;

        // Shipping
        var shipping_auth_date = addDays(issueDate, (Math.round(Math.random() * 10)));
        var shipping_invoice_amount = Math.round(Math.random() * 1000000) / 100;

        data.push({
            imp: imp,
            division: division,
            product: product,
            operation: 'CVE',
            supplier: suppliers[supplier],
            status: status,
            order_destination: 'Navegantes',
            order_date: orderDate,
            order_issueDate: issueDate,
            order_proformaDate: proformaDate,
            shipping_auth_date: shipping_auth_date,
            shipping_incoter: "FOB",
            shipping_currency: "USD",
            shipping_invoice_amount: shipping_invoice_amount
        });
    }

    return data;

};

export const format = function(date: Date) {
    return `${moment(date).format("DD/MM/YYYY")}`
}

export const formatMoney = function(n: number, c: number, d: string, t: string) {
    var nn = "",
        c = isNaN(c = Math.abs(c)) ? 2 : c,
        d = d == undefined ? "." : d,
        t = t == undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = String(parseInt(nn = Math.abs(Number(n) || 0).toFixed(c))),
        ni = Number(i),
        j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - ni).toFixed(c).slice(2) : "");
};

export const layouts = {
    "Pedido": [{
        field: "imp",
        title: "Processo",
        selector: false,
        textAlign: "right",
    }, {
        field: "division",
        title: "Divisão",
    }, {
        field: "product",
        title: "Produto"
    }, {
        field: "operation",
        title: "Operação",
        textAlign: "center"
    }, {
        field: "order_destination",
        title: "Destino",
    }, {
        field: "order_date",
        title: "Rec. Pedido",
        template: function(row) {
            return `${format(row.order_date)}`;
        },
        textAlign: "center"
    }, {
        field: "order_issueDate",
        title: "Ped. Comexport",
        template: function(row) {
            return `${format(row.order_date)}`;
        },
        textAlign: "center"
    }, {
        field: "order_proformaDate",
        title: "Rec. Pro Forma",
        template: function(row) {
            return `${format(row.order_date)}`;
        },
        textAlign: "center"
    }],
    "A Embarcar": [{
        field: "imp",
        title: "Processo",
        selector: false,
        textAlign: "right",
    }, {
        field: "division",
        title: "Divisão",
    }, {
        field: "product",
        title: "Produto"
    }, {
        field: "operation",
        title: "Operação",
        textAlign: "center"
    }, {
        field: "shipping_auth_date",
        title: "Aut. Embarque",
        template: function(row) {
            return `${format(row.shipping_auth_date)}`;
        },
        textAlign: "center"
    }, {
        field: "shipping_currency",
        title: "Moeda",
        textAlign: "center"
    }, {
        field: "shipping_invoice_amount",
        title: "Valor Invoice",
        textAlign: "right",
        template: function(row) {
            return `${formatMoney(row.shipping_invoice_amount, 2, ",", ".")}`;
        }
    }],
    "Trânsito": [

    ],
    "Em Registro": [

    ],
    "Registrado": [

    ],
    "Faturamento e Entrega": [

    ]
};
