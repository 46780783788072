export class ListItem {
    id: any;
    itemName: string;
    amount?: number;
    currency?: number;
    value?: any;
    key?: any;
    disabled?: boolean;
    disabledInfo?: string;
    info?: string;
}
export class MyException {
    status: number;
    body: any;
    constructor(status: number, body: any) {
        this.status = status;
        this.body = body;
    }

}
export class DropdownOption {
    constructor(
        readonly id: any,
        readonly itemName: string,
        readonly noCodeItemName?,
        readonly statusEntityName?,
        readonly code?,
        readonly email?,
        readonly amount?,
        readonly currency?,
        readonly disabled?,
        readonly disabledInfo?,
    ) { }
}
