<cmx-custom-dialog [id]="id" content="middle">
    <ng-container cmx-custom-dialog-header>
        <ul *ngIf="!loading" class="header-content">
            <li>
                <ng-container *ngIf="isInternalUser">
                    <span>Cliente:</span>
                    <span
                        class="list-item-value client"
                        [tooltip]="headerInfo.clientGroupName"
                        [checkEllipsisActive]="true"
                        >{{ headerInfo.clientGroupName }}
                    </span>
                    <span class="separator">|</span>
                </ng-container>
                <span>IMP`s:</span>
                <span class="list-item-value">{{ headerInfo.totalImps }}</span>
            </li>
            <li>
                <ng-container
                    *ngIf="
                        headerInfo.type === 'FREE_TIME' ||
                        headerInfo.type === 'REGISTRATION_DEADLINE' ||
                        headerInfo.type === 'STORAGE_LIMIT'
                    "
                >
                    <span>Containers:</span>
                    <span class="list-item-value">{{ headerInfo.totalContainers }}</span>
                </ng-container>

                <ng-container
                    *ngIf="
                        headerInfo.type === 'DEFERMENT_LI' ||
                        headerInfo.type === 'BOARDING_LIMIT' ||
                        headerInfo.type === 'RESCHEDULE_CLEARANCE'
                    "
                >
                    <span>Qtd. Licenças:</span>
                    <span class="list-item-value">{{ headerInfo.totalLicenses }}</span>
                </ng-container>

                <span class="separator" *ngIf="headerInfo.type !== 'DOCUMENT_DELIVERY'">|</span>
                <span>Dias p/ vencer:</span>
                <span class="list-item-value">{{ headerInfo.dueDays }}</span>
            </li>
        </ul>
    </ng-container>
    <ng-container cmx-custom-dialog-body>
        <ng-container *ngIf="loading">
            <loader></loader>
        </ng-container>
        <ng-container *ngIf="!loading">
            <cmx-custom-grid
                [loading]="loading"
                [columns]="gridColumns"
                [rows]="gridRows"
                [options]="{ forceFitColumns: true }"
                [styles]="{ height: 'calc(100% - 20px)', 'margin-top': 0 }"
                (onCallback)="onGridCallback($event)"
            ></cmx-custom-grid>
        </ng-container>
    </ng-container>
</cmx-custom-dialog>


<alert-client-details-dialog></alert-client-details-dialog>
