import { AppModule } from 'app/app.module';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { ITopLiRegistrationFilter } from './interface/top-li-registration.interface';
import moment from 'moment';

export class TopLiRegistrationFilter {
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private startDate: string;
    private endDate: string;

    private _initialTopLiRegistrationFilter: ITopLiRegistrationFilter = <ITopLiRegistrationFilter>{};
    private _topLiRegistrationFilter: ITopLiRegistrationFilter = <ITopLiRegistrationFilter>{
        clientGroups: []
    };


    constructor(filter?: TopLiRegistrationFilter) {
        this._topLiRegistrationFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'topLiRegistrationFilter', this._topLiRegistrationFilter, filter);
        this._initialTopLiRegistrationFilter = _.cloneDeep(this._topLiRegistrationFilter);
        this.startDate = moment().add(-11, 'M').format('MM/YYYY');
        this.endDate = moment().format('MM/YYYY');
    }

    getClientGroups(): string[] { return this._topLiRegistrationFilter.clientGroups; }
    getStartDate(): string { return this.startDate; }
    getEndDate(): string { return this.endDate; }

    setClientGroups(clientGroups): void { this._topLiRegistrationFilter.clientGroups = clientGroups; }
    setStartDate(startDate): void { this.startDate = startDate; }
    setEndDate(endDate): void { this.endDate = endDate; }

    updatePreferences() {
        this._initialTopLiRegistrationFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialTopLiRegistrationFilter, this._topLiRegistrationFilter, 'topLiRegistrationFilter', this._userCacheService.getUserPreferences().id);
    }
}
