import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { GroupingStateService } from './grouping-state.service';
import { HierarchyStateService } from './hierarchy-state.service';
import { StructureChangedEvent } from './constants/structure-changed-event';
import { StructureProperty } from './constants/structure-property';
import { StructureSettings } from './constants/structure-settings';

@Component({
    selector: 'cmx-results-structure',
    templateUrl: './structure.component.html',
    styleUrls: ['./structure.component.scss'],
})
export class StructureComponent implements OnDestroy {

    public readonly options = defaultOptions;

    public readonly help = {
        zeroedNodes: defaultOptions.zeroedNodes.false.example,
        amountsFormat: defaultOptions.amountsFormat[2].example,
        periodGrouping: defaultOptions.periodGrouping[0].example,
    };

    public filterOptionsGroupingClient: boolean = false;

    @Output() public onChange = new EventEmitter<StructureChangedEvent>();

    @Output() public onCancel = new EventEmitter<void>();

    public editableStructure = new StructureSettings();

    private activeStructure = this.editableStructure.clone();

    private subscriptions: Subscription[] = [];

    constructor(
        private hierarchyState: HierarchyStateService,
        private groupingState: GroupingStateService) {

        this.subscriptions.push(hierarchyState.current$.subscribe(
            (hierarchy) => this.onHierarchyChange(hierarchy),
        ));

        this.subscriptions.push(groupingState.current$.subscribe(
            (grouping) => this.onGroupingChange(grouping),
        ));
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    public applyChanges() {
        const changedProperties = this.changedPropertiesArray();
        this.activeStructure = this.editableStructure.clone();
        $('.structureModal').removeClass('modal-open');
        this.onChange.emit(new StructureChangedEvent(this.activeStructure, changedProperties));
    }

    public cancel() {

        // resets help texts
        if (this.hasChanged((it) => it.displayAccountCodes)) {
            this.toggleAccountCodes();
        }

        if (this.hasChanged((it) => it.displayZeroedNodes)) {
            this.toggleZeroedNodes();
        }

        if (this.hasChanged((it) => it.unknownLines)) {
            this.toggleUnknownLines();
        }

        if (this.hasChanged((it) => it.accumulateBalance)) {
            this.toggleAccumulateBalance();
        }

        if (this.hasChanged((it) => it.columnTotal)) {
            this.toggleColumnTotal();
        }

        if (this.hasChanged((it) => it.groupingClients)) {
            this.toggleShowGroupingClients();
        }

        this.editableStructure = this.activeStructure.clone();
        this.onCancel.emit(null);
    }

    public changePeriodGrouping(grouping: string, helpText: string) {
        this.editableStructure.periodGrouping = grouping;
        this.help.periodGrouping = helpText;
    }

    public showPeriodGrouping() {
        const activeGrouping = this.groupingState.getCurrent();
        return activeGrouping === 'byMonth';
    }

    public changeAmountsFormat(format: string, helpText: string) {
        this.editableStructure.amountsFormat = format;
        this.help.amountsFormat = helpText;
    }

    public toggleShowGroupingClients() {
        const currentValue = this.editableStructure.groupingClients;
        this.editableStructure.groupingClients = !currentValue;
    }

    public toggleAccountCodes() {
        const currentValue = this.editableStructure.displayAccountCodes;
        this.editableStructure.displayAccountCodes = !currentValue;
    }

    public toggleZeroedNodes() {
        this.editableStructure.displayZeroedNodes = !this.editableStructure.displayZeroedNodes;
        this.help.zeroedNodes = (this.editableStructure.displayZeroedNodes)
            ? defaultOptions.zeroedNodes.true.example
            : defaultOptions.zeroedNodes.false.example;
    }

    public toggleUnknownLines() {
        const currentValue = this.editableStructure.unknownLines;
        this.editableStructure.unknownLines = (currentValue === 'showUnknownLines')
            ? 'hideUnknownLines' : 'showUnknownLines';
    }

    public toggleAccumulateBalance() {
        this.editableStructure.accumulateBalance = !this.editableStructure.accumulateBalance;
    }

    public showAccumulateBalance() {
        const activeHierarchy = this.hierarchyState.getCurrent();
        const activeGrouping = this.groupingState.getCurrent();
        return (activeHierarchy === 'income-statement'
            || activeHierarchy === 'income-statement-mgmt'
            || activeHierarchy === 'income-statement-mgmt-legacy')
            && activeGrouping === 'byMonth';
    }

    public toggleColumnTotal() {
        const currentValue = this.editableStructure.columnTotal;
        this.editableStructure.columnTotal = (currentValue === 'showColumnTotal')
            ? 'hideColumnTotal' : 'showColumnTotal';
    }

    public onHierarchyChange(hierarchy: string) {
        this.refresh(hierarchy, this.groupingState.getCurrent());
    }

    public onGroupingChange(grouping: string) {
        this.refresh(this.hierarchyState.getCurrent(), grouping);
    }

    public getActiveStructure(): StructureSettings {
        return this.activeStructure;
    }

    private refresh(hierarchy: string, grouping: string) {
        hierarchyDefaults[hierarchy](this.activeStructure);

        if (!this.showAccumulateBalance()) {
            this.activeStructure.accumulateBalance = null;
        }

        if (!this.showPeriodGrouping()) {
            this.activeStructure.periodGrouping = 'month';
        }

        if (grouping !== 'byClient') {
            this.filterOptionsGroupingClient = false;
        } else {
            this.filterOptionsGroupingClient = true;
        }

        this.editableStructure = this.activeStructure.clone();
    }

    private changedPropertiesArray() {
        const changedProperties = [];

        if (this.hasChanged((it) => it.amountsFormat)) {
            changedProperties.push(StructureProperty.amountsFormat);
        }

        if (this.hasChanged((it) => it.displayAccountCodes)) {
            changedProperties.push(StructureProperty.displayAccountCodes);
        }

        if (this.hasChanged((it) => it.displayZeroedNodes)) {
            changedProperties.push(StructureProperty.displayZeroedNodes);
        }

        if (this.hasChanged((it) => it.unknownLines)) {
            changedProperties.push(StructureProperty.uknownLines);
        }

        if (this.hasChanged((it) => it.columnTotal)) {
            changedProperties.push(StructureProperty.columnTotal);
        }

        if (this.hasChanged((it) => it.accumulateBalance)) {
            changedProperties.push(StructureProperty.accumulateBalance);
        }

        if (this.hasChanged((it) => it.periodGrouping)) {
            changedProperties.push(StructureProperty.periodGrouping);
        }

        if (this.hasChanged((it) => it.groupingClients)) {
            changedProperties.push(StructureProperty.groupingClients);
        }

        return changedProperties;
    }

    private hasChanged(property: (structure: StructureSettings) => any) {
        const currentValue = property(this.activeStructure);
        const newValue = property(this.editableStructure);
        return currentValue !== newValue;
    }

    trackByFn(index: number, item: any) {
        return index;
    }
}

const defaultOptions = {
    amountsFormat: [
        { value: 'cents', desc: 'Com Centavos', example: 'Valor 900.876.543,21 será exibido como 900.876.543,21' },
        { value: 'no_cents', desc: 'Sem Centavos', example: 'Valor 900.876.543,21 será exibido como 900.876.543' },
        { value: 'thousands', desc: 'Milhares (Padrão)', example: 'Valor 900.876.543,21 será exibido como 900.876' },
        { value: 'millions', desc: 'Milhões', example: 'Valor 900.876.543,21 será exibido como 900' },
    ],
    columnTotal: {
        showColumnTotal: { value: 'showColumnTotal', example: 'Será exibida a coluna de totalizadores.' },
        hideColumnTotal: { value: 'hideColumnTotal', example: 'NÃO será exibida a coluna de totalizadores.' },
    },
    accumulateBalance: {
        true: { value: true, example: 'Acumula o saldo do mês anterior no mês seguinte' },
        false: { value: false, example: 'NÃO acumula o saldo do mês anterior no mês seguinte' },
    },
    accountCodes: {
        true: { value: true, example: 'Conta 1 (ATIVO) será exibida como "1 - ATIVO"' },
        false: { value: false, example: 'Conta 1 (ATIVO) será exibida como "ATIVO"' },
    },
    zeroedNodes: {
        true: { value: 'showZeroedNodes', example: ' Exibir Contas Contábeis com valores Zerados.' },
        false: { value: 'hideZeroedNodes', example: ' NÃO Exibir Contas Contábeis com valores Zerados.' },
    },
    unknownLines: {
        showUnknownLines: { value: 'showUnknownLines', example: 'Conta 9 - Desconhecido será exibida no relatório' },
        // tslint:disable-next-line:max-line-length
        hideUnknownLines: { value: 'hideUnknownLines', example: 'Conta 9 - Desconhecido NÃO será exibida no relatório' },
    },
    periodGrouping: [
        { value: 'month', desc: 'Por Mẽs (Padrão)', example: 'Valores serão agrupados por mês (Jan/2017, Fev/2017)' },
        // tslint:disable-next-line:max-line-length
        { value: 'quarter', desc: 'Por Trimestre', example: 'Valores serão agrupados por trimestre (1º Trim/2017, 2º Trim/2017)' },
        // tslint:disable-next-line:max-line-length
        { value: 'halfYear', desc: 'Por Semestre', example: 'Valores serão agrupados por semestre (1º Sem/2017, 2º Sem/2017)' },
    ],
};

const hierarchyDefaults = {
    'balance-sheet': (settings: StructureSettings) => {
        settings.displayAccountCodes = true;
    },
    'income-statement': (settings: StructureSettings) => {
        settings.displayAccountCodes = false;
        settings.columnTotal = 'hideColumnTotal';
        settings.accumulateBalance = true;
    },
    'income-statement-mgmt': (settings: StructureSettings) => {
        settings.displayAccountCodes = false;
        settings.columnTotal = 'showColumnTotal';
        settings.accumulateBalance = false;
    },
    'income-statement-mgmt-legacy': (settings: StructureSettings) => {
        settings.displayAccountCodes = false;
        settings.columnTotal = 'showColumnTotal';
        settings.accumulateBalance = false;
    },
};
