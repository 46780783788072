import { AppModule } from 'app/app.module';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { ITopDiRegistrationFilter } from './interface/top-di-registration.interface';
import moment from 'moment';

export class TopDiRegistrationFilter {
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialTopDiRegistrationFilter: ITopDiRegistrationFilter = <ITopDiRegistrationFilter>{};
    private _topDiRegistrationFilter: ITopDiRegistrationFilter = <ITopDiRegistrationFilter>{
        clientGroups: []
    };

    private startDate: string;
    private endDate: string;

    constructor(filter?: TopDiRegistrationFilter) {
        this._topDiRegistrationFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'topDiRegistrationFilter', this._topDiRegistrationFilter, filter);
        this._initialTopDiRegistrationFilter = _.cloneDeep(this._topDiRegistrationFilter);
        this.startDate = moment().add(-11, 'M').format('MM/YYYY');
        this.endDate = moment().format('MM/YYYY');
    }

    public getStartDate(): string {
        return this.startDate;
    }
    public getEndDate(): string {
        return this.endDate;
    }

    getClientGroups(): string[] {
        return this._topDiRegistrationFilter.clientGroups;
    }

    public setStartDate(startDate): void {
        this.startDate = startDate;
    }
    public setEndDate(endDate): void {
        this.endDate = endDate;
    }

    setClientGroups(clientGroups): void {
        this._topDiRegistrationFilter.clientGroups = clientGroups;
    }

    updatePreferences() {
        this._initialTopDiRegistrationFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialTopDiRegistrationFilter, this._topDiRegistrationFilter, 'topDiRegistrationFilter', this._userCacheService.getUserPreferences().id);
    }
}
