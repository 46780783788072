import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cmxValid',
    pure: false,
})
export class CmxValidValuePipe implements PipeTransform {
    public transform(value: any): any {
        if (value === null || value === undefined || value === '' || value === 'Invalid date' || value === 'Data inválida') {
            return '-';
        } else {
            return value;
        }
    }
}
