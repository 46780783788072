import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import moment from 'moment';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { ISkuImportStatusFilter } from './interface/sku-status.interface';
import { TransportModalService } from '#services/_transportModal/transportModal.service';

export class SkuImportStatusFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialSkuImportStatusFilter: ISkuImportStatusFilter = <ISkuImportStatusFilter>{};
    private _skuImportStatusFilter: ISkuImportStatusFilter = <ISkuImportStatusFilter>{
        search: '',
        clientGroups: [],
        modals: [
            {id: './assets/app/media/img/icons/air.png', itemName: 'AIR'},
            {id: './assets/app/media/img/icons/maritime.png', itemName: 'MARITIME'},
            {id: './assets/app/media/img/icons/roads.png', itemName: 'ROADS'},
        ],
        status: [
            {name: 'Aguard. Aut. Embarque', code: 'INICIADO'},
            {name: 'Aguard. Embarque', code: 'EMBARQUE'},
            {name: 'Em Trânsito', code: 'TRANSITO'},
            {name: 'Aguard. Presença de Carga', code: 'CARGA'},
            {name: 'Aguard. Liberação', code: 'LIBERACAO'},
            {name: 'Aguard. Registro DA', code: 'REGISTRO_DA'},
            {name: 'Aguard. Registro DI', code: 'REGISTRO'},
            {name: 'Aguard. Desembaraço DA', code: 'DESEMBARACO_DA'},
            {name: 'Aguard. Desembaraço DI', code: 'DESEMBARACO'},
            {name: 'Aguard. Faturamento', code: 'FATURAMENTO'},
            {name: 'Entrega', code: 'ENTREGA'},
        ]

    };

    private startDate;
    private endDate;
    private sort: string;
    private offset: number;
    private limit: number;

    constructor(private _transportModalService?: TransportModalService, filter?: SkuImportStatusFilter) {
        this._skuImportStatusFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'skuImportStatusFilter', this._skuImportStatusFilter, filter);
        this._initialSkuImportStatusFilter = _.cloneDeep(this._skuImportStatusFilter);

        this.startDate = filter ? filter.startDate : moment().add(-24, 'M').format('DD/MM/YYYY');
        this.endDate = filter ? filter.endDate : moment().format('DD/MM/YYYY');
        this.sort = filter ? filter.sort : 'comexportSku,desc';
        this.offset = filter ? filter.offset : 0;
        this.limit = filter ? filter.limit : 30;
    }

    public getSearch(): string { return this._skuImportStatusFilter.search; }
    public getClientGroups(): string[] { return this._skuImportStatusFilter.clientGroups; }
    public getStartDate() { return this.startDate; }
    public getEndDate() { return this.endDate; }
    public getStatus(): any[] { return this._skuImportStatusFilter.status; }
    public getSort(): string { return this.sort; }
    public getOffset(): number { return this.offset; }
    public getLimit(): number { return this.limit; }
    public getModals(): object[] { return this._skuImportStatusFilter.modals; }

    public setSearch(search): void { this._skuImportStatusFilter.search = search; }
    public setClientGroups(clientGroups): void { this._skuImportStatusFilter.clientGroups = clientGroups; }
    public setStartDate(startDate): void { this.startDate = startDate; }
    public setEndDate(endDate): void { this.endDate = endDate; }
    public setStatus(status): void { this._skuImportStatusFilter.status = status; }
    public setSort(sort): void { this.sort = sort; }
    public setOffset(offset): void { this.offset = offset; }
    public setLimit(limit): void { this.limit = limit; }
    public setModals(modals): void { this._skuImportStatusFilter.modals = modals; }

    public getRequest(typeSearch, skuView) {
        this._initialSkuImportStatusFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialSkuImportStatusFilter, this._skuImportStatusFilter, 'skuImportStatusFilter', this._userCacheService.getUserPreferences().id);
        let headers = new HttpHeaders();
        let params = new HttpParams();
        headers = headers.set('X-Requested-Client-Groups',
        this.verifySearch(this.getClientGroups().map((c) => c['id']).join(',')));
        params = params.append('modalTransportations',
        this.verifySearch(this.getModals().map((c) => this._transportModalService.getEnglishName(c['itemName'])).join(',')));
        params = params.append('sort',  _.cloneDeep(this.getSort()));
        params = params.append('startDate',
        `${ this.getSearch() === '' ? moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD') : '2016-01-01'}`);
        params = params.append('endDate',
        `${ this.getSearch() === '' ? moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD') }`);
        params = params.append('offset', `${this.getOffset() * this.getLimit()}`);
        params = params.append('limit', `${this.getLimit()}`);
        params = params.set('status',
        this.verifySearch(this.getStatus().map((c) => c['code']).join(',')));
        params = params.append(`${typeSearch === 'IMP' ? 'imp' : typeSearch === 'Referência' ? 'clientReference' :
        typeSearch === 'Fornecedor' ? 'manufacturerName' : skuView === 'skuClient' ? 'customerSku' : 'skuComex'}`,
        `${this.getSearch()}`);
        return { headers, params };
    }

    private verifySearch(value) {
        return this.getSearch() !== '' ? '' : value;
    }

}
