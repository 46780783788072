export class ClientGroup {

    public static blank() {
        return new ClientGroup('Novo Grupo', null, null, []);
    }

    public code: string;
    public name: string;
    public trader: Itrader;
    public clients: Iclient[];
    public domain: string;
    

    constructor(name: string, code: string, trader: Itrader, clients: Iclient[], domain?: string);
    constructor(source: {name: string, code: string, trader?: Itrader, clients?: Iclient[], domain?: string});
    constructor(source: string | any, code?: string, trader?: Itrader, clients?: Iclient[], domain?: string) {
        if (typeof source === 'string') {
            this.name = source;
            this.code = null;
            this.trader = null;
            this.clients = [];
        } else {
            this.name = source.name;
            this.code = source.code;
            this.trader = source.trader;
            this.clients = [].concat(...source.clients);
            this.domain = source.domain;
        }

    }

    public clone() {
        return new ClientGroup(this);
    }

    public equals(other: ClientGroup): boolean {
        return this.name === other.name
            && this.code === other.code
            && this.trader.code === other.trader.code
            && this.trader.dtValidityIni === other.trader.dtValidityIni;
    }
}

interface Itrader {
    name: string;
    code: string;
    dtValidityIni: string;
}

interface Iclient {
    name: string;
    code: string;
}
