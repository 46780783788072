import { Injectable } from '@angular/core';

@Injectable()
export class AdmPanelService {

    public openDeleteModal(modal) {
        $(`#${modal} .deleteModal`).removeClass('hidden');
    }

    public closeDeleteModal(modal) {
        if (!$(`#${modal} .deleteModal`).hasClass('hidden')) {
            $(`#${modal} .deleteModal`).addClass('hidden');
        }
    }

    public hideError(modal) {
        $(`#${modal} .error`).addClass('hidden');
        $(`#${modal} .button`).removeClass('grey');
    }

    public hideSuccess(modal) {
        $(`#${modal} .success`).addClass('hidden');
        $(`#${modal} .button`).removeClass('grey');
    }

    public showError(modal, type) {
        $(`#${modal} .error.${type}`).removeClass('hidden');
    }

    public showSuccess(modal, type) {
        $(`#${modal} .success.${type}`).removeClass('hidden');
    }

    public resetPage(modal) {
        this.closeDeleteModal(modal);
        this.hideError(modal);
        this.hideSuccess(modal);
    }

}
