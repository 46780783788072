import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable()
export class FollowUpReportImpFormatter {

    // IMP
    public formatImp(row, cell, value, columnDef, dataContext) {
        return `<span class='ImpValue'>${value}</span>`;
    }
    public formatChassi(row, cell, value, columnDef, dataContext) {
        return `<span class='ChassiValue'>${value}</span>`;
    }
    public formatSku(row, cell, value, columnDef, dataContext) {
        return `<span class='SkuValue'>${value}</span>`;
    }

    // Referência
    public formatRef(row, cell, value, columnDef, dataContext) {
        const references = [];
        value?.forEach((order) => {
            if (!references.includes(order.clientReference)) {
                references.push(order.clientReference);
            }
        });
        if (references.length === 1) {
            return references[0];
        } else if (references.length > 1) {
            return `<span class="multipleReferences">Várias (${references.length})</span>`;
        } else {
            return '';
        }
    }

    // Status
    public formatStatus(row, cell, value, columnDef, dataContext) {
        if (dataContext.clazz) {
            const img = dataContext.clazz.replace('label ', '');
            return `<img title="${value}" class="impImg"
                style='width: 30px'
                src="assets/img/icons/${img}-checked.png">`;
        } else {
            return null;
        }
    }

    // Total Invoice
    public formatValorTotalInvoice(row, cell, value, columnDef, dataContext) {
        const format = { minimumFractionDigits: 2 };
        if (value && value.totalAmount !== '' && value.totalAmount !== null && value.totalAmount !== undefined) {
            return `<span class="formatValue">
            ${value.currency} ${value.totalAmount.toLocaleString('pt-BR', format)}</span>`;
        } else {
            return '';
        }
    }

    // Termos de Pagamento
    public formatPaymentTerms(row, cell, value, columnDef, dataContext) {
        const exchanges = [];
        value?.forEach((exchange) => {
            if (!exchanges.includes(exchange.paymentTerms)) {
                exchanges.push(exchange.paymentTerms);
            }
        });
        if (exchanges.length === 1) {
            if (exchanges[0] === null || exchanges[0] === undefined || exchanges[0] === '') {
                return '';
            } else {
                switch (exchanges[0]) {
                    case 'CLIENT':
                    return '<span class="pay client">CLIENTE</span>';
                    case 'APRAZO':
                    return '<span class="pay prazo">A PRAZO</span>';
                    case 'ANTECI':
                    return '<span class="pay anteci">ANTECIPADO</span>';
                    case 'AVIST':
                    return '<span class="pay vista">A VISTA</span>';
                    default:
                    return `<span class="pay">${exchanges[0]}</span>`;
                }
            }
        } else if (exchanges.length > 1) {
            return `<span class="multiplePaymentTerms">Várias (${exchanges.length})</span>`;
        } else {
            return '';
        }
    }

    // Carta de Crédito
    public formatCreditLetter(row, cell, value, columnDef, dataContext) {
        let letterOfCredits = false;
        value?.forEach((order) => {
            if (order.letterOfCredit === '1') {
                letterOfCredits = true;
            }
        });
        if (letterOfCredits) {
            return '<div class="ball true "></div>';
        }
        return '<div class="ball"></div>';
    }

    // Valor Numerário
    formatTotalCash(row, cell, value, columnDef, dataContext) {
        const format = { minimumFractionDigits: 2 };
        return value ? `<span class="formatValue">R$ ${value.toLocaleString('pt-BR', format)}</span>` : '';
    }

}
