import { Injectable } from '@angular/core';

@Injectable()
export class FollowUpReportPenaltyFormatter {

    // Valor Multa
    public formatTotalPenaltyValue(row, cell, value, columnDef, dataContext) {
        const format = { minimumFractionDigits: 2 };
        let newValue;
        if (value === null || value === undefined) {
            return '';
        } else if (typeof value === 'object') {
            newValue = `${value.currency} ${value.toLocaleString('pt-BR', format)}`;
        } else {
            newValue = parseFloat(value).toLocaleString('pt-BR', format);
        }
        return `<span class="formatValue">${newValue}</span>`;
    }

}
