<div class="moveBtn"><div class="moveLine"></div></div>
<div *ngIf="asyncDocumentConsolidation"><loader></loader></div>

    <div class="title-container">
        <span class="analyticsName">Consolidação de DI's e LI's</span>
        <cmx-help-modal help-title="Consolidação de DI's e LI's" [help-description]="helpDescription"></cmx-help-modal>
        <h5>Consolidação de DI's e LI's</h5>
    </div>

<cmx-marker-filter
    [filters]="periodTypes"
    [preSelected]="preSelectedPeriod"
    type="period"
    [multiple]="false"
    [hasSelectAll]="false"
    [leftDistance]="275"
    (onSelected)="onSelectedPeriod($event)"
></cmx-marker-filter>

<div id="searchLine" class="reduzed">
    <div class="search-container">
        <div *ngIf="isInternalUser" class="d-flex">
            <div class="input-group">
                <cmx-dropdown
                    [styles]="{ width: '220px' }"
                    name="clients"
                    [data]="options.client"
                    [ngModel]="filterState.getClients()"
                    (ngModelChange)="filterState.setClients($event)"
                    [settings]="dropdownSettings.clientGroups"
                ></cmx-dropdown>
                <span class="input-group-addon" style="margin: 0 0 0 -15px"></span>
            </div>
            <div class="input-group middle-input">
                <cmx-dropdown
                    [styles]="{ width: '220px' }"
                    name="subsidiary-names"
                    [data]="options.subsidiaryNames"
                    [ngModel]="filterState.getSubsidiaryNames()"
                    (ngModelChange)="filterState.setSubsidiaryNames($event)"
                    [settings]="dropdownSettings.subsidiaryNames"
                ></cmx-dropdown>
                <span class="input-group-addon" style="margin: 0 0 0 -15px"></span>
            </div>
            <div class="input-group">
                <cmx-dropdown
                    [styles]="{ width: '220px', 'margin-right': '-10px' }"
                    name="urfNationalizationCodes"
                    [data]="options.urf"
                    [ngModel]="filterState.getUrfs()"
                    (ngModelChange)="filterState.setUrfs($event)"
                    [settings]="dropdownSettings.urfs"
                ></cmx-dropdown>
            </div>
        </div>
        <div class="input-group date-picker">
            <cmx-filter-datepicker
                id="document-consolidation-datepicker"
                [date]="'Registro'"
                type="range-month"
                periodType="month"
                [startDate]="filterState.getStartDate()"
                [endDate]="filterState.getEndDate()"
                (onChangeDates)="datesChanged($event)"
            ></cmx-filter-datepicker>
        </div>
        <button (click)="loadConsolidation()" class="applyImpStatus">
            <i class="fa fa-check"></i>
        </button>
    </div>
</div>

<div *ngIf="!asyncDocumentConsolidation" class="document-consolidation-container">
    <div class="di-consolidation-container pointer" (click)="loadDetails('DI')">
        <div class="title">
            <span class="value">{{ diData?.total }}</span>
            <span>DI's Registradas</span>
        </div>
        <div *ngFor="let distributed of diData.data; let i = index; trackBy: trackByFn" class="distributed-container">
            <hr />
            <div class="distributed-values-container">
                <span class="value">{{ distributed?.value }}</span>
                <img src="assets/img/icons/{{ distributed.name }}.png" alt="" />
                <span class="percentage">{{ distributed?.percentage }}%</span>
            </div>
        </div>
        <div *ngIf="selectedType === 'DI'" class="sign"></div>
    </div>

    <div class="li-consolidation-container pointer" (click)="loadDetails('LI')">
        <div class="title">
            <span class="value">{{ liData?.total }}</span>
            <span> LI's Registradas </span>
        </div>
        <div
            *ngFor="let distributed of liConsolidationPages[consolidationPage]; trackBy: trackByFn"
            class="distributed-container"
        >
            <hr />
            <div class="distributed-values-container">
                <span class="value">{{ distributed?.value }}</span>
                <span class="name">{{ distributed?.name }}</span>
                <span class="percentage">{{ distributed?.percentage }}%</span>
            </div>
        </div>
        <div
            *ngIf="liConsolidationPages.length > 1 && !asyncDocumentConsolidation"
            class="arrows-container"
            (click)="$event.stopPropagation()"
        >
            <div
                class="arrow arrow-top"
                [ngClass]="{ unclickable: consolidationPage === liConsolidationPages.length - 1 }"
                (click)="changeConsolidatedPage(1)"
            ></div>
            <div
                class="arrow arrow-down"
                [ngClass]="{ unclickable: consolidationPage === 0 }"
                (click)="changeConsolidatedPage(-1)"
            ></div>
        </div>
        <div *ngIf="selectedType === 'LI'" class="sign"></div>
    </div>
    <div class="total-info-container">
        <div class="item">
            <div class="title">Total de Itens:</div>
            <div class="value">{{ diData.totalItems }}</div>
        </div>
        <p class="divisor">&bull;</p>
        <div class="item">
            <div class="title">Total de Adições:</div>
            <div class="value">{{ diData.totalAdditions }}</div>
        </div>
    </div>
</div>

<div class="document-details-container invisible">
    <i id="close" (click)="closeChart()" class="la la-close"></i>
    <loader *ngIf="asyncChart && !asyncDocumentConsolidation"></loader>
    <div *ngIf="!asyncDocumentConsolidation" id="documentDetailChart" style="height: 200px; margin-top: 20px"></div>
</div>
