import { _applyPosFormatters } from "./pos-formatters/pos-formatters";

export function percentageFormatter(row, cell, value, columnDef, dataContext) {
    const percentage = !value ? 0 : value;
    const fontSize = (columnDef.formatterData && columnDef.formatterData.fontSize) || '14px';
    let color = (columnDef.formatterData && columnDef.formatterData.color) || 'black';
    const dynamic = color === 'dynamic' ? true : false;
    if (color === 'dynamic') {
        if (percentage >= 70) {
            color = 'green';
        } else if (percentage < 40) {
            color = '#a80000';
        } else {
            color = '#ffb515';
        }
    }
    const contentValue = `<div style="font-size: ${fontSize};
            color: ${percentage === 0 && !dynamic ? 'grey' : color}; font-weight: 500">
            ${percentage.toString().replace('.', ',')}%</div>`;
    return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
}