import { environment } from '#environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FeatureToggle, FeatureVariant } from './feature-flag.entity';

@Injectable()
export class FeatureFlagService {
    private _enabledList: string[] = [];

    constructor(private _http: HttpClient) { }

    public static readonly FEATURE_REDIRECT_STATUS_FUP: string = "redirect_status_fup";
    public static readonly FEATURE_FOLLOWUP_UNIFIED_ENABLED: string = "followup_unified_enabled";
    public static readonly FEATURE_REDIRECT_TAX_CODE_SERVICE: string = "redirect_tax_code_service";
    public static readonly FEATURE_REDIRECT_GED_V2: string = "redirect_ged_v2";
    public static readonly FEATURE_FOLLOWUP_MONGODB_SERVICE: string = "followup_mongodb_service";

    getEnabledList(): string[] { return this._enabledList }
    setEnabledList(list): void { this._enabledList = list }

    getFeatureFlagEnabled(): Observable<any> {
        return this._http.get(`${environment.endpoints.featureFlagService}/rs/featureflag-enabled`);
    }

    isFeatureFlagEnabled(feature: string): boolean {
        return this._enabledList.includes(feature);
    }

    private getFeatureFlagEnabledV2(feature: string): Observable<FeatureToggle> {
        return this._http.get<FeatureToggle>(`${environment.endpoints.featureFlagServiceV2}/feature/${feature}`);
    }

    private getFeatureFlagVariantV2(feature: string): Observable<FeatureVariant> {
        return this._http.get<FeatureVariant>(`${environment.endpoints.featureFlagServiceV2}/feature/${feature}/variant`);
    }

    public async isFeatureFlagEnabledV2(feature: string): Promise<boolean> {
        try {
            const resp = await this.getFeatureFlagEnabledV2(feature).toPromise();
            return resp.enabled;
        } catch (error) {
            return false;
        }
    }

    public async getFeatureFlagValueV2(feature: string): Promise<string> {
        try {
            const respVariant = await this.getFeatureFlagVariantV2(feature).toPromise();
            return respVariant.value;
        } catch (error) {
            return '';
        }
    }
}