import { Component, ViewEncapsulation, AfterViewInit } from '@angular/core';

@Component({
    selector: '.m-grid__item.m-grid__item--fluid.m-wrapper',
    templateUrl: './maquina-rateio.component.html',
    encapsulation: ViewEncapsulation.None
})
export class MaquinaRateioComponent implements AfterViewInit {

    engines: Array<string> = [
        "Head Count",
        "Engine 1",
        "Engine 2",
        "Engine 3",
        "Engine 4"
    ];

    profitCenters: Array<string> = [
        "20 - Novos Negócios",
        "22 - Petroquímico",
        "23 - Químico",
        "24 - Infraestrutura",
        "30 - Aço",
        "31 - Têxtil",
        "35 - Terceirização",
        "42 - Fertilizante"
    ];

    years: Array<string> = ["2017", "2018"];

    indexes: Array<number> = [1, 2, 3, 4, 5, 6];

    visibleSection: number;

    constructor() {
        this.visibleSection = 1;
    }

    ngAfterViewInit() {
        $(".m-bootstrap-select").selectpicker();
    }

    firstSection(e: any) {
        this.visibleSection = 1;
    }

    secondSection(e: any) {
        this.visibleSection = 2;
    }

    trackByFn(index: number, item: any) {
        return index;
    }
}
