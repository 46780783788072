import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';

export class NewTrackingTimelineFilter {

    private consignmentID: string;
    private equipmentNumber: string;
    private asyncTimeline: boolean = false;
    private timeline: object[] = [];

    constructor(filter?: NewTrackingTimelineFilter) {
        this.consignmentID = filter ? filter.consignmentID : '';
        this.equipmentNumber = filter ? filter.equipmentNumber : '';
    }

    public getConsignmentID(): string { return this.consignmentID; }
    public getEquipmentNumber(): string { return this.equipmentNumber; }
    public getTimeline(): object[] { return this.timeline; }
    public getAsync(): boolean { return this.asyncTimeline; }

    public setConsignmentID(consignmentID): void { this.consignmentID = consignmentID; }
    public setEquipmentNumber(equipmentNumber): void { this.equipmentNumber = equipmentNumber; }
    public setTimeline(timeline): void { this.timeline.push(timeline); }
    public resetTimeline(): void { this.timeline = []; }
    public setAsync(asyncTimeline): void { this.asyncTimeline = asyncTimeline; }

    public getRequest() {
        let params = new HttpParams();
        params = params.append('consignmentID',  _.cloneDeep(this.getConsignmentID()));
        params = params.append('equipmentNumber',  _.cloneDeep(this.getEquipmentNumber()));
        return params;
    }
}
