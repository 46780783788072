import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'app/shared/shared.module';
import { LayoutModule } from 'app/theme/layouts/layout.module';
import { DragulaModule } from 'ng2-dragula';
import { AccordionModule } from 'primeng/accordion';
import { LineChartModule } from 'app/shared/components/cmx-line-chart/cmx-line-chart.module';

import { DocumentConsolidationService } from './document-consolidation/document-consolidation.service';
import { LogisticsHandlingService } from './logistics-handling/logistics-handling.service';
import { OperationalAlertsService } from './operational-alerts/services/operational-alerts.service';
import { OperationalConsolidationService } from './operational-consolidation/operational-consolidation.service';
import { TopRegistrationService } from './top-registration/top-registration.service';
import { ForecastByStatusService } from './forecast-by-status/forecast-by-status.service';

import { DocumentConsolidationComponent } from './document-consolidation/document-consolidation.component';
import { LogisticsHandlingComponent } from './logistics-handling/logistics-handling.component';
import { TopDIRegistrationComponent } from './top-registration/top-di-registration/top-di-registration.component';
import { TopLIRegistrationComponent } from './top-registration/top-li-registration/top-li-registration.component';
import { ForecastByStatusComponent } from './forecast-by-status/forecast-by-status.component';
import { InfoDialogComponent } from './operational-alerts/components/info-dialog/info-dialog.component';
import { AlertTypeComponent } from './operational-alerts/components/alert-type/alert-type.component';
import { AlertClientsDialogComponent } from './operational-alerts/components/alert-clients-dialog/alert-clients-dialog.component';
import { AlertsBySeverityChartComponent } from './operational-alerts/components/alerts-by-severity-chart/alerts-by-severity-chart.component';
import { OperationalAlertsComponent } from './operational-alerts/operational-alerts.component';
import { OperationalConsolidationComponent } from './operational-consolidation/operational-consolidation.component';
import { AlertClientDialogComponent } from './operational-alerts/components/alert-client-dialog/alert-client-dialog.component';
import { AlertClientDetailsDialogComponent } from './operational-alerts/components/alert-client-details-dialog/alert-client-details-dialog.component';
import { MovementByModalComponent } from './movement-by-modal/movement-by-modal.component';
import { MovementByModalService } from './movement-by-modal/movement-by-modal.service';
import { SimpleLineChartModule } from 'app/shared/components/cmx-simple-line-chart/cmx-simple-line-chart.module';

// Charts Import

@NgModule({
    entryComponents: [
        OperationalAlertsComponent,
        ForecastByStatusComponent,
        OperationalConsolidationComponent,
        TopDIRegistrationComponent,
        TopLIRegistrationComponent,
        LogisticsHandlingComponent,
        MovementByModalComponent,
        DocumentConsolidationComponent,
        AlertTypeComponent,
        AlertsBySeverityChartComponent,
        AlertClientsDialogComponent,
        AlertClientDialogComponent,
        AlertClientDetailsDialogComponent,
        InfoDialogComponent,
    ],
    declarations: [
        OperationalAlertsComponent,
        ForecastByStatusComponent,
        OperationalConsolidationComponent,
        TopDIRegistrationComponent,
        TopLIRegistrationComponent,
        LogisticsHandlingComponent,
        MovementByModalComponent,
        DocumentConsolidationComponent,
        AlertTypeComponent,
        AlertsBySeverityChartComponent,
        AlertClientsDialogComponent,
        AlertClientDialogComponent,
        AlertClientDetailsDialogComponent,
        InfoDialogComponent,
    ],
    exports: [
        OperationalAlertsComponent,
        ForecastByStatusComponent,
        OperationalConsolidationComponent,
        TopDIRegistrationComponent,
        TopLIRegistrationComponent,
        LogisticsHandlingComponent,
        MovementByModalComponent,
        DocumentConsolidationComponent,
        AlertTypeComponent,
        AlertsBySeverityChartComponent,
        AlertClientsDialogComponent,
        AlertClientDetailsDialogComponent,
        InfoDialogComponent,
    ],
    providers: [
        OperationalConsolidationService,
        TopRegistrationService,
        OperationalConsolidationService,
        LogisticsHandlingService,
        DocumentConsolidationService,
        OperationalAlertsService,
        ForecastByStatusService,
        MovementByModalService,
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        SharedModule,
        AccordionModule,
        DragulaModule,
        LayoutModule,
        LineChartModule,
        SimpleLineChartModule
    ],
})
export class OperationalModule {}
