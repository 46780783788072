import { Injectable } from '@angular/core';
import { currencyFormatter } from 'app/shared/components/cmx-custom-grid/formatters/currency.formatter';

@Injectable()
export class FollowUpReportSkuFormatter {

    public formatPrice(row, cell, value, columnDef, dataContext) {
        const format = { minimumFractionDigits: 2 };
        if (value && Array.isArray(value)) {
            if (value.length === 1) {
                value = value[0][columnDef.field];
            } else if (value.length > 0) {
                return `<span class="multipleSkuPrice">Vários (${value.length})</span>`;
            } else {
                return ''
            }
        }
        
        if (value !== '' && value !== null && value !== undefined) {
            let currency = dataContext.skuCurrency
            if (!currency && dataContext.sku && dataContext.sku.length) {
                currency = dataContext.skuCurrency;
            }
            if (!dataContext.skuCurrency && dataContext?.skus?.length === 1){
                currency = dataContext.skus[0].skuCurrency || '';
            }
            return `<span class="formatValue">${currency} ${value.toLocaleString('pt-BR', format)}</span>`;
        } else {
            return '';
        }   
    }

    public formatTotalPrice(row, cell, value, columnDef, dataContext) {
        if (value && Array.isArray(value)) {
            if (value.length === 1) {
                value = value[0][columnDef.field];
            } else if (value.length > 0) {
                return `<span class="multipleSkuPrice">Vários (${value.length})</span>`;
            } else {
                return ''
            }
        }

        const format = { minimumFractionDigits: 2 };
        
        if (value !== '' && value !== null && value !== undefined) {
            let currency = dataContext.skuCurrency
            if (!dataContext.skuCurrency && dataContext?.skus?.length === 1){
                currency = dataContext.skus[0].skuCurrency || '';
            }
            return `<span class="formatValue">${currency} ${value.toLocaleString('pt-BR', format)}</span>`;
        } else {
            return '';
        }
    }

    public formatQuantity(row, cell, value, columnDef, dataContext) {
        if (value && Array.isArray(value) && value.length > 0 && value[0][columnDef.field]) {
            let newValue = [];
            value.forEach((item) => {
                if(item[columnDef.field] && Array.isArray(item[columnDef.field])) {
                    newValue = newValue.concat(item[columnDef.field]);
                }
            });
            value = newValue;
        }

        const containers = [];
        value?.forEach((container) => {
            if(container.quantityByNumber) {
                if (!containers.includes(container.quantityByNumber)) {
                    containers.push(container.quantityByNumber);
                }
            } else {
                const quantityByNumber = `${container.number}|${container.quantity}`;
                if (!containers.includes(quantityByNumber)) {
                    containers.push(quantityByNumber);
                }
            }
        });

        if (containers.length === 1) {
            if (containers[0] === null || containers[0] === undefined || containers[0] === '') {
                return '';
            }
            return containers[0];
        } else if (containers.length > 1) {
            return `<span class="multipleSkuQuantity">Várias (${containers.length})</span>`;
        } else {
            return '';
        }
    }


}
