export interface IQuotationFreightRatesFilter {
    internationalCargoType;
    internationalOrigin;
    internationalDestination;
    nationalOrigin;
    nationalDestination;
    nationalTransport;
    tabSelected;
}

export enum Tabs {
    NATIONAL,
    INTERNATIONAL
}