export const FORMATTERS = {
    exchangeApproval(data) {
        let icons = '';
        if (Array.isArray(data.value)) {
            data.value.forEach((approval) => {

                if (approval.approvalCommand === 'APROVAR' || approval.approvalCommand === 'LIBERAR') {
                    icons += `<span class="material-icons approval" style="color: green; font-size: 18px;">done</span>`;
                } else if (approval.approvalSituation === 'PENDENTE' || approval.approvalSituation === 'LIBERADO AUTO') {
                    icons += `<span class="material-icons approval" style="color: #a7a400; font-size: 18px;">panorama_fish_eye</span>`;
                } else {
                    icons += `<span class="material-icons approval" style="color: #c10101; font-size: 18px;">clear</span>`;
                }
            });
        }
        return `<div style="display: flex; justify-content: space-between; align-items: center;">
            ${icons}
        </div>`;
    },

    status(data) {
        const statusTitle = (data.value && data.value.name) || data.value;
        return `<span class="status" style="background-color:${data.color || 'grey'}">${statusTitle.toUpperCase()}</span>`;
    },

    percentage(data) {
        const percentage = !data.value ? 0 : data.value;
        return `${percentage.toString().replace('.', ',')} %`;
    },

    multiple(data) {
        if ((!data.value) || (Array.isArray(data.value) && !data.value.length) || (Array.isArray(data.value) && data.value.length === 1 && !data.value[0])) {
            return `—`;
        }
        if (data.value && !Array.isArray(data.value) ) {
            return data.value
        }
        if ((Array.isArray(data.value) && data.value.length > 1)) {
            return `<span class="multiple">Vários (${data.value.length})</span>`;
        }

        return data.value[0];
    },

    alert(data) {
        return `<span class="alert ${data.color || 'grey'}"></span>`;
    },

    input(data) {
        return `<input id="input-${data.title.toLowerCase().replace(/\s/g, '_')}" class="input" placeholder="${data.title}" name="${data.title}" value="${data.value}">`
    },

    textarea(data) {
        return `<textarea id="input-${data.title.toLowerCase().replace(/\s/g, '_')}" class="input textarea" placeholder="${data.title}" name="${data.title}">${data.value}</textarea>`
    },

    breakline(data) {
        return data.value
    },

    customIcom(data) {
        const iconConfig = data.iconConfig || {};
        const urlIcon = iconConfig.iconTypeUrl ? `<img class="url-icon" src="assets/img/icons/${iconConfig.iconTypeUrl}.svg">` : '';
        const checkIconClass = urlIcon ? 'url-custom-icon' : 'material-icons';

        const icon =
            `<span class="${checkIconClass} assembled-custom-icon ${iconConfig.iconClickable ? 'clickable' : '' }" style="color: ${iconConfig.color || '#193d69'}; font-size: ${iconConfig.fontSize || '18px'}" title="${iconConfig.iconTooltip}">
                ${iconConfig.iconType || 'info'}
            </span>`
        ;

        return `<div class="custom-icon-container">${data.value} ${icon}</div>`;
    }
};
