<div class="page-title-identifier">Customização Dashboard</div>
<div *ngIf="loginPermissionService.asyncComponents" class="fullLoader">
    <span class="exporting">• Carregando Componentes •</span>
    <loader></loader>
</div>

<div class="mainContainerPage">
    <div *ngIf="!loginPermissionService.asyncComponents" class="customDashContainer">
        <div class="containerInfo">
            <div class="titleContainer">
                <span class="title"><i class="material-icons">dashboard</i>
                    {{selectedComponent?.name || 'Personalização Dashboard'}}</span>
                <i *ngIf="selectedComponent.name" (click)="clearSelection()" class="close la la-close"></i>
            </div>
            <hr>
            <div *ngIf="selectedComponent.name" class="content">
                <span>{{selectedComponent.description}}</span>
                <hr style="width:100%">
                <img src="../../../../../assets/app/media/img/tutos/dashCustom/{{selectedComponent.component}}.png" alt="">
            </div>
            <div *ngIf="!selectedComponent.name" class="content">
                <span>Na personalização do Dashboard, é possível escolher quais componentes deseja visualizar, na ordem que desejar.
                    Basta posicioná-los na ordem desejada na área do Dashboard Atual.</span>
                <hr style="width:100%">
                <img src="../../../../../assets/app/media/img/tutos/dashCustom/DashCustomComponent.png" alt="">
            </div>
        </div>
        <div class="trashListContainer">
            <div class="titleContainer">
                <span class="title"><i class="material-icons">indeterminate_check_box</i>Componentes Disponíveis</span>
            </div>
            <hr>
            <div id="storedComponents" class="content">
                <span *ngIf="loginPermissionService.getStoredComponentList().length === 0" class="noContent">
                    Nenhum componente disponível.
                </span>
                <div *ngFor="let component of loginPermissionService.getStoredComponentList(); trackBy: trackByFn" (click)="selectComponent(component)"
                class="component{{component.size === 100 ? ' full' : ''}}" id="{{component.component}}" style="cursor:pointer">
                    <div (click)="loginPermissionService.addComponent(component)">
                        <i class="add la la-plus"></i>
                    </div>
                    <i *ngIf="component.expansive"
                       (click)="resizeComponent(component)" class="resize la {{component.size === 100 ? 'la-compress' : 'la-expand'}}"></i>
                    {{component.name}}
                </div>
            </div>
        </div>
        <div class="dashListContainer">
            <div class="titleContainer">
                <span class="title"><i class="material-icons">check_box</i>Dashboard Atual</span>
            </div>
            <hr>
            <div id="mergedComponents" class="content" [dragula]='"customDash-bag"' [dragulaModel]="">
                <span *ngIf="loginPermissionService.getMergedComponentList().length === 0" class="noContent">
                    Nenhum componente selecionado.
                </span>
                <div *ngFor="let component of loginPermissionService.getMergedComponentList(); trackBy: trackByFn" (click)="selectComponent(component)"
                class="component{{component.size === 100 ? ' full' : ''}}" id="{{component.component}}">
                    <i (click)="loginPermissionService.removeComponent(component)" class="close la la-close"></i>
                    <i *ngIf="component.expansive"
                       (click)="resizeComponent(component)" class="resize la {{component.size === 100 ? 'la-compress' : 'la-expand'}}"></i>
                    {{component.name}}
                </div>
            </div>
           <hr>
            <div class="buttonsContainer">
                <button (click)="loginPermissionService.resetChanges()" class="reset"><i class="material-icons">cached</i>Resetar</button>
                <button (click)="saveCustomChanges()" class="save"><i class="material-icons">save</i>Salvar</button>
            </div>
        </div>
    </div>
</div>

