import { NotificationService } from '#services/_notification/notification.service';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';

export type TypeAccept = 'image/*' | 'image/jpg' | 'image/jpeg' | 'image/png';

@Component({
    selector: 'cmx-img-upload',
    templateUrl: 'img-upload.component.html',
    styleUrls: ['img-upload.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class CmxImgUploadComponent implements OnChanges {
    @Input() id: string;
    @Input() title?: string;
    @Input() accept?: TypeAccept[];
    @Input() required?: boolean = false;
    @Input() disable?: boolean = false;
    @Input() maxSizeFile: number = 1000000;
    @Input() tooltipOptions: { show: false, position: 'right'};
    @Input() width: string = '130px';
    @Input() height: string = '130px';
    @Input() preview? = null;

    @Output('onChange') onChange = new EventEmitter();

    imageURL = null;
    loadedImagePreview = [null, 'loading', 'loaded'];
    statusImage = null;
    imagePreview = null;
    fileToUpload: File = null;

    feedbackFileErrorSize = 'O arquivo de imagem precisa ser menor ou igual a ' + this.maxSizeFile / 1000000 + 'mb';
    feedbackFileErrorFormat = 'Este formato de arquivo não é permitido, selecione outro';

    constructor(private _notificationService: NotificationService) {}

    ngOnChanges(changes: SimpleChanges){
        if (changes.preview?.currentValue){
            this.handleAttachFile(this.preview);
        }
    }

    handleAttachFile($file) {

        if ($file.size > this.maxSizeFile) {
            return this._notificationService.openNotification('error', this.feedbackFileErrorSize);
        }

        if (!_.includes(this.accept, $file.type)) {
            return this._notificationService.openNotification('error', this.feedbackFileErrorFormat);
        }

        this.statusImage = this.loadedImagePreview[2];

        if ($file && $file.name) {
            this.imageURL = $file.name;
            this.fileToUpload = $file;
            this.onChange.emit($file);
        }

        const preview = new FileReader();
        preview.readAsDataURL($file);
        preview.onload = () => {
            this.imagePreview = preview.result;
        };
    }

    handleCleanAttach($event) {
        this.imageURL = null;
        this.fileToUpload = null;
        $event.target.value = '';
    }

    handleRemoveAttachFile($event) {
        this.imageURL = null;
        this.fileToUpload = null;
        this.imagePreview = null;
        this.statusImage = null;
        this.onChange.emit(null);
        $event.stopPropagation();
    }

    clenupAttach() {
        this.imageURL = null;
        this.fileToUpload = null;
        this.imagePreview = null;
        this.statusImage = null;
        this.onChange.emit(null);
    }

    createTooltip() {
        if (_.includes(this.accept, 'image/*')) {
            return 'Tamanho máximo: ' + this.maxSizeFile / 1000000 + 'mb';
        } else {
            let simpleTypeAccept = [];
            this.accept.forEach((item) => {
                simpleTypeAccept.push(item.toString().replace('image/', ''))
            });
            var types = simpleTypeAccept.join(", ");
            return 'Permitido: ' + types + ' | Tamanho máximo: ' + this.maxSizeFile / 1000000 + 'mb';
        }
    }
}
