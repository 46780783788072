export const FORMATTER_TITLES = {
    icon: {
        air: 'Aéreo',
        roads: 'Rodoviário',
        maritime: 'Marítimo',
        aereo: 'Aéreo',
        rodoviario: 'Rodoviário',
        maritimo: 'Marítimo',
    },
    status: {
        pending: 'pendente',
        authorized: 'autorizado',
        registered: 'registrado',
        approved: 'aprovado',
    },
};
