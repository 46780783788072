<div class="m-subheader">
    <div class="d-flex align-items-center">
        <div class="mr-auto">

            <h3 class="m-subheader__title m-subheader__title--separator">
                Processos por Status
            </h3>

            <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                <li class="m-nav__item m-nav__item--home">
                    <a href="#" class="m-nav__link m-nav__link--icon">
                        <i class="m-nav__link-icon la la-home"></i>
                    </a>
                </li>
                <li class="m-nav__separator">
                    -
                </li>
                <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                        <span class="m-nav__link-text">
                            Supply Chain
                        </span>
                    </a>
                </li>
                <li class="m-nav__separator">
                    -
                </li>
                <li class="m-nav__item">
                    <a href="/supply-chain/processos-por-status" class="m-nav__link">
                        <span class="m-nav__link-text">
                            Processos por Status
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="m-content">
    <div class="m-portlet">
        <div class="m-portlet__body">
            <ul class="nav nav-tabs m-tabs-line m-tabs-line--primary m-tabs-line--2x" role="tablist">
                <li *ngFor="let tab of tabs; let i = index; trackBy: trackByFn" class="nav-item m-tabs__item">
                    <a class="nav-link m-tabs__link text-center" [ngClass]="i == 0 ? 'active' : null" data-toggle="tab" [attr.href]="'#tab_' + i"
                        (click)="updateTable(i, tab)">
                        {{tab}}
                    </a>
                </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="tab_0" role="tabpanel" (click)="updateTable(0, tabs[0])">
                    <div id="imp-follow-up-0"></div>
                </div>
                <div *ngFor="let tab of tabs | slice: 1; let i = index; trackBy: trackByFn" class="tab-pane" id="tab_{{i + 1}}" role="tabpanel">
                    <div id="imp-follow-up-{{i + 1}}"></div>
                </div>
            </div>
        </div>
    </div>
</div>