<div *ngIf="asyncStock">
    <loader></loader>
</div>

<h5>{{ stockTitle }}</h5>
<div class="moveBtn">
    <div class="moveLine"></div>
</div>
<div class="checkmark-container">
    <span id="ano-corrente" (click)="changeDateParam($event, 'CURRENT_YEAR')" class="checkmark text">Ano Corrente</span>
    <span id="doze-meses" (click)="changeDateParam($event, 'LAST_12_MONTHS')" class="checkmark text checked">12 Meses</span>
    <span id="mes-corrente" (click)="changeDateParam($event, 'CURRENT_MONTH')" class="checkmark text">Mês Corrente</span>
    <span id="mes-anterior" (click)="changeDateParam($event, 'PREVIOUS_MONTH')" class="checkmark text">Mês Anterior</span>
</div>

<div *ngIf="currentUser$ | async as user">
    <div id='searchLine' class="reduzed" *ngIf="stockTitle == 'Veículos Nacionalizados' && user.has('INTERNAL_FILTERS')">
        <div class="search-container">
            <cmx-dropdown id="clients" name="clients" [data]="clientOptions"
            [(ngModel)]='selectedClients' [settings]="dropdownSettings.clientGroups"></cmx-dropdown>
        </div>
        <button (click)="loadStock(periodType)" class="applyImpStatus applyFilter">
            <i class="fa fa-check"></i>
        </button>
    </div>
</div>
<div class="chart" id="stock"></div>
<div *ngIf="!asyncStock && chartData === undefined && !firstLoad" class="reloadContainer">
    <div class="reloadContent">
        <i (click)="loadStock(periodType)" class="material-icons">refresh</i>
    </div>
</div>
