import { AppModule } from 'app/app.module';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IInternationalQuotationFilter } from '../interfaces/international-quotation.interface';
import moment from 'moment';
import * as _ from 'lodash';

export class InternationalQuotationFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);
    private _initialInternationalQuotationFilter: IInternationalQuotationFilter = <IInternationalQuotationFilter>{};
    private _internationalQuotationFilter: IInternationalQuotationFilter = <IInternationalQuotationFilter>{
        type: { id: 'IN_PROGRESS', name: 'Em Andamento', value: 'FIRME' },
        reference: '',
        user: [],
        status: [],
        costCenter: [],
        quotationType: '',
    };

    private _system;
    private _startDate;
    private _endDate;
    private _sort: string;
    private _page: number;
    private _size: number;

    constructor(filter?: InternationalQuotationFilter) {
        this._internationalQuotationFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'internationalQuotationFilter', this._internationalQuotationFilter, filter);
        this._initialInternationalQuotationFilter = _.cloneDeep(this._internationalQuotationFilter);
        this._startDate = filter ? filter._startDate : moment().format('01/01/YYYY');
        this._endDate = filter ? filter._endDate : moment().format('DD/MM/YYYY');
        this._sort = filter ? filter._sort : 'reference,asc';
        this._page = filter ? filter._page : 0;
        this._size = filter ? filter._size : 50;
    }

    getReference(): string{ return this._internationalQuotationFilter.reference }
    getUsers() { return this._internationalQuotationFilter.user }
    getCostCenter() { return this._internationalQuotationFilter.costCenter }
    getStatus() { return this._internationalQuotationFilter.status }
    getQuotationType() { return this._internationalQuotationFilter.quotationType }
    getStartDate() { return this._startDate; }
    getEndDate() { return this._endDate; }
    getSort(): string { return this._sort; }
    getPage(): number { return this._page; }
    getSize(): number { return this._size; }
    getSystem(): number { return this._system; }
    getType() { return this._internationalQuotationFilter.type }

    setReference(reference) { this._internationalQuotationFilter.reference = reference }
    setStatus(status) { this._internationalQuotationFilter.status = status }
    setCostCenter(costCenter) { this._internationalQuotationFilter.costCenter = costCenter }
    setUsers(user): void { this._internationalQuotationFilter.user = user }
    setQuotationType(quotationType): void { this._internationalQuotationFilter.quotationType = quotationType }
    setStartDate(startDate): void { this._startDate = startDate; }
    setEndDate(endDate): void { this._endDate = endDate; }
    setSort(sort): string { return this._sort = sort; }
    setPage(page): void { this._page = page; }
    setSize(size): void { this._size = size; }
    setSystem(system): void { this._system = system; }
    setType(type) { this._internationalQuotationFilter.type = type; }

    updatePreferences() {
        this._initialInternationalQuotationFilter = this._userPreferencesService.verifyFilterChanges(this._initialInternationalQuotationFilter,this._internationalQuotationFilter,'internationalQuotationFilter',this._userCacheService.getUserPreferences().id);
    }

    getRequest() {
        this.updatePreferences();

        let params = new HttpParams();
        let headers = new HttpHeaders();

        params = params.append(this.getSystem() ? 'status' : 'externalStatus', this.getStatus().map((status) => status.id).join(',').toUpperCase());
        params = params.append('quotationType', `${ this.getQuotationType() }`);
        params = params.append('onGoing', `${this.getType().name === 'Em Andamento' ? true : false}`);
        params = params.append('system', `${ this.getSystem() || '' }`);
        params = params.append('page', `${ this.getPage() }`);
        params = params.append('size', `${ this.getSize() }`);
        params = params.append('sort', `${this.getSort()}`);

        if (this.getReference()) {
            params = params.append('reference', `${ this.getReference() }`);
        } else {
            params = params.append('user', this.getUsers().map((user) => user.itemName).join(','));
            params = params.append('costCenter', this.getCostCenter().map((costCenter) => costCenter.id).join(',').toUpperCase());
            params = params.append('startDate', `${moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`);
            params = params.append('endDate', `${moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`);
        }

        this.removeNullValuesFromQueryParams(params);

        return { params, headers };
    }

    removeNullValuesFromQueryParams(params: HttpParams) {
        const paramsKeysAux = params.keys();
        paramsKeysAux.forEach((key) => {
          const value = params.get(key);
          if (value === null || value === undefined || value === '') {
            params['map'].delete(key);
          }
        });

        return params;
    }
}
