import { AppModule } from 'app/app.module';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { ISlaStatusFilter } from './interface/sla-status.interface';
import { UserService } from 'app/auth/_services';

export class SlaStatusFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);
    private _userService = AppModule.injector.get(UserService);

    private _initialSlaStatusFilter: ISlaStatusFilter = <ISlaStatusFilter>{};
    private _slaStatusFilter: ISlaStatusFilter = <ISlaStatusFilter>{
        toggleStatus: [],
        toogleJustify: ['PREJUSTIFIED', 'UNJUSTIFIED', 'JUSTIFIED'],
        automotiveMode: 'Desligado',
        typeSearch: this._userService.getCurrentUser().has('INTERNAL_FILTERS') ? 'IMP' : 'Ref',
        modals: [
            { id: './assets/app/media/img/icons/air.png', itemName: 'Aéreo' },
            { id: './assets/app/media/img/icons/maritime.png', itemName: 'Marítimo' },
            { id: './assets/app/media/img/icons/roads.png', itemName: 'Rodoviário' },
        ],
        clientGroups: [],
        destinations: [],
        origin: [],
        selectedKpi: [],
        typeSwitch: 'impIssueDate'
    };
    private search: string = '';
    private filterValue: string;
    private filterField: string;

    constructor(filter?: SlaStatusFilter) {
        this._slaStatusFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'slaStatusFilter', this._slaStatusFilter, filter);
        const filters = _.cloneDeep(this._slaStatusFilter);
        filters.modals.forEach((modal) => { modal.itemName = this._defineModalName(modal.itemName) })
        this._initialSlaStatusFilter = _.cloneDeep(filters);
        this.updatePreferences(true);
        this.filterValue = filter ? filter.filterValue : '';
        this.filterField = filter ? filter.filterField : '';
    }

    public getClientGroups() {
        return this._slaStatusFilter.clientGroups;
    }
    public getDestinations() {
        return this._slaStatusFilter.destinations;
    }
    public getOrigins() {
        return this._slaStatusFilter.origin;
    }
    public getToggleStatus() {
        return this._slaStatusFilter.toggleStatus;
    }
    public getSelectedKpi() {
        return this._slaStatusFilter.selectedKpi;
    }
    public getToogleJustify() {
        return this._slaStatusFilter.toogleJustify;
    }
    public getAutomotiveMode() {
        return this._slaStatusFilter.automotiveMode;
    }
    public getModals() {
        return this._slaStatusFilter.modals;
    }
    public getTypeSearch() {
        return this._slaStatusFilter.typeSearch;
    }
    public getSearch() {
        return this.search;
    }
    public getTypeSwitch() {
        return this._slaStatusFilter.typeSwitch;
    }
    public getFilterValue() {
        return this.filterValue;
    }
    public getFilterField() {
        return this.filterField;
    }

    public setDestinations(destinations): void {
        this._slaStatusFilter.destinations = destinations;
    }
    public setOrigins(origin): void {
        this._slaStatusFilter.origin = origin;
    }
    public setClientGroups(clientGroups): void {
        this._slaStatusFilter.clientGroups = clientGroups;
    }
    public setToggleStatus(toggleStatus): void {
        this._slaStatusFilter.toggleStatus = toggleStatus;
    }
    public setSelectedKpi(selectedKpi): void {
        this._slaStatusFilter.selectedKpi = selectedKpi;
    }
    public setToogleJustify(toogleJustify): void {
        this._slaStatusFilter.toogleJustify = toogleJustify;
    }
    public setAutomotiveMode(automotiveMode): void {
        this._slaStatusFilter.automotiveMode = automotiveMode;
    }
    public setModals(modals): void {
        this._slaStatusFilter.modals = modals;
    }
    public setTypeSearch(typeSearch): void {
        this._slaStatusFilter.typeSearch = typeSearch;
    }
    public setSearch(search): void {
        this.search = search;
    }
    public setTypeSwitch(typeSwitch): void {
        this._slaStatusFilter.typeSwitch = typeSwitch;
    }
    public setFilterValue(filterValue): void {
        this.filterValue = filterValue;
    }
    public setFilterField(filterField): void {
        this.filterField = filterField;
    }

    public updatePreferences(force?) {
        const filter = _.cloneDeep(this._slaStatusFilter);
        filter.modals.forEach((modal) => { modal.itemName = modal.itemName
            .replace('MARITIME', 'Marítimo').replace('AIR', 'Aéreo').replace('ROADS', 'Rodoviário') });
        this._initialSlaStatusFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialSlaStatusFilter, filter, 'slaStatusFilter', this._userCacheService.getUserPreferences().id, force);
    }

    private _defineModalName(name) {
        switch (name) {
            case 'AIR':
            case 'Aéreo':
                return 'Aéreo'
            case 'MARITIME':
            case 'Marítimo':
                return 'Marítimo'
            case 'ROADS':
            case 'Rodoviário':
                return 'Rodoviário'
        }
    }
}
