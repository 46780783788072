import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

type Placements = 'top' | 'right' | 'bottom' | 'left';

@Directive({
    selector: '[popover]',
})
export class CMXPopoverDirective {
    @Input('popover') popoverTitle: string;
    @Input('popover-content') popoverContent;
    @Input('popover-header-color') headerColor = null;
    @Input() placement?: Placements = 'top';
    @Input() nowrap?: boolean = false;
    @Input() delay?: number = 300;
    @Input() checkEllipsisActive?: boolean = false;

    private _popover: HTMLElement;

    constructor(private _el: ElementRef, private _renderer: Renderer2) {}

    @HostListener('mouseenter') onMouseEnter() {
        if (this.popoverTitle && !this._popover) {
            if ((this.checkEllipsisActive && this._isEllipsisActive()) || !this.checkEllipsisActive) {
                this._show();
            }
        }
    }

    @HostListener('mouseleave') onMouseLeave() {
        if (this._popover) {
            this._hide();
        }
    }

    @HostListener('click', ['$event'])
    onClick(event: any): void {
        if (this._popover) {
            this._hide();
        }
        event.stopPropagation();
    }

    private _show() {
        this._create();
        this._setPosition();
        this._setClass('cmx-popover-show');
    }

    private _hide() {
        this._renderer.removeClass(this._popover, 'cmx-popover-show');
        window.setTimeout(() => {
            if(this._popover){
                this._renderer.removeChild(document.body, this._popover);
                this._popover = null;
            }
        }, this.delay);
    }

    private _create() {
        this._popover = this._renderer.createElement('div');
        this._renderer.setProperty(this._popover, 'innerHTML', `<div class="popover-header" style="background-color:${this.headerColor}"><h5>${this.popoverTitle}</h5></div><div class="popover-content">${this.popoverContent}</div>`);

        this._renderer.appendChild(document.body, this._popover);

        this._setClass('cmx-popover');
        this._setClass(`cmx-popover-${this.placement}`);

    }

    private _setStyle(property: string, value: any): void {
        if (property === 'transition') {
            const browserCompatibilitiesPrefix = ['-webkit-', '-moz-', '-o-', ''];

            for (const browserPrefix of browserCompatibilitiesPrefix) {
                this._renderer.setStyle(this._popover, `${browserPrefix}${property}`, value);
            }
        } else {
            this._renderer.setStyle(this._popover, property, value);
        }
    }

    private _setClass(name: string): void {
        this._renderer.addClass(this._popover, name);
    }

    private _setPosition(): void {
        const hostPosition = this._el.nativeElement.getBoundingClientRect();
        const tooltipPosisition = this._popover.getBoundingClientRect();

        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        const offset = 10;

        const positionsByPlacement = {
            top: [
                hostPosition.top - tooltipPosisition.height - offset,
                hostPosition.left + (hostPosition.width - tooltipPosisition.width) / 2,
            ],

            right: [
                hostPosition.top + (hostPosition.height - tooltipPosisition.height) / 2,
                hostPosition.right + offset,
            ],

            bottom: [
                hostPosition.bottom + offset,
                hostPosition.left + (hostPosition.width - tooltipPosisition.width) / 2,
            ],

            left: [
                hostPosition.top + (hostPosition.height - tooltipPosisition.height) / 2,
                hostPosition.left - tooltipPosisition.width - offset,
            ],
        };

        this._setStyle('top', `${positionsByPlacement[this.placement][0] + scrollPosition}px`);

        this._setStyle('left', `${positionsByPlacement[this.placement][1]}px`);
    }

    private _isEllipsisActive(): boolean {
        return this._el.nativeElement.offsetWidth < this._el.nativeElement.scrollWidth;
    }
}
