import { environment } from '#environment';
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class DocumentsGedCRUDService {

    redirectEnabled;

    constructor(private http: HttpClient, private _featureFlagService: FeatureFlagService) { 
        this.getFeatureFlag();
    }

    public getDocumentsConfiguration(clientGroupCode): Observable<any> {
        if (this.redirectEnabled) {
            return this.http.get(`${ environment.endpoints.documentQueryService}/v1/search-client-config/${clientGroupCode}`);
        }
        return this.http.get(`${ environment.endpoints.documentService}/v1/search-client-config/${clientGroupCode}`);
    }

    public saveDocumentsConfiguration(clientGroupCode, configuration): Observable<any> {
        if (this.redirectEnabled) {
            return this.http.put(`${ environment.endpoints.documentCommandService}/v1/update-client-config/${clientGroupCode}`, configuration);
        }
        return this.http.put(`${environment.endpoints.documentService}/v1/update-client-config/${clientGroupCode}`, configuration);
    }

    async getFeatureFlag(): Promise<void> {
        this.redirectEnabled = await this._featureFlagService.isFeatureFlagEnabledV2(FeatureFlagService.FEATURE_REDIRECT_GED_V2);
    }

}