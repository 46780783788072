export const TAX_STUDY_FORM_SCRIPT = {
    // Estudo Tributário (Dados do Cliente)
    'tax-study-client-data-form': {
        noFocus: { newFocus: 'segment', dropdown: true },
        segment: { newFocus: 'companyCnpj' },
        companyCnpj: { newFocus: 'acquirer' },
        acquirer: { newFocus: 'leaseHolder' },
        leaseHolder: { newFocus: 'mainBusiness', dropdown: true },
        mainBusiness: { newFocus: 'costCenter', dropdown: true },
        costCenter: { newFocus: 'importModality', dropdown: true },
        importModality: { newFocus: 'secondaryImportModality', dropdown: true },
        secondaryImportModality: { newFocus: 'tertiaryImportModality', dropdown: true },
        tertiaryImportModality: { newFocus: 'destination', dropdown: true },
        destination: { newFocus: 'icmsTaxpayer', switch: true },
        icmsTaxpayer: { newFocus: 'comexportBranch', dropdown: true },
        comexportBranch: { newFocus: 'comexportSpecialRegime', dropdown: true },
        comexportSpecialRegime: { newFocus: 'specialTaxRegime', dropdown: true },
        specialTaxRegime: { newFocus: 'icmsTaxpayerPhysicalDestination', switch: true },
        icmsTaxpayerPhysicalDestination: { newFocus: 'originUF', dropdown: true },
        originUF: { newFocus: 'customsClearanceLocation', dropdown: true },
        customsClearanceLocation: { newFocus: 'destinationUF', dropdown: true },
        destinationUF: { newFocus: 'physicalDeliveryLocation', dropdown: true },
        physicalDeliveryLocation: { newFocus: 'observation' },
        observation: { newFocus: 'segment' },
    },
    // Estudo Tributário (Solicitação de NCMs)
    'tax-study-operational-ncms-form': {
        noFocus: { newFocus: 'ncm' },
        ncm: { newFocus: 'cest' },
        cest: { newFocus: 'exTariffII' },
        exTariffII: { newFocus: 'exTariffIPI' },
        exTariffIPI: { newFocus: 'productCondition', dropdown: true },
        productCondition: { newFocus: 'countryOfOrigin', dropdown: true },
        countryOfOrigin: { newFocus: 'domesticManufacture', switch: true },
        domesticManufacture: { newFocus: 'importedProductPurchasedNationally', switch: true },
        importedProductPurchasedNationally: { newFocus: 'energyEfficiency' },
        energyEfficiency: { newFocus: 'mass' },
        mass: { newFocus: 'ncTipi' },
        ncTipi: { newFocus: 'substituteIcmsSt', switch: true },
        substituteIcmsSt: { newFocus: 'drugClassification', dropdown: true },
        drugClassification: { newFocus: 'drugType', dropdown: true },
        drugType: { newFocus: 'drugTax', dropdown: true },
        drugTax: { newFocus: 'secondaryDrugTax', dropdown: true },
        secondaryDrugTax: { newFocus: 'pmcValue' },
        pmcValue: { newFocus: 'pmcDiscountPercentage' },
        pmcDiscountPercentage: { newFocus: 'productDescription' },
        productDescription: { newFocus: 'ncm' },
    },
    // Estudo Tributário (Resposta de NCMs)
    'tax-study-tributary-ncms-form': {
        noFocus: { newFocus: 'icmsImportation' },
        icmsImportation: { newFocus: 'isNationalSimilar', switch: true },
        isNationalSimilar: { newFocus: 'icmsExitTaxOrLoad' },
        icmsExitTaxOrLoad: { newFocus: 'legalBaseIcmsExitTaxOrLoad' },
        legalBaseIcmsExitTaxOrLoad: { newFocus: 'bcIcmsReduction', switch: true },
        bcIcmsReduction: { newFocus: 'legalBaseBcIcmsReduction' },
        legalBaseBcIcmsReduction: { newFocus: 'tributaryIcmsLoad' },
        tributaryIcmsLoad: { newFocus: 'profitabilityLegal' },
        profitabilityLegal: { newFocus: 'icmsSt', dropdown: true },
        icmsSt: { newFocus: 'ivaStAdjustedDestination' },
        ivaStAdjustedDestination: { newFocus: 'ivaStOriginal' },
        ivaStOriginal: { newFocus: 'internalRateDestinationForSt' },
        internalRateDestinationForSt: { newFocus: 'icmsStPriceList' },
        icmsStPriceList: { newFocus: 'stByProtocolOrAgreement' },
        stByProtocolOrAgreement: { newFocus: 'importTax' },
        importTax: { newFocus: 'legalBaseImportTax' },
        legalBaseImportTax: { newFocus: 'ipiTax' },
        ipiTax: { newFocus: 'legalBaseIpiTax' },
        legalBaseIpiTax: { newFocus: 'pisImport' },
        pisImport: { newFocus: 'legalBasePisImport' },
        legalBasePisImport: { newFocus: 'cofinsImport' },
        cofinsImport: { newFocus: 'legalBaseCofinsImport' },
        legalBaseCofinsImport: { newFocus: 'pisBilling' },
        pisBilling: { newFocus: 'legalBasePisBilling' },
        legalBasePisBilling: { newFocus: 'cofinsBilling' },
        cofinsBilling: { newFocus: 'legalBaseCofinsBilling' },
        legalBaseCofinsBilling: { newFocus: 'isIpiBaseForIcms', switch: true },
        isIpiBaseForIcms: { newFocus: 'originalLock' },
        originalLock: { newFocus: 'destinationInternalTaxRate' },
        destinationInternalTaxRate: { newFocus: 'interstateTaxRate' },
        interstateTaxRate: { newFocus: 'adjustedLock' },
        adjustedLock: { newFocus: 'responsibleName' },
        responsibleName: { newFocus: 'conclusionDate' },
        conclusionDate: { newFocus: 'reviewer' },
        reviewer: { newFocus: 'reviewDate' },
        reviewDate: { newFocus: 'observationResponse' },
        observationResponse: { newFocus: 'icmsImportation' },
    }
}