import { IGridColumn } from "app/shared/components/cmx-custom-grid/interfaces";

export const SLICK_COLUMNS: IGridColumn[] = [{
    name: 'Id',
    field: 'id',
  },
  {
    name: 'CountryCode',
    field: 'countryCode',
  },
  {
    name: 'País',
    field: 'country',
    width: 120,
    headerCssClass: 'grid-header align-left',
    cssClass: 'grid-cell align-left',
    formatterData: {
        hoverTitle: true,
    },
  },
  {
    name: 'Porto / Aeroporto',
    field: 'name',
    width: 150,
    headerCssClass: 'grid-header align-left',
    cssClass: 'grid-cell align-left',
    formatterData: {
        hoverTitle: true,
    },
  },
  {
    name: 'Tipo',
    field: 'type',
    width: 150,
    headerCssClass: 'grid-header align-left',
    cssClass: 'grid-cell align-left',
    formatterData: {
        hoverTitle: true,
    },
  },
  {
    name: 'Última atualização',
    field: 'lastUpdated',
    width: 200,
    headerCssClass: 'grid-header align-left',
    cssClass: 'grid-cell align-left',
  },
  {
    name: '',
    field: 'delete',
    formatterType: 'button',
    formatterData: {
        buttonType: 'rounded',
        iconType: 'material',
        icon: 'close',
        color: 'danger',
        size: 'sm'
    }
  }]
