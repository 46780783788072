<div *ngIf="asyncCustomization" class="fullLoader">
    <span class="exporting">Salvando Preferências</span>
    <loader></loader>
</div>

<cmx-custom-dialog 
    id="costs-customization-modal" 
    [title]="'Custos - Customização'" 
    content="full"
    overlay="true"
    animation="bottom-top"
    customColorBorderTop="#495057">
    <ng-container cmx-custom-dialog-body>
        
        <div style="display: flex; height: 100%">
            <div style="width: 30%; margin-right: 20px;">
                <cmx-save-config
                    [saveList]="reportPreferencesService.saveList"
                    [selectedSave]="reportPreferencesService.selectedSave"
                    [startAsNew]="true"
                    [iconsBaseHeight]="'calc(100vh - 420px)'"
                    [savesBaseHeight]="'calc(100vh - 325px)'"
                    [forceClear]="forceClear"
                    (onChange)="onSaveChanges($event)"
                    (onNew)="onNewSave($event)"
                    (onDelete)="onDeleteSave($event)"
                    (onSelect)="onSelectSave($event)"
                ></cmx-save-config>
            </div>
    
            <div *ngIf="!costsService.customizeAsync"
            style="width: 75%;
            border-left: 1px solid grey;
            padding-left: 20px;">
                <cmx-drag-and-drop-table
                    [tableType]="'grouped'"
                    [title]="'Despesas'"
                    [leftTitle]="'Visíveis'"
                    [leftIcon]="'check_circle_outline'"
                    [leftData]="reportPreferencesService.leftData"
                    [defaultLeftData]="reportPreferencesService.defaultLeftData"
                    [leftColor]="'#314968'"
                    [rightTitle]="'Ocultos'"
                    [rightIcon]="'block'"
                    [rightData]="reportPreferencesService.rightData"
                    [rightColor]="'#ad0000'"
                    [search]="'Despesa'"
                    [cleanSearch]="true"
                    [enableChangeColumnGroup]="false"
                    [enableGroupRename]="false"
                    [enableGroupReorder]="false"
                    [enableResetNames]="false"
                    [enableRearrangeGroups]="false"
                    [enableResetAll]="false"
                    [enableColumnCreate]="false"
                    [enableRightDrag]="false"
                    [leftContainerHeightBase]="'calc(100vh - 320px)'"
                    [rightContainerHeightBase]="'calc(100vh - 320px)'"
                    (onFinishButton)="saveCustomChanges($event)">
                </cmx-drag-and-drop-table>
                <div *ngIf="reportPreferencesService.currentDefaultReport" class="util-columns-button-container">
                    <div (click)="mergeUtilColumns()">Apenas Colunas c/ Valor</div>
                </div>
            </div>
        </div>

    </ng-container>
</cmx-custom-dialog>