import 'moment/locale/pt-br';

import { Grouping, PeriodGrouping } from '#services/_results/results.service';
import moment from 'moment';

import { StructureSettings } from './constants/structure-settings';

export class ColumnVisitor {
    constructor(
        private grouping: Grouping,
        private settings: StructureSettings,
        private columnsWithAmounts: Set<string>,
    ) {}

    public visit(columns: any[]): any[] {
        if (this.grouping === Grouping.byMonth) {
            this.formatPeriodHeader(columns);
        }

        if (this.settings.columnTotal === 'hideColumnTotal') {
            return columns.filter(this.removingTotal);
        }

        return columns.filter((column) =>
                column.field === 'description' ||
                column.field === 'TOTAL' ||
                this.columnsWithAmounts.has(column.field));
    }

    private formatPeriodHeader(columns: any[]) {
        columns
            .filter(this.removingDescription)
            .filter(this.removingTotal)
            .forEach((column) => {
                const periodGrouping = this.settings.periodGrouping;

                if (periodGrouping === PeriodGrouping.month) {
                    const formattedDate = moment(column.header, 'YYYYMM').format(
                        'MMM/YYYY',
                    );
                    column.header =
                        formattedDate.charAt(0).toUpperCase() +
                        formattedDate.slice(1);
                    return;
                }

                const year = column.header.substring(0, 4);

                if (periodGrouping === PeriodGrouping.year) {
                    column.header = year;
                    return;
                }

                const cardinal = column.header.charAt(column.header.length - 1);
                const groupingText =
                    periodGrouping === PeriodGrouping.quarter
                        ? 'Trim'
                        : periodGrouping === PeriodGrouping.halfYear
                            ? 'Sem'
                            : 'Desconhecido';

                column.header = `${cardinal}º ${groupingText}/${year}`;
            });
    }

    private removingTotal(column: any) {
        return column.field !== 'TOTAL';
    }

    private removingDescription(column: any) {
        return column.field !== 'description';
    }
}
