import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { environment } from '#environment';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { UserService } from 'app/auth/_services';
import { ChartsServices } from './../charts.services';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { TransportModalService } from '#services/_transportModal/transportModal.service';
import { UtilsService } from '#services/_utils/utils.service';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { LogisticCostsFilter } from './logistic-costs.filter';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';

@Component({
    selector: 'logistic-costs-chart',
    templateUrl: './logistic-costs.component.html',
    styleUrls: ['./../charts.scss', './logistic-costs.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LogisticCostsComponent {
    filterState: LogisticCostsFilter = this._filterStateService.getLogisticCostsFilter();
    subscription;
    editableElements = ['#logisticCosts', '.checkmark-container'];
    periodType = 'LAST_12_MONTHS';
    asyncLogisticCosts = false;
    chartData;
    costsOptions: DropdownOption[] = [];
    subsidiaryNames: DropdownOption[] = [];
    summaryLine = [];
    filterTypes = [{ name: 'Média' }, { name: 'Total' }, { name: 'Tonelada Líquida' }]
    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);

    readonly dropdownSettingsCosts = {
        text: 'Custo Operacional',
        maxHeight: 200,
        badgeShowLabel: 'Custos logísticos selecionados',
    };

    clientOptions: DropdownOption[] = [];
    modalOptions: DropdownOption[] = [];
    currentUser$ = this.userService.currentUser$;
    currentUser = this.userService.getCurrentUser();
    isInternalUser = this.currentUser.has('INTERNAL_FILTERS') ? true : false;
    isMixedUser = this.currentUser.has('ROUTE_AUTOMOTIVE') && !this.currentUser.has('AUTOMOTIVE_USER') ? true : false;
    manualSearch = this.isInternalUser;
    public typeSwitch = {
        values: ['Média', 'Total'],
        index: 0,
    };
    public visualSwitch = {
        values: ['Colunas', 'Linhas'],
        index: 0,
    };

    periodTypes = [
        {
            code: 'CURRENT_YEAR',
            name: 'Ano Corrente',
        },
        {
            code: 'LAST_12_MONTHS',
            name: '12 Meses',
        },
    ];

    preSelectedPeriod = ['CURRENT_YEAR'];
    selectedPeriod = _.cloneDeep(this.preSelectedPeriod);
    helpDescription = this._defineHelpDescription();

    @HostListener('click') onSetZIndex() {
        this.renderer2.setStyle(this._elementRef.nativeElement, 'z-index', this.utilsService.autoIncrementValue);
    }

    constructor(
        public chartService: ChartsServices,
        public router: Router,
        private userService: UserService,
        private http: HttpClient,
        private utilsService: UtilsService,
        private userCacheService: UserCacheService,
        private transportModalService: TransportModalService,
        private _cmxDropdownService: CmxDropdownService,
        private _elementRef: ElementRef,
        private renderer2: Renderer2,
        private _filterStateService: FilterStateService,
        private _utilsService: UtilsService
    ) {}

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.subsidiaryNames = this._cmxDropdownService.toDropdownItems(this.userCacheService.getSubsidiaries());
        this.clientOptions = this._cmxDropdownService.toDropdownItems(this.userCacheService.getClientGroups());
        this.modalOptions = this._cmxDropdownService.toDropdownItemsImg(
            _.cloneDeep(this.transportModalService.getModalList())
        );
        this.loadOperationalCosts().subscribe((data) => {
            this.costsOptions = this._cmxDropdownService.toDropdownItems(data);
        });
        if (this.clientOptions.length === 1) {
            this.loadLogisticCosts('LAST_12_MONTHS');
        }
    }

    public toggleFilterMode() {
        if ($('logistic-costs-chart .filterMode').hasClass('clicked')) {
            this.filterState.setAutomotiveMode('Desligado');
            $('logistic-costs-chart .filterMode').removeClass('clicked');
        } else {
            this.filterState.setAutomotiveMode('Ligado');
            $('logistic-costs-chart .filterMode').addClass('clicked');
        }
        this.loadLogisticCosts(this.periodType);
    }

    toggleTypeSwitch(value) {
        this.filterState.setType(value?.name);
        this.loadLogisticCosts(this.periodType);
    }

    toggleVisualSwitch(values) {
        this.filterState.setVisual(values.value);
        this.loadLogisticCosts(this.periodType);
    }

    toggleStorage() {
        if ($('logistic-costs-chart .storageToogle').hasClass('clicked')) {
            $('logistic-costs-chart .storageToogle').removeClass('clicked');
            this.filterState.setStorageToogle('Desconsiderando');
        } else {
            $('logistic-costs-chart .storageToogle').addClass('clicked');
            this.filterState.setStorageToogle('Considerando');
        }
        this.loadLogisticCosts(this.periodType);
    }

    changeDateParam({ selected }): void {
        let periodCod = selected[0].code;

        if (periodCod === 'CURRENT_YEAR' || periodCod === 'LAST_12_MONTHS') {
            this.periodType = periodCod;
            this.loadLogisticCosts(periodCod);
        } else {
            this.periodType = '';
            this.filterState.setStartDate(moment().add(-periodCod, 'days').format('DD/MM/YYYY'));
            this.filterState.setEndDate(moment().format('DD/MM/YYYY'));
            this.loadLogisticCosts();
        }
    }

    loadLogisticCosts(preset?) {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.filterState.getClientGroups().length === 0 && this.isInternalUser) {
            this.asyncLogisticCosts = false;
            this.manualSearch = true;
        } else {
            this.manualSearch = false;
            this.asyncLogisticCosts = true;
            this.utilsService.setInvisible('logistic-costs-chart', this.editableElements);
            this.subscription = this.getLogisticCosts(preset).subscribe(
                (logisticCosts) => {
                    this.chartData = logisticCosts;
                    logisticCosts.consolidatedCosts.forEach((cost) => {
                        cost.value = cost.value.toFixed(2);
                    });
                    this._createChart(logisticCosts.consolidatedCosts);
                    this._populateSummaryLine(logisticCosts.consolidatedCosts);
                    this.asyncLogisticCosts = false;
                    this.utilsService.setVisible('logistic-costs-chart', this.editableElements);
                },
                (error) => {
                    this.chartData = undefined;
                    this.asyncLogisticCosts = false;
                    this.utilsService.errorHandler(error, 'Custos Logísticos');
                }
            );
        }
    }

    private _populateSummaryLine(costs) {
        const total = costs.reduce((acc, p) => acc + parseFloat(
            this.filterState.getType() === 'Média' ? p.value : p.totalValue), 0);
        this.summaryLine = [
            {
                title: 'Valor Total',
                value: this._utilsService.formatNumber(total || 0, { style: 'currency' }) || 0,
            }
        ];
    }

    private loadOperationalCosts() {
        return this.http.get(`${environment.endpoints.expensesService}/costs/operational`);
    }

    private getLogisticCosts(preset?): Observable<any> {
        const request = this.filterState.getRequest(this.costsOptions, preset);
        return this.http.get(`${environment.endpoints.expensesService}/importationExpenses/logistic-cost`, request);
    }

    private _createChart(data) {
        const chart = am4core.create('logisticCosts', am4charts.XYChart);
        chart.language.locale['_decimalSeparator'] = ',';
        chart.language.locale['_thousandSeparator'] = '.';
        chart.data = data;
        chart.maskBullets = false;
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'date';
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.fontSize = 9;
        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = '[bold]Custos em R$ milhares[/]';
        valueAxis.title.fontSize = 11;
        valueAxis.fontSize = 9;
        valueAxis.extraMax = 0.05;
        let totalLine = chart.series.push(new am4charts.LineSeries());
        totalLine.dataFields.valueY = this.filterState.getType() === 'Média' ? 'limit' : '';
        totalLine.dataFields.categoryX = 'date';
        totalLine.stroke = am4core.color('#FF0000');

        if (this.filterState.getVisual() === 'Colunas') {
            let column = chart.series.push(new am4charts.ColumnSeries());
            column.dataFields.valueY = this.filterState.getType() === 'Média' ? 'value' : 'totalValue';
            column.dataFields.categoryX = 'date';
            column.columns.template.fillOpacity = 0.8;
            column.columns.template.strokeWidth = 0;
            column.columns.template.strokeOpacity = 1;
            column.columns.template.adapter.add('fill', () => {
                return <any>'#1f3d7a';
            });
            totalLine.strokeWidth = 3;
            let bullet = column.bullets.push(new am4charts.LabelBullet());
            let label = bullet.label;
            label.text = '{valueY}';
            bullet.fontSize = 10;
            bullet.fontWeight = '600';
            label.truncate = false;
            label.hideOversized = false;
            bullet.valign = "top";
            bullet.dy = -10;
            bullet.strokeWidth = 0;
        } else {
            totalLine.strokeWidth = 2;
            let line = chart.series.push(new am4charts.LineSeries());
            line.dataFields.valueY = this.filterState.getType() === 'Média' ? 'value' : 'totalValue';
            line.dataFields.categoryX = 'date';
            line.strokeWidth = 2;
            line.tensionX = 0.77;
            let bullet = line.bullets.push(new am4charts.Bullet());
            bullet.tooltipText = '{valueY}';
            if (data.every((value, i, arr) => value.limit === arr[0].limit) && this.filterState.getType() === 'Média') {
                let range = valueAxis.createSeriesRange(line);
                range.value = data[0].limit;
                range.endValue = 99999999;
                range.contents.stroke = am4core.color('#FF0000');
                range.contents.fill = range.contents.stroke;
                range.label.inside = true;
                range.label.text = 'Limite Desejado';
                range.label.fill = range.grid.stroke;
            }

            chart.cursor = new am4charts.XYCursor();
        }
        $("g[filter*='id']").remove();
    }

    private _defineHelpDescription(): object[] {
        return [
            { type: 'text', value: "Informa a quantidade total ou média de custos logísticos dos últimos 12 meses ou ano corrente."},
        ]
    }
}
