import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import moment from 'moment';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { IPoStatusFilter } from './interfaces/po-status-filter.interface';

export class POStatusFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialPoFilter: IPoStatusFilter = <IPoStatusFilter>{};
    private _poFilter: IPoStatusFilter = <IPoStatusFilter>{
        searchType: 'P.O',
        poNumber: '',
        customerPONumber: '',
        subsidiaryClients: [],
        subsidiaryNames: [],
        clientGroups: [],
        referenceDateType: "registration",
        status: [],
        percentRange: [],
        filterType: 'Fulfillment'
    };

    private startDate;
    private endDate;
    private offset: number;
    private limit: number;
    private _activeFilters: { [key: string]: string } = {};
    private _filterField: string;
    private _filterValue: string;

    constructor(filter?: POStatusFilter) {
        this._poFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'poStatusFilter', this._poFilter, filter);
        this._initialPoFilter = _.cloneDeep(this._poFilter);
        this.startDate = filter ? filter.startDate : moment().add(-6, 'M').format('DD/MM/YYYY');
        this.endDate = filter ? filter.endDate : moment().format('DD/MM/YYYY');
        this.offset = filter ? filter.offset : 0;
        this.limit = filter ? filter.limit : 40;
    }

    getSubsidiaryClients() { return this._poFilter.subsidiaryClients || [] }
    getSubsidiaryNames() { return this._poFilter.subsidiaryNames || [] }
    getClientGroups() { return this._poFilter.clientGroups || [] }
    getReferenceDateType(): DropdownOption[] { return this._poFilter.referenceDateType }
    getStartDate() { return this.startDate }
    getEndDate() { return this.endDate }
    getOffset(): number { return this.offset }
    getLimit(): number { return this.limit }
    getStatus(): any[] { return this._poFilter.status }
    getFilterType(): string { return this._poFilter.filterType }
    getPercentRange(): any[] { return this._poFilter.percentRange }
    getFilterField() { return this._filterField; }
    getFilterValue() { return this._filterValue; }

    setClientGroups(clientGroups): void { this._poFilter.clientGroups = clientGroups }
    setSubsidiaryClients(subsidiaryClients): void { this._poFilter.subsidiaryClients = subsidiaryClients }
    setSubsidiaryNames(subsidiaryNames): void { this._poFilter.subsidiaryNames = subsidiaryNames }
    setReferenceDateType(referenceDateType): void { this._poFilter.referenceDateType = referenceDateType }
    setStartDate(startDate): void { this.startDate = startDate }
    setEndDate(endDate): void { this.endDate = endDate }
    setOffset(offset): void { this.offset = offset }
    setLimit(limit): void { this.limit = limit }
    setStatus(status): void { this._poFilter.status = status }
    setFilterType(filterType): void { this._poFilter.filterType = filterType }
    setPercentRange(percentRange): void { this._poFilter.percentRange = percentRange }
    setFilterField(filterField): void { this._filterField = filterField }
    public setFilterValue(value): void {
        this._filterValue = value;
        this._activeFilters[this._filterField] = this._filterValue;
    }

    getRequest() {
        this._initialPoFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialPoFilter, this._poFilter, 'poStatusFilter', this._userCacheService.getUserPreferences().id);
        let params = new HttpParams();
        let headers = new HttpHeaders();
        headers = headers.set('X-Requested-Client-Groups', 
        this.getClientGroups().map((c) => c.id).join(','));
        Object.keys(this._activeFilters).forEach((key) => {
            let filterValue = this._activeFilters[key];
            if (filterValue) {
                if (key === 'poItems[*].quantity' || key === 'poItems[*].quantityMet' 
                    || key === 'poItems[*].quantityDeducted' || key === 'poItems[*].balance' || key === 'poItems[*].grossValue') {
                    filterValue = parseFloat(filterValue.replace('.', '').replace(',', '.')).toString();
                }
                if (key === 'tradingPONumber' && filterValue) {
                    filterValue += `%%poItems[*].customerSKU::${filterValue}`;
                }
                params = params.append('field', `${key}::${filterValue}`);
            }
        });
        params = params.append('startDate', `${
            moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`);
        params = params.append('endDate', `${
            moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`);
        params = params.append('customerCNPJ', this.getSubsidiaryClients().map((c) => c.id).join(','));
        params = params.append('comexportSubsidiary', this.getSubsidiaryNames().map((c) => c.id).join(','));
        params = params.append('status', this.getFilterType() === 'Status' ? this.getStatus()?.map((c) => c.code).join(',') : '');
        params = params.append('percentRange', this.getFilterType() === 'Fulfillment' || this.getFilterType() === 'SKU' ? this.getPercentRange()?.map((c) => c.code).join(',') : '');
        params = params.append('page', `${this.getOffset()}`);
        params = params.append('size', `${this.getLimit()}`);
        return { params, headers };
    }

    private _treatNumericValues(value) {

    }

}
