<div *ngIf="asyncReleases == true">
    <loader></loader>
</div>

<i (click)="close()" id="close" class="la la-close"></i>

<div id="releaseModal" class="releasesCrudComponent">

    <div class="admin-account-releases">
        <div class="line-space">
            <span class="admin-account-title">Releases</span>
            <cmx-listing
                [list]="releaseList"
                height="calc(100vh - 180px)"
                searchPlaceholder="Buscar Release"
                [hasAddButton]="true"
                (onSelect)="selectRelease($event)"
                (onNewItem)="startNewRelease()"
            ></cmx-listing>
            <div *ngIf="pages.length > 1">
                <pagination [numberOfPages]="pages.length" [queryId]="queryId" (onPageChange)="changePage($event)" [maxPages]="MAX_PAGES"></pagination>
            </div>
        </div>
    </div>


    <!-- <div class="releaseListComponent">
        <div class="searchComponent">
            <input (keyup)="enterBtn($event)" autocomplete="off" id="releaseSearch" type="text" name="user"
                placeholder="Buscar Release" [(ngModel)]="releaseSearch">
            <a (click)="startNewRelease()" class="addBtn"><span>+</span></a>
        </div>
        <hr>
        <div class="table">
            <table>
                <tr *ngFor="let release of releaseList; let i = index; trackBy: trackByFn">
                    <td (click)="selectRelease(release, $event)">{{release.title}}</td>
                </tr>
            </table>
        </div>
        <div *ngIf="pages.length > 1">
            <pagination [numberOfPages]="pages.length" [queryId]="queryId" (onPageChange)="changePage($event)" [maxPages]="MAX_PAGES"></pagination>
        </div>
    </div> -->
    <div class="releaseInputsComponent admItem">
        <div class="admFormRelease">
            <a (click)="deleteMode = 'release';admService.openDeleteModal(modal)" class="deleteBtn release"><i
                    class="la la-trash"></i></a>
            <label>Release</label>
            <input class="admInput release" type="text" [ngModel]="selectedRelease.getTitle()"
                (ngModelChange)="selectedRelease.setTitle($event)">
            <label>Lista de Mudanças</label>
            <div class="featureListComponent">
                <table>
                    <tr (click)="selectFeature(feature)" *ngFor="let feature of selectedRelease.features; let i = index; trackBy: trackByFn"
                        class="{{feature.type.code}}">
                        <td>
                            <span class="title">{{feature.title}}</span>
                            <a *ngIf="selectedRelease.features.length > 1"
                                (click)="deleteMode = 'feature';admService.openDeleteModal(modal)"
                                class="deleteBtn"><i class="la la-trash"></i></a>
                        </td>
                    </tr>
                </table>
            </div>
            <a (click)="addNewFeature()" class="addBtn feature"><span>+</span></a>
        </div>
    </div>
    <div class="releaseFeaturesComponent admItem">
        <div class="admForm">
            <label>Mudança</label>
            <input class="admInput" type="text" [ngModel]='selectedFeature.getTitle()'
                (ngModelChange)="selectedFeature.setTitle($event)">
            <label style="display: flex; justify-content: space-between">Descrição
                <div class="typeContainer">
                    <span *ngFor="let type of featureTypeList; trackBy: trackByFn" (click)="toogleFeatureType(type, $event)"
                        class="featureType {{type.code}}"
                        [ngClass]="selectedFeature.type.code == type.code ? 'selected' : null">{{type.name}}</span>
                </div>
            </label>
            <textarea class="admInput" cols="30" rows="2" [ngModel]="selectedFeature.getDescription()"
                (ngModelChange)="selectedFeature.setDescription($event)"></textarea>
            <label style="margin-top: 4px !important">Permissões</label>
            <cmx-dropdown (onScroll)="scrollList($event)" [(ngModel)]="dropdown.permissions.selected"
                [data]="dropdown.permissions.options" [settings]="dropdownSettingsPermissions" [styles]="{ width: '250px' }" size="md" id="permissions"
                name="damageSeverities" (onChange)="updatePermissions()"></cmx-dropdown>
            <label>Link</label>
            <input class="admInput" type="text" [ngModel]="selectedFeature.getLink()"
                (ngModelChange)="selectedFeature.setLink($event)">
            <div class="imageContainer">
                <label for='selecao-arquivo'>
                    <cmx-img-upload
                        #imageUpload
                        id="adm-group-release-cmx-img-upload"
                        [title]="'Anexo'"
                        [accept]="['image/png']"
                        [tooltipOptions]="{ show: true, position: 'right' }"
                        [required]="false"
                        [preview]="imagePreview"
                        (onChange)="addFile($event)"
                        [disable]="false"
                        height="20vh"
                        width="40vh"
                    ></cmx-img-upload>
                </label>
                <input (change)="addFile($event)" id='selecao-arquivo' type='file' accept="image/png">
            </div>
            <button *ngIf="newRelease == true" (click)="createRelease()" class="add">Salvar</button>
            <button *ngIf="newRelease == false" (click)="saveChanges(false)" class="edit">Editar</button>
        </div>
    </div>
    <div class="releasesInfo" id="releasesInfo">
        <p class="success create hidden"><i (click)='hideAllErrorOrSucess()' class="la la-close"></i>
            Criado com sucesso.</p>
        <p class="success edit hidden"><i (click)='hideAllErrorOrSucess()' class="la la-close"></i>
            Editado com sucesso.</p>
        <p class="success delete hidden"><i (click)='hideAllErrorOrSucess()' class="la la-close"></i>
            Deletado com sucesso.</p>
        <p class="error emptyDates hidden"><i (click)='hideAllErrorOrSucess()' class="la la-close"></i>
            Preencha as Datas de Vigência.</p>
        <p class="error emptyField hidden"><i (click)='hideAllErrorOrSucess()' class="la la-close"></i>
            Preencha o nome da Release.</p>
        <p class="error emptyFeatureName hidden"><i (click)='hideAllErrorOrSucess()' class="la la-close"></i>
            Preencha o nome da Mudança.</p>
        <p class="error emptyFeatureDescription hidden"><i (click)='hideAllErrorOrSucess()' class="la la-close"></i>
            Preencha a Descrição.</p>
    </div>

    <div class="deleteModal hidden">
        <p>Deseja deletar a
            {{deleteMode === 'release' ? 'Release ' + selectedRelease.title : 'Feature ' + selectedFeature.title}} ?
        </p>
        <div class="deleteButtons">
            <button (click)="confirmDelete()" class="delete">Deletar</button>
            <button (click)="admService.closeDeleteModal(modal)" class="cancel">Cancelar</button>
        </div>
    </div>

</div>
