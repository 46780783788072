<div *ngIf="!followUpStructureService.getAsyncCustomization()">
    <loader></loader>
</div>

<span class="title">
    <span>Customização (FollowUp)</span>
    <i class="material-icons selectedSave">edit</i
    ><span>{{ followUpModelService.getSelectedFollowUpEditModel()?.modelName }}</span>
</span>
<div class="customButtons">
    <i (click)="openInfoModal()" class="material-icons info">info</i>
    <span (click)="resetAllNames()" class="customButton resetNames"
        ><i class="material-icons">settings_ethernet</i>Resetar Nomes</span
    >
    <span (click)="callResetGroupOrder()" class="customButton resetGroups"
        ><i class="material-icons">view_week</i>Reordenar Grupos</span
    >
    <span (click)="resetAll()" class="customButton reset"><i class="material-icons">dashboard</i>Resetar Campos</span>
    <span (click)="createGroup()" class="customButton new"><i class="material-icons">add_box</i>Adicionar Grupo</span>
</div>
<div class="customContainer">
    <div
        id="{{group?.label}}"
        *ngFor="let group of followUpStructureService.getMergedFollowUp().groups"
        class="anchorContainer"
    >
        <div id="{{group?.name}}" class="titleContainer {{group?.new}}">
            <div class="anchorHandler"></div>
            <div
                *ngIf="group?.columns?.length > 0 && !group?.new"
                (click)="removeRewriteGroupColumns(group?.label)"
                class="anchorCleanner"
            >
                <i class="comexport-change"></i>
            </div>
            <div
                *ngIf="group?.columns?.length === 0 && !group?.new"
                (click)="removeRewriteGroupColumns(group?.label)"
                class="anchorCleanner empty"
            >
                <i class="comexport-change"></i>
            </div>
            <div (click)="removeGroup(group, $event)" class="anchorRemove" *ngIf="group?.new">
                <i style="pointer-events: none" class="la la-close"></i>
            </div>
            <span class="name" style="pointer-events: none">{{group?.name}}</span>
        </div>
        <div id="{{group?.label}}" class="columnsContainer">
            <div [attr.data-value]="stringify(column)" *ngFor="let column of group.columns" class="column">
                <span class="name" style="pointer-events: none">{{column?.label}}</span>
            </div>
        </div>
    </div>
</div>
<div class="trashContainer">
    <div class="iconContainer">
        <i class="material-icons">delete_forever</i>
    </div>
    <div id="Trash" class="trashColumns columnsContainer">
        <div
            [attr.data-value]="stringify(column)"
            *ngFor="let column of followUpStructureService.getTrashColumns()"
            class="column"
        >
            <span class="name" style="pointer-events: none">{{column?.label}}</span>
        </div>
    </div>
    <div class="mainButtons">
        <!-- <span (click)="openDeleteModal()" class="customButton delete"><i class="material-icons">delete</i>Deletar</span> -->
        <span (click)="openSavesModal()" class="customButton edit">Editar Modelos</span>
        <span (click)="saveCustom()" class="customButton save"><i class="material-icons">save</i>Salvar e Fechar</span>
    </div>
</div>

<div class="savesModal hidden">
    <report-followup-model (onChangeSaveModel)="mergeCustomFollowUp()"></report-followup-model>
</div>

<div class="overlayInfo hidden"></div>

<div class="deleteModal hidden">
    <div class="deleteModalContent">
        <div class="dangerContainer">
            <i class="material-icons">report_problem</i>
            <span>CUIDADO!</span>
            <i class="material-icons">report_problem</i>
        </div>
        <div class="content">
            <b>Ao deletar sua customização você perderá:</b>
            <span>• Grupos criados •</span>
            <span>• Nomes dos grupos customizados •</span>
            <span>• Todas Reordenações •</span>
        </div>
        <div class="buttons">
            <span (click)="deleteCustom()" class="delete"><i class="material-icons">save</i>Deletar</span>
            <span (click)="closeDeleteModal()">Cancelar</span>
        </div>
    </div>
</div>

<div class="closeConfirmationModal hidden">
    <div class="closeConfirmationContent">
        <div class="dangerContainer">
            <span>Você deseja sair?</span>
        </div>
        <div class="buttons">
            <span (click)="exitAndSave()" class="save"><i class="material-icons">save</i>Salvar e Sair</span>
            <span (click)="exitWithoutSave()" class="noSave">Sair sem Salvar</span>
            <span (click)="closeCloseOptions()">Cancelar</span>
        </div>
    </div>
</div>

<div class="infoModal hidden">
    <div class="infoContent">
        <i (click)="closeInfoModal()" class="close la la-close"></i>
        <span class="infoTitle"
            ><i class="material-icons">info</i>
            <span
                >Bem vindo(a), à customização do FollowUp. Aqui você pode alterar a estrutura do FollowUp de acordo com
                sua necessidade. Ao salvar, o relatório será modificado sem perder as configurações ao deslogar ou
                desligar sua máquina.</span
            >
        </span>
        <!-- <span class="sub">Aqui você pode alterar a estrutura do FollowUp de acordo com sua necessidade. Ao salvar, o relatório será modificado sem perder as configurações ao deslogar ou desligar sua máquina.</span> -->
        <hr />
        <span class="sub">Customizando Estrutura.</span>
        <div class="images">
            <div class="imageContainer">
                <img src="assets/img/tutos/fupCustom/betweenGroups.png" alt="" />
                <span class="explain">É possível mover colunas entre diferentes grupos...</span>
            </div>
            <div class="imageContainer">
                <img src="assets/img/tutos/fupCustom/changeColumnOrder.png" alt="" />
                <span class="explain">alterar a ordem das colunas dentro dos grupos...</span>
            </div>
            <div class="imageContainer">
                <img src="assets/img/tutos/fupCustom/toTrash.png" alt="" />
                <span class="explain">remover colunas do FollowUp, jogando-as na lixeira...</span>
            </div>
            <div class="imageContainer">
                <img src="assets/img/tutos/fupCustom/reorderGroups.png" alt="" />
                <span class="explain">e reordenar grupos, arrastando horizontalmente.</span>
            </div>
        </div>
        <hr />
        <span class="sub">Customizando Grupos (âncoras).</span>
        <div class="images">
            <div class="imageContainer">
                <img src="assets/img/tutos/fupCustom/addGroup.png" alt="" />
                <span class="explain">Para adicionar um grupo, basta clicar no botão acima...</span>
            </div>
            <div class="imageContainer">
                <img src="assets/img/tutos/fupCustom/renameGroup.png" alt="" />
                <span class="explain">sendo possível renomear um grupo novo ou pré existente...</span>
            </div>
            <div class="imageContainer">
                <img src="assets/img/tutos/fupCustom/deleteGroup.png" alt="" />
                <span class="explain">e remover grupos novos.</span>
            </div>
            <div class="imageContainer">
                <img src="assets/img/tutos/fupCustom/groupToTrash.png" alt="" />
                <span class="explain">Ao remover um grupo, suas colunas irão para a lixeira.</span>
            </div>
        </div>
        <hr />
        <span class="sub">Atalhos funcionais.</span>
        <div class="shortcutsContainer">
            <div><img src="assets/img/tutos/fupCustom/resetPositions.png" alt="" /></div>
            <span class="explain"
                >- Retorna todas as colunas para seus grupos originais e remove os novos grupos criados.</span
            >
        </div>
        <div class="shortcutsContainer">
            <div><img src="assets/img/tutos/fupCustom/reorderGroupsBtn.png" alt="" /></div>
            <span class="explain"
                >- Retorna todos os grupos para suas posições originais (reordenação horizontal).</span
            >
        </div>
        <div class="shortcutsContainer">
            <div><img src="assets/img/tutos/fupCustom/resetNames.png" alt="" /></div>
            <span class="explain">- Renomeia todos os grupos para o nome original.</span>
        </div>
        <div class="shortcutsContainer">
            <div><img src="assets/img/tutos/fupCustom/discardGroup.png" alt="" /></div>
            <span class="explain"
                >- Remove / Inclui todas as colunas de um determinado grupo. (inclui somente as colunas do grupo que
                estiverem na lixeira)</span
            >
        </div>
    </div>
</div>
