export const COST_HELP_DESCRIPTION = [
    { type: 'text', value: 'Realizar a inclusão do Ícone Informativo na tela inicial dos novos Custos:' },
    { type: 'text', value: 'Ao acessar a tela, por padrão, é apresentado o filtro com período de 1 mês, podendo ser alterado conforme necessidade do usuário.' },
    { type: 'text', value: 'Os valores são divididos por categorias e, dentro deles, separados por nome de cada custos.' },
    { type: 'text', hasLineAfter: true, value: 'É possível alterar a forma de visualização clicando no botão "Mudar Visão"' },
    { type: 'text', textBold: 'Detalhado:' },
    { type: 'text', value: 'Para visualizar a composição detalhada dos custos, basta clicar na aba Detalhado.' },
    { type: 'text', value: 'Será aberta uma janela, onde é possível selecionar se a visualização detalhada será por IMP , SKU ou NF' },
    { type: 'list', list: 'Quando a visualização selecionada for por IMP, a relação de itens será listada, informando o custo de cada IMP.' },
    { type: 'list', list: 'Quando a visualização selecionada for por SKU, a relação de itens será listada, informando o custo de cada item.' },
    { type: 'list', list: 'Quando a visualização selecionada for por NF, a relação de itens será listada, informando o custo de cada item por NF.' },
    { type: 'text', value: 'Ao selecionar o Filtro com U.C o sistema irá adicionar a coluna de valores por Unidade comercializada por despesa.' },
]