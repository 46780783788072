import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatBytes',
})
export class FormatBytes implements PipeTransform {
    
    constructor() {}
    
    transform(bytes: number, decimals = 2) {
        
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        const value = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
        
        const result = bytes === 0 ? `${bytes} ${sizes[0]}` : `${value} ${sizes[i]}`;

        return result;
    }
}
