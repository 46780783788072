<div class="moveBtn"><div class="moveLine"></div></div>

<div class="header-container">
    <div class="title-container">
        <h5>Alertas Operacionais</h5>
    </div>
</div>

<div class="config-button" (click)="openConfigModal()">
    <span class="material-icons">settings</span>
</div>

<div id="searchLine" class="reduzed" *ngIf="currentUser$ | async as user">
    <div class="search-container" *ngIf="user.has('INTERNAL_FILTERS')">
        <div class="input-group">
            <cmx-dropdown
                [styles]="{width: '220px'}"
                name="clients"
                [data]="options.client"
                [ngModel]="filterState.getClientGroups()"
                (ngModelChange)="filterState.setClientGroups($event)"
                [settings]="dropdownSettings.clientGroups"
            ></cmx-dropdown>
            <span class="input-group-addon" style="margin-left: -20px;"></span>
        </div>
        <div class="input-group middle-input">
            <cmx-dropdown
                [styles]="{width: '220px'}"
                name="subsidiary-names"
                [data]="options.subsidiaryNames"
                [ngModel]="filterState.getSubsidiaryNames()"
                (ngModelChange)="filterState.setSubsidiaryNames($event)"
                [settings]="dropdownSettings.subsidiaryNames"
            ></cmx-dropdown>
        </div>
        <button id="operational-alertsn-apply-button" class="applyImpStatus" (click)="applyFilter()">
            <em class="fa fa-check"></em>
        </button>
    </div>
</div>

<div class="operational-alerts-container">
    <ng-container *ngFor="let alert of alerts">
        <div class="alert-container" *ngIf="verifySelectedAlert(defineTitle(alert.title))">
            <ng-container *ngIf="loading"><loader></loader></ng-container>
            <ng-container *ngIf="!loading">
                <div class="header-container">
                    <div class="title-container">
                       <b class="title">{{ defineTitle(alert.title) }}</b>
                       <cmx-help-modal [help-title]="alert.title" [help-description]="defineHelpDescription(alert.type)"></cmx-help-modal>
                    </div>
                    <span class="subtitle" style="min-height: 15px">{{ alert?.subtitle }}</span>
                </div>
                <div class="chart-container">
                    <cmx-simple-line-chart
                        height="5px"
                        inverseLegend="true"
                        legendFontSize="10px"
                        legendSquareSize="10px"
                        minSectionSize="17%"
                        [clickable]="true"
                        [visualSelection]="false"
                        [legendBold]="false"
                        [chartData]="chartData[alert.type]"
                        (onSelect)="openDetail($event, alert)"   
                    ></cmx-simple-line-chart>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <div class="alert-container" *ngIf="verifySelectedAlert('Média Total')">
        <ng-container *ngIf="loading"><loader></loader></ng-container>
        <ng-container *ngIf="!loading">
            <div class="header-container">
                <div class="title-container">
                   <b class="title">Média Total</b>
                </div>
            </div>
            <div class="chart" id="operational-alerts-chart"></div>
        </ng-container>
    </div>

</div>

<cmx-custom-dialog
    id="operational-alerts-configuration-dialog"
    title="Configuração"
    animation="right-left"
    [internalFade]="true"
    [closeButton]="true"
    [overlay]="false"
    [widthModal]="20"
>
    <ng-container cmx-custom-dialog-body>
        <div class="log-modal-container">
            <div *ngFor="let alert of alertInfos" class="switch">
                <span class="label-switch">{{ alert.title }}</span>
                <p-inputSwitch 
                    [ngModel]="verifySelectedAlert(alert.title)"
                    (onChange)="changeSelectedAlert(alert)"
                ></p-inputSwitch>
            </div>
        </div>
    </ng-container>
</cmx-custom-dialog>

<alert-clients-dialog></alert-clients-dialog>
<info-dialog></info-dialog>
