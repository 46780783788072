<ng-template #panelConsulta>
  <div class="grid filters">
    <div>
        <cmx-dropdown
            [styles]="{ width: '240px' }"
            name="cargoType"
            [data]="cargoTypes"
            [ngModel]="filterState.getInternationalCargoType()"
            (ngModelChange)="handleCargoType($event)"
            [settings]="dropdownSettingsCargoType"
        ></cmx-dropdown>
    </div>
    <div>
        <cmx-dropdown
            [styles]="{ width: '240px' }"
            name="origins"
            [data]="origins"
            [ngModel]="filterState.getInternationalOrigin()"
            (ngModelChange)="handleOrigins($event)"
            [settings]="dropdownSettingsOrigins"
        ></cmx-dropdown>
    </div>
    <div>
        <cmx-dropdown
            [styles]="{ width: '240px' }"
            name="destinations"
            [data]="destinations"
            [ngModel]="filterState.getInternationalDestination()"
            (ngModelChange)="handleDestinations($event)"
            [settings]="dropdownSettingsDestinations"
        ></cmx-dropdown>
    </div>
    <button
      id="international-freight-rate-button-get-data-filter"
      class="btn-search"
      (click)="handleSelect()">
        <i class="fa fa-check"></i>
    </button>
  </div>
</ng-template>

<ng-template #panelGrid>
  <loader *ngIf="showLoader"></loader>
  <div id="international-freight-rate-table-freight-values">
    <div *ngIf="loadFreight">
      <h5>
        <i class="fa fa-th-list"></i>
        Custo detalhado - Data da atualização dos dados: <strong style="font-size: small;">{{creationDate | date: 'dd/MM/yyyy'}}</strong>
      </h5>
      <table id="international-freight-rate-table-freight-data" style="margin-top: 10px">
        <thead>
          <tr>
            <th class="center">Origem</th>
            <th class="center">Destino</th>
            <th class="center">Equipamento</th>
            <th class="center">Moeda</th>
            <th class="center">Frete</th>
            <th class="center">Taxa extra</th>
            <th class="center">Frete total</th>
            <th class="center">Transit Time</th>
            <th class="center">Válido até</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of gridRows">
            <td class="center" style="width: 70px">{{ item.origin }}</td>
            <td class="center" style="width: 70px">{{ item.destination }}</td>
            <td class="center" style="width: 50px">{{ item.equipment.type }}</td>
            <td class="center" style="width: 30px">{{ item.equipment.currency }}</td>
            <td class="center" style="width: 40px">{{ item.equipment.freight }}</td>
            <td class="center" style="width: 40px">{{ item.equipment.extraCharge }}</td>
            <td class="center" style="width: 40px">{{ item.equipment.totalFreight }}</td>
            <td class="center" style="width: 30px">{{ item.transitTime }}</td>
            <td class="center" style="width: 30px">{{ item.finalValidity | date: 'dd/MM/yyyy'}}</td>
          </tr>
        </tbody>
      </table>
      <br>
    </div>
  </div>
</ng-template>

<ng-template #panelTax>
  <loader *ngIf="showLoader"></loader>
  <div id="international-freight-rate-table-freight-tax-values">
    <div *ngIf="loadFreight">
      <h5><i class="fa fa-list"></i> Taxas locais</h5>
      <table id="international-freight-rate-table-freight-tax-data" style="margin-top: 10px">
        <thead>
          <tr>
            <th class="center">Taxa & Unid. medida</th>
            <th class="center">Moeda</th>
            <th class="center">valor</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let tax of taxes">
            <td class="center" style="width: 70px">{{ tax.name }}</td>
            <td class="center" style="width: 70px">{{ tax.currency }}</td>
            <td class="center" style="width: 70px">{{ tax.valueFormatted }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<div class="group-attach">
  <cmx-button
    id="international-freight-rate-upload-button"
    variant="success"
    icon="fa-cloud-upload"
    (click)="fileInput.click()"
    style="position: absolute; right: 10px; top: 15px">

    <ng-container *ngIf="fileSelected">
      <b>Arquivo:</b> <span>{{ fileSelected }}</span>
    </ng-container>

    <ng-container *ngIf="!fileSelected">Atualizar base de dados</ng-container>

  </cmx-button>

  <cmx-button
    id="international-freight-rate-clear-button"
    variant="danger"
    icon="fa-times"
    *ngIf="fileSelected"
    class="btn-update-freight-values"
    (click)="handleClearAttachFile()"
    style="position: absolute; right: 10px; top: 15px">
  </cmx-button>

</div>

<input
  id="international-freight-rate-select-hidden-file"
  hidden
  type="file"
  #fileInput
  (change)="handleAttachFile($event)"
  (click)="cleanEvent($event)"
  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>

<br>

<div class="content">

  <section>
    <ng-container *ngTemplateOutlet="panelConsulta"></ng-container>
    <div class="table-list">
      <ng-container *ngTemplateOutlet="panelGrid"></ng-container>
      <ng-container *ngTemplateOutlet="panelTax"></ng-container>
    </div>
  </section>

</div>
