<div class="mainContainer">
    <div *ngIf="asyncValidation" class="fullLoader">
        <loader></loader>
    </div>
    <div class="titleContainer">
        <img alt="" src="./assets/app/media/img/icons/xml.png" />
        <span>Validação DI</span>
    </div>
    <hr />
    <div *ngIf="impDetails" class="mainContent">
        <p class="messageError hidden"><i (click)="hideError()" class="la la-close"></i>{{ messageError }}</p>
        <div *ngIf="impDetails.status === 'PENDING_APPROVAL_REQUEST'; else noApproval" class="mainContent">
            <div class="text">
                Os documentos da IMP <b>{{ imp }}</b> foram validados e obtiveram <span class="sucess">sucesso</span> em
                suas comparações. Por favor, confirme a validação dos campos abaixo:
            </div>
            <div class="bottomContent">
                <div class="tableContainer">
                    <div id="validatedGrid"></div>
                </div>
                <div class="commentContainer">
                    <div class="commentContent">
                        <h6 *ngIf="!asyncValidation && comments">Comentários:</h6>
                        <div *ngIf="!asyncValidation && comments" class="comments">
                            <div class="comment-container" *ngFor="let comment of comments; trackBy: trackByFn">
                                <span class="comment-header"
                                    >{{ comment?.createdDate | date: 'dd/MM/yyyy' }} -
                                    {{ comment?.commentOrigin }}</span
                                >
                                <span>{{ comment?.comment }}</span>
                            </div>
                        </div>
                        <textarea class="comment" [(ngModel)]="comment"></textarea>
                        <span>*comentário necessário em caso de reprovação</span>
                    </div>
                    <div class="buttonsContainer">
                        <div class="btn reprove" (click)="approvalChoice('REFUSED')">Reprovar</div>
                        <div class="btn aprove" (click)="approvalChoice('APPROVED')">Aprovar</div>
                    </div>
                </div>
            </div>
            <div *ngIf="impDetails" class="downloadContainer">
                <span class="title">Download dos Arquivos</span>
                <div class="inputsContainer">
                    <div class="inputButton">
                        <span class="name">XML Comexport</span>
                        <label
                            (click)="downloadFile('INTERNAL')"
                            *ngIf="!(files.comexport && files.comexport.loading); else loader"
                            class="btnDoc"
                            for="comexport"
                            [style.background-color]="files.comexport.file !== null ? '#14509b' : '#d5d5d5'"
                            ><i class="material-icons">get_app</i></label
                        >
                    </div>
                    <div class="inputButton">
                        <span class="name">XML Original</span>
                        <label
                            (click)="downloadFile('CLIENT')"
                            *ngIf="!(files.client && files.client.loading); else loader"
                            class="btnDoc"
                            for="client"
                            [style.background-color]="files.client.file !== null ? '#14509b' : '#d5d5d5'"
                            ><i class="material-icons">get_app</i></label
                        >
                    </div>
                </div>
            </div>
        </div>
        <ng-template #noApproval>Mais nenhuma ação é necessária para esta IMP.</ng-template>
    </div>
</div>
