import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import moment from 'moment';
import { Observable } from 'rxjs';
import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';
import { ImportDetailsService } from '../import-details.service';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';
import { UtilsService } from '#services/_utils/utils.service';
import { INVOICES_COLUMNS } from './constants/invoices-columns.constants';
import { INVOICES_ITEMS_COLUMNS } from './constants/invoices-items-columns.constants';
import { INVOICES_EXCHANGE_COLUMNS } from './constants/invoices-exchange-columns.constants';
import { ImportDetailItem } from '../import-details-item';
import { Router } from '@angular/router';

@Component({
    selector: 'app-import-details-invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  export class InvoicesComponent extends ImportDetailItem implements OnInit {
    asyncDetail = !this.showLoader;
    showInvoicesItemsModal = false;
    showInvoicesNcmModal = false;
    showInvoicesExchangeModal = false;

    dataLoaded;

    invoicesDetails;
    invoicesExchangeDetails;
    invoicesList;

    exchangeList;

    invoiceNumber;
    itemsDetails;
    itemsList;

    ncms;
    ncmNumber;
    ncmDetails;

    gridInvoicesColumns: IGridColumn[] = INVOICES_COLUMNS;
    gridInvoicesItemsColumns: IGridColumn[] = INVOICES_ITEMS_COLUMNS;
    gridInvoicesExchangeColumns: IGridColumn[] = INVOICES_EXCHANGE_COLUMNS;

    constructor(
        private _importDetailsService: ImportDetailsService,
        private _customDialogService: CustomDialogService,
        private _utilsService: UtilsService,
        private _http: HttpClient,
        protected _router: Router,
    ) {
        super(_router);
        this._getNCMS().subscribe((data) => (this.ncms = data));
    }

    ngOnInit() {
        (this.showLoader) ? 
            setTimeout(() => {this.loadDetails();}, 1000) : 
            this.loadDetails();
    }

    loadDetails() {
        this.asyncDetail = false;
        this.dataLoaded = this._importDetailsService.getDetails();
        if(this.dataLoaded) {
            this.invoicesExchangeDetails = this._formatExchange(this.dataLoaded.exchange);
            this.exchangeList = this._verifyConditionColor(null, this.dataLoaded.exchange);
            if (this.dataLoaded.vendorInvoices.invoices ) {
                this.invoicesList = this.dataLoaded.vendorInvoices.invoices;
            }

            this.invoicesDetails = [
                {
                    title: 'Data Câmbio',
                    value: this.invoicesExchangeDetails?.exchangeDates,
                    formatter: 'multiple'
                },
                {
                    title: 'Vencimento Câmbio',
                    value: this.invoicesExchangeDetails?.exchangeDueDates,
                    formatter: 'multiple'
                },
                {
                    title: 'Condição Pagamento',
                    value: this.invoicesExchangeDetails?.paymentTerms.length === 1 ? this.invoicesExchangeDetails?.paymentTerms[0] : this.invoicesExchangeDetails?.paymentTerms,
                    formatter: this.invoicesExchangeDetails?.paymentTerms.length === 1 ? 'status' : 'multiple',
                    color: this.invoicesExchangeDetails?.paymentTerms.length === 1 ? this._verifyConditionColor(this.invoicesExchangeDetails?.paymentTerms[0]) : null
                },
                {
                    title: 'Valor Câmbio ME',
                    value: this._utilsService.formatNumber(this.dataLoaded.exchangeDetail?.exchangeAmountForeignTotal || 0, { style: 'currency', currency: this.dataLoaded.exchangeDetail?.invoiceCurrency })
                },
                {
                    title: 'Valor Câmbio',
                    value: this._utilsService.formatNumber(this.dataLoaded.exchangeDetail?.exchangeAmountLocalTotal || 0, { style: 'currency' })
                },
                {
                    title: 'Saldo',
                    value: this._utilsService.formatNumber(this.dataLoaded.exchangeDetail?.exchangeBalanceTotal || 0, { style: 'currency', currency: this.dataLoaded.exchangeDetail?.invoiceCurrency })
                },
                {
                    title: 'Taxa Média',
                    value: this.dataLoaded.exchangeDetail?.exchangeRateTotal ? this.dataLoaded.exchangeDetail?.exchangeRateTotal.toString().replace('.', ',') : null,
                }
            ];
        }

        this.asyncDetail = true;
    }

    onCellClick({ item }) {
        this.gridInvoicesItemsColumns[6].formatterData = { currency: this.dataLoaded.exchangeDetail?.invoiceCurrency };
        this.gridInvoicesItemsColumns[7].formatterData = { currency: this.dataLoaded.exchangeDetail?.invoiceCurrency };
        this.invoiceNumber = item.invoiceNumber;
        this.itemsList = item.items;

        this.itemsDetails = [
            {
                title: 'Data',
                value: item.invoiceDate,
            },
            {
                title: 'Quantidade Total',
                value: item.totalQuantity,
            },
            {
                title: 'Peso Total',
                value: item.totalWeight,
            },
            {
                title: 'Valor Invoice',
                value: this._utilsService.formatNumber(item?.amount || 0, { style: 'currency', currency: this.dataLoaded.exchangeDetail?.invoiceCurrency })
            },
        ];

        this._customDialogService.open('import-details-invoices-items-dialog');

        setTimeout(() => {
            this.showInvoicesItemsModal = true;
        }, 100);
    }

    onInvoicesDetailsClick(event) {
        if((event.title == 'Data Câmbio' || event.title == 'Vencimento Câmbio' || event.title == 'Condição Pagamento') && this.exchangeList.length > 1) {
            this._customDialogService.open('import-details-invoices-exchange-dialog');

            setTimeout(() => {
                this.showInvoicesExchangeModal = true;
            }, 100);
        }
    }

    itemsModalOnCellClick({ item }) {
        this.closeInvoicesNcmModal();
        this.ncmNumber = item.ncm.split('.').join('');

        this.ncms.NCMS.map((ncm) => {
            if (ncm.ncm === this.ncmNumber) {
                this.ncmDetails = [
                    {
                        title: 'Categoria',
                        value: ncm.categoria,
                    },
                    {
                        title: 'Descrição',
                        value: ncm.descricao,
                    },
                    {
                        title: 'Vigência',
                        value: moment(ncm.inicioVigencia,'DD/MM/YYYY'),
                    },
                    {
                        title: 'Unidade Tributária',
                        value: ncm.descricaoUTrib,
                    },
                    {
                        title: 'IPI',
                        value: ncm.IPI,
                        formatter: 'percentage'
                    },
                ];
            }
        });

        this._customDialogService.open('import-details-invoices-ncm-dialog');
        this.showInvoicesNcmModal = true;
    }

    closeInvoicesItemsModal() {
        this.showInvoicesItemsModal = false;
        this.invoiceNumber = null;
        this.itemsList = null;
        this.itemsDetails = null;
        this._customDialogService.close('import-details-invoices-ncm-dialog');
        this.closeInvoicesNcmModal();
    }

    closeInvoicesNcmModal() {
        this.showInvoicesNcmModal = false;
        this.ncmNumber = null;
        this.ncmDetails = null;
    }

    private _formatExchange(exchange) {
        let formattedExchange = {
            exchangeDates: [],
            exchangeDueDates: [],
            paymentTerms: []
        }

        exchange.forEach((ex, index) => {
            switch (ex.paymentTerms) {
                case 'CLIENT':
                    ex.paymentTerms = 'Cliente';
                    break;
                case 'APRAZO':
                    ex.paymentTerms = 'A Prazo';
                    break;
                case 'ANTECI':
                    ex.paymentTerms = 'Antecipado';
                    break;
                case 'AVIST':
                    ex.paymentTerms = 'A Vista';
                    break;
            }
            formattedExchange.exchangeDates.push(ex.exchangeDate);
            formattedExchange.exchangeDueDates.push(ex.exchangeDueDate);
            if (!formattedExchange.paymentTerms.includes(ex.paymentTerms)) {
                formattedExchange.paymentTerms.push(ex.paymentTerms);
            }
        });

        return formattedExchange;
    }

    private _verifyConditionColor(paymentTerms, rows?) {
        const colors = {
            "A Prazo": "#3b4f8f",
            "Cliente": "#8f743b",
            "Antecipado": "#8f3e3b",
            "A Vista": "#66986b"
        }

        if (rows) {
            rows.forEach(row => {
                row.badgeColor = colors[row.paymentTerms] || 'grey';
            });
            return rows
        } else {
            return colors[paymentTerms];
        }
    }

    private _getNCMS(): Observable<any> {
        return this._http.get('/assets/app/js/ncms.json');
    }
}
