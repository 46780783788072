<div class="cmx-assembled-data">
    <div
        *ngFor="let element of data" class="data"
        [ngClass]="element.align ? 'align-' + element.align : ''"
        [style.margin]=" noMargin ? '0' : ('10px ' + internalSideMargin + 'px')"
        [style.width]="element?.fullWidth ? '100%' : (element.width ? 'calc(' + element.width + ' - ' + internalSideMargin * 2 + 'px)' : elementWidth)"
        [style.display]="verifyPermitedInfo(element) ? 'flex' : 'none'"
    >
        <div *ngIf="verifyPermitedInfo(element)" [style.width]="getWidth(element)">
            <div class="title">{{ element?.title | valueOrDash }}</div>
            <div class="valueContainer">
                <span *ngIf="element.flag"><img style="width:25px; margin-right:5px" alt="" [src]="countryFlagsService.getFlag(element.flag)"></span>
                <div *ngIf="!element.formatter" class="value" [class.no-break-words]="element.noBreakWord" [tooltip]="element?.value" [checkEllipsisActive]="true">{{ element?.value | valueOrDash }}</div>
                <div *ngIf="element.formatter" id="cmx-assembled-data-{{ element.title }}" class="formatter-{{ element.formatter }} value" [style.width]="element.formatter === 'textarea' ? '100%' : ''" (click)="handleClick(element)"></div>
            </div>
        </div>
    </div>
</div>
