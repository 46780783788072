import * as _ from 'lodash';

export class User {

    public static blank() {
        return new User('Novo Usuário');
    }

    public uid: string;
    public name: string;
    public email: string;
    public profitCenters: string[];
    public clientGroups: string[];
    public accessProfile: string;
    public password: string;
    public domains: string[];
    public deleted: boolean = false;
    public twoFactorKey: string;
    public twoFactorMandatory: boolean;

    constructor(source: string | User) {
        if (typeof source === 'string') {
            this.name = source;
            this.profitCenters = [];
            this.clientGroups = [];
            this.domains = [];
        } else {
            this.uid = source.uid;
            this.name = source.name;
            this.email = source.email;
            this.profitCenters = [].concat(...source.profitCenters);
            this.clientGroups = [].concat(...source.clientGroups);
            this.accessProfile = source.accessProfile;
            this.password = source.password;
            this.domains = [].concat(...source.domains);
            this.deleted = source.deleted;
            this.twoFactorKey = source.twoFactorKey;
            this.twoFactorMandatory = source.twoFactorMandatory;
        }
    }

    public clone() {
        return new User(this);
    }

    public equals(other: User): boolean {
        return this.uid === other.uid
            && this.name === other.name
            && this.email === other.email
            && this.accessProfile === other.accessProfile
            && this.password === other.password
            && this.twoFactorKey === other.twoFactorKey
            && this.twoFactorMandatory === other.twoFactorMandatory
            && this.same(this.profitCenters, other.profitCenters)
            && this.same(this.clientGroups, other.clientGroups)
            && this.same(this.domains, other.domains);
    }

    public addProfitCenter(profitCenter: {code: string, name: string}) {
        if (!this.hasProfitCenter(profitCenter)) {
            this.profitCenters.push(profitCenter.code);
        }
    }

    public removeProfitCenter(profitCenter: {code: string, name: string}) {
        if (this.hasProfitCenter(profitCenter)) {
            this.profitCenters.splice(
                this.profitCenters.indexOf(profitCenter.code), 1,
            );
        }
    }

    public isActive() {
        return !this.deleted;
    }

    private hasProfitCenter(profitCenter: {code: string, name: string}) {
        return this.profitCenters.includes(profitCenter.code);
    }

    private same(a: any[], b: any[]) {
        return _.difference(a, b).length === 0 && _.difference(b, a).length === 0;
    }

}
