import { Component, ViewEncapsulation } from '@angular/core';

import { UserService } from 'app/auth/_services';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { APIKeygeneraationService } from './api-key-generation.service';

@Component({
    selector: 'api-key-generation',
    templateUrl: './api-key-generation.component.html',
    styleUrls: ['./api-key-generation.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class APIKeyGenerationComponent {
    asyncKeyGeneration = false;
    key = '';
    errorMessage = '';
    copiedText = '';

    constructor(
        private userService: UserService,
        private apiKeygeneraationService: APIKeygeneraationService,
        private userCacheService: UserCacheService
    ) {}

    generateKey() {
        this.errorMessage = '';
        this.asyncKeyGeneration = true;
        this.userService.currentUser$.subscribe(
            (user) => {
                const clientGroups = this.userCacheService.getClientGroups();
                if (clientGroups.length > 0) {
                    this.apiKeygeneraationService.generateKey(clientGroups[0].code, user.getEmail()).subscribe(
                        (key) => {
                            this.key = `{ "api_key": "${key.api_key}", "user_key": "${key.user_key}" }`;
                            this.copiedText = '';
                            this.asyncKeyGeneration = false;
                        },
                        (error) => {
                            this.errorMessage = 'Falha ao gerar chave de segurança';
                            this.asyncKeyGeneration = false;
                        }
                    );
                } else {
                    this.errorMessage = 'Falha ao acessar código de cliente';
                    this.asyncKeyGeneration = false;
                }
            },
            (error) => {
                this.errorMessage = 'Falha ao carregar dados iniciais';
                this.asyncKeyGeneration = false;
            }
        );
    }

    copyToClipboard() {
        const inputKey = document.getElementById('inputKey') as HTMLInputElement;
        inputKey.select();
        document.execCommand('copy');
        this.copiedText = 'Copiado para área de transferência';
    }
}
