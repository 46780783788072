import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Historic } from './interfaces/historic.interface';

@Component({
    selector: 'cmx-historic',
    templateUrl: './cmx-historic.component.html',
    styleUrls: ['./cmx-historic.component.scss'],
})
export class CmxHistoricComponent implements OnInit {
    @Input('inform-historic') historicInformation: Historic[] = [];
    @Input('icon-tooltip') iconTooltip: string = '';

    @Output() onIconClick = new EventEmitter<Historic>();

    isFirstItem = true;

    constructor() {}

    ngOnInit(): void {
        this.updateFirstItemStatus();
    }

    iconClicked(item: Historic) {
        this.onIconClick.emit(item);
    }

    updateFirstItemStatus() {
        this.isFirstItem = false;
    }
}
