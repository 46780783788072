import { IGridColumn } from "app/shared/components/cmx-custom-grid/interfaces";

export const DATE_NOTIFICATION_COLUMNS: IGridColumn[] = [{ 
        field: 'label',
        name: 'Data',
        width: 400,
        isFilterable: true,
        webFilter: true,
    }, {
        field: 'add',
        name: 'Inclusão',
        width: 90,
        formatterType: 'switch',
        headerCssClass: 'grid-header align-center',
    }, {
        field: 'update',
        name: 'Alteração',
        width: 90,
        formatterType: 'switch',
        headerCssClass: 'grid-header align-center',
    }, {
        field: 'frequency',
        width: 200,
        name: 'Periodicidade',
        formatterType: 'dropdown',
    }
]