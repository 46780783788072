import { Component, ViewEncapsulation } from '@angular/core';
import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';
import { ImportDetailsService } from '../import-details.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HISTORIC_COLUMNS } from './constants/historic-columns.constants';
import { ImportDetailItem } from '../import-details-item';
@Component({
    selector: 'app-import-details-historic',
    templateUrl: './historic.component.html',
    styleUrls: ['./historic.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  export class HistoricComponent extends ImportDetailItem {
    impNumber = this._route.snapshot.params.imp;
    asyncDetail = !this.showLoader;

    dataLoaded;
    historicList;

    gridHistoricColumns: IGridColumn[] = HISTORIC_COLUMNS;

    constructor(
        private _importDetailsService: ImportDetailsService,
        private _route: ActivatedRoute,
        protected _router: Router,
    ) {
        super(_router);
    }

    ngOnInit() {
        (this.showLoader) ? 
            setTimeout(() => {this.loadDetails();}, 1000) : 
            this.loadDetails();
    }

    loadDetails() {
        this.asyncDetail = false;
        this.dataLoaded = this._importDetailsService.getDetails();
        if(this.dataLoaded) {
            this.historicList = this.dataLoaded.notes;
        }

        this.asyncDetail = true;
    }
}
