export const CLIENT_GROUPS_PERMITED = {
    approval_flow: [
        // Gallo
        '90e85ad1',
        // TokStok
        '6c55833a',
        // Jatinox
        '391978c5',
        // Kalium
        '7aee7cf6',
        // Aurora
        'd4c10d46',
        // Casa Flora
        '83d60db8',
        // Kenerson
        '18ed53a2',
        // Aeropipa
        '725970c1',
        // Guess
        'da01419a',
        // Agfa
        '262f40ab',
        // Henniges
        'f0fc08ac',
        // Mitsubishi
        '4a59a8df',
        // Isdin
        '3a607e68',
        // Zaraplast
        '2da19a87',
        // Timken
        'dfd15a8c',
        // Isapa
        'e3992305',
        // CXT
        '3fc59a82',
        // Tania Bulhões
        '56e68be2',
        // TELHANORTE / SAINT GOBAIN
        'dd8f2cd3',
        // Mercado Livre
        '35bc8535',
        // Simpress
        '35c7ce9a',
        // Fiação Alpina
        'e4b36fa3',
        // Minasa
        '2948cc0b',
        // Sergitex
        '54fd1ac7',
        // Rendas Acácia
        '928a4f42',
        // Fibertex
        '6527b0e6',
        // DDF
        '661694d9',
        // Comesc
        '355698f6',
        // Covolan
        '9b3162bf',
        // Comex Cia
        '438e1ab2',
        // Outros
        '86cf2994',
        // Lucena Tecidos
        'b9485e8f',
        // 4D malhas
        '53d0afea',
        // A.R Confecções
        'f5b1742e',
        // A3 Tecidos
        '1c92abcf',
        // Advance Textil
        '96048ae6',
        // Aguti Textil
        '235f84a3',
        // Alartex
        '8f191aa5',
        // Alternativa Textil
        'ed55d13f',
        // AN Textil
        '9092a528',
        // Armazens / Transportadoras
        '4de7bb69',
        // Asa Sul
        '12cd7565',
        // AVR
        'b60ac35f',
        // Beni Galter
        '5d4b5703',
        // Bertinelli
        'e8eb82ea',
        // Brisa Textil
        '0cde51f6',
        // Brockton
        '101102e6',
        // BS Textil
        'b484fb6f',
        // Camelon Mamut
        'ee07e0c3',
        // Canatiba
        'b962def9',
        // Carlos Dias
        'feca0813',
        // Chiesa Textil
        '632f72d9',
        // Ciro Textil
        '8992d5db',
        // Click Bonita
        'd85ddcae',
        // Coltex
        '651a15d9',
        // Cotton Star
        '29db1672',
        // DDAL Confecções
        '595b9a57',
        // DEK Textil
        'df1da574',
        // Delta
        '5b1c2621',
        // Desconhecido
        '31f626a0',
        // Dianfa IND
        '6765c109',
        // Dimensão Vestuário
        'd96c7e7d',
        // Donna Dotti
        '08821a53',
        // Falcon Textil
        '928f44e4',
        // Felix Lucena
        'f8397786',
        // Flit Comércio
        'a2eb163a',
        // FR Textil
        '50352458',
        // Frant-Ness
        '53e2e4ad',
        // Genyo Textil
        '0f0ce7b1',
        // Group Textil
        'e28dba66',
        // H A R Textil
        '2bd72352',
        // HGD Indústria
        'a982e265',
        // Izitex
        'd9be59c1',
        // J Amorim
        '8a3f0e46',
        // J Dias
        'bfe2da0c',
        // J B Cicuto
        '20c8e53b',
        // Jolube
        'b9fda82d',
        // JSC Confecções
        'ea53cec8',
        // KR Tecidos
        'a23a1b2a',
        // Lanificio
        '3a5be1a2',
        // Lara Diva
        '9b459555',
        // Lavanderia Lavinorte
        '02dff9cf',
        // Lucena Textil
        '62ab927f',
        // Malharia Berlan
        '906ab7b6',
        // Malharia Du Carmo
        '5cd5a7eb',
        // Malharia Tauana
        '1cf64876',
        // Malhas Wilson
        '6bfd04df',
        // Malwee
        'bb0c106d',
        // Marco Textil
        'a03028a5',
        // MC-Ju
        '5862faa9',
        // Mendel Malhas
        'ec1adb23',
        // Milton Rinco
        '9abfd58d',
        // Minas La Fios
        '3072647b',
        // Minasa
        '2948cc0b',
        // Mundi Textil
        '5ea66b24',
        // Mundo Textil
        '34d5b84b',
        // MWL Textil
        'fc0acc82',
        // Nicoletti
        '8609512c',
        // Nortex Decor
        '3bdb8e70',
        // Objeto Brasil
        'ee3fc022',
        // Pagiri Textil
        '47a6bd18',
        // Passamanaria
        '6abfdc62',
        // R F A Tecidos
        '5986158b',
        // R M S Textil
        'f7e6183d',
        // Raul CIA
        'af26e3f4',
        // Real Textil
        'bc0d17e6',
        // REC Confecções
        '7b7e0ba7',
        // RS Garcia
        'dfc7240d',
        // S4 Confecções
        'f9d7f74b',
        // Santex
        'da67ec0d',
        // Sapope
        '80e16ba7',
        // Schlindwein Textil
        'b26e136d',
        // Siebje & Mancinelli
        'c7aa79a9',
        // Smartcom
        'e9472b01',
        // Soutex
        '90afd62b',
        // Sucesso Textil
        '0d6d3d0f',
        // Sufex
        '82d7152c',
        // Textil Carmen
        'd63889ca',
        // Textil Electra
        '46d7f950',
        // Textil Farbe
        '16d44bd8',
        // Textil Lira
        '80960b18',
        // Textil Lygia
        '7e5231a3',
        // Textil São João
        '8c444d4c',
        // Toque Íntimo
        '7e7cce4b',
        // Unicenter Pharma
        '4003f0de',
        // Verônica Vieira
        '5ea5a324',
        // Vira Vício Textil
        'd65b12ac',
        // Viviane Furrier
        '111f5049',
        // Wameltex
        '5bb24341',
        // World Blue
        '5e5ddce5',
    ],
};