export enum StructureProperty {
    amountsFormat,
    displayAccountCodes,
    displayZeroedNodes,
    uknownLines,
    columnTotal,
    accumulateBalance,
    periodGrouping,
    groupingClients,
    allProfitCenter,
}
