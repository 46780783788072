import { Component, ComponentFactoryResolver, EventEmitter, Input, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { TAB_GROUPS } from './constants/tab-groups.constants'
import { IMarkerFilter } from 'app/shared/components/cmx-marker-filter/interfaces/marker-filter.interface'
import { SpListingDetailsComponent } from '../../../theme/pages/finance/exchange/exchange-sp-listing/grid-modals/sp-listing-details/sp-listing-details.component';
import { SpListingExchangeDetailsComponent } from '../../../theme/pages/finance/exchange/exchange-sp-listing/grid-modals/sp-listing-exchange-details/sp-listing-exchange-details.component';
import { SpDocumentsComponent } from 'app/theme/pages/finance/exchange/exchange-sp-listing/grid-modals/sp-documents/sp-documents.component';
import { SpBankingChannelsComponent } from 'app/theme/pages/finance/exchange/exchange-sp-listing/grid-modals/sp-banking-channels/sp-banking-channels.component';
import { PoStatusDataComponent } from 'app/modules/operational/pages/po-status/grid-modals/po-status-data/po-status-data.component';
import { PoStatusImpsComponent } from 'app/modules/operational/pages/po-status/grid-modals/po-status-imps/po-status-imps.component';
import { PoStatusSkusComponent } from 'app/modules/operational/pages/po-status/grid-modals/po-status-skus/po-status-skus.component';
import { ProjectPricingDetailsComponent } from 'app/modules/operational/pages/project-pricing/grid-modals/project-pricing-details/project-pricing-details.component';
import { ProjectPricingProposesComponent } from 'app/modules/operational/pages/project-pricing/grid-modals/project-pricing-proposes/project-pricing-proposes.component';
import { ImpComponent } from 'app/theme/pages/import-details/imp/imp.component';
import { InvoicesComponent } from 'app/theme/pages/import-details/invoices/invoices.component';
import { LicenceComponent } from 'app/theme/pages/import-details/licence/licence.component';
import { BoardingComponent } from 'app/theme/pages/import-details/boarding/boarding.component';
import { ClearanceComponent } from 'app/theme/pages/import-details/clearance/clearance.component';
import { DeliveryComponent } from 'app/theme/pages/import-details/delivery/delivery.component';
import { HistoricComponent } from 'app/theme/pages/import-details/historic/historic.component';
import { InternalComponent } from 'app/theme/pages/import-details/internal/internal.component';
import { SkuComponent } from 'app/theme/pages/import-details/sku/sku.component';
import { ChassiComponent } from 'app/theme/pages/import-details/chassi/chassi.component';


@Component({
    selector: 'cmx-slick-tabs-modal',
    templateUrl: './cmx-slick-tabs-modal.component.html',
    styleUrls: ['./cmx-slick-tabs-modal.component.scss'],
})
export class SlickTabsModalComponent {
    @Input() tabs;
    @Input() dinamicTabs: boolean = false;
    @Input() hidden: boolean = true;
    @Input() hideTabs: boolean = false;
    @Input() contentPadding: string = '70px 20px 20px 20px';

    @Output('onDinamicClick') onDinamicClick = new EventEmitter();
    @Output('onClose') onClose = new EventEmitter();
    @Output('onSelectTab') onSelectTab = new EventEmitter();

    @ViewChild('contentContainer', { read: ViewContainerRef }) contentContainer: ViewContainerRef;

    height: string;
    tabGroups: IMarkerFilter[];
    preSelectedTab: string;
    selectedDinamicTab: string;

    private _componentMapping = {
        // P.O Status
        po_detail: PoStatusDataComponent,
        imp: PoStatusImpsComponent,
        sku: PoStatusSkusComponent,
        // SP Listing
        sp_detail: SpListingDetailsComponent,
        sp_exchange_detail: SpListingExchangeDetailsComponent,
        sp_documents: SpDocumentsComponent,
        sp_banking_channels: SpBankingChannelsComponent,
        // Project Pricing
        project_pricing_proposes: ProjectPricingProposesComponent,
        project_pricing_details: ProjectPricingDetailsComponent,
        // FollowUP
        followup_imp: ImpComponent,
        followup_sku: SkuComponent,
        followup_chassi: ChassiComponent,
        followup_invoices: InvoicesComponent,
        followup_licenca: LicenceComponent,
        followup_embarque: BoardingComponent,
        followup_desembaraco: ClearanceComponent,
        followup_entrega: DeliveryComponent,
        followup_historico: HistoricComponent,
        followup_interno: InternalComponent,
    }

    constructor(private resolver: ComponentFactoryResolver) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.hidden) {
            if (changes.hidden.currentValue === false) {
                this._getModalTopDistance();
            }
            if (!changes.hidden.firstChange && changes.hidden.currentValue) {
                $('cmx-slick-tabs-modal .custom-grid').remove();
            }
            if (this.tabs) {
                this._getTabGroups();
            }
        }
    }

    onCloseEvent() {
        $('cmx-slick-tabs-modal .custom-grid').remove();
        if (this.contentContainer) this.contentContainer.clear();
        this.onClose.emit();
    }

    onDinamicDataClick($event) {
        this.onDinamicClick.emit($event);
    }

    onSelectTabEvent(event) {
        if (this.dinamicTabs) {
            this.selectedDinamicTab = event.selected[0] || '';
        } else {
            this.contentContainer.clear();
            const factory = this.resolver.resolveComponentFactory(this._componentMapping[event.selected[0]]);
            this.contentContainer.createComponent(factory);
        }
    }

    getDinamicData() {
        const tab = this.tabs.find((tab) => tab.code === this.selectedDinamicTab);
        return tab ? tab.data : [];
    }

    private _getModalTopDistance() {
        const windowHeight = window.innerHeight;
        const viewportGrid = document.querySelector('.slick-viewport').getBoundingClientRect();
        const firstRowMeasure = document.getElementsByClassName('slick-row')[0].getBoundingClientRect();
        const topDistance = viewportGrid.top + firstRowMeasure.height - 1;
        const height = windowHeight - topDistance + 'px';

        this.height = `${height} !important`;
    }

    private _getTabGroups() {
        this.tabGroups = this.dinamicTabs ? this.tabs : TAB_GROUPS[this.tabs];
        this.selectedDinamicTab = this.dinamicTabs ? this.tabs[0]?.code : '';
        this.preSelectedTab = this.tabGroups[0] ? this.tabGroups[0]?.code : null ;
    }
}
