<div *ngIf="loadingDamageWidget">
    <loader></loader>
</div>
<div *ngIf="loadingExport" class="fullLoader">
    <span class="exporting">Exportando</span>
    <loader></loader>
</div>
<div class="moveBtn">
    <div class="moveLine"></div>
</div>

<h5>Não Conformidades</h5>

<div class="checkmark-container">
    <span id="ano-corrente" (click)="changeDateParam($event, 'CURRENT_YEAR')" class="checkmark text">Ano Corrente</span>
    <span id="doze-meses" (click)="changeDateParam($event, 'LAST_12_MONTHS')" class="checkmark text checked"
        >12 Meses</span
    >
</div>

<div id="searchLine" class="reduzed">
    <div (click)="toggleOriginSwitch($event)" class="toogleContainer">
        <div class="toogleContent">
            <label class="toogleLeft">Origem</label>
            <span
                *ngIf="currentUser.has('AUTOMOTIVE_USER') || currentUser.has('IMP_AUTOMOTIVE_USER')"
                class="middleHandler"
            ></span>
            <label class="toogleRight">Destino</label>
        </div>
    </div>
    <div class="input-group contriesComponent">
        <span class="input-group-addon" style="margin-right: -15px"></span>
        <div class="main originComponent">
            <div
                *ngFor="let country of damageFilter.originCountries; trackBy: trackByFn"
                (click)="country.selected = !country.selected"
                class="ball origin"
            >
                <img
                    [ngClass]="{ selected: country.selected === true }"
                    class="insideOriginImg"
                    src="../../../../../../assets/app/media/img/icons/flags/{{ country.code }}_rounded.png"
                    alt=""
                />
            </div>
        </div>
        <div class="main destinationComponent hidden">
            <div
                *ngFor="let country of damageFilter.destinationCountries; trackBy: trackByFn"
                (click)="country.selected = !country.selected"
                class="ball destination"
            >
                <img
                    [ngClass]="{ selected: country.selected === true }"
                    class="insideDestinationImg"
                    src="../../../../../../assets/app/media/img/icons/flags/{{ country.code }}_rounded.png"
                    alt=""
                />
            </div>
        </div>
    </div>

    <div *ngIf="damageFilter.stageMode === 'destination'" class="input-group stage-input-group">
        <span class="input-group-addon"></span>
        <cmx-dropdown
            (onScroll)="scrollList($event)"
            [(ngModel)]="damageFilter.stages"
            [data]="dropdown.stagesOptions"
            [settings]="dropdownSettingsStage"
            id="stages"
            name="damageStages"
        ></cmx-dropdown>
    </div>
    <div class="input-group client-input-group">
        <span class="input-group-addon"></span>
        <cmx-dropdown
            [(ngModel)]="damageFilter.clients"
            [data]="dropdown.clientsOptions"
            [settings]="dropdownSettings.clientGroups"
            id="clients"
            name="damageClients"
        ></cmx-dropdown>
    </div>
    <button
        *ngIf="currentUser.has('AUTOMOTIVE_USER') || currentUser.has('IMP_AUTOMOTIVE_USER')"
        (click)="loadDamageWidget()"
        class="applyImpStatus"
    >
        <i class="fa fa-check"></i>
    </button>
</div>

<span
    routerLinkActive="btn-active"
    routerLink="/damage"
    [queryParams]="{ client: damageFilter.getSelectedClients().selectedClientNames }"
    class="damageKpiConsolidation"
>
    <i class="material-icons">healing</i>Analítico</span
>

<div class="flex-btn" (click)="export()"
    ><i class="flaticon-download"></i>
    <span class="tooltip-text">Download</span>
</div>

<div [style.visibility]="hasDamageData ? 'visible' : 'hidden'" id="damage-chart"></div>

<div *ngIf="!loadingDamageWidget && !hasDamageData" class="reloadContainer">
    <div class="reloadContent">
        <i (click)="loadDamageWidget()" class="material-icons">refresh</i>
    </div>
</div>
