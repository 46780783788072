<div id="cmx-save-config">
    <div class="title-container">
        <div class="p-field">
            <input type="text" pInputText placeholder="Título"
                [(ngModel)]="title" 
                (ngModelChange)="emitChanges()"
            />
            <div *ngIf="selectedSave?.title && saveList?.length > 1" (click)="openDeleteModal()" class="delete-button">
                <span class="material-icons">delete</span>
            </div>
        </div>
    </div>
    <div class="info-container">
        <div class="left-container">
            <div *ngIf="enableNewSave" class="save" (click)="newSave()">
                <span class="material-icons">add</span>
            </div>
            <hr *ngIf="enableNewSave">
            <div id="cmx-save-config-saveList-container" class="saveList-container"
            [style.height]="savesBaseHeight">
                <div *ngFor="let save of saveList" class="save" 
                    [ngClass]="{ 'selected': save?.title === selectedSave?.title }"
                    [title]="save?.title"
                    (click)="selectSave(save)">
                    <span class="material-icons">{{ save?.icon }}</span>
                </div>
            </div>
        </div>
        <hr>
        <div class="right-container">
            <div class="preview-description-container">
                <div class="preview-container">
                    <div class="preview big"
                        [style.background-color]="selectedColor || '#e6e7e8'"
                        [style.color]="'white'">
                        <span class="material-icons">{{ selectedIcon }}</span>
                    </div>
                    <div class="preview small">
                        <span class="material-icons"
                        [style.background-color]="'#e6e7e8'"
                        [style.color]="selectedColor || '#e6e7e8'">{{ selectedIcon }}</span>
                    </div>
                </div>
            </div>
            <div class="icon-color-container">
                <div id="cmx-save-config-icon-container" class="icon-container"
                [style.max-height]="iconsBaseHeight">
                    <div class="icon" *ngFor="let icon of iconList"
                        (click)="selectIcon(icon)"><span class="material-icons">{{ icon }}</span>
                    </div>
                </div>
                <hr>
                <div class="color-container">
                    <div class="color" *ngFor="let color of colorList"
                        [style.background-color]="color"
                        (click)="selectColor(color)">
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <cmx-confirmation-modal
        id="deleteSaveModal"
        question="Deseja excluir o save {{ auxSelectedSave?.title }}?"
        labelAction="Excluir"
        confirmType="danger"
        [hidden]="!showDeleteModal"
        (onConfirm)="deleteSave()"
        (onCancel)="showDeleteModal = false"
    ></cmx-confirmation-modal>

</div>