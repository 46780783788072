import { toggles } from '#environment';
import { Observable, of } from 'rxjs';

export class User {
    private readonly data: {
        uid: string;
        accessProfile: string;
        email: string;
        displayName: string;
        photoURL: string;
        resetPassword: boolean;
        twoFactorKey: string;
        twoFactorMandatory: boolean;
        idToken: string;
        refreshToken: string;
        passwordExpiration: number;
        expireIn: any;
        token: () => Observable<any>;
        profileName?: string;
    };

    private readonly clientGroups: string[];

    private readonly profitCenters: string[];

    constructor(user: any, readonly permissions: Set<string> = new Set()) {

        this.data = {
            ...user,
            displayName: user.name,
            token: () => {
                return of(user.idToken);
            }
        };

        this.clientGroups = user.clientGroups;
        this.profitCenters = user.profitCenters;
    }

    has(permission: string): boolean {

        if (this.permissions && this.permissions.size > 0) {
            return this.permissions.has(permission);
        } else {
            return toggles.authorization.missingPermissions === 'allow';
        }
    }

    isTwoFactorMandatory() {

        return this.data.twoFactorMandatory;
    }

    isTwoFactorEnabled() {

        return this.data.twoFactorKey !== null;
    }

    getToken() {

        return this.data.token()
    }

    getIdToken() {

        return this.data.idToken;
    }

    getPasswordExpiration() {

        return this.data.passwordExpiration;
    }

    getTwoFactorKey() {

        return this.data.twoFactorKey;
    }

    getEmail() {

        return this.data.email;
    }

    getProfile() {
        return this.data.accessProfile;
    }

    getUid() {

        return this.data.uid;
    }

    getName() {
        return this.data.displayName
    }

    getProfileName() {
        return this.data.profileName
    }

    hasToEnableTwoFactor() {

        return (
            (this.data.twoFactorKey == null && this.data.twoFactorMandatory) ||
            this.data.twoFactorKey === 'change_it'
        );
    }

    setResetPassword(status: boolean) {

        this.data.resetPassword = status;
    }

    hasAllClientGroups() {

        return this.clientGroups.includes('all');
    }

    hasAllProfitCenters() {

        return this.profitCenters.includes('all');
    }

    isInternalUser() {
        return this.has('INTERNAL_FILTERS')
    }
}
