///<reference path="../../../../../../node_modules/@types/amcharts/index.d.ts"/>

import { Component, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { generateData } from './data';

@Component({
    selector: ".m-grid__item.m-grid__item--fluid.m-wrapper",
    templateUrl: "./sla.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class SlaComponent implements AfterViewInit {

    chart: any;

    constructor() {

    }

    ngAfterViewInit() {
        this.chart = this.initGraph();
    }

    resetGraph(event: any) {
        if (this.chart) {
            this.chart.destroy();
        }
        this.chart = this.initGraph();
    }

    initGraph() {
        return AmCharts.makeChart("slas", {
            type: "serial",
            fontSize: 12,
            fontFamily: "Poppins; sans serif",
            decimalSeparator: ",",
            thousandsSeparator: ".",
            dataProvider: generateData(),
            addClassNames: !0,
            startDuration: 1,
            color: "#6c7b88",
            marginLeft: 0,
            categoryField: "imp",
            categoryAxis: {
                parseDates: false,
                gridAlpha: .1,
                gridColor: "#FFFFFF",
                axisColor: "#555555",
            },
            valueAxes: [{
                id: "a1",
                gridAlpha: 0,
                axisAlpha: 0
            }],
            graphs: [{
                id: "sla",
                valueField: "sla1",
                title: "SLA",
                type: "column",
                fillAlphas: .1,
                valueAxis: "a1",
                balloonText: "<span style='font-size:20px;'>[[actual1]]</span><br/><span style='font-size:12px;'>SLA: [[value]]</span>",
                color: "#ffb822",
                lineColor: "#ffb822",
                alpha: .2,
                clustered: false,
                dashLength: 5,
            }, {
                id: "actual",
                valueField: "actual1",
                title: "Valor",
                type: "column",
                fillAlphas: .7,
                valueAxis: "a1",
                balloonText: "<span style='font-size:20px;'>[[value]]</span><br/><span style='font-size:12px;'>SLA: [[sla1]]</span>",
                lineColor: "#08a3cc",
                lineColorField: "color1",
                fillColors: "#08a3cc",
                colorField: "color1",
                columnWidth: .6
            }, {
                id: "average",
                balloonText: "<span style='font-size:12px;'>[[title]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
                lineThickness: 1,
                lineColor: '#c4c5d6',
                useLineColorForBulletBorder: !0,
                bullet: 'none',
                fillAlphas: 0,
                lineAlpha: 1,
                title: "Média",
                dashLength: 10,
                legendPeriodValueText: '[[value.average]]',
                valueField: "average1"
            }],
            chartCursor: {
                zoomable: !1,
                cursorAlpha: 0,
                categoryBalloonColor: "#e26a6a",
                categoryBalloonAlpha: 1,
                valueBalloonsEnabled: !1
            },
            legend: {
                bulletType: "round",
                equalWidths: !1,
                valueWidth: 120,
                useGraphSettings: !0,
                color: "#6c7b88"
            }
        });
    }


}
