import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as _ from 'lodash';

import { Observable } from 'rxjs';

import { environment } from '#environment';

@Injectable()
export class ChannelService {
    constructor(private _http: HttpClient) {}

    // Invoices Received
    getChannelAutomotive(start, end, params): Observable<any> {
        return this._http.get(`${environment.endpoints.importationMongoDBService}/chassis/stats/channel?startMonth=${start}&endMonth=${end}`, params);
    }

    // Invoices Received
    getChannel(start, end, params): Observable<any> {
        return this._http.get(`${environment.endpoints.importationMongoDBService}/imps/count-by-channel/all?startMonth=${start}&endMonth=${end}`, params);
    }
}
