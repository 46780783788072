import { _applyPosFormatters } from "./pos-formatters/pos-formatters";
import { textFormatter } from "./text.formatter";

export function fullfilmentFormatter(row, cell, value, columnDef, dataContext) {
    if (value === 'empty') {
        return textFormatter(row, cell, null, columnDef, dataContext);
    }
    if (value === null || value === undefined || value === '') {
        return fullfilmentFormatter(row, cell, 0, columnDef, dataContext);
    }

    let backgroundColor = 'green';
    let color = 'black';
    let position = 'center';

    if (value < 99.9) {
        backgroundColor = '#7fbf02';
        position = 'left';
    }
    if (value < 75) {
        backgroundColor = '#bfb900';
        position = 'left';
    }
    if (value < 50) {
        backgroundColor = '#f5b21b';
        position = 'right';
    }
    if (value < 25) {
        backgroundColor = '#d05600';
        position = 'right';
    }
    if (value === 0) {
        position = 'center';
    }
    if (value >= 100) {
        color = 'white';
    }

    const contentValue = `<div class="fullfilment-container">
        <div class="fullfilment-fill" style="width: ${
            value > 100 ? 100 : value
        }%; background-color: ${backgroundColor}"></div>
        <div class="percentage" style="text-align: ${position}; color: ${color}">${value}%</div>
    </div>`;
    return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
}