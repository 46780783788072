import { FORMATTER_TITLES } from "../constants/titles.constant";
import { _applyPosFormatters } from "./pos-formatters/pos-formatters";
import { textFormatter } from "./text.formatter";

export function textStatusFormatter(row, cell, value, columnDef, dataContext) {
    if (!value) {
        return textFormatter(row, cell, value, columnDef, dataContext);
    }

    let statusClass = (value.code && value.code.toUpperCase()) || value;
    let statusValue = value.description || value.name || value;
    const statusTitle = (value && value.code && FORMATTER_TITLES.status[value.code.toLowerCase()]) || '';
    const classes = (columnDef.formatterData && columnDef.formatterData.classes) || '';
    const float = columnDef.formatterData && columnDef.formatterData.float ? columnDef.formatterData.float : 'center';
    const tooltip = statusTitle ? `title="${statusTitle}"` : '';

    const contentValue =
    `<div class="status-container" style="justify-content: ${ float ? 'flex-' + float : 'center' };">
        <div class="status ${statusClass} ${classes}" style="background-color: ${dataContext.statusColor || value.color || ''}" ${tooltip}>
            <span>${typeof statusValue === 'string' ? statusValue?.toUpperCase() : ''}</span>
        </div>
    </div>`;
    return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
}
