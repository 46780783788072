<cmx-page-wrapper
    layout="bigHeader"
    [titleComponent]="titleComponent"
    [topButtonList]="topButton"
    (onTopButtonClick)="export($event)"
    [helpDescription]="helpDescription"
    helpTitle="Chassi">
    <ng-container cmx-page-wrapper-body>
        <ng-container *ngTemplateOutlet="markerFilterChassiStatus"></ng-container>
        <ng-container *ngTemplateOutlet="filterChassiStatus"></ng-container>
        <ng-container *ngTemplateOutlet="gridChassiStatus"></ng-container>
    </ng-container>
</cmx-page-wrapper>

<ng-template #markerFilterChassiStatus>
    <cmx-marker-filter
        *ngIf="allStatus.length"
        [filters]="allStatus"
        [selectedFilters]="filterState.getSearch().length === 0 ? filterState.getStatus() : []"
        [preSelected]="filterState.getSearch().length === 0 ? preSelectedStatus : []"
        type="status"
        (onSelected)="onSelectedStatus($event)"
        [leftDistance]="180"
    ></cmx-marker-filter>
</ng-template>

<ng-template #filterChassiStatus>
    <div *ngIf="refParam === 'registration'" id="searchLine" class="reduzed statusComponent">
        <div class="search-container" style="margin-right: 50px">
            <cmx-filter-search
                type="Chassi"
                [types]="['Chassi']"
                [value]="filterState.getSearch()"
                (onInput)="filterState.setSearch($event)"
                (onApply)="applyFilter()"
            ></cmx-filter-search>
        </div>
        <cmx-filter-wrapper [flex]="false" (onClick)="applyFilter()">
            <cmx-filter-wrapper-group [divisor]="false">
                <cmx-dropdown
                    [styles]="{ width: '200px' }"
                    name="clients"
                    [data]="options.clients"
                    [ngModel]="filterState.getClientGroups()"
                    (ngModelChange)="filterState.setClientGroups($event)"
                    [settings]="dropdownSettings.clientGroups"
                ></cmx-dropdown>

                <cmx-filter-wrapper-group [divisor]="false">
                    <cmx-dropdown
                    [styles]="{ width: '200px' }"
                    name="models"
                    [data]="options.models"
                    [settings]="dropdownSettingsModels"
                    [ngModel]="filterState.getModels()"
                    (ngModelChange)="filterState.setModels($event)"
                ></cmx-dropdown>
                </cmx-filter-wrapper-group>

                <cmx-filter-wrapper-group [divisor]="false">
                    <cmx-dropdown
                        [styles]="{ width: '180px' }"
                        name="colors"
                        [data]="options.colors"
                        [settings]="dropdownSettingsColors"
                        [ngModel]="filterState.getColors()"
                        (ngModelChange)="filterState.setColors($event)"
                ></cmx-dropdown>
                </cmx-filter-wrapper-group>

                <cmx-filter-wrapper-group [divisor]="false">
                    <cmx-filter-switch
                        size="md"
                        [values]="typeSwitch.values"
                        [inputIndex]="typeSwitch.index"
                        [inputIndex]="typeSwitch.values.indexOf(filterState.getReferenceDateType() === 'registration' ? 'Criação' : 'Chegada')"
                        (onSwitch)="toggleTypeSwitch($event)"
                    ></cmx-filter-switch>
                </cmx-filter-wrapper-group>

                <div class="input-group">
                    <cmx-filter-datepicker
                        id="chassi-status-datepicker"
                        [startDate]="filterState.getStartDate()"
                        [endDate]="filterState.getEndDate()"
                        (onChangeDates)="datesChanged($event)"
                    ></cmx-filter-datepicker>
                </div>

            </cmx-filter-wrapper-group>
        </cmx-filter-wrapper>
        <div
            *ngIf="filterState.getSearch().length !== 0"
            class="disableSearch"
            title="Os filtros são desativados ao realizar uma busca específica."
        ></div>
    </div>
</ng-template>

<ng-template #gridChassiStatus>
    <div id="chassi-status-grid-content">
        <ng-container *ngIf="asyncChassiStatusTable"><loader></loader></ng-container>
        <cmx-custom-grid
            [columns]="gridColumns"
            [loading]="asyncChassiStatusTable"
            [options]="{ forceFitColumns: false }"
            [rows]="gridRows"
            [options]="gridOptions"
            [styles]="{ height: 'calc(100vh - 280px)', width: '99%', left: '8px'}"
            (onCellClick)="onCellClick($event)"
        ></cmx-custom-grid>
    </div>

    <div *ngIf="pages.length > 1">
        <pagination
            [numberOfPages]="pages.length"
            [queryId]="queryId"
            (onPageChange)="changePage($event)"
        ></pagination>
    </div>
</ng-template>
