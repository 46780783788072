import { HttpHeaders, HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
import moment from 'moment';

export class SkuStatusFilter {
    private search: string;
    private clientGroups: string[];
    private startDate;
    private endDate;
    private status: object[];
    private sort: string;
    private offset: number;
    private limit: number;
    private modals: object[];

    constructor(filter?: SkuStatusFilter) {
        this.search = filter ? filter.search : '';
        this.clientGroups = filter ? filter.clientGroups : [];
        this.startDate = filter ? filter.startDate : moment().add(-24, 'M').format('DD/MM/YYYY');
        this.endDate = filter ? filter.endDate : moment().format('DD/MM/YYYY');
        this.status = filter ? filter.status : [
            {name: 'Aguard. Aut. Embarque', code: 'INICIADO'},
            {name: 'Aguard. Embarque', code: 'EMBARQUE'},
            {name: 'Em Trânsito', code: 'TRANSITO'},
            {name: 'Aguard. Presença de Carga', code: 'CARGA'},
            {name: 'Aguard. Liberação', code: 'LIBERACAO'},
            {name: 'Aguard. Registro DA', code: 'REGISTRO_DA'},
            {name: 'Aguard. Registro DI', code: 'REGISTRO'},
            {name: 'Aguard. Desembaraço DA', code: 'DESEMBARACO_DA'},
            {name: 'Aguard. Desembaraço DI', code: 'DESEMBARACO'},
            {name: 'Aguard. Faturamento', code: 'FATURAMENTO'},
            {name: 'Entrega', code: 'ENTREGA'},
        ];
        this.sort = filter ? filter.sort : 'comexportSku,desc';
        this.offset = filter ? filter.offset : 0;
        this.limit = filter ? filter.limit : 30;
        this.modals = filter ? filter.modals : [
            {id: 'assets/img/icons/air.png', itemName: 'AIR'},
            {id: 'assets/img/icons/maritime.png', itemName: 'MARITIME'},
            {id: 'assets/img/icons/roads.png', itemName: 'ROADS'},
        ];
    }

    public getSearch(): string { return this.search; }
    public getClientGroups(): string[] { return this.clientGroups; }
    public getStartDate() { return this.startDate; }
    public getEndDate() { return this.endDate; }
    public getStatus(): any[] { return this.status; }
    public getSort(): string { return this.sort; }
    public getOffset(): number { return this.offset; }
    public getLimit(): number { return this.limit; }
    public getModals(): object[] { return this.modals; }

    public setSearch(search): void { this.search = search; }
    public setClientGroups(clientGroups): void { this.clientGroups = clientGroups; }
    public setStartDate(startDate): void { this.startDate = startDate; }
    public setEndDate(endDate): void { this.endDate = endDate; }
    public setStatus(status): void { this.status = status; }
    public setSort(sort): void { this.sort = sort; }
    public setOffset(offset): void { this.offset = offset; }
    public setLimit(limit): void { this.limit = limit; }
    public setModals(modals): void { this.modals = modals; }

    public getRequest(typeSearch, skuView) {
        let headers = new HttpHeaders();
        let params = new HttpParams();
        headers = headers.set('X-Requested-Client-Groups',
        this.verifySearch(this.getClientGroups().map((c) => c['id']).join(',')));
        params = params.append('modalTransportations',
        this.verifySearch(this.getModals().map((c) => c['itemName']).join(',')));
        params = params.append('sort',  _.cloneDeep(this.getSort()));
        params = params.append('startDate',
        `${ this.getSearch() === '' ? moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD') : '2016-01-01'}`);
        params = params.append('endDate',
        `${ this.getSearch() === '' ? moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD') }`);
        params = params.append('offset', `${this.getOffset() * this.getLimit()}`);
        params = params.append('limit', `${this.getLimit()}`);
        params = params.set('status',
        this.verifySearch(this.getStatus().map((c) => c['code']).join(',')));
        params = params.append(`${typeSearch === 'IMP' ? 'imp' : typeSearch === 'Referência' ? 'clientReference' :
        typeSearch === 'Fornecedor' ? 'manufacturerName' : skuView === 'skuClient' ? 'customerSku' : 'skuComex'}`,
        `${this.getSearch()}`);
        return { headers, params };
    }

    private verifySearch(value) {
        return this.getSearch() !== '' ? '' : value;
    }

}
