export class FeatureType {

    public static blank() {
        return new FeatureType();
    }

    public code: string;
    public name: string;

    constructor(source?: FeatureType) {
        if (!source) {
            this.code = 'NEW_FEATURE';
            this.name = 'Novidade';
        } else {
            this.code = source.code || '';
            this.name = source.name || '';
        }
    }

}
