import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, HostListener, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import Inputmask from 'inputmask';
import moment from 'moment';
import { Observable } from 'rxjs';

import { environment } from '#environment';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';

import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { ChartsServices } from '../charts.services';
import { UtilsService } from '#services/_utils/utils.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserService } from 'app/auth/_services';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { LiLeadTimeFilter } from './li-lead-time.filter';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import * as _ from 'lodash';
declare let AmCharts: any;

@Component({
    selector: 'li-lead-time-chart',
    templateUrl: './li-lead-time.component.html',
    styleUrls: ['../charts.scss', './li-lead-time.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LiLeadTimeComponent implements AfterViewInit {
    filterState: LiLeadTimeFilter = this._filterStateService.getLiLeadTimeFilter();
    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);

    options: { clients: DropdownOption[] } = { clients: [] };
    currentUser$ = this.userService.currentUser$;
    currentUser;
    asyncLiLeadTime = false;
    approvalSwitch = true;
    approvalTitle = "LI's";
    chartData;
    accomplished = moment('YYYYMM').format('MM/YY');
    expected = moment('YYYYMM').format('MM/YY');
    kpiName;
    qtSla;
    yearMonth;
    periodSwitch = true;
    noContent = false;
    helpDescription = this._defineHelpDescription();

    @HostListener('click') onSetZIndex() {
        this.renderer2.setStyle(this._elementRef.nativeElement, 'z-index', this.utilsService.autoIncrementValue);
    }

    constructor(
        public chartService: ChartsServices,
        public router: Router,
        private http: HttpClient,
        private utilsService: UtilsService,
        private userService: UserService,
        private userCacheService: UserCacheService,
        private _cmxDropdownService: CmxDropdownService,
        private _elementRef: ElementRef,
        private _filterStateService: FilterStateService,
        private renderer2: Renderer2
    ) { }

    ngAfterViewInit() {
        this.options.clients = this._cmxDropdownService.toDropdownItems(this.userCacheService.getClientGroups());
        this.userService.currentUser$.subscribe((user) => {
            this.currentUser = user;
            this.loadLiLeadTime('lileadtime', '7');
        });
    }

    reloadChart() {
        this.loadLiLeadTime('lileadtime', '7');
    }
    togglePeriodSwitch() {
        this.loadLiLeadTime('lileadtime', '7');
    }

    loadLiLeadTime(chartName, kpi) {
        this.asyncLiLeadTime = true;
        this.utilsService.setInvisible('li-lead-time-chart', ['#lileadtime']);
        this.getLiLeadTime(kpi).subscribe(
            (response) => {
                this.chartData = response;
                if (this.chartData === null || this.chartData === undefined) {
                    this.noContent = true;
                    this.asyncLiLeadTime = false;
                } else {
                    this.noContent = false;
                    this.utilsService.setVisible('li-lead-time-chart', ['#lileadtime']);
                }
                let maxChartHeight = 0;
                if (this.chartData?.length) {
                    this.chartData.forEach((month) => {
                        month.yearMonth = moment(month.yearMonth, 'YYYYMM').format('MM/YY');
                        this.asyncLiLeadTime = false;
                        if (month.qtSla > maxChartHeight) {
                            maxChartHeight = month.qtSla;
                        }
                    });
                }
                const chart = AmCharts.makeChart(chartName, {
                    type: 'serial',
                    theme: 'light',
                    dataProvider: this.chartData,
                    valueAxes: [
                        {
                            id: 'v1',
                            axisAlpha: 0,
                            minimum: 0,
                            maximum: maxChartHeight + 10,
                            step: 5,
                        },
                    ],
                    startDuration: 0,
                    graphs: [
                        {
                            balloonText:
                                "LI's: <b>[[qtSla]]</b><br>Esperado: <b>[[expected]] dia(s)</b><br>Executado: <b>[[accomplished]] dia(s)</b>",
                            bullet: 'yError',
                            bulletSize: 10,
                            errorField: 'accomplished',
                            bulletColor: '#1f3d7a',
                            lineThickness: 2,
                            valueField: 'qtSla',
                            bulletAxis: 'v1',
                            fillAlphas: 0,
                        },
                        {
                            bullet: 'round',
                            bulletBorderAlpha: 1,
                            bulletBorderColor: '#FFFFFF',
                            bulletColor: '#1f3d7a',
                            lineAlpha: 0,
                            lineThickness: 2,
                            showBalloon: false,
                            valueField: 'qtSla',
                        },
                    ],
                    chartCursor: {
                        cursorAlpha: 0,
                        cursorPosition: 'mouse',
                        graphBulletSize: 1,
                        zoomable: false,
                    },
                    categoryField: 'yearMonth',
                    categoryAxis: {
                        gridPosition: 'start',
                        axisAlpha: 0,
                    },
                    export: {
                        enabled: true,
                        menu: [
                            {
                                format: 'PDF',
                                label: "<i class='flaticon-download'></i>",
                                title: 'Gerar PDF',
                            },
                        ],
                    },
                });

                chart.addListener('drawn', () => {
                    $('a[title*=JavaScript]').attr('style', 'display: none; opacity: 0;');
                    $('g[aria-label*=Meta]').attr('style', 'display: none; opacity: 0;');
                });
                $('a[title*=JavaScript]').attr('style', 'display: none; opacity: 0;');
                $('g[aria-label*=Meta]').attr('style', 'display: none; opacity: 0;');
            },
            (error) => {
                this.chartData = undefined;
                this.asyncLiLeadTime = false;
                this.utilsService.errorHandler(error, 'LI LeadTime');
            }
        );
    }

    datesChanged(event) {
        this.filterState.setStartDate(event.startDate);
        this.filterState.setEndDate(event.endDate);
    }

    private getLiLeadTime(kpi): Observable<any> {
        return this.http.get(`${environment.endpoints.slaKpiService}/slas/leadtime`, this.filterState.getRequest(kpi));
    }

    private _defineHelpDescription(): object[] {
        return [
            { type: 'text', value: "Informa a quantidade de LIs deferidas nos últimos 6 meses, assim como o tempo médio de deferimento para cada período."},
        ]
    }

}
