export class StructureSettings {

    public amountsFormat: string = 'thousands';
    public displayAccountCodes: boolean = true;
    public displayZeroedNodes: boolean = false;
    public groupingClients: boolean = true;
    public unknownLines: string = 'hideUnknownLines';
    public columnTotal: string = 'hideColumnTotal';
    public accumulateBalance: boolean = true;
    public periodGrouping: string = 'month';
    public allProfitCenter: boolean = false;
    public showLeaseHolder: boolean = false;
    public showServiceInfo: boolean = false;

    // não esqueça de adicionar novas propriedades no método clone()
    // e no changedPropertiesArray() mais acima!

    public clone() {
        const clone = new StructureSettings();

        clone.amountsFormat = this.amountsFormat;
        clone.displayAccountCodes = this.displayAccountCodes;
        clone.displayZeroedNodes = this.displayZeroedNodes;
        clone.groupingClients = this.groupingClients;
        clone.unknownLines = this.unknownLines;
        clone.columnTotal = this.columnTotal;
        clone.accumulateBalance = this.accumulateBalance;
        clone.periodGrouping = this.periodGrouping;
        clone.allProfitCenter = this.allProfitCenter;
        clone.showLeaseHolder = this.showLeaseHolder;
        clone.showServiceInfo = this.showServiceInfo;

        return clone;
    }
}
