export const DETAIL_COSTS_MODAL = {
    purchaseOrders: {
        title: 'Ordens de Compra',
        data: 'purchaseOrders',
        width: '600px',
        columns: [
            { name: 'Invoice', field: 'invoiceNumber', width: 100 },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date', width: 100 },
            { name: 'P.O', field: 'purchaseOrderCode', width: 100 }
        ]
    },
    vendors: {
        title: 'Fornecedores',
        data: 'vendors',
        width: '700px',
        columns: [
            { name: 'Invoice', field: 'invoiceNumber', width: 100 },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date', width: 100 },
            { name: 'Fornecedor', field: 'vendorName', width: 200 }
        ]
    },
    productCode: {
        title: 'Produtos',
        data: 'productCode',
        width: '900px',
        columns: [
            { name: 'Invoice', field: 'invoiceNumber', width: 150 },
            { name: 'Data', field: 'invoiceDate', width: 100, formatterType: 'date' },
            { name: 'SKU', field: 'code', width: 100 },
            { name: 'Produto', field: 'description', width: 300 },
            { name: 'Quantidade', field: 'quantity', width: 110 },
            { name: 'Unidade', field: 'unitMeasureDescription', width: 100}
        ]
    },
    productDesc: {
        title: 'Produtos',
        data: 'productCode',
        width: '900px',
        columns: [
            { name: 'Invoice', field: 'invoiceNumber', width: 150 },
            { name: 'Data', field: 'invoiceDate', width: 100, formatterType: 'date' },
            { name: 'SKU', field: 'code', width: 100 },
            { name: 'Produto', field: 'description', width: 300 },
            { name: 'Quantidade', field: 'quantity', width: 110 },
            { name: 'Unidade', field: 'unitMeasureDescription', width: 100}
        ]
    },
    productQuantity: {
        title: 'Produtos',
        data: 'productCode',
        width: '900px',
        columns: [
            { name: 'Invoice', field: 'invoiceNumber', width: 150 },
            { name: 'Data', field: 'invoiceDate', width: 100, formatterType: 'date' },
            { name: 'SKU', field: 'code', width: 100 },
            { name: 'Produto', field: 'description', width: 300 },
            { name: 'Quantidade', field: 'quantity', width: 110 },
            { name: 'Unidade', field: 'unitMeasureDescription', width: 100}
        ]
    },
    unitMeasureDescription: {
        title: 'Produtos',
        data: 'productCode',
        width: '900px',
        columns: [
            { name: 'Invoice', field: 'invoiceNumber', width: 150 },
            { name: 'Data', field: 'invoiceDate', width: 100, formatterType: 'date' },
            { name: 'SKU', field: 'code', width: 100 },
            { name: 'Produto', field: 'description', width: 300 },
            { name: 'Quantidade', field: 'quantity', width: 110 },
            { name: 'Unidade', field: 'unitMeasureDescription', width: 100}
        ]
    },
    skuCustomer: {
        title: 'Produtos',
        data: 'productCode',
        width: '900px',
        columns: [
            { name: 'Invoice', field: 'invoiceNumber', width: 150 },
            { name: 'Data', field: 'invoiceDate', width: 100, formatterType: 'date' },
            { name: 'SKU', field: 'code', width: 100 },
            { name: 'Produto', field: 'description', width: 300 },
            { name: 'Quantidade', field: 'quantity', width: 110 },
            { name: 'Unidade', field: 'unitMeasureDescription', width: 100}
        ]
    },
    ncms: {
        title: 'NCMs',
        data: 'ncms',
        width: '500px',
        columns: [
            { name: 'Nota Fiscal', field: 'invoiceNumber' },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date' },
            { name: 'Código NCM', field: 'ncmCode' },
        ]
    },
    cfops: {
        title: 'CFOPs',
        data: 'cfops',
        width: '600px',
        columns: [
            { name: 'Nota Fiscal', field: 'invoiceNumber' },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date' },
            { name: 'Série', field: 'series' },
            { name: 'Código CFOP', field: 'cfopCode' },
        ]
    },
    clientInvoiceNumber: {
        title: 'Notas Fiscais',
        data: 'clientInvoiceNumber',
        width: '400px',
        columns: [
            { name: 'Nota Fiscal', field: 'invoiceNumber' },
            { name: 'Série', field: 'series' },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date' },
        ]
    },
    clientInvoiceDate: {
        title: 'Notas Fiscais',
        data: 'clientInvoiceNumber',
        width: '400px',
        columns: [
            { name: 'Nota Fiscal', field: 'invoiceNumber' },
            { name: 'Série', field: 'series' },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date' },
        ]
    },
    installments: {
        title: 'Vencimentos',
        data: 'installments',
        width: '400px',
        columns: [
            { name: 'NF', field: 'invoiceNumber' },
            { name: 'Pagamento', field: 'code' },
            { name: 'Vencimento', field: 'dueDate', formatterType: 'date' },
        ]
    },
    installmentDueDate: {
        title: 'Vencimentos',
        data: 'installments',
        width: '400px',
        columns: [
            { name: 'NF', field: 'invoiceNumber' },
            { name: 'Parcela', field: 'code' },
            { name: 'Vencimento NF', field: 'dueDate', formatterType: 'date', width: 120 },
        ]
    },
    installmentCode: {
        title: 'Vencimentos',
        data: 'installments',
        width: '400px',
        columns: [
            { name: 'NF', field: 'invoiceNumber' },
            { name: 'Parcela', field: 'code' },
            { name: 'Vencimento NF', field: 'dueDate', formatterType: 'date', width: 120 },
        ]
    },
    line: {
        title: 'Produtos',
        data: 'line',
        width: '100%',
        columns: [
            { name: 'Invoice', field: 'invoiceNumber', width: 100 },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date', width: 100 },
            { name: 'SKU', field: 'code', width: 100 },
            { name: 'Descrição Produto', field: 'description', width: 300 },
            { name: 'Linha', field: 'line', width: 150 },
            { name: 'Família', field: 'family', width: 150 },
            { name: 'Marca', field: 'brand', width: 150 },
            { name: 'Coleção', field: 'collection', width: 150 },
        ]
    },
    family: {
        title: 'Produtos',
        data: 'family',
        width: '100%',
        columns: [
            { name: 'Invoice', field: 'invoiceNumber', width: 100 },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date', width: 100 },
            { name: 'SKU', field: 'code', width: 100 },
            { name: 'Descrição Produto', field: 'description', width: 400 },
            { name: 'Linha', field: 'line', width: 200 },
            { name: 'Família', field: 'family', width: 200 },
            { name: 'Marca', field: 'brand', width: 200 },
            { name: 'Coleção', field: 'collection', width: 200 },
        ]
    },
    brand: {
        title: 'Produtos',
        data: 'brand',
        width: '100%',
        columns: [
            { name: 'Invoice', field: 'invoiceNumber', width: 100 },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date', width: 100 },
            { name: 'SKU', field: 'code', width: 100 },
            { name: 'Descrição Produto', field: 'description', width: 400 },
            { name: 'Linha', field: 'line', width: 200 },
            { name: 'Família', field: 'family', width: 200 },
            { name: 'Marca', field: 'brand', width: 200 },
            { name: 'Coleção', field: 'collection', width: 200 },
        ]
    },
    collection: {
        title: 'Produtos',
        data: 'collection',
        width: '100%',
        columns: [
            { name: 'Invoice', field: 'invoiceNumber', width: 100 },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date', width: 100 },
            { name: 'SKU', field: 'code', width: 100 },
            { name: 'Descrição Produto', field: 'description', width: 400 },
            { name: 'Linha', field: 'line', width: 200 },
            { name: 'Família', field: 'family', width: 200 },
            { name: 'Marca', field: 'brand', width: 200 },
            { name: 'Coleção', field: 'collection', width: 200 },
        ]
    },
    countryFrom: {
        title: 'País de Origem',
        data: 'countryFrom',
        width: '600px',
        columns: [
            { name: 'Invoice', field: 'invoiceNumber', width: 100 },
            { name: 'País de Origem', field: 'countryFrom', width: 200 }
        ]
    },
    vendorInvoiceNumber: {
        title: 'Invoice',
        data: 'vendorInvoiceNumber',
        width: '400px',
        columns: [
            { name: 'Número Invoice', field: 'invoiceNumber', width: 140 },
            { name: 'Data Invoice', field: 'invoiceDate', formatterType: 'date' }
        ]
    },
    vendorInvoiceDate: {
        title: 'Invoice',
        data: 'vendorInvoiceDate',
        width: '400px',
        columns: [
            { name: 'Número Invoice', field: 'invoiceNumber', width: 140 },
            { name: 'Data Invoice', field: 'invoiceDate', formatterType: 'date' }
        ]
    },
    iiTaxPercentage: {
        title: 'Taxas',
        data: 'iiTaxPercentage',
        width: '400px',
        columns: [
            { name: 'I.I. Nac', field: 'iiTaxPercentage', width: 140, formatterType: 'percentage' },
            { name: 'PIS Nac', field: 'pisTaxPercentage', width: 140, formatterType: 'percentage' },
            { name: 'IPI Nac', field: 'ipiTaxPercentage', width: 140, formatterType: 'percentage' },
            { name: 'COFINS Nac', field: 'cofinsTaxPercentage', width: 140, formatterType: 'percentage' },
            { name: 'ICMS Nac', field: 'icmsTaxPercentage', width: 140, formatterType: 'percentage' },
        ]
    },
    pisTaxPercentage: {
        title: 'Taxas',
        data: 'pisTaxPercentage',
        width: '400px',
        columns: [
            { name: 'I.I. Nac', field: 'iiTaxPercentage', width: 140, formatterType: 'percentage' },
            { name: 'PIS Nac', field: 'pisTaxPercentage', width: 140, formatterType: 'percentage' },
            { name: 'IPI Nac', field: 'ipiTaxPercentage', width: 140, formatterType: 'percentage' },
            { name: 'COFINS Nac', field: 'cofinsTaxPercentage', width: 140, formatterType: 'percentage' },
            { name: 'ICMS Nac', field: 'icmsTaxPercentage', width: 140, formatterType: 'percentage' },
        ]
    },
    ipiTaxPercentage: {
        title: 'Taxas',
        data: 'ipiTaxPercentage',
        width: '400px',
        columns: [
            { name: 'I.I. Nac', field: 'iiTaxPercentage', width: 140, formatterType: 'percentage' },
            { name: 'PIS Nac', field: 'pisTaxPercentage', width: 140, formatterType: 'percentage' },
            { name: 'IPI Nac', field: 'ipiTaxPercentage', width: 140, formatterType: 'percentage' },
            { name: 'COFINS Nac', field: 'cofinsTaxPercentage', width: 140, formatterType: 'percentage' },
            { name: 'ICMS Nac', field: 'icmsTaxPercentage', width: 140, formatterType: 'percentage' },
        ]
    },
    cofinsTaxPercentage: {
        title: 'Taxas',
        data: 'cofinsTaxPercentage',
        width: '400px',
        columns: [
            { name: 'I.I. Nac', field: 'iiTaxPercentage', width: 140, formatterType: 'percentage' },
            { name: 'PIS Nac', field: 'pisTaxPercentage', width: 140, formatterType: 'percentage' },
            { name: 'IPI Nac', field: 'ipiTaxPercentage', width: 140, formatterType: 'percentage' },
            { name: 'COFINS Nac', field: 'cofinsTaxPercentage', width: 140, formatterType: 'percentage' },
            { name: 'ICMS Nac', field: 'icmsTaxPercentage', width: 140, formatterType: 'percentage' },
        ]
    },
    icmsTaxPercentage: {
        title: 'Taxas',
        data: 'icmsTaxPercentage',
        width: '400px',
        columns: [
            { name: 'I.I. Nac', field: 'iiTaxPercentage', width: 140, formatterType: 'percentage' },
            { name: 'PIS Nac', field: 'pisTaxPercentage', width: 140, formatterType: 'percentage' },
            { name: 'IPI Nac', field: 'ipiTaxPercentage', width: 140, formatterType: 'percentage' },
            { name: 'COFINS Nac', field: 'cofinsTaxPercentage', width: 140, formatterType: 'percentage' },
            { name: 'ICMS Nac', field: 'icmsTaxPercentage', width: 140, formatterType: 'percentage' },
        ]
    },
    itemSummarizedApplication: {
        title: 'SKU',
        data: 'itemSummarizedApplication',
        width: '600px',
        columns: [
            { name: 'SKU', field: 'customerSku', width: 200 },
            { name: 'Aplicação', field: 'application', width: 200, formatterType: 'default' },
            { name: 'Cód. Externo', field: 'externalCode', width: 200, formatterType: 'default' },
            { name: 'Cód. de Barras', field: 'barCode', width: 200, formatterType: 'default' },
        ]
    },
    itemSummarizedExternalCode: {
        title: 'SKU',
        data: 'itemSummarizedExternalCode',
        width: '600px',
        columns: [
            { name: 'SKU', field: 'customerSku', width: 200 },
            { name: 'Aplicação', field: 'application', width: 200, formatterType: 'default' },
            { name: 'Cód. Externo', field: 'externalCode', width: 200, formatterType: 'default' },
            { name: 'Cód. de Barras', field: 'barCode', width: 200, formatterType: 'default' },
        ]
    },
    itemSummarizedBarCode: {
        title: 'SKU',
        data: 'itemSummarizedBarCode',
        width: '600px',
        columns: [
            { name: 'SKU', field: 'customerSku', width: 200 },
            { name: 'Aplicação', field: 'application', width: 200, formatterType: 'default' },
            { name: 'Cód. Externo', field: 'externalCode', width: 200, formatterType: 'default' },
            { name: 'Cód. de Barras', field: 'barCode', width: 200, formatterType: 'default' },
        ]
    },
    clientInvoiceAccessKey: {
        title: 'Chave de Acesso',
        data: 'clientInvoiceAccessKey',
        width: '500px',
        columns: [
            { name: 'Invoice', field: 'invoiceNumber', width: 150 },
            { name: 'Chave de Acesso da NF-e', field: 'accessKey', width: 200, formatterType: 'default' },
        ]
    }
}

export const DETAIL_COSTS_CONTEXT = [
    {
        code: 'imp',
        name: 'IMP',
        disabled: false,
    },
    {
        code: 'sku',
        name: 'SKU',
        disabled: false,
        disabledInfo: 'Selecione apenas um cliente para habilitar visão SKU'
    }
];
