import { environment } from '#environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';

@Injectable()
export class AutomotiveDailyService {

    constructor(private http: HttpClient) {  }

    public getDaily(date): Observable<any> {
        return  this.http.get(`${environment.endpoints.automotiveV2QueryService}
            /api/v2/reports-query/report/DAILY_MOVEMENT?date=${moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')}`);
    }

    public verifyLastActivity() {
        return this.http.get(`${environment.endpoints.automotiveCommandService}/api/v2/reports/status/last-success/DAILY_ACTIVITY`);
    }

}
