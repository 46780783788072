import { NotificationService } from '#services/_notification/notification.service';
import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';

export type TypeAccept = 'image/*' | '.pdf' | '.doc' | '.docx' | '.xlsx'| '.xls' | '.csv' | 'text/csv' | 'application/vnd.ms-excel' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | '.zip' | '.rar' | '.7zip';

@Component({
    selector: 'cmx-file-upload',
    templateUrl: 'file-upload.component.html',
    styleUrls: ['file-upload.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class CmxFileUploadComponent {
    @Input() id: string;
    @Input() labelTitle?: string;
    @Input() accept?: TypeAccept[];
    @Input() required?: boolean = false;
    @Input() disable?: boolean = false;
    @Input() multiple?: boolean = false;
    @Input() maxFiles: number = 1;
    @Input() maxSizeFile: number;
    @Input() tooltipOptions?: { message: null, showRules: false, position: 'right', nowrap: false };
    @Input() tooltipNameOptions?: { show: false, position: 'left', nowrap: false, showOnCancel: true, positionOnCancel: 'left'};
    @Input() fileNameField: string = 'name';
    @Input() listNameWidth: string;
    @Input() listNameHeight: string;
    @Input() listNameMaxHeight: string;
    @Input() filesList?: [];

    @Output('onAttachFile') onAttachFile = new EventEmitter();
    @Output('onRemoveAttachFile') onRemoveAttachFile = new EventEmitter();
    @Output('onDownloadFile') onDownloadFile = new EventEmitter();

    constructor(private _notificationService: NotificationService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.filesList && !changes.filesList.firstChange) {
            const fileInput = document.querySelector(`#file-upload-input`) as HTMLInputElement;
            if (fileInput) {
                fileInput.value = null;
            }
        }
    }

    handleDownloadFile(index) {
        this.onDownloadFile.emit(this.filesList[index]);
    }

    handleAttachFile(files) {
        if (!this.multiple && this.filesList?.length && this.filesList?.length === 1){
            const message = 'Não é permitido anexar multiplos arquivos';
            return this._notificationService.openNotification('error', message);
        }

        if (this.maxFiles && (files.length > this.maxFiles) || (this.filesList?.length + files.length > this.maxFiles)) {
            const message = 'Quantidade máxima de arquivos permitidos: ' + this.maxFiles;
            return this._notificationService.openNotification('error', message);
        }

        for (const element of files) {
            const file = element;
            if (this.maxSizeFile && file.size > this.maxSizeFile) {
                const message = 'Tamanho máximo permitido: ' + this.maxSizeFile / 1000000 + 'mb';
                return this._notificationService.openNotification('error', message, this.labelTitle || 'Anexo');
            }
        }

        this.onAttachFile.emit(files);
    }

    handleRemoveAttachFile(index) {
        this.onRemoveAttachFile.emit(index);
    }

    createTooltip() {
        if(this.tooltipOptions?.message) {
            return this.tooltipOptions.message;
        }

        if(this.tooltipOptions?.showRules){
            if(this.accept && this.maxFiles && this.maxSizeFile) {
                let simpleTypeAccept = [];
                this.accept.forEach((item) => { simpleTypeAccept.push(item.toString()) });
                let types = 'Permitido: ' + simpleTypeAccept.join(", ");
                return types + '\nQuantidade máxima: ' + this.maxFiles + '\nTamanho máximo: ' + this.maxSizeFile / 1000000 + 'mb';
            } else {
                if (this.accept) {
                    let simpleTypeAccept = [];
                    this.accept.forEach((item) => { simpleTypeAccept.push(item.toString()) });
                    let types = 'Permitido: ' + simpleTypeAccept.join(", ");

                    if(this.maxFiles) {
                        return types + '\nQuantidade máxima: ' + this.maxFiles;
                    }

                    if(this.maxSizeFile) {
                        return types + '\nTamanho máximo: ' + this.maxSizeFile / 1000000 + 'mb';
                    }

                    return types;
                } else if (this.maxFiles && this.maxSizeFile) {
                    return 'Quantidade máxima: ' + this.maxFiles + '\nTamanho máximo: ' + this.maxSizeFile / 1000000 + 'mb';
                } else if (this.maxFiles) {
                    return 'Quantidade máxima: ' + this.maxFiles;
                } else if (this.maxSizeFile) {
                    return 'Tamanho máximo: ' + this.maxSizeFile / 1000000 + 'mb';
                }

                return '';
            }
        }

        return '';
    }
}
