
import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { forkJoin } from 'rxjs';
import Inputmask from 'inputmask';
import * as _ from 'lodash';
import moment from 'moment';

import { Utils } from './utils/utils';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';

import { LogisticsHandlingFilter } from 'app/theme/layouts/operational/logistics-handling/logisticsHandling.model';
import { LogisticsHandlingChart } from './models/logistics-handling-chart.model';
import { LogisticsHandlingTypesChart } from './models/logistics-handling-types-chart.model';

import { LOGISTICS_HANDLING_ICONS } from 'app/shared/constants/logistics-handling-icons.constant';
import { LOGISTICS_TYPES } from './constants/logistics-types.constant';

import { ILogisticsHandlingTypeChart, ILogisticsHandlingTypeResponse } from 'app/shared/interfaces/logistics-handling-types.interface';
import { ILogisticsHandlingResponse } from 'app/shared/interfaces/logistics-handling.interface';
import { ILogisticsState, ILogisticsWarehouse } from 'app/shared/interfaces/logistics-types.interface';

import { LogisticsHandlingModalTypes } from 'app/shared/types/logistics-handling-types.type';
import { LogisticsTypes } from 'app/shared/types/logistics-types.type';

import { UserService } from '../../../../auth/_services/user.service';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import { LogisticsHandlingService } from './logistics-handling.service';
import { UtilsService } from '#services/_utils/utils.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { FilterStateService } from '#services/_filters/filter-state.service';

@Component({
    selector: 'logistics-handling',
    templateUrl: './logistics-handling.component.html',
    styleUrls: ['./logistics-handling.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LogisticsHandlingComponent implements OnInit, OnDestroy {
    @Input() modalType: LogisticsHandlingModalTypes;
    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);

    readonly dropdownSettingsStates = {
        singleSelection: true,
        text: 'Estado',
    };

    readonly dropdownSettingsWarehouses = {
        singleSelection: true,
        text: 'Recinto',
    };

    filterState: LogisticsHandlingFilter;
    hasTypeData: boolean;
    iconMargin: number;
    valuesModalTypes: any;
    loadingSecondaryChart: boolean;
    loadingPrincipalChart: boolean;
    logisticsHandlingIcon: string;
    statesOptions: DropdownOption[];
    clientsOptions: DropdownOption[];
    selectedType: LogisticsTypes = 'state';
    selectedTypeSwitch = 'Estado';
    warehousesOptions: DropdownOption[];
    modalTypeFormats: [string, string, string];
    pagination: {
        page: number;
        pageSize: number;
        isFirstPage: boolean;
        isLastPage: boolean;
        data: ILogisticsHandlingTypeChart[];
    };

    currentUser$ = this.userService.currentUser$;
    helpDescription = [];

    private _subscription;
    private _subscriptionType;

    @HostListener('click') onSetZIndex() {
        this.renderer2.setStyle(this._elementRef.nativeElement, 'z-index', this._utilsService.autoIncrementValue);
    }

    constructor(private _logisticsHandlingService: LogisticsHandlingService,
        private _utilsService: UtilsService,
        private _userCacheService: UserCacheService,
        private userService: UserService,
        private _elementRef: ElementRef,
        private _filterStateService: FilterStateService,
        private renderer2: Renderer2) {
        this.loadingPrincipalChart = true;
        this.loadingSecondaryChart = true;
        this.statesOptions = [];
        this.warehousesOptions = [];
    }

    ngOnInit(): void {
        this.iconMargin = Utils.getIconMarginByLogisticsHandlingModalType(this.modalType);
        this.logisticsHandlingIcon = LOGISTICS_HANDLING_ICONS[this.modalType];
        this.modalTypeFormats = Utils.getLogisticsHandlingModalTypeFormats(this.modalType, this._utilsService);

        if(this.modalType === 'AIR'){
            this.filterState = this._filterStateService.getLogisticsHandlingFilterAir();
        }else if(this.modalType === 'MARITIME'){
            this.filterState = this._filterStateService.getLogisticsHandlingFilterMaritime();
        }else if(this.modalType === 'RAIL'){
            this.filterState = this._filterStateService.getLogisticsHandlingFilterRail();
        }else if(this.modalType === 'ROAD'){
            this.filterState = this._filterStateService.getLogisticsHandlingFilterRoad();
        }else if(this.modalType === 'VEHICLES'){
            this.filterState = this._filterStateService.getLogisticsHandlingFilterVehicles();
        }

        if (this.modalType === 'AIR' || this.modalType === 'MARITIME') {
            this._loadLogisticStatesAndWarehouses();
        }
        this._loadClientGroups();
        this._loadLogisticsHandling();
        this._loadLogisticsHandlingType();
        this.helpDescription = this._defineHelpDescription();

        this.getFilters();
    }

    ngOnDestroy() {
        if (this._subscription) { this._subscription.unsubscribe() }
        if (this._subscriptionType) { this._subscriptionType.unsubscribe() }
    }

    applyDate() {
        this.filterState.savePreferences(this.modalTypeFormats[0])
        this.getFilters();
    }


    getFilters(): void {
        this._loadLogisticsHandling();
        this._loadLogisticsHandlingType();
    }

    onSelectTypeChanged({ name }): void {
        this.selectedTypeSwitch = name;
        this.selectedType = LOGISTICS_TYPES[name];
        this._loadLogisticsHandlingType();
    }

    paginateWarehouseTypeChart(paginate = 0): void {
        if ((this.pagination.isFirstPage && paginate < 0) || (this.pagination.isLastPage && paginate > 0)) {
            return;
        }

        this.pagination.page += paginate;
        this.pagination.isFirstPage = this.pagination.page === 0;
        this.pagination.isLastPage =
            (this.pagination.page + 1) * this.pagination.pageSize >= this.pagination.data.length;

        const { page, pageSize, data } = this.pagination;
        const pagedData = _.cloneDeep(data).slice(page * pageSize, (page + 1) * pageSize);

        this._loadLogisticsHandlingTypeChart(pagedData);
    }

    private _loadClientGroups(){
        const clients = this._userCacheService.getClientGroups()
        this.clientsOptions = this._toDropdownItems(clients, false);
    }

    private _loadLogisticStatesAndWarehouses(): void {
        const requests = [
            this._logisticsHandlingService.getLogisticsStates(),
            this._logisticsHandlingService.getLogisticsWarehouses(),
        ];
        forkJoin(requests).subscribe(
            ([states, warehouses]: [ILogisticsState[], ILogisticsWarehouse[]]) => {
                const defaultSelection = {
                    code: 'all',
                    name: 'Todos',
                };
                this.statesOptions = this._toDropdownItems([defaultSelection, ...states], false);
                this.warehousesOptions = this._toDropdownItems(
                    [defaultSelection, ...warehouses.map((d) => ({ ...d, name: `${d.code}-${d.name}` }))],
                    false
                );
            }, (error) => this._showError(error)
        );
    }

    private _loadLogisticsHandling(): void {
        if (this._subscription) { this._subscription.unsubscribe() }
        this.loadingPrincipalChart = true;
        this._subscription = this._logisticsHandlingService.getLogisticsHandling(
            this.filterState.getYear(),
            this.modalType,
            this.filterState.getStates().map((c) => c.id).join(','),
            this.filterState.getWarehouses().map((c) => c.id).join(','),
            this.filterState.getClientGroups().map((c) => c.id).join(','),
            this.filterState.getTypeView().toLowerCase()
        ).subscribe((logisticsHandling: ILogisticsHandlingResponse) => {
                this.loadingPrincipalChart = false;
                this._loadLogisticsHandlingChart(logisticsHandling);
            }, (error) => this._showError(error)
        );
    }

    private _loadLogisticsHandlingType(): void {
        if (this._subscriptionType) { this._subscriptionType.unsubscribe() }
        if (this.modalType === 'AIR' || this.modalType === 'MARITIME') {
            this.loadingSecondaryChart = true;
            this._subscriptionType = this._logisticsHandlingService.getLogisticsHandlingTypes(
                this.filterState.getYear(),
                this.modalType,
                this.selectedType,
                this.filterState.getClientGroups().map((c) => c.id).join(','),
                this.filterState.getTypeView().toLowerCase()
            ).subscribe(
                    (logisticsHandlingType: ILogisticsHandlingTypeResponse) => {
                        this.loadingSecondaryChart = false;
                        const logisticsHandlingTypesData = logisticsHandlingType ? logisticsHandlingType.data : [];
                        this.hasTypeData = !!logisticsHandlingTypesData.length;
                        if (this.selectedType === 'warehouse') {
                            this._setPaginationDefault(logisticsHandlingTypesData);
                            this.paginateWarehouseTypeChart();
                        } else {
                            this._loadLogisticsHandlingTypeChart(logisticsHandlingTypesData);
                        }
                    }, (error) => this._showError(error)
                );
        }
    }

    private _loadLogisticsHandlingChart(logisticsHandling: ILogisticsHandlingResponse): void {
        const logisticsHandlingData = logisticsHandling ? logisticsHandling.data : [];
        const [previousYear, currentYear] = logisticsHandling.period.split('-');

        new LogisticsHandlingChart(
            this.modalType,
            logisticsHandlingData,
            previousYear,
            currentYear,
            this._utilsService
        ).loadChart(`${this.modalTypeFormats[1]}-chart`, this.filterState.getTypeView());

        this._updateDateInputs();
    }

    private _loadLogisticsHandlingTypeChart(logisticsHandlingTypesData: ILogisticsHandlingTypeChart[]): void {
        new LogisticsHandlingTypesChart(this.modalType, logisticsHandlingTypesData, this._utilsService)
            .loadChart(`type-${this.modalTypeFormats[0]}-chart`, this.filterState.getTypeView())
            .addListener('clickGraphItem', (event: any) => {
                const [item] = event.item.category.split('<br>');
                this._changeTypeFilter(item);
            });
    }

    private _changeTypeFilter(clickedType: string): void {
        if (this.selectedType === 'state') {
            this.filterState.setStates(this.statesOptions.filter((stateOption) => stateOption.id === clickedType));
        } else {
            this.filterState.setWarehouses( this.warehousesOptions.filter(
                (warehouseOption) => warehouseOption.itemName === clickedType
            ));
        }

        this._loadLogisticsHandling();
    }

    private _toDropdownItems(items: any[], includeCode = true): any[] {
        let mapFunction;
        if (includeCode) {
            mapFunction = this._toDropdownItemWithCode;
        } else {
            mapFunction = this._toDropdownItem;
        }
        return items ? items.map(mapFunction) : [];
    }

    private _toDropdownItemWithCode(e: any): DropdownOption {
        return new DropdownOption(e.code, `${e.code} - ${e.name}`);
    }

    private _toDropdownItem(e: any): DropdownOption {
        return new DropdownOption(e.code, `${e.name}`);
    }

    private _updateDateInputs(): void {
        if (!moment(this.filterState.getYear(), 'YYYY').isValid()) {
            this.filterState.setYear(moment().format('YYYY'));
        }

        ($(`logistics-handling input#startYear${this.modalTypeFormats[0]}`) as any)
            .val(this.filterState.getYear())
            .Zebra_DatePicker({
                view: 'years',
                format: 'Y',
                onSelect: (selectedDate) => {
                    this.filterState.setYear(selectedDate);
                    this.applyDate();
                },
                onClear: () => {
                    this.filterState.setYear(undefined);
                    this.loadingPrincipalChart = true;
                    this._updateDateInputs();
                },
            });
        Inputmask({ mask: '9999' }).mask($(`logistics-handling input#startYear${this.modalTypeFormats[0]}`));
    }

    private _showError(error): void {
        this.loadingPrincipalChart = true;
        this.loadingSecondaryChart = true;
        this._utilsService.errorHandler(error, `Movimentação ${this.modalTypeFormats[2]}`);
    }

    private _setPaginationDefault(data: ILogisticsHandlingTypeChart[]): void {
        this.pagination = {
            page: 0,
            pageSize: 3,
            isFirstPage: null,
            isLastPage: null,
            data,
        };
    }

    private _defineHelpDescription(): object[] {

        if (this.modalType === 'MARITIME') {

        return [
            { type: 'text',hasLineAfter: true, value: "Informa o volume de TEU’s ou Containers movimentados ao longo do ano."},
            { type: 'text', hasLineAfter: true, value:'Compara a movimentação realizada com o período anterior.'},
            { type: 'text', hasLineAfter: true, value: 'Permite analisar se houve aumento ou diminuição no volume embarcado por Recinto e Estado.' },
            { type: 'text', value: "Dados com base na data de chegada dos embarques."},
        ]
        } else if (this.modalType === 'AIR') {
            return [
                { type: 'text',hasLineAfter: true, value: "Informa o volume de Toneladas Aéreas movimentados ao longo do ano."},
                { type: 'text', hasLineAfter: true, value:'Compara a movimentação realizada com o período anterior.'},
                { type: 'text', hasLineAfter: true, value: 'Permite analisar se houve aumento ou diminuição no volume embarcado por Recinto e Estado.' },
                { type: 'text', value: "Dados com base na data de chegada dos embarques."},
            ]
        } else if (this.modalType === 'ROAD') {
            return [
                { type: 'text',hasLineAfter: true, value: "Informa o volume de Carretas Internacionais movimentados ao longo do ano."},
                { type: 'text', hasLineAfter: true, value:'Compara a movimentação realizada com o período anterior.'},
                { type: 'text', value: "Dados com base na data de chegada dos embarques."},
            ]
        } else if (this.modalType === 'VEHICLES') {
            return [
                { type: 'text',hasLineAfter: true, value: "Informa o volume de Veículos movimentados ao longo do ano."},
                { type: 'text', hasLineAfter: true, value:'Compara a movimentação realizada com o período anterior.'},
                { type: 'text', value: "Dados com base na data de chegada dos embarques."},
            ]
        }

    }
}
