export const PROJECT_PRICING_FORM_SCRIPT = {
    'project-pricing-form': {
        noFocus: {  newFocus: 'cotationNumber' },
        cotationNumber: {  newFocus: 'isClientIcmsTaxpayer', switch: true },
        isClientIcmsTaxpayer: { newFocus: 'priority', switch: true },
        priority: { newFocus: 'fiscalState', dropdown: true },
        fiscalState: { newFocus: 'rate' },
        rate: { newFocus: 'margin' },
        margin: { newFocus: 'receivableAnticipation' },
        receivableAnticipation: { newFocus: 'deliveryLocation' },
        deliveryLocation: { newFocus: 'commercialDiscount' },
        commercialDiscount: { newFocus: 'note' },
        note: { newFocus: 'cotationNumber' },
    },
};
