<div class="page-title-identifier">Relatório de {{ reportTitle }}</div>
<div class="traderReportContainer">

    <div class="title-line-report-base {{reportType}}">
        <div class="title-container-report-base">
            <cmx-help-modal help-title="{{ reportTitle }}" [help-description]="helpDescription"></cmx-help-modal>
            <span class="adjustment-span">{{ reportTitle }}</span>
            <div *ngIf="asyncTitle && reportType !== 'despesas'">
                <span class="color-label adjustment-span" id="period">{{startDate}} • {{endDate}}</span>
                <span *ngIf="filters.profitCenter.length === 0" class="color-label adjustment-span" id="cr">Todos CR's</span>
                <span *ngIf="filters.profitCenter.length === 1" class="color-label adjustment-span" id="cr">{{filters.profitCenter[0].itemName}}</span>
                <span *ngIf="filters.profitCenter.length > 1" class="color-label adjustment-span" id="cr">{{filters.profitCenter.length}} CR's</span>
                <span *ngIf="filters.trader.length === 0" class="color-label adjustment-span" id="trader">Todos Traders</span>
                <span *ngIf="filters.trader.length === 1" class="color-label adjustment-span" id="trader">{{filters.trader[0].itemName}}</span>
                <span *ngIf="filters.trader.length > 1" class="color-label adjustment-span" id="trader">{{filters.trader.length}} Traders</span>
                <span *ngIf="filters.director.length === 0" class="color-label adjustment-span" id="director">Todos Diretores</span>
                <span *ngIf="filters.director.length === 1" class="color-label adjustment-span" id="director">{{filters.director[0].itemName}}</span>
                <span *ngIf="filters.director.length > 1" class="color-label adjustment-span" id="director">{{filters.director.length}} Diretores</span>
                <span *ngIf="filters.client.length === 0" class="color-label adjustment-span" id="client">Todos Clientes</span>
                <span *ngIf="filters.client.length === 1" class="color-label adjustment-span" id="client">{{filters.client[0].itemName}}</span>
                <span *ngIf="filters.client.length > 1" class="color-label adjustment-span" id="client">{{filters.client.length}} Clientes</span>
                <span class="color-label adjustment-span" id="currency">{{filters.currency}}</span>
            </div>
            <div *ngIf="asyncTitle && reportType === 'despesas'">
                <span class="color-label adjustment-span" id="period">{{startDate}} • {{endDate}}</span>
                <span *ngIf="filters.profitCenter.length === 0" class="color-label adjustment-span" id="cr">Todos CC's</span>
                <span *ngIf="filters.profitCenter.length === 1" class="color-label adjustment-span" id="cr">{{filters.profitCenter[0].itemName}}</span>
                <span *ngIf="filters.profitCenter.length > 1" class="color-label adjustment-span" id="cr">{{filters.profitCenter.length}} CC's</span>
                <span class="color-label adjustment-span" id="currency">{{filters.currency}}</span>
            </div>
        </div>
        <div *ngIf='firstFilter === false' class="inputContainer">
            <div *ngIf="reportType !== 'imp'" class="flex-btn" (click)='openStructure()'>
                <i class="flaticon-cogwheel-2 adjustment-icon"></i>
            </div>
            <div class="flex-btn" (click)='openFilter()' style="margin: 1px 1px 0px 0px">
                <i class="flaticon-interface-8 adjustment-icon"></i>
            </div>
            <div *ngIf="reportType !== 'imp'" class="downloadTable" title="Download" (click)="export('xls')">
                <i class="flaticon-download adjustment-icon"></i>
            </div>
            <div *ngIf="reportType === 'imp'" class="downloadTable" title="Download" (click)="applyFilter(true)">
                <i class="flaticon-download adjustment-icon"></i>
            </div>
        </div>
    </div>
    <hr>
    <div *ngIf="!asyncReport && !firstFilter">
        <loader></loader>
    </div>
    <div id="reportTable" [class]='reportType'></div>
    <div *ngIf="rows?.length === 0 && asyncReport" class="noMsg">Sem resultados para esse filtro.</div>
    <!-- <div *ngIf="pages.length > 1 && reportType === 'imp'" class="pagination">
        <pagination [numberOfPages]="pages.length" [queryId]="queryId" (onPageChange)="changePage($event)"></pagination>
    </div> -->
</div>

<div class="reportStructureModal hidden">
    <div class="modalContent">
        <i (click)='closeStructure()' class="la la-close close"></i>
        <report-structure (onChange)="changeStructure($event)"></report-structure>
    </div>
</div>

<div class="reportFilterModal hidden" [id]="reportType">
    <div class="modalContent">
        <i (click)='closeFilter()' class="la la-close close"></i>
        <cmx-results-filter (onChange)="changeFilters($event)"></cmx-results-filter>
    </div>
</div>

<ul id="contextMenu" style="display:none;position:fixed;top:168px;left:174px;border-top-left-radius:0px">
    <b style="cursor: default;">Download de {{selectedImp}}:</b>
    <div class="list">
        <li *ngFor="let arquive of downloadArquives; trackBy: trackByFn" (click)="export('xls', arquive)">{{arquive}}</li>
    </div>
    <span class="all" (click)="export('zip', 'all')">Baixar Todos</span>
</ul>

<div *ngIf="asyncExport" class="fullLoader">
    <span class="exporting">Exportando</span>
    <loader></loader>
</div>
