import { HttpHeaders, HttpParams } from '@angular/common/http';
import { UserService } from 'app/auth/_services';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { AppModule } from 'app/app.module';
import moment from 'moment';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IFupSkuFilter } from './interfaces/fup-sku-filter.interface';
import { TransportModalService } from '#services/_transportModal/transportModal.service';

export class FupSkuFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);
    private _userService = AppModule.injector.get(UserService);

    private _initialFupSkuFilter: IFupSkuFilter = <IFupSkuFilter>{};
    private _fupSkuFilter: IFupSkuFilter = <IFupSkuFilter>{
        imp: '',
        sku: '',
        clientGroups: [],
        status: [
            {name: 'Aguard. Aut. Embarque', code: 'INICIADO', statusEntityName: 'newProcess'},
            {name: 'Aguard. Embarque', code: 'EMBARQUE', statusEntityName: 'preShipping'},
            {name: 'Em Trânsito', code: 'TRANSITO', statusEntityName: 'inTransit'},
            {name: 'Aguard. Presença de Carga', code: 'CARGA', statusEntityName: 'cargoPresence'},
            {name: 'Aguard. Liberação', code: 'LIBERACAO', statusEntityName: 'liberation'},
            {name: 'Aguard. Registro DA', code: 'REGISTRO_DA', statusEntityName: 'registrationDa'},
            {name: 'Aguard. Registro DI', code: 'REGISTRO', statusEntityName: 'registration'},
            {name: 'Aguard. Desembaraço DA', code: 'DESEMBARACO_DA', statusEntityName: 'clearanceDa'},
            {name: 'Aguard. Desembaraço DI', code: 'DESEMBARACO', statusEntityName: 'clearance'},
            {name: 'Aguard. Faturamento', code: 'FATURAMENTO', statusEntityName: 'invoicing'},
            {name: 'Entrega', code: 'ENTREGA', statusEntityName: 'delivery'},
        ],
        clientReference: '',
        typeSearch: this._userService.getCurrentUser().has('INTERNAL_FILTERS') ? 'IMP' : 'REF',
        referenceDateType: [new DropdownOption('registration','Criação')],
        modals: [
            { id: 'assets/img/icons/air.png', itemName: 'Aéreo' },
            { id: 'assets/img/icons/maritime.png', itemName: 'Marítimo' },
            { id: 'assets/img/icons/roads.png', itemName: 'Rodoviário' },
        ]
    };

    private startDate;
    private endDate;
    private offset: number;
    private limit: number;

    constructor(private _transportModalService: TransportModalService, userService: UserService, filter?: FupSkuFilter) {
        this._fupSkuFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'fupSkuFilter', this._fupSkuFilter, filter);
        this._initialFupSkuFilter = _.cloneDeep(this._fupSkuFilter);

        const initialTypeSearch = userService.getCurrentUser().has('INTERNAL_FILTERS') ? 'IMP' : 'REF';
        this.startDate = filter ? filter.startDate : moment().add(-24, 'M').format('DD/MM/YYYY');
        this.endDate = filter ? filter.endDate : moment().format('DD/MM/YYYY');
        this.offset = filter ? filter.offset : 0;
        this.limit = filter ? filter.limit : 50;

    }

    public getSearch(): string { return this._fupSkuFilter.imp; }
    public getSku(): string { return this._fupSkuFilter.sku; }
    public getClientGroups(): string[] { return this._fupSkuFilter.clientGroups; }
    public getStatus(): any[] { return this._fupSkuFilter.status; }
    public getStartDate() { return this.startDate; }
    public getEndDate() { return this.endDate; }
    public getReferenceDateType(): DropdownOption[] { return this._fupSkuFilter.referenceDateType; }
    public getOffset(): number { return this.offset; }
    public getLimit(): number { return this.limit; }
    public getClientReference(): string { return this._fupSkuFilter.clientReference; }
    public getTypeSearch(): string { return this._fupSkuFilter.typeSearch; }
    public getModals(): object[] { return this._fupSkuFilter.modals; }

    public setSearch(imp): void { this._fupSkuFilter.imp = imp; }
    public setSku(sku): void { this._fupSkuFilter.sku = sku; }
    public setClientGroups(clientGroups): void { this._fupSkuFilter.clientGroups = clientGroups; }
    public setStatus(status): void { this._fupSkuFilter.status = status; }
    public setStartDate(startDate): void { this.startDate = startDate; }
    public setEndDate(endDate): void { this.endDate = endDate; }
    public setReferenceDateType(referenceDateType): void { this._fupSkuFilter.referenceDateType = referenceDateType; }
    public setOffset(offset): void { this.offset = offset; }
    public setLimit(limit): void { this.limit = limit; }
    public setClientReference(clientReference): void { this._fupSkuFilter.clientReference = clientReference; }
    public setTypeSearch(typeSearch): void { this._fupSkuFilter.typeSearch = typeSearch; }
    public setModals(modals): void { this._fupSkuFilter.modals = modals; }

    public getFilterData() {
        const data = {
            imp: this.getSearch(),
            sku: this.getSku(),
            clientGroups: this.getClientGroups(),
            status: this.getStatus(),
            clientReference: this.getClientReference(),
            typeSearch: this.getTypeSearch(),
            referenceDateType: this.getReferenceDateType(),
            modals: this.getModals(),
            startDate: this.getStartDate(),
            endDate: this.getEndDate(),
            offset: this.getOffset(),
            limit: this.getLimit()
        } as const;
        return data;
    }

    public getRequest(allStatus, currentType?) {
        this._initialFupSkuFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialFupSkuFilter, this._fupSkuFilter, 'fupSkuFilter', this._userCacheService.getUserPreferences().id);

        let headers = new HttpHeaders();
        headers = headers.set('X-Requested-Client-Groups', this.getClientGroups()?.length === 0 ? '' :
        this.getClientGroups().map((c) => c['id']).join(','));
        let params = new HttpParams();
        const status = this.getSearch() === '' ? this.getStatus() : allStatus;

        params = params.append('imp', `${this.getTypeSearch() === 'IMP' ? this.getSearch() : ''}`);
        params = params.append('sku', this.getSku());
        params = params.append('field', `consolidatedImp.status.code::${status.map((c) => c['code']).join(',')}`);
        params = params.append('startDate', `${this.getSearch() === '' ?
                moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD') :
                moment().add(-90, 'days').format('YYYY-MM-DD')}`);
        params = params.append('endDate', `${this.getSearch() === '' ?
                moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD') :
                moment().format('YYYY-MM-DD')}`);
        const selectedReferenceDateType = this.getReferenceDateType();
        const referenceDateType = selectedReferenceDateType && selectedReferenceDateType.length
                ? selectedReferenceDateType[0].id
                : 'registration';
        params = params.append('referenceDateType', referenceDateType);
        const modals = this.getSearch() !== '' ? '' : this.getModals().map((c) => this._transportModalService.getEnglishName(c['itemName'])).join(',');
        params = params.append('modalTransportations', modals);
        params = params.append('limit', `${this.getLimit()}`);
        params = params.append('offset', `${this.getOffset() * this.getLimit()}`);
        params = params.append('clientReference', `${this.getTypeSearch() === 'REF' ? this.getSearch() : ''}`);
        return { params, headers };
    }
}
