import { IGridColumn } from "app/shared/components/cmx-custom-grid/interfaces";

export const PO_ITEMS_SLICK_COLUMNS: IGridColumn[] = [{
    field: 'number',
    name: 'Item',
    width: 100,
}, {
    field: 'externalCode',
    name: 'SKU',
    width: 150,
}, {
    field: 'exporterCode',
    name: 'Cód Exportador',
    width: 150,
}, {
    field: 'description',
    name: 'Descrição',
    width: 100,
}, {
    field: 'unitMeasurementCode',
    name: 'Un. Medida',
    width: 100,
}, {
    field: 'quantity',
    name: 'Quantidade',
    width: 100,
}, {
    field: 'quantityMet',
    name: 'Qtd Utilizada',
    width: 100,
}, {
    field: 'quantityDeducted',
    name: 'Qtd Glosada',
    width: 100,
}, {
    field: 'balance',
    name: 'Saldo',
    width: 100,
}, {
    field: 'fullfillmentItem',
    name: 'Fullfilment',
    formatterType: 'fullfilment',
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: 100,
}, {
    field: 'unitPrice',
    name: 'Valor Unit.',
    formatterType: 'currency',
    width: 150,
    headerCssClass: 'grid-header align-right',
    cssClass: 'grid-cell align-right',
}, {
    field: 'grossValue',
    name: 'Valor Total',
    formatterType: 'currency',
    width: 150,
    headerCssClass: 'grid-header align-right',
    cssClass: 'grid-cell align-right',
}, {
    field: 'ncm',
    name: 'NCM',
    width: 100,
}, {
    field: 'sendExporterDate',
    name: 'Envio Exportador',
    formatterType: 'date',
    formatterData: {
        hourFormat: false,
    },
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: 155,
}, {
    field: 'confirmationDate',
    name: 'Confirmação Exp',
    formatterType: 'date',
    formatterData: {
        hourFormat: false,
    },
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: 155,
}, {
    field: 'estimatedProductionStartDate',
    name: 'Previsão Início Produção',
    formatterType: 'date',
    formatterData: {
        hourFormat: false,
    },
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
        width: 155,
}, {
    field: 'productionStartDate',
    name: 'Início Produção',
    formatterType: 'date',
    formatterData: {
        hourFormat: false,
    },
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: 155,
}, {
    field: 'estimatedAvailabilityDate',
    name: 'Previsão Disponibilidade',
    formatterType: 'date',
    formatterData: {
        hourFormat: false,
    },
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: 155,
}, {
    field: 'availabilityDate',
    name: 'Disponibilidade',
    formatterType: 'date',
    formatterData: {
        hourFormat: false,
    },
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: 155,
}, {
    field: 'estimatedBoardingDate',
    name: 'Previsão Embarque',
    formatterType: 'date',
    formatterData: {
        hourFormat: false,
    },
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: 155,
}, {
    field: 'boardingDate',
    name: 'Data Embarque',
    formatterType: 'date',
    formatterData: {
        hourFormat: false,
    },
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: 155,
}, {
    field: 'estimatedArrivalDate',
    name: 'Previsão Chegada',
    formatterType: 'date',
    formatterData: {
        hourFormat: false,
    },
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: 155,
}, {
    field: 'arrivalDate',
    name: 'Data Chegada',
    formatterType: 'date',
    formatterData: {
        hourFormat: false,
    },
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: 155,
}, {
    field: 'estimatedDeliveryDate',
    name: 'Previsão Entrega Cliente',
    formatterType: 'date',
    formatterData: {
        hourFormat: false,
    },
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: 155,
}, {
    field: 'deliveryDate',
    name: 'Data Entrega Cliente',
    formatterType: 'date',
    formatterData: {
        hourFormat: false,
    },
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: 155,
}]
