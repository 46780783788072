export class Permission {

    public static blank() {
        return new Permission('Nova Permissão', null, null);
    }

    public name: string;
    public type: string;
    public path: string;

    constructor(name: string, type: string, path: string);
    constructor(source: {name: string, type: string, path: string});
    constructor(source: string | any, type?: string, path?: string) {
        if (typeof source === 'string') {
            this.name = source;
            this.type = type;
            this.path = path;
        } else {
            this.name = source.name;
            this.type = source.type;
            this.path = source.path;
        }
    }

    public clone() {
        return new Permission(this);
    }

    public equals(other: Permission): boolean {
        return this.name === other.name
            && this.type === other.type
            && this.path === other.path;
    }
}
