import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';

export const IMP_ORDERS_COLUMNS: IGridColumn[] = [
    {
        field: 'orderNumber',
        name: 'P.O',
        width: 30,
        formatterType: 'badge',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
    {
        field: 'vendor',
        name: 'Fornecedor',
        width: 115,
        formatterData: {
            singleField: 'name',
        },
    },
    {
        field: 'manufacturerName',
        name: 'Fabricante',
        width: 115,
        formatterType: 'multiple',
    },
    {
        field: 'totalAmount',
        name: 'Valor P.O',
        width: 115,
        formatterType: 'currency',
        headerCssClass: 'grid-header align-right',
        cssClass: 'grid-cell align-right',
    },
];
