import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';

export const COMMISSION_LOG_COLUMNS: IGridColumn[] = [
    {
        field: 'traderName',
        name: 'Trader',
        formatterType: 'default',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        width: 140,
    },
    {
        field: 'value',
        name: 'Comissão',
        formatterType: 'percentage',
        formatterData: {
            fontSize: '17px',
            color: 'dynamic',
        },
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        width: 50,
    },
    {
        field: 'startDate',
        name: 'Início Vigência',
        formatterType: 'date',
        formatterData: {
            monthFormat: true,
        },
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        width: 80,
    },
    {
        field: 'endDate',
        name: 'Fim Vigência',
        formatterType: 'date',
        formatterData: {
            monthFormat: true,
        },
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        width: 80,
    },
];
