import { Injectable } from '@angular/core';

@Injectable()
export class FollowUpStructureService {

    private asyncCustomization: boolean = false;
    private groupBag;
    private columnBag;
    private dragAnchor;
    private dropAnchor;
    private trashColumns = [];
    private labelWithSpace = [];
    private defaultOrderGroups = [];
    private defaultFollowUp = {
        groups: [],
    };
    private customFollowUp = {
        groups: [],
    };
    private mergedFollowUp = {
        groups: [],
    };

    getAsyncCustomization() {
        return this.asyncCustomization;
    }
    getGroupBag() {
        return this.groupBag;
    }
    getColumnBag() {
        return this.columnBag;
    }
    getDragAnchor() {
        return this.dragAnchor;
    }
    getDropAnchor() {
        return this.dropAnchor;
    }
    getTrashColumns() {
        return this.trashColumns;
    }
    getLabelWithSpace() {
        return this.labelWithSpace;
    }
    getDefaultFollowUp() {
        return this.defaultFollowUp;
    }
    getCustomFollowUp() {
        return this.customFollowUp;
    }
    getMergedFollowUp() {
        return this.mergedFollowUp;
    }
    getDefaultOrderGroups() {
        return this.defaultOrderGroups;
    }

    setDefaultFollowUp(defaultFollowUp) {
        this.defaultFollowUp = defaultFollowUp;
    }
    setGroupBag(groupBag) {
        this.groupBag = groupBag;
    }
    setColumnBag(columnBag) {
        this.columnBag = columnBag;
    }
    setDragAnchor(dragAnchor) {
        this.dragAnchor = dragAnchor;
    }
    setDropAnchor(dropAnchor) {
        this.dropAnchor = dropAnchor;
    }
    setTrashColumns(trashColumns) {
        this.trashColumns = trashColumns;
    }
    setLabelWithSpace(labelWithSpace) {
        this.labelWithSpace = labelWithSpace;
    }
    setCustomFollowUp(customFollowUp) {
        this.customFollowUp = customFollowUp;
    }
    setMergedFollowUp(mergedFollowUp) {
        this.mergedFollowUp = mergedFollowUp;
    }
    setAsyncCustomization(asyncCustomization) {
        this.asyncCustomization = asyncCustomization;
    }
    setDefaultOrderGroups(defaultOrderGroups) {
        this.defaultOrderGroups = defaultOrderGroups;
    }

}
