<div id="cmx-file-upload" class="cmx-file-upload">
    <div class="p-field" *ngIf="labelTitle">
        <label>{{labelTitle}}<span *ngIf="required" class="required">*</span></label>
    </div>

    <div
        class="upload-file {{ filesList?.length ? 'filled' : 'empty' }}"
        [style.border]="filesList?.length ? '2px dashed #9191c6' : '2px dashed #c9c9c9'"
        (click)="!disable && filesList?.length !== maxFiles && fileInput.click()"
        [class.disable]="disable || (filesList?.length == maxFiles ? true : false)"
        [tooltip]="!disable ? createTooltip() : ''"
        [placement]="tooltipOptions?.position || null"
        [nowrap]="tooltipOptions?.message ? tooltipOptions?.nowrap || false : false">
        <i class="fa fa-cloud-upload"></i>
        <p>CLIQUE PARA ANEXAR</p>
        <input hidden id="file-upload-input" type="file" #fileInput (change)="handleAttachFile($event.target.files);" [multiple]="multiple" [accept]="accept || null"/>
    </div>

    <div class="fileNameContainer" *ngIf="filesList?.length > 0" [style.width]="listNameWidth" [style.height]="listNameHeight" [style.max-height]="listNameMaxHeight">
        <div class="fileNameContent" *ngFor="let file of filesList; let i = index;">
            <div class="download-container" (click)="handleDownloadFile(i)">
                <span class="material-icons icon download">file_download</span>
                <span class="file-name" [tooltip]="tooltipNameOptions?.show ? file[fileNameField] : null" [placement]="tooltipNameOptions?.position || 'left'" [nowrap]="tooltipNameOptions?.nowrap || false">
                    {{ file[fileNameField] || file.name }}
                </span>
            </div>
            <span class="material-icons icon remove-file"
                *ngIf="!disable"
                [tooltip]="tooltipNameOptions?.showOnCancel ? file[fileNameField] : null"
                [placement]="tooltipNameOptions?.positionOnCancel || tooltipNameOptions?.position || 'left'"
                [nowrap]="tooltipNameOptions?.nowrap || false"
                (click)="handleRemoveAttachFile(i); fileInput.value = null"
            >cancel</span>
        </div>
    </div>
</div>
