import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, ElementRef, HostListener, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';

import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { environment } from '#environment';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { ChartsServices } from '../charts.services';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UtilsService } from '#services/_utils/utils.service';
import { UserService } from 'app/auth/_services';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { LeadTimeFilter } from './lead-time.filter';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';

@Component({
    selector: 'lead-time-chart',
    templateUrl: './lead-time.component.html',
    styleUrls: ['./../charts.scss', './lead-time.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LeadTimeComponent {
    currentUser = this.userService.getCurrentUser();
    currentUser$ = this.userService.currentUser$;
    helpDescription = this._defineHelpDescription();
    filterState: LeadTimeFilter = this._filterStateService.getLeadTimeFilter();
    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);

    readonly dropdownSettingsKPI = {
        text: 'KPI',
        singleSelection: true,
        enableSearchFilter: false
    };

    options: {
        client: DropdownOption[];
        kpiList: DropdownOption[];
    } = {
        client: [],
        kpiList: [],
    };
    editableElements = ['#leadTime'];
    asyncLeadTime = true;
    accomplished = moment('YYYYMM').format('MM/YY');
    expected = moment('YYYYMM').format('MM/YY');
    yearMonth;
    kpiSelected = [];

    isInternalUser = this.currentUser.has('INTERNAL_FILTERS') ? true : false;
    visualSwitch = {
        values: ['Colunas', 'Linhas'],
        index: 0,
    };
    chartData;
    maxMonthDifference;
    startDateRestriction = _.cloneDeep(this.filterState.getStartDate());
    endDateRestriction = _.cloneDeep(this.filterState.getEndDate());
    tabs = [
        { code: 'CURRENT_YEAR', name: 'Ano Corrente' },
        { code: 'PREVIOUS_MONTH', name: 'Mês Anterior' },
        { code: 'CURRENT_MONTH', name: 'Mês Corrente' },
    ];
    preSelectedPreset = ['CURRENT_YEAR'];
    chartVisible = true;
    noResultsFound = false;
    kpiSubscription;


    @HostListener('click') onSetZIndex() {
        this.renderer2.setStyle(this._elementRef.nativeElement, 'z-index', this.utilsService.autoIncrementValue);
    }

    constructor(
        public chartService: ChartsServices,
        public router: Router,
        private userService: UserService,
        private http: HttpClient,
        private utilsService: UtilsService,
        private userCacheService: UserCacheService,
        private _cmxDropdownService: CmxDropdownService,
        private _elementRef: ElementRef,
        private renderer2: Renderer2,
        private _filterStateService: FilterStateService
    ) {}

    ngAfterViewInit() {
        const clientGroups = this.userCacheService.getClientGroups();
        this.options.client = this._cmxDropdownService.toDropdownItems(clientGroups);
        if (this.filterState.getClientGroups().length) {
            this.getDropdownKPIs();
        } else {
            this.asyncLeadTime = false;
            this.noResultsFound = true;
        }
    }

    getDropdownKPIs(onlyDropdown?) {
        this.kpiSubscription = this.getKPIs().subscribe((kpis) => {
            if (!kpis.length) {
                this.options.kpiList = []
                this.filterState.setSelectedKpi([])
                this.utilsService.setInvisible('lead-time-chart', this.editableElements);
                this.chartVisible = false;
                this.asyncLeadTime = false;
            } else {
                this.options.kpiList = this._cmxDropdownService.toDropdownItems(kpis);
                const currentSelectedKpi = this.filterState.getSelectedKpi()[0];
                const kpiExistsInList = this.options.kpiList.some((kpi) => kpi.id === currentSelectedKpi.id);
                if (!kpiExistsInList) {
                    this.filterState.setSelectedKpi([this.options.kpiList[0]]);
                }
                if (!onlyDropdown) {
                    this.utilsService.setVisible('lead-time-chart', this.editableElements);
                    this.chartVisible = true;
                    this.loadLeadTime();
                }
            }
        });
    }

    datesChanged(event) {
        const lastStartDate = _.cloneDeep(this.filterState.getStartDate());
        const lastEndDate = _.cloneDeep(this.filterState.getEndDate());
        this.filterState.setStartDate(event.startDate);
        this.filterState.setEndDate(event.endDate);
        this._verifyDateLimit(event.startDate, event.endDate, lastStartDate, lastEndDate);
    }

    togglePeriodSwitch() {
        this.loadLeadTime();
    }

    loadLeadTime() {
        if (!this.filterState.getSelectedKpi().length || !this.filterState.getClientGroups().length) {
            this.utilsService.setInvisible('lead-time-chart', this.editableElements);
            this.asyncLeadTime = false;
            this.noResultsFound = true;
            return;
        } else {
            this.asyncLeadTime = true;
            this.utilsService.setInvisible('lead-time-chart', this.editableElements);
            this.getLeadTime().subscribe(
                (response) => {
                    const months = response ? response : [];
                    months.forEach((month) => {
                        month.yearMonth = moment(month.yearMonth, 'YYYYMM').format('MM/YY');
                        month.expectedWithUnit = `${month.accomplished.toFixed(2)} dia(s)`;
                    });
                    this.utilsService.setVisible('lead-time-chart', this.editableElements);
                    this.chartData = months;
                    this._createChart(response);
                    this.asyncLeadTime = false;
                    if (this.chartData && this.chartData.length === 0) {
                        this.utilsService.setInvisible('lead-time-chart', this.editableElements);
                        this.noResultsFound = true;
                    } else {
                        this.noResultsFound = false;
                    }
                },
                (error) => {
                    this.chartData = undefined;
                    this.asyncLeadTime = false;
                    this.utilsService.errorHandler(error, 'LeadTime');
                }
            );
        }
    }

    toggleVisualSwitch(values) {
        this.filterState.setVisual(values.value);
        this._createChart(this.chartData);
        this.filterState.savePreferences();
    }

    changePreset(selectedPreset) {
        const lastStartDate = _.cloneDeep(this.filterState.getStartDate());
        const lastEndDate = _.cloneDeep(this.filterState.getEndDate());
        if (!selectedPreset.firstChange) {
            const preset = selectedPreset.selected[0];
            if (preset) {
                this.filterState.setPeriodType(preset);
                const currentDay = moment().format('MM/YYYY');
                const firstDayOfMonth = moment().format('MM/YYYY');
                switch (preset) {
                    case 'CURRENT_YEAR':
                        this.filterState.setStartDate(moment().format('01/YYYY'));
                        this.filterState.setEndDate(currentDay);
                        break;
                    case 'CURRENT_MONTH':
                        this.filterState.setStartDate(firstDayOfMonth);
                        this.filterState.setEndDate(currentDay);
                        break;
                    case 'PREVIOUS_MONTH':
                        this.filterState.setStartDate(moment().add('-1', 'M').format('MM/YYYY'));
                        this.filterState.setEndDate(
                            moment(firstDayOfMonth, 'MM/YYYY').add('-1', 'days').format('MM/YYYY')
                        );
                        break;
                }
                this.loadLeadTime();
            }
        }
        this._verifyDateLimit(this.filterState.getStartDate(), this.filterState.getEndDate(), lastStartDate, lastEndDate);
    }

    private _verifyDateLimit(startDate, endDate, lastStartDate, lastEndDate) {
        if (startDate !== lastStartDate) {
            this.endDateRestriction = moment(this.filterState.getStartDate(), 'MM/YYYY').add(12, 'M').format('MM/YYYY');
        } else if (endDate !== lastEndDate) {
            this.startDateRestriction = moment(this.filterState.getEndDate(), 'MM/YYYY').add(-12, 'M').format('MM/YYYY');
        }
    }

    private _createChart(data) {
        const chart = am4core.create('leadTime', am4charts.XYChart);
        chart.language.locale['_decimalSeparator'] = ',';
        chart.language.locale['_thousandSeparator'] = '.';
        chart.data = data;
        chart.maskBullets = false;
        chart.legend = new am4charts.Legend();
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'yearMonth';
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.fontSize = 9;
        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = '[bold]LeadTime em dias[/]';
        valueAxis.title.fontSize = 11;
        valueAxis.fontSize = 9;
        valueAxis.extraMax = 0.05;
        if (this.filterState.getVisual() === 'Colunas') {
            // Planejado
            let column = chart.series.push(new am4charts.ColumnSeries());
            column.dataFields.valueY = 'expected';
            column.dataFields.categoryX = 'yearMonth';
            column.name = 'Planejado';
            column.columns.template.fillOpacity = 0.8;
            column.columns.template.strokeWidth = 0;
            column.columns.template.strokeOpacity = 1;
            column.columns.template.fill = am4core.color('#7194da');
            let bullet = column.bullets.push(new am4charts.LabelBullet());
            let label = bullet.label;
            label.text = '{expected}';
            bullet.fontSize = 10;
            bullet.fontWeight = '600';
            label.truncate = false;
            label.hideOversized = false;
            bullet.valign = 'top';
            bullet.dy = -10;
            bullet.strokeWidth = 0;
            // Real
            let column2 = chart.series.push(new am4charts.ColumnSeries());
            column2.dataFields.valueY = 'accomplished';
            column2.dataFields.categoryX = 'yearMonth';
            column2.name = 'Real';
            column2.columns.template.fillOpacity = 0.8;
            column2.columns.template.strokeWidth = 0;
            column2.columns.template.strokeOpacity = 1;
            column2.columns.template.fill = am4core.color('#1f3d7a');
            let bullet2 = column2.bullets.push(new am4charts.LabelBullet());
            let label2 = bullet2.label;
            label2.text = '{accomplished}';
            bullet2.fontSize = 10;
            bullet2.fontWeight = '600';
            label2.truncate = false;
            label2.hideOversized = false;
            bullet2.valign = 'top';
            bullet2.dy = -10;
            bullet2.strokeWidth = 0;
        } else {
            // Planejado
            let line = chart.series.push(new am4charts.LineSeries());
            line.dataFields.valueY = 'expected';
            line.dataFields.categoryX = 'yearMonth';
            line.name = 'Planejado';
            line.stroke = am4core.color('#7194da');
            line.strokeWidth = 3;
            line.tensionX = 0.77;
            let bullet = line.bullets.push(new am4charts.Bullet());
            bullet.tooltipText = '{valueY}';
            // Real
            let lineReal = chart.series.push(new am4charts.LineSeries());
            lineReal.dataFields.valueY = 'accomplished';
            lineReal.dataFields.categoryX = 'yearMonth';
            lineReal.name = 'Real';
            lineReal.stroke = am4core.color('#1f3d7a');
            lineReal.strokeWidth = 3;
            lineReal.tensionX = 0.77;
            let bulletReal = lineReal.bullets.push(new am4charts.Bullet());
            bulletReal.tooltipText = '{valueY}';
            chart.cursor = new am4charts.XYCursor();
        }
        $("g[filter*='id']").remove();
    }

    private getKPIs(): Observable<any> {
        return this.http.get(
            `${environment.endpoints.slaKpiService}/kpis-by-client-code`,
            this.filterState.getKpiRequest()
        );
    }

    private getLeadTime(): Observable<any> {
        return this.http.get(`${environment.endpoints.slaKpiService}/slas/leadtime`, this.filterState.getLeadTimeRequest());
    }

    private _defineHelpDescription(): object[] {
        return [
            {
                type: 'text',
                value: 'Informa o esperado e o realizado em dias da etapa (KPI) selecionada.',
            }
        ];
    }
}
