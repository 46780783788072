import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { Ng2CompleterModule } from 'ng2-completer';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { IConfig, NgxMaskModule } from 'ngx-mask';

import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { SplitterModule } from 'primeng/splitter';

import { CmxDropdownModule } from './components/cmx-dropdown/cmx-dropdown.module';
import { PipesModule } from './pipes/pipes.module';

import { CMXTooltipDirective } from './directives/cmx-tooltip/cmx-tooltip.directive';
import { CMXPopoverDirective } from './directives/cmx-popover/cmx-popover.directive';
import { DragDropUploadDirective } from './directives/dragdrop-upload/dragdrop-upload.directive';

import { LoaderComponent } from 'app/theme/layouts/loader/loader.component';
import { ListingComponent } from './components/cmx-listing/cmx-listing.component';
import { SlickPopoverComponent } from './components/cmx-custom-grid/components/cmx-slick-popover/cmx-slick-popover.component';
import { BadgeComponent } from './components/cmx-badge/badge.component';
import { CmxButtonComponent } from './components/cmx-button/button.component';
import { CmxImgUploadComponent } from './components/cmx-img-upload/img-upload.component';
import { CmxFileUploadComponent } from './components/cmx-file-upload/file-upload.component';
import { FilterSearchComponent } from './components/cmx-filter/cmx-filter-search/cmx-filter-search.component';
import { CustomDialogComponent } from './components/cmx-custom-dialog/custom-dialog.component';
import { CustomDialogService } from './components/cmx-custom-dialog/custom-dialog.service';
import { FilterMultipleDatepickerComponent } from './components/cmx-filter/cmx-filter-multiple-datepicker/cmx-filter-multiple-datepicker.component';
import { AssembledDataComponent } from './components/cmx-assembled-data/cmx-assembled-data.component';
import { SettingsClientsComponent } from 'app/shared/components/cmx-settings-clients/cmx-settings-clients.component';
import { FilterDatepickerComponent } from './components/cmx-filter/cmx-filter-datepicker/cmx-filter-datepicker.component';
import { SummaryLineComponent } from './components/cmx-summary-line/cmx-summary-line.component';
import { SwitchComponent } from './components/cmx-switch/cmx-switch.component';
import { FilterSwitchComponent } from './components/cmx-filter/cmx-filter-switch/cmx-filter-switch.component';
import { DownloadComponent } from './components/cmx-download/cmx-download.component';
import { FilterInputComponent } from './components/cmx-filter/cmx-filter-input/cmx-filter-input.component';
import { DragAndDropTableComponent } from './components/cmx-drag-and-drop-table/cmx-drag-and-drop-table.component';
import { ConfirmationModalComponent } from './components/cmx-confirmation-modal/cmx-confirmation-modal.component';
import { CustomGridComponent } from './components/cmx-custom-grid/custom-grid.component';
import { MarkerFilterComponent } from './components/cmx-marker-filter/marker-filter.component';
import { ToggleSwitchComponent } from './components/cmx-toggle-switch/toggle-switch.component';
import { PageWrapperComponent } from './components/cmx-page-wrapper/page-wrapper.component';
import { FormSelectComponent } from './components/cmx-form-select/form-select.component';
import { CmxBadgeStatusComponent } from './components/cmx-badge-status/cmx-badge-status.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { FormMessageComponent } from './components/cmx-form-message/cmx-form-message.component';
import { FilterReloadTimerComponent } from './components/cmx-filter/cmx-filter-reload-timer/cmx-filter-reload-timer.component';
import { SwitchSectionComponent } from './components/cmx-switch-section/cmx-switch-section.component';
import { CmxFilterWrapperModule } from './components/cmx-filter/cmx-filter-wrapper/cmx-filter-wrapper.module';
import { DatamartPreferencesComponent } from './components/cmx-datamart-preferences/cmx-datamart-preferences.component';
import { CmxHelpModalComponent } from './components/cmx-help-modal/cmx-help-modal.component';
import { CmxTimelineComponent } from './components/cmx-timeline/cmx-timeline.component';
import { CustomGridUtilsService } from './components/cmx-custom-grid/services/custom-grid-utils.service';
import { CmxSaveConfigComponent } from './components/cmx-save-config/cmx-save-config.component';
import { CmxSplitterLineComponent } from './components/cmx-splitter-line/cmx-splitter-line.component';
import { FormComponent } from './components/cmx-form/cmx-form.component';
import { CmxHistoricComponent } from './components/cmx-historic/cmx-historic.component';
import { SkeletonLoaderComponent } from './components/cmx-skeleton-loader/cmx-skeleton-loader.component';
import { CmxCountdownComponent } from './components/cmx-countdown/countdown.component';
import { CalendarComponent } from './components/cmx-calendar/cmx-calendar.component';

const maskConfig: Partial<IConfig> = {
    validation: false,
};

@NgModule({
    declarations: [
        AssembledDataComponent,
        BadgeComponent,
        CmxButtonComponent,
        CmxImgUploadComponent,
        CmxFileUploadComponent,
        CMXPopoverDirective,
        CMXTooltipDirective,
        ConfirmationModalComponent,
        CustomDialogComponent,
        CustomGridComponent,
        DownloadComponent,
        DragAndDropTableComponent,
        DragDropUploadDirective,
        FilterDatepickerComponent,
        FilterInputComponent,
        FilterMultipleDatepickerComponent,
        FilterSearchComponent,
        FilterSwitchComponent,
        FilterReloadTimerComponent,
        FormSelectComponent,
        FormMessageComponent,
        ListingComponent,
        LoaderComponent,
        MarkerFilterComponent,
        PageWrapperComponent,
        SettingsClientsComponent,
        SlickPopoverComponent,
        SummaryLineComponent,
        SwitchComponent,
        ToggleSwitchComponent,
        CmxBadgeStatusComponent,
        SwitchSectionComponent,
        DatamartPreferencesComponent,
        CmxHelpModalComponent,
        CmxTimelineComponent,
        CmxSaveConfigComponent,
        CmxSplitterLineComponent,
        FormComponent,
        CmxHistoricComponent,
        SkeletonLoaderComponent,
        CmxCountdownComponent,
        CalendarComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        CalendarModule,
        CmxDropdownModule,
        CurrencyMaskModule,
        DragDropModule,
        InputSwitchModule,
        InputTextareaModule,
        InputTextModule,
        Ng2CompleterModule,
        NgxMaskModule.forRoot(maskConfig),
        SplitterModule,
        PipesModule,
        CmxFilterWrapperModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        CmxDropdownModule,
        Ng2CompleterModule,
        DragDropModule,
        InputSwitchModule,
        InputTextareaModule,
        InputTextModule,
        InputNumberModule,
        DropdownModule,
        CalendarModule,
        SplitterModule,
        SplitButtonModule,
        ProgressSpinnerModule,
        PipesModule,
        RadioButtonModule,
        AssembledDataComponent,
        BadgeComponent,
        CmxButtonComponent,
        CmxImgUploadComponent,
        CmxFileUploadComponent,
        CMXPopoverDirective,
        CMXTooltipDirective,
        ConfirmationModalComponent,
        CustomDialogComponent,
        CustomGridComponent,
        DownloadComponent,
        DragAndDropTableComponent,
        DragDropUploadDirective,
        FilterDatepickerComponent,
        FilterInputComponent,
        FilterMultipleDatepickerComponent,
        FilterSearchComponent,
        FilterSwitchComponent,
        FilterReloadTimerComponent,
        FormSelectComponent,
        FormMessageComponent,
        ListingComponent,
        LoaderComponent,
        MarkerFilterComponent,
        PageWrapperComponent,
        SettingsClientsComponent,
        SlickPopoverComponent,
        SummaryLineComponent,
        SwitchComponent,
        ToggleSwitchComponent,
        CmxBadgeStatusComponent,
        TableModule,
        SwitchSectionComponent,
        CmxFilterWrapperModule,
        DatamartPreferencesComponent,
        CmxHelpModalComponent,
        CmxTimelineComponent,
        CmxSaveConfigComponent,
        CmxSplitterLineComponent,
        FormComponent,
        CmxHistoricComponent,
        SkeletonLoaderComponent,
        CmxCountdownComponent,
        CalendarComponent
    ],
    providers: [
        CustomDialogService,
    ],
    entryComponents: [ ]
})
export class SharedModule { }
