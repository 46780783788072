export const ALERT_TYPE_INFO = [{

    type: 'DEFERMENT_LI',
    title: 'Deferimento de LI',
    value: [{
        paragraph: 'Esse widget tem o objetivo de auxiliar o acompanhamento do deferimento de LI, após o protocolo da LI junto aos órgãos anuentes.',
        type: 'text', value: 'Então, dividimos em 3 status:'
    }, {
        green: 'Verde:', description: `Dentro do prazo: LI's aguardando deferimento, que não ultrapassaram o prazo médio de deferimento do órgão anuente.`
    }, {
        yellow: 'Amarelo:', description: `Próximo ao vencimento: LI's aguardando deferimento, que estão próximos ao vencimento do prazo médio do deferimento do órgão anuente. (aproximadamente 1 ou 2 dias).`
    }, {
        red: 'Vermelho:', hasLineAfter: true, description: `Expirado: LI's aguardando deferimento, que já ultrapassaram o prazo médio de deferimento do órgão anuente.`
    }, {
        paragraph: 'Cada órgão anuente possui um prazo médio de deferimento da LI após a data do protocolo, conforme abaixo:'
    }, {
        textBold: 'MAPA:', secondaryText: '8 Dias'
    }, {
        textBold: 'ANVISA:', secondaryText: '4 Dias'
    }, {
        textBold: 'INMETRO:', secondaryText: '35 Dias'
    }, {
        textBold: 'MCT:', secondaryText: '3 Dias'
    }, {
        textBold: 'IBAMA:', secondaryText: '10 Dias'
    }, {
        paragraph: 'Essa informação está localizada na coluna prazo atual no widget.'
    }, {
        paragraph: 'Na tela inicial, os dados são demonstrados em quantidade de processos.'
    }]
}, {
    type: 'DOCUMENT_DELIVERY',
    title: 'LI - Valid. para Embarque',
    value: [{
        paragraph: `Esse widget tem o objetivo de auxiliar o acompanhamento dos processos sem documentos originais
                recebidos pelo time Operacional com data de chegada próxima. Documentos originais: Invoice, packing
                list e Conhecimento de Embarque.`,
        type: 'text', value: 'Então, dividimos em 4 status:'
    }, {
        green: 'Verde:', description: `A partir de 7 dias (Para a chegada do navio).`
    }, {
        yellow: 'Amarelo:', description: ` 4 a 6 dias (Para a chegada do navio).`
    }, {
        red: 'Vermelho:', description: `0 a 3 dias (Para a chegada do navio)`
    }, {
        grey: 'Cinza:', hasLineAfter: true, description: `Vencido (Navio já atracado sem documentos originais).`
    }, {
        paragraph: 'Na tela inicial, os dados são demonstrados em quantidade de processos.'
    }]
}, {
    type: 'REGISTRATION_DEADLINE',
    title: 'LI - Valid. para Despacho',
    value: [{
        paragraph: 'Esse widget tem o objetivo de auxiliar o acompanhamento do prazo do vencimento do Limite de Registro da DI.',
        type: 'text', value: 'Então, dividimos em 4 status:'
    }, {
        green: 'Verde:', description: 'A partir de 61 dias (para vencer o prazo).'
    }, {
        yellow: 'Amarelo:', description: '30 até 60 dias (para vencer o prazo).'
    }, {
        red: 'Vermelho:', description: '29 dias até 1 dia (para vencer o prazo).'
    }, {
        grey: 'Cinza:', hasLineAfter: true, description: 'Prazo vencido (Mercadoria em Perdimento).'
    }, {
        paragraph: 'Na tela inicial, os dados são demonstrados em quantidade de processos.'
    }]
}, {
    type: 'BOARDING_LIMIT',
    title: 'Documentos Originais',
    value: [{
        paragraph: `Esse widget tem o objetivo de auxiliar o acompanhamento da Validade de Embarque da LI. Portanto
                podemos analisar os processos que possuem LI e não possuem data de embarque preenchida no sistema.
                Realizando o acompanhamento de quantos dias faltam a vencer da validade de embarque das LI's vinculadas ao processo.`,
        type: 'text', value: 'Então, dividimos em 4 status:'
    }, {
        green: 'Verde:', description: '26 a 90 dias - Dias para vencer a Validade de Embarque da LI.'
    }, {
        yellow: 'Amarelo:', description: '16 a 25 dias - Dias para vencer a Validade de Embarque da LI.'
    }, {
        red: 'Vermelho:', description: '0 a 15 dias - Dias para vencer a Validade de Embarque da LI.'
    }, {
        grey: 'Cinza:', hasLineAfter: true, description: 'Vencido - Prazo vencido - Data de validade de embarque da LI anterior a data de hoje e processos sem data de embarque preenchida no sistema.'
    }, {
        paragraph: 'Na tela inicial, os dados são demonstrados em quantidade de processos.'
    }]
}, {
    type: 'RESCHEDULE_CLEARANCE',
    title: 'Perdimento',
    value: [{
        paragraph: `Esse widget tem o objetivo de auxiliar o acompanhamento da Validade de Despacho da LI. Portanto
            podemos analisar os processos que possuem LI e não possuem DI vinculada ao processo Realizando o
            acompanhamento dos dias que faltam a vencer da validade de despacho das LI's vinculadas ao processo.`,
        type: 'text', value: 'Então, dividimos em 4 status:'
    }, {
        green: 'Verde:', description: 'A partir de 9 dias - Dias para vencer a Validade de despacho da LI.'
    }, {
        yellow: 'Amarelo:', description: '5 a 8 dias - Dias para vencer a Validade de despacho da LI.'
    }, {
        red: 'Vermelho:', description: '1 a 4 dias - Dias para vencer a Validade de despacho da LI.'
    }, {
        grey: 'Cinza:', hasLineAfter: true, description: 'Vencido - Prazo vencido - Data de validade de despacho da LI anterior a data de hoje e processos sem DI vinculada.'
    }, {
        paragraph: 'Na tela inicial, os dados são demonstrados em quantidade de processos.'
    }]
}, {
    type: 'DA_EXPIRATION',
    title: 'Vencimento DA',
    value: [{
        paragraph: `Esse widget tem o objetivo de auxiliar o acompanhamento da Data de Expiração do processo`,
        type: 'text', value: 'Então, dividimos em 4 status:'
    }, {
        green: 'Verde:', description: '76 dias  ou mais da data de expiração do processo.'
    }, {
        yellow: 'Amarelo:', description: '75 até 56 dias da data de expiração do processo.'
    }, {
        red: 'Vermelho:', description: '55 dias até 1 dias da data de expiração do processo.'
    }, {
        grey: 'Cinza:', description: 'A partir 40 dias da data de expiração do processo.'
    }]
}, {
    type: 'FREE_TIME',
    title: 'Free Time',
    value: [{
        paragraph: `Esse widget tem o objetivo de auxiliar o acompanhamento do vencimento do Free time dos containers.`,
        type: 'text', value: 'Então, dividimos em 4 status:'
    }, {
        green: 'Verde:', description: 'A partir de 9 dias: Dias para a data do vencimento do Free time.'
    }, {
        yellow: 'Amarelo:', description: '5 a 8 dias: Dias para a data do vencimento do Free time.'
    }, {
        red: 'Vermelho:', description: '0 a 4 dias: Dias para a data do vencimento do Free time.'
    }, {
        grey: 'Cinza:', hasLineAfter: true, description: 'Vencido: Container com pagamento de demurrage em andamento.'
    }, {
        paragraph: 'Na tela inicial, os dados são demonstrados em quantidade de processos.'
    }]
}, {
    type: 'STORAGE_LIMIT',
    title: 'Venc. de Armazenagem',
    value: [{
        paragraph: `Esse widget tem o objetivo de auxiliar o acompanhamento do vencimento de armazenagem:`,
        type: 'text', value: 'Então, dividimos em 4 status:'
    }, {
        green: 'Verde:', description: '6 dias ou mais -. Para o 2° período de armazenagem.'
    }, {
        yellow: 'Amarelo:', description: '3 a 5 dias – Para o 2° período de armazenagem.'
    }, {
        red: 'Vermelho:', description: '0 a 2 dias - Para o 2° período de armazenagem.'
    }, {
        grey: 'Cinza:', hasLineAfter: true, description: 'A partir do 2° Período – Processos com cobrança de armazenagem a partir do segundo período.'
    }, {
        paragraph: 'Cada armazém possui prazos diferentes para cada período de armazenagem. Na tela inicial, os dados são demonstrados em quantidade de processos.'
    }, {
        type: 'table', columns: [{
            name: 'ARMAZEM', field: 'storage', width: 60
        }, {
            name: 'BASE DE CALCULO', field: 'baseAccount', width: 60
        }, {
            name: 'UF', field: 'uf', width: 10
        }, {
            name: '1ª PERÍODO', field: 'firstPeriod', width: 60
        }, {
            name: '2ª PERÍODO', field: 'secondPeriod', width: 60
        }, {
            name: '3ª PERÍODO +', field: 'thirdPeriod', width: 60
        }], rows: [{
            id: 1,
            storage: 'NAVEGANTES',
            baseAccount: 'DATA DE CHEGADA',
            uf: 'SC',
            firstPeriod: '7 DIAS',
            secondPeriod: 'AO DIA (1 DIA)',
            thirdPeriod: 'MULTIPLOS DO SEGUNDO PERÍODO',
        }, {
            id: 2,
            storage: 'ITAJAÍ',
            baseAccount: 'DATA DE CHEGADA',
            uf: 'SC',
            firstPeriod: '8 DIAS',
            secondPeriod: 'DO 9º AO 16º',
            thirdPeriod: 'MULTIPLOS DO SEGUNDO PERÍODO',
        }, {
            id: 3,
            storage: 'ITAPOÁ',
            baseAccount: 'DATA DE CHEGADA',
            uf: 'SC',
            firstPeriod: '5 DIAS',
            secondPeriod: 'DO 6º AO 20º',
            thirdPeriod: 'MULTIPLOS DO SEGUNDO PERÍODO',
        }, {
            id: 4,
            storage: 'TVV',
            baseAccount: 'DATA DE CHEGADA',
            uf: 'ES',
            firstPeriod: '12 DIAS',
            secondPeriod: '12 DIAS',
            thirdPeriod: 'MULTIPLOS DO SEGUNDO PERÍODO',
        }, {
            id: 5,
            storage: 'SUAPE',
            baseAccount: 'DATA DE CHEGADA',
            uf: 'PE',
            firstPeriod: '3 DIAS (AO DIA)',
            secondPeriod: 'AO DIA (1 DIA)',
            thirdPeriod: 'MULTIPLOS DO SEGUNDO PERÍODO',
        }, {
            id: 6,
            storage: 'POLY',
            baseAccount: 'DATA DE CHEGADA NO RECINTO',
            uf: 'SC',
            firstPeriod: '10 DIAS',
            secondPeriod: 'AO DIA (1 DIA)',
            thirdPeriod: 'MULTIPLOS DO SEGUNDO PERÍODO',
        }, {
            id: 7,
            storage: 'CLIF',
            baseAccount: 'DATA DE CHEGADA NO RECINTO',
            uf: 'SC',
            firstPeriod: '10 DIAS',
            secondPeriod: 'AO DIA (1 DIA)',
            thirdPeriod: 'MULTIPLOS DO SEGUNDO PERÍODO',
        }, {
            id: 8,
            storage: 'TERCA',
            baseAccount: 'DATA DE CHEGADA NO RECINTO',
            uf: 'SC',
            firstPeriod: '10 DIAS',
            secondPeriod: '10 DIAS',
            thirdPeriod: 'MULTIPLOS DO SEGUNDO PERÍODO',
        }, {
            id: 9,
            storage: 'TORA',
            baseAccount: 'DATA DE CHEGADA NO RECINTO',
            uf: 'MG',
            firstPeriod: '10 DIAS',
            secondPeriod: 'AO DIA (1 DIA)',
            thirdPeriod: 'MULTIPLOS DO SEGUNDO PERÍODO',
        }, {
            id: 10,
            storage: 'LOCALFRIO',
            baseAccount: 'DATA DE CHEGADA NO RECINTO',
            uf: 'PE',
            firstPeriod: '10 DIAS',
            secondPeriod: '10 DIAS',
            thirdPeriod: 'MULTIPLOS DO SEGUNDO PERÍODO',
        }, {
            id: 11,
            storage: 'MULTILOG',
            baseAccount: 'DATA DE CHEGADA NO RECINTO',
            uf: 'SP',
            firstPeriod: '10 DIAS',
            secondPeriod: '10 DIAS',
            thirdPeriod: 'MULTIPLOS DO SEGUNDO PERÍODO',
        }, {
            id: 12,
            storage: 'MULTILOG',
            baseAccount: 'DATA DE CHEGADA NO RECINTO',
            uf: 'SP',
            firstPeriod: '15 DIAS',
            secondPeriod: '15 DIAS',
            thirdPeriod: 'MULTIPLOS DO SEGUNDO PERÍODO',
        }, {
            id: 13,
            storage: 'MULTILOG',
            baseAccount: 'DATA DE CHEGADA NO RECINTO',
            uf: 'SP',
            firstPeriod: '15 DIAS',
            secondPeriod: '15 DIAS',
            thirdPeriod: 'MULTIPLOS DO SEGUNDO PERÍODO',
        }, {
            id: 14,
            storage: 'MULTILOG',
            baseAccount: 'DATA DE CHEGADA NO RECINTO',
            uf: 'SC',
            firstPeriod: '10 DIAS',
            secondPeriod: 'AO DIA (1 DIA)',
            thirdPeriod: 'MULTIPLOS DO SEGUNDO PERÍODO',
        }],
    }]
}, {
    type: 'MERCHANDISE_NEEDED',
    title: 'Necessidade do Cliente',
    value: [{
        paragraph: `Esse widget tem o objetivo de auxiliar o acompanhamento da Necessidade do Cliente`,
        type: 'text', value: 'Então, dividimos em 4 status:'
    }, {
        green: 'Verde:', description: '50 dias  ou mais da data de necessidade do Cliente.'
    }, {
        yellow: 'Amarelo:', description: '49 até 21 dias da data de necessidade do Cliente.'
    }, {
        red: 'Vermelho:', description: '20 dias até 11 dias da data de necessidade do Cliente.'
    }, {
        grey: 'Cinza:', description: 'A partir 10 dias da data de necessidade do Cliente.'
    }]
}, {
    type: 'TOTAL',
    title: 'Média Total',
}]
