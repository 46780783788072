<div *ngIf="asyncCrProcesses == false">
    <loader></loader>
</div>

<h5>{{ CrName }} {{ CrImpQtyName }}</h5>
<div class="moveBtn">
    <div class="moveLine"></div>
</div>
<i class="resizeBtn la la-expand" (click)="chartService.resizeBtn($event)"></i>
<i class="editBtn la la-ellipsis-v" (click)="chartService.editBtn($event); changeDateCustom();"></i>
<div style="margin-left: 50px; width: 90%; float: right;" class="chart" id="cr-processes"></div>
<div *ngIf="asyncCrProcesses === true && chartData === undefined" class="reloadContainer">
    <div class="reloadContent">
        <i (click)="loadCrProcesses()" class="material-icons">refresh</i>
    </div>
</div>
<div class="checkmark-container left-menu">
    <span (click)="crChangeDateParam($event, 2)" class="checkmark cr">3 Meses</span>
    <span (click)="crChangeDateParam($event, 5)" class="checkmark cr">6 Meses</span>
    <span (click)="crChangeDateParam($event, 11)" class="checkmark cr">12 Meses</span>
    <span (click)="crChangeDateParam($event, 98)" class="checkmark cr">Mês Anterior</span>
    <span (click)="crChangeDateParam($event, 0)" class="checkmark cr checked">Mês Corrente</span>
    <span (click)="crChangeDateParam($event, 99)" class="checkmark cr">Ano Corrente</span>
    <span class="checkmark cr custom hidden">{{startDate}} • {{endDate}}</span>
</div>

<!-- editModal -->
<div class="editModal cr-processes-chart hidden">
    <div class="modalContent">
        <h5>Editar - {{ CrName }} {{ CrImpQtyName }}</h5>
        <div class="flex-container-vertical">
        <p class="sub-title">Período:</p>
        <div class="flex-container-horizontal" style="justify-content: space-around;">
            <div class="input-group">
                <span class="input-group-addon">De</span>
                <input name="periodFrom-cr" id="periodFrom-cr" [(ngModel)]="startDate" type="text" class="form-control m-input"/>
            </div>
            <div class="input-group">
                <span class="input-group-addon">Até</span>
                <input name="periodTo-cr" id="periodTo-cr" [(ngModel)]="endDate" type="text" class="form-control m-input"/>
            </div>
        </div>
        <p class="datepicker-invalid cr hidden">Por favor, insira uma data válida.</p>
    </div>
    </div>
</div>
