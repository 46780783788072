import { HttpHeaders, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { AppModule } from 'app/app.module';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IExchangeSPListingFilter } from './interface/exchange-sp-listing.interface';

export class ExchangeSpListingFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);
    private _initialExchangeSpListingFilter: IExchangeSPListingFilter = <IExchangeSPListingFilter>{};
    private _exchangeSpListingFilter: IExchangeSPListingFilter = <IExchangeSPListingFilter>{
        status: [],
        documentaryAnalysisStatus: [],
        subsidiaryNames: [],
    };

    private startDate;
    private endDate;
    private filterField;
    private filterValue;
    private page: number;
    private pageSize: number = 50;

    constructor(filter?: ExchangeSpListingFilter) {
        this._exchangeSpListingFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'exchangeSpListingFilter', this._exchangeSpListingFilter, filter);
        this._initialExchangeSpListingFilter = _.cloneDeep(this._exchangeSpListingFilter);

        this.filterField = filter ? filter.filterField : '';
        this.filterValue = filter ? filter.filterValue : '';
        this.startDate = filter ? filter.startDate : moment().format('DD/MM/YYYY');
        this.endDate = filter ? filter.endDate : moment().add(13, 'days').format('DD/MM/YYYY');
        this.page = filter ? filter.page : 0;
    }

    getStatus() { return this._exchangeSpListingFilter.status; }
    getDocumentaryAnalysisStatus() { return this._exchangeSpListingFilter.documentaryAnalysisStatus; }
    getSubsidiaryNames(): any[] { return this._exchangeSpListingFilter.subsidiaryNames; }
    getStartDate() { return this.startDate; }
    getEndDate() { return this.endDate; }
    getFilterField() { return this.filterField; }
    getFilterValue() { return this.filterValue; }
    getPage() { return this.page; }
    getPageSize() { return this.pageSize; }

    setStatus(status): void { this._exchangeSpListingFilter.status = status }
    setDocumentaryAnalysisStatus(documentaryAnalysisStatus): void { this._exchangeSpListingFilter.documentaryAnalysisStatus = documentaryAnalysisStatus }
    setSubsidiaryNames(subsidiaryNames): void { this._exchangeSpListingFilter.subsidiaryNames = subsidiaryNames; }
    setStartDate(startDate): void { this.startDate = startDate }
    setEndDate(endDate): void { this.endDate = endDate }
    setFilterField(filterField): void { this.filterField = filterField }
    setFilterValue(filterValue): void { this.filterValue = filterValue }
    setPage(page): void { this.page = page }
    setPageSize(size): void { this.pageSize = size }

    getRequest() {
        let params = new HttpParams();
        let headers = new HttpHeaders();

        this._initialExchangeSpListingFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialExchangeSpListingFilter, this._exchangeSpListingFilter, 'exchangeSpListingFilter', this._userCacheService.getUserPreferences().id);

        params = params.append('status', this.getStatus().map((c) => c.id).join(','));
        params = params.append('documentaryAnalysisStatus', this.getDocumentaryAnalysisStatus().map((c) => c.id).join(','));
        params = params.append('subsidiaryNames', this.getSubsidiaryNames().map((c) => c.itemName).join(','));
        params = params.append('startDate', moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD'));
        params = params.append('endDate', moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD'));
        params = params.append('page', `${this.getPage()}`);
        params = params.append('size', `${this.getPageSize()}`);

        if (this.getFilterField() && this.getFilterValue()) {
            params = params.append(`${this.getFilterField()}`, `${this.getFilterValue()}`);
        }

        this.removeNullValuesFromQueryParams(params);

        return { params, headers };
    }

    removeNullValuesFromQueryParams(params: HttpParams) {
        const paramsKeysAux = params.keys();
        paramsKeysAux.forEach((key) => {
          const value = params.get(key);
          if (value === null || value === undefined || value === '') {
            params['map'].delete(key);
          }
        });

        return params;
    }
}
