export const ROUND_SETTINGS = {
    roundIcons: {
        none: '',
        empty: '',
        edit: 'mode_edit',
        delete: 'delete_forever',
        download: 'download',
        view: 'visibility',
        details: 'notes',
        add: 'add',
    },
    roundColors: {
        green: 'green',
        yellow: '#ffb515',
        red: 'rgb(128, 0, 0)',
        grey: 'grey',
        VERDE: 'green',
        AMARELO: '#ffb515',
        VERMELHO: 'rgb(128, 0, 0)',
        CINZA: 'grey',
    },
};
