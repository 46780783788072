import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class CustomGridHorizontalAnchoringService {

    viewport: string;

    defineAnchor(columns, gridId, frozenColumn) {
        const hasFrozenColumn = frozenColumn !== undefined && frozenColumn !== null && frozenColumn !== -1;
        this.viewport = hasFrozenColumn ? '.slick-viewport-right' : '.slick-viewport-left';
        const gridCanvas = hasFrozenColumn ? '.grid-canvas-right' : '.grid-canvas-left'
        const table = $(`#${gridId} ${this.viewport}`);
        const innerTable = $(`#${gridId} ${gridCanvas}`);
        const groupNames = _.uniqBy(columns, 'groupName').filter(e => e.groupName !== null && e.groupName !== '')?.map(e => e.groupName);
        if (groupNames.length > 1 && innerTable?.width() > table?.width()) {
            const tableWidth = $(`#${gridId}`)?.width();
            const container = document.createElement('div');
            container.classList.add('cmx-grid-horizontal-anchor-container');
            const content = document.createElement('div');
            content.classList.add('cmx-grid-horizontal-anchor-content');
            container.appendChild(content);
            content.setAttribute("style", `width: ${tableWidth}px`);
            this._createArrows(container, gridId, 'left');
            groupNames.forEach((groupName) => {
                if (groupName) {
                    const anchor = document.createElement('div');
                    anchor.classList.add('cmx-grid-horizontal-anchor');
                    anchor.setAttribute('id', `cmx-grid-anchor-${groupName?.replace(/\ /g, '')?.replace(/\&/g, '')}`);
                    anchor.innerHTML = groupName;
                    anchor.addEventListener('click', () => {
                        this._scrollToGroup(groupName.replace(/\ /g, '').replace(/\&/g, ''), gridId, hasFrozenColumn);
                    });
                    content.appendChild(anchor);
                }
            });
            this._createArrows(container, gridId, 'right');
            document.getElementById(gridId).appendChild(container);
            this._getActualGroupListenner(gridId, hasFrozenColumn);
            this._verifyAnchorContainerWidth(gridId);
        }
    }

    private _scrollToGroup(groupName, gridId, hasFrozenColumn) {
        const offset = $(`#${gridId} #${groupName}`)?.offset();
        const table = $(`#${gridId} ${this.viewport}`);
        const currentPosition = $(`#${gridId} ${this.viewport}`)?.scrollLeft();
        table.stop();
        table.animate({ scrollLeft: offset.left - 40 + (hasFrozenColumn ? 0 : currentPosition) }, 1000);
    }

    private _getActualGroupListenner(gridId, hasFrozenColumn) {
        const table = $(`#${gridId} ${this.viewport}`);
        table.on('scroll', () => {
            const anchors = document.querySelectorAll(`#${gridId} .cmx-grid-horizontal-anchor`);
            anchors.forEach((a) => a.classList.remove('selected'));
            const currentPosition = $(`#${gridId} ${this.viewport}`)?.scrollLeft();
            anchors.forEach((a, index) => {
                const group = $(a)?.attr('id')?.replace('cmx-grid-anchor-', '');
                const offset = $(`#${gridId} #${group}`)?.offset();
                const groupWidth = $(`#${gridId} #${group}`)?.width();
                const groupPosition = offset.left - 40 + (hasFrozenColumn ? 0 : currentPosition);
                const groupEndPosition = groupPosition + groupWidth;
                if (currentPosition >= $(`#${gridId} ${this.viewport}`)[0].scrollWidth - $(`#${gridId} ${this.viewport}`)[0].clientWidth) {
                    anchors[anchors.length - 1].classList.add('selected');
                } else if (groupPosition <= currentPosition && currentPosition <= groupEndPosition) {
                    a.classList.add('selected');
                } else if ((currentPosition === 0 || currentPosition === 1) && index === 0) {
                    a.classList.add('selected');
                }
            })
        });
    }

    private _createArrows(container, gridId, size) {
        const arrowContainer = document.createElement('div');
        const arrow = document.createElement('div');
        arrow.classList.add('material-icons');
        arrow.innerHTML = `chevron_${size}`;
        arrowContainer.appendChild(arrow);
        arrowContainer.classList.add('cmx-grid-horizontal-anchor-arrow');
        arrowContainer.classList.add('hidden');
        arrowContainer.classList.add(size);
        arrowContainer.addEventListener('click', () => {
            const content = $(`#${gridId} .cmx-grid-horizontal-anchor-content`);
            content.animate({ scrollLeft: content?.offset()?.left + (size === 'left' ? -content.width()/2 : content.width()/2) }, 1000);
        });
        container.appendChild(arrowContainer);
    }

    private _verifyAnchorContainerWidth(gridId) {
        const tableWidth = $(`#${gridId}`)?.width();
        const anchorContent = $(`#${gridId} .cmx-grid-horizontal-anchor-content`);
        let innerAnchorWidth = 0;
        anchorContent.children().each((i, e) => {
            innerAnchorWidth += $(e)?.width() + 20 + 4; // padding + margin
        });
        if (innerAnchorWidth > tableWidth) {
            $(`#${gridId} .cmx-grid-horizontal-anchor-arrow`)?.removeClass('hidden');
            $(`#${gridId} .cmx-grid-horizontal-anchor-content`)?.addClass('scrollable');
        }
    }

}
