<div id="cmx-img-upload" class="cmx-img-upload" [style.width]="width" [style.height]="height">
    <label class="label-flex">
        <div class="title">
            {{title ? title : 'Imagem'}}<span class="required" *ngIf="required">*</span>
        </div>
        <i
            class="btn-info fa fa-info"
            [tooltip]="tooltipOptions?.show ? createTooltip() : ''"
            [placement]="tooltipOptions?.position || 'right'"
            [nowrap]="true"
        ></i>
    </label>
    <div id="cmx-img-drop-file" class="drop-file" (click)="!disable && fileInput.click()" [class.disable]="disable">
        <ng-container *ngIf="!statusImage" id="cmx-img-select-file">
            <span *ngIf="!imagePreview && !disable" class="material-icons">add_a_photo</span>
            <span *ngIf="!imagePreview && disable" class="material-icons">no_photography</span>
        </ng-container>
        <ng-container *ngIf="statusImage === 'loading'">
            <loader></loader>
        </ng-container>
        <ng-container *ngIf="statusImage === 'loaded'">
            <img [src]="imagePreview" *ngIf="imagePreview" alt="" [style.maxWidth]="width" (onerror)="handleRemoveAttachFile($event)"/>
            <button id="cmx-img-remove-file" class="btn-remove" *ngIf="imagePreview" (click)="handleRemoveAttachFile($event)">
                <i class="fa fa-times"></i>
            </button>
        </ng-container>
    </div>
    <input
        id="cmx-img-input-file"
        type="file"
        hidden
        #fileInput
        [accept]="accept ? accept : 'image/*'"
        (click)="handleCleanAttach($event)"
        (change)="handleAttachFile($event.target.files[0])"
    />
</div>
