import { Component } from '@angular/core';
import { ProjectPricingService } from '../../project-pricing.service';

@Component({
    selector: 'project-pricing-details',
    templateUrl: './project-pricing-details.component.html',
    styleUrls: ['./project-pricing-details.component.scss'],
})
export class ProjectPricingDetailsComponent {

    constructor(public projectPricingService: ProjectPricingService) { }

}
