<cmx-custom-dialog [id]="dialogId" [childrenDialogId]="childDialogId" content="full" animation="bottom-top">
    <ng-container cmx-custom-dialog-header>
        <div *ngIf="!loading" class="alert-detail-header">
            <span class="title">
                {{ headerInfo?.title }}
            </span>
            <span class="separator">•</span>
            <span class="subtitle" [style.color]="utils.getColorBySeverity(headerInfo?.severity)">{{
                headerInfo?.severityName
            }}</span>
        </div>
    </ng-container>

    <ng-container cmx-custom-dialog-body>
        <ng-container *ngIf="loading">
            <loader></loader>
        </ng-container>
        <ng-container *ngIf="!loading">
            <cmx-custom-grid
                [loading]="loading"
                [columns]="gridColumns"
                [rows]="gridRows"
                [options]="{ forceFitColumns: true }"
                [styles]="{ height: '95%', 'margin-top': 0 }"
                (onCallback)="onGridCallback($event)"
            ></cmx-custom-grid>
        </ng-container>
    </ng-container>
</cmx-custom-dialog>

<alert-client-dialog></alert-client-dialog>
