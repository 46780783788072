import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DownloadModalService {

    public isVisibleDownloads = false;
    public reloadDownloads = new Subject<any>();
    public reloadDownloads$ = this.reloadDownloads.asObservable();

    public openDownloads() {
        this.isVisibleDownloads = true;
        $('.asyncDownloadsContainer').addClass('border');
        $('.asyncDownloadsContainer').removeClass('hidden');
        this.reloadDownloads.next();
    }

    public closeDownloads() {
        this.isVisibleDownloads = false;
        $('.asyncDownloadsContainer').removeClass('border');
        $('.asyncDownloadsContainer').addClass('hidden');
    }

}
