import { _applyPosFormatters } from "./pos-formatters/pos-formatters";
import { FORMATTERS } from "./_formatters";

export function multipleFormatter(row, cell, value, columnDef, dataContext) {
    const includeZero = (columnDef.formatterData && columnDef.formatterData?.includeZero) || null;
    if ((!value === null || !value === undefined || (!includeZero && value === 0)) || (Array.isArray(value) && !value?.length)) {
        return (columnDef.formatterData && columnDef.formatterData.emptyMessage) || '\u2014';
    }
    const attributeValue = (columnDef.formatterData && columnDef.formatterData.attributeValue) || null;
    let filteredValue = Array.isArray(value) ? value.filter((v) => v !== null) : value;
    const onlyFirst = (columnDef.formatterData && columnDef.formatterData.onlyFirst) || false;
    const showFirst = (columnDef.formatterData && columnDef.formatterData.showFirst) || false;
    const customLabel = (columnDef.formatterData && columnDef.formatterData.customLabel) || false;
    const showRatioValues =  (columnDef.formatterData && columnDef.formatterData.showRatioValues) || false;

    let label = `${(columnDef.formatterData && columnDef.formatterData.label) || 'Vários'} (${filteredValue?.length})`;

    if(filteredValue && Array.isArray(filteredValue) && filteredValue?.length > 0 && attributeValue && showRatioValues) {
        let filteredUniqueValue = filteredValue?.map(v => v[attributeValue]);
        let filteredNonEmptyValue = filteredUniqueValue.filter(v => (v != null && (typeof v !== 'string' || v.trim() !== '')));
        if(filteredUniqueValue?.length > 0) {
            filteredUniqueValue = [...new Set(filteredUniqueValue)];
        }

        if(filteredUniqueValue?.length > 1) {
            label = `${(columnDef.formatterData && columnDef.formatterData.label) || 'Vários'} (${filteredNonEmptyValue?.length}/${filteredValue?.length})`;
        } else {
            filteredValue = filteredUniqueValue;
        }
    }

    if ( !filteredValue || !filteredValue?.length || typeof filteredValue === 'string' || (Array.isArray(filteredValue) && filteredValue && filteredValue?.length === 1 && !customLabel) ) {
        const formatterType = (columnDef.formatterData && columnDef.formatterData.formatterType) || 'default';
        const resultValue = Array.isArray(filteredValue) && filteredValue?.length > 0 ? attributeValue && !showRatioValues ? filteredValue[0][attributeValue] : filteredValue[0] : filteredValue;
        return FORMATTERS[formatterType](row, cell, resultValue, columnDef, dataContext);
    }

    if (filteredValue) {
        if (Array.isArray(filteredValue) && filteredValue?.length > 0 && customLabel) {
            let afterLength = `(${value?.length - 1} ${customLabel})`;

            if(showFirst && Array.isArray(customLabel)) {
                afterLength = `(${value?.length - 1} ${customLabel[0]})`;
            }

            label = showFirst ? `${filteredValue[0][attributeValue] || filteredValue[0]} ${afterLength}` : afterLength;
        } else if (filteredValue?.length > 1 && !customLabel) {
            const afterLength = onlyFirst ? '' : `(+${value?.length - 1})`;
            label = columnDef.formatterData && showFirst ? `${filteredValue[0][attributeValue] || filteredValue[0]} ${afterLength}` : label;
        }
    }

    const contentValue = `<span class="${onlyFirst ? '' : 'multiple pointer'}">${label}</span>`;
    const preventPrefixSuffix = label.includes('Vários (');
    return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext, preventPrefixSuffix);
}
