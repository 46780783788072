import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'value_in_pt_br',
})
export class ValuePtBrPipe implements PipeTransform {

    transform(input: number): string {

        if (input == null) {

            return ''
        }

        return input.toLocaleString('pt-br')
    }
}
