import { ICMXFilterInput } from './../cmx-filter-datepicker/interfaces/cmx-filter-input.interface';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import Inputmask from 'inputmask';

@Component({
    selector: 'cmx-filter-input',
    templateUrl: './cmx-filter-input.component.html',
    styleUrls: ['./cmx-filter-input.component.scss'],
})
export class FilterInputComponent {
    @Input() id: string = null;
    @Input() value: string = '';
    @Input() placeholder?: string = ''
    @Input() mask?: string;
    @Input() width?: string = null;
    @Input('max-length') maxLength: number = null;
    @Input('icon') hasIcon: ICMXFilterInput = null;

    @Output('onInputEvent') onInputEvent = new EventEmitter();

    ngOnInit(): void {
        if (!this.id) {
            console.error('Filter Input must have an ID');
        }
    }

    ngAfterViewInit(): void {
        if (this.mask) {
            this._applyMask();
        }
    }

    onInput(event) {
        this.value = event.target.value;
        this.onInputEvent.emit(this.mask === 'currency' ? this._convertCurrency(event.target.value) : event.target.value);
    }

    private _applyMask() {
        if (this.mask === 'currency') {
            Inputmask('decimal', { 
                numericInput: true,
                alias: 'numeric',
                groupSeparator: '.',
                autoGroup: true,
                digits: '2',
                radixPoint: ",",
                digitsOptional: false,
                allowMinus: false,
                placeholder: '0,00'
            }).mask($(`#${this.id}`));
        }
    }

    private _convertCurrency(value) {
        return value.replace(/\./g, '').replace(',', '.');
    }

}
