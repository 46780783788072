import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { IDataType } from './interfaces/dataType.interface';

@Component({
    selector: 'cmx-filter-multiple-datepicker',
    templateUrl: './cmx-filter-multiple-datepicker.component.html',
    styleUrls: ['./cmx-filter-multiple-datepicker.component.scss'],
})
export class FilterMultipleDatepickerComponent {
    @Input('dateTypes') dateTypes: IDataType[];
    @Output('onChangeDates') onChangeDates = new EventEmitter();
    @ViewChildren('calendars') calendars: QueryList<any>;

    startDate = {};
    endDate = {};
    desactivated = [];

    ngOnInit() {
        this.dateTypes?.map((dateType) => {
            this.startDate[dateType.code] = dateType.range[1] ? dateType.range[0] || dateType.range[1] : dateType.range[0] || new Date();
            this.endDate[dateType.code] = dateType.range[0] ? dateType.range[1] || dateType.range[0] : dateType.range[1] || new Date();
        })
    }

    changeDates(dates, name, code, activated) {
        this.startDate[code] = dates[1] ? dates[0] || dates[1] : dates[0] || new Date();
        this.endDate[code] = dates[0] ? dates[1] || dates[0] : dates[1] || new Date();
        this.onChangeDates.emit({ name, code,
            startDate: this.startDate[code],
            endDate: this._verifyEndDate(this.startDate[code], this.endDate[code]),
            activated
        });
    }

    openCalendar(dateType) {
        this.calendars.toArray().forEach((calendar) => {
            if (calendar.el.nativeElement.id === dateType.code) {
                const element = calendar.el.nativeElement;
                const calendarInput = element.children[0].children[0];
                calendarInput.focus();
            }
        });
    }

    clearDate(dateType) {
        this._verifyEndDate(this.startDate[dateType.code], this.endDate[dateType.code]);
        const element = document.getElementById(dateType.code);
        const noDataElement = document.getElementById(`${dateType.code}-noData`);
        if (element.classList.contains('hidden')) {
            this.desactivated.splice(this.desactivated.indexOf(dateType.code), 1);
            dateType.activated = true;
            element.classList.remove('hidden');
            noDataElement.classList.add('hidden');
            this.onChangeDates.emit({
                name: dateType.name,
                code: dateType.code,
                startDate: this.startDate[dateType.code],
                endDate: this._verifyEndDate(this.startDate[dateType.code], this.endDate[dateType.code]),
                activated: dateType.activated,
            });
        } else {
            this.desactivated.push(dateType.code);
            dateType.activated = false;
            element.classList.add('hidden');
            noDataElement.classList.remove('hidden');
            this.onChangeDates.emit({
                name: dateType.name,
                code: dateType.code,
                startDate: this.startDate[dateType.code],
                endDate: this._verifyEndDate(this.startDate[dateType.code], this.endDate[dateType.code]),
                activated: dateType.activated,
            });
        }
    }

    private _verifyEndDate(startDate, endDate) {
        return startDate === endDate ? new Date() : endDate
    }
}
