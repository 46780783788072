<div  *ngIf="asyncRegistrationLI" >
    <loader></loader>
</div>
<div class="title-container">
    <span class="analyticsName">TOP LI's Registradas</span>
    <cmx-help-modal help-title="TOP LI's Registradas" [help-description]="helpDescription"></cmx-help-modal>
    <h5>TOP LI's Registradas</h5>
</div>
    <div class="moveBtn">
        <div class="moveLine"></div>
    </div>
    <div id="searchLine" class="reduzed">
        <div class="input-group" >
            <cmx-filter-datepicker
                [alone]="true"
                [date]="'Registro'"
                [startDate]="filterState.getStartDate()"
                [endDate]="filterState.getEndDate()"
                (onChangeDates)="datesChanged($event)"
                type="range-month"
                periodType="month"
            ></cmx-filter-datepicker>
        </div>
        <button (click)="changeDateCustom()" class="applyImpStatus">
            <i class="fa fa-check"></i>
        </button>
    </div>
    <div *ngIf="!asyncRegistrationLI && registrationLIData === undefined" class="reloadContent">
        <i (click)="loadRegistration()" class="material-icons">refresh</i>
    </div>
<div id="graficoLI" style="height: 300px;" ></div>


