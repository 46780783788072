import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Observable } from 'rxjs';

import { UserPermissionBlockerService } from '#services/_user/userPermissionBlocker.service';
import { UtilsService } from '#services/_utils/utils.service';
import { ChartsServices } from './../charts.services';
import { environment } from '#environment';

declare let AmCharts: any;

@Component({
    selector: 'cr-processes-chart',
    templateUrl: './cr-processes.component.html',
    styleUrls: [ '../charts.scss' ],
    encapsulation: ViewEncapsulation.None,
})
export class CrProcessesComponent implements AfterViewInit {

    public CrProcessesChart: any;
    public asyncCrProcesses: boolean = false;
    // Default values for dataset
    public CrSwitch = true;
    public changed = false;
    public CrImpQtySwitch = true;
    public CrPeriodSwitch = true;
    // Default name component
    public CrName = 'Centro de Resultado x';
    public CrImpQtyName = 'Notas Fiscais';

    public chartData;

    public startDate = moment().format('MM/YYYY');
    public endDate = moment().format('MM/YYYY');

    constructor(
        public chartService: ChartsServices,
        public router: Router,
        private http: HttpClient,
        private el: ElementRef,
        private permissionBlocker: UserPermissionBlockerService,
        private utilsService: UtilsService,
    ) { }

    public ngAfterViewInit() {
        this.loadCrProcesses();
    }

    public crChangeDateParam(e, period) {
        this.startDate = moment().format('MM/YYYY');
        this.endDate = moment().format('MM/YYYY');
        this.asyncCrProcesses = false;

        this.startDate = period === 99
            ? moment().format('01/YYYY')
            : moment().add(-period, 'M').format('MM/YYYY');

        if (period === 98) {
            this.startDate = moment().add(-1, 'M').format('MM/YYYY');
            this.endDate = moment().add(-1, 'M').format('MM/YYYY');
        }

        $('.checkmark.cr.custom').addClass('hidden');
        $('span.checkmark.cr').removeClass('checked');
        $(e.target).addClass('checked');

        this.loadCrProcesses();
    }

    public changeDateCustom() {
        $('#periodFrom-cr , #periodTo-cr').datepicker({
            format: 'mm/yyyy',
            viewMode: 'months',
            minViewMode: 'months',
        });
        $('#periodFrom-cr , #periodTo-cr').datepicker().on('change', () => {
            this.changed = true;
            const from = $('#periodFrom-cr').val().toString();
            const splitFrom = from.split('/');
            const invertedFrom = splitFrom[1] + '-' + splitFrom[0];
            const to = $('#periodTo-cr').val().toString();
            const splitTo = to.split('/');
            const invertedTo = splitTo[1] + '-' + splitTo[0];
            if (invertedFrom > invertedTo) {
                $('.datepicker-invalid.cr').removeClass('hidden');
            } else {
                this.asyncCrProcesses = false;
                $('span.checkmark.cr').removeClass('checked');
                $('.datepicker-invalid.cr').addClass('hidden');
                $('.checkmark.cr.custom').removeClass('hidden');
                this.startDate = $('#periodFrom-cr').val().toString();
                this.endDate = $('#periodTo-cr').val().toString();
            }
        });
        if (this.changed) {
            this.loadCrProcesses();
        }
        this.changed = false;
    }

    // Get data for Invoice Amount and Imp Quantity
    public getCr_CrImpQty(start, end): Observable<any> {
        return this.http.get(
            `${
            environment.endpoints.supplyChainService
            }/stats/invoiceAmountByCR,invoiceQtyByCR?startMonth=${start}&endMonth=${end}`,
        );
    }

    public loadCrProcesses() {
        if (this.permissionBlocker.permissionValidator(this.el.nativeElement.tagName)) {
            this.asyncCrProcesses = false;
            $('#cr-processes').attr('style', 'opacity:0; margin-left: 50px; width: 90%; float: right;');
            const newStart = moment(this.startDate, 'MM/YYYY').format('YYYY-MM');
            const newEnd = moment(this.endDate, 'MM/YYYY').format('YYYY-MM');
            this.getCr_CrImpQty(newStart, newEnd).subscribe((data) => {
                this.chartData = this.generateChartData(data);
                this.asyncCrProcesses = true;
                const chart = AmCharts.makeChart('cr-processes', {
                    type: 'serial',
                    startDuration: 0,
                    listeners: [{
                        event: 'init',
                        method: (event) => {
                            const showChart = () => {
                                this.asyncCrProcesses = true;
                                $('a[title*=JavaScript]').attr('style', 'display: none; opacity: 0;');
                                showChart();
                            };
                        },
                    }, {
                        event: 'drawn',
                        method: () => {
                            $('a[title*=JavaScript]').attr('style', 'display: none; opacity: 0;');
                        },
                    }, {
                        event: 'clickGraphItem',
                        method: (event) => {
                            const profitCenter = event.item.category;
                            const startDate  = moment(this.startDate, 'MM/YYYY').format('YYYY-MM-01');
                            const current = moment().format('YYYY-MM');
                            const endDate = moment(newEnd).add('1', 'M').add('-1', 'days').format('YYYY-MM-DD');
                            this.router.navigate([`./supply-chain/notas-fiscais/${profitCenter
                            }/0/${startDate}/${endDate}`]);
                        },
                    }],
                    fontSize: 12,
                    language: 'pt',
                    fontFamily: 'Poppins; sans serif',
                    dataDateFormat: 'YYYY-MM',
                    decimalSeparator: '.',
                    thousandsSeparator: '',
                    dataProvider: this.chartData,
                    addClassNames: !0,
                    color: '#6c7b88',
                    marginLeft: 0,
                    categoryField: 'cr',
                    categoryAxis: {
                        forceShowField: 'alwaysShow',
                    },
                    valueAxes: [
                        {
                            id: 'a1',
                            title: 'Milhões de R$',
                            axisAlpha: 1,
                            baseValue: 0,
                            labelFunction: (value) => value / 1000000,
                        },
                        {
                            id: 'a3',
                            title: 'Notas Fiscais',
                            position: 'right',
                            axisAlpha: 0,
                            baseValue: 0,
                            gridAlpha: 0,
                            labelFunction: (value) => value.toLocaleString('pt-BR'),
                        },
                    ],
                    graphs: [
                        {
                            id: 'notasfiscais',
                            valueField: 'imps',
                            classNameField: 'bulletClass',
                            title: ' ',
                            type: 'line',
                            valueAxis: 'a3',
                            lineColor: '#786c56',
                            lineThickness: 1,
                            legendValueText: '[[value]]',
                            legendPeriodValueText: '[[value.sum]]',
                            bullet: 'round',
                            bulletBorderColor: '#02617a',
                            bulletBorderAlpha: 1,
                            bulletBorderThickness: 2,
                            bulletColor: '#0091D6',
                            labelPosition: 'right',
                            balloonFunction: this.formatBalloon,
                            showBalloon: !0,
                            animationPlayed: !0,
                        },
                        {
                            id: 'receita',
                            valueField: 'income',
                            title: 'Receita',
                            type: 'column',
                            fillAlphas: 0.7,
                            valueAxis: 'a1',
                            balloonFunction: this.formatBalloon,
                            legendValueText: '[[value]]',
                            legendPeriodValueText: '[[value.sum]]',
                            lineColor: '#082442',
                            alphaField: 'alpha',
                            dashLengthField: 'dashLengthColumn',
                        },
                    ],
                    chartCursor: {
                        zoomable: !1,
                        categoryBalloonDateFormat: 'MMM',
                        cursorAlpha: 0,
                        categoryBalloonColor: '#808083',
                        categoryBalloonAlpha: 1,
                        valueBalloonsEnabled: !1,
                    },
                    legend: {
                        equalWidths: false,
                        position: 'bottom',
                        valueAlign: 'left',
                        valueWidth: 100,
                        valueFunction: this.formatLegendLabel.bind(this),
                        bulletType: 'round',
                        useGraphSettings: !0,
                        color: '#6c7b88',
                    },
                    export: {
                        enabled: true,
                        pageOrigin: false,
                        menu: [
                            {
                                format: 'PDF',
                                label: '<i class="flaticon-download"></i>',
                                title: 'Gerar PDF',
                                fileName: `CR x Notas Fiscais (${this.startDate} • ${this.endDate})`,
                                content: [
                                    `CR x Notas Fiscais (${this.startDate} • ${this.endDate})`
                                    , {
                                        image: 'reference',
                                        fit: [ 523.28, 769.89 ],
                                    }],
                            },
                        ],
                    },
                });
                $('#cr-processes').attr('style', 'opacity:1; margin-left: 50px; width: 90%;  float: right;');
                chart.addListener('drawn', () => {
                    $('a[title*=JavaScript]').attr(
                        'style',
                        'display: none; opacity: 0;',
                    );
                });
                $('a[title*=JavaScript]').attr(
                    'style',
                    'display: none; opacity: 0;',
            );
            }, (error) => {
                this.chartData = undefined;
                this.asyncCrProcesses = true;
                this.utilsService.errorHandler(error, 'CR x NF');
            });
        }
    }

    private generateChartData(data: any) {
        const chartData = [];
        const invoiceQtyByCR = data.invoiceQtyByCR.data;
        const newAmountCr = data.invoiceAmountByCR.data;
        for (let i = 0; i < newAmountCr.length; i++) {
            const imps = invoiceQtyByCR[i].value;
            const income = newAmountCr[i].value < 0 ? 0 : newAmountCr[i].value;
            const cr = invoiceQtyByCR[i].profitCenterCode;
            const name = invoiceQtyByCR[i].profitCenterName;
            chartData.push({
                imps,
                cr,
                name,
                income,
                alwaysShow: true,
            });
        }
        return chartData;
    }

    private formatBalloon(item, graph) {
        const name = item.dataContext.name;
        const imps = item.dataContext.imps.toLocaleString('pt-BR');
        const result = (item.dataContext.income / 1000000).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return `
            ${name}
            <hr class='hrChart'>
            <span style='font-size:12px;'>
                R$ <span style='font-size:20px;'>${result}</span> mi
            </span>
            <br>
            <hr class='hrChart'>
            <span style='font-size:14px;'>${imps}</span> NFs`;
    }

    private formatLegendLabel(graphDataItem: any, valueText: string) {
        if (this.isInvoiceGraph(graphDataItem)) {
            const quantity = parseInt(valueText, 10).toLocaleString('pt-BR');

            return graphDataItem.id === 'notasfiscais'
                ? `Total NFs: ${quantity}`
                : `${quantity} NFs`;

        } else {
            const amount = (parseFloat(valueText) / 1000000).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });

            return graphDataItem.id === 'receita'
                ? `Total: R$ ${amount} mi`
                : `R$ ${amount} mi`;
        }
    }

    private isInvoiceGraph(graphDataItem: any) {
        return graphDataItem.id === 'notasfiscais'
            || (graphDataItem.graph && graphDataItem.graph.id === 'notasfiscais');
    }

}
