import { Component } from '@angular/core';
import { PoStatusService } from '../../po-status.service';

@Component({
    selector: 'po-status-data',
    templateUrl: './po-status-data.component.html',
    styleUrls: ['./po-status-data.component.scss'],
})
export class PoStatusDataComponent {

    constructor(public poStatusService: PoStatusService) { }

}
