import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '#environment';
import { Observable } from 'rxjs';

@Injectable()
export class AdmDomainTraderDirectorCrud {

    constructor( private http: HttpClient ) {}

    // Create trader
    public createTrader(selectedDomain, selectedDropdown): Observable<HttpResponse<any>> {
        return this.http.post(
            `${environment.endpoints.domainService}/traders`,
            this.prepareData('trader', selectedDomain, selectedDropdown),
            { observe: 'response', responseType: 'json' },
        );
    }
    // Edit trader
    public editTrader(selectedDomain): Observable<HttpResponse<any>> {
        return this.http.put(`${environment.endpoints.domainService}/traders/${selectedDomain.code}`
        , this.prepareData('traderEdit', selectedDomain),
        { observe: 'response', responseType: 'json' });
    }
    // Delete trader
    public deleteTrader(selectedDomain): Observable<HttpResponse<any>> {
        return this.http.delete(
            `${environment.endpoints.domainService}/traders/${selectedDomain.code}`,
            { observe: 'response', responseType: 'json' },
        );
    }

    // Create director
    public createDirector(selectedDomain): Observable<HttpResponse<any>> {
        return this.http.post(
            `${environment.endpoints.domainService}/directors`,
            this.prepareData('director', selectedDomain),
            { observe: 'response', responseType: 'json' },
        );
    }
    // Edit director
    public editDirector(selectedDomain): Observable<HttpResponse<any>> {
        return this.http.put(`${environment.endpoints.domainService}/directors/${selectedDomain.code}`
        , this.prepareData('director', selectedDomain),
        { observe: 'response', responseType: 'json' });
    }
    // Delete director
    public deleteDirector(selectedDomain): Observable<HttpResponse<any>> {
        return this.http.delete(
            `${environment.endpoints.domainService}/directors/${selectedDomain.code}`,
            { observe: 'response', responseType: 'json' },
        );
    }

    private prepareData(domain, selectedDomain, selectedDropdown?) {
        let data;
        if (domain === 'trader') {
            data = {
                name: selectedDomain.name,
                director: {
                    code: selectedDropdown.id,
                    name: selectedDropdown.itemName,
                },
            };
        } else if (domain === 'traderEdit') {
            data = {
                name: selectedDomain.name,
            };
        } else {
            data = {
                name: selectedDomain.name,
                code: selectedDomain.code,
            };
        }
        return data;
    }

}
