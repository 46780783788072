import { Injectable } from '@angular/core';
import moment from 'moment';
import * as _ from 'lodash';

@Injectable()
export class FollowUpReportFormatter {

    // Alertas
    public formatAlert(row, cell, value, columnDef, dataContext) {
        if (!_.isNil(value) && value.length > 0) {
            if (value.length > 0) {
                return `<div class='violationsBall multipleViolations'>${
                    value.length}</div>`;
            }
        }
        return '<div class="violationsBall grey"></div>';
    }

    // Datas
    public formatDate(row, cell, value, columnDef, dataContext) {
        if (value === null || value === undefined || value === '') {
            return '';
        }
        return `<span class='dateValue'>${moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
    }

    public formatMapInfoDate(row, cell, value, columnDef, dataContext) {
        if (value === null || value === undefined || value === '') {
            return '';
        } else if (value == 'NÃO SE APLICA') {
            return value;
        }

        return `<span class='dateValue'>${moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
    }

    public formatUnloadingInfoDate(row, cell, value, columnDef, dataContext) {
        if (value === null || value === undefined || value === '') {
            return '';
        } else if (value == 'NÃO SE APLICA') {
            return value;
        }

        return `<span class='dateValue'>${moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
    }

    public formatDtaInfoDate(row, cell, value, columnDef, dataContext) {
        if (value === null || value === undefined || value === '') {
            return '';
        } else if (value == 'NÃO SE APLICA') {
            return value;
        }
        return `<span class='dateValue'>${moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
    }

    // Números
    public formatNumber(row, cell, value, columnDef, dataContext) {
        if (value === null || value === undefined || value === '') {
            return '';
        }

        if (value && Array.isArray(value)) {
            if (value.length === 1) {
                value = value[0][columnDef.field];
            }
        }

        return `<span class='number'>${value}</span>`;
    }

    public formatDtaInfoNumber(row, cell, value, columnDef, dataContext) {
        if (value === null || value === undefined || value === '') {
            return '';
        } else if (value == 'NÃO SE APLICA') {
            return value;
        }
        return `<span class='number'>${value}</span>`;
    }

    // Números centralizado
    public formatNumberCenter(row, cell, value, columnDef, dataContext) {
        if (value === null || value === undefined || value === '') {
            return '';
        }
        return `<span class='number'>${value}</span>`;
    }

    // Valores Monetários
    public formatValue(row, cell, value, columnDef, dataContext) {
        const format = { minimumFractionDigits: 2 };
        let newValue;

        if (typeof value === 'string') {
            if (value.includes('Várias')) {
                return `<span class="multipleInvoices">${value}</span>`;
            }
        }
        if ( value === null || value === undefined || value.amount === null ) {
            return '';
        } else if (typeof value === 'object') {
            newValue = `${value.currency} ${value.totalAmount.toLocaleString('pt-BR', format)}`;
        } else {
            newValue = parseFloat(value).toLocaleString('pt-BR', format);
        }
        if (columnDef.field === 'entryInvoiceAmount') {
            return `<span class="formatValue">${dataContext.entryInvoiceCurrency} ${newValue}</span>`;
        } else {
            return `<span class="formatValue">${dataContext.currency} ${newValue}</span>`;
        }

    }

    // Canais
    public formatChannel(row, cell, value, columnDef, dataContext) {
        switch (value) {
            case 'VERDE':
            return `<div class='ball ${value}'></div>`;
            case 'AMARELO':
            return `<div class='ball ${value}'></div>`;
            case 'VERMELHO':
            return `<div class='ball ${value}'></div>`;
            case 'CINZA':
            return `<div class='ball ${value}'></div>`;
            case 'SEM PARAMETRIZAÇÃO':
            case 'SEM PARAMETRIZAC?O':
            return '<span class = "SemParametro">SEM PARAMETRIZAÇÃO</span>';
            case 'DESCONHECIDO':
            return '<span>-</span>';
        }
    }

    public formatDtaInfoChannel(row, cell, value, columnDef, dataContext) {
        switch (value) {
            case 'NÃO SE APLICA':
            return 'NÃO SE APLICA'
            case 'VERDE':
            return `<div class='ball ${value}'></div>`;
            case 'AMARELO':
            return `<div class='ball ${value}'></div>`;
            case 'VERMELHO':
            return `<div class='ball ${value}'></div>`;
            case 'CINZA':
            return `<div class='ball ${value}'></div>`;
            case 'SEM PARAMETRIZAÇÃO':
            case 'SEM PARAMETRIZAC?O':
            return '<span class = "SemParametro">SEM PARAMETRIZAÇÃO</span>';
            case 'DESCONHECIDO':
            return '<span>-</span>';
        }
    }

    // Outros

    // Data de Câmbio
    public formatExchangeDate(row, cell, value, columnDef, dataContext) {
        const exchanges = [];
        value?.forEach((exchange) => {
            if (!exchanges.includes(exchange.exchangeDate)) {
                exchanges.push(exchange.exchangeDate);
            }
        });
        if (exchanges.length === 1) {
            if (exchanges[0] === null || exchanges[0] === undefined || exchanges[0] === '') {
                return '';
            }
            return `<span class='dateValue'>${moment(exchanges[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (exchanges.length > 1) {
            return `<span class="multipleExchangeDates">Várias (${exchanges.length})</span>`;
        } else {
            return '';
        }
    }

        // Prazo Câmbio
        public formatExchangeRemainingDays(row, cell, value, columnDef, dataContext) {
            const exchanges = [];
            value?.forEach((exchange) => {
                if (!exchanges.includes(exchange.exchangeRemainingDays)) {
                    exchanges.push(exchange.exchangeRemainingDays);
                }
            });
            if (exchanges.length === 1) {
                if (exchanges[0] === null || exchanges[0] === undefined || exchanges[0] === '') {
                    return '';
                }
                return `<span class='dateValue'>${exchanges[0]}</span>`;
            } else if (exchanges.length > 1) {
                return `<span class="multipleExchangeDates">Várias (${exchanges.length})</span>`;
            } else {
                return '';
            }
        }

    // AWB BL
    public formatAWB(row, cell, value, columnDef, dataContext) {
        const types = [];
        value.forEach((order) => {
            if (!types.includes(order.trackingNumber)) {
                types.push(order.trackingNumber);
            }
        });
        if (types.length === 1) {
            return types[0];
        } else if (types.length > 1) {
            return `<span class="multipleAWB">Vários (${types.length})</span>`;
        } else {
            return '';
        }
    }

    // Observações
    public formatObservations(row, cell, value, columnDef, dataContext) {
        let newValue;
        if (value !== null && value !== undefined) {
            newValue = value.replace(/!%!%/g, ' ');
            return `<span title='${newValue}'>${newValue}</span>`;
        }
    }

    // Histórico
    public formatHistoric(row, cell, value, columnDef, dataContext) {
        if (!value || value.length === 0) {
            return '';
        } else if (value.length === 1) {
            return `<span title='${value[0].formatedNote}'>${value[0].formatedNote}</span>`;
        } else {
            return `<span class="multipleObservations">Várias (${value.length})</span>`;
        }
    }

    // Forecast
    public formatValueForecast(row, cell, value, columnDef, dataContext) {
        const format = { minimumFractionDigits: 2 };
        if (value !== undefined && value !== '' && value !== null && value > 0) {
            return `<span class="formatValue"> R$
            ${value.toLocaleString('pt-BR', format)}</span>`;
        } else  if (value < 0) {
            return 'N/A';
        } else {
            return '';
        }
    }

    // Forecast
    public formatValueForecastTaxImportation(row, cell, value, columnDef, dataContext) {
        const format = { minimumFractionDigits: 2 };
        if (value !== undefined && value !== '' && value !== null && value > 0) {
            return `<span class="formatValue"> R$
            ${value.toLocaleString('pt-BR', format)}</span>`;
        } else  if (value < 0) {
            return 'N/A';
        } else {
            return '';
        }
    }

    // Taxa DI - Dolar
    public formatDiTaxDolar(row, cell, value, columnDef, dataContext) {
        const format = { minimumFractionDigits: 4, maximumFractionDigits: 4 };
        if (value === null || value === undefined) {
            return '';
        } else {
            return `<span class="formatValue">USD ${value.toLocaleString('pt-BR', format)}</span>`;
        }
    }

    // Taxa DI - Euro
    public formatDiTaxEuro(row, cell, value, columnDef, dataContext) {
        const format = { minimumFractionDigits: 4, maximumFractionDigits: 4 };
        if (value === null || value === undefined) {
            return '';
        } else {
            return `<span class="formatValue">EUR ${value.toLocaleString('pt-BR', format)}</span>`;
        }
    }

    // Fabricante
    public formatManufacturer(row, cell, value, columnDef, dataContext) {
        if (value) {
            const manufacturers = [];

            value.forEach((order) => {
                if (!manufacturers.includes(order.manufacturerName)) {
                    manufacturers.push(order.manufacturerName);
                }
            });

            if (manufacturers.length === 1) {
                return manufacturers[0];
            } else if (manufacturers.length > 1) {
                return `<span class="multipleManufacturers">Várias (${manufacturers.length})</span>`;
            }
        }

        return '';

    }


}
