
export function setupAnalytics(googleTag) {
    const script = document.createElement('script');
    script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${googleTag}', {send_page_view: false, 'debug_mode': false });
    `;
    const gtagScript = document.createElement('script');
    gtagScript.type = "text/javascript";
    gtagScript.async = true;
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleTag}`;
    document.head.appendChild(gtagScript);
    document.head.appendChild(script);

}