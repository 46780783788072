import { Component, ElementRef, HostListener, Input, Renderer2, SimpleChanges, ViewChild } from '@angular/core';

@Component({
    selector: 'cmx-slick-popover',
    templateUrl: './cmx-slick-popover.component.html',
    styleUrls: ['./cmx-slick-popover.component.scss'],
})
export class SlickPopoverComponent {
    @Input() headerTitle: string = null;
    @Input() dataRows: any[] = [];
    @Input() top: number = 0;
    @Input() left: number = 0;
    @Input() closeWithClick: boolean = false;
    @Input() fadeInTimmer: number = 100;
    @Input() fadeOutTimmer: number = 1000;
    @Input() forceFadeOut: boolean = false;
    @Input() maxWidth: string = 'auto';
    @Input() inverseHeightAdition: number = 0;
    @ViewChild('slickPopover') cmxSlickPopover: ElementRef;
    topDistance = 0;
    leftDistance = 0;
    hidden = true;
    clearTimeoutHidden;
    inversePopover: boolean = false;

    constructor(private _renderer: Renderer2) {

    }

    @HostListener('mouseleave', ['$event']) onLeave( e: MouseEvent ) {
        this.onTimeHidden();
    }

    @HostListener('click', ['$event']) onClickLeave( e: MouseEvent ) {
        if (this.closeWithClick){
            this.onHidden();
        }
    }

    @HostListener('mouseenter', ['$event']) onEnter( e: MouseEvent ) {
        clearTimeout(this.clearTimeoutHidden);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && (changes.top || changes.left || changes.dataRows)) {
            if (changes?.top?.currentValue || changes?.left?.currentValue || changes?.dataRows?.currentValue) {
                setTimeout(() => {
                    this.leftDistance = this.left - 2;
                    this.topDistance = this.top - 2;
                    if(this.cmxSlickPopover){
                        this.onShow();
                        this.checkPositionPopover();
                    }
                }, this.fadeInTimmer);
            }
        }
        if (changes && (changes.forceFadeOut)) {
            if (changes.forceFadeOut.currentValue === true) {
                this.dataRows = [];
                this.topDistance = window.innerHeight; this.leftDistance = window.innerWidth;
                this.onHidden();
                this.forceFadeOut = false;
            }
        }
    }

    onHidden(){
        this._renderer.removeClass(this.cmxSlickPopover.nativeElement, 'visible-popover');
        this._renderer.addClass(this.cmxSlickPopover.nativeElement, 'hidden-popover');
    }

    onShow(){
        this._renderer.removeClass(this.cmxSlickPopover.nativeElement, 'hidden-popover');
        this._renderer.addClass(this.cmxSlickPopover.nativeElement, 'visible-popover');
    }

    onTimeHidden() {
        this.clearTimeoutHidden = setTimeout(() => {
            this.onHidden();
        }, this.fadeOutTimmer);
    }

    checkPositionPopover() {
        const elHeightTotal = (this.top + 45) + this.cmxSlickPopover.nativeElement.offsetHeight;
        const winHeightTotal = window.innerHeight;
        if (elHeightTotal > winHeightTotal) {
            this.inversePopover = true;
            this.topDistance = winHeightTotal - this.top;
        } else {
            this.inversePopover = false;
        }
    }

}
