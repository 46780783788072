import { Injectable } from '@angular/core';

@Injectable()
export class FollowUpReportSailingFormatter {

    // País Procedência
    public formatCountry(row, cell, value, columnDef, dataContext) {
        if (value === null || value === undefined || value === '') {
            return '';
        }
        if (dataContext.flagUrl !== 'https://flagcdn.com/h20/.png' &&
            dataContext.flagUrl !== 'https://flagcdn.com/h20/undefined.png') {
            return `<span'><img style="width:20px;margin-right:5px" src="${dataContext.flagUrl}">${value}</span>`;
        } else {
            return value;
        }
    }

}
