import { AppModule } from 'app/app.module';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IDiValidationFilter } from './interfaces/di-validation-filter.interface';

export class DiValidationFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialDiValidationFilter: IDiValidationFilter = <IDiValidationFilter>{};
    private _diValidationFilter: IDiValidationFilter = <IDiValidationFilter>{
        search: '',
    };


    constructor(filter?: DiValidationFilter) {
        this._diValidationFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'diValidationFilter', this._diValidationFilter, filter);
        this._initialDiValidationFilter = _.cloneDeep(this._diValidationFilter);
    }

    getSearch(): string { return this._diValidationFilter.search }

    setSearch(search): void { this._diValidationFilter.search = search }


    updatePreferences() {
        this._initialDiValidationFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialDiValidationFilter, this._diValidationFilter, 'diValidationFilter', this._userCacheService.getUserPreferences().id);
    }

}
