<div class="cmx-switch">
    <div *ngFor="let option of options, let i = index"
    class="option {{ i === 0 ? 'left' : i === options.length - 1 ? 'right' : '' }}
    {{ option.name === selected ? 'selected' : '' }}
    {{ option.disabled ? 'disabled' : '' }}"
    (click)="selectOption(option)">
        <span *ngIf="option.disabledIcon === false ? false : option.disabled" [title]="option.disabledText || ''" class="material-icons disabled-icon">report_problem</span>
        {{ option.name }}
    </div>
</div>
