import { Injectable } from '@angular/core';

@Injectable()
export class ComponentDescriptions {
    public descriptions = [
        {
            component: 'InvoicesComponent',
            description: `Informa a quantidade de processos faturados, numero de notas fiscais faturadas
             e quantidade faturada no mes selecionado. É apresentado também a cotação do Dólar em relação ao Real.`,
        },
        {
            component: 'ContainerTrackingWidgetComponent',
            description: `Informa a posição atual dos aviões ou navios mercantes através do código MMSI.
            No modo de navegação por navios mercantes, é possivel ter acesso às previsões de atracação, data de saída dos portos, origem e destino.
            No modo de navegação por aviões, é possível obter acesso as previsões de origem e destino, eventos que ocorrem no percurso do avição.`,
        },
        {
            component: 'ExchangeComponent',
            description: 'Informa a cotação do Dólar em relação ao Real dia a dia dos últimos 2 anos.',
        },
        {
            component: 'ChannelComponent',
            description: `Informa a quantidade e porcentagem de importações em seus determinados canais de desembaraço.
            Ao clicar em um canal, é aberto uma tabela contendo todas as importações do canal selecionado.`,
        },
        {
            component: 'CrProcessesTableComponent',
            description: `Informa a quantia faturada para cada centro de resultado,
             sendo possível segregar os valores por clientes ou empresas.
              As informações podem ser vistas por meses ou dias.`,
        },
        {
            component: 'InvoicesProcessesComponent',
            description: `Informa a quantidade de faturamento e de notas fiscais para cada mês dos últimos 12 meses.
            Ao clicar em um mês, é redirecionado para a página contendo todas as notas fiscais do mês selecionado.`,
        },
        {
            component: 'CrProcessesComponent',
            description: `Informa a quantidade de faturamento para cada centro de resultado,
            sendo possível visualizá-lo em diferentes períodos. Ao clicar em um CR, é redirecionado para a
             página contendo todas as notas fiscais do CR e período selecionado.`,
        },
        {
            component: 'ImpStatusComponent',
            description: `Informa sobre as importações presentes no período e status desejado.
             Contém as seguintes informações: Referência, Descrição, Nº da Invoice, Valor da Invoice,
              Fornecedor, Status e Valor de Nota Fiscal.`,
        },
        {
            component: 'SkuStatusComponent',
            description: `Informa sobre os SKUs presentes no período e status desejado.
            Contém as seguintes informações: IMP, Modal, Descrição, NCM, Nº da Invoice,
            Quantidade, Valor Unitário e Valor Total.`,
        },
        {
            component: 'ChassiStatusComponent',
            description: `Informa sobre os Chassis presentes no período e status desejado.
            Contém as seguintes informações: IMP, Nº de Embarque, B/L, Modelo, Cor, Status
            Valor da Invoice, Data de Embarque, ETA, Registro de DI, Canal, Presença de Carga e Situação de PDI.`,
        },
        {
            component: 'LeadTimeComponent',
            description: `Informa o esperado e o realizado em dias da etapa (KPI) selecionada.
             É mostrado os últimos 6 meses para cada KPI.`,
        },
        {
            component: 'LiLeadTimeComponent',
            description: `Informa a quantidade de LIs deferidas nos últimos 6 meses,
             assim como o tempo médio de deferimento para cada período`,
        },
        {
            component: 'BoardingStatusComponent',
            description: `Informa os embarques que estão aguardando embarque, em trânsito e com operação finalizada.
             Contém as seguintes informações: Nº do Embarque, Navio, Viagem, Veículos, Status, ETD e ETA.`,
        },
        {
            component: 'LogisticCostsComponent',
            description: `Informa a quantidade total ou média de custos logisticos dos últimos meses.
             É possível desconsiderar a Armazenagem EADI.`,
        },
        {
            component: 'SlaStatusComponent',
            description: `Informa sobre a quantidade de dias gasto em cada etapa da importação (KPI).
             É possível filtrar os resultados que estão No Prazo, Em Andamento, A Expirar e Expirado.`,
        },
        {
            component: 'SlaConsolidationComponent',
            description: `Informa resumidamente o andamento de todas as etapas dos SLA's acordados com o cliente,
             sendo elas : Antes do prazo , No Prazo , Expirado.`,
        },
        {
            component: 'StatusConsolidationComponent',
            description: `Informa a quantidade de processo por status , ao acionar qualquer status o sistema
             automaticamente direciona para o detalhes permitindo buscar o processo específico.`,
        },
        {
            component: 'CostDistributionComponent',
            description: 'Informa a porcentagem de custos para cada categoria de custo selecionada.',
        },
        {
            component: 'NewTrafficMapComponent',
            description: 'Informa a quantidade e a localização em tempo real dos navios, assim como previsões de chegada, linha do tempo e informações relevantes'
        }
    ];
}
