import { Injectable } from '@angular/core';

export const byImp = {
    "metadata": {
        "columns": [
            {
                "field": "description",
                "header": "Conta Contábil",
                "type": "text"
            },
            {
                "field": "column001",
                "header": "14452-00",
                "type": "number"
            },
            {
                "field": "column002",
                "header": "21583-00",
                "type": "number"
            },
            {
                "field": "column003",
                "header": "23316-00",
                "type": "number"
            },
            {
                "field": "column004",
                "header": "26167-00",
                "type": "number"
            },
            {
                "field": "column005",
                "header": "27341-00",
                "type": "number"
            },
            {
                "field": "column006",
                "header": "30322-00",
                "type": "number"
            },
            {
                "field": "column007",
                "header": "34148-00",
                "type": "number"
            },
            {
                "field": "column008",
                "header": "53313-00",
                "type": "number"
            },
            {
                "field": "column009",
                "header": "80972-00",
                "type": "number"
            },
            {
                "field": "column010",
                "header": "8341-00",
                "type": "number"
            },
            {
                "field": "column011",
                "header": "95957-00",
                "type": "number"
            },
            {
                "field": "column012",
                "header": "20493-00",
                "type": "number"
            }
        ]
    },
};

export const byTrader = {
    "metadata": {
        "columns": [
            {
                "field": "description",
                "header": "Conta Contábil",
                "type": "text"
            },
            {
                "field": "column001",
                "header": "14_035 - LUCIANO HERÉDIA",
                "type": "number"
            },
            {
                "field": "column002",
                "header": "20_029 - CAROLINA VELLOSO ROOS CARDOSO DE ALMEIDA",
                "type": "number"
            },
            {
                "field": "column003",
                "header": "22_072 - EUCLIDES JOSE RIGON",
                "type": "number"
            },
            {
                "field": "column004",
                "header": "23_043 - VICTOR LUIS MALUF AMARILLA",
                "type": "number"
            },
            {
                "field": "column005",
                "header": "23_059 - DENNIS DOS SANTOS",
                "type": "number"
            },
            {
                "field": "column006",
                "header": "49_212 - PIO GUERRA NETO",
                "type": "number"
            },
            {
                "field": "column007",
                "header": "53_006 - SAMUEL VENTUROLI",
                "type": "number"
            },
            {
                "field": "column008",
                "header": "55_017 - BRUNO DE CASTRO RIBAS PETRILLO",
                "type": "number"
            },
            {
                "field": "column009",
                "header": "70_286 - CAROLINA PITTAVINO",
                "type": "number"
            },
            {
                "field": "column010",
                "header": "999 - INDEFINIDO",
                "type": "number"
            },
            {
                "field": "column011",
                "header": "AD0089 - HAROLDO MORAES FILHO",
                "type": "number"
            },
            {
                "field": "column012",
                "header": "AD0149 - RODRIGO PIMENTEL TEIXEIRA",
                "type": "number"
            },
            {
                "field": "column001",
                "header": "BD0058 - FERNANDO CAJADO COSTA",
                "type": "number"
            },
            {
                "field": "column002",
                "header": "FUN003 - SERGIO VLADIMIRSCHI JUNIOR",
                "type": "number"
            },
            {
                "field": "column003",
                "header": "FUN015 - CARLOS ALBERTO MORAIS",
                "type": "number"
            },
            {
                "field": "column004",
                "header": "FUN028 - BRUNO CEZAR DE OLIVEIRA SANTOS",
                "type": "number"
            },
            {
                "field": "column005",
                "header": "FUN079 - CAIO DIAS CARRION",
                "type": "number"
            },
            {
                "field": "column006",
                "header": "SID177 - DANIEL SALDANHA DE AZEVEDO SANTOS",
                "type": "number"
            },
            {
                "field": "column007",
                "header": "TC0183 - MARCUS VINICIUS PRATINI",
                "type": "number"
            },
            {
                "field": "column008",
                "header": "TC0697 - LUIZ ANTONIO DE SOUZA Q.FERRAZ NETTO",
                "type": "number"
            },
            {
                "field": "column009",
                "header": "TC1617 - JULIANO SIMONETTI LEFEVRE",
                "type": "number"
            },
            {
                "field": "column010",
                "header": "TC3640 - HUGO S. WADA",
                "type": "number"
            }
        ]
    },
};

export const byClient = {
    "metadata": {
        "columns": [
            {
                "field": "description",
                "header": "Conta Contábil",
                "type": "text"
            },
            {
                "field": "column001",
                "header": "Cabelos",
                "type": "number"
            },
            {
                "field": "column002",
                "header": "Coloração",
                "type": "number"
            },
            {
                "field": "column003",
                "header": "Pele",
                "type": "number"
            },
            {
                "field": "column004",
                "header": "Protetor Solar",
                "type": "number"
            }
        ]
    },
};

export const byDirector = {
    "metadata": {
        "columns": [
            {
                "field": "description",
                "header": "Conta Contábil",
                "type": "text"
            },
            {
                "field": "column001",
                "header": "DIRCC001 - HAROLDO DE MORAES",
                "type": "number"
            },
            {
                "field": "column002",
                "header": "DIRCC002 - ANTONIO CLAUDIO",
                "type": "number"
            },
            {
                "field": "column003",
                "header": "DIRCC003 - MARCUS V PRATINI",
                "type": "number"
            },
            {
                "field": "column004",
                "header": "DIRCK001 - VICTOR MALUF",
                "type": "number"
            },
            {
                "field": "column005",
                "header": "DIRCK002 - DENNIS SANTOS",
                "type": "number"
            },
            {
                "field": "column006",
                "header": "DIRCI001 - DANIEL SALDANHA",
                "type": "number"
            },
            {
                "field": "column007",
                "header": "DIRCT001 - LUIZ FERNANDO BRAGA",
                "type": "number"
            },
            {
                "field": "column008",
                "header": "DIRCT002 - FERNANDO SMITH",
                "type": "number"
            }
        ]
    },
};

export const byCompany = {
    "metadata": {
        "columns": [
            {
                "field": "description",
                "header": "Conta Contábil",
                "type": "text"
            },
            {
                "field": "column001",
                "header": "6 - COMEXPORT TRADING",
                "type": "number"
            }
        ]
    },
};

export const byCr = {
    "metadata": {
        "columns": [
            {
                "field": "description",
                "header": "Conta Contábil",
                "type": "text"
            },
            {
                "field": "column001",
                "header": "20 - Novos Negócios",
                "type": "number"
            },
            {
                "field": "column002",
                "header": "22 - Petroquímico",
                "type": "number"
            },
            {
                "field": "column003",
                "header": "23 - Químico",
                "type": "number"
            },
            {
                "field": "column004",
                "header": "24 - Infraestrutura",
                "type": "number"
            },
            {
                "field": "column005",
                "header": "30 - Aço",
                "type": "number"
            },
            {
                "field": "column006",
                "header": "31 - Têxtil",
                "type": "number"
            },
            {
                "field": "column007",
                "header": "35 - Terceirização",
                "type": "number"
            },
            {
                "field": "column008",
                "header": "42 - Fertilizante",
                "type": "number"
            },
            {
                "field": "column009",
                "header": "998 - CC sem CR",
                "type": "number"
            },
            {
                "field": "column010",
                "header": "999 - Indefinido",
                "type": "number"
            }
        ]
    },
};

export const byMonth = {
    "metadata": {
        "columns": [
            {
                "field": "description",
                "header": "Conta Contábil",
                "type": "text"
            },
            {
                "field": "column001",
                "header": "01/2017",
                "type": "number"
            },
            {
                "field": "column002",
                "header": "02/2017",
                "type": "number"
            },
            {
                "field": "column003",
                "header": "03/2017",
                "type": "number"
            },
            {
                "field": "column004",
                "header": "04/2017",
                "type": "number"
            },
            {
                "field": "column005",
                "header": "05/2017",
                "type": "number"
            },
            {
                "field": "column006",
                "header": "06/2017",
                "type": "number"
            },
            {
                "field": "column007",
                "header": "07/2017",
                "type": "number"
            },
            {
                "field": "column008",
                "header": "08/2017",
                "type": "number"
            },
            {
                "field": "column009",
                "header": "09/2017",
                "type": "number"
            },
            {
                "field": "column010",
                "header": "10/2017",
                "type": "number"
            },
            {
                "field": "column011",
                "header": "11/2017",
                "type": "number"
            },
            {
                "field": "column012",
                "header": "12/2017",
                "type": "number"
            }
        ]
    },
    "results": [
        {
            "data": {
                "id": "1",
                "description": "ATIVO",
                "column001": "-106.731.071,09",
                "column002": "-317.909.929,73",
                "column003": "-228.272.691,17",
                "column004": "255,47",
                "column005": "-66.016.801,60",
                "column006": "-73.481.989,38",
                "column007": "-114.509.009,32",
                "column008": "-122.586.274,85",
                "column009": "-87.746.419,22",
                "column010": "-61.992.202,85",
                "column011": "-33.199.294,23",
                "column012": "292.019.897,31",
                "TOTAL": "1.000,00"
            },
            "children": [
                {
                    "data": {
                        "id": "11",
                        "description": "ATIVO CIRCULANTE",
                        "column001": "-106.731.071,09",
                        "column002": "-315.650.837,04",
                        "column003": "-228.268.691,17",
                        "column004": "255,47",
                        "column005": "-66.016.801,60",
                        "column006": "-73.478.871,66",
                        "column007": "-112.940.211,35",
                        "column008": "-121.995.730,87",
                        "column009": "-85.570.026,80",
                        "column010": "-59.703.710,38",
                        "column011": "-33.199.294,23",
                        "column012": "293.515.601,77",
                        "TOTAL": "1.000,00"
                    },
                    "children": [
                        {
                            "data": {
                                "id": "111",
                                "description": "DISPONIVEL",
                                "column001": "-144.342.340,89",
                                "column002": "-283.414.376,46",
                                "column003": "-133.272.905,90",
                                "column004": "0,00",
                                "column005": "-114.937.143,34",
                                "column006": "-161.794.426,92",
                                "column007": "-94.239.285,12",
                                "column008": "-167.698.185,48",
                                "column009": "-172.959.947,83",
                                "column010": "-115.707.300,20",
                                "column011": "47.326.235,80",
                                "column012": "286.541.363,80",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "1111",
                                        "description": "CAIXA",
                                        "column001": "53,46",
                                        "column002": "7.115,81",
                                        "column003": "5.048,25",
                                        "column004": "0,00",
                                        "column005": "4.531,03",
                                        "column006": "151,68",
                                        "column007": "5.451,95",
                                        "column008": "-8.293,83",
                                        "column009": "7.170,94",
                                        "column010": "4.093,86",
                                        "column011": "21.267,16",
                                        "column012": "-4.753,18",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "1111001",
                                                "description": "CAIXA - SAO PAULO",
                                                "column001": "-3.883,52",
                                                "column002": "-563,10",
                                                "column003": "5.048,25",
                                                "column004": "0,00",
                                                "column005": "-1.756,80",
                                                "column006": "-366,06",
                                                "column007": "1.202,74",
                                                "column008": "-7.001,71",
                                                "column009": "2.270,02",
                                                "column010": "212,55",
                                                "column011": "1.540,71",
                                                "column012": "-4.747,67",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1111002",
                                                "description": "CAIXA - ITAJAI",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1111003",
                                                "description": "CAIXA - VITORIA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "166,05",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1111005",
                                                "description": "CAIXA - VITORIA OPERAC",
                                                "column001": "3.936,98",
                                                "column002": "6.933,29",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "3.561,94",
                                                "column006": "1.517,74",
                                                "column007": "3.243,41",
                                                "column008": "-277,56",
                                                "column009": "3.754,89",
                                                "column010": "2.793,01",
                                                "column011": "13.202,57",
                                                "column012": "373,65",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1111006",
                                                "description": "CAIXA - ITAJAI OPERAC",
                                                "column001": "0,00",
                                                "column002": "745,62",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "2.725,89",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "-1.014,56",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "5.606,56",
                                                "column012": "-1.379,30",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1111007",
                                                "description": "CAIXA - RECIFE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "-1.000,00",
                                                "column007": "1.005,80",
                                                "column008": "0,00",
                                                "column009": "979,98",
                                                "column010": "1.088,30",
                                                "column011": "917,32",
                                                "column012": "1.000,14",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "1112",
                                        "description": "BANCOS CONTA MOVIMENTO",
                                        "column001": "-177.733.352,14",
                                        "column002": "-313.886.189,43",
                                        "column003": "-94.681.486,28",
                                        "column004": "0,00",
                                        "column005": "-120.688.100,32",
                                        "column006": "-208.694.583,84",
                                        "column007": "-142.497.906,34",
                                        "column008": "-143.784.959,57",
                                        "column009": "-195.633.502,47",
                                        "column010": "-144.078.054,30",
                                        "column011": "60.258.971,05",
                                        "column012": "61.070.808,20",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "1112001",
                                                "description": "BANCO DO BRASIL",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "-7.894.230,82",
                                                "column004": "0,00",
                                                "column005": "410,25",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "-3.049.707,56",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112002",
                                                "description": "BANCO BRADESCO - ANTIGO HSBC",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112003",
                                                "description": "BANCO RENDIMENTO S/A",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112004",
                                                "description": "BANCO BRADESCO S.A-SP",
                                                "column001": "-39.080.124,69",
                                                "column002": "-135.657.900,16",
                                                "column003": "-11.691.725,93",
                                                "column004": "0,00",
                                                "column005": "-47.117.589,70",
                                                "column006": "-91.690.586,06",
                                                "column007": "-58.123.107,11",
                                                "column008": "-28.065.982,33",
                                                "column009": "-39.805.366,54",
                                                "column010": "-46.726.351,73",
                                                "column011": "-34.349.651,90",
                                                "column012": "-15.994.661,44",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112013",
                                                "description": "BANCO SANTANDER",
                                                "column001": "-11.931.362,06",
                                                "column002": "-15.112.285,42",
                                                "column003": "-17.733.493,13",
                                                "column004": "0,00",
                                                "column005": "158.650,56",
                                                "column006": "-1.283.513,04",
                                                "column007": "-8.743.464,14",
                                                "column008": "-15.141.861,06",
                                                "column009": "-109.438.858,92",
                                                "column010": "-14.016.073,26",
                                                "column011": "-7.474.131,07",
                                                "column012": "-278.570,15",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112016",
                                                "description": "BANCO FIBRA",
                                                "column001": "-12.410.000,00",
                                                "column002": "304.143,43",
                                                "column003": "39.870,13",
                                                "column004": "0,00",
                                                "column005": "-1.220.000,00",
                                                "column006": "-20.750.000,00",
                                                "column007": "-3.038.333,02",
                                                "column008": "-18.876.054,85",
                                                "column009": "0,00",
                                                "column010": "172.492,83",
                                                "column011": "-15.886.618,15",
                                                "column012": "-3.513.451,69",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112017",
                                                "description": "BANCO ITAU S/A",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112023",
                                                "description": "BCO BANESTES",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112025",
                                                "description": "BANCO SAFRA S/A",
                                                "column001": "-23.223.831,87",
                                                "column002": "-50.067.940,32",
                                                "column003": "-24.343.608,78",
                                                "column004": "0,00",
                                                "column005": "-19.683.698,98",
                                                "column006": "-18.376.312,03",
                                                "column007": "-25.239.995,56",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "-1.523.747,98",
                                                "column011": "-36.141.526,90",
                                                "column012": "-65.461.072,31",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112027",
                                                "description": "BANCO SANTANDER BRA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112028",
                                                "description": "BANCO BRADESCO - HSBC",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112029",
                                                "description": "CITIBANK",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112030",
                                                "description": "BANCO BRADESCO - VITORIA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112031",
                                                "description": "BANCO ABN",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112032",
                                                "description": "BANCO ITAU SA",
                                                "column001": "-81.195.699,50",
                                                "column002": "-95.115.502,98",
                                                "column003": "-42.200.900,50",
                                                "column004": "0,00",
                                                "column005": "-60.930.905,67",
                                                "column006": "-70.736.054,01",
                                                "column007": "-19.065.309,36",
                                                "column008": "-70.107.419,43",
                                                "column009": "-49.996.055,15",
                                                "column010": "-79.262.193,97",
                                                "column011": "-65.221.775,47",
                                                "column012": "-30.575.756,83",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112038",
                                                "description": "BANCO ITAU / UNIBAN",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112039",
                                                "description": "BANCO DO BRASIL AG.",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112043",
                                                "description": "CITIBANK S.A.",
                                                "column001": "-13.770.000,00",
                                                "column002": "0,00",
                                                "column003": "10.502,59",
                                                "column004": "0,00",
                                                "column005": "-4.130.000,00",
                                                "column006": "-8.140.000,00",
                                                "column007": "-19.171.979,99",
                                                "column008": "-12.362.064,79",
                                                "column009": "-2.058.782,01",
                                                "column010": "0,00",
                                                "column011": "-6.264.755,00",
                                                "column012": "-760.298,20",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112044",
                                                "description": "BANCO INDUSTRIAL",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112045",
                                                "description": "ABC BRASIL - 00019 - 022018516",
                                                "column001": "-405.000,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-240.000,00",
                                                "column006": "-30.000,00",
                                                "column007": "0,00",
                                                "column008": "-5.702.544,01",
                                                "column009": "0,00",
                                                "column010": "-4.200.000,65",
                                                "column011": "-1.437.326,59",
                                                "column012": "-25.000,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112046",
                                                "description": "BANCO BRADESCO S.A. - AG 2372 C/C 006170",
                                                "column001": "289.589,60",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "197.000,00",
                                                "column007": "-3.000,00",
                                                "column008": "-1.280,00",
                                                "column009": "-3.000,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112047",
                                                "description": "BANCO DAYCOVAL S.A.",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "-1.000,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112048",
                                                "description": "BANCO ITAU SA - C/C 131042",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112049",
                                                "description": "BANCO PINE S.A.",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112051",
                                                "description": "BANCO CCB",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "-159.062,59",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112055",
                                                "description": "BANCO DEUTSCHE BANK (487)",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112059",
                                                "description": "BANCO DO BRASIL S.A.",
                                                "column001": "4.733.306,44",
                                                "column002": "-1.185.900,49",
                                                "column003": "403.579,46",
                                                "column004": "0,00",
                                                "column005": "-1.968.446,60",
                                                "column006": "6.545.381,30",
                                                "column007": "-4.710.257,57",
                                                "column008": "907.207,83",
                                                "column009": "-1.359.333,64",
                                                "column010": "1.763.611,26",
                                                "column011": "2.423.714,17",
                                                "column012": "217.937,36",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112060",
                                                "description": "BANCO DO BRASIL C/C 105010-9 AG 1913-5",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "8.479,82",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112061",
                                                "description": "BANCO BMG",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1112999",
                                                "description": "BANCOS",
                                                "column001": "-740.230,06",
                                                "column002": "-17.050.803,49",
                                                "column003": "8.728.520,70",
                                                "column004": "0,00",
                                                "column005": "14.435.000,00",
                                                "column006": "-4.430.500,00",
                                                "column007": "-4.243.397,00",
                                                "column008": "5.565.039,07",
                                                "column009": "7.027.893,79",
                                                "column010": "-285.790,80",
                                                "column011": "227.660.749,52",
                                                "column012": "177.462.681,46",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "1113",
                                        "description": "BANCOS CONTA VINCULADA",
                                        "column001": "14.997,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "1113999",
                                                "description": "NUMERARIOS EM TRANSITO",
                                                "column001": "14.997,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "1114",
                                        "description": "APLICACOES FINANCEIRAS",
                                        "column001": "33.525.586,49",
                                        "column002": "30.464.444,96",
                                        "column003": "-38.596.467,87",
                                        "column004": "0,00",
                                        "column005": "4.036.644,06",
                                        "column006": "46.900.005,24",
                                        "column007": "48.253.169,27",
                                        "column008": "-23.904.932,08",
                                        "column009": "22.666.047,91",
                                        "column010": "28.366.660,24",
                                        "column011": "-12.954.335,72",
                                        "column012": "225.475.202,29",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "1114001",
                                                "description": "BANCO ITAU S.A.",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1114006",
                                                "description": "BANCO DO BRASIL S.A",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "22.272.214,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1114013",
                                                "description": "BANCO SANTANDER",
                                                "column001": "20.029.568,17",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-5.149.998,36",
                                                "column006": "0,00",
                                                "column007": "-22.274.231,55",
                                                "column008": "-3.371.232,20",
                                                "column009": "23.846.897,11",
                                                "column010": "7.563.136,19",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1114014",
                                                "description": "BANCO ITAU S/A - CD",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1114019",
                                                "description": "BANCO ABC BRASIL",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,01",
                                                "column009": "0,00",
                                                "column010": "4.200.000,65",
                                                "column011": "1.437.326,59",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1114025",
                                                "description": "BANCO SAFRA",
                                                "column001": "17.953.239,00",
                                                "column002": "-5.879.156,72",
                                                "column003": "-2.656.391,22",
                                                "column004": "0,00",
                                                "column005": "-2.742.605,35",
                                                "column006": "3.026.323,17",
                                                "column007": "25.241.792,93",
                                                "column008": "0,00",
                                                "column009": "-28.000.000,00",
                                                "column010": "-23.976.252,02",
                                                "column011": "-27.816.166,63",
                                                "column012": "27.461.072,31",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1114036",
                                                "description": "BANCO ITAU BBA S/A",
                                                "column001": "-4.457.220,68",
                                                "column002": "36.343.601,68",
                                                "column003": "-35.940.076,65",
                                                "column004": "0,00",
                                                "column005": "11.929.247,77",
                                                "column006": "43.873.682,07",
                                                "column007": "23.013.393,89",
                                                "column008": "-20.533.699,89",
                                                "column009": "26.819.150,80",
                                                "column010": "40.579.775,42",
                                                "column011": "13.424.504,32",
                                                "column012": "198.014.129,98",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "1119",
                                        "description": "TIT. E VALORES IMOBILIARI",
                                        "column001": "-149.625,70",
                                        "column002": "252,20",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "1.709.781,89",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "335,79",
                                        "column010": "0,00",
                                        "column011": "333,31",
                                        "column012": "106,49",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "1119001",
                                                "description": "APLICACAO CDB FUNDAP",
                                                "column001": "0,00",
                                                "column002": "252,20",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "1.411.230,09",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "333,31",
                                                "column012": "106,49",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1119002",
                                                "description": "APLICACOES FUNDAP-CAUCAO",
                                                "column001": "-149.625,70",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "298.551,80",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "335,79",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "112",
                                "description": "CLIENTES",
                                "column001": "-12.268.414,90",
                                "column002": "-52.343.203,59",
                                "column003": "-80.810.877,93",
                                "column004": "0,00",
                                "column005": "3.282.548,21",
                                "column006": "1.893.137,45",
                                "column007": "-51.245.974,04",
                                "column008": "-15.994.150,91",
                                "column009": "36.292.075,30",
                                "column010": "-8.956.843,94",
                                "column011": "-59.773.840,11",
                                "column012": "-50.477.577,91",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "1121",
                                        "description": "CLIENTES NACIONAIS",
                                        "column001": "-12.130.476,73",
                                        "column002": "-51.856.422,59",
                                        "column003": "-82.454.290,94",
                                        "column004": "0,00",
                                        "column005": "776.220,91",
                                        "column006": "-9.125.034,85",
                                        "column007": "-57.642.604,86",
                                        "column008": "-21.941.938,53",
                                        "column009": "33.514.957,47",
                                        "column010": "-15.097.762,89",
                                        "column011": "-56.537.863,71",
                                        "column012": "-49.438.512,90",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "112100000",
                                                "description": "CLIENTES",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "11210001",
                                                "description": "CLIENTES NACIONAIS",
                                                "column001": "-12.130.476,73",
                                                "column002": "-51.856.422,59",
                                                "column003": "-82.454.290,94",
                                                "column004": "0,00",
                                                "column005": "780.579,30",
                                                "column006": "-9.125.034,85",
                                                "column007": "-57.642.604,86",
                                                "column008": "-21.941.938,53",
                                                "column009": "33.514.957,47",
                                                "column010": "-15.097.762,89",
                                                "column011": "-56.537.863,71",
                                                "column012": "-49.438.512,90",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "112100010",
                                                "description": "PLUS CARGO INTERNAC",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "112109553",
                                                "description": "AJUSTE A VALOR PRES",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "11219998",
                                                "description": "PROVISAO DEV.DUVIDOSOS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-4.358,39",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "11219999",
                                                "description": "AJUSTE DE CUT OFF",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "112199998",
                                                "description": "PROVISAO P/DEV DUVI",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "112199999",
                                                "description": "PROV. DEVEDORES DUV",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "1124",
                                        "description": "CLIENTES EXTERIOR",
                                        "column001": "-1.614.713,02",
                                        "column002": "983,93",
                                        "column003": "2.241,37",
                                        "column004": "0,00",
                                        "column005": "4.716,19",
                                        "column006": "347.399,41",
                                        "column007": "21.457,06",
                                        "column008": "-878.059,54",
                                        "column009": "-468.820,32",
                                        "column010": "962.100,34",
                                        "column011": "-856.607,05",
                                        "column012": "-241.496,44",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "112400004",
                                                "description": "CX TRADE AG",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "11240001",
                                                "description": "CLIENTES ESTRANGEIROS",
                                                "column001": "-1.614.713,02",
                                                "column002": "983,93",
                                                "column003": "2.241,37",
                                                "column004": "0,00",
                                                "column005": "4.716,19",
                                                "column006": "347.399,41",
                                                "column007": "21.457,06",
                                                "column008": "-878.059,54",
                                                "column009": "-468.820,32",
                                                "column010": "962.100,34",
                                                "column011": "-856.607,05",
                                                "column012": "-241.496,44",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "1125",
                                        "description": "TITULOS DESCONTADOS",
                                        "column001": "1.476.774,85",
                                        "column002": "-487.764,93",
                                        "column003": "1.641.171,64",
                                        "column004": "0,00",
                                        "column005": "2.501.611,11",
                                        "column006": "10.670.772,89",
                                        "column007": "6.375.173,76",
                                        "column008": "6.825.847,16",
                                        "column009": "3.245.938,15",
                                        "column010": "5.178.818,61",
                                        "column011": "-2.379.369,35",
                                        "column012": "-797.568,57",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "1125001",
                                                "description": "(-) DUPLICATAS DESCONTADAS",
                                                "column001": "1.476.774,85",
                                                "column002": "-487.764,93",
                                                "column003": "1.641.171,64",
                                                "column004": "0,00",
                                                "column005": "2.501.611,11",
                                                "column006": "10.670.772,89",
                                                "column007": "6.375.173,76",
                                                "column008": "6.825.847,16",
                                                "column009": "3.245.938,15",
                                                "column010": "5.178.818,61",
                                                "column011": "-2.379.369,35",
                                                "column012": "-797.568,57",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "113",
                                "description": "OUTROS CREDITOS",
                                "column001": "-35.428.097,43",
                                "column002": "-65.770.007,10",
                                "column003": "-15.340.038,16",
                                "column004": "255,47",
                                "column005": "-31.410.447,60",
                                "column006": "-30.673.255,91",
                                "column007": "-71.378.465,91",
                                "column008": "-36.881.749,76",
                                "column009": "-69.375.630,74",
                                "column010": "-30.739.311,73",
                                "column011": "-25.725.775,88",
                                "column012": "-27.650.280,64",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "1131",
                                        "description": "ADIANTAMENTO A FORNECEDOR",
                                        "column001": "-937.201,35",
                                        "column002": "-154.621,27",
                                        "column003": "-59.700,92",
                                        "column004": "0,00",
                                        "column005": "-361.476,21",
                                        "column006": "-73.243,38",
                                        "column007": "-149.951,50",
                                        "column008": "236.051,67",
                                        "column009": "-608.541,36",
                                        "column010": "43.411,45",
                                        "column011": "-40.360,56",
                                        "column012": "-359.456,73",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "113100000",
                                                "description": "ADIANTAMENTO FORNEC",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113100005",
                                                "description": "PREFEITURA DO MUNIC",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "11310001",
                                                "description": "ADIANTAMENTOS FORNECEDORES NACIONAIS",
                                                "column001": "-937.201,35",
                                                "column002": "-154.621,27",
                                                "column003": "-59.700,92",
                                                "column004": "0,00",
                                                "column005": "-361.476,21",
                                                "column006": "-73.243,38",
                                                "column007": "-149.951,50",
                                                "column008": "236.051,67",
                                                "column009": "-608.541,36",
                                                "column010": "43.411,45",
                                                "column011": "-40.360,56",
                                                "column012": "-359.456,73",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113100027",
                                                "description": "ALLIED S.A.",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113100041",
                                                "description": "ARVEDI METALFER DO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113100042",
                                                "description": "SHIPMASTERS DO BRAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113100064",
                                                "description": "SODEXO PASS DO BRAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113100075",
                                                "description": "JOEL LUIZ GUILONZON",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113100082",
                                                "description": "EURO-DIESEL SISTEMA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113100083",
                                                "description": "CONCEPTCOMEX EIRELI",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113100088",
                                                "description": "C.OLIVIER DASLTON T",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1131001",
                                                "description": "OUTROS ADIANT.FORNEC.",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113102553",
                                                "description": "VALE S.A.",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "1132",
                                        "description": "CONTAS A RECEBER - CONTA E ORDEM",
                                        "column001": "-37.737.782,92",
                                        "column002": "-64.354.601,58",
                                        "column003": "-21.762.653,97",
                                        "column004": "0,00",
                                        "column005": "-24.744.262,70",
                                        "column006": "-31.140.600,43",
                                        "column007": "-58.403.477,10",
                                        "column008": "-37.940.241,93",
                                        "column009": "-60.704.872,85",
                                        "column010": "-26.176.164,40",
                                        "column011": "-33.242.398,75",
                                        "column012": "-37.022.197,81",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "11320001",
                                                "description": "CLIENTES CONTA E ORDEM",
                                                "column001": "-6.529.524,83",
                                                "column002": "-11.695.616,79",
                                                "column003": "-3.029.074,40",
                                                "column004": "0,00",
                                                "column005": "-6.420.613,14",
                                                "column006": "-6.812.103,97",
                                                "column007": "-7.992.024,96",
                                                "column008": "-4.679.375,47",
                                                "column009": "-4.856.516,43",
                                                "column010": "-2.821.710,29",
                                                "column011": "-9.490.618,30",
                                                "column012": "-8.938.631,98",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1132001",
                                                "description": "OUTRAS CONTAS A RECEBER",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "-33.580,47",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113209921",
                                                "description": "TOYOTA DO BRASIL LTDA",
                                                "column001": "-31.208.258,09",
                                                "column002": "-52.658.984,79",
                                                "column003": "-18.733.579,57",
                                                "column004": "0,00",
                                                "column005": "-18.323.649,56",
                                                "column006": "-24.328.496,46",
                                                "column007": "-50.411.452,14",
                                                "column008": "-33.260.866,46",
                                                "column009": "-55.848.356,42",
                                                "column010": "-23.320.873,64",
                                                "column011": "-23.751.780,45",
                                                "column012": "-28.083.565,83",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "1134",
                                        "description": "CREDITOS DE COLIGADAS",
                                        "column001": "4.172.169,49",
                                        "column002": "360.500,89",
                                        "column003": "-2.513.628,59",
                                        "column004": "0,00",
                                        "column005": "-5.544.366,87",
                                        "column006": "365.895,83",
                                        "column007": "-6.006.611,36",
                                        "column008": "-5.729.179,08",
                                        "column009": "-5.531.433,60",
                                        "column010": "-4.776.784,76",
                                        "column011": "7.161.978,75",
                                        "column012": "6.541.044,77",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "1134007",
                                                "description": "TROP COMERCIO EXTER",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1134050",
                                                "description": "COMEXPORT CIA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "26.259,55",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1134051",
                                                "description": "TROP COMERCIO EXTER",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1134052",
                                                "description": "KALIUM CHEMICAL",
                                                "column001": "4.172.169,49",
                                                "column002": "360.628,09",
                                                "column003": "-2.513.628,59",
                                                "column004": "0,00",
                                                "column005": "-5.661.657,03",
                                                "column006": "365.895,83",
                                                "column007": "-6.006.611,36",
                                                "column008": "-5.729.179,08",
                                                "column009": "-5.531.433,60",
                                                "column010": "-4.776.784,76",
                                                "column011": "7.161.978,75",
                                                "column012": "6.541.044,77",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1134053",
                                                "description": "COMEXPORT SERVICOS",
                                                "column001": "0,00",
                                                "column002": "-127,20",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "88.971,27",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1134055",
                                                "description": "CXT DISTRIBUIDORA LTDA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "2.059,34",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "1135",
                                        "description": "IMPOSTOS A RECUPERAR",
                                        "column001": "1.430.730,95",
                                        "column002": "-95.052,60",
                                        "column003": "-22.603,88",
                                        "column004": "255,47",
                                        "column005": "-166.823,46",
                                        "column006": "-156.225,14",
                                        "column007": "-994.664,18",
                                        "column008": "-518.289,61",
                                        "column009": "-181.449,07",
                                        "column010": "-112.815,62",
                                        "column011": "-49.487,47",
                                        "column012": "-152.855,58",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "1135004",
                                                "description": "I.C.M.S. A RECUPERAR  DIFAL",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "-346.500,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113501001",
                                                "description": "ICMS A RECUP VITORIA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113501002",
                                                "description": "ICMS A RECUP ITAJAI",
                                                "column001": "-592,02",
                                                "column002": "-2.643,07",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-24.367,93",
                                                "column006": "0,00",
                                                "column007": "-6.272,15",
                                                "column008": "-4.260,79",
                                                "column009": "-3.370,28",
                                                "column010": "-7.047,86",
                                                "column011": "-8.146,86",
                                                "column012": "-6.073,33",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113501004",
                                                "description": "ICMS A RECUP ARMAZE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113501005",
                                                "description": "ICMS A RECUP CURITI",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113501008",
                                                "description": "ICMS A RECUP ITAJAI",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113501009",
                                                "description": "ICMS A RECUP CARIAC",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113501011",
                                                "description": "ICMS ST RECUP ITAJAI",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135011",
                                                "description": "PIS A COMPENSAR",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135012",
                                                "description": "COFINS A COMPENSAR",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113502001",
                                                "description": "IPI A RECUP VITORIA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113502002",
                                                "description": "IPI A RECUP ITAJAI",
                                                "column001": "-15.330,20",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-53.203,34",
                                                "column006": "-1.058,37",
                                                "column007": "0,00",
                                                "column008": "-9.809,75",
                                                "column009": "-29.756,41",
                                                "column010": "-8.809,83",
                                                "column011": "0,00",
                                                "column012": "-38.128,16",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113502006",
                                                "description": "IPI A RECUPERAR CARIACICA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113502008",
                                                "description": "IPI A RECUP ITAJAI",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113502009",
                                                "description": "IPI A RECUP CARIACI",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135023",
                                                "description": "IRRF S/SERVICOS",
                                                "column001": "-9.083,27",
                                                "column002": "-3.508,06",
                                                "column003": "-1.097,49",
                                                "column004": "0,00",
                                                "column005": "-4.397,61",
                                                "column006": "-8.971,65",
                                                "column007": "-7.480,90",
                                                "column008": "-4.983,19",
                                                "column009": "-2.514,40",
                                                "column010": "-5.662,06",
                                                "column011": "-4.167,05",
                                                "column012": "-2.756,44",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135025",
                                                "description": "I.I. A COMP S/IMPORTACAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113503002",
                                                "description": "ISS A RECUP SAO PAULO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135032",
                                                "description": "IRRF S/APLIC FINANCEIRA",
                                                "column001": "-309.242,81",
                                                "column002": "-52.339,22",
                                                "column003": "-33.595,90",
                                                "column004": "0,00",
                                                "column005": "-104.845,07",
                                                "column006": "-130.454,22",
                                                "column007": "-591.243,90",
                                                "column008": "-161.065,55",
                                                "column009": "-155.980,35",
                                                "column010": "-74.144,08",
                                                "column011": "-68.558,72",
                                                "column012": "-133.279,02",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135033",
                                                "description": "PIS A RECUPERAR SERVICOS",
                                                "column001": "-3.986,38",
                                                "column002": "-1.536,65",
                                                "column003": "-576,42",
                                                "column004": "0,00",
                                                "column005": "-2.031,96",
                                                "column006": "-3.573,04",
                                                "column007": "-4.261,26",
                                                "column008": "-2.750,21",
                                                "column009": "-1.993,52",
                                                "column010": "-2.448,09",
                                                "column011": "-1.918,81",
                                                "column012": "-1.213,75",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135034",
                                                "description": "COFINS A RECUPERAR SERVICOS",
                                                "column001": "-14.007,25",
                                                "column002": "-4.946,89",
                                                "column003": "-3.033,06",
                                                "column004": "0,00",
                                                "column005": "-6.862,48",
                                                "column006": "-16.695,28",
                                                "column007": "-23.167,27",
                                                "column008": "-13.276,67",
                                                "column009": "-9.115,98",
                                                "column010": "-11.261,61",
                                                "column011": "-7.208,39",
                                                "column012": "-6.008,93",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135035",
                                                "description": "C.S.L. SOBRE SERVICOS",
                                                "column001": "-5.829,39",
                                                "column002": "-1.767,35",
                                                "column003": "-992,96",
                                                "column004": "0,00",
                                                "column005": "-2.898,77",
                                                "column006": "-5.397,30",
                                                "column007": "40.332,02",
                                                "column008": "-4.581,76",
                                                "column009": "-3.161,95",
                                                "column010": "-3.442,09",
                                                "column011": "-2.551,27",
                                                "column012": "-1.960,54",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135037",
                                                "description": "COFINS S/IMPORT A RECUPER",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135039",
                                                "description": "ANTECIPACAO  CSLL",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113504000",
                                                "description": "PIS A RECUP S/FRETE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113504001",
                                                "description": "PIS A RECUP S PAULO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113504002",
                                                "description": "PIS A RECUPERAR PA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113504003",
                                                "description": "PIS A RECUPERAR ITAJAI",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "3.900,24",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113504004",
                                                "description": "PIS A RECUPERAR RECIFE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113504006",
                                                "description": "PIS A RECUPERAR CARIACICA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113504099",
                                                "description": "PIS A RECUPERAR VENDA FUTURA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135042",
                                                "description": "ANTECIPACAO IRPJ",
                                                "column001": "1.449.050,28",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113504201",
                                                "description": "IRPJ PAGAMENTO INDE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113504202",
                                                "description": "IRPJ PAGAMENTO IND",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113504203",
                                                "description": "IRPJ PAGAMENTO IND",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135043",
                                                "description": "ANTECIPACAO CSLL",
                                                "column001": "288.439,06",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113504303",
                                                "description": "CSLL PAGAMENTO IND",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135047",
                                                "description": "PG INDEVIDO OU A MAIOR IRPJ 2015",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135049",
                                                "description": "SALDO NEGATIVO IRPJ 2015",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135050",
                                                "description": "SALDO NEGATIVO CSLL 2015",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,01",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113505000",
                                                "description": "COFINS A RECUP S/FRETE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113505001",
                                                "description": "COFINS A RECUP S PA",
                                                "column001": "19.254,18",
                                                "column002": "0,00",
                                                "column003": "4.439,62",
                                                "column004": "0,00",
                                                "column005": "6.403,42",
                                                "column006": "8.365,84",
                                                "column007": "0,00",
                                                "column008": "25.180,02",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "5.883,10",
                                                "column012": "13.105,80",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113505002",
                                                "description": "COFINS A RECUPERAR",
                                                "column001": "24,80",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113505003",
                                                "description": "COFINS A RECUP ITAJAI",
                                                "column001": "6.744,96",
                                                "column002": "0,00",
                                                "column003": "12.252,33",
                                                "column004": "0,00",
                                                "column005": "18.167,82",
                                                "column006": "0,00",
                                                "column007": "2.178,41",
                                                "column008": "11.131,66",
                                                "column009": "24.443,82",
                                                "column010": "0,00",
                                                "column011": "18.020,59",
                                                "column012": "15.867,13",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113505004",
                                                "description": "COFINS A RECUPERAR RECIFE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113505006",
                                                "description": "COFINS A RECUPERAR CARIACICA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113505099",
                                                "description": "COFINS A RECUPERAR VENDA FUTURA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "-12.221,08",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135051",
                                                "description": "ICMS TRANSFERENCIAS",
                                                "column001": "25.288,99",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135052",
                                                "description": "IPI - TRANSFERENCIAS",
                                                "column001": "0,00",
                                                "column002": "3.303,83",
                                                "column003": "0,00",
                                                "column004": "255,47",
                                                "column005": "7.212,46",
                                                "column006": "1.558,87",
                                                "column007": "7.840,19",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "10.183,56",
                                                "column012": "7.591,66",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135056",
                                                "description": "OUTROS IMPOSTOS E TAXAS A RECUPERAR",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "8.976,38",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135057",
                                                "description": "IPI A RESTITUIR PROC 55627-00",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135058",
                                                "description": "PGTO INDEVIDO OU A MAIOR IRPJ 2016",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135059",
                                                "description": "PGTO INDEVIDO OU A MAIOR CSLL 2016",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135060",
                                                "description": "SALDO BASE NEGATIVA 2016",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "-66.089,32",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135061",
                                                "description": "SALDO NEGATIVO CSLL 2016",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135062",
                                                "description": "IRPJ RECUP BASE NEG",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135098",
                                                "description": "PIS PROC.10783-902.879/2017-76",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "-345.552,53",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1135099",
                                                "description": "INSS A COMPENSAR - RECUPER. GRAU I",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113512006",
                                                "description": "ANTECIPACAO IRPJ 20",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113512007",
                                                "description": "ANTECIPACAO CSLL 20",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113512009",
                                                "description": "CSLL PAGA A MAIOR 2",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113512010",
                                                "description": "IRPJ PAGO A MAIOR 2",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113512011",
                                                "description": "PIS - CRED PRES  RE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113512012",
                                                "description": "COFINS CRED PRES RE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113501003",
                                                "description": "ICMS A RECUP ITAJAI",
                                                "column001": "0,00",
                                                "column002": "-9.032,91",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113501006",
                                                "description": "ICMS A RECUPERAR CARIACICA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113502004",
                                                "description": "IPI A RECUP RECIFE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "113502003",
                                                "description": "IPI A RECUP ITAJAI",
                                                "column001": "0,00",
                                                "column002": "-22.582,28",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "1136",
                                        "description": "ADIANTAMENTOS P/ VIAGENS",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "1136002",
                                                "description": "ALAN GOLDLUST",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "1137",
                                        "description": "ADIANTAMENTOS A FUNCION.",
                                        "column001": "10.332,74",
                                        "column002": "-29.440,81",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "23.301,14",
                                        "column006": "2.489,30",
                                        "column007": "-34.303,06",
                                        "column008": "37.241,45",
                                        "column009": "-51.799,54",
                                        "column010": "-3.493,06",
                                        "column011": "16.450,91",
                                        "column012": "20.434,55",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "1137001",
                                                "description": "ADIANTAMENTO DE SALARIOS",
                                                "column001": "0,00",
                                                "column002": "-773,64",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "40.977,07",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "773,64",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1137002",
                                                "description": "ADIANTAMENTOS 13 SALARIO",
                                                "column001": "0,00",
                                                "column002": "-6.368,38",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-24.998,28",
                                                "column006": "-10.471,66",
                                                "column007": "-7.453,78",
                                                "column008": "-22.387,21",
                                                "column009": "37,12",
                                                "column010": "-11.361,86",
                                                "column011": "-15.768,58",
                                                "column012": "-12.594,02",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1137003",
                                                "description": "ADIANTAMENTOS DE FERIAS",
                                                "column001": "10.332,74",
                                                "column002": "13.962,65",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "15.025,08",
                                                "column006": "5.258,25",
                                                "column007": "-64.378,30",
                                                "column008": "97.157,68",
                                                "column009": "-51.836,66",
                                                "column010": "7.868,80",
                                                "column011": "32.219,49",
                                                "column012": "-4.006,51",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1137009",
                                                "description": "EMPRESTIMO CONSIGNADO",
                                                "column001": "0,00",
                                                "column002": "-36.261,44",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "33.274,34",
                                                "column006": "-33.274,36",
                                                "column007": "37.529,02",
                                                "column008": "-37.529,02",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "36.261,44",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "1138",
                                        "description": "ADTO A FORNECEDORES ESTRANGEIROS",
                                        "column001": "-2.366.346,34",
                                        "column002": "-1.496.791,73",
                                        "column003": "9.018.549,20",
                                        "column004": "0,00",
                                        "column005": "-616.819,50",
                                        "column006": "287.450,84",
                                        "column007": "-5.789.458,71",
                                        "column008": "7.032.667,74",
                                        "column009": "-2.297.534,32",
                                        "column010": "283.399,84",
                                        "column011": "424.846,81",
                                        "column012": "3.321.496,11",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "11380001",
                                                "description": "ADIANTAMENTOS FORNECEDORES ESTRANGEIROS",
                                                "column001": "-2.366.346,34",
                                                "column002": "-1.496.791,73",
                                                "column003": "9.018.549,20",
                                                "column004": "0,00",
                                                "column005": "-616.819,50",
                                                "column006": "287.450,84",
                                                "column007": "-5.789.458,71",
                                                "column008": "7.032.667,74",
                                                "column009": "-2.297.534,32",
                                                "column010": "283.399,84",
                                                "column011": "424.846,81",
                                                "column012": "3.321.496,11",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "11389998",
                                                "description": "FINANCIAMENTOS (FINIMP)",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "1139",
                                        "description": "OUTROS CREDITOS",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "40.977,07",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "3.134,82",
                                        "column011": "3.194,43",
                                        "column012": "1.254,05",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "1139001",
                                                "description": "CAUCAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1139002",
                                                "description": "SHEILA SCOMAZZON  D",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1139009",
                                                "description": "CXT DISTRIBUIDORA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1139010",
                                                "description": "MUTUO - COMEXPORT T",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1139011",
                                                "description": "SERGIO RENATINO STOS DE MEDEIROS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "10.977,07",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1139016",
                                                "description": "CREDITOS PROJETO TOYOTA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1139017",
                                                "description": "RESULTADO LIQUIDO HEDGE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1139018",
                                                "description": "MARIA EDUARDA BRINQUEDOS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "3.134,82",
                                                "column011": "3.194,43",
                                                "column012": "1.254,05",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1139019",
                                                "description": "PAULA LIMA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1139020",
                                                "description": "CLAUDIA ELAINE N.",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "30.000,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "114",
                                "description": "ESTOQUES",
                                "column001": "85.330.397,29",
                                "column002": "85.880.367,61",
                                "column003": "1.155.130,82",
                                "column004": "0,00",
                                "column005": "77.009.109,00",
                                "column006": "117.123.905,60",
                                "column007": "103.927.254,81",
                                "column008": "98.579.233,86",
                                "column009": "120.477.093,97",
                                "column010": "95.700.055,97",
                                "column011": "4.977.703,46",
                                "column012": "85.102.096,52",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "1141",
                                        "description": "PRODUTOS DIVERSOS",
                                        "column001": "8.938,57",
                                        "column002": "-759.161,68",
                                        "column003": "-665.892,79",
                                        "column004": "0,00",
                                        "column005": "-1.220.654,65",
                                        "column006": "-601.127,43",
                                        "column007": "-602.666,48",
                                        "column008": "-517.953,42",
                                        "column009": "-2.155.634,11",
                                        "column010": "0,00",
                                        "column011": "-485.141,35",
                                        "column012": "-375.561,46",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "1141020",
                                                "description": "CHINA TRADING",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1141022",
                                                "description": "PETROQUIMICO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "-6.818,54",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1141023",
                                                "description": "QUIMICO",
                                                "column001": "-5.616,49",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-2.361,27",
                                                "column006": "0,00",
                                                "column007": "-12.276,50",
                                                "column008": "-151.441,93",
                                                "column009": "-156.823,97",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "-3.868,41",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1141024",
                                                "description": "SIDERURGICO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "-135.938,74",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1141030",
                                                "description": "NOVOS NEGOCIOS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "-5.982,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1141031",
                                                "description": "TEXTIL MP",
                                                "column001": "-8.473,08",
                                                "column002": "-24.281,11",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "-1.430,00",
                                                "column008": "0,00",
                                                "column009": "-334.194,96",
                                                "column010": "0,00",
                                                "column011": "-3.743,29",
                                                "column012": "11.903,70",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1141032",
                                                "description": "TEXTIL PA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1141035",
                                                "description": "TERCEIRIZACAO",
                                                "column001": "42.273,00",
                                                "column002": "-716.364,54",
                                                "column003": "-661.591,90",
                                                "column004": "0,00",
                                                "column005": "-179.749,94",
                                                "column006": "-601.127,43",
                                                "column007": "-580.319,13",
                                                "column008": "-358.498,81",
                                                "column009": "-1.641.365,03",
                                                "column010": "0,00",
                                                "column011": "-486.946,88",
                                                "column012": "-244.664,09",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1141049",
                                                "description": "AUTOMOTIVOS",
                                                "column001": "-14.089,46",
                                                "column002": "-18.516,03",
                                                "column003": "-4.300,89",
                                                "column004": "0,00",
                                                "column005": "-364,50",
                                                "column006": "0,00",
                                                "column007": "-8.640,85",
                                                "column008": "-2.030,68",
                                                "column009": "-16.431,61",
                                                "column010": "0,00",
                                                "column011": "5.548,82",
                                                "column012": "-2.993,92",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1141058",
                                                "description": "KROMBACHER",
                                                "column001": "-5.155,40",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1141061",
                                                "description": "DISTRIBUICAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1141998",
                                                "description": "PROVISAO GIRO LENTO DOS ESTOQUES",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-1.038.178,94",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1141999",
                                                "description": "PROVISAO PARA PERDAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "1142",
                                        "description": "ESTOQUE DE TERCEIROS",
                                        "column001": "76.782.175,58",
                                        "column002": "84.333.703,79",
                                        "column003": "-119.648,80",
                                        "column004": "0,00",
                                        "column005": "59.110.806,61",
                                        "column006": "82.343.180,10",
                                        "column007": "99.024.694,69",
                                        "column008": "113.568.978,08",
                                        "column009": "117.159.769,69",
                                        "column010": "92.681.168,81",
                                        "column011": "84.939.482,00",
                                        "column012": "84.388.947,71",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "1142035",
                                                "description": "TERCEIRIZACAO",
                                                "column001": "21.963.191,04",
                                                "column002": "15.732.858,67",
                                                "column003": "-119.648,80",
                                                "column004": "0,00",
                                                "column005": "14.087.166,20",
                                                "column006": "24.337.175,08",
                                                "column007": "23.181.527,78",
                                                "column008": "22.193.175,76",
                                                "column009": "22.464.204,56",
                                                "column010": "18.095.596,10",
                                                "column011": "18.117.994,82",
                                                "column012": "12.155.073,39",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1142049",
                                                "description": "AUTOMOTIVOS",
                                                "column001": "54.818.984,54",
                                                "column002": "68.600.845,12",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "45.023.640,41",
                                                "column006": "58.006.005,02",
                                                "column007": "75.843.166,91",
                                                "column008": "91.188.593,92",
                                                "column009": "94.695.565,13",
                                                "column010": "74.585.572,71",
                                                "column011": "66.821.487,18",
                                                "column012": "72.165.904,95",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1142999",
                                                "description": "(-) AUTOMOTIVOS - V",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1142031",
                                                "description": "TEXTIL",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1142023",
                                                "description": "QUIMICO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "187.208,40",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1142024",
                                                "description": "SIDERURGICO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "67.969,37",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "1143",
                                        "description": "IMPORTACOES NAO DESEMBARACADAS",
                                        "column001": "84.036,51",
                                        "column002": "-624.354,50",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "13.505.503,69",
                                        "column006": "29.802.894,99",
                                        "column007": "180.090,93",
                                        "column008": "-17.211.106,36",
                                        "column009": "126.109,00",
                                        "column010": "0,15",
                                        "column011": "-82.546.899,54",
                                        "column012": "-71.249,49",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "1143020",
                                                "description": "IMPORTACOES NAO DESEMBARACADAS - 20",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1143022",
                                                "description": "IMPORTACOES NAO DESEMBARACADAS - 22",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "469.315,77",
                                                "column007": "0,00",
                                                "column008": "-329.815,52",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1143023",
                                                "description": "IMPORTACOES NAO DESEMBARACADAS - 23",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-223.614,22",
                                                "column006": "2.150.406,42",
                                                "column007": "0,00",
                                                "column008": "-1.699.163,11",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1143024",
                                                "description": "IMPORTACOES NAO DESEMBARACADAS - 24",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "1.609.877,16",
                                                "column006": "-5.626.373,33",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1143030",
                                                "description": "IMPORTACOES NAO DESEMBARACADAS - 30",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1143031",
                                                "description": "IMPORTACOES NAO DESEMBARACADAS - 31",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "-38.973,99",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "-576.968,53",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1143032",
                                                "description": "IMPORTACOES NAO DESEMBARACADAS - 32",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1143035",
                                                "description": "IMPORTACOES NAO DESEMBARACADAS - 35",
                                                "column001": "12.267,84",
                                                "column002": "-624.354,50",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "11.271.471,75",
                                                "column006": "23.834.836,44",
                                                "column007": "180.090,93",
                                                "column008": "-12.338.610,67",
                                                "column009": "0,00",
                                                "column010": "0,15",
                                                "column011": "-81.097.433,05",
                                                "column012": "-71.323,45",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1143049",
                                                "description": "IMPORTACOES NAO DESEMBARACADAS - 49",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "847.769,00",
                                                "column006": "8.969.243,98",
                                                "column007": "0,00",
                                                "column008": "-2.804.543,07",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "-872.424,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1143058",
                                                "description": "KROMBACHER",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1143061",
                                                "description": "IMPORTACOES NAO DES",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1143997",
                                                "description": "IPI S/TRANSFERENCIA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1143998",
                                                "description": "ICMS S/TRANSFERENCI",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1143999",
                                                "description": "TRANSFERENCIAS",
                                                "column001": "71.768,67",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "5.465,71",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "126.109,00",
                                                "column010": "0,00",
                                                "column011": "-73,96",
                                                "column012": "73,96",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "1146",
                                        "description": "IMPORTACAO CONTA E ORDEM",
                                        "column001": "3.382.544,53",
                                        "column002": "655.489,76",
                                        "column003": "1.017.243,56",
                                        "column004": "0,00",
                                        "column005": "2.035.149,62",
                                        "column006": "1.596.612,66",
                                        "column007": "3.119.228,55",
                                        "column008": "947.986,89",
                                        "column009": "2.240.910,73",
                                        "column010": "246.607,02",
                                        "column011": "1.733.526,33",
                                        "column012": "252.383,19",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "11460001",
                                                "description": "IMPORTACAO CONTA E ORDEM",
                                                "column001": "3.382.544,53",
                                                "column002": "655.489,76",
                                                "column003": "1.017.243,56",
                                                "column004": "0,00",
                                                "column005": "2.035.149,62",
                                                "column006": "1.596.612,66",
                                                "column007": "3.119.228,55",
                                                "column008": "947.986,89",
                                                "column009": "2.240.910,73",
                                                "column010": "246.607,02",
                                                "column011": "1.733.526,33",
                                                "column012": "252.383,19",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "1147",
                                        "description": "IMPORTACAO COMPRA E VENDA",
                                        "column001": "5.072.702,10",
                                        "column002": "2.274.690,24",
                                        "column003": "920.928,85",
                                        "column004": "0,00",
                                        "column005": "3.578.303,73",
                                        "column006": "3.982.345,28",
                                        "column007": "2.205.907,12",
                                        "column008": "1.791.328,67",
                                        "column009": "3.105.938,66",
                                        "column010": "2.772.279,99",
                                        "column011": "1.336.736,02",
                                        "column012": "907.576,57",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "11470001",
                                                "description": "IMPORTACAO COMPRA E VENDA",
                                                "column001": "5.072.702,10",
                                                "column002": "2.274.690,24",
                                                "column003": "920.928,85",
                                                "column004": "0,00",
                                                "column005": "3.578.303,73",
                                                "column006": "3.982.345,28",
                                                "column007": "2.205.907,12",
                                                "column008": "1.791.328,67",
                                                "column009": "3.105.938,66",
                                                "column010": "2.772.279,99",
                                                "column011": "1.336.736,02",
                                                "column012": "907.576,57",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "1145",
                                        "description": "IMPORTACAO EM ANDAMENTO",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "2.500,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "1145000",
                                                "description": "IMPORTACAO EM ANDAMENTO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "2.500,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "116",
                                "description": "DESP. EXERCICIO SEGUINTE",
                                "column001": "-22.615,16",
                                "column002": "-3.617,50",
                                "column003": "0,00",
                                "column004": "0,00",
                                "column005": "39.132,13",
                                "column006": "-28.231,88",
                                "column007": "-3.741,09",
                                "column008": "-878,58",
                                "column009": "-3.617,50",
                                "column010": "-310,48",
                                "column011": "-3.617,50",
                                "column012": "0,00",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "1161",
                                        "description": "DESPESAS A APROPRIAR",
                                        "column001": "-22.615,16",
                                        "column002": "-3.617,50",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "39.132,13",
                                        "column006": "-28.231,88",
                                        "column007": "-3.741,09",
                                        "column008": "-878,58",
                                        "column009": "-3.617,50",
                                        "column010": "-310,48",
                                        "column011": "-3.617,50",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "1161007",
                                                "description": "SEGUROS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "-310,48",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1161008",
                                                "description": "CAUCAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1161011",
                                                "description": "DESPESAS APROP",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1161017",
                                                "description": "SEGURO DE SERVICO DE CREDITO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1161018",
                                                "description": "ALUGUEIS A APROPRIAR",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "30.163,04",
                                                "column006": "-23.461,48",
                                                "column007": "-123,59",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1161020",
                                                "description": "CONDOMINIO",
                                                "column001": "-22.615,16",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-2.718,61",
                                                "column006": "-1.152,90",
                                                "column007": "0,00",
                                                "column008": "-878,58",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1161023",
                                                "description": "IPTU",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "11.687,70",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1161024",
                                                "description": "DESPESA COM ESTACIONAMENTO",
                                                "column001": "0,00",
                                                "column002": "-3.617,50",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "-3.617,50",
                                                "column007": "-3.617,50",
                                                "column008": "0,00",
                                                "column009": "-3.617,50",
                                                "column010": "0,00",
                                                "column011": "-3.617,50",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "data": {
                        "id": "12",
                        "description": "REALIZAVEL A LONGO PRAZO",
                        "column001": "0,00",
                        "column002": "-2.056.852,69",
                        "column003": "0,00",
                        "column004": "0,00",
                        "column005": "0,00",
                        "column006": "-3.117,72",
                        "column007": "-1.561.986,47",
                        "column008": "-541.702,76",
                        "column009": "-2.167.763,89",
                        "column010": "-2.238.104,93",
                        "column011": "0,00",
                        "column012": "-1.486.679,56",
                        "TOTAL": "1.000,00"
                    },
                    "children": [
                        {
                            "data": {
                                "id": "121",
                                "description": "CREDITOS DIVERSOS",
                                "column001": "0,00",
                                "column002": "-1.883.124,35",
                                "column003": "0,00",
                                "column004": "0,00",
                                "column005": "0,00",
                                "column006": "-3.117,72",
                                "column007": "-1.561.986,47",
                                "column008": "-541.702,76",
                                "column009": "-2.167.763,89",
                                "column010": "-2.455.677,19",
                                "column011": "0,00",
                                "column012": "-1.376.195,22",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "1210",
                                        "description": "CREDITOS DIVERSOS",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "-424.812,84",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "12100001",
                                                "description": "KUEHNE + NAGEL SERVICOS LOGISTICOS LTDA.",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "-424.812,84",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "1211",
                                        "description": "CREDITOS DIVERSOS",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "1211010",
                                                "description": "FERTICITRUS IND E C",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1211016",
                                                "description": "FAZENDA BOQUEIRAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1211019",
                                                "description": "CESSAO CREDITO DEMU",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1211020",
                                                "description": "MUTUO - RODRIGO TEI",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1211051",
                                                "description": "TROP COMERCIO EXTER",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1211099",
                                                "description": "PROVISAO PARA PERDAS COM INVESTIMENTOS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "1213",
                                        "description": "DEPOSITOS JUDICIAIS",
                                        "column001": "0,00",
                                        "column002": "-1.883.124,35",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "-3.117,72",
                                        "column007": "-1.561.986,47",
                                        "column008": "-541.702,76",
                                        "column009": "-2.167.763,89",
                                        "column010": "-2.455.677,19",
                                        "column011": "0,00",
                                        "column012": "-951.382,38",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "1213001",
                                                "description": "DEPOSITO JUDICIAL",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1213005",
                                                "description": "DEPOSITO JUDICIAL",
                                                "column001": "0,00",
                                                "column002": "-260.339,64",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "187.605,45",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "1.110,26",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1213008",
                                                "description": "PIS - DEPOSITO JUDICIAL",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "116.873,92",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1213009",
                                                "description": "COFINS - DEPOSITO JUDICIA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "539.845,41",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1213011",
                                                "description": "I.I. - RECURSO ADM",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1213012",
                                                "description": "IPI - RECURSO ADM",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1213013",
                                                "description": "TRABALHISTA - DEP JUDICIAL",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1213014",
                                                "description": "DEPOSITO JUDICIAL - TJ ES",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1213015",
                                                "description": "DEPOSITO JUDICIAL - BANCOS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "-3.117,72",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "-8.151,08",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1213016",
                                                "description": "DEPOSITO JUDICIAL -",
                                                "column001": "0,00",
                                                "column002": "-1.139.746,11",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "-1.124.479,39",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1213017",
                                                "description": "DEPOSITO JUDICIAL - CSLL",
                                                "column001": "0,00",
                                                "column002": "-483.038,60",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "-484.732,58",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1213018",
                                                "description": "DEPOSITO JUDICIAL SUSANA COSTA LIMA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1213019",
                                                "description": "PIS REC FINANCEIRA - DEPOSITO JUDICIAL",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1213020",
                                                "description": "COFINS REC FINANCEIRA - DEPOSITO JUDICIA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1213021",
                                                "description": "PIS REC VENDAS - DEPOSITO JUDICIAL",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "-278.734,12",
                                                "column008": "-93.831,31",
                                                "column009": "-417.317,22",
                                                "column010": "-428.350,82",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1213022",
                                                "description": "COFINS REC VENDAS - DEPOSITO JUDICIAL",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "-1.283.252,35",
                                                "column008": "-447.871,45",
                                                "column009": "-1.938.052,12",
                                                "column010": "-2.019.175,29",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "123",
                                "description": "BENS NAO DESTINADOS A VENDA",
                                "column001": "0,00",
                                "column002": "-173.728,34",
                                "column003": "0,00",
                                "column004": "0,00",
                                "column005": "0,00",
                                "column006": "0,00",
                                "column007": "0,00",
                                "column008": "0,00",
                                "column009": "0,00",
                                "column010": "217.572,26",
                                "column011": "0,00",
                                "column012": "-110.484,34",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "1231",
                                        "description": "BENS NAO DESTINADOS A VENDA",
                                        "column001": "0,00",
                                        "column002": "-173.728,34",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "217.572,26",
                                        "column011": "0,00",
                                        "column012": "-110.484,34",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "1231001",
                                                "description": "BENS NAO DE USO PRO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1231002",
                                                "description": "IMOVEIS",
                                                "column001": "0,00",
                                                "column002": "-173.728,34",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "217.572,26",
                                                "column011": "0,00",
                                                "column012": "-110.484,34",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1231003",
                                                "description": "BENS NAO DE USO PRO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1231099",
                                                "description": "PROVISAO ATIVOS NAO REALIZAVEIS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "data": {
                        "id": "13",
                        "description": "PERMANENTE",
                        "column001": "0,00",
                        "column002": "-202.240,00",
                        "column003": "-4.000,00",
                        "column004": "0,00",
                        "column005": "0,00",
                        "column006": "0,00",
                        "column007": "-6.811,50",
                        "column008": "-48.841,22",
                        "column009": "-8.628,53",
                        "column010": "-50.387,54",
                        "column011": "0,00",
                        "column012": "-9.024,90",
                        "TOTAL": "1.000,00"
                    },
                    "children": [
                        {
                            "data": {
                                "id": "131",
                                "description": "INVESTIMENTOS",
                                "column001": "0,00",
                                "column002": "0,00",
                                "column003": "0,00",
                                "column004": "0,00",
                                "column005": "0,00",
                                "column006": "0,00",
                                "column007": "0,00",
                                "column008": "0,00",
                                "column009": "0,00",
                                "column010": "0,00",
                                "column011": "0,00",
                                "column012": "0,00",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "1311",
                                        "description": "PARTICIPACAO SOCIET",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "1311002",
                                                "description": "INV ELDRIDGE TRADIN",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1311006",
                                                "description": "INV TROP CIA COM EX",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1311011",
                                                "description": "INVESTIMENTO KALIUM",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1311014",
                                                "description": "COMEXPORT TRADING G",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1311015",
                                                "description": "COMEXPORT HK",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1311016",
                                                "description": "COMEXPORT SERVICOS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1311021",
                                                "description": "CXT DISTRIBUIDORA L",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "1312",
                                        "description": "OUTROS INVESTIMENTO",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "1312002",
                                                "description": "FAZENDA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "132",
                                "description": "IMOBILIZADO",
                                "column001": "0,00",
                                "column002": "-202.240,00",
                                "column003": "-4.000,00",
                                "column004": "0,00",
                                "column005": "0,00",
                                "column006": "0,00",
                                "column007": "-6.811,50",
                                "column008": "-48.841,22",
                                "column009": "-8.628,53",
                                "column010": "-50.387,54",
                                "column011": "0,00",
                                "column012": "-9.024,90",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "1321",
                                        "description": "IMOBILIZADO TECNICO",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "-4.000,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "-6.811,50",
                                        "column008": "0,00",
                                        "column009": "-8.628,53",
                                        "column010": "-46.198,64",
                                        "column011": "0,00",
                                        "column012": "-9.024,90",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "13210001",
                                                "description": "MOVEIS E UTENSILIOS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1321001",
                                                "description": "COMPUTADORES E PERIFERICO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "-4.000,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "-6.811,50",
                                                "column008": "0,00",
                                                "column009": "-1.926,50",
                                                "column010": "-46.198,64",
                                                "column011": "0,00",
                                                "column012": "-9.024,90",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1321002",
                                                "description": "MOVEIS E UTENSILIOS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1321003",
                                                "description": "VEICULOS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1321004",
                                                "description": "INSTALACOES",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1321011",
                                                "description": "SOFTWARE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1321014",
                                                "description": "INSTALACOES",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1321016",
                                                "description": "BENFEITORIAS PREDIO 3.S",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "-6.702,03",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "1322",
                                        "description": "(-)DEPRECIACOES ACUMULADAS",
                                        "column001": "0,00",
                                        "column002": "-202.240,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "-48.841,22",
                                        "column009": "0,00",
                                        "column010": "-4.188,90",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "13220001",
                                                "description": "(-) DEPR. MOVEIS E",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1322001",
                                                "description": "(-)DEPREC. ACUMUL.C.P.D.",
                                                "column001": "0,00",
                                                "column002": "-189.990,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1322002",
                                                "description": "(-)DEPREC. ACUMUL.MOV/UTE",
                                                "column001": "0,00",
                                                "column002": "-12.250,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1322003",
                                                "description": "(-)DEPREC. ACUMUL. VEICUL",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "-48.841,22",
                                                "column009": "0,00",
                                                "column010": "-4.188,90",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1322004",
                                                "description": "INSTALACOES",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1322006",
                                                "description": "(-) DEPREC.ACUML.SOFTWARE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1322014",
                                                "description": "(-) INSTALACOES",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "1323",
                                        "description": "(-)AMORTIZACOES ACUMULADAS",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "1323001",
                                                "description": "(-) AMORTIZACAO BENFEITORIAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "1323004",
                                                "description": "SOFTWARE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "data": {
                        "id": "14",
                        "description": "DIFERIDO",
                        "column001": "0,00",
                        "column002": "0,00",
                        "column003": "0,00",
                        "column004": "0,00",
                        "column005": "0,00",
                        "column006": "0,00",
                        "column007": "0,00",
                        "column008": "0,00",
                        "column009": "0,00",
                        "column010": "0,00",
                        "column011": "0,00",
                        "column012": "0,00",
                        "TOTAL": "1.000,00"
                    },
                    "children": [
                        {
                            "data": {
                                "id": "141",
                                "description": "DIFERIDO",
                                "column001": "0,00",
                                "column002": "0,00",
                                "column003": "0,00",
                                "column004": "0,00",
                                "column005": "0,00",
                                "column006": "0,00",
                                "column007": "0,00",
                                "column008": "0,00",
                                "column009": "0,00",
                                "column010": "0,00",
                                "column011": "0,00",
                                "column012": "0,00",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "1411",
                                        "description": "DIFERIDO",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "1411001",
                                                "description": "DIFERIDO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "data": {
                "id": "2",
                "description": "PASSIVO",
                "column001": "-105.949.477,20",
                "column002": "-17.721.958,41",
                "column003": "95.762.174,07",
                "column004": "0,00",
                "column005": "-25.918.667,45",
                "column006": "45.589.471,30",
                "column007": "-48.602.725,37",
                "column008": "-16.457.449,52",
                "column009": "-96.443.023,91",
                "column010": "-31.247.298,42",
                "column011": "-328.226.122,10",
                "column012": "146.068.820,17",
                "TOTAL": "1.000,00"
            },
            "children": [
                {
                    "data": {
                        "id": "21",
                        "description": "CIRCULANTE",
                        "column001": "-73.847.694,11",
                        "column002": "-26.677.749,64",
                        "column003": "95.762.174,07",
                        "column004": "0,00",
                        "column005": "-31.575.837,08",
                        "column006": "49.045.067,08",
                        "column007": "-50.327.401,92",
                        "column008": "-20.740.459,28",
                        "column009": "-99.362.961,78",
                        "column010": "-32.883.405,02",
                        "column011": "-330.184.616,05",
                        "column012": "146.414.211,87",
                        "TOTAL": "1.000,00"
                    },
                    "children": [
                        {
                            "data": {
                                "id": "211",
                                "description": "EMPREST. E FINANCIAMENTOS",
                                "column001": "-25.601.570,87",
                                "column002": "18.886.677,77",
                                "column003": "0,00",
                                "column004": "0,00",
                                "column005": "-14.728.487,14",
                                "column006": "19.422.724,66",
                                "column007": "-20.843.961,88",
                                "column008": "1.980.318,15",
                                "column009": "15.296.851,46",
                                "column010": "-3.856.190,22",
                                "column011": "-465.980,48",
                                "column012": "7.960.153,98",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "2113",
                                        "description": "EMPREST. E FINANC. NACION",
                                        "column001": "-17.139.344,38",
                                        "column002": "18.886.677,77",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "-14.725.343,17",
                                        "column006": "19.422.724,66",
                                        "column007": "-20.843.961,88",
                                        "column008": "2.198.873,27",
                                        "column009": "15.296.851,46",
                                        "column010": "-3.856.190,22",
                                        "column011": "-465.980,48",
                                        "column012": "7.960.153,98",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "2113001",
                                                "description": "MUTUO - COMEXPORT C",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2113012",
                                                "description": "MUTUO GRUNTERS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2113029",
                                                "description": "FINIMP",
                                                "column001": "-17.139.344,38",
                                                "column002": "18.886.677,77",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-14.725.343,17",
                                                "column006": "19.422.724,66",
                                                "column007": "-20.843.961,88",
                                                "column008": "2.198.873,27",
                                                "column009": "15.296.851,46",
                                                "column010": "-3.856.190,22",
                                                "column011": "-465.980,48",
                                                "column012": "7.960.153,98",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "2118",
                                        "description": "RESULTADO HEDGE",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "2118001",
                                                "description": "RESULTADO LIQUIDO HEDGE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "2119",
                                        "description": "COLIGADAS E/OU CONTROLADAS",
                                        "column001": "-8.462.226,49",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "-3.143,97",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "-218.555,12",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "2119050",
                                                "description": "COMEXPORT CIA",
                                                "column001": "-8.465.370,46",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "-218.555,12",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2119051",
                                                "description": "TROP COMERCIO EXT.",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2119053",
                                                "description": "COMEXPORT SERVICOS",
                                                "column001": "3.143,97",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-3.143,97",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "212",
                                "description": "FORNECEDORES",
                                "column001": "-19.958.542,18",
                                "column002": "11.612.288,19",
                                "column003": "23.390.048,79",
                                "column004": "0,00",
                                "column005": "27.515.652,11",
                                "column006": "28.493.616,32",
                                "column007": "41.352.641,89",
                                "column008": "-2.815.991,29",
                                "column009": "-77.832.624,53",
                                "column010": "-16.774.582,67",
                                "column011": "-290.707.584,26",
                                "column012": "198.191.896,81",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "2121",
                                        "description": "FORNECEDORES NACIONAIS",
                                        "column001": "-1.555.423,59",
                                        "column002": "-5.744.876,18",
                                        "column003": "-2.424.928,70",
                                        "column004": "0,00",
                                        "column005": "-2.997.405,44",
                                        "column006": "-2.318.422,36",
                                        "column007": "-3.121.475,47",
                                        "column008": "-3.494.301,09",
                                        "column009": "-3.300.720,01",
                                        "column010": "-6.194.927,82",
                                        "column011": "-4.294.983,79",
                                        "column012": "-5.502.817,47",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "212100000",
                                                "description": "FORNECEDORES",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "21210001",
                                                "description": "FORNECEDORES NACIONAIS",
                                                "column001": "-1.555.423,59",
                                                "column002": "-5.744.876,18",
                                                "column003": "-2.424.928,70",
                                                "column004": "0,00",
                                                "column005": "-2.997.405,44",
                                                "column006": "-2.318.422,36",
                                                "column007": "-3.121.475,47",
                                                "column008": "-3.494.301,09",
                                                "column009": "-3.296.871,87",
                                                "column010": "-6.194.991,82",
                                                "column011": "-4.166.355,73",
                                                "column012": "-5.537.802,64",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "212100042",
                                                "description": "SHIPMASTERS DO BRAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2121001",
                                                "description": "FORNECEDORES NACIONAIS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "-3.848,14",
                                                "column010": "64,00",
                                                "column011": "-128.628,06",
                                                "column012": "34.985,17",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "212102553",
                                                "description": "VALE S.A.",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2121999",
                                                "description": "FORNECEDORES DIVERSOS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "2122",
                                        "description": "FORNECEDORES ESTRANGEIROS",
                                        "column001": "-18.667.810,56",
                                        "column002": "17.027.363,01",
                                        "column003": "26.222.340,64",
                                        "column004": "0,00",
                                        "column005": "30.297.978,01",
                                        "column006": "30.615.764,89",
                                        "column007": "44.293.074,88",
                                        "column008": "308.231,21",
                                        "column009": "-74.944.021,44",
                                        "column010": "-10.937.218,09",
                                        "column011": "-286.381.151,29",
                                        "column012": "203.511.814,15",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "21220001",
                                                "description": "FORNECEDORES ESTRANGEIROS",
                                                "column001": "6.960.717,62",
                                                "column002": "-97.900.313,80",
                                                "column003": "15.629.502,61",
                                                "column004": "0,00",
                                                "column005": "26.188.246,33",
                                                "column006": "9.096.968,39",
                                                "column007": "44.293.074,88",
                                                "column008": "308.231,21",
                                                "column009": "-63.132.653,44",
                                                "column010": "156.634.314,81",
                                                "column011": "-304.447.434,47",
                                                "column012": "-213.132.983,17",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "21220002",
                                                "description": "FORNECEDORES ESTRANGEIROS",
                                                "column001": "0,00",
                                                "column002": "125.637.901,51",
                                                "column003": "10.592.838,03",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "15.261.815,78",
                                                "column012": "241.686.823,25",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "21220003",
                                                "description": "FORNECEDORES ESTRANGEIROS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "-1.620.459,43",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "21229999",
                                                "description": "(-) FINIMP",
                                                "column001": "-25.628.528,18",
                                                "column002": "-10.710.224,70",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "4.109.731,68",
                                                "column006": "21.518.796,50",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "-11.811.368,00",
                                                "column010": "-167.571.532,90",
                                                "column011": "2.804.467,40",
                                                "column012": "176.578.433,50",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "2124",
                                        "description": "SEGURO",
                                        "column001": "264.691,97",
                                        "column002": "329.801,36",
                                        "column003": "-407.363,15",
                                        "column004": "0,00",
                                        "column005": "215.079,54",
                                        "column006": "196.273,79",
                                        "column007": "181.042,48",
                                        "column008": "370.078,59",
                                        "column009": "412.116,92",
                                        "column010": "357.563,24",
                                        "column011": "-31.449,18",
                                        "column012": "182.900,13",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "2124001",
                                                "description": "SEGUROS A PAGAR",
                                                "column001": "264.691,97",
                                                "column002": "329.801,36",
                                                "column003": "-407.363,15",
                                                "column004": "0,00",
                                                "column005": "215.079,54",
                                                "column006": "196.273,79",
                                                "column007": "181.042,48",
                                                "column008": "370.078,59",
                                                "column009": "412.116,92",
                                                "column010": "357.563,24",
                                                "column011": "-31.449,18",
                                                "column012": "182.900,13",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "2125",
                                        "description": "DIVIDENDOS A PAGAR",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "2125001",
                                                "description": "DIVIDENDOS A PAGAR",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "213",
                                "description": "OBRIGACOES FISCAIS",
                                "column001": "-29.254.800,54",
                                "column002": "-59.270.878,94",
                                "column003": "71.236.696,36",
                                "column004": "0,00",
                                "column005": "-42.536.252,16",
                                "column006": "478.027,11",
                                "column007": "-69.978.276,27",
                                "column008": "-18.578.737,77",
                                "column009": "-36.502.192,78",
                                "column010": "-14.448.157,82",
                                "column011": "-36.378.201,83",
                                "column012": "-63.250.666,97",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "2131",
                                        "description": "ENCARGOS TRIBUTARIOS",
                                        "column001": "-37.937.204,93",
                                        "column002": "-36.421.491,74",
                                        "column003": "32.682.646,15",
                                        "column004": "0,00",
                                        "column005": "-30.574.482,59",
                                        "column006": "-30.842.905,19",
                                        "column007": "-28.251.911,62",
                                        "column008": "-30.361.184,87",
                                        "column009": "-22.795.616,55",
                                        "column010": "-39.360.037,23",
                                        "column011": "-54.070.543,32",
                                        "column012": "-70.471.406,75",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "2131001",
                                                "description": "P.I.S.",
                                                "column001": "-2.557.836,52",
                                                "column002": "-119.625,91",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-1.803.284,81",
                                                "column006": "-2.672.940,62",
                                                "column007": "-1.974.951,65",
                                                "column008": "-2.498.214,99",
                                                "column009": "-2.317.090,97",
                                                "column010": "-2.280.516,54",
                                                "column011": "-3.204.905,39",
                                                "column012": "-2.359.632,04",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2131002",
                                                "description": "COFINS",
                                                "column001": "-11.860.757,14",
                                                "column002": "-671.324,37",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-8.356.517,92",
                                                "column006": "-12.488.483,31",
                                                "column007": "-9.182.861,88",
                                                "column008": "-11.620.133,32",
                                                "column009": "-10.756.265,13",
                                                "column010": "-10.582.141,46",
                                                "column011": "-14.874.554,16",
                                                "column012": "-10.909.707,21",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2131003",
                                                "description": "I.S.S.",
                                                "column001": "-26.087,07",
                                                "column002": "-8.175,84",
                                                "column003": "-19.897,61",
                                                "column004": "0,00",
                                                "column005": "-32.971,41",
                                                "column006": "-13.405,17",
                                                "column007": "-24.238,10",
                                                "column008": "-25.141,48",
                                                "column009": "-24.090,10",
                                                "column010": "-16.432,27",
                                                "column011": "-205.169,43",
                                                "column012": "-61.671,40",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2131006",
                                                "description": "I.R.R.F.",
                                                "column001": "-1.505.169,03",
                                                "column002": "-416,73",
                                                "column003": "-16.979,70",
                                                "column004": "0,00",
                                                "column005": "-2.734,42",
                                                "column006": "-2.740,83",
                                                "column007": "-2.332,74",
                                                "column008": "-5.328,23",
                                                "column009": "-7.103,07",
                                                "column010": "-5.002,31",
                                                "column011": "-5.012,36",
                                                "column012": "-6.006,67",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2131007",
                                                "description": "C S L",
                                                "column001": "34.281,49",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-522.588,88",
                                                "column006": "-731.627,89",
                                                "column007": "-929.688,20",
                                                "column008": "-304.166,67",
                                                "column009": "-858.006,51",
                                                "column010": "-751.260,39",
                                                "column011": "-327.277,58",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2131008",
                                                "description": "II",
                                                "column001": "-12.789,88",
                                                "column002": "-1.991.395,82",
                                                "column003": "10.899.370,77",
                                                "column004": "0,00",
                                                "column005": "-443.157,43",
                                                "column006": "124.070,65",
                                                "column007": "150.169,25",
                                                "column008": "-34.777,65",
                                                "column009": "94.731,37",
                                                "column010": "-1.637.734,72",
                                                "column011": "1.419.495,89",
                                                "column012": "508.939,47",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2131009",
                                                "description": "I.R.P.J.",
                                                "column001": "116.632,46",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-1.449.635,79",
                                                "column006": "-2.078.739,24",
                                                "column007": "-2.553.433,77",
                                                "column008": "-842.907,42",
                                                "column009": "-2.831.351,42",
                                                "column010": "-1.684.834,42",
                                                "column011": "-1.077.104,39",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2131010",
                                                "description": "IPI S/IMPORTACAO",
                                                "column001": "-599.381,21",
                                                "column002": "-2.376.309,72",
                                                "column003": "7.707.778,99",
                                                "column004": "0,00",
                                                "column005": "-743.775,65",
                                                "column006": "692.923,32",
                                                "column007": "-469.086,56",
                                                "column008": "-50.578,07",
                                                "column009": "324.128,45",
                                                "column010": "-910.109,32",
                                                "column011": "840.145,68",
                                                "column012": "102.586,54",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213101001",
                                                "description": "ICMS A RECOLHER VITORIA",
                                                "column001": "-7.932.106,79",
                                                "column002": "-3.364.689,23",
                                                "column003": "292.389,89",
                                                "column004": "0,00",
                                                "column005": "-2.442.685,06",
                                                "column006": "-1.955.596,32",
                                                "column007": "-7.710.405,49",
                                                "column008": "-4.392.217,42",
                                                "column009": "-2.656.484,80",
                                                "column010": "-2.596.461,45",
                                                "column011": "-2.746.657,33",
                                                "column012": "-19.850.240,46",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213101002",
                                                "description": "ICMS A RECOLHER S PAULO",
                                                "column001": "-150.490,17",
                                                "column002": "-548.718,13",
                                                "column003": "-31.840,21",
                                                "column004": "0,00",
                                                "column005": "-294.673,78",
                                                "column006": "-1.166.640,94",
                                                "column007": "-359.723,20",
                                                "column008": "-70.295,84",
                                                "column009": "-140.626,42",
                                                "column010": "-90.810,53",
                                                "column011": "-361.342,45",
                                                "column012": "-303.070,84",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213101003",
                                                "description": "ICMS A RECOLHER ITAJAI",
                                                "column001": "-2.889.878,49",
                                                "column002": "-1.365.371,76",
                                                "column003": "-1.662.726,53",
                                                "column004": "0,00",
                                                "column005": "-2.344.443,82",
                                                "column006": "-903.208,96",
                                                "column007": "-1.384.878,10",
                                                "column008": "-1.282.158,27",
                                                "column009": "-1.907.400,49",
                                                "column010": "-2.015.557,21",
                                                "column011": "-1.247.528,86",
                                                "column012": "-3.256.892,29",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213101004",
                                                "description": "ICMS A RECOLHER RECIFE",
                                                "column001": "7.124.917,06",
                                                "column002": "10.866.486,95",
                                                "column003": "1.797.438,58",
                                                "column004": "0,00",
                                                "column005": "5.284.392,86",
                                                "column006": "7.557.653,48",
                                                "column007": "9.838.436,13",
                                                "column008": "9.044.958,57",
                                                "column009": "11.979.616,34",
                                                "column010": "6.452.691,03",
                                                "column011": "6.485.116,96",
                                                "column012": "8.107.582,04",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213101006",
                                                "description": "ICMS A RECOLHER CARIACICA",
                                                "column001": "0,00",
                                                "column002": "-118.368,47",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "-11.907,40",
                                                "column011": "-27.697,43",
                                                "column012": "-294.386,01",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213101009",
                                                "description": "OUTROS IMPOSTOS E TAXAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "9,86",
                                                "column006": "-9,86",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213101010",
                                                "description": "OUTROS IMPOSTOS E T",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213101012",
                                                "description": "ICMS ST RECOLHER SP",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-65.746,24",
                                                "column006": "0,00",
                                                "column007": "-51.477,85",
                                                "column008": "-61.526,03",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "-59.123,45",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213101013",
                                                "description": "ICMS ST RECOLHER ITAJAI",
                                                "column001": "-6.901.035,32",
                                                "column002": "-2.148.941,78",
                                                "column003": "-2.091.533,49",
                                                "column004": "0,00",
                                                "column005": "-2.035.059,87",
                                                "column006": "-5.631.845,56",
                                                "column007": "-6.328.737,97",
                                                "column008": "-3.559.290,57",
                                                "column009": "0,00",
                                                "column010": "-4.685.658,07",
                                                "column011": "-4.123.087,12",
                                                "column012": "-7.314.094,31",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213101021",
                                                "description": "ICMS DIFERENCIAL ALIQ VITORIA",
                                                "column001": "0,00",
                                                "column002": "-150.560,53",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "-167.973,92",
                                                "column009": "0,00",
                                                "column010": "-256,25",
                                                "column011": "-0,81",
                                                "column012": "-7.664,73",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213101022",
                                                "description": "ICMS DIF ALIQUOTA SAO PAULO",
                                                "column001": "0,00",
                                                "column002": "-105.483,50",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "-199.120,68",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "-0,54",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213101023",
                                                "description": "ICMS DIF ALIQUOTA ITAJAI",
                                                "column001": "-5.925,49",
                                                "column002": "-74.310,03",
                                                "column003": "-59,93",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "-14,96",
                                                "column007": "-15,12",
                                                "column008": "-2.157,49",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "-39.658,49",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2131011",
                                                "description": "TX SISCOMEX",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213101115",
                                                "description": "ICMS A REC TX ADM PORTUARIA PRODEAUTO",
                                                "column001": "-201.842,63",
                                                "column002": "-159.040,34",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-159.337,18",
                                                "column006": "-117.504,35",
                                                "column007": "-101.054,17",
                                                "column008": "-186.474,11",
                                                "column009": "-158.916,86",
                                                "column010": "-228.427,21",
                                                "column011": "-150.326,65",
                                                "column012": "-138.756,33",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2131012",
                                                "description": "ICMS S/IMPORTACOES",
                                                "column001": "-354,10",
                                                "column002": "-14.876,47",
                                                "column003": "-173.554,06",
                                                "column004": "0,00",
                                                "column005": "-1.763.387,25",
                                                "column006": "1.679.017,99",
                                                "column007": "-4.035,43",
                                                "column008": "12.282,80",
                                                "column009": "10.494,39",
                                                "column010": "-225.948,31",
                                                "column011": "233.670,86",
                                                "column012": "4,34",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2131015",
                                                "description": "IMPOSTOS LEI 10833/2004",
                                                "column001": "-14.458,12",
                                                "column002": "134,75",
                                                "column003": "5.991,78",
                                                "column004": "0,00",
                                                "column005": "-12.764,28",
                                                "column006": "-212,39",
                                                "column007": "-11.495,78",
                                                "column008": "1.277,39",
                                                "column009": "-4.980,15",
                                                "column010": "-5.402,81",
                                                "column011": "-4.814,26",
                                                "column012": "-8.011,34",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2131016",
                                                "description": "PIS S/IMPORT A RECOLHER",
                                                "column001": "-237.048,22",
                                                "column002": "79.138,25",
                                                "column003": "1.294.336,07",
                                                "column004": "0,00",
                                                "column005": "-343.817,28",
                                                "column006": "-15.725,26",
                                                "column007": "169.980,35",
                                                "column008": "-8.624,28",
                                                "column009": "-87.787,58",
                                                "column010": "-200.263,38",
                                                "column011": "26.720,60",
                                                "column012": "20.795,21",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2131017",
                                                "description": "COFINS S/IMPORT A RECOLHE",
                                                "column001": "-1.141.128,68",
                                                "column002": "438.005,41",
                                                "column003": "6.184.712,93",
                                                "column004": "0,00",
                                                "column005": "-1.701.839,42",
                                                "column006": "-98.503,42",
                                                "column007": "903.181,80",
                                                "column008": "-35.010,17",
                                                "column009": "-505.327,37",
                                                "column010": "-887.547,19",
                                                "column011": "24.838,92",
                                                "column012": "108.529,31",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213102001",
                                                "description": "IPI A RECOLHER VITORIA",
                                                "column001": "-6.701.129,84",
                                                "column002": "-27.486.662,83",
                                                "column003": "1.088.802,08",
                                                "column004": "0,00",
                                                "column005": "-7.831.325,73",
                                                "column006": "-8.886.295,57",
                                                "column007": "-7.080.302,80",
                                                "column008": "-10.345.720,38",
                                                "column009": "-6.847.656,62",
                                                "column010": "-10.846.365,64",
                                                "column011": "-27.011.868,35",
                                                "column012": "-30.285.639,47",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213102002",
                                                "description": "IPI A RECOLHER S PAULO",
                                                "column001": "197.441,55",
                                                "column002": "68.411,37",
                                                "column003": "120.655,26",
                                                "column004": "0,00",
                                                "column005": "-307.009,00",
                                                "column006": "-1.727.200,73",
                                                "column007": "398.834,65",
                                                "column008": "-614.377,94",
                                                "column009": "-9.809,98",
                                                "column010": "106.422,17",
                                                "column011": "-153.105,10",
                                                "column012": "-272.125,89",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213102003",
                                                "description": "IPI A RECOLHER ITAJAI",
                                                "column001": "-2.934.544,59",
                                                "column002": "-2.520.354,98",
                                                "column003": "867.494,16",
                                                "column004": "0,00",
                                                "column005": "-2.347.843,73",
                                                "column006": "-2.730.148,20",
                                                "column007": "-2.745.588,33",
                                                "column008": "-2.900.235,79",
                                                "column009": "-4.485.516,88",
                                                "column010": "-4.113.017,50",
                                                "column011": "-2.742.365,29",
                                                "column012": "-3.273.041,43",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213102004",
                                                "description": "IPI A RECOLHER RECIFE",
                                                "column001": "638.136,74",
                                                "column002": "760.371,65",
                                                "column003": "6.354.766,87",
                                                "column004": "0,00",
                                                "column005": "-1.163.983,15",
                                                "column006": "164.992,56",
                                                "column007": "1.314.746,19",
                                                "column008": "-287.815,89",
                                                "column009": "810.965,71",
                                                "column010": "-1.672.420,10",
                                                "column011": "-322.348,54",
                                                "column012": "296.169,92",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213102006",
                                                "description": "IPI A RECOLHER CARIACICA",
                                                "column001": "0,00",
                                                "column002": "-5.310.512,09",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "-140.723,84",
                                                "column010": "-692.974,32",
                                                "column011": "-4.174.657,92",
                                                "column012": "-1.232.966,05",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2131022",
                                                "description": "ICMS SUBST.TRIBUTAR",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213102201",
                                                "description": "ICMS ST RECOLHER CC49",
                                                "column001": "15.641,24",
                                                "column002": "-36.256,11",
                                                "column003": "478,04",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "11.296,74",
                                                "column007": "478,04",
                                                "column008": "596,48",
                                                "column009": "1.128,09",
                                                "column010": "6.386,43",
                                                "column011": "103.530,27",
                                                "column012": "-13.626,59",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2131029",
                                                "description": "INSS A RECOLHER TERCEIROS",
                                                "column001": "-1.921,56",
                                                "column002": "-2.490,79",
                                                "column003": "-10.767,38",
                                                "column004": "0,00",
                                                "column005": "-19.556,66",
                                                "column006": "-8.681,70",
                                                "column007": "-9.929,63",
                                                "column008": "-7.208,29",
                                                "column009": "-8.087,15",
                                                "column010": "-6.363,48",
                                                "column011": "-641,46",
                                                "column012": "-10.723,46",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2131030",
                                                "description": "ANTIDUMPING",
                                                "column001": "-203.991,93",
                                                "column002": "-53.894,75",
                                                "column003": "75.789,64",
                                                "column004": "0,00",
                                                "column005": "203.924,60",
                                                "column006": "1,46",
                                                "column007": "-0,04",
                                                "column008": "0,08",
                                                "column009": "-141.819,48",
                                                "column010": "141.819,49",
                                                "column011": "-24.039,07",
                                                "column012": "24.039,08",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213106001",
                                                "description": "PIS RF A RECOLHER",
                                                "column001": "-11.085,37",
                                                "column002": "-875,05",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "15.831,87",
                                                "column006": "8.798,17",
                                                "column007": "-25.678,27",
                                                "column008": "-2.627,59",
                                                "column009": "22.253,76",
                                                "column010": "-10.537,88",
                                                "column011": "-11.063,40",
                                                "column012": "1.865,60",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213106004",
                                                "description": "PIS A RECOLHER PRODEAUTO",
                                                "column001": "-107.075,68",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "12.070,08",
                                                "column006": "7.506,06",
                                                "column007": "19.933,63",
                                                "column008": "11.167,41",
                                                "column009": "-2.286.836,05",
                                                "column010": "0,00",
                                                "column011": "18.348,23",
                                                "column012": "7.977,71",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213107001",
                                                "description": "COFINS RF A RECOLHER",
                                                "column001": "-68.217,64",
                                                "column002": "-5.384,89",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "97.426,90",
                                                "column006": "54.142,56",
                                                "column007": "-158.020,11",
                                                "column008": "-16.169,81",
                                                "column009": "136.946,21",
                                                "column010": "-64.848,47",
                                                "column011": "-68.082,47",
                                                "column012": "11.480,61",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213107004",
                                                "description": "COFINS A RECOLHER PRODEAUTO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "86.217,10",
                                                "column007": "60.263,53",
                                                "column008": "88.784,70",
                                                "column009": "0,00",
                                                "column010": "155.526,23",
                                                "column011": "-358.760,37",
                                                "column012": "35.661,88",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213111004",
                                                "description": "IMPOSTO DE RENDA DIFERIDO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "213101024",
                                                "description": "ICMS DIF ALIQUOTA RECIFE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "-83,95",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "2132",
                                        "description": "FORNECEDORES CONTA E ORDEM",
                                        "column001": "8.682.404,39",
                                        "column002": "-22.849.387,20",
                                        "column003": "38.554.050,21",
                                        "column004": "0,00",
                                        "column005": "-11.961.769,57",
                                        "column006": "31.320.932,30",
                                        "column007": "-41.726.364,65",
                                        "column008": "11.782.447,10",
                                        "column009": "-13.706.576,23",
                                        "column010": "24.911.879,41",
                                        "column011": "17.692.341,49",
                                        "column012": "7.220.739,78",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "21320001",
                                                "description": "FORNECEDORES CONTA E ORDEM",
                                                "column001": "8.682.404,39",
                                                "column002": "-1.371.332,52",
                                                "column003": "8.690.738,52",
                                                "column004": "0,00",
                                                "column005": "-11.961.769,57",
                                                "column006": "31.320.932,30",
                                                "column007": "-41.726.364,65",
                                                "column008": "11.782.447,10",
                                                "column009": "-13.706.576,23",
                                                "column010": "24.911.879,41",
                                                "column011": "-5.905.536,43",
                                                "column012": "-2.023.069,60",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "21320002",
                                                "description": "FORNECEDORES CONTA E ORDEM",
                                                "column001": "0,00",
                                                "column002": "-21.478.054,68",
                                                "column003": "29.863.311,69",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "23.597.877,92",
                                                "column012": "9.243.809,38",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2132016",
                                                "description": "TASA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "214",
                                "description": "OBRIGAC. E ENCARGOS TRAB.",
                                "column001": "-634.766,51",
                                "column002": "-426.146,53",
                                "column003": "-183.941,48",
                                "column004": "0,00",
                                "column005": "-298.124,58",
                                "column006": "-28.361,79",
                                "column007": "-643.351,41",
                                "column008": "-421.880,85",
                                "column009": "-628.404,06",
                                "column010": "-367.176,40",
                                "column011": "-369.086,36",
                                "column012": "-138.661,70",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "2141",
                                        "description": "HONOR. E SALARIOS A",
                                        "column001": "760,02",
                                        "column002": "0,00",
                                        "column003": "-83.395,20",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "-50,61",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "2141002",
                                                "description": "SALARIOS A PAGAR",
                                                "column001": "760,02",
                                                "column002": "0,00",
                                                "column003": "-83.395,20",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "-50,61",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2141006",
                                                "description": "PLR A PAGAR",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2141004",
                                                "description": "PENSAO ALIMENTICA A PAGAR",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2141001",
                                                "description": "HONORARIOS A PAGAR",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "2142",
                                        "description": "ENCARGOS TRABALHISTAS",
                                        "column001": "-729.450,27",
                                        "column002": "-505.392,43",
                                        "column003": "-100.546,28",
                                        "column004": "0,00",
                                        "column005": "-384.814,34",
                                        "column006": "-46.852,59",
                                        "column007": "-643.996,77",
                                        "column008": "-421.880,85",
                                        "column009": "-613.890,56",
                                        "column010": "-336.977,08",
                                        "column011": "-439.964,63",
                                        "column012": "-226.984,48",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "2142001",
                                                "description": "I.N.S.S. A RECOLHER",
                                                "column001": "-445.448,57",
                                                "column002": "-370.742,15",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-282.729,39",
                                                "column006": "-19.690,42",
                                                "column007": "-330.384,62",
                                                "column008": "-318.851,17",
                                                "column009": "-363.998,06",
                                                "column010": "-331.921,63",
                                                "column011": "-340.225,12",
                                                "column012": "-314.146,10",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2142002",
                                                "description": "F.G.T.S. A RECOLHER",
                                                "column001": "-175.481,10",
                                                "column002": "-11.684,91",
                                                "column003": "-100.546,28",
                                                "column004": "0,00",
                                                "column005": "-88.728,65",
                                                "column006": "-124.541,50",
                                                "column007": "-91.391,10",
                                                "column008": "-86.280,12",
                                                "column009": "-254.073,42",
                                                "column010": "-3.436,86",
                                                "column011": "-78.737,86",
                                                "column012": "-83.921,73",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2142003",
                                                "description": "CONTRIB. SIND.A RECOLHER",
                                                "column001": "1.353,74",
                                                "column002": "-138,30",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "133,45",
                                                "column006": "43.124,24",
                                                "column007": "-29.240,63",
                                                "column008": "-17.911,45",
                                                "column009": "55,11",
                                                "column010": "-468,23",
                                                "column011": "169,76",
                                                "column012": "-2,37",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2142004",
                                                "description": "I.R.R. FONTE  A RECOLHER",
                                                "column001": "-109.874,34",
                                                "column002": "-122.827,07",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-13.489,75",
                                                "column006": "54.255,09",
                                                "column007": "-192.980,42",
                                                "column008": "1.161,89",
                                                "column009": "4.125,81",
                                                "column010": "-1.150,36",
                                                "column011": "-21.171,41",
                                                "column012": "171.085,72",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "2143",
                                        "description": "PROVISAO FERIAS E 13 SAL.",
                                        "column001": "93.923,74",
                                        "column002": "79.245,90",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "86.689,76",
                                        "column006": "18.490,80",
                                        "column007": "695,97",
                                        "column008": "0,00",
                                        "column009": "-14.513,50",
                                        "column010": "-30.199,32",
                                        "column011": "70.878,27",
                                        "column012": "88.322,78",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "2143001",
                                                "description": "PROVISAO P/ 13 SALARIO",
                                                "column001": "83.748,58",
                                                "column002": "76.631,16",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "48.120,99",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "2.508,64",
                                                "column011": "69.622,41",
                                                "column012": "81.764,42",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2143002",
                                                "description": "I.N.S.S. S/ 13 SALARIO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2143003",
                                                "description": "F.G.T.S. S/ 13 SALARIO",
                                                "column001": "0,00",
                                                "column002": "2.614,74",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "4.971,14",
                                                "column012": "6.558,36",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2143004",
                                                "description": "PROVISAO P/ FERIAS",
                                                "column001": "11.817,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "29.718,31",
                                                "column006": "26.249,02",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "-14.513,50",
                                                "column010": "-32.707,96",
                                                "column011": "-3.715,28",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2143005",
                                                "description": "I.N.S.S. S/ FERIAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "9.386,54",
                                                "column006": "-7.758,22",
                                                "column007": "695,97",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2143006",
                                                "description": "F.G.T.S. S/ FERIAS",
                                                "column001": "-1.641,84",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-536,08",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "216",
                                "description": "OUTRAS OBRIGACOES",
                                "column001": "-12.420,87",
                                "column002": "258.066,66",
                                "column003": "325.940,49",
                                "column004": "0,00",
                                "column005": "0,00",
                                "column006": "0,00",
                                "column007": "0,00",
                                "column008": "0,00",
                                "column009": "0,00",
                                "column010": "925.300,72",
                                "column011": "-605.541,84",
                                "column012": "4.485.575,30",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "2161",
                                        "description": "CONTAS A PAGAR",
                                        "column001": "-12.420,87",
                                        "column002": "258.066,66",
                                        "column003": "325.940,49",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "925.300,72",
                                        "column011": "-605.541,84",
                                        "column012": "4.485.575,30",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "21610001",
                                                "description": "OUTRAS CONTAS A PAGAR",
                                                "column001": "-15.490,01",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "5.055,24",
                                                "column011": "0,00",
                                                "column012": "6.499,51",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "21610002",
                                                "description": "FRETES A PAGAR",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2161001",
                                                "description": "OUTRAS CONTAS A PAGAR",
                                                "column001": "3.069,14",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2161002",
                                                "description": "FRETES A PAGAR",
                                                "column001": "0,00",
                                                "column002": "258.066,66",
                                                "column003": "325.940,49",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "954.875,02",
                                                "column011": "-605.541,84",
                                                "column012": "259.242,98",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2161962",
                                                "description": "JUROS SOBRE CAPITAL PROPRIO A PAGAR",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "-34.629,54",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2161963",
                                                "description": "DIVIDENDOS A PAGAR",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "4.219.832,81",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "217",
                                "description": "ADIANTAMENTOS DE CLIENTES",
                                "column001": "1.614.406,86",
                                "column002": "2.262.243,21",
                                "column003": "993.429,91",
                                "column004": "0,00",
                                "column005": "-1.528.625,31",
                                "column006": "679.060,78",
                                "column007": "-214.454,25",
                                "column008": "-904.167,52",
                                "column009": "303.408,13",
                                "column010": "1.637.401,37",
                                "column011": "-1.658.221,28",
                                "column012": "-834.085,55",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "2171",
                                        "description": "ADIAN.DE CLIENTE NACION.",
                                        "column001": "1.614.406,86",
                                        "column002": "2.230.857,07",
                                        "column003": "993.429,91",
                                        "column004": "0,00",
                                        "column005": "-1.528.625,31",
                                        "column006": "679.060,78",
                                        "column007": "-214.454,25",
                                        "column008": "-904.167,52",
                                        "column009": "303.408,13",
                                        "column010": "1.637.401,37",
                                        "column011": "-1.658.221,28",
                                        "column012": "-834.085,55",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "217100000",
                                                "description": "ADIANTAMENTO CLIENT",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "217100001",
                                                "description": "ALLIED S.A.",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "217100005",
                                                "description": "MBMB IND COM DE PRO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "217100009",
                                                "description": "ARVEDI METALFER DO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "21710001",
                                                "description": "ADIANTAMENTOS CLIENTES NACIONAIS",
                                                "column001": "1.614.406,86",
                                                "column002": "2.230.857,07",
                                                "column003": "993.429,91",
                                                "column004": "0,00",
                                                "column005": "-1.528.625,31",
                                                "column006": "679.060,78",
                                                "column007": "-214.454,25",
                                                "column008": "-904.167,52",
                                                "column009": "303.408,13",
                                                "column010": "1.637.401,37",
                                                "column011": "-1.658.221,28",
                                                "column012": "-834.085,55",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "217100012",
                                                "description": "PROMOVE CORRETORA D",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "217100018",
                                                "description": "EURO-DIESEL SISTEMA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "217100038",
                                                "description": "ALLIED S.A",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "217100039",
                                                "description": "KNAPP SUDAMERICA LO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "217107930",
                                                "description": "COMEXPORT CAMPANHA DE COMERCIO EXTERIOR",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "2172",
                                        "description": "ADIAN.DE CLIENTE EXTERIOR",
                                        "column001": "0,00",
                                        "column002": "31.386,14",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "217200004",
                                                "description": "CX TRADE AG",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "21720001",
                                                "description": "ADIANTAMENTOS CLIENTES ESTRANGEIROS",
                                                "column001": "0,00",
                                                "column002": "31.386,14",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "data": {
                        "id": "22",
                        "description": "EXIGIVEL A LONGO PRAZO",
                        "column001": "-1.918.412,67",
                        "column002": "8.955.791,23",
                        "column003": "0,00",
                        "column004": "0,00",
                        "column005": "5.657.169,63",
                        "column006": "1.382.428,14",
                        "column007": "1.724.676,55",
                        "column008": "4.283.009,76",
                        "column009": "2.919.937,87",
                        "column010": "1.636.106,60",
                        "column011": "2.072.493,95",
                        "column012": "3.575.608,30",
                        "TOTAL": "1.000,00"
                    },
                    "children": [
                        {
                            "data": {
                                "id": "221",
                                "description": "EMPRESAS COLIGADAS/CONTRO",
                                "column001": "-6.807.484,99",
                                "column002": "0,00",
                                "column003": "0,00",
                                "column004": "0,00",
                                "column005": "-100.320,38",
                                "column006": "-111.068,99",
                                "column007": "0,00",
                                "column008": "-218.555,12",
                                "column009": "-107.486,12",
                                "column010": "0,00",
                                "column011": "0,00",
                                "column012": "-383.110,78",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "2211",
                                        "description": "COLIGADAS E/OU CONTROLADAS",
                                        "column001": "-6.807.484,99",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "-100.320,38",
                                        "column006": "-111.068,99",
                                        "column007": "0,00",
                                        "column008": "-218.555,12",
                                        "column009": "-107.486,12",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "-383.110,78",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "2211050",
                                                "description": "COMEXPORT CIA COMERCIO EXTERIOR",
                                                "column001": "-6.807.484,99",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-100.320,38",
                                                "column006": "-111.068,99",
                                                "column007": "0,00",
                                                "column008": "-218.555,12",
                                                "column009": "-107.486,12",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "-383.110,78",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "222",
                                "description": "PROMITENTES CREDORES",
                                "column001": "4.889.072,32",
                                "column002": "8.955.791,23",
                                "column003": "0,00",
                                "column004": "0,00",
                                "column005": "5.757.490,01",
                                "column006": "1.493.497,13",
                                "column007": "1.724.676,55",
                                "column008": "4.501.564,88",
                                "column009": "3.027.423,99",
                                "column010": "1.636.106,60",
                                "column011": "2.072.493,95",
                                "column012": "2.133.363,78",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "2221",
                                        "description": "PROMITENTES CREDORES",
                                        "column001": "4.889.072,32",
                                        "column002": "8.955.791,23",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "5.757.490,01",
                                        "column006": "1.493.497,13",
                                        "column007": "1.724.676,55",
                                        "column008": "4.501.564,88",
                                        "column009": "3.027.423,99",
                                        "column010": "1.636.106,60",
                                        "column011": "2.072.493,95",
                                        "column012": "2.133.363,78",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "2221002",
                                                "description": "BANDES",
                                                "column001": "4.889.072,32",
                                                "column002": "8.955.791,23",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "5.757.490,01",
                                                "column006": "1.766.901,54",
                                                "column007": "1.724.676,55",
                                                "column008": "4.889.181,34",
                                                "column009": "3.289.689,20",
                                                "column010": "1.998.516,40",
                                                "column011": "2.072.493,95",
                                                "column012": "2.133.363,78",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2221005",
                                                "description": "BANCO ABC BRASIL S.A.",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "-273.404,41",
                                                "column007": "0,00",
                                                "column008": "-387.616,46",
                                                "column009": "-262.265,21",
                                                "column010": "-362.409,80",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "223",
                                "description": "OBRIGACOES FISCAIS",
                                "column001": "0,00",
                                "column002": "0,00",
                                "column003": "0,00",
                                "column004": "0,00",
                                "column005": "0,00",
                                "column006": "0,00",
                                "column007": "0,00",
                                "column008": "0,00",
                                "column009": "0,00",
                                "column010": "0,00",
                                "column011": "0,00",
                                "column012": "1.825.355,30",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "2232",
                                        "description": "OUTRAS CONTINGENCIAS",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "1.825.355,30",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "2232001",
                                                "description": "PROV. P/CONTING.TRA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2232002",
                                                "description": "PROVISAO P/CONTIGENCIAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "1.825.355,30",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "225",
                                "description": "RESULTADO EXERCICIO",
                                "column001": "0,00",
                                "column002": "0,00",
                                "column003": "0,00",
                                "column004": "0,00",
                                "column005": "0,00",
                                "column006": "0,00",
                                "column007": "0,00",
                                "column008": "0,00",
                                "column009": "0,00",
                                "column010": "0,00",
                                "column011": "0,00",
                                "column012": "0,00",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "2251",
                                        "description": "RECEITA FIN A APROP",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "2251001",
                                                "description": "RECEITA FUNDAP",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2251002",
                                                "description": "PIS CRED PRES REG E",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2251003",
                                                "description": "COFINS CRED PRES RE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "data": {
                        "id": "25",
                        "description": "PATRIMONIO  LIQUIDO",
                        "column001": "-30.183.370,42",
                        "column002": "0,00",
                        "column003": "0,00",
                        "column004": "0,00",
                        "column005": "0,00",
                        "column006": "-4.838.023,92",
                        "column007": "0,00",
                        "column008": "0,00",
                        "column009": "0,00",
                        "column010": "0,00",
                        "column011": "-114.000,00",
                        "column012": "-3.921.000,00",
                        "TOTAL": "1.000,00"
                    },
                    "children": [
                        {
                            "data": {
                                "id": "251",
                                "description": "PATRIMONIO  LIQUIDO",
                                "column001": "-30.183.370,42",
                                "column002": "0,00",
                                "column003": "0,00",
                                "column004": "0,00",
                                "column005": "0,00",
                                "column006": "-4.838.023,92",
                                "column007": "0,00",
                                "column008": "0,00",
                                "column009": "0,00",
                                "column010": "0,00",
                                "column011": "-114.000,00",
                                "column012": "-3.921.000,00",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "2511",
                                        "description": "CAPITAL SOC.INTEGRALIZADO",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "2511001",
                                                "description": "CAPITAL SOCIAL",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2511003",
                                                "description": "ACOES EM TESOURARIA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "2513",
                                        "description": "RESERVAS DE LUCROS",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "2513001",
                                                "description": "RESERVA LEGAL",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2513002",
                                                "description": "EXERCICIOS ANTERIORES",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2513004",
                                                "description": "RESERVA DE LUCROS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2513005",
                                                "description": "AJUSTE ACUMULADO DE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "2514",
                                        "description": "LUCROS/PREJUIZOS ACUMULADOS",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "2514004",
                                                "description": "EXERCICIOS ANTERIORES",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2514021",
                                                "description": "EXERCICIOS ANT 2007",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2514022",
                                                "description": "EXERCICIOS ANT 2008",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2514023",
                                                "description": "EXERCICIOS ANT 2009",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2514024",
                                                "description": "EXERCICIOS ANT 2010",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2514025",
                                                "description": "EXERCICIOS ANT 2011",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2514026",
                                                "description": "EXERCICIOS ANT 2012",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2514027",
                                                "description": "EXERCICIOS ANT 2013",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2514028",
                                                "description": "EXERCICIOS ANT 2014",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2514029",
                                                "description": "EXERCICIOS ANT 2015",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "2516",
                                        "description": "ADTO CTA RESULTADOS",
                                        "column001": "-30.183.370,42",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "-4.838.023,92",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "-114.000,00",
                                        "column012": "-3.921.000,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "2516001",
                                                "description": "DIVIDENDOS DISTRIBUIDOS",
                                                "column001": "-30.183.370,42",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "-4.838.023,92",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "-114.000,00",
                                                "column012": "-3.921.000,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2516002",
                                                "description": "TJLP DISTRIBUIDA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2516003",
                                                "description": "PARTICIPAÇÃO MINORITÁRIO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2516004",
                                                "description": "PARTICIPAÇÃO MINORITÁRIO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "2516005",
                                                "description": "PARTICIPAÇÃO MINORITÁRIO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "data": {
                "id": "3",
                "description": "CUSTOS/DESPESAS",
                "column001": "-164.336.445,87",
                "column002": "-305.263.840,99",
                "column003": "-150.084.531,85",
                "column004": "0,00",
                "column005": "-140.061.339,67",
                "column006": "-183.227.025,30",
                "column007": "-158.535.929,90",
                "column008": "-163.806.211,62",
                "column009": "-161.105.157,12",
                "column010": "-146.907.637,95",
                "column011": "-418.779.765,47",
                "column012": "-323.927.020,57",
                "TOTAL": "1.000,00"
            },
            "children": [
                {
                    "data": {
                        "id": "31",
                        "description": "CUSTOS DAS MERC. VENDIDAS",
                        "column001": "-151.251.269,92",
                        "column002": "-292.117.207,74",
                        "column003": "-146.133.627,12",
                        "column004": "0,00",
                        "column005": "-127.196.687,08",
                        "column006": "-175.725.896,35",
                        "column007": "-149.046.884,66",
                        "column008": "-151.428.103,48",
                        "column009": "-149.205.625,54",
                        "column010": "-138.532.770,51",
                        "column011": "-393.559.644,07",
                        "column012": "-314.342.184,74",
                        "TOTAL": "1.000,00"
                    },
                    "children": [
                        {
                            "data": {
                                "id": "311",
                                "description": "CUSTOS DAS MERC VENDIDAS",
                                "column001": "-151.251.269,92",
                                "column002": "-292.117.207,74",
                                "column003": "-146.133.627,12",
                                "column004": "0,00",
                                "column005": "-127.196.687,08",
                                "column006": "-175.725.896,35",
                                "column007": "-149.046.884,66",
                                "column008": "-151.428.103,48",
                                "column009": "-149.205.625,54",
                                "column010": "-138.532.770,51",
                                "column011": "-393.559.644,07",
                                "column012": "-314.342.184,74",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "3111",
                                        "description": "CUSTOS MERC VEND EXPORTACAO",
                                        "column001": "-6.021.445,62",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "-269.137,87",
                                        "column009": "-556.371,47",
                                        "column010": "0,00",
                                        "column011": "-556.370,66",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3111014",
                                                "description": "CMV EXPORTACAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3111020",
                                                "description": "CMV EXPORTACAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3111023",
                                                "description": "CMV EXPORTACAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3111024",
                                                "description": "CMV EXPORTACAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3111028",
                                                "description": "CMV EXPORTACAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3111031",
                                                "description": "CMV EXPORTACAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3111032",
                                                "description": "CMV EXPORTACAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3111033",
                                                "description": "CMV EXPORTACAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3111035",
                                                "description": "CMV EXPORTACAO",
                                                "column001": "-6.021.445,62",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "-269.137,87",
                                                "column009": "-556.371,47",
                                                "column010": "0,00",
                                                "column011": "-556.370,66",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3111038",
                                                "description": "CMV EXPORTACAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3111042",
                                                "description": "CMV EXPORTACAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "3112",
                                        "description": "CUSTO MERC VEND MERC INTERNO",
                                        "column001": "-134.706.553,42",
                                        "column002": "-282.285.230,63",
                                        "column003": "-140.033.105,06",
                                        "column004": "0,00",
                                        "column005": "-98.407.331,19",
                                        "column006": "-163.400.871,55",
                                        "column007": "-134.640.000,27",
                                        "column008": "-133.730.285,62",
                                        "column009": "-126.649.033,89",
                                        "column010": "-124.137.233,19",
                                        "column011": "-384.062.849,41",
                                        "column012": "-304.523.017,15",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3112011",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112015",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112020",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "-4.410,00",
                                                "column004": "0,00",
                                                "column005": "-2.785,32",
                                                "column006": "-17.460,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "-12.614,85",
                                                "column010": "-17.693,52",
                                                "column011": "-8.904,60",
                                                "column012": "-18.551,25",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112021",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112022",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "-1.660.518,18",
                                                "column002": "-582.737,76",
                                                "column003": "-312.775,88",
                                                "column004": "0,00",
                                                "column005": "-96.684,44",
                                                "column006": "-610.498,79",
                                                "column007": "-732.862,44",
                                                "column008": "-1.103.829,28",
                                                "column009": "-204.726,86",
                                                "column010": "-64.495,47",
                                                "column011": "-895.744,38",
                                                "column012": "-360.972,92",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112023",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "-111.409,20",
                                                "column002": "-1.747.643,00",
                                                "column003": "-431.547,85",
                                                "column004": "0,00",
                                                "column005": "-656.665,95",
                                                "column006": "-274.512,09",
                                                "column007": "-41.729,00",
                                                "column008": "-17.656,12",
                                                "column009": "-178.112,71",
                                                "column010": "-163.785,89",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112024",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "-2.712.898,39",
                                                "column002": "-6.400.305,63",
                                                "column003": "-318.029,79",
                                                "column004": "0,00",
                                                "column005": "-4.791.097,09",
                                                "column006": "-6.561.948,08",
                                                "column007": "-5.330.732,44",
                                                "column008": "-7.369.745,54",
                                                "column009": "-5.703.972,96",
                                                "column010": "-1.002.653,41",
                                                "column011": "-5.027.873,01",
                                                "column012": "-6.599.449,43",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112029",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112030",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "-924.675,25",
                                                "column002": "-497.851,71",
                                                "column003": "-190.464,76",
                                                "column004": "0,00",
                                                "column005": "-849.938,90",
                                                "column006": "-1.627.932,19",
                                                "column007": "-696.256,44",
                                                "column008": "-1.204.630,28",
                                                "column009": "-199.441,64",
                                                "column010": "-555.561,70",
                                                "column011": "-1.238.405,14",
                                                "column012": "-1.100.472,89",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112031",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "-7.839.070,07",
                                                "column002": "-9.936.169,04",
                                                "column003": "-4.813.046,11",
                                                "column004": "0,00",
                                                "column005": "-4.022.008,12",
                                                "column006": "-5.401.798,45",
                                                "column007": "-8.551.368,09",
                                                "column008": "-9.527.726,14",
                                                "column009": "-9.265.950,61",
                                                "column010": "-8.351.014,84",
                                                "column011": "-10.151.809,35",
                                                "column012": "-8.766.068,10",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112032",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112033",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112035",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "-101.154.778,95",
                                                "column002": "-233.374.204,49",
                                                "column003": "-109.140.526,28",
                                                "column004": "0,00",
                                                "column005": "-71.400.678,46",
                                                "column006": "-124.281.179,00",
                                                "column007": "-103.522.839,04",
                                                "column008": "-87.290.978,30",
                                                "column009": "-89.986.072,24",
                                                "column010": "-97.687.168,34",
                                                "column011": "-338.734.616,72",
                                                "column012": "-275.138.875,24",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112036",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112037",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112038",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112039",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112040",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112041",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112042",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112044",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112045",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112047",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112048",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112049",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "-21.244.594,11",
                                                "column002": "-30.901.944,82",
                                                "column003": "-24.822.304,39",
                                                "column004": "0,00",
                                                "column005": "-18.252.977,53",
                                                "column006": "-25.509.273,20",
                                                "column007": "-20.584.518,98",
                                                "column008": "-28.023.684,68",
                                                "column009": "-23.081.801,62",
                                                "column010": "-17.990.079,39",
                                                "column011": "-28.886.845,85",
                                                "column012": "-16.151.343,12",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112051",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112052",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112053",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112054",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112056",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112058",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112061",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112120",
                                                "description": "CUSTO DEVOLUCAO INTERNA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112122",
                                                "description": "CUSTO DEVOLUCAO INTERNA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "109.921,32",
                                                "column008": "0,00",
                                                "column009": "452.915,94",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112123",
                                                "description": "CUSTO DEVOLUCAO INTERNA",
                                                "column001": "32.049,32",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "187.208,40",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112124",
                                                "description": "CUSTO DEVOLUCAO INTERNA",
                                                "column001": "86.072,20",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "122.960,28",
                                                "column007": "3.797.364,02",
                                                "column008": "0,00",
                                                "column009": "491.734,36",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "815.632,44",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112130",
                                                "description": "CUSTO DEVOLUCAO INTERNA",
                                                "column001": "53.753,21",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "277.784,94",
                                                "column007": "0,00",
                                                "column008": "21.144,34",
                                                "column009": "0,00",
                                                "column010": "42.266,05",
                                                "column011": "113.022,46",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112131",
                                                "description": "CUSTO DEVOLUCAO INTERNA",
                                                "column001": "0,00",
                                                "column002": "567.282,40",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "58.001,30",
                                                "column006": "111.467,70",
                                                "column007": "205.845,32",
                                                "column008": "138.774,16",
                                                "column009": "145.956,41",
                                                "column010": "244.113,42",
                                                "column011": "48.595,27",
                                                "column012": "347.164,28",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112132",
                                                "description": "CUSTO DEVOLUCAO INTERNA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112135",
                                                "description": "CUSTO DEVOLUCAO INTERNA",
                                                "column001": "769.516,00",
                                                "column002": "588.343,42",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "1.607.503,32",
                                                "column006": "371.517,33",
                                                "column007": "707.175,50",
                                                "column008": "460.837,82",
                                                "column009": "893.052,89",
                                                "column010": "1.408.839,90",
                                                "column011": "719.731,91",
                                                "column012": "2.449.919,08",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112136",
                                                "description": "CUSTO DEVOLUCAO INTERNA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112137",
                                                "description": "CUSTO DEVOLUCAO INTERNA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112139",
                                                "description": "CUSTO DEVOLUCAO INTERNA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112140",
                                                "description": "CUSTO DEVOLUCAO INTERNA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112141",
                                                "description": "CUSTO DEVOLUCAO INTERNA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112147",
                                                "description": "CUSTO DEVOLUCAO INTERNA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112148",
                                                "description": "CUSTO DEVOLUCAO INTERNA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112151",
                                                "description": "CUSTO DEVOLUCAO INTERNA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112152",
                                                "description": "CUSTO DEVOLUCAO INTERNA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112161",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3112999",
                                                "description": "RECUPERACAO CUSTOS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "3113",
                                        "description": "OUTROS CUSTOS",
                                        "column001": "-2.677.741,59",
                                        "column002": "-2.941.991,08",
                                        "column003": "-2.339.494,24",
                                        "column004": "0,00",
                                        "column005": "-21.471.330,11",
                                        "column006": "-2.718.247,99",
                                        "column007": "-2.406.766,88",
                                        "column008": "-4.266.179,15",
                                        "column009": "-4.807.887,19",
                                        "column010": "-1.154.538,38",
                                        "column011": "-2.764.382,18",
                                        "column012": "-3.568.233,66",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3113001",
                                                "description": "FRETES",
                                                "column001": "-1.807.929,91",
                                                "column002": "-1.941.260,59",
                                                "column003": "-885.746,57",
                                                "column004": "0,00",
                                                "column005": "-1.276.224,71",
                                                "column006": "-1.389.977,25",
                                                "column007": "-1.473.366,60",
                                                "column008": "-1.872.674,61",
                                                "column009": "-1.551.733,82",
                                                "column010": "-749.671,96",
                                                "column011": "-1.207.146,65",
                                                "column012": "-1.267.110,15",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3113002",
                                                "description": "SEGURO DE MERCADORIAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3113003",
                                                "description": "DESPESAS EMBARQUE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3113004",
                                                "description": "DESPESAS DE DESEMBARACO",
                                                "column001": "269.985,19",
                                                "column002": "-2.208.917,37",
                                                "column003": "-1.019.279,13",
                                                "column004": "0,00",
                                                "column005": "-917.402,23",
                                                "column006": "-180.995,26",
                                                "column007": "272.930,45",
                                                "column008": "-1.240.408,93",
                                                "column009": "-2.058.528,01",
                                                "column010": "-1.287.442,90",
                                                "column011": "-1.347.825,74",
                                                "column012": "-1.318.375,90",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3113005",
                                                "description": "DESPESAS C/ARMAZENAGEM",
                                                "column001": "-563.521,43",
                                                "column002": "-639.310,83",
                                                "column003": "-386.214,64",
                                                "column004": "0,00",
                                                "column005": "-518.826,44",
                                                "column006": "-521.307,60",
                                                "column007": "-444.138,86",
                                                "column008": "-587.015,88",
                                                "column009": "-455.126,90",
                                                "column010": "-519.326,87",
                                                "column011": "68.023,40",
                                                "column012": "-769.730,27",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3113006",
                                                "description": "SOBRE ESTADIA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3113007",
                                                "description": "CUSTO PERDA DE ESTOQUE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "1.038.178,94",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3113008",
                                                "description": "DESESTIVA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3113010",
                                                "description": "AJUSTE DE ESTOQUE",
                                                "column001": "-70.177,66",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "-292,48",
                                                "column007": "-85.713,59",
                                                "column008": "-4.786,86",
                                                "column009": "-5.586,55",
                                                "column010": "-2.378,31",
                                                "column011": "-10.332,84",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3113012",
                                                "description": "OUTROS CUSTOS",
                                                "column001": "-37.591,03",
                                                "column002": "1.889.642,61",
                                                "column003": "-16.193,90",
                                                "column004": "0,00",
                                                "column005": "-83.356,66",
                                                "column006": "-54.006,60",
                                                "column007": "1.716,15",
                                                "column008": "-98.042,42",
                                                "column009": "-424.893,43",
                                                "column010": "1.409.083,66",
                                                "column011": "87.212,19",
                                                "column012": "-188.628,59",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3113013",
                                                "description": "ESCOLTA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "-3.993,32",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3113014",
                                                "description": "DESPESAS COM FUMIGACAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3113016",
                                                "description": "ANALISE DE AMOSTRAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3113017",
                                                "description": "DESP NAO REPASSADA A IMP",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "-305.531,02",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3113020",
                                                "description": "DEMURRAGE",
                                                "column001": "0,00",
                                                "column002": "-42.144,90",
                                                "column003": "-28.730,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "-4.802,00",
                                                "column011": "-44.788,20",
                                                "column012": "-23.485,59",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3113021",
                                                "description": "OUTROS CUSTOS OPERAC",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3113022",
                                                "description": "MANUTENCAO DE MAQUINAS",
                                                "column001": "-771,24",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-1.300,00",
                                                "column006": "0,00",
                                                "column007": "-1.820,00",
                                                "column008": "-800,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "-903,16",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3113025",
                                                "description": "OUTROS FRETES",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3113026",
                                                "description": "CUSTOS DOS SERVICOS PRESTADOS",
                                                "column001": "-467.735,51",
                                                "column002": "0,00",
                                                "column003": "-3.330,00",
                                                "column004": "0,00",
                                                "column005": "-267.801,26",
                                                "column006": "-571.668,80",
                                                "column007": "-676.374,43",
                                                "column008": "-462.450,45",
                                                "column009": "-312.018,48",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3113027",
                                                "description": "AJUSTE DE CUT OFF",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-19.444.597,75",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3113024",
                                                "description": "CPLTO CUSTO IMPORTACAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "3114",
                                        "description": "CMV MERCADO INTERNO",
                                        "column001": "-7.845.529,29",
                                        "column002": "-6.889.986,03",
                                        "column003": "-3.761.027,82",
                                        "column004": "0,00",
                                        "column005": "-7.318.025,78",
                                        "column006": "-9.606.776,81",
                                        "column007": "-12.000.117,51",
                                        "column008": "-13.162.500,84",
                                        "column009": "-17.192.332,99",
                                        "column010": "-13.240.998,94",
                                        "column011": "-6.176.041,82",
                                        "column012": "-6.250.933,93",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3114022",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "-60.128,97",
                                                "column009": "-62.041,80",
                                                "column010": "0,00",
                                                "column011": "-62.041,80",
                                                "column012": "-62.041,81",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3114023",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "-7.845.529,29",
                                                "column002": "-6.889.986,03",
                                                "column003": "-3.761.027,82",
                                                "column004": "0,00",
                                                "column005": "-7.318.025,78",
                                                "column006": "-9.606.776,81",
                                                "column007": "-12.000.117,51",
                                                "column008": "-13.102.371,87",
                                                "column009": "-17.130.291,19",
                                                "column010": "-13.240.998,94",
                                                "column011": "-6.114.000,02",
                                                "column012": "-6.188.892,12",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3114024",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3114031",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3114035",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3114037",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3114039",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3114047",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3114051",
                                                "description": "CMV MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3114123",
                                                "description": "CUSTO DEVOLUCAO INTER",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3114131",
                                                "description": "CUSTO DEVOLUCAO INTER",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "data": {
                        "id": "32",
                        "description": "DESPESAS OPERACIONAIS",
                        "column001": "-11.091.933,09",
                        "column002": "-11.764.938,78",
                        "column003": "-5.615.164,98",
                        "column004": "0,00",
                        "column005": "-10.209.159,89",
                        "column006": "-2.958.952,53",
                        "column007": "-9.429.755,28",
                        "column008": "-9.174.356,80",
                        "column009": "-8.953.981,69",
                        "column010": "-8.094.136,18",
                        "column011": "-22.286.415,63",
                        "column012": "-7.658.708,26",
                        "TOTAL": "1.000,00"
                    },
                    "children": [
                        {
                            "data": {
                                "id": "321",
                                "description": "DESPESAS COMERCIAIS",
                                "column001": "-258.785,39",
                                "column002": "-384.198,52",
                                "column003": "-222.737,80",
                                "column004": "0,00",
                                "column005": "-456.685,62",
                                "column006": "-804.469,94",
                                "column007": "-452.734,27",
                                "column008": "-524.096,15",
                                "column009": "-726.861,44",
                                "column010": "-240.667,37",
                                "column011": "-441.850,05",
                                "column012": "-686.461,28",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "3211",
                                        "description": "COMISSOES",
                                        "column001": "-168.702,88",
                                        "column002": "-198.690,44",
                                        "column003": "-113.843,38",
                                        "column004": "0,00",
                                        "column005": "-291.477,89",
                                        "column006": "-236.951,88",
                                        "column007": "-279.442,66",
                                        "column008": "-355.031,14",
                                        "column009": "-519.920,05",
                                        "column010": "-57.300,13",
                                        "column011": "-246.466,03",
                                        "column012": "-445.996,58",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3211001",
                                                "description": "COMISSOES S/ VENDAS EXPOR",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3211002",
                                                "description": "COMISSOES S/ VENDAS NACIO",
                                                "column001": "-153.702,88",
                                                "column002": "-181.108,94",
                                                "column003": "-97.437,88",
                                                "column004": "0,00",
                                                "column005": "-276.477,89",
                                                "column006": "-221.951,88",
                                                "column007": "-263.037,16",
                                                "column008": "-340.031,14",
                                                "column009": "-503.514,55",
                                                "column010": "-40.894,63",
                                                "column011": "-230.060,53",
                                                "column012": "-429.591,08",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3211003",
                                                "description": "ASSESSORIA COMERCIAL",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3211004",
                                                "description": "SERV TECNICOS PREST PJ",
                                                "column001": "-15.000,00",
                                                "column002": "-17.581,50",
                                                "column003": "-16.405,50",
                                                "column004": "0,00",
                                                "column005": "-15.000,00",
                                                "column006": "-15.000,00",
                                                "column007": "-16.405,50",
                                                "column008": "-15.000,00",
                                                "column009": "-16.405,50",
                                                "column010": "-16.405,50",
                                                "column011": "-16.405,50",
                                                "column012": "-16.405,50",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "3212",
                                        "description": "PROPAGANDA E PUBLICIDADE",
                                        "column001": "-90.082,51",
                                        "column002": "-185.508,08",
                                        "column003": "-108.894,42",
                                        "column004": "0,00",
                                        "column005": "-165.207,73",
                                        "column006": "-567.518,06",
                                        "column007": "-173.291,61",
                                        "column008": "-169.065,01",
                                        "column009": "-206.941,39",
                                        "column010": "-183.367,24",
                                        "column011": "-195.384,02",
                                        "column012": "-240.464,70",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3212001",
                                                "description": "AMOSTRAS",
                                                "column001": "0,00",
                                                "column002": "-2.164,97",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-1,00",
                                                "column006": "-1.666,04",
                                                "column007": "733,45",
                                                "column008": "-630,53",
                                                "column009": "-5.830,16",
                                                "column010": "-892,78",
                                                "column011": "-9.155,29",
                                                "column012": "-1.444,47",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3212002",
                                                "description": "FEIRAS E EXPOSICOES",
                                                "column001": "-39.128,70",
                                                "column002": "-9.366,45",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "-7.853,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3212003",
                                                "description": "PROPAGANDA E PUBLICIDADE",
                                                "column001": "0,00",
                                                "column002": "-29.423,46",
                                                "column003": "-5.000,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "-20.000,00",
                                                "column010": "-24.423,46",
                                                "column011": "-15.000,00",
                                                "column012": "-9.423,46",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3212004",
                                                "description": "BRINDES",
                                                "column001": "-6.231,21",
                                                "column002": "-4.362,52",
                                                "column003": "-1.990,00",
                                                "column004": "0,00",
                                                "column005": "-419,24",
                                                "column006": "-68.138,76",
                                                "column007": "-2.852,70",
                                                "column008": "-1.516,80",
                                                "column009": "-1.035,52",
                                                "column010": "-3.605,29",
                                                "column011": "-8.653,00",
                                                "column012": "-4.680,71",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3212005",
                                                "description": "CERTIFICADO QUALIDADE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3212006",
                                                "description": "REPRESENTACOES",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3212007",
                                                "description": "VIAGENS E ESTADIAS EXT",
                                                "column001": "-11.623,94",
                                                "column002": "-78.285,99",
                                                "column003": "-68.698,34",
                                                "column004": "0,00",
                                                "column005": "-97.606,97",
                                                "column006": "-138.424,06",
                                                "column007": "-85.698,52",
                                                "column008": "-70.915,52",
                                                "column009": "-101.748,34",
                                                "column010": "-73.974,89",
                                                "column011": "-84.178,32",
                                                "column012": "-146.799,99",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3212008",
                                                "description": "VIAGENS E ESTADIAS NAC",
                                                "column001": "-25.596,52",
                                                "column002": "-49.159,51",
                                                "column003": "-10.122,09",
                                                "column004": "0,00",
                                                "column005": "-49.404,43",
                                                "column006": "-97.874,00",
                                                "column007": "-66.954,19",
                                                "column008": "-77.417,11",
                                                "column009": "-66.822,96",
                                                "column010": "-61.830,14",
                                                "column011": "-66.731,51",
                                                "column012": "-62.700,83",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3212009",
                                                "description": "LANCHES E REFEICOES",
                                                "column001": "-1.452,40",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "-1.655,20",
                                                "column007": "-5.248,05",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "-1.328,35",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3212010",
                                                "description": "CONDUCAO",
                                                "column001": "-6.049,74",
                                                "column002": "-12.745,18",
                                                "column003": "-8.083,99",
                                                "column004": "0,00",
                                                "column005": "-17.776,09",
                                                "column006": "-9.760,00",
                                                "column007": "-13.271,60",
                                                "column008": "-18.585,05",
                                                "column009": "-11.504,41",
                                                "column010": "-17.312,33",
                                                "column011": "-11.665,90",
                                                "column012": "-7.562,24",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3212011",
                                                "description": "PATROCINIO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "-15.000,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "-250.000,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3212012",
                                                "description": "PROVISAO PARA CONTINGENCIA CIVEL",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "3213",
                                        "description": "OUTRAS DESPESAS COMERCIAIS",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3213001",
                                                "description": "ASSESSORIA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3213002",
                                                "description": "OUTRAS DESPESAS COMERCIAI",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "322",
                                "description": "DESPESAS COM PESSOAL",
                                "column001": "-2.108.321,90",
                                "column002": "-2.514.203,39",
                                "column003": "-230.335,56",
                                "column004": "0,00",
                                "column005": "-1.942.024,08",
                                "column006": "-3.456.188,39",
                                "column007": "-2.273.200,73",
                                "column008": "-2.285.954,93",
                                "column009": "-2.468.949,88",
                                "column010": "-2.211.291,96",
                                "column011": "-2.088.429,92",
                                "column012": "-4.348.660,62",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "3221",
                                        "description": "DESPESAS COM PESSOAL",
                                        "column001": "-1.765.001,90",
                                        "column002": "-2.138.483,39",
                                        "column003": "-230.335,56",
                                        "column004": "0,00",
                                        "column005": "-1.598.704,08",
                                        "column006": "-3.112.868,39",
                                        "column007": "-1.929.880,73",
                                        "column008": "-1.942.634,93",
                                        "column009": "-2.097.549,88",
                                        "column010": "-1.835.571,96",
                                        "column011": "-1.712.709,92",
                                        "column012": "-3.972.940,62",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3221001",
                                                "description": "PRO-LABORE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221002",
                                                "description": "HONORARIOS DA DIRETORIA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221003",
                                                "description": "SALARIOS E ORDENADOS",
                                                "column001": "-819.671,05",
                                                "column002": "-858.517,31",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-835.296,52",
                                                "column006": "-857.430,19",
                                                "column007": "-861.195,57",
                                                "column008": "-804.012,11",
                                                "column009": "-824.715,65",
                                                "column010": "-806.546,85",
                                                "column011": "-777.441,32",
                                                "column012": "-806.728,31",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221004",
                                                "description": "13 SALARIO",
                                                "column001": "-2.453,82",
                                                "column002": "-2.366,13",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-31.283,51",
                                                "column006": "-80.691,33",
                                                "column007": "-82.765,65",
                                                "column008": "-86.904,87",
                                                "column009": "-79.455,14",
                                                "column010": "-78.320,76",
                                                "column011": "-5.733,91",
                                                "column012": "-6.326,37",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221005",
                                                "description": "FERIAS",
                                                "column001": "-125.300,37",
                                                "column002": "-108.416,89",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-79.518,53",
                                                "column006": "-66.400,29",
                                                "column007": "-117.886,13",
                                                "column008": "-123.973,58",
                                                "column009": "-113.551,58",
                                                "column010": "-126.352,33",
                                                "column011": "-121.659,84",
                                                "column012": "-117.779,35",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221006",
                                                "description": "INDENIZACOES",
                                                "column001": "-1.495,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "-33.718,85",
                                                "column010": "-4.188,13",
                                                "column011": "-3.613,51",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221007",
                                                "description": "MULTA RESCISORIA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221008",
                                                "description": "I.N.S.S.",
                                                "column001": "-348.405,70",
                                                "column002": "-338.247,83",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-222.296,39",
                                                "column006": "-159.864,96",
                                                "column007": "-330.770,34",
                                                "column008": "-350.601,55",
                                                "column009": "-324.641,80",
                                                "column010": "-331.316,32",
                                                "column011": "-315.915,38",
                                                "column012": "-352.246,64",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221009",
                                                "description": "I.N.S.S S/ AUTONOMOS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221010",
                                                "description": "F.G.T.S.",
                                                "column001": "-140.488,12",
                                                "column002": "-4.731,01",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-79.077,99",
                                                "column006": "-126.534,11",
                                                "column007": "-107.537,61",
                                                "column008": "-92.349,89",
                                                "column009": "-252.437,12",
                                                "column010": "-66.407,52",
                                                "column011": "-63.421,65",
                                                "column012": "-81.660,50",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221011",
                                                "description": "PREVIDENCIA PRIVADA",
                                                "column001": "-38.290,26",
                                                "column002": "-31.915,73",
                                                "column003": "-62.955,29",
                                                "column004": "0,00",
                                                "column005": "-41.696,56",
                                                "column006": "-38.197,16",
                                                "column007": "-2.954,58",
                                                "column008": "-4.100,26",
                                                "column009": "-2.935,75",
                                                "column010": "-39.347,71",
                                                "column011": "-32.278,31",
                                                "column012": "-31.349,77",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221012",
                                                "description": "ASSISTENCIA MEDICA",
                                                "column001": "-9.764,83",
                                                "column002": "-377.363,58",
                                                "column003": "-36.512,79",
                                                "column004": "0,00",
                                                "column005": "-167.141,06",
                                                "column006": "-150.795,00",
                                                "column007": "-174.789,61",
                                                "column008": "-208.490,38",
                                                "column009": "-200.334,50",
                                                "column010": "-153.390,97",
                                                "column011": "-193.419,82",
                                                "column012": "-226.691,83",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221013",
                                                "description": "VALE TRANSPORTE",
                                                "column001": "-10.346,95",
                                                "column002": "7.585,76",
                                                "column003": "-18.473,87",
                                                "column004": "0,00",
                                                "column005": "-9.371,36",
                                                "column006": "-9.619,51",
                                                "column007": "-6.326,17",
                                                "column008": "-13.768,91",
                                                "column009": "-15.619,56",
                                                "column010": "-10.891,63",
                                                "column011": "-10.519,77",
                                                "column012": "-13.518,27",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221015",
                                                "description": "AVISO PREVIO INDENIZADO",
                                                "column001": "-28.195,96",
                                                "column002": "-2.768,83",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-7.449,56",
                                                "column006": "-25.355,77",
                                                "column007": "-21.639,71",
                                                "column008": "-6.485,68",
                                                "column009": "-83.463,40",
                                                "column010": "-51.146,92",
                                                "column011": "-10.839,19",
                                                "column012": "-8.586,45",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221016",
                                                "description": "CURSOS E SEMINARIOS",
                                                "column001": "-25.099,77",
                                                "column002": "-24.180,08",
                                                "column003": "-6.154,23",
                                                "column004": "0,00",
                                                "column005": "-20.967,65",
                                                "column006": "-20.714,68",
                                                "column007": "-20.600,33",
                                                "column008": "-19.038,07",
                                                "column009": "-19.833,14",
                                                "column010": "-39.447,98",
                                                "column011": "-35.843,36",
                                                "column012": "-22.141,92",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221017",
                                                "description": "INSS S/ PRO LABORE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221018",
                                                "description": "HORAS EXTRAS",
                                                "column001": "-77.347,18",
                                                "column002": "-5.023,27",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-7.503,61",
                                                "column006": "-7.424,07",
                                                "column007": "-10.492,18",
                                                "column008": "-89.384,17",
                                                "column009": "-6.021,33",
                                                "column010": "-5.044,17",
                                                "column011": "-8.816,83",
                                                "column012": "-106.632,61",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221019",
                                                "description": "GRATIFICACAO FUNCIONARIOS",
                                                "column001": "0,00",
                                                "column002": "-46.060,09",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221020",
                                                "description": "COMISSOES",
                                                "column001": "-25.936,80",
                                                "column002": "-11.160,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-27.518,02",
                                                "column006": "-24.536,40",
                                                "column007": "-30.351,74",
                                                "column008": "-31.322,11",
                                                "column009": "-32.523,60",
                                                "column010": "-35.563,60",
                                                "column011": "-9.104,40",
                                                "column012": "-25.000,43",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221021",
                                                "description": "INSS S/ PROV FERIAS/13",
                                                "column001": "-17.821,25",
                                                "column002": "-37.026,02",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-9.387,94",
                                                "column006": "-20.076,66",
                                                "column007": "-17.338,11",
                                                "column008": "-12.278,20",
                                                "column009": "-10.836,84",
                                                "column010": "2.365,28",
                                                "column011": "-20.843,05",
                                                "column012": "-28.045,69",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221022",
                                                "description": "FGTS S/ PROV FERIAS/13",
                                                "column001": "-6.938,41",
                                                "column002": "-8.390,33",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-7.999,32",
                                                "column006": "-4.958,87",
                                                "column007": "-6.766,95",
                                                "column008": "-5.832,94",
                                                "column009": "-5.386,23",
                                                "column010": "-627,53",
                                                "column011": "-2.550,88",
                                                "column012": "-2.954,92",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221023",
                                                "description": "VALE REFEICAO",
                                                "column001": "-80.202,10",
                                                "column002": "-91.513,04",
                                                "column003": "-106.239,38",
                                                "column004": "0,00",
                                                "column005": "-48.244,77",
                                                "column006": "-94.456,30",
                                                "column007": "-75.814,83",
                                                "column008": "-90.714,26",
                                                "column009": "-87.244,60",
                                                "column010": "-84.738,35",
                                                "column011": "-95.066,80",
                                                "column012": "-101.612,26",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221024",
                                                "description": "TEMPORARIOS",
                                                "column001": "0,00",
                                                "column002": "-10.070,74",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "-8.822,31",
                                                "column012": "-10.298,54",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221025",
                                                "description": "GREMIO-RECREACOES",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221026",
                                                "description": "ESTAGIARIOS",
                                                "column001": "-3.684,19",
                                                "column002": "-4.629,48",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-3.951,29",
                                                "column006": "-3.575,86",
                                                "column007": "-2.978,86",
                                                "column008": "-3.377,95",
                                                "column009": "-4.830,79",
                                                "column010": "-4.606,47",
                                                "column011": "-4.852,52",
                                                "column012": "-5.085,86",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221027",
                                                "description": "SEGUROS PESSOAIS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221028",
                                                "description": "PROV P/ CONTING TRABALHISTAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221029",
                                                "description": "PROG PARTICIPACAO RESULTADOS",
                                                "column001": "0,00",
                                                "column002": "-183.688,79",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "-1.422.237,23",
                                                "column007": "-59.672,36",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "-1.350.853,88",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221030",
                                                "description": "INDENIZACOES ESPECIAIS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221031",
                                                "description": "AUXILIO CRECHE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221032",
                                                "description": "CONTRIBUICAO SINDICAL",
                                                "column001": "-3.560,14",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "8.032,93",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3221099",
                                                "description": "PROVISAO PARTICIPACAO NOS LUCROS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "-675.427,02",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "3222",
                                        "description": "DESPESAS DIRETORIA",
                                        "column001": "-343.320,00",
                                        "column002": "-375.720,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "-343.320,00",
                                        "column006": "-343.320,00",
                                        "column007": "-343.320,00",
                                        "column008": "-343.320,00",
                                        "column009": "-371.400,00",
                                        "column010": "-375.720,00",
                                        "column011": "-375.720,00",
                                        "column012": "-375.720,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3222001",
                                                "description": "PRO- LABORE",
                                                "column001": "-343.320,00",
                                                "column002": "-375.720,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-343.320,00",
                                                "column006": "-343.320,00",
                                                "column007": "-343.320,00",
                                                "column008": "-343.320,00",
                                                "column009": "-371.400,00",
                                                "column010": "-375.720,00",
                                                "column011": "-375.720,00",
                                                "column012": "-375.720,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3222002",
                                                "description": "HONORARIOS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "323",
                                "description": "DESPESAS ADMINISTRATIVAS",
                                "column001": "-907.527,87",
                                "column002": "-1.241.415,93",
                                "column003": "-727.898,58",
                                "column004": "0,00",
                                "column005": "-770.479,70",
                                "column006": "-307.128,55",
                                "column007": "-826.899,68",
                                "column008": "-827.317,97",
                                "column009": "-1.004.996,32",
                                "column010": "-796.415,46",
                                "column011": "-2.199.726,43",
                                "column012": "-873.503,89",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "3231",
                                        "description": "COMUNICACOES",
                                        "column001": "-46.568,22",
                                        "column002": "-18.230,25",
                                        "column003": "-4.192,50",
                                        "column004": "0,00",
                                        "column005": "-40.247,75",
                                        "column006": "-36.341,64",
                                        "column007": "-36.630,02",
                                        "column008": "-42.518,01",
                                        "column009": "-27.267,00",
                                        "column010": "-29.244,81",
                                        "column011": "-26.727,22",
                                        "column012": "-23.264,82",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3231001",
                                                "description": "TELEFONE",
                                                "column001": "-12.163,43",
                                                "column002": "-14.803,94",
                                                "column003": "-1.315,39",
                                                "column004": "0,00",
                                                "column005": "-16.765,60",
                                                "column006": "-12.029,67",
                                                "column007": "-12.611,57",
                                                "column008": "-9.822,83",
                                                "column009": "-11.744,49",
                                                "column010": "-10.434,52",
                                                "column011": "-11.007,53",
                                                "column012": "-10.060,67",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3231003",
                                                "description": "CORREIOS",
                                                "column001": "-13.425,59",
                                                "column002": "-2.457,21",
                                                "column003": "-2.568,62",
                                                "column004": "0,00",
                                                "column005": "-14.596,17",
                                                "column006": "-14.555,04",
                                                "column007": "-14.056,99",
                                                "column008": "-14.136,81",
                                                "column009": "-9.739,29",
                                                "column010": "-8.326,29",
                                                "column011": "-8.981,69",
                                                "column012": "-7.975,81",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3231004",
                                                "description": "ENCOMENDAS E MALOTES",
                                                "column001": "-20.979,20",
                                                "column002": "-969,10",
                                                "column003": "-308,49",
                                                "column004": "0,00",
                                                "column005": "-8.885,98",
                                                "column006": "-9.756,93",
                                                "column007": "-9.961,46",
                                                "column008": "-18.558,37",
                                                "column009": "-5.783,22",
                                                "column010": "-7.499,00",
                                                "column011": "-6.738,00",
                                                "column012": "-5.228,34",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3231006",
                                                "description": "ASSINATURAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "-2.985,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "3232",
                                        "description": "MATERIAIS",
                                        "column001": "-30.191,76",
                                        "column002": "-20.107,59",
                                        "column003": "-7.090,91",
                                        "column004": "0,00",
                                        "column005": "-9.062,40",
                                        "column006": "-20.048,09",
                                        "column007": "-29.521,12",
                                        "column008": "-23.368,56",
                                        "column009": "-33.115,05",
                                        "column010": "-7.874,86",
                                        "column011": "-21.317,35",
                                        "column012": "-15.997,91",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3232001",
                                                "description": "IMPRES.E MAT.P/ESCRITORIO",
                                                "column001": "-4.287,62",
                                                "column002": "-11.585,89",
                                                "column003": "-836,10",
                                                "column004": "0,00",
                                                "column005": "-2.896,74",
                                                "column006": "-9.364,62",
                                                "column007": "-9.610,85",
                                                "column008": "-12.328,94",
                                                "column009": "-22.765,63",
                                                "column010": "557,52",
                                                "column011": "-5.945,03",
                                                "column012": "-7.239,10",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3232002",
                                                "description": "FOTOCOPIAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3232004",
                                                "description": "LIMPEZA",
                                                "column001": "-12.831,34",
                                                "column002": "-4.580,02",
                                                "column003": "-4.580,02",
                                                "column004": "0,00",
                                                "column005": "-5.933,56",
                                                "column006": "-9.260,37",
                                                "column007": "-12.169,35",
                                                "column008": "-8.343,12",
                                                "column009": "-10.170,02",
                                                "column010": "-4.580,02",
                                                "column011": "-4.580,02",
                                                "column012": "-2.896,34",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3232005",
                                                "description": "SERVICOS GRAFICOS",
                                                "column001": "-3.074,40",
                                                "column002": "-2.561,00",
                                                "column003": "-1.414,90",
                                                "column004": "0,00",
                                                "column005": "-232,10",
                                                "column006": "-1.423,10",
                                                "column007": "-1.535,30",
                                                "column008": "-1.012,50",
                                                "column009": "-179,40",
                                                "column010": "-3.092,36",
                                                "column011": "-215,30",
                                                "column012": "-1.142,88",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3232006",
                                                "description": "OUTRAS DESPESAS",
                                                "column001": "-9.998,40",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "-4.126,99",
                                                "column008": "-84,00",
                                                "column009": "0,00",
                                                "column010": "-760,00",
                                                "column011": "-10.000,00",
                                                "column012": "-1.305,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3232007",
                                                "description": "MATERIAL DE INFORMATICA - ELETRONICOS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3232008",
                                                "description": "BENS DE PEQUENO VALOR",
                                                "column001": "0,00",
                                                "column002": "-1.380,68",
                                                "column003": "-259,89",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "-2.078,63",
                                                "column008": "-1.600,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "-577,00",
                                                "column012": "-3.414,59",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "3233",
                                        "description": "SERVICOS",
                                        "column001": "-383.375,44",
                                        "column002": "-521.823,86",
                                        "column003": "-310.209,10",
                                        "column004": "0,00",
                                        "column005": "-359.746,21",
                                        "column006": "180.504,60",
                                        "column007": "-372.298,11",
                                        "column008": "-312.423,50",
                                        "column009": "-506.728,60",
                                        "column010": "-281.180,46",
                                        "column011": "-810.819,50",
                                        "column012": "-402.787,42",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3233001",
                                                "description": "ENERGIA ELETRICA",
                                                "column001": "-12.019,81",
                                                "column002": "-12.531,46",
                                                "column003": "-1.241,43",
                                                "column004": "0,00",
                                                "column005": "-12.675,33",
                                                "column006": "-13.893,79",
                                                "column007": "-11.736,95",
                                                "column008": "-11.095,34",
                                                "column009": "-10.027,96",
                                                "column010": "-9.392,13",
                                                "column011": "-9.786,23",
                                                "column012": "-10.800,43",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3233002",
                                                "description": "SEGUROS PATRIMONIAIS",
                                                "column001": "-91,63",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-91,63",
                                                "column006": "-227,17",
                                                "column007": "-91,63",
                                                "column008": "-91,63",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3233003",
                                                "description": "SERV.PREST. PESSOA FISICA",
                                                "column001": "-3.917,57",
                                                "column002": "-2.863,27",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-696,63",
                                                "column006": "-528,09",
                                                "column007": "-2.998,70",
                                                "column008": "-2.224,72",
                                                "column009": "-2.156,00",
                                                "column010": "-2.156,00",
                                                "column011": "-2.751,51",
                                                "column012": "-1.393,26",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3233004",
                                                "description": "SERV.PREST. PESSOA JURID",
                                                "column001": "-78.634,11",
                                                "column002": "-40.697,00",
                                                "column003": "-152.050,54",
                                                "column004": "0,00",
                                                "column005": "-65.188,85",
                                                "column006": "-33.973,36",
                                                "column007": "-40.866,73",
                                                "column008": "-31.472,79",
                                                "column009": "-35.237,86",
                                                "column010": "-64.792,62",
                                                "column011": "-57.909,32",
                                                "column012": "-70.570,17",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3233005",
                                                "description": "CONSERVACAO DE VEICULOS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3233006",
                                                "description": "ASSESSORIA REC.HUMANOS",
                                                "column001": "-4.654,50",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "-5.239,40",
                                                "column008": "0,00",
                                                "column009": "-12.720,32",
                                                "column010": "-13.148,16",
                                                "column011": "-5.175,40",
                                                "column012": "-5.225,83",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3233007",
                                                "description": "ESCRITORIOS REPRESENTACAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3233008",
                                                "description": "CONSERVACAO DE MOV.UTENS.",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3233009",
                                                "description": "INFORMATICA",
                                                "column001": "-61.274,44",
                                                "column002": "-200.442,38",
                                                "column003": "-31.732,28",
                                                "column004": "0,00",
                                                "column005": "-148.364,35",
                                                "column006": "-148.912,99",
                                                "column007": "-187.553,96",
                                                "column008": "-111.233,42",
                                                "column009": "-267.752,58",
                                                "column010": "-61.227,33",
                                                "column011": "-299.822,75",
                                                "column012": "-187.420,29",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3233010",
                                                "description": "SERVICOS DE ENTREGAS",
                                                "column001": "-22.522,18",
                                                "column002": "-9.281,21",
                                                "column003": "-13.977,50",
                                                "column004": "0,00",
                                                "column005": "-21.436,45",
                                                "column006": "-19.445,50",
                                                "column007": "-21.880,40",
                                                "column008": "-21.834,38",
                                                "column009": "-19.818,07",
                                                "column010": "-21.361,36",
                                                "column011": "-21.708,86",
                                                "column012": "-20.182,61",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3233011",
                                                "description": "HONORARIOS ADVOCATICIOS",
                                                "column001": "-103.363,15",
                                                "column002": "-143.827,79",
                                                "column003": "-88.683,50",
                                                "column004": "0,00",
                                                "column005": "-46.196,08",
                                                "column006": "-101.751,40",
                                                "column007": "-40.748,22",
                                                "column008": "-72.530,27",
                                                "column009": "-112.394,03",
                                                "column010": "-57.468,20",
                                                "column011": "-323.768,96",
                                                "column012": "-51.522,88",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3233012",
                                                "description": "GUARDA DE DOCUMENTOS",
                                                "column001": "-9.193,98",
                                                "column002": "-9.041,67",
                                                "column003": "-986,56",
                                                "column004": "0,00",
                                                "column005": "-8.322,19",
                                                "column006": "-9.853,90",
                                                "column007": "-9.264,14",
                                                "column008": "-9.833,09",
                                                "column009": "-9.565,30",
                                                "column010": "-9.386,57",
                                                "column011": "-18.795,04",
                                                "column012": "-8.422,33",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3233013",
                                                "description": "HONORARIOS CONTABEIS",
                                                "column001": "-14.617,50",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-6.400,00",
                                                "column006": "-6.400,00",
                                                "column007": "-6.745,42",
                                                "column008": "-6.745,42",
                                                "column009": "0,00",
                                                "column010": "-13.490,84",
                                                "column011": "-11.495,42",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3233014",
                                                "description": "AUDITORIA",
                                                "column001": "-21.541,08",
                                                "column002": "-59.620,40",
                                                "column003": "-13.450,00",
                                                "column004": "0,00",
                                                "column005": "-21.541,08",
                                                "column006": "-21.541,08",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "-15.510,20",
                                                "column012": "-6.650,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3233015",
                                                "description": "TRADUCOES",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "-698,00",
                                                "column007": "0,00",
                                                "column008": "-2.423,00",
                                                "column009": "0,00",
                                                "column010": "-565,00",
                                                "column011": "-4.027,00",
                                                "column012": "-286,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3233016",
                                                "description": "ASSESSORIA TECNICA",
                                                "column001": "-325,00",
                                                "column002": "-22.972,57",
                                                "column003": "-7.263,54",
                                                "column004": "0,00",
                                                "column005": "-7.674,81",
                                                "column006": "-2.040,07",
                                                "column007": "-1.490,07",
                                                "column008": "-7.785,32",
                                                "column009": "-11.240,21",
                                                "column010": "-7.704,03",
                                                "column011": "-24.451,74",
                                                "column012": "-18.978,89",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3233017",
                                                "description": "SERV. COBRANCA/CREDITO",
                                                "column001": "-11.816,87",
                                                "column002": "-4.509,97",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-11.821,97",
                                                "column006": "-10.939,82",
                                                "column007": "-11.234,69",
                                                "column008": "-10.517,38",
                                                "column009": "-11.976,19",
                                                "column010": "-8.632,73",
                                                "column011": "1.055,07",
                                                "column012": "-5.473,59",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3233018",
                                                "description": "CONSULTORIA TRIBUTARIA",
                                                "column001": "-20.003,12",
                                                "column002": "-823,75",
                                                "column003": "-823,75",
                                                "column004": "0,00",
                                                "column005": "-3.613,75",
                                                "column006": "-3.969,00",
                                                "column007": "-13.855,75",
                                                "column008": "-4.926,75",
                                                "column009": "-3.734,75",
                                                "column010": "-3.734,75",
                                                "column011": "-1.634,75",
                                                "column012": "-823,75",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3233019",
                                                "description": "ASSESSORIA FISCAL SPED",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3233020",
                                                "description": "DESPESA INFORMATICA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3233021",
                                                "description": "SEGURO DE RESPONSABILIDADE CIVIL",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3233022",
                                                "description": "CONSUMO DE AGUA",
                                                "column001": "-91,80",
                                                "column002": "-266,80",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-91,80",
                                                "column006": "-91,80",
                                                "column007": "-3.646,96",
                                                "column008": "-4.764,40",
                                                "column009": "-91,80",
                                                "column010": "-91,80",
                                                "column011": "-91,80",
                                                "column012": "-91,80",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3233023",
                                                "description": "SERVICOS DE LIMPEZA",
                                                "column001": "-19.308,70",
                                                "column002": "-14.945,59",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-5.631,27",
                                                "column006": "-10.698,65",
                                                "column007": "-14.945,59",
                                                "column008": "-14.945,59",
                                                "column009": "-10.013,53",
                                                "column010": "-8.028,94",
                                                "column011": "-14.945,59",
                                                "column012": "-14.945,59",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3233999",
                                                "description": "RECUPERACAO DE DESPESA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-0,02",
                                                "column006": "565.469,22",
                                                "column007": "0,50",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "3234",
                                        "description": "OCUPACAO",
                                        "column001": "-388.762,27",
                                        "column002": "-405.481,90",
                                        "column003": "-319.834,31",
                                        "column004": "0,00",
                                        "column005": "-305.694,07",
                                        "column006": "-373.803,34",
                                        "column007": "-325.521,06",
                                        "column008": "-351.724,10",
                                        "column009": "-352.669,34",
                                        "column010": "-332.491,47",
                                        "column011": "-293.269,63",
                                        "column012": "-342.540,86",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3234001",
                                                "description": "ALUGUEL",
                                                "column001": "-273.211,38",
                                                "column002": "-281.790,11",
                                                "column003": "-246.664,16",
                                                "column004": "0,00",
                                                "column005": "-259.519,81",
                                                "column006": "-258.992,56",
                                                "column007": "-253.917,39",
                                                "column008": "-248.137,64",
                                                "column009": "-271.594,45",
                                                "column010": "-257.198,41",
                                                "column011": "-218.523,91",
                                                "column012": "-258.441,74",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3234002",
                                                "description": "CONDOMINIO",
                                                "column001": "-110.701,67",
                                                "column002": "-72.101,77",
                                                "column003": "-72.220,15",
                                                "column004": "0,00",
                                                "column005": "-36.216,31",
                                                "column006": "-74.196,04",
                                                "column007": "-67.565,69",
                                                "column008": "-86.195,72",
                                                "column009": "-74.616,86",
                                                "column010": "-73.685,85",
                                                "column011": "-71.710,02",
                                                "column012": "-73.376,32",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3234003",
                                                "description": "MANUTENCAO EM EDIFICIOS",
                                                "column001": "-4.849,22",
                                                "column002": "-51.590,02",
                                                "column003": "-950,00",
                                                "column004": "0,00",
                                                "column005": "-9.957,95",
                                                "column006": "-40.614,74",
                                                "column007": "-4.037,98",
                                                "column008": "-17.390,74",
                                                "column009": "-6.458,03",
                                                "column010": "-1.607,21",
                                                "column011": "-3.035,70",
                                                "column012": "-10.722,80",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3234004",
                                                "description": "LEASING",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "3235",
                                        "description": "GERAIS",
                                        "column001": "-58.630,18",
                                        "column002": "-275.772,33",
                                        "column003": "-86.571,76",
                                        "column004": "0,00",
                                        "column005": "-55.729,27",
                                        "column006": "-57.440,08",
                                        "column007": "-62.929,37",
                                        "column008": "-97.283,80",
                                        "column009": "-85.216,33",
                                        "column010": "-145.623,86",
                                        "column011": "-1.047.592,73",
                                        "column012": "-88.912,88",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3235001",
                                                "description": "COPA E COZINHA",
                                                "column001": "-3.023,07",
                                                "column002": "-10.125,69",
                                                "column003": "-3.528,48",
                                                "column004": "0,00",
                                                "column005": "-7.488,66",
                                                "column006": "-4.052,09",
                                                "column007": "-3.673,28",
                                                "column008": "-6.493,99",
                                                "column009": "-6.216,26",
                                                "column010": "-11.751,77",
                                                "column011": "-11.793,82",
                                                "column012": "-11.440,80",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3235002",
                                                "description": "LANCHES E REFEICOES",
                                                "column001": "-5.810,50",
                                                "column002": "-7.442,03",
                                                "column003": "-2.989,51",
                                                "column004": "0,00",
                                                "column005": "-5.689,26",
                                                "column006": "-5.545,59",
                                                "column007": "-5.096,36",
                                                "column008": "-8.497,69",
                                                "column009": "-5.111,22",
                                                "column010": "-9.671,50",
                                                "column011": "-16.463,59",
                                                "column012": "-5.053,62",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3235003",
                                                "description": "CONDUCAO",
                                                "column001": "-7.043,75",
                                                "column002": "-3.553,37",
                                                "column003": "-4.052,59",
                                                "column004": "0,00",
                                                "column005": "-5.682,25",
                                                "column006": "-5.076,75",
                                                "column007": "-4.586,43",
                                                "column008": "-7.083,89",
                                                "column009": "-8.405,72",
                                                "column010": "-4.741,52",
                                                "column011": "-2.231,12",
                                                "column012": "-3.950,25",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3235005",
                                                "description": "EDITAIS E PUBLICACOES",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3235006",
                                                "description": "ASSOCIACOES DE CLASSE",
                                                "column001": "-8.302,09",
                                                "column002": "-21.334,00",
                                                "column003": "-16.258,00",
                                                "column004": "0,00",
                                                "column005": "-14.415,00",
                                                "column006": "-16.827,66",
                                                "column007": "-21.345,20",
                                                "column008": "-34.844,16",
                                                "column009": "-28.152,66",
                                                "column010": "-10.852,66",
                                                "column011": "-32.470,66",
                                                "column012": "-16.977,32",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3235007",
                                                "description": "LEGAIS",
                                                "column001": "-25.641,82",
                                                "column002": "-13.791,98",
                                                "column003": "-11.501,44",
                                                "column004": "0,00",
                                                "column005": "-21.144,77",
                                                "column006": "-17.033,43",
                                                "column007": "-16.836,67",
                                                "column008": "-17.752,80",
                                                "column009": "-20.756,75",
                                                "column010": "-15.098,62",
                                                "column011": "-911.371,86",
                                                "column012": "-10.335,53",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3235008",
                                                "description": "LIVROS",
                                                "column001": "-99,90",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "-4.500,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3235012",
                                                "description": "FARMACIA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3235013",
                                                "description": "DOACAO",
                                                "column001": "-1.559,87",
                                                "column002": "-202.660,72",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-1.149,33",
                                                "column006": "-2.874,91",
                                                "column007": "-917,88",
                                                "column008": "-4.409,47",
                                                "column009": "-8.566,75",
                                                "column010": "-17.090,22",
                                                "column011": "-397,36",
                                                "column012": "-2.816,89",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3235014",
                                                "description": "EVENTOS_RECREACAO",
                                                "column001": "-2.797,30",
                                                "column002": "-8.176,13",
                                                "column003": "-48.134,77",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "-5.940,81",
                                                "column008": "-13.701,80",
                                                "column009": "-7.835,00",
                                                "column010": "-71.237,85",
                                                "column011": "-70.454,32",
                                                "column012": "-37.887,56",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3235015",
                                                "description": "DESPESAS VEICULOS",
                                                "column001": "-734,38",
                                                "column002": "-180,00",
                                                "column003": "-40,00",
                                                "column004": "0,00",
                                                "column005": "-160,00",
                                                "column006": "-2.341,00",
                                                "column007": "-915,24",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "-120,00",
                                                "column011": "-240,00",
                                                "column012": "-160,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3235016",
                                                "description": "ANUIDADES E SUBSCRICOES",
                                                "column001": "0,00",
                                                "column002": "-200,91",
                                                "column003": "-66,97",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "-71,15",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "-156,97",
                                                "column010": "0,00",
                                                "column011": "-270,00",
                                                "column012": "-290,91",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3235017",
                                                "description": "VIAGENS E ESTADIAS EXTERIOR",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3235018",
                                                "description": "VIAGENS E ESTADIAS NACIONAIS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3235019",
                                                "description": "ESTACIONAMENTO",
                                                "column001": "-3.617,50",
                                                "column002": "-8.307,50",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "-3.617,50",
                                                "column007": "-3.617,50",
                                                "column008": "0,00",
                                                "column009": "-15,00",
                                                "column010": "-5.059,72",
                                                "column011": "-1.900,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "324",
                                "description": "DEPRECIACAO/AMORTIZACAO",
                                "column001": "-30.934,11",
                                "column002": "-35.877,69",
                                "column003": "0,00",
                                "column004": "0,00",
                                "column005": "-30.934,11",
                                "column006": "-30.934,11",
                                "column007": "-31.909,94",
                                "column008": "-34.962,22",
                                "column009": "-35.016,67",
                                "column010": "-33.590,63",
                                "column011": "-33.417,39",
                                "column012": "-32.507,34",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "3241",
                                        "description": "DEPRECIACAO/AMORTIZACAO",
                                        "column001": "-30.934,11",
                                        "column002": "-35.877,69",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "-30.934,11",
                                        "column006": "-30.934,11",
                                        "column007": "-31.909,94",
                                        "column008": "-34.962,22",
                                        "column009": "-35.016,67",
                                        "column010": "-33.590,63",
                                        "column011": "-33.417,39",
                                        "column012": "-32.507,34",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3241001",
                                                "description": "DEPRECIACAO",
                                                "column001": "-30.934,11",
                                                "column002": "-35.877,69",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-30.934,11",
                                                "column006": "-30.934,11",
                                                "column007": "-31.909,94",
                                                "column008": "-34.962,22",
                                                "column009": "-32.922,22",
                                                "column010": "-33.590,63",
                                                "column011": "-33.417,39",
                                                "column012": "-32.507,34",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3241002",
                                                "description": "AMORTIZACAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3241003",
                                                "description": "DEPRECIACAO - VEICULOS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "-2.094,45",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "325",
                                "description": "IMPOSTOS E TAXAS",
                                "column001": "-278.376,50",
                                "column002": "-1.379.026,27",
                                "column003": "-514.812,19",
                                "column004": "0,00",
                                "column005": "-128.686,46",
                                "column006": "-114.783,52",
                                "column007": "-143.118,63",
                                "column008": "-102.294,73",
                                "column009": "9.389.437,15",
                                "column010": "-78.572,96",
                                "column011": "-826.152,02",
                                "column012": "-213.037,15",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "3251",
                                        "description": "IMPOSTOS E TAXAS",
                                        "column001": "-270.666,10",
                                        "column002": "-1.379.026,27",
                                        "column003": "-514.812,19",
                                        "column004": "0,00",
                                        "column005": "-128.686,46",
                                        "column006": "-114.773,44",
                                        "column007": "-143.118,63",
                                        "column008": "-102.294,73",
                                        "column009": "9.389.437,15",
                                        "column010": "-67.977,24",
                                        "column011": "-826.152,02",
                                        "column012": "-213.037,15",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3251001",
                                                "description": "I.C.M.S. S/ AMOSTRAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "-15,12",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3251002",
                                                "description": "I.C.M.S. S/ OUTRAS SAIDAS",
                                                "column001": "-3.126,34",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-25,09",
                                                "column006": "-26.164,36",
                                                "column007": "-77.123,15",
                                                "column008": "-3.600,98",
                                                "column009": "-20.009,44",
                                                "column010": "-6.479,29",
                                                "column011": "-172,88",
                                                "column012": "-405,12",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3251003",
                                                "description": "I.P.T.U.",
                                                "column001": "-166,04",
                                                "column002": "-3.225,92",
                                                "column003": "-2.946,44",
                                                "column004": "0,00",
                                                "column005": "-11.145,89",
                                                "column006": "-13.120,74",
                                                "column007": "-9.027,22",
                                                "column008": "-10.862,80",
                                                "column009": "-227,46",
                                                "column010": "-21.305,68",
                                                "column011": "-154.089,25",
                                                "column012": "-3.225,92",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3251004",
                                                "description": "I.P.V.A.",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3251007",
                                                "description": "TAXA DE LICENCA FUNC.PUB.",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3251009",
                                                "description": "OUTROS IMPOSTOS E TAXAS",
                                                "column001": "-16.130,59",
                                                "column002": "-85.500,17",
                                                "column003": "-7.188,77",
                                                "column004": "0,00",
                                                "column005": "-6.038,33",
                                                "column006": "-23.446,43",
                                                "column007": "-4.055,38",
                                                "column008": "-3.416,10",
                                                "column009": "-16.423,05",
                                                "column010": "-23.973,70",
                                                "column011": "-89.347,51",
                                                "column012": "-41.550,28",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3251010",
                                                "description": "IPI S/ OUTRAS RECEITAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3251011",
                                                "description": "PIS S/ OUTRAS RECEITAS",
                                                "column001": "-1.210,03",
                                                "column002": "-23.789,95",
                                                "column003": "-18.528,10",
                                                "column004": "0,00",
                                                "column005": "-1.077,87",
                                                "column006": "-156,59",
                                                "column007": "17,78",
                                                "column008": "-342,54",
                                                "column009": "-1.404,67",
                                                "column010": "-3.820,40",
                                                "column011": "-18.439,50",
                                                "column012": "-9.356,84",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3251012",
                                                "description": "COFINS S/OUTRAS RECEITAS",
                                                "column001": "-5.573,45",
                                                "column002": "-109.577,85",
                                                "column003": "-85.344,15",
                                                "column004": "0,00",
                                                "column005": "-4.964,79",
                                                "column006": "-721,24",
                                                "column007": "93,03",
                                                "column008": "-1.577,79",
                                                "column009": "-6.469,93",
                                                "column010": "-17.597,00",
                                                "column011": "-84.934,31",
                                                "column012": "-43.097,96",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3251013",
                                                "description": "PROVISAO P/ACOES TRIBUTARIAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3251014",
                                                "description": "PIS S/CREDITO PRODEAUTO",
                                                "column001": "-107.075,68",
                                                "column002": "-169.682,94",
                                                "column003": "-58.784,91",
                                                "column004": "0,00",
                                                "column005": "12.070,08",
                                                "column006": "7.506,06",
                                                "column007": "19.933,63",
                                                "column008": "11.167,41",
                                                "column009": "-159.546,14",
                                                "column010": "0,00",
                                                "column011": "18.348,23",
                                                "column012": "7.977,71",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3251015",
                                                "description": "COFINS S/CREDITO PRODEAUTO",
                                                "column001": "0,00",
                                                "column002": "-781.571,68",
                                                "column003": "-270.765,39",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "86.217,10",
                                                "column007": "60.263,53",
                                                "column008": "88.784,70",
                                                "column009": "9.798.415,27",
                                                "column010": "155.526,23",
                                                "column011": "-358.760,37",
                                                "column012": "35.661,88",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3251016",
                                                "description": "TX PORTUARIA PRODEAUTO RECIFE",
                                                "column001": "-137.383,97",
                                                "column002": "-205.677,76",
                                                "column003": "-71.254,43",
                                                "column004": "0,00",
                                                "column005": "-117.504,57",
                                                "column006": "-144.887,24",
                                                "column007": "-133.205,73",
                                                "column008": "-182.446,63",
                                                "column009": "-204.897,43",
                                                "column010": "-150.327,40",
                                                "column011": "-138.756,43",
                                                "column012": "-159.040,62",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3251017",
                                                "description": "PIS S/OUTRAS SAIDAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3251018",
                                                "description": "COFINS S/OUTRAS SAI",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3251019",
                                                "description": "IPI OUTRAS SAIDAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "3252",
                                        "description": "OUTROS IMPOSTOS E TAXAS",
                                        "column001": "-7.710,40",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "-10,08",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "-10.595,72",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3252001",
                                                "description": "I.C.M.S S/ AMOSTRAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3252002",
                                                "description": "I.C.M.S S/ OUTRAS SAIDAS",
                                                "column001": "-7.710,40",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "-10,08",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3252003",
                                                "description": "I.P.T.U",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3252009",
                                                "description": "OUTROS IMPOSTOS E TAXAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "-10.595,72",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3252010",
                                                "description": "IPI",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "326",
                                "description": "DESPESAS FINANCEIRAS",
                                "column001": "-9.093.609,70",
                                "column002": "-12.510.669,07",
                                "column003": "-6.175.139,21",
                                "column004": "0,00",
                                "column005": "-8.288.038,26",
                                "column006": "-2.273.418,07",
                                "column007": "-8.866.236,27",
                                "column008": "-7.115.075,38",
                                "column009": "-15.954.061,78",
                                "column010": "-6.444.861,92",
                                "column011": "-24.849.442,08",
                                "column012": "-8.906.861,45",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "3261",
                                        "description": "DESPESAS FINANCEIRAS",
                                        "column001": "-9.529.265,81",
                                        "column002": "-10.033.998,97",
                                        "column003": "-3.176.183,06",
                                        "column004": "0,00",
                                        "column005": "-5.996.796,65",
                                        "column006": "-8.512.322,54",
                                        "column007": "-7.930.293,48",
                                        "column008": "-7.526.105,15",
                                        "column009": "-11.253.148,92",
                                        "column010": "-6.631.006,43",
                                        "column011": "-12.124.266,44",
                                        "column012": "-7.447.219,78",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3261001",
                                                "description": "JUROS INCORRIDOS",
                                                "column001": "-178.899,62",
                                                "column002": "-4.692,50",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-11.769,12",
                                                "column006": "-43.982,13",
                                                "column007": "-27.932,89",
                                                "column008": "-37.214,02",
                                                "column009": "-9.620,12",
                                                "column010": "29.275,31",
                                                "column011": "-260.406,04",
                                                "column012": "-70.909,43",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3261002",
                                                "description": "DESPESAS COMIS. BANCARIA",
                                                "column001": "-400.888,49",
                                                "column002": "-49.479,15",
                                                "column003": "-391,49",
                                                "column004": "0,00",
                                                "column005": "-34.141,57",
                                                "column006": "-67.568,37",
                                                "column007": "-20.311,35",
                                                "column008": "-115.654,08",
                                                "column009": "-31.359,47",
                                                "column010": "-98.541,78",
                                                "column011": "-94.145,28",
                                                "column012": "-64.291,53",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3261003",
                                                "description": "DESCONTOS CONCEDIDOS",
                                                "column001": "-2.400.330,45",
                                                "column002": "-129.979,74",
                                                "column003": "-10.786,13",
                                                "column004": "0,00",
                                                "column005": "-1.499.389,47",
                                                "column006": "-1.824.935,93",
                                                "column007": "-1.924.894,23",
                                                "column008": "-957.163,05",
                                                "column009": "-2.903.524,48",
                                                "column010": "-1.554.166,16",
                                                "column011": "-4.024.983,76",
                                                "column012": "-311.409,04",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3261004",
                                                "description": "MULTAS E JUROS",
                                                "column001": "-35.805,87",
                                                "column002": "-202.768,32",
                                                "column003": "-669,79",
                                                "column004": "0,00",
                                                "column005": "-4.236,27",
                                                "column006": "-47.463,56",
                                                "column007": "-49.283,74",
                                                "column008": "-19.316,53",
                                                "column009": "-8.899,36",
                                                "column010": "-9.495,63",
                                                "column011": "-313.178,08",
                                                "column012": "-55.052,94",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3261005",
                                                "description": "MULTAS(INDED.REL.P/FISCO)",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3261006",
                                                "description": "DESP.CORRETAGENS DE CAMB.",
                                                "column001": "-18.619,39",
                                                "column002": "-17.093,88",
                                                "column003": "-12.406,18",
                                                "column004": "0,00",
                                                "column005": "-16.297,94",
                                                "column006": "-18.167,41",
                                                "column007": "-18.598,87",
                                                "column008": "-20.908,22",
                                                "column009": "-18.723,38",
                                                "column010": "-19.980,61",
                                                "column011": "-16.341,16",
                                                "column012": "-19.965,99",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3261007",
                                                "description": "REC/DESP OPER FUNDAP",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3261008",
                                                "description": "CUSTO FINANCEIRO IMP",
                                                "column001": "-812.205,02",
                                                "column002": "-1.001.374,04",
                                                "column003": "-929.450,13",
                                                "column004": "0,00",
                                                "column005": "-663.710,60",
                                                "column006": "-1.813.964,54",
                                                "column007": "-100.981,84",
                                                "column008": "-219.101,50",
                                                "column009": "-295.718,45",
                                                "column010": "-548.195,57",
                                                "column011": "-890.712,01",
                                                "column012": "30.846,93",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3261009",
                                                "description": "DESPESAS DE I.O.F.",
                                                "column001": "-80.582,02",
                                                "column002": "-7.344,10",
                                                "column003": "-4.360,96",
                                                "column004": "0,00",
                                                "column005": "-7.844,86",
                                                "column006": "-31.993,83",
                                                "column007": "-36.280,34",
                                                "column008": "-14.986,01",
                                                "column009": "-13.949,64",
                                                "column010": "-23.682,08",
                                                "column011": "-48.672,02",
                                                "column012": "-41.794,58",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3261010",
                                                "description": "PERDAS OPERACOES SWAP",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3261011",
                                                "description": "JUROS S DESC DUPLICATAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3261012",
                                                "description": "TAXAS S/ REMESSAS EXTERIOR",
                                                "column001": "-1.451,67",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-927,52",
                                                "column006": "-578,82",
                                                "column007": "0,00",
                                                "column008": "-974,37",
                                                "column009": "-617,43",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3261013",
                                                "description": "DESPESA COM COBRANCA",
                                                "column001": "-2.513,13",
                                                "column002": "-432,54",
                                                "column003": "-438,95",
                                                "column004": "0,00",
                                                "column005": "-1.841,39",
                                                "column006": "-1.797,41",
                                                "column007": "-1.024,25",
                                                "column008": "-656,54",
                                                "column009": "-678,07",
                                                "column010": "-566,79",
                                                "column011": "-542,12",
                                                "column012": "-1.593,41",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3261014",
                                                "description": "IMPOSTO S OPER FINANCEIRAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3261015",
                                                "description": "SEGURO CREDITO",
                                                "column001": "-29.951,36",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-29.951,36",
                                                "column006": "-29.951,36",
                                                "column007": "-29.951,36",
                                                "column008": "-29.951,36",
                                                "column009": "-70.624,37",
                                                "column010": "-70.624,37",
                                                "column011": "-158.833,37",
                                                "column012": "-45.992,19",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3261016",
                                                "description": "SERV ANALISE CRED.",
                                                "column001": "-15.600,00",
                                                "column002": "-9.300,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-4.600,00",
                                                "column006": "-40.830,98",
                                                "column007": "-77.313,31",
                                                "column008": "0,00",
                                                "column009": "-3.800,00",
                                                "column010": "-6.000,00",
                                                "column011": "-8.000,00",
                                                "column012": "-17.000,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3261017",
                                                "description": "JUROS E MULTAS SOBRE IMPOSTOS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "-107.755,44",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3261018",
                                                "description": "CUSTO FINANCEIRO IMP",
                                                "column001": "540.467,00",
                                                "column002": "1.866.472,31",
                                                "column003": "523.467,13",
                                                "column004": "0,00",
                                                "column005": "755.274,79",
                                                "column006": "1.679.086,59",
                                                "column007": "1.725.769,00",
                                                "column008": "1.386.044,92",
                                                "column009": "1.411.903,40",
                                                "column010": "1.983.885,09",
                                                "column011": "1.919.000,45",
                                                "column012": "30.901,40",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3261019",
                                                "description": "IR S/REMESSA AO EXTERIOR",
                                                "column001": "-229.648,91",
                                                "column002": "-62.143,04",
                                                "column003": "-984,09",
                                                "column004": "0,00",
                                                "column005": "-13.093,56",
                                                "column006": "-38.158,74",
                                                "column007": "-22.985,96",
                                                "column008": "-48.857,84",
                                                "column009": "-13.497,23",
                                                "column010": "-44.872,39",
                                                "column011": "-168.154,16",
                                                "column012": "-45.078,07",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3261020",
                                                "description": "(-) DISTRIBUICAO TJ",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3261022",
                                                "description": "JUROS FINIMP",
                                                "column001": "-50.897,54",
                                                "column002": "-159.866,10",
                                                "column003": "-8.395,51",
                                                "column004": "0,00",
                                                "column005": "-39.690,93",
                                                "column006": "-68.418,74",
                                                "column007": "-77.930,52",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "-15.111,10",
                                                "column011": "-170.101,78",
                                                "column012": "-162.734,91",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3261024",
                                                "description": "RESULTADO FIDIC",
                                                "column001": "-242.849,16",
                                                "column002": "-523.177,74",
                                                "column003": "-148.464,58",
                                                "column004": "0,00",
                                                "column005": "-214.971,47",
                                                "column006": "-786.226,97",
                                                "column007": "-408.814,17",
                                                "column008": "-505.700,85",
                                                "column009": "-436.395,92",
                                                "column010": "-496.115,40",
                                                "column011": "-477.277,55",
                                                "column012": "-361.284,84",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3261025",
                                                "description": "DESP OPER PRODEAUTO",
                                                "column001": "-5.569.490,18",
                                                "column002": "-9.052.727,35",
                                                "column003": "-2.583.346,93",
                                                "column004": "0,00",
                                                "column005": "-4.209.605,38",
                                                "column006": "-5.269.614,90",
                                                "column007": "-6.859.759,65",
                                                "column008": "-6.941.665,70",
                                                "column009": "-8.857.644,40",
                                                "column010": "-5.564.617,97",
                                                "column011": "-5.564.434,65",
                                                "column012": "-5.955.212,91",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3261100",
                                                "description": "DESCONTO FINANCEIRO",
                                                "column001": "0,00",
                                                "column002": "-680.092,78",
                                                "column003": "44,55",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "-192.196,98",
                                                "column011": "-1.847.484,91",
                                                "column012": "-356.648,27",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "3262",
                                        "description": "VAR.MONET./CAMBIAIS PASS.",
                                        "column001": "435.656,11",
                                        "column002": "-2.476.670,10",
                                        "column003": "-2.998.956,15",
                                        "column004": "0,00",
                                        "column005": "-2.291.241,61",
                                        "column006": "6.238.904,47",
                                        "column007": "-935.942,79",
                                        "column008": "411.029,77",
                                        "column009": "-4.700.912,86",
                                        "column010": "186.144,51",
                                        "column011": "-12.725.175,64",
                                        "column012": "-1.459.641,67",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3262001",
                                                "description": "VARIACOES MONET. PASSIVAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3262002",
                                                "description": "VARIACAO CAMBIAL PASSIVA",
                                                "column001": "1.297.631,35",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-2.061.742,07",
                                                "column006": "7.178.191,30",
                                                "column007": "-296.767,84",
                                                "column008": "1.492.098,73",
                                                "column009": "1.270.858,09",
                                                "column010": "2.448.964,58",
                                                "column011": "-11.329.234,14",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3262003",
                                                "description": "VAR. MON. S/ IMPOSTOS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3262004",
                                                "description": "VARIAC CAMB PASSIVA REALI",
                                                "column001": "-861.975,24",
                                                "column002": "-2.476.670,10",
                                                "column003": "-2.998.956,15",
                                                "column004": "0,00",
                                                "column005": "-229.499,54",
                                                "column006": "-939.286,83",
                                                "column007": "-639.174,95",
                                                "column008": "-1.081.068,96",
                                                "column009": "-5.971.770,95",
                                                "column010": "-2.262.820,07",
                                                "column011": "-1.395.941,50",
                                                "column012": "-1.459.641,67",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3262007",
                                                "description": "VARICAO CAMBIAL NAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "327",
                                "description": "OUTRAS REC/DESP.OPERAC.",
                                "column001": "1.599.505,44",
                                "column002": "6.344.568,62",
                                "column003": "2.300.457,94",
                                "column004": "0,00",
                                "column005": "1.414.045,70",
                                "column006": "4.199.891,88",
                                "column007": "3.216.242,96",
                                "column008": "1.746.426,50",
                                "column009": "1.910.024,36",
                                "column010": "1.850.974,34",
                                "column011": "8.182.886,28",
                                "column012": "7.433.963,41",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "3271",
                                        "description": "OUTRAS REC. OPERACIONAIS",
                                        "column001": "1.599.505,44",
                                        "column002": "6.344.568,62",
                                        "column003": "2.300.457,94",
                                        "column004": "0,00",
                                        "column005": "1.414.045,70",
                                        "column006": "4.199.891,88",
                                        "column007": "3.216.242,96",
                                        "column008": "1.746.426,50",
                                        "column009": "1.910.024,36",
                                        "column010": "1.850.974,34",
                                        "column011": "8.182.886,28",
                                        "column012": "7.433.963,41",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3271003",
                                                "description": "OUTRAS RECEITAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3271005",
                                                "description": "RESULTADO OPERACAO FUNDAP",
                                                "column001": "1.599.505,44",
                                                "column002": "6.344.568,62",
                                                "column003": "2.300.457,94",
                                                "column004": "0,00",
                                                "column005": "1.414.045,70",
                                                "column006": "4.199.891,88",
                                                "column007": "3.216.242,96",
                                                "column008": "1.746.426,50",
                                                "column009": "1.910.024,36",
                                                "column010": "1.850.974,34",
                                                "column011": "8.182.886,28",
                                                "column012": "7.433.963,41",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "328",
                                "description": "OUTRAS REC/DESP OPERACIONAIS",
                                "column001": "-13.883,06",
                                "column002": "-44.116,53",
                                "column003": "-44.699,58",
                                "column004": "0,00",
                                "column005": "-6.357,36",
                                "column006": "-171.921,83",
                                "column007": "-51.898,72",
                                "column008": "-31.081,92",
                                "column009": "-63.557,11",
                                "column010": "-139.710,22",
                                "column011": "-30.284,02",
                                "column012": "-31.639,94",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "3281",
                                        "description": "OUTRAS DESPESAS",
                                        "column001": "-13.883,06",
                                        "column002": "-44.116,53",
                                        "column003": "-44.699,58",
                                        "column004": "0,00",
                                        "column005": "-6.357,36",
                                        "column006": "-171.921,83",
                                        "column007": "-51.898,72",
                                        "column008": "-31.081,92",
                                        "column009": "-63.557,11",
                                        "column010": "-139.710,22",
                                        "column011": "-30.284,02",
                                        "column012": "-31.639,94",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3281001",
                                                "description": "PERDAS EVENTUAIS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3281002",
                                                "description": "DESPESAS INDEDUTIVEIS",
                                                "column001": "-13.883,06",
                                                "column002": "-44.116,53",
                                                "column003": "-44.699,58",
                                                "column004": "0,00",
                                                "column005": "-6.357,36",
                                                "column006": "-171.921,83",
                                                "column007": "-51.898,72",
                                                "column008": "-31.081,92",
                                                "column009": "-63.557,11",
                                                "column010": "-139.710,22",
                                                "column011": "-30.284,02",
                                                "column012": "-31.639,94",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "3282",
                                        "description": "OUTRAS RECEITAS",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3282001",
                                                "description": "RECUPERACAO DESPESAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "data": {
                        "id": "34",
                        "description": "REC./DESP.NAO OPERACIONAL",
                        "column001": "-21.018,19",
                        "column002": "123.722,68",
                        "column003": "1.664.260,25",
                        "column004": "0,00",
                        "column005": "13.692,52",
                        "column006": "-1.068.786,49",
                        "column007": "1.087.784,13",
                        "column008": "35.994,46",
                        "column009": "-109.455,08",
                        "column010": "953.650,71",
                        "column011": "-798.539,67",
                        "column012": "-409.694,86",
                        "TOTAL": "1.000,00"
                    },
                    "children": [
                        {
                            "data": {
                                "id": "341",
                                "description": "GANHOS E PERDAS NAO OPER.",
                                "column001": "0,00",
                                "column002": "0,00",
                                "column003": "0,00",
                                "column004": "0,00",
                                "column005": "0,00",
                                "column006": "0,00",
                                "column007": "0,00",
                                "column008": "0,00",
                                "column009": "0,00",
                                "column010": "-121.478,09",
                                "column011": "0,00",
                                "column012": "0,00",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "3411",
                                        "description": "LUC./PREJ.PART.OUTR",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3411002",
                                                "description": "DIVIDENDOS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "3412",
                                        "description": "GAN./PERDAS ATIVO PERMAN.",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3412001",
                                                "description": "GANHOS/PERDAS VENDA IMOB.",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3412002",
                                                "description": "GANHOS/PERDAS EM INVEST.",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "3413",
                                        "description": "OUTRAS DESP./REC.NAO OPER",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "-121.478,09",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3413001",
                                                "description": "LIQUIDACAO DE SINISTROS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3413004",
                                                "description": "OUTRAS RECEITAS NAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3413005",
                                                "description": "CUSTO DE VENDA DE ATIVO IMOBILIZADO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "-121.478,09",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "342",
                                "description": "RESULT.PARTIC.SOCIE",
                                "column001": "0,00",
                                "column002": "0,00",
                                "column003": "0,00",
                                "column004": "0,00",
                                "column005": "0,00",
                                "column006": "0,00",
                                "column007": "0,00",
                                "column008": "0,00",
                                "column009": "0,00",
                                "column010": "0,00",
                                "column011": "0,00",
                                "column012": "0,00",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "3421",
                                        "description": "RESULT.PARTIC.SOCIE",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3421001",
                                                "description": "RESULT.EQUIV.PATRIM",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3421002",
                                                "description": "EQUIVALENCIA ELDRID",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3421003",
                                                "description": "(-) EQUIV ELDRIDGE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3421006",
                                                "description": "RESULTADO EQUIVAL T",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3421007",
                                                "description": "PERDAS P/DISTRIB DI",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3421011",
                                                "description": "RESULTADO EQUIV KAL",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3421012",
                                                "description": "PERDAS P/DISTRIB DI",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3421014",
                                                "description": "RESULTADO EQUIV GMB",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3421016",
                                                "description": "RESULTADO EQUIV COM",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3421017",
                                                "description": "RESULTADO EQUIV CXT",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3421018",
                                                "description": "RESULTADOS COM INVE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "3422",
                                        "description": "DISTRIB.DESPROPORCI",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3422006",
                                                "description": "DISTRUBUICAO DESPRO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3422011",
                                                "description": "DISTRUBUICAO DESPRO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3422016",
                                                "description": "DISTRUBUICAO DESPRO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "343",
                                "description": "PROVISAO DEV. DUVIDOSOS",
                                "column001": "-21.018,19",
                                "column002": "123.722,68",
                                "column003": "1.664.260,25",
                                "column004": "0,00",
                                "column005": "13.692,52",
                                "column006": "-1.068.786,49",
                                "column007": "1.087.784,13",
                                "column008": "35.994,46",
                                "column009": "-109.455,08",
                                "column010": "1.075.128,80",
                                "column011": "-798.539,67",
                                "column012": "-409.694,86",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "3431",
                                        "description": "PROVISAO DEV. DUVIDOSOS",
                                        "column001": "-21.018,19",
                                        "column002": "123.722,68",
                                        "column003": "1.664.260,25",
                                        "column004": "0,00",
                                        "column005": "13.692,52",
                                        "column006": "-1.068.786,49",
                                        "column007": "1.087.784,13",
                                        "column008": "35.994,46",
                                        "column009": "-109.455,08",
                                        "column010": "1.075.128,80",
                                        "column011": "-798.539,67",
                                        "column012": "-409.694,86",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3431001",
                                                "description": "PROVISAO DEV. DUVIDOSOS",
                                                "column001": "-21.618,19",
                                                "column002": "123.265,46",
                                                "column003": "1.664.260,25",
                                                "column004": "0,00",
                                                "column005": "13.535,30",
                                                "column006": "-1.069.543,71",
                                                "column007": "1.087.784,13",
                                                "column008": "35.694,46",
                                                "column009": "-109.755,08",
                                                "column010": "1.073.390,17",
                                                "column011": "17.231,81",
                                                "column012": "-412.752,36",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3431002",
                                                "description": "PROVISAO DEV DUVIDOSOS REALIZADA",
                                                "column001": "600,00",
                                                "column002": "457,22",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "157,22",
                                                "column006": "757,22",
                                                "column007": "0,00",
                                                "column008": "300,00",
                                                "column009": "300,00",
                                                "column010": "1.738,63",
                                                "column011": "-815.771,48",
                                                "column012": "3.057,50",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "data": {
                        "id": "35",
                        "description": "PROVISOES",
                        "column001": "-1.972.224,67",
                        "column002": "-1.505.417,15",
                        "column003": "0,00",
                        "column004": "0,00",
                        "column005": "-2.669.185,22",
                        "column006": "-3.473.389,93",
                        "column007": "-1.147.074,09",
                        "column008": "-3.239.745,80",
                        "column009": "-2.836.094,81",
                        "column010": "-1.234.381,97",
                        "column011": "-2.135.166,10",
                        "column012": "-1.516.432,71",
                        "TOTAL": "1.000,00"
                    },
                    "children": [
                        {
                            "data": {
                                "id": "351",
                                "description": "PROV.P/CONTR.SOC S/ LUCRO",
                                "column001": "-522.588,88",
                                "column002": "-399.022,19",
                                "column003": "0,00",
                                "column004": "0,00",
                                "column005": "-707.078,44",
                                "column006": "-919.956,16",
                                "column007": "-304.166,67",
                                "column008": "-858.109,18",
                                "column009": "-751.260,39",
                                "column010": "-327.277,58",
                                "column011": "-565.720,44",
                                "column012": "-401.938,07",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "3511",
                                        "description": "PROV.P/CONTR.SOC S/ LUCRO",
                                        "column001": "-522.588,88",
                                        "column002": "-399.022,19",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "-707.078,44",
                                        "column006": "-919.956,16",
                                        "column007": "-304.166,67",
                                        "column008": "-858.109,18",
                                        "column009": "-751.260,39",
                                        "column010": "-327.277,58",
                                        "column011": "-565.720,44",
                                        "column012": "-401.938,07",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3511001",
                                                "description": "CONTRIB SOCIAL S/ LUCRO",
                                                "column001": "-522.588,88",
                                                "column002": "-399.022,19",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-707.078,44",
                                                "column006": "-919.956,16",
                                                "column007": "-304.166,67",
                                                "column008": "-858.109,18",
                                                "column009": "-751.260,39",
                                                "column010": "-327.277,58",
                                                "column011": "-565.720,44",
                                                "column012": "-401.938,07",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "352",
                                "description": "PROVISAO P/ IMPOSTO RENDA",
                                "column001": "-1.449.635,79",
                                "column002": "-1.106.394,96",
                                "column003": "0,00",
                                "column004": "0,00",
                                "column005": "-1.962.106,78",
                                "column006": "-2.553.433,77",
                                "column007": "-842.907,42",
                                "column008": "-2.381.636,62",
                                "column009": "-2.084.834,42",
                                "column010": "-907.104,39",
                                "column011": "-1.569.445,66",
                                "column012": "-1.114.494,64",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "3521",
                                        "description": "PROVISAO P/ IMPOSTO RENDA",
                                        "column001": "-1.449.635,79",
                                        "column002": "-1.106.394,96",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "-1.962.106,78",
                                        "column006": "-2.553.433,77",
                                        "column007": "-842.907,42",
                                        "column008": "-2.381.636,62",
                                        "column009": "-2.084.834,42",
                                        "column010": "-907.104,39",
                                        "column011": "-1.569.445,66",
                                        "column012": "-1.114.494,64",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "3521001",
                                                "description": "PROVISAO IMPOSTO DE RENDA",
                                                "column001": "-1.449.635,79",
                                                "column002": "-1.106.394,96",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-1.962.106,78",
                                                "column006": "-2.553.433,77",
                                                "column007": "-842.907,42",
                                                "column008": "-2.381.636,62",
                                                "column009": "-2.084.834,42",
                                                "column010": "-907.104,39",
                                                "column011": "-1.569.445,66",
                                                "column012": "-1.114.494,64",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3521002",
                                                "description": "PROVISAO IMPOSTO DE RENDA DIFERIDO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3521003",
                                                "description": "CREDITO BASE NEGATI",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "3521004",
                                                "description": "IRPJ EXERCICIOS ANTERIORES",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "data": {
                "id": "4",
                "description": "RECEITAS",
                "column001": "171.659.458,36",
                "column002": "315.327.488,42",
                "column003": "140.546.667,49",
                "column004": "0,00",
                "column005": "151.952.911,59",
                "column006": "192.838.262,34",
                "column007": "165.296.072,32",
                "column008": "173.337.561,36",
                "column009": "180.242.276,33",
                "column010": "158.631.920,71",
                "column011": "428.232.677,98",
                "column012": "334.272.594,37",
                "TOTAL": "1.000,00"
            },
            "children": [
                {
                    "data": {
                        "id": "41",
                        "description": "RECEITAS BRUTA DE VENDAS",
                        "column001": "195.424.596,67",
                        "column002": "371.194.210,41",
                        "column003": "190.958.657,65",
                        "column004": "0,00",
                        "column005": "145.087.276,74",
                        "column006": "232.654.747,79",
                        "column007": "196.967.813,32",
                        "column008": "201.651.294,75",
                        "column009": "198.035.402,67",
                        "column010": "182.616.802,43",
                        "column011": "487.040.179,75",
                        "column012": "390.594.553,00",
                        "TOTAL": "1.000,00"
                    },
                    "children": [
                        {
                            "data": {
                                "id": "411",
                                "description": "RECEITAS DE MERCADORIAS",
                                "column001": "194.794.205,09",
                                "column002": "370.921.882,62",
                                "column003": "190.840.412,90",
                                "column004": "0,00",
                                "column005": "144.768.544,54",
                                "column006": "232.031.874,44",
                                "column007": "196.194.006,69",
                                "column008": "201.175.914,32",
                                "column009": "197.694.458,56",
                                "column010": "182.210.541,55",
                                "column011": "486.734.943,46",
                                "column012": "390.380.653,04",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "4111",
                                        "description": "RECEITA VENDA EXPORTACAO",
                                        "column001": "6.027.411,84",
                                        "column002": "2.561,94",
                                        "column003": "1.576,85",
                                        "column004": "0,00",
                                        "column005": "16.245,61",
                                        "column006": "14.983,63",
                                        "column007": "5.709,72",
                                        "column008": "880.600,42",
                                        "column009": "1.828.047,87",
                                        "column010": "0,00",
                                        "column011": "903.224,69",
                                        "column012": "244.185,14",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "4111014",
                                                "description": "RECEITA VENDAS EXPO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4111020",
                                                "description": "RECEITA VENDAS EXPO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4111021",
                                                "description": "RECEITA VENDAS EXPO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4111023",
                                                "description": "RECEITA VENDAS EXPORTACAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4111024",
                                                "description": "RECEITA VENDAS EXPO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4111028",
                                                "description": "RECEITA VENDAS EXPO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4111030",
                                                "description": "RECEITA VENDAS EXPORTACAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4111031",
                                                "description": "RECEITA VENDAS EXPO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4111032",
                                                "description": "RECEITA VENDAS EXPORTACAO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4111033",
                                                "description": "RECEITA VENDAS EXPO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4111035",
                                                "description": "RECEITA VENDAS EXPORTACAO",
                                                "column001": "6.027.411,84",
                                                "column002": "2.561,94",
                                                "column003": "1.576,85",
                                                "column004": "0,00",
                                                "column005": "16.245,61",
                                                "column006": "14.983,63",
                                                "column007": "5.709,72",
                                                "column008": "880.600,42",
                                                "column009": "1.828.047,87",
                                                "column010": "0,00",
                                                "column011": "903.224,69",
                                                "column012": "244.185,14",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4111038",
                                                "description": "RECEITA VENDAS EXPO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4111042",
                                                "description": "RECEITA VENDAS EXPO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "4112",
                                        "description": "RECEITA VENDAS NACIONAIS",
                                        "column001": "178.795.324,16",
                                        "column002": "361.443.320,09",
                                        "column003": "185.708.769,57",
                                        "column004": "0,00",
                                        "column005": "135.431.663,22",
                                        "column006": "220.484.547,39",
                                        "column007": "180.960.223,65",
                                        "column008": "183.286.854,06",
                                        "column009": "174.175.536,25",
                                        "column010": "165.290.389,86",
                                        "column011": "478.072.675,05",
                                        "column012": "382.298.491,26",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "4112011",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112020",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "4.851,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "21.680,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "17.340,00",
                                                "column010": "25.096,80",
                                                "column011": "12.240,00",
                                                "column012": "25.500,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112021",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112022",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "1.943.059,44",
                                                "column002": "780.562,78",
                                                "column003": "397.313,00",
                                                "column004": "0,00",
                                                "column005": "110.215,00",
                                                "column006": "837.321,68",
                                                "column007": "925.287,18",
                                                "column008": "1.395.001,10",
                                                "column009": "243.656,08",
                                                "column010": "82.751,81",
                                                "column011": "1.058.529,04",
                                                "column012": "436.310,75",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112023",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "146.173,35",
                                                "column002": "2.394.256,97",
                                                "column003": "546.515,07",
                                                "column004": "0,00",
                                                "column005": "628.195,65",
                                                "column006": "311.259,58",
                                                "column007": "51.194,32",
                                                "column008": "20.751,34",
                                                "column009": "140.983,06",
                                                "column010": "108.834,11",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112024",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "3.700.132,75",
                                                "column002": "11.119.722,00",
                                                "column003": "454.007,19",
                                                "column004": "0,00",
                                                "column005": "7.834.424,21",
                                                "column006": "9.924.852,76",
                                                "column007": "7.942.553,84",
                                                "column008": "13.322.132,74",
                                                "column009": "9.197.041,85",
                                                "column010": "1.329.776,29",
                                                "column011": "7.014.750,28",
                                                "column012": "12.478.650,06",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112029",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112030",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "1.479.709,68",
                                                "column002": "816.301,07",
                                                "column003": "288.958,03",
                                                "column004": "0,00",
                                                "column005": "1.614.037,05",
                                                "column006": "2.842.928,58",
                                                "column007": "1.072.575,77",
                                                "column008": "1.767.068,04",
                                                "column009": "348.338,84",
                                                "column010": "971.000,92",
                                                "column011": "1.987.414,42",
                                                "column012": "1.788.536,52",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112031",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "10.641.632,07",
                                                "column002": "13.282.125,36",
                                                "column003": "6.293.294,77",
                                                "column004": "0,00",
                                                "column005": "5.547.712,76",
                                                "column006": "7.453.953,52",
                                                "column007": "11.236.783,86",
                                                "column008": "12.626.942,91",
                                                "column009": "11.949.974,89",
                                                "column010": "11.003.722,85",
                                                "column011": "13.284.610,57",
                                                "column012": "11.707.067,86",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112032",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112033",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112035",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "131.433.642,86",
                                                "column002": "291.063.573,04",
                                                "column003": "144.328.222,93",
                                                "column004": "0,00",
                                                "column005": "93.759.870,27",
                                                "column006": "163.315.922,46",
                                                "column007": "130.909.812,89",
                                                "column008": "114.248.897,64",
                                                "column009": "119.583.033,21",
                                                "column010": "126.099.834,97",
                                                "column011": "414.023.852,97",
                                                "column012": "333.175.532,33",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112036",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112037",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112038",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112039",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112040",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112041",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112044",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112045",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112047",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112048",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112049",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "29.450.974,01",
                                                "column002": "41.986.778,87",
                                                "column003": "33.395.607,58",
                                                "column004": "0,00",
                                                "column005": "25.937.208,28",
                                                "column006": "35.776.628,81",
                                                "column007": "28.822.015,79",
                                                "column008": "39.888.560,29",
                                                "column009": "32.695.168,32",
                                                "column010": "25.564.872,11",
                                                "column011": "40.691.277,77",
                                                "column012": "22.686.893,74",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112051",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112052",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112053",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112054",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112056",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112058",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112061",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112077",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4112099",
                                                "description": "VENDA ATIVO IMOBILIZADO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "17.500,00",
                                                "column009": "0,00",
                                                "column010": "104.500,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "4114",
                                        "description": "RECEITA VENDAS NACIONAIS",
                                        "column001": "9.971.469,09",
                                        "column002": "9.476.000,59",
                                        "column003": "5.130.066,48",
                                        "column004": "0,00",
                                        "column005": "9.320.635,71",
                                        "column006": "11.532.343,42",
                                        "column007": "15.228.073,32",
                                        "column008": "17.008.459,84",
                                        "column009": "21.690.874,44",
                                        "column010": "16.920.151,69",
                                        "column011": "7.759.043,72",
                                        "column012": "7.837.976,64",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "4114022",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "85.758,75",
                                                "column009": "85.758,75",
                                                "column010": "0,00",
                                                "column011": "82.244,58",
                                                "column012": "85.758,75",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4114023",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "9.971.469,09",
                                                "column002": "9.476.000,59",
                                                "column003": "5.130.066,48",
                                                "column004": "0,00",
                                                "column005": "9.320.635,71",
                                                "column006": "11.532.343,42",
                                                "column007": "15.228.073,32",
                                                "column008": "16.922.701,09",
                                                "column009": "21.605.115,69",
                                                "column010": "16.920.151,69",
                                                "column011": "7.676.799,14",
                                                "column012": "7.752.217,89",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4114024",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4114031",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4114035",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4114037",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4114039",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4114047",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4114051",
                                                "description": "VENDA MERCADO INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "412",
                                "description": "RECEITAS DE SERVICOS",
                                "column001": "630.391,58",
                                "column002": "272.327,79",
                                "column003": "118.244,75",
                                "column004": "0,00",
                                "column005": "318.732,20",
                                "column006": "622.873,35",
                                "column007": "773.806,63",
                                "column008": "475.380,43",
                                "column009": "340.944,11",
                                "column010": "406.260,88",
                                "column011": "305.236,29",
                                "column012": "213.899,96",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "4121",
                                        "description": "COMISSOES S/ VENDAS",
                                        "column001": "630.391,58",
                                        "column002": "272.327,79",
                                        "column003": "118.244,75",
                                        "column004": "0,00",
                                        "column005": "313.226,55",
                                        "column006": "622.873,35",
                                        "column007": "773.806,63",
                                        "column008": "475.380,43",
                                        "column009": "346.449,76",
                                        "column010": "406.260,88",
                                        "column011": "305.236,29",
                                        "column012": "213.899,96",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "4121001",
                                                "description": "REC.COMIS.S/ VENDAS NAC.",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4121002",
                                                "description": "REC.COMIS.S/ VENDAS EXP.",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4121003",
                                                "description": "REC.COMIS.S/ VENDAS EXP.",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4121011",
                                                "description": "RECEITA DE SERVICOS - VITORIA",
                                                "column001": "7.279,55",
                                                "column002": "29.957,39",
                                                "column003": "17.382,30",
                                                "column004": "0,00",
                                                "column005": "3.918,39",
                                                "column006": "17.382,21",
                                                "column007": "10.274,44",
                                                "column008": "13.689,88",
                                                "column009": "38.774,62",
                                                "column010": "26.177,41",
                                                "column011": "19.095,58",
                                                "column012": "15.005,47",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4121012",
                                                "description": "RECEITA DE SERVICOS - SAO PAULO",
                                                "column001": "310.390,73",
                                                "column002": "114.540,96",
                                                "column003": "19.180,08",
                                                "column004": "0,00",
                                                "column005": "158.834,56",
                                                "column006": "183.206,15",
                                                "column007": "167.396,73",
                                                "column008": "116.842,20",
                                                "column009": "40.074,67",
                                                "column010": "194.396,71",
                                                "column011": "50.279,96",
                                                "column012": "32.675,68",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4121013",
                                                "description": "RECEITA DE SERVICOS - ITAJAI",
                                                "column001": "286.221,68",
                                                "column002": "95.102,60",
                                                "column003": "65.282,39",
                                                "column004": "0,00",
                                                "column005": "128.449,29",
                                                "column006": "397.504,04",
                                                "column007": "319.044,24",
                                                "column008": "308.126,57",
                                                "column009": "225.994,00",
                                                "column010": "155.676,51",
                                                "column011": "208.661,02",
                                                "column012": "135.771,24",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4121014",
                                                "description": "RECEITA DE SERVICOS - RECIFE",
                                                "column001": "26.499,62",
                                                "column002": "32.726,84",
                                                "column003": "16.399,98",
                                                "column004": "0,00",
                                                "column005": "22.024,31",
                                                "column006": "24.780,95",
                                                "column007": "277.091,22",
                                                "column008": "36.721,78",
                                                "column009": "41.606,47",
                                                "column010": "30.010,25",
                                                "column011": "27.199,73",
                                                "column012": "30.447,57",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4121016",
                                                "description": "RECEITA DE SERVICOS - CARIACICA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4121017",
                                                "description": "RECEITA DE SERVICOS - BETIM",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "4124",
                                        "description": "RECEITA DE SERVICOS",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "5.505,65",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "-5.505,65",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "4124001",
                                                "description": "REC.COMISS S/VENDAS NAC.",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4124002",
                                                "description": "REC.COMIS.S/VENDAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4124003",
                                                "description": "RECEITA DE VENDA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "5.505,65",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "-5.505,65",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "data": {
                        "id": "42",
                        "description": "(-)DEDUCOES DA REC. BRUTA",
                        "column001": "-28.404.685,49",
                        "column002": "-59.434.101,20",
                        "column003": "-47.008.324,51",
                        "column004": "0,00",
                        "column005": "-1.370.756,78",
                        "column006": "-38.565.239,20",
                        "column007": "-33.582.974,90",
                        "column008": "-29.672.907,10",
                        "column009": "-30.077.212,99",
                        "column010": "-31.019.299,03",
                        "column011": "-72.914.572,57",
                        "column012": "-61.243.109,79",
                        "TOTAL": "1.000,00"
                    },
                    "children": [
                        {
                            "data": {
                                "id": "421",
                                "description": "(-)DEVOLUCOES/ABATIMENTOS",
                                "column001": "-1.678.560,71",
                                "column002": "-1.491.823,11",
                                "column003": "-7.834.237,85",
                                "column004": "0,00",
                                "column005": "-810.450,48",
                                "column006": "-1.844.851,02",
                                "column007": "-7.293.253,57",
                                "column008": "-1.120.585,75",
                                "column009": "-2.582.395,37",
                                "column010": "-5.994.128,42",
                                "column011": "-1.277.443,39",
                                "column012": "-4.966.039,88",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "4211",
                                        "description": "(-)DEVOLUCOES DE VENDAS",
                                        "column001": "-1.678.560,71",
                                        "column002": "-1.491.823,11",
                                        "column003": "-7.834.237,85",
                                        "column004": "0,00",
                                        "column005": "-810.450,48",
                                        "column006": "-1.844.851,02",
                                        "column007": "-7.293.253,57",
                                        "column008": "-896.493,53",
                                        "column009": "-2.582.395,37",
                                        "column010": "-5.994.128,42",
                                        "column011": "-1.277.443,39",
                                        "column012": "-4.966.039,88",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "4211001",
                                                "description": "(-)DEV. PRECO VENDA EXT",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "-2.704.277,44",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4211002",
                                                "description": "(-)DEV. PRECO VENDA INT",
                                                "column001": "-1.678.560,71",
                                                "column002": "-1.491.823,11",
                                                "column003": "-7.834.237,85",
                                                "column004": "0,00",
                                                "column005": "-810.450,48",
                                                "column006": "-1.844.851,02",
                                                "column007": "-7.293.253,57",
                                                "column008": "-896.493,53",
                                                "column009": "-2.582.395,37",
                                                "column010": "-3.289.850,98",
                                                "column011": "-1.277.443,39",
                                                "column012": "-4.966.039,88",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "4212",
                                        "description": "(-)ABATIMENTOS",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "4212002",
                                                "description": "(-)ABATIM. MERC. INTERNO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "4214",
                                        "description": "(-)DEVOLUCOES DE VENDAS INTERCOMPANY",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "-224.092,22",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "4214001",
                                                "description": "(-)DEV.PRECO VENDA INTERCOMPANY",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "-224.092,22",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "data": {
                                "id": "422",
                                "description": "(-)IMPOSTOS S/ RECEITAS",
                                "column001": "-26.726.124,78",
                                "column002": "-57.942.278,09",
                                "column003": "-39.174.086,66",
                                "column004": "0,00",
                                "column005": "-560.306,30",
                                "column006": "-36.720.388,18",
                                "column007": "-26.289.721,33",
                                "column008": "-28.552.321,35",
                                "column009": "-27.494.817,62",
                                "column010": "-25.025.170,61",
                                "column011": "-71.637.129,18",
                                "column012": "-56.277.069,91",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "4221",
                                        "description": "(-)IMPOSTOS INCID.S/VENDA",
                                        "column001": "-36.174.353,92",
                                        "column002": "-71.146.954,33",
                                        "column003": "-42.828.272,05",
                                        "column004": "0,00",
                                        "column005": "-26.115.745,40",
                                        "column006": "-45.120.676,71",
                                        "column007": "-37.264.324,02",
                                        "column008": "-39.612.091,14",
                                        "column009": "-39.058.386,54",
                                        "column010": "-33.770.695,70",
                                        "column011": "-81.725.888,55",
                                        "column012": "-66.852.363,64",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "4221001",
                                                "description": "(-)I.C.M.S.",
                                                "column001": "-7.270.151,86",
                                                "column002": "-16.728.998,49",
                                                "column003": "-8.223.867,12",
                                                "column004": "0,00",
                                                "column005": "-5.162.746,30",
                                                "column006": "-11.918.146,29",
                                                "column007": "-8.672.678,76",
                                                "column008": "-7.997.044,89",
                                                "column009": "-7.089.763,01",
                                                "column010": "-6.542.175,66",
                                                "column011": "-19.258.452,26",
                                                "column012": "-16.897.258,14",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4221002",
                                                "description": "(-)I.P.I.",
                                                "column001": "-9.367.165,48",
                                                "column002": "-31.620.796,08",
                                                "column003": "-19.518.786,44",
                                                "column004": "0,00",
                                                "column005": "-9.306.521,02",
                                                "column006": "-11.700.710,77",
                                                "column007": "-10.805.003,45",
                                                "column008": "-12.864.158,14",
                                                "column009": "-13.228.672,80",
                                                "column010": "-11.491.598,29",
                                                "column011": "-37.505.140,12",
                                                "column012": "-33.956.350,83",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4221003",
                                                "description": "(-)I.S.S.",
                                                "column001": "-25.819,52",
                                                "column002": "-11.714,96",
                                                "column003": "-4.606,91",
                                                "column004": "0,00",
                                                "column005": "-13.188,41",
                                                "column006": "-23.265,81",
                                                "column007": "-34.205,05",
                                                "column008": "-18.308,82",
                                                "column009": "-12.595,42",
                                                "column010": "-17.200,20",
                                                "column011": "-8.470,17",
                                                "column012": "-7.980,12",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4221004",
                                                "description": "(-)PIS S/ VENDAS",
                                                "column001": "-2.226.458,92",
                                                "column002": "-3.647.947,96",
                                                "column003": "-2.187.719,35",
                                                "column004": "0,00",
                                                "column005": "-1.726.297,03",
                                                "column006": "-2.785.457,69",
                                                "column007": "-1.935.048,71",
                                                "column008": "-2.663.332,89",
                                                "column009": "-2.451.800,27",
                                                "column010": "-1.998.022,51",
                                                "column011": "-3.067.048,91",
                                                "column012": "-2.430.485,21",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4221005",
                                                "description": "(-)COFINS S/ VENDAS",
                                                "column001": "-10.368.115,23",
                                                "column002": "-16.944.731,89",
                                                "column003": "-10.215.375,66",
                                                "column004": "0,00",
                                                "column005": "-7.885.297,57",
                                                "column006": "-12.961.048,12",
                                                "column007": "-9.028.136,60",
                                                "column008": "-12.411.614,95",
                                                "column009": "-11.427.606,05",
                                                "column010": "-9.315.643,64",
                                                "column011": "-14.307.072,44",
                                                "column012": "-11.299.989,40",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4221006",
                                                "description": "(-)PIS S/ SERVICOS",
                                                "column001": "-10.403,31",
                                                "column002": "-10.679,10",
                                                "column003": "-1.952,03",
                                                "column004": "0,00",
                                                "column005": "-5.169,70",
                                                "column006": "-10.279,16",
                                                "column007": "-12.769,69",
                                                "column008": "-11.240,40",
                                                "column009": "-5.719,22",
                                                "column010": "-6.705,14",
                                                "column011": "-5.038,11",
                                                "column012": "-3.531,31",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4221007",
                                                "description": "(-)COFINS S/ SERVICOS",
                                                "column001": "-47.910,24",
                                                "column002": "-49.111,95",
                                                "column003": "-8.986,82",
                                                "column004": "0,00",
                                                "column005": "-23.805,64",
                                                "column006": "-47.338,82",
                                                "column007": "-58.809,59",
                                                "column008": "-51.764,39",
                                                "column009": "-26.330,97",
                                                "column010": "-30.876,35",
                                                "column011": "-23.198,46",
                                                "column012": "-16.256,96",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4221008",
                                                "description": "(-)I.C.M.S. SUBST. TRIBUT",
                                                "column001": "-6.858.329,36",
                                                "column002": "-2.079.807,00",
                                                "column003": "-2.653.182,30",
                                                "column004": "0,00",
                                                "column005": "-1.992.719,73",
                                                "column006": "-5.674.430,05",
                                                "column007": "-6.385.804,37",
                                                "column008": "-3.559.290,57",
                                                "column009": "-4.815.471,72",
                                                "column010": "-4.368.472,56",
                                                "column011": "-7.485.370,59",
                                                "column012": "-1.851.838,28",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4221009",
                                                "description": "(-) IRRF S/ SERVICO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4221011",
                                                "description": "ICMS DIFAL",
                                                "column001": "0,00",
                                                "column002": "-53.166,90",
                                                "column003": "-13.795,42",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "-331.867,80",
                                                "column008": "-35.336,09",
                                                "column009": "-427,08",
                                                "column010": "-1,35",
                                                "column011": "-66.097,49",
                                                "column012": "-388.673,39",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4221101",
                                                "description": "(-)ICMS ITAJAI",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "4222",
                                        "description": "(-)IMPOSTOS S/DEVOLUCOES",
                                        "column001": "428.833,91",
                                        "column002": "220.615,56",
                                        "column003": "46.939,28",
                                        "column004": "0,00",
                                        "column005": "247.473,27",
                                        "column006": "313.455,98",
                                        "column007": "1.428.799,84",
                                        "column008": "171.573,14",
                                        "column009": "517.294,51",
                                        "column010": "672.551,64",
                                        "column011": "287.283,27",
                                        "column012": "676.135,37",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "4222001",
                                                "description": "(-)ICMS S/ DEVOLUCOES",
                                                "column001": "145.542,28",
                                                "column002": "58.614,76",
                                                "column003": "8.444,83",
                                                "column004": "0,00",
                                                "column005": "84.919,39",
                                                "column006": "142.449,32",
                                                "column007": "761.013,82",
                                                "column008": "35.189,64",
                                                "column009": "206.102,52",
                                                "column010": "119.212,57",
                                                "column011": "71.657,32",
                                                "column012": "336.350,13",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4222002",
                                                "description": "(-)IPI S/ DEVOLUCOES",
                                                "column001": "154.362,71",
                                                "column002": "26.454,18",
                                                "column003": "14.379,81",
                                                "column004": "0,00",
                                                "column005": "8.178,30",
                                                "column006": "394,40",
                                                "column007": "35.161,65",
                                                "column008": "46.473,47",
                                                "column009": "79.718,19",
                                                "column010": "67.114,97",
                                                "column011": "53.610,73",
                                                "column012": "20.815,13",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4222004",
                                                "description": "(-)PIS S/ DEVOLUCOES",
                                                "column001": "22.998,11",
                                                "column002": "24.178,58",
                                                "column003": "4.261,70",
                                                "column004": "0,00",
                                                "column005": "27.537,26",
                                                "column006": "30.433,58",
                                                "column007": "112.846,52",
                                                "column008": "14.023,10",
                                                "column009": "41.289,95",
                                                "column010": "18.871,58",
                                                "column011": "9.476,80",
                                                "column012": "45.883,64",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4222005",
                                                "description": "(-)COFINS S/DEVOLUCOES",
                                                "column001": "105.930,81",
                                                "column002": "111.368,04",
                                                "column003": "19.852,94",
                                                "column004": "0,00",
                                                "column005": "126.838,32",
                                                "column006": "140.178,68",
                                                "column007": "519.777,85",
                                                "column008": "64.591,20",
                                                "column009": "190.183,85",
                                                "column010": "86.923,65",
                                                "column011": "43.967,20",
                                                "column012": "211.342,79",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4222006",
                                                "description": "(-)PIS S/ SERVICOS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4222007",
                                                "description": "(-)COFINS S/ SERVIC",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4222008",
                                                "description": "(-)I.C.M.S. SUBST. TRIBUT",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "11.295,73",
                                                "column009": "0,00",
                                                "column010": "380.428,87",
                                                "column011": "108.571,22",
                                                "column012": "61.743,68",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "4223",
                                        "description": "RECUPERACAO DESP IMPOSTOS",
                                        "column001": "10.878.273,49",
                                        "column002": "15.693.047,37",
                                        "column003": "4.685.643,81",
                                        "column004": "0,00",
                                        "column005": "8.133.431,66",
                                        "column006": "10.176.304,73",
                                        "column007": "12.335.071,20",
                                        "column008": "14.060.837,81",
                                        "column009": "14.952.294,37",
                                        "column010": "11.523.852,32",
                                        "column011": "11.297.629,85",
                                        "column012": "11.399.913,64",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "42230101",
                                                "description": "ICMS ITAJAI - 148A",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "42230102",
                                                "description": "ICMS INVEST",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "42230103",
                                                "description": "ICMS CURITIBA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "42230104",
                                                "description": "ICMS ITAJAI DIAT I",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "42230105",
                                                "description": "ICMS ITAJAI DIAT II",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "42230106",
                                                "description": "ICMS INVEST CARIACICA",
                                                "column001": "0,00",
                                                "column002": "1.283.489,06",
                                                "column003": "1.122.943,20",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "41.134,64",
                                                "column010": "192.477,86",
                                                "column011": "1.064.750,84",
                                                "column012": "525.590,10",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "42230107",
                                                "description": "ICMS RECIFE",
                                                "column001": "73.334,96",
                                                "column002": "158.324,42",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "53.813,35",
                                                "column006": "9.489,94",
                                                "column007": "4.603,55",
                                                "column008": "20.760,45",
                                                "column009": "43.995,88",
                                                "column010": "39.061,47",
                                                "column011": "52.803,73",
                                                "column012": "41.488,67",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "42230108",
                                                "description": "ICMS ITAJAI DIAT III",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "42230109",
                                                "description": "ICMS ITAJAI",
                                                "column001": "2.838.080,52",
                                                "column002": "3.967.404,17",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "2.204.401,61",
                                                "column006": "2.922.467,45",
                                                "column007": "3.006.761,29",
                                                "column008": "4.917.752,26",
                                                "column009": "3.445.803,17",
                                                "column010": "3.775.981,28",
                                                "column011": "3.242.257,67",
                                                "column012": "2.880.817,52",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "42230111",
                                                "description": "ICMS RECIFE PRODEUATO",
                                                "column001": "7.966.858,01",
                                                "column002": "10.283.829,72",
                                                "column003": "3.562.700,61",
                                                "column004": "0,00",
                                                "column005": "5.875.216,70",
                                                "column006": "7.244.347,34",
                                                "column007": "9.323.706,36",
                                                "column008": "9.122.325,10",
                                                "column009": "11.421.360,68",
                                                "column010": "7.516.331,71",
                                                "column011": "6.937.817,61",
                                                "column012": "7.952.017,35",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "4224",
                                        "description": "IMPOSTOS INCID S/VENDA  INTERCOMPANY",
                                        "column001": "-1.858.878,26",
                                        "column002": "-2.708.986,69",
                                        "column003": "-1.078.397,70",
                                        "column004": "0,00",
                                        "column005": "-1.719.147,06",
                                        "column006": "-2.089.472,18",
                                        "column007": "-2.789.268,35",
                                        "column008": "-3.172.641,16",
                                        "column009": "-3.906.019,96",
                                        "column010": "-3.450.878,87",
                                        "column011": "-1.496.153,75",
                                        "column012": "-1.500.755,28",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "4224001",
                                                "description": "ICMS",
                                                "column001": "-724.324,31",
                                                "column002": "-1.504.060,63",
                                                "column003": "-450.831,53",
                                                "column004": "0,00",
                                                "column005": "-591.963,38",
                                                "column006": "-750.982,90",
                                                "column007": "-979.396,45",
                                                "column008": "-1.165.154,15",
                                                "column009": "-1.078.596,55",
                                                "column010": "-1.323.442,29",
                                                "column011": "-568.307,31",
                                                "column012": "-486.549,82",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4224002",
                                                "description": "IPI",
                                                "column001": "-233.821,46",
                                                "column002": "-361.868,87",
                                                "column003": "-168.633,65",
                                                "column004": "0,00",
                                                "column005": "-292.038,44",
                                                "column006": "-299.445,51",
                                                "column007": "-442.176,41",
                                                "column008": "-501.303,59",
                                                "column009": "-904.702,50",
                                                "column010": "-619.634,07",
                                                "column011": "-231.518,80",
                                                "column012": "-318.669,54",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4224003",
                                                "description": "ISS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4224004",
                                                "description": "PIS S/ FATURAMENTO",
                                                "column001": "-160.671,21",
                                                "column002": "-150.383,17",
                                                "column003": "-81.863,63",
                                                "column004": "0,00",
                                                "column005": "-148.971,87",
                                                "column006": "-185.342,90",
                                                "column007": "-243.967,33",
                                                "column008": "-268.670,55",
                                                "column009": "-342.971,82",
                                                "column010": "-268.959,37",
                                                "column011": "-124.209,81",
                                                "column012": "-124.068,57",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4224005",
                                                "description": "COFINS S/FATURAMENTO",
                                                "column001": "-740.061,28",
                                                "column002": "-692.674,02",
                                                "column003": "-377.068,89",
                                                "column004": "0,00",
                                                "column005": "-686.173,37",
                                                "column006": "-853.700,87",
                                                "column007": "-1.123.728,16",
                                                "column008": "-1.237.512,87",
                                                "column009": "-1.579.749,09",
                                                "column010": "-1.238.843,14",
                                                "column011": "-572.117,83",
                                                "column012": "-571.467,35",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4224006",
                                                "description": "PIS S/ SERVICOS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4224007",
                                                "description": "COFINS S/ SERVICOS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4224101",
                                                "description": "ICMS ITAJAI",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "4225",
                                        "description": "AVP - AJUSTE A VALOR PRESENTE",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "18.893.681,23",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "0,00",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "4225001",
                                                "description": "AJUSTE A VALOR PRESENTE",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4225002",
                                                "description": "AJUSTE DE CUT OFF",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "18.893.681,23",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "data": {
                        "id": "43",
                        "description": "RECEITAS FINANCEIRAS",
                        "column001": "4.639.547,18",
                        "column002": "3.567.379,21",
                        "column003": "-3.403.665,65",
                        "column004": "0,00",
                        "column005": "8.236.391,63",
                        "column006": "-1.251.246,25",
                        "column007": "1.911.233,90",
                        "column008": "1.359.173,71",
                        "column009": "12.284.086,65",
                        "column010": "7.034.417,31",
                        "column011": "14.103.695,13",
                        "column012": "4.921.151,16",
                        "TOTAL": "1.000,00"
                    },
                    "children": [
                        {
                            "data": {
                                "id": "431",
                                "description": "RECEITAS FINANCEIRAS",
                                "column001": "4.639.547,18",
                                "column002": "3.567.379,21",
                                "column003": "-3.403.665,65",
                                "column004": "0,00",
                                "column005": "8.236.391,63",
                                "column006": "-1.251.246,25",
                                "column007": "1.911.233,90",
                                "column008": "1.359.173,71",
                                "column009": "12.284.086,65",
                                "column010": "7.034.417,31",
                                "column011": "14.103.695,13",
                                "column012": "4.921.151,16",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "4311",
                                        "description": "RECEITAS FINANCEIRAS",
                                        "column001": "-1.285.163,87",
                                        "column002": "903.790,44",
                                        "column003": "-2.859.531,30",
                                        "column004": "0,00",
                                        "column005": "8.796,82",
                                        "column006": "1.426.136,62",
                                        "column007": "1.227.557,23",
                                        "column008": "670.829,99",
                                        "column009": "7.739.928,91",
                                        "column010": "44.019,41",
                                        "column011": "966.293,19",
                                        "column012": "2.249.526,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "4311001",
                                                "description": "JUROS AUFERIDOS",
                                                "column001": "8.757,18",
                                                "column002": "1.455,22",
                                                "column003": "2,22",
                                                "column004": "0,00",
                                                "column005": "2.042,43",
                                                "column006": "19.095,57",
                                                "column007": "71.603,19",
                                                "column008": "1.144,60",
                                                "column009": "8.858,79",
                                                "column010": "14.029,72",
                                                "column011": "-6.409,33",
                                                "column012": "12.141,87",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4311002",
                                                "description": "DESCONTOS OBTIDOS",
                                                "column001": "432.706,88",
                                                "column002": "104.227,78",
                                                "column003": "77.822,36",
                                                "column004": "0,00",
                                                "column005": "-4.452,38",
                                                "column006": "221.439,11",
                                                "column007": "42.532,18",
                                                "column008": "13.757,59",
                                                "column009": "599.824,51",
                                                "column010": "460.885,54",
                                                "column011": "31.156,28",
                                                "column012": "162.407,32",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4311003",
                                                "description": "REC. S/ APLIC. FINAN. FAF",
                                                "column001": "555.125,46",
                                                "column002": "320.028,80",
                                                "column003": "-3.149.023,71",
                                                "column004": "0,00",
                                                "column005": "685.954,58",
                                                "column006": "789.475,88",
                                                "column007": "594.517,79",
                                                "column008": "520.043,18",
                                                "column009": "512.943,51",
                                                "column010": "479.421,20",
                                                "column011": "380.257,79",
                                                "column012": "420.503,37",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4311004",
                                                "description": "GANHO REAL APLIC R. FIXA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4311005",
                                                "description": "MULTAS S/ ATRASO",
                                                "column001": "196.073,82",
                                                "column002": "24.514,69",
                                                "column003": "2.997,29",
                                                "column004": "0,00",
                                                "column005": "33.926,27",
                                                "column006": "144.164,37",
                                                "column007": "143.340,75",
                                                "column008": "30.544,66",
                                                "column009": "17.733,26",
                                                "column010": "4.271,16",
                                                "column011": "14.329,88",
                                                "column012": "44.557,93",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4311006",
                                                "description": "RECEITAS DIVERSAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "114.340,75",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4311007",
                                                "description": "RECEITA AGIO FUNDAP",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4311008",
                                                "description": "HEDGE CAMBIAL LIQUIDACAO (EFETIVO)",
                                                "column001": "-1.841.123,03",
                                                "column002": "453.563,95",
                                                "column003": "208.670,54",
                                                "column004": "0,00",
                                                "column005": "-1.693.117,61",
                                                "column006": "-649.055,84",
                                                "column007": "91.553,00",
                                                "column008": "38.196,27",
                                                "column009": "6.387.084,74",
                                                "column010": "-914.588,21",
                                                "column011": "869.836,93",
                                                "column012": "1.609.915,51",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4311009",
                                                "description": "HEDGE CAMBIAL AJUSTE (LALUR)",
                                                "column001": "-636.704,18",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "984.443,53",
                                                "column006": "786.676,78",
                                                "column007": "284.010,32",
                                                "column008": "67.143,69",
                                                "column009": "213.484,10",
                                                "column010": "0,00",
                                                "column011": "-322.878,36",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4311010",
                                                "description": "JUROS S CAPITAL PROPRIO",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "4312",
                                        "description": "VAR.MON./CAMBIAIS ATIVAS",
                                        "column001": "4.430.103,27",
                                        "column002": "739.280,95",
                                        "column003": "352.221,72",
                                        "column004": "0,00",
                                        "column005": "3.822.122,32",
                                        "column006": "-7.865.374,73",
                                        "column007": "-910.702,37",
                                        "column008": "-867.925,76",
                                        "column009": "138.103,17",
                                        "column010": "4.024.802,60",
                                        "column011": "11.334.595,30",
                                        "column012": "802.079,06",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "4312001",
                                                "description": "VARIACOES MONET. ATIVAS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4312002",
                                                "description": "VARIACOES CAMBIAIS ATIVAS",
                                                "column001": "328.750,65",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "1.402.199,97",
                                                "column006": "-10.095.647,00",
                                                "column007": "-1.749.377,81",
                                                "column008": "-1.209.875,88",
                                                "column009": "-440.802,65",
                                                "column010": "2.454.011,57",
                                                "column011": "9.310.741,15",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4312003",
                                                "description": "VAR.MONET.APL.RENDA FIXA",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "-2,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4312004",
                                                "description": "VARIAC CAMB ATIVA REALIZ",
                                                "column001": "4.101.352,62",
                                                "column002": "739.280,95",
                                                "column003": "352.221,72",
                                                "column004": "0,00",
                                                "column005": "2.419.924,35",
                                                "column006": "2.230.272,27",
                                                "column007": "838.675,44",
                                                "column008": "341.950,12",
                                                "column009": "578.905,82",
                                                "column010": "1.570.791,03",
                                                "column011": "2.023.854,15",
                                                "column012": "802.079,06",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4312007",
                                                "description": "VARIACAO CAMBIAL N",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "4313",
                                        "description": "AGIO/DESAGIO",
                                        "column001": "1.494.607,78",
                                        "column002": "1.924.307,82",
                                        "column003": "-896.356,07",
                                        "column004": "0,00",
                                        "column005": "4.405.472,49",
                                        "column006": "5.187.991,86",
                                        "column007": "1.594.379,04",
                                        "column008": "1.556.269,48",
                                        "column009": "4.406.054,57",
                                        "column010": "2.965.595,30",
                                        "column011": "1.802.806,64",
                                        "column012": "1.869.546,10",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "4313001",
                                                "description": "RESULTADO FUNDAP",
                                                "column001": "1.494.607,78",
                                                "column002": "1.924.307,82",
                                                "column003": "-896.356,07",
                                                "column004": "0,00",
                                                "column005": "4.405.472,49",
                                                "column006": "5.187.991,86",
                                                "column007": "1.594.379,04",
                                                "column008": "1.556.269,48",
                                                "column009": "4.406.054,57",
                                                "column010": "2.965.595,30",
                                                "column011": "1.802.806,64",
                                                "column012": "1.869.546,10",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "data": {
                        "id": "44",
                        "description": "OUTRAS RECEITAS",
                        "column001": "0,00",
                        "column002": "0,00",
                        "column003": "0,00",
                        "column004": "0,00",
                        "column005": "0,00",
                        "column006": "0,00",
                        "column007": "0,00",
                        "column008": "0,00",
                        "column009": "0,00",
                        "column010": "0,00",
                        "column011": "3.375,67",
                        "column012": "0,00",
                        "TOTAL": "1.000,00"
                    },
                    "children": [
                        {
                            "data": {
                                "id": "441",
                                "description": "RECEITAS INCENTIVADAS",
                                "column001": "0,00",
                                "column002": "0,00",
                                "column003": "0,00",
                                "column004": "0,00",
                                "column005": "0,00",
                                "column006": "0,00",
                                "column007": "0,00",
                                "column008": "0,00",
                                "column009": "0,00",
                                "column010": "0,00",
                                "column011": "3.375,67",
                                "column012": "0,00",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "4412",
                                        "description": "OUTRAS RECEITAS",
                                        "column001": "0,00",
                                        "column002": "0,00",
                                        "column003": "0,00",
                                        "column004": "0,00",
                                        "column005": "0,00",
                                        "column006": "0,00",
                                        "column007": "0,00",
                                        "column008": "0,00",
                                        "column009": "0,00",
                                        "column010": "0,00",
                                        "column011": "3.375,67",
                                        "column012": "0,00",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "4412001",
                                                "description": "RECEITA DE ALUGUEIS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4412002",
                                                "description": "RECEITAS DE IMPOSTOS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4412003",
                                                "description": "OUTRAS RECEITAS OPERACIONAIS",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "3.375,67",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "data": {
                        "id": "49",
                        "description": "COMPENSACAO",
                        "column001": "0,00",
                        "column002": "0,00",
                        "column003": "0,00",
                        "column004": "0,00",
                        "column005": "0,00",
                        "column006": "0,00",
                        "column007": "0,00",
                        "column008": "0,00",
                        "column009": "0,00",
                        "column010": "0,00",
                        "column011": "0,00",
                        "column012": "0,00",
                        "TOTAL": "1.000,00"
                    },
                    "children": [
                        {
                            "data": {
                                "id": "499",
                                "description": "COMPENSACAO",
                                "column001": "0,00",
                                "column002": "0,00",
                                "column003": "0,00",
                                "column004": "0,00",
                                "column005": "0,00",
                                "column006": "0,00",
                                "column007": "0,00",
                                "column008": "0,00",
                                "column009": "0,00",
                                "column010": "0,00",
                                "column011": "0,00",
                                "column012": "0,00",
                                "TOTAL": "1.000,00"
                            },
                            "children": [
                                {
                                    "data": {
                                        "id": "4998",
                                        "description": "COMPENSACAO",
                                        "column001": "-128.703.592,64",
                                        "column002": "-156.597.057,57",
                                        "column003": "-74.422.542,70",
                                        "column004": "0,00",
                                        "column005": "-93.094.765,71",
                                        "column006": "-121.063.126,49",
                                        "column007": "-168.181.389,31",
                                        "column008": "-163.486.296,64",
                                        "column009": "-188.014.612,57",
                                        "column010": "-133.739.722,08",
                                        "column011": "-128.369.696,12",
                                        "column012": "-128.910.635,35",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "4998044",
                                                "description": "COMPENSACAO",
                                                "column001": "-128.703.592,64",
                                                "column002": "-156.844.152,11",
                                                "column003": "-74.422.542,70",
                                                "column004": "0,00",
                                                "column005": "-93.094.765,71",
                                                "column006": "-122.412.242,91",
                                                "column007": "-168.181.389,31",
                                                "column008": "-163.486.296,64",
                                                "column009": "-188.014.612,57",
                                                "column010": "-133.739.722,08",
                                                "column011": "-128.369.696,12",
                                                "column012": "-129.325.201,47",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4998144",
                                                "description": "DEV CTA ORDEM CMV",
                                                "column001": "0,00",
                                                "column002": "247.094,54",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "1.349.116,42",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "414.566,12",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "data": {
                                        "id": "4999",
                                        "description": "COMPENSACAO",
                                        "column001": "128.703.592,64",
                                        "column002": "156.597.057,57",
                                        "column003": "74.422.542,70",
                                        "column004": "0,00",
                                        "column005": "93.094.765,71",
                                        "column006": "121.063.126,49",
                                        "column007": "168.181.389,31",
                                        "column008": "163.486.296,64",
                                        "column009": "188.014.612,57",
                                        "column010": "133.739.722,08",
                                        "column011": "128.369.696,12",
                                        "column012": "128.910.635,35",
                                        "TOTAL": "1.000,00"
                                    },
                                    "children": [
                                        {
                                            "data": {
                                                "id": "4999020",
                                                "description": "OPERACAO 699 - CC 2",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4999024",
                                                "description": "OPERACAO 699 - CC 024",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4999029",
                                                "description": "OPERACAO 699 - CC 029",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4999030",
                                                "description": "OPERACAO 699 - CC 030",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4999031",
                                                "description": "OPERACAO 699 - CC 031",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4999032",
                                                "description": "OPERACAO 699 - CC 032",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4999035",
                                                "description": "OPERACAO 699 - CC 035",
                                                "column001": "32.996.386,32",
                                                "column002": "31.652.546,36",
                                                "column003": "20.859.253,19",
                                                "column004": "0,00",
                                                "column005": "23.022.875,75",
                                                "column006": "34.713.939,47",
                                                "column007": "33.996.769,91",
                                                "column008": "30.136.050,67",
                                                "column009": "29.268.266,86",
                                                "column010": "25.025.816,56",
                                                "column011": "30.723.594,84",
                                                "column012": "22.966.750,51",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4999036",
                                                "description": "OPERACAO 699 - CC 036",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4999038",
                                                "description": "OPERACAO 699 - CC 038",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4999040",
                                                "description": "OPERACAO 699 - CC 040",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4999044",
                                                "description": "OPERACAO 699 - CC 044",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4999049",
                                                "description": "OPERACAO 699 - CC 049",
                                                "column001": "95.707.206,32",
                                                "column002": "125.191.605,75",
                                                "column003": "53.563.289,51",
                                                "column004": "0,00",
                                                "column005": "70.071.889,96",
                                                "column006": "87.698.303,44",
                                                "column007": "134.184.619,40",
                                                "column008": "133.350.245,97",
                                                "column009": "158.746.345,71",
                                                "column010": "108.713.905,52",
                                                "column011": "97.646.101,28",
                                                "column012": "106.358.450,96",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4999053",
                                                "description": "OPERACAO 699 - CC 053",
                                                "column001": "0,00",
                                                "column002": "0,00",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "0,00",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "0,00",
                                                "TOTAL": "1.000,00"
                                            }
                                        },
                                        {
                                            "data": {
                                                "id": "4999135",
                                                "description": "DEV CTA ORDEM PRECO VENDA",
                                                "column001": "0,00",
                                                "column002": "-247.094,54",
                                                "column003": "0,00",
                                                "column004": "0,00",
                                                "column005": "0,00",
                                                "column006": "-1.349.116,42",
                                                "column007": "0,00",
                                                "column008": "0,00",
                                                "column009": "0,00",
                                                "column010": "0,00",
                                                "column011": "0,00",
                                                "column012": "-414.566,12",
                                                "TOTAL": "1.000,00"
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
};
