
export class Mock {

    public static readonly companies = [
        { code: 1, name: 'COMEX CONSOLI' },
        { code: 2, name: 'COMEXPORT CIA' },
        { code: 6, name: 'COMEXPORT TRADING COMERCIO EXTERIOR LTDA' },
        { code: 21, name: 'KALIUM' },
        { code: 25, name: 'COMEXPORT SER' },
        { code: 22, name: 'OFF BOOK' },
        { code: 27, name: 'CX TRADE AG' },
        { code: 23, name: 'SAMA' }
    ];

    public static readonly profitCenter = [
        { code: 20755, name: "EXC.DESC.DUPL. -C-KENKO MAGNUM" },
        { code: 20788, name: "EXC.DESC.DUPL. -E-RC INDUSTRIA" },
        { code: 20796, name: "EXC.DESC.DUPL. -D-RICINOIL" },
        { code: 20824, name: "EXC.DESC.DUPL. -B-CCI" },
        { code: 20837, name: "EXC.DESC.DUPL. -D-NOBRESPUMA" },
        { code: 20843, name: "EXC.DESC.DUPL. -C-ECO-BLASTER" },
        { code: 20845, name: "EXC.DESC.DUPL. -C-SATHLER" },
        { code: 20848, name: "EXC.DESC.DUPL. -C-OLIGOM-TEC" },
        { code: 20853, name: "EXC.DESC.DUPL. -A-CCI" },
        { code: 20854, name: "EXC.DESC.DUPL. -C-ECO-BLASTER" },
        { code: 20861, name: "EXC.DESC.DUPL. -C-ARCHOTE" },
        { code: 20863, name: "EXC.DESC.DUPL. -A-QI TINTAS" },
        { code: 20864, name: "EXC.DESC.DUPL. -C-SATHLER" }
    ];

    public static readonly traders = [
        { code: 55, name: "Maiara" },
        { code: 88, name: "Sheila" },
        { code: 96, name: "Emily" }

    ];

    public static readonly clients = [
        { code: 5, name: "Gallo" },
        { code: 8, name: "Toyota" },
        { code: 6, name: "Tok Stok" }

    ];

    public static readonly directors = [
        { code: 5, name: "Guerra" },
        { code: 8, name: "Sergio" },
        { code: 6, name: "Rodrigo" },
        { code: 6, name: "Juliano" }
    ];

    public static readonly clientGroups = [
        { code: 1, name: "Grupo 1" },
        { code: 2, name: "Grupo 2" },
        { code: 3, name: "Grupo 3" }
    ];
}
