import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';
import { AfterContentInit, Component } from '@angular/core';
import * as _ from 'lodash';

import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';

import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { ExchangeSpListingService } from 'app/theme/pages/finance/exchange/services/exchange-sp-listing.service';
import { NotificationService } from '#services/_notification/notification.service';
import { UtilsService } from '#services/_utils/utils.service';
import { ExchangeBrokerService } from 'app/modules/crud/pages/exchange/exchange-broker/exchange-broker.service';

@Component({
    selector: 'sp-listing-details',
    templateUrl: './sp-listing-details.component.html',
    styleUrls: ['./sp-listing-details.component.scss'],
    providers: [ExchangeBrokerService]
})
export class SpListingDetailsComponent implements AfterContentInit {

    confirmationModal: boolean = false;
    options: {
        finance: DropdownOption[]; owner: DropdownOption[]; broker: DropdownOption[]; }
        = { finance: [], owner: [] , broker: []
    };

    history: any;
    spDetails = this.spListingService.getSpDetails();

    constructor(
        public spListingService: ExchangeSpListingService,
        private _cmxDropdownService: CmxDropdownService,
        private _exchangeBrokerService: ExchangeBrokerService,
        private _notificationService: NotificationService,
        private _utilsService: UtilsService,
        private customDialogService: CustomDialogService,
    ) { }

    ngAfterContentInit () {
        this.options.finance = this._cmxDropdownService.toDropdownItems([
            { code: 'Vazio', name: 'Vazio' }, { code: 'Finimp', name: 'Finimp' }, { code: 'LC', name: 'LC' },
            { code: 'Cobrança', name: 'Cobrança' }, { code: 'P-tax (Hedge)', name: 'P-tax (Hedge)' }
        ]);
        this.options.owner = this._cmxDropdownService.toDropdownItems([
            { code: 'Tesouraria', name: 'Tesouraria' }, { code: 'Cliente', name: 'Cliente' }
        ]);
        this._exchangeBrokerService.getBrokerList(false).subscribe((brokerList) => {
            this.options.broker = this._cmxDropdownService.toDropdownItems(brokerList);
        });
    }

    changeSpDetails() {
        this.confirmationModal = false;
        this.spListingService.dataChanged = true;
        this.spListingService.asyncData = true;

        this.spListingService.setChangedSpDetails().subscribe(() => {
            this._notificationService.openNotification('success', 'Os dados foram salvos', 'Detalhes da SP')
            this._updateGridLine();
            this.getSpDetails();
            this.spListingService.asyncData = false;
        }, (error) => {
            this._utilsService.errorHandler(error, 'Detalhes da SP');
            this.spListingService.asyncData = false;
        });
    }

    private _updateGridLine() {
        const newGridLine = _.cloneDeep(this.spListingService.getSpGridRows()[0]);
        this.spListingService.setSpGridRows([newGridLine]);
        setTimeout(() => { $('.slick-cell.l0.r0').remove() }, 200);
    }

    handleHistory() {
        this.openHistory(this.spListingService.getPaymentRequestNumber());
    }

    openHistory(paymentRequestNumber: string) {

        this.spListingService.getHistory(paymentRequestNumber).subscribe((history) => {
            this.history = history;
        });

        this.customDialogService.open('history');
    }

    closeModal(): void {
        this.customDialogService.close('history');
    }

    getSpDetails(){
        this.spDetails = this.spListingService.getSpDetails();
    }
}
