import { environment } from '#environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';

import { DownloadModalService } from '#services/_download/download-modal.service';
import { DownloadService } from '#services/_download/download.service';

@Component({
    selector: 'async-downloads',
    templateUrl: './async-downloads.component.html',
    styleUrls: ['./async-downloads.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AsyncDownloadsComponent {

    public moment = moment;
    public objectKeys = Object.keys;
    public offset = 0;
    public limit = 30;
    public pages = [];
    public page = 0;
    public queryId = Math.random();
    public downloadList = [];
    public selectedDownload;
    public asyncDownloads = false;

    constructor(
        public downloadModalService: DownloadModalService,
        private http: HttpClient,
        private downloadService: DownloadService,
    ) {
        downloadModalService.reloadDownloads$.subscribe((reload) => {
            this.resetPages();
            this.loadAsyncDownloads();
        });
    }

    public loadAsyncDownloads() {
        $('async-downloads pagination').addClass('invisible');
        this.asyncDownloads = false;
        this.getAsyncDownloads().subscribe((downloads) => {
            this.downloadList = downloads.content;
            this.pages = Array.apply(null, { length: downloads.totalPages }).map(
                Number.call,
                Number,
            );
            $('async-downloads pagination').removeClass('invisible');
            this.asyncDownloads = true;
        });
    }

    public downloadArquive(origin, path) {
        this.asyncDownloads = false;
        const options = this.downloadService.getSourceOptions();
        options.headers = new HttpHeaders({
            Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = this.http.post(`${environment.endpoints.reportService}/v1/download/${origin}/${path}`,
        { }, options);
        this.downloadService.downloadFrom(url, `${origin}-${moment().format('YYYY-MM-DD')}.xlsx`)
        .subscribe(() => {
            this.asyncDownloads = true;
        });
    }

    public confirmDelete(download) {
        this.closeDeleteModal();
        this.asyncDownloads = false;
        this.deleteArquive(download.origin, download.path).subscribe((response) => {
            this.resetPages();
            this.loadAsyncDownloads();
        }, (error) => {
            if (error.status === 202) {
                this.resetPages();
                this.loadAsyncDownloads();
            }
        });
    }

    public openDeleteModal(selectedDownload) {
        this.selectedDownload = selectedDownload;
        $('async-downloads .deleteModal').removeClass('hidden');
    }
    public closeDeleteModal() {
        $('async-downloads .deleteModal').addClass('hidden');
    }

    public showInfo(element) {
        $('async-downloads .infoContent').addClass('hidden');
        if ($(element.target).hasClass('checked')) {
            $(element.target).removeClass('checked');
        } else {
            $('async-downloads .info').removeClass('checked');
            $(element.target).addClass('checked');
            $(element.target.parentElement.parentElement.children[1]).removeClass('hidden');
        }
    }

    public changePage(e) {
        $('async-downloads pagination a').removeClass('active');
        $(e.target).addClass('active');
        this.page = (e - 1);
        this.loadAsyncDownloads();
    }

    public formatFilter(value) {
        if (/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/.test(value)) {
            return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
        } else if (Array.isArray(value)) {
            let newValue = '';
            value.forEach((arrayValue, i) => {
                if (i === 0) {
                    newValue += `${arrayValue}`;
                } else {
                    newValue += `, ${arrayValue}`;
                }
            });
            return newValue;
        } else {
            return value;
        }
    }

    private resetPages() {
        this.queryId = Math.random();
        this.page = 0;
    }

    private getAsyncDownloads(): Observable<any> {
    return this.http.get(`${environment.endpoints.reportService}/v1/report?sort=createdTime,desc&page=${this.page}`);
    }

    private getDownloadArquive(origin, path): Observable<any> {
        return this.http.get(`${environment.endpoints.reportService}/v1/download/${origin}/${path}`);
    }

    private deleteArquive(origin, path): Observable<any> {
        return this.http.delete(`${environment.endpoints.reportService}/v1/report/${origin}/${path}`);
    }

    trackByFn(index: number, item: any) {
        return index;
    }
}
