
import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import mapboxgl from 'mapbox-gl';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

import { NewTrackingTimelineComponent } from '../../../new-tracking-timeline/newTrackingTimeline.component';
import { NewTrackingTimelineFilter } from 'app/theme/layouts/new-tracking-timeline/newTrackingTimelineFilter.model';
import { NewTrafficMapFilter } from 'app/theme/layouts/charts/new-traffic-map/new-vessels/newTrafficMapFilter.model';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { NewTrafficSummary } from '../newTraffic-summary.model';

import { UserService } from 'app/auth/_services';
import { NewTrackingTimelineService } from 'app/theme/layouts/new-tracking-timeline/newTrackingTimeline.service';
import { NewTrafficTraceabilityService } from '#services/_traffic-traceability/newTraffic-traceability.service';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { CountryFlagsService } from '#services/_country-flags/country-flags.service';
import { StatusService } from '#services/_status/status.service';


declare let Slick: any;

@Component({
  selector: 'newTraffic-vessels-map',
  templateUrl: './newTraffic-vessels.component.html',
  styleUrls: ['./../../charts.scss', './newTraffic-vessels.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [NewTrackingTimelineComponent, NewTrackingTimelineService],
})
export class NewTrafficVesselsMapComponent {
  @Output() changeNavigationType: EventEmitter<any> = new EventEmitter<any>();

  readonly dropdownSettingsClients = {
    singleSelection: false,
    text: 'Clientes',
  };
  readonly dropdownSettingsStatus = {
    singleSelection: false,
    text: 'Status',
    icon: true,
  };
  possibleStatus;
  editableElements = ['.newMapContainer'];
  asyncTrafficMap = false;
  options: {
    client: DropdownOption[];
    status: DropdownOption[];
  } = { client: [], status: [] };
  moment = moment;
  selectedVessel;
  selectedImp = {
    containers: [],
    imp: null,
  };
  _subscription: Subscription;
  asyncVessels = true;
  newMapBox: mapboxgl;
  mapStyle: string = 'streets-v11';
  markerList = [];
  untrackedVesselList = [];
  centerCoordenateLine = { lat: 0, lng: 0 };
  allWithdrawn = true;
  vesselList = [];
  initialVesselList = [];
  impList = [];
  initialImpList = [];
  errorMessage = '';
  currentUser = this._userService.currentUser$;
  allStatus;
  filterState: NewTrafficMapFilter = this._filterStateService.getNewTrafficMapFilter();
  trackingFilterState: NewTrackingTimelineFilter = this._filterStateService.getNewTrackingTimelineFilter();
  newImpListGrid;
  dataView;
  columns;
  rows;
  summary: NewTrafficSummary = new NewTrafficSummary();
  helpDescription = this._defineHelpDescription();

  constructor(
    private _router: Router,
    private _newTrafficTraceabilityService: NewTrafficTraceabilityService,
    private _filterStateService: FilterStateService,
    private _statusService: StatusService,
    private _userService: UserService,
    private _newTrackingTimelineComponent: NewTrackingTimelineComponent,
    private _flagsService: CountryFlagsService,
    private userCacheService: UserCacheService
  ) {}

  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      $(document).ready(() => {
        $('.newTrafficMapContainer').on('click', (element) => {
          if (
            !$(element.target).hasClass('marker') &&
            !$(element.target).hasClass('timelineBtn') &&
            !$(element.target).hasClass('impMarker')
          ) {
            $('.marker').removeClass('selected');
            this.vesselList = _.cloneDeep(this.initialVesselList);
            this.impList = _.cloneDeep(this.initialImpList);
            this._loadImpList();
          }
          if (
            !$(element.target).hasClass('untrackedListModal') &&
            !$(element.target).hasClass('untrackedIcon') &&
            !$(element.target).hasClass('untrackedList') &&
            !$(element.target).hasClass('impMarker')
          ) {
            $('.untrackedListModal').addClass('invisible');
          }
        });
      });
      this._subscription = this._newTrafficTraceabilityService.getClientsWithVessels(this.filterState).subscribe((clientsWithVessels) => {
        this.options.client = this._toDropdownItems(this.userCacheService.getClientGroups() || [], false);
        this.filterState.setClientGroups(this._toDropdownItems(clientsWithVessels.clientCodes, false));
        this.verifyDomainStatus(clientsWithVessels);
      });
      this.asyncTrafficMap = true;
      this.createMapBox();
    });
  }

  changeMapTo(component) {
    setTimeout(() => {
      this.changeNavigationType.emit(component);
    });
  }

  openUntracked() {
    $('.untrackedListModal').removeClass('invisible');
  }

  loadVessels() {
    $('.marker').remove();
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    if (this.filterState.getClientGroups().length === 0) {
      this.vesselList = [];
      this.summary = new NewTrafficSummary();
      this.errorMessage = 'Selecione ao menos um Cliente.';
    } else {
      this.errorMessage = '';
      this.vesselList = this.initialVesselList = [];
      this.impList = this.initialImpList = [];
      this.asyncVessels = true;
      this._subscription = this._newTrafficTraceabilityService.newGetVessels(this.filterState).subscribe(
        (response) => {
          this.summary = new NewTrafficSummary(response);
          this.vesselList = response.vessels;
          if (this.vesselList.length === 0) {
            this.errorMessage = 'Não existem embarcações rastreáveis em transito.';
          }
          this.initialVesselList = _.cloneDeep(this.vesselList);
          this.vesselList.forEach((vessel) => {
            this.impList = this.impList.concat({ imps: vessel.imps, vessel });
          });
          this.initialImpList = _.cloneDeep(this.impList);
          setTimeout(() => {
            this._loadImpList();
          }, 500);

          this._addVesselsToMapBox(response.vessels);

          this.asyncVessels = false;
        },
        (error) => {
          this.asyncVessels = false;
        }
      );
    }
  }

  async verifyDomainStatus(clientsWithVessels) {
      this.allStatus = this.getImpDomainStatus();
      this._verifyClientWithVessels(clientsWithVessels);
    this.options.status = this._toDropdownItems(_.cloneDeep(this.allStatus), true);
  }

  getImpDomainStatus() {
    var impStatus = [
      {name: 'Aguard. Embarque', code: 'EMBARQUE', statusEntityName: 'preShipping'},
      {name: 'Em Trânsito', code: 'TRANSITO', statusEntityName: 'inTransit'}
    ];
    return impStatus;
  }

  applyStatus(clientsWithVessels: any) {
    const validStatus = _.cloneDeep(this.allStatus);
    this.possibleStatus = this._statusService.convertStatusToVisualDropdown(validStatus);
    this._verifyValidStatus(clientsWithVessels);
    this.loadVessels();
  }

  changeMapStyle(style) {
    this.newMapBox.setStyle('mapbox://styles/mapbox/' + style);
  }

  toogleRetreat(vessel) {
    let someWithdraw = false;
    vessel.withdrawn = !vessel.withdrawn;
    this.vesselList.forEach((vesselfromList) => {
      if (vesselfromList.withdrawn === true) {
        someWithdraw = true;
      }
    });
    this.allWithdrawn = someWithdraw ? true : false;
  }
  retreatAll() {
    this.vesselList.forEach((vessel) => {
      vessel.withdrawn = this.allWithdrawn ? false : true;
    });
    this.allWithdrawn = !this.allWithdrawn;
  }

  openTrackingTimeline(vessel) {
    this.closeContainerModal();
    this.selectedVessel = vessel;
    if (vessel.imps.length > 0) {
      if (vessel.imps[0].containers.length > 0) {
        $('new-traffic-map #clients').addClass('unclickable');
        this.trackingFilterState.setConsignmentID(vessel.imps[0].containers[0].consignmentId);
        var equipmentNumber = vessel.imps[0].containers.reduce(function(containerTracked, container) {
          if (container.usedInTracking) {
              var containerNumber = container.equipmentNumber
              containerTracked.push(containerNumber);
          }
          return containerTracked;
        }, []);
        this.trackingFilterState.setEquipmentNumber(equipmentNumber);

        this._newTrackingTimelineComponent.getTimelineTracking();
        $('new-traffic-map .timelineModal').removeClass('hidden');
        $('new-traffic-map .timelineContent').removeClass('hidden');
      }
    }
  }
  openContainerModal(vessel, imp) {
    this.selectedVessel = vessel;
    this.selectedImp = imp;
    if (imp.containers !== null) {
      $('new-traffic-map #clients').addClass('unclickable');
      $('new-traffic-map .containerModal').removeClass('hidden');
      $('new-traffic-map .containerContent').removeClass('hidden');
    }
  }
  closeTimelineModal() {
    $('new-traffic-map #clients').removeClass('unclickable');
    $('new-traffic-map .timelineModal').addClass('hidden');
    $('new-traffic-map .timelineContent').addClass('hidden');
  }
  closeContainerModal() {
    if (!$('new-traffic-map .containerModal').hasClass('hidden')) {
      $('new-traffic-map #clients').removeClass('unclickable');
      $('new-traffic-map .containerModal').addClass('hidden');
      $('new-traffic-map .containerContent').addClass('hidden');
    }
  }

  createMapBox() {
    mapboxgl.accessToken =
      'pk.eyJ1Ijoic2lyZ2FzcGFyIiwiYSI6ImNrYjgwd3FhdTBiMnozMHFhOHVlZjdhazIifQ.7oKMLkdq-nkRhA8Q8kZsNQ';
    this.newMapBox = new mapboxgl.Map({
      container: 'newMapBox',
      style: `mapbox://styles/mapbox/${this.mapStyle}`,
      center: [0, 0],
      zoom: 1,
    });

    const layerList = document.getElementById('menu');
    const inputs = layerList.getElementsByTagName('input');

    function switchLayer(layer) {
      const layerId = layer.target.id;
      this.mapBox.setStyle('mapbox://styles/mapbox/' + layerId);
    }
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].onclick = switchLayer;
    }
  }

  toImpDetail(imp, companyCode) {
    if (
      navigator.userAgent.indexOf('MSIE') !== -1 ||
      navigator.appVersion.indexOf('Trident/') > -1 ||
      window.navigator.userAgent.indexOf('Edge') > -1
    ) {
      this._router.navigate([`./supply-chain/importacao/${companyCode}/${imp}`]);
    } else {
      window.open(`./supply-chain/importacao/${companyCode}/${imp}`);
    }
  }

  private _verifyClientWithVessels(clientsWithVessels) {
    if (clientsWithVessels.clientCodes.length === 0 && clientsWithVessels.status.length === 0) {
      return (this.errorMessage = 'Não existem embarcações rastreáveis em transito.'), (this.asyncVessels = false);
    } else {
      this.applyStatus(clientsWithVessels);
    }
  }

  private _loadImpList() {
    this.columns = this._createColumns();
    this.rows = this._extractRows();
    const options = {
      defaultColumnWidth: 120,
      editable: false,
      enableCellNavigation: true,
      enableTextSelectionOnCells: true,
      rowHeight: 28,
      forceFitColumns: true,
    };
    this.dataView = new Slick.Data.DataView({ inlineFilters: false });
    this.dataView.beginUpdate();
    this.dataView.setItems(this.rows);
    this.dataView.endUpdate();
    this.newImpListGrid = new Slick.Grid('#newImpListGrid', this.dataView, this.columns, options);
    this.newImpListGrid.onClick.subscribe(this._onClick.bind(this));
  }

  private _onClick(e, args) {
    const item = this.dataView.getItem(args.row);
    const companyCode = item.vessel.imps[0].companyCode;
    if ($(e.target).hasClass('ImpValue') && this._userService.getCurrentUser().has('ROUTE_SUPPLY_CHAIN_IMP_DETAIL')) {
      if (
        navigator.userAgent.indexOf('MSIE') !== -1 ||
        navigator.appVersion.indexOf('Trident/') > -1 ||
        window.navigator.userAgent.indexOf('Edge') > -1
      ) {
        this._router.navigate([`./supply-chain/importacao/${companyCode}/${item.imp}`]);
      } else {
        window.open(`./supply-chain/importacao/${companyCode}/${item.imp}`);
      }
    }
    if ($(e.target).hasClass('multipleConteiners')) {
      this.openContainerModal(item.vessel, item);
    }
    if ($(e.target).hasClass('impMarker')) {
      $('.marker').removeClass('selected');
      if (item.vessel.latitude === 0) {
        this.openUntracked();
      } else {
        this.markerList.forEach((marker) => {
            if (marker._element.id === item.vessel.imps[0].imp) {
            if (this.centerCoordenateLine.lat < parseFloat(item.vessel.latitude)) {
              marker._element.classList.add('bottom');
            }
            marker._element.classList.add('selected');
          }
        });
      }
      this.openTrackingTimeline(item.vessel);
    }
  }

  private _formatImp(row, cell, value, columnDef, dataContext) {
    return `<div class='${dataContext.highlight ? 'highlight' : ''}'></div>
        <span class='${value ? 'ImpValue' : ''}'>${value}</span>`;
  }

  private _formatMarker(row, cell, value, columnDef, dataContext) {
    return '<span class="material-icons impMarker">location_on</span>';
  }

  private _formatCountrie(row, cell, value, columnDef, dataContext) {
    const countryValue = dataContext.vessel.locationCountryFrom;
    if (typeof countryValue === 'string' && dataContext.countryFlag) {
      return `<img style="height: 18px; width: 28px; margin-top: 2px" title='${countryValue}'
              src="${dataContext.countryFlag}" alt="">`;
    } else {
      return '';
    }
  }

  private _formatStatus(row, cell, value, columnDef, dataContext) {
    return `<img title="${value.name}" class="impImg" style='width: 30px' src="assets/img/icons/${value.code.toLowerCase()}-checked.png">`;
  }

  private _formatText(row, cell, value, columnDef, dataContext) {
    return `<span title="${value ? value : ''}" style="font-size: 12px; margin-left: 4px">${value ? value : ''}</span>`;
  }

  private _createColumns() {
    const cols = [
      {
        id: 0,
        name: 'IMP',
        width: 80,
        field: 'imp',
        formatter: this._formatImp,
        cssClass: 'center-cell',
        headerCssClass: 'center',
      },
      {
        id: 1,
        name: '<span class="material-icons impMarker">location_on</span>',
        width: 15,
        field: 'marker',
        formatter: this._formatMarker,
        cssClass: 'center-cell',
        headerCssClass: 'center',
      },
      {
        id: 2,
        name: 'Navio',
        width: 90,
        field: 'vehicleName',
        formatter: this._formatText,
        cssClass: '',
        headerCssClass: 'impHeader',
      },
      {
        id: 3,
        name: 'País',
        width: 48,
        field: 'countryFrom',
        formatter: this._formatCountrie,
        cssClass: 'center-cell',
        headerCssClass: 'center',
      },
      {
        id: 4,
        name: 'Descrição',
        width: 100,
        field: 'description',
        formatter: this._formatText,
        cssClass: '',
        headerCssClass: 'impHeader',
      },
      {
        id: 5,
        name: 'Status',
        width: 50,
        field: 'status',
        formatter: this._formatStatus,
        cssClass: 'center-cell',
        headerCssClass: 'center',
      },
    ];
    if (this._userService.getCurrentUser().has('INTERNAL_FILTERS')) {
      cols[4].name = 'Cliente';
      cols[4].field = 'client';
    }
    return cols;
  }

  private _extractRows() {
    const rows = [];
    this.impList.forEach((infos, index) => {
      infos.imps.forEach((info, innerIndex) => {
        if (info) {
          rows.push({
            id: `${index}-${innerIndex}`,
            imp: info.imp,
            countryFrom: info.countryFrom,
            countryFlag: infos.vessel.locationCountryFrom ?
              this._flagsService.getFlag(infos.vessel.locationCountryFrom) : null,
            description: info.description,
            containers: info.containers,
            status: info.status,
            vessel: infos.vessel,
            client: info.clientName,
            highlight: info.highlight,
            vehicleName: infos.vessel.vehicleName,
          });
        }
      });
    });
    return rows;
  }

  private _addVesselsToMapBox(vessels) {
    this.markerList = [];
    vessels.forEach((vessel) => {
      if (vessel.latitude !== 0 && vessel.longitude !== 0) {
        const el = document.createElement('div');
        const img = document.createElement('img');
        img.src = 'assets/img/icons/frontShip.png';
        const info = document.createElement('div');
        el.id = `${vessel.imps[0].imp}`;
        info.className = 'info';
        const vesselName = document.createElement('span');
        vesselName.textContent = `${vessel.vehicleName}`;
        let impsLength = 0;
        let containersLength = 0;
        let associatedLength = 0;
        if (vessel.imps) {
          impsLength = vessel.imps.length;
          vessel.imps.forEach((imp) => {
            if (imp.clientCode !== '') {
              associatedLength++;
            }
            if (imp) {
              if (imp.containers) {
                containersLength += imp.containers.length;
              }
            }
          });
        }
        const infoLine = document.createElement('span');
        infoLine.textContent = `${impsLength} IMPs`;
        const timelineBtn = document.createElement('span');
        timelineBtn.className = 'timelineBtn';
        timelineBtn.textContent = 'TimeLine';
        info.appendChild(vesselName);
        info.appendChild(infoLine);
        info.appendChild(document.createElement('hr'));
        const dockingText = document.createElement('span');
        dockingText.textContent = 'Previsão de Atracação:';
        const etaLine = document.createElement('span');
        etaLine.textContent = `${vessel.eta ? moment(vessel.eta, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}`;
        info.appendChild(dockingText);
        info.appendChild(etaLine);
        info.appendChild(timelineBtn);
        el.appendChild(img);
        el.appendChild(info);
        el.className =
          associatedLength === impsLength
            ? 'marker'
            : associatedLength === 0
            ? 'marker notAssociated'
            : 'marker halfAssociated';
        el.addEventListener('click', (element) => {
          if ($(element.target).hasClass('timelineBtn')) {
            this.openTrackingTimeline(vessel);
          } else {
            const markerLatitude = $(element.target).attr('id');
            if (this.centerCoordenateLine.lat < parseFloat(markerLatitude)) {
              $(element.target).addClass('bottom');
            }
            $('.marker').removeClass('selected');
            $(element.target).addClass('selected');
            this._reorderImps(vessel);
          }
        });
        const marker = new mapboxgl.Marker(el).setLngLat([vessel.longitude, vessel.latitude]).addTo(this.newMapBox);
        this.markerList.push(marker);
      } else {
        this.untrackedVesselList.push(vessel);
      }
    });
    const bounds = new mapboxgl.LngLatBounds();
    this.markerList.forEach((feature) => {
      bounds.extend(feature._lngLat);
    });

    if (
      bounds._ne !== undefined &&
      bounds._ne.lng !== undefined &&
      bounds._sw !== undefined &&
      bounds._sw.lat !== undefined
    ) {
      const lngRange = Math.abs(bounds._ne.lng) + Math.abs(bounds._sw.lng);

      bounds._ne.lat += 12;
      bounds._ne.lng += lngRange / 7;
      bounds._sw.lat -= 18;
      bounds._sw.lng -= lngRange / 7;
      this.centerCoordenateLine = {
        lat: (bounds._ne.lat + bounds._sw.lat) / 2,
        lng: (bounds._ne.lng + bounds._sw.lng) / 2,
      };
      this.newMapBox.fitBounds(bounds);
    }
  }

  private _reorderImps(selectedVessel) {
    this.vesselList = _.cloneDeep(this.initialVesselList);
    this.impList = _.cloneDeep(this.initialImpList);
    const otherVessels = _.cloneDeep(this.vesselList.filter((el) => el.vehicleId !== selectedVessel.vehicleId));
    this.vesselList = [selectedVessel].concat(otherVessels);
    this.vesselList[0].imps.forEach((imp) => {
      imp['highlight'] = true;
    });
    this.impList = [];
    this.vesselList.forEach((vessel) => {
      this.impList = this.impList.concat({ imps: vessel.imps, vessel });
    });
    this._loadImpList();
  }

  private _verifyValidStatus(clientsWithVessels: any) {
    if (!this.filterState.getStatus()){
      const validStatus = [];
      this.possibleStatus.forEach((statusState: any) => {
        clientsWithVessels.status.forEach((status) => {
          if (statusState.code === status.code) {
            validStatus.push(statusState);
          }
        });
      });
      this.filterState.setStatus(validStatus)
    }
  }

  private _toDropdownItems(items: any[], includeCode = true) {
    return items != null ? items.map(includeCode ? this._toDropdownItemWithCode : this._toDropdownItem) : [];
  }

  private _toDropdownItemWithCode(e: any): DropdownOption {
    const src = `assets/img/icons/${e.code.toLowerCase()}-checked.png`;
    return new DropdownOption(src, `${e.name}`, e.code);
  }

  private _toDropdownItem(e: any): DropdownOption {
    return new DropdownOption(e.code, `${e.name}`);
  }

  trackByFn(index: number, item: any) {
    return index;
  }

  private _defineHelpDescription(): object[] {
    return [
        { type: 'text', hasLineAfter: true,  value: 'Informa a posição atual dos aviões ou navios mercantes através do código MMSI.'},
        { type: 'text', hasLineAfter: true,  value: 'No modo de navegação por navios mercantes, é possivel ter acesso às previsões de atracação, data de saída dos portos, origem e destino.'},
        { type: 'text', value: "No modo de navegação por aviões, é possível obter acesso as previsões de origem e destino, eventos que ocorrem no percurso do avição."},
    ]
}
}
