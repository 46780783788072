import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { IFreightTypeTransportation } from '../../interfaces/freight-forwarder.interface';
import { LocationsInterface } from '../../interfaces/locations.interface';
import { IPageWrapperTitle } from 'app/shared/components/cmx-page-wrapper/page-wrapper-interface';
import { IGridColumn, IGridRow } from 'app/shared/components/cmx-custom-grid/interfaces';

import { NotificationService } from '#services/_notification/notification.service';
import { LocationsService } from '../../services/locations.service';
import { UserService } from 'app/auth/_services';

import moment from 'moment';
import { SLICK_COLUMNS } from './constants/slick-columns.constant';

@Component({
  selector: 'locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class LocationsComponent {

  @ViewChild('wrapperTable') tableContent: ElementRef;
  dataTitle: IPageWrapperTitle;

  gridColumns: IGridColumn[] = this._setGridColumns();
  gridRows: IGridRow[];
  gridOptions: any;

  private _subscription: Subscription;

  freightTypeTransport: IFreightTypeTransportation[] = [
    { name: 'Aéreo', value: 'AIR', checked: false },
    { name: 'Ferroviário', value: 'RAIL', checked: false },
    { name: 'Marítimo', value: 'MARITIME', checked: false },
    { name: 'Rodoviário', value: 'ROADS', checked: false },
  ];

  labelCountriesFilter;
  listCountries = [];
  listLocations: LocationsInterface[];
  formValue: Partial<LocationsInterface> = {};
  currentUser = null;

  loadLocations = true;
  locationsPaginationState = {
    page: 0,
    limit: 15,
    totalPages: null,
    totalResults: null,
    totalElements: 15,
    queryId: '',
    reset: false,
  };

  selectedCountry = null;
  showCreateLocation = true;
  searchLocation = null;
  searchCountry = null;
  selectedCell = null;
  selectedField = null;
  selectedCellData = null;
  target = null;
  selectedDeleted;

  configInput = {
    type: 'text',
    borderFormat: 'rounded',
    borderColor: '#cccccc',
    labelPlaceholder: true,
    enabled: true,
  };

  constructor(
    private notificationService: NotificationService,
    private locationsService: LocationsService,
    private userService: UserService
  ) {
    this._getLocations();
    this._getCountries();
    this.loadingUser();
  }

  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }


  ngOnInit() {
    this.dataTitle = {
      title: 'Logística - Cotação - Locais',
    };

    this.reset();
  }

  reset() {
    this.showCreateLocation = true;
    this.selectedCountry = null;
    this.formValue = {};
    this.formValue.username = this.currentUser.data.displayName;

    this.freightTypeTransport = this.freightTypeTransport.map((transport) => {
      return {
        ...transport,
        checked: false,
      };
    });
    this.loadLocations = false;
  }

  handleModalTransportation($event: any) {
    const modalType = $event.target.value;

    this.freightTypeTransport.map((transport) => {

      if(transport.value === modalType) {
        transport.checked = transport.checked ? false : true;
      }

    });
    this.formValue.types =  this.freightTypeTransport.filter(f => f.checked).map((m) => m.value);;
  }

  handleCountry($event) {
    this.formValue.countryCode = $event.countryCode;
    this.formValue.country = $event.country;
  }

  resetLocations() {
    this.searchCountry = null;
    this._getLocations();
  }

  setPagination(data) {
    this.locationsPaginationState = null;
    this.locationsPaginationState = {
      page: data.pageable.pageNumber,
      limit: data.pageable.pageSize,
      totalPages: data.totalPages,
      totalResults: data.totalElements,
      totalElements: data.numberOfElements,
      queryId: '',
      reset: false,
    }
  }

  handleFilterByCountry($event) {

    const filter = {
      filterValue: 'country=' + $event.country,
      ...this.locationsPaginationState,
      page: 0,
    }

    this._getLocationsByFilter(filter);
  }

  handleFilterByLocation() {
    if (!this.searchLocation) {
      return this._getLocations();
    }

    const filter = {
      filterValue: 'name=' + this.searchLocation,
      ...this.locationsPaginationState,
      page: 0,
    }

    this._getLocationsByFilter(filter);
  }

  changePage(pageNumber: number): void {
    this.locationsPaginationState.page = pageNumber - 1;
    this._getLocations();
  }

  setLastUpdated(user, date) {
    return `${moment(date).format('DD/MM/YYYY')} ${user ? ' - ' + user : ''}`;
  }

  trackBy(index: number, item: any) {
    return index;
  }

  onGridCallback({ grid, dataView }): void {
    grid.onClick.subscribe((e, _) => {
      const cell = grid.getCellFromEvent(e);
      const cellData = dataView.getItem(cell.row);
      const selectedField = grid.getColumns()[cell.cell].field;


      if(selectedField === 'delete') {
      this.selectedDeleted = selectedField;
      this.selectedField = selectedField;
      this.selectedCell = cell;
      this.selectedCellData = cellData;
      this.target = e.target;
      }

      this.loadItem(cellData);
    });
  }

  loadItem(row: LocationsInterface) {
    const typeModal = this.freightTypeTransport.filter(f => row.type.includes(f.name) ? f.checked = true : f.checked = false).map((m) => m.value);
    const selectTransport = { target: { value: typeModal, checked: true } };

    this.loadLocations = true;
    this.formValue.id = row.id;
    this.formValue.countryCode = row.countryCode;
    this.formValue.country = row.country;
    this.formValue.name = row.name;
    this.formValue.types = this.freightTypeTransport.filter(f => f.checked).map((m) => m.value);

    this.showCreateLocation = false;
    this.selectedCountry = { countryCode: row.countryCode, country: row.country };
    this.handleModalTransportation(selectTransport);
    this.loadLocations = false;
  }

  deleteLocations(){

    if (this.selectedField === 'delete' && $(this.target).hasClass("material-icons") || this.selectedField === 'delete' && $(this.target).hasClass("button-slickgrid")) {
      this.selectedDeleted = null;
      return this.deleteItem(this.selectedCellData.id, this.selectedCellData);
    }
  }


  addItem() {
    if (!this.formValue.types) {
      return this.notificationService.openNotification('error', `Selecione um tipo de local`);
    }
    if (!this.formValue.country) {
      return this.notificationService.openNotification('error', `Selecione um país`);
    }
    if (!this.formValue.name) {
      return this.notificationService.openNotification('error', `Digite um local`);
    }
    this.loadLocations = true;
    this.locationsService.create(this.formValue).subscribe(data => {
      this.labelCountriesFilter = 'Selecione';
      this.notificationService.openNotification('success', `${this.formValue.country} - ${this.formValue.name} cadastrado com sucesso!`);
      this._getLocations();
      this.reset();
      this.resetLocations();
    }, error => {
      this.notificationService.openNotification('error', error.message);
      this.loadLocations = false;
    });

  }

  update() {
    if (!this.formValue.types) {
      return this.notificationService.openNotification('error', `Selecione um tipo de local`);
    }
    if (!this.formValue.country) {
      return this.notificationService.openNotification('error', `Selecione um país`);
    }
    if (!this.formValue.name) {
      return this.notificationService.openNotification('error', `Digite um local`);
    }
    this.loadLocations = true;
    this.locationsService.update(this.formValue).subscribe(data => {
      this.labelCountriesFilter = 'Selecione';
      this.notificationService.openNotification('success', `${this.formValue.country} - ${this.formValue.name} atualizado com sucesso!`);
      this._getLocations();
      this.reset();
      this.resetLocations();
    }, error => {
      this.notificationService.openNotification('error', 'Erro ao atualizar localização');
      this.loadLocations = false;
    });
  }

  deleteItem(id, location) {
    this.loadLocations = true;
    this.locationsService.delete(id).subscribe(data => {
      this.notificationService.openNotification('success', `${location.country} - ${location.name} excluido com sucesso!`);
      this._getLocations();
      this.resetLocations();
    }, error => {
      this.notificationService.openNotification('error', `Erro ao deletar ${location.country} - ${location.name}`);
      this.loadLocations = false;
    });
  }

  private loadingUser() {
    this.userService.currentUser$.subscribe((user) => {
      this.currentUser = user;
    });

  }

  private _setGridColumns(): IGridColumn[] {
    return SLICK_COLUMNS.map((column: IGridColumn) => {
      const hiddenColumn = column.field === 'id' || column.field === 'countryCode';
      return {
        ...column,
        hidden: hiddenColumn,
      };
    });
  }

  private _getLocationsByFilter(filter) {
    this.loadLocations = true;
    this.listLocations = [];
    this.locationsService.getByFilter(filter).subscribe(data => {
      this.loadLocations = false;
      this.setPagination(data);
      this.gridRows = data.content.map((row: IGridRow) => {
        let types = [];
        row.types.forEach(e => {
          types.push(this.freightTypeTransport.filter(f => f.value === e).map((m) => m.name));
        });
        return {
          id: row.id,
          type: types.join(', '),
          country: row.country,
          countryCode: row.countryCode,
          name: row.name,
          lastUpdated: this.setLastUpdated(row.username, row.lastModifiedDate),
          delete: 'close'
        }
      })
    }, error => {
      this.notificationService.openNotification('error', error.message);
      this.loadLocations = false;
    })
  }

  private _getLocations() {
    this.loadLocations = true;
    this.listLocations = [];
    this.locationsService.getLocations(this.locationsPaginationState).subscribe(data => {
      this.listLocations = data.content;
      this.loadLocations = false;
      this.setPagination(data);

      this.gridRows = this.listLocations.map((row: IGridRow) => {
        let types = [];
        row.types.forEach(e => {
          types.push(this.freightTypeTransport.filter(f => f.value === e).map((m) => m.name));
        });

        return {
          id: row.id,
          type: types.join(', '),
          country: row.country,
          countryCode: row.countryCode,
          name: row.name,
          lastUpdated: this.setLastUpdated(row.username, row.lastModifiedDate),
          delete: 'close'
        }
      })
    }, error => {
      this.notificationService.openNotification('error', error.message);
      this.loadLocations = false;
    }
    );
  }

  private _getCountries() {
    this.loadLocations = true;
    this.labelCountriesFilter = 'Carregando...';
    this._subscription = this.locationsService.getCountries().subscribe(data => {
      this.listCountries = data;
      this.labelCountriesFilter = 'Selecione';
      this.loadLocations = false;
    }, error => {
      this.notificationService.openNotification('error', 'Erro ao carregar o combo de paises');
      this.loadLocations = false;
    });
  }
}
