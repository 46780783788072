import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import Inputmask from 'inputmask';
import * as _ from 'lodash';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';

import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { Modal } from './models/modal.model';
import { Message } from './models/message.model';
import { SubscriptionsFilter } from './models/subscriptionsFilter.model';


import { SubscriptionsService } from './subscriptions.service';
import { UserService } from 'app/auth/_services';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UtilsService } from '#services/_utils/utils.service';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { NotificationService } from '#services/_notification/notification.service';

declare let Slick: any;

@Component({
    selector: 'webhooks',
    templateUrl: './webhooks.component.html',
    styleUrls: ['./webhooks.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class WebhooksComponent {
    asyncControl: boolean = true;
    requiredClient: boolean = true;
    disabledFormControl: boolean = true;

    results: any[] = [];
    modal = new Modal();
    payload;

    filterState: SubscriptionsFilter = this.filterStateService.getSubscriptionsFilter();

    clientGroups: any[] = [];
    subscriptionsSearch: any;
    subscriptions: any[];
    events: any[] = [];
    clientEvents: any[];
    allEvents: any[];
    subscription: any = {};
    currentSubscription;
    newMode: boolean = true;
    eventMap: any;

    queryId = Math.random();
    asyncLogs = false;
    currentUser$;
    allStatus = [
        { code: 'SUCCESS', name: 'Sucesso' },
        { code: 'ERROR', name: 'Erro' },
    ];
    options: {
        status: DropdownOption[];
        clients: DropdownOption[];
    } = {
        status: [],
        clients: [],
    };
    selectedStatus = [];
    impSearch: string = '';
    startDate = moment().add(-7, 'days').format('DD/MM/YYYY');
    endDate = moment().format('DD/MM/YYYY');
    logColumns;
    logRows;
    dataView;
    logGrid;
    pages = [];
    page = 0;
    logList;
    selectedLogPayload;

    eventSelected;

    readonly dropdownSettingsEvents = {
        singleSelection: true,
        text: 'Eventos'
    };

    readonly dropdownSettingStatus = {
        singleSelection: true,
        text: 'Status',
        enableSearchFilter: false,
    };

    constructor(
        private router: Router,
        private userService: UserService,
        private utilsService: UtilsService,
        private filterStateService: FilterStateService,
        private subscriptionsService: SubscriptionsService,
        private userCacheService: UserCacheService,
        private _cmxDropdownService: CmxDropdownService,
        private notificationService: NotificationService,
    ) {}

    ngAfterViewInit() {
        this.currentUser$ = this.userService.currentUser$;
        this.userService.currentUser$.subscribe((user) => {
            this.options.clients = this._cmxDropdownService.toDropdownItems(this.userCacheService.getClientGroups());
            this.filterState.setClientGroups([this.options.clients[0]]);
            this.options.status = this._cmxDropdownService.toDropdownItems(this.allStatus);
            const requests = [
                this.subscriptionsService.findAllEventType(),
                this.subscriptionsService.getSubscriptions(this.filterState.getRequestFilter()),
            ];
            forkJoin(requests).subscribe(([events, subscriptions]) => {

                if (subscriptions.error) {
                    this.asyncControl = false;
                    return this.notificationService.openNotification('error',
                    'Ocorreu um erro ao carregar o serviço');
                }

                this.loadEventMap(events);
                this.mapSubscriptions(subscriptions);
                this.subscription.selectedEvent = this.toDropdownEvents([this.allEvents[0]]);
            });
        });
    }

    save() {
        this.asyncControl = true;
        this.hideAllErrorOrSucess();
        if (this.formValidation()) {
            if (this.newMode) {
                this.subscriptionsService.save(this.subscription, this.filterState.getRequest()).subscribe(
                    (res) => this.updateList('infoCreate'),
                    (error) => this.errorHanlder(error)
                );
            } else {
                const deleteCurrent = this.currentSubscription.id !== this.subscription.selectedEvent[0].id;
                this.subscriptionsService.save(this.subscription, this.filterState.getRequest()).subscribe(
                    (res) => {
                        if (deleteCurrent) {
                            this.subscriptionsService
                                .delete(this.currentSubscription.id, this.filterState.getRequest())
                                .subscribe(
                                    () => this.updateList('infoEdit'),
                                    (error) => {
                                        this.asyncControl = false;
                                        this.utilsService.errorHandler(error, 'Inscrições');
                                    }
                                );
                        } else {
                            this.updateList('infoEdit');
                        }
                    },
                    (error) => this.errorHanlder(error)
                );
            }
        } else {
            this.asyncControl = false;
        }
    }

    openLogsModal() {
        $('.logsModal').removeClass('hidden');
        this.updateDateInputs();
        this.loadLogs();
    }
    closeLogsModal() {
        $('.logsModal').addClass('hidden');
    }

    onSelectClient(item) {
        this.asyncControl = true;
        this.disabledFormControl = false;
        this.resetFields();
        this.hideAllErrorOrSucess();
        this.findSubscriptions();
    }

    onDeSelectClient(item) {
        this.hideAllErrorOrSucess();
        this.disabledFormControl = true;
        this.resetFields();
        this.hideAllErrorOrSucess();
        this.cleanSearch();
    }

    filterSubscriptions() {
        this.asyncControl = true;
        this.hideAllErrorOrSucess();
        this.findSubscriptions();
    }

    startNewSubscription() {
        this.resetFields();
        this.hideAllErrorOrSucess();
        this.newMode = true;
        this.events = this.toDropdownEvents(this.allEvents.filter((s) => !this.clientEvents.includes(s)));
        this.payload = this.eventMap[this.allEvents[0].id].properties;
        this.subscription.selectedEvent = this.toDropdownEvents([this.allEvents[0]]);
    }

    selectSubscriptions(subscription) {
        this.hideAllErrorOrSucess();
        this.newMode = false;
        this.subscription = _.cloneDeep(subscription);
        this.events = this.toDropdownEvents(
            this.allEvents.filter((s) => !this.clientEvents.includes(s) || s.id === subscription.id)
        );
        this.subscription.selectedEvent = this.toDropdownEvents([this.subscription]);
        this.currentSubscription = subscription;
        this.payload = this.eventMap[subscription.id].properties
            ? this.eventMap[subscription.id].properties.payload
            : {};
    }

    openDeleteModal() {
        this.hideAllErrorOrSucess();
        this.modal.type = 'delete';
        this.modal.message = `Deseja deletar a assinatura ${this.subscription.customName}?`;
        this.modal.confirm = 'Deletar';
        this.modal.close = 'Cancelar';
        this.modal.onConfirm = () => this.confirmDelete();
        this.modal.onClose = () => this.closeModal();
        $('.confirmModal').removeClass('hidden');
    }

    hideAllErrorOrSucess() {
        this.closeModal();
        this.results = [];
        $('.resultMessage').addClass('hidden');
        $('#cmx-events-cmx-dropdown').removeClass('error');
        $('#cmx-endpoint-input').removeClass('error');
        $('#cmx-custom-name-input').removeClass('error');
    }

    onSelectEvent(event, element) {
        this.subscription.selectedEvent = this.toDropdownEvents([event]);
        this.payload = this.eventMap[event.id].properties ? this.eventMap[event.id].properties.payload : {};
    }

    onDeSelectEvent($event) {
        this.payload = {};
    }

    changePage(pageNumber) {
        this.selectedLogPayload = undefined;
        this.page = pageNumber - 1;
        this.loadLogs();
    }

    loadLogs() {
        this.asyncLogs = true;
        this.subscriptionsService
            .getLogs(
                this.subscription.id,
                this.impSearch,
                moment(this.startDate, 'DD/MM/YYYY').format('YYYY-MM-DDT00:00:00'),
                moment(this.endDate, 'DD/MM/YYYY').format('YYYY-MM-DDT23:59:59'),
                this.page,
                this.selectedStatus,
                this.filterState.getRequestFilter()
            )
            .subscribe(
                (logList) => {
                    this.logList = logList;
                    this.pages = Array.apply(null, { length: logList.totalPages }).map(Number.call, Number);
                    this.loadLogTable();
                },
                (error) => {
                    this.asyncLogs = false;
                }
            );
    }

    loadLogTable() {
        this.logColumns = this.createColumns();
        this.logRows = this.extractRows(this.logList);
        const options = {
            defaultColumnWidth: 120,
            editable: false,
            enableCellNavigation: true,
            enableTextSelectionOnCells: true,
            rowHeight: 28,
            forceFitColumns: false,
        };
        this.dataView = new Slick.Data.DataView({
            inlineFilters: false,
        });
        this.dataView.beginUpdate();
        this.dataView.setItems(this.logRows);
        this.dataView.endUpdate();
        this.logGrid = new Slick.Grid('#logGrid', this.dataView, this.logColumns, options);
        this.logGrid.onClick.subscribe(this.onClick.bind(this));
        this.asyncLogs = false;
    }

    verifyDates(datePicker, date) {
        if (date.replace(/\D/g, '').length === 8) {
            if (datePicker === 'startDate') {
                if (moment(date, 'DD/MM/YYYY').isAfter(moment(this.endDate, 'DD/MM/YYYY'))) {
                    this.startDate = date;
                    this.endDate = date;
                    setTimeout(() => {
                        this.updateDateInputs();
                    }, 300);
                } else {
                    this.startDate = date;
                    this.updateDateInputs();
                }
            } else if (moment(this.startDate, 'DD/MM/YYYY').isAfter(moment(date, 'DD/MM/YYYY'))) {
                this.startDate = date;
                this.endDate = date;
                setTimeout(() => {
                    this.updateDateInputs();
                }, 300);
            } else {
                this.endDate = date;
                this.updateDateInputs();
            }
        }
    }

    getEventImageSrc(eventName) {
        const baseUrl = 'assets/img/icons/';
        switch (eventName) {
            case 'Container':
                return baseUrl + 'carga.png';
            case 'Dados Básicos do Processo':
                return baseUrl + 'spreadsheets.png';
            case 'Controle de Pacote':
                return baseUrl + 'iniciado.png';
            case 'Dados de Embarque e DI':
                return baseUrl + 'embarque.png';
            case 'Documentos Originais':
                return baseUrl + 'webhooks/documentos_originais.svg';
            case 'Notas Fiscais':
                return baseUrl + 'cfop.png';
            case 'Invoices':
                return baseUrl + 'accounting.png';
            case 'Itens de Invoice':
                return baseUrl + 'webhooks/itens_de_invoice.svg';
            case 'Licença':
                return baseUrl + 'registro.png';
            default:
                return baseUrl + 'document.png';
        }
    }

    trackByFn(index: number, item: any) {
        return index;
    }

    private onClick(e, args) {
        const item = this.dataView.getItem(args.row);
        if ($(e.target).hasClass('view')) {
            this.selectedLogPayload = JSON.parse(item.payload);
            $('#logGrid .view').removeClass('selected');
            $(e.target).addClass('selected');
        }
        if ($(e.target).hasClass('retry')) {
            this.asyncLogs = true;
            this.subscriptionsService.retry(item.entryId).subscribe((response) => {
                this.loadLogs();
            });
        }
        if (
            $(e.target).hasClass('ImpValue') &&
            this.userService.getCurrentUser().has('ROUTE_SUPPLY_CHAIN_IMP_DETAIL')
        ) {
            if (
                navigator.userAgent.indexOf('MSIE') !== -1 ||
                navigator.appVersion.indexOf('Trident/') > -1 ||
                window.navigator.userAgent.indexOf('Edge') > -1
            ) {
                this.router.navigate([`./supply-chain/importacao/0/${item.imp}`]);
            } else {
                window.open(`./supply-chain/importacao/0/${item.imp}`);
            }
        }
    }

    private updateDateInputs() {
        if (!moment(this.startDate, 'DD/MM/YYYY').isValid()) {
            this.startDate = moment().format('DD/MM/YYYY');
        }
        if (!moment(this.endDate, 'DD/MM/YYYY').isValid()) {
            this.endDate = moment().format('DD/MM/YYYY');
        }
        ($('webhooks input#startDatePicker') as any).val(this.startDate).Zebra_DatePicker({
            onSelect: (selectedDate) => {
                this.verifyDates('startDate', selectedDate);
            },
            onClear: () => {
                this.startDate = undefined;
                this.endDate = undefined;
                this.updateDateInputs();
            },
        });
        setTimeout(() => {
            ($('webhooks input#endDatePicker') as any).val(this.endDate).Zebra_DatePicker({
                direction: [this.startDate, false],
                onSelect: (selectedDate) => {
                    this.endDate = selectedDate;
                },
            });
        }, 300);
        Inputmask({ mask: '99/99/9999' }).mask($('webhooks input#startDatePicker'));
        Inputmask({ mask: '99/99/9999' }).mask($('webhooks input#endDatePicker'));
    }

    private createColumns() {
        const cols = [
            {
                id: 0,
                name: 'IMP',
                width: 112,
                field: 'imp',
                cssClass: 'centered',
                headerCssClass: 'centered',
                formatter: this.impFormatter,
            },
            {
                id: 1,
                name: 'STATUS',
                width: 112,
                field: 'status',
                cssClass: 'centered',
                headerCssClass: 'centered',
                formatter: this.logStatusFormatter,
            },
            {
                id: 2,
                name: 'Data de Envio',
                width: 150,
                field: 'date',
                cssClass: 'leftAlign',
                headerCssClass: 'leftAlign',
            },
            {
                id: 3,
                name: 'Observação',
                width: 220,
                field: 'errorInfo',
                cssClass: 'leftAlign',
                headerCssClass: 'leftAlign',
                formatter: this.logErrorFormatter,
            },
            {
                id: 4,
                name: '<span class="material-icons" style="font-size:20px">replay</span>',
                width: 45,
                field: 'retry',
                cssClass: 'centered',
                headerCssClass: 'centered',
                formatter: this.retryFormatter,
            },
            {
                id: 5,
                name: '<span class="material-icons" style="font-size:20px">visibility</span>',
                width: 45,
                field: 'view',
                cssClass: 'centered',
                headerCssClass: 'centered',
                formatter: this.viewFormatter,
            },
        ];
        return cols;
    }

    private extractRows(data) {
        const rows = [];
        data.content.forEach((log, index) => {
            const notificationTime =
                log.notificationTime && log.notificationTime.length === 22
                    ? `${log.notificationTime}0`
                    : log.notificationTime;
            rows.push({
                id: index,
                entryId: log.entryId,
                imp: log.fields.imp,
                status: log.status,
                date: `${moment(notificationTime, 'YYYY-MM-DDTHH:mm:ss.sss').format('DD/MM/YYYY')} às ${moment(
                    log.notificationTime,
                    'YYYY-MM-DDTHH:mm:ss.sss'
                ).format('HH:mm:ss')}`,
                errorInfo: log.error || '',
                payload: log.fields.body,
            });
        });
        return rows;
    }

    private impFormatter(row, cell, value, columnDef, dataContext) {
        return `<span class='ImpValue'>${value}</span>`;
    }

    private logStatusFormatter(row, cell, value, columnDef, dataContext) {
        return `<span class="status ${value}">${value === 'ERROR' ? 'ERRO' : 'SUCCESSO'}</span>`;
    }

    private logErrorFormatter(row, cell, value, columnDef, dataContext) {
        return `<span>${value.hasOwnProperty('message') ? value.message : ''}</span>`;
    }

    private retryFormatter(row, cell, value, columnDef, dataContext) {
        return `<span class="material-icons retry
        ${dataContext.status}">replay</span>`;
    }

    private viewFormatter(row, cell, value, columnDef, dataContext) {
        return `<span class="material-icons view
        ${dataContext.status}">visibility</span>`;
    }

    private loadEventMap(events) {
        this.eventMap = events.reduce((map, event) => {
            map[event.id] = event;
            return map;
        }, {});
    }

    private formValidation(): any {
        let isValid = true;
        if (!this.subscription.customName || this.subscription.customName === '') {
            isValid = false;
            this.addMessage('error', 'Nome deve ser informado');
            $('#cmx-custom-name-input').addClass('error');
        }

        if (!this.subscription.endpoint || this.subscription.endpoint === '') {
            isValid = false;
            this.addMessage('error', 'URI deve ser informado');
            $('#cmx-endpoint-input').addClass('error');
        } else if (!this.isValidHttpUrl(this.subscription.endpoint)) {
            isValid = false;
            this.addMessage('error', 'URI não é válida');
            $('#cmx-endpoint-input').addClass('error');
        } else if (!this.isHttps(this.subscription.endpoint)) {
            isValid = false;
            this.addMessage('error', 'URI não é válida, requer protocolo "https://"');
            $('#cmx-endpoint-input').addClass('error');
        }

        if (!this.subscription.selectedEvent || this.subscription.selectedEvent.length === 0) {
            isValid = false;
            $('#cmx-events-cmx-dropdown').addClass('error');
            this.addMessage('error', 'Evento deve ser selecionado');
        }
        return isValid;
    }

    private isHttps(string) {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === 'https:';
    }

    private isValidHttpUrl(string) {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === 'http:' || url.protocol === 'https:';
    }

    private confirmDelete() {
        this.asyncControl = true;
        this.subscriptionsService.delete(this.currentSubscription.id, this.filterState.getRequest()).subscribe(
            (res) => {
                this.closeModal();
                this.updateList('infoDelete');
            },
            (error) => {
                this.asyncControl = false;
                this.utilsService.errorHandler(error, 'Inscrições');
            }
        );
    }

    private errorHanlder(error) {
        this.asyncControl = false;
        this.utilsService.errorHandler(error, 'Inscrições');
    }

    private showSuccess(type) {
        let message = 'Sucesso';
        switch (type) {
            case 'infoCreate':
                message = 'Criada com sucesso.';
                break;
            case 'infoEdit':
                message = 'Alterada com sucesso.';
                break;
            case 'infoDelete':
                message = 'Excluída com sucesso.';
                break;
        }
        this.addMessage('success', message);
    }

    private updateList(type) {
        this.findSubscriptions();
        this.showSuccess(type);
        this.resetFields();
    }

    private resetFields() {
        this.subscription = {
            selectedEvent: this.toDropdownEvents([this.allEvents[0]]),
        };
        this.newMode = true;
        this.payload = null;
        this.closeModal();
    }

    private cleanSearch() {
        this.closeModal();
        this.subscriptions = [];
    }

    private closeModal() {
        $('.confirmModal').addClass('hidden');
    }

    private addMessage(type: string, message: string) {
        this.results.push(new Message(type, message));
        setTimeout(() => {
            $('.resultMessage').removeClass('hidden');
        }, 1000);
        setTimeout(() => {
            this.hideAllErrorOrSucess();
        }, 15000);
    }

    private toDropdownEvents(items: any[], includeCode = true) {
        return items != null ? items.map(this.toDropdownEventItem) : [];
    }

    private findSubscriptions(first?) {
        this.subscriptionsService.getSubscriptions(this.filterState.getRequestFilter()).subscribe(
            (subscriptions) => {
                this.mapSubscriptions(subscriptions);
            },
            (error) => {
                this.asyncControl = false;
                this.utilsService.errorHandler(error);
            }
        );
    }

    private mapSubscriptions(subscriptions) {
        this.asyncControl = false;
        this.allEvents = subscriptions;
        this.clientEvents = subscriptions.filter((subscription) => this.onlyClient(subscription));
        this.events = this.toDropdownEvents(this.allEvents.filter((s) => !this.clientEvents.includes(s)));
        this.subscriptions = this.clientEvents.filter((subscription) => this.filterByName(subscription));
        this.payload = this.eventMap[this.allEvents[0].id].properties;
    }

    private filterByName(subscription) {
        if (this.filterState.textFilter && this.filterState.textFilter.length > 0) {
            return (
                subscription &&
                subscription.customName.toLowerCase().indexOf(this.filterState.textFilter.toLowerCase()) > -1
            );
        }
        return true;
    }
    private onlyClient(subscription) {
        return subscription && subscription.customName;
    }

    private toDropdownEventItem(e: any): DropdownOption {
        return new DropdownOption(e.id, `${e.name ? e.name : e.customName}`);
    }

}
