import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';

import { StructureChangedEvent } from '../../default/balance-sheet/constants/structure-changed-event';
import { StructureProperty } from '../../default/balance-sheet/constants/structure-property';
import { StructureSettings } from '../../default/balance-sheet/constants/structure-settings';

@Component({
    selector: 'report-structure',
    templateUrl: './report-structure.component.html',
    styleUrls: ['../reports.component.scss', './report-structure.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ReportStructureComponent {

    @Output() public onChange = new EventEmitter<StructureChangedEvent>();

    public editableStructure: StructureSettings;
    public activeStructure: StructureSettings;

    public readonly options = defaultOptions;

    public amountsFormat = true;
    public zeroedNodes = true;
    public allProfitCenter = true;
    public showLeaseHolder = true;
    public showServiceInfo = true;

    public followUp = false;
    public followUpColumns = {
        followUpDates: true,
        followUpClient: true,
        followUpImp: true,
        followUpPo: true,
        followUpInvoice: true,
        followUpLi: true,
        followUpSailing: true,
        followUpDta: true,
        followUpClearence: true,
        followUpPenalty: true,
        followUpContainer: true,
        followUpDelivery: true,
        followUpNf: true,
        followUpBilling: true,
        followUpOthers: true,
    };
    public allChecked = true;

    public readonly help = {
        zeroedNodes: defaultOptions.zeroedNodes.false.example,
        amountsFormat: defaultOptions.amountsFormat[2].example,
        allProfitCenter: defaultOptions.allProfitCenter.false.example,
        showLeaseHolder: defaultOptions.showLeaseHolder.false.example,
        showServiceInfo: defaultOptions.showServiceInfo.false.example,
        followUpDates: defaultOptions.followUpDates.true.example,
        followUpClient: defaultOptions.followUpClient.true.example,
        followUpImp: defaultOptions.followUpImp.true.example,
        followUpPo: defaultOptions.followUpPo.true.example,
        followUpInvoice: defaultOptions.followUpInvoice.true.example,
        followUpLi: defaultOptions.followUpLi.true.example,
        followUpSailing: defaultOptions.followUpSailing.true.example,
        followUpDta: defaultOptions.followUpDta.true.example,
        followUpClearence: defaultOptions.followUpClearence.true.example,
        followUpPenalty: defaultOptions.followUpPenalty.true.example,
        followUpContainer: defaultOptions.followUpContainer.true.example,
        followUpDelivery: defaultOptions.followUpDelivery.true.example,
        followUpNf: defaultOptions.followUpNf.true.example,
        followUpBilling: defaultOptions.followUpBilling.true.example,
        followUpOthers: defaultOptions.followUpOthers.true.example,
    };

    constructor() {
        this.editableStructure = new StructureSettings();
        this.editableStructure.displayAccountCodes = false;
        this.editableStructure.allProfitCenter = false;
        this.activeStructure = this.editableStructure.clone();
    }

    public changeAmountsFormat(format: string, helpText: string) {
        this.editableStructure.amountsFormat = format;
        this.help.amountsFormat = helpText;
    }

    public toggleZeroedNodes() {
        this.editableStructure.displayZeroedNodes = !this.editableStructure.displayZeroedNodes;
        this.help.zeroedNodes = (this.editableStructure.displayZeroedNodes)
            ? defaultOptions.zeroedNodes.true.example
            : defaultOptions.zeroedNodes.false.example;
    }

    public toggleProfitCenter() {
        this.editableStructure.allProfitCenter = !this.editableStructure.allProfitCenter;
        this.help.allProfitCenter = (this.editableStructure.allProfitCenter)
            ? defaultOptions.allProfitCenter.true.example
            : defaultOptions.allProfitCenter.false.example;
    }

    public toggleLeaseHolder() {
        this.editableStructure.showLeaseHolder = !this.editableStructure.showLeaseHolder;
        this.help.showLeaseHolder = (this.editableStructure.showLeaseHolder)
            ? defaultOptions.showLeaseHolder.true.example
            : defaultOptions.showLeaseHolder.false.example;
    }

    public toggleServiceInfo() {
        this.editableStructure.showServiceInfo = !this.editableStructure.showServiceInfo;
        this.help.showServiceInfo = (this.editableStructure.showServiceInfo)
            ? defaultOptions.showServiceInfo.true.example
            : defaultOptions.showServiceInfo.false.example;
    }

    public toggleFollowUp(column) {
        switch (column) {
            case 'dates':
                this.followUpColumns.followUpDates = !this.followUpColumns.followUpDates;
                this.help.followUpDates = this.followUpColumns.followUpDates ?
                defaultOptions.followUpDates.true.example
                : defaultOptions.followUpDates.false.example;
                break;
            case 'client':
                this.followUpColumns.followUpClient = !this.followUpColumns.followUpClient;
                this.help.followUpClient = this.followUpColumns.followUpClient ?
                defaultOptions.followUpClient.true.example
                : defaultOptions.followUpClient.false.example;
                break;
            case 'imp':
                this.followUpColumns.followUpImp = !this.followUpColumns.followUpImp;
                this.help.followUpImp = this.followUpColumns.followUpImp ?
                defaultOptions.followUpImp.true.example
                : defaultOptions.followUpImp.false.example;
                break;
            case 'po':
                this.followUpColumns.followUpPo = !this.followUpColumns.followUpPo;
                this.help.followUpPo = this.followUpColumns.followUpPo ?
                defaultOptions.followUpPo.true.example
                : defaultOptions.followUpPo.false.example;
                break;
            case 'invoice':
                this.followUpColumns.followUpInvoice = !this.followUpColumns.followUpInvoice;
                this.help.followUpInvoice = this.followUpColumns.followUpInvoice
                ? defaultOptions.followUpInvoice.true.example
                : defaultOptions.followUpInvoice.false.example;
                break;
            case 'li':
                this.followUpColumns.followUpLi = !this.followUpColumns.followUpLi;
                this.help.followUpLi = this.followUpColumns.followUpLi ?
                defaultOptions.followUpLi.true.example
                : defaultOptions.followUpLi.false.example;
                break;
            case 'sailing':
                this.followUpColumns.followUpSailing = !this.followUpColumns.followUpSailing;
                this.help.followUpSailing = this.followUpColumns.followUpSailing ?
                defaultOptions.followUpSailing.true.example
                : defaultOptions.followUpSailing.false.example;
                break;
            case 'dta':
                this.followUpColumns.followUpDta = !this.followUpColumns.followUpDta;
                this.help.followUpDta = this.followUpColumns.followUpDta ?
                defaultOptions.followUpDta.true.example
                : defaultOptions.followUpDta.false.example;
                break;
            case 'clearence':
                this.followUpColumns.followUpClearence = !this.followUpColumns.followUpClearence;
                this.help.followUpClearence = this.followUpColumns.followUpClearence ?
                defaultOptions.followUpClearence.true.example
                : defaultOptions.followUpClearence.false.example;
                break;
            case 'penalty':
                this.followUpColumns.followUpPenalty = !this.followUpColumns.followUpPenalty;
                this.help.followUpPenalty = this.followUpColumns.followUpPenalty ?
                defaultOptions.followUpPenalty.true.example
                : defaultOptions.followUpPenalty.false.example;
                break;
            case 'container':
                this.followUpColumns.followUpContainer = !this.followUpColumns.followUpContainer;
                this.help.followUpContainer = this.followUpColumns.followUpContainer ?
                defaultOptions.followUpContainer.true.example
                : defaultOptions.followUpContainer.false.example;
                break;
            case 'delivery':
                this.followUpColumns.followUpDelivery = !this.followUpColumns.followUpDelivery;
                this.help.followUpDelivery = this.followUpColumns.followUpDelivery ?
                defaultOptions.followUpDelivery.true.example
                : defaultOptions.followUpDelivery.false.example;
                break;
            case 'nf':
                this.followUpColumns.followUpNf = !this.followUpColumns.followUpNf;
                this.help.followUpNf = this.followUpColumns.followUpNf ?
                defaultOptions.followUpNf.true.example
                : defaultOptions.followUpNf.false.example;
                break;
            case 'billing':
                this.followUpColumns.followUpBilling = !this.followUpColumns.followUpBilling;
                this.help.followUpBilling = this.followUpColumns.followUpBilling ?
                defaultOptions.followUpBilling.true.example
                : defaultOptions.followUpBilling.false.example;
                break;
            case 'others':
                this.followUpColumns.followUpOthers = !this.followUpColumns.followUpOthers;
                this.help.followUpOthers = this.followUpColumns.followUpOthers ?
                defaultOptions.followUpOthers.true.example
                : defaultOptions.followUpOthers.false.example;
                break;
        }
        this.allChecked = true;
        Object.values(this.followUpColumns).map((value) => {
            if (value === false) {
                this.allChecked = false;
            }
        });
    }

    public changeAllFollowUp(change) {
        Object.keys(this.followUpColumns).map((key, value) => {
            this.followUpColumns[key] = change;
            this.help[key] = defaultOptions[key][change].example;
        });
        this.allChecked = change;
    }

    public getActiveStructure(): StructureSettings {
        return this.activeStructure;
    }

    public applyChanges() {
        const changedProperties = this.changedPropertiesArray();
        this.activeStructure = this.editableStructure.clone();
        // $('report-base .reportStructureModal').addClass('hidden');
        this.onChange.emit(new StructureChangedEvent(this.activeStructure, changedProperties));
    }

    private changedPropertiesArray() {
        const changedProperties = [];

        if (this.hasChanged((it) => it.amountsFormat)) {
            changedProperties.push(StructureProperty.amountsFormat);
        }

        if (this.hasChanged((it) => it.displayZeroedNodes)) {
            changedProperties.push(StructureProperty.displayZeroedNodes);
        }

        if (this.hasChanged((it) => it.allProfitCenter)) {
            changedProperties.push(StructureProperty.allProfitCenter);
        }

        return changedProperties;
    }

    private hasChanged(property: (structure: StructureSettings) => any) {
        const currentValue = property(this.activeStructure);
        const newValue = property(this.editableStructure);
        return currentValue !== newValue;
    }

    trackByFn(index: number, item: any) {
        return index;
    }
}

const defaultOptions = {
    amountsFormat: [
        { value: 'cents', desc: 'Com Centavos', example: 'Valor 900.876.543,21 será exibido como 900.876.543,21' },
        { value: 'no_cents', desc: 'Sem Centavos', example: 'Valor 900.876.543,21 será exibido como 900.876.543' },
        { value: 'thousands', desc: 'Milhares (Padrão)', example: 'Valor 900.876.543,21 será exibido como 900.876' },
        { value: 'millions', desc: 'Milhões', example: 'Valor 900.876.543,21 será exibido como 900' },
    ],
    zeroedNodes: {
        true: { value: 'showZeroedNodes', example: ' Exibir linhas com valores Zerados.' },
        false: { value: 'hideZeroedNodes', example: ' NÃO Exibir linhas com valores Zerados.' },
    },
    allProfitCenter: {
        true: { value: 'all', example: ' Exibir TODOS CRs.' },
        false: { value: 'outsourcing', example: ' Exibir apenas CR 35 (Terceirização).' },
    },
    showLeaseHolder: {
        true: { value: 'all', example: ' Mostrar Arrendatários.' },
        false: { value: 'outsourcing', example: ' NÃO Mostrar Arrendatários.' },
    },
    showServiceInfo: {
        true: { value: 'all', example: ' Mostrar Serviços.' },
        false: { value: 'outsourcing', example: ' NÃO Mostrar Serviços.' },
    },
    followUpDates: {
        true: { value: 'all', example: ' Exibir TODAS Datas.' },
        false: { value: 'none', example: ' NÂO Exibir Datas.' },
    },
    followUpClient: {
        true: { value: 'all', example: ' Exibir Coluna de Clientes.' },
        false: { value: 'none', example: ' NÂO Exibir Coluna de Clientes.' },
    },
    followUpImp: {
        true: { value: 'all', example: ' Exibir Coluna de Importação.' },
        false: { value: 'none', example: ' NÂO Exibir Coluna de Importação.' },
    },
    followUpPo: {
        true: { value: 'all', example: ' Exibir Coluna de PO.' },
        false: { value: 'none', example: ' NÂO Exibir Coluna de PO.' },
    },
    followUpInvoice: {
        true: { value: 'all', example: ' Exibir Coluna de Invoice.' },
        false: { value: 'none', example: ' NÂO Exibir Coluna de Invoice.' },
    },
    followUpLi: {
        true: { value: 'all', example: ' Exibir Coluna de LI.' },
        false: { value: 'none', example: ' NÂO Exibir Coluna de LI.' },
    },
    followUpSailing: {
        true: { value: 'all', example: ' Exibir Coluna de Trânsito.' },
        false: { value: 'none', example: ' NÂO Exibir Coluna de Trânsito.' },
    },
    followUpDta: {
        true: { value: 'all', example: ' Exibir Coluna de DTA.' },
        false: { value: 'none', example: ' NÂO Exibir Coluna de DTA.' },
    },
    followUpClearence: {
        true: { value: 'all', example: ' Exibir Coluna de Desembaraço.' },
        false: { value: 'none', example: ' NÂO Exibir Coluna de Desembaraço.' },
    },
    followUpPenalty: {
        true: { value: 'all', example: ' Exibir Coluna de Penalidade.' },
        false: { value: 'none', example: ' NÂO Exibir Coluna de Penalidade.' },
    },
    followUpContainer: {
        true: { value: 'all', example: ' Exibir Coluna de Container.' },
        false: { value: 'none', example: ' NÂO Exibir Coluna de Container.' },
    },
    followUpDelivery: {
        true: { value: 'all', example: ' Exibir Coluna de Entrega.' },
        false: { value: 'none', example: ' NÂO Exibir Coluna de Entrega.' },
    },
    followUpNf: {
        true: { value: 'all', example: ' Exibir Coluna de NFs.' },
        false: { value: 'none', example: ' NÂO Exibir Coluna de NFs.' },
    },
    followUpBilling: {
        true: { value: 'all', example: ' Exibir Coluna de Faturamento.' },
        false: { value: 'none', example: ' NÂO Exibir Coluna de Faturamento.' },
    },
    followUpOthers: {
        true: { value: 'all', example: ' Exibir Coluna de Outros.' },
        false: { value: 'none', example: ' NÂO Exibir Coluna de Outros.' },
    },
};
