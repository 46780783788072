export const FORM_MOCK = [
    { 
        splitterLine: {
            enable: true,
            title: 'Input',
            icon: null,
            switch: [{ code: '1', name: 'Opção 1' }, { code: '2', name: 'Opção 2' }],
        },
        fields: [
            { 
                label: 'Teste 1',
                type: 'textarea',
                info: 'Ajuda informativa',
                required: true,
                pattern: null,
                controlName: 'teste1',
                classes: 'col-md-12',
                disabled: true,
            }
        ]
    },
    { 
        fields: [
            { 
                label: 'Teste 2 (Não Obrigatório)',
                type: 'input',
                info: 'Ajuda informativa (redirecionada)',
                infoDirection: 'right',
                pattern: null,
                controlName: 'teste2',
                classes: 'col-md-4',
            },
            { 
                label: 'Teste 3 (Pré Preenchido)',
                type: 'input',
                required: true,
                initialValue: 'Valor Inicial',
                pattern: null,
                controlName: 'teste3',
                classes: 'col-md-6',
            }
        ]
    },
    { 
        fields: [
            { 
                label: 'Teste 4',
                type: 'input',
                required: true,
                pattern: null,
                controlName: 'teste4',
                classes: 'col-md-3',
            },
            { 
                label: 'Teste 5',
                type: 'input',
                required: true,
                pattern: null,
                controlName: 'teste5',
                classes: 'col-md-3',
            },
            { 
                label: 'Teste 6',
                type: 'input',
                required: true,
                pattern: null,
                controlName: 'teste6',
                classes: 'col-md-3',
            },
            { 
                label: 'Teste 7',
                type: 'input',
                required: true,
                pattern: null,
                controlName: 'teste7',
                classes: 'col-md-3',
            }
        ]
    },
    { 
        splitterLine: {
            enable: true,
            title: 'Pattern + Mask',
            buttonList: [{ code: 'button', name: 'Botão' }]
        },
        fields: [
            { 
                label: 'Pattern (Números)',
                type: 'input',
                required: true,
                pattern: '[0-9]*',
                controlName: 'formatador1',
                classes: 'col-md-3',
            },
            { 
                label: 'Pattern + Mask (Date)',
                type: 'input',
                required: true,
                pattern: '[0-9]*',
                mask: '00/00/0000',
                controlName: 'formatador2',
                classes: 'col-md-3',
            },
            { 
                label: 'Pattern + Mask (Phone)',
                type: 'input',
                required: true,
                pattern: '[0-9]*',
                mask: '(00) 0000-0000||(00) 00000-0000',
                controlName: 'formatador3',
                classes: 'col-md-3',
            }
        ]
    },
    { 
        splitterLine: {
            enable: true,
            title: 'Booleanos',
        },
        fields: [
            { 
                label: 'Booleano 1',
                type: 'boolean',
                required: true,
                pattern: null,
                controlName: 'booleano1',
                classes: 'col-md-2',
                disabled: true,
            },
            { 
                label: 'Booleano 2 (Inicio Ligado)',
                type: 'boolean',
                initialValue: true,
                required: true,
                pattern: null,
                controlName: 'booleano2',
                classes: 'col-md-4',
            }
        ]
    },
    { 
        splitterLine: {
            enable: true,
            title: 'Dropdown',
        },
        fields: [
            { 
                label: 'Dropdown 1',
                type: 'dropdown',
                required: true,
                controlName: 'dropdown1',
                classes: 'col-md-3',
                dropdownData: [{ id: '1', itemName: 'Item 1', info: 'Teste Informativo' }, { id: '2', itemName: 'Item 2' }]
            },
            { 
                label: 'Dropdown 2 (Seleção Única)',
                type: 'dropdown',
                required: true,
                controlName: 'dropdown2',
                classes: 'col-md-3',
                dropdownData: [{ id: '1', itemName: 'Item 1' }, { id: '2', itemName: 'Item 2' }],
                dropdownSettings: { 
                    enableCheckAll: false,
                    singleSelection: true
                }
            },
            { 
                label: 'Dropdown 3 (Pré Selecionado)',
                type: 'dropdown',
                required: true,
                controlName: 'dropdown3',
                classes: 'col-md-3',
                dropdownData: [{ id: '1', itemName: 'Item 1' }, { id: '2', itemName: 'Item 2' }],
                initialValue: [{ id: '1', itemName: 'Item 1' }]
            },
        ]
    },
    { 
        splitterLine: {
            enable: true,
            title: 'Datepicker',
        },
        fields: [
            { 
                label: 'Datepicker 1',
                type: 'datepicker',
                required: true,
                controlName: 'datepicker1',
                classes: 'col-md-3',
                disabled: true,
            },
            { 
                label: 'Datepicker 2 (Pré Preenchido)',
                type: 'datepicker',
                required: true,
                controlName: 'datepicker2',
                classes: 'col-md-3',
                initialValue: '10/10/2020'
            },
            { 
                label: 'Datepicker 3 (Restrições)',
                type: 'datepicker',
                required: true,
                controlName: 'datepicker3',
                classes: 'col-md-3',
                datepickerSettings: {
                    startDateRestriction: '02/08/2023'
                }
            },
        ]
    },
]