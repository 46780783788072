import { UserCacheService } from '#services/_user/app-user-cache.service';
import { Component, ViewEncapsulation } from '@angular/core';
import { UserService } from 'app/auth/_services';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { DateNotificationService } from './date-notification.service';
import { DATE_NOTIFICATION_COLUMNS } from './constants/date-notification-columns.constant';
import { UtilsService } from '#services/_utils/utils.service';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash';
import { NotificationService } from '#services/_notification/notification.service';

@Component({
    selector: 'date-notification-crud',
    templateUrl: './date-notification.component.html',
    styleUrls: ['./date-notification.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DateNotificationCRUDComponent {

    async: boolean = false;
    currentUser;
    changes: boolean = false;
    initialEmpty: boolean = false;
    showNoEmailConfirmationModal: boolean = false;
    clientGroups = this._userCacheService.getClientGroups();
    selectedClientGroup;
    dropdown: { emails: { options: DropdownOption[]; selected: DropdownOption[] };
          } = { emails: { options: [], selected: [] }};
    emailDropdownSettings = {
        enableCheckAll: false,
        singleSelection: false,
        text: 'Selecionar E-mail',
        badgeShowLabel: 'E-mails selecionados',
        badgeShowLimit: 1,
        disabled: false,
    };
    gridColumns = DATE_NOTIFICATION_COLUMNS;
    gridRows = [];

    constructor(
        private _userService: UserService,
        private _utilsService: UtilsService,
        private _userCacheService: UserCacheService,
        private _cmxDropdownService: CmxDropdownService,
        private _notificationService: NotificationService,
        private _dateNotificationService: DateNotificationService,
    ) {
        this._userService.currentUser$.subscribe((user) => {
            this.currentUser = user;
            // auto selecionar cliente no caso de usuários externos (clientes)
            if (!this.currentUser.has('INTERNAL_FILTERS') && this.currentUser.clientGroups[0]) {
                this.selectedClientGroup = { code: this.currentUser.clientGroups[0] };
                this._updateEmailList();
                this._updateDataList();
            }
        });
    }

    selectClientGroup(clientGroup) {
        this.async = true;
        this.selectedClientGroup = clientGroup;
        this._updateDataList();
    }

    emailSelected() {
        this.changes = true;
    }

    switchNotification({ item, column }): void {
        this.gridRows[item?.rawIndex][column] = !this.gridRows[item?.rawIndex][column];
        this.changes = true;
    }

    frequencySelection({ id, itemName, column, row }) {
        this.gridRows[row][column] = { ...this.gridRows[row][column], id, itemName };
        this.changes = true;
    }

    prepareNotification(skipConfirmation?: boolean) {
        const notificationData = [];
        this.gridRows.forEach((row) => {
            notificationData.push({
                id: `DATA_CHANGE_${this._formatPath(row.path)}_add`,
                includedClientGroupCodes: row.includedClientGroupCodes,
                frequency: { id: row?.frequency?.id, itemName: row?.frequency?.itemName },
                mediums: { EMAIL: row.add ? true : false }
            });
            notificationData.push({
                id: `DATA_CHANGE_${this._formatPath(row.path)}_update`,
                includedClientGroupCodes: row.includedClientGroupCodes,
                frequency: { id: row?.frequency?.id, itemName: row?.frequency?.itemName },
                mediums: { EMAIL: row.update ? true : false }
            });
        });
        const request = {
            category: {
                id: 'DATA_CHANGE',
                children: notificationData,
            },
            mediumProperties: [{
                value: [],
                key: 'email',
                medium: 'EMAIL',
            }, {
                value: [],
                key: 'external-email',
                medium: 'EMAIL',
            }],
        }
        this.dropdown.emails.selected.forEach((email) => {
            request.mediumProperties[0].value.push(email.id)
            if (!email.id?.includes('@comexport.com.br')) {
                request.mediumProperties[1].value.push(email.id)
            }
        })
        if (!this.dropdown.emails.selected.length && !skipConfirmation) {
            this.showNoEmailConfirmationModal = true;
        } else {
            this.showNoEmailConfirmationModal = false
            this._saveNotification(request);
        }
    }

    hasConfiguration() {
        return this.gridRows.some((row) => { return row.add || row.update })
    }

    private _saveNotification(notificationData) {
        this.async = true;
        this._dateNotificationService.saveNotification(notificationData, this.selectedClientGroup?.code).subscribe((response) => {
            this._notificationService.openNotification('success', 'Configuração Salva', '', 3000);
            this.changes = false;
            this.async = false;
        }, (error) => {
            this.async = false;
            this._utilsService.errorHandler(error, 'Notificações de Datas');
        });
    }

    private _updateEmailList() {
        this._dateNotificationService.getEmailList(this.selectedClientGroup?.code, this.currentUser.has('INTERNAL_FILTERS')).subscribe((response) => {
            const filteredEmailList = this._filterExternalUserEmailList(response);
            this.dropdown.emails.options = this._cmxDropdownService.toDropdownItemsEmail(filteredEmailList);
        });
    }

    // caso usuário externo, retorna apenas emails de final semelhante.
    private _filterExternalUserEmailList(emaiList) {
        const emailSuffix = this.currentUser?.data?.email.split('@')[1];
        return this.currentUser.has('INTERNAL_FILTERS') ? emaiList :
            emaiList.filter((email) => { return email?.email?.includes(emailSuffix) })
    }

    private _updateDataList() {
        this.gridRows = [];
        forkJoin(
            this._dateNotificationService.getDataList(this.selectedClientGroup?.code),
            this._dateNotificationService.getDataDefaultList(!this.currentUser.has('INTERNAL_FILTERS')),
            this._dateNotificationService.getEmailList(this.selectedClientGroup?.code, this.currentUser.has('INTERNAL_FILTERS'))
        ).subscribe(([notificationData, defaultDataList, emailDataList]) => {
            this._verifyEmptyClientConfiguration(notificationData);
            const filteredEmailList = this._filterExternalUserEmailList(emailDataList);
            this.dropdown.emails.options = this._cmxDropdownService.toDropdownItemsEmail([ ...filteredEmailList.map((email) => { return { ...email, name: email?.email }; }) ]);
            this._populateEmails(notificationData?.mediumProperties);
            const notificationDateColumns = notificationData?.category?.children;
            defaultDataList?.groups.forEach((group) => {
                group?.columns.forEach((column) => {
                    if (column?.field?.toLowerCase()?.includes('date') || column?.formatter?.toLowerCase()?.includes('date') ||
                        column?.path?.toLowerCase()?.includes('dates')) {
                        this.gridRows.push({ ...column,
                            frequency: {
                                data: notificationData?.frequencyList || [],
                                id: notificationDateColumns.find((notificationColumn) => notificationColumn?.id === `DATA_CHANGE_${this._formatPath(column?.path)}_add`)?.frequency?.id || 'ON_CHANGE',
                                itemName: notificationDateColumns.find((notificationColumn) => notificationColumn?.id === `DATA_CHANGE_${this._formatPath(column?.path)}_add`)?.frequency?.itemName || 'por Atualização',
                                disabled: false,
                            },
                            includedClientGroupCodes: [this.selectedClientGroup?.code],
                            add: notificationDateColumns.find((notificationColumn) => notificationColumn?.id === `DATA_CHANGE_${this._formatPath(column?.path)}_add`
                                && notificationColumn?.mediums?.EMAIL === true)  ? true : false,
                            update: notificationDateColumns.find((notificationColumn) => notificationColumn?.id === `DATA_CHANGE_${this._formatPath(column?.path)}_update`
                                && notificationColumn?.mediums?.EMAIL === true) ? true : false,
                        });
                    }
                });
            })
            this.async = false;
        });
    }

    private _populateEmails(emailLists) {
        const emailList = this.currentUser.has('INTERNAL_FILTERS') ? emailLists[0] : emailLists[1];
        this.dropdown.emails.selected = [];
        emailList?.value?.forEach((email) => {
            const selectedEmail = this.dropdown.emails.options.find((option) => option?.id === email);
            if (selectedEmail) {
                this.dropdown.emails.selected.push(selectedEmail);
            }
        });
    }

    private _verifyEmptyClientConfiguration(notificationData) {
        this.initialEmpty = !notificationData?.mediumProperties?.length &&
            !notificationData?.category?.children?.length
    }

    private _formatPath(path): string {
        return path?.replaceAll('[*].', '_')?.replaceAll('.', '_');
    }

}
