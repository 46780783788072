import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';

export const CLEARANCE_DI_COLUMNS: IGridColumn[] = [
    {
        field: 'warehouseNumber',
        name: 'IMP Entreposto',
        width: 115,
    },
    {
        field: 'registrationNumber',
        name: 'Registro DI',
        width: 120,
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
    {
        field: 'registrationDate',
        name: 'Data Registro DI',
        width: 120,
        formatterType: 'date',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
    {
        field: 'channel',
        name: 'Canal DI',
        width: 100,
        formatterType: 'badge',
        formatterData: {
            backgroundColor: '',
        },
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
    {
        field: 'clearanceDate',
        name: 'Desembaraço DI',
        width: 125,
        formatterType: 'date',
        headerCssClass: 'grid-header align-right',
        cssClass: 'grid-cell align-right',
    },
];
