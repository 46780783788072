<ng-template #shipmentInfos>
  <div class="headerContainer">
    <span class="shipName">
      <i class="comexport-ship"></i>
      {{ selectedVessel?.vehicleName }}
    </span>
    <div class="headerInfos">
      <div class="headerInfo">
        <span>Procedência</span>
        <span class="value" title="{{ selectedVessel?.locationCountryFrom }}">
          {{ selectedVessel?.locationCountryFrom }}</span
        >
      </div>
      <div class="headerInfo">
        <span>Porto Embar.</span>
        <span class="value">{{ selectedVessel?.harborFrom }}</span>
      </div>
      <div class="headerInfo">
        <span>Data Embar.</span>
        <span class="value">{{
          selectedVessel?.shippingDate ? moment(selectedVessel?.shippingDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
        }}</span>
      </div>
      <div class="headerInfo">
        <span>Prev. Atracação</span>
        <span class="value">{{
          selectedVessel?.eta ? moment(selectedVessel?.eta, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
        }}</span>
      </div>
      <div class="headerInfo">
        <span>Destino</span>
        <span class="value">{{ selectedVessel?.harborTo }}</span>
      </div>
      <div class="headerInfo">
        <span>Agente</span>
        <span class="value">{{ selectedVessel?.customAgentName }}</span>
      </div>
      <div class="headerInfo">
        <span>Situação</span>
        <span class="value">{{ selectedVessel?.situation?.accomplished }}%</span>
      </div>
      <div class="headerInfo">
        <span>AWB BL</span>
        <span class="value">{{ selectedVessel?.billOfLading }}</span>
      </div>
    </div>
    <hr />
  </div>
</ng-template>

<div class="newTrafficMapContainer">
  <div *ngIf="!asyncTrafficMap">
    <loader></loader>
  </div>
  <div style="display: flex; align-items: center; margin-bottom: 10px; margin-top: -10px">
    <div class="title-information">
        <cmx-help-modal help-title="Rastreamento de Containers" [help-description]="helpDescription"></cmx-help-modal>
        <h5>Rastreamento de Containers</h5>
    </div>
    <div id="searchLine" class="search-container" class="reduzed" *ngIf="currentUser | async as user">
      <cmx-dropdown
        *ngIf="user.has('INTERNAL_FILTERS')"
        id="clients"
        name="clients"
        [data]="options.client"
        [ngModel]="filterState.getClientGroups()"
        (ngModelChange)="filterState.setClientGroups($event)"
        [settings]="dropdownSettingsClients"
      ></cmx-dropdown>
      <span
        *ngIf="user.has('INTERNAL_FILTERS')"
        class="input-group-addon"
        style="height: 25px; margin-left: -20px; margin-right: -15px"
      ></span>
      <span
        *ngIf="user.has('INTERNAL_FILTERS')"
        class="input-group-addon"
        style="height: 25px; margin-left: -20px; margin-right: -15px"
      ></span>
      <cmx-dropdown
        [styles]="{width: '122px'}"
        name="status"
        [data]="options.status"
        [ngModel]="filterState.getStatus()"
        (ngModelChange)="filterState.setStatus($event)"
        [settings]="dropdownSettingsStatus"
      ></cmx-dropdown>
      <button (click)="loadVessels()" class="applyImpStatus">
        <i class="fa fa-check"></i>
      </button>
    </div>
  </div>
  <div class="newMainContainer">
    <div class="newMapContainer">
      <div id="newMapBox" style="height: 350px; width: 100%"></div>
      <div *ngIf="untrackedVesselList.length > 0" class="untracked">
        <span (click)="openUntracked()" class="material-icons untrackedIcon">report_problem</span>
        <div class="untrackedListModal invisible">
          <div class="untrackedConsolidation" style="pointer-events: none">
            {{ untrackedVesselList.length }} Navio{{ untrackedVesselList.length === 1 ? '' : 's' }} não rastreado{{
              untrackedVesselList.length === 1 ? '' : 's'
            }}.
          </div>
          <div class="untrackedList">
            <div *ngFor="let untrackedVessel of untrackedVesselList; trackBy: trackByFn" class="vessel">
              <div class="untrackedContainer">
                <div class="untrackedContent">
                  <div class="infoTitle">Navio</div>
                  <div class="info">{{ untrackedVessel?.vehicleName }}</div>
                </div>
                <div class="untrackedContent">
                  <div class="infoTitle">Data Embar.</div>
                  <div class="info">
                    {{
                      untrackedVessel?.shippingDate
                        ? moment(untrackedVessel?.shippingDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
                        : ''
                    }}
                  </div>
                </div>
                <div class="untrackedContent">
                  <div class="infoTitle">Procedência</div>
                  <div class="info">{{ untrackedVessel?.locationCountryFrom }}</div>
                </div>
                <div class="untrackedContent">
                  <div class="infoTitle">Destino</div>
                  <div class="info">{{ untrackedVessel?.harborTo }}</div>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div id="menu">
        <div (click)="changeMapStyle('streets-v11')" class="colorBall"></div>
        <div (click)="changeMapStyle('light-v10')" class="colorBall light"></div>
        <div (click)="changeMapStyle('dark-v10')" class="colorBall dark"></div>
        <div (click)="changeMapStyle('satellite-v9')" class="colorBall satellite"></div>
      </div>
    </div>
    <loader *ngIf="asyncVessels" style="position: absolute; right: 23%; top: 0; bottom: 0"></loader>
    <div *ngIf="asyncTrafficMap && !asyncVessels" class="infoContainer">
      <div class="summaryContainer" *ngIf="!errorMessage">
        <div class="generalSummaryContainer">
          <div class="clientsSearched">
            <span class="material-icons">search</span>
            <span>{{ summary.getTotalCustomerSearch() }} Clientes Pesquisados:</span>
          </div>
          <div class="summary">
            {{ summary.getTotalVessel() }} Navios • {{ summary.getTotalImps() }} IMPs
          </div>
        </div>
       </div>
      <div *ngIf="!asyncVessels && !errorMessage" class="vesselsContainer">
        <div id="newImpListGrid" style="height: 270px; margin-top: 20px"></div>
      </div>
      <div *ngIf="!asyncVessels && errorMessage">
        <p class="error-message">{{ errorMessage }}</p>
      </div>
      <div class="timelineModal hidden">
        <i (click)="closeTimelineModal()" class="la la-close"></i>
        <div class="timelineContent hidden">
          <ng-container *ngTemplateOutlet="shipmentInfos"></ng-container>
          <div class="timeline">
            <newTrackingTimeline></newTrackingTimeline>
          </div>
        </div>
      </div>
      <div class="containerModal hidden">
        <i (click)="closeContainerModal()" class="la la-close"></i>
        <div class="containerContent hidden">
          <ng-container *ngTemplateOutlet="shipmentInfos"></ng-container>
          <div class="containers">
            <span class="title">Contâiners • IMP {{ selectedImp?.imp }}</span>
            <span *ngFor="let container of selectedImp.containers; trackBy: trackByFn" class="container">
              {{ container?.equipmentNumber }}
              <hr />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
