import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Chart } from 'app/shared/utils/chart';
import { ALERT_COLORS } from '../../constants/alert-colors.constant';
import { IAlertBySeverity, ICustomizedAlertBySeverity } from '../../interfaces/alert-by-severity.interface';

@Component({
    selector: 'alerts-by-severity-chart',
    templateUrl: './alerts-by-severity-chart.component.html',
    styleUrls: ['./alerts-by-severity-chart.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AlertsBySeverityChartComponent implements OnInit {
    @Input() data: IAlertBySeverity[];

    ngOnInit(): void {
        this._LoadChart();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data && !changes.data.firstChange) {
            this._LoadChart();
        }
    }

    private _getCustomizedData(): ICustomizedAlertBySeverity[] {
        return this.data.map((data) => ({
            ...data,
            color: ALERT_COLORS[data.severity],
        }));
    }

    private _LoadChart(): void {
        const configs = {
            type: 'pie',
            theme: 'none',
            dataProvider: this._getCustomizedData(),
            titleField: 'percentage',
            valueField: 'quantity',
            labelRadius: 5,
            colorField: 'color',
            fontSize: 12,
            startAngle: 180,
            radius: '30%',
            innerRadius: '60%',
            labelText: '[[percentage]]',
            balloon: {
                maxWidth: 300,
                textAlign: 'left',
            },
            startDuration: 0,
            balloonFunction: (v) => v.dataContext.tooltip,
            labelFunction: (v) => `${v.dataContext.percentage.toFixed(2)}%`,
            export: {
                enabled: true,
            },
        };

        Chart.makeChart('resulting-operational-alerts-chart', configs);
    }
}
