import { Component, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';

import { NewTrackingTimelineFilter } from 'app/theme/layouts/new-tracking-timeline/newTrackingTimelineFilter.model';
import { NewTrackingTimelineLocation } from './newTrackingTimelineLocation';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { NewTrackingTimelineService } from './newTrackingTimeline.service';
import { CountryFlagsService } from '#services/_country-flags/country-flags.service';

@Component({
    selector: 'newTrackingTimeline',
    templateUrl: './newTrackingTimeline.component.html',
    styleUrls: ['./newTrackingTimeline.component.scss'],
    providers: [NewTrackingTimelineService],
    encapsulation: ViewEncapsulation.None,
})
export class NewTrackingTimelineComponent {

    moment = moment;
    trackingFilterState: NewTrackingTimelineFilter = this.filterStateService.getNewTrackingTimelineFilter();

    constructor(
        private filterStateService: FilterStateService,
        private trackingTimelineService: NewTrackingTimelineService,
        private _flagsService: CountryFlagsService,
    ) { }

    getTimelineTracking() {
        this.trackingFilterState.resetTimeline();
        this.trackingFilterState.setAsync(true);
        this.trackingTimelineService.getTrackingTimeline({
            equipmentNumber: this.trackingFilterState.getEquipmentNumber()
        }).subscribe((timeline) => {
            timeline.forEach((timelineLocation) => {
                this.trackingFilterState.setTimeline(new NewTrackingTimelineLocation(timelineLocation));
            });
            this.trackingFilterState.setAsync(false);
        }, (error) => {
            this.trackingFilterState.setAsync(false);
        });
    }

    getFlag(countryName) {
        return this._flagsService.getFlag(countryName);
    }

    trackByFn(index: number, item: any) {
        return index;
    }
}
