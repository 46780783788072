
export class NewTrackingTimelineLocation {

    public static blank() {
        return new NewTrackingTimelineLocation();
    }

    public location: string;
    public country: string;
    public events: object[];

    constructor(source?: NewTrackingTimelineLocation) {
        this.events = [];
        if (!source) {
            this.location = 'Sem Localização';
        } else {
            this.location = source.location || 'Sem Localização';
            const splitedCountry = source.location.split(',');
            this.country = splitedCountry[splitedCountry.length - 1].trim().toLowerCase();
            this.events = source.events || [];
        }
    }
}