import { Component, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';

import { AutomotiveDailyReportSlickGrid } from './report-automotive-daily.slickGrid';
import { DetailAutomotiveDailyReportComponent } from './detail-daily.component';
import { AutomotiveDetailDailyService } from './detail-daily.service';
import { AutomotiveDailyService } from './report-automotive-daily.service';
import { UtilsService } from '#services/_utils/utils.service';

declare let Slick: any;

@Component({
    selector: 'report-automotive-daily',
    templateUrl: './report-automotive-daily.component.html',
    styleUrls: ['../reports.component.scss', './report-automotive-daily.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [DetailAutomotiveDailyReportComponent],
})
export class AutomotiveDailyReportComponent {

    public editableElements = ['#dailyGrid', '#searchLine'];
    public date = moment().format('DD/MM/YYYY');
    public asyncDaily = true;
    public noData = false;
    public subscription;
    public columns;
    public rows;
    public dataView;
    public gridModels;
    public gridData;
    public dailyGrid;

    constructor(
        private slick: AutomotiveDailyReportSlickGrid,
        private automotiveDetailDailyService: AutomotiveDetailDailyService,
        private automotiveDailyService: AutomotiveDailyService,
        private utilsService: UtilsService,
    ) { }

    public ngAfterViewInit() {
        this.automotiveDailyService.verifyLastActivity().subscribe((lastActivity: any) => {
            this.date = lastActivity ? moment(lastActivity.endTime.split('T')[0], 'YYYY-MM-DD').format('DD/MM/YYYY') :
            moment().format('DD/MM/YYYY');
            this.loadDailyTable(true);
            ($('report-automotive-daily input#date') as any).val(this.date).Zebra_DatePicker({
                direction: ['01/01/2016', this.date],
                onSelect: (selectedDate) => {
                    this.date = selectedDate;
                    this.loadDailyTable();
                },
            });
        });
    }

    public openDetailModal(filters, division) {
        $('report-automotive-daily .dailyDetailModal').removeClass('hidden');
        this.automotiveDetailDailyService.loadDetailDaily(this.date, filters, division);
    }
    public closeDetailModal() {
        $('#dailyGrid').removeClass('reduced');
        if (!this.noData) { this.loadGrid(this.gridData); }
        if (!$('report-automotive-daily .dailyDetailModal').hasClass('hidden')) {
            $('report-automotive-daily .dailyDetailModal').addClass('hidden');
        }
    }

    public loadDailyTable(initial?) {
        if (!initial) { this.closeDetailModal(); }
        this.asyncDaily = true;
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.noData = false;
        this.utilsService.setInvisible('report-automotive-daily', this.editableElements);
        this.subscription = this.automotiveDailyService.getDaily(this.date).subscribe((data) => {
            if (data) {
                this.gridModels = data.models;
                this.loadGrid(data);
            } else {
                this.noData = true;
                this.asyncDaily = false;
                $('report-automotive-daily #searchLine').removeClass('invisible');
            }
        }, (error) => {
            this.asyncDaily = false;
            this.utilsService.setVisible('report-automotive-daily', this.editableElements);
            this.utilsService.errorHandler(error, 'Movimentação Diária');
        });
    }

    private loadGrid(data) {
        this.gridData = data;
        this.columns = this.slick.createColumns(this.gridModels);
        this.rows = this.slick.extractRows(this.gridData);
        const options = {
            defaultColumnWidth: 120,
            enableCellNavigation: true,
            enableTextSelectionOnCells: true,
            rowHeight: 30,
            forceFitColumns: false,
            enableColumnReorder: true,
        };
        this.dataView = new Slick.Data.DataView({ inlineFilters: false });
        this.dataView.beginUpdate();
        this.dataView.setItems(this.rows);
        this.dataView.setFilter(this.filter.bind(this));
        this.dataView.endUpdate();
        this.dailyGrid = new Slick.Grid('#dailyGrid', this.dataView, this.columns, options);
        this.dailyGrid.render();
        this.dailyGrid.onClick.subscribe(this.onClick.bind(this));
        this.dailyGrid.onCellChange.subscribe((function(e, args) {
            this.dataView.updateItem(args.item.id, args.item);
        }).bind(this));
        this.dataView.onRowCountChanged.subscribe((function(e, args) {
            this.dailyGrid.updateRowCount();
            this.dailyGrid.render();
        }).bind(this));
        this.dataView.onRowsChanged.subscribe((function(e, args) {
            this.dailyGrid.invalidateRows(args.rows);
            this.dailyGrid.render();
        }).bind(this));
        this.asyncDaily = false;
        this.utilsService.setVisible('report-automotive-daily', this.editableElements);
    }

    private onClick(e, args) {
        const item = this.dataView.getItem(args.row);
        const division = this.dailyGrid.getColumns()[this.dailyGrid.getCellFromEvent(e).cell].field;
        if ($(e.target.childNodes[1]).hasClass('toggle') || $(e.target).hasClass('toggle') ||
        $(e.target).hasClass('damageContainer') || $(e.target).hasClass('nameContainer')) {
            if (item) {
                if (!item._collapsed) {
                    item._collapsed = true;
                } else {
                    item._collapsed = false;
                }
                this.dataView.updateItem(item.id, item);
            }
            e.stopImmediatePropagation();
        } else if (item[division]) {
            this.automotiveDetailDailyService.page = 0;
            this.automotiveDetailDailyService.queryId = Math.random();
            const title = `${item.parentStep ? `${item.parentStep} - ` : ''}${
                item.parentGroup ? `${item.parentGroup} - ` : ''}${item.step
                } (${division.charAt(0).toUpperCase() + division.slice(1)})`;
            $('#detailDailyTitle').text(title);
            $('#dailyGrid').addClass('reduced');
            this.loadGrid(this.gridData);
            let filters;
            if (division === 'total') {
                filters = item.filter;
                filters.division = '';
            } else {
                filters = item[`filter-${division}`];
            }
            this.openDetailModal(filters, division);
        }
    }

    private filter(item) {
        if (item.parent != null) {
            let parent = this.rows[item.parent];
            while (parent) {
                if (parent._collapsed) {
                    return false;
                }
                parent = this.rows[parent.parent];
            }
        }
        return true;
    }

}
