<div class="page-title-identifier">Relatório Financeiro</div>
<div class="TreetableContainer">

    <div *ngIf="asyncExport" class="fullLoader">
        <span class="exporting">Exportando</span>
        <loader></loader>
    </div>
    <div class="title-information-dre" *ngIf='firstFilter === false'>
        <cmx-help-modal help-title="DRE" [help-description]="helpDescription"></cmx-help-modal>
        <span class="title-container-dre">DRE</span>
    </div>

    <div class="titleLine">
        <div class="titleContainer">
            <div *ngIf='firstFilter === false' class="flex-title">
                <cmx-filter-state></cmx-filter-state>
                <div class="flex-title">
                    <div class="flex-btn" (click)="openModal()"><i class="flaticon-cogwheel-2"></i>
                        <span class="tooltip-text">Estrutura</span>
                    </div>
                    <div id="filterDiv" class="flex-btn" (click)="showFilter()"><i class="flaticon-interface-8"></i>
                        <span class="tooltip-text">Filtros</span>
                    </div>
                    <div class="flex-btn" (click)="export()"><i class="flaticon-download"></i>
                        <span class="tooltip-text">Download</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>

    <div *ngIf='firstFilter === true' class="centerBtn">
        <div class="flex-btn" (click)="showFilter()"><i class="flaticon-interface-8"></i>
            <span class="tooltip-text">Filtros</span>
        </div>
    </div>
    <div *ngIf="asyncTreetable === false && firstFilter === false">
        <loader></loader>
    </div>
    <div *ngIf="noContent" class="noContent">Sem resultados para esse filtro de pesquisa.</div>
    <treetable></treetable>
</div>

<!--BEGIN of Filters -->
<div class="modal fade" id="filter-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <cmx-results-filter></cmx-results-filter>
            </div>
            <div class="modal-footer">
                <button *ngIf='!firstFilter' type="button" class="btn btn-secondary" (click)="closeFilter()">Cancelar</button>
                <button *ngIf='!firstFilter' type="button" class="btn btn-outline-primary m-btn" (click)="changeFilter()">Aplicar</button>
                <button *ngIf='firstFilter' type="button" class="btn btn-outline-primary m-btn" (click)="firstState()">Aplicar</button>
            </div>
        </div>
    </div>
</div>
<!--END of Filters -->

<div class="structureModal">
    <div class="modalContent">
        <cmx-results-structure (onChange)="changeStructure($event)" (onCancel)="closeStructure()"></cmx-results-structure>
    </div>
</div>
