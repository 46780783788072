import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';

import { LayoutModule } from '../../../layouts/layout.module';
import { CurrencyExchangeComponent } from './currency-exchange.component';

const routes: Routes = [
    { path: '', component: CurrencyExchangeComponent },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        FormsModule,
        LayoutModule,
        SharedModule
    ], declarations: [
        CurrencyExchangeComponent,
    ],
})
export class CurrencyExchangeModule {

}