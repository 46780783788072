import { NotificationService } from '#services/_notification/notification.service';
import { UtilsService } from '#services/_utils/utils.service';
import { Component, ViewEncapsulation } from '@angular/core';
import { ExchangeSpListingService } from 'app/theme/pages/finance/exchange/services/exchange-sp-listing.service';

@Component({
    selector: 'sp-listing-exchange-details',
    templateUrl: './sp-listing-exchange-details.component.html',
    styleUrls: ['./sp-listing-exchange-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SpListingExchangeDetailsComponent {

    creditNote: boolean = this.spListingService.getCreditNote();
    spExchangeDetails = this.spListingService.getSpExchangeDetails();
    spDetails = this.spListingService.getSpDetails();
    spStatus;

    constructor(
        public spListingService: ExchangeSpListingService,
        private _notificationService: NotificationService,
        private _utilsService: UtilsService,
    ) {
        this.spStatus = this.spDetails[10].value;
    }

    changeSpDetails() {
        this.spListingService.asyncData = true;
        this.spListingService.dataChanged = true;
        this.spListingService.asyncData = true;

        this.spListingService.setChangedSpDetails().subscribe(() => {
            this._notificationService.openNotification('success', 'Os dados foram salvos', 'Detalhes da SP')
            this.getDetails();
            this.spListingService.asyncData = false;
        }, (error) => {
            this._utilsService.errorHandler(error, 'Detalhes da SP');
            this.spListingService.asyncData = false;
        });
    }

    getDetails(){
        this.spDetails = this.spListingService.getSpDetails();
        this.spExchangeDetails = this.spListingService.getSpExchangeDetails();
        this.spStatus = this.spDetails[10].value;
    }
}
