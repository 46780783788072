import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'cmx-filter-switch',
    templateUrl: './cmx-filter-switch.component.html',
    styleUrls: ['./cmx-filter-switch.component.scss'],
})
export class FilterSwitchComponent {
    @Input() values: string[];
    @Input() inputIndex: number = 0;
    @Input() disabled?: boolean = false;
    @Input() disableMessage?: string;
    @Input() size: string = 'md';

    @Output('onSwitch') onSwitch = new EventEmitter();

    index: number = 0;

    ngOnInit(): void {
        if (this.values.length !== 2 && this.values.length !== 3) {
            console.error('Filter Switch must have 2 or 3 values');
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.inputIndex) {
            this.index = changes.inputIndex.currentValue;
        }
    }

    switch(directionNumber, byValueField?) {
        if (!this.disabled) {
            if (byValueField) {
                if (directionNumber === 0 || (directionNumber === 1 && this.values.length === 2) || directionNumber === 2) {
                    this.index += directionNumber === 0 ? 1 : -1;
                    this.emitSwitch();
                }
            } else {
                this.index += directionNumber;
                this.emitSwitch();
            }
        }
    }

    private emitSwitch() {
        this.onSwitch.emit({
            index: this.index,
            value: this.values[this.index],
        });
    }
}