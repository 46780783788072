import 'moment/locale/pt-br';

import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';


import { FinanceExportService } from '#services/_finance-export/finance-export.service';
import { FilterStateService } from '../../pages/default/balance-sheet/filter-state.service';
import { GroupingStateService } from '../../pages/default/balance-sheet/grouping-state.service';
import { HierarchyStateService } from '../../pages/default/balance-sheet/hierarchy-state.service';
import { DetailTableComponent } from '../detailtable/detailtable.component';

declare let Slick: any;
declare let document: any;

@Component({
    selector: 'treetable',
    templateUrl: './treetable.component.html',
    styleUrls: ['./treetable.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TreeTableComponent {
    @ViewChild(DetailTableComponent, { static: true })
    public detailTable: DetailTableComponent;
    public asyncExport = false;
    public asyncDetailTable = false;
    public asyncDetailTitle = false;

    private columns = null;
    private rows = null;
    private dataView = null;
    private grid = null;

    constructor(
        private http: HttpClient,
        private filterState: FilterStateService,
        private groupingState: GroupingStateService,
        private hierarchyState: HierarchyStateService,
        private financeExportService: FinanceExportService,
        public router: Router,
    ) { }

    public closeDetailModal() {
        $('.DetailTableModal').removeClass('modal-open');
        $('#detailGrid .grid-canvas').addClass('hidden');
        this.detailTable.asyncDetailTitle = false;
        this.detailTable.hasData = true;
        this.detailTable.resetFilters();
        $('.pagination').addClass('invisible');
    }

    public loadData(data: any) {
        this.columns = this.extractColumns(data);
        this.rows = this.extractRows(data);
        const options = {
            defaultColumnWidth: 120,
            editable: false,
            enableCellNavigation: true,
            enableTextSelectionOnCells: true,
            frozenColumn: 0,
        };

        // initialize the model
        this.dataView = new Slick.Data.DataView({ inlineFilters: false });
        this.dataView.beginUpdate();
        this.dataView.setItems(this.rows);
        this.dataView.setFilter(this.filter.bind(this));
        this.dataView.endUpdate();

        // initialize the grid
        this.grid = new Slick.Grid('#myGrid', this.dataView, this.columns, options);

        // wire up model events to drive the grid
        this.grid.onClick.subscribe(this.onClick.bind(this));

        this.grid.onHeaderClick.subscribe(this.headerClick.bind(this));

        this.grid.onDblClick.subscribe(this.onDblClick.bind(this));

        this.grid.onContextMenu.subscribe(this.onContextMenu.bind(this));

        this.grid.onCellChange.subscribe((function (e, args) {
            this.dataView.updateItem(args.item.id, args.item);
        }).bind(this));

        this.dataView.onRowCountChanged.subscribe((function (e, args) {
            this.grid.updateRowCount();
            this.grid.render();
        }).bind(this));

        this.dataView.onRowsChanged.subscribe((function (e, args) {
            this.grid.invalidateRows(args.rows);
            this.grid.render();
        }).bind(this));

        this.grid.getCanvases().bind('mousedown', (event) => {
            if (event.detail > 1) {
                event.preventDefault();
            }
        });
    }

    private formatAccountName(row, cell, value, columnDef, dataContext) {
        value = value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        const indent = 'indent';
        const spacer = `<span style='display:inline-block;height:1px;width:${15 * dataContext[indent]}px'></span>`;

        const idx = this.dataView.getIdxById(dataContext.id);
        if (dataContext.alone) {
            return `${spacer} <span class='toggle'></span>&nbsp;${value.bold()}`;
        }
        if (this.rows[idx + 1] && this.rows[idx + 1].indent > this.rows[idx].indent) {
            if (dataContext._collapsed) {
                return `${spacer}  <span class='toggle fa fa-caret-right pointer'></span>&nbsp;&nbsp;${value}`;
            } else {
                return `${spacer} <span class='toggle fa fa-caret-down pointer'></span>&nbsp;&nbsp;${value}`;
            }
        } else {
            return `${spacer}<span style='margin-left: 13px;' class=''></span>&nbsp;${value}`;
        }
    }

    private extractColumns(data: any) {
        const cols = [];
        for (let c = 0; c < data.metadata.columns.length; c++) {
            const name = data.metadata.columns[c].header;
            const field = data.metadata.columns[c].field;
            const id = data.metadata.columns[c].id;
            if (c === 0) {
                cols.push({
                    id,
                    name,
                    field,
                    width: 400,
                    formatter: this.formatAccountName.bind(this),
                    validator: this.requiredFieldValidator.bind(this),
                    cssClass: 'pointer',
                });
            } else {
                cols.push({
                    id,
                    name: data.grouping === 'byClient' ?
                        `<div class="headerDetail"><i class='comexport-folder-2'></i>${name}<div>` :
                        data.grouping === 'byImp' ?
                        `<div class="headerDetail"><i class='flaticon-download'></i>${name}<div>` : name,
                    field,
                    width: data.grouping === 'byClient' ? 160 : 120,
                    client: data.grouping === 'byClient' ? id : false,
                    imp: data.grouping === 'byImp' ? id : false,
                });
            }
        }
        return cols;
    }

    private extractRows(data: any) {
        const dataGrid = [];
        const parents = [];
        let index = -1;
        for (let i = 0; i < data.results.length; i++) {
            index++;
            const indent = 0;
            const parent = null;
            const description = null;
            dataGrid[index] = data.results[i].data;
            if (data.results[i].data.description == null) {
                dataGrid[index].description = data.results[i].data.id;
            } else {
                dataGrid[index].description = data.results[i].data.description;
            }
            if (data.hierarchy === 'balance-sheet') {
                dataGrid[index]._collapsed = false;
            } else {
                dataGrid[index]._collapsed = true;
            }
            dataGrid[index].indent = indent;
            dataGrid[index].parent = parent;
            const children = data.results[i].children;
            if (children == null) {
                dataGrid[index].alone = true;
            } else {
                getChildren(children, indent, index);
            }
        }

        function getChildren(children, indentPassed, parent) {
            indentPassed++;
            for (let x = 0; x < children.length; x++) {
                index++;
                dataGrid[index] = children[x].data;
                if (children[x].data.description == null) {
                    dataGrid[index].description = children[x].data.id;
                } else {
                    dataGrid[index].description = children[x].data.description;
                }
                if (data.hierarchy === 'balance-sheet') {
                    dataGrid[index]._collapsed = false;
                    if (indentPassed === 3) {
                        dataGrid[index]._collapsed = true;
                    }
                } else {
                    dataGrid[index]._collapsed = true;
                }
                dataGrid[index].indent = indentPassed;
                dataGrid[index].parent = parent;
                const childParent = index;
                const child = children[x].children;
                if (child == null) {
                    dataGrid[index].isLeaf = true;
                } else {
                    getChildren(child, indentPassed, childParent);
                }
            }
        }
        return dataGrid;
    }

    private requiredFieldValidator(value) {
        if (value == null || value === undefined || !value.length) {
            return { valid: false, msg: 'This is a required field' };
        } else {
            return { valid: true, msg: null };
        }
    }

    private filter(item) {
        if (item.parent != null) {
            let parent = this.rows[item.parent];
            while (parent) {
                if (parent._collapsed) {
                    return false;
                }
                parent = this.rows[parent.parent];
            }
        }
        return true;
    }

    private headerClick(e, args) {
        if ($(e.target).is('i')) {
            if (args.column.client) {
                this.addLocalStorageData();
                window.open(`./report/${args.column.client}/imp`);
            } else if (args.column.imp) {
                this.asyncExport = true;
                this.financeExportService.exportZip(args.column.imp).subscribe(() => {
                    this.asyncExport = false;
                });
            }
        }
    }

    private addLocalStorageData() {
        localStorage.setItem('financeiro-filterState', JSON.stringify({
            startDate: this.filterState.getCurrent()?.startDate || '',
            endDate: this.filterState.getCurrent()?.endDate || '',
            client: this.filterState.getCurrent()?.client || [],
            profitCenter: this.filterState.getCurrent()?.profitCenter || [],
            trader: this.filterState.getCurrent()?.trader || [],
            director: this.filterState.getCurrent()?.director || [],
            currency: this.filterState.getCurrent()?.currency || '',
        }));
        localStorage.setItem('financeiro-groupingState', JSON.stringify(this.groupingState.getCurrent()));
        localStorage.setItem('financeiro-hierarchyState', JSON.stringify(this.hierarchyState.getCurrent()));
    }

    private onClick(e, args) {
        if ($(e.target.childNodes[2]).hasClass('toggle') || $(e.target).hasClass('toggle')) {
            const item = this.dataView.getItem(args.row);
            if (item) {
                if (!item._collapsed) {
                    item._collapsed = true;
                } else {
                    item._collapsed = false;
                }
                this.dataView.updateItem(item.id, item);
            }
            e.stopImmediatePropagation();
        }
        if ($(e.target).hasClass('slick-cell')) {
            const table = e.target.parentElement.parentElement;
            const item = this.dataView.getItem(args.row);
            let row = args.row;
            let col = args.cell;
            row = item.id;
            col = this.columns[col].id;
        }
    }

    private onDblClick(e, args) {
        const table = e.target.parentElement.parentElement;
        if ($(table).hasClass('grid-canvas grid-canvas-top grid-canvas-right')) {
            e.preventDefault();
            const cell = this.grid.getCellFromEvent(e);
            const item = this.dataView.getItem(cell.row);
            if (item.isLeaf) {
                const row = item.id;
                const col = this.columns[cell.cell];
                const content = $(e.target).text();
                $('#contextMenu').data('cell', { row, col, content });
                $('body').one('click', () => {
                    $('#contextMenu').hide();
                });
                if (col.name !== 'Total') {
                    this.detailTable.openDetailModal(0, false, false, this._identifyRowGroup(row));
                }
            }
        }
    }

    private _identifyRowGroup(rowId) {
        let rowIndex = this.rows.findIndex((row: any) => row.id === rowId);
        let indent = this.rows[rowIndex].indent;
        while (indent != 0) {
            rowIndex--;
            indent = this.rows[rowIndex].indent;
        }
        return this.rows[rowIndex].description;
    }

    private onContextMenu(e, args) {
        const table = e.target.parentElement.parentElement;
        if (
            $(table).hasClass(
                'grid-canvas grid-canvas-top grid-canvas-right',
            )
        ) {
            e.preventDefault();
            const cell = this.grid.getCellFromEvent(e);
            const item = this.dataView.getItem(cell.row);
            if (item.isLeaf) {
                const row = item.id;
                const col = this.columns[cell.cell];
                const content = $(e.target).text();
                $('#contextMenu')
                    .data('cell', { row, col, content })
                    .css('top', e.pageY)
                    .css('left', e.pageX)
                    .show();
                $('body').one('click', () => {
                    $('#contextMenu').hide();
                });
            }
        }
    }
}
