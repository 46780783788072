import { Injectable } from "@angular/core";
@Injectable()
export class CustomDialogService {
    private _dialogs: any[] = [];

    add(dialog: any) {
        this._dialogs.push(dialog);
    }

    remove(id: string) {
        this._dialogs = this._dialogs.filter((dialogInstance) => dialogInstance.id !== id);
    }

    open(id: string) {
        const dialog: any = this._dialogs.filter((dialogInstance) => dialogInstance.id === id)[0];
        if (dialog) {
            dialog.open();
        }
    }

    close(id: string) {
        const dialog: any = this._dialogs.filter((dialogInstance) => dialogInstance.id === id)[0];

        if (dialog) {
            dialog.close();
        }
    }
}
