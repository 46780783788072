import { fn, transformations } from './constants/node-transformations';
import { StructureSettings } from './constants/structure-settings';

export class NodeVisitor {
    private visitStyle: (node: any) => void;
    private visitDescription: (data: any, propertyName: string) => any;
    private visitAmount: (data: any, propertyName: string) => any;
    private showZeroed: boolean;
    private columnsWithAmounts = new Set<string>();

    constructor(private settings: StructureSettings) {
        this.visitStyle = transformations.style;
        this.visitDescription = transformations.description.accountCodes['' + settings.displayAccountCodes] || null;
        this.visitAmount = transformations.amounts[settings.amountsFormat] || null;
        this.showZeroed = settings.displayZeroedNodes;
    }

    public visit(nodes: any[], columns, traders?): { nodes: any[]; columns: Set<string> } {
        const fieldTypes = new Map<string, string>();
        columns.forEach((c) => fieldTypes.set(c.field, c.type));

        let newNodes = this.settings.unknownLines === 'hideUnknownLines'
                ? nodes.filter((node) => node.data.id !== '9')
                : nodes;

        for (const node of newNodes) {
            this.visitNode(node, fieldTypes);
            this.visitStyle(node); // only 1st level

            if (!this.showZeroed) {
                this.removeZeroedChildrenOf(node);
            }
        }
        if (!this.showZeroed && traders) {
            newNodes = this.removeZeroedNodeOf(nodes);
        }
        return {
            nodes: newNodes,
            columns: this.columnsWithAmounts,
        };
    }

    private visitNode(node: any, fieldTypes: Map<string, string>) {
        for (const property of Object.keys(node.data)) {
            if (property === 'id') {
                continue;
            }

            if (property === 'description' && this.visitDescription) {
                node.data[property] = this.visitDescription(
                    node.data,
                    property,
                );

            } else {

                if (fieldTypes.get(property) === 'percentage') {
                    node.data[property] = `${node.data[property]}%`;

                } else if (this.visitAmount) {
                    node.data[property] = this.visitAmount(node.data, property);
                }

                if (node.data[property] !== '0') {
                    this.columnsWithAmounts.add(property);
                }
            }
        }

        if (node.children) {
            for (const child of node.children) {
                this.visitNode(child, fieldTypes);
            }
        }
    }

    private removeZeroedNodeOf(nodes: any) {
        const newNodes = [];
        for (const node of nodes) {
            if (!this.isZeroed(node)) {
                newNodes.push(node);
            }
        }
        return newNodes;
    }

    private removeZeroedChildrenOf(node: any) {
        if (node.children) {
            const newChildren = [];
            for (const child of node.children) {
                if (!this.isZeroed(child)) {
                    newChildren.push(child);
                }

                if (child.children) {
                    this.removeZeroedChildrenOf(child);
                }
            }
            node.children = newChildren;
        }
    }

    private isZeroed(node: any) {
        for (const property of Object.keys(node.data)) {
            if (property === 'description' || property === 'id') {
                continue;
            }
            if (fn.toNumber(node.data[property]) !== 0 && node.data[property] !== '0,00%') {
                return false;
            }
        }
        return true;
    }
}
