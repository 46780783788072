export const TAB_GROUPS = {
    poStatus: [{
        code: 'po_detail',
        name: 'Dados P.O',
    },{
        code: 'imp',
        name: 'IMPs',
    },{
        code: 'sku',
        name: 'SKUs',
    }],
    skuPoStatus: [{
        code: 'po_detail',
        name: 'Dados P.O',
    },{
        code: 'imp',
        name: 'IMPs',
    }],
    spListing: [{
        code: 'sp_detail',
        name: 'Infos. Gerais',
    },{
        code: 'sp_exchange_detail',
        name: 'Infos. Cambiais',
    },{
        code: 'sp_documents',
        name: 'Documentos',
    },
    {
        code: 'sp_banking_channels',
        name: 'Canais Bancários',
    }],
    projectPricingProposes: [{
        code: 'project_pricing_proposes',
        name: '',
        hidden: true,
    }],
    projectPricingDetails: [{
        code: 'project_pricing_details',
        name: '',
        hidden: true,
    }],
    followUPSKU: [{
        code: 'followup_imp',
        name: 'Imp',
    },{
        code: 'followup_sku',
        name: 'SKU',
    },{
        code: 'followup_invoices',
        name: 'Invoices',
    },{
        code: 'followup_licenca',
        name: 'Licença',
    },{
        code: 'followup_embarque',
        name: 'Embarque',
    },{
        code: 'followup_desembaraco',
        name: 'Desembaraço',
    },{
        code: 'followup_entrega',
        name: 'Entrega',
    },{
        code: 'followup_historico',
        name: 'Histórico',
    },{
        code: 'followup_interno',
        name: 'Interno',
    },],
    followUPCHASSI: [{
        code: 'followup_imp',
        name: 'Imp',
    },{
        code: 'followup_chassi',
        name: 'Chassi',
    },{
        code: 'followup_invoices',
        name: 'Invoices',
    },{
        code: 'followup_licenca',
        name: 'Licença',
    },{
        code: 'followup_embarque',
        name: 'Embarque',
    },{
        code: 'followup_desembaraco',
        name: 'Desembaraço',
    },{
        code: 'followup_entrega',
        name: 'Entrega',
    },{
        code: 'followup_historico',
        name: 'Histórico',
    },{
        code: 'followup_interno',
        name: 'Interno',
    },],
    detailPremiseTabs: [{
        code: 'premise_resume',
        name: 'Resumo',
    }],
}
