import { FilterStateService } from '#services/_filters/filter-state.service';
import { Component, ViewEncapsulation } from '@angular/core';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import { NewTrafficMapFilter } from '../new-traffic-map/new-vessels/newTrafficMapFilter.model';
import { ContainerTrackingService } from './container-tracking.service';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';
import { CountryFlagsService } from '#services/_country-flags/country-flags.service';
import { UserService } from 'app/auth/_services';
import { NotificationService } from '#services/_notification/notification.service';
import mapboxgl from 'mapbox-gl';
import moment from 'moment';
import * as _ from 'lodash';
import { UtilsService } from '#services/_utils/utils.service';

@Component({
    selector: 'container-tracking-widget',
    templateUrl: './container-tracking.component.html',
    styleUrls: ['../charts.scss', './container-tracking.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [ContainerTrackingService]
})
export class ContainerTrackingWidgetComponent {

    array = Array;
    moment = moment;
    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);
    mapBox: mapboxgl;
    mapStyle: string = 'light-v10';
    filterState: NewTrafficMapFilter = this._filterStateService.getNewTrafficMapFilter();
    options: { clients: DropdownOption[] } = { clients: [] };
    summary = { totalVessel: 0, totalImps: 0 };
    vesselList: object[] = [];
    filteredVesselList: object[] = [];
    filteredImpList: object[] = [];
    asyncInit: boolean = true;

    asyncVesselModal: boolean = false;
    selectedVessel;
    selectedVesselImps = [];
    selectedVesselImp;
    vesselInfo = [];
    vesselSecoundaryInfo = [];
    vesselTimelineInfo = [];
    helpDescription = this._defineHelpDescription();

    centerCoordenateLine = { lat: 0, lng: 0 };
    markerList = [];

    currentUser = this._userService.currentUser$;
    pages: [] = [];
    impsPerPage: number = 0;
    page: number = 0;

    constructor(
        private _filterStateService: FilterStateService,
        private _cmxDropdownService: CmxDropdownService,
        private _userService: UserService,
        private _userCacheService: UserCacheService,
        private _customDialogService: CustomDialogService,
        private _containerTrackingService: ContainerTrackingService,
        private _countryFlagsService: CountryFlagsService,
        private _notificationService: NotificationService,
        private _utilsService: UtilsService
    ) { }

    ngAfterViewInit() {
        this.options.clients = this._cmxDropdownService.toDropdownItems(this._userCacheService.getClientGroups() || [], false);
        this.getVessels();
    }

    onSearch(search): void {
        this.filterState.setSearch(search);
        this.applyFilter();
    }

    onClientChanged($event): void {
        this.filterState.setClientGroups($event)
        this.applyFilter();
    }

    datesChanged(dates): void {
        if (!dates.invalid) {
            this.filterState.setStartDate(dates.startDate);
            this.filterState.setEndDate(dates.endDate);
        }
        this.applyFilter();
    }

    applyFilter(): void {
        this._removeLines();
        this.filteredImpList = [];
        this.filteredVesselList = [];
        this.vesselList.forEach((vessel) => {
            vessel['imps'].forEach((imp) => {
                if (this._verifyImpFilter(vessel, imp)) {
                    this.filteredImpList.push({ ...vessel, ...imp, firstImp: vessel['imp'], vesselEta: vessel['eta'] })
                }
            });
            if (this._verifyVesselFilter(vessel)) {
                this.filteredVesselList.push(vessel);
            }
        })
        this.pages = Array.apply(null, { length: Math.ceil(this.filteredImpList.length / this.impsPerPage) }).map( Number.call, Number);
        this.page = 0;
        this._calculateSummary();
        this.filterState.updateUserPreferences();
        this._addVesselsToMapBox(this.filteredVesselList);
        setTimeout(() => {
            this.mapBox?.setLayoutProperty('country-label', 'text-field', ['get',`name_pt`]);
        }, 200);
    }

    changePage(number): void {
        this.page += number;
    }

    openVesselModal(vessel, firstImp?, imp?): void {
        const atualVessel = firstImp ? this._findVesselByImp(firstImp) : vessel;
        if (atualVessel) {
            this.asyncVesselModal = true;
            this.selectedVessel = atualVessel;
            this._determineSelectedVesselImps(imp);
            this._addVesselsToMapBox([this.selectedVessel], true);
            this._populateModalInfo(this.selectedVesselImps.length ? this.selectedVesselImps[0] : null);
            this._customDialogService.open('container-tracking-vessel-modal');
            if (imp) {
                this.selectVesselImp(this.selectedVesselImps.filter(vessel => vessel.imp == imp)[0]);
            }
        } else {
            this._notificationService.openNotification('error', 'Algo deu errado com os detalhes desse navio, tente novamente mais tarde', 'Rastreamento de Container');
        }
    }

    closeVesselModal(): void {
        this._addVesselsToMapBox(this.filteredVesselList);
    }

    selectVesselImp(imp): void {
        if (imp) {
            this.selectedVesselImp = imp.imp || '';
            this._populateModalInfo(imp);
        }
    }

    getVessels(): void {
        this._containerTrackingService.newGetVessels(this.filterState).subscribe((response) => {
            this.summary = response?.resume || { }
            this.vesselList = response?.vessels ? response.vessels.map((vessel) => { return {
                ...vessel.imps[0], ...vessel
            }}) : [];
            this._determineImpPages();
            this.renderMap();
        }, (error) => {
            this._utilsService.errorHandler(error, 'Rastreamento de Containers');
        });
    }

    renderMap(): void {
        mapboxgl.accessToken =
            'pk.eyJ1Ijoic2lyZ2FzcGFyIiwiYSI6ImNrYjgwd3FhdTBiMnozMHFhOHVlZjdhazIifQ.7oKMLkdq-nkRhA8Q8kZsNQ';
        this.mapBox = new mapboxgl.Map({
            container: 'mapBox',
            style: `mapbox://styles/mapbox/${this.mapStyle}`,
            center: [0, 10],
            zoom: 0.6,
            optimize: true,
        });
        this.applyFilter();
        this.asyncInit = false;
    }

    private _determineImpPages(): void {
        const containerWidth = $('.imp-list-container').width();
        this.impsPerPage = Math.floor((containerWidth - 80) / 110);
    }

    private _calculateSummary(): void {
        this.summary.totalImps = 0;
        this.summary.totalVessel = 0;
        this.filteredVesselList.forEach((vessel) => {
            this.summary.totalVessel++;
            const impsAfterClientFilter = this.filterState.getClientGroups().length == 0 ? vessel['imps'].length : this._verifyImpClientGroupFilter(this.filterState.getClientGroups(), vessel['imps']);
            this.summary.totalImps += impsAfterClientFilter;
        })
    }

    private _verifyImpFilter(vessel, imp) {
        const reference = imp?.clientReference || '';
        if (this.filterState.getSearch()) {
            return imp.imp.includes(this.filterState.getSearch()) || reference.toLowerCase().includes(this.filterState.getSearch().toLowerCase())
        } else {
            return (this.filterState.getClientGroups().length == 0 || this.filterState.getClientGroups().map((c) => c['id']).join(',').includes(imp['groupCode'])) &&
            this._filterDateRange(vessel);
        }
    }

    private _verifyVesselFilter(vessel) {
        const selectedClientGroups = this.filterState.getClientGroups();
        const vesselClientGroups = vessel['imps']
        if (this.filterState.getSearch()) {
            return vessel['imps'].map((imp) => imp.imp).join(',').includes(this.filterState.getSearch()) ||
            vessel['imps'].map((imp) => (imp.clientReference || '').toLowerCase()).join(',').includes(this.filterState.getSearch().toLowerCase())
        } else {
            return (this.filterState.getClientGroups().length == 0 || this._verifyImpClientGroupFilter(selectedClientGroups, vesselClientGroups)) &&
            this._filterDateRange(vessel);
        }
    }

    private _verifyImpClientGroupFilter(selectedClientGroups, vesselClientGroups) {
        let intersection = 0;
        selectedClientGroups.forEach((selectedClientGroup) => {
            vesselClientGroups.forEach((vesselClientGroup) => {
                if (selectedClientGroup.id === vesselClientGroup.groupCode) {
                    intersection++;
                }
            })
        })
        return intersection;
    }

    private _determineSelectedVesselImps(imp) {
        this.selectedVesselImps = [];
        this.selectedVessel.imps.forEach((vesselImp) => {
            if (this.filterState.getClientGroups().length === 0 || this.filterState.getClientGroups().map((c) => c['id']).join(',').includes(vesselImp['groupCode'])) {
                this.selectedVesselImps.push({ ...vesselImp, code: vesselImp?.imp, name: vesselImp?.imp })
            }
        });
        this.selectedVesselImp = this.selectedVesselImps.length ? imp || this.selectedVesselImps[0].imp : null;
    }

    private _filterDateRange(vessel) {
        if (this.filterState.getStartDate() && this.filterState.getEndDate()) {
            return moment(vessel['eta'], 'YYYY-MM-DD').isBetween(
                moment(this.filterState.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD'),
                moment(this.filterState.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD'),
                undefined, '[]')
        } else if (this.filterState.getStartDate()) {
            return moment(vessel['eta'], 'YYYY-MM-DD').isSameOrAfter(
                moment(this.filterState.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD'))
        } else if (this.filterState.getEndDate()) {
            return moment(vessel['eta'], 'YYYY-MM-DD').isSameOrBefore(
                moment(this.filterState.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD'))
        } else {
            return true;
        }
    }

    private _defineHelpDescription(): object[] {
        return [
            { type: 'text', hasLineAfter: true,  value: 'Informa a posição atual dos aviões ou navios mercantes através do código MMSI.'},
            { type: 'text', hasLineAfter: true,  value: 'No modo de navegação por navios mercantes, é possivel ter acesso às previsões de atracação, data de saída dos portos, origem e destino.'},
            { type: 'text', value: "No modo de navegação por aviões, é possível obter acesso as previsões de origem e destino, eventos que ocorrem no percurso do avição."},
        ]
    }

    private _populateModalInfo(imp): void {
        let countryFrom = '';
        this._countryFlagsService.getReduzedName(imp?.countryFrom || '').then((result) => {
            countryFrom = result;
        })
        let countryTo = '';
        this._countryFlagsService.getReduzedName(imp?.countryTo || '').then((result) => {
            countryTo = result;
        })
        setTimeout(() => {
            this.vesselInfo = [
                { title: 'Navio Transbordo', value: this.selectedVessel?.vehicleVADName  || '' },
                { title: 'IMP', value: imp?.imp || '' },
                { title: 'Referência', value: imp?.clientReference || '' },
                { title: 'Procedência', value: countryFrom, flag: imp?.countryFrom || '' },
                { title: 'Destino', value: countryTo, flag: imp?.countryTo },
            ]
            this.vesselSecoundaryInfo = [
                { title: 'Situação', value: `${this.selectedVessel?.situation?.accomplished}%` || '' },
                { title: 'Conhecimento Embarque', value: imp?.trackingNumber || '' },
                { title: 'Porto Embarque', value: imp?.harborFrom || '' },
                { title: 'Agente', value: imp?.customAgentName || '' },
                { title: 'Cliente', value: imp?.groupName || '' },
            ]
            this.vesselTimelineInfo = [
                { title: 'Prev. Embarque', value: imp?.boardingForecastDate || '' },
                { title: 'Embarcado', value: imp?.shippingDate || '' },
                { title: 'Prev. Atracação', value: this.selectedVessel?.eta || '' },
            ]
            this.asyncVesselModal = false;
        }, 300);
    }

    private _addVesselsToMapBox(vessels, reposition?): void {
        $('.marker').remove();
        this.markerList = [];
        vessels.forEach((vessel) => {
          if ((vessel?.pin?.latitude && vessel?.pin?.longitude) || (vessel?.latitude !== 0 && vessel?.longitude !== 0)) {
            const el = document.createElement('div');
            const img = document.createElement('img');
            img.src = 'assets/img/icons/map_vessel.svg';
            el.id = `${vessel.imp}`;
            img.setAttribute('style', `transform: rotate(${vessel?.pin?.angle || 0}deg)`);
            if (vessel?.pin?.angle) {
                el.appendChild(img);
                el.className = 'marker';
            } else {
                el.className = 'marker round';
            }
            el.addEventListener('mouseover', (element) => {
                const imp = $(element.target).attr('id');
                this._createRouteLine(this._findVesselByImp(imp));
            })
            el.addEventListener('click', (element) => {
                const imp = $(element.target).attr('id');
                this.openVesselModal(this._findVesselByImp(imp))
            });
            const currentPin = vessel?.pin?.latitude && vessel?.pin?.longitude ? [vessel.pin.longitude, vessel.pin.latitude]
                : [vessel.longitude, vessel.latitude]
            this._createInfoPopover(vessel, el, currentPin);
            const marker = new mapboxgl.Marker(el).setLngLat(currentPin).addTo(this.mapBox);
            this.markerList.push(marker);
          }
        });
        const bounds = new mapboxgl.LngLatBounds();
        this.markerList.forEach((feature) => {
          bounds.extend(feature._lngLat);
        });
        if (bounds._ne !== undefined && bounds._ne.lng !== undefined &&
        bounds._sw !== undefined && bounds._sw.lat !== undefined) {
          const lngRange = Math.abs(bounds._ne.lng) + Math.abs(bounds._sw.lng);
          bounds._ne.lat += 14 - (reposition ? 5 : 0);
          bounds._ne.lng += lngRange / 7;
          bounds._sw.lat -= 20;
          bounds._sw.lng -= lngRange / 7;
          this.centerCoordenateLine = {
            lat: (bounds._ne.lat + bounds._sw.lat) / 2,
            lng: (bounds._ne.lng + bounds._sw.lng) / 2,
          };
          this.mapBox.fitBounds(bounds);
        }
    }

    private _createInfoPopover(vessel, el, currentPin): void {
        const info = document.createElement('div');
        info.className = `info ${currentPin[1] < 0 ? 'top' : 'bottom'} ${
            vessel?.pin?.angle ? 'angle' : ''}`;
        const headerContainer = document.createElement('div');
        headerContainer.className = 'headerContainer';
        const vesselName = document.createElement('span');
        const vesselImg = document.createElement('img');
        vesselImg.src = 'assets/img/icons/vessel.svg';
        vesselName.textContent = `${vessel.vehicleName}`;
        headerContainer.appendChild(vesselImg);
        headerContainer.appendChild(vesselName);
        let vesselImps = [];
        vessel['imps'].forEach((imp) => {
            if (this._verifyImpFilter(vessel, imp)) {
                vesselImps.push(imp)
            }
        });
        const infoLine = document.createElement('span');
        const clientLine = document.createElement('span');
        infoLine.textContent = vesselImps.length === 1 ? `IMP ${vesselImps[0].imp}` : `${vesselImps.length} IMPs`;
        const clients = this.filterState.getSearch() ? vessel.imps.map((imp) => { return imp.groupName }) : this._defineModalClients(vessel)
        const uniqueClients = clients.filter((client, i, ar) => ar.indexOf(client) === i)
        clientLine.textContent = uniqueClients.length > 1 ? `${uniqueClients.length} Clientes` : `${uniqueClients[0] || ''}`;
        info.appendChild(headerContainer);
        info.appendChild(document.createElement('hr'));
        const transhipmentShip = document.createElement('span');
        transhipmentShip.textContent = `Transbordo: ${vessel.vehicleVADName}`;
        if (vessel.vehicleVADName) {
            info.appendChild(transhipmentShip);
        }
        info.appendChild(infoLine);
        if (this._userService.getCurrentUser().has('INTERNAL_FILTERS')) {
            info.appendChild(clientLine);
        }
        info.appendChild(document.createElement('hr'));
        const dockingText = document.createElement('span');
        dockingText.textContent = `ETA: ${vessel.eta ? moment(vessel.eta, 'YYYY-MM-DD').format('DD/MM/YYYY') : 'sem previsão'}`;
        info.appendChild(dockingText);
        el.appendChild(info);
    }

    private _defineModalClients(vessel) {
        const vesselClients = vessel.imps.map((imp) => { return imp.groupName })
        const filterClients = this.filterState.getClientGroups().map((client) => { return client.itemName })
        return this.filterState.getClientGroups().length == 0 ? vesselClients : vesselClients.filter((client) => filterClients.includes(client));
    }

    private _createRouteLine(vessel): void {
        this._removeLines();
        let previousLati = 0;
        let previousLong = 0;
        let currentRoute = 0;
        let vesselRoutes = { 0: [] };
        const route = vessel ? vessel.routes || [] : [];
        route.forEach((route) => {
            if (route.type === 'SEA') {
                route.positions.forEach((position) => {
                    currentRoute = this._verifyExtremityHemisphereChange(position, previousLong, previousLati, vesselRoutes, currentRoute);
                    vesselRoutes[currentRoute].push([position.longitude, position.latitude]);
                    previousLati = position.latitude;
                    previousLong = position.longitude;
                })
            }
        })
        Object.entries(vesselRoutes).forEach((route, index) => {
            this.mapBox.addSource(`route${index}`, {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    properties: { },
                    geometry: {
                        type: 'LineString',
                        coordinates: route[1],
                    }
                }
            });
            this.mapBox.addLayer({
                id: `route${index}`,
                type: 'line',
                source: `route${index}`,
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                paint: {
                    'line-color': '#5a6e88',
                    'line-width': 2
                }
            });
        })
    }

    private _verifyExtremityHemisphereChange(position: any, previousLong: number, previousLati: number, vesselRoutes: { 0: any[]; }, currentRoute: number) {
        const extremityPosition = Math.abs(position.longitude) > 70;
        const hemisphereChanged = (previousLong && (Math.sign(previousLong) !== Math.sign(position.longitude)));
        if (extremityPosition && hemisphereChanged) {
            const latitudeMeeting = (previousLati + position.latitude) / 2;
            vesselRoutes[currentRoute].push([previousLong > 0 ? 180 : -180, latitudeMeeting]);
            currentRoute++;
            vesselRoutes[currentRoute] = [];
            vesselRoutes[currentRoute].push([position.longitude > 0 ? 180 : -180, latitudeMeeting]);
        }
        return currentRoute;
    }

    private _removeLines(): void {
        if (this.mapBox) {
            if (this.mapBox.getLayer(`route0`)) { this.mapBox.removeLayer(`route0`) }
            if (this.mapBox.getLayer(`route1`)) { this.mapBox.removeLayer(`route1`) }
            if (this.mapBox.getSource(`route0`)) { this.mapBox.removeSource(`route0`) }
            if (this.mapBox.getSource(`route1`)) { this.mapBox.removeSource(`route1`) }
        }
    }

    private _findVesselByImp(imp) {
        return this.filteredVesselList.filter((object) => { return object['imp'] === imp})[0];
    }

}
