import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'cmx-simple-line-chart',
    templateUrl: './cmx-simple-line-chart.component.html',
    styleUrls: ['./cmx-simple-line-chart.component.scss'],
})
export class SimpleLineChartComponent {
    @Input() chartData: any[] = [];
    @Input() clickable: boolean = false
    @Input() visualSelection: boolean = true;
    @Input() percentageValue: boolean = false;
    @Input() height: string = '14px';
    @Input() inverseLegend: boolean = false;
    @Input() legendFontSize: string = '12px';
    @Input() legendBold: boolean = true;
    @Input() legendSquareSize: string = '15px';
    @Input() minSectionSize: string = '55px';

    @Output('onSelect') onSelect = new EventEmitter();

    asyncData: boolean = true;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.chartData) {
            this._verifyNoData();
        }
    }

    selectItem(item) {
        const value = typeof item.value === 'string' ? parseFloat(item.value) : item.value;
        if (this.clickable && value) {
            item['selected'] = item['selected'] ? false : true;
            this.onSelect.emit(item);
        }
    }

    verifyWidth(data) {
        return !this.chartData.some((data) => data.value) ? 
        (100/this.chartData.length + '%') : (data.value + '%');
    }

    private _verifyNoData() {
        this.asyncData = true;
        this.chartData.forEach((data: any, index) => {
            data['value'] = typeof data['value'] === 'string' ? parseFloat(data['value']) : data['value'];
            if (!data['value']) { 
                data.selected = false;
            } 
            if (index === this.chartData.length - 1) {
                this.asyncData = false;
            }
        })
    }

}
