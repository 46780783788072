
import { ROUND_SETTINGS } from "../constants/round-colors.constant";
import { multipleFormatter } from "./multiple.formatter";
import { _applyPosFormatters } from "./pos-formatters/pos-formatters";

export function roundFormatter(row, cell, value, columnDef, dataContext) {
    const currentValue = columnDef.formatterData && columnDef.formatterData.singleField ? value[columnDef.formatterData.singleField] : value;
    const roundType = (columnDef.formatterData && columnDef.formatterData.roundType) || 'none';
    const roundColorDefault = (columnDef.formatterData && columnDef.formatterData.roundColor) || '';
    const needValue = (columnDef.formatterData && columnDef.formatterData.needValue) || false;
    const emptyBall = (columnDef.formatterData && columnDef.formatterData.emptyBall) || false;
    const showNumberOne = (columnDef.formatterData && columnDef.formatterData.showNumberOne) || false;
    const defaultMultiple = (columnDef.formatterData && columnDef.formatterData.defaultMultiple) || false;
    const useDefaultValueWhenSingle = (columnDef.formatterData && columnDef.formatterData.useDefaultValueWhenSingle) || false;
    const isNumber = /^\d+$/.test(currentValue);

    if (((Array.isArray(currentValue) && currentValue.length === 1) || (currentValue && typeof currentValue === 'string')) && useDefaultValueWhenSingle) {
        return _applyPosFormatters(row, cell,Array.isArray(currentValue) ? currentValue[0] : currentValue, columnDef, dataContext);
    } else if (roundType === 'none') {
        const roundColor = ROUND_SETTINGS.roundColors[currentValue] || roundColorDefault;

        if (Array.isArray(currentValue) && currentValue.length > 1 && defaultMultiple) {
            return multipleFormatter(row, cell, value, columnDef, dataContext);
        }

        return `<div style="width: 100%; display: flex; justify-content: center"><div class="round ${roundType}" style="background-color: ${roundColor}"></div></div>`;
    } else if(roundType === 'number' && currentValue >= 1) {
        if (needValue) {
            if (isNumber) {
                if (parseInt(currentValue) >= 1 || (showNumberOne && parseInt(currentValue) == 1)) {
                    return `<div class="round ${roundType}"><span>${currentValue}</span></div>`;
                }
            }
            const sideText = dataContext.roundText ? `<span style="margin-left: 4px">${dataContext.roundText}</span>` : '';
            const contentValue = `<div class="round ${roundType} ${sideText ? 'hasText' : ''}">${sideText}</div>`;
            return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
        }
    } else {
        const roundIcon = ROUND_SETTINGS.roundIcons[roundType];
        const roundColor = ROUND_SETTINGS.roundColors[currentValue] || roundColorDefault || '';
        if (needValue) {

            if (!currentValue) {
                if (emptyBall) {
                    return `<div class="round none empty"><span></span></div>`;
                }
                return _applyPosFormatters(row, cell, '', columnDef, dataContext)
            }

            if (Array.isArray(currentValue)) {
                if (currentValue.length > 1 || (showNumberOne && currentValue.length == 1)) {
                    return defaultMultiple ? multipleFormatter(row, cell, value, columnDef, dataContext) : `<div class="round ${roundType}" ${(roundColor ? 'style="background-color: ' + roundColor : '')}"><span>${currentValue.length}</span></div>`;
                }
            }

            if (isNumber) {
                if (parseInt(currentValue) > 1 || (showNumberOne && parseInt(currentValue) == 1)) {
                    return `<div class="round ${roundType}"><span>${currentValue}</span></div>`;
                }
            }
        }
        const sideText = dataContext.roundText ? `<span style="margin-left: 4px">${dataContext.roundText}</span>` : '';
        const contentValue = `<div class="round ${roundType} ${sideText ? 'hasText' : ''}"><span class="material-icons">${roundIcon}</span>${sideText}</div>`;
        return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
    }
}
