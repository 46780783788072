import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { IRightButton, ITopButton } from '../cmx-page-wrapper/interfaces/top-button.interface';
import { ISwitchOption } from '../cmx-switch/cmx-switch.interface';

@Component({
    selector: 'cmx-splitter-line',
    templateUrl: './cmx-splitter-line.component.html',
    styleUrls: ['./cmx-splitter-line.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CmxSplitterLineComponent implements OnInit {
    // Splitter Line
    @Input() subtitle: boolean = false;
    @Input('title') title: string = '';
    @Input('icon') iconLine?: string = '';
    @Input() info?: string;
    @Input() infoNoWrap?: boolean = true;
    @Input() placement?: string;
    // List Buttons
    @Input() buttonList?: ITopButton[] = [];
    // Right Buttons
    @Input() rightButton?: boolean = false;
    @Input() rightButtonList?: IRightButton[] = [];
    @Input() selectedRightButton: IRightButton;
    // Switch
    @Input() switch?: ISwitchOption[] = [];
    @Input() selectedSwitch?: string;
    @Input() checkChangeSwitch?: boolean = false;
    @Input() switchName: string = '';
    @Input('enabled') enabledSwitchSection: boolean = false;

    @Output() listButtonClick = new EventEmitter();
    @Output() onSwitchSelect = new EventEmitter();
    @Output() onRightButtonClick = new EventEmitter();

    selectedTopMultipleButton: ITopButton;
    showConfirmationModal: boolean = false;
    switchEvent: any;

    constructor() {}

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges) {
        this._defineInitialTopButtonColor();
        if (changes.switch && changes.switch.previousValue != this.switch && !this.selectedSwitch) {
            this.selectedSwitch = Array.isArray(this.switch) && this.switch.length ? this.switch[0].name : null;
            this._defineInitialTopButtonColor();
        }
    }

    selectSwitch(event: any) {
        if (!this.checkChangeSwitch) {
            this.changeSwitchPage(event);
        } else {
            this.switchEvent = event;
            this.showConfirmationModal = true;
        }
    }

    listButtonClicked(button: ITopButton, event: Event) {
        if (button.multiple) {
            this.selectedTopMultipleButton = this.selectedTopMultipleButton?.code === button.code ? null : button;
        } else {
            this.selectedTopMultipleButton = null;
            if (!button.disabled) {
                this.listButtonClick.emit(button);
            }
        }
        event.stopPropagation();
    }

    rightButtonClicked(button: ITopButton) {
        this.selectedRightButton = button;
        this.onRightButtonClick.emit(button);
    }

    listButtonMouseHover(button: ITopButton, out?: boolean, subItem?: ITopButton) {
        const element = document.getElementById(subItem ? subItem.code : button.code);
        if (out) {
            element.style.backgroundColor = button.color || '#b1b1b1';
            element.style.color = button.textColor || '#265b87';
        } else {
            element.style.backgroundColor = button.hoverColor || button.color || '#b1b1b1';
            element.style.color = button.textHoverColor || 'white';
        }
        event.stopPropagation();
    }

    changeSwitchPage(event: any) {
        this.showConfirmationModal = false;
        this.selectedSwitch = event.name;
        this.onSwitchSelect.emit(event);
    }

    private _defineInitialTopButtonColor() {
        if (this.buttonList) {
            this.buttonList.forEach((button) => {
                button.color = button.color ? button.color : '#ddd';
                button.hoverColor = button.hoverColor ? button.hoverColor : '#265b87';
                button.textColor = button.textColor ? button.textColor : 'grey';
                button.textHoverColor = button.textHoverColor ? button.textHoverColor : 'white';
            });
        }
    }
}
