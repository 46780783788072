<div *ngIf="asyncInvoiceAmountImpQty == false">
    <loader></loader>
</div>

<h5>{{ invoiceAmountName }} {{ impQtyName }}</h5>
<div class="moveBtn">
    <div class="moveLine"></div>
</div>
<i class="resizeBtn solo la la-expand" (click)="chartService.resizeBtn($event)"></i>
<div class="chart" id="invoices-processes"></div>
<div *ngIf="asyncInvoiceAmountImpQty === true && chartData === undefined" class="reloadContainer">
    <div class="reloadContent">
        <i (click)="loadInvoiceAmount_impQty(currentUser)" class="material-icons">refresh</i>
    </div>
</div>

<div *ngIf="currentUser$ | async as user"  id='searchLine' class="reduzed">
    <cmx-dropdown *ngIf="user.has('INTERNAL_FILTERS')"
        id="clients" name="clients" [data]="options.clients" [(ngModel)]="selectedClients"
        [settings]="dropdownSettings.clientGroups">
    </cmx-dropdown>
    <div class="input-group right">
        <span class="input-group-addon" style="margin: 0 -6px;"></span>
    </div>
    <div class="input-group">
        <div *ngIf="currentUser?.has('API_AUTOMOTIVE_CHANNEL') && !currentUser?.has('AUTOMOTIVE_USER')"
        title="Modo Automotivo: {{filterAutomotiveMode}}" class="filterMode"
        (click)="toggleFilterMode()">
            <div><i class="material-icons">directions_car</i></div>
        </div>
    </div>
    <div class="input-group right">
        <span class="input-group-addon">De</span>
        <input (keyup)="verifyDates('startMonth', startMonth)" [(ngModel)]="startMonth" name="startDatePicker" id="startDatePicker" type="text"
        class="form-control m-input date-impStatusTable" style="width:125px"/>
    </div>
    <div class="input-group right">
        <span class="input-group-addon">Até</span>
        <input (keyup)="verifyDates('endMonth', endMonth)" [(ngModel)]="endMonth" name="endDatePicker" id="endDatePicker" type="text"
        class="form-control m-input date-impStatusTable" style="width:125px"/>
    </div>
    <button (click)="togglePeriodSwitch()" class="applyImpStatus">
        <i class="fa fa-check"></i>
    </button>
</div>
