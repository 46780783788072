import { Injectable} from '@angular/core';
import moment from 'moment';

@Injectable()
export class FollowUpReportClearenceFormatter {

    // Documentos Originais
    public formatOldOriginalDocuments(row, cell, value, columnDef, dataContext) {

        const documents = [];
        if (value === null || value === undefined || value === '') {
            return '';
        }

        value.forEach((document) => {
            if (!documents.includes(document.documentNumberDelivered)
            && document.documentNumberDelivered !== null
            && document.documentNumberDelivered !== '') {
                documents.push({
                    doc: document.documentNumberDelivered,
                    date: document.documentDeliveryDate,
                });
            }
        });
        if (documents.length === 1 && documents[0].doc !== null && documents[0].date !== null) {
            return `<span>${documents[0].doc}</span>`;
        } else if (documents.length > 1) {
            return `<span class="multipleDocuments">Vários (${documents.length})</span>`;
        } else {
            return '';
        }
    }

    public formatOldOriginalDocumentsDate(row, cell, value, columnDef, dataContext) {

        const documents = [];
        if (value === null || value === undefined || value === '') {
            return '';
        }

        value.forEach((document) => {
            if (!documents.includes(document.documentDeliveryDate)
            && document.documentDeliveryDate !== null
            && document.documentDeliveryDate !== '') {
                documents.push({
                    doc: document.documentNumberDelivered,
                    date: document.documentDeliveryDate,
                });
            }
        });
        if (documents.length === 1 && documents[0].doc !== null && documents[0].date !== null) {
            return `<span>${moment(documents[0].date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (documents.length > 1) {
            return `<span class="multipleDocuments">Vários (${documents.length})</span>`;
        } else {
            return '';
        }
    }

    // Documentos Originais Operacional
    public formatOriginalDocuments(row, cell, value, columnDef, dataContext) {

        const documents = [];
        if (value === null || value === undefined || value === '') {
            return '';
        }

        value.filter((d) => d.documentAreaIdentifier === 'OPERATIONAL').forEach((document) => {
            if (!documents.includes(document.operationalDocumentNumberDelivered)
            && document.operationalDocumentNumberDelivered !== null
            && document.operationalDocumentNumberDelivered !== '') {
                documents.push({
                    doc: document.operationalDocumentNumberDelivered,
                    date: document.operationalDocumentDeliveryDate,
                });
            }
        });
        if (documents.length === 1 && documents[0].doc !== null && documents[0].date !== null) {
            return `<span>${documents[0].doc}</span>`;
        } else if (documents.length > 1) {
            return `<span class="multipleOperationalDocuments">Vários (${documents.length})</span>`;
        } else {
            return '';
        }
    }

    // Data Receb. Documentos Operacional
    public formatOriginalDocumentsDate(row, cell, value, columnDef, dataContext) {

        const documents = [];
        if (value === null || value === undefined || value === '') {
            return '';
        }

        value.filter((d) => d.documentAreaIdentifier === 'OPERATIONAL').forEach((document) => {
            if (!documents.includes(document.operationalDocumentDeliveryDate)
            && document.operationalDocumentDeliveryDate !== null
            && document.operationalDocumentDeliveryDate !== '') {
                documents.push({
                    doc: document.operationalDocumentNumberDelivered,
                    date: document.operationalDocumentDeliveryDate,
                });
            }
        });
        if (documents.length === 1 && documents[0].doc !== null && documents[0].date !== null) {
            return `<span>${moment(documents[0].date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (documents.length > 1) {
            return `<span class="multipleOperationalDocuments">Vários (${documents.length})</span>`;
        } else {
            return '';
        }
    }

    // Documentos Originais Desembaraço
    public formatClearingOriginalDocuments(row, cell, value, columnDef, dataContext) {

        const documents = [];
        if (value === null || value === undefined || value === '') {
            return '';
        }

        value.filter((d) => d.documentAreaIdentifier === 'CLEARING').forEach((document) => {
            if (!documents.includes(document.clearingDocumentNumberDelivered)
            && document.clearingDocumentNumberDelivered !== null
            && document.clearingDocumentNumberDelivered !== '') {
                documents.push({
                    doc: document.clearingDocumentNumberDelivered,
                    date: document.clearingDocumentDeliveryDate,
                });
            }
        });
        if (documents.length === 1 && documents[0].doc !== null && documents[0].date !== null) {
            return `<span>${documents[0].doc}</span>`;
        } else if (documents.length > 1) {
            return `<span class="multipleClearingDocuments">Vários (${documents.length})</span>`;
        } else {
            return '';
        }
    }

    // Data Receb. Documentos Desembaraço
    public formatClearingReceivedDocDate(row, cell, value, columnDef, dataContext) {

        const documents = [];
        if (value === null || value === undefined || value === '') {
            return '';
        }

        value.filter((d) => d.documentAreaIdentifier === 'CLEARING').forEach((document) => {
            if (!documents.includes(document.clearingDocumentDeliveryDate)
            && document.clearingDocumentDeliveryDate !== null
            && document.clearingDocumentDeliveryDate !== '') {
                documents.push({
                    doc: document.clearingDocumentNumberDelivered,
                    date: document.clearingDocumentDeliveryDate,
                });
            }
        });
        if (documents.length === 1 && documents[0].doc !== null && documents[0].date !== null) {
            return `<span>${moment(documents[0].date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (documents.length > 1) {
            return `<span class="multipleClearingDocuments">Vários (${documents.length})</span>`;
        } else {
            return '';
        }

    }

    // Imp Entreposto
    public formatWarehouseNumber(row, cell, value, columnDef, dataContext) {

        if (value === null || value === undefined || value === '' || value.length === 0) {
            return '';
        }

        if (value.length > 1) {
            return `<span class="multipleDi">Vários (${value.length})</span>`;
        }

        const warehouseNumber = value[0].warehouseNumber;
        if (warehouseNumber === '' || warehouseNumber === null || warehouseNumber === undefined ) {
            return '';
        }

        return warehouseNumber;
    }

    // Número DI
    public formatDiNumber(row, cell, value, columnDef, dataContext) {

        if (value === null || value === undefined || value === '' || value.length === 0) {
            return '';
        }

        if (value.length > 1) {
            return `<span class="multipleDi">Vários (${value.length})</span>`;
        }

        const diNumber = value[0].registrationNumber;
        if (diNumber === '' || diNumber === null || diNumber === undefined ) {
            return '';
        }

        return diNumber;
    }

    // Registro DI
    public formatDiRegistrationDate(row, cell, value, columnDef, dataContext) {

        if (value === null || value === undefined || value === '' || value.length === 0) {
            return '';
        }

        if (value.length > 1) {
            return `<span class="multipleDi">Vários (${value.length})</span>`;
        }

        const date = value[0].registrationDate;
        if (date === '' || date === null || date === undefined ) {
            return '';
        }

        return moment(date).format('DD/MM/YYYY');
    }

       // Protocolo do Registro da DI
    public formatDiRegistrationProtocol(row, cell, value, columnDef, dataContext) {
        if (value === null || value === undefined || value === '' || value.length === 0) {
            return '';
        }

        if (value.length > 1) {
            return `<span class="multipleDi">Vários (${value.length})</span>`;
        }

        const registrationProtocol = value[0].registrationProtocol;
        if (registrationProtocol === '' || registrationProtocol === null || registrationProtocol === undefined ) {
            return '';
        }

        return registrationProtocol;
    }

    // Canal DI
    public formatDiChannel(row, cell, value, columnDef, dataContext) {

        if (value === null || value === undefined || value === '' || value.length === 0) {
            return '';
        }

        if (value.length > 1) {
            return `<span class="multipleDi">Vários (${value.length})</span>`;
        }

        const channel = value[0].channel;
        if (channel === '' || channel === null || channel === undefined ) {
            return '';
        }

        switch (channel) {
            case 'VERDE':
            return `<div class='ball ${channel}'></div>`;
            case 'AMARELO':
            return `<div class='ball ${channel}'></div>`;
            case 'VERMELHO':
            return `<div class='ball ${channel}'></div>`;
            case 'CINZA':
            return `<div class='ball ${channel}'></div>`;
            case 'SEM PARAMETRIZAÇÃO':
            case 'SEM PARAMETRIZAC?O':
            return '<span class = "SemParametro">SEM PARAMETRIZAÇÃO</span>';
            case 'DESCONHECIDO':
            return '<span>-</span>';
        }
    }

    // Desembaraço DI
    public formatDiClearanceDate(row, cell, value, columnDef, dataContext) {

        if (value === null || value === undefined || value === '' || value.length === 0) {
            return '';
        }

        if (value.length > 1) {
            return `<span class="multipleDi">Vários (${value.length})</span>`;
        }

        const date = value[0].clearanceDate;
        if (date === '' || date === null || date === undefined ) {
            return '';
        }

        return moment(date).format('DD/MM/YYYY');
    }

}
