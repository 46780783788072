<div class="historic-wrapper">
    <ng-container *ngFor="let item of historicInformation; let last = last; let first = first;">
        <div class="historic">
            <div class="historic-information">
                <div class="historic-item">
                    <div class="historic-title">
                        <div>
                            <h5>{{ item.title }}</h5>
                        </div>
                        <div class="historic-icon" *ngIf="item.showIcon" (click)="iconClicked(item)"
                            [tooltip]="iconTooltip" [nowrap]="true">
                            <span class="material-icons" aria-hidden="true">read_more</span>
                        </div>
                    </div>
                    <div class="historic-data">
                        <div class="user-data">
                            <span class="historic-user">{{ item?.user }}</span>
                            <span>{{ item.date }}</span>
                        </div>
                        <div class="circle-line">
                            <hr class="line" />
                            <div class="circle" [tooltip]="item.tooltip" [placement]="'left'" [style.backgroundColor]="item.colorBall">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="historic-description"
                    [ngClass]="{ 'no-subtitle': !item.subtitle, 'with-margin': !item.listItems || item.listItems.length === 0 }">
                    <span>{{ item.subtitle }}</span>
                    <p>{{ item.description }}</p>
                </div>
                <div class="historic-list">
                    <ul>
                        <li *ngFor="let listItem of item.listItems; let i = index">
                            <span *ngIf="listItem?.key">{{ listItem?.key }}:</span> {{ listItem?.value }}
                        </li>
                    </ul>
                </div>
                <hr class="vertical-line" />
                <hr class="first-vertical-line" *ngIf="!first" />
            </div>
        </div>
        <hr class="horizontal-line" *ngIf="!last" />
    </ng-container>
</div>
