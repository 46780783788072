<div class="mainContainer">
    <div *ngIf="asyncKeyGeneration" class="fullLoader"><loader></loader></div>
    <div class="title">
        <img src="assets/img/icons/key.png" alt="" />
        <span>Chave de Segurança de APIs</span>
    </div>
    <div class="keyContainer">
        <span>
            Caso deseje utilizar nossas APIs, é necessário o uso da chave de segurança. Clique para gerar uma exclusiva
            para você.
        </span>
        <div class="p-field w-100 align-items-center">
            <input pInputText [(ngModel)]="key" id="inputKey" type="text" readonly />
        </div>
        <cmx-button *ngIf="key.length === 0" id="api-generate-key" variant="warning" (click)="generateKey()">GERAR CHAVE DE SEGURANÇA</cmx-button>
        <div *ngIf="key.length > 0" class="copyKeyButton" (click)="copyToClipboard()">
            <span class="material-icons">content_copy</span>
            <span class="text">{{ copiedText }}</span>
        </div>
        <span class="errorMessage">{{ errorMessage }}</span>
    </div>
</div>
