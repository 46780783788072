import { customIconPosFormatter } from "./custom-icon.formatters";

// Inclusões Globais, indiferente de formatadores (pós formatação).
export function _applyPosFormatters(_row, _cell, value, columnDef, dataContext, preventPrefixSuffix?: boolean) {
    const customIcon = (columnDef.formatterData && columnDef.formatterData.customIcons) || null;
    const finalValue = preventPrefixSuffix ? value : (`${columnDef?.formatterData?.prefix || ''} ${value} ${columnDef?.formatterData?.suffix || ''}` || value);
    const tooltip = (columnDef.formatterData && columnDef.formatterData.hoverTitle) ? `title="${dataContext?.hoverTitleValue}"` : '';

    return `<div ${tooltip} class="grid-value-container ${columnDef.formatterData?.editable ? 'cell-editor editor-'+_row+'-'+_cell : ''} ${customIcon?.iconPosition || ''}">
        ${finalValue} ${customIcon ? customIconPosFormatter(customIcon, dataContext) : ''}
    </div>`;
}
