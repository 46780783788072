import { Injectable } from '@angular/core';

@Injectable()
export class NotificationService {

  public openSmallNotification(type, text, timer?: number) {
    if (!$('.smallNotificationModal').hasClass('hidden')) {
      this.closeSmallNotification();
      setTimeout(() => {
        this.smallModalAppear(type, text);
      }, 800);
    } else {
      this.smallModalAppear(type, text);
    }
  }

  public updateSmallNotification(type, text) {
    this.smallModalAppear(type, text);
    setTimeout(() => {
      this.closeSmallNotification();
    }, 3000);
  }

  public openNotification(type, text, title = null, timer?: number) {
    if (!$('.notificationModal').hasClass('hidden')) {
      this.closeNotification({ type, text, title, timer });
    } else {
      this.modalAppear(type, text, title, timer);
    }
  }

  public closeNotification(newAppear?) {
    $('.notificationModal').addClass('hidden');
    setTimeout(() => {
      $('.notificationModal .styleContainer').removeClass('downloadSuccess downloadError error success');
      if (newAppear) {
        setTimeout(() => {
          this.modalAppear(newAppear.type, newAppear.text, newAppear.title, newAppear.timer);
        }, 600);
      }
    }, 300);
  }
  public closeSmallNotification() {
    $('.smallNotificationModal').addClass('hidden');
    setTimeout(() => {
      $('.smallNotificationModal').removeClass('sucess');
      $('.smallNotificationModal').removeClass('error');
    }, 800);
  }

  private smallModalAppear(type: any, text: any, timer?: number) {
    $('.smallNotificationModal').text(text);
    $('.smallNotificationModal').addClass(type);
    $('.smallNotificationModal').removeClass('hidden');
    if (timer) {
      setTimeout(() => { this.closeSmallNotification() }, timer);
    }
  }

  private modalAppear(type: any, text: any, title?, timer?: number) {
    $('.notificationModal .styleContainer .material-icons').text(this.chooseIcon(type));
    $('.notificationModal .infoContainer .title').text(title);
    $('.notificationModal .infoContainer .info').text(text);
    $('.notificationModal').removeClass('hidden');
    setTimeout(() => {
      $('.notificationModal .styleContainer').addClass(type);
    }, 100);
    if (timer) {
      setTimeout(() => { this.closeNotification() }, timer);
    }
  }

  private chooseIcon(type) {
    switch (type) {
      case 'downloadInfo':
        return 'schedule';
      case 'downloadSuccess':
      case 'downloadError':
        return 'get_app';
      case 'error':
        return 'bug_report';
      case 'success':
        return 'check';
      case 'startUpload':
        return 'schedule';
      case 'startProcess':
        return 'schedule';
    }
  }
}
