<ng-container *ngIf="enableDropdown">
    <cmx-dropdown
        [styles]="{ width: '100%' }"
        id="preferencesDropdown"
        [theme]="darkTheme"
        [data]="preferences"
        [ngModel]="selectedPreference"
        (onSelect)="onSelect($event)"
        [settings]="dropdownSettings"
    ></cmx-dropdown>
</ng-container>
<ng-container *ngIf="!enableDropdown">
    <cmx-button variant="success" size="sm" [routerLink]="['/configurations/preferences']">Cadastrar novo</cmx-button>
</ng-container>
