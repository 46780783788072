<div class="m-subheader">
    <div class="d-flex align-items-center">
        <div class="mr-auto">

            <h3 class="m-subheader__title m-subheader__title--separator">
                Máquina de Rateio
            </h3>

            <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                <li class="m-nav__item m-nav__item--home">
                    <a href="#" class="m-nav__link m-nav__link--icon">
                        <i class="m-nav__link-icon la la-home"></i>
                    </a>
                </li>
                <li class="m-nav__separator">
                    -
                </li>
                <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                        <span class="m-nav__link-text">
                            Financeiro
                        </span>
                    </a>
                </li>
                <li class="m-nav__separator">
                    -
                </li>
                <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                        <span class="m-nav__link-text">
                            Rateio
                        </span>
                    </a>
                </li>
                <li class="m-nav__separator">
                    -
                </li>
                <li class="m-nav__item">
                    <a href="/financeiro/rateio/maquina" class="m-nav__link">
                        <span class="m-nav__link-text">
                            Máquina de Rateio
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="m-content">
    <!--begin::Portlet-->
    <div class="m-portlet">
        <div class="m-portlet__body">
            <ul class="nav nav-tabs m-tabs-line m-tabs-line--primary m-tabs-line--2x" role="tablist">
                <li *ngFor="let year of years; let i = index; trackBy: trackByFn" class="nav-item m-tabs__item">
                    <a class="nav-link m-tabs__link text-center" [ngClass]="i == 0 ? 'active' : null" data-toggle="tab" [attr.href]="'#tab_' + year"
                        style="width: 5rem" (click)="firstSection($event)">
                        {{year}}
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link m-tabs__link text-center" href="#" style="width: 5rem">
                        <i class="la la-plus"></i>
                    </a>
                </li>
            </ul>
            <div class="tab-content">
                <div *ngFor="let year of years; let i = index; trackBy: trackByFn" class="tab-pane" [ngClass]="i == 0 ? 'active' : null" id="tab_{{year}}" role="tabpanel">
                    <table class="table m-table m-table--head-no-border" [hidden]="visibleSection != 1">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Jan/{{year}}</th>
                                <th>Fev/{{year}}</th>
                                <th>Mar/{{year}}</th>
                                <th>Abr/{{year}}</th>
                                <th>Mai/{{year}}</th>
                                <th>Jun/{{year}}
                                    <a class="m-portlet__nav-link m-portlet__nav-link--icon pull-right" href="#" (click)="secondSection($event)">
                                        <i class="fa fa-chevron-right"></i>
                                    </a>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let profitCenter of profitCenters; trackBy: trackByFn">
                                <th style="width: 15%">{{profitCenter}}</th>
                                <td *ngFor="let index of indexes; trackBy: trackByFn">
                                    <select class="form-control m-bootstrap-select" id="m_form_type">
                                        <option *ngFor="let engine of engines; trackBy: trackByFn">{{engine}}</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table m-table m-table--head-no-border" [hidden]="visibleSection != 2">
                        <thead>
                            <tr>
                                <th>
                                    <a class="m-portlet__nav-link m-portlet__nav-link--icon" href="#" (click)="firstSection($event)">
                                        <i class="fa fa-chevron-left"></i>
                                    </a>
                                </th>
                                <th>Jul/{{year}}</th>
                                <th>Ago/{{year}}</th>
                                <th>Set/{{year}}</th>
                                <th>Out/{{year}}</th>
                                <th>Nov/{{year}}</th>
                                <th>Dez/{{year}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let profitCenter of profitCenters; trackBy: trackByFn">
                                <th style="width: 15%">{{profitCenter}}</th>
                                <td *ngFor="let index of indexes; trackBy: trackByFn">
                                    <select class="form-control m-bootstrap-select" id="m_form_type">
                                        <option *ngFor="let engine of engines; trackBy: trackByFn">{{engine}}</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>