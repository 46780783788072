import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import moment from 'moment';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IInvoicesFilter } from './interface/invoices.interface';


export class InvoicesFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initiaInvoiceFilter: IInvoicesFilter = <IInvoicesFilter>{};
    private _invoiceFilter: IInvoicesFilter = <IInvoicesFilter>{
        clientGroups: [],
        automotiveMode: 'Desligado',
        intraCompany: false
    };

    private startDate;
    private endDate;

    constructor(filter?: InvoicesFilter) {

        this._invoiceFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'invoicesFilter', this._invoiceFilter, filter);
        this._initiaInvoiceFilter = _.cloneDeep(this._invoiceFilter);

        this.startDate = filter ? filter.startDate : moment().add(-6, 'M').format('MM/YYYY');
        this.endDate = filter ? filter.endDate : moment().format('MM/YYYY');
    }

    getClientGroups() {
        return this._invoiceFilter.clientGroups;
    }
    getAutomotiveMode() {
        return this._invoiceFilter.automotiveMode;
    }
    getIntraCompany() {
        return this._invoiceFilter.intraCompany;
    }
    getStartDate() {
        return this.startDate;
    }
    getEndDate() {
        return this.endDate;
    }

    setClientGroups(clientGroups): void {
        this._invoiceFilter.clientGroups = clientGroups;
    }
    setAutomotiveMode(automotiveMode): void {
        this._invoiceFilter.automotiveMode = automotiveMode;
    }
    setIntraCompany(intraCompany): void {
        this._invoiceFilter.intraCompany = intraCompany;
    }
    setStartDate(startDate): void {
        this.startDate = startDate;
    }
    setEndDate(endDate): void {
        this.endDate = endDate;
    }

    updatePreferences() {
        this._initiaInvoiceFilter = this._userPreferencesService.verifyFilterChanges(
            this._initiaInvoiceFilter, this._invoiceFilter, 'invoicesFilter', this._userCacheService.getUserPreferences().id);
    }
}
