import * as _ from 'lodash';
import { PermissionGroup } from './permission-group';

export class Profile {

    public static blank() {
        return new Profile('Novo Perfil', '', true, [], 0, '00:00', '00:00', false, false, 240);
    }

    assignedUsers: number;
    name: string;
    code: string;
    iconCondition: boolean;
    permissionGroups: string[];
    startHour: string;
    endHour: string;
    externalAccess: boolean;
    simultaneousLogin: boolean;
    sessionMaxIdleTime: number;

    constructor(source: Profile);
    constructor(name: string, code: string, iconCondition: boolean, permissionGroups: string[],
                assignedUsers: number, startHour: string, endHour: string, externalAccess: boolean,
                simultaneousLogin?: boolean, sessionMaxIdleTime?: number);
    constructor(
        sourceOrDescription: string | Profile, name?: string, iconCondition?: boolean, permissionGroups?: string[], assignedUsers?: number,
        startHour?: string, endHour?: string, externalAccess?: boolean, simultaneousLogin?: boolean, sessionMaxIdleTime?: number) {
            if (typeof sourceOrDescription === 'string') {
            this.assignedUsers = assignedUsers;
            this.name = sourceOrDescription;
            this.code = name;
            this.startHour = startHour;
            this.endHour = endHour;
            this.externalAccess = externalAccess;
            this.permissionGroups = permissionGroups;
            this.simultaneousLogin = simultaneousLogin;
            this.sessionMaxIdleTime = sessionMaxIdleTime;
        } else {
            this.assignedUsers = sourceOrDescription.assignedUsers;
            this.name = sourceOrDescription['description'];
            this.code = sourceOrDescription.name;
            this.startHour = sourceOrDescription.startHour;
            this.endHour = sourceOrDescription.endHour;
            this.externalAccess = sourceOrDescription.externalAccess;
            this.permissionGroups = [].concat(...sourceOrDescription.permissionGroups);
            this.simultaneousLogin = sourceOrDescription.simultaneousLogin;
            this.sessionMaxIdleTime = sourceOrDescription.sessionMaxIdleTime;
        }
            this.iconCondition = true;
    }

    public clone() {
        return new Profile(this);
    }

    public equals(other: Profile): boolean {
        return this.name === other.name
            && this.code === other.code
            && this.startHour === other.startHour
            && this.endHour === other.endHour
            && this.externalAccess === other.externalAccess
            && this.simultaneousLogin === other.simultaneousLogin
            && this.sessionMaxIdleTime === other.sessionMaxIdleTime
            && _.difference(this.permissionGroups, other.permissionGroups).length === 0
            && _.difference(other.permissionGroups, this.permissionGroups).length === 0;
    }

    public add(permissionGroup: PermissionGroup) {
        if (!this.has(permissionGroup)) {
            this.permissionGroups.push(permissionGroup.name);
        }
    }

    public remove(permissionGroup: PermissionGroup) {
        if (this.has(permissionGroup)) {
            this.permissionGroups.splice(
                this.permissionGroups.indexOf(permissionGroup.name), 1,
            );
        }
    }

    public has(permissionGroup: PermissionGroup) {
        return this.permissionGroups.includes(permissionGroup.name);
    }

    public hasAll(permissionGroups: PermissionGroup[]) {
        for (const p of permissionGroups) {
            if (!this.permissionGroups.includes(p.name)) {
                return false;
            }
        }
        return true;
    }
}
