import { AppModule } from 'app/app.module';
import * as _ from 'lodash';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';

import { IStatusDomain } from 'app/shared/interfaces/status-domain.interface';
import { IForecastStatusFilter } from '../interfaces/forecast-status-filter.interface';

export class ForecastStatusFilter {
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialForecastStatusFilter: IForecastStatusFilter = <IForecastStatusFilter>{};
    private _forecastStatusFilter: IForecastStatusFilter = <IForecastStatusFilter>{
        clientGroups: [],
        status: [],
    };

    constructor(filter?: IForecastStatusFilter) {
        this._forecastStatusFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'forecastStatusFilter', this._forecastStatusFilter, filter);
        this._initialForecastStatusFilter = _.cloneDeep(this._forecastStatusFilter);
    }

    get clientGroups(): DropdownOption[] {
        return this._forecastStatusFilter.clientGroups;
    }

    set clientGroups(clientGroups: DropdownOption[]) {
        this._forecastStatusFilter.clientGroups = clientGroups;
    }

    get status(): IStatusDomain[] {
        return this._forecastStatusFilter.status;
    }

    set status(status) {
        this._forecastStatusFilter.status = status;
    }

    getRequest(allStatusList: IStatusDomain[]) {
        this._initialForecastStatusFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialForecastStatusFilter, this._forecastStatusFilter, 'forecastStatusFilter', this._userCacheService.getUserPreferences().id);

        const headers = {
            clientCodes: this.clientGroups.length === 0 ? '' : this.clientGroups.map((c) => c['id']).join(','),
        };

        const params = {
            status: (this.status || allStatusList).map((status: IStatusDomain) => status.code).join(','),
        };

        return { params, headers };
    }
}
