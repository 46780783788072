
import { AppModule } from 'app/app.module';
import * as _ from 'lodash';
import moment from 'moment';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IMovementByModalFilter } from './interface/movement.by-modal.interface';
import { MOVEMENT_BY_MODAL_TYPES } from './constants/movement-by-modal-types.constant';


export class MovementByModalFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);
    private _initialMovementByModalFilter: IMovementByModalFilter = <IMovementByModalFilter>{};
    private _movementByModalFilter: IMovementByModalFilter = <IMovementByModalFilter>{
        clientGroups: [],
        states: [],
        warehouses: [],
        type: 'Marítima',
        secondaryType: 'Estado',
        typeView: 'TEUs',
        year: moment().format('YYYY'),
        referenceDateType : [{ id: 'arrival', itemName: 'Atracação' }]
    };

    constructor(filter?: MovementByModalFilter) {
        this._movementByModalFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), `movementByModalFilter`, this._movementByModalFilter, filter);
        this._initialMovementByModalFilter = _.cloneDeep(this._movementByModalFilter);
    }

    getClientGroups() { return this._movementByModalFilter.clientGroups }
    getStates() { return this._movementByModalFilter.states }
    getWarehouses() { return this._movementByModalFilter.warehouses }
    getType() { return this._movementByModalFilter.type }
    getTypeView() { return this._movementByModalFilter.typeView }
    getYear(): string { return this._movementByModalFilter.year }
    getSecondaryType(): string { return this._movementByModalFilter.secondaryType }
    getReferenceDateType() { return this._movementByModalFilter.referenceDateType }

    setClientGroups(clientGroups): void { this._movementByModalFilter.clientGroups = clientGroups }
    setStates(states): void { this._movementByModalFilter.states = states }
    setWarehouses(warehouses): void { this._movementByModalFilter.warehouses = warehouses }
    setType(type): void { this._movementByModalFilter.type = type.name }
    setTypeView(typeView): void { this._movementByModalFilter.typeView = typeView.name }
    setYear(year): void { this._movementByModalFilter.year = year.startDate }
    setSecondaryType(secondaryType): void { this._movementByModalFilter.secondaryType = secondaryType.name }
    setReferenceDateType(referenceDateType): void { this._movementByModalFilter.referenceDateType = referenceDateType }

    savePreferences() {
        this._initialMovementByModalFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialMovementByModalFilter, this._movementByModalFilter, `movementByModalFilter`, this._userCacheService.getUserPreferences().id);
    }

    getRequest() {
        const type = MOVEMENT_BY_MODAL_TYPES[this.getType()].paramName;
        return {
            params: {
                year: this.getYear(),
                state: this.getStates().map((c) => c.id).join(','),
                referenceDateType: this.getReferenceDateType()[0]?.id,
                warehouse: this.getWarehouses().map((c) => c.id).join(','),
                ...(type === 'MARITIME' && { typeView: this.getTypeView().toLowerCase() })
            },
            headers: { 'X-Requested-Client-Groups': this.getClientGroups().map((c) => c.id).join(',') }
        }
    }

    getStateAndWarehouseRequest() {
        const type = MOVEMENT_BY_MODAL_TYPES[this.getType()].paramName;
        return {
            params: {
                year: this.getYear(),
                type: this.getSecondaryType() === 'Estado' ? 'state' : 'warehouse',
                referenceDateType: this.getReferenceDateType()[0]?.id,
                ...(type === 'MARITIME' && { typeView: this.getTypeView().toLowerCase() })
            },
            headers: { 'X-Requested-Client-Groups': this.getClientGroups().map((c) => c.id).join(',') }
        }
    }

}
