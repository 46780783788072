import { environment } from '#environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ExchangeBrokerService {

    constructor(private _http: HttpClient) { }

    getBrokerList(inactive?): Observable<any> {
        if(inactive != undefined) {
            return this._http.get(`${environment.endpoints.exchangeService}/exchange-broker?inactive=${inactive}`);
        } else {
            return this._http.get(`${environment.endpoints.exchangeService}/exchange-broker`);
        }
    }

    createBroker(request): Observable<any> {
        return this._http.post(`${environment.endpoints.exchangeService}/exchange-broker/`, request);
    }

    editBroker(brokerId, request): Observable<any> {
        return this._http.put(`${environment.endpoints.exchangeService}/exchange-broker/${brokerId}`, request);
    }

    updatedInactiveBroker(brokerId): Observable<any> {
        return this._http.put(`${environment.endpoints.exchangeService}/exchange-broker/update-inactive-status/${brokerId}`, null);
    }

    deleteBroker(brokerId): Observable<any> {
        return this._http.delete(`${environment.endpoints.exchangeService}/exchange-broker/${brokerId}`);
    }

}
