<cmx-datamart-preferences
    *ngIf="selectedModel"
    [serviceId]="serviceId"
    [datamartName]="datamartName"
    [generatorId]="generatorId"
    [subItensData]="options.modelList"
    [selectedSubItem]="selectedModel"
    [subItensSettings]="dropdownSettings.fupModels"
    [modalTitle]="datamartModalTitle"
    [filters]="filters"
    [filterState]="filterState"
></cmx-datamart-preferences>
