<div>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
    <!-- Creator: CorelDRAW 2018 (64 Bit) -->
    <svg id="svg-vehicle" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="800px" height="500px" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
    viewBox="0 0 376.11 235.07"
     xmlns:xlink="http://www.w3.org/1999/xlink">
     <defs>
      <style type="text/css">
       <![CDATA[
        .str0 {stroke:#373435;stroke-width:1.11;stroke-miterlimit:22.9256}
        .fil0 {fill:none}
       ]]>
      </style>
     </defs>
     <g id="Camada_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"/>
      <rect id="svgArea" class="fil0" width="376.11" height="235.07"/>
      <g id="_2405722188672">
      <ellipse id="Roda_Esquerda_Dianteira" class="area str0" cx="130.31" cy="213.72" rx="13.58" ry="13.4"/>
      <path id="Lateral_Esquerda" class="area str0" d="M147.27 208.8c0.07,0.38 0.1,0.75 0.1,1.12 0.06,2.83 0.06,6 0,9.51 7.88,-0.03 35.57,-0.03 83.05,0 0.02,-2.69 0.03,-5.55 0.02,-8.55 0.02,-0.66 -0.29,-2.08 -0.29,-2.08 -3.93,-0.09 -3.34,0.07 -2.7,-2.2 2.76,-8.31 10.53,-13.91 19.28,-13.91 8.74,0 16.5,5.59 19.27,13.87 5.91,-0.43 13,-0.6 21.29,-0.52 0.01,-2.48 0.02,-4.69 0.01,-6.62 -1.98,0.04 -4.54,0.06 -7.68,0.04 -2.54,0.02 -3.79,-1.48 -4.17,-5.09 -0.4,-5.35 -0.08,-8.31 1.21,-8.9 2.12,-0.86 4.51,-0.9 8.16,-0.83 -0.64,-1.2 -1.42,-2.42 -2.33,-3.64l-0.05 0.04c-1.62,0.01 -6.14,0.38 -7.2,-0.66 -4.43,-4.55 -7.32,-7.65 -10.47,-11.62 -0.42,-0.43 -0.37,-2.02 -0.4,-2.88l0.05 -0.01c-2.09,-0.93 -3.98,-1.4 -5.68,-1.4 -19.24,-0.12 -39.31,-0.11 -60.2,0.04 -7.33,0.1 -12.42,0.35 -15.27,0.76l-0.65 0.12c-2.7,1.79 -6.16,3.51 -10.36,5.14 -7.62,2.83 -16.32,8.97 -20.57,11.59 -3.91,2.2 -10.19,2.6 -16.08,2.96 -9.04,2.31 -13.92,2.96 -19.96,3.77 -3.8,0.5 -6.92,0.94 -9.52,1.38l0.18 -0.03c-0.28,11.58 -4.98,14.22 -16.4,13.61 0,0 0,0 -0.2,2.78 9.1,0.01 16.33,0.01 21.7,0.01 2.77,-8.31 10.54,-13.91 19.29,-13.91 8.75,0 16.52,5.6 19.28,13.91 0.64,2.27 1.23,2.11 -2.71,2.2zm5.33 -19.61c0.04,1.3 0.14,6.21 0,0zm42.44 26.46c12.3,-0.01 21.66,-0.01 28.07,0 1.32,-8.05 3.64,-17.35 14.95,-29.1 -1.6,-6.49 -4.18,-12.06 -7.76,-16.7 -8.76,-0.34 -19.02,-0.44 -30.79,-0.3 -17.74,-0.44 -34.85,4.97 -46.91,19.64 0.26,7.59 0.21,16.41 -0.16,26.46 15.6,0 27.55,0 42.56,0 -3.88,0 1.35,0 0.04,0z"/>
      <ellipse id="Roda_Esquerda_Traseira" class="area str0" cx="247.06" cy="213.72" rx="13.58" ry="13.4"/>
      <path id="Para_Choque_Esquerdo_Dianteiro" class="area str0" d="M89.78 207.98c-0.04,-0.52 -0.06,-0.98 -0.07,-1.39 10.82,0.01 19,0.01 24.55,0 -0.46,1.03 -0.71,2.16 -0.76,3.4 0,0 0.01,2.9 0.02,8.68 -4.14,0.13 -8.13,-0.04 -11.95,-0.5 -3.01,-0.38 -5.51,-1.07 -7.51,-2.08 -1.47,-0.66 -2.6,-1.82 -3.38,-3.48 -0.45,-1.52 -0.75,-3.06 -0.9,-4.63z"/>
      <path id="Para_Choque_Esquerdo_Traseiro" class="area str0" d="M287.29 208.17c0.01,-1.42 0.01,-2.13 0.01,-2.13 -9.67,-0.1 -17.73,0.16 -24.16,0.76 0.74,2.07 1.06,3.93 0.96,5.57 0,4.23 0,6.34 0,6.34 6.06,-0.64 11.38,-1.76 15.95,-3.35 4.91,-1.8 7.33,-4.2 7.24,-7.19z"/>
      <path id="Farol_Esquerdo_Dianteiro" class="area str0" d="M94.32 194.99c1.59,-2.24 5.59,-3.84 11.99,-4.79 -0.28,11.58 -4.98,14.22 -16.4,13.61 0.64,-3.57 2.12,-6.51 4.41,-8.82z"/>
      <path id="Farol_Esquerdo_Traseiro" class="area str0" d="M287.16 192.22c0.09,2.33 0.14,4.73 0.14,7.2 -1.98,0.04 -4.54,0.06 -7.68,0.04 -2.54,0.02 -3.79,-1.48 -4.17,-5.09 -0.4,-5.35 -0.08,-8.31 1.21,-8.9 2.12,-0.86 4.51,-0.9 8.17,-0.83 0.9,1.68 1.54,3.29 1.9,4.82 0.23,0.9 0.37,1.82 0.43,2.76z"/>
      <path id="Vidro_Traseiro" class="area str0" d="M264.37 165.88c1.2,0.55 2.33,1.15 3.37,1.81 1.99,1.24 3.99,2.72 6.02,4.43 1.43,1.18 2.92,2.55 4.49,4.1 1.79,1.83 3.19,3.44 4.19,4.82 -1.62,0.01 -6.14,0.38 -7.2,-0.66 -4.43,-4.55 -7.32,-7.65 -10.47,-11.62 -0.42,-0.43 -0.37,-2.02 -0.4,-2.88z"/>
      <path id="Porta_Esquerda_Dianteira" class="area str0" d="M152.44 215.65c0.37,-10.05 0.42,-18.87 0.16,-26.46 31.31,-1.01 43.6,-1.52 43.6,-1.52 -0.65,7.21 -1.05,16.53 -1.2,27.98 -15.01,0 -26.96,0 -42.56,0z"/>
      <path id="Vidro_Esquerdo_Dianteiro" class="area str0" d="M152.6 189.19c15.54,-0.51 29.62,-1 43.6,-1.52 0,0 1.07,-5.9 3.31,-18.12 -17.74,-0.44 -34.85,4.97 -46.91,19.64z"/>
      <path id="Porta_Esquerda_Traseira" class="area str0" d="M223.11 215.65c1.32,-8.05 3.64,-17.35 14.95,-29.1 0,0 -13.94,0.37 -41.82,1.12 -0.63,6.8 -1.03,16.13 -1.2,27.98 12.3,-0.01 21.66,-0.01 28.07,0z"/>
      <path id="Vidro_Dianteiro" class="area str0" d="M152.52 177.89c-3.8,2.84 -9.44,5.23 -16.91,7.19 5.89,-0.36 12.17,-0.76 16.08,-2.96 4.25,-2.62 12.95,-8.76 20.57,-11.59 4.2,-1.63 7.66,-3.35 10.37,-5.14 -6.29,1.27 -11.77,2.88 -16.45,4.83 -2.59,1.03 -5.46,2.46 -8.62,4.28 -1.92,1.16 -3.6,2.29 -5.04,3.39z"/>
      <path id="Vidro_Esquerdo_Traseiro" class="area str0" d="M196.2 187.67c19.1,-0.51 33.05,-0.89 41.86,-1.12 -1.6,-6.49 -4.18,-12.06 -7.76,-16.7 -8.76,-0.34 -19.02,-0.44 -30.79,-0.3 -1.22,6.67 -2.33,12.71 -3.31,18.12z"/>
      <ellipse id="Roda_Direita_Dianteira" class="area str0" cx="130.31" cy="21.36" rx="13.58" ry="13.4"/>
      <path id="Lateral_Direita" class="area str0" d="M101.16 81.92c3.7,-2.21 8.12,-3.71 13.27,-4.51 12.37,-0.67 23.88,-0.99 34.55,-0.96 32.3,0 60.91,0 85.81,0 26.6,0.82 41.83,3.2 45.69,7.13l-5.29 3.68c-3.33,-3.71 -14.02,-5.92 -32.07,-6.61 -16.46,-0.06 -37.66,-0.06 -63.6,0 -11.73,-0.01 -20.65,0.47 -26.78,1.44 -1.86,0.36 -3.57,1.01 -5.12,1.94 -9.84,-1.59 -23.62,-2.29 -44.48,-2.11l-1.98 0z"/>
      <path id="Teto_Direito" class="area str0" d="M277.32 92.29c-0.5,-2.02 -1.22,-3.69 -2.13,-5.03 0.92,1.5 1.63,3.18 2.13,5.03zm-2.13 -5.03c-3.33,-3.71 -14.02,-5.92 -32.07,-6.61 -9.69,-0.03 -21.02,-0.05 -34,-0.04 9.98,0.01 19.86,0.04 29.63,0.1l-11.74 10.47 -28.26 -1.2 0 -0.02 -19.45 -0.82c-5.27,-0.06 -13.26,-2.48 -23.96,-7.25 5.35,-0.62 10.69,-1.01 16.01,-1.16 -7.79,0.18 -13.99,0.63 -18.61,1.36 -4.69,0.91 -8.41,3.62 -11.13,8.17 1.58,-2.47 3.59,-4.55 6.05,-6.23 5.3,2.4 10.7,4.25 16.22,5.53 2.7,0.68 4.74,1.13 6.11,1.36 1.57,0.48 2.58,2.1 2.34,4.13 -1.51,8.98 -2.27,15.46 -2.28,21.91l8.7 0c0.08,-1.63 1.43,-2.93 3.08,-2.93 1.33,0 2.47,0.86 2.89,2.05l9.2 0c0.5,0 0.91,0.39 0.94,0.88l65.36 0c-0.06,-7.44 -0.1,-14.36 -0.11,-20.77 -0.05,-1.89 0.21,-3.2 1.18,-3.82 6.26,-3.32 10.86,-5.12 13.9,-5.11zm-136.8 10.76c-0.8,2.83 -1.4,6.04 -1.81,9.62 0.4,-3.23 0.99,-6.38 1.77,-9.45l0.04 -0.17zm42.02 -17.37l-0.89 0c-1.27,0 -2.51,0 -3.71,0.01l0.66 0 3.94 -0.01z"/>
      <path id="Teto_Esquerdo" class="area str0" d="M135.99 117.1l0 0.01 0 0.01 0 -0.02zm0.98 12.4c0.44,2.85 1.01,5.43 1.73,7.74 -0.72,-2.43 -1.3,-5.01 -1.73,-7.74zm5.76 16.15c2.6,3.54 5.93,5.69 10.01,6.48 4.78,0.75 11.27,1.21 19.45,1.38 -5.59,-0.14 -11.21,-0.53 -16.85,-1.18 10.7,-4.77 18.69,-7.19 23.96,-7.25l19.45 -0.83 0 -0.01 28.26 -1.2 11.74 10.47c-10.94,0.06 -22,0.1 -33.2,0.1 14.48,0.01 27,0 37.57,-0.04 18.05,-0.69 28.74,-2.9 32.07,-6.61 -3.04,0.02 -7.64,-1.79 -13.9,-5.11 -0.97,-0.62 -1.23,-1.93 -1.18,-3.82 0.01,-6.8 0.05,-11.73 0.12,-19.69l-0.01 -1.38 -65.36 0 0 0.06 0 0.18c0,0.52 -0.42,0.94 -0.94,0.94l-9.2 0c-0.42,1.19 -1.56,2.05 -2.89,2.05 -1.7,0 -3.08,-1.38 -3.08,-3.08l0 -0.15 -8.7 0c-0.02,6.55 0.75,13.09 2.28,22.21 0.24,2.04 -0.77,3.65 -2.34,4.13 -1.37,0.23 -3.41,0.68 -6.11,1.36 -5.52,1.28 -10.92,3.13 -16.22,5.53 -1.92,-1.26 -3.56,-2.77 -4.93,-4.54zm134.37 -2.91c0.64,-2.19 1.02,-4.82 1.13,-7.88 -0.12,3.08 -0.5,5.71 -1.13,7.88zm-102.27 10.81c1.51,0.01 3.07,0.02 4.69,0.02l2.88 0.01 -5.93 -0.03c-0.55,0 -1.1,0 -1.64,0z"/>
      <path id="Vidro_Dianteiro2" class="area str0" d="M138.88 137.85c-1.85,-5.99 -2.81,-12.9 -2.89,-20.71 0.04,-6.62 0.83,-12.94 2.36,-18.95 1.46,-6.19 4.57,-10.91 9.31,-14.16 5.3,2.4 10.7,4.25 16.22,5.53 2.7,0.68 4.74,1.13 6.11,1.36 1.57,0.48 2.58,2.1 2.34,4.13 -3.05,18.11 -3.05,26.01 0,44.12 0.24,2.04 -0.77,3.65 -2.34,4.13 -1.37,0.23 -3.41,0.68 -6.11,1.36 -5.52,1.28 -10.92,3.13 -16.22,5.53 -4.31,-2.83 -7.23,-6.94 -8.78,-12.34zm24.89 -26.49l2.31 0c0.52,0 0.94,0.43 0.94,0.94l0 9.62c0,0.52 -0.42,0.94 -0.94,0.94l-2.31 0c-0.51,0 -0.94,-0.42 -0.94,-0.94l0 -9.62c0,-0.51 0.43,-0.94 0.94,-0.94z"/>
      <path id="Vidro_Traseiro2" class="area str0" d="M260.23 118.34c-0.07,-7.97 -0.11,-15.35 -0.12,-22.15 -0.05,-1.89 0.21,-3.2 1.18,-3.82 6.26,-3.32 10.86,-5.12 13.9,-5.11 1.97,3.22 2.99,7.25 3.04,12.11 0,0 0,11.83 0,35.49 -0.22,5.48 -1.24,9.52 -3.04,12.1 -3.04,0.02 -7.64,-1.79 -13.9,-5.11 -0.97,-0.62 -1.23,-1.93 -1.18,-3.82 0.01,-6.8 0.05,-11.73 0.12,-19.69z"/>
      <path id="Vidro_Direito_Dianteiro" class="area str0" d="M176.47 80.66c5.66,-0.02 11.28,-0.04 16.87,-0.05l5.41 9.36 -19.45 -0.83c-5.27,-0.06 -13.26,-2.48 -23.96,-7.25 7.08,-0.82 14.12,-1.23 21.13,-1.23z"/>
      <path id="Vidro_Direito_Traseiro" class="area str0" d="M193.34 80.62c15.38,-0.03 30.52,0 45.41,0.09l-11.74 10.47 -28.26 -1.2 -5.41 -9.36z"/>
      <path id="Vidro_Esquerdo_Dianteiro2" class="area str0" d="M176.47 153.55c5.66,0.03 11.28,0.05 16.87,0.06l5.41 -9.36 -19.45 0.83c-5.27,0.06 -13.26,2.48 -23.96,7.25 7.08,0.82 14.12,1.23 21.13,1.22z"/>
      <path id="Vidro_Esquerdo_Traseiro2" class="area str0" d="M193.34 153.6c15.38,0.03 30.52,0 45.41,-0.09l-11.74 -10.47 -28.26 1.2 -5.41 9.36z"/>
      <path id="Capô" class="area str0" d="M135.99 117.11c0.07,17.36 3.96,28.39 11.67,33.08 -12.54,1.79 -28.04,2.48 -46.5,2.09 -8.67,-5.19 -13.33,-14.2 -13.98,-27.01 0,-6.26 0,-11.7 0,-16.32 0.24,-12.03 4.9,-21.03 13.98,-27.01 22,-0.25 36.34,0.45 46.5,2.09 -7.92,5.49 -11.81,16.52 -11.67,33.08z"/>
      <path id="Para_Choque_Dianteiro2" class="area str0" d="M54.8 78.9c2.77,-1.24 4.43,-1.66 6.26,-2.07l0 14.44 0 51.74 0 14.44c-4.1,-1.01 -3.67,-1.07 -6.26,-2.07l0 -76.48zm15.68 12.37l0 -14.69c4.15,0.94 7.19,3.61 7.21,8.79 0.11,10.56 0.11,21.38 0,31.91 0.11,10.53 0.11,21.07 0,31.62 -0.02,5.19 -3.06,7.86 -7.21,8.79l0 -14.68 0 -51.74z"/>
      <path id="Para_Choque_Dianteiro3" class="area str0" d="M46.95 117.4c-0.06,-13.01 0.11,-20.64 0,-30.46 -0.01,-2.98 3.35,-5.97 7.85,-8.04l0 76.48c-4.5,-2.07 -7.86,-5.06 -7.85,-8.04 0.11,-9.82 -0.06,-16.93 0,-29.94z"/>
      <path id="Grade_Do_Radiador" class="area str0" d="M70.48 91.27l0 51.74c-3.14,-3.46 -6.28,-3.46 -9.42,0l0 -51.74c3.14,3.46 6.28,3.46 9.42,0z"/>
      <path id="Farol_Esquerdo_Dianteiro2" class="area str0" d="M70.48 143.01l0 14.69c-2.82,0.63 -6.16,0.46 -9.42,-0.25l0 -14.44c3.14,-3.46 6.28,-3.46 9.42,0z"/>
      <path id="Farol_Direito_Dianteiro" class="area str0" d="M70.48 76.57l0 14.7c-3.14,3.46 -6.28,3.46 -9.42,0l0 -14.44c3.26,-0.72 6.6,-0.88 9.42,-0.26z"/>
      <path id="Porta_Malas" class="area str0" d="M315.57 157.9c-2.9,0.01 -5.88,0.02 -15.13,0.05 -3.2,-0.06 -7.15,-3.72 -7.16,-6.99 -0.03,-11.5 -0.03,-24.67 0,-34.09 -0.03,-9.42 -0.03,-22.1 0,-33.61 0.01,-2.38 2.11,-4.97 4.49,-6.23 0,2.65 0,5.3 0,7.96 0.01,1.65 1.27,2.44 3.78,2.38 2.56,0 4.69,0 6.39,0 3.12,-0.62 4.63,-2.14 4.54,-4.56 -0.01,-2.11 0,-4.27 0.02,-6.5l4.52 0.02c-5.08,25.85 -5.08,53.04 0,81.56l-1.45 0.01zm-17.67 -80.93c0.34,-0.18 0.69,-0.32 1.04,-0.43 -0.34,0.11 -0.68,0.25 -1.04,0.43zm2.54 80.98c-0.82,-0.02 -1.72,-0.27 -2.67,-0.76 0,-2.65 0,-5.3 0,-7.96 0.01,-1.65 1.27,-2.44 3.78,-2.38 2.56,0 4.69,0 6.39,0 3.12,0.62 4.63,2.14 4.54,4.56 -0.01,2.11 0,4.27 0.02,6.5 -3.05,0.01 -7.07,0.02 -12.06,0.04z"/>
      <path id="Farol_Direito_Traseiro" class="area str0" d="M300.44 76.27c-0.82,0.01 -1.72,0.27 -2.67,0.76 0,2.65 0,5.3 0,7.96 0.01,1.65 1.27,2.44 3.78,2.38 2.56,0 4.69,0 6.39,0 3.12,-0.62 4.63,-2.14 4.54,-4.56 -0.01,-2.11 0,-4.27 0.02,-6.5 -3.05,-0.01 -7.07,-0.02 -12.06,-0.04z"/>
      <path id="Farol_Esquerdo_Traseiro2" class="area str0" d="M300.44 157.95c-0.82,-0.02 -1.72,-0.27 -2.67,-0.76 0,-2.65 0,-5.3 0,-7.96 0.01,-1.65 1.27,-2.44 3.78,-2.38 2.56,0 4.69,0 6.39,0 3.12,0.62 4.63,2.14 4.54,4.56 -0.01,2.11 0,4.27 0.02,6.5 -3.05,0.01 -7.07,0.02 -12.06,0.04z"/>
      <path id="Para_Choque_Traseiro" class="area str0" d="M328.94 146.3c0,-2.7 0,-4.06 0,-4.06 -1.22,0 -3.18,0 -5.88,0 -1.79,0.04 -2.64,-1.2 -2.53,-3.7 -0.03,-2.52 0.84,-3.7 2.63,-3.55 1.64,0 3.6,0 5.88,0 0.18,-12.14 0.18,-24.06 0,-35.76 0,0 -1.96,0 -5.88,0 -1.89,0.05 -2.77,-1.14 -2.63,-3.55 -0.15,-2.46 0.7,-3.69 2.53,-3.7 1.96,0 3.92,0 5.88,0 0,0 0,-1.36 0,-4.06 0.03,-2.17 -0.81,-4.63 -2.53,-7.38 -1.47,-2.41 -3.38,-3.81 -5.71,-4.19 -1.08,-0.01 -2.31,-0.01 -3.68,-0.02 -5.08,25.85 -5.08,53.04 0,81.56 1.6,-0.01 2.83,-0.01 3.68,-0.01 2.52,-0.35 4.65,-2.13 6.41,-5.34 1.27,-2.45 1.88,-4.53 1.83,-6.24z"/>
      <circle id="Escapamento_Direito" class="area str0" cx="324.86" cy="95.55" r="2.38"/>
      <circle id="Escapamento_Esquerdo" class="area str0" cx="324.86" cy="138.58" r="2.38"/>
      <path id="Retrovisor_Direito" class="area str0" d="M143 76.47c3.61,-0.02 5.42,-0.02 5.42,-0.02 -0.52,-1.03 -0.35,-2.13 0.5,-3.28 0.55,-0.56 0.8,-1.43 0.74,-2.62 -0.43,-2.07 -1.64,-2.59 -3.63,-1.56 0,0 0,1.16 0,3.49 -0.26,1.79 -1.27,3.12 -3.03,3.99z"/>
      <path id="Retrovisor_Esquerdo" class="area str0" d="M143 157.76c3.61,0.01 5.42,0.02 5.42,0.02 -0.52,1.03 -0.35,2.13 0.5,3.28 0.55,0.55 0.8,1.43 0.74,2.62 -0.43,2.07 -1.64,2.59 -3.63,1.56 0,0 0,-1.16 0,-3.49 -0.26,-1.79 -1.27,-3.12 -3.03,-3.99z"/>
      <rect id="Retrovisor_Interno" class="area str0" x="162.83" y="111.36" width="4.19" height="11.5" rx="0.94" ry="0.94"/>
      <path id="Antena" class="area str0" d="M181.83 114.03c1.33,0 2.47,0.86 2.89,2.05l9.2 0c0.52,0 0.94,0.42 0.94,0.94l0 0.18c0,0.52 -0.42,0.94 -0.94,0.94l-9.2 0c-0.42,1.19 -1.56,2.05 -2.89,2.05 -1.7,0 -3.08,-1.38 -3.08,-3.08 0,-1.7 1.38,-3.08 3.08,-3.08z"/>
      <path id="Lateral_Esquerda2" class="area str0" d="M101.16 152.3c3.7,2.21 8.12,3.71 13.27,4.51 12.37,0.67 23.88,0.99 34.55,0.96 32.3,0 60.91,0 85.81,0 26.6,-0.82 41.83,-3.2 45.69,-7.13l-5.29 -3.68c-3.33,3.71 -14.02,5.92 -32.07,6.61 -16.46,0.06 -37.66,0.06 -63.6,0 -11.73,0.01 -20.65,-0.47 -26.78,-1.44 -1.86,-0.36 -3.57,-1.01 -5.12,-1.94 -9.84,1.59 -23.62,2.3 -44.48,2.11l-1.98 0z"/>
      <path id="Porta_Malas2" class="area str0" d="M284.66 115.75c0,4.46 0.02,11.66 0.07,21.62 0.34,5.37 -1.07,9.79 -4.25,13.27 -1.51,-1.05 -3.27,-2.28 -5.29,-3.68 1.95,-3.15 2.97,-7.18 3.04,-12.1 0,-8.33 0,-10.27 0,-15.36 0,-5.09 0,-11.81 0,-20.13 -0.07,-4.92 -1.09,-8.96 -3.04,-12.11 2.02,-1.4 3.78,-2.63 5.29,-3.68 3.18,3.48 4.59,7.9 4.25,13.27 -0.05,9.96 -0.07,14.44 -0.07,18.9z"/>
      <path id="Outros" class="area str0" d="M303.9 26.95l3.23 -5.59 3.23 -5.6 6.46 0 6.46 0 3.23 5.6 3.23 5.59 -3.23 5.6 -3.23 5.59 -6.46 0 -6.46 0 -3.23 -5.59 -3.23 -5.6zm12.92 -6.2c3.42,0 6.2,2.78 6.2,6.2 0,3.42 -2.78,6.2 -6.2,6.2 -3.42,0 -6.2,-2.78 -6.2,-6.2 0,-3.42 2.78,-6.2 6.2,-6.2z"/>
      <path id="Para_Lama_Esquerdo_Dianteiro" class="area str0" d="M139.2 198.31c4.29,2.73 6.98,6.23 8.07,10.49 3.94,-0.09 3.35,0.07 2.71,-2.2 -2.76,-8.31 -10.53,-13.91 -19.28,-13.91 -8.75,0 -16.52,5.6 -19.29,13.91 1.9,-0.01 2.85,-0.01 2.85,-0.01 2.86,-4.8 6.68,-8.18 11.47,-10.16 4.63,-1.52 9.12,-0.9 13.47,1.88z"/>
      <path id="Para_Lama_Esquerdo_Traseiro" class="area str0" d="M238.23 198.31c-4.29,2.73 -6.98,6.23 -8.08,10.49 -3.93,-0.09 -3.34,0.07 -2.7,-2.2 2.76,-8.31 10.53,-13.91 19.28,-13.91 8.75,0 16.52,5.6 19.28,13.91 -1.89,-0.01 -2.84,-0.01 -2.84,-0.01 -2.86,-4.8 -6.69,-8.18 -11.47,-10.16 -4.64,-1.52 -9.13,-0.9 -13.47,1.88z"/>
      <path id="Lateral_Direita2" class="area str0" d="M147.27 26.27c0.07,-0.38 0.1,-0.75 0.1,-1.11 0.06,-2.84 0.06,-6.01 0,-9.52 7.88,0.03 35.57,0.03 83.05,0 0.02,2.7 0.03,5.55 0.02,8.55 0.02,0.66 -0.29,2.08 -0.29,2.08 -3.93,0.1 -3.34,-0.07 -2.7,2.21 2.76,8.3 10.53,13.9 19.28,13.9 8.74,0 16.5,-5.59 19.27,-13.87 5.91,0.43 13,0.61 21.29,0.53 0.01,2.47 0.02,4.68 0.01,6.61 -1.98,-0.04 -4.54,-0.06 -7.68,-0.04 -2.54,-0.02 -3.79,1.49 -4.17,5.09 -0.4,5.35 -0.08,8.31 1.21,8.9 2.12,0.86 4.51,0.9 8.16,0.84 -0.64,1.19 -1.42,2.41 -2.33,3.63l-0.05 -0.04c-1.62,-0.01 -6.14,-0.38 -7.2,0.66 -4.43,4.55 -7.32,7.65 -10.47,11.62 -0.42,0.44 -0.37,2.02 -0.4,2.88l0.05 0.02c-2.09,0.93 -3.98,1.39 -5.68,1.4 -19.24,0.12 -39.31,0.1 -60.2,-0.04 -7.33,-0.11 -12.42,-0.36 -15.27,-0.77l-0.65 -0.12c-2.7,-1.79 -6.16,-3.51 -10.36,-5.14 -7.62,-2.83 -16.32,-8.96 -20.57,-11.58 -3.91,-2.21 -10.19,-2.61 -16.08,-2.97 -9.04,-2.31 -13.92,-2.96 -19.96,-3.77 -3.8,-0.5 -6.92,-0.93 -9.52,-1.38l0.18 0.03c-0.28,-11.58 -4.98,-14.22 -16.4,-13.61 0,0 0,0 -0.2,-2.78 9.1,-0.01 16.33,-0.01 21.7,0 2.77,8.3 10.54,13.9 19.29,13.9 8.75,0 16.52,-5.6 19.28,-13.9 0.64,-2.28 1.23,-2.11 -2.71,-2.21zm5.33 19.61c0.04,-1.3 0.14,-6.21 0,0zm42.44 -26.45c12.3,0 21.66,0 28.07,0 1.32,8.04 3.64,17.34 14.95,29.09 -1.6,6.49 -4.18,12.06 -7.76,16.7 -8.76,0.34 -19.02,0.44 -30.79,0.3 -17.74,0.44 -34.85,-4.97 -46.91,-19.64 0.26,-7.59 0.21,-16.41 -0.16,-26.45 15.6,0 27.55,0 42.56,0 -3.88,0 1.35,0 0.04,0z"/>
      <ellipse id="Roda_Direita_Traseira" class="area str0" cx="247.06" cy="21.36" rx="13.58" ry="13.4"/>
      <path id="Para_Choque_Direito_Dianteiro" class="area str0" d="M89.78 27.09c-0.04,0.52 -0.06,0.98 -0.07,1.39 10.82,-0.01 19,-0.01 24.55,0 -0.46,-1.03 -0.71,-2.16 -0.76,-3.4 0,0 0.01,-2.9 0.02,-8.68 -4.14,-0.13 -8.13,0.04 -11.95,0.5 -3.01,0.38 -5.51,1.07 -7.51,2.08 -1.47,0.66 -2.6,1.82 -3.38,3.48 -0.45,1.52 -0.75,3.06 -0.9,4.63z"/>
      <path id="Para_Choque_Direito_Traseiro" class="area str0" d="M287.29 26.9c0.01,1.42 0.01,2.14 0.01,2.14 -9.67,0.09 -17.73,-0.16 -24.16,-0.77 0.74,-2.07 1.06,-3.93 0.96,-5.57 0,-4.23 0,-6.34 0,-6.34 6.06,0.64 11.38,1.76 15.95,3.35 4.91,1.8 7.33,4.2 7.24,7.19z"/>
      <path id="Farol_Direito_Dianteiro2" class="area str0" d="M94.32 40.08c1.59,2.24 5.59,3.84 11.99,4.79 -0.28,-11.58 -4.98,-14.22 -16.4,-13.61 0.64,3.57 2.12,6.51 4.41,8.82z"/>
      <path id="Farol_Direito_Traseiro2" class="area str0" d="M287.16 42.85c0.09,-2.33 0.14,-4.73 0.14,-7.2 -1.98,-0.04 -4.54,-0.06 -7.68,-0.04 -2.54,-0.02 -3.79,1.49 -4.17,5.09 -0.4,5.35 -0.08,8.31 1.21,8.9 2.12,0.86 4.51,0.9 8.17,0.84 0.9,-1.69 1.54,-3.3 1.9,-4.83 0.23,-0.9 0.37,-1.82 0.43,-2.76z"/>
      <path id="Vidro_Traseiro3" class="area str0" d="M264.37 69.19c1.2,-0.55 2.33,-1.15 3.37,-1.81 1.99,-1.24 3.99,-2.72 6.02,-4.43 1.43,-1.18 2.92,-2.55 4.49,-4.1 1.79,-1.83 3.19,-3.44 4.19,-4.82 -1.62,-0.01 -6.14,-0.38 -7.2,0.66 -4.43,4.55 -7.32,7.65 -10.47,11.62 -0.42,0.44 -0.37,2.02 -0.4,2.88z"/>
      <path id="Porta_Direita_Dianteira" class="area str0" d="M152.44 19.43c0.37,10.04 0.42,18.86 0.16,26.45 31.31,1.01 43.6,1.52 43.6,1.52 -0.65,-7.2 -1.05,-16.53 -1.2,-27.97 -15.01,0 -26.96,0 -42.56,0z"/>
      <path id="Vidro_Direito_Dianteiro" class="area str0" d="M152.6 45.88c15.54,0.51 29.62,1 43.6,1.52 0,0 1.07,5.91 3.31,18.12 -17.74,0.44 -34.85,-4.97 -46.91,-19.64z"/>
      <path id="Porta_Direita_Traseira" class="area str0" d="M223.11 19.43c1.32,8.04 3.64,17.34 14.95,29.09 0,0 -13.94,-0.37 -41.82,-1.12 -0.63,-6.8 -1.03,-16.12 -1.2,-27.97 12.3,0 21.66,0 28.07,0z"/>
      <path id="Vidro_Dianteiro3" class="area str0" d="M152.52 57.18c-3.8,-2.84 -9.44,-5.23 -16.91,-7.19 5.89,0.36 12.17,0.76 16.08,2.97 4.25,2.62 12.95,8.75 20.57,11.58 4.2,1.63 7.66,3.35 10.37,5.14 -6.29,-1.27 -11.77,-2.88 -16.45,-4.83 -2.59,-1.03 -5.46,-2.46 -8.62,-4.28 -1.92,-1.16 -3.6,-2.29 -5.04,-3.39z"/>
      <path id="Vidro_Direito_Traseiro" class="area str0" d="M196.2 47.4c19.1,0.51 33.05,0.89 41.86,1.12 -1.6,6.49 -4.18,12.06 -7.76,16.7 -8.76,0.34 -19.02,0.44 -30.79,0.3 -1.22,-6.67 -2.33,-12.71 -3.31,-18.12z"/>
      <path id="Para_Lama_Direito_Dianteiro" class="area str0" d="M139.2 36.76c4.29,-2.73 6.98,-6.22 8.07,-10.49 3.94,0.1 3.35,-0.07 2.71,2.21 -2.76,8.3 -10.53,13.9 -19.28,13.9 -8.75,0 -16.52,-5.6 -19.29,-13.9 1.9,0 2.85,0 2.85,0 2.86,4.8 6.68,8.18 11.47,10.16 4.63,1.52 9.12,0.9 13.47,-1.88z"/>
      <path id="Para_Lama_Direito_Traseiro" class="area str0" d="M238.23 36.76c-4.29,-2.73 -6.98,-6.22 -8.08,-10.49 -3.93,0.1 -3.34,-0.07 -2.7,2.21 2.76,8.3 10.53,13.9 19.28,13.9 8.75,0 16.52,-5.6 19.28,-13.9 -1.89,0 -2.84,0 -2.84,0 -2.86,4.8 -6.69,8.18 -11.47,10.16 -4.64,1.52 -9.13,0.9 -13.47,-1.88z"/>
    </g></g>
    </svg>
  </div>
