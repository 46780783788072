import { _applyPosFormatters } from "./pos-formatters/pos-formatters";
import { textFormatter } from "./text.formatter";

export function statusFormatter(row, cell, value, columnDef, dataContext) {
    if (!value) {
        return textFormatter(row, cell, value, columnDef, dataContext);
    }

    const contentValue = `<img title="${value.name}" class="impImg"
    style='width: 30px'
    src="./assets/app/media/img/icons/${value.code.toLowerCase()}-checked.png">`;
    return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
}