import { ReportPreferencesService } from '#services/_report-preferences/report-preferences.service';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ISave } from 'app/shared/components/cmx-save-config/interfaces/save.interface';
import { NotificationService } from '#services/_notification/notification.service';
import * as _ from 'lodash';
import { UserService } from 'app/auth/_services';

@Component({
    selector: 'followup-unified-customization',
    templateUrl: './followup-unified-customization.component.html',
    styleUrls: ['./followup-unified-customization.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [],
})
export class FollowupUnifiedCustomizationComponent {
    
    @Input() reportId? = 'SKU';

    selectedSave: ISave;
    saveChanges: ISave;
    forceClear: boolean = false;
    asyncCustomization: boolean = false;
    currentUser = this.userService.getCurrentUser();
    currentUser$ = this.userService.currentUser$;

    constructor(
        public reportPreferencesService: ReportPreferencesService,
        private notificationService: NotificationService,
        private userService: UserService
    ) { }

    ngAfterViewInit() {
        if(this.reportPreferencesService.selectedSave) {
            this.onSelectSave(this.reportPreferencesService.selectedSave);
        }
    }

    onSaveChanges(changes) {
        this.selectedSave = _.cloneDeep(changes);
        this._verifySaveChanges(changes.save, changes)
    }

    onNewSave(newSave) {
        this.selectedSave = newSave;
        this._changeGroupAndColumns(this.reportPreferencesService.currentDefaultReport);
    }

    onSelectSave(save) {
        this.selectedSave = save;
        this._changeGroupAndColumns(save);
    }

    onDeleteSave(save) {
        this.reportPreferencesService.deleteCustomReport(save.id, this.currentUser.has('FOLLOWUP_COMPLETE') ? false: true).subscribe((_response) => {
            this.reportPreferencesService.removeSaveFromList(save);
            this.reportPreferencesService.fupDeleteSubject.next(save);
            this.forceClear = true;
            setTimeout(() => { this.forceClear = false }, 1000);
        }, (_error) => {
            this.notificationService.openNotification('error', 'Não foi possível excluir seu save')
        })
    }

    saveCustomChanges(data) {
        if (!this.selectedSave?.title) {
            this.notificationService.openNotification('error', 'Por favor, digite um título')
            return
        }
        const save = {
            ..._.cloneDeep(this.selectedSave),
            id: this.selectedSave?.save?.id,
            reportName: `${this.selectedSave?.title}`,
            groups: data.leftData.map((group) => { return { name: group.name, columns: group.data, isCustom: group.isCustom } })
        }
        if (this.selectedSave?.save?.id) {
            this.asyncCustomization = true;
            this.reportPreferencesService.setCustomReport(save, this.currentUser.has('FOLLOWUP_COMPLETE') ? false: true).subscribe((response) => {
                this.reportPreferencesService.fupSaveSubject.next(save);
                this.asyncCustomization = false;
            })
        } else {
            this.asyncCustomization = true;
            this.reportPreferencesService.createCustomReport(this.reportId, save, this.currentUser.has('FOLLOWUP_COMPLETE') ? false: true).subscribe((response) => {
                this.forceClear = true;
                setTimeout(() => { this.forceClear = false }, 1000);
                this.reportPreferencesService.fupSaveSubject.next(save);
                this.asyncCustomization = false;
            })
        }
    }

    private _verifySaveChanges(save, currentValues) {
        if (save) {
            if (save.title !== currentValues.title ||
                save.description !== currentValues.description ||
                save.icon !== currentValues.icon ||
                save.color !== currentValues.color) {
                    const save = { ...currentValues }
                    delete save.save;
                    this.saveChanges = save;
            } else {
                this.saveChanges = null;
            }
        }
    }

    private _changeGroupAndColumns(changes) {
        this.reportPreferencesService.defineLeftAndRightData(
            this.reportPreferencesService.currentDefaultReport?.groups || [], 
            changes?.save?.groups || changes?.groups || [], true, true
        );
    }

    onBeforeDialogOpen() {
        if(this.reportPreferencesService.selectedSave) {
            const selected = _.cloneDeep(this.reportPreferencesService.selectedSave);
            delete selected.save;
            this._changeGroupAndColumns(selected);
        }
    }
}
