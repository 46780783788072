import { Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';

export type SizeButton = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type TypeButton = 
    // Green
    'save' | 'add' | 'success' |
    // Blue
    'primary' | 'edit' |
    // Red
    'delete' | 'danger' |
    // White
    'clear' |
    // Gray
    'secondary' | 'disabled' |
    // Others
    'warning' | 'alert' | 'neutral' | 'custom';
export type FormatButton = 'rounded' | 'square';
export type typeIconButton = 'awesome' | 'fa' | 'material';

@Component({
    selector: 'cmx-button',
    templateUrl: 'button.component.html',
    styleUrls: ['button.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class CmxButtonComponent {
    @Input() width: string = null;
    @Input() responsive: boolean = false;
    @Input() size: SizeButton = 'md';
    @Input() type: string = 'button';
    @Input() variant: TypeButton = 'primary';
    @Input() format: FormatButton = 'rounded';
    @Input() icon: string = null;
    @Input() iconType: typeIconButton = 'fa';
    @Input() disabled: boolean = false;
    @Input() hidden: boolean = false;
    @Input() customColor: string = 'black';
    @Input() customTextColor: string = 'white';
    @Input() styles: any = {};

    @ViewChild('refContentButton') contentButton: ElementRef;

    hasContent: boolean = false;

    ngAfterViewInit() {
        setTimeout(()=> {
            this.hasContent = this.contentButton.nativeElement.childNodes.length > 0 ? true : false;
        })
    }
}
