import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import dragula from 'dragula/dragula';
import * as _ from 'lodash';

import { CustomService } from '#services/_custom/custom.service';
import { UtilsService } from '#services/_utils/utils.service';
import { FollowUpModelService } from '../report-followup-model/report-followup-model.service';
import { FollowUpStructureService } from './report-followup-structure.service';

@Component({
    selector: 'report-followup-custom',
    templateUrl: './report-followup.custom.html',
    styleUrls: ['../../reports.component.scss', './report-followup.custom.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FollowUpReportCustom {
    @Output() onUpdate = new EventEmitter<number>();
    @Output() onReload = new EventEmitter<number>();
    @Output() onClose = new EventEmitter<number>();

    editableElements = ['.title', '.customContainer', '.customButtons', '.trashContainer'];

    lastGroupName;
    lastSpanAnchor;
    renameNewAnchor = false;
    newGroupName = '';
    newGroup = 0;
    selectedColumn;

    constructor(
        public followUpModelService: FollowUpModelService,
        public followUpStructureService: FollowUpStructureService,
        private customService: CustomService,
        private utilsService: UtilsService
    ) { }

    openInfoModal() {
        $('.overlayInfo').removeClass('hidden');
        $('.infoModal').removeClass('hidden');
    }
    closeInfoModal() {
        $('.overlayInfo').addClass('hidden');
        $('.infoModal').addClass('hidden');
    }
    openSavesModal() {
        $('.overlayInfo').removeClass('hidden');
        $('.savesModal').removeClass('hidden');
    }
    openDeleteModal() {
        $('.overlayInfo').removeClass('hidden');
        $('.deleteModal').removeClass('hidden');
    }
    closeDeleteModal() {
        $('.overlayInfo').addClass('hidden');
        $('.deleteModal').addClass('hidden');
    }
    callCloseOptions() {
        $('.overlayInfo').removeClass('hidden');
        $('.closeConfirmationModal').removeClass('hidden');
    }
    closeCloseOptions() {
        $('.overlayInfo').addClass('hidden');
        $('.closeConfirmationModal').addClass('hidden');
    }
    exitWithoutSave() {
        this.closeCloseOptions();
        this.onClose.emit();
    }
    exitAndSave() {
        this.closeCloseOptions();
        this.saveCustom();
    }

    setDragulaOptions(reset?) {
        $(document).ready(() => {
            $('.reportStructureModal').on('click', this.click.bind(this));
        });
        this.followUpStructureService.setGroupBag(
            dragula([document.querySelector('.customContainer')], {
                moves: (el, container, handle) => {
                    return handle.classList.contains('anchorHandler');
                },
            })
        );
        if (this.followUpStructureService.getColumnBag() && !reset) {
            const length = document.querySelectorAll('.columnsContainer').length;
            this.followUpStructureService
                .getColumnBag()
                .containers.push(document.querySelectorAll('.columnsContainer')[length - 2]);
        } else {
            this.followUpStructureService.setColumnBag(
                dragula([].slice.apply(document.querySelectorAll('.columnsContainer')), {})
                    .on('drag', (element) => {
                        if ($(element.parentElement).hasClass('trashColumns')) {
                            this.followUpStructureService.setDragAnchor('trashColumns');
                        } else {
                            this.followUpStructureService.setDragAnchor(
                                element.parentElement.parentElement.firstElementChild.innerText
                            );
                        }
                        this.selectedColumn = element.innerText;
                    })
                    .on('drop', (element, target, source) => {
                        if ($(element.parentElement).hasClass('trashColumns')) {
                            this.followUpStructureService.setDropAnchor('trashColumns');
                        } else {
                            this.followUpStructureService.setDropAnchor(
                                element.parentElement.parentElement.firstElementChild.innerText
                            );
                        }
                    })
            );
        }
    }

    click(element) {
        this._identifyNewGroupName();
        if ($(element.target).hasClass('titleContainer')) {
            if ($(element.target.lastElementChild).is('span')) {
                if (this.lastGroupName !== $(element.target.id)) {
                    $('#renameGroupInput').replaceWith(this.lastSpanAnchor);
                    this.newGroupName = '';
                }
                this.lastGroupName = $(element.target.parentElement.id);
                const input = $('<input id="renameGroupInput" [(ngModel)]="newGroupName" placeholder="Renomear">', {
                    val: $(this).text(),
                    type: 'text',
                    style: `width:${$(element.target).width()}`,
                });
                this.lastSpanAnchor = $(element.target.lastElementChild.outerHTML, {});
                $(element.target.lastElementChild).replaceWith(input);
                $('#renameGroupInput').focus();
                $('#renameGroupInput').keyup((e) => {
                    this.newGroupName = (document.getElementById('renameGroupInput') as HTMLInputElement).value;
                    if ($(element.target).hasClass('new')) {
                        this.renameNewAnchor = true;
                    } else {
                        this.renameNewAnchor = false;
                    }
                });
            }
        } else {
            if (!$(element.target).is('renameGroupInput')) {
                $('#renameGroupInput').replaceWith(this.lastSpanAnchor);
                this.newGroupName = '';
            }
        }
    }

    changeGroupName(newAnchor) {
        const selector = 'selector';
        if (!this.verifyGroupNameDuplicity()) {
            if (newAnchor) {
                this.lastSpanAnchor[0].innerText = this.newGroupName;
                this.lastSpanAnchor[0].id = this.newGroupName;
                this.followUpStructureService.getMergedFollowUp().groups.push({
                    name: this.newGroupName,
                    columns: [],
                    new: 'new',
                });
            } else {
                this.followUpStructureService.getMergedFollowUp().groups.forEach((anchor) => {
                    if (anchor.label === this.lastGroupName[selector]) {
                        anchor.name = this.newGroupName;
                        this.lastSpanAnchor = $(
                            `<span class="name" style="pointer-events:none">${this.newGroupName}</span>`,
                            {
                                val: $(this).text(),
                                type: 'text',
                                style: 'width:100%',
                            }
                        );
                    }
                });
            }
        } else {
            alert('Esse grupo já existe');
        }
    }

    removeRewriteGroupColumns(selectedGroup) {
        const atualStructure = this.getCurrentStructure(true);
        const atualTrash = this.getTrashStructure();
        this.followUpStructureService.setTrashColumns(atualTrash);
        // this.trashColumns = atualTrash;
        this.updateMergedJson(atualStructure);
        atualStructure.groups.forEach((structureGroup) => {
            // Varre a estrutura
            if (structureGroup.label === selectedGroup) {
                // Verifica o grupo selecionado na estrutura
                if (structureGroup.columns.length === 0) {
                    // Verifica se é inserir ou remover
                    const comparedColumns = [];
                    this.followUpStructureService.getDefaultFollowUp().groups.forEach((defaultAnchor) => {
                        // Varre o default
                        if (defaultAnchor.label === selectedGroup) {
                            // Verifica o grupo no default
                            defaultAnchor.columns.forEach((defaultColumn) => {
                                // Varre as colunas
                                this.followUpStructureService.getTrashColumns().forEach((trashColumn) => {
                                    // Varre a lixera
                                    if (defaultColumn.label === trashColumn.label) {
                                        // Compara as colunas
                                        this.followUpStructureService
                                            .getMergedFollowUp()
                                            .groups.forEach((mergedAnchor) => {
                                                // Varrer o Merged
                                                if (mergedAnchor.label === selectedGroup) {
                                                    // Verifico o grupo do Merged
                                                    mergedAnchor.columns.push(defaultColumn);
                                                    comparedColumns.push(defaultColumn);
                                                }
                                            });
                                    }
                                });
                            });
                        }
                    });
                    comparedColumns.forEach((comparedColumn) => {
                        _.pullAllBy(
                            this.followUpStructureService.getTrashColumns(),
                            [{ label: comparedColumn.label }],
                            'label'
                        );
                    });
                } else {
                    structureGroup.columns.forEach((structureColumn) => {
                        this.followUpStructureService.getDefaultFollowUp().groups.forEach((defaultAnchor) => {
                            defaultAnchor.columns.forEach((defaultColumn) => {
                                if (structureColumn.label === defaultColumn.label) {
                                    this.followUpStructureService.getTrashColumns().push(defaultColumn);
                                }
                            });
                        });
                        this.followUpStructureService.getMergedFollowUp().groups.forEach((mergedAnchor) => {
                            mergedAnchor.columns.forEach((mergedColumn) => {
                                if (structureColumn.label === mergedColumn.label) {
                                    _.pullAllBy(mergedAnchor.columns, [{ label: mergedColumn.label }], 'label');
                                }
                            });
                        });
                    });
                }
            }
        });
        this.updateMergedJson(this.followUpStructureService.getMergedFollowUp(), true);
        setTimeout(() => {
            this.onUpdate.emit();
        }, 1000);
    }

    resetAll() {
        this.updateMergedJson(this.getCurrentStructure(true));
        this.followUpStructureService.getMergedFollowUp().groups.map((anchor) => {
            if (anchor.new === 'new') {
                anchor.columns.splice(0, anchor.columns.length);
            }
            this.followUpStructureService.getDefaultFollowUp().groups.map((defaultAnchor) => {
                if (anchor.label === defaultAnchor.label) {
                    anchor.columns = [];
                    defaultAnchor.columns.map((column) => {
                        anchor.columns.push(_.cloneDeep(column));
                    });
                }
            });
        });
        this.followUpStructureService.setTrashColumns([]);
    }

    resetAllNames() {
        const elementAnchors = this.getElementGroups();
        let groupId;
        Array.from(elementAnchors).forEach((elementAnchor) => {
            let found = false;
            this.followUpStructureService.getLabelWithSpace().forEach((spacedLabel) => {
                if (elementAnchor.id === spacedLabel.replace(/\s/g, '')) {
                    groupId = spacedLabel
                    found = true;
                } else if (!found) {
                    groupId = elementAnchor.id
                }
            });
            const element = elementAnchor.firstElementChild.lastElementChild as HTMLElement;
            if (groupId === '' || groupId === null || groupId === undefined) {
                this.newGroup++;
                $(element).html(`NovoGrupo${this.newGroup}`);
            } else {
                $(element).html(groupId);
            }
        });
    }

    callResetGroupOrder() {
        this.resetGroupOrder();
        setTimeout(() => {
            this.resetGroupOrder();
        }, 100);
    }
    resetGroupOrder() {
        this.followUpStructureService
            .getDefaultOrderGroups()
            .reverse()
            .forEach((order) => {
                $(`.anchorContainer#${order.replace(/\s/g, '')}`).prependTo('.customContainer');
            });
        this.followUpStructureService.getDefaultOrderGroups().reverse();
        this.updateMergedJson(this.getCurrentStructure(true));
    }

    createGroup() {
        this.updateMergedJson(this.getCurrentStructure(true));
        setTimeout(() => {
            this.newGroup++;
            const name = `NovoGrupo${this.newGroup}`;
            this.followUpStructureService.getMergedFollowUp().groups.push({
                label: name,
                name,
                columns: [],
                new: 'new',
            });
            this.updateMergedJson(this.getCurrentStructure(true));
            const width = $('.customContainer')[0].scrollWidth;
            const table = $('.customContainer');
            table.stop();
            table.animate(
                {
                    scrollLeft: width,
                },
                1000
            );
        }, 500);
        setTimeout(() => {
            this.onUpdate.emit();
        }, 500);
    }

    removeGroup(group) {
        const atualStructure = this.getCurrentStructure(true);
        this.updateMergedJson(atualStructure);
        const atualTrash = this.getTrashStructure();
        this.followUpStructureService.setTrashColumns(atualTrash);
        atualStructure.groups.forEach((structureGroup) => {
            if (structureGroup.name === group.name) {
                structureGroup.columns.forEach((structureColumn) => {
                    this.followUpStructureService.getDefaultFollowUp().groups.forEach((defaultAnchor) => {
                        defaultAnchor.columns.forEach((defaultColumn) => {
                            if (structureColumn.label === defaultColumn.label) {
                                this.followUpStructureService.getTrashColumns().push(defaultColumn);
                            }
                        });
                    });
                    this.followUpStructureService.getMergedFollowUp().groups.forEach((mergedAnchor) => {
                        mergedAnchor.columns.forEach((mergedColumn) => {
                            if (structureColumn.label === mergedColumn.label) {
                                _.pullAllBy(mergedAnchor.columns, [{ label: mergedColumn.label }], 'label');
                            }
                        });
                    });
                });
            }
        });
        _.pullAllBy(this.followUpStructureService.getMergedFollowUp().groups, [{ name: group.name }], 'name');
        this.updateMergedJson(this.followUpStructureService.getMergedFollowUp());
        setTimeout(() => {
            this.onUpdate.emit();
        }, 1000);
    }

    getCurrentStructure(complete?) {
        const newFollowUpStructure = {
            groups: [],
        };
        const elementAnchors = this.getElementGroups();
        Array.from(elementAnchors).forEach((elementAnchor) => {
            const element = elementAnchor.firstElementChild.lastElementChild as HTMLElement;
            newFollowUpStructure.groups.push({
                name: element.innerHTML,
                label: elementAnchor.id,
                columns: [],
            });
            const elementColumns = elementAnchor.children[1].children;
            Array.from(elementColumns).forEach((elementColumn) => {
                newFollowUpStructure.groups.map((newAnchor) => {
                    if (newAnchor.name === element.innerHTML) {
                        newAnchor.columns.push($(elementColumn).data('value'));
                    }
                });
            });
        });
        if (!complete) {
            const newFollowUpStructureTemp = [];
            newFollowUpStructure.groups.map((group) => {
                if (group.columns.length !== 0) {
                    newFollowUpStructureTemp.push(group);
                }
            });
            newFollowUpStructure.groups = newFollowUpStructureTemp;
        }
        return newFollowUpStructure;
    }

    getTrashStructure() {
        const newTrash = [];
        const trashElements = this.getTrashColumns();
        Array.from(trashElements).forEach((trashElement) => {
            newTrash.push($(trashElement).data('value'));
        });
        return newTrash;
    }

    saveCustom() {
        this._identifyNewGroupName();
        this.utilsService.setInvisible('report-followup-custom', this.editableElements);
        setTimeout(() => {
            this.followUpStructureService.setAsyncCustomization(false);
            const newStructureforSave = this.getCurrentStructure();
            newStructureforSave.groups = this.resetLabelSpaces(newStructureforSave.groups);
            this.customService
            .saveCustomization(
                'report',
                this.getFollowUpType(window.location.pathname),
                newStructureforSave,
                this.followUpModelService.getSelectedFollowUpEditModel().modelName
            )
            .subscribe((saveResponse) => {
                this.onReload.emit();
                this.onClose.emit();
                this.followUpStructureService.setAsyncCustomization(true);
                setTimeout(() => {
                    this.utilsService.setVisible('report-followup-custom', this.editableElements);
                }, 500);
            });
        }, 500);
    }

    deleteCustom() {
        this.closeDeleteModal();
        this.utilsService.setInvisible('report-followup-custom', this.editableElements);
        this.followUpStructureService.setAsyncCustomization(false);
        this.customService.deleteCustomization('report', this.getFollowUpType(window.location.pathname)).subscribe((deleteResponse) => {
            this.callResetGroupOrder();
            this.resetAllNames();
            this.resetAll();
            _.pullAllBy(this.followUpStructureService.getMergedFollowUp().groups, [{ new: 'new' }], 'new');
            $('.customContainer').animate(
                {
                    scrollLeft: 0,
                },
                0
            );
            this.followUpStructureService.setAsyncCustomization(true);
            this.newGroup = 0;
            this.onReload.emit();
            this.onClose.emit();
            setTimeout(() => {
                this.utilsService.setVisible('report-followup-custom', this.editableElements);
            }, 500);
        });
    }

    mergeCustomFollowUp() {
        this.followUpStructureService.setTrashColumns([]);
        this.followUpStructureService.setAsyncCustomization(false);
        this.utilsService.setInvisible('report-followup-custom', this.editableElements);
        this.customService.getDefault('report', this.getFollowUpType(window.location.pathname)).subscribe((defaultResult) => {
            this.customService
                .getCustomization(
                    'report',
                    this.getFollowUpType(window.location.pathname),
                    this.followUpModelService.getSelectedFollowUpEditModel().modelName
                )
                .subscribe((customResult) => {
                    this.mergeStructure(defaultResult, customResult);
                });
        });
    }

    private mergeStructure(defaultResult: any, customResult: any) {
        this.followUpStructureService.setDefaultFollowUp(defaultResult);
        this.followUpStructureService.getDefaultFollowUp().groups.forEach((group) => {
            this.identifyLabelWithSpaces(group.label);
            group.label = group.label.replace(/\s/g, '');
            this.followUpStructureService.getDefaultOrderGroups().push(group.label);
        });

        this.followUpStructureService.setCustomFollowUp(customResult);

        // Grupos que possuem colunas são inseridas no Merged, na ordem customizada.
        this.followUpStructureService.getMergedFollowUp().groups = customResult.groups;
        this.followUpStructureService.getMergedFollowUp().groups.forEach((mergedGroup) => {
            mergedGroup.label = mergedGroup.label.replace(/\s/g, '');
        });

        // Verifica quais grupos são novos (criados pelo usuário)
        this.setNewGroups();

        // Grupos do Default vazios são inseridos no Merged.
        this.setEmptyDefaultGroups();

        // Colunas que não estão no Merged, são inseridas na lixeira.
        this.notMergedToTrash();

        setTimeout(() => {
            this.followUpStructureService.setAsyncCustomization(true);
            this.utilsService.setVisible('report-followup-custom', this.editableElements);
            this.setDragulaOptions(true);
        }, 500);
    }

    private verifyGroupNameDuplicity() {
        let duplicityFound = false;
        const elementAnchors = this.getElementGroups();
        Array.from(elementAnchors).forEach((elementAnchor) => {
            const element = elementAnchor.firstElementChild.lastElementChild as HTMLElement;
            if ($(element).hasClass('name')) {
                if (element.innerText.toLowerCase() === this.newGroupName.toLowerCase()) {
                    duplicityFound = true;
                }
            }
        });
        return duplicityFound;
    }

    private setNewGroups() {
        this.followUpStructureService.getMergedFollowUp().groups.map((mergedGroup) => {
            if (!this.followUpStructureService.getDefaultOrderGroups().includes(mergedGroup.label)) {
                mergedGroup.new = 'new';
            }
        });
    }
    private setEmptyDefaultGroups() {
        let isEmpty = true;
        this.followUpStructureService.getDefaultFollowUp().groups.map((defaultGroup) => {
            isEmpty = true;
            this.followUpStructureService.getMergedFollowUp().groups.map((mergedGroup) => {
                if (defaultGroup.label === mergedGroup.label) {
                    isEmpty = false;
                }
            });
            if (isEmpty) {
                this.followUpStructureService.getMergedFollowUp().groups.push({
                    label: defaultGroup.label,
                    name: defaultGroup.name,
                    columns: [],
                });
            }
        });
    }
    private notMergedToTrash() {
        const defaultColumns = [];
        const mergedColumns = [];
        let used = false;
        const notUsedColumns = [];
        this.followUpStructureService.getDefaultFollowUp().groups.map((defaultGroup) => {
            defaultGroup.columns.map((column) => {
                defaultColumns.push(column);
            });
        });
        this.followUpStructureService.getMergedFollowUp().groups.map((mergedGroup) => {
            mergedGroup.columns.map((column) => {
                mergedColumns.push(column);
            });
        });
        defaultColumns.map((defaultColumn) => {
            used = false;
            mergedColumns.map((mergedColumn) => {
                if (defaultColumn.label === mergedColumn.label) {
                    used = true;
                }
            });
            if (!used) {
                this.followUpStructureService.getTrashColumns().push(defaultColumn);
            }
        });
    }

    private getElementGroups() {
        const structure = $('.customContainer')[0];
        return structure.children;
    }
    private getTrashColumns() {
        const structure = $('.trashColumns')[0];
        return structure.children;
    }

    private updateMergedJson(updater, ignoreTrash?) {
        if (!ignoreTrash) {
            this.followUpStructureService.setTrashColumns(this.getTrashStructure());
        }
        const mergedFollowUpNew = JSON.parse(this.stringify(updater));
        mergedFollowUpNew.groups.forEach((newGroup, index) => {
            this.followUpStructureService.getMergedFollowUp().groups.forEach((group) => {
                if (newGroup.label === group.label) {
                    group.index = index;
                    group.name = newGroup.name;
                    group.columns.splice(0, group.columns.length);
                    newGroup.columns.forEach((newColumn) => {
                        group.columns.push(newColumn);
                    });
                }
            });
        });
        this.followUpStructureService.getMergedFollowUp().groups.sort((a, b) => {
            return a.index - b.index;
        });
    }
    private stringify(column) {
        return JSON.stringify(column);
    }

    private getDomainType(url) {
        const urlSplited = url.split('/');
        const currentUrlType = urlSplited[urlSplited.length - 1];
        const currentType = currentUrlType.split('-')[1];
        return currentType === 'chassi' || currentType  === 'automotive' || currentType === 'sku' ? currentType : 'imp';
    }

    private getFollowUpType(url) {
        const type = this.getDomainType(url);
        if (type === 'chassi' || type  === 'automotive') {
            return 'followup:chassi';
        } else if (type === 'sku') {
            return 'followup:sku';
        } else {
            return 'followup';
        }
    }

    private identifyLabelWithSpaces(label) {
        if (/\s/.test(label)) {
            this.followUpStructureService.getLabelWithSpace().push(label);
        }
    }

    private _identifyNewGroupName() {
        if (this.newGroupName !== '' && this.newGroupName !== undefined) {
            this.changeGroupName(this.renameNewAnchor);
        }
    }

    private resetLabelSpaces(structure) {
        structure.forEach((group) => {
            this.followUpStructureService.getLabelWithSpace().forEach((spacedGroup) => {
                if (group.label === spacedGroup.replace(/\s/g, '')) {
                    group.label = spacedGroup;
                }
            });
        });
        return structure;
    }
}
