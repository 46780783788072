import { take, map } from 'rxjs/operators';
import { toggles } from '#environment';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';

import { User } from '../_models';
import { SPECIFIC_USER_ROUTES } from '../constants/specific-user-routes';
import { AuthenticationService } from '../_services';
import { UserService } from '../_services/user.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    authenticationReady = false;
    globalLoader = false;

    private readonly __PERM__ = '__PERM__';

    private permissionsReady = false;

    private authenticationReadyTrigger = new Subject<boolean>();
    private permissionsReadyTrigger = new Subject<boolean>();

    private routeToPermissionTree = {};
    private pageToLogin = '/index';

    private currentUser: User = null;

    constructor(
        private router: Router,
        private userService: UserService,
        private authService: AuthenticationService,
        private userCacheService: UserCacheService,
    ){

        this.userService.currentUser$.subscribe((user) => {
            this.currentUser = user;

            if (user) {
                this.userCacheService.Init().then(() => {
                    this.authService.getPermissionsRoutes().subscribe((routes: any[]) => {

                        routes.filter(r => r.type === 'route').forEach((doc) => {
                            this.map(
                                doc.name,
                                this.tokenizePath(doc.path),
                                this.routeToPermissionTree
                            );
                        });
                        this.permissionsReady = true;
                        this.permissionsReadyTrigger.next(this.permissionsReady);
                        this.authenticationReady = true;
                        this.authenticationReadyTrigger.next(this.authenticationReady);
                    });
                });
            } else {
                this.authenticationReady = true;
                this.authenticationReadyTrigger.next(this.authenticationReady);
            }
        });

        this.authService.changeInProgress$.subscribe(() => {
            this.authenticationReady = false;
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

        if (this.authenticationReady) {
            return this.checkAuthenticationStatus(state.url);
        } else {
            if (
                navigator.userAgent.indexOf('MSIE') !== -1 ||
                navigator.appVersion.indexOf('Trident/') > -1 ||
                window.navigator.userAgent.indexOf('Edge') > -1
            ) {

                return this.checkAuthenticationStatus(state.url);
            } else {

                return combineLatest([
                    this.authenticationReadyTrigger.asObservable(),
                    this.userService.currentUser$.pipe(take(1)),
                ]).pipe(
                    take(1),
                    map(() => this.checkAuthenticationStatus(state.url))
                );
            }
        }
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    
        if (this.permissionsReady) {
            return this.checkAuthorizationFor(state.url);
        } else {

            if (
                navigator.userAgent.indexOf('MSIE') !== -1 ||
                navigator.appVersion.indexOf('Trident/') > -1 ||
                window.navigator.userAgent.indexOf('Edge') > -1
            ) {
                return this.checkAuthorizationFor(state.url);
            } else {
                return combineLatest([
                    this.permissionsReadyTrigger.asObservable(),
                    this.userService.currentUser$.pipe(take(1)),
                ]).pipe(
                    take(1),
                    map(() => this.checkAuthorizationFor(state.url))
                );
            }
        }
    }
    
    getPreviousPageLogin(): string {

        return this.pageToLogin;
    }
    setPreviousPageLogin(url): void {

        if (url !== '/index' && url !== '/auth-handler') {
            this.pageToLogin = url;
        }
    }

    private checkAuthenticationStatus(url: string) {

        this.setPreviousPageLogin(url);
        const allowAccess = this.userIsLoggedIn();
        if (!allowAccess) {
            this.router.navigate(['/login']);
        }
        return allowAccess;
    }

    private checkAuthorizationFor(url: string) {

        this.setPreviousPageLogin(url);
        if (this.userIsNotLoggedIn()) {
            this.router.navigate(['/login']);
            return false;
        }

        const permission = this.permissionFor(url);
        let allowRoute;

        if (this.isMissing(permission)) {
            allowRoute = this.allowMissingRoutePermission();
        } else {
            allowRoute = this.currentUser.has(permission);
        }

        if (!allowRoute) {
            this.router.navigate(['/403']);
        }

        this.userService.currentUser$.subscribe((user) => {
            if (user.has('SPECIFIC_USER') && (url === '' || url === '/index')) {
                this.router.navigate([SPECIFIC_USER_ROUTES.find((route) => user.has(route.permission)).route || '']);
                return false;
            }
        });

        return allowRoute;
    }

    private isMissing(permission: string) {

        return !permission;
    }

    private allowMissingRoutePermission() {

        return toggles.authorization.missingRoute === 'allow';
    }

    private userIsNotLoggedIn() {

        return !this.userIsLoggedIn();
    }

    private userIsLoggedIn(): boolean {

        return this.currentUser != null;
    }

    private map(permissionName: string, pathTokens: string[], tree: {}) {

        if (pathTokens.length === 0) {
            tree[this.__PERM__] = permissionName;
        } else {
            const token = pathTokens.shift();
            tree[token] = tree[token] || Object.create(null);
            this.map(permissionName, pathTokens, tree[token]);
        }
    }

    private tokenizePath(path: string) {

        return path.split('/').filter((s) => s.trim() !== '');
    }

    private permissionFor(path: string) {

        const pathTokens = this.tokenizePath(path);
        let tree = this.routeToPermissionTree;

        while (pathTokens.length > 0) {
            const token = pathTokens.shift();
            if (!tree[token]) {
                break;
            }
            tree = tree[token];
        }

        return tree[this.__PERM__];
    }
}
