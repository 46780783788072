import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import dragula from 'dragula/dragula';
import * as _ from 'lodash';

import { FollowUpModel } from './model.model';
import { FollowUpIconList } from './report-icon-list';
import { UtilsService } from '#services/_utils/utils.service';
import { FollowUpModelService } from './report-followup-model.service';
import { DatamartService } from '#services/_datamart/datamart.service';
import { UserService } from 'app/auth/_services';
import { DomainTypeService } from '#services/_domain/domainType.service';

@Component({
    selector: 'report-followup-model',
    templateUrl: './report-followup-model.component.html',
    styleUrls: ['./report-followup-model.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FollowUpReportModelComponent {

    @Output() onChangeSaveModel = new EventEmitter<any>();
    asyncModel = true;
    createMode = true;
    modelName = '';
    modelDescription = '';
    selectedIcon;
    selectedColor = '#808080';
    selectedModel: FollowUpModel;
    currentUser;
    type;

    constructor(
        public followUpIconList: FollowUpIconList,
        public followUpModelService: FollowUpModelService,
        private _utilsService: UtilsService,
        private _datamartService: DatamartService,
        private _userService: UserService,
        private _domainTypeService: DomainTypeService,
    ) {
        this.currentUser = this._userService.getCurrentUser();
     }

    ngAfterViewInit() {
        dragula([document.querySelector('.dragulaContainer')], {
        }).on('drop', (element, target, source) => {
            this.reorderModelList(target);
        });
    }

    closeModelsModal() {
        $('.overlayInfo').addClass('hidden');
        $('.savesModal').addClass('hidden');
    }

    selectIcon(icon) {
        this.selectedIcon = icon;
    }

    selectColor(color) {
        this.selectedColor = color;
    }

    selectMode(mode, model?, element?) {
        $('.saveListContainer .ball').removeClass('selected');
        if (mode === 'create') {
            this.selectedModel = undefined;
            this.createMode = true;
            this.selectedColor = '#808080';
            this.selectedIcon = undefined;
            this.modelName = '';
            this.modelDescription = '';
        } else {
            this.selectedModel = _.cloneDeep(model);
            this.createMode = false;
            this.selectedColor = model.color;
            this.selectedIcon = model.icon;
            this.modelName = model.modelName;
            this.modelDescription = model.description;
            $(element.target).addClass('selected');
        }
    }

    editCollunsModel(model?) {
        this.followUpModelService.setSelectedFollowUpEditModel(
            model
            ? model
            : this.selectedModel
        );
        this.onChangeSaveModel.emit(true);
        this.closeModelsModal();
    }

    editModel() {
        if (!this.compareInfo()) {
            this.asyncModel = false;
            this.followUpModelService.editModel({
                modelName: this.modelName,
                description: this.modelDescription,
                icon: this.selectedIcon,
                color: this.selectedColor,
            },
                this.selectedModel.modelName
            ).subscribe((editedModel) => {
                this.followUpModelService.getSaveModelList()
                .subscribe((modelList) => {
                    this.followUpModelService.setModelList(modelList);
                    this.asyncModel = true;
                    if (this.selectedModel.modelName ===
                    this.followUpModelService.getSelectedFollowUpEditModel().modelName) {
                        this.followUpModelService.setSelectedFollowUpEditModel(editedModel);
                    }
                }, (error) => {
                    this.handleError(error);
                });
            }, (error) => {
                this.handleError(error);
            });
        }
    }

    newModel() {
        if (this.validateNewModel()) {
            this.asyncModel = false;
            this.followUpModelService.saveNewModel({
                modelName: this.modelName,
                description: this.modelDescription,
                icon: this.selectedIcon,
                color: this.selectedColor,
            }).subscribe(() => {
                this.followUpModelService.getSaveModelList()
                .subscribe((modelList) => {
                    this.followUpModelService.setModelList(modelList);
                    this.selectCreatedModel();
                    this.asyncModel = true;
                }, (error) => {
                    this.handleError(error);
                });
            }, (error) => {
                this.handleError(error);
            });
        }
    }

    deleteModel() {
        this.asyncModel = false;
        const modelName = this.selectedModel.modelName;
        this.type = this._domainTypeService.getDomainType();
        let serviceId = this.type === 'imp' ? 'imp-service' : 'sku-service';
        let generatorId = this.type === 'imp' ? 'fup' : 'fup-sku';

        let datamartName = this.type === 'imp' ? 'FUP IMP' : 'FUP SKU';

        this._datamartService.setDatamartConfiguration(datamartName, serviceId, generatorId, [], modelName,
        { userId: [ this.currentUser.data.uid ], modelName: [modelName], field: [], clientGroupCodes: [] }).subscribe(() => {
            this.followUpModelService.deleteModel(modelName).subscribe(() => {
                this.followUpModelService.getSaveModelList()
                .subscribe((modelList) => {
                    this.followUpModelService.setModelList(modelList);
                    this.followUpModelService.setSelectedFollowUpEditModel(modelList[0]);
                    this.selectMode('create');
                    this.asyncModel = true;
                    this.onChangeSaveModel.emit(true);
                }, (error) => {
                    this.handleError(error);
                });
            }, (error) => {
                this.handleError(error);
            });
        }, (error) => {
            this.asyncModel = true;
            this._utilsService.errorHandler(error, 'Datamart');
        });
    }

    sortModelList(sortList) {
        this.followUpModelService.sortModelList(sortList)
        // tslint:disable-next-line:no-empty
        .subscribe((response) => { }, (error) => {
            this.handleError(error);
        });
    }

    compareInfo(): boolean {
        return this.selectedModel.modelName === this.modelName &&
        this.selectedModel.description === this.modelDescription &&
        this.selectedModel.color === this.selectedColor &&
        this.selectedModel.icon === this.selectedIcon;
    }

    validateNewModel(): boolean {
        return this.modelName !== '' &&
        this.modelDescription !== '' &&
        this.selectedColor !== '' &&
        this.selectedIcon;
    }

    private reorderModelList(newListOrder) {
        const sortList = [];
        Array.from(newListOrder.children)
        .forEach((
            newModelOrder: HTMLElement,
            index: number
        ) => {
            this.followUpModelService.getModelList()
            .forEach((model) => {
                if (newModelOrder.id === model.modelName) {
                    sortList.push({
                        modelName: model.modelName,
                        sorting: index,
                    });
                }
            });
        });
        this.sortModelList(sortList);
    }

    private selectCreatedModel() {
        this.followUpModelService.getModelList()
        .forEach((model: FollowUpModel) => {
            if (model.modelName === this.modelName) {
                this.editCollunsModel(model);
            }
        });
    }

    private handleError(error) {
        this.asyncModel = true;
        this._utilsService.errorHandler(error, 'Customização FollowUp');
    }

    trackByFn(index: number, item: any) {
        return index;
    }
}
