import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'keyValue',
})
export class KeyValuePipe implements PipeTransform {
    transform(obj: { [key: string]: any }): Array<{ key: string; value: any }> {
        const objectKeys = Object.keys(obj || {});

        return objectKeys && objectKeys.length
            ? objectKeys?.map((key: string) => ({
                  key,
                  value: obj[key],
              }))
            : [];
    }
}
