import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesOrNo',
})
export class BooleanYesOrNoPipe implements PipeTransform {
  transform(value: boolean) {
    return value ? 'Sim' : 'Não';
  }
}
