<ng-template #loader>
    <loader></loader>
</ng-template>

<span *ngIf="asyncPermisssionInfo === true; else loader" class="clientsAndCrs">  
    <div *ngIf = "currentUser.has('DASHBOARD_PERMISSION_INFO')">
        <span style='margin: 0 5px 0 10px; color: #0091D6'>Visualizando: </span>
        <span *ngIf='clients.length > 1' (click)='openPermissionInfoModal()'>
            <span class="hoverable">
                <span *ngIf = "preferenceClients && preferenceClients.length < clients.length" >
                    {{preferenceClients.length}} de
                </span>
              {{clients.length}} Clientes
            </span>
            <span style='margin: 0px 5px'>|</span>
        </span>
        <span *ngIf="clients.length === 1">
            <span>{{clients[0].name}}</span>
            <span style='margin: 0px 5px'>|</span>
        </span>
        <span *ngIf='crs.length > 1' (click)='openPermissionInfoModal()'>
            <span class="hoverable">{{crs.length}} CRs</span>
        </span>
        <span *ngIf="crs.length === 1">
            <span>CR</span>
            <span>{{crs[0].code}} - {{crs[0].name}}</span>
        </span>
    </div>
</span>

<div class="permissionInfoModal hidden">
    <div class="content">
        <i (click)="closePermissionInfoModal()" class="la la-close close"></i>
        <div class="title">
            Permissões de Visualização
        </div>
        <div class="groupPermissionList">
            <div style="border-right: 1px solid grey" class="permissionList"
            *ngIf = "preferenceClients && preferenceClients.length < clients.length" >
                <div class="listTitle">
                    Preferência do Usuário
                </div>
                <div class="table">
                    <table class="list">
                        <tr *ngFor="let client of preferenceClients; trackBy: trackByFn">
                            <td>{{client.itemName}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div style="border-right: 1px solid grey" class="permissionList">
                <div class="listTitle">
                    Clientes
                </div>
                <div class="table">
                    <table class="list">
                        <tr *ngFor="let client of clients; trackBy: trackByFn">
                            <td>{{client.name}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="permissionList">
                <div class="listTitle">
                    CRs
                </div>
                <div class="table">
                    <table class="list">
                        <tr *ngFor="let cr of crs; trackBy: trackByFn">
                            <td>{{cr.name}} ({{cr.code}})</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
