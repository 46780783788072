import { _applyPosFormatters } from "./pos-formatters/pos-formatters";

export function textFormatter(_row, _cell, value, columnDef, _dataContext) {
    const includeZero = columnDef.formatterData && columnDef.formatterData.includeZero && value === 0;
    if ((!value && !includeZero) || (typeof value === 'object' && Array.isArray(value) && !value?.length)) {
        return (columnDef.formatterData && columnDef.formatterData.emptyMessage) || '\u2014';
    }
    const classes = (columnDef.formatterData && columnDef.formatterData.classes) || '';
    const hoverTitle = (columnDef.formatterData && columnDef.formatterData.hoverTitle) || false;
    let contentValue = '';
    if (columnDef.formatterData && columnDef.formatterData.singleField) {
        const objectValue = columnDef.formatterData.singleField;
        contentValue = hoverTitle
            ? `<span class="${classes}" title="${value[objectValue]}">${value[objectValue]}</span>`
            : `<span class="${classes}">${value[objectValue] || '\u2014'}</span>`;
    } else {
        contentValue = hoverTitle ? `<span class="${classes}" title="${value}">${value}</span>` : `<span class="${classes}">${value}</span>`;
    }
    return _applyPosFormatters(_row, _cell, contentValue, columnDef, _dataContext);
}
