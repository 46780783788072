import { IGridColumn } from '../../../../../shared/components/cmx-custom-grid/interfaces/grid-column.interface';

export const CHASSI_STATUS_SLICK_COLUMNS: IGridColumn[] = [
    {
        name: 'Chassi',
        width: 200,
        field: 'chassi',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
    {
        name: 'IMP',
        field: 'imp',
        formatterType: 'badge',
        formatterData: {
            backgroundColor: '#808080',
            classes: 'pointer',
        },
        width: 130,
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
    {
        name: 'Nº Embarque',
        width: 120,
        field: 'boardingNumber',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
    {
        name: 'Cliente',
        width: 200,
        field: 'client',
    },
    {
        name: 'B/L',
        width: 150,
        field: 'bl',
    },
    {
        name: 'Tipo',
        width: 120,
        field: 'vehicleType',
    },
    {
        name: 'Modelo',
        width: 195,
        field: 'model',
    },
    {
        name: 'Cor',
        width: 165,
        field: 'color',
    },
    {
        name: 'Status',
        field: 'status',
        formatterType: 'status',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        width: 75,
    },
    {
        name: 'Valor Invoice',
        field: 'invoiceAmount',
        formatterType: 'currency',
        formatterData: {
            locale: 'pt-BR',
            currency: 'USD',
        },
        width: 140,
        headerCssClass: 'grid-header align-right',
        cssClass: 'grid-cell align-right',
    },
    {
        name: 'Embarque',
        field: 'shippingDate',
        width: 150,
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        formatterType: 'date',
    },
    {
        name: 'E.T.A',
        field: 'eta',
        width: 150,
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        formatterType: 'date',
    },
    {
        id: 11,
        name: 'Registro DA',
        width: 130,
        field: 'daNumber',
    },
    {
        name: 'Data Registro DA',
        field: 'daRegistrationDate',
        width: 150,
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        formatterType: 'date',
    },
    {
        name: 'Canal DA',
        width: 110,
        field: 'daChannel',
    },
    {
        name: 'Registro DI',
        width: 130,
        field: 'diNumber',
    },
    {
        name: 'Data Registro DI',
        field: 'diRegistrationDate',
        width: 150,
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        formatterType: 'date',
    },
    {
        name: 'Canal DI',
        width: 110,
        field: 'diChannel',
        formatterType: 'round',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
    {
        name: 'Pres. de Carga',
        field: 'cargoPresenceDate',
        width: 150,
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        formatterType: 'date',
    },
    {
        name: 'Data Saída',
        field: 'checkoutDate',
        width: 150,
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        formatterType: 'date',
    },
    {
        name: 'Situação PDI',
        field: 'pdiSituation',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        formatterType: 'textStatus',
        formatterData: {
            theme: 'default',
            size: 'md',
        },
        width: 160,
    },
]
