import { Component, Input, Output, EventEmitter, ViewEncapsulation, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { FORM_MOCK } from './cmx-form.mock';
import { IFormRow } from './cmx-form.interface';
import moment from 'moment';

@Component({
    selector: 'cmx-form',
    templateUrl: './cmx-form.component.html',
    styleUrls: ['./cmx-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class FormComponent {

    @Input() fieldRows: IFormRow[] = FORM_MOCK;
    controlNames: string[] = [];
    controlArrays: object = { };
    form: FormGroup;
    currentDate = moment().format('DD/MM/YYYY');

    @Output('onChanges') onChanges = new EventEmitter;
    @Output('onSplitterButtonClick') onSplitterButtonClick = new EventEmitter;

    constructor(private _fb: FormBuilder) { }

    ngOnInit() {
        this._createForm();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.fieldRows && changes.fieldRows.currentValue) {
            this.fieldRows = changes.fieldRows.currentValue;
            this._createForm();
        }
    }

    onChange(controlName) {
        this.onChanges.emit({ form: this._convertArrayAtributes(this.form.getRawValue()),
            changed: controlName, valid: this.form.valid && this._validateRequired() });
    }

    onSplitterLineSwitch(changes, controlName) {
        this.form.controls[controlName].setValue(changes?.name);
        this.onChange(controlName);
    }

    onSplitterButton(button, row) {
        this.onSplitterButtonClick.emit(button)
    }

    onDatepickerChanges(changes, controlName) {
        this.form.controls[controlName].setValue(changes?.startDate);
        this.onChange(controlName);
    }

    preventEnterKey(event) {
        event.preventDefault();
    }

    private _createForm() {
        this.controlNames = [];
        let controller = { };
        this.fieldRows.forEach((row: IFormRow, rowIndex) => {
            if (row?.splitterLine?.enable) {
                this.controlNames.push(`splitter${rowIndex}`);
                controller[`splitter${rowIndex}`] = false;
            }
            row.fields.forEach((field) => {
                let control = []
                if (field.type === 'boolean') {
                    control.push(field.initialValue ? field.initialValue : false)
                } else {
                    const value = field.initialValue ? field.initialValue : null
                    control = [value];
                    if (field.pattern && field.required) {
                        control = [value, [Validators.pattern(field.pattern), Validators.required]];
                    } else if (field.pattern) {
                        control = [value, [Validators.pattern(field.pattern)]];
                    } else if (field.required) {
                        control = [value, [Validators.required]];
                    }
                }
                this.controlNames.push(field.controlName);
                controller[field.controlName] = control;
            })
        })
        this.form = this._fb.group(controller);
    }

    private _validateRequired() {
        let valid = true;
        this.fieldRows.forEach((row: IFormRow) => {
            row.fields.forEach((field) => {
                if (field.required && !this.form.controls[field.controlName].value
                    && field.type !== 'boolean') {
                    valid = false;
                }
            })
        });
        return valid;
    }

    private _convertArrayAtributes(form) {
        const convertedForm = _.cloneDeep(form);
        Object.entries(convertedForm).forEach(([key, value]) => {
            if (key.includes('_')) {
                const arrayKeyName = key.split('_')[0];
                const index = key.split('_')[1];
                const arrayName = arrayKeyName.split('-')[0];
                const keyName = arrayKeyName.split('-')[1];
                convertedForm[arrayName] = convertedForm[arrayName] ? convertedForm[arrayName] : [];
                convertedForm[arrayName][index] = convertedForm[arrayName][index] ? convertedForm[arrayName][index] : { };
                convertedForm[arrayName][index][keyName] = value;
                delete convertedForm[key];
            }
        })
        return convertedForm;
    }

}
