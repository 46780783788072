import { _applyPosFormatters } from "./pos-formatters/pos-formatters";
import { textFormatter } from "./text.formatter";

export function preIconFormatter(row, cell, value, columnDef, dataContext) {
    if (!value) {
        return textFormatter(row, cell, value, columnDef, dataContext);
    }
    const preIcon =
        columnDef.formatterData && columnDef.formatterData.preIcon
            ? `<img class="pre-icon" src="./assets/app/media/img/icons/${columnDef.formatterData.preIcon}.svg">`
            : '';
    const preIconHoverTitle = (columnDef.formatterData && columnDef.formatterData.preIconHoverTitle) || '';
    const activePremiseField = (columnDef.formatterData && columnDef.formatterData.activePremiseField) || false;
    const activePremiseValues = (columnDef.formatterData && columnDef.formatterData.activePremiseValues) || [];
    const contentValue =
        activePremiseField && !activePremiseValues.includes(dataContext[activePremiseField])
            ? value
            : `<div class="pre-icon-container" title="${preIconHoverTitle}">${preIcon} ${value}</div>`;
    return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
}