<div class="splitter-line" [ngClass]="{ 'title-alone': (title && !rightButton && !buttonList.length && !switch.length) }">
    <div class="line-title" [ngClass]="{'title-margin-right': (buttonList && title && !switch), 'subtitle': subtitle}">
        <span class="material-icons" [ngClass]="{'icon-margin-right': iconLine }" aria-hidden="true" *ngIf="iconLine">{{ iconLine }}</span>
        <span>{{ title }}</span>
    </div>
    <div *ngIf="buttonList && buttonList.length" id="top-button-list-line">
        <div *ngFor="let button of buttonList; let i = index" [id]="button.code"
            class="splitter-button {{  buttonList.length === 1 ? 'left right' :  i === 0 ? 'left' : i === buttonList.length - 1 ? 'right' : '' }}
            {{ button.disabled ? 'disabled' : '' }}"
            [style.background-color]="button.color || '#ddd'"
            [style.color]="button.textColor || 'grey'"
            [nowrap]="true"
            [tooltip]="button.tooltip && button.disabled === true ? '' : button.tooltip"
            (mouseover)="listButtonMouseHover(button)"
            (mouseout)="listButtonMouseHover(button, 'out')"
            (click)="listButtonClicked(button, $event)">
            <span *ngIf="button.disabled" [tooltip]="button.disabledText || ''" [nowrap]="true" class="material-icons disabled-icon">report_problem</span>
            <span *ngIf="button.leftIcon && !button.disabled" class="material-icons {{ button.name ? 'left' : '' }}">{{ button.leftIcon }}</span>
            <span >{{ button.name }}</span>
            <span *ngIf="button.multiple" class="multiple material-icons">keyboard_arrow_down</span>
            <span *ngIf="button.rightIcon && button.name && !button.disabled" class="material-icons right">{{ button.rightIcon }}</span>
        </div>
    </div>
    <div *ngIf="switch.length">
        <cmx-switch
            [options]="switch"
            [selected]="selectedSwitch"
            (onSelect)="selectSwitch($event)"
        ></cmx-switch>
    </div>
    <div *ngIf="info"
        [tooltip]="info"
        [placement]="placement || 'top'"
        [nowrap]="infoNoWrap"
        class="info-container-splitter">
    <span>i</span></div>
    <hr class="line-hr" />
    <div *ngIf="rightButtonList && rightButtonList.length" id="right-button-list">
        <div *ngFor="let button of rightButtonList; let i = index"
            [id]="button.code"
            class="splitter-button {{  rightButtonList.length === 1 ? 'left right' : i === 0 ? 'left' : i === rightButtonList.length - 1 ? 'right' : '' }}
            {{ button.disabled ? 'disabled' : '' }}"
            [style.background-color]="button.color || '#ddd'"
            [style.color]="button.textColor || 'grey'"
            [nowrap]="true"
            [tooltip]="button.tooltip && button.disabled === true ? '' : button.tooltip"
            (mouseover)="listButtonMouseHover(button)"
            (mouseout)="listButtonMouseHover(button, 'out')"
            (click)="rightButtonClicked(button, $event)">
            <span *ngIf="button.disabled" [tooltip]="button.disabledText || ''" [nowrap]="true" class="material-icons disabled-icon">report_problem</span>
            <span *ngIf="button.leftIcon && !button.disabled" class="material-icons {{ button.name ? 'left' : '' }}">{{ button.leftIcon }}</span>
            <span >{{ button.name }}</span>
            <span *ngIf="button.multiple" class="multiple material-icons">keyboard_arrow_down</span>
            <span *ngIf="button.rightIcon && button.name && !button.disabled" class="material-icons right">{{ button.rightIcon }}</span>
        </div>
    </div>
</div>
