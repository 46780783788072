<ng-template #loader>
    <loader></loader>
</ng-template>

<ng-template #trace>
    <span class="invoice-percentage">-</span>
</ng-template>

<div *ngIf="currentUser$ | async as user">
    <div class="moveBtn">
        <div class="moveLine"></div>
    </div>

    <div (click)='changeMonth(-1)' *ngIf="currentMonth !== '2017-01'" class="btnMonth leftBtnMonth">
        <rem class="la la-angle-left"></rem>
    </div>
    <div (click)='changeMonth(+1)' *ngIf="changedMonth < 0" class="btnMonth rightBtnMonth">
        <rem class="la la-angle-right"></rem>
    </div>

    <div id="headerContainer">
        <span class="analyticsName">Cartão de Métricas</span>
        <cmx-help-modal
            help-title="Cartão de Métricas"
            [help-description]="helpDescription"
        ></cmx-help-modal>

        <div style="display: flex;">
            <h5>Cartão de Métricas</h5>
            <h5 style="margin-left: 5px">&#183; {{UIcurrentMonth}}<span></span><span
                    *ngIf="invoicesGrouping == true">&#183; {{ client }}</span></h5>
        </div>

        <div *ngIf="user.has('INTERNAL_FILTERS')" class="toggleIntraCompanyContainer">
            <cmx-filter-switch
                size="lg"
                [values]="intraSwitch.values"
                [inputIndex]="intraSwitch.values.indexOf(filterState.getIntraCompany() === true ? 'c/ IntraCompany' : 's/ IntraCompany')"
                (onSwitch)="toggleTypeSwitch($event.value === 'c/ IntraCompany' ? true : false )"
            ></cmx-filter-switch>
        </div>

        <div *ngIf="user.has('ROUTE_AUTOMOTIVE') && !user.has('AUTOMOTIVE_USER')"
        title="Modo Automotivo: {{filterState.getAutomotiveMode()}}" class="filterMode"
        [ngClass]="{'clicked': filterState.getAutomotiveMode() === 'Ligado'}"
        (click)="toggleFilterMode()">
            <div><span class="material-icons">directions_car</span></div>
        </div>
    </div>

    <div class="flex-invoices">
        <!-- Processos Faturados por Mês -->
        <div class="flex-invoice">
            <div *ngIf="asyncInvoicesBilledItens == true; else loader" style="height: 100%;">
                <div *ngIf="dataInvoicesBilledItens !== undefined" style="height: 100%;">
                    <h1 (click)="toIMPStatus()" *ngIf="user.has('ROUTE_SUPPLY_CHAIN_IMP_STATUS') && dataInvoicesBilledItens > 0" id="invoiceData1">
                    <span class="invoiceData">{{ dataInvoicesBilledItens }}
                    <rem style='font-size: 18px;' class="flaticon-folder-2"></rem></span></h1>
                    <h1 *ngIf="!user.has('ROUTE_SUPPLY_CHAIN_IMP_STATUS') || dataInvoicesBilledItens <= 0" id="invoiceData1"><span>{{ dataInvoicesBilledItens }}</span></h1>
                    <h4 *ngIf="!user.has('ROUTE_AUTOMOTIVE') || (!user.has('AUTOMOTIVE_USER') && user.has('ROUTE_AUTOMOTIVE') && filterState.getAutomotiveMode() === 'Desligado')" class="invoice-title">IMPs Faturadas no mês</h4>
                    <h4 *ngIf="user.has('AUTOMOTIVE_USER') || (user.has('ROUTE_AUTOMOTIVE') && filterState.getAutomotiveMode() === 'Ligado')" class="invoice-title">Chassis Faturados no mês</h4>
                    <div class="invoice-progress progress">
                        <div id="dataInvoicesBilledItens" style="background-color: #652D90;" class="progress-bar"
                        role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"
                        [style.width]="percentageInvoicesBilledItens + '%'"></div>
                    </div>
                    <span class="invoice-detail">Rel. Mês Anterior</span>
                    <span *ngIf="UIcurrentMonth != '01/2017' ; else trace" class="invoice-percentage">{{ percentageInvoicesBilledItens }}%</span>
                </div>
                <div *ngIf="dataInvoicesBilledItens === undefined" class="reloadContent">
                    <rem (click)="loadImpOrChassi(user)" class="material-icons">refresh</rem>
                </div>
            </div>
        </div>
        <!-- Faturas Recebidas -->
        <div class="flex-invoice">
            <div *ngIf="asyncInvoicesReceived == true; else loader" style="height: 100%;">
                <div *ngIf="dataInvoicesReceived !== undefined" style="height: 100%;">
                    <h1 (click)="toInvoiceStatus()" *ngIf="user.has('ROUTE_SUPPLY_CHAIN_INVOICE_STATUS') && dataInvoicesReceived.numberOfProcess > 0" id="invoiceData2"><span class="invoiceData">{{ dataInvoicesReceived.numberOfProcess }}
                    <rem style='font-size: 18px;' class="flaticon-folder-2"></rem></span></h1>
                    <h1 *ngIf="!user.has('ROUTE_SUPPLY_CHAIN_INVOICE_STATUS') || dataInvoicesReceived.numberOfProcess <= 0" id="invoiceData2"><span>{{ dataInvoicesReceived.numberOfProcess }}</span></h1>
                    <h4 class="invoice-title">Faturas (Notas Fiscais)</h4>
                    <div class="invoice-progress progress">
                        <div id="dataInvoicesReceived" style="background-color: #29B473;" class="progress-bar"
                        role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"
                        [style.width]="(dataInvoicesReceived.percentage * 100) + '%'"></div>
                    </div>
                    <span class="invoice-detail">Rel. Mês Anterior</span>
                    <span *ngIf="UIcurrentMonth != '01/2017' ; else trace" class="invoice-percentage">{{ percentageInvoicesReceived }}%</span>
                </div>
                <div *ngIf="dataInvoicesReceived === undefined" class="reloadContent">
                    <rem (click)="loadImpOrChassi(user)" class="material-icons">refresh</rem>
                </div>
            </div>
        </div>

        <!-- Faturamento Mensal -->
        <div class="flex-invoice" *ngIf="featureFlagService.isFeatureFlagEnabled('invoice-service:client-invoice-use-elastic')">
            <div *ngIf="asyncInvoiceAmount == true; else loader" style="height: 100%;">
                <div *ngIf="dataInvoicesReceived !== undefined" style="height: 100%;">
                    <h1 (click)="toInvoiceStatus()" *ngIf="user.has('ROUTE_SUPPLY_CHAIN_INVOICE_STATUS') && dataInvoicesReceived.amountValue > 0" id="invoiceData3"><span class="invoiceData">R$ {{ stringInvoiceAmount }}mi
                    <rem style='font-size: 18px;' class="flaticon-folder-2"></rem></span></h1>
                    <h1 *ngIf="!user.has('ROUTE_SUPPLY_CHAIN_INVOICE_STATUS') || dataInvoicesReceived.amountValue <= 0" id="invoiceData3"><span>R$ {{ stringInvoiceAmount }}mi</span></h1>
                    <h4 class="invoice-title">Faturamento Mensal</h4>
                    <div class="invoice-progress progress">
                        <div id="dataInvoicesBilledItens" style="background-color: #FFC50A;" class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" [style.width]="(percentageInvoiceAmount) + '%'"></div>
                    </div>
                    <span class="invoice-detail">Rel. Mês Anterior</span>
                    <span *ngIf="UIcurrentMonth != '01/2017' ; else trace" class="invoice-percentage">{{ percentageInvoiceAmount }}%</span>
                </div>
                <div *ngIf="dataInvoicesReceived === undefined" class="reloadContent">
                    <rem (click)="loadImpOrChassi(user)" class="material-icons">refresh</rem>
                </div>
            </div>
        </div>
        <!-- Faturamento Mensal -->
        <div class="flex-invoice" *ngIf="!featureFlagService.isFeatureFlagEnabled('invoice-service:client-invoice-use-elastic')">
            <div *ngIf="asyncInvoiceAmount == true; else loader" style="height: 100%;">
                <div *ngIf="dataInvoiceAmount !== undefined" style="height: 100%;">
                    <h1 (click)="toInvoiceStatus()" *ngIf="user.has('ROUTE_SUPPLY_CHAIN_INVOICE_STATUS') && dataInvoiceAmount > 0" id="invoiceData3"><span class="invoiceData">R$ {{ stringInvoiceAmount }}mi
                    <rem style='font-size: 18px;' class="flaticon-folder-2"></rem></span></h1>
                    <h1 *ngIf="!user.has('ROUTE_SUPPLY_CHAIN_INVOICE_STATUS') || dataInvoiceAmount <= 0" id="invoiceData3"><span>R$ {{ stringInvoiceAmount }}mi</span></h1>
                    <h4 class="invoice-title">Faturamento Mensal</h4>
                    <div class="invoice-progress progress">
                        <div id="dataInvoicesBilledItens" style="background-color: #FFC50A;" class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" [style.width]="(percentageInvoiceAmount) + '%'"></div>
                    </div>
                    <span class="invoice-detail">Rel. Mês Anterior</span>
                    <span *ngIf="UIcurrentMonth != '01/2017' ; else trace" class="invoice-percentage">{{ percentageInvoiceAmount }}%</span>
                </div>
                <div *ngIf="dataInvoiceAmount === undefined" class="reloadContent">
                    <rem (click)="loadImpOrChassi(user)" class="material-icons">refresh</rem>
                </div>
            </div>
        </div>


        <!-- PTAX BRL/USD -->
        <div class="last flex-invoice">
            <div *ngIf="asyncExchangeValue == true; else loader" style="height: 100%;">
                <div *ngIf="stringCurrentExchange !== undefined" style="height: 100%;">
                    <h1 id="invoiceData4">
                        <li style="color: #29B473;" *ngIf="dataCurrentExchange >= dataLastExchange" class="la la-arrow-up"></li>
                        <li style="color: red;" *ngIf="dataCurrentExchange < dataLastExchange" class="la la-arrow-down"></li>
                        <span>{{ stringCurrentExchange }}</span>
                    </h1>
                    <h4 class="invoice-title">PTAX BRL/USD • {{dateCurrentExchange}}</h4>
                    <div class="lineContainer">
                        <div class="invoice-progress progress" id="progress-left">
                            <div id="percentageNegativeExchange" style="background-color: red;" class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" [style.width]="(100 - percentageNegativeExchange) + '%'"></div>
                        </div>
                        <div class="invoice-progress progress" id="progress-right">
                            <div id="percentagePositiveExchange" style="background-color: #29B473;" class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" [style.width]="(percentagePositiveExchange) + '%'"></div>
                        </div>
                    </div>
                    <span class="invoice-detail">Rel. PTAX BRL/USD • {{dateLastExchange}}</span>
                    <span *ngIf="UIcurrentMonth != '01/2017'; else trace" class="invoice-percentage">{{ percentageExchange }}%</span>
                </div>
                <div *ngIf="stringCurrentExchange === undefined" class="reloadContent">
                    <rem (click)="loadExchange()" class="material-icons">refresh</rem>
                </div>
            </div>
        </div>
    </div>

</div>


