<form class="m-form m-form--label-align-right">

    <div class="structure-container">

        <div class="left-container">

            <!-- Formatação Valores -->
            <div style="margin-bottom: 30px">
                <label class="">Formatação Valores</label>
                <div class="">
                    <div class="">
                        <div class="m-radio-list">
                            <label class="m-radio" *ngFor="let option of options.amountsFormat; trackBy: trackByFn">
                                <input name="value_format" type="radio" [value]="option.value" [checked]="editableStructure.amountsFormat == option.value"
                                    (change)="changeAmountsFormat(option.value, option.example)">{{option.desc}}
                                <span></span>
                            </label>
                        </div>
                        <span class="m-form__help">{{help.amountsFormat}}</span>
                    </div>
                </div>
            </div>

            <!-- Agrupamento de dados do Período -->
            <div style="margin-bottom: 30px" *ngIf="showPeriodGrouping()">
                <label class="">Agrupamento de dados do Período</label>
                <div class="">
                    <div class="">
                        <div class="m-radio-list">
                            <label class="m-radio" *ngFor="let option of options.periodGrouping; trackBy: trackByFn">
                                <input type="radio" [value]="option.value" [checked]="editableStructure.periodGrouping == option.value"
                                    (change)="changePeriodGrouping(option.value, option.example)">{{option.desc}}
                                <span></span>
                            </label>
                        </div>
                        <span class="m-form__help">{{help.periodGrouping}}</span>
                    </div>
                </div>
            </div>

        </div>

        <div class="right-container">

            <div class="structure-flex-container">

                <!-- Consolidar Clientes por Grupo -->
                <div class="structure-flex-item" *ngIf="filterOptionsGroupingClient">
                    <label class="">Consolidar Clientes por Grupo</label>
                    <div class="">
                        <div class="">
                            <span class="m-switch m-switch--icon m-switch--primary">
                                <label>
                                    <input type="checkbox" name="" [checked]="editableStructure.groupingClients" (click)="toggleShowGroupingClients()">
                                    <span></span>
                                </label>
                            </span>
                            <br/>
                            <span class="m-form__help">Clientes do mesmo grupo serão mostrados na mesma coluna</span>
                        </div>
                    </div>
                </div>

                <!-- Exibição do Número da Conta Contábil -->
                <div class="structure-flex-item">
                    <label class="">Exibir Códigos da Conta</label>
                    <div class="">
                        <div class="">
                            <span class="m-switch m-switch--icon m-switch--primary">
                                <label>
                                    <input type="checkbox" name="" [checked]="editableStructure.displayAccountCodes" (click)="toggleAccountCodes()">
                                    <span></span>
                                </label>
                            </span>
                            <br/>
                            <span class="m-form__help">{{options.accountCodes[editableStructure.displayAccountCodes + ''].example}}</span>
                        </div>
                    </div>
                </div>

                <!-- Ocultar Desconhecido  -->
                <div class="structure-flex-item">
                    <label class="" for="companies">Mostrar Desconhecidos</label>
                    <div class="">
                        <div class="">
                            <span class="m-switch m-switch--icon m-switch--primary">
                                <label>
                                    <input type="checkbox" name="" [checked]="editableStructure.unknownLines == 'showUnknownLines'" (click)="toggleUnknownLines()">
                                    <span></span>
                                </label>
                            </span>
                            <br/>
                            <span class="m-form__help">{{options.unknownLines[editableStructure.unknownLines].example}}</span>
                        </div>
                    </div>
                </div>

                <!-- Acúmulo de Saldo -->
                <div class="structure-flex-item" *ngIf="showAccumulateBalance()">
                    <label class="">Acumular Saldo</label>
                    <div class="">
                        <div class="">
                            <span class="m-switch m-switch--icon m-switch--primary">
                                <label>
                                    <input type="checkbox" name="" [checked]="editableStructure.accumulateBalance" (click)="toggleAccumulateBalance()">
                                    <span></span>
                                </label>
                            </span>
                            <br/>
                            <span class="m-form__help">{{options.accumulateBalance[editableStructure.accumulateBalance].example}}</span>
                        </div>
                    </div>
                </div>

                <!-- Coluna de Totalizadores -->
                <div class="structure-flex-item">
                    <label class="">Coluna de Totalizadores</label>
                    <div class="">
                        <div class="m-form__group form-group">
                            <div class="m-form__group form-group">
                                <span class="m-switch m-switch--icon m-switch--primary">
                                    <label>
                                        <input type="checkbox" name="" [checked]="editableStructure.columnTotal == 'showColumnTotal'" (click)="toggleColumnTotal()">
                                        <span></span>
                                    </label>
                                </span>
                                <br/>
                                <span class="m-form__help">{{options.columnTotal[editableStructure.columnTotal].example}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Valores Zerados -->
                <div class="structure-flex-item">
                    <label class="">Mostrar Contas Zeradas</label>
                    <div class="">
                        <div class="">
                            <span class="m-switch m-switch--icon m-switch--primary">
                                <label>
                                    <input type="checkbox" name="" [checked]="editableStructure.displayZeroedNodes" (click)="toggleZeroedNodes()">
                                    <span></span>
                                </label>
                            </span>
                            <br/>
                            <span class="m-form__help">{{help.zeroedNodes}}</span>
                        </div>
                    </div>
                </div>

            </div>

            </div>

        </div>

    <div class="structure-buttons">
        <button type="button" class="btn btn-secondary" onclick="$('.structureModal').removeClass('modal-open');">Cancelar</button>
        <button type="button" class="btn btn-outline-primary m-btn" (click)="applyChanges()">Aplicar</button>
    </div>

    <div class="m-portlet__foot m-portlet__foot--fit">
    </div>
</form>
