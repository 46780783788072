<div class="moveBtn">
    <div class="moveLine"></div>
</div>

<div class="header-container">
    <div class="title-container">
        <span class="analyticsName">Movimentação</span>
        <cmx-help-modal help-title="Movimentação" [help-description]="helpDescription"></cmx-help-modal>
        <h5>Movimentação</h5>
    </div>
    <div class="filter-container">
        <cmx-switch
            [options]="modalTypes"
            [selected]="filterState.getType()"
            (onSelect)="filterState.setType($event); applyFilters()"
        ></cmx-switch>
    </div>
    <div *ngIf="filterState.getType() === 'Marítima'" style="border-left: 1px solid grey"></div>
    <div class="filter-container" *ngIf="filterState.getType() === 'Marítima'">
        <cmx-switch
            [options]="[{ name: 'TEUs' }, { name: 'Containers' }]"
            [selected]="filterState.getTypeView()"
            (onSelect)="filterState.setTypeView($event); applyFilters()"
        ></cmx-switch>
    </div>
</div>

<div id="searchLine" class="reduzed" *ngIf="currentUser$ | async as user">
    <div class="search-container">
        <ng-container *ngIf="filterState.getType() === 'Aérea' || filterState.getType() === 'Marítima'">
            <div class="input-group">
                <cmx-dropdown
                    name="states"
                    [styles]="{ width: '160px' }"
                    [data]="dropdown.states.options"
                    [style.margin-left]="'-20px'"
                    [ngModel]="filterState.getStates()"
                    (ngModelChange)="filterState.setStates($event)"
                    [settings]="dropdownSettings.states"
                ></cmx-dropdown>
                <span class="input-group-addon" style="margin: 0 15px 0 -15px"></span>
            </div>
            <div class="input-group">
                <cmx-dropdown
                    name="warehouses"
                    [styles]="{ width: '160px' }"
                    [data]="dropdown.warehouses.options"
                    [style.margin-left]="'-20px'"
                    [ngModel]="filterState.getWarehouses()"
                    (ngModelChange)="filterState.setWarehouses($event)"
                    [settings]="dropdownSettings.warehouses"
                ></cmx-dropdown>
            </div>
        </ng-container>
        <div>
            <div *ngIf="user.has('INTERNAL_FILTERS')" class="input-group">
                <span class="input-group-addon" style="margin: 0 -15px 0 -5px"></span>
                <cmx-dropdown
                    name="clients"
                    [styles]="{ width: '160px'}"
                    [data]="dropdown.clients.options"
                    [ngModel]="filterState.getClientGroups()"
                    (ngModelChange)="filterState.setClientGroups($event)"
                    [settings]="dropdownSettings.clientGroups">
                </cmx-dropdown>
            </div>
        </div>
        <div>
            <div *ngIf="user.has('INTERNAL_FILTERS')" class="input-group">
                <span class="input-group-addon" style="margin: 0 -15px 0 0">Por</span>
                <cmx-dropdown
                    name="reference-date-type"
                    [styles]="{width: '100px'}"
                    [data]="dropdown.referenceDateType.options"
                    [ngModel]="filterState.getReferenceDateType()"
                    (ngModelChange)="filterState.setReferenceDateType($event)"
                    [settings]="dropdownSettings.referenceDateTypes"
                ></cmx-dropdown>
            </div>
        </div>
        <div class="input-group right">
            <span class="input-group-addon" style="margin: 0 -5px 0 0px"></span>
            <cmx-filter-datepicker
                id="year"
                [date]="'Chegada'"
                [type]="'single-year'"
                [periodType]="'year'"
                [alone]="true"
                [startDate]="filterState.getYear()"
                (onChangeDates)="filterState.setYear($event)"
            ></cmx-filter-datepicker>
        </div>
        <button class="applyImpStatus"
            (click)="applyFilters()">
            <i class="fa fa-check"></i>
        </button>
    </div>
</div>

<div class="charts-container" >
    <div class="main-chart-container"
    [style.width]="filterState.getType() === 'Marítima' || filterState.getType() === 'Aérea' ? '70%' : '100%'">
        <loader *ngIf="async"></loader>
        <div class="movementChart" id="movementChart" [style.opacity]="async ? '0' : '1'"></div>
        <div *ngIf="!async" class="chart-info">
            <span class="material-icons">info</span>
            <span>Dados com base na data de chegada</span>
        </div>
    </div>

    <div *ngIf="filterState.getType() === 'Marítima' || filterState.getType() === 'Aérea'" class="divisor"></div>

    <div class="secondary-chart-container"
    [style.opacity]="filterState.getType() === 'Marítima' || filterState.getType() === 'Aérea' ? '1' : '0'"
    [style.width]="filterState.getType() === 'Marítima' || filterState.getType() === 'Aérea' ? '30%' : '0%'">

        <div class="type-header-container"
            *ngIf="filterState.getType() === 'Aérea' || filterState.getType() === 'Marítima'">
            <span>Movimentação por:</span>
            <cmx-switch
                [options]="[{ name: 'Estado' }, { name: 'Recinto' }]"
                [selected]="filterState.getSecondaryType()"
                (onSelect)="filterState.setSecondaryType($event); applyFilters()"
            ></cmx-switch>
        </div>

        <loader *ngIf="secondaryAsync"></loader>
        <div class="secondaryChart" id="secondaryChart" [style.opacity]="secondaryAsync ? '0' : '1'"></div>
        <span *ngIf="!pages.length && !secondaryAsync" class="noData">Nenhuma informação de movimentação por {{ filterState.getSecondaryType() }} encontrada</span>

        <div *ngIf="pages.length > 1" class="arrows-container"
        (click)="$event.stopPropagation()">
            <div class="arrow arrow-left pointer"
                [ngClass]="{ unclickable: page === 0 }"
                (click)="page > 0 ? changeTypeChartPage(-1) : return">
            </div>
            <div class="arrow arrow-right pointer"
                [ngClass]="{ unclickable: page === pages.length - 1 }"
                (click)="page < pages.length - 1 ? changeTypeChartPage(1) : return">
            </div>
        </div>

    </div>
</div>
