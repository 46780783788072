import * as moment from 'moment';

import { UtilsService } from '#services/_utils/utils.service';

import { Utils } from '../utils/utils';

import { Chart } from '../../../../../shared/utils/chart';

import { ILogisticsHandling } from 'app/shared/interfaces/logistics-handling.interface';

import { LogisticsHandlingModalTypes } from 'app/shared/types/logistics-handling-types.type';

import { LOGISTICS_HANDLING_VALUE_AXIS_TITLES } from '../constants/logistics-handling-value-axis-titles.constant';

interface ICustomizedLogisticsHandling extends ILogisticsHandling {
    label: string;
    month: string;
}

export class LogisticsHandlingChart {
    private _logisticsHandlingType: LogisticsHandlingModalTypes;
    private _logisticsHandlingData: ILogisticsHandling[];
    private _previousTitle: string;
    private _currentTitle: string;

    constructor(
        logisticsHandlingType: LogisticsHandlingModalTypes,
        logisticsHandlingData: ILogisticsHandling[],
        previousTitle: string,
        currentTitle: string,
        private _utilsService: UtilsService
    ) {
        this._logisticsHandlingType = logisticsHandlingType;
        this._logisticsHandlingData = logisticsHandlingData;
        this._previousTitle = previousTitle;
        this._currentTitle = currentTitle;
    }

    loadChart(chartId: string, typeView?: string): AmCharts.AmChart {
        return Chart.makeChart(chartId, this._getConfigs(typeView));
    }

    private _getCustomizedLogisticsHandlingData(): ICustomizedLogisticsHandling[] {
        return this._logisticsHandlingData
            .map((data: ILogisticsHandling) => ({
                ...data,
                month: moment(data.yearMonth, 'YYYYMM').format('MM'),
            }))
            .sort((a, b) => a.month.localeCompare(b.month))
            .map((data) => {
                const month = this._utilsService.capitalize(moment(data.month, 'MM').format('MMMM'));

                const label = `${month}<br>${Utils.getFormattedPercentage(
                    'table',
                    data.percentage,
                    this._utilsService
                )}`;

                return {
                    ...data,
                    month,
                    label,
                };
            });
    }

    private _getConfigs(typeView?: string): any {
        return {
            dataProvider: this._getCustomizedLogisticsHandlingData(),
            type: 'serial',
            theme: 'light',
            categoryField: 'label',
            language: 'pt',
            categoryAxis: {
                gridPosition: 'start',
                position: 'left',
                autoWrap: false,
                fontSize: 9,
                boldLabels: true,
            },
            graphs: [
                {
                    balloonText: '',
                    fillColors: '#d9d9d9',
                    lineColor: '#d9d9d9',
                    fillAlphas: 0.8,
                    lineAlpha: 0.2,
                    title: this._previousTitle,
                    type: 'column',
                    valueField: 'previousValue',
                    labelText: '[[previousValue]]',
                    showAllValueLabels: true,
                    fontSize: 10,
                    labelFunction: (value) =>
                        Utils.getGraphLabelByLogisticsHandlingModalType(
                            value.values.value,
                            this._logisticsHandlingType,
                            this._utilsService
                        ),
                },
                {
                    balloonText: '',
                    fillColors: '#364476',
                    lineColor: '#364476',
                    fillAlphas: 0.8,
                    lineAlpha: 0.2,
                    title: this._currentTitle,
                    type: 'column',
                    valueField: 'value',
                    labelText: '[[value]]',
                    showAllValueLabels: true,
                    fontSize: 10,
                    labelFunction: (value) =>
                        Utils.getGraphLabelByLogisticsHandlingModalType(
                            value.values.value,
                            this._logisticsHandlingType,
                            this._utilsService
                        ),
                },
            ],
            legend: {
                enabled: true,
                useGraphSettings: true,
            },
            valueAxes: [
                {
                    axisAlpha: 0,
                    position: 'left',
                    labelFunction: (value) =>
                        Utils.getGraphLabelByLogisticsHandlingModalType(
                            value,
                            this._logisticsHandlingType,
                            this._utilsService
                        ),
                    title: LOGISTICS_HANDLING_VALUE_AXIS_TITLES[`${this._logisticsHandlingType}${this._logisticsHandlingType === 'MARITIME' ? `_${typeView.toUpperCase()}` : ''}`],
                },
            ],
            export: {
                enabled: true,
                menu: [
                    {
                        format: 'PDF',
                        label: "<i class='flaticon-download'></i>",
                        title: 'Gerar PDF',
                    },
                ],
            },
        };
    }
}
