<div *ngIf="asyncControl" class="fullLoader">
    <loader></loader>
</div>
<div id="webhookContainer">
    <a *ngIf="!newMode" (click)="openLogsModal()" class="logs">
        <span class="material-icons">list_alt</span>
        Registro de Atividades
    </a>

    <!-- Menu subscriptions list -->
    <div class="listColumn">
        <div class="search">
            <cmx-settings-clients
                (onSelectClient)="onSelectClient($event)"
                (onDeSelectClient)="onDeSelectClient($event)"
                [singleSelection]="true"
                [filterState]="filterState"
            ></cmx-settings-clients>
        </div>

        <div class="p-field search-container">
            <input pInputText type="text" id="cmx-subscriptionsSearch" name="subscriptionsSearch" placeholder="Buscar" [(ngModel)]="filterState.textFilter" (keydown.enter)="filterSubscriptions()" [title]="disabledFormControl ? 'Selecionar cliente para consultar Inscrições' : ''">
            <a (click)="startNewSubscription()" class="addBtn"><span>+</span></a>
        </div>
        <hr />

        <div class="table-container">
            <div *ngIf="!asyncControl" class="table">
                <table>
                    <tr *ngFor="let item of subscriptions; let i = index; trackBy: trackByFn">
                        <td [ngClass]="{ selected: item.id === subscription.id }" (click)="selectSubscriptions(item)">
                            <span>{{ item.customName }}</span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>

    <!-- Content subscriptions form -->
    <div class="middleColumn">
        <div>
            <span class="title">
                Selecione o tipo de informação que deseja receber:
            </span>
        </div>
        <div class="eventCardContainer">
            <div
                *ngFor="let event of allEvents; let i = index; trackBy: trackByFn"
                class="eventCard"
                (click)="onSelectEvent(event, $event)"
                [ngClass]="subscription.selectedEvent[0]?.itemName === event.name ? 'selected' : ''"
            >
                <div class="eventCardContent" style="pointer-events: none">
                    <img [src]="getEventImageSrc(event.name)" alt="" />
                    <span class="name">{{ event.name }}</span>
                </div>
            </div>
        </div>

        <div class="confirmModal hidden">
            <p>{{ modal.message }}</p>
            <div class="buttons">
                <button (click)="modal.onConfirm()" [class]="modal.type">Deletar</button>
                <button (click)="modal.onClose()" class="cancel">Cancelar</button>
            </div>
        </div>
    </div>

    <div class="lastColumn">
        <div class="subscriptionForm">
            <div class="mb-3">
                <span class="title">Cadastro de Inscrição:</span>
            </div>
            <div class="p-field">
                <input
                    pInputText
                    id="cmx-custom-name-input"
                    type="text"
                    [(ngModel)]="subscription.customName"
                    placeholder="Nome"
                    [disabled]="events.length === 0"
                />
                <a *ngIf="!newMode" (click)="openDeleteModal()" class="deleteBtn">
                    <span class="material-icons">delete_forever</span>
                </a>
            </div>
            <div class="p-field my-3">
                <input
                    pInputText
                    id="cmx-endpoint-input"
                    type="text"
                    [(ngModel)]="subscription.endpoint"
                    placeholder="URI, exemplo: https://api.com/service"
                    [disabled]="events.length === 0"
                />
            </div>
            <div class="p-field">
                <input
                    pInputText
                    id="cmx-shared-secret-input"
                    type="text"
                    [(ngModel)]="subscription.sharedSecret"
                    placeholder="Segredo Compartilhado (Chave de Segurança)"
                    [disabled]="events.length === 0"
                />
            </div>
            <hr />
            <div class="json">
                <span class="info">Preview de Payload</span>
                <pre>{{ payload | json }}</pre>
            </div>
            <hr class="mb-0" />
            <div class="inputComponent">
                <cmx-button *ngIf="newMode && events.length > 0" variant="save" (click)="save()">Cadastrar</cmx-button>
                <cmx-button *ngIf="!newMode" (click)="save()" variant="primary">Salvar</cmx-button>
            </div>
        </div>
    </div>

    <!--Messages -->
    <div class="resultMessage hidden" id="resultMessage">
        <div *ngFor="let item of results; trackBy: trackByFn">
            <p class="{{ item.type }}"
                ><i (click)="hideAllErrorOrSucess()" class="la la-close"></i> {{ item.message }}</p
            >
        </div>
    </div>

    <div class="logsModal hidden">
        <div *ngIf="asyncLogs" class="fullLoader">
            <loader></loader>
        </div>
        <i (click)="closeLogsModal()" class="close la la-close"></i>
        <div class="titleContainer">
            <div class="title">
                <span class="material-icons">list_alt</span>
                <span>Registro de Atividades • {{ subscription?.customName }}</span>
            </div>
            <div class="filter">
                <div id="searchLine" class="reduzed statusComponent" *ngIf="currentUser$ | async as user">
                    <div class="search-container">
                        <div class="searchInput" style="z-index: 2">
                            <input
                                id="impSearch"
                                class="search"
                                style="width: 120px"
                                type="text"
                                name="imp"
                                placeholder="Buscar IMP"
                                autocomplete="off"
                                [(ngModel)]="impSearch"
                            />
                        </div>
                        <br />
                        <div class="search-container" style="margin-left: 20px">
                            <div class="input-group" style="width: max-content; width: fit-content">
                                <cmx-dropdown
                                    size="md"
                                    id="status"
                                    name="status"
                                    [data]="options.status"
                                    [(ngModel)]="selectedStatus"
                                    [settings]="dropdownSettingStatus"
                                ></cmx-dropdown>
                            </div>
                            <div class="input-group">
                                <span class="input-group-addon">De</span>
                                <input
                                    (keyup)="verifyDates('startDate', startDate)"
                                    [(ngModel)]="startDate"
                                    name="startDatePicker"
                                    id="startDatePicker"
                                    type="text"
                                    class="form-control m-input date-impStatusTable"
                                    style="width: 125px"
                                />
                            </div>
                            <div class="input-group right">
                                <span class="input-group-addon">Até</span>
                                <input
                                    (keyup)="verifyDates('endDate', endDate)"
                                    [(ngModel)]="endDate"
                                    name="endDatePicker"
                                    id="endDatePicker"
                                    type="text"
                                    class="form-control m-input date-impStatusTable"
                                    style="width: 125px"
                                />
                            </div>
                            <button (click)="loadLogs()" class="applyImpStatus">
                                <i class="fa fa-check"></i>
                            </button>
                        </div>
                        <button (click)="loadLogs()" class="applyImpStatus">
                            <i class="fa fa-check"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div class="logsContainer">
            <div class="gridContainer">
                <div id="logGrid"></div>
                <div *ngIf="pages.length > 1">
                    <pagination
                        [numberOfPages]="pages.length"
                        [queryId]="queryId"
                        (onPageChange)="changePage($event)"
                    ></pagination>
                </div>
            </div>
            <div class="payloadContainer">
                <span>Payload do Registro</span>
                <hr />
                <span *ngIf="!selectedLogPayload" class="noData">
                    Selecione um log através do icone
                    <span class="material-icons" style="font-size: 20px">visibility</span>
                    para ver ser registro.</span
                >
                <div class="json">
                    <pre style="padding-bottom: 10px">{{ selectedLogPayload | json }}</pre>
                </div>
            </div>
        </div>
    </div>
</div>
