import { environment } from '#environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class APIKeygeneraationService {
    constructor(private http: HttpClient) {}

    generateKey(clientCode, email): Observable<any> {
        return this.http.post(`${environment.endpoints.adminService}/iam-api-key/${clientCode}/${email}`, {});
    }
}
