import { AppModule } from 'app/app.module';
import { HttpHeaders, HttpParams } from '@angular/common/http';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IPaymentRequestAutoFilter } from '../interfaces/payment-request.interface';

import moment from 'moment';
import * as _ from 'lodash';

export class PaymentRequestAutoFilter {
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);
    private _initialSuppliersListingFilter: IPaymentRequestAutoFilter = <IPaymentRequestAutoFilter>{};
    private _suppliersListingFilter: IPaymentRequestAutoFilter = <IPaymentRequestAutoFilter>{
        suppliers: [],
        subsidiaries: [],
        status: [],
    };

    private _startDate;
    private _endDate;
    private _filterField;
    private _filterValue;
    private _page: number;
    private _size: number;

    constructor(filter?: PaymentRequestAutoFilter) {
        this._suppliersListingFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'paymentRequestAutoFilter', this._suppliersListingFilter, filter);
        this._initialSuppliersListingFilter = _.cloneDeep(this._suppliersListingFilter);
        this._startDate = filter ? filter._startDate : moment().add(-3, 'M').format('DD/MM/YYYY');
        this._endDate = filter ? filter._endDate : moment().format('DD/MM/YYYY');
        this._filterField = filter ? filter._filterField : '';
        this._filterValue = filter ? filter._filterValue : '';
        this._page = filter ? filter._page : 0;
        this._size = filter ? filter._size : 50;
    }

    getSuppliersAccounts() { return this._suppliersListingFilter.suppliers }
    getSubsidiaries() { return this._suppliersListingFilter.subsidiaries }
    getStatus() { return this._suppliersListingFilter.status }
    getStartDate() { return this._startDate; }
    getEndDate() { return this._endDate; }
    getFilterField() { return this._filterField; }
    getFilterValue() { return this._filterValue; }
    getPage(): number { return this._page; }
    getSize(): number { return this._size; }

    setSuppliersAccounts(suppliers) { this._suppliersListingFilter.suppliers = suppliers }
    setSubsidiaries(subsidiaries): void { this._suppliersListingFilter.subsidiaries = subsidiaries }
    setStatus(status): void { this._suppliersListingFilter.status = status }
    setStartDate(startDate): void { this._startDate = startDate; }
    setEndDate(endDate): void { this._endDate = endDate; }
    setFilterField(filterField): void { this._filterField = filterField; }
    setFilterValue(filterValue): void { this._filterValue = filterValue; }
    setPage(page): void { this._page = page; }
    setSize(size): void { this._size = size; }

    updatePreferences() {
        this._initialSuppliersListingFilter = this._userPreferencesService.verifyFilterChanges(this._initialSuppliersListingFilter, this._suppliersListingFilter, 'suppliersListingFilter', this._userCacheService.getUserPreferences().id);
    }

    getRequest() {
        this.updatePreferences();

        let params = new HttpParams();
        let headers = new HttpHeaders();

        if (this.getFilterValue() && this.getFilterField()) {
            params = params.append(this.getFilterField(), this.getFilterValue());
        } else {
            this.setFilterField(null);
            this.setFilterValue(null);
        }

        params = params.append('status', (this.getStatus() || []).map((status) => status.id).join(',').toUpperCase());
        params = params.append('suppliers', (this.getSuppliersAccounts() || []).map((suppliers) => suppliers.id).join(','));
        params = params.append('subsidiaries', (this.getSubsidiaries() || []).map((subsidiaries) => subsidiaries.id).join(','));
        params = params.append('startDate', `${moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`);
        params = params.append('endDate', `${moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`);
        params = params.append('page', `${ this.getPage() }`);
        params = params.append('size', `${ this.getSize() }`);

        this.removeNullValuesFromQueryParams(params);

        return { params, headers };
    }

    removeNullValuesFromQueryParams(params: HttpParams) {
        const paramsKeysAux = params.keys();
        paramsKeysAux.forEach((key) => {
          const value = params.get(key);
          if (value === null || value === undefined || value === '') {
            params['map'].delete(key);
          }
        });

        return params;
    }
}
