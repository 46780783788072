<!-- Calendário Mensal (dias) -->
<div *ngIf="amplitude === 'month'" class="calendar-container calendar-month-container">
    <div class="calendar-top-container">
        <div (click)="changeMonth(-1)" class="arrow left-arrow"><span class="material-icons">chevron_left</span></div>
        <span class="current-month">{{ utilsService.capitalize(moment().month(this.month).format("MMMM")) + ' de ' + year }}</span>
        <div (click)="changeMonth(1)" class="arrow right-arrow"><span class="material-icons">chevron_right</span></div>
    </div>
    <div class="calendar-bottom-container">
        <div class="calendar-header-container">
            <div class="calendar-header-day" *ngFor="let headerDay of calendarDaysList">
                <div *ngIf="useLeftDayContainer" class="left-container"></div>
                <span>{{ useDayAbbreviation ? headerDay?.abbreviation : headerDay?.name }}</span>
            </div>
        </div>
        <div class="calendar-body-container">
            <div [id]="'calendarDay-' + bodyDay.number?.toString()?.padStart(2, '0') + '-' + bodyDay.period"
                (click)="selectDay(bodyDay)" class="calendar-body-day" *ngFor="let bodyDay of structureDays"
                [ngClass]="{ disabled: bodyDay?.disabled, selected: bodyDay?.selected, enabled: !bodyDay?.disabled }"
                [style.min-height]="useEvents ? '65px' : 'auto'">
                <div *ngIf="useLeftDayContainer" class="left-container"
                    [ngClass]="{ disabled: bodyDay?.disabled, selected: bodyDay?.selected }"
                    [style.background-color]="bodyDay?.color || '#3b6394'"
                ></div>
                <span class="number">
                    <span *ngIf="showHolidays && bodyDay.holiday" class="material-icons holiday"
                        [title]="bodyDay.holiday.name">star</span>
                    {{ bodyDay?.number }}
                </span>
                <div *ngIf="bodyDay?.events?.length" class="events-container"
                [ngClass]="{ useLeftDayContainer: useLeftDayContainer }">
                    <div *ngFor="let event of bodyDay.events" class="event-container">
                        <span>{{ event?.title }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>