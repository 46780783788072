import { ReportPreferencesService } from '#services/_report-preferences/report-preferences.service';
import { Component, ViewEncapsulation } from '@angular/core';
import { ISave } from 'app/shared/components/cmx-save-config/interfaces/save.interface';
import { CostsService } from '../../consolidated/costs.service';
import { NotificationService } from '#services/_notification/notification.service';
import * as _ from 'lodash';
import { DatamartService } from '#services/_datamart/datamart.service';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'costs-customization',
    templateUrl: './costs-customization.component.html',
    styleUrls: ['./costs-customization.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [],
})
export class CostsCustomizationComponent {

    selectedSave: ISave;
    saveChanges: ISave;
    forceClear: boolean = false;
    asyncCustomization: boolean = false;

    constructor(
        public reportPreferencesService: ReportPreferencesService,
        private _datamartService: DatamartService,
        public costsService: CostsService,
        private notificationService: NotificationService
    ) { }

    onSaveChanges(changes) {
        this.selectedSave = _.cloneDeep(changes);
        this._verifySaveChanges(changes.save, changes)
    }

    onNewSave(newSave) {
        this.selectedSave = newSave;
        this._changeGroupAndColumns(this.reportPreferencesService.currentDefaultReport);
    }

    onSelectSave(save) {
        this.selectedSave = save;
        this._changeGroupAndColumns(save);
    }

    onDeleteSave(save) {
        // TODO remover delete de datamart após evolução no reportPreferences
        forkJoin(
            this.reportPreferencesService.deleteCustomReport(save.id),
            this._datamartService.setDatamartConfiguration('Custos','expenses-service', 'expenses-detail', [], 'default', {
                preferencesId: [save.id],
                clientGroupCodes: []
            })
        ).subscribe((_response) => {
            this.reportPreferencesService.removeSaveFromList(save);
            this.forceClear = true;
            setTimeout(() => { this.forceClear = false }, 1000);
        }, (_error) => {
            this.notificationService.openNotification('error', 'Não foi possível excluir seu save')
        })
    }

    mergeUtilColumns() {
        const defaultColumns = _.cloneDeep(this.reportPreferencesService.currentDefaultReport);
        const changes = this.costsService.determineUtilColumns(defaultColumns, this.reportPreferencesService.currentUtilColumns);
        this.reportPreferencesService.defineLeftAndRightData(
            defaultColumns?.groups || [],
            changes?.save?.groups || changes?.groups || [],
            true, true);
    }

    saveCustomChanges(data) {
        if (!this.selectedSave?.title) {
            this.notificationService.openNotification('error', 'Por favor, digite um título')
            return
        }
        const save = {
            ..._.cloneDeep(this.selectedSave),
            id: this.selectedSave?.save?.id,
            reportName: `${this.selectedSave?.title}`,
            groups: data.leftData.map((group) => { return { name: group.name, columns: group.data, isCustom: group.isCustom } })
        }
        if (this.selectedSave?.save?.id) {
            this.asyncCustomization = true;
            this.reportPreferencesService.setCustomReport(save).subscribe((response) => {
                this.reportPreferencesService.costsSaveSubject.next(save);
                this.asyncCustomization = false;
            })
        } else {
            this.asyncCustomization = true;
            this.reportPreferencesService.createCustomReport('EXPENSES_DETAIL', save).subscribe((response) => {
                this.forceClear = true;
                setTimeout(() => { this.forceClear = false }, 1000);
                this.reportPreferencesService.costsSaveSubject.next(save);
                this.asyncCustomization = false;
            })
        }
    }

    private _verifySaveChanges(save, currentValues) {
        if (save) {
            if (save.title !== currentValues.title ||
                save.description !== currentValues.description ||
                save.icon !== currentValues.icon ||
                save.color !== currentValues.color) {
                    const save = { ...currentValues }
                    delete save.save;
                    this.saveChanges = save;
            } else {
                this.saveChanges = null;
            }
        }
    }

    private _changeGroupAndColumns(changes) {
        this.reportPreferencesService.defineLeftAndRightData(
            this.reportPreferencesService.currentDefaultReport?.groups || [],
            changes?.save?.groups || changes?.groups || [], true, true
        );
    }

}
