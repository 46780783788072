import { Component, ViewEncapsulation } from '@angular/core';
import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';
import { ImportDetailsService } from '../import-details.service';
import { DELIVERY_CONTAINERS_COLUMNS } from './constants/delivery-containers-columns.constants';
import { DELIVERY_NFE_COLUMNS } from './constants/delivery-nfe-columns.constants';
import { Router } from '@angular/router';
import { ImportDetailItem } from '../import-details-item';

@Component({
    selector: 'app-import-details-delivery',
    templateUrl: './delivery.component.html',
    styleUrls: ['./delivery.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DeliveryComponent extends ImportDetailItem {
    asyncDetail = !this.showLoader;

    dataLoaded;

    deliveryDetails;

    gridNfeColumns: IGridColumn[] = DELIVERY_NFE_COLUMNS;
    gridContainerColumns: IGridColumn[] = DELIVERY_CONTAINERS_COLUMNS;

    nfeList;
    containersList: any = [];

    constructor(
        private _importDetailsService: ImportDetailsService,
        protected _router: Router,
    ) {
            super(_router);
    }

    ngOnInit() {
        (this.showLoader) ? 
            setTimeout(() => {this.loadDetails();}, 1000) : 
            this.loadDetails();
    }

    loadDetails() {
        // this.asyncDetail = false;
        this.dataLoaded = this._importDetailsService.getDetails();
        if (this.dataLoaded) {
            this._getLists();
            this.nfeList = this.dataLoaded.billingInvoices;

            this.deliveryDetails = [
                {
                    title: 'Data Carregamento',
                    value: this.dataLoaded.customsClearance.loadingDate,
                },
                {
                    title: 'Data Entrega',
                    value: this.dataLoaded.customsClearance.deliveryDate,
                },
            ];
        }

        this.asyncDetail = true;
    }

    private _getLists() {
        let hasContainerDate = (container) => container.loadingDate === null || container.loadingDate === '';
        if (!this.dataLoaded.containerControls.every(hasContainerDate)) {
            this.containersList = this.dataLoaded.containerControls;
        }
    }
}
