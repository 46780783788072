import { PageTabControlService } from '#services/_page-tab-control/page-tab-control.service';
import { Component,  ViewChild,  ViewContainerRef,  ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentsGedCRUDComponent } from './documents-ged/documents-ged.component';
import { NotificationsCRUDComponent } from './notifications/notifications.component';
import { PreferencesCRUDComponent } from './preferences/preferences.component';
import { DateNotificationCRUDComponent } from './notifications/date-notification/date-notification.component';
@Component({
    selector: 'configurations',
    templateUrl: './configurations.component.html',
    styleUrls: ['./configurations.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ConfigurationsComponent {

    @ViewChild('bodyContent', { read: ViewContainerRef }) _bodyContent: ViewContainerRef;
    pageConfig = {
        title: 'Configurações',
        url: 'configurations',
        urlParams: this._route.snapshot.params,
        tabs: [
            { code: 'notifications', title: 'Notificações', component: NotificationsCRUDComponent,
                switch: [
                    { name: 'Status', component: NotificationsCRUDComponent },
                    { name: 'Datas', component: DateNotificationCRUDComponent }
                ]
             },
            { code: 'documents', title: 'Documentos GED', component: DocumentsGedCRUDComponent, permission: 'GED_DOC_CONFIGURATION' },
            { code: 'preferences', title: 'Preferências do Usuário', component: PreferencesCRUDComponent, permission: 'ROUTE_USER_PREFERENCES_VIEWS' }
        ]
    }

    constructor(
        public pageTabControlService: PageTabControlService,
        private _route: ActivatedRoute,
    ) { }

    ngAfterViewInit() {
        setTimeout(() => {
            this.pageTabControlService.setPageConfigs(this.pageConfig, this._bodyContent);
        }, 0);
    }

}
