import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppComponent } from '../app.component';
import { AuthenticationService } from './_services/authentication.service';
import { LoginService } from './_services/login.service';
import { UserAuthService } from './_services/user-auth.service';
import { UserService } from './_services/user.service';
import { environment } from '#environment';
import { TemplateLoginEnum } from './template-login.enum';

const ERROR_CAPTCHA_MESSAGE = 'Favor resolver a verificação de segurança';

@Component({
    selector: 'app-recovery-password-page',
    templateUrl: './pwd-recovery.component.html',
    styleUrls: ['./pwd-recovery.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PwdRecoveryComponent implements OnInit {

    captchaEnable = environment.captchaEnable;
    formRecoveryPassword: FormGroup;
    initialized = false;
    loading = false;
    sentEmail = false;
    returnUrl: string;
    errorPasswordRecovery = '';
    msgPasswordRecovery = '';
    asyncLogin = false;
    isIE = false;
    @ViewChild('txtEmail') inputTxtEmail: ElementRef;
    @ViewChild('captcha') captcha;
    captchaToken: string;

    themeLogo: string = null;
    themeBackground: string = null;
    themeClient: string = null;

    captchaValidated: boolean = true;
    captchaKey: string;
    showCaptcha: boolean = true;
    mobile: any = true;

    constructor(
        private router: Router,
        private userService: UserService,
        private route: ActivatedRoute,
        private authService: AuthenticationService,
        private _loginService$: LoginService,
        private app: AppComponent,
        private userAuthService: UserAuthService,
        private _formBuilder: FormBuilder
    ) {}

    ngOnInit() {

        this.formRecoveryPassword = this._formBuilder.group({
            email: [null, Validators.required],
            domain: [null, Validators.required]
        });
        this.showCaptcha = true;
        this.captchaKey = this.authService.getCaptchaKey();
        this.route.queryParams.subscribe((params) => {
            this.formRecoveryPassword.controls['email'].patchValue(params.email);
            this.formRecoveryPassword.controls['domain'].patchValue(params.domain);
        });

        this.themeLogo = this._loginService$.getLogo();
        this.themeBackground = this._loginService$.getBackground();
        this.themeClient = this._loginService$.getClient();

        this.app.globalLoader = false;
        this.msgPasswordRecovery =
            'Digite seu e-mail de cadastro abaixo e clique em enviar. ' +
            'Nós lhe enviaremos um e-mail com link para recadastrar sua senha';
        this.getUser();
    }

    ngAfterViewInit(): void {

        this.inputTxtEmail?.nativeElement.focus();
        setTimeout(() => {
            if(
                this.captchaEnable &&
                this.captcha &&
                this.captcha.el.nativeElement.childNodes.length > 0 &&
                this.captcha.el.nativeElement.childNodes[0].childElementCount > 0
            ){
                this.captchaValidated = false;
            }
        }, 1000);
    }

    sendEmail() {
        this.errorPasswordRecovery = '';
        this.showCaptcha = true;

        if (!this.formRecoveryPassword.controls['email'].valid || !this.validateEmail(this.formRecoveryPassword.controls['email'].value)) {
            this.errorPasswordRecovery = 'Insira um e-mail válido';
            return;
        }

        if (!this.formRecoveryPassword.controls['domain'].valid) {
            this.errorPasswordRecovery = 'Seu domínio não foi especificado, entre em contato com o administrador';
            return;
        }

        this.asyncLogin = true;

        if (this.captchaValidated) {
            const origin = location.host;;
            this.userAuthService.sendPasswordResetEmail(this.formRecoveryPassword.controls['email'].value, false, this.captchaToken, this.mobile, origin).subscribe(
                () => {
                    this.msgPasswordRecovery =
                        'Link enviado com sucesso. ' +
                        'Por favor verifique seu email e siga as instruções para recadastrar sua senha';
                    this.sentEmail = true;
                    this.asyncLogin = false;
                    this.showCaptcha = false;
                },
                error => {
                    this.asyncLogin = false;
                    this.resetCaptcha();
                    if (error.code === 'auth/invalid-email') {
                        this.errorPasswordRecovery = 'Email inválido';
                    } else if (error.code === 'auth/user-not-found') {
                        this.errorPasswordRecovery = 'Email não cadastrado';
                    } else if(error.error === 'INVALID_CAPTCHA') {
                        this.errorPasswordRecovery = 'Favor resolver a verificação de segurança';
                    }
                    else {
                        this.errorPasswordRecovery = 'Não foi possível enviar o email para recadastro de senha';
                    }
                }
            );
        } else {
            this.errorPasswordRecovery = ERROR_CAPTCHA_MESSAGE;
            this.asyncLogin = false;
        }

    }

    public validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    goBack() {
        this.authService.setTemplate(TemplateLoginEnum.LOGIN);
        this.router.navigate(['/login']);
    }

    private getUser() {

        this.userService.setIsLoggedIn(false);
        this.initialize();
    }

    private initialize() {
        this.app.globalLoader = false;
        this.initialized = true;
    }

    validateCaptcha($event) {
        this.captchaValidated = true;
        this.captchaToken = $event.response;

	}

    resetCaptcha() {
        this.captchaValidated = false;
        this.captcha.reset();
    }
}
