import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ViewEncapsulation,
  ElementRef,
  SimpleChanges,
} from '@angular/core';

import { ConfigFormSelect, IFormSelect } from './form-select.interface';
@Component({
  selector: 'cmx-select-form',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FormSelectComponent {
  @Input() items: IFormSelect[] = null;

  @Input() config: ConfigFormSelect = {
    borderFormat: 'rounded',
    labelPlaceholder: true,
    withIcon: true,
    width: '100%',
  };
  @Input('size') sizeComponent: string = 'lg';
  @Input() label: string = 'Selecione';
  @Input() id: string = null;
  @Input() disabled: boolean = false;
  @Input() itemSelected = null;

  @ViewChild('buttonSelect', { static: true }) el: ElementRef;

  toggleItems: boolean = false;
  loaded = false;

  @Output('onSelect')
  onSelect: EventEmitter<IFormSelect> = new EventEmitter<IFormSelect>();

  constructor() {
    document.addEventListener('click', this.clickOutside.bind(this));
  }

  ngOnDestroy() {
    document.addEventListener('click', this.clickOutside, false);
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if ('items' in changes && changes.items.currentValue) {
      const items = changes['items'];

      if (items.currentValue && items.currentValue.length > 0) {
        this.items = this.items.map((item) => {
          const description = 'description' in item ? item.description : item.name;
          return {
            ...item,
            name: description,
            disabled: 'disabled' in item ? item.disabled : false,
          };
        });
        this.loaded = true;
      }
    }
    if ('itemSelected' in changes && changes.itemSelected.currentValue && this.items.length > 0) {
      const itemFiltered = this.items.filter(
        (el) => el.value === changes.itemSelected.currentValue || el.name === changes.itemSelected.currentValue
      );
      if (itemFiltered[0]) {
        this.itemSelected = itemFiltered[0] && itemFiltered[0].name;
      } else {
        this.itemSelected = changes.itemSelected.currentValue;
      }
    }
  }

  selectItem(item) {
    if (item.disabled) {
      return;
    }
    const itemSelect = {
      id: this.el.nativeElement.id,
      ...item,
    };
    this.itemSelected = itemSelect.name;
    this.toggleItems = false;
    if (this.disabled !== true) {
      this.onSelect.emit(itemSelect);
    }
  }

  clickOutside(event: any) {
    if (this.disabled) {
      return;
    }
    if (this.el && this.el.nativeElement.contains(event.target) && !this.toggleItems) {
      return (this.toggleItems = true);
    }
    this.toggleItems = false;
  }

  trackByFn(index: number, item: any) {
    return index;
  }
}
