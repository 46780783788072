
export class StopWatch {

    private duration = 0;
    private timer;

    constructor(private interval: number, private listener: (n: number) => any) {
    }

    public start() {
        if (!this.timer) {
            const self = this;
            this.timer = setInterval(function () {
                self.timerTick();
                self.listener(self.duration);
            }, this.interval);
        }
    }

    public stop() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    public restart() {
        this.stop();
        this.duration = 0;
        this.start();
    }

    public getDuration() {
        return this.duration;
    }

    private timerTick() {
        this.duration += this.interval;
    }

}
