import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { AppModule } from 'app/app.module';
import * as _ from 'lodash';
import { IFilterSortByStateSelection } from './interfaces/filter-sort-by-state-selection.interface';

@Injectable()
export class SortByStateService {
    state: BehaviorSubject<any> = new BehaviorSubject('');

    public current$ = this.state.asObservable();

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialSortBySelection: IFilterSortByStateSelection = <IFilterSortByStateSelection>{};
    private _sortByState: IFilterSortByStateSelection = <IFilterSortByStateSelection>{
        sortBy: ''
    };

    public loadSortBy(){
        this._sortByState = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'sortByfilter', this._sortByState, undefined);
        this._initialSortBySelection = _.cloneDeep(this._sortByState);
        this.state.next(this._sortByState.sortBy);
    }

    public changeSortBy(newSortBy: string) {
        this._sortByState.sortBy = newSortBy;
        this._initialSortBySelection = this._userPreferencesService.verifyFilterChanges(
            this._initialSortBySelection, this._sortByState, 'sortByfilter', this._userCacheService.getUserPreferences().id);
        this.state.next(newSortBy);
    }

    public getCurrent() {
        return this.state.getValue();
    }
}
