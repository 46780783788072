import { LoginPermissionService } from '#services/_login-permission/login-permission.service';
import { Component, ViewEncapsulation } from '@angular/core';
import { DashComponent } from 'app/theme/pages/default/custom-dashboard/dashComponent.model';
import { DragulaService } from 'ng2-dragula/ng2-dragula';

import { ComponentDescriptions } from './custom-dashboard.descriptions';

@Component({
    selector: 'custom-dashboard',
    templateUrl: './custom-dashboard.component.html',
    styleUrls: ['./custom-dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CustomDashboardComponent {

    public isVisibleCustomDash: boolean = false;
    public selectedComponent: DashComponent = new DashComponent();

    constructor(
        public loginPermissionService: LoginPermissionService,
        private dragula: DragulaService,
        private componentDescriptions: ComponentDescriptions) {
        const bag: any = this.dragula.find('customDash');
        if (bag !== undefined) {
            this.dragula.destroy('customDash');
        }
        this.dragula.setOptions('customDash', {
            revertOnSpill: true,
            direction: 'horizontal',
            moves: (el, container, handle) => {
                return $(handle).is('.component');
            },
        });
    }

    public ngAfterViewInit() {
        if (this.loginPermissionService.getMergedComponentList().length === 0) {
            this.loginPermissionService.loadComponents();
        } else {
            this.loginPermissionService.asyncComponents = false;
        }
        this.loginPermissionService.populateStoredComponents();
    }

    public saveCustomChanges(): void {
        this.clearSelection();
        this.loginPermissionService.saveCustomChanges();
    }

    public selectComponent(component: DashComponent) {
        this.selectedComponent = component;
        this.selectedComponent.description = '';
        this.componentDescriptions.descriptions.forEach((descriptionComponent) => {
            if (descriptionComponent.component === component.component) {
                this.selectedComponent.description = descriptionComponent.description;
            }
        });
    }

    public clearSelection(): void {
        this.selectedComponent = new DashComponent();
    }

    public resizeComponent(component): void {
        component.size === 100 ? component.size = 50 : component.size = 100;
        this.loginPermissionService.changeComponent(component);
    }

    trackByFn(index: number, item: any) {
        return index;
    }

}
