export class Chart {
    static makeChart(chartId: string, configs: any): AmCharts.AmChart {
        const chart = AmCharts.makeChart(chartId, configs);

        chart.addListener('drawn', () => {
            $('a[title*=JavaScript]').attr('style', 'display: none; opacity: 0;');
            $('g[aria-label*=Meta]').attr('style', 'display: none; opacity: 0;');
        });

        $('a[title*=JavaScript]').attr('style', 'display: none; opacity: 0;');
        $('g[aria-label*=Meta]').attr('style', 'display: none; opacity: 0;');

        return chart;
    }
}
