import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';

@Injectable()
export class UrfCodesInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Regra: Ao enviar os códigos de URF, adicionamos um segundo código com zero à esquerda, que representa uma possível URF proveniente do Protheus.
        // removido anteriormente no serviço: DomainService
        if (request.params.get('urfNationalizationCodes')) {
            const splittedUrfs = request.params.get('urfNationalizationCodes').split(',');
            let conexosAndProtheusUrfs = []
            splittedUrfs.forEach((urf) => { conexosAndProtheusUrfs.push(urf); conexosAndProtheusUrfs.push(`0${urf}`) })
            return next.handle(request.clone({
                params: request.params.set('urfNationalizationCodes', conexosAndProtheusUrfs.join())
            }));
        } else {
            return next.handle(request.clone({}));
        }
    }
}