import { environment } from '#environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class DetailTableCommentsService {

    constructor(private http: HttpClient) {
        //
    }

    public getComments(transactionReferenceDate, recno,
                       originalRecno , debitCreditIndicator, companyCode): Observable<any> {
      let params = new HttpParams();
      params = params.set('transactionReferenceDate', transactionReferenceDate);
      params = params.set('recno', recno);
      params = params.set('originalRecno', originalRecno);
      params = params.set('debitCreditIndicator', debitCreditIndicator);
      params = params.set('companyCode', companyCode);
      return this.http.get(
          `${environment.endpoints.resultsService}/imp-comment/v2`, {params},
      );
  }

    public postComment(imp, account, comment, costCenter, transactionReferenceDate,
                       recno, originalRecno, debitCreditIndicator, companyCode): Observable<any> {
        return this.http.post(
            `${environment.endpoints.resultsService}/imp-comment/v2`, {
                importationNumber: imp,
                accountingCode: account,
                importationComment: comment,
                costCenter,
                transactionReferenceDate,
                recno,
                originalRecno,
                debitCreditIndicator,
                companyCode,
            },
        );
    }

    public editComment(commentId, imp, account, comment, costCenter, transactionReferenceDate,
                       recno, originalRecno, debitCreditIndicator, companyCode): Observable<any> {
        return this.http.put(
            `${environment.endpoints.resultsService}/imp-comment/v2/${commentId}`, {
                importationNumber: imp,
                accountingCode: account,
                importationComment: comment,
                commentId,
                costCenter,
                transactionReferenceDate,
                recno,
                originalRecno,
                debitCreditIndicator,
                companyCode,
            },
        );
    }

}
