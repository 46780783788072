import { Component, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { Chart } from 'app/shared/utils/chart';
import { NotificationService } from '#services/_notification/notification.service';
import { UtilsService } from '#services/_utils/utils.service';
import { FreightRatesService } from '../../../services/freight-rates.service';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { QuotatioFreightRatesFilter } from '../quotation-freight-rates-filter.model';
import * as _ from 'lodash';
import { NATIONAL_FREIGHT_RATES_GRID_COLUMNS } from '../constants/national-freight-rates.constant';
import { IGridColumn, IGridRow } from 'app/shared/components/cmx-custom-grid/interfaces';

@Component({
    selector: 'national-freight-rate',
    templateUrl: './national-freight-rates.component.html',
    styleUrls: ['./national-freight-rates.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class NationalFreightRatesComponent {

    private _subscription: Subscription;
    filterState: QuotatioFreightRatesFilter = this.filterStateService.getQuotatioFreightRatesFilter();
    fileSelected = null;
    origin: any = [];
    destination: any = [];
    transport: any = [];
    freightRatesChartData = [];
    showLoader = false;

    gridColumns: IGridColumn[] = NATIONAL_FREIGHT_RATES_GRID_COLUMNS;
    gridRows: IGridRow[] = [];

    readonly dropdownSettingsTransport = {
        singleSelection: true,
        text: 'Selecione o Veículo utilizado',
        badgeShowLabel: 'Veículos selecionados'
    };
    readonly dropdownSettingsDestination = {
        singleSelection: true,
        text: 'Selecione o Destino da Mercadoria',
        badgeShowLabel: 'Destinos selecionados',
    };
    readonly dropdownSettingsOrigin = {
        singleSelection: true,
        text: 'Selecione a Origem',
        badgeShowLabel: 'Origens selecionadas',
    };

    constructor(
        private _freightRatesService: FreightRatesService,
        private notificationService: NotificationService,
        private _formatCurrency: UtilsService,
        private filterStateService: FilterStateService
    ) {
        this._getServiceOrigin();
        this._getServiceDestination();
        this._getServiceModalTransport();
    }

    ngOnDestroy() {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }

    ngOnInit() {
        this._getShippingNational();
    }

    trackByFn(index: number, item: any) {
        return index;
    }

    handleSelectOrigin($event) {
        this.filterState.setNationalOrigin($event);
    }

    handleSelectDestination($event) {
        this.filterState.setNationalDestination($event);
    }

    handleSelectTransport($event) {
        this.filterState.setNationalTransport($event);
    }

    handleSelect() {
        if (this.filterState.getNationalOrigin() !== null
            && this.filterState.getNationalDestination() !== null
            && this.filterState.getNationalTransport() !== null
        ) {
            this._getShippingNational();
        }
    }

    private _getServiceOrigin() {
        this._subscription = this._freightRatesService.getOrigin().subscribe(
            (data) => {
                const _data = [];

                data.forEach((element) => {
                    const _element = {
                        id: element,
                        itemName: element,
                    };
                    _data.push(_element);
                });

                this.origin = _data;
            }, (error) => {
                this.origin = [];
                this.notificationService.openNotification('error', error.message);
            }
        );
    }

    private _getServiceDestination() {
        this._subscription = this._freightRatesService.getDestination().subscribe(
            (data) => {
                const _data = [];

                data.forEach((element) => {
                    const _element = {
                        id: element,
                        itemName: element,
                    };
                    _data.push(_element);
                });

                this.destination = _data;
            }, (error) => {
                this.destination = [];
                this.notificationService.openNotification('error', error.message);
            }
        );
    }

    private _getServiceModalTransport() {
        this._subscription = this._freightRatesService.getModalTransport().subscribe(
            (data) => {
                const _data = [];

                data.forEach((element) => {
                    const _element = {
                        id: element,
                        itemName: element,
                    };
                    _data.push(_element);
                });

                this.transport = _data;
            },  (error) => {
                this.transport = [];
                this.notificationService.openNotification('error', error.message);
            }
        );
    }

    private _getShippingNational() {
        this.gridRows = [];
        this.showLoader = true;
        this.filterState.updatePreference();

        let freightValues = [];

        this._freightRatesService.getShippingComparison(
            this.filterState.getNationalOrigin()[0]?.itemName,
            this.filterState.getNationalDestination()[0]?.itemName,
            this.filterState.getNationalTransport()[0]?.itemName
        ).subscribe(
            (data) => {
                this.gridRows = data?.map((row: any) => {
                    freightValues.push({
                        shippingCompany: row.shippingCompany,
                        totalPrice: row.totalPrice,
                    });

                    return {
                        ...row,
                        adv: `${row.adv?.toLocaleString('pt-br')}`,
                        tax: `${row.tax?.toLocaleString('pt-br')}`,
                    };
                });

                const sorted = freightValues.sort((a, b) => a.totalPrice - b.totalPrice);
                this.loadChart(sorted);
                this.showLoader = false;
            }, (error) => {
                this.showLoader = false;
            }
        );
    }

    private loadChart(freightValues) {
        Chart.makeChart('national-freight-rate-total-freight-chart', {
            dataProvider: freightValues,
            categoryField: 'shippingCompany',
            type: 'serial',
            theme: 'light',
            graphs: [
                {
                    valueField: 'totalPrice',
                    labelText: '[[totalPrice]]',
                    labelFunction: (value) => this.formatCurrency(value),
                    fontSize: 9,
                    type: 'column',
                    balloonText: '[[totalPrice]]',
                    balloonFunction: (value) => this.formatCurrency(value),
                    lineColor: ['#C5D8FA', '#AAC2EC', '#97AFD8', '#7291C6', '#5B83C8'],
                    showAllValueLabels: true,
                    fillAlphas: 0.8,
                    lineAlpha: 0.2,
                    columnWidth: 0.5,
                },
            ],
            categoryAxis: {
                fontSize: 9,
                boldLabels: true,
            },
            titles: [
                {
                    size: 12,
                    text: 'Parceiro mais competitivo (Frete + Pedágio)',
                },
            ],
        });
    }

    formatCurrency(data) {
        return this._formatCurrency.formatNumber(data.values.value, {
            style: 'currency',
        });
    }

    cleanEvent($event) {
        $event.target.value = '';
    }

    handleAttachFile() {
        this.showLoader = true;
        const request = new FormData();
        request.append('file', this.fileSelected.file, this.fileSelected.name);

        this.notificationService.openNotification('startUpload', 'Atualizando valores de frete, aguarde por favor');

        this._freightRatesService.uploadFreightValues(request).subscribe(
            (data) => {
                this._getServiceOrigin();
                this._getServiceDestination();
                this._getServiceModalTransport();
                this._getShippingNational();
                this.notificationService.openNotification('success', 'Valores de frete atualizados com sucesso');
            }, (responseError) => {
                let err = JSON.parse(responseError.error);
                this.notificationService.openNotification('error', err.message);
            }
        );

        this.handleClearAttachFile();
    }

    handleClearAttachFile() {
        this.fileSelected = null;
    }

    setSelectedFile($event) {
        let file = $event.target.files[0];

        this.fileSelected = {
            file: file,
            name: file.name
        };
    }
}
