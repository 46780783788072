export enum Type {
    company = 'company',
    profitCenter = 'profitCenter',
    costsCenter = 'costsCenter',
    trader = 'trader',
    client = 'client',
    clientGroup = 'clientGroup',
    director = 'director',
    subsidiary = 'subsidiaries',
}
