import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import * as _ from 'lodash';

import { UtilsService } from '#services/_utils/utils.service';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';
import { IGridColumn, IGridRow } from 'app/shared/components/cmx-custom-grid/interfaces';

@Component({
    selector: 'alert-client-details-dialog',
    templateUrl: './alert-client-details-dialog.component.html',
    styleUrls: ['./alert-client-details-dialog.component.scss'],
})
export class AlertClientDetailsDialogComponent implements OnDestroy {
    gridColumns: IGridColumn[];
    gridRows: IGridRow[];
    link: string;
    id: string;
    loading: boolean;
    details: any;

    private _unsubscribeAll$: Subject<any>;

    constructor(
        private _customDialogService: CustomDialogService,
        private _utilsService: UtilsService,
    ) {
        this.id = this._utilsService.generateGUID('dialog');
        this.loading = true;
        this._unsubscribeAll$ = new Subject();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    open(): void {
        this.loading = true;
        this._customDialogService.open(this.id);

        this.gridColumns = this._createColumns();
        this.gridRows = this._createRows();

        const timeout = setTimeout(() => {
            this.loading = false;
            clearTimeout(timeout);
        }, 1000);
    }

    private _createColumns(): IGridColumn[] {
        const columns: any = [
            {
                field: 'invoice',
                label: 'Invoice',
                order: '1',
                width: 110
            },
            {
                field: 'packingList',
                label: 'Packing',
                order: '2',
                width: 110
            },
            {
                field: 'billOfLading',
                label: 'AWB/CRT/BL',
                order: '3',
                width: 110
            },
        ];
        const cssClasses = {
            headerCssClass: 'grid-header align-center',
            cssClass: 'grid-cell align-center',
        };

        return columns.reduce((accumulator: IGridColumn[], currentValue: IGridColumn, currentIndex: number) => {
            const fieldOption = { ...currentValue, id: currentIndex, name: currentValue.label, ...cssClasses };
            return [...accumulator, fieldOption];
        }, []);
    }

    private _createRows(): IGridRow[] {
        let array = this.details.invoice.length > this.details.packingList.length ? this.details.invoice : this.details.packingList;
        array = array.length > this.details.billOfLading.length ? array : this.details.billOfLading;

        const rows = [];
        array.forEach((item, index) => {
            rows.push({ id: index, invoice:this.details.invoice[index], packingList: this.details.packingList[index], billOfLading: this.details.billOfLading[index]});
        });
        return rows;
    }
}
