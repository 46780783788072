<div class="page-title-identifier">Custos</div>
<div *ngIf="exportAsync" class="fullLoader">
    <span class="exporting">Solicitando Exportação</span>
    <loader></loader>
</div>

<div class="costsContainer">
    <div *ngIf="!asyncCosts">
        <loader></loader>
    </div>

    <em (click)="swapVision()" title="visão {{ filterState.getVision() }}" class="material-icons swap">swap_vert</em>
    <em (click)="exportCosts('detail')" title="Download" class="flaticon-download downloadButton"></em>

    <span (click)="openAnaliticModal('')" class="detail-btn">
        <em class="material-icons">sort</em> Acessar Detalhamento Geral dos Custos
    </span>

    <span class="warning-btn" *ngIf="showWarning">
        <em class="material-icons" tooltip="Faturamento Parcial" size="sm" placement="bottom">warning</em>
    </span>

    <div class="checkmark-container">
        <span id="current-year"
            class="checkmark {{ filterState.getPeriodType() === 'CURRENT_YEAR' && filterState.getSearch() === '' ? 'checked' : '' }}"
            (click)="changePreset('CURRENT_YEAR', $event)">
            Ano Corrente
        </span>
        <span id="last12-months"
            class="checkmark {{ filterState.getPeriodType() === 'LAST_12_MONTHS' && filterState.getSearch() === '' ? 'checked' : '' }}"
            (click)="changePreset('LAST_12_MONTHS', $event)">
            12 Meses
        </span>
        <span id="current-month"
            class="checkmark {{ filterState.getPeriodType() === 'CURRENT_MONTH' && filterState.getSearch() === '' ? 'checked' : '' }}"
            (click)="changePreset('CURRENT_MONTH', $event)">
            Mês Corrente
            </span>
        <span id="previous-month"
            class="checkmark {{ filterState.getPeriodType() === 'PREVIOUS_MONTH' && filterState.getSearch() === '' ? 'checked' : '' }}"
            (click)="changePreset('PREVIOUS_MONTH', $event)">
            Mês Anterior
        </span>
    </div>

    <div class="titleLine">
        <div class="title-information-costs">
            <cmx-help-modal help-title="Custos" [help-description]="helpDescription"></cmx-help-modal>
            <span class="title-container-custos">Custos</span>
        </div>
        <div id="searchLine" class="reduzed">
            <div class="search-container" style="margin-right: 50px">
                <!-- Chassi -->
                <cmx-filter-search
                    *ngIf="this.currentUser?.has('AUTOMOTIVE_USER') || !this.currentUser?.has('AUTOMOTIVE_USER') && filterState.getAutomotiveMode() === 'Ligado'"
                    [type]="filterState.getTypeSearch()"
                    [types]="['Chassi']"
                    [width]="'130px'"
                    [value]="filterState.getChassiSearch()"
                    (onInput)="filterState.setChassiSearch($event)"
                    (onChangeType)="filterState.setTypeSearch($event)"
                    (onApply)="loadCosts(true)"
                ></cmx-filter-search>
                <!-- IMP / REF -->
                <cmx-filter-search
                    *ngIf="!currentUser?.has('AUTOMOTIVE_USER') && filterState.getAutomotiveMode() === 'Desligado'"
                    [type]="filterState.getTypeSearch()"
                    [types]="['IMP', 'REF']"
                    [width]="'130px'"
                    [value]="filterState.getImpSearch()"
                    (onInput)="filterState.setImpSearch($event)"
                    (onChangeType)="filterState.setTypeSearch($event)"
                    (onApply)="loadCosts(true)"
                ></cmx-filter-search>
            </div>

            <div class="search-container">
                <ng-container *ngIf="!currentUser?.has('AUTOMOTIVE_USER') || filterState.getAutomotiveMode() === 'Desligado'">
                    <div class="input-group"
                        *ngIf="currentUser?.has('INTERNAL_FILTERS') && filterState.getAutomotiveMode() === 'Desligado'">
                        <cmx-dropdown
                            name="crs"
                            [styles]="{ width: '100px' }"
                            [data]="options.cr"
                            [settings]="dropdownSettings.profitCenters"
                            [ngModel]="filterState.getProfitCenters()"
                            (ngModelChange)="filterState.setProfitCenters($event)"
                        ></cmx-dropdown>
                        <span class="input-group-addon" style="margin-left: -15px">Por</span>
                    </div>
                    <div class="input-group" *ngIf="currentUser?.has('INTERNAL_FILTERS')">
                        <cmx-dropdown
                            [styles]="{ width: '120px'}"
                            name="clients"
                            [data]="options.client"
                            [settings]="dropdownSettings.clientGroups"
                            [ngModel]="filterState.getClientGroups()"
                            (ngModelChange)="filterState.setClientGroups($event)"
                        ></cmx-dropdown>
                        <span class="input-group-addon" style="margin-left: -22px"></span>
                    </div>
                </ng-container>

                <ng-container *ngIf="currentUser?.has('AUTOMOTIVE_USER') || filterState.getAutomotiveMode() === 'Ligado'">
                    <div class="input-group">
                        <cmx-dropdown
                            [styles]="{ width: '120px' }"
                            name="models"
                            [data]="options.models"
                            [settings]="dropdownSettings.models"
                            [ngModel]="filterState.getModels()"
                            (ngModelChange)="filterState.setModels($event)">
                        </cmx-dropdown>
                        <span class="input-group-addon" style="margin-left: -22px"></span>
                    </div>
                    <div class="input-group">
                        <cmx-dropdown
                            [styles]="{ width: '100px' }"
                            name="colors"
                            [data]="options.colors"
                            [settings]="dropdownSettings.colors"
                            [ngModel]="filterState.getColors()"
                            (ngModelChange)="filterState.setColors($event)">
                        </cmx-dropdown>
                        <span class="input-group-addon" style="margin-left: -22px"></span>
                    </div>
                    <div class="input-group">
                        <cmx-dropdown
                            [styles]="{ width: '110px' }"
                            name="modelYears"
                            [data]="options.modelYears"
                            [settings]="dropdownSettings.modelYears"
                            [ngModel]="filterState.getModelYears()"
                            (ngModelChange)="filterState.setModelYears($event)">
                        </cmx-dropdown>
                        <span class="input-group-addon" style="margin-left: -22px"></span>
                    </div>
                    <div class="input-group">
                        <cmx-dropdown
                            [styles]="{ width: '100px' }"
                            name="manufacturerYears"
                            [data]="options.manufacturerYears"
                            [settings]="dropdownSettings.manufacturerYears"
                            [ngModel]="filterState.getManufacturerYears()"
                            (ngModelChange)="filterState.setManufacturerYears($event)">
                        </cmx-dropdown>
                        <span *ngIf="currentUser?.has('ROUTE_AUTOMOTIVE') && !currentUser?.has('AUTOMOTIVE_USER')"
                        class="input-group-addon" style="margin-left: -22px"></span>
                    </div>
                </ng-container>

                <div *ngIf="currentUser?.has('ROUTE_AUTOMOTIVE') && !currentUser?.has('AUTOMOTIVE_USER')"
                    title="Modo Automotivo: {{ filterState.getAutomotiveMode() }}"
                    class="input-group filterMode"
                    (click)="toggleFilterMode()">
                    <div><em class="material-icons">directions_car</em></div>
                </div>

                <div class="input-group">
                    <cmx-filter-datepicker
                        date="Faturamento"
                        id="costs-datepicker"
                        [startDate]="filterState.getStartDate()"
                        [endDate]="filterState.getEndDate()"
                        (onChangeDates)="datesChanged($event)"
                    ></cmx-filter-datepicker>
                </div>

                <button (click)="loadCosts(true)" class="applyImpStatus">
                    <em class="fa fa-check"></em>
                </button>

                <div
                    *ngIf="filterState.getChassiSearch().length !== 0 || filterState.getImpSearch().length !== 0"
                    class="disableSearch"
                    title="Os filtros são desativados ao realizar uma busca específica."
                ></div>

            </div>
        </div>
    </div>

    <hr style="margin: 10px 0px 5px 0px" />

    <div *ngIf="asyncCosts" class="content">
        <div *ngIf="allCosts?.length === 0" class="noContent"> Não existem custos para os filtros aplicados. </div>
        <div *ngFor="let category of allCosts; trackBy: trackByFn" class="categoryContainer">
            <div *ngIf="category.expensesItems.length > 0" class="costsSubTitle">
                <img src="./assets/app/media/img/icons/{{ formatCategoryName(category.name) }}.png" alt="" />
                <span class="detailTitle" style="color: #215aa0">{{ category.name }}</span>
                <!-- <div class="categoryBall" (click)="openDetailModal(category.name)">
                    <img src="../../../../../assets/app/media/img/icons/uc.png" style="width: 55%"/>
                </div> -->
                <span (click)="openAnaliticModal(category.name)" class="analiticButton">
                    <span style="margin-right: 5px">|</span> detalhamento<i class="material-icons">sort</i>
                </span>
            </div>
            <div *ngIf="category.expensesItems.length > 0" class="costsContent">
                <div *ngFor="let cost of category.expensesItems; trackBy: trackByFn" class="costsSet">
                    <div *ngIf="category.name != 'Duplicatas'" class="inner-costs-set w-100">
                        <span class="costsTitle" (click)="openAnaliticModal(category.name, cost.name)">
                            {{cost.name}}
                        </span>
                        <div class="costsValueContainer">
                            <span class="costsValue">{{ cost?.value || 0 | cmxValid | currencyType }}</span>
                            <!-- <div class="categoryBall" (click)="openDetailModal(category.name, cost.name)">
                                <img src="../../../../../assets/app/media/img/icons/uc.png" alt=""/>
                            </div> -->
                        </div>
                        <div class="invoice-progress progress">
                            <div
                                id="dataInvoicesBilledItens"
                                class=""
                                role="progressbar"
                                style="background-color: #98b8e0 !important"
                                [style.width]="cost?.percentage + '%'"
                                aria-valuenow="50"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        <div class="textProgress">
                            <span class="invoice-detail">Rel. Mês Anterior</span>
                            <span class="invoice-percentage">{{ cost?.percentage || 0 | cmxValid }}%</span>
                        </div>
                    </div>
                    <div *ngIf="category.name == 'Duplicatas'" class="inner-costs-set w-100">
                        <div class="duplicatesTitleContainer">
                            <span class="costsTitle" (click)="openAnaliticModal(category.name, cost.name)" [tooltip]="cost.name.length > 20 ? cost.name : null" size="sm" placement="bottom">
                                {{cost.name | slice: 0:20}}{{cost.name.length > 20 ? '...' : ''}}
                            </span>
                            <!-- <div class="categoryBall" (click)="openDetailModal(category.name, cost.name)">
                                <img src="../../../../../assets/app/media/img/icons/uc.png" alt="" />
                            </div> -->
                            <div class="duplicatesDueDate" *ngIf="cost.dueDate">
                                <div class="title">
                                    Vencimento
                                </div>
                                <div class="value">
                                    <strong>{{cost.dueDate | date: 'dd/MM/yyyy'}}</strong>
                                </div>
                            </div>
                        </div>
                        <div class="duplicatesValueContainer">
                            <div class="duplicatesItens">
                                <div class="title">
                                    Valor Total
                                </div>
                                <div class="value">
                                    <span>{{ cost?.totalValue || 0 | cmxValid | currencyType }}</span>
                                </div>
                            </div>
                            <div class="duplicatesItens">
                                <div class="title">
                                    Desconto
                                </div>
                                <div class="value discount">
                                    <span>{{ cost?.discountValue || 0 | cmxValid | currencyType }}</span>
                                </div>
                            </div>
                            <div class="duplicatesItens">
                                <div class="title">
                                    Valor Líquido
                                </div>
                                <div class="value netValue">
                                    <span>{{ cost?.value || 0 | cmxValid | currencyType }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="invoice-progress progress">
                            <div id="dataInvoicesBilledItens" class="" role="progressbar"
                                style="background-color: #98b8e0 !important" [style.width]="cost?.percentage + '%'"
                                aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div class="textProgress">
                            <span class="invoice-detail">Rel. Mês Anterior</span>
                            <span class="invoice-percentage">{{ cost?.percentage || 0 | cmxValid }}%</span>
                        </div>
                    </div>
                </div>
                <div class="costsSet">
                    <span class="costsTitle" (click)="openAnaliticModal(category.name)">TOTAL</span>
                    <div class="costsValueContainer">
                        <span class="costsValue">{{ category?.value || 0 | cmxValid | currencyType }}</span>
                        <!-- <div class="categoryBall" (click)="openDetailModal(category.name)"
                            ><img src="../../../../../assets/app/media/img/icons/uc.png" alt=""
                        /></div> -->
                    </div>
                    <div class="invoice-progress progress">
                        <div
                            id="dataInvoicesBilledItens"
                            class=""
                            role="progressbar"
                            style="background-color: #98b8e0 !important"
                            [style.width]="category?.percentage + '%'"
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        ></div>
                    </div>
                    <div class="textProgress">
                        <span class="invoice-detail">Rel. Mês Anterior</span>
                        <span class="invoice-percentage">{{ category?.percentage || 0 | cmxValid }}%</span>
                    </div>
                </div>
            </div>
        </div>

        <cmx-custom-dialog id="cmx-custom-dialog-costs-sku" overlay="true">
            <ng-container cmx-custom-dialog-header>
                <div *ngIf="!asyncSkuValues">
                    <loader></loader>
                </div>
                <div class="title-modal" *ngIf="asyncSkuValues">
                    <i (click)="exportCosts('modal')" class="flaticon-download downloadButtonDialog"></i>
                    <div class="titleContainer">
                        <div class="categoryBallDialog"><img src="../../../../../assets/app/media/img/icons/uc.png"
                                alt="" /></div>
                        <span class="titleHeader">{{ costCategoryDetail?.name }}</span>
                    </div>
                </div>
            </ng-container>

            <ng-container cmx-custom-dialog-body *ngIf="asyncSkuValues">
                <div class="conteinerContainer">
                    <div class="containerNumberContainer">
                        <span class="title">Contêineres</span>
                        <span class="value">{{ costCategoryDetail?.containerQuantity }}</span>
                    </div>
                    <div class="containerAverageValueContainer">
                        <span class="title">Valor Médio por Contêiner</span>
                        <span class="value">{{ costCategoryDetail?.containerAverageValue | currencyType }}</span>
                    </div>
                </div>
                <hr style="width: calc(100% + 20px); margin-left: -20px" />
                <div class="skuListContainer">
                    <span style="font-size: 12px; font-weight: 500">Valores por SKU e Unidade Comercializada
                        (U.C)</span>
                    <div class="skuList">
                        <div *ngFor="let sku of costCategoryDetail.skuValueList; trackBy: trackByFn"
                            class="valueContainer">
                            <div class="skuContainer">
                                <span class="sku">{{ sku?.skuCode }}</span>
                                <span class="value">{{ sku?.skuCostValue | currencyType }}</span>
                            </div>
                            <div class="ucContainer">
                                <span class="uc">x{{ sku?.unitsSold }} U.C</span>
                                <span class="value">{{ sku?.skuCostValueByUnit | currencyType }}</span>
                            </div>
                            <hr style="margin: 10px 0" />
                        </div>
                    </div>
                </div>
                <div *ngIf="skuPages.length > 1">
                    <pagination
                        class="small"
                        [numberOfPages]="skuPages.length"
                        [queryId]="queryId"
                        [maxPages]="5"
                        [showPagesText]="false"
                        (onPageChange)="changeSkuModalPage($event)"
                    ></pagination>
                </div>
            </ng-container>
        </cmx-custom-dialog>
    </div>
</div>

<div class="analiticCostsModal hidden">
    <div class="modalContent">
        <div class="download-container">
            <div class="content left" title="Download" (click)="exportCosts('detail')"
                [ngClass]="{datamartDisabled: !currentUser.has('API_DATAMART')}" >
                <em class="flaticon-download"></em>
            </div>
            <div class="content right" title="Datamart" (click)="openDatamartModal()"
                *ngIf="currentUser.has('API_DATAMART')" >
                <em class="fa fa-database"></em>
            </div>
        </div>
        <em (click)="closeAnaliticModal()" class="la la-close close"></em>
        <detail-costs></detail-costs>
        <cmx-datamart-preferences
            *ngIf="datamartFilters.length && currentUser.has('API_DATAMART')"
            datamartName="Custos"
            serviceId="expenses-service"
            generatorId="expenses-detail"
            modalTitle="Data Mart - Custos"
            hideEmail="true"
            [filters]="datamartFilters"
            [filterState]="filterState"
        ></cmx-datamart-preferences>
    </div>
</div>
