import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';

export const NATIONAL_FREIGHT_RATES_GRID_COLUMNS: IGridColumn[] = [
    {
        groupName: 'Custo Detalhado',
        field: 'shippingCompany',
        name: 'Transportadora',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        width: 150,
    },
    {
        groupName: 'Custo Detalhado',
        field: 'shipping',
        name: 'Valor Frete',
        formatterType: 'currency',
        formatterData: {
            currency: 'BRL',
        },
        width: 100,
        headerCssClass: 'grid-header align-right',
        cssClass: 'grid-cell align-right',
    },
    {
        groupName: 'Custo Detalhado',
        field: 'toll',
        name: 'Pedágio',
        formatterType: 'currency',
        formatterData: {
            currency: 'BRL',
        },
        width: 100,
        headerCssClass: 'grid-header align-right',
        cssClass: 'grid-cell align-right',
    },
    {
        groupName: 'Custo Detalhado',
        field: 'adv',
        name: 'Ad Valorem',
        formatterType: 'percentage',
        width: 100,
        headerCssClass: 'grid-header align-right',
        cssClass: 'grid-cell align-right',
    },
    {
        groupName: 'Custo Detalhado',
        field: 'helper',
        name: 'Ajudante <span style="color: red">*</span>',
        formatterType: 'currency',
        formatterData: {
            currency: 'BRL',
        },
        width: 100,
        headerCssClass: 'grid-header align-right',
        cssClass: 'grid-cell align-right',
    },
    {
        groupName: 'Custo Detalhado',
        field: 'tax',
        name: 'Imposto',
        formatterType: 'percentage',
        width: 100,
        headerCssClass: 'grid-header align-right',
        cssClass: 'grid-cell align-right',
    }
];
