import { environment } from '#environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class FreightRatesService {
  private msgErrorDefault = 'Não foi possível carregar os dados';
  constructor(private http: HttpClient) {}

  getOrigin(): Observable<any> {
    return this.http.get(`${environment.endpoints.quotationService}/national-transport/origin`).pipe(catchError((err) => {
      return Observable.throw({
        status: err.status,
        message: 'Erro ao carregar a lista de Origem',
      });
    }));
  }

  getDestination(): Observable<any> {
    return this.http.get(`${environment.endpoints.quotationService}/national-transport/destination`).pipe(catchError((err) => {
      return Observable.throw({
        status: err.status,
        message: 'Erro ao carregar os destinos',
      });
    }));
  }

  getModalTransport(): Observable<any> {
    return this.http.get(`${environment.endpoints.quotationService}/national-transport/equipment`).pipe(catchError((err) => {
      return Observable.throw({
        status: err.status,
        message: 'Erro ao carregar os Tipo de Transportes',
      });
    }));
  }

  getShippingComparison(origin, destination, modalTransport): Observable<any> {
    return this.http
      .get(
        `${environment.endpoints.quotationService}/national-transport/shipping-comparison?origin=${origin}&destination=${destination}&equipment=${modalTransport}`
      )
      .pipe(catchError((err) => {
        return Observable.throw({
          status: err.status,
          message: 'Erro ao carregar os valores comparativos',
        });
      }));
  }

  uploadFreightValues(file) {

    const headers = new HttpHeaders();

    headers.set('Content-Type', 'multipart/form-data');

    return this.http.post(`${environment.endpoints.quotationService}/national-transport/upload-freight-values`, file, { headers, responseType: 'text' });
  }
}
