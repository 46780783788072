import { Injectable } from '@angular/core';

import { UserService } from '../../auth/_services';

@Injectable()
export class UserPermissionBlockerService {

    public currentUser = this.userService.getCurrentUser();

    constructor( private userService: UserService) { }

    public permissionValidator(permissionRequired) {
        return this.currentUser.has(this.permissionIdentifier(permissionRequired));
    }

    private permissionIdentifier(permission) {
        switch (permission.toLowerCase()) {
            case 'alertservice': // Alertas
                return 'API_NOTIFICATIONS';
            case 'invoices-chart': // Cartão de Métricas
                return 'DASHBOARD_COMPONENT_METRICS';
            case 'exchange-chart': // Widget Câmbio
                return 'DASHBOARD_COMPONENT_EXCHANGE';
            case 'channel-chart': // Widget Canal
                return 'DASHBOARD_COMPONENT_CHANNEL';
            case 'cr-processes-table': // Faturamento x CR (Tabela)
                return 'DASHBOARD_COMPONENT_CR_TABLE';
            case 'invoices-processes-chart': // Faturamento x Notas Fiscais
                return 'DASHBOARD_COMPONENT_INVOICES';
            case 'cr-processes-chart': // CR x Notas Fiscais
                return 'DASHBOARD_COMPONENT_CR_PROCESSES';
            case 'imp-status': // Quick IMP Status
                return 'DASHBOARD_COMPONENT_IMP_STATUS';
            case 'sla-status': // Quick SLA Status
                return 'DASHBOARD_COMPONENT_SLA_STATUS';
            case 'chassi-status-chart': // Quick CHASSI Status
                return 'DASHBOARD_COMPONENT_CHASSI_STATUS';
            case 'boarding-status-chart': // Quick Embarque Status
                return 'DASHBOARD_COMPONENT_BOARDING_STATUS';
            case 'messages': // Mensagens Importantes
                return 'DASHBOARD_COMPONENT_MESSAGES';
        }
    }

}
