<div *ngIf="!asyncDetail" class="fullLoader">
    <loader></loader>
</div>

<div id="import-details-historic-container" *ngIf="asyncDetail">
    <div id="import-details-historic-content-error" *ngIf="historicList.length < 1">
        <span class="noContent">Nenhuma Licença atribuída à IMP {{impNumber}}</span>
    </div>
    <div id="import-details-historic-content" *ngIf="historicList.length > 0">
        <cmx-custom-grid
            [id]="'import-details-historic-grid'"
            [columns]="gridHistoricColumns"
            [rows]="historicList"
            [styles]="{ 'height': 'calc(100vh - 230px)', 'margin-top': '0px' }"
        ></cmx-custom-grid>
    </div>
</div>
