import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import bowser from 'bowser';
import moment from 'moment';
import { initGoogleAnalytics } from '../../../assets/usage-analytics/ga'
import { environment } from '#environment';
import { LoginService } from '../../auth/_services/login.service';
import { UserService } from '../../auth/_services/user.service';
import { AnalyticsConfig } from './usage-analytics.config';
import { UsageEvent } from './usage-analytics.event';
import { StopWatch } from './usage-analytics.stop-watch';

import { setupAnalytics } from './setup-analytics';
import { User } from 'app/auth/_models';

declare const gtag: Function;

const IGNORE_LIST = [
    '/login',
    '/logout-msg'
];

let TIME_ON_PAGE_CHECKPOINTS = [5, 10, 30, 60, 180, 300, 600, 1800, 3600, 5400, 7200, 9000];

@Injectable()
export class UsageAnalyticsService {
    private readonly endpoint;
    private readonly browserName;
    private readonly browserVersion;
    private inited: boolean;
    private config: AnalyticsConfig;
    private ga;
    private stopWatch: StopWatch;
    private lastTimeEventLabel: string;
    private lastTimeEventSeconds: number;
    private lastCheckpoint: number;
    private lastFeature: any;
    private lastEvent: UsageEvent;

    constructor(private http: HttpClient, private loginService: LoginService, private userService: UserService) {
        this.endpoint = environment.endpoints.usageAnalyticsService;
        const browser = bowser.getParser(window.navigator.userAgent);
        const browserInfo = browser.getBrowser();
        this.browserName = browserInfo.name;
        this.browserVersion = browserInfo.version;
    }

    public getClient() {
        return this.loginService.getClient();
    }

    public reset() {
        this.lastEvent = null;
    }

    public async sendEvent(name: string, action: string, label: string, value: number, domainProperties: object) {
        if (IGNORE_LIST.includes(location.pathname)) {
            return;
        }

        if (!this.userService.isLoggedIn()) {
            return;
        }
        if (!this.inited) {
            await this.init();
        }
        if (!this.inited || !this.config.enabled) {
            return;
        }
        if (!name || !action) {
            console.warn('Invalid usage event:', { name, action, label, value, domainProperties });
            return;
        }

        const usageEvent = new UsageEvent(name, action, label, value, this.getCommonProperties(), domainProperties);

        if (name === 'Feature') {
            if (usageEvent.equalsTo(this.lastEvent)) {
                // avoid multiple calls in case of route error
                return;
            } else {
                this.lastEvent = usageEvent;
            }
        }


        this._sendAnalyticsEvent(usageEvent);
        try {
            if (typeof (window as any).ga === 'undefined') {
                this._sendDeprecatedAnalyticsEvent(usageEvent);
             } else {
                this._loadAndSendDeprecatedAnalyticsEven(usageEvent);
             }
        } catch (error) {
            console.warn(error)
        }

     }

    private _loadAndSendDeprecatedAnalyticsEven(usageEvent: any) {
        const self = this;
        (window as any).ga((tracker) => {
            try {
                usageEvent.domainProperties['_ga_cid'] = tracker.get('clientId');
                usageEvent.domainProperties['_ga_v'] = tracker.get('apiVersion');
                usageEvent.domainProperties['_ga_tid'] = tracker.get('trackingId');
            } catch (err) {
                console.warn(err);
            }
            this._sendDeprecatedAnalyticsEvent(usageEvent);
        });
    }

    private _sendDeprecatedAnalyticsEvent(usageEvent: any) {
        this.http.post(`${this.endpoint}/event`, usageEvent, {
            responseType: 'text',
        }).subscribe({
            error: (error) => {
                console.warn(error);
            }
        });
    }

    private _sendAnalyticsEvent(usageEvent: any) {
        const user = this.userService.getCurrentUser();
        gtag('event', usageEvent.action, {
            'event_category': usageEvent.name,
            'event_label': usageEvent.label,
            'event_value': usageEvent.value,
            'cms_client': this.getClient(),
            'cms_role': this._getProfileName(user),
            'cms_internal_user': user?.isInternalUser()
        });

    }


    public getCurrentFeature() {
        const pathname = location.pathname;
        let parent = $('body');
        const title = parent.find('.page-title-identifier').first().text() ||
            parent.find('#quickTitle').clone().children().remove().end().text() ||
            parent.find('.titleContainer').last().text() ||
            parent.find('h5').first().text() ||
            parent.find('span.title').text() ||
            parent.find('h3').first().text();
        return (title || '').split('·')[0].trim() || pathname;
    }

    public getFeature(target) {
        const targetRef = $(target);

        if (document.location.pathname === '/index' ||
            document.location.pathname.indexOf('/dashboard') != -1) {
            let widget = targetRef.closest('.flex-item');

            return widget.find('h5').text() || widget.find('.widgetTitle').text();
        } else {
            return this.getCurrentFeature();
        }
    }

    public async sendPageView(title?: any) {
        let pathname = location.pathname;

        if (IGNORE_LIST.includes(pathname)) {
            return;
        }

        if (pathname.indexOf('/supply-chain/notas-fiscais/') > -1) {
            pathname = '/supply-chain/notas-fiscais';
        };
        if (!this.inited) {
            await this.init();
        }

        gtag('event', 'page_view', {
            page_title: title,
            page_location: location.href,
            page_path: pathname,
        });
        try {
            this.ga('send', 'pageview', pathname);
        } catch (err) {
            console.warn(err);
        }

        if (this.stopWatch) {
            this.stopWatch.stop();
            if (this.lastTimeEventLabel) {
                let currentDuration = this.stopWatch.getDuration() / 1000;
                var lastDuration = TIME_ON_PAGE_CHECKPOINTS[this.lastCheckpoint];
                let diff = currentDuration - lastDuration;
                if (diff > 1 && diff <= TIME_ON_PAGE_CHECKPOINTS[TIME_ON_PAGE_CHECKPOINTS.length - 1]) {
                    this.sendEvent('Timer', 'time-on-page', this.lastTimeEventLabel, diff, {
                        Feature: this.lastFeature
                    });
                }
            }
        }
        this.lastTimeEventLabel = null;
        this.lastFeature = null;
        this.stopWatch = new StopWatch(1000, (duration) => {
            let checkpoint = TIME_ON_PAGE_CHECKPOINTS.indexOf(duration / 1000);
            if (checkpoint > -1) {
                this.sendCheckpointEvent(checkpoint);
            }
        });
        this.stopWatch.start();
    }

    private formatPeriod(seconds: number) {
        let str: string;
        if (seconds < 60) {
            str = moment.utc(seconds * 1000).format('s[s]');
        } else if (seconds < 3600) {
            str = moment.utc(seconds * 1000).format('m[m]:s[s]');
        } else {
            str = moment.utc(seconds * 1000).format('H[h]:m[m]:s[s]');
        }
        return str.replace(':0s', '').replace(':0m', '');
    }

    private sendCheckpointEvent(checkpoint) {
        let label;

        if (checkpoint == TIME_ON_PAGE_CHECKPOINTS.length - 1) {
            label = `${this.formatPeriod(TIME_ON_PAGE_CHECKPOINTS[checkpoint])}+`;
            this.stopWatch.stop();
        } else {
            label = `${this.formatPeriod(TIME_ON_PAGE_CHECKPOINTS[checkpoint])}-${this.formatPeriod(TIME_ON_PAGE_CHECKPOINTS[checkpoint + 1])}`;
        }

        const lastTime = checkpoint > 0 ? TIME_ON_PAGE_CHECKPOINTS[checkpoint - 1] : 0;
        const time = TIME_ON_PAGE_CHECKPOINTS[checkpoint] - lastTime;

        this.lastCheckpoint = checkpoint;
        this.lastTimeEventLabel = label;
        this.lastTimeEventSeconds = Date.now() / 1000;
        this.lastFeature = this.getCurrentFeature();

        if (time <= TIME_ON_PAGE_CHECKPOINTS[TIME_ON_PAGE_CHECKPOINTS.length - 1]) {
            this.sendEvent('Timer', 'time-on-page', label, time, {
                Feature: this.lastFeature
            });
        }
    }

    private getBrowser() {
        return this.browserName;
    }

    private getBrowserVersion() {
        return this.browserVersion;
    }

    private getOs() {
        return navigator.platform;
    }

    private getReferringDomain(referrer) {
        const split = referrer.split('/');

        if (split.length >= 3) {
            return split[2];
        }

        return '';
    }

    private getCommonProperties() {
        return {
            OS: this.getOs(),
            BROWSER: this.getBrowser(),
            REFERRER: document.referrer,
            REFERRING_DOMAIN: this.getReferringDomain(document.referrer),
            CURRENT_URL: window.location.href,
            BROWSER_VERSION: this.getBrowserVersion(),
            SCREEN_HEIGHT: screen.height,
            SCREEN_WIDTH: screen.width,
            CLIENT: this.getClient()
        };
    }

    private async init() {
        try {
            this.config = await this.http.get<AnalyticsConfig>(`${this.endpoint}/config`).toPromise();
            if (this.config.ga4Tag && !this.inited) {
                await setupAnalytics(this.config.ga4Tag);
                this.inited = true;
                if (!Date.now) {
                    Date.now = function () { return new Date().getTime(); }
                }
                let self = this;
                $(document).on({
                    'show': function () {
                        if (self.stopWatch) {
                            self.stopWatch.start();
                        }
                    },
                    'hide': function () {
                        if (self.stopWatch) {
                            self.stopWatch.stop();
                        }
                    },
                    'visibilitychange': function () {
                        if (self.stopWatch) {
                            if (document.hidden) {
                                self.stopWatch.stop();
                            } else {
                                self.stopWatch.start();
                            }
                        }
                    }
                });
                $(window).on({
                    'focus': function () {
                        if (self.stopWatch) {
                            self.stopWatch.start();
                        }
                    },
                    'blur': function () {
                        if (self.stopWatch) {
                            self.stopWatch.stop();
                        }
                    }
                });
            }
            if (this.config.enabled && typeof this.config.gaAccount !== 'undefined') {
                this.ga = await initGoogleAnalytics(this.config.gaAccount);
            }
            this.inited = true;

        } catch (err) {
            console.warn(err);
        }
    }

    private _getProfileName(user: User) {
        return user?.getProfileName();
    }

}
