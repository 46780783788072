import { Component, ViewEncapsulation } from '@angular/core';

import * as _ from 'lodash';

import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';

import { STAGES } from './constants/stages.constant';
import { DamageChartModel } from './models/damage-chart.model';
import { DamageFilter } from './models/damage-filter.model';

import { UserService } from 'app/auth/_services';
import { DamageChartService } from './damage.service';
import { UtilsService } from '#services/_utils/utils.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';

declare const AmCharts: any;

@Component({
    selector: 'damage-chart',
    templateUrl: './damage.component.html',
    styleUrls: ['./../charts.scss', './damage.component.scss'],
    providers: [DamageChartService],
    encapsulation: ViewEncapsulation.None,
})
export class DamageWidgetComponent {

    currentUser = this._userService.getCurrentUser();
    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);
    readonly dropdownSettingsStage = {
        singleSelection: false,
        text: 'Etapas',
        enableSearchFilter: false,
        badgeShowLimit: 1,
    };

    hasDamageData: boolean;
    loadingDamageWidget: boolean;
    loadingExport: boolean;
    damageFilter: DamageFilter;

    dropdown: {
        clientsOptions: DropdownOption[];
        stagesOptions: DropdownOption[];
    };

    constructor(
        private _damageChartService: DamageChartService,
        private _utilsService: UtilsService,
        private _userService: UserService,
        private _userCacheService: UserCacheService,
    ) {
        this.loadingDamageWidget = true;
        this.loadingExport = false;
        this.dropdown = {
            clientsOptions: [],
            stagesOptions: [],
        };
        this.hasDamageData = false;
        this.damageFilter = new DamageFilter(this._damageChartService.getPermitedOriginCountries());
    }

    ngAfterViewInit() {
        this.dropdown.stagesOptions = this._toDropdownItems(STAGES);

        this._setClientsDropdown();
        this.loadDamageWidget();
    }

    toggleOriginSwitch(e) {
        if (this.currentUser.has('AUTOMOTIVE_USER') || this.currentUser.has('IMP_AUTOMOTIVE_USER')) {
            if ($('#searchLine .toogleLeft').hasClass('clicked')) {
                $('#searchLine .toogleLeft').removeClass('clicked');
                $('.originComponent').removeClass('hidden');
                $('.destinationComponent').addClass('hidden');
                $('#searchLine .applyImpStatus').removeClass('destination');
                this.damageFilter.stageMode = 'origin';
            } else {
                $('#searchLine .toogleLeft').addClass('clicked');
                $('.originComponent').addClass('hidden');
                $('.destinationComponent').removeClass('hidden');
                $('#searchLine .applyImpStatus').addClass('destination');
                this.damageFilter.stageMode = 'destination';
            }
        }
    }

    loadDamageWidget() {
        const { params, headers } = this.damageFilter.getRequest();

        this.loadingDamageWidget = true;

        this._damageChartService.getDamageConsolidation(params, headers.clientGroupCodes).subscribe(
            (damageData) => {
                this.hasDamageData = damageData && damageData.length;
                this._loadDamageKpiChart(damageData);
            },
            (error) => {
                this.loadingDamageWidget = false;
                this._utilsService.errorHandler(error, 'Avarias');
            }
        );
    }

    changeDateParam(e, period) {
        this.loadingDamageWidget = true;
        if (!$(e.target).hasClass('checked')) {
            if (
                period === 'CURRENT_YEAR' ||
                period === 'LAST_12_MONTHS' ||
                period === 'CURRENT_MONTH' ||
                period === 'PREVIOUS_MONTH'
            ) {
                this.damageFilter.periodType = period;
                this.loadDamageWidget();
            }
            $('damage-chart span.checkmark').removeClass('checked');
            $(e.target).addClass('checked');
        }
    }

    export(): void {
        const { params, headers } = this.damageFilter.getRequest();

        this.loadingExport = true;

        this._damageChartService.export(params, headers.clientGroupCodes).subscribe(() => {
            this.loadingExport = false;
        });
    }

    private _loadDamageKpiChart(damageData) {
        new DamageChartModel(damageData).loadChart('damage-chart');
        this.loadingDamageWidget = false;
    }

    private _toDropdownItems(items: any[]) {
        return !_.isNil(items) ? items.map(this._toDropdownItem) : [];
    }

    private _toDropdownItem(e: any): DropdownOption {
        return new DropdownOption(e.code, `${e.name}`);
    }

    private _setClientsDropdown(): void {
        const clients = this._userCacheService.getClientGroups();

        this.dropdown.clientsOptions = this._toDropdownItems(clients);

        if (this.currentUser.has('AUTOMOTIVE_USER')) {
            this.damageFilter.clients = this._toDropdownItems([clients[0]]);
        } else {
            const client = clients.filter((c) => c.name === 'MERCEDES');

            this.damageFilter.clients =
                client && client.length ? this._toDropdownItems([client[0]]) : this._toDropdownItems([clients[0]]);
        }
    }

    trackByFn(index: number, item: any) {
        return index;
    }
}
