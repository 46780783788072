

import { Component, ViewEncapsulation } from '@angular/core';
import { NewFeaturesConsumerService } from '#services/_newFeatureConsumer/newFeatureConsumer.service';

@Component({
    selector: 'new-features',
    templateUrl: './new-features.component.html',
    styleUrls: ['./new-features.component.scss'],
    // providers: [MessagesCrud],
    encapsulation: ViewEncapsulation.None,
})
export class NewFeaturesComponent {

    constructor(public newFeaturesConsumerService: NewFeaturesConsumerService) { }

    trackByFn(index: number, item: any) {
        return index;
    }
}
