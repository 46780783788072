import { FORMATTER_TITLES } from "../constants/titles.constant";
import { _applyPosFormatters } from "./pos-formatters/pos-formatters";
import { textFormatter } from "./text.formatter";

export function iconFormatter(row, cell, value, columnDef, dataContext) {
    if (!value) {
        return columnDef?.formatterData?.emptyMessage ? columnDef.formatterData.emptyMessage :
        textFormatter(row, cell, value, columnDef, dataContext);
    }

    const iconName =
        (columnDef.formatterData &&
            columnDef.formatterData.icons &&
            columnDef.formatterData.icons[value.toLowerCase()]) ||
        '';

    const iconColor =
        (columnDef.formatterData &&
            columnDef.formatterData.colors &&
            columnDef.formatterData.colors[value.toLowerCase()]) ||
        '#1d8ab3';

    const contentValue = `<i class="material-icons" style="font-size: 1.7em; color: ${iconColor}" title="
        ${FORMATTER_TITLES.icon[value.toLowerCase()]}">${iconName}</i>`;
    return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
}