import moment from 'moment';
import { AppModule } from 'app/app.module';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IOperationalConsolidationFilter } from './interface/operational-consolidation.interface';

export class OperationalConsolidationFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialOperationalConsolidationFilter: IOperationalConsolidationFilter = <IOperationalConsolidationFilter>{};
    private _operationalConsolidationFilter: IOperationalConsolidationFilter = <IOperationalConsolidationFilter>{
        clientGroups: [],
        costCenters: [],
    };

    private _startDate: string;
    private _endDate: string;

    constructor(filter?: OperationalConsolidationFilter) {
        this._operationalConsolidationFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'operationalConsolidationFilter', this._operationalConsolidationFilter, filter);
        this._initialOperationalConsolidationFilter = _.cloneDeep(this._operationalConsolidationFilter);
        this._startDate = moment().format('01/MM/YYYY');
        this._endDate = moment().format('DD/MM/YYYY');
    }

    public getStartDate(): string { return this._startDate; }

    public setStartDate(startDate): void { this._startDate = startDate; }

    public getEndDate(): string { return this._endDate; }

    public setEndDate(endDate): void { this._endDate = endDate; }

    getClientGroups(): string[] {
        return this._operationalConsolidationFilter.clientGroups;
    }

    getProfitCenters(): string[] {
        return this._operationalConsolidationFilter.costCenters;
    }

    setClientGroups(clientGroups): void {
        this._operationalConsolidationFilter.clientGroups = clientGroups;
    }

    setProfitCenters(costCenters): void {
        this._operationalConsolidationFilter.costCenters = costCenters;
    }

    updatePreferences() {
        this._initialOperationalConsolidationFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialOperationalConsolidationFilter, this._operationalConsolidationFilter, 'operationalConsolidationFilter', this._userCacheService.getUserPreferences().id);
    }

}
