export const DEFAULT_SELECTION_OPTIONS = [
    {
        code: 'all',
        description: 'Todos',
    },
    {
        code: 'none',
        description: 'Nenhum',
    },
];
