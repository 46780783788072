<div *ngIf="asyncDetailCosts === true">
    <loader></loader>
</div>

<div class="titleLine">
    <div class="titleContainer">
        <i class="flaticon-coins"></i>
        <span style="margin-left: 5px">Custos Detalhados {{ categoryName }}</span>
        <span style="margin: 0 10px">|</span>
        <div class="paramState">
            <span class="paramItem">
                <i class="material-icons">insert_invitation</i>
                <span>{{ costService.currentParams?.startDate }} à</span>
                <span style="margin-left: 2px">{{ costService.currentParams?.endDate }}</span>
            </span>
            <span *ngIf="currentUser?.has('INTERNAL_FILTERS')" class="paramItem">
                <span style="margin: 0 10px">|</span>
                <i class="material-icons">people</i>
                <span *ngIf="costService.currentParams?.clients.length === 0">Todos Clientes</span>
                <span *ngIf="costService.currentParams?.clients.length === 1">{{
                    costService.currentParams.clients[0].itemName
                }}</span>
                <span *ngIf="costService.currentParams?.clients.length > 1"
                    >{{ costService.currentParams?.clients.length }} Clientes</span
                >
            </span>
            <span
                *ngIf="currentUser?.has('INTERNAL_FILTERS') && automotivefilterMode === 'Desligado'"
                class="paramItem"
            >
                <span style="margin: 0 10px">|</span>
                <i class="material-icons">gps_not_fixed</i>
                <span *ngIf="costService.currentParams?.crs.length === 0">Todos CRs</span>
                <span *ngIf="costService.currentParams?.crs.length === 1">{{
                    costService.currentParams.crs[0].itemName
                }}</span>
                <span *ngIf="costService.currentParams?.crs.length > 1"
                    >{{ costService.currentParams?.crs.length }} Clientes</span
                >
            </span>
            <span *ngIf="currentUser?.has('AUTOMOTIVE_USER') || automotivefilterMode === 'Ligado'" class="paramItem">
                <span style="margin: 0 10px">|</span>
                <i class="material-icons">directions_car</i>
                <span *ngIf="costService.currentParams?.models.length === 0">Todos Modelos</span>
                <span *ngIf="costService.currentParams?.models.length === 1">{{
                    costService.currentParams.models[0].itemName
                }}</span>
                <span *ngIf="costService.currentParams?.models.length > 1"
                    >{{ costService.currentParams?.models.length }} Modelos</span
                >
            </span>
            <span *ngIf="currentUser?.has('AUTOMOTIVE_USER') || automotivefilterMode === 'Ligado'" class="paramItem">
                <span style="margin: 0 10px">|</span>
                <i class="material-icons">colorize</i>
                <span *ngIf="costService.currentParams?.colors.length === 0">Todas Cores</span>
                <span *ngIf="costService.currentParams?.colors.length === 1">{{
                    costService.currentParams.colors[0].itemName
                }}</span>
                <span *ngIf="costService.currentParams?.colors.length > 1"
                    >{{ costService.currentParams?.colors.length }} Cores</span
                >
            </span>

            <span *ngIf="automotivefilterMode === 'Desligado'">
                <span style="margin: 0 10px">|</span>
                <span>Por</span>
            </span>

            <div style="margin-left: 15px;">
                <cmx-dropdown
                    *ngIf="automotivefilterMode === 'Desligado'"
                    name="imp"
                    [styles]="{ width: '100px' }"
                    [settings]="dropdownSettingsImp"
                    [data]="checkDisabled(options.imp)"
                    [ngModel]="selectedDropdownType"
                    (ngModelChange)="onDropdownTypeChange($event)"
                ></cmx-dropdown>
            </div>

            <cmx-filter-switch
                size="sm"
                style="margin-left: 10px"
                [values]="ucSwitch.values"
                [inputIndex]="ucSwitch.values.indexOf(filterState.getShowUc() === true ? 'com U.C' : 'sem U.C' )"
                (onSwitch)="toggleUcSwitch($event)"
            ></cmx-filter-switch>

            <ng2-completer
                [dataService]="completerColumnsData"
                [minSearchLength]="2"
                [placeholder]="'Localizar Coluna'"
                [textSearching]="'Carregando...'"
                [textNoResults]="'Coluna não encontrada'"
                [overrideSuggested]="true"
                [clearUnselected]="true"
                [(ngModel)]="completerSelectedValue"
                [disableInput]="asyncDetailCosts"
                (selected)="selectColumn($event)"
            ></ng2-completer>
        </div>
    </div>
</div>

<hr />

<cmx-custom-grid
    id="detail-costs-grid"
    [style.visibility]="rows?.length && !asyncDetailCosts ? 'visible' : 'hidden'"
    [loading]="asyncDetailCosts"
    [columns]="columns"
    [rows]="rows"
    [options]="gridOptions"
    [styles]="{ height: 'calc(100vh - 230px)' }"
    (onCellClick)="onCellClick($event)"
></cmx-custom-grid>

<cmx-slick-tabs-modal
    *ngIf="detailDinamicDataOpen"
    id="project-pricing-slick-modal"
    [tabs]="detailCostsTabs"
    [dinamicTabs]="true"
    [contentPadding]="'50px 20px 20px 20px'"
    [hidden]="!selectedGrid"
    (onDinamicClick)="onDinamicClick($event)"
    (onClose)="closeSlickModal()">
</cmx-slick-tabs-modal>

<cmx-custom-dialog
    [id]="'detail-costs-custom-dialog'"
    [title]="'IMP ' + selectedImp + ' • ' + selectedModalData?.title"
    [internalFade]="false"
    [closeButton]="true"
    [overlay]="true"
    [widthModal]="widthModal"
    (modal-closed)="closeModal()">

    <ng-container cmx-custom-dialog-body>
        <loader *ngIf="asyncModal"></loader>
        <div class="modal-container" *ngIf="gridModalMode && !asyncModal">
            <cmx-custom-grid
                id="detail-costs-modal-grid"
                [style.visibility]="modalRows?.length ? 'visible' : 'hidden'"
                [columns]="selectedModalData?.columns"
                [rows]="modalRows"
                [options]="{ forceFitColumns: true, defaultColumnWidth: 100 }"
                [styles]="{ margin: 0, height: 'calc(100vh - 195px)' }"
            ></cmx-custom-grid>
        </div>
        <div class="modal-container" *ngIf="!gridModalMode && !asyncModal">
            <div *ngFor="let uc of modalRows; let i = index; trackBy: trackByFn" class="valueContainer">
                <div class="skuContainer">
                    <span class="sku">{{ uc?.skuCode }}</span>
                    <span class="value">{{ uc?.skuCostValue | currencyType }}</span>
                </div>
                <div class="ucContainer">
                    <span class="uc">x{{ uc?.unitsSold }} U.C</span>
                    <span class="value">{{ uc?.skuCostValueByUnit | currencyType }}</span>
                </div>
                <hr />
            </div>
        </div>
    </ng-container>

</cmx-custom-dialog>

<div class="paginationContent" *ngIf="pages.length > 1">
    <pagination [numberOfPages]="pages.length" [queryId]="queryId" (onPageChange)="changePage($event)"></pagination>
    <div *ngIf="!asyncDetailCosts" id="totalChassis">
        <img *ngIf="automotivefilterMode === 'Ligado'" width="30px" alt=""
            src="/assets/app/media/img/icons/carModel-checked.png"
        />
        <span *ngIf="automotivefilterMode === 'Ligado'" class="title">Total de Chassis:</span>
        <em *ngIf="automotivefilterMode === 'Desligado'" class="flaticon-transport"
            width="35px" style="color: #215aa0"></em>
        <span *ngIf="automotivefilterMode === 'Desligado'" class="title"
            >Total de {{ costService.currentParams?.context === 'item' ? 'Itens de NF' : 
            costService.currentParams?.context === 'sku' ? 'SKUs' : 'IMPs' }}:</span
        >
        <span class="value">{{ totalItems }}</span>
    </div>
</div>

<ul id="contextMenu">
    <i class="fa fa-close pointer" (click)="closeContextMenu()"></i>
    <b style="cursor: default">Download de {{ selectedImp }}:</b>
    <div class="list">
        <li *ngFor="let archive of downloadArchives; trackBy: trackByFn" (click)="export('xls', archive)">{{
            archive
        }}</li>
    </div>
    <span class="all" (click)="export('zip', 'all')">Baixar Todos</span>
</ul>
