import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '#environment';
import moment from 'moment';
import { Observable } from 'rxjs';

@Injectable()
export class AdmDomainClientGroupCrud {

    constructor(
        private http: HttpClient,
    ) {}

    public getAllClientGroup() {
        return this.http.get(`${environment.endpoints.domainService}/client-groups`);
    }

    // Create client
    public createClient(selectedDomain, selectedDropdown): Observable<HttpResponse<any>> {
        return this.http.post(
            `${environment.endpoints.domainService}/clients`,
            this.prepareData('client', selectedDomain, selectedDropdown),
            { observe: 'response', responseType: 'json' },
        );
    }
    // Edit client
    public editClient(selectedDomain, selectedDropdown): Observable<HttpResponse<any>> {
        return this.http.put(`${environment.endpoints.domainService}/clients/${selectedDomain.code}`
        , this.prepareData('client', selectedDomain, selectedDropdown),
        { observe: 'response', responseType: 'json' });
    }
    
    // Delete client
    public deleteClient(selectedDomain): Observable<HttpResponse<any>> {
        return this.http.delete(
            `${environment.endpoints.domainService}/clients/${selectedDomain.code}`,
            { observe: 'response', responseType: 'text' },
        );
    }

    // Delete client
    public loadImage(url): Observable<any> {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Accep', 'Accept:application/json');
        return this.http.get(url, {  responseType: 'blob' });
    }

    // Create group
    public createGroup(selectedDomain, selectedDropdown, logo, loginBackground): Observable<HttpResponse<any>> {
        const request = new FormData();
        if (logo) request.append('logo', logo, logo.name);
        if (loginBackground) request.append('login_background', loginBackground, loginBackground.name);

        request.append('clientGroup',
            new Blob([JSON.stringify(this.prepareData('createGroup', selectedDomain, selectedDropdown))], {
                type: 'application/json',
            })
        );

        return this.http.post(
            `${environment.endpoints.domainService}/client-groups/v2`, request, { observe: 'response', responseType: 'json' },
        );
    }

    // Update group
    public updateGroup(groupCode, selectedDomain, selectedDropdown, logo, loginBackground): Observable<HttpResponse<any>> {
        const request = new FormData();
        if (logo) request.append('logo', logo, logo.name);
        if (loginBackground) request.append('login_background', loginBackground, loginBackground.name);

        request.append('clientGroup',
            new Blob([JSON.stringify(this.prepareData('createGroup', selectedDomain, selectedDropdown))], { //request and request use the same payload
                type: 'application/json',
            })
        );

        return this.http.put(
            `${environment.endpoints.domainService}/client-groups/v2/${groupCode}`, request, { observe: 'response', responseType: 'json' },
        );
    }
    
    

    // Edit group
    public editGroup(selectedDomain, selectedDropdown): Observable<HttpResponse<any>> {
        return this.http.put(`${environment.endpoints.domainService}/clientGroupTrader`
        , this.prepareData('editGroup', selectedDomain, selectedDropdown),
        { observe: 'response', responseType: 'json' });
    }
    // Delete group
    public deleteGroup(selectedDomain): Observable<HttpResponse<any>> {
        return this.http.delete(
            `${environment.endpoints.domainService}/client-groups/${selectedDomain.code}`,
            { observe: 'response', responseType: 'text' },
        );
    }

    // Get Leasing Imps
    public getLeasingImps(params) {
        return this.http.get(
            `${environment.endpoints.resultsService}/imps`, { params },
        );
    }
    // Get Leasing Imps
    public editLeaseHolder(data) {
        return this.http.put(
            `${environment.endpoints.resultsService}/update-analytical-transaction-by-lease-holder`, data);
    }

    private prepareData(domain, selectedDomain, selectedDropdown) {
        let data;
        if (domain === 'client') {
            let cleanDocNumber;
            if (selectedDomain.documentNumber !== '' && selectedDomain.documentNumber !== null
            && selectedDomain.documentNumber !== undefined) {
                cleanDocNumber = selectedDomain.documentNumber.replace(/\D/g, '');
            }
            data = {
                code: selectedDomain.code,
                name: selectedDomain.name,
                documentNumber: cleanDocNumber,
                leasing: selectedDomain.leasing,
                clientGroup: {
                    code: selectedDropdown.id,
                    name: selectedDropdown.itemName,
                },
            };
        } else if (domain === 'editGroup') {
            data = {
                clientGroup: {
                    code: selectedDomain.code,
                    name: selectedDomain.name,
                    domain: selectedDomain.domain,
                },
            };
        } else {
            data = {
                code: selectedDomain.code,
                name: selectedDomain.name,
                domain: selectedDomain.domain,
            };
        }
        return data;
    }

}
