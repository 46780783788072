import { Grouping } from '#services/_results/results.service';

export const groupingOptions = [
    { name: "Por Empresa", value: Grouping.byCompany },
    { name: "Por Período", value: Grouping.byMonth },
    { name: "Por CR", value: Grouping.byCr, beta: true },
    { name: "Por Diretor", value: Grouping.byDirector, beta: true },
    { name: "Por Cliente", value: Grouping.byClient, beta: true },
    { name: "Por Trader", value: Grouping.byTrader, beta: true },
    { name: "Por IMP", value: Grouping.byImp },
]

export function translateGrouping(value: string) {
    switch (value) {
        case "byCompany": return "Por Empresa";
        case "byMonth": return "Por Período";
        case "byCr": return "Por CR";
        case "byCrGrouped": return "Por CR Grouped";
        case "byDirector": return "Por Diretor";
        case "byClient": return "Por Cliente";
        case "byTrader": return "Por Trader";
        case "byImp": return "Por IMP";
    }
};
