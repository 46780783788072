import { PREMISE_FORM_SCRIPT } from "./premise-form-script.constant";
import { PROJECT_PRICING_FORM_SCRIPT } from "./project-pricing-script.constant";
import { TAX_STUDY_FORM_SCRIPT } from "./tax-study-form-script.constant";

export const FORM_SCRIPT = {
    // Estudo Tributário
    ...TAX_STUDY_FORM_SCRIPT,
    // Premissa Comercial
    ...PREMISE_FORM_SCRIPT,
    // Precificação
    ...PROJECT_PRICING_FORM_SCRIPT
}