export const SPECIFIC_USER_ROUTES = [
    {
        // Agente de Cargas
        permission: 'API_QUOTATION_CREATE',
        route: '/logistica/cotacao'
    },
    {
        // Devolução de Container
        permission: 'DELIVERY_CONTAINER_RETURN',
        route: '/devolucao-container'
    },
    {
        // Solicitações de Pagamentos • Manuais
        permission: 'SUPPLY_ENGINE_MANUAL',
        route: '/integracoes/fornecedores/pagamentos/manual'
    },
];
