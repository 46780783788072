import { environment } from '#environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';

import { DownloadModalService } from '#services/_download/download-modal.service';
import { DownloadService } from '#services/_download/download.service';
import { UserService } from 'app/auth/_services';
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';

@Component({
    selector: 'chat-ai',
    templateUrl: './chat-ai.component.html',
    styleUrls: ['./chat-ai.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ChatAIComponent {

    enabledChatAI: boolean = false;
    collapseTimeout: any;
    expanded: boolean = false;
    currentUser;

    chatAiPreferences: any = {
        rememberClose: false
    }
    asyncEditPhone = true;
    errorMessage = '';

    notification = false;
    phoneIntegrationData: any = null;
    whatsappUrl;
    otpInterval = null;
    showOtp: boolean = false;


    phoneNumber: any = null;
    updatePhoneNumber: boolean = false;
    formattedPhoneNumber: any = null;

    constructor(
        private userService: UserService,
        private _featureFlagService: FeatureFlagService,
        private _userPreferencesService: UserPreferencesService,
        private _userCache: UserCacheService,
        private http: HttpClient,
       
    ) {
        this.currentUser = this.userService.getCurrentUser();
    }

    ngOnInit() {
        this._fetchUserPermission();
        this.getPhoneIntegrationData();
    }
    
    ngOnDestroy() {
        clearInterval(this.otpInterval);
    }

    startOtpCodePolling() {
        this.otpInterval = setInterval(() => this.getPhoneIntegrationData(), 60000);
    }

    expand(): void {
        if (this.collapseTimeout) {
            clearTimeout(this.collapseTimeout);
            this.collapseTimeout = null;
        }
        this.expanded = true;
    }
    
    collapse(): void {
        this.collapseTimeout = setTimeout(() => {
            this.close();
        }, 30_000);
    }

    editPhoneOnKeydown(event) {
        if (event.key === 'Enter') {
            this.savePhoneIntegration();
        }
    }

    showEditPhoneOnKeydown() {
        this.updatePhoneNumber = true;
        this.phoneNumber = this.phoneIntegrationData.phoneNumber;
    }
    cancelPhoneOnKeydown() {
        this.updatePhoneNumber = false;
        this.errorMessage = '';
    }
    
    savePhoneIntegration() {
        this.errorMessage = '';
        if ( this.updatePhoneNumber && this.phoneIntegrationData.phoneNumber === this.phoneNumber) {
            this.cancelPhoneOnKeydown();
            return;
        }
        
        if (this._verifyPhoneNumber(this.phoneNumber) === false) {
            this.errorMessage = 'Número de telefone inválido';
            this.phoneNumber = this.updatePhoneNumber ? this.phoneIntegrationData.phoneNumber : null;
            return;
        }
        if (this.updatePhoneNumber) {
            this._updatePhoneIntegration();
        } else {
            this._createPhoneIntegration();
        }
    }

    close(): void {
        if (this.chatAiPreferences.rememberClose) {
            this.saveUserPreferences();
            setTimeout(() => {
                this.enabledChatAI = false;
            }, 200);
        }
        this.expanded = false;
    }

    saveUserPreferences() {
        this.chatAiPreferences = this._userPreferencesService.verifyFilterChanges(
            {}, this.chatAiPreferences, 'chatAi', this._userCache.getUserPreferences().id);
    }

    getPhoneIntegrationData() {
        const uid = this.userService.getCurrentUser().getUid();
        this.http.get(`${environment.endpoints.adminService}/api/v1/phone-integration/user/${uid}`).subscribe(
            (data: any) => {
                this.phoneIntegrationData = data;
                this.phoneNumber = data.phoneNumber;
                this.cancelPhoneOnKeydown();
                const match = data.phoneNumber.match(/^(\d{2})(\d{5})(\d{4})$/);
                this.formattedPhoneNumber = `(${match[1]}) ${match[2]}-${match[3]}`;
                this.whatsappUrl = this._generateWhatsappUrl(data.otpCode);
                if (data.otpCode === null && this.otpInterval !== null) {
                    clearInterval(this.otpInterval);
                } else if (data.otpCode !== null && this.otpInterval === null) {
                    this.startOtpCodePolling();
                }
            }
        );
    }

    private _generateWhatsappUrl(otpCode: string | null): string {
        const baseUrl = environment.chatAI.whatsappBaseUrl;
        const name = environment.chatAI.name;
        const defaultMessage = `Olá ${name}!`;
        const messageWithOtp = `Olá ${name}, meu código de acesso é #${otpCode} !`;
    
        const message = otpCode === null ? defaultMessage : messageWithOtp;
        const encodedMessage = encodeURIComponent(message);
    
        return `${baseUrl}?text=${encodedMessage}`;
    }

    

    private _fetchUserPermission() {
        if (this.currentUser.has('API_ADMIN_PHONE_INTEGRATION')) {
            this.getFeatureFlag();
        }
    }

    async getFeatureFlag(): Promise<void> {
        let enabledChatAI = await this._featureFlagService.isFeatureFlagEnabledV2('profile_enable_ai');
        if (enabledChatAI) {
            this.chatAiPreferences = this._userPreferencesService.defineFilter(this._userCache.getUserPreferences(), 'chatAi', this.chatAiPreferences, null);
            enabledChatAI = !this.chatAiPreferences.rememberClose;  
        }
        this.enabledChatAI = enabledChatAI;      
        setTimeout(() => {
            this.notification = true    
        }, 2000);
        
    }

    private _verifyPhoneNumber(number) {
        return number !== null && number.length === 11;
    }

    private _updatePhoneIntegration() {
        this.asyncEditPhone = false;
        const uid = this.userService.getCurrentUser().getUid();
        this.http.put(`${environment.endpoints.adminService}/api/v1/phone-integration/user/${uid}/${this.phoneNumber}`, '').subscribe(
            (data: any) => {
                this.asyncEditPhone = true;
                this.getPhoneIntegrationData();
            },
            (error) => {
                this.asyncEditPhone = true;
                this.errorMessage = 'Não foi possível atualizar o número de telefone';
            }
        );
    }

    private _createPhoneIntegration() {
        this.asyncEditPhone = false;
        const uid = this.userService.getCurrentUser().getUid();
        this.http.post(`${environment.endpoints.adminService}/api/v1/phone-integration/user/${uid}/${this.phoneNumber}`, '').subscribe(
            (data: any) => {
                this.asyncEditPhone = true;
                this.getPhoneIntegrationData();
                this.startOtpCodePolling();
            },
            (error) => {
                this.asyncEditPhone = true;
                this.errorMessage = 'Não foi possível adicionar o número de telefone';
            }
        );
    }


}
