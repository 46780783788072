import {throwError as observableThrowError,  Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '#environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ReportService {
  private msgErrorDefault = 'Não foi possível carregar os dados';
  constructor(private http: HttpClient) {}

  //Test endpoint with delay return use:
  //https://deelay.me/5000/ + url

  getToken(clientId: string, reportId: string): Observable<any>  {
    return this.http.get(`${environment.endpoints.reportService}/v1/token/${clientId}?reportId=${reportId}`);
  }
}
