import * as _ from 'lodash';

export class Utils {

    static getColors(baseColors: string[], scaleSideSwitch = false): string[] {
        if (_.isNil(baseColors) || !baseColors.length) {
            throw new Error('Must have [baseColors] to generate color scales.');
        }

        const colorScales = [];
        const scaleDefault = 3;

        let scale = scaleDefault;
        let baseColorIndexSwap = 0;
        let scaleSide = scaleSideSwitch ? -1 : 1;

        while (baseColorIndexSwap < baseColors.length) {
            const scaleOffset = scaleSide < 0 ? 5 : 30;
            const amount = scaleSide * scale * scaleOffset;

            colorScales.push(Utils.prototype._LightenDarkenColor(baseColors[baseColorIndexSwap], amount));

            scale = scale - 1;

            if (scale === 0) {
                if (scaleSide === 1) {
                    baseColorIndexSwap = baseColorIndexSwap + 1;
                }

                if (scaleSideSwitch) {
                    scaleSide = ~scaleSide + 1;
                }

                scale = scaleDefault;
            }
        }

        return _.union(baseColors, Utils.prototype._shuffle(colorScales));
    }

    private _shuffle(colors: string[]): string[] {
        let currentIndex = colors.length;
        let temporaryValue: string;
        let randomIndex: number;

        while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            temporaryValue = colors[currentIndex];
            colors[currentIndex] = colors[randomIndex];
            colors[randomIndex] = temporaryValue;
        }

        return colors;
    }

    private _LightenDarkenColor(color: string, amount: number): string {
        return `#${color
            .replace(/^#/, '')
            .replace(/../g, (color) =>
                ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
            )}`;
    }
}
