import { MenuControlService } from '#services/_menu-control/menu-control.service';
import { UtilsService } from '#services/_utils/utils.service';
import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'cmx-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss', './main-menu.theme.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MainMenuComponent implements OnInit {

    menu = [];
    currentPath: string = null;
    parentHighlight: string = null;
    itemHighlight: {
        name: string;
        route: string;
    } = null;

    @Input('has-actived-menu') hasActivedMenu: boolean = false;
    @Input('cmx-dark-theme') darkTheme: boolean = true;

    @ViewChild('cmxNavMainMenu') _elCmxNavMainMenu: ElementRef;

    constructor(
        public utilsService: UtilsService,
        private _menuControlService: MenuControlService,
        private _router: Router,
        private renderer: Renderer2
    ) { }

    ngOnInit():void {
        this._menuControlService.defineMenu().then((menu) => {
            this.menu = menu;
    
            this.setHighlightMenu();
            this._findCurrentRouteHasMenu(this.menu, this._router.url);
    
            this._router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((data: NavigationStart) => {
                this._findCurrentRouteHasMenu(this.menu, data.url);
            });
        });
    }

    get sessionHighlightMenuItem(){

        return JSON.parse(window.sessionStorage.getItem('highlightMenu'));
    }

    set sessionHighlightMenuItem(itemToSession){

        window.sessionStorage.setItem('highlightMenu', JSON.stringify(itemToSession));
    }

    removeSessionHighlight() {
        window.sessionStorage.removeItem('highlightMenu');
        this.parentHighlight = null;
        this.itemHighlight = null;
    }

    open(item, menu: HTMLElement, event: MouseEvent, parentName: string) {

        this.hasActivedMenu = true;

        this.sessionHighlightMenuItem = {
            groupName: parentName,
            children: {
                name: item.name,
                route: item.route
            }
        }

        this.setHighlightMenu();

        if(menu){
            this._unfreezeMenu(menu);
        }
        event.stopPropagation();
        if (item.route) {
            if (event.button > 0) {
                const routeWithParam = item.queryParam ? `?${item.queryParam}` : '';
                return window.open(item.route + routeWithParam);
            }

            const params = {};

            if(item.queryParam){
                for (const param of item.queryParam) {

                    const navigateWithParam = param.split('=');
                    params[navigateWithParam[0]] = navigateWithParam[1];
                }

                return this._router.navigate([item.route], {queryParams: params, queryParamsHandling: 'merge'});
            }

            this._router.navigate([item.route]);
        }
    }

    private setHighlightMenu(){

        if(window.sessionStorage.getItem('highlightMenu')){
            this.parentHighlight = this.sessionHighlightMenuItem.groupName;
            this.itemHighlight = {
                name: this.sessionHighlightMenuItem.children.name,
                route: this.sessionHighlightMenuItem.children.route
            }
        }
    }

    private _findCurrentRouteHasMenu(menuItems, currentRoute){
        let foundItem = false;
        for(const item of menuItems){
            if(item.route === currentRoute){
                foundItem = true;
                break;
            }
            if(item.children){
                for(const subitem of item.children){
                    if(subitem.route === currentRoute){
                        foundItem = true;
                        break;
                    }
                }
                if(foundItem){
                    break;
                }
            }
        }

        if(!foundItem){
            this.removeSessionHighlight();
        }
    }

    private _unfreezeMenu(menu: HTMLElement){

        this.renderer.setStyle(menu, 'display', 'none');
        setTimeout(() => {
            this.renderer.removeStyle(menu, 'display');
        }, 100);
    }

}
