import { Component, ViewEncapsulation } from '@angular/core';
import moment from 'moment';
import { UtilsService } from '#services/_utils/utils.service';
import { TopRegistrationService } from '../top-registration.service';
import { TopLiRegistrationFilter } from './top-li-registration.filter';
import { FilterStateService } from '#services/_filters/filter-state.service';

@Component({
    selector: 'top-li-registration',
    templateUrl: './top-li-registration.component.html',
    styleUrls: [ '../../../charts/charts.scss', './top-li-registration.component.scss' ],
    encapsulation: ViewEncapsulation.None,
})

export class TopLIRegistrationComponent {

    filterState: TopLiRegistrationFilter = this._filterStateService.getTopLiRegistrationFilter();
    public subscription;
    public startDate = moment().format('MM/YYYY');
    public endDate = moment().format('MM/YYYY');
    public asyncRegistrationLI = true;
    public registrationLIData;
    public typeRegister = 'LI';
    helpDescription = this._defineHelpDescription();

    constructor(private topRegistrationService: TopRegistrationService,
                private utilsService: UtilsService,
                private _filterStateService: FilterStateService ) { }

    public ngAfterViewInit() {
        this.loadRegistration();
    }

    public loadRegistration() {
        if (this.subscription) { this.subscription.unsubscribe(); }
        this.asyncRegistrationLI = true;
        this.utilsService.setInvisible('top-li-registration', ['#graficoLI']);
        this.subscription = this.topRegistrationService.
        getRegistration(
            moment(this.filterState.getStartDate(), 'MM/YYYY').format('YYYY-MM'),
            moment(this.filterState.getEndDate(), 'MM/YYYY').format('YYYY-MM'),
            this.typeRegister,
            this.filterState.getClientGroups()
        ).subscribe((data) => {
            this.registrationLIData = data;
            this.asyncRegistrationLI = false;
            this.utilsService.setVisible('top-li-registration', ['#graficoLI']);
            this.loadChart();
        }, (error) => {
            this.asyncRegistrationLI = false;
        });
    }

    public loadChart() {
        const chart = AmCharts.makeChart('graficoLI', {
            type: 'serial',
            theme: 'light',
            categoryField: 'clientName',
            rotate: true,
            categoryAxis: {
                gridPosition: 'start',
                position: 'left',
            },
            trendLines: [],
            graphs: [
                {
                    fillColors: '#1f3d7a',
                    lineColor: '#1f3d7a',
                    balloonText: `${moment(this.registrationLIData.from, 'YYYY-MM')
                    .format('MM/YYYY')} - ${
                    moment(this.registrationLIData.to, 'YYYY-MM').format('MM/YYYY')
                    }: [[totalCurrentYear]] LI's <br> ${moment(this.registrationLIData.previousFrom, 'YYYY-MM')
                    .format('MM/YYYY')} - ${
                    moment(this.registrationLIData.previousTo, 'YYYY-MM').format('MM/YYYY')
                    }: [[totalLastYear]] LI's`,
                    fillAlphas: 0.8,
                    id: 'AmGraph-1',
                    lineAlpha: 0.2,
                    title: 'Registros',
                    type: 'column',
                    valueField: 'totalCurrentYear',
                },
                {
                    fillColors: '#7194da',
                    lineColor: '#7194da',
                    balloonText: `${moment(this.registrationLIData.from, 'YYYY-MM')
                    .format('MM/YYYY')} - ${
                    moment(this.registrationLIData.to, 'YYYY-MM').format('MM/YYYY')
                    }: [[totalCurrentYear]] LI's <br> ${moment(this.registrationLIData.previousFrom, 'YYYY-MM')
                    .format('MM/YYYY')} - ${
                    moment(this.registrationLIData.previousTo, 'YYYY-MM').format('MM/YYYY')
                    }: [[totalLastYear]] LI's`,
                    fillAlphas: 0.8,
                    id: 'AmGraph-2',
                    lineAlpha: 0.2,
                    title: 'Registros',
                    type: 'column',
                    valueField: 'totalLastYear',
                },
            ],
            guides: [],
            valueAxes: [
                {
                    id: 'ValueAxis-1',
                    position: 'top',
                    axisAlpha: 0,
                },
            ],
            allLabels: [],
            balloon: {},
            titles: [],
             dataProvider: this.registrationLIData.data,
            export: {
                enabled: true,
            },

        });

        chart.addListener('drawn', () => {
            $('a[title*=JavaScript]').attr(
                'style',
                'display: none; opacity: 0;',
            );
            $('g[aria-label*=Meta]').attr(
                'style',
                'display: none; opacity: 0;',
            );
        });
        $('a[title*=JavaScript]').attr(
            'style',
            'display: none; opacity: 0;',
        );
        $('g[aria-label*=Meta]').attr(
            'style',
            'display: none; opacity: 0;',
        );
    }

    datesChanged(event) {
        this.filterState.setStartDate(event.startDate);
        this.filterState.setEndDate(event.endDate);
    }

    public changeDateCustom() {
        $('top-li-registration .checkmark').removeClass('checked');
        $('top-li-registration .checkmark.custom').removeClass('hidden');
        this.loadRegistration();
    }

    private _defineHelpDescription(): object[] {
        return [
            { type: 'text',hasLineAfter: true, value: "Informa os 10 clientes com maior número de LI’s registradas no período selecionado."},
            { type: 'text', value: "Compara a quantidade de LI’s registradas do período selecionado com o ano anterior."},
        ]
    }

}
