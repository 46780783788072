import { UtilsService } from '#services/_utils/utils.service';
import { Component, ElementRef, HostListener, Renderer2, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'new-traffic-map',
    template: `
        <ng-container *ngIf="setNavigationType === 'vessels'">
            <newTraffic-vessels-map (changeNavigationType)="handleNavigation($event)"></newTraffic-vessels-map>
        </ng-container>
    `,
    encapsulation: ViewEncapsulation.None,
})
export class NewTrafficMapComponent {
    setNavigationType: string = 'vessels';

    @HostListener('click') onSetZIndex() {
        this.renderer2.setStyle(this._elementRef.nativeElement, 'z-index', this.utilsService.autoIncrementValue);
    }

    constructor(
        private utilsService: UtilsService,
        private _elementRef: ElementRef,
        private renderer2: Renderer2
    ) {}

    handleNavigation(event) {
        this.setNavigationType = event;
    }
}
