<div #slickPopover class="cmx-slick-popover" *ngIf="top !== 0 && left !== 0"
    [style.top.px]="!inversePopover ? topDistance - 18 : null"
    [style.bottom.px]="inversePopover ? topDistance + inverseHeightAdition : null"
    [style.left.px]="leftDistance"
    [style.maxWidth]="maxWidth"
    [class.inverse]="inversePopover">
    <h3 *ngIf="headerTitle">
        {{ headerTitle }}
    </h3>
    <ul class="slick-popover">
        <ng-container *ngFor="let data of dataRows">
            <li *ngIf="data.value" class="single-description slick-popover">
                <span *ngIf="data.title" class="title">{{ data.title }}</span>
                <span class="value">{{ data.value }}</span>
            </li>
            <li *ngFor="let list of data.list" [class.list-item]="data.list">
                <span>&bull;</span> {{ list }}
            </li>
        </ng-container>
    </ul>
</div>
