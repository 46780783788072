import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError,  Observable, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '#environment';
import { UtilsService } from '#services/_utils/utils.service';
import { QuotationStatusType } from 'app/theme/pages/quotation/types/quotation.types';
import { IFinishQuotation, IImprovementDemand, IQuotationRevalidate } from './interfaces/international-quotation.interface';

@Injectable()
export class InternationalQuotationService {
  private msgErrorDefault = 'Não foi possível carregar os dados';
  private data$: BehaviorSubject<Object> = new BehaviorSubject(null);
  private _quotationModelId;

    constructor(
        private http: HttpClient,
        private _utilsService: UtilsService,
    ) {}

    set saveFilters(data) {
        this.data$.next(data);
    }

    get filters(): Observable<any> {
        return this.data$.asObservable();
    }

  getQuotationEditable(status: QuotationStatusType) {
    const isNotAllowed = ['CANCELLED', 'FINALIZED'];
    return isNotAllowed.includes(status);
  }

  getCountriesByModal(modal: string): Observable<any> {
    return this.http.get(`${environment.endpoints.quotationService}/locations/${modal.toUpperCase()}/countries`).pipe(catchError(err => {
        return observableThrowError({
            status: err.status,
            message: 'Erro ao carregar lista de paises.'
        });
    }));
  }

  getLocationsByModalCountryCode(modal: string, countryCode: string): Observable<any> {
    return this.http.get(`${environment.endpoints.quotationService}/locations/${modal.toUpperCase()}/${countryCode.toUpperCase()}`).pipe(catchError(err => {
        return observableThrowError({
            status: err.status,
            message: 'Erro ao carregar locais.'
        });
    }));
  }

  getDestination(): Observable<any> {
    return this.http.get(`${environment.endpoints.quotationService}/destination`).pipe(catchError((err) => {
      return observableThrowError({
        status: err.status,
        message: 'Erro ao carregar os destinos',
      });
    }));
  }

  getEquipment(): Observable<any> {
    return this.http.get(`${environment.endpoints.quotationService}/equipment`).pipe(catchError((err) => {
      return observableThrowError({
        status: err.status,
        message: 'Erro ao carregar os equipamentos',
      });
    }));
  }

  getEquipmentByType(type: string): Observable<any> {
    return this.http.get(`${environment.endpoints.quotationService}/equipment/type/${type}`).pipe(catchError((err) => {
      return observableThrowError({
        status: err.status,
        message: 'Erro ao carregar os equipamentos',
      });
    }));
  }

  getFreightForwarders(): Observable<any> {
    return this.http.get(`${environment.endpoints.quotationService}/freightforwarder`).pipe(catchError((err) => {
      return observableThrowError({
        status: err.status,
        message: this.msgErrorDefault,
      });
    }));
  }

  getCostCenter(): Observable<any> {
    return this.http.get(`${environment.endpoints.domainService}/profitCenter`).pipe(catchError((err) => {
      return observableThrowError({
        status: err.status,
        message: 'Erro ao carregar a lista de centro de custos',
      });
    }));
  }

  getClientGroups(): Observable<any> {
    return this.http.get(`${environment.endpoints.domainService}/client-groups`).pipe(catchError((err) => {
      return observableThrowError({
        status: err.status,
        message: this.msgErrorDefault,
      });
    }));
  }

  createQuotation(data): Observable<any> {
    return this.http.post(`${environment.endpoints.quotationService}/quotation`, data).pipe(catchError((err) => {
      return observableThrowError({
        status: err.status,
        message: err?.error?.message || err?.error || err,
      });
    }));
  }

  updateReturnQuotationDate(id, returnQuotationDate): Observable<any> {
    return this.http.put(`${environment.endpoints.quotationService}/quotation/returnQuotationDate/${id}?returnQuotationDate=${returnQuotationDate}`, null).pipe(catchError((err) => {
      return observableThrowError({
        status: err.status,
        message: err?.error?.message || err?.error || err,
      });
    }));
  }

  getQuotationByAgent(filterId: string): Observable<any> {
    return this.http.post(`${environment.endpoints.quotationService}/quotation/agent`, filterId).pipe(catchError((err) => {
      return observableThrowError({
        status: err.status,
        message: err.status === 404 ? 'Cotação não encontrada' : 'Erro ao carregar os dados',
      });
    }));
  }

  getQuotationsByFilter(request): Observable<any> {
    return this.http.get(`${environment.endpoints.quotationService}/quotation/filter`, request);
  }

  getUsers(): Observable<any> {
    return this.http.get(`${environment.endpoints.quotationService}/quotation/users`).pipe(catchError((err) => {
      return observableThrowError({
        status: err.status,
        message: err.status === 404 ? 'Nenhum resultado encontrado' : 'Erro ao carregar as usuários',
      });
    }));
  }
  getTaxes(modal: string): Observable<any> {
    return this.http.get(`${environment.endpoints.quotationService}/tax/${modal}`).pipe(catchError((err) => {
      return observableThrowError({
        status: err.status,
        message: 'Erro ao carregar as taxas de pagamento.',
      });
    }));
  }

  getRate(): Observable<any> {
    return this.http.get(`${environment.endpoints.quotationService}/rate/`).pipe(catchError((err) => {
      return observableThrowError({
        status: err.status,
        message: 'Erro ao carregar as taxas de pagamento.',
      });
    }));
  }

  sumPriceQuotation(data): Observable<any> {
    return this.http
      .post(`${environment.endpoints.quotationService}/quotation/sum-price-quotation/`, data).pipe(
      catchError((err) => {
        return observableThrowError({
          status: err.status,
          message: 'Não foi possível realizar os cálculos da Proposta, tente novamente.',
        });
      }));
  }

  updateProposalsByQuotationId(idQuotation, proposals): Observable<any> {
    return this.http
      .put(`${environment.endpoints.quotationService}/quotation/${idQuotation}`, proposals, {responseType: 'text'}).pipe(
      catchError((err) => {
        return observableThrowError({
          status: err.status,
          message: err?.error?.message || err?.error || err,
        });
      }));
  }

  getQuotationById(idQuotation): Observable<any> {
    return this.http.get(`${environment.endpoints.quotationService}/quotation/${idQuotation}`).pipe(catchError((err) => {
      return observableThrowError({
        status: err.status,
        message: 'Erro ao carregar dados da proposta',
      });
    }));
  }

  deleteProposalQuotation(idQuotation, proposalId) {
    return this.http
      .delete(`${environment.endpoints.quotationService}/quotation/${idQuotation}/${proposalId}`)
      .pipe(catchError((err) => {
        return observableThrowError({
          status: err.status,
          message: err?.error?.message || err?.error || err,
        });
      }));
  }

  cancelQuotation(idQuotation) {
    return this.http
      .delete(`${environment.endpoints.quotationService}/quotation/${idQuotation}`)
      .pipe(catchError((err) => {
        return observableThrowError({
          status: err.status,
          message: err?.error?.message || err?.error || err,
        });
      }));
  }

  declineQuotation(idQuotation: String, data) {
    return this.http
      .post(`${environment.endpoints.quotationService}/quotation/decline/${idQuotation}`, data)
      .pipe(catchError((err) => {
        return observableThrowError({
          status: err.status,
          message: err?.error?.message || err?.error || err,
        });
      }));
  }

  revalidateQuotation(quotationId:string, quotationRevalidate: IQuotationRevalidate){
    return this.http.patch(`${environment.endpoints.quotationService}/quotation/revalidation/${quotationId}`, quotationRevalidate).pipe(catchError((err) => {
        return observableThrowError({
          status: err.status,
          message: err?.error?.message || err?.error || err,
        });
      }));
  }

  finishQuotation(quotationId:string, finishQuotation: IFinishQuotation){
    return this.http.patch(`${environment.endpoints.quotationService}/quotation/finish/${quotationId}`, finishQuotation).pipe(catchError((err) => {
        return observableThrowError({
          status: err.status,
          message: err?.error?.message || err?.error || err,
        });
      }));
  }

  approveProposal(quotationId:string, proposalId:string, improvementDemand: IImprovementDemand){
    return this.http.patch(`${environment.endpoints.quotationService}/quotation/${quotationId}/${proposalId}`, improvementDemand).pipe(catchError((err) => {
        return observableThrowError({
          status: err.status,
          message: err.error[0]?.message,
        });
      }));
  }

  improvementReply(quotationId:string, proposalId:string, requestData: any){
    return this.http.patch(`${environment.endpoints.quotationService}/quotation/improvement/${quotationId}/${proposalId}`, requestData).pipe(catchError((err) => {
      return observableThrowError({
        status: err.status,
        message: err?.error?.message || err?.error || err,
      });
    }));
  }

  getQuotationByIdOrderReply(idQuotation, orderReply: any): Observable<any> {
    let url = `${environment.endpoints.quotationService}/quotation/${idQuotation}?orderReply=${orderReply}`

    if (orderReply == null) {
      url = `${environment.endpoints.quotationService}/quotation/${idQuotation}`
    }

    return this.http
      .get(url)
      .pipe(catchError((err) => {
        return observableThrowError({
          status: err.status,
          message: 'Erro ao carregar dados da proposta',
        });
      }));
  }

  getQuotations(): Observable<any> {
    return this.http.get(`${environment.endpoints.quotationService}/quotation`).pipe(catchError((err) => {
      return observableThrowError({
        status: err.status,
        message: 'Erro ao carregar dados da proposta',
      });
    }));
  }

  createProposalsByQuotationId(idQuotation, proposals): Observable<any> {
    return this.http
      .put(`${environment.endpoints.quotationService}/quotation/${idQuotation}`, proposals).pipe(
      catchError((err) => {
        return observableThrowError({
          status: err.status,
          message: 'Erro ao enviar os dados da proposta',
        });
      }));
  }

  exportDocument(relativePath, documentAlias): Observable<HttpResponse<any>> {
    return this.http.get(
      `${environment.endpoints.quotationService}/storage?name=${relativePath}&aliasName=${documentAlias}`,
      { observe: 'response', responseType: 'blob' }
    );
  }

  sendFileToStorage(file) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');

    return this.http.post(`${environment.endpoints.quotationService}/storage`, file, { headers, responseType: 'text' });
  }

  sendMultipleFilesToStorage(files) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');

    return this.http.post(`${environment.endpoints.quotationService}/storage/upload-multiple-files`, files);
  }

  exportSpreadsheet(quotationId: string): Observable<HttpResponse<any>> {
    let url = `${environment.endpoints.quotationService}/export/spreadsheet/${quotationId}`;

    return this.http.get(url, { responseType: 'blob', observe: 'response' });
  }

  updateQuotationFreightForwarder(quotationId, freightForwarders): Observable<any> {
    return this.http.patch(`${environment.endpoints.quotationService}/quotation/${quotationId}/update-freight-forwarder`, freightForwarders).pipe(catchError((err) => {
      return observableThrowError({
        status: err.status,
        message: err?.error?.message || err?.error || err,
      });
    }));
  }

  getCountries(modal): Observable<any> {
    return this.http.get(`${environment.endpoints.quotationService}/locations/${modal}/countries`).pipe(catchError((err) => {
      return observableThrowError({
        status: err.status,
        message: 'Erro ao carregar a lista de países',
      });
    }));
  }

  getLocations(modal, countryCode): Observable<any> {
    return this.http.get(`${environment.endpoints.quotationService}/locations/${modal}/${countryCode}`).pipe(catchError((err) => {
      return observableThrowError({
        status: err.status,
        message: 'Erro ao carregar a lista de embarque/desembarque',
      });
    }));
  }

  setDraftsProposals(idQuotation, proposals, taxes, departures):void {

    proposals.proposal = proposals.proposal.map((p, index) => {
      return {
        ...p,
        taxes: taxes[index],
        departures: departures[index]
      }
    });

    const saveDrafts = btoa(JSON.stringify(proposals));
    if(window.localStorage.getItem(`draft-${idQuotation}`)){
      window.localStorage.removeItem(`draft-${idQuotation}`);
    }

    window.localStorage.setItem(`draft-${idQuotation}`, saveDrafts);
  }

  getDraftsProposals(idQuotation) {

    const getDrafts = window.localStorage.getItem(`draft-${idQuotation}`) || null;
    if(getDrafts){
      return JSON.parse(atob(getDrafts));
    }
    return null;
  }

  cancelDrafts(idQuotation){

    if(window.localStorage.getItem(`draft-${idQuotation}`)){
      window.localStorage.removeItem(`draft-${idQuotation}`);
    }
  }

    getQuotationModelId() {
        return this._quotationModelId;
    }

    setQuotationModelId(quotationModelId): void {
        this._quotationModelId = quotationModelId;
    }
}
