import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { MarkerFilterError } from './marker-filter-error';

import { IMarkerFilter } from './interfaces/marker-filter.interface';

import { MarkerFilterAlignments, MarkerFilterTypes, ReturnBasedOnParameters } from './types/marker-filter.type';

import { MARKER_FILTER_TYPES } from './constants/marker-filter-types.const';
import { UtilsService } from '#services/_utils/utils.service';

@Component({
    selector: 'cmx-marker-filter',
    templateUrl: './marker-filter.component.html',
    styleUrls: ['./marker-filter.component.scss'],
})
export class MarkerFilterComponent implements OnChanges {
    @Input() filters: IMarkerFilter[];
    @Input() alignment: MarkerFilterAlignments = 'default';
    @Input() multiple: boolean = true;
    @Input() hasMarker: boolean = false;
    @Input() selectedFilters: string[] = [];
    @Input() preSelected?: string | string[];
    @Input() type?: MarkerFilterTypes;
    @Input() returnBasedOn?: ReturnBasedOnParameters;
    @Input() hasSelectAll?: boolean = true;
    @Input() blocked?: boolean = false;
    @Input() televisionMode?: boolean = false;
    @Input() leftDistance: number = 20;
    @Input() rightDistance: number = 150;
    @Input() arrowsPosition: number = 45;
    @Input() preventMaxWidth: boolean = false;
    @Input() smallSize: boolean = false;

    @Output() onSelected: EventEmitter<{
        firstChange: boolean;
        selected: IMarkerFilter[] | string[];
    }> = new EventEmitter();

    markerFilterId = this._utilsService.generateGUID('marker-filter');
    maxWidth: number;
    surpassWidth: boolean = false;
    defaultColor = '#14509b';

    constructor(private _utilsService: UtilsService) { }

    ngOnChanges(changes: SimpleChanges): void {
        this._validate();
        this.filters = this._getFilters();
        setTimeout(() => {
            this.maxWidth = this.preventMaxWidth ? 999999 : this.defineMaxWidthFilters();
        }, 500);

        if (changes && changes.preSelected) {
            this.selectedFilters = this._getSelectedFiltersDefault();
        }

        if (changes && changes.selectedFilters) {
            if (Array.isArray(changes.selectedFilters.currentValue)) {
                this.selectedFilters = [];
                changes.selectedFilters.currentValue.forEach((status: IMarkerFilter) => {
                    this.selectedFilters.push(status.code);
                });
            }
        }

        if (changes && changes.filters && changes.filters.firstChange) {
            this._emitOnSelectedFilters(true);
        }
    }

    identify(_, item: IMarkerFilter): string {
        return item.code;
    }

    defineMaxWidthFilters(): number {
        const container = $(`#${this.markerFilterId}`);
        const containerWidth = $(window).width() - this.leftDistance - this.rightDistance;
        this.surpassWidth = container.innerWidth() >= containerWidth ? true : false;
        return containerWidth;
    }

    selectFilter(filter: IMarkerFilter): void {
        if (!this.blocked) {
            const filterCode = filter.code;
            if (this.multiple) {
                if (this.selectedFilters.indexOf(filterCode) !== -1) {
                    this.selectedFilters.splice(this.selectedFilters.indexOf(filterCode), 1);
                } else {
                    this.selectedFilters.push(filterCode);
                }
            } else {
                if (this.type === 'tab') {
                    this._selectTabFilter(filter);
                } else {
                    this.selectedFilters = [filterCode];
                }
            }

            this._emitOnSelectedFilters();
        }
    }

    selectAllFilters(): void {
        if (!this.blocked) {
            if (this.selectedFilters.length === this.filters.length) {
                this.selectedFilters = [];
            } else {
                this.selectedFilters = this.filters?.map((filter: IMarkerFilter) => filter.code);
            }

            this._emitOnSelectedFilters();
        }
    }

    scrollContainer(direction: string, element) {
        element.preventDefault();
        const container = $(`#${this.markerFilterId}`);
        const halfWidth = container.width() / 2;
        container.animate({ scrollLeft: container.scrollLeft() + (direction === 'left' ? -halfWidth : halfWidth) }, 1000);
    }

    private _getFilters(): IMarkerFilter[] {
        if (!this.type) {
            return this.filters;
        }
        return this.filters.reduce((accumulator: IMarkerFilter[], currentValue: IMarkerFilter) => {
            const filter = {
                ...currentValue,
                ...MARKER_FILTER_TYPES[this.type][currentValue.code],
            };
            return [...accumulator, filter];
        }, []);
    }

    private _getSelectedFiltersDefault(): string[] {
        if (this.preSelected) {
            return typeof this.preSelected === 'string' ? [this.preSelected] : this.preSelected;
        }
        if (this.multiple) {
            return this.filters?.map((filter: IMarkerFilter) => filter.code);
        }
        return [this.filters[0].code];
    }

    private _getSelectedFilters(): IMarkerFilter[] | string[] {
        const selectedFilters = this.filters.filter(
            (filter: IMarkerFilter) => this.selectedFilters.indexOf(filter.code) !== -1
        );

        if (!this.returnBasedOn) {
            return selectedFilters?.map(({ code, name }) => ({ code, name }));
        }

        return selectedFilters?.map((filter: IMarkerFilter) => filter[this.returnBasedOn]);
    }

    private _emitOnSelectedFilters(firstChange = false): void {
        const selectedFilters = this._getSelectedFilters();

        this.onSelected.emit({
            firstChange,
            selected: selectedFilters,
        });
    }

    private _validate(): void {
        if (!this.filters || !this.filters.length) {
            throw new MarkerFilterError('MarkerFilter must have [filters] to render!');
        }

        if (!this.multiple && this.preSelected && this.preSelected.length > 1 && this.type !== 'tab') {
            throw new MarkerFilterError(
                'It cannot set [preSelected] more than one item, because the config [multiple] is false!'
            );
        }

        if (this.type && Object.keys(MARKER_FILTER_TYPES).indexOf(this.type) === -1) {
            throw new MarkerFilterError('[type] must be a valid marker type to render!');
        }
    }

    private _selectTabFilter(selectedFilter: IMarkerFilter) {
        const filterCode = selectedFilter.code;
        const isChildOfCode = selectedFilter.father;

        if (!isChildOfCode) {
            this.selectedFilters = [filterCode];
            this._selectAllTabChildren(filterCode);
        }

        if (isChildOfCode) {
            // Adiciona ou remove o filho selecionado
            if (this.selectedFilters.indexOf(filterCode) !== -1) {
                this.selectedFilters.splice(this.selectedFilters.indexOf(filterCode), 1);
            } else {
                this.selectedFilters.push(filterCode);
            }

            // Adiciona o pai caso não tenha
            if (this.selectedFilters.indexOf(isChildOfCode) === -1) {
                this.selectedFilters.push(isChildOfCode);
            }

            // Remove outros pais e filhos
            this.selectedFilters.forEach((selectedCode) => {
                this.filters.forEach((baseFilter) => {
                    if (baseFilter.code === selectedCode) {
                        if (baseFilter.father !== isChildOfCode && baseFilter.code !== isChildOfCode) {
                            this.selectedFilters.splice(this.selectedFilters.indexOf(selectedCode), 1);
                        }
                    }
                });
            });
        }
    }

    private _selectAllTabChildren(filterCode) {
        this.filters.forEach((filter) => {
            if (filter.father === filterCode) {
                if (this.selectedFilters.indexOf(filter.code) === -1) {
                    this.selectedFilters.push(filter.code);
                }
            }
        });
    }

}
