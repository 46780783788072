import { FeatureType } from './featureType';

export class Feature {

    public static blank() {
        return new Feature();
    }

    public id: string;
    public link: string;
    public title: string;
    public type: FeatureType;
    public description: string;
    public fileName: string;
    public base64Image: string;
    public deleteImage: Boolean;
    public permissions: string[];

    constructor(source?: Feature) {
        if (!source) {
            this.id = '';
            this.link = '';
            this.title = 'Nova Mudança';
            this.type = new FeatureType();
            this.description = '';
            this.fileName = '';
            this.base64Image = '';
            this.deleteImage = false;
            this.permissions = null;
        } else {
            this.id = source.id || '';
            this.link = source.link || '';
            this.title = source.title || '';
            this.type = source.type || new FeatureType();
            this.description = source.description || '';
            this.fileName = source.fileName;
            this.base64Image = source.base64Image;
            this.deleteImage = false;
            this.permissions = source.permissions || null;
        }
    }

    public getId(): string { return this.id; }
    public getLink(): string { return this.link; }
    public getTitle(): string { return this.title; }
    public getFeatureType(): FeatureType { return this.type; }
    public getDescription(): string { return this.description; }
    public getFileName(): string { return this.fileName; }
    public getBase64Image(): string { return this.base64Image; }
    public getDeleteImage(): Boolean { return this.deleteImage; }
    public getPermissions(): string[] { return this.permissions; }

    public setId(id): void { this.id = id; }
    public setLink(link): void { this.link = link; }
    public setTitle(title): void { this.title = title; }
    public setFeatureType(type): void { this.type = type; }
    public setDescription(description): void { this.description = description; }
    public setFileName(fileName): void { this.fileName = fileName; }
    public setBase64Image(base64Image): void { this.base64Image = base64Image; }
    public setDeleteImage(deleteImage): void { this.deleteImage = deleteImage; }
    public setPermissions(permissions): void { this.permissions = permissions; }
}
