import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import moment from 'moment';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { ICostDistribuitionFilter } from './interface/cost-distribuition.interface';

export class CostDistribuitionFilter {
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialCostDistribuitionFilter: ICostDistribuitionFilter = <ICostDistribuitionFilter>{};
    private _costDistribuitionFilter: ICostDistribuitionFilter = <ICostDistribuitionFilter>{
        clientGroups: [],
        searchType: 'IMP',
        searchValue:'',
    };

    private startDate;
    private endDate;

    constructor(filter?: CostDistribuitionFilter) {
        this._costDistribuitionFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'costDistribuitionFilter', this._costDistribuitionFilter, filter);
        this._initialCostDistribuitionFilter = _.cloneDeep(this._costDistribuitionFilter);
        this.startDate = filter ? filter.startDate : moment().add(-12, 'M').format('DD/MM/YYYY');
        this.endDate = filter ? filter.endDate : moment().format('DD/MM/YYYY');
    }

    getClientGroups(): string[] {
        return this._costDistribuitionFilter.clientGroups;
    }
    getSearchType(){
        return this._costDistribuitionFilter.searchType;
    }
    getSearchValue() {
        return this._costDistribuitionFilter.searchValue;
    }
    getStartDate() {
        return this.startDate;
    }
    getEndDate() {
        return this.endDate;
    }

    setClientGroups(clientGroups): void {
        this._costDistribuitionFilter.clientGroups = clientGroups;
    }
    setSearchType(searchType): void {
        this._costDistribuitionFilter.searchType = searchType;
    }
    setSearchValue(searchValue): void {
        this._costDistribuitionFilter.searchValue = searchValue;
    }
    setStartDate(startDate): void {
        this.startDate = startDate;
    }
    setEndDate(endDate): void {
        this.endDate = endDate;
    }

    getRequest() {
        this._initialCostDistribuitionFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialCostDistribuitionFilter, this._costDistribuitionFilter, 'costDistribuitionFilter', this._userCacheService.getUserPreferences().id);

        let params = new HttpParams();
        let headers = new HttpHeaders();
        headers = headers.set('X-Requested-Client-Groups', this.getClientGroups().map((c) => c['id']).join(','));
        let startDateFormat = moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD');
        let endDateFormat = moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD');
        params = params.append('startDate', startDateFormat);
        params = params.append('endDate', endDateFormat);
        if (this.getSearchType() === 'IMP') params = params.append('imp', this.getSearchValue());
        if (this.getSearchType() === 'REF') params = params.append('clientReference', this.getSearchValue());
        if (this.getSearchType() === 'SKU') params = params.append('sku', this.getSearchValue());
        return { headers, params };
    }
}
