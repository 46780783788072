import { Component, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { data } from './data';

@Component({
    selector: ".m-grid__item.m-grid__item--fluid.m-wrapper",
    templateUrl: "./kpi.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class KpiComponent implements AfterViewInit {

    statuses = {
        0: { desc: "Atrasado", label: "danger" },
        1: { desc: "No prazo", label: "success" }
    };

    constructor() {

    }

    ngAfterViewInit() {
        $("#kpis").mDatatable({
            // datasource definition
            data: {
                type: 'local',
                source: data,
                pageSize: 10
            },

            // layout definition
            layout: {
                theme: 'default', // datatable theme
                class: '', // custom wrapper class
                scroll: false, // enable/disable datatable scroll both horizontal and vertical when needed.
                height: 450, // datatable's body's fixed height
                footer: false // display/hide footer
            },

            // column sorting
            sortable: true,

            pagination: true,

            // columns definition
            columns: [{
                field: "imp",
                title: "Processo",
                width: 80,
                textAlign: 'center'
            }, {
                field: "kpi1",
                title: "Chegada x Deferimento LI",
                textAlign: 'center',
                template: this.formatKPIBadge("kpi1")
            }, {
                field: "kpi2",
                title: "Deferimento LI x Registro DI",
                textAlign: 'center',
                template: this.formatKPIBadge("kpi2")
            }, {
                field: "kpi3",
                title: "Presença Carga x Registro DI",
                textAlign: 'center',
                template: this.formatKPIBadge("kpi3")
            }, {
                field: "kpi4",
                title: "Faturamento x Entrega",
                textAlign: 'center',
                template: this.formatKPIBadge("kpi4")
            }]
        });
    }

    formatKPIBadge(kpi: string): Function {
        return function(row: any) {
            let label = row[kpi] == 1 ? 'success' : 'danger';
            let desc = row[kpi] == 1 ? 'No Prazo' : 'Atrasado';
            return `<span class="m-badge m-badge--${label} m-badge--wide">${desc}</span>`;
        }
    }
}
