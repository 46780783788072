<div *ngIf="spListingService.asyncData">
    <loader></loader>
</div>

<div *ngIf="!spListingService.asyncData" class="sp-details-container">
    <div class="sp-listing-data">
        <cmx-assembled-data [data]="spDetails" [maxRowElements]="4"></cmx-assembled-data>
    </div>
    <div class="action-buttons">
        <cmx-button variant="historic" (click)="handleHistory()">Histórico</cmx-button>
    </div>
</div>

<cmx-custom-dialog
    [id]="'history'"
    animation="right-left"
    [heightModal]="'calc(100vh - 92px)'"
    [maxWidthModal]="1024"
    [overlay]="true"
    width="340px"
>
    <ng-container cmx-custom-dialog-header>
        <div>
            <span> <i class="fa fa-file-text-o" aria-hidden="true"></i>&nbsp; Histórico de SP</span>
        </div>
    </ng-container>

    <ng-container cmx-custom-dialog-body>
        <ng-container>
            <div class="timeline">
                <div>
                    <h6>SP {{history?.paymentRequestNumber}}</h6>
                </div>
                <div class="row no-gutters" *ngFor="let item of history?.changes; let i = index">
                    <div class="item">
                        <p>{{ item.message }}</p>
                        <p class="dateUser">
                            {{ item.date | date: 'dd/MM/yyyy' }} às {{ item.date | date: 'HH:mm' }} por
                            {{ item.user }}</p>
                    </div>
                </div>
                <div *ngIf="!history">Não existe histórico para este processo</div>
            </div>
        </ng-container>
    </ng-container>
</cmx-custom-dialog>

<cmx-confirmation-modal
    question="Essa SP está vinculada a mais SPs. Confirma a seleção dessa Corretora?"
    labelAction="Vincular"
    [hidden]="!confirmationModal"
    (onConfirm)="changeSpDetails()"
    (onCancel)="confirmationModal = false"
></cmx-confirmation-modal>
