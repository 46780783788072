import { CountryFlagsService } from '#services/_country-flags/country-flags.service';
import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import moment from 'moment';
import { IAssembledData } from './interfaces/assembled-data.interface';
import { FORMATTERS } from './constants/formatters.constant';
import { UserService } from 'app/auth/_services';
import Inputmask from 'inputmask';

@Component({
    selector: 'cmx-assembled-data',
    templateUrl: './cmx-assembled-data.component.html',
    styleUrls: ['./cmx-assembled-data.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AssembledDataComponent {
    @Input() maxRowElements: number = 6;
    @Input() data: IAssembledData[] = [];
    @Input() noMargin: boolean = false;
    @Input() internalSideMargin: number = 20;

    @Output('onClick') onClick = new EventEmitter();
    @Output('onInputChanges') onInputChanges = new EventEmitter();

    elementWidth: string;
    private _currentUser = this._userService.getCurrentUser();
    private _inputListennerList = [];

    constructor(public countryFlagsService: CountryFlagsService, private _userService: UserService) {}

    ngOnInit() {
        this.elementWidth = !this.noMargin
            ? `calc(${100 / this.maxRowElements}% - ${this.internalSideMargin * 2}px)`
            : `${100 / this.maxRowElements}%`;
        this._validateSpecialData(this.data);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && !changes.data.firstChange) {
            this._validateSpecialData(this.data);
            setTimeout(() => {
                this._verifyFormatter();
            }, 500);
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this._verifyFormatter();
        }, 500);
    }

    ngOnDestroy() {
        this._inputListennerList.forEach((id) => {
            $(`#${id}`).off("input");
        })
    }

    verifyPermitedInfo(element) {
        if (element.permission !== undefined && element.permission !== null) {
            if (typeof element.permission === 'string') {
                return this._currentUser.has(element.permission);
            }

            return element.permission;
        } else {
            return true;
        }
    }

    handleClick(element) {
        this.onClick.emit(element);
    }

    getWidth(element) {
        return element.noBreakWord ? '100%' : ((element.formatter === 'input' || element.formatter === 'textarea') ? '80%' : '');
    }

    private _verifyFormatter() {
        this.data.forEach((data: IAssembledData) => {
            if (data.formatter) {
                const element = document.getElementById(`cmx-assembled-data-${data.title}`);
                element.innerHTML = FORMATTERS[data.formatter](data);
                if (data.formatter === 'input' || data.formatter === 'textarea') {
                    this._createInputListenner(data);
                }
            }
        });
    }

    private _createInputListenner(data) {
        const id = data.title.toLowerCase().replace(/\s/g, '_');
        this._inputListennerList.push(id);
        if (data.inputMask) { this._verifyInputMask(data.inputMask, id) }
        $(`#input-${id}`).on('keyup', () => {
            const newValue = $(`#input-${id}`).val();
            this.onInputChanges.emit({ ...data, value: newValue });
        });
    }

    private _verifyInputMask(mask, id) {
        switch (mask) {
            case 'date':
                Inputmask({ mask: '99/99/9999' }).mask($(`#input-${id}`));
                break;
            case 'currency':
                Inputmask('decimal', {
                    numericInput: true,
                    alias: 'numeric',
                    groupSeparator: '.',
                    autoGroup: true,
                    digits: '2',
                    radixPoint: ',',
                    digitsOptional: false,
                    allowMinus: false,
                    placeholder: '0,00',
                }).mask($(`#input-${id}`));
                break;
            case 'number':
                Inputmask({ regex: "[0-9]*" }).mask($(`#input-${id}`));
                break;
        }
    }

    private _validateSpecialData(dataList: IAssembledData[]) {
        const currencyRegex = /^\d+(?:\.\d{0,2})$/;
        dataList.forEach((data: IAssembledData) => {
            if (moment(data.value, 'YYYY-MM-DD', true).isValid()) {
                data.value = moment(data.value, 'YYYY-MM-DD').format('DD/MM/YYYY');
            }
            if (currencyRegex.test(data.value) || data.currency) {
                let currencyValue;
                currencyValue = Intl.NumberFormat(data.locale || 'pt-br', {
                    style: 'currency',
                    currency: !data.currency ? 'BRL' : data.currency,
                    minimumFractionDigits: 2,
                }).format(parseFloat(data.value));
                data.value = !data.currency ? currencyValue.substring(3) : currencyValue;
            }
        });
    }
}
