import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { ICrProcessesFilter } from './interface/cr-processes-table.interface';

export class CrProcessesFilter {
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialCrProcessesFilter: ICrProcessesFilter = <ICrProcessesFilter>{};
    private _crProcessesFilter: ICrProcessesFilter = <ICrProcessesFilter>{
        clientGroups: [],
        companies: [],
        typeSwitch: 'Todas',
        viewSwitch: 'Clientes',
        sortSwitch: 'Filhos',
        zeroValuesSwitch: 'Sim',
        currencySwitch: 'BRL',
        interCompanySwitch: false,
        valueSwitch: 'noCents'
    };

    constructor(filter?: CrProcessesFilter) {
        this._crProcessesFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'crProcessesFilter', this._crProcessesFilter, filter);
        this._initialCrProcessesFilter = _.cloneDeep(this._crProcessesFilter);
    }

    getClientGroups(): string[] {
        return this._crProcessesFilter.clientGroups;
    }
    getCompanies() {
        return this._crProcessesFilter.companies;
    }
    getTypeSwitch() {
        return this._crProcessesFilter.typeSwitch;
    }
    getViewSwitch() {
        return this._crProcessesFilter.viewSwitch;
    }
    getSortSwitch() {
        return this._crProcessesFilter.sortSwitch;
    }
    getZeroValuesSwitch() {
        return this._crProcessesFilter.zeroValuesSwitch;
    }
    getCurrencySwitch() {
        return this._crProcessesFilter.currencySwitch;
    }
    getInterCompanySwitch() {
        return this._crProcessesFilter.interCompanySwitch;
    }
    getValueSwitch() {
        return this._crProcessesFilter.valueSwitch;
    }
    setCompanies(companies): void {
        this._crProcessesFilter.companies = companies;
    }
    setTypeSwitch(typeSwitch): void {
        this._crProcessesFilter.typeSwitch = typeSwitch;
    }
    setViewSwitch(viewSwitch): void {
        this._crProcessesFilter.viewSwitch = viewSwitch;
    }
    setSortSwitch(sortSwitch): void {
        this._crProcessesFilter.sortSwitch = sortSwitch;
    }
    setZeroValuesSwitch(zeroValuesSwitch): void {
        this._crProcessesFilter.zeroValuesSwitch = zeroValuesSwitch;
    }
    setCurrencySwitch(currencySwitch): void {
        this._crProcessesFilter.currencySwitch = currencySwitch;
    }
    setInterCompanySwitch(interCompanySwitch): void {
        this._crProcessesFilter.interCompanySwitch = interCompanySwitch;
    }
    setValueSwitch(valueSwitch): void {
        this._crProcessesFilter.valueSwitch = valueSwitch;
    }

    getRequest(optionalProfitCenters, orderField, orderDirection) {

        this._initialCrProcessesFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialCrProcessesFilter, this._crProcessesFilter, 'crProcessesFilter', this._userCacheService.getUserPreferences().id);

        let params = new HttpParams();
        let headers = new HttpHeaders();

        headers = headers.set('X-Requested-Client-Groups', this.getClientGroups().map((c) => c['id']).join(','));

        params = params.append('optionalProfitCenters', `${optionalProfitCenters}`);
        params = params.append('intraCompanies', `${this.getInterCompanySwitch()}`);
        params = params.append('orderField', `${orderField}`);
        params = params.append('orderDirection', `${orderDirection}`);

        params = params.append('companyCodes', this.getCompanies().map((company) => company.id).join(','));
        params = params.append('zeroValues', `${this.getZeroValuesSwitch() === 'Sim' ? true : false}`)
        params = params.append('orderMaster', `${this.getSortSwitch() === 'Pais' ? true : false}`);
        params = params.append('orderChildren', `${this.getSortSwitch() === 'Filhos' ? true : false}`);
        params = params.append('exchangeCurrency', `${this.getTypeSwitch() === 'Internacionais' && this.getCurrencySwitch() === 'BRL' ? true : false}`);
        params = params.append('currency', `${this.getCurrencySwitch()}`);

        return { headers, params } ;
    }
}
