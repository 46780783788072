export class UsageEvent {
    constructor(public name: string, public action: string, public label: string,
                public value: number, public commonProperties: any = {},
                public domainProperties: any = {}) {
    }

    public equalsTo(e: UsageEvent): boolean {
        return e && e.name === this.name &&
            e.action === this.action &&
            e.label === this.label &&
            e.value === this.value;
    }
}
