import { AuthenticationService } from './_services/authentication.service';
import {
    Component,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppComponent } from '../app.component';
import { LoginService } from './_services/login.service';

@Component({
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    templateUrl: './logout-msg.component.html',
    styleUrls: ['./logout-msg.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LogoutMsgComponent implements OnInit {
    model: any = {};
    initialized = false;
    loading = false;
    msgLogout = '';
    asyncLogin = false;

    logoLogin = this.loginService.getLogo();
    backLogin = this.loginService.getBackground();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private loginService: LoginService,
        private authService$: AuthenticationService,
        private app: AppComponent,
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.model.errorCode = params.errorCode;
        });
        this.app.globalLoader = false;
        $('.m-page-loader.m-page-loader--non-block').attr('style', 'display: none');
        this.msgLogout = this.getErrorMsg(this.model.errorCode);
        this.initialized = true;
    }

    private getErrorMsg(code: string) {
        switch (code) {
            case 'UNAUTHORIZED':
                return 'Sua sessão foi encerrada devido à uma operação não autorizada'
            case 'INVALID_OR_EXPIRED_SESSION':
                return 'Sua sessão foi encerrada por inatividade'
            case 'LOCATION_NOT_ALLOWED':
                return 'Não é permitido o login a partir de sua localização atual'
            case 'TIME_NOT_ALLOWED':
                return 'Não é permitido o uso do sistema neste horário'
            case 'DOMAIN_NOT_ALLOWED':
                return `Não foi permitido acesso ao domínio ${window.location.hostname}`
            default:
                return 'Sua sessão foi encerrada'
        }
    }

    goToLogin() {
        
        this.authService$.logout();
    }

}
