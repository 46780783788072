import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';

export const LICENCE_COLUMNS: IGridColumn[] = [
    {
        field: 'liNumber',
        name: 'Licença',
        width: 50,
        formatterType: 'badge',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
    {
        field: 'dates',
        name: 'Data Registro da LI',
        width: 115,
        formatterType: 'date',
        formatterData: {
            singleField: 'issueDate',
            float: 'start'
        },
    },
    {
        field: 'dates',
        name: 'Data Situação da LI',
        width: 115,
        formatterType: 'date',
        formatterData: {
            singleField: 'statusUpdatedAt',
            float: 'start'
        },
    },
    {
        field: 'liStatus',
        name: 'Status',
        width: 115,
        headerCssClass: 'grid-header align-right',
        cssClass: 'grid-cell align-right',
    },
];
