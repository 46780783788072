import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { IGridColumn } from '../interfaces';

@Injectable()
export class CustomGridHeaderDropdownService {

    currentDropdownColumn: string;
    dropdownSearch: string = '';
    dropdownSelected = [];
    dropdownApplySubject: Subject<any> = new Subject<any>();

    defineDropdown(column: IGridColumn, index: number) {
        this.dropdownSelected = column.selectedDropdownOptions || [];
        const viewportGrid = document.querySelector('.slick-viewport').getBoundingClientRect();
        let options = '';
        column.dropdownOptions.forEach((option) => {
            const selected = this.dropdownSelected.filter(e => e.id === option.id).length ? 'selected' : '';
            options += `<div id="${option.id}" class="cmx-grid-dropdown-option ${selected}">${option.itemName}</div>`
        })
        const scrollList = (column.dropdownOptions.length * 35) >= viewportGrid.height ? true : false;
        return `<div id="cmx-grid-dropdown-${index}" class="cmx-grid-dropdown-filter" style="max-height: ${
        viewportGrid.height}px; width: 150px">
            <div class="cmx-grid-dropdown-filter-search">
                <span class="material-icons">search</span>
                <input id="${column.field}-dropdown-search" type="text" placeholder="Buscar" value="${column.filter || ''}">
            </div>
            <div class="cmx-grid-dropdown-filter-list" style="height: ${scrollList ? `calc(${viewportGrid.height}px - 65px)` : 'fit-content'}">${options}</div>
            <div class="cmx-grid-dropdown-filter-apply">Aplicar</div>
        </div>`
    }

    dropdownFilterSearch(index) {
        const searchInput = $(`#cmx-grid-dropdown-${index} .cmx-grid-dropdown-filter-search input`);
        const searchList = $(`#cmx-grid-dropdown-${index} .cmx-grid-dropdown-filter-list`);
        searchInput.on('keyup', () => {
            this.dropdownSearch = searchInput.val().toString();
            searchList.children().each((_index, child) => {
                if (child.innerHTML.toLowerCase().includes(this.dropdownSearch.toLowerCase())) {
                    child.style.display = 'block';
                } else {
                    child.style.display = 'none';
                }
            })
        })
    }

    dropdownSelection(index) {
        const dropdownOptions = $(`#cmx-grid-dropdown-${index} .cmx-grid-dropdown-filter-list .cmx-grid-dropdown-option`);
        dropdownOptions.click((event) => {
            if (event.target.classList.contains('selected')) {
                event.target.classList.remove('selected');
                this.dropdownSelected.splice(_.findIndex(this.dropdownSelected, { id: event.target.id }), 1);
            } else {
                event.target.classList.add('selected');
                this.dropdownSelected.push({ id: parseInt(event.target.id), itemName: event.target.innerHTML })
            }
        });
    }

    dropdownApply(column, index) {
        const dropdownApplyButton = $(`#cmx-grid-dropdown-${index} .cmx-grid-dropdown-filter-apply`);
        dropdownApplyButton.click(() => {
            this.currentDropdownColumn = column.field;
            this.dropdownApplySubject.next({ column, dropdownSelection: this.dropdownSelected });
        });
    }

    dropdownClear(column, index) {
        const dropdownClearButton = $(`.slick-column-name #${column.field} .cmx-grid-dropdown-clear`);
        dropdownClearButton.click(() => {
            this.currentDropdownColumn = column.field;
            this.dropdownSelected = []
            this.dropdownApplySubject.next({ column, dropdownSelection: [] });
        });
    }

}