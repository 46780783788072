import * as _ from 'lodash';

export class Client {

    public static blank() {
        return new Client('Novo Cliente', null, null, null, null, false);
    }

    public code: string;
    public name: string;
    public documentNumber: string;
    public documentNumberType: string;
    public clientGroup: Igroup;
    public leasing: boolean;

    constructor(name: string, code: string, clientGroup: Igroup,
                documentNumber: string, documentNumberType: string, leasing: boolean);
    constructor(source: {name: string, code: string, clientGroup?: Igroup, documentNumber?: string,
    documentNumberType?: string, leasing?: boolean});
    constructor(source: string | any, code?: string, clientGroup?: Igroup, documentNumber?: string) {
        if (typeof source === 'string') {
            this.name = source;
            this.code = null;
            this.documentNumber = null;
            this.documentNumberType = null,
            this.clientGroup = null;
            this.leasing = false;
        } else {
            this.name = source.name;
            this.code = source.code;
            this.documentNumber = source.documentNumber;
            this.documentNumberType = source.documentNumberType;
            this.clientGroup = source.clientGroup;
            this.leasing = source.leasing;
        }
    }

    public clone() {
        return new Client(this);
    }

    public equals(other: Client): boolean {
        this.documentNumber === null ? this.documentNumber = '' : this.documentNumber = this.documentNumber
        .replace(/\D/g, '');
        other.documentNumber === null ? other.documentNumber = '' : other.documentNumber = other.documentNumber
        .replace(/\D/g, '');
        this.leasing === null ? this.leasing = false : this.leasing = this.leasing;
        other.leasing === null ? other.leasing = false : other.leasing = other.leasing;
        if (other.clientGroup === null) {
            return true;
        }
        return this.name === other.name
        && this.code === other.code
        && this.documentNumber === other.documentNumber
        && this.clientGroup.code === other.clientGroup.code
        && this.leasing === other.leasing;
    }

}

interface Igroup {
    name: string;
    code: string;
}
