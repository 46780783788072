import { NgModule } from '@angular/core';

import { CrProcessesTablePageModule } from '../cr-processes-table-page/cr-processes-table-page.module';
import { EditProfileModule } from '../edit-profile/edit-profile.module';
import { InvoiceStatusModule } from '../invoice-status/invoice-status.module';
import { ImpFollowUpModule } from './imp-follow-up/imp-follow-up.module';
import { KpiModule } from './kpi/kpi.module';
import { SlaModule } from './sla/sla.module';

@NgModule({
    imports: [
        KpiModule,
        SlaModule,
        ImpFollowUpModule,
        EditProfileModule,
        InvoiceStatusModule,
        CrProcessesTablePageModule,
    ],
})
export class SupplyChainModule {

}
