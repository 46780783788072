import { environment } from '#environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Observable } from 'rxjs';
import { CompleterService, CompleterData, CompleterItem } from 'ng2-completer';
import { CostsService } from '.././costs.service';
import { UserService } from 'app/auth/_services';
import { UtilsService } from '#services/_utils/utils.service';
import { DownloadService } from '#services/_download/download.service';
import { FinanceExportService } from '#services/_finance-export/finance-export.service';
import { DETAIL_COSTS_CONTEXT, DETAIL_COSTS_MODAL } from '.././detail-costs/detail-costs-modal.constant';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';
import * as _ from 'lodash';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { CostsFilter } from '../costsFilter.model';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';

@Component({
    selector: 'detail-costs',
    templateUrl: './detail-costs.component.html',
    styleUrls: ['./detail-costs.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [],
})
export class DetailCostsComponent {
    moment = moment;
    subscription;
    editableElements = ['#DetailCosts', 'pagination'];
    asyncAnaliticCost = false;
    asyncModal = true;
    categoryName = '';
    offset = 0;
    limit = 30;
    pages = [];
    queryId = Math.random();
    asyncDetailCosts = false;
    currentUser;
    automotivefilterMode;
    totalItems;
    selectedImp;
    downloadArchives = [];
    metadataColumns: object = { };
    detailDinamicDataOpen: boolean = false;

    detailCostsTabs = [];
    selectedGrid: boolean = false;

    selectedModalData;
    gridModalMode: boolean = false;
    modalRows = [];
    widthModal: string = '400px';

    typeSwitch = {
        values: ['IMP', 'SKU'],
        index: 0,
    }
    ucSwitch = {
        values: ['com U.C', 'sem U.C'],
        index: 0,
    }

    dataView;
    previousGridRows = null;
    rows = null;
    completerColumnsData: CompleterData;
    completerSelectedValue: string;
    columns = null;
    filterState: CostsFilter = this._filterStateService.getCostsFilter();

    gridOptions = {
        defaultColumnWidth: 120,
        editable: false,
        enableCellNavigation: true,
        enableTextSelectionOnCells: true,
        rowHeight: 35,
        forceFitColumns: false,
        enableColumnReorder: true,
        useCategoryGrouping: true
    }

    options: {
        imp: DropdownOption[];
    } = {
        imp: [],
    };
    selectedDropdownType = []

    dropdownSettingsImp = {
        singleSelection: true,
        text: 'IMP',
        preventyEmpty: true,
    };

    private currentCategory;

    constructor(
        private http: HttpClient,
        private userService: UserService,
        private utilsService: UtilsService,
        private downloadService: DownloadService,
        private financeExportService: FinanceExportService,
        public costService: CostsService,
        public router: Router,
        private completerService: CompleterService,
        private _customDialogService: CustomDialogService,
        private _filterStateService: FilterStateService,
        private _cmxDropdownService: CmxDropdownService,
    ) { }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.userService.currentUser$.subscribe((user) => {
            this.currentUser = user;
            const itens = _.cloneDeep(DETAIL_COSTS_CONTEXT);
            this.options.imp = this._cmxDropdownService.toDropdownItems(itens);
            this.getDropdownContext(this.filterState.getContext())
        });

    }

    identify(index: number): number {
        return index;
    }

    changePage(pageNumber) {
        $('#DetailCosts .grid-canvas').addClass('hidden');
        this.offset = pageNumber - 1;
        this.loadAnaliticCost(this.currentCategory, this.costService.currentParams, this.automotivefilterMode);
    }

    onDropdownTypeChange(event) {
        this.costService.currentParams.context = event[0].id;
        this.filterState.setContext(event[0].id);
        this.getDropdownContext(event[0].id)
        this.loadAnaliticCost(this.currentCategory, this.costService.currentParams, this.automotivefilterMode);
        this.filterState.updatePreference();
        this.checkDisabled(event)
    }

    checkDisabled(dropdownOptions) {
        return dropdownOptions.map((option) => {
            return {
                ...option,
                disabled: this.filterState.getDropdownOptionsBlocked() && option.id === 'sku' ? true : false,
            }
        })
    }

    getDropdownContext(context) {
        this.selectedDropdownType = [this.options.imp.find((item) => item.id === context.toLowerCase())]
    }

    public toggleTypeSwitch(values) {
        const context = values.value === 'IMP' ? 'imp' : 'sku'
        this.costService.currentParams.context = context;
        this.filterState.setContext(context);
        this.loadAnaliticCost(this.currentCategory, this.costService.currentParams, this.automotivefilterMode);
        this.filterState.updatePreference();
    }

    public toggleUcSwitch(values) {
        this.costService.currentParams.showUc = values.value === 'com U.C' ? true : false;
        this.filterState.setShowUc(values.value === 'com U.C' ? true : false);
        this.loadAnaliticCost(this.currentCategory, this.costService.currentParams, this.automotivefilterMode);
        this.filterState.updatePreference();
    }

    selectColumn(selectedColumn: CompleterItem): void {
        if (selectedColumn && selectedColumn.originalObject) {
            const offset = $(`#${selectedColumn.originalObject.id}`).offset();
            const currentPosition = $('#detail-costs-grid .slick-viewport').scrollLeft();
            const grid = $('#detail-costs-grid .slick-viewport');
            grid.stop();
            grid.animate({
                scrollLeft: offset.left + currentPosition - 55,
            }, 1000 );
        }
    }

    loadAnaliticCost(category, params, automotivefilterMode, spotlight?) {
        this.utilsService.setInvisible('detail-costs', this.editableElements);
        this.asyncDetailCosts = true;
        this.currentCategory = category;
        this.automotivefilterMode = automotivefilterMode;
        this.costService.currentParams = params;
        this.categoryName = category === '' ? category : `(${category})`;
        this.asyncAnaliticCost = true;
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.completerSelectedValue = '';
        this.subscription = this.getAnaliticCost(category, params).subscribe((analiticCosts) => {
            this.totalItems = analiticCosts.totalElements;
            this.pages = Array.apply(null, { length: analiticCosts.totalPages }).map(Number.call, Number);
            this.asyncAnaliticCost = false;
            const contentData = analiticCosts.content[0];
            this.columns = this.createColumns(contentData && contentData.metadata, spotlight);
            this._defineTabGroups();
            this.completerColumnsData = this._getCompleterColumnsData(this.columns);
            this.rows = this.extractRows(contentData && contentData.results);
            this.utilsService.setVisible('detail-costs', this.editableElements);
            if (this.selectedDropdownType[0].id === 'sku' && this.filterState.getDropdownOptionsBlocked()) {
                this.selectedDropdownType = [this.options.imp[0]]
            }
            this.asyncDetailCosts = false;
        });
    }

    closeContextMenu(): void {
        $('#contextMenu').hide();
    }

    export(type: any, financeArchive?) {
        this.asyncDetailCosts = true;
        const options = this.downloadService.getSourceOptions();
        options.headers = new HttpHeaders({
            'X-Requested-Client-Groups': '',
            Accept: type === 'csv' ? 'text/csv' : type === 'xls' ? 'application/vnd.ms-excel' : 'application/zip',
        });
        if (financeArchive && financeArchive !== 'all') {
            this.financeExportService.exportArquive(financeArchive).subscribe(() => {
                this.asyncDetailCosts = false;
            });
        } else if (financeArchive === 'all') {
            this.financeExportService.exportZip(this.selectedImp).subscribe(() => {
                this.asyncDetailCosts = false;
            });
        }

        this.closeContextMenu();
    }

    onCellClick({ item, column, _multiButtom, element }): void {
        if (column === 'importationNumber') {
            const companyCode = item.companyCode ? item.companyCode.padStart(2, "0") : 0;
            window.open(`./supply-chain/importacao/${companyCode}/${item.importationNumber}`);
        }
        if (column === 'details') {
            this.detailDinamicDataOpen = true;
            this._defineDinamicData(item);
        }
        if (element[0].classList.contains('multiple')) {
            this._openMultipleModal(item, column);
        }
        if (column === 'download' && item.download) {
            this.selectedImp = this.automotivefilterMode === 'Desligado' ? item.importationNumber : item.chassisNumber;
            this.downloadArchives = item.download;
            $('#contextMenu').show();
        } else {
            this.closeContextMenu();
        }
    }

    onDinamicClick(event) {
        if (Array.isArray(event.value)) {
            this._openMultipleModal(this.rows[0], event.field)
        }
    }

    closeModal() {
        this.gridModalMode = false;
    }

    closeSlickModal() {
        this.detailDinamicDataOpen = false;
        this.rows = this.previousGridRows;
        this.selectedGrid = false;
    }

    private _defineTabGroups() {
        const groups = [...new Set(this.columns.map(column => column.groupName))].filter((e) => { return e != null && e != ''; });
        this.detailCostsTabs = groups.map((group) => { return { code: group, name: group, data: [] }; });
    }

    private _defineDinamicData(item) {
        this.detailCostsTabs.forEach((tab) => { tab.data = [] });
        this.columns.forEach((column) => {
            if (column.groupName) {
                const group = this.detailCostsTabs.find(group => group.code === column.groupName)
                group.data.push({
                    ...column,
                    title: column.toolTip, formatter: 'multiple',
                    value: typeof item[`${column.field}`] === 'number' ? `${
                        this.utilsService.formatNumber(item[column.field], { style: 'currency', currency: 'BRL' })
                    }` : item[`${column.field}`],
                });
            }
        })
        this._openSlickModal(item, this.detailCostsTabs[0])
    }

    private _openSlickModal(item, tab) {
        if (!this.selectedGrid) {
            this.selectedGrid = true;
            this.previousGridRows = this.rows;
            this.rows = [item];
        }
    }

    private _openMultipleModal(item, column) {
        this.asyncModal = true;
        this.selectedImp = this.automotivefilterMode === 'Desligado' ? item.importationNumber : item.chassisNumber;
            if (DETAIL_COSTS_MODAL.hasOwnProperty(column)) {
                this.widthModal = DETAIL_COSTS_MODAL[column]?.width;
                this.selectedModalData = _.cloneDeep(DETAIL_COSTS_MODAL[column]);
                this.modalRows = _.cloneDeep(item[DETAIL_COSTS_MODAL[column].data]) || [];
                this._customDialogService.open('detail-costs-custom-dialog');
                setTimeout(() => {
                    this.gridModalMode = true;
                    this.asyncModal = false;
                }, 100);
            }
            if (column.includes('column_')) {
                this.selectedModalData = { title: this.metadataColumns[column] };
                this.modalRows = _.cloneDeep(item[column]) || [];
                this.gridModalMode = false;
                this.asyncModal = false;
                this._customDialogService.open('detail-costs-custom-dialog');
            }
    }

    private _removeSlickModal() {
        this.selectedGrid = false;
    }

    private _getCompleterColumnsData(columns: any): any {
        return this.completerService.local(columns.map((column: any) => {
            const regex = /<div[^>]*>([^<]+)<\/div>/g;
            const match = regex.exec(column.name);
            return {
                id: `${column.id}-grid-column`,
                name: match && match[1],
            };
        }).slice(1), 'name', 'name');
    }

    private getAnaliticCost(category, currentParams): Observable<any> {
        let params = new HttpParams();
        let headers = new HttpHeaders();
        headers = headers.set('X-Requested-Client-Groups', currentParams.imp || currentParams.clientReference ?
            '' : currentParams.clients.map((c) => c.id).join(',') );
        params = params.append('imp', currentParams.imp);
        params = params.append('chassisNumbers', currentParams.chassi);
        params = params.append('startDate', moment(currentParams.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
        params = params.append('endDate', moment(currentParams.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
        params = params.append('profitCenters', currentParams.crs.map((c) => c.id).join(','));
        params = params.append('models', currentParams.models.map((c) => c.id).join(','));
        params = params.append('colors', currentParams.colors.map((c) => c.id).join(','));
        params = params.append('manufacturerYears', currentParams.manufacturerYears.map((c) => c.id).join(','));
        params = params.append('modelYears', currentParams.modelYears.map((c) => c.id).join(','));
        params = params.append('page', `${this.offset}`);
        params = params.append('size', '25');
        params = params.append('expensesCategory', encodeURIComponent(category));
        params = params.append(
            'context',
            this.automotivefilterMode === 'Ligado' ? 'automotive' : currentParams.context
        );
        params = params.append('clientReference', currentParams.clientReference);
        params = params.append('showUc', currentParams.showUc);
        return this.http.get(`${environment.endpoints.expensesService}/importationExpenses`, { params, headers });
    }

    private createColumns(categories, spotlight) {
        let index = 1;
        const cols = [];
        cols.push({
            id: 0,
            name: '<i class="flaticon-download" style="font-size:15px"></i>',
            width: 45,
            field: 'download',
            formatterType: 'round',
            formatterData: { roundType: 'download', needValue: true, emptyBall: true },
        });

        cols.push({
            id: 'details',
            name: '<i class="material-icons" style="font-size:19px">notes</i>',
            width: 45,
            field: 'details',
            formatterType: 'round',
            formatterData: { roundType: 'details', needValue: false, emptyBall: true },
        });

        if (categories && categories.length) {
            categories.forEach((columnGroup) => {
                columnGroup.columns.forEach((column) => {
                    index++;

                    this._verifyMetadataColumn(column);

                    cols.push({
                        id: index,
                        name: `<div id="${index}-grid-column">${this.formatColumnName(column.columnName)}</div>`,
                        width: 155,
                        field: column.columnId,
                        groupName: columnGroup.name === 'MAIN' ? '' : columnGroup.name,
                        formatterType: 'currency',
                        formatterData: { includeZero: true, float: 'right' },
                        cssClass: spotlight === column.columnName ? 'spotlightValue' : 'value',
                        toolTip: column.columnName,
                    });

                    if (column.columnName === 'IMP') {
                        cols[cols.length - 1].formatterType = 'badge';
                        cols[cols.length - 1].formatterData = { classes: 'pointer hoverable' };
                        cols[cols.length - 1].width = 120;
                    }

                    if (column.columnName === 'Chassi') {
                        cols[cols.length - 1].formatterType = 'badge';
                        cols[cols.length - 1].width = 195;
                    }

                    if (column.columnName === 'SKU') {
                        if (column.columnId === 'skuCustomer') {
                            cols[cols.length - 1].formatterType = 'badge';
                            cols[cols.length - 1].width = 195;
                        } else {
                            cols[cols.length - 1].formatterType = 'multiple';
                            cols[cols.length - 1].formatterData = { attributeValue: 'code' };
                            cols[cols.length - 1].width = 195;
                        }
                    }

                    if (column.columnName === 'Ref. Cliente') {
                        cols[cols.length - 1].formatterType = 'default';
                        cols[cols.length - 1].cssClass = 'align-left';
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.columnName === 'PO') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'purchaseOrderCode' };
                        cols[cols.length - 1].cssClass = 'align-left';
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.columnName === 'Fornecedor') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'vendorName' };
                        cols[cols.length - 1].cssClass = 'align-left';
                        cols[cols.length - 1].width = 250;
                    }

                    if (column.columnName === 'País de Origem') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'countryFrom' };
                        cols[cols.length - 1].cssClass = 'align-left';
                        cols[cols.length - 1].width = 250;
                    }

                    if (column.columnName === 'Descrição Produto') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'description' };
                        cols[cols.length - 1].cssClass = 'align-left';
                        cols[cols.length - 1].width = 400;
                    }

                    if (column.columnName === 'Quantidade') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'quantity' };
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.columnId === 'line') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'line' };
                        cols[cols.length - 1].width = 152;
                    }

                    if (column.columnId === 'family') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'family' };
                        cols[cols.length - 1].cssClass = 'align-left';
                        cols[cols.length - 1].width = 250;
                    }

                    if (column.columnId === 'brand') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'brand' };
                        cols[cols.length - 1].width = 152;
                    }

                    if (column.columnId === 'collection') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'collection' };
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.columnName === 'Unidade') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'unitMeasureDescription' };
                        cols[cols.length - 1].cssClass = 'align-left';
                        cols[cols.length - 1].width = 200;
                    }

                    if (column.columnName === 'Número DI') {
                        cols[cols.length - 1].formatterType = 'default';
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.columnName === 'Data DI') {
                        cols[cols.length - 1].formatterType = 'date';
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.columnName === 'Conhecimento Embarque') {
                        cols[cols.length - 1].formatterType = 'date';
                        cols[cols.length - 1].width = 225;
                    }

                    if (column.columnName === 'Incoterm') {
                        cols[cols.length - 1].formatterType = 'default';
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.columnName === 'NCM') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'ncmCode' };
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.columnName === 'Nota Fiscal' || column.columnName === 'Número Invoice') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'invoiceNumber' };
                        cols[cols.length - 1].width = 150;
                    }

                    if (column.columnName === 'Data Nota Fiscal' || column.columnName === 'Data Invoice') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { formatterType: 'date', attributeValue: 'invoiceDate' };
                        cols[cols.length - 1].width = 150;
                    }

                    if (column.columnName === 'CFOP') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'cfopCode' };
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.columnId === 'installmentCode') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'code' };
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.columnId === 'installmentDueDate') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { formatterType: 'date', attributeValue: 'dueDate' };
                        cols[cols.length - 1].width = 150;
                    }

                    if (column.columnId === 'installments') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { formatterType: 'date', attributeValue: 'dueDate' };
                        cols[cols.length - 1].width = 150;
                    }

                    if (column.columnName === 'Fator da Importação') {
                        cols[cols.length - 1].formatterType = 'default';
                        cols[cols.length - 1].width = 200;
                        cols[cols.length - 1].name =
                        `
                            <div id="${index}-grid-column">${this.formatColumnName(column.columnName)}</div>
                            <span id="tooltip-${column.field}" class='material-icons info' style="position: absolute; top: 6px; right: 10px; font-size: 20px; color: #345683;">info
                            <div class="informative">Base de Cálculo: Total da NF C/ IPI ÷ FOB</div><div class="arrow"></div></span>
                        `
                    }

                    if (column.columnName === 'Taxa Dolar') {
                        cols[cols.length - 1].formatterType = 'default';
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.columnName === 'F.O.B. Dolar' ||
                        column.columnName === 'Frete Dolar' ||
                        column.columnName === 'Seguro Dolar') {
                        cols[cols.length - 1].formatterType = 'currency';
                        cols[cols.length - 1].formatterData = {
                            includeZero: true, currency: 'USD',
                            locale: 'pt-BR', float: 'left'
                        };
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.columnName === 'Desconto ICMS') {
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.columnName === 'I.I. Nac') {
                        cols[cols.length - 1].formatterType = 'multiple',
                        cols[cols.length - 1].formatterData =  {
                            formatterType: 'percentage',
                            attributeValue: 'iiTaxPercentage'
                        },
                        cols[cols.length - 1].width = 100;
                    }

                    if (column.columnName === 'IPI Nac') {
                        cols[cols.length - 1].formatterType = 'multiple',
                        cols[cols.length - 1].formatterData =  {
                            formatterType: 'percentage',
                            attributeValue: 'ipiTaxPercentage'
                        },
                        cols[cols.length - 1].width = 100;
                    }

                    if (column.columnName === 'PIS Nac') {
                        cols[cols.length - 1].formatterType = 'multiple',
                        cols[cols.length - 1].formatterData =  {
                            formatterType: 'percentage',
                            attributeValue: 'pisTaxPercentage'
                        },
                        cols[cols.length - 1].width = 100;
                    }

                    if (column.columnName === 'COFINS Nac') {
                        cols[cols.length - 1].formatterType = 'multiple',
                        cols[cols.length - 1].formatterData =  {
                            formatterType: 'percentage',
                            attributeValue: 'cofinsTaxPercentage'
                        },
                        cols[cols.length - 1].width = 100;
                    }

                    if (column.columnName === 'ICMS Nac') {
                        cols[cols.length - 1].formatterType = 'multiple',
                        cols[cols.length - 1].formatterData =  {
                            formatterType: 'percentage',
                            attributeValue: 'icmsTaxPercentage'
                        },
                        cols[cols.length - 1].width = 100;
                    }
                });
            });
        }

        return cols;
    }

    private _verifyMetadataColumn(column) {
        if (column.columnId.includes('column_')) {
            this.metadataColumns[column.columnId] = column.columnName;
        }
    }

    private formatColumnName(name) {
        if (name.includes('(TROP)')) {
            const splitted = name.split('-');
            return splitted[splitted.length - 1].replace('(TROP)', '');
        } else {
            return name;
        }
    }

    private extractRows(data: any) {
        const rows = [];
        if (data && data.length) {
            data.forEach((element, i) => {
                const columns = Object.entries(element.columns);
                rows.push({
                    id: i,
                    download: element.expensesWorksheetFileNames,
                    chassisNumber: element.chassisNumber,
                    importationNumber: element.importationNumber,
                    companyCode: element.companyCode,
                    clientReferences: element.clientReference,
                    purchaseOrders: element.purchaseOrders,
                    vendors: element.vendors,
                    skuCustomer: element.skuCustomer,
                    productCode: element.products,
                    productDesc: element.products,
                    productQuantity: element.products,
                    quantity: element.products,
                    diNumber: element.diNumber,
                    diDate: element.diDate,
                    blDate: element.blDate,
                    incoterm: element.incoterm,
                    ncms: element.ncms,
                    additions: element.additions,
                    vendorInvoiceNumber: element.vendorInvoices,
                    vendorInvoiceDate: element.vendorInvoices,
                    clientInvoiceNumber: element.clientInvoices,
                    clientInvoiceDate: element.clientInvoices,
                    cfops: element.cfops,
                    installments: element.installments,
                    importationFactor: element.importationFactor ?
                        element.importationFactor.toFixed(2).toString().replace('.', ',') : '',
                    averageExchangeRate: element.averageExchangeRate ?
                        element.averageExchangeRate.toString().replace('.', ',') : '',
                    usFob: element.usFob,
                    usFreight: element.usFreight,
                    usInsurance: element.usInsurance,
                    discountIcms: element.discountIcms,
                    iiTaxPercentage: element.taxes,
                    pisTaxPercentage: element.taxes,
                    ipiTaxPercentage: element.taxes,
                    cofinsTaxPercentage: element.taxes,
                    icmsTaxPercentage: element.taxes,
                    unitMeasureDescription: element.products,
                    countryFrom: element.vendorInvoices,
                    installmentDueDate: element.installments,
                    installmentCode: element.installments,
                    line: element.products,
                    family: element.products,
                    brand: element.products,
                    collection: element.products,
                });
                columns.forEach((c) => {
                    if (c[1].hasOwnProperty('value')) {
                        let value = '';
                        if (c[1]['value'] === null) {
                            if (c[1].hasOwnProperty('skuList')) {
                                const skuList = c[1]['skuList'] || [];
                                value = skuList.length === 0 ? '' : skuList;
                            }
                        } else {
                            value = c[1]['value'];
                        }
                        rows[rows.length - 1][`${c[0]}`] = value;
                    }
                });
            });
        }
        return rows;
    }

    trackByFn(index: number, item: any) {
        return index;
    }
}
