import { DropdownSettings } from '../cmx-dropdown/cmx-dropdown.interface';
import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { DropdownOption } from 'bootstrap';
import { CmxDropdownService } from '../cmx-dropdown/cmx-dropdown.service';
import * as _ from 'lodash';

@Component({
    selector: 'cmx-listing',
    templateUrl: './cmx-listing.component.html',
    styleUrls: ['./cmx-listing.component.scss'],
})
export class ListingComponent {
    @Input() size: string = 'md';
    @Input() list: [] = [];
    @Input() searchPlaceholder?: string;
    @Input() instantSearch: boolean = true;
    @Input() hasAddButton: boolean = false;
    @Input() height: string = '100%';
    @Input() atributeName: string = 'name';
    @Input() secondaryAtributeName?: string;
    @Input() atributeDescription?: string;
    @Input() secondaryAtributeDescription?: string;
    @Input() dropdownListItems: DropdownOption[] = null;
    @Input() dropdownListSelecteds: DropdownOption[] = null;
    @Input() dropdownListSettings: DropdownSettings = null;
    @Input() dropdownCssStyle: {} = {};
    @Input() icon?: string = null;
    @Input() iconAtributeCondition?: string = null;
    @Input() iconCount?: number = null;
    @Input() selectedItem;
    @Input() iconList?: [] = [];
    @Input() search: string = '';

    dropdownList: {
        list: DropdownOption[],
        selecteds: DropdownOption[]
    } = {
        list: [],
        selecteds: []
    };

    dropdownSettings: DropdownSettings = {
        singleSelection: true,
        text: '',
    };

    dropdownStyle:{} = {
        marginLeft: '10px',
        width:'50%'
    };

    @Output('onSelect') onSelect = new EventEmitter();
    @Output('onIconSelect') onIconSelect = new EventEmitter();
    @Output('onSearch') onSearch = new EventEmitter();
    @Output('onNewItem') onNewItem = new EventEmitter();
    @Output('onSelectDropdownList') onSelectDropdownList = new EventEmitter();
    @Output('onClickIcon') onClickIcon = new EventEmitter();

    constructor(private _cmxDropdownService: CmxDropdownService){}

    ngOnInit(){
        this.dropdownSettings = _.merge(this.dropdownSettings, this.dropdownListSettings);
        this.dropdownStyle = _.merge(this.dropdownStyle, this.dropdownCssStyle);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.list) {
            this.list?.map((item: any, index) => {
                if (!item['id']) { item['id'] = index }
                this._defineSecondaryName(item);
                this._defineDescription(item);
            })
            this.newItem();
        }

        if (changes && changes.dropdownListItems && changes.dropdownListItems.currentValue.length > 0){
            this.dropdownList.list = this._cmxDropdownService.toDropdownItems(changes.dropdownListItems.currentValue, false);
            if(changes.dropdownListSelecteds && changes.dropdownListSelecteds.currentValue.length > 0){
                this.dropdownList.selecteds = this._cmxDropdownService.toDropdownItems(changes.dropdownListSelecteds.currentValue, false);
            }
        }

        if (changes && changes.selectedItem && changes.selectedItem.currentValue) {
            this.selectedItem = changes.selectedItem.currentValue;
        }
    }

    selectItem(item, element) {
         if ($(element.target).hasClass('icon-container')) {
            this.onClickIcon.emit(item);
        } else {
            this.onSelect.emit(item);
        }
        this.selectedItem = item;
    }

    selectIcon(item) {
        this.onIconSelect.emit(item);
        this.selectedItem = item;
    }

    onSelectDropdownItems(itemSelected){
        this.onSelectDropdownList.emit(itemSelected);
    }

    applySearch() {
        if (!this.instantSearch) {
            this.onSearch.emit(this.search);
        }
    }

    newItem() {
        this.selectedItem = null;
        this.onNewItem.emit();
    }

    verifySelected(item) {
        const selectedItemCode = this.selectedItem ? this.selectedItem.code || this.selectedItem.id : null;
        const itemCode = item.code || item.id;
        return itemCode === selectedItemCode;
    }

    verifySearch(item) {
        const description = item[this.atributeDescription] || '';
        return item.name ? !this.instantSearch || (this.instantSearch &&
            ((item.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)) ||
            (description.toLowerCase().indexOf(this.search.toLowerCase()) !== -1))
            : false;
    }

    verifyIcon(item) {
        return item && this.iconAtributeCondition && item[this.iconAtributeCondition];
    }

    private _defineSecondaryName(item) {
        const secondaryName = this.secondaryAtributeName ? ' - ' + item[this.secondaryAtributeName] : '';
        item['name'] = item[this.atributeName] ? `${item[this.atributeName]}${secondaryName}` : 'Desconhecido';
    }

    private _defineDescription(item) {
        item['cmxListDescription'] = this.atributeDescription ? item[this.atributeDescription] || '' : '';
        if (item['cmxListDescription']) {
            item['cmxListDescription'] = this.secondaryAtributeDescription ?
            `${item[this.atributeDescription]} - ${item[this.secondaryAtributeDescription]}` : item['cmxListDescription'];
        }

    }

}
