import { Component, ViewEncapsulation } from '@angular/core';

declare let Slick: any;

@Component({
    selector: 'cr-processes-table-page',
    templateUrl: './cr-processes-table-page.component.html',
    styleUrls: ['./cr-processes-table-page.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CrProcessesTablePageComponent {

}
