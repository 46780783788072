<header id="cmx-header" [class.dark-theme]="userPreference.darkTheme" *ngIf="currentUser$ | async as user">
    <div class="main-menu-content">
        <div class="left-content">
            <div class="logo-content" (click)="redirectToBasePage()">
                <img [src]="getLogo" alt="Comexport" />
            </div>
            <cmx-main-menu [cmx-dark-theme]="userPreference.darkTheme"></cmx-main-menu>
        </div>
        <div class="right-content">

            <div class="options-container">
                <div *ngIf="user.has('API_NOTIFICATIONS')" (click)="alertService.openAlertModal()" [style.margin-right]="getAlertMargin()" class="alert-icon-container">
                    <div class="loader-container" *ngIf="alertService.asyncAlert === false">
                        <loader></loader>
                    </div>
                    <div class="header-nav-alerts-container" *ngIf="alertService.asyncAlert">
                        <img alt="" [src]="userPreference.darkTheme ? '/assets/app/media/img/icons/icon-sine-dark.svg' : '/assets/app/media/img/icons/icon-sine.svg'" (load)="showNotificationInfo = true" />
                        <span class="header-nav-alerts-ball low s{{ alertService?.lowBallSpace?.toString() }}" *ngIf="alertService && showNotificationInfo">
                            {{ alertService.lowNotificationQty }}
                        </span>
                        <span class="header-nav-alerts-ball medium s{{ alertService?.mediumBallSpace?.toString() }}" *ngIf="alertService && showNotificationInfo && (alertService.criticalNotificationQty !== alertService.alertList.notificationQty > 0)">
                            {{ alertService.mediumNotificationQty }}
                        </span>
                        <span class="header-nav-alerts-ball high s{{ alertService?.highBallSpace?.toString() }}" *ngIf="alertService && showNotificationInfo && (alertService.criticalNotificationQty !== alertService.alertList.notificationQty > 0)">
                            {{ alertService.highNotificationQty }}
                        </span>
                        <span class="header-nav-alerts-ball critical" *ngIf="alertService && showNotificationInfo && (alertService.criticalNotificationQty !== alertService.alertList.notificationQty > 0)">
                            {{ alertService?.criticalNotificationQty }}
                        </span>
                        <span class="header-nav-alerts-ball {{ alertService?.alertList?.maxPriority }} total" *ngIf="alertService && showNotificationInfo && alertService.alertList.notificationQty > 0">
                            {{ alertService.alertList.notificationQty }}
                        </span>
                    </div>
                </div>
                <div class="chat-ai">
                    <chat-ai></chat-ai>
                </div>
            </div>

            <div class="user-profile-container">
                <profile-display-menu [cmx-dark-theme]="userPreference.darkTheme" [user]="user" [hidden]="hiddenMenu" (onChangeTheme)="onChangeThemeUserPreferences($event)"></profile-display-menu>
            </div>
        </div>
    </div>
</header>

<!-- New Features -->
<new-features></new-features>
<!-- Async Downloads -->
<async-downloads></async-downloads>
<!-- Notifications -->
<notification></notification>

<div id="overlayContainer" *ngIf="
        alertService.isVisibleAlerts ||
        downloadModalService.isVisibleDownloads ||
        messagesService.isVisibleMessages ||
        newFeaturesConsumerService.isVisibleFeatures
    ">
    <overlay></overlay>
</div>

<!-- AlertModal -->
<div *ngIf="alertService.asyncAlert" class="alertModal border hidden">
    <div *ngIf="alertService.selectedTypes.length === 1 &&
    (alertService.selectedTypes[0] != 'exchange' && alertService.selectedTypes[0] != 'client_invoice') " class="alertSources">
        <div (click)="alertService.toogleSource(alertService.alertList.atualFilters[0], $event)" class="sourceItem protheus selected">
            {{ alertService.alertList.atualFilters[0].label }}
        </div>
        <div (click)="alertService.toogleSource(alertService.alertList.atualFilters[1], $event)" class="sourceItem conexos selected">
            {{ alertService.alertList.atualFilters[1].label }}
        </div>
    </div>
    <div *ngIf="alertService.alertList.allowedTypes.length === 1" class="alertTypes">
        <span class="onlyInfo">
            {{ alertService.alertList.allowedTypes[0] === 'imp' ? 'IMPs: ' : 'Contas: ' }}
            {{
                alertService.alertList.allowedTypes[0] === 'imp'
                ? alertService.alertQuantityByType.imp
                : alertService.alertList.allowedTypes[0] === 'financial'
                ? alertService.alertQuantityByType.financial
                : '0'
            }}
        </span>
    </div>
    <div *ngIf="alertService.alertList.allowedTypes.length > 1" class="alertTypes">
        <div *ngFor="let type of alertService.alertList.allowedTypes; trackBy: trackByFn" class="alertType" (click)="alertService.toogleAlertTypes($event, type)">
            <span class="alertQuantity">
                {{
                    type === 'imp' ? alertService.alertQuantityByType.imp :
                    (type === 'financial' ? alertService.alertQuantityByType.financial :
                    (type === 'exchange' ? alertService.alertQuantityByType.exchange :
                    (type === 'client_invoice' ? alertService.alertQuantityByType.client_invoice :
                    '0')))
                }}
                <span style="font-size: 10px">
                    {{ type === 'financial' || type === 'client_invoice' ? 'Contas' : 'IMPs' }}
                </span>
            </span>
            <i class="material-icons">
                {{ type === 'imp' ? 'directions_boat' :
                   type === 'financial' ? 'local_atm' :
                   type === 'exchange' ? 'attach_money' :
                   'receipt_long' }}
            </i>
            <span class="tooltip-text">
                {{type === 'imp' ? 'Importação' :
                  type === 'financial' ? 'Financeiro' :
                  type === 'exchange' ? 'Câmbio' :
                  'a Receber'}}
            </span>
        </div>
    </div>
    <div class="search">
        <input
            autocomplete="off"
            id="alertSearch"
            (keydown.enter)="alertService.loadInternalAlerts()"
            type="text"
            name="user"
            placeholder="Buscar Alerta"
            [(ngModel)]="alertService.alertSearch"
        />
    </div>
    <div class="settings">
        <div (click)="alertService.selectCriticalLevel('LOW', $event)" title="Prioridade: Baixa" class="low">
            <span class="low">{{ alertService.internLowNotificationQty }}</span>
            <i class="comexport-alarm-1"></i>
        </div>
        <div (click)="alertService.selectCriticalLevel('MEDIUM', $event)" title="Prioridade: Média" class="medium">
            <span class="medium">{{ alertService.internMediumNotificationQty }}</span>
            <i class="comexport-alarm-1"></i>
        </div>
        <div (click)="alertService.selectCriticalLevel('HIGH', $event)" title="Prioridade: Alta" class="high">
            <span class="high">{{ alertService.internHighNotificationQty }}</span>
            <i class="comexport-alarm-1"></i>
        </div>
        <div (click)="alertService.selectCriticalLevel('CRITICAL', $event)" title="Prioridade: Crítica" class="critical">
            <span class="critical">{{ alertService.internCriticalNotificationQty }}</span>
            <i class="comexport-alarm-1"></i>
        </div>
    </div>
    <loader *ngIf="!alertService.asyncInternAlert || !alertService.asyncAlert"></loader>
    <div class="alertContent">
        <table>
            <tr (click)="alertService.selectAlert(alert, $event, i)" *ngFor="let alert of alertService.alertList.notifications; let i = index; trackBy: trackByFn">
                <div class="alertLine" style="width: 100%; position: relative">
                    <td>
                        <figure class="circle {{ alert.priority }}"></figure>
                        <div class="title">
                            <pre class="alertName superior">{{ alert?.mainCode }}</pre>
                            <span class="alertName">{{ alert?.title }}</span>
                            <span *ngIf="alert?.affected?.key" class="alertName company">{{ alert?.affected?.key }}</span>
                        </div>
                    </td>
                    <td class="days">
                        <div class="title">
                            <span class="alertName">{{ alertService.alertDays(alert?.creationTime) }} {{
                                alertService.alertDays(alert?.creationTime) === 1 ? 'dia' : 'dias'}}</span>
                            <span *ngIf="alert?.filterValues[0] !== 'Conta'" class="alertSource {{ alert.filterValues[0] }}">{{ alert?.filterValues[0] }}</span>
                        </div>
                    </td>
                </div>
            </tr>
        </table>
    </div>
    <div *ngIf="alertService.pages.length > 1">
        <pagination [numberOfPages]="alertService.pages.length" [queryId]="alertService.queryId"
            (onPageChange)="alertService.changePage($event)"></pagination>
    </div>
</div>
<div *ngIf="alertService.asyncAlert" class="selectedAlertModal hidden">
    <div class="selectedAlertContent">
        <i (click)="alertService.closeSelectedAlert()" class="close la la-close"></i>
        <div class="alertTitle">
            <i class="comexport-alarm {{ alertService.selectedAlert.priority }}"></i>
            <span>{{ alertService.selectedAlert.title }}</span>
        </div>
        <hr />
        <div class="alertDescription">
            <span *ngFor="let field of alertService.selectedFields; trackBy: trackByFn">
                <p><b>{{ field.label }}:</b> {{ field.value }}</p>
            </span>
            <div *ngIf="alertService.selectedAlert.type === 'IMP'" class="detailContainer">
                <span (click)="alertService.toDetail()">Detalhamento</span>
            </div>
        </div>
        <hr />
    </div>
</div>

<!-- FeatureList Modal -->
<div class="featureListModal border hidden">
    <div class="modalTitle">
        <span>Atualizações</span>
        <div class="subtitle">
            <span class="subtitleType IMPROVEMENT">Melhorias</span>
            <span class="subtitleType NEW_FEATURE">Novidades</span>
            <span class="subtitleType BUG">Correções</span>
        </div>
    </div>
    <loader *ngIf="newFeaturesConsumerService.asyncHistoricReleases"></loader>
    <div *ngIf="!newFeaturesConsumerService.asyncHistoricReleases" class="featureListContent">
        <div *ngFor="let release of newFeaturesConsumerService.getReleaseList(); trackBy: trackByFn"
            (click)="newFeaturesConsumerService.selectRelease(release)" class="title">
            <span>{{ release?.title }}</span>
            <div class="subInfoContainer">
                <div *ngIf="release.featuresSummary" class="featureTypes">
                    <div *ngFor="let type of objectKeys(release.featuresSummary); trackBy: trackByFn" class="featureType {{ type }}">
                        {{ release.featuresSummary[type] }}
                    </div>
                </div>
                <span class="date">
                    {{moment(release?.creationDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}}
                </span>
            </div>
        </div>
    </div>
    <div *ngIf="newFeaturesConsumerService.pages.length > 1">
        <pagination [maxPages]="7" [numberOfPages]="newFeaturesConsumerService.pages.length"
            [queryId]="newFeaturesConsumerService.queryId"
            (onPageChange)="newFeaturesConsumerService.changePage($event)"></pagination>
    </div>
</div>

<div *ngIf="enableAlterPassword" class="overlay-expires-popup" [ngClass]="{'actived-expires': enableAlterPassword}"
    (click)="closeModal = true">
    <div class="window-expires">
        <p>
            {{passwordMessage}}
        </p>
        <div class="actions">
            <button class="btn-edit-password-expires" (click)="alterPassword()">Alterar senha</button>
            <button class="btn-close-expires" (click)="enableAlterPassword = false">Fechar</button>
        </div>
    </div>
</div>
