import { NgModule } from '@angular/core';

import { BalanceSheetModule } from './../default/balance-sheet/balance-sheet.module';
import { CostsModule } from './costs/costs.module';
import { CurrencyExchangeModule } from './currency-exchange/currency-exchange.module';

@NgModule({
    imports: [
        CurrencyExchangeModule,
        CostsModule,
        BalanceSheetModule,
    ],
})
export class FinanceModule {

}
