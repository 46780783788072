import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ReplaySubject } from 'rxjs';

import { UtilsService } from '#services/_utils/utils.service';
import { UserService } from 'app/auth/_services';

@Injectable()
export class InvoiceStatusUtilsService {
    selectedImpsChanged: ReplaySubject<any> = new ReplaySubject();
    selectedExtraFieldDetailsChanged: ReplaySubject<any> = new ReplaySubject();

    constructor(private _utilsService: UtilsService, private _router: Router, private _userService: UserService) {}

    selectImps(imps: any): void {
        this.selectedImpsChanged.next(imps);
    }

    selectExtraFieldDetails(extraFieldDetails: any): void {
        this.selectedExtraFieldDetailsChanged.next(extraFieldDetails);
    }

    navigateToImpDetail(impData: any): void {
        const { companyCode, imp } = impData;

        if (this._utilsService.isIE()) {
            this._router.navigate([`./supply-chain/importacao/${companyCode}/${imp}`]);
        } else {
            window.open(`./supply-chain/importacao/${companyCode}/${imp}`);
        }
    }

    userHasAutomotivePermission(): boolean {
        return (
            this._userService.getCurrentUser().has('ROUTE_AUTOMOTIVE') ||
            this._userService.getCurrentUser().has('AUTOMOTIVE_USER')
        );
    }

    filterHasCxTradeCompany(companies): boolean {
        return companies.find((company) => company.itemName === 'CX TRADE AG') ? true : false;
    }
}
