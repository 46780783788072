import { HttpHeaders, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { AppModule } from 'app/app.module';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IInvoiceExternalFilter } from './interfaces/InvoiceExternal.interface';

export class InvoiceFilter {
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialInvoiceExternalFilter: IInvoiceExternalFilter = <IInvoiceExternalFilter>{};
    private _invoiceExternalFilter: IInvoiceExternalFilter = <IInvoiceExternalFilter>{
        search: '',
        clientGroups: [],
        situations: [],
        types: []
    };

    private static firstPage = {
        page: 0,
        size: 20,
    };

    private page = Object.assign({}, InvoiceFilter.firstPage);
    private startDate;
    private endDate;

    constructor(filter?: InvoiceFilter) {

        this._invoiceExternalFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'invoiceExternalFilter', this._invoiceExternalFilter, filter);
        this._initialInvoiceExternalFilter = _.cloneDeep(this._invoiceExternalFilter);

        this.startDate = filter ? filter.startDate : moment().add(-30, 'days').format('DD/MM/YYYY');
        this.endDate = filter ? filter.endDate : moment().format('DD/MM/YYYY');
    }

    getSearch(): string {
        return this._invoiceExternalFilter.search;
    }
    getStartDate() {
        return this.startDate;
    }
    getEndDate() {
        return this.endDate;
    }
    getClientGroups(): object[] {
        return this._invoiceExternalFilter.clientGroups;
    }
    getSituations(): object[] {
        return this._invoiceExternalFilter.situations;
    }
    getTypes(): object[] {
        return this._invoiceExternalFilter.types;
    }

    setSearch(search): void {
        this._invoiceExternalFilter.search = search;
    }
    setStartDate(startDate): void {
        this.startDate = startDate;
    }
    setEndDate(endDate): void {
        this.endDate = endDate;
    }
    setClientGroups(clientGroups): void {
        this._invoiceExternalFilter.clientGroups = clientGroups;
    }
    setPage(pageNumber): void {
        this.page.page = pageNumber;
    }
    setSituations(situations): void {
        this._invoiceExternalFilter.situations = situations;
    }
    setTypes(types): void {
        this._invoiceExternalFilter.types = types;
    }

    getRequest(automotiveMode) {

        this._initialInvoiceExternalFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialInvoiceExternalFilter, this._invoiceExternalFilter, 'invoiceExternalFilter', this._userCacheService.getUserPreferences().id);

        let params = new HttpParams();
        let headers = new HttpHeaders();
        headers = headers.set(
            'X-Requested-Client-Groups',
            this.getSearch() === '' ? this._invoiceExternalFilter.clientGroups.map((c) => c.id).join(',') : ''
        );
        // pagable
        params = params.append('page', `${this.page.page}`);
        params = params.append('size', `${this.page.size}`);

        if (this.getSearch() && this.getSearch() !== '') {
            params = params.append('commercialInvoiceNumber', `${this.getSearch()}`);
        } else {
            params = params.append('type', this.getSearch() === '' ? this._invoiceExternalFilter.types.map((c) => c.id).join(',') : '');
            params = params.append(
                'startDate',
                `${
                    this.getSearch() === ''
                        ? moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')
                        : '2016-01-01'
                }`
            );
            params = params.append(
                'endDate',
                `${
                    this.getSearch() === ''
                        ? moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')
                        : moment().format('YYYY-MM-DD')
                }`
            );
        }
        params = params.append('context', automotiveMode ? 'automotive' : 'imp');
        return { params, headers, observe: 'body' };
    }

    getExportRequest() {

        this._initialInvoiceExternalFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialInvoiceExternalFilter, this._invoiceExternalFilter, 'invoiceExternalFilter', this._userCacheService.getUserPreferences().id);

        let params = new HttpParams();
        let headers = new HttpHeaders();
        headers = headers.set(
            'X-Requested-Client-Groups',
            this.getSearch() === '' ? this._invoiceExternalFilter.clientGroups.map((c) => c.id).join(',') : ''
        );
        if (this.getSearch() && this.getSearch() !== '') {
            params = params.append('commercialInvoiceNumber', `${this.getSearch()}`);
        } else {
            params = params.append(
                'startDate',
                `${
                    this.getSearch() === ''
                        ? moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')
                        : '2016-01-01'
                }`
            );
            params = params.append(
                'endDate',
                `${
                    this.getSearch() === ''
                        ? moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')
                        : moment().format('YYYY-MM-DD')
                }`
            );
        }
        return { params, headers, observe: 'body' };
    }

    reset() {
        this.page = Object.assign({}, InvoiceFilter.firstPage);
    }
}
