import { UsageAnalyticsService } from '#services/_usage-analytics/usage-analytics.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';


import { Helpers } from '../helpers';

declare let mApp: any;
declare let mUtil: any;
declare let mLayout: any;
@Component({
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    templateUrl: './theme.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ThemeComponent implements OnInit {
    constructor(
        private _router: Router,
        private usageAnalyticsService: UsageAnalyticsService
    ) {}

    ngOnInit() {
        const self = this;
        $(window).on('scroll', () => {
            if ($(window).scrollTop() + $(window).height() === $(document).height()) {
                $('.footer').attr('class', 'footer show');
            } else {
                $('.footer').attr('class', 'footer');
            }
        });

        this._router.events.subscribe((route) => {
            if (route instanceof NavigationStart) {
                (mLayout as any).closeMobileAsideMenuOffcanvas();
                (mLayout as any).closeMobileHorMenuOffcanvas();
                (mApp as any).scrollTop();
                Helpers.setLoading(true);
                // hide visible popover
                ($("[data-toggle='m-popover']") as any).popover('hide');
            }
            if (route instanceof NavigationEnd) {
                // init required js
                (mApp as any).init();
                (mUtil as any).init();
                Helpers.setLoading(false);
                // content m-wrapper animation
                const animation = 'm-animate-fade-in-up';
                $('.m-wrapper')
                    .one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', (e) => {
                        $('.m-wrapper').removeClass(animation);
                    })
                    .removeClass(animation)
                    .addClass(animation);
            }
        });

        this._router.events.subscribe((route) => {
            if (route instanceof NavigationEnd) {
                self.sendPageViewEvent();
            }
        });

        this.monitorUsageEvents();
    }

    private sendPageViewEvent() {
        const self = this;
        setTimeout(() => {
            const feature = self.usageAnalyticsService.getCurrentFeature();
            self.usageAnalyticsService.sendPageView(feature);
            self.usageAnalyticsService.sendEvent('Feature', 'view', feature, null, {
                Feature: feature,
            });
        }, 10);
    }

    private monitorUsageEvents() {
        const self = this;
        self.usageAnalyticsService.reset();
        self.sendPageViewEvent();

        $(document).on('click', '.checkmark', function (e) {
            const tabName = $(this).find('.tooltip-text').text() || $(this).text();
            if (location.pathname == '/cadastro/automotivo') {
                const feature = `Automotivo > ${tabName}`;
                self.usageAnalyticsService.sendEvent('Feature', 'view', feature, null, {
                    Feature: feature,
                });
            } else {
                self.usageAnalyticsService.sendEvent('Tab', 'click', tabName, null, {
                    Feature: self.usageAnalyticsService.getFeature(e.currentTarget),
                });
            }
        });

        $(document).on('click', '.marker-filter-content', function (e) {
            const tabName = $(this).find('.name-description').text();
            self.usageAnalyticsService.sendEvent('Tab', 'click', tabName, null, {
                Feature: self.usageAnalyticsService.getFeature(e.currentTarget),
            });
        });

        $(document).on('click', '.tab-item', function (e) {
            const tabName = $(this).text();
            self.usageAnalyticsService.sendEvent('Tab', 'click', tabName, null, {
                Feature: self.usageAnalyticsService.getCurrentFeature(),
            });
        });

        $(document).on('click', '.channelNumbers .card', function (e) {
            let tabName = $(this).attr('class').replace('card', '').replace('checked', '').trim();
            tabName = tabName[0].toUpperCase() + tabName.slice(1);
            self.usageAnalyticsService.sendEvent('Tab', 'click', tabName, null, {
                Feature: self.usageAnalyticsService.getCurrentFeature(),
            });
        });

        $(document).on('click', '.flaticon-download', (e) => {
            self.usageAnalyticsService.sendEvent('Exportation', 'use', null, null, {
                Feature: self.usageAnalyticsService.getCurrentFeature(),
                Type: 'Excel',
            });
        });

        $(document).on('click', '.analiticButton', (e) => {
            self.usageAnalyticsService.sendEvent('Feature', 'view', null, null, {
                Feature: 'Custos Detalhados'
            });
        });

        $(document).on('click', '.applyReport, .filter-button', (e) => {
            let properties = {};

            let imp = $('#impSearch[name=imp]').val();
            if (imp) {
                properties['Imp'] = imp;
            }

            let chassi = $('[name=chassi]').val();
            if (chassi) {
                properties['Chassi'] = chassi;
            }

            let sku = $('[name=sku]').val();
            if (sku) {
                properties['Sku'] = sku;
            }

            if (Object.entries(properties).length == 0) {
                let fromDate = $('#periodFrom-reportTable').attr('ng-reflect-model') || $('#startDatePicker').val();
                if (fromDate) {
                    properties['From Date'] = fromDate;
                }

                let toDate = $('#periodTo-reportTable').attr('ng-reflect-model') || $('#endDatePicker').val();
                if (toDate) {
                    properties['To Date'] = toDate;
                }

                let referenceDateType = $('#fup-imp-reference-date-type').data('type');
                if (referenceDateType) {
                    properties['Reference'] = referenceDateType;
                }

                let clients = $('#clients').find('.selected-list').find('.c-label').map(function () { return $(this).text().trim(); }).get().join();
                if (clients) {
                    properties['Clients'] = clients;
                }

                let crs = $('#crs').find('.selected-list').find('.c-label').map(function () { return $(this).text().trim(); }).get().join();
                if (crs) {
                    properties['CRs'] = crs;
                }
            }

            if (Object.entries(properties).length != 0) {
                let label = Object.keys(properties).join(',');
                properties['Feature'] = self.usageAnalyticsService.getFeature(e.currentTarget);
                self.usageAnalyticsService.sendEvent('Filter', 'apply', label, null, properties);
            }
        });

        $(document).on('click', 'a.page', function (e) {
            self.usageAnalyticsService.sendEvent('Pagination', 'click', 'Page Number', parseInt($(this).text(), 10), {
                Feature: self.usageAnalyticsService.getFeature(e.currentTarget),
                'Page Number': $(this).text(),
            });
        });
    }
}
