<div class="asyncDownloadsContainer hidden">
   <div class="download-title-container">
        <span class="download-title">Downloads
            <span>(últimos 30 dias)</span>
        </span>
   </div>
   <div *ngIf="!asyncDownloads">
        <loader></loader>
    </div>
    <div class="listContainer">
        <div *ngIf="asyncDownloads" class="listContent">
            <div *ngFor="let download of downloadList; trackBy: trackByFn" class="download">
                <div class="downloadContent">
                    <div (click)="showInfo($event)" class="info">
                        <span style="pointer-events:none">?</span>
                    </div>
                    <span class="title">{{ download?.label }}</span>
                    <span class="date">{{moment(download.filters.requestDate, 'DD/MM/YYYY HH:mm').format('DD/MM HH:mm')}}</span>
                    <div class="buttonsContainer">
                        <div (click)="downloadArquive(download.origin, download.path)" class="downloadButton">
                            <i class="material-icons">arrow_downward</i>
                        </div>
                        <div (click)="openDeleteModal(download)" class="deleteButton">
                            <i class="material-icons">clear</i>
                        </div>
                    </div>
                </div>
                <div class="infoContent hidden">
                    <span class="title">Filtros Aplicados:</span>
                    <div *ngFor="let filter of objectKeys(download?.filters?.reportFilters); trackBy: trackByFn">
                        <span class="filter">• {{filter}}: </span>
                        <span class="filterValue">{{formatFilter(download.filters.reportFilters[filter])}}</span>
                    </div>
                    <hr>
                </div>
            </div>
        </div>
        <div *ngIf="pages.length > 1">
            <pagination [numberOfPages]="pages.length" [queryId]="queryId" (onPageChange)="changePage($event)"></pagination>
        </div>
   </div>
   <div class="deleteModal hidden">
        <span class="title">
            <i class="material-icons">delete_forever</i>
            Deletar {{selectedDownload?.label}} de {{moment(selectedDownload?.filters?.requestDate, 'DD/MM/YYYY HH:mm').format('DD/MM HH:mm')}}?
        </span>
        <div (click)="confirmDelete(selectedDownload)" class="deleteButton">Deletar</div>
        <div (click)="closeDeleteModal()" class="cancelButton">Cancelar</div>
   </div>
</div>
