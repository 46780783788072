<cmx-page-wrapper
    layout="bigHeader"
    [titleComponent]="titleComponent"
    [tabs]="configurations"
    [tab-blur]="currentTabSelected"
    (onSelectTab)="setCurrentSelectedTab($event)">

    <ng-container cmx-page-wrapper-body>
        <loader *ngIf="loadingReport"></loader>
        <div [hidden]="loadingReport">
            <div id="report-container" frameBorder="0" [hidden]="showDashCms"></div>
            <div id="report-datastudio" frameBorder="0" *ngIf="showDashCms">
                <iframe width="100%" height="100%" frameBorder="0" [src]="urlSafe"></iframe>
            </div>
            <div id="right-buttons">
                <button id="{{autoRefreshEnabled ? 'buttons-action-format-enabled': 'buttons-action-format'}}" type="button" class="fa fa-refresh fa-lg" (click)="handleRefresh()"></button>
                <button id="buttons-action-format" type="button" class="fa fa-expand fa-lg" (click)="openFullscreen()"></button>
            </div>
        </div>
    </ng-container>
</cmx-page-wrapper>
