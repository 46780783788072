<cmx-custom-dialog [id]="id" animation="right-left" [maxWidthModal]="370">
    <ng-container cmx-custom-dialog-header>
        <div class="modalTitle" *ngIf="details">
            <span>IMP {{details.importationNumber}}</span>
        </div>
    </ng-container>
    <ng-container cmx-custom-dialog-body>
        <ng-container *ngIf="loading">
            <loader></loader>
        </ng-container>
        <ng-container *ngIf="!loading">
            <cmx-custom-grid
                [loading]="loading"
                [columns]="gridColumns"
                [rows]="gridRows"
                [options]="{ forceFitColumns: false }"
                [styles]="{ height: 'calc(100% - 20px)', 'margin-top': 0 }"
            ></cmx-custom-grid>
        </ng-container>
    </ng-container>
</cmx-custom-dialog>
