<button
    class="cmx-button"
    [ngClass]="[
        'btn-' + size,
        'btn-type-' + variant,
        'btn-format-' + format,
        responsive ? 'btn-fill' : '',
        disabled ? 'btn-disabled' : ''
    ]"
    [disabled]="disabled || variant === 'disabled'"
    [hidden]="hidden"
    [style.backgroundColor]="variant === 'custom' ? customColor : ''"
    [style.color]="variant === 'custom' ? customTextColor : ''"
    [style.width]="width ? width : ''"
    [ngStyle]="styles"
    [type]="type"
>
    <span class="content">
        <em
            class="awesome"
            [ngClass]="icon"
            *ngIf="icon && iconType == 'awesome'"
            [class.no-margin]="!hasContent"
        ></em>
        <em
            class="fa"
            [ngClass]="icon"
            *ngIf="icon && iconType == 'fa'"
            [class.no-margin]="!hasContent"
        ></em>
        <em
            class="material-icons"
            [ngClass]="icon"
            *ngIf="icon && iconType == 'material'"
            [class.no-margin]="!hasContent"
            >{{ iconButton }}</em
        >
        <span #refContentButton>
            <ng-content></ng-content>
        </span>
    </span>
</button>
