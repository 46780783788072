import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';

export const CHANNEL_COLUMNS: IGridColumn[] = [
    {
        field: 'imp',
        name: 'IMP',
        width: 90,
        formatterType: 'badge',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
    {
        field: 'clientReference',
        name: 'Referência',
        width: 90,
    },
    {
        field: 'clientName',
        name: 'Cliente',
        width: 115,
    },
    {
        field: 'description',
        name: 'Descrição',
        width: 115,
    },
    {
        field: 'clearanceDate',
        name: "<img src='./assets/app/media/img/icons/desembaraco.png'>",
        width: 75,
        formatterType: 'date',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
    {
        field: 'channel',
        name: 'Canal',
        width: 60,
        formatterType: 'round',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
];

export const CHANNEL_PAGINATION_STYLES = {
    height: `215px`,
    'margin-top': '0px',
}

export const CHANNEL_NON_PAGINATION_STYLES = {
    height: `250px`,
    'margin-top': '0px',
}
