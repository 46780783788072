<div *ngIf="asyncUser == false" class="fullLoader admModalLoader">
    <loader></loader>
</div>

 <i (click)="close()" id="close" class="la la-close"></i>

<div class="admin-account-border">
    <div class="line-space">
        <cmx-listing
            searchPlaceholder="Buscar Usuário"
            atributeDescription="email"
            height="calc(100vh - 160px)"
            [list]="users"
            [hasAddButton]="true"
            [selectedItem]="selectedUser"
            (onNewItem)="clearUserForm()"
            (onSelect)="selectUser($event)"
        ></cmx-listing>
    </div>
</div>
<div class="admItem">
    <a *ngIf="newUser == false" (click)="admService.openDeleteModal(modal)" class="deleteBtn"><i class="la la-trash"></i></a>
    <span *ngIf="newUser" class="admTitle">Adicionar Novo Usuário</span>
    <span *ngIf="newUser == false" class="admTitle">Editar Usuário</span>
    <hr>
    <div class="admForm">
        <div class="p-field">
            <label>Nome Completo</label>
            <input pInputText [(ngModel)]='selectedUser.name' class="admInput" type="text" (keyup)="refreshState()">
        </div>
        <div class="p-field">
            <label>E-mail</label>
            <input [(ngModel)]='selectedUser.email' *ngIf='newUser' class="admInput" type="text">
            <input [(ngModel)]='selectedUser.email' *ngIf='newUser == false' disabled class="admInput" type="text">
        </div>

         <cmx-dropdown [styles]="{minWidth: '100%'}" size="md" id="clients" name="clients" [data]="dropdowns.clientGroups.options" [(ngModel)]="dropdowns.clientGroups.selected" (onScroll)="scrollList($event)"
        [settings]="dropdownSettingsClients" (onChange)='refreshState()'></cmx-dropdown>

         <div class="flexDropDown">
            <div class="item-dropdown">
                <cmx-dropdown [styles]="{width: '100%'}" size="md" id="profiles" name="profiles" [data]="dropdowns.profiles.options" [(ngModel)]="dropdowns.profiles.selected" (onScroll)="scrollList($event)"
                [settings]="dropdownSettingsProfiles" (onChange)='refreshState()'></cmx-dropdown>
            </div>
            <div class="item-dropdown">
                <cmx-dropdown [styles]="{width: '100%'}" size="md" id="domains" name="domains" [data]="dropdowns.domains.options" [(ngModel)]="dropdowns.domains.selected" (onScroll)="scrollList($event)"
                    *ngIf="!userDomainDisabled"
                    [settings]="dropdownSettingsDomains" (onChange)='refreshState()'></cmx-dropdown>
            </div>

        </div>

        <label>Centros de Resultado</label>
        <span *ngIf='this.selectedUser.profitCenters.length !== this.profitCenters.length ; else unselect' (click)='selectAllProfit()' class="selectAll">Selecionar Todos</span>
        <ng-template #unselect>
            <span (click)='selectAllProfit()' class="selectAll">Remover Todos</span>
        </ng-template>
        <div class="checkContainer" id='profit'>
            <div class="admCheckGroup">
                <div *ngFor='let cr of profitCenters; trackBy: trackByFn' class="admCheck component">
                    <input (click)="inputClicked(cr)" [checked]="selectedUser.profitCenters.includes(cr.code)" class="admCheckItem component {{ cr }}" name="type" type="checkbox">
                    <label (click)="inputClicked(cr)">{{ cr.name }}</label>
                </div>
            </div>
        </div>

        <label>Autenticação de Dois Fatores</label>
        <div id="twoFactorOptions" class="flexDropDown">
            <div id="twoFactorMandatory" class="admRadioDiv component">
                <input (click)="changeTwoFactor()" [checked]="true" class="admRadio component" name="type" type="checkbox" [(ngModel)]="twoFactorMandatory">
                <label (click)="changeTwoFactor()">Obrigatória</label>
            </div>

            <div id="twoFactorEnabled" class="admRadioDiv component">
                <input (click)="resetTwoFactor()" [checked]="true" class="admRadio component" name="type" type="checkbox" [(ngModel)]="twoFactorEnabled">
                <label (click)="resetTwoFactor()">Habilitada</label>
            </div>
        </div>

         <p class="error 404 hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>Esse perfil não existe!</p>
        <!-- Create Errors -->
        <p class="error name hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>O campo Nome é obrigatório.</p>
        <p class="error email hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>O campo E-mail é obrigatório.</p>
        <p class="error clientGroups hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>Selecione ao menos um cliente.</p>
        <p class="error accessProfile hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>Selecione um perfil.</p>
        <p class="error profitCenters hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>Selecione ao menos um CR.</p>
        <p class="error domains hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>Selecione ao menos um domínio.</p>
        <p class="error duplicatedEmail hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>Já existe um usuário com esse E-mail</p>
        <p class="error signInEmail hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>Não foi possível enviar o E-mail de Sign In.</p>
        <!-- All Success -->
        <p class="success created hidden"><i (click)='admService.hideSuccess(modal)' class="la la-close"></i>Usuário criado!</p>
        <p class="success edited hidden"><i (click)='admService.hideSuccess(modal)' class="la la-close"></i>Usuário alterado!</p>
        <p class="success deleted hidden"><i (click)='admService.hideSuccess(modal)' class="la la-close"></i>Usuário deletado!</p>
    </div>
    <button *ngIf="newUser" (click)="addUser()" class="add">Adicionar</button>
    <button *ngIf="!newUser" (click)="saveUser()" class="edit">Salvar</button>
</div>
<div class="deleteModal hidden">
    <p>Deseja deletar o usuário {{selectedUser.name}} ?</p>
    <div class="deleteButtons">
        <button (click)="confirmDeleteUser()" class="delete">Deletar</button>
        <button (click)="admService.closeDeleteModal(modal)" class="cancel">Cancelar</button>
    </div>
</div>
