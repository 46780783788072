<div class="page-title-identifier">Meu Perfil</div>
<div class="topContainer">
</div>

<div class="photoContainer">
    <img style="width:100%" src="assets/img/users/user-for-light.png" alt="">
</div>

<div class="profileInfo">
    <div *ngIf="currentUser$ | async as user" class="displayName">
        <span>{{user.data.displayName}}</span>
    </div>
    <div *ngIf="formattedPhoneNumber && phoneFeatureFlag" class="displayPhone">
        <span>{{formattedPhoneNumber}}</span>
    </div>
    <div *ngIf="currentUser$ | async as user" class="displayEmail">
        <span>{{user.data.email}}</span>
    </div>

    <div class="changePassword">
        <span (click)='openChangePasswordModal()'>Alterar Senha</span>
    </div>
</div>


<div class="profileOptions">
    <div class="enableTwoFactor">
        <div class="optionContent" (click)='openTwoFactorModal()'>
            <i class="material-icons">lock</i>
            <span>Autenticação de 2 Fatores</span>
        </div>
    </div>

    <div *ngIf="phoneFeatureFlag" class="enableAI">
        <div class="optionContent" (click)='openEnableAIModal()'>
            <i class="material-icons">memory</i>
            <span>Configurar Chat com IA</span>
        </div>
    </div>
</div>

<div #changePasswordModal class="changePasswordModal hidden">
    <div class="changePasswordContent">
        <div class="errorMessage">
            <span>{{errorMessage}}</span>
            <span style="color:green">{{successMessage}}</span>
        </div>
        <div class="inputItem">
            Senha Atual
            <input [(ngModel)]="actualPassword" type="password">
        </div>
        <div class="inputItem">
            Nova Senha
            <input [(ngModel)]="newPassword" type="password"
            (focus)="focusHit = true"
            (blur)="focusHit = false"
            (input)="checkPassword($event)"
            >
        </div>
        <div class="inputItem">
            Repetir Senha
            <input [(ngModel)]="repeatPassword" type="password"
            (focus)="focusHit = true"
            (blur)="focusHit = false"
            (input)="checkPassword($event)"
            >
        </div>

        <div class="password-hits">
            <ul [ngClass]="{'hit-password': focusHit}">
                <li [ngClass]="{'danger-color': !checkNumbersCharsPassword, 'success-color': checkNumbersCharsPassword}"><i class="fa fa-times" *ngIf="!checkNumbersCharsPassword"></i><i class="fa fa-check" *ngIf="checkNumbersCharsPassword"></i> Deve conter número, letras minúscula e maiúscula</li>
                <li [ngClass]="{'danger-color': !checkSpecialCharPassword, 'success-color': checkSpecialCharPassword}"><i class="fa fa-times" *ngIf="!checkSpecialCharPassword"></i><i class="fa fa-check" *ngIf="checkSpecialCharPassword"></i> {{ 'Pelo menos um caracter especial: !@#$%&' }}</li>
                <li [ngClass]="{'danger-color': !checkTotalPassword, 'success-color': checkTotalPassword}"><i class="fa fa-times" *ngIf="!checkTotalPassword"></i><i class="fa fa-check" *ngIf="checkTotalPassword"></i> Mínimo de 8 caracteres</li>
            </ul>
        </div>
        <div *ngIf="asyncChangePassword === true" class="buttons">
            <button (click)='closeChangePasswordModal()' class="cancelBtn">{{ successMessage ? 'Fechar' : 'Cancelar'}}</button>
            <button *ngIf="!successMessage" (click)='changePassword()' class="editPasswordBtn">Alterar</button>
        </div>
        <div *ngIf="asyncChangePassword === false">
            <loader></loader>
        </div>
    </div>
</div>

<div #enableAIModal class="enableAIModal hidden">
    <div *ngIf="!phoneIntegrationData" class="enableAIModalFirstAccess">
        <span class="dialogTitle">Configurar Chat com IA</span>
        <div class="infoMessage">
            <span>Para ativar o Chat com IA, é necessário cadastrar seu número de telefone.</span>
        </div>
        <div class="actions">
            <button (click)='closeEnableAIModal()' class="cancelBtn">Cancelar</button>
            <button (click)='openEditPhoneModal()' class="nextBtn">Seguinte</button>
        </div>
    </div>

    <div *ngIf="phoneIntegrationData" class="enableAIModalContent">
        <div class="modalContent">
            <div class="orderedList">
                <div class="listItem">
                    <div class="itemHeader">1.</div><div class="itemContent">Inicie a conversa com a ComeXa pelo link ou QR Code abaixo.</div>
                </div>
                <div class="listItem">
                    <div class="itemHeader">2.</div><div class="itemContent">No primeiro acesso, será necessário informar um código de validação.</div>
                </div>
                <div class="listItem">
                    <div class="itemHeader">3.</div><div class="itemContent">Acesse o código nessa mesma página através do botão Exibir Código.</div>
                </div>
                <div class="listItem">
                    <div class="itemHeader">4.</div><div class="itemContent">Informe o código pelo whatsapp e seu acesso será liberado.</div>
                </div>
            </div>
            <div class="integrationLinks">
                <a [href]="whatsappUrl" class="whatsappIcon" ><img style="width: 150px;" src="assets/img/icons/whatsapp_green.svg"/></a>
                <qrcode [qrdata]="whatsappUrl" [cssClass]="'center'" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
            </div>
            <div class="integrationOptions">
                <button *ngIf="phoneIntegrationData.otpCode && !showOtp" (click)='this.setShowOtp(true)' class="showOtpBtn">Exibir Código</button>
                <span *ngIf="showOtp" class="otpCode">{{phoneIntegrationData.otpCode}}</span>
                <button (click)='openEditPhoneModal()' class="editPhoneBtn">Alterar Telefone</button>
            </div>
        </div>
        <div class="buttons">
            <button (click)='closeEnableAIModal()' class="cancelBtn">Fechar</button>
        </div>
    </div>
</div>

<div #editPhoneModal class="editPhoneModal hidden">
    <div class="editPhoneModalContent">
        <div class="errorMessage">
            <span>{{errorMessage}}</span>
            <span style="color:green">{{successMessage}}</span>
        </div>
        <div class="inputItem">
            Telefone
            <input pInputText (keydown)="editPhoneOnKeydown($event)" [(ngModel)]="phoneNumber" type="text" placeholder="(99) 99999-9999" mask="(00) 00000-0000"/>
        </div>
        <div *ngIf="asyncEditPhone === true" class="buttons">
            <button (click)='closeEditPhoneModal()' class="cancelBtn">{{ successMessage ? 'Fechar' : 'Cancelar'}}</button>
            <button *ngIf="!successMessage" (click)='savePhoneIntegration()' class="editPhoneBtn">{{ updatePhoneNumber ? 'Atualizar' : 'Salvar' }}</button>
        </div>
        <div *ngIf="asyncEditPhone === false">
            <loader></loader>
        </div>
    </div>
</div>

<div #enableTwoFactorModal class="enableTwoFactorModal hidden">
    <div *ngIf="!asyncTwoFactor">
        <loader></loader>
    </div>
    <div *ngIf="asyncTwoFactor" class="enableTwoFactorContent">
        <div *ngIf="isTwoFactorEnabled()" class="infoMessage">
            <span>{{infoMessage}}</span>
        </div>
        <div *ngIf="isTwoFactorEnabled()" class="warnMessage">
            <span *ngIf="warnMessage">{{warnMessage}}</span>
        </div>
        <div *ngIf="isTwoFactorEnabled()" (click)="openTutorial()" class="guideMessage">
            <span>Veja Como</span>
            <i class="material-icons">view_carousel</i>
        </div>
        <div id="qrcode"></div>
        <div class="errorMessage">
            <span>{{errorMessage}}</span>
            <span style="color:green">{{successMessage}}</span>
        </div>
        <div *ngIf="!isTwoFactorEnabled()" class="enableMessage">
            <span *ngIf="confirmTwoFactorMoment">
            <span style="color:#ad0000">
                <i class="material-icons warning left">warning</i>
                ATENÇÃO!
            </span> Após a confirmação da autenticação
            por dois fatores, não será possível entrar no sistema sem o código de acesso.
            Deseja continuar?</span>
            <span *ngIf="!confirmTwoFactorMoment">{{initialInfoMessage}}</span>
        </div>
        <div *ngIf="asyncChangePassword === true" class="buttons">
            <button *ngIf="!isTwoFactorEnabled() && !confirmTwoFactorMoment" (click)='enableTwoFactor()' class="enableBtn">Habilitar</button>
            <button *ngIf="isTwoFactorEnabled()" (click)='disableTwoFactor()' class="enableBtn">Desabilitar</button>
            <button *ngIf="isTwoFactorEnabled() || !isTwoFactorMandatory()" (click)='closeEnableTwoFactorModal()' class="cancelBtn">Fechar</button>
            <button *ngIf="!isTwoFactorEnabled() && confirmTwoFactorMoment" (click)='confirmTwoFactor()' class="enableBtn">Continuar</button>
        </div>
        <div *ngIf="asyncChangePassword === false">
            <loader></loader>
        </div>
    </div>
</div>

<div #closeConfirmationModal class="closeConfirmationModal hidden">
    <div class="closeConfirmationContent">
        <div class="dangerContainer">
            <span>Deseja desabilitar a autenticação por dois fatores?</span>
        </div>
        <div class="buttons">
            <span (click)="confirmDisableTwoFactor()" class="save">Sim</span>
            <span (click)="closeConfirmationModal()" class="noSave">Não</span>
        </div>
    </div>
</div>

<div #twoFactorTutorial class="twoFactorTutorial hidden">
    <i (click)="closeTutorial()" class="la la-close"></i>
    <div class="twoFactorTutorialContent">
        <div class="imageContainer">
            <span>Baixo o Google Authenticartor na AppStore ou GooglePlay.</span>
            <img src="assets/img/tutos/two-factor/two-factor1.png" alt="">
        </div>
        <div class="imageContainer">
            <span>Após uma breve explicação, clique em Iniciar.</span>
            <img src="assets/img/tutos/two-factor/two-factor2.png" alt="">
        </div>
        <div class="imageContainer">
            <span>Escolha a opção 'Ler um código de barras'.</span>
            <img src="assets/img/tutos/two-factor/two-factor3.png" alt="">
        </div>
        <div class="imageContainer">
            <span>Mire a câmera do seu celular para o código de barras disponível.</span>
            <img src="assets/img/tutos/two-factor/two-factor4.png" alt="">
        </div>
        <div class="imageContainer">
            <span>Pronto! Agora você tem a chave de acesso no seu dispositivo móvel.</span>
            <img src="assets/img/tutos/two-factor/two-factor5.png" alt="">
        </div>
    </div>
</div>
