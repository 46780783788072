

import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { ChartsServices } from './../charts.services';

declare let AmCharts: any;
declare let moment: any;

@Component({
    selector: 'freight-rates-chart',
    templateUrl: './freight-rates.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class FreightRatesComponent implements AfterViewInit {

    public freightRatesChart: any;

    constructor(
        public chartService: ChartsServices,
        public router: Router,
        private http: HttpClient,
    ) { }

    public generateData = () => {
        const data = [];
        const today = new Date().getTime();

        function monthAfter(receivedDate: Date) {
            const nextMonth = new Date(receivedDate.getTime());
            nextMonth.setMonth(receivedDate.getMonth() + 1);
            return nextMonth;
        }

        function random(floor: number, max: number) {
            const n = ((max - floor) + '').length;
            return floor + Math.round(Math.random() * Math.pow(10, n) % (max - floor));
        }

        let date = monthAfter(new Date(2016, 1, 1));

        while ((date.getTime() / 1000 || 0) <= (today / 1000 || 0)) {

            const open = random(2200, 2400);
            const close = random(2200, 2400);

            let low;
            if (open < close) {
                low = open - Math.round(Math.random() * 5);
            } else {
                low = close - Math.round(Math.random() * 5);
            }

            let high;
            if (open < close) {
                high = close + Math.round(Math.random() * 5);
            } else {
                high = open + Math.round(Math.random() * 5);
            }

            data.push({
                date: moment(date).format('YYYY-MM-DD'),
                open,
                close,
                low,
                high,
            });

            date = monthAfter(date);
        }
        return data;
    }

    public ngAfterViewInit() {
        this.freightRatesChart = this.initFreightRatesChart(null);
    }

    public initFreightRatesChart(event: any) {
        return AmCharts.makeChart('freightRates', {
            type: 'stock',
            fontSize: 12,
            fontFamily: 'Poppins; sans serif',
            marginLeft: 30,
            language: 'pt',
            theme: 'light',
            dataSets: [{
                fieldMappings: [{
                    fromField: 'open',
                    toField: 'open',
                }, {
                    fromField: 'close',
                    toField: 'close',
                }, {
                    fromField: 'high',
                    toField: 'high',
                }, {
                    fromField: 'low',
                    toField: 'low',
                }, {
                    fromField: 'volume',
                    toField: 'volume',
                }, {
                    fromField: 'value',
                    toField: 'value',
                }],
                color: '#08a3cc',
                dataProvider: this.generateData(),
                categoryField: 'date',
            }],
            balloon: {
                horizontalPadding: 13,
            },
            chartCursorSettings: {
                zoomable: !1,
                categoryBalloonDateFormats: [{
                    period: 'DD',
                    format: 'DD',
                }, {
                    period: 'WW',
                    format: 'MMM',
                }, {
                    period: 'MM',
                    format: 'MMM',
                }, {
                    period: 'YYYY',
                    format: 'YYYY',
                }],
                cursorAlpha: 0,
                categoryBalloonColor: '#e26a6a',
                categoryBalloonAlpha: 1,
                valueBalloonsEnabled: !1,
            },
            categoryAxesSettings: {
                minPeriod: 'MM',
            },
            panels: [{
                title: 'Value',
                fontSize: 12,
                fontFamily: 'Poppins; sans serif',
                marginLeft: 30,
                thousandsSeparator: '.',
                stockGraphs: [{
                    id: 'g1',
                    type: 'candlestick',
                    openField: 'open',
                    closeField: 'close',
                    highField: 'high',
                    lowField: 'low',
                    valueField: 'close',
                    lineColor: '#08a3cc',
                    fillColors: '#08a3cc',
                    negativeLineColor: '#db4c3c',
                    negativeFillColors: '#db4c3c',
                    fillAlphas: 1,
                    balloonText:
"<span style='font-size:12px;'>Abertura:</span> <span style='font-size:20px;'><b>[[open]]</b></span><br/>" +
"<span style='font-size:12px;'>Fechamento:</span> <span style='font-size:20px;'><b>[[close]]</b></span><br/>" +
"<span style='font-size:12px;'>Mínimo:</span> <span style='font-size:20px;'><b>[[low]]</b></span><br/>" +
"<span style='font-size:12px;'>Máximo:</span> <span style='font-size:20px;'><b>[[high]]</b></span>",
                    useDataSetColors: false,
                }],
            }],
            scrollBarSettings: {
                graphType: 'line',
                usePeriod: 'MM',
            },
            panelsSettings: {
                panEventsEnabled: true,
            },
            cursorSettings: {
                valueBalloonsEnabled: true,
                valueLineBalloonEnabled: true,
                valueLineEnabled: true,
                categoryBalloonColor: '#e26a6a',
            },
            periodSelector: {
                position: 'bottom',
                dateFormat: 'MM/YYYY',
                fromText: 'De: ',
                toText: 'Até: ',
                periods: [{
                    period: 'YYYY',
                    count: 1,
                    label: '1 ano',
                }, {
                    period: 'YTD',
                    label: 'YTD',
                }, {
                    period: 'MAX',
                    label: 'MÁX',
                }],
            },
        });
    }

}
