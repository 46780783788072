import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, Renderer2, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { environment } from '#environment';

import { REFERENCE_DATE_TYPES } from 'app/shared/constants/reference-date-types.constants';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import { MARKER_FILTER_TYPES } from 'app/shared/components/cmx-marker-filter/constants/marker-filter-types.const';
import { IMarkerFilter } from 'app/shared/components/cmx-marker-filter/interfaces/marker-filter.interface';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { StatusConsolidationFilter } from 'app/theme/layouts/charts/status-consolidation/statusConsolidationFilter.model';

import { UserService } from 'app/auth/_services';
import { SkuStatusService } from '../sku-status/sku-status.service';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { TransportModalService } from '#services/_transportModal/transportModal.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UtilsService } from '#services/_utils/utils.service';
import { ChassiStatusService } from 'app/modules/operational/pages/chassi-status/chassi-status.service';
import { ChassiStatusFilter } from 'app/modules/operational/pages/chassi-status/chassiStatusFilter.model';
import { ImpStatusService } from 'app/modules/operational/pages/imp-status/imp-status.service';
import { ImpStatusFilter } from 'app/modules/operational/pages/imp-status/impStatusFilter.model';
import { SkuImportStatusFilter } from 'app/modules/operational/pages/sku-status/skuImportStatusFilter.model';
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';
import { FupChassiFilter } from 'app/theme/pages/reports/report-followup/report-followup-filter/fupChassiFilter.model';
import { FupSkuFilter } from 'app/theme/pages/reports/report-followup/report-followup-filter/fupSkuFilter.model';
import { FupImpFilter } from 'app/theme/pages/reports/report-followup/report-followup-filter/fupImpFilter.model';

@Component({
    selector: 'status-consolidation-widget',
    templateUrl: './status-consolidation.component.html',
    styleUrls: ['./../charts.scss', './status-consolidation.component.scss'],
    providers: [ImpStatusService, SkuStatusService, ChassiStatusService],
    encapsulation: ViewEncapsulation.None,
})
export class StatusConsolidationComponent implements AfterViewInit, OnDestroy {
    readonly dropdownSettingsReferenceDateType = {
        singleSelection: true,
        text: 'Selecionar',
    };

    currentUser = this._userService.getCurrentUser();
    currentUser$;
    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);
    asyncConsolidation = true;
    subscription: Subscription;
    errorMessage: string = '';
    validDate: boolean = true;
    totalPercentage = 0;
    allStatus = [];
    filterState: StatusConsolidationFilter = this._filterStateService.getStatusConsolidationFilter();
    helpDescription = this._defineHelpDescription();

    defaultColor = '#14509b';

    allTypes = [
        { name: 'IMP', icon: 'directions_boat', type: 'material', permission: 'DASHBOARD_COMPONENT_IMP_STATUS', checked: true },
        { name: 'SKU', icon: 'comexport-package', type: 'awesome', permission: 'DASHBOARD_COMPONENT_SKU_STATUS', checked: false },
        { name: 'CHASSI', icon: 'directions_car', type: 'material', permission: 'DASHBOARD_COMPONENT_CHASSI_STATUS', checked: false },
    ];

    typeSelected = this.filterState.getTypeStatus();
    skuFilterState: SkuImportStatusFilter = this._filterStateService.getSkuImportStatusFilter();
    impFilterState: ImpStatusFilter = this._filterStateService.getImpStatusFilter();
    chassiFilterState: ChassiStatusFilter = this._filterStateService.getChassiStatusFilter();

    skuFupFilterState: FupSkuFilter = this._filterStateService.getFupSkuFilter();
    impFupFilterState: FupImpFilter = this._filterStateService.getFupImpFilter();
    chassiFupFilterState: FupChassiFilter = this._filterStateService.getFupChassiFilter();

    options: {
        client: DropdownOption[];
        referenceDateType: DropdownOption[];
        subsidiaryNames: DropdownOption[];
        status: DropdownOption[];
        modal: DropdownOption[];
    } = {
            client: [],
            referenceDateType: REFERENCE_DATE_TYPES,
            subsidiaryNames: [],
            status: [],
            modal: [],
        };

        @HostListener('click') onSetZIndex() {
            this.renderer2.setStyle(this._elementRef.nativeElement, 'z-index', this.utilsService.autoIncrementValue);
        }

    constructor(
        public router: Router,
        private http: HttpClient,
        private _userService: UserService,
        public _filterStateService: FilterStateService,
        private _skuStatusService: SkuStatusService,
        private _impStatusService: ImpStatusService,
        private _chassiStatusService: ChassiStatusService,
        private _cmxDropdownService: CmxDropdownService,
        private _userCacheService: UserCacheService,
        private _transportModalService: TransportModalService,
        private utilsService: UtilsService,
        private _elementRef: ElementRef,
        private renderer2: Renderer2,
        private _featureFlagService: FeatureFlagService
    ) {
        this.currentUser$ = this._userService.currentUser$;
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.getSubsidiaryNames().subscribe((data) => {
            this.options.subsidiaryNames = this._cmxDropdownService.toDropdownItems(data);
        });

        this.options.client = this._cmxDropdownService.toDropdownItems(this._userCacheService.getClientGroups());
        this.options.modal = this._cmxDropdownService.toDropdownItemsImg(_.cloneDeep(this._transportModalService.getModalList()));

        this.applyType({ name: this.typeSelected });
    }

    async applyType(event: any) {
        const type = event?.name || event;
        for (let status of this.allTypes) {
            if (status.name == type) {
                this.filterState.setTypeStatus(status.name);
                this.typeSelected = status.name;
                status.checked = true;
                if (type == 'IMP') { this._getImpStatus(); }
                if (type == 'SKU') { this._getSkuStatus(); }
                if (type == 'CHASSI') { this._getChassiStatus(); }
            } else {
                status.checked = false;
            }
        }
    }

    private _getStatus(status: any) {
        this.allStatus = this._getStatusList(status);
        this.totalPercentage = status.reduce(function (total, numero) {
            return total + numero.count;
        }, 0);
    }

    private _getStatusList(statusList: any): IMarkerFilter[] {
        return statusList.reduce((accumulator: IMarkerFilter[], currentValue: IMarkerFilter) => {
            const filter = {
                ...currentValue,
                ...MARKER_FILTER_TYPES['status'][currentValue.code],
            };

            return [...accumulator, filter];
        }, []);
    }

    private async _getImpStatus() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.asyncConsolidation = true;
        const request = this.filterState.getRequest('IMP');

        this.subscription = this._impStatusService.getImpStatusList(request).subscribe(
            (impStatusList) => {
                this._getStatus(impStatusList);
                this.asyncConsolidation = false;
            }, error => {
                this.error(error);
            }
        );
    }

    private async _getSkuStatus() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.asyncConsolidation = true;
        const request = this.filterState.getRequest('SKU');

        this.subscription = this._skuStatusService.getSkuStatusList(request).subscribe(
            (skuStatusList) => {
                this._getStatus(skuStatusList);
                this.asyncConsolidation = false;
            }, error => {
                this.error(error);
            }
        );
    }

    private async _getChassiStatus() {
        if (this.subscription) { this.subscription.unsubscribe() }
        this.errorMessage = '';
        this.asyncConsolidation = true;
        const request = this.filterState.getRequest('CHASSI');
        this.subscription = this._chassiStatusService.getChassiStatusList(request).subscribe(
            (chassiStatusList) => {
                this._getStatus(chassiStatusList);
                this.asyncConsolidation = false;
            }, error => {
                this.error(error);
            }
        );
    }

    private error(error: any) {
        this.asyncConsolidation = false;
        if (error.status === 404) {
            this.errorMessage = error.error.message;
        } else {
            this.errorMessage = 'Não foram encontrados registros'
        }
    }

    getValue(element) {
        return element.count;
    }

    getPercentage(element) {
        return (this.totalPercentage > 0 ? ((element.count / this.totalPercentage) * 100).toFixed(0) : 0 )
    }

    getTitle(element) {
        return element.name ? element.name : '';
    }

    redirectToFup(status) {
        if (this.typeSelected == 'IMP') {
            this.impFupFilterState.setSearch('');
            this.impFupFilterState.setModals(this.filterState.getModals());
            this.impFupFilterState.setProfitCenters([]);
            this.impFupFilterState.setClientGroups(this.filterState.getClientGroups());
            this.impFupFilterState.setSubsidiaryNames(this.filterState.getSubsidiaryNames());
            this.impFupFilterState.setReferenceDateType(this.filterState.getReferenceDateType());
            this.impFupFilterState.setStartDate(this.filterState.getStartDate());
            this.impFupFilterState.setEndDate(this.filterState.getEndDate());
            this.impFupFilterState.setStatus([{ name: status.name, code: status.code }]);
            this.router.navigate([`./report/followup-imp`]);
        } else if (this.typeSelected == 'SKU') {
            this.skuFupFilterState.setSearch('');
            this.skuFupFilterState.setSku('');
            this.skuFupFilterState.setModals(this.filterState.getModals());
            this.skuFupFilterState.setClientGroups(this.filterState.getClientGroups());
            this.skuFupFilterState.setStartDate(this.filterState.getStartDate());
            this.skuFupFilterState.setEndDate(this.filterState.getEndDate());
            this.skuFupFilterState.setStatus([{ name: status.name, code: status.code }]);
            this.router.navigate([`./report/followup-sku`]);
        } else {
            this.chassiFupFilterState.setSearch('');
            this.chassiFupFilterState.setChassi('');
            this.chassiFupFilterState.setClientGroups(this.filterState.getClientGroups());
            this.chassiFupFilterState.setStartDate(this.filterState.getStartDate());
            this.chassiFupFilterState.setEndDate(this.filterState.getEndDate());
            this.chassiFupFilterState.setStatus([{ name: status.name, code: status.code }]);
            this.router.navigate([`./report/followup-automotive`]);
        }
    }

    redirectToOperacional(status) {
        if (this.typeSelected == 'IMP') {
            this.impFilterState.setModals(this.filterState.getModals());
            this.impFilterState.setClientGroups(this.filterState.getClientGroups());
            this.impFilterState.setSubsidiaryNames(this.filterState.getSubsidiaryNames());
            this.impFilterState.setReferenceDateType(this.filterState.getReferenceDateType());
            this.impFilterState.setStartDate(this.filterState.getStartDate());
            this.impFilterState.setEndDate(this.filterState.getEndDate());
            this.impFilterState.setStatus([{ name: status.name, code: status.code }]);
            this.router.navigate([`./operacional/importacao`]);
        } else if (this.typeSelected == 'SKU') {
            this.skuFilterState.setModals(this.filterState.getModals());
            this.skuFilterState.setClientGroups(this.filterState.getClientGroups());
            this.skuFilterState.setStartDate(this.filterState.getStartDate());
            this.skuFilterState.setEndDate(this.filterState.getEndDate());
            this.skuFilterState.setStatus([{ name: status.name, code: status.code }]);
            this.router.navigate([`./operacional/sku`]);
        } else {
            this.chassiFilterState.setClientGroups(this.filterState.getClientGroups());
            this.chassiFilterState.setStartDate(this.filterState.getStartDate());
            this.chassiFilterState.setEndDate(this.filterState.getEndDate());
            this.chassiFilterState.setStatus([{ name: status.name, code: status.code }]);
            this.router.navigate([`./operacional/chassi`]);
        }
    }

    redirectTo(status) {
        this._featureFlagService.isFeatureFlagEnabledV2(FeatureFlagService.FEATURE_REDIRECT_STATUS_FUP)
            .then((isEnabled) => {
                if (isEnabled) {
                    this.redirectToFup(status);
                    return;
                }
                this.redirectToOperacional(status);
            })
            .catch(() => this.redirectToOperacional(status));
    }

    styleConsolidation() {
        return this.allStatus.length > 10 ? 'large' : 'small';
    }

    datesChanged(event) {
        this.filterState.setStartDate(event.startDate);
        this.filterState.setEndDate(event.endDate);
    }

    getSubsidiaryNames(): Observable<any> {
        return this.http.get(`${environment.endpoints.importationService}/imps/subsidiaryNames`);
    }

    private _defineHelpDescription(): object[] {
        return [
            { type: 'text', value: "Informa a quantidade de processo por status , ao acionar qualquer status o sistema automaticamente direciona para o detalhes permitindo buscar o processo específico."},
        ]
    }
}
