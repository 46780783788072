import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import { NotificationService } from '../_notification/notification.service';
import { Subject } from 'rxjs';

interface NumberFormatOptions {
    style?: 'decimal' | 'percent' | 'currency';
    locale?: string;
    currency?: string;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
}

@Injectable()
export class UtilsService {
    subject: Subject<any> = new Subject();

    constructor(private notificationService: NotificationService) { }

    get autoIncrementValue(){
        if(window.localStorage.getItem("autoIncrementValue") === null){
            window.localStorage.setItem("autoIncrementValue", "1000")
        }
        const getValue = parseInt(window.localStorage.getItem("autoIncrementValue"));
        window.localStorage.setItem("autoIncrementValue", (getValue + 1).toString());
        return getValue;
    }

    set destroyAutoIncrementValue(value){
        window.localStorage.removeItem("autoIncrementValue");
    }

    setInvisible(namespace: string, elements: string[]) {
        elements.forEach((element) => {
            if (!$(`${namespace} ${element}`).hasClass('invisible')) {
                $(`${namespace} ${element}`).addClass('invisible');
            }
        });
    }

    setVisible(namespace: string, elements: string[]) {
        elements.forEach((element) => {
            $(`${namespace} ${element}`).removeClass('invisible');
        });
    }

    capitalize(text: string): string {
        return `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;
    }

    prepareIdName(text: string): string {
        return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, '-');
    }

    convertLineBreak(text) {
        return (text && typeof text === 'string') ? text.replace(/!%!%/g, ' ') : text;
    }

    formatNumber(value: number | string, numberFormatOptions: NumberFormatOptions = {}): string {
        const style = numberFormatOptions.style || 'decimal';
        const locale = numberFormatOptions.locale || 'pt-br';
        const currency = numberFormatOptions.currency === 'none' ? 'BRL' : numberFormatOptions?.currency?.substring(0,3) || 'BRL';
        const minimumFractionDigits = !_.isNil(numberFormatOptions.minimumFractionDigits)
            ? numberFormatOptions.minimumFractionDigits
            : 2;
        const maximumFractionDigits = !_.isNil(numberFormatOptions.maximumFractionDigits)
            ? numberFormatOptions.maximumFractionDigits
            : 2;

        const options = {
            style,
            minimumFractionDigits,
            maximumFractionDigits,
        };

        let resultValue = value;

        if (typeof resultValue === 'string') {
            resultValue = parseFloat(resultValue.replace(/,/, '.'));
        }

        if (options.style === 'percent') {
            resultValue = resultValue / 100;
        } else if (options.style === 'currency') {
            _.assign(options, { currency });
        }

        const formatedValue = Intl.NumberFormat(locale, options).format(resultValue)
        const noneFormattedValue = numberFormatOptions.currency === 'none' ? formatedValue.substring(3) : formatedValue;
        return numberFormatOptions.currency === 'none' && resultValue < 0 ? `-${noneFormattedValue}` : noneFormattedValue;
    }

    formatCpfCnpj(value: string): string {
        const cleanValue = value.replace(/\D/g, '');
        if (cleanValue.length === 14) {
            return cleanValue.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
        } else if (cleanValue.length === 11) {
            return cleanValue.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
        }
        return value;
    }

    formatTimestamp(timestamp: string): string {
        const dateObj = new Date(timestamp);
        const formattedDate = `${this.padZero(dateObj.getDate())}/${this.padZero(dateObj.getMonth() + 1)}/${dateObj.getFullYear()} - ${this.padZero(dateObj.getHours())}:${this.padZero(dateObj.getMinutes())}`;
        return formattedDate;
    }

    padZero(value: number): string {
        return value < 10 ? `0${value}` : `${value}`;
    }

    generateGUID(prefix = ''): string {
        function S4(): string {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return `${prefix}-${S4() + S4()}`;
    }

    isIE(): boolean {
        return (
            navigator.userAgent.indexOf('MSIE') !== -1 ||
            navigator.appVersion.indexOf('Trident/') > -1 ||
            window.navigator.userAgent.indexOf('Edge') > -1
        );
    }

    errorHandler(error, component?, backMessage?, timer?: number) {
        const title = component ? `${component} • Erro ${error?.status}` : `Erro ${error?.status}`;
        const objectError = error?.error?.ApiError || error?.error || null;
        const errorMessage = objectError ? objectError?.message : null;

        let message = errorMessage || (() => {
            switch (error.status) {
                case 400:
                    return 'Parâmetros não encontrados.';
                case 401:
                    return 'Encontramos um problema de autenticação.';
                case 403:
                    return 'Seu usuário não possui uma ou mais permissões.';
                case 404:
                    return 'Serviço não encontrado.';
                case 405:
                    return 'Método não permitido.';
                case 408:
                    return 'Estamos com problemas de conexão.';
                case 409:
                    return 'Houve um conflito/duplicação na base de dados.';
                case 500:
                    return 'Algo deu Errado.';
                case 502:
                    return 'O serviço está indisponível no momento.';
                case 412:
                    return error.error[0].message;
                default:
                    return (backMessage ? error.error[0].message : 'Erro desconhecido.');
            }
        });
        this.notificationService.openNotification('error', message, title, timer);
    }

    sortObjectArrayBy(array, atribute) {
        return array.sort((a, b) => a[atribute] > b[atribute] ? 1 : a[atribute] === b[atribute] ? 0 : -1);
    }

    changeObjectArrayIndex(array, fromIndex, toIndex) {
        const element = array[fromIndex];
        array.splice(fromIndex, 1);
        array.splice(toIndex, 0, element);
    }

    manageBarForUrl(url, decode?): string {
        return url ? url.replaceAll(decode ? '-@!-' : '/', decode? '/' : '-@!-') : '';
    }

    getTopDistanceByElement(componentId, bottomDistance?: number): number {
        const component = document.getElementById(componentId).getBoundingClientRect()
        const topDistance = component.top + (bottomDistance || 20);
        return topDistance;
    }

    normalizeString(str: string): string {
        return str?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')?.toLowerCase();
    }

    findInvalidInputsForm(form) {
        let invalidList = [];
        const controlList = form?.controls;
        if (controlList) {
            Object.keys(controlList).forEach((key) => {
                if (controlList[key]?.status === 'INVALID') {
                    invalidList.push(key)
                }
            })
        }
        console.error('Campos Inválidos na Submissão do Formulário: ', invalidList);
    }
}
