import { Injectable } from '@angular/core';
import { FORMATTERS } from '../formatters/_formatters';
import { IGridColumn } from '../interfaces';
import { CustomGridError } from '../models/custom-grid-error.model';
import Inputmask from 'inputmask';
import * as _ from 'lodash';
import { CustomGridHeaderDropdownService } from './custom-grid-header-dropdown';

@Injectable()
export class CustomGridColumnsService {

    gridColumns: IGridColumn[] = [];

    constructor(private _customGridHeaderDropdownService: CustomGridHeaderDropdownService) { }

    createColumns(columnList): any[] {
        this.gridColumns = columnList?.map((column, index) => {
            return this._formatExchangeableHeader(
                   this.formatHeader(
                   this._defineColumn(column, index), index))
        }).filter(this._isVisibleColumn).sort(this._sortColumnByOrder);
        return this.gridColumns;
    }

    defineColumnAutoWidth(columnList, rowList) {
        let atualGroup = '';
        let totalGroupColumnsWidth = 0;
        columnList.forEach((column, index) => {
            if (column.autoWidth) {
                if (column.groupName !== atualGroup) {
                    atualGroup = column.groupName;
                    totalGroupColumnsWidth = 0;
                }
                let stringLength = 0;
                rowList.forEach((row) => {
                    let value = row[column.field];
                    if (Array.isArray(value)) { if (value.length === 1) {
                        value = value[0];
                    }}
                    if (typeof value === 'string') {
                        stringLength = value.length > stringLength ? value.length : stringLength;
                    }
                    if (typeof value === 'number') {
                        const cloneValue = JSON.stringify(value);
                        stringLength = cloneValue.toString()?.length > stringLength ? cloneValue.toString().length : stringLength;
                    }
                    if (typeof value === 'object' && column?.formatterData?.singleField) {
                        stringLength = value[column?.formatterData?.singleField]?.length > stringLength ? value[column?.formatterData?.singleField]?.length : stringLength;
                    }
                })
                const groupMinWidth = ([...column.groupName || []]?.length * ([...column.groupName || []]?.length > 100 ? 6 : 7)) + 35;
                const measuredWidth = (stringLength * 7) + 35;
                totalGroupColumnsWidth += measuredWidth;
                let headerMeasuredWidth = column.autoHeaderName ? (column.autoHeaderName.length * (column.autoHeaderName.length > 100 ? 6 : 7)) + 30 : 0;
                if (columnList[index + 1]?.groupName !== atualGroup) {
                    headerMeasuredWidth = totalGroupColumnsWidth > groupMinWidth ? headerMeasuredWidth : groupMinWidth;
                }
                if (column.isSortable) headerMeasuredWidth += 25;
                if (column.isFilterable) headerMeasuredWidth += 25;
                column.width = column.minAutoWidth && column.minAutoWidth > measuredWidth ? column.minAutoWidth :
                    column.maxAutoWidth && column.maxAutoWidth < measuredWidth ? column.maxAutoWidth :
                    measuredWidth > headerMeasuredWidth ? measuredWidth : headerMeasuredWidth;
            }
        })
    }

    formatHeader(column: IGridColumn, index): IGridColumn {
        if (column.preSortable) {
            column.sort = column.preSortable;
            column.preSortable = null;
        }

        let sortableText = '';
        if (column.isSortable) {
            if (!column.sortName) column.sortName = column.name;
            if (column.sort === 'desc') {
                sortableText = "<i class='la la-arrow-up sortColumn'></i>";
            } else if (column.sort === 'asc') {
                sortableText = "<i class='la la-arrow-down sortColumn'></i>";
            } else {
                sortableText = "<i class='default sortColumn'>•</i>"
            }
        }

        let infoContainer = '';
        if (column.informative) {
            infoContainer = `<span id="tooltip-${column.field}" class='material-icons info'>info
            <div class="informative">${column.informative}</div><div class="arrow"></div></span>`;
        }

        if ((column.isFilterable || column.isDropdownFilterable) && !column.filterName) column.filterName = column.name;

        const iconSearch = column.isDropdownFilterable ? 'youtube_searched_for' : 'search';
        const rightIcon = column.selectedDropdownOptions?.length ? `<div class="dropdown-length">( ${
            column.selectedDropdownOptions.length > 99 ? '99+' : column.selectedDropdownOptions.length
            } ) <div class="cancelFilter cmx-grid-dropdown-clear"><i class="material-icons">close</i></div></div>`
            : "<span class='material-icons search'>" + iconSearch + "</span>";
        const id = column.field?.replace(/\|/g, "*");

        let dropdownFilter = '';
        if (column.isDropdownFilterable && column.dropdownOptions && column.filtering) {
            dropdownFilter = this._customGridHeaderDropdownService.defineDropdown(column, index);
            column.name = `<div id="${id}" class="headerItem ${column.headerCssClass
            }" style="display:flex">` + `${sortableText}` + `<span style="margin-left: ${column.isSortable ?
            '25px' : '0px'}; margin-right: ${column.isFilterable || column.isDropdownFilterable ? '5px' : '0px' }">${
            column.filterName || column.sortName || column.name }</span>` + '' + '<div class="cancelFilter cmx-grid-dropdown-clear" style="right: 7px; top: 8px"><i class="material-icons">close</i></div>'
            + "</div>" + dropdownFilter;
        } else if (column.isFilterable && column.filtering) {
            column.name = `<div id="${id}" class="headerItem ${column.headerCssClass}">${this._createFilterInputs(column)}
            <div class="cancelFilter"><i class="material-icons">close</i></div></div>`;
        } else {
            column.name = `<div id="${id}" class="headerItem ${column.headerCssClass
            }" style="display:flex">` + `${sortableText}` + `<span style="margin-left: ${column.isSortable ?
            '25px' : '0px'}; margin-right: ${column.isFilterable || column.isDropdownFilterable ? '5px' : '0px' }">${
            column.filterName || column.sortName || column.name }</span>` + `${column.isFilterable || column.isDropdownFilterable ?
            rightIcon : ''} ${infoContainer}` + "</div>";
        }

        if (column.filtering) {
            if (!column.acceptSpecialCharacters) {
                setTimeout(()=> {
                    let mask;
                    if (column.filterType) {
                        if (column.filterType == 'date') {
                            mask = Inputmask({ mask: '99/99/9999' });
                        } else if (column.filterType == 'hours') {
                            mask = Inputmask({ mask: '99:99' });
                        } else if (column.filterType == 'currency') {
                            const filterPlaceholder = column.filterPlaceholder || '0,00';
                            const filterDigits = column.filterDigits || '2';
                            const filterRadixPoint = column.filterRadixPoint || ',';
                            const allowNegative = column.allowNegative || false;
                            mask = Inputmask('decimal', {
                                numericInput: true,
                                alias: 'numeric',
                                groupSeparator: '.',
                                autoGroup: true,
                                digits: filterDigits,
                                radixPoint: filterRadixPoint,
                                digitsOptional: false,
                                allowMinus: allowNegative,
                                placeholder: filterPlaceholder,
                            });
                            if (!column.filterRadixPoint) {
                                this._applyCurrencyFilterVal(column, 2);
                            }
                        } else if (column.filterType == 'currency_exchange') {
                            mask = Inputmask('decimal', {
                                numericInput: true,
                                alias: 'numeric',
                                groupSeparator: '.',
                                autoGroup: true,
                                digits: '4',
                                radixPoint: ',',
                                digitsOptional: false,
                                allowMinus: false,
                                placeholder: '0,0000',
                            });
                            this._applyCurrencyFilterVal(column, 4);
                        } else if (column.filterType == 'only_numbers'){
                            mask = Inputmask({ regex: "[0-9-.,]*" });
                        }
                    } else {
                        mask = Inputmask({ regex: "[A-Z-a-zÀ-ú0-9/., ]*" });
                    }
                    this._applyFilterMask(mask, column);
                }, 0);
            }
            setTimeout(() => {
                this._customGridHeaderDropdownService.dropdownFilterSearch(index);
                this._customGridHeaderDropdownService.dropdownSelection(index);
                this._customGridHeaderDropdownService.dropdownApply(column, index);
            }, 100);
        }
        if (column.isDropdownFilterable) {
            setTimeout(() => { this._customGridHeaderDropdownService.dropdownClear(column, index); }, 100)
        }

        return column;
    }

    resetColumnFilters(column: IGridColumn): IGridColumn {
        column.filtering = false;
        column.filter = column.filterSelectionMode === 'range' ? ['', ''] :'';
        return column;
    }

    private _defineColumn(column: IGridColumn, index: number): IGridColumn {
        const columnData: IGridColumn = {
            id: index,
            name: column.name,
            groupName: column.groupName,
            field: column.field,
            order: index,
            hidden: false,
            formatter: FORMATTERS['default'].bind(this),
            headerCssClass: 'grid-header',
            cssClass: 'grid-cell',
        };

        if (column.formatterType) {
            columnData['formatterType'] = column.formatterType;
        }

        for (const [key, value] of _.toPairs(column)) {
            if (key === 'formatterType') {
                if (!FORMATTERS[value]) {
                    throw new CustomGridError(`[${value}] is not a valid formatter type!`);
                }

                _.assign(columnData, {
                    formatter: FORMATTERS[value].bind(this),
                });
            } else {
                _.assign(columnData, {
                    [key]: value,
                });
            }
        }
        return columnData;
    }

    private _applyFilterMask(mask: any, column: IGridColumn): void {
        if (column.filterSelectionMode === 'range') {
            mask.mask($(`cmx-custom-grid input#${column.field}-input-start`));
            mask.mask($(`cmx-custom-grid input#${column.field}-input-end`));
        } else {
            mask.mask($(`cmx-custom-grid input#${column.field}-input`));
        }
    }

    private _applyCurrencyFilterVal(column: IGridColumn, digits: number): void {
        if (column.filterSelectionMode === 'range') {
            $(`cmx-custom-grid input#${column.field}-input-start`).val(parseFloat((column.filter[0].replaceAll('.', '').replace(',', '.'))).toFixed(digits));
            $(`cmx-custom-grid input#${column.field}-input-end`).val(parseFloat((column.filter[1].replaceAll('.', '').replace(',', '.'))).toFixed(digits));
        } else {
            $(`cmx-custom-grid input#${column.field}-input`)?.val(parseFloat((column?.filter?.replaceAll('.', '')?.replace(',', '.')))?.toFixed(digits));
        }
    }

    private _createFilterInputs(column: IGridColumn): string {
        let inputs, headerHeight;
        if (column.filterSelectionMode === 'range') {
            inputs = `<div class="rangeInputs"><input id="${column.field}-input-start" type="text" placeholder="De: " title="${column.filterName}" value="${column.filter[0] || ''}">
            <input id="${column.field}-input-end" type="text" placeholder="Até: " title="${column.filterName}" value="${column.filter[1] || ''}"></div>`;
            headerHeight = "60px";
        } else {
            inputs = `<input id="${column.field}-input" type="text" placeholder="${column.filterName}" value="${column.filter || ''}">`;
            headerHeight = "16px";
        }
        setTimeout(() => {
            $('.slick-header-columns .slick-header-column').css('height', headerHeight);
        }, 200);
        return inputs;
    }
    private _sortColumnByOrder(column1: IGridColumn, column2: IGridColumn): number {
        return column1.order - column2.order;
    }

    private _isVisibleColumn(column: IGridColumn): boolean {
        return !column.hidden;
    }

    private _formatExchangeableHeader(column: IGridColumn): IGridColumn {
        if (column.isExchangeable) {
            column.name = `${column.name}<span class="material-icons exchange">loop</span>`
        }
        return column;
    }

}
