<span class="material-icons cmx-help-icon" (click)="openDetailModal()">{{ helpIcon }}</span>
<cmx-custom-dialog [id]="helpId" overlay="true" animation="scale-center" content="window" [widthModal]="helpDialogWidth"
    [maxHeightModal]="helpDialogHeight" modalType="info" heightModal="fit-content" style="z-index: 1600;">
    <ng-container cmx-custom-dialog-header>
        <div class="title-modal">
            <span class="material-icons help-dialog-info" aria-hidden="true">{{ helpIcon }}</span>&nbsp; {{ helpTitle }}
        </div>
    </ng-container>
    <ng-container cmx-custom-dialog-body *ngIf="!loading">
        <div *ngFor="let info of helpDescription" class="help-dialog-info {{ info.type }}">
            <h5 class="info-title">{{ info.title }}</h5>
            <p class="info-paragraph">{{ info.paragraph }}</p>
            <p class="info-description"><b class="info-green">{{ info.green }}</b><b class="info-red">{{ info.red }}</b>
                <b class="info-yellow">{{ info.yellow }}</b><b class="info-grey">{{ info.grey }}</b> {{ info.description }}</p>
            <p class="info-secondary-text"><b class="info-text-bold">{{ info.textBold }}</b> {{ info.secondaryText }}</p>
            <span *ngIf="info.type === 'text'"> {{ info.value }} </span>
            <ul *ngIf="info.type === 'list'" class="info-list"> <li>{{ info.list }}</li></ul>
            <cmx-custom-grid
                *ngIf="info.type === 'table' && renderTable"
                [columns]="info.columns"
                [rows]="info.rows"
                id="help-dialog-custom-grid"
                [options]="{ forceFitColumns: true, autoHeight: true }"
            ></cmx-custom-grid>
            <hr *ngIf="info.hasLineAfter">
        </div>
    </ng-container>
</cmx-custom-dialog>
