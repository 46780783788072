<div *ngIf="asyncDocuments">
    <loader></loader>
</div>

<div class="sp-documents-container" *ngIf="!asyncDocuments">
    <div class="documents-container">
        <div *ngIf="!asyncDocuments && gridRows?.length < 1" class="noContent">Não foram encontrados documentos</div>

        <cmx-custom-grid
            *ngIf="!initialGridLoading"
            id="sp-documents-grid"
            class="sp-documents-grid"
            [loading]="asyncDocuments"
            [columns]="gridColumns"
            [rows]="gridRows"
            [options]="{forceFitColumns: true}"
            (onCellClick)="onCellClick($event)"
            (onClose)="onClose($event)"
            [styles]="{ height: 50 + (35 * gridRows?.length) + 'px', maxHeight: 'calc(100vh - 405px)', marginTop: 0, marginBottom: 0 }"
            [style.visibility]="gridRows?.length > 0 ? 'visible' : 'hidden'"
        ></cmx-custom-grid>
    </div>
    <div class="action-buttons" *ngIf="!asyncDocuments">
        <cmx-button variant="historic" (click)="openLogModal()">Histórico</cmx-button>
        <cmx-button variant="primary" [disabled]="status?.code != 'OPENED'" (click)="openConfirmationModal()">{{documentStatus != 'Aprovado' ? 'Aprovar' : 'Desfazer Aprovação'}}</cmx-button>
    </div>
</div>

<cmx-confirmation-modal
    id="confirmApproved"
    question="Deseja seguir com a aprovação?"
    labelAction="Confirmar"
    [hidden]="!showApprovedModal"
    (onConfirm)="confirmReview()"
    (onCancel)="showApprovedModal = false"
></cmx-confirmation-modal>

<cmx-confirmation-modal
    id="undoApproval"
    question="Deseja desfazer a aprovação?"
    labelAction="Confirmar"
    [hidden]="!showUndoApprovedModal"
    (onConfirm)="confirmUndoApproval()"
    (onCancel)="showUndoApprovedModal = false"
></cmx-confirmation-modal>

<cmx-custom-dialog [id]="'sp-documents-cmx-custom-dialog-view'" animation="right-left" [title]="'Visualizar Documento'" [widthModal]="90" [closeButton]="true" [overlay]="true">
    <ng-container cmx-custom-dialog-body>
        <div *ngIf="pdfData !== undefined" class="view-file-container">
            <div *ngIf="asyncPdf"><loader></loader></div>
            <embed *ngIf="!asyncPdf && pdfData !== 'noPdf'" width="100%" height="100%" [src]='pdfData' type='application/pdf'>
            <img style="width: 40%;" *ngIf="!asyncPdf && pdfData === 'noPdf'" src="../../../../../../assets/app/media/img/icons/noPdf.png" alt="">
        </div>
    </ng-container>
</cmx-custom-dialog>

<cmx-custom-dialog [id]="'sp-documents-cmx-custom-dialog-log'" animation="right-left" [title]="'Histórico de Documentos'" [internalFade]="true" [closeButton]="true" [overlay]="true">
    <ng-container cmx-custom-dialog-body>
        <loader *ngIf="asyncHistoric"></loader>
        <span *ngIf="!asyncHistoric && !historicList.length">Não existe histórico para este documento</span>
        <div *ngFor="let historic of historicList" class="log-container">
            <div class="info-container">
                <span class="historic-message">{{ historic.message }}</span>
                <span class="historic-date">{{ historic.creation | date: 'dd/MM/yyyy' }} às {{ historic.creation | date: 'HH:mm' }}</span>
            </div>
            <hr>
        </div>
    </ng-container>
</cmx-custom-dialog>
