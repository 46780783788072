import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { CustomDialogService } from './custom-dialog.service';
import { Animations } from './types/animations.type';
import { Contents } from './types/contents.type';
import * as _ from 'lodash';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UserCacheService } from '../../../services/_user/app-user-cache.service';

@Component({
    selector: 'cmx-custom-dialog',
    templateUrl: './custom-dialog.component.html',
    styleUrls: ['./custom-dialog.component.scss'],
    animations: [
        trigger('anCustomModal', [
            state('show', style({transform:'translateX(0)', opacity: 1})),
            transition(':enter', [
                animate('.2s ease-out'),
            ]),
            transition(':leave', [
                animate('.1s ease-in')
            ])

        ]),
        trigger('anCustomModalOverlay', [
            state('true', style({ opacity: 1 })),
            state('false', style({ opacity: 0 })),
            transition('false <=> true', animate('.3s ease-in-out'))
        ])
    ]
})
export class CustomDialogComponent {
    @Input() id: string;
    @Input() title: string;
    @Input() subtitle: string;
    @Input() childrenDialogId?: string | string[];
    @Input() closeButton: boolean = true;
    @Input() content: Contents = 'default';
    @Input() animation: Animations = 'left-right';
    @Input() overlay: boolean = false;
    @Input() customColorBorderTop: string = null;
    @Input() heightModal: string = null;
    @Input() widthModal: string = null;
    @Input() minHeightModal: number = null;
    @Input() maxHeightModal: number = null;
    @Input() maxWidthModal: number = null;
    @Input() internalFade: boolean = false;
    @Input() hideHeader: boolean = false;
    @Input() subtitleClickable: boolean = false;
    @Input() modalType: string = 'default';

    @Output('modal-closed') modalClosed: EventEmitter<any> = new EventEmitter<any>(null);
    @Output('subtitleClick') subtitleClick = new EventEmitter();

    @Output() beforeOpen = new EventEmitter<void>();

    fade: boolean;
    hidden: boolean = true;
    dinamicStyles: {
        left: string;
        right: string;
        height: string;
        width: string;
        minHeight: string;
        maxHeight: string;
        maxWidth: string;
    };

    private _element: any;

    constructor(
        private _customDialogService: CustomDialogService,
        public userCacheService: UserCacheService,
        private _el: ElementRef) {
        this._element = this._el.nativeElement;
    }

    ngOnInit(): void {
        if (!this.id) {
            console.error('Dialog must have an id');
            return;
        }

        this.fade = _.cloneDeep(this.internalFade);
        this._customDialogService.add(this);
        this._setDinamicStyles();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('heightModal' in changes) {
            this.heightModal = changes.heightModal.currentValue;
            this._setDinamicStyles();
        }
        if ('widthModal' in changes) {
            this.widthModal = changes.widthModal.currentValue;
            this._setDinamicStyles();
        }
    }

    ngOnDestroy(): void {
        this._customDialogService.remove(this.id);
        this._element.remove();
    }

    open(): void {
        this.hidden = false;
        this.onBeforeOpen();
        if (this.internalFade) {
            setTimeout(() => {
                this.fade = false;
            }, 1000);
        }
    }

    close(): void {
        this.hidden = true;
        if (this.internalFade) {
            this.fade = true;
        }
        this.modalClosed.emit(this.id);
        this._closeChildrenDialogs();
    }

    subClick() {
        this.subtitleClick.emit();
    }

    private _closeChildrenDialogs(): void {
        if (this.childrenDialogId) {
            if (typeof this.childrenDialogId === 'string') {
                this._customDialogService.close(this.childrenDialogId);
            } else {
                for (const childDialogId of this.childrenDialogId) {
                    this._customDialogService.close(childDialogId);
                }
            }
        }
    }

    private _setDinamicStyles(): void {

        const typeHeight = typeof this.heightModal === 'string' ? '' : 'px';

        this.dinamicStyles = {
            left: '0px',
            right: '0px',
            height: this.heightModal ? this.heightModal + typeHeight : null,
            width: this.widthModal ?
                this.widthModal?.toString()?.includes('px') ? this.widthModal :
                this.widthModal + 'vw' : null,
            minHeight: this.minHeightModal ? this.minHeightModal + 'px' : 'auto',
            maxHeight: this.maxHeightModal ? this.maxHeightModal + 'vh' : 'auto',
            maxWidth: this.maxWidthModal ? this.maxWidthModal + 'px' : null
        };

        if (this.animation === 'left-right') {
            this.dinamicStyles.right = 'unset';
        }
        if (this.animation === 'right-left') {
            this.dinamicStyles.left = 'unset';
        }
    }

    onBeforeOpen() {
        this.beforeOpen.emit();
    }
}
