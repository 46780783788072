import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

type Placements = 'top' | 'right' | 'bottom' | 'left';

@Directive({
    selector: '[tooltip]',
})
export class CMXTooltipDirective {
    @Input('tooltip') tooltipTitle: string;
    @Input() placement?: Placements = 'top';
    @Input() nowrap?: boolean = false;
    @Input() delay?: number = 300;
    @Input() checkEllipsisActive?: boolean = false;
    @Input() size?: string = 'lg';

    private _tooltip: HTMLElement;

    constructor(private _el: ElementRef, private _renderer: Renderer2) { }

    @HostListener('mouseenter') onMouseEnter() {
        if (this.tooltipTitle && !this._tooltip) {
            if ((this.checkEllipsisActive && this._isEllipsisActive()) || !this.checkEllipsisActive) {
                this._show();
            }
        }
    }

    @HostListener('mouseleave') onMouseLeave() {
        if (this._tooltip) {
            this._hide();
        }
    }

    @HostListener('click', ['$event'])
    onClick(event: any): void {
        if (this._tooltip) {
            this._hide();
        }
        event.stopPropagation();
    }

    private _show() {
        if (this._tooltip) {
            this._hide();
            setTimeout(() => { this._create() }, this.delay + 100);
        } else {
            this._create();
            this._setPosition();
            this._setClass('cmx-tooltip-show');
        }
    }

    private _hide() {
        this._renderer.removeClass(this._tooltip, 'cmx-tooltip-show');
        window.setTimeout(() => {
            if (this._tooltip){
                this._renderer.removeChild(document.body, this._tooltip);
                this._tooltip = null;
            }
        }, this.delay);
    }

    private _create() {
        this._tooltip = this._renderer.createElement('span');
        this._renderer.appendChild(this._tooltip, this._renderer.createText(this.tooltipTitle));
        this._renderer.appendChild(document.body, this._tooltip);
        this._setClass('cmx-tooltip');
        this._setClass(`cmx-tooltip-${this.placement}`);
        this._setClass(`${this.size}`);
        if (this.nowrap) {
            this._setClass(`cmx-tooltip-nowrap`);
        }
        this._setStyle('transition', `opacity ${this.delay}ms`);
    }

    private _setStyle(property: string, value: any): void {
        if (property === 'transition') {
            const browserCompatibilitiesPrefix = ['-webkit-', '-moz-', '-o-', ''];
            for (const browserPrefix of browserCompatibilitiesPrefix) {
                this._renderer.setStyle(this._tooltip, `${browserPrefix}${property}`, value);
            }
        } else {
            this._renderer.setStyle(this._tooltip, property, value);
        }
    }

    private _setClass(name: string): void {
        this._renderer.addClass(this._tooltip, name);
    }

    private _setPosition(): void {
        const hostPosition = this._el.nativeElement.getBoundingClientRect();
        const tooltipPosisition = this._tooltip.getBoundingClientRect();
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const offset = 10;
        const positionsByPlacement = {
            top: [
                hostPosition.top - tooltipPosisition.height - offset,
                hostPosition.left + (hostPosition.width - tooltipPosisition.width) / 2,
            ],
            right: [
                hostPosition.top + (hostPosition.height - tooltipPosisition.height) / 2,
                hostPosition.right + offset,
            ],
            bottom: [
                hostPosition.bottom + offset,
                hostPosition.left + (hostPosition.width - tooltipPosisition.width) / 2,
            ],
            left: [
                hostPosition.top + (hostPosition.height - tooltipPosisition.height) / 2,
                hostPosition.left - tooltipPosisition.width - offset,
            ],
        };
        this._setStyle('top', `${positionsByPlacement[this.placement][0] + scrollPosition}px`);
        this._setStyle('left', `${positionsByPlacement[this.placement][1]}px`);
    }

    private _isEllipsisActive(): boolean {
        return this._el.nativeElement.offsetWidth < this._el.nativeElement.scrollWidth;
    }
}
