<div *ngIf="async">
    <loader></loader>
</div>

<div class="sp-banking-channels-container" *ngIf="!async">
    <div class="banking-channels-content">
        <div *ngIf="gridRows?.length < 1" class="noContent">Não foram encontrados canais bancarios</div>

        <cmx-custom-grid
            id="sp-banking-channels-grid"
            class="sp-banking-channels-grid"
            [columns]="gridColumns"
            [rows]="gridRows"
            [styles]="{ height: 50 + (35 * gridRows?.length) + 'px', maxHeight: 'calc(100vh - 405px)', marginTop: 0, marginBottom: 0 }"
            [style.visibility]="gridRows?.length > 0 ? 'visible' : 'hidden'"
        ></cmx-custom-grid>
    </div>
</div>
