import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { LayoutModule } from '../../layouts/layout.module';
import { ChartModule } from 'primeng/chart';

import { SharedModule } from '../../../shared/shared.module';
import { QuotationFreightRatesComponent } from './pages/quotation-freight-rates/quotation-freight-rates.component';
import { FreightRatesService } from './services/freight-rates.service';
import { NationalFreightRatesComponent } from './pages/quotation-freight-rates/national/national-freight-rates.component';
import { InternationalFreightRatesComponent } from './pages/quotation-freight-rates/international/international-freight-rates.component';
import { InternationalFreightService } from './services/international-freight.service';
import {MultiSelectModule} from 'primeng/multiselect';


const routes: Routes = [
  {
    path: '',
    component: QuotationFreightRatesComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    LayoutModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ChartModule,
    MultiSelectModule
  ],
  declarations: [QuotationFreightRatesComponent, NationalFreightRatesComponent, InternationalFreightRatesComponent],
  exports: [QuotationFreightRatesComponent, NationalFreightRatesComponent, InternationalFreightRatesComponent],
  providers: [FreightRatesService, InternationalFreightService],
})
export class FreightRatesModule {}
