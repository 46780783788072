import { GetterSetter } from 'app/shared/decorators/getter-setter.decorator';

import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';

import { DESTINATION_COUNTRIES } from '../constants/destination-countries.constant';
import { STAGES } from '../constants/stages.constant';

export class DamageFilter {

    @GetterSetter([])
    clients: DropdownOption[];

    @GetterSetter(DESTINATION_COUNTRIES)
    destinationCountries: any[];

    @GetterSetter()
    originCountries: any[];

    @GetterSetter('LAST_12_MONTHS')
    periodType: string;

    @GetterSetter('origin')
    stageMode: string;

    @GetterSetter([])
    stages: DropdownOption[];

    constructor(originCountries: any[]) {
        this.originCountries = originCountries || [];
    }

    getSelectedClients(): { selectedClientCodes: string; selectedClientNames: string } {
        const selectedClientCodes = this.clients.map((c) => c.id).join(',');
        const selectedClientNames = this.clients.map((c) => c.itemName).join(',');

        return { selectedClientCodes, selectedClientNames };
    }

    getRequest(): { params: { [key: string]: string }; headers: { clientGroupCodes: string } } {
        const params = {
            periodType: this.periodType,
        };
        const headers = {
            clientGroupCodes: this.getSelectedClients().selectedClientCodes,
        };

        if (this.stageMode === 'origin') {
            params['origins'] = this.originCountries
                .filter((country) => country.selected)
                .map((country) => country.code)
                .join(',');
            params['steps'] = 'ORIGIN';
        } else {
            params['destinations'] = this.destinationCountries
                .filter((country) => country.selected)
                .map((country) => country.code)
                .join(',');

            if (this.stages.length !== 0 && this.stages.length !== STAGES.length) {
                params['steps'] = this.stages.map((stage) => stage.id).join(',');
            } else {
                params['steps'] = STAGES.map((stage) => stage.code).join(',');
            }
        }

        return {
            params,
            headers,
        };
    }
}
