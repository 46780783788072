import { environment } from '#environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';

@Injectable()
export class NewFeaturesConsumerService {

    public newFeatureActive: boolean = false;
    public isVisibleFeatures = false;
    public asyncHistoricReleases = false;
    public asyncModal = false;
    public imageToShow;
    public base64File;
    public images = [];
    public queryId = Math.random();
    public size = 20;
    public page = 0;
    public pages = [];
    private releases = [];
    private releaseList = [];

    constructor(
        private http: HttpClient,
        private sanitizer: DomSanitizer) {
        this.verifyNewFeatures();
    }

    public openReleaseList() {
        if ($('.featureListModal').hasClass('hidden')) {
            $('.newFeaturesButton').addClass('selected');
            this.historicFeatures();
            this.isVisibleFeatures = true;
            $('.featureListModal').removeClass('hidden');
        } else {
            this.closeReleaseList();
        }
    }
    public closeReleaseList() {
        this.isVisibleFeatures = false;
        $('.newFeaturesButton').removeClass('selected');
        $('.featureListModal').addClass('hidden');
    }

    public getPath(fileName) { return "assets/img/releases/" + fileName; }

    public getReleases() { return this.releases; }
    public getReleaseList() { return this.releaseList; }

    public selectRelease(release) {
        this.asyncModal = true;
        this.releases = [];
        this.getReleaseSelected(release.id).subscribe((releaseReponse) => {
            this.releases = [releaseReponse.body];
            this.asyncModal = false;
        });
        this.notifyNewFeature();
    }

    public historicFeatures() {
        this.asyncHistoricReleases = true;
        this.getAllReleases(this.size, this.page).subscribe((releases) => {
            this.pages = Array.apply(null, { length: releases.body.totalPages }).map(
                Number.call,
                Number,
            );
            this.releaseList = [];
            if (releases.body.content.length > 0) {
                releases.body.content.forEach((eachRelease) => {
                    this.releaseList.push(eachRelease);
                });
            }
            this.asyncHistoricReleases = false;
        });
    }

    public verifyNewFeatures() {
        this.getNewFetures().subscribe((releases) => {
            if (releases.length > 0) {
                this.releaseList = this.releases = releases;
                this.notifyNewFeature();
            }
        });
    }
    public notifyNewFeature() {
        this.newFeatureActive = true;
        $('.newFeaturesComponent').removeClass('hidden');
    }
    public closeNewFeature() {
        this.newFeatureActive = false;
        $('.newFeaturesComponent').addClass('hidden');
    }
    public toFeatureLink(link) {
        window.open(`${link}`);
    }

    public transform(base64Image) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(base64Image);
    }

    public changePage(pageNumber) {
        this.page = (pageNumber - 1);
        this.historicFeatures();
    }

    private getNewFetures(): Observable<any> {
        return this.http.get(environment.endpoints.releasesService);
    }

    private getReleaseSelected(idRelease): Observable<any> {
        return this.http.get(`${environment.endpoints.releasesService}/${idRelease}`,
            { observe: 'response', responseType: 'json' },
        );
    }

    private getAllReleases(size, page): Observable<any> {
        return this.http.get(`${environment.endpoints.releasesService}/summary?size=${size}&page=${page}`,
            { observe: 'response', responseType: 'json' },
        );
    }

}
