import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'cmx-summary-line',
    templateUrl: './cmx-summary-line.component.html',
    styleUrls: ['./cmx-summary-line.component.scss'],
})
export class SummaryLineComponent {
    @Input() title?: string;
    @Input() dataList: object[] = [];
    @Input() fontSize?: string = '12px';
    @Input() marginLeft?: string = '0px';
    @Input() switchList?: string[] = [];

    @Output('onSwitch') onSwitch = new EventEmitter();

    selectedSwitch;

    ngAfterViewInit() {
        this.selectedSwitch = this.switchList.length ? this.switchList[0] : null;
    }

    changeSwitch() {
        this.selectedSwitch = this.switchList[0] === this.selectedSwitch ? this.switchList[1] : this.switchList[0];
        this.onSwitch.emit(this.selectedSwitch);
    }
}
