import { environment } from '#environment';
import { DownloadService } from '#services/_download/download.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class DIValidationService {
    private _baseUrl: string;

    constructor(private http: HttpClient, private downloadService: DownloadService) {
        this._baseUrl = `${environment.endpoints.diValidationService}/api/v1`;
    }

    // NEW_PROCESS, WAITING_UPLOAD, FAILED_VALIDATION, PENDING_SEND_APPROVAL_REQUEST,
    // PENDING_APPROVAL_REQUEST, APPROVAL_REFUSED, APPROVED
    getIMPs(status, impSearch, page, client): Observable<any> {
        let headers = new HttpHeaders();

        headers = headers.set('X-Requested-Client-Groups', client || '');
        const statusList =
            status === 'NEW_PROCESS'
                ? 'NEW_PROCESS, WAITING_UPLOAD, WAITING_VALIDATION, FAILED_VALIDATION, APPROVAL_REFUSED'
                : status;
        return this.http.get(
            `${this._baseUrl}?status=${impSearch ? '' : statusList}&imp=${impSearch}&page=${page}&size=50`,
            { headers }
        );
    }

    uploadFile(data, type, imp) {
        const headers = new HttpHeaders();
        headers.set('Content-Type', 'multipart/form-data');
        return this.http.post(`${this._baseUrl}/upload/${type}/${imp}`, data, { headers });
    }

    compareXML(imp): Observable<any> {
        return this.http.post(`${this._baseUrl}/validate/${imp}`, {});
    }

    getValidated(imp): Observable<any> {
        return this.http.get(`${this._baseUrl}/tags?imp=${imp}`);
    }

    getTagsValidated(imp: string, status?: string): Observable<any> {
        let url = `${this._baseUrl}/tags/validated?imp=${imp}`;

        if (status) {
            url += `&status=${status}`;
        }
        return this.http.get(url);
    }

    downloadFile(type, imp) {
        const options = this.downloadService.getSourceOptions();
        options.headers = new HttpHeaders({ 'X-Requested-Client-Groups': '' });
        const url = this.http.get(`${this._baseUrl}/download/${type}/${imp}`, options);
        return this.downloadService.downloadFrom(url, `${type === 'CLIENT' ? 'Original' : 'Comexport'}_${imp}.xml`);
    }

    forceApproval(imp, comment: string) {
        return this.http.put(`${this._baseUrl}/approval/conditions/${imp}?comment=${comment}`, {});
    }

    internalApproval(imp) {
        return this.http.put(`${this._baseUrl}/approval/${imp}`, {});
    }

    approvalChoice(imp, comment, status) {
        return this.http.post(`${this._baseUrl}/update/approval/${imp}`, {
            comment,
            status,
        });
    }
}
