import { PageTabControlService } from '#services/_page-tab-control/page-tab-control.service';
import { Component, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIKeyGenerationComponent } from './api-key-generation/api-key-generation.component';
import { WebhooksComponent } from './webhooks/webhooks.component';

@Component({
    selector: 'technical-integrations',
    templateUrl: './technical-integrations.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class TechnicalIntegrationsComponent {

    @ViewChild('bodyContent', { read: ViewContainerRef }) _bodyContent: ViewContainerRef;
    pageConfig = {
        title: 'Integrações',
        url: 'integracoes-tech',
        urlParams: this._route.snapshot.params,
        tabs: [
            { code: 'webhooks', title: 'Webhooks', component: WebhooksComponent, permission: 'ROUTE_NOTIFICATIONS_WEBHOOOK' },
            { code: 'apis', title: 'APIs', component: APIKeyGenerationComponent, permission: 'ROUTE_NOTIFICATIONS_WEBHOOOK' },
        ]
    }

    constructor(
        public pageTabControlService: PageTabControlService,
        private _route: ActivatedRoute,
    ) { }

    ngAfterViewInit() {
        setTimeout(() => {
            this.pageTabControlService.setPageConfigs(this.pageConfig, this._bodyContent);
        }, 0);
    }

}
