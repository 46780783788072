
import {throwError as observableThrowError,  Observable } from 'rxjs';

import { environment } from "#environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NewTrafficMapFilter } from 'app/theme/layouts/charts/new-traffic-map/new-vessels/newTrafficMapFilter.model';

@Injectable()
export class NewTrafficTraceabilityService {
  constructor(private _http: HttpClient) {}

  public getClientsWithVessels(filterState: NewTrafficMapFilter): Observable<any> {
    const request = filterState.getRequest();    
    return this._http.get(`${environment.endpoints.newTrafficTraceability}/tracking-vessel/clients`, request);
  }

  public newGetVessels(filterState: NewTrafficMapFilter): Observable<any> {
    const request = filterState.getRequest();
    return this._http.get(`${environment.endpoints.newTrafficTraceability}/tracking-vessel`, request);
  }
}
