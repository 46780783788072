import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { LayoutModule } from '../../layouts/layout.module';
import { SharedModule } from '../../../shared/shared.module';
import { LocationsComponent } from './pages/locations/locations.component';
import { LocationsService } from './services/locations.service';

import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';

const routes: Routes = [
  {
    path: '',
    component: LocationsComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    LayoutModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule
  ],
  declarations: [LocationsComponent],
  exports: [LocationsComponent],
  providers: [LocationsService],
})
export class LocationsModule {}
