import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from 'app/shared/shared.module';
import { TreeTableModule } from 'primeng/treetable';
import { DefaultComponent } from '../default.component';

import { FilterSelection } from './filter-selection';

import { LayoutModule } from '../../../layouts/layout.module';
import { PermissionInfoModule } from '../permission-info/permission-info.module';
import { BalanceSheetComponent } from './balance-sheet.component';
import { FilterStateComponent } from './filter-state.component';
import { StructureComponent } from './structure.component';

import { FilterStateService } from './filter-state.service';
import { GroupingStateService } from './grouping-state.service';
import { HierarchyStateService } from './hierarchy-state.service';
import { SortByStateService } from './sort-by-state.service';

const routes: Routes = [
    {
        path: '',
        component: DefaultComponent,
        children: [
            {
                path: '',
                component: BalanceSheetComponent,
            },
        ],
    },
    {
        path: ':impCode/byImp',
        component: DefaultComponent,
        children: [
            {
                path: '',
                component: BalanceSheetComponent,
            },
        ],
    },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        TreeTableModule,
        LayoutModule,
        PermissionInfoModule,
        SharedModule
    ],
    declarations: [
        StructureComponent,
        BalanceSheetComponent,
        // FilterComponent,
        FilterStateComponent,
    ],
    providers: [
        HierarchyStateService,
        GroupingStateService,
        SortByStateService,
        FilterStateService,
        FilterStateComponent,
        FilterSelection,
    ],
})
export class BalanceSheetModule {

}
