import { Component, ViewEncapsulation } from '@angular/core';
import { DownloadModalService } from '#services/_download/download-modal.service';
import { NotificationService } from '#services/_notification/notification.service';
import { AsyncDownloadsComponent } from 'app/theme/pages/async-download/async-downloads.component';

@Component({
    selector: 'notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    providers: [AsyncDownloadsComponent],
    encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent {

    constructor(
        public asyncDownloadComponent: AsyncDownloadsComponent,
        public notificationService: NotificationService,
        private _downloadModalService: DownloadModalService,
    ) {}

    public notificationClick(e) {
        if (!$(e.target).hasClass('hideArrow')
        && $('.notificationModal .styleContainer').hasClass('downloadSuccess')) {
            this._downloadModalService.openDownloads();
        }
        this.notificationService.closeNotification();
    }

}
