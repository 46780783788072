import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '#environment';
import { Observable } from 'rxjs';

@Injectable()
export class ReleasesCrud {

    constructor(
        private http: HttpClient,
    ) {
    }

    public getReleases(title?, pagination?): Observable<any> {
        const size = pagination.size;
        const page = pagination.page;
        if (title != null || '') {
            return this.http.get(
                `${environment.endpoints.releasesService}/summary?title=${title}&size=${size}&page=${page}`);
        } else {
            return this.http.get(
                `${environment.endpoints.releasesService}/summary?size=${size}&page=${page}`);
        }
    }

    public getReleaseById(id): Observable<any> {
        return this.http.get(`${environment.endpoints.releasesService}/${id}`,
            { observe: 'response', responseType: 'json' },
        );
    }

    public deleteReleases(id): Observable<HttpResponse<any>> {
        return this.http.delete(
            `${environment.endpoints.releasesService}/${id}`,
            { observe: 'response', responseType: 'json' },
        );
    }

    public createReleases(releaseData): Observable<any> {
        return this.http.post(
            `${environment.endpoints.releasesService}`, releaseData,
            { observe: 'response', responseType: 'json' });
    }

    public editEditReleases(id, releaseData): Observable<HttpResponse<any>> {
        return this.http.put(
            `${environment.endpoints.releasesService}/${id}`, releaseData,
            { observe: 'response', responseType: 'json' },
        );
    }

    public loadImage(fileName): Observable<any>{
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Accept', 'Accept:image/png');
        return this.http.get(`assets/img/releases/${fileName}`, {  responseType: 'blob' });
    }
}
