import { IGridColumn } from "app/shared/components/cmx-custom-grid/interfaces";

export const INVOICE_STATUS_COLUMNS: IGridColumn[] = [{
    field: 'invoiceNumber',
    name: 'NF',
    formatterType: 'badge',
    formatterData: {
        backgroundColor: '#345683'
    },
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: 200,
}, {
    field: 'imps' ,
    name: 'IMP',
    formatterType: 'badge',
    formatterData: {
        label: 'Várias',
        classes: 'pointer',
        defaultMultiple: true,
    },
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: 140,
}, {
    field: 'clientReference',
    name: 'Ref. Cliente',
    formatterType: 'multiple',
    formatterData: {
        label: 'Várias',
    },
    width: 200,
}, {
    field: 'clientName',
    name: 'Cliente',
    formatterType: 'multiple',
    width: 300,
}, {
    field: 'subsidiaryCnpj',
    name: 'Filial NF',
    formatterType: 'multiple',
    formatterData: { label: 'Várias' },
    width: 180,
}, {
    field: 'companyName',
    name: 'Empresa',
    width: 300,
}, {
    field: 'vendor',
    name: 'Exportador',
    width: 160,
}, {
    field: 'invoiceDate',
    name: 'Emissão',
    width: 115,
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
}, {
    field: 'invoiceAmount',
    name: 'Valor NF',
    formatterType: 'currency',
    width: 220,
    toolTip: 'Base de cálculo considerando Taxa Bacen',
    headerCssClass: 'grid-header align-right',
}]
