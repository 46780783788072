<div  *ngIf="asyncRegistrationDI" >
    <loader></loader>
</div>
<div class="title-container">
    <span class="analyticsName">TOP DI's Registradas</span>
    <cmx-help-modal help-title="TOP DI's Registradas" [help-description]="helpDescription"></cmx-help-modal>
    <h5>TOP DI's Registradas</h5>
</div>
<div class="moveBtn">
    <div class="moveLine"></div>
</div>
<div id="searchLine" class="reduzed">

    <div class="input-group" >
        <cmx-filter-datepicker
            [alone]="true"
            [date]="'Registro'"
            [startDate]="filterState.getStartDate()"
            [endDate]="filterState.getEndDate()"
            (onChangeDates)="datesChanged($event)"
            type="range-month"
            periodType="month"
        ></cmx-filter-datepicker>
    </div>

    <button (click)="changeDateCustom()" class="applyImpStatus">
        <i class="fa fa-check"></i>
    </button>
</div>
<div *ngIf="!asyncRegistrationDI && registrationDIData === undefined" class="reloadContent">
    <i (click)="loadRegistration()" class="material-icons">refresh</i>
</div>
<div id="graficoDI" style="height: 300px;"></div>


