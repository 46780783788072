<label *ngIf="!config.labelPlaceholder">{{ label }}</label>
<section
  [ngClass]="['select-component', 'select-' + sizeComponent, items.length === 0 || disabled === true ? 'disabled' : '']"
  [style.width]="config.width"
>
  <div [ngClass]="'border-' + config.borderFormat">
    <button type="button" [ngClass]="'button-' + sizeComponent" [id]="id" #buttonSelect [disabled]="disabled">
      <ng-container *ngIf="config.labelPlaceholder && !itemSelected && loaded">
        <span class="placeholder-color">{{ label }}</span>
      </ng-container>
      <ng-container *ngIf="!loaded">
        <span class="placeholder-color">Carregando...</span>
      </ng-container>
      <span *ngIf="itemSelected !== ''"> {{ itemSelected }} </span>
      <i class="material-icons icon-arrow" [ngClass]="toggleItems && loaded ? 'rotate' : ''">keyboard_arrow_down</i>
    </button>
  </div>
  <ul
    [ngClass]="[items.length > 3 ? 'with-scroll' : '', 'border-' + config.borderFormat, 'scroll-' + sizeComponent]"
    *ngIf="toggleItems && loaded"
  >
    <li
      *ngFor="let item of items; trackBy: trackByFn"
      [id]="'option-' + item.value"
      (click)="selectItem(item)"
      [title]="item.name"
      [ngClass]="{ disabled: item.disabled }"
    >
      {{ item.name }}
    </li>
  </ul>
</section>
