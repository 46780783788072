<div *ngIf="!asyncModel" class="fullLoader">
    <loader></loader>
</div>
<div class="followUpSaveContainer">
    <div class="followUpSaveContent">
        <i (click)="closeModelsModal()" class="close la la-close"></i>
        <div class="saveListContainer">
            <div class="ball add" (click)="selectMode('create')">
                <span class="material-icons">add</span>
            </div>
            <hr>
            <div class="dragulaContainer">
                <div class="ball" id="{{save?.modelName}}"
                    *ngFor="let save of followUpModelService.getModelList(); trackBy: trackByFn"
                    (click)="selectMode('edit', save, $event)">
                        <span class="material-icons">{{ save?.icon }}</span>
                </div>
            </div>
            <hr class="inverted">
        </div>
        <div class="saveIconContainer">
            <div class="iconListContent">
                <div class="icon"
                    *ngFor="let icon of followUpIconList.getIconList(); trackBy: trackByFn"
                    (click)="selectIcon(icon)">
                        <span class="material-icons">{{ icon }}</span>
                </div>
            </div>
            <div class="colorListContent">
                <div class="colorBall grey" (click)="selectColor('#808080')"></div>
                <div class="colorBall blue" (click)="selectColor('#0091d6')"></div>
                <div class="colorBall orange" (click)="selectColor('#ea9f00')"></div>
                <div class="colorBall green" (click)="selectColor('#63ad58')"></div>
                <div class="colorBall red" (click)="selectColor('#ad5858')"></div>
                <div class="colorBall yellow" (click)="selectColor('#fbd016')"></div>
                <div class="colorBall violet" (click)="selectColor('#9c73a5')"></div>
                <div class="colorBall brown" (click)="selectColor('#776363')"></div>
            </div>
        </div>
        <div class="saveEditContainer">
            <div class="inputsContainer">
                <input id="followUpSaveName"
                    placeholder="Nome"
                    type="text"
                    [(ngModel)]="modelName">
                <textarea
                    id="followUpSaveDescription"
                    placeholder="Descrição"
                    cols="30" rows="10"
                    [(ngModel)]="modelDescription">
                </textarea>
                <a class="deleteBtn"
                    *ngIf="!createMode && followUpIconList.getIconList().length > 1"
                    (click)="deleteModel()">
                        <i class="la la-trash"></i>
                </a>
            </div>
            <div class="choosenIconContainer">
                <div class="ball mainBall"
                    [ngStyle]="{ 'background-color': selectedColor }">
                    <span class="material-icons"
                        *ngIf="selectedIcon">
                            {{ selectedIcon }}
                        </span>
                </div>
                <div class="ball subBall">
                    <span class="material-icons"
                        *ngIf="selectedIcon"
                        [ngStyle]="{ 'color': selectedColor }">
                            {{ selectedIcon }}
                    </span>
                </div>
            </div>
            <div class="btnContainer">
                <div *ngIf="createMode && validateNewModel()" class="button customFupCreateBtn" (click)="newModel()">CRIAR</div>
                <div *ngIf="createMode && !validateNewModel()" class="button customFupCreateBtn disabled">CRIAR</div>
                <div *ngIf="!createMode && !compareInfo()" class="button customFupSaveBtn" (click)="editModel()">SALVAR</div>
                <div *ngIf="!createMode && compareInfo()" class="button customFupSaveBtn disabled">SALVAR</div>
                <div *ngIf="!createMode" class="button customFupEditBtn" (click)="editCollunsModel()">EDITAR COLUNAS</div>
            </div>
        </div>
    </div>
</div>
